import React, { useEffect, useMemo, useState } from "react";
import UseTopBar from "../../Components/TopBar/TopBar";
import LazyBreadCrum from "../BreadCrum/BreadCrum";
import "./AddCenter.scss";
// import {} from 'react-bootstrap';
import { getFunctions, httpsCallable } from "firebase/functions";
import moment from "moment";
import { Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { useLocation, useNavigate } from "react-router-dom";
import CardFour from "../../Components/Card/CardFour/CardFour";
import UseLoader from "../../Components/Loader/Loader";
import Modal from "../../Components/Modal/Modal";
import UseAlert from "../../Components/UseAlert/UseAlert";
import UseButton from "../../Components/UseButton/UseButton";
moment.locale("it");
const AddCenter = () => {
  const [data, setData] = useState([]);
  const [centerName, setCenterName] = useState("");
  const [cname, setCname] = useState("");
  const [address, setAddress] = useState("");
  const [cemail, setCmail] = useState("");
  const [phone, setPhone] = useState("");
  const [convention, setConvention] = useState("");
  const [conventionList, setConventionList] = useState([]);
  const [city, setCity] = useState("");
  const [loader, setLoader] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [dayToDelete, setDayToDelete] = useState(null);
  const [deleteDayModal, setDeleteDayModal] = useState(false);
  //Alert
  const [show, setShow] = useState(false);
  const [variant, setVarient] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const [deleteUserData, setDeleteUserData] = useState(null);
  const [id, setId] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const functions = getFunctions();
  const addReservations = () => {
    navigate("/Centro-donazione/Scheda-centro/Aggiungi-data", {
      state: { centerName: data?.donationCenter?.name },
    });
  };

  useEffect(() => {
    getCenter();
    getPartnership();
    // console.log('donationid',location?.state?.donationCenterId)
  }, []);

  const csvData = useMemo(() => data, [data]);

  const getPartnership = () => {
    try {
      // const getLocations = httpsCallable(functions,"getLocations")
      const getPartnerships = httpsCallable(functions, "getPartnerships");
      getPartnerships()
        .then((response) => {
          // console.log('loaded partner ship',response)
          let result = response.data.map(({ id, agencyName }) => ({ id, agencyName }));
          // console.log('extracted partnershop',result)
          setConventionList(result);
        })
        .catch((error) => console.error("error", error));
    } catch (error) {
      console.log("error", error);
    }
  };

  const getCenter = () => {
    const donationCenterId = localStorage.getItem("dcid");
    try {
      setLoader(true);
      // const getLocations = httpsCallable(functions,"getLocations")
      const getLocations = httpsCallable(functions, "getDonationCenterById");
      getLocations({ id: location?.state?.donationCenterId || donationCenterId })
        .then((response) => {
          setLoader(false);
          console.log("response111", response);
          setData(response.data);
          setId(response.data.donationCenter.id);
          setCenterName(response.data.donationCenter.name);
          setCname(response.data.donationCenter.contactPersonName);
          setAddress(response.data.donationCenter.address);
          setCmail(response.data.donationCenter.contactPersonEmail);
          setConvention(response.data.donationCenter.partnership);
          setPhone(response.data.donationCenter.contactPersonPhone);
          setCity(response.data.donationCenter.city);
        })
        .catch((error) => console.error("error", error));
    } catch (error) {
      console.log("error", error);
    }
  };

  const updateCenter = (e) => {
    e.preventDefault();
    console.log(
      "Center Name : ",
      "contact person name",
      cname,
      "Address : ",
      address,
      "Email : ",
      cemail,
      "Phone : ",
      phone,
    );
    const data = {
      id: id,
      name: centerName,
      contactPersonName: cname,
      address: address,
      contactPersonEmail: cemail,
      contactPersonPhone: phone,
    };
    setLoader(true);
    const updateDoantioncenter = httpsCallable(functions, "updateDonationCenter");
    updateDoantioncenter(data)
      .then((response) => {
        console.log("edit response", response);
        setShow(true);
        setVarient("success");
        setAlertMsg(response.data.message);
        setLoader(false);
        // setTimeout(() => {
        //       setShow(true)
        //       setVarient('success')
        //       setAlertMsg(response.data.message)
        //       setLoader(false)
        //       navigate('/Centro-donazione')
        // }, 3000);
      })
      .catch((error) => {
        setShow(true);
        setVarient("danger");
        setAlertMsg(error.message);
      })
      .finally(() => setLoader(false));
  };
  const deleteUser = (userId, timeslotId, intervalId) => {
    let postData = {
      userId,
      timeslotId,
      intervalId,
      donationCenterId: data.donationCenter.id,
    };
    setDeleteUserData(postData);
    setDeleteModalOpen(true);
  };

  const onDeleteUserModalCancel = () => {
    setDeleteModalOpen(false);
    setDeleteUserData(null);
  };

  const onDeleteUserModalConfim = (_data) => {
    setLoader(true);
    setDeleteModalOpen(false);
    setDeleteUserData(null);
    const deleteUser = httpsCallable(functions, "removeUserFromBookingSlot");
    deleteUser(_data)
      .then((response) => {
        setLoader(false);
        removeUserFromSlotLocal(_data);
      })
      .catch((error) => {
        console.error("error", error);
        setShow(true);
        setVarient("danger");
        setAlertMsg(error.data.message);
      })
      .finally(() => setLoader(false));
  };
  //   console.log(data);
  const removeUserFromSlotLocal = (_data) => {
    const updatedData = data.data.map((center) => {
      if (center.donationCenterId === _data.donationCenterId) {
        return {
          ...center,
          timeIntervals: center.timeIntervals.map((interval) => {
            if (interval.id === _data.intervalId) {
              return {
                ...interval,
                bookedUsers: interval.bookedUsers.map((user) => {
                  // Only modify user if their userId matches _data.userId
                  if (user.userId === _data.userId) {
                    // Return a new object with the status updated to "canceled"
                    return {
                      ...user,
                      status: "cancelled",
                    };
                  }
                  // For users that don't match the condition, return them unchanged
                  return user;
                }),
              };
            }
            return interval;
          }),
        };
      }
      return center;
    });

    setData({ ...data, data: updatedData });
  };

  const csvUserData = useMemo(() => {
    if (csvData?.data?.length) {
      return [
        ["Data dell'evento", "Nome Evento", "Persone per slot"],
        ...csvData.data.map(({ date, eventName, personPerSlot }) => [
          date,
          eventName,
          personPerSlot,
        ]),
      ];
    } else {
      return [];
    }
  }, [csvData]);
  return (
    <div className='AddCenter' data-testid='AddCenter'>
      <UseTopBar back='Centro-donazione' />
      <LazyBreadCrum />
      {/* {(location?.state?.centerName && location?.state?.donationCenterId) &&
            <h2>{location?.state?.centerName || '-'}</h2>
      } */}

      {data?.donationCenter && <h2>{data?.donationCenter?.name || "-"}</h2>}
      <section className='addCenter'>
        <Tabs id='uncontrolled-tab-example' className='mb-3'>
          <Tab eventKey='home' title='Prenotazioni'>
            <div className='groupBtn'>
              <div className='adminBtnPrimary UseButton'>
                <CSVLink
                  name='adminBtnPrimary'
                  className='btn btn-btn btn-outline-primary'
                  filename='centro-donazione.csv'
                  data={csvUserData}
                  style={{ borderRadius: 12, marginRight: 10 }}
                >
                  Esporta CSV
                </CSVLink>
              </div>
              <UseButton
                name='adminBtnPrimary'
                variant='primary'
                labels='Aggiungi data'
                onClick={addReservations}
              />
            </div>

            <Row>
              {data?.data?.length > 0
                ? data?.data?.map((datas, index) => {
                    return (
                      <Col xs={12} sm={4} md={4} lg={4} key={index}>
                        <CardFour
                          data={datas}
                          onDeleteItem={deleteUser}
                          onDeleteDay={(d) => {
                            setDeleteDayModal(true);
                            setDayToDelete(d);
                          }}
                        />
                      </Col>
                    );
                  })
                : "Nessun dato"}
            </Row>

            {/* <Row>
                                <Col xs={12} sm={4} md={4} lg={4}>
                                      <CardFour />
                                </Col>
                                <Col xs={12} sm={4} md={4} lg={4}>
                                      <CardFour />
                                </Col>
                                <Col xs={12} sm={4} md={4} lg={4}>
                                      <CardFour />
                                </Col>
                            </Row> */}
          </Tab>
          <Tab eventKey='profile' title='Informazioni'>
            <form onSubmit={updateCenter}>
              <Row>
                <Col xs={12} sm={6} md={6} lg={6}>
                  <Form.Group className='mb-3' controlId='formBasicEmail'>
                    <Form.Label>Nome centro</Form.Label>
                    <Form.Control
                      type='text'
                      name='centerName'
                      value={centerName}
                      onChange={(e) => setCenterName(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6}>
                  <Form.Group className='mb-3' controlId='formBasicEmail'>
                    <Form.Label>Referente</Form.Label>
                    <Form.Control
                      type='text'
                      name='cname'
                      value={cname}
                      onChange={(e) => setCname(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6}>
                  <Form.Group className='mb-3' controlId='formBasicEmail'>
                    <Form.Label>Indirizzo</Form.Label>
                    <Form.Control
                      type='text'
                      name='address'
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6}>
                  <Form.Group className='mb-3' controlId='formBasicEmail'>
                    <Form.Label>Email referente</Form.Label>
                    <Form.Control
                      type='text'
                      name='cemail'
                      value={cemail}
                      onChange={(e) => setCmail(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6}>
                  <Form.Group className='mb-3' controlId='formBasicEmail'>
                    <Form.Label>Città</Form.Label>
                    <Form.Control
                      type='text'
                      name='city'
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6}>
                  <Form.Group className='mb-3' controlId='formBasicEmail'>
                    <Form.Label>Numero di telefono referente</Form.Label>
                    <Form.Control
                      type='text'
                      name='phone'
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6}>
                  <Form.Group className='mb-3' controlId='formBasicEmail'>
                    <Form.Label>Convenzione</Form.Label>
                    {/* <Form.Control type="text" name="convention" value={convention} onChange={(e) => setConvention(e.target.value)} /> */}
                    <Form.Select
                      name='convention'
                      value={convention}
                      onChange={(e) => setConvention(e.target.value)}
                    >
                      {conventionList.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.agencyName}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col
                  xs={12}
                  sm={6}
                  md={12}
                  lg={12}
                  className='d-flex justify-content-end'
                >
                  <UseButton
                    name='adminBtnPrimary'
                    variant='primary'
                    labels='Salva modifiche'
                  />
                </Col>
              </Row>
            </form>
          </Tab>
        </Tabs>
      </section>
      {deleteModalOpen && (
        <Modal
          title='Sei sicuro?'
          onConfirmReturnData={deleteUserData}
          onCancel={onDeleteUserModalCancel}
          onConfirm={onDeleteUserModalConfim}
        >
          <p>Una volta eliminato non potrai tornare indietro</p>
        </Modal>
      )}
      {deleteDayModal && (
        <Modal
          title={`Vuoi davvero eliminare questa data ${moment(dayToDelete.date).format(
            "D MMMM",
          )}`}
          onCancel={() => {
            setDeleteDayModal(false);
          }}
          onConfirm={() => {
            setDeleteDayModal(false);
            setLoader(true);
            const deleteEvents = httpsCallable(functions, "deleteDonationCenterTimeslot");

            deleteEvents({
              timeslotId: dayToDelete.id,
              donationCenterId: dayToDelete.donationCenterId,
            })
              .then((response) => {
                setLoader(false);
                getCenter();
              })
              .catch((error) => {
                setLoader(false);
              });
          }}
        >
          <p>Una volta eliminato non potrai tornare indietro</p>
        </Modal>
      )}
      {loader == true && <UseLoader />}
      <UseAlert show={show} setShow={setShow} variant={variant} alertMsg={alertMsg} />
    </div>
  );
};

AddCenter.propTypes = {};

AddCenter.defaultProps = {};

export default AddCenter;
