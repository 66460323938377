import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import UseLoader from "../../Components/Loader/Loader";
import UseTopBar from "../../Components/TopBar/TopBar";
import UseAlert from "../../Components/UseAlert/UseAlert";
import UseButton from "../../Components/UseButton/UseButton";
import LazyBreadCrum from "../BreadCrum/BreadCrum";
import "./AddProfile.scss";
const AddProfile = () => {
  const [image, setImage] = useState("");
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [variant, setVarient] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const functions = getFunctions();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      lastName: "",
      role: "",
      email: "",
      profileImage: "",
    },
    mode: "onSubmit",
  });
  const navigate = useNavigate();

  const onSubmit = (data) => {
    try {
      setLoader(true);
      const updateProfile = httpsCallable(functions, "createCollaborator");
      updateProfile(data)
        .then((response) => {
          setLoader(false);
          console.log("edit prodfile reesponse", response);
          setShow(true);
          setVarient("success");
          setAlertMsg("profilo creato con successo");
          setTimeout(() => {
            navigate("/Impostazioni", { state: { addTabs: "profile" } });
            //   setActiveKey('profile');
          }, 2000);
        })
        .catch((error) => {
          console.error("error", error);
          setShow(true);
          setVarient("success");
          setAlertMsg(error.message);
        });
    } catch (error) {
      console.error("error", error);
    } finally {
      setLoader(false);
    }
  };
  return (
    <div className='AddProfile' data-testid='AddProfile'>
      <UseTopBar back='Impostazioni' />
      <LazyBreadCrum />
      <h2>Crea nuovo profilo</h2>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs={12} sm={6} md={6} lg={6}>
            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <Form.Label>Nome</Form.Label>
              <Form.Control
                type='text'
                name='name'
                {...register("name", { required: true })}
              />
              {errors.name && (
                <p
                  style={{
                    color: "red",
                    fontSize: "14px",
                    position: "relative",
                    top: "5px",
                  }}
                >
                  Per favore inserisci Nome
                </p>
              )}
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}>
            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type='email'
                name='email'
                {...register("email", {
                  required: true,
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "ID e-mail non valido",
                  },
                })}
              />
              {errors.email && (
                <p
                  style={{
                    color: "red",
                    fontSize: "14px",
                    position: "relative",
                    top: "5px",
                  }}
                >
                  Inserisci un ID e-mail valido
                </p>
              )}
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}>
            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <Form.Label>Cognome</Form.Label>
              <Form.Control type='text' name='lastName' {...register("lastName")} />
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}>
            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <Form.Label>Ruolo</Form.Label>
              <Form.Select name='role' {...register("role")}>
                <option value='admin'>Admin</option>
                <option value='viewer'>Viewer</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}></Col>
          {/* <Col xs={12} sm={6} md={6} lg={6}>
                     <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Password</Form.Label>
                            <UseButton name="adminBtnPrimary" variant="outline-primary" labels="Invia link reset" />
                     </Form.Group>
              </Col>
             */}
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className='d-flex justify-content-end profileBtn'
          >
            <UseButton
              name='adminBtnPrimary profileBottomBtn'
              variant='primary'
              labels='Salva modifiche'
            />
          </Col>
        </Row>
      </Form>
      <UseAlert show={show} setShow={setShow} variant={variant} alertMsg={alertMsg} />
      {loader == true && <UseLoader />}
    </div>
  );
};

AddProfile.propTypes = {};

AddProfile.defaultProps = {};

export default AddProfile;
