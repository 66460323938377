import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CardBox from "../../Components/Card/CardBox/CardBox";
import UseNavLink from "../../Components/Card/NavLink/UseNavLink";
import UseLoader from "../../Components/Loader/Loader";
import UseTopBar from "../../Components/TopBar/TopBar";
import { useAuth } from "../../Hookes/useAuth";
import "./Dashboard.scss";
const Dashboard = () => {
  const { currentUser, loading } = useAuth();
  const [data, setData] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [status, setStatus] = useState([]);
  const [loader, setLoader] = useState(true);
  const [username, setUserName] = useState("");
  const functions = getFunctions();
  const navigate = useNavigate();
  useEffect(() => {
    let name = localStorage.getItem("name");
    setUserName(name);
    getDonarDetails();
    getCompanyDetails();
    getDashboardStatus();
  }, []);

  const getDonarDetails = () => {
    try {
      const getDonorsList = httpsCallable(functions, "getDonors");
      getDonorsList()
        .then((response) => {
          setLoader(false);
          console.log("doanr response1111111111", response);
          setData(
            response.data
              .sort((a, b) => b.createdAt._seconds - a.createdAt._seconds)
              .slice(0, 6),
          );
        })
        .catch((error) => console.error("error", error));
    } catch (error) {
      console.log("error", error);
    }
  };

  const getCompanyDetails = () => {
    try {
      const getcompaniesList = httpsCallable(functions, "getCompanies");
      getcompaniesList()
        .then((response) => {
          setLoader(false);
          console.log("companies response", response);
          setCompanies(response.data.slice(0, 6));
        })
        .catch((error) => console.error("error", error));
    } catch (error) {
      console.log("error", error);
    }
  };

  const getDashboardStatus = () => {
    try {
      const dashboardStatus = httpsCallable(functions, "getDashboardStats");
      dashboardStatus()
        .then((response) => {
          setLoader(false);
          console.log("dashboard status", response);
          setStatus(response.data);
        })
        .catch((error) => console.error("error", error));
    } catch (error) {
      console.log("error", error);
    }
  };

  const editCompanys = (id, name) => {
    console.log("id", id);
    navigate("/Aziende/Modifica-azienda", {
      state: {
        editCompanyId: id,
        companyName: name,
        editCompany: true,
      },
    });
  };

  const registeredUsers = (id, name, surname, userId) => {
    console.log("data11111", id, ",", "name : ", name, "userId : ", userId);
    navigate("/Utenti/Modificare-Utenti", {
      state: {
        editUserId: id,
        userName: name,
        surName: surname,
        userId: userId,
        editUserStatus: true,
      },
    });
  };
  // console.log('dataaaaaaaaaaaaa',data)
  if (loading) return null;
  return (
    <div className='Dashboard' data-testid='Dashboard'>
      <UseTopBar />
      <h2>
        Ciao, <span>{currentUser?.displayName || "User"}</span>!
      </h2>
      <Row>
        <Col xs={12} sm={6} md={6} lg={3}>
          <CardBox
            value={status?.donatedToday}
            text='Donatori del giorno'
            styleBg={{ background: "#F7F8FC" }}
            color={{ color: "#5C6BC0" }}
          />
        </Col>
        <Col xs={12} sm={6} md={6} lg={3}>
          <CardBox
            value={status?.totalDonors}
            text='Donatori totali'
            styleBg={{ background: "#FFF7FA" }}
            color={{ color: "#EC407A" }}
          />
        </Col>
        <Col xs={12} sm={6} md={6} lg={3}>
          <CardBox
            value={status.totalUsers}
            text='Utenti registrati'
            styleBg={{ background: "#FFF6ED" }}
            color={{ color: "#FB8200" }}
          />
        </Col>
        <Col xs={12} sm={6} md={6} lg={3}>
          <CardBox
            value={status.totalCompanies}
            text='Aziende totali'
            styleBg={{ background: "#F0FAFA" }}
            color={{ color: "#069697" }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} lg={6} className='tableCol'>
          <h6>
            Ultimi utenti registrati <UseNavLink label='Vedi tutto' link={"/Utenti"} />
          </h6>
          {/* <CardList value="23" text="Donatori del giorno" data={RegisteredUser} /> */}
          <Table hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Cognome</th>
                <th>Data registrazione</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 ? (
                data?.map((value, index) => {
                  return (
                    <tr key={index}>
                      <td className='user_name'>{value.name}</td>
                      <td>{value.surname}</td>
                      <td>
                        {new Date(
                          value.updatedAt._seconds * 1000 +
                            value.updatedAt._nanoseconds / 1000000,
                        ).toLocaleDateString("en-GB", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "2-digit",
                        })}
                      </td>
                      <td>
                        <svg
                          onClick={() =>
                            registeredUsers(value.id, value.name, value.surname)
                          }
                          style={{ cursor: "pointer" }}
                          width='21'
                          height='20'
                          viewBox='0 0 21 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <g clipPath='url(#clip0_15171_52262)'>
                            <path
                              d='M19.8211 9.74687C19.7937 9.68516 19.132 8.21719 17.6609 6.74609C15.7008 4.78594 13.225 3.75 10.5 3.75C7.77499 3.75 5.29921 4.78594 3.33905 6.74609C1.86796 8.21719 1.20312 9.6875 1.1789 9.74687C1.14336 9.82681 1.125 9.91331 1.125 10.0008C1.125 10.0883 1.14336 10.1748 1.1789 10.2547C1.20624 10.3164 1.86796 11.7836 3.33905 13.2547C5.29921 15.2141 7.77499 16.25 10.5 16.25C13.225 16.25 15.7008 15.2141 17.6609 13.2547C19.132 11.7836 19.7937 10.3164 19.8211 10.2547C19.8566 10.1748 19.875 10.0883 19.875 10.0008C19.875 9.91331 19.8566 9.82681 19.8211 9.74687ZM10.5 15C8.0953 15 5.99452 14.1258 4.25546 12.4023C3.5419 11.6927 2.93483 10.8836 2.45312 10C2.9347 9.11636 3.54179 8.30717 4.25546 7.59766C5.99452 5.87422 8.0953 5 10.5 5C12.9047 5 15.0055 5.87422 16.7445 7.59766C17.4595 8.307 18.0679 9.11619 18.5508 10C17.9875 11.0516 15.5336 15 10.5 15ZM10.5 6.25C9.75831 6.25 9.03329 6.46993 8.4166 6.88199C7.79992 7.29404 7.31927 7.87971 7.03544 8.56494C6.75162 9.25016 6.67735 10.0042 6.82205 10.7316C6.96674 11.459 7.32389 12.1272 7.84834 12.6517C8.37279 13.1761 9.04097 13.5332 9.7684 13.6779C10.4958 13.8226 11.2498 13.7484 11.9351 13.4645C12.6203 13.1807 13.2059 12.7001 13.618 12.0834C14.0301 11.4667 14.25 10.7417 14.25 10C14.249 9.00575 13.8535 8.05253 13.1505 7.34949C12.4475 6.64645 11.4942 6.25103 10.5 6.25ZM10.5 12.5C10.0055 12.5 9.52219 12.3534 9.11107 12.0787C8.69994 11.804 8.37951 11.4135 8.19029 10.9567C8.00107 10.4999 7.95157 9.99723 8.04803 9.51227C8.14449 9.02732 8.38259 8.58186 8.73222 8.23223C9.08186 7.8826 9.52731 7.6445 10.0123 7.54804C10.4972 7.45157 10.9999 7.50108 11.4567 7.6903C11.9135 7.87952 12.304 8.19995 12.5787 8.61107C12.8534 9.0222 13 9.50555 13 10C13 10.663 12.7366 11.2989 12.2678 11.7678C11.7989 12.2366 11.163 12.5 10.5 12.5Z'
                              fill='#2264D1'
                              stroke='#2264D1'
                              strokeWidth='0.637037'
                            />
                          </g>
                          <defs>
                            <clipPath id='clip0_15171_52262'>
                              <rect
                                width='20'
                                height='20'
                                fill='white'
                                transform='translate(0.5)'
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan='4'>Nessun dato</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
        <Col xs={12} sm={12} md={12} lg={6} className='tableCol'>
          <h6>
            Ultime aziende registrate <UseNavLink label='Vedi tutto' link={"/Aziende"} />
          </h6>
          {/* <CardList value="31" text="Aziende totali" data={RegisteredCompanies} /> */}
          <Table hover>
            <thead>
              <tr>
                <th>Ragione sociale</th>
                <th>Utenti iscritti</th>
                <th align='center'>Stato</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {companies.length > 0 ? (
                companies?.map((value, index) => {
                  return (
                    <tr key={index}>
                      <td className='user_name'>{value.name}</td>
                      <td>{value.totalUsers}</td>
                      <td>
                        {value.isActive || value.isActive == "" ? (
                          <span
                            className={value.isActive ? "activeUser" : "inactiveUser"}
                          ></span>
                        ) : null}
                      </td>
                      {/* <td>{value.isActive?<span className={value.isActive?'activeUser':'inactiveUser'}></span>:null}</td>      */}
                      <td align='center'>
                        <svg
                          style={{ cursor: "pointer" }}
                          onClick={() => editCompanys(value.id, value.name)}
                          width='21'
                          height='20'
                          viewBox='0 0 21 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <g clipPath='url(#clip0_15171_52262)'>
                            <path
                              d='M19.8211 9.74687C19.7937 9.68516 19.132 8.21719 17.6609 6.74609C15.7008 4.78594 13.225 3.75 10.5 3.75C7.77499 3.75 5.29921 4.78594 3.33905 6.74609C1.86796 8.21719 1.20312 9.6875 1.1789 9.74687C1.14336 9.82681 1.125 9.91331 1.125 10.0008C1.125 10.0883 1.14336 10.1748 1.1789 10.2547C1.20624 10.3164 1.86796 11.7836 3.33905 13.2547C5.29921 15.2141 7.77499 16.25 10.5 16.25C13.225 16.25 15.7008 15.2141 17.6609 13.2547C19.132 11.7836 19.7937 10.3164 19.8211 10.2547C19.8566 10.1748 19.875 10.0883 19.875 10.0008C19.875 9.91331 19.8566 9.82681 19.8211 9.74687ZM10.5 15C8.0953 15 5.99452 14.1258 4.25546 12.4023C3.5419 11.6927 2.93483 10.8836 2.45312 10C2.9347 9.11636 3.54179 8.30717 4.25546 7.59766C5.99452 5.87422 8.0953 5 10.5 5C12.9047 5 15.0055 5.87422 16.7445 7.59766C17.4595 8.307 18.0679 9.11619 18.5508 10C17.9875 11.0516 15.5336 15 10.5 15ZM10.5 6.25C9.75831 6.25 9.03329 6.46993 8.4166 6.88199C7.79992 7.29404 7.31927 7.87971 7.03544 8.56494C6.75162 9.25016 6.67735 10.0042 6.82205 10.7316C6.96674 11.459 7.32389 12.1272 7.84834 12.6517C8.37279 13.1761 9.04097 13.5332 9.7684 13.6779C10.4958 13.8226 11.2498 13.7484 11.9351 13.4645C12.6203 13.1807 13.2059 12.7001 13.618 12.0834C14.0301 11.4667 14.25 10.7417 14.25 10C14.249 9.00575 13.8535 8.05253 13.1505 7.34949C12.4475 6.64645 11.4942 6.25103 10.5 6.25ZM10.5 12.5C10.0055 12.5 9.52219 12.3534 9.11107 12.0787C8.69994 11.804 8.37951 11.4135 8.19029 10.9567C8.00107 10.4999 7.95157 9.99723 8.04803 9.51227C8.14449 9.02732 8.38259 8.58186 8.73222 8.23223C9.08186 7.8826 9.52731 7.6445 10.0123 7.54804C10.4972 7.45157 10.9999 7.50108 11.4567 7.6903C11.9135 7.87952 12.304 8.19995 12.5787 8.61107C12.8534 9.0222 13 9.50555 13 10C13 10.663 12.7366 11.2989 12.2678 11.7678C11.7989 12.2366 11.163 12.5 10.5 12.5Z'
                              fill='#2264D1'
                              stroke='#2264D1'
                              strokeWidth='0.637037'
                            />
                          </g>
                          <defs>
                            <clipPath id='clip0_15171_52262'>
                              <rect
                                width='20'
                                height='20'
                                fill='white'
                                transform='translate(0.5)'
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan='4'>Nessun dato</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
      {loader == true && <UseLoader />}
    </div>
  );
};

Dashboard.propTypes = {};

Dashboard.defaultProps = {};

export default Dashboard;
