import React, { useState } from "react";
import Modal from "../../Modal/Modal";

import { getFunctions } from "firebase/functions";
import { Col, Row } from "react-bootstrap";
import "../../../../node_modules/react-responsive-carousel/lib/styles/carousel.min.css";
import CardEight from "../../Card/CardEight/CardEight";
import "./CardSliderFour.scss";
const CardSliderFour = ({ events = [] }) => {
  const functions = getFunctions();
  const [onDonateModal, setOnDonateModal] = useState(false);

  const [modalData, setModalData] = useState(null);

  return (
    <div className='CardSliderFour' data-testid='CardSliderFour'>
      <Row>
        {events.map((_data, index) => {
          return (
            <Col xs='12' sm='6' lg='4'>
              <CardEight
                onDownload={(_data) => {
                  setOnDonateModal(true);
                  setModalData(_data.downloadType == "text" ? _data.downloadText : null);
                }}
                key={index}
                data={_data}
              />
            </Col>
          );
        })}
      </Row>

      {!events.length && <p className='noSlot'>Nessuna donazione passata trovata</p>}
      {onDonateModal && (
        <Modal
          cancelText={"Chiudi"}
          onCancel={() => setOnDonateModal(false)}
          redButtons={true}
          showConfirm={false}
          title={""}
        >
          <p>{modalData}</p>
        </Modal>
      )}
    </div>
  );
};

CardSliderFour.propTypes = {};

CardSliderFour.defaultProps = {};

export default CardSliderFour;
