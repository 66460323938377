import { useState } from "react";
import UseButton from "../UseButton/UseButton";
import "./Modal.scss";
function Modal({
  onCancel,
  onConfirm,
  onConfirmReturnData,
  showCancel = true,
  showConfirm = true,
  title,
  children,
  redButtons,
  cancelText,
  confirmText,
  className,
}) {
  const [isClosing, setIsClosing] = useState(false);
  return (
    <div className={["_modal", className].join(" ")}>
      <div
        className={["_modal__overlay", isClosing ? "_modal__overlay_closing" : ""].join(
          " ",
        )}
        onClick={(e) => {
          setIsClosing(true);
          setTimeout(() => {
            onCancel();
          }, 100);
        }}
      ></div>
      <div
        className={["_modal__main", isClosing ? "_modal__main_closing" : ""].join(" ")}
      >
        <div className={"_modal__title"}>{title}</div>
        <div className={"_modal__body"}>{children}</div>
        <div className={"_modal__actions"}>
          {showCancel && (
            <UseButton
              variant={"btn btn-outline-primary" + redButtons ? " redButtonShallow" : ""}
              labels={cancelText || "Annulla"}
              name='adminBtnPrimary'
              className={"_modal__actions_button"}
              onClick={() => {
                setIsClosing(true);
                setTimeout(() => {
                  onCancel();
                }, 100);
              }}
            ></UseButton>
          )}
          {showConfirm && (
            <UseButton
              variant={"primary" + redButtons ? " redButton" : ""}
              labels={confirmText || "Elimina"}
              name='adminBtnPrimary'
              className={"_modal__actions_button adminBtnPrimary"}
              onClick={() => onConfirm && onConfirm(onConfirmReturnData)}
            >
              Confirm
            </UseButton>
          )}
        </div>
      </div>
    </div>
  );
}
export default Modal;
