import { onAuthStateChanged, signOut } from "firebase/auth";
import * as React from "react";
import { auth } from "../Config/firebase";

const AuthContext = React.createContext(null);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = React.useState(null);
  const [uid, setUid] = React.useState(null);
  const [role, setRole] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setCurrentUser(currentUser);
        setUid(currentUser?.uid);
        currentUser?.reloadUserInfo?.customAttributes &&
          setRole(
            JSON.parse(currentUser?.reloadUserInfo.customAttributes).role || "donor",
          );
      }
      setLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const logout = () => {
    setLoading(true);
    return signOut(auth).then(() => {
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
    });
  };

  const value = {
    currentUser,
    uid,
    role,
    logout,
    loading,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return React.useContext(AuthContext);
};
