import { useEffect, useState } from "react";
const UseWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    handleResize();

    window.addEventListener("resize", handleResize);

    const clearWindowResize = () => {
      window.removeEventListener("resize", handleResize);
    };

    return clearWindowResize;
  }, []);
  return windowSize;
};
export default UseWindowSize;
