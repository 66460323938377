import React from "react";
import { Form } from "react-bootstrap";
import "./BookingStatus.scss";
const BookingStatus = ({
  open,
  setOpen,
  status,
  getBookingStatus,
  changeStatus,
  disabled,
}) => {
  return (
    <div
      className={["bookingStatus", disabled ? " bookingStatusDisabled" : ""].join(" ")}
    >
      <div
        className={
          getBookingStatus === "pending"
            ? "progress showBookingStatus"
            : getBookingStatus === "completed"
            ? "carried showBookingStatus"
            : getBookingStatus === "cancelled"
            ? "cancelled showBookingStatus"
            : getBookingStatus === "failed"
            ? "failed showBookingStatus"
            : getBookingStatus === "analyzed"
            ? "analyzed showBookingStatus"
            : ""
        }
        onClick={() => setOpen(!open)}
      >
        {status.filter((s) => s.value == getBookingStatus)[0]?.label}
      </div>
      <div className='selctBookingSttaus' style={{ display: open ? "block" : "none" }}>
        {status.map((value, index) => {
          return (
            <div className={getBookingStatus === value ? "active" : "status"}>
              <Form.Group controlId={value.value}>
                <Form.Check
                  key={index}
                  type='radio'
                  value={value.value}
                  label={value.label}
                  name='bookingStatus'
                  checked={getBookingStatus === value.value}
                  onChange={changeStatus}
                />
              </Form.Group>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default BookingStatus;
