import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import UseLoader from "../../Components/Loader/Loader";
import UseAlert from "../../Components/UseAlert/UseAlert";
import BLogo from "../../assets/images/bloodLogo.png";

const RegistrationCancelled = () => {
  const navigate = useNavigate();
  const functions = getFunctions();
  const { donationCenterId, timeslotId, intervalId, userId, bookingId } = useParams();
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [variant, setVarient] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const [isCancelled, setIsCancelled] = useState(false);

  useEffect(() => {
    setLoader(true);
    const checkReservation = httpsCallable(functions, "getReservationById");
    checkReservation({ id: bookingId })
      .then((response) => {
        setLoader(false);
        setShow(false);
        if (response.data.status === "cancelled") {
          setIsCancelled(true);
          setShow(true);
          setVarient("danger");
          setAlertMsg("Prenotazione già annullata");
        }
      })
      .catch((error) => {
        console.error("error", error);
        setShow(true);
        setVarient("danger");
        setAlertMsg("Fallito");
      })
      .finally(() => {
        setLoader(false);
      });
  }, [bookingId, functions]);

  const handleCancel = () => {
    setLoader(true);
    const deleteUser = httpsCallable(functions, "removeUserFromBookingSlot");
    deleteUser({ donationCenterId, timeslotId, intervalId, userId })
      .then((response) => {
        setLoader(false);
        setShow(true);
        setVarient("success");
        setAlertMsg("Prenotazione annullata");
        setLoader(false);
        setIsCancelled(true);
      })
      .catch((error) => {
        console.error("error", error);
        setShow(true);
        setVarient("danger");
        setAlertMsg("Fallito");
      })
      .finally(() => {
        setLoader(false);
      });
  };

  if (loader) return <UseLoader />;

  return (
    <div className='GuestRegister' data-testid='GuestRegister'>
      <div className='booking-failer'>
        <div className='failerDetails'>
          <img src={BLogo} alt='Rosso' className='logo' />
          {!isCancelled ? (
            <>
              <h2>Sei sicur* di voler eliminare la prenotazione?</h2>
              <Button onClick={handleCancel} disabled={isCancelled}>
                Elimina prenotazione
              </Button>
            </>
          ) : (
            <>
              <h2>La tua prenotazione è stata cancellata con successo</h2>
              <p style={{ fontWeight: "normal", color: "#111928", fontSize: "1rem" }}>
                Visita il nostro sito web
              </p>
              <Button href='https://donarosso.it/'>Sito web</Button>
            </>
          )}
        </div>
      </div>
      <UseAlert show={show} setShow={setShow} variant={variant} alertMsg={alertMsg} />
    </div>
  );
};

export default RegistrationCancelled;
