import React from "react";
import "./venudetails.scss";

const Venudetails = () => (
  <div className='venudetails' data-testid='Venudetails'>
    Venudetails Component
  </div>
);

Venudetails.propTypes = {};

Venudetails.defaultProps = {};

export default Venudetails;
