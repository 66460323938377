import React, { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import "./FAQ.scss";
const Faq = () => {
  const [Id, setId] = useState();

  const setPos = (pos) => {
    if (pos == Id) {
      setId(null);
    } else {
      setId(pos);
    }
  };

  return (
    <div className='FAQ' data-testid='Faq'>
      <span id='FAQ'></span>
      <h2>Domande frequenti</h2>
      <Row>
        <Col xs={12} sm={5} md={5} lg={5}>
          <Card>
            <Card.Body>
              <Card.Header className={Id === 0 ? "open" : ""} onClick={() => setPos(0)}>
                <svg
                  width='42'
                  height='42'
                  viewBox='0 0 42 42'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <rect width='42' height='42' rx='10' fill='#E8C6C9' fillOpacity='0.3' />
                  <path
                    d='M20.9998 25.675C20.7936 25.675 20.6217 25.6062 20.4498 25.4687L12.5436 17.7C12.2342 17.3906 12.2342 16.9094 12.5436 16.6C12.8529 16.2906 13.3342 16.2906 13.6436 16.6L20.9998 23.7844L28.3561 16.5312C28.6654 16.2219 29.1467 16.2219 29.4561 16.5312C29.7654 16.8406 29.7654 17.3219 29.4561 17.6312L21.5498 25.4C21.3779 25.5719 21.2061 25.675 20.9998 25.675Z'
                    fill='#BB0F31'
                  />
                </svg>{" "}
                Tutti possono diventare donatori?
              </Card.Header>
              <Card.Text eventKey='0' className={Id === 0 ? "show" : "hide"}>
                Chiunque abbia compiuto i 18 anni di età e pesi più di 50 kg. Un medico
                effettuerà un colloquio, una visita, e gli accertamenti di tipo
                diagnostico e strumentale per verificare che non vi siano
                controindicazioni alla donazione.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <Card.Header className={Id === 1 ? "open" : ""} onClick={() => setPos(1)}>
                <svg
                  width='42'
                  height='42'
                  viewBox='0 0 42 42'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <rect width='42' height='42' rx='10' fill='#E8C6C9' fillOpacity='0.3' />
                  <path
                    d='M20.9998 25.675C20.7936 25.675 20.6217 25.6062 20.4498 25.4687L12.5436 17.7C12.2342 17.3906 12.2342 16.9094 12.5436 16.6C12.8529 16.2906 13.3342 16.2906 13.6436 16.6L20.9998 23.7844L28.3561 16.5312C28.6654 16.2219 29.1467 16.2219 29.4561 16.5312C29.7654 16.8406 29.7654 17.3219 29.4561 17.6312L21.5498 25.4C21.3779 25.5719 21.2061 25.675 20.9998 25.675Z'
                    fill='#BB0F31'
                  />
                </svg>{" "}
                Bisogna essere a digiuno per donare sangue?
              </Card.Header>
              <Card.Text eventKey='0' className={Id === 1 ? "show" : "hide"}>
                Chiunque abbia compiuto i 18 anni di età e pesi più di 50 kg. Un medico
                effettuerà un colloquio, una visita, e gli accertamenti di tipo
                diagnostico e strumentale per verificare che non vi siano
                controindicazioni alla donazione.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} sm={7} md={7} lg={7}>
          <Card>
            <Card.Body>
              <Card.Header className={Id === 2 ? "open" : ""} onClick={() => setPos(2)}>
                <svg
                  width='42'
                  height='42'
                  viewBox='0 0 42 42'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <rect width='42' height='42' rx='10' fill='#E8C6C9' fillOpacity='0.3' />
                  <path
                    d='M20.9998 25.675C20.7936 25.675 20.6217 25.6062 20.4498 25.4687L12.5436 17.7C12.2342 17.3906 12.2342 16.9094 12.5436 16.6C12.8529 16.2906 13.3342 16.2906 13.6436 16.6L20.9998 23.7844L28.3561 16.5312C28.6654 16.2219 29.1467 16.2219 29.4561 16.5312C29.7654 16.8406 29.7654 17.3219 29.4561 17.6312L21.5498 25.4C21.3779 25.5719 21.2061 25.675 20.9998 25.675Z'
                    fill='#BB0F31'
                  />
                </svg>{" "}
                Per le donne donare sangue non è dannoso?
              </Card.Header>
              <Card.Text eventKey='0' className={Id === 2 ? "show" : "hide"}>
                Chiunque abbia compiuto i 18 anni di età e pesi più di 50 kg. Un medico
                effettuerà un colloquio, una visita, e gli accertamenti di tipo
                diagnostico e strumentale per verificare che non vi siano
                controindicazioni alla donazione.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <Card.Header className={Id === 3 ? "open" : ""} onClick={() => setPos(3)}>
                <svg
                  width='42'
                  height='42'
                  viewBox='0 0 42 42'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <rect width='42' height='42' rx='10' fill='#E8C6C9' fillOpacity='0.3' />
                  <path
                    d='M20.9998 25.675C20.7936 25.675 20.6217 25.6062 20.4498 25.4687L12.5436 17.7C12.2342 17.3906 12.2342 16.9094 12.5436 16.6C12.8529 16.2906 13.3342 16.2906 13.6436 16.6L20.9998 23.7844L28.3561 16.5312C28.6654 16.2219 29.1467 16.2219 29.4561 16.5312C29.7654 16.8406 29.7654 17.3219 29.4561 17.6312L21.5498 25.4C21.3779 25.5719 21.2061 25.675 20.9998 25.675Z'
                    fill='#BB0F31'
                  />
                </svg>{" "}
                Dopo la donazione viene lasciato un permesso lavorativo?
              </Card.Header>
              <Card.Text eventKey='0' className={Id === 3 ? "show" : "hide"}>
                Chiunque abbia compiuto i 18 anni di età e pesi più di 50 kg. Un medico
                effettuerà un colloquio, una visita, e gli accertamenti di tipo
                diagnostico e strumentale per verificare che non vi siano
                controindicazioni alla donazione.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <Card.Header className={Id === 4 ? "open" : ""} onClick={() => setPos(4)}>
                <svg
                  width='42'
                  height='42'
                  viewBox='0 0 42 42'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <rect width='42' height='42' rx='10' fill='#E8C6C9' fillOpacity='0.3' />
                  <path
                    d='M20.9998 25.675C20.7936 25.675 20.6217 25.6062 20.4498 25.4687L12.5436 17.7C12.2342 17.3906 12.2342 16.9094 12.5436 16.6C12.8529 16.2906 13.3342 16.2906 13.6436 16.6L20.9998 23.7844L28.3561 16.5312C28.6654 16.2219 29.1467 16.2219 29.4561 16.5312C29.7654 16.8406 29.7654 17.3219 29.4561 17.6312L21.5498 25.4C21.3779 25.5719 21.2061 25.675 20.9998 25.675Z'
                    fill='#BB0F31'
                  />
                </svg>{" "}
                Per quale motivo dovrei donare il mio sangue?
              </Card.Header>
              <Card.Text eventKey='0' className={Id === 4 ? "show" : "hide"}>
                Chiunque abbia compiuto i 18 anni di età e pesi più di 50 kg. Un medico
                effettuerà un colloquio, una visita, e gli accertamenti di tipo
                diagnostico e strumentale per verificare che non vi siano
                controindicazioni alla donazione.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

Faq.propTypes = {};

Faq.defaultProps = {};

export default Faq;
