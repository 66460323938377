import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { Col, Form, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import UseLoader from "../../Components/Loader/Loader";
import Modal from "../../Components/Modal/Modal";
import SearchFilter from "../../Components/SearchFilter/SearchFilter";
import UseTopBar from "../../Components/TopBar/TopBar";
import UseButton from "../../Components/UseButton/UseButton";
import "./DonationCenters.scss";
const DonationCenters = () => {
  const navigate = useNavigate();
  const functions = getFunctions();
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");

  const [openFilterOne, setOpenFilterOne] = useState();
  const [openFilterTwo, setOpenFilterTwo] = useState();
  const [openFilterThree, setOpenFilterThree] = useState();

  const [partnerships, setPartnerships] = useState(null);
  const [deleteCenterModalOpen, setDeleteCenterModalOpen] = useState(false);
  const [centerToDelete, setCenterToDelete] = useState(null);
  const [selectedorderBy, setSelectedOrderBy] = useState(null);
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedPartnerships, setSelectedPartnerships] = useState([]);

  const [donationCentersCities, setDonationCentersCities] = useState([]);
  const [donationCentersPartnerships, setDonationCentersPartnerships] = useState([]);
  const addCenter = () => {
    navigate("/Centro-donazione/Scheda-centro-donazioni");
  };

  useEffect(() => {
    loadLocations();
    getFilters();
  }, []);

  const getFilters = () => {
    try {
      const getDonationCentersPartnerships = httpsCallable(
        functions,
        "getDonationCentersPartnerships",
      );
      const getDonationCentersCities = httpsCallable(
        functions,
        "getDonationCentresCities",
      );

      getDonationCentersPartnerships()
        .then((response) => {
          let DCP = [];
          if (response.data && response.data.length > 0) {
            response.data.map((d) => {
              if (d.partnershipId && d.partnershipName) {
                DCP.push({
                  value: d.partnershipId,
                  label: d.partnershipName,
                });
              }
            });
          }
          setDonationCentersPartnerships(DCP);
        })
        .catch((error) => console.error("error", error));

      getDonationCentersCities()
        .then((response) => {
          if (response.data && response.data.length > 0) {
            setDonationCentersCities(
              response.data.map((d) => {
                return {
                  value: d,
                  label: d,
                };
              }),
            );
          }
        })
        .catch((error) => console.error("error", error));
    } catch (error) {
      console.log("error", error);
    }
  };
  const loadLocations = (data) => {
    try {
      // const getLocations = httpsCallable(functions,"getLocations")
      const getLocations = httpsCallable(functions, "getDonationCenters");
      getLocations(data)
        .then((response) => {
          setLoader(false);
          setData(response.data);
        })
        .catch((error) => console.error("error", error));

      const getPartnership = httpsCallable(functions, "getPartnerships");
      getPartnership()
        .then((response) => {
          setPartnerships(response.data);
        })
        .catch((error) => console.error("error", error));
    } catch (error) {
      console.log("error", error);
    }
  };

  const removeLocation = (id) => {
    setCenterToDelete(id);
    setDeleteCenterModalOpen(true);
  };
  const removeCenterCancel = () => {
    setCenterToDelete(null);
    setDeleteCenterModalOpen(false);
  };
  const removeCenterConfirm = (id) => {
    try {
      setLoader(true);
      setCenterToDelete(null);
      setDeleteCenterModalOpen(false);
      const removeLocations = httpsCallable(functions, "deleteDonationCenter");
      removeLocations({ id: id })
        .then((response) => {
          setLoader(false);
          window.location.reload();
        })
        .catch((error) => console.error("error", error));
    } catch (error) {
      console.log("error", error);
    }
  };

  const editLocation = (id, name) => {
    localStorage.setItem("dcid", id);
    navigate("/Centro-donazione/Modificare-centro-donazioni", {
      state: { locatiosId: id },
    });
  };

  const addCenters = (id, name) => {
    localStorage.setItem("dcid", id);
    navigate("/Centro-donazione/Scheda-centro", { state: { donationCenterId: id } });
  };

  const filterCities = (value) => {
    setSelectedCities((prev) => {
      if (prev.includes(value)) {
        return prev.filter((val) => val !== value);
      }

      return [...prev, value];
    });
  };

  const filterPartnerships = (value) => {
    setSelectedPartnerships((prev) => {
      if (prev.includes(value)) {
        return prev.filter((val) => val !== value);
      }

      return [...prev, value];
    });
  };

  const getDonationCentresWithFilters = (reset) => {
    loadLocations(
      reset
        ? null
        : {
            filters: {
              orderBy: selectedorderBy,
              city: selectedCities,
              partnership: selectedPartnerships,
            },
          },
    );
    setOpenFilterOne(false);
    setOpenFilterTwo(false);
    setOpenFilterThree(false);
  };

  return (
    <div className='DonationCenters' data-testid='DonationCenters'>
      <UseTopBar />
      <Row>
        <Col xs={12} sm={12} md={12} lg={6}>
          <h2>Centri donazione</h2>
        </Col>
        <Col xs={12} sm={12} md={12} lg={6}>
          <Row>
            <Col xs={12} sm={12} md={12} lg={5}>
              <Form.Control
                className='search'
                type='text'
                placeholder='Cerca un centro'
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={7} className='d-flex buttons'>
              <UseButton
                name='adminBtnPrimary'
                variant='btn btn-outline-primary'
                labels='Cancella filtri'
                onClick={() => {
                  setSelectedCities([]);
                  setSelectedPartnerships([]);
                  setSelectedOrderBy(null);
                  getDonationCentresWithFilters(true);
                }}
              />
              <UseButton
                onClick={addCenter}
                name='adminBtnPrimary'
                variant='primary'
                labels='Aggiungi centro'
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={4} lg={3}>
          <p>Ordina per</p>
          {/* <Form.Select aria-label="Default select example">
              <option>Tutte</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </Form.Select> */}
          <SearchFilter
            open={openFilterOne}
            setOpen={setOpenFilterOne}
            onConfirm={() => {
              getDonationCentresWithFilters();
            }}
            data={{
              name: "donors",
              options: [
                {
                  label: "Ultimi eventi creati",
                  value: "lastEvents",
                },
              ],
            }}
            type='radio'
            onChangeFilterValue={setSelectedOrderBy}
            placeholder={"Cerca filtro"}
            selectedValue={selectedorderBy}
          />
        </Col>

        <Col xs={12} sm={12} md={4} lg={3}>
          <p>Città</p>
          {/* <Form.Select aria-label="Default select example">
              <option>Tutti</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </Form.Select> */}
          <SearchFilter
            open={openFilterTwo}
            setOpen={setOpenFilterTwo}
            data={donationCentersCities}
            selectedValue={selectedCities}
            onConfirm={() => {
              getDonationCentresWithFilters();
            }}
            onChangeFilterValue={filterCities}
            placeholder='Filtra per città'
          />
        </Col>
        <Col xs={12} sm={12} md={4} lg={3}>
          <p>Convenzioni</p>
          {/* <Form.Select aria-label="Default select example">
              <option>Tutte</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </Form.Select> */}
          <SearchFilter
            open={openFilterThree}
            setOpen={setOpenFilterThree}
            data={donationCentersPartnerships}
            selectedValue={selectedPartnerships}
            onConfirm={() => {
              getDonationCentresWithFilters();
            }}
            onChangeFilterValue={filterPartnerships}
            placeholder='Filtra per convenzione'
          />
        </Col>
      </Row>
      {/* <Link to={'/add-center'}>Add Center</Link> */}
      <Table hover>
        <thead>
          <tr>
            <th>Nome centro</th>
            <th>Convenzione</th>
            <th>Referente</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data
              .filter((item) => {
                return search.toLowerCase() === ""
                  ? item
                  : item.name.toLowerCase().includes(search.toLowerCase());
              })
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((datas, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <span onClick={() => addCenters(datas.id, datas.name)}>
                        {datas.name}
                      </span>
                    </td>
                    <td>
                      {partnerships
                        ? partnerships.filter((ps) => ps.id == datas.partnership)[0]
                            ?.agencyName
                        : ""}
                    </td>

                    <td>{datas.contactPersonName}</td>
                    <td className='d-flex'>
                      <button
                        className='edit'
                        onClick={() => editLocation(datas.id, datas.name)}
                      >
                        <svg
                          width='16'
                          height='18'
                          viewBox='0 0 16 18'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M3.345 12.2419L11.7967 3.79026L10.6183 2.61193L2.16667 11.0636V12.2419H3.345ZM4.03583 13.9086H0.5V10.3728L10.0292 0.843598C10.1854 0.687372 10.3974 0.599609 10.6183 0.599609C10.8393 0.599609 11.0512 0.687372 11.2075 0.843598L13.565 3.2011C13.7212 3.35737 13.809 3.56929 13.809 3.79026C13.809 4.01123 13.7212 4.22316 13.565 4.37943L4.03583 13.9086ZM0.5 15.5753H15.5V17.2419H0.5V15.5753Z'
                            fill='#2264D1'
                          />
                        </svg>
                      </button>
                      <button className='delete' onClick={() => removeLocation(datas.id)}>
                        <svg
                          width='14'
                          height='16'
                          viewBox='0 0 14 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M2.83398 15.5C2.37565 15.5 1.98343 15.3369 1.65732 15.0108C1.33121 14.6847 1.16787 14.2922 1.16732 13.8333V3H0.333984V1.33333H4.50065V0.5H9.50065V1.33333H13.6673V3H12.834V13.8333C12.834 14.2917 12.6709 14.6842 12.3448 15.0108C12.0187 15.3375 11.6262 15.5006 11.1673 15.5H2.83398ZM11.1673 3H2.83398V13.8333H11.1673V3ZM4.50065 12.1667H6.16732V4.66667H4.50065V12.1667ZM7.83398 12.1667H9.50065V4.66667H7.83398V12.1667Z'
                            fill='#2264D1'
                          />
                        </svg>
                      </button>
                    </td>
                  </tr>
                );
              })
          ) : (
            <tr>
              <td colSpan='5' style={{ textAlign: "center" }}>
                Nessun dato
              </td>
            </tr>
          )}

          {/* {
                data.length>0?(data.map((datas,index)=>{
                  return <tr key={index}>
                            <td><span onClick={()=>addCenters(datas.id,datas.name)}>{datas.name}</span></td>
                            <td>{datas.name}</td>
                            <td>
                                {new Date(datas.createdAt._seconds * 1000 + datas.createdAt._nanoseconds / 1000000).toDateString()}&nbsp;&nbsp;
                                {new Date(datas.createdAt._seconds * 1000 + datas.createdAt._nanoseconds / 1000000).toLocaleTimeString()}
                            </td>
                            <td>{datas.city}</td>
                            <td>
                    <button className='edit' onClick={()=>editLocation(datas.id,datas.name)} ><svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.345 12.2419L11.7967 3.79026L10.6183 2.61193L2.16667 11.0636V12.2419H3.345ZM4.03583 13.9086H0.5V10.3728L10.0292 0.843598C10.1854 0.687372 10.3974 0.599609 10.6183 0.599609C10.8393 0.599609 11.0512 0.687372 11.2075 0.843598L13.565 3.2011C13.7212 3.35737 13.809 3.56929 13.809 3.79026C13.809 4.01123 13.7212 4.22316 13.565 4.37943L4.03583 13.9086ZM0.5 15.5753H15.5V17.2419H0.5V15.5753Z" fill="#2264D1"/>
</svg></button>
<button className='delete' onClick={()=>removeLocation(datas.id)}>
                      <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.83398 15.5C2.37565 15.5 1.98343 15.3369 1.65732 15.0108C1.33121 14.6847 1.16787 14.2922 1.16732 13.8333V3H0.333984V1.33333H4.50065V0.5H9.50065V1.33333H13.6673V3H12.834V13.8333C12.834 14.2917 12.6709 14.6842 12.3448 15.0108C12.0187 15.3375 11.6262 15.5006 11.1673 15.5H2.83398ZM11.1673 3H2.83398V13.8333H11.1673V3ZM4.50065 12.1667H6.16732V4.66667H4.50065V12.1667ZM7.83398 12.1667H9.50065V4.66667H7.83398V12.1667Z" fill="#2264D1"/>
</svg>

                      </button>
                    </td>
                        </tr>
                })):(<tr><td colSpan="5">No data</td></tr>)
              } */}
        </tbody>
      </Table>
      {data.length >= 10 && (
        <div className='pagination'>
          {page + 1} - {Math.round(data.length / rowsPerPage)}
          <button className='prev' onClick={() => setPage(page - 1)} disabled={page == 0}>
            <svg
              width='8'
              height='13'
              viewBox='0 0 8 13'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M7.71094 2.12094L3.12094 6.71094L7.71094 11.3009L6.29094 12.7109L0.290937 6.71094L6.29094 0.710938L7.71094 2.12094Z'
                fill='#787885'
              />
            </svg>
          </button>
          <button
            className='next'
            onClick={() => setPage(page + 1)}
            disabled={Number(page) == Math.round(Math.abs(data.length / rowsPerPage - 1))}
          >
            <svg
              width='8'
              height='13'
              viewBox='0 0 8 13'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M0.295078 2.11312L4.87508 6.70312L0.295078 11.2931L1.70508 12.7031L7.70508 6.70312L1.70508 0.703125L0.295078 2.11312Z'
                fill='#787885'
              />
            </svg>
          </button>
        </div>
      )}
      {loader == true && <UseLoader />}
      {deleteCenterModalOpen && (
        <Modal
          title='Sei sicuro?'
          onConfirmReturnData={centerToDelete}
          onCancel={removeCenterCancel}
          onConfirm={removeCenterConfirm}
        >
          <p>Una volta eliminato non potrai tornare indietro</p>
        </Modal>
      )}
    </div>
  );
};

DonationCenters.propTypes = {};

DonationCenters.defaultProps = {};

export default DonationCenters;
