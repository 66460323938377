import React from "react";

const TongueIcon = ({ height, width, fill }) => {
  return (
    <svg
      width='6'
      height='19'
      viewBox='0 0 6 19'
      fill={fill}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6 5.49277C6 5.27135 5.94489 5.05619 5.8433 4.88104L3.5933 1.00155C3.29305 0.483859 2.70695 0.483857 2.4067 1.00155L0.1567 4.88104C0.0551143 5.05619 0 5.27135 0 5.49277V14.6001C0 16.8092 1.34315 18.6001 3 18.6001C4.65685 18.6001 6 16.8092 6 14.6001V5.49277Z'
        fill='#D9D9D9'
      />
    </svg>
  );
};

export default TongueIcon;
