import React from "react";
import { Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./CardOne.scss";
const CardOne = ({ data }) => {
  const navigate = useNavigate();
  const bookSlots = () => {
    navigate(`/eventi/${data.donationCenterId ? data.donationCenterId : data.location}`, {
      state: { event: data?.id },
    });
  };
  function formatDateToItalian(dateStr) {
    const months = [
      "Gennaio",
      "Febbraio",
      "Marzo",
      "Aprile",
      "Maggio",
      "Giugno",
      "Luglio",
      "Agosto",
      "Settembre",
      "Ottobre",
      "Novembre",
      "Dicembre",
    ];

    const date = new Date(dateStr);

    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  }
  return (
    <div className='customCardOne'>
      <Card>
        <Card.Body>
          <div>
            <Card.Header>
              <svg
                width='30'
                height='31'
                viewBox='0 0 30 31'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M18.75 5.48438V2.98438M18.75 5.48438V7.98438M18.75 5.48438H13.125M3.75 12.9844V24.2344C3.75 25.6151 4.86929 26.7344 6.25 26.7344H23.75C25.1307 26.7344 26.25 25.6151 26.25 24.2344V12.9844H3.75Z'
                  stroke='#292A31'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M3.75 12.9844V7.98438C3.75 6.60366 4.86929 5.48438 6.25 5.48438H8.75'
                  stroke='#292A31'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M8.75 2.98438V7.98438'
                  stroke='#292A31'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M26.25 12.9844V7.98438C26.25 6.60366 25.1307 5.48438 23.75 5.48438H23.125'
                  stroke='#292A31'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>{" "}
              {formatDateToItalian(data.date)}
            </Card.Header>
            <Card.Title>{data.name}</Card.Title>

            <Card.Text>{data.description}</Card.Text>
          </div>
          <Card.Footer>
            <Button onClick={() => bookSlots()}>
              Prenota ora{" "}
              <svg
                width='23'
                height='24'
                viewBox='0 0 23 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M1.00015 12.0001H21.5018M21.5018 12.0001L11.251 1.74927M21.5018 12.0001L11.251 22.2509'
                  stroke='#BA0F31'
                  strokeWidth='1.96605'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </Button>
          </Card.Footer>
        </Card.Body>
      </Card>
    </div>
  );
};
export default CardOne;
