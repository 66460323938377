import { getFunctions, httpsCallable } from "firebase/functions";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import BookIcon from "./BookIcon";
import BookedIcon from "./BookedIcon";
import "./DonationTimeline.scss";
import DoubleCheckedicon from "./DoubleCheckedicon";
import TongueIcon from "./TongueIcon";

moment.locale("it", {
  months:
    "gennaio_febbraio_marzo_aprile_maggio_giugno_luglio_agosto_settembre_ottobre_novembre_dicembre".split(
      "_",
    ),
  monthsShort: "gen._feb._mar._apr._mag._giu._lug._ago._set._ott._nov._dic.".split("_"),
  monthsParseExact: true,
  weekdays: "domenica_lunedì_martedì_mercoledì_giovedì_venerdì_sabato".split("_"),
  weekdaysShort: "dom._lun._mar._mer._gio._ven._sab.".split("_"),
  weekdaysMin: "Do_Lu_Ma_Me_Gi_Ve_Sa".split("_"),
  weekdaysParseExact: true,
  longDateFormat: {
    LT: "HH:mm",
    LTS: "HH:mm:ss",
    L: "DD/MM/YYYY",
    LL: "D MMMM YYYY",
    LLL: "D MMMM YYYY HH:mm",
    LLLL: "dddd D MMMM YYYY HH:mm",
  },
  calendar: {
    sameDay: "[Oggi alle] LT",
    nextDay: "[Domani alle] LT",
    nextWeek: "dddd [alle] LT",
    lastDay: "[Ieri alle] LT",
    lastWeek: "dddd [scorso alle] LT",
    sameElse: "L",
  },
  relativeTime: {
    future: "tra %s",
    past: "%s fa",
    s: "alcuni secondi",
    ss: "%d secondi",
    m: "un minuto",
    mm: "%d minuti",
    h: "un'ora",
    hh: "%d ore",
    d: "un giorno",
    dd: "%d giorni",
    M: "un mese",
    MM: "%d mesi",
    y: "un anno",
    yy: "%d anni",
  },
  dayOfMonthOrdinalParse: /\d{1,2}º/,
  ordinal: "%dº",
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4, // The week that contains Jan 4th is the first week of the year.
  },
});

const DonationTimeline = ({ userId }) => {
  const functions = getFunctions();
  const [donor, setDonor] = useState(null);
  const [firstDate, setFirstDate] = useState(moment().format());
  const [isToday, setIsToday] = useState(false);
  const [barWidth, setbarWidth] = useState(0);
  const [reservations, setReservations] = useState([]);
  const [pendingDate, setPendingDate] = useState();

  function getNextSixMonthsDetails(startDate) {
    let date = moment(startDate).tz("Europe/Paris").locale("it");
    let monthsDetails = [];
    let dayNumber = date.date();
    let monthName = date.format("MMMM");
    monthName = monthName.charAt(0).toUpperCase() + monthName.slice(1);

    let count = 5;

    if (!isToday) {
      monthsDetails.push({
        label: `${dayNumber} ${monthName}`,
        date: date.format(),
        year: date.year(),
      });
      if (pendingDate) {
        date = moment(pendingDate).tz("Europe/Paris").locale("it");
      } else {
        date = donor && donor.sex === "maschio" ? date.add(3, "M") : date.add(6, "M");
      }
    } else {
      if (pendingDate) {
        date = moment(pendingDate).tz("Europe/Paris").locale("it");
        count = 6;
      } else {
        monthsDetails.push({
          label: `${dayNumber} ${monthName}`,
          date: date.format(),
          year: date.year(),
        });
        date = donor && donor.sex === "maschio" ? date.add(3, "M") : date.add(6, "M");
      }
    }

    for (let i = 0; i < count; i++) {
      dayNumber = date.date();
      monthName = date.format("MMMM");
      monthName = monthName.charAt(0).toUpperCase() + monthName.slice(1).toLowerCase();
      monthName = monthName.charAt(0).toUpperCase() + monthName.slice(1);
      monthsDetails.push({
        label: `${dayNumber} ${monthName}`,
        date: date.format(),
        year: date.year(),
      });

      date = donor && donor.sex === "maschio" ? date.add(3, "M") : date.add(6, "M");
    }

    return monthsDetails;
  }

  useEffect(() => {
    try {
      const getDonorById = httpsCallable(functions, "getDonorById");
      const data = {
        id: userId,
      };
      getDonorById(data)
        .then((response) => {
          setDonor(response.data);
          localStorage.setItem("sex", response.data.sex);
        })
        .catch((error) => console.error("error", error));
    } catch (error) {
      console.log("error", error);
    }

    try {
      const getReservations = httpsCallable(functions, "getReservations");
      const data = {
        userId: userId,
      };
      getReservations(data)
        .then((response) => {
          let data = response.data;
          //setReservations(data)
          setReservations(data);
        })
        .catch((error) => {
          setReservations([]);
        });
    } catch (error) {
      setReservations([]);
    }
  }, []);

  let daysFrom = calculateDaysBetweenDates(
    firstDate,
    getNextSixMonthsDetails(firstDate)[getNextSixMonthsDetails(firstDate).length - 1]
      .date,
  );
  let dayspassed = calculateDaysBetweenDates(firstDate, moment().format());
  let percentageWidth = calculatePercentage(dayspassed, daysFrom);

  useEffect(() => {
    let donations = reservations && reservations.filter((r) => r.status === "completed");
    let date = new Date();
    date.setDate(date.getDate() + 1);
    setFirstDate(date);
    setIsToday(true);

    if (donations && donations.length > 0) {
      let lastDonation = donations[donations.length - 1];
      let gapFromLastDonation = calculateDaysBetweenDates(
        moment(lastDonation.date).format(),
        moment().format(),
      );
      let maleGap = 3 * 30 * 5;
      let femaleGap = 6 * 30 * 5;
      if (donor && donor.sex == "maschio") {
        if (gapFromLastDonation < maleGap) {
          setFirstDate(lastDonation.date);
        }
      } else if (donor && donor.sex != "maschio") {
        if (gapFromLastDonation < femaleGap) {
          setFirstDate(lastDonation.date);
        }
      }
      setIsToday(false);
    }

    let nextPending =
      reservations &&
      reservations.filter((r) => {
        const reservationDate = new Date(r.date);
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        return r.status === "pending" && reservationDate >= today;
      })[0];
    setPendingDate(nextPending?.date);
  }, [reservations]);

  const getIcon = (date, index) => {
    let Icon = <BookIcon fill='#3C3C3C' />;
    let formattedInputDate = moment(date).format("YYYY-MM-DD");

    let futureReservations = reservations.filter((r) => r.status === "pending");

    futureReservations.forEach((fr) => {
      let formattedReservationDate = moment(fr.date).format("YYYY-MM-DD");
      if (formattedInputDate === formattedReservationDate) {
        Icon = (
          <div title={"Prenotata"}>
            <BookedIcon />
          </div>
        );
        return;
      }
    });

    const hasCompletedDonation = reservations.some(
      (r) =>
        r.status === "completed" &&
        moment(r.date).format("YYYY-MM-DD") === formattedInputDate,
    );
    if (hasCompletedDonation) {
      Icon = (
        <div title={"Completata"}>
          <DoubleCheckedicon fill={"#fff"} />
        </div>
      );
    }

    return Icon;
  };

  function calculatePercentage(num1, num2) {
    if (num2 === 0) {
      console.log("Second number cannot be 0.");
      return; // Prevent division by zero
    }
    return (num1 / num2) * 100;
  }

  function calculateDaysBetweenDates(startDate, endDate) {
    const start = moment(startDate);
    const end = moment(endDate);
    return end.diff(start, "days");
  }

  return (
    <div className='DonationTimelineWrap'>
      <div className='DonationTimelineWrapInner'>
        <div className='DonationTimeline'>
          <div
            className='DonationTImelineBar'
            style={{ width: `${percentageWidth}%` }}
            title={moment().tz("Europe/Paris").locale("it").format("D MMMM YYYY")}
          ></div>
          {donor &&
            reservations &&
            getNextSixMonthsDetails(firstDate).map((date, index) => {
              return (
                <div
                  className={`DonationTimelineItem ${
                    index === 0 ? "DonationTimelineItemFirst" : ""
                  } ${index === 5 ? "DonationTimelineItemLast" : ""}`}
                >
                  <div className='DonationTimelineItemText'>
                    {date.label}
                    <span>{date.year}</span>
                  </div>
                  <div className='DonationTimelineItemTongue'>
                    <TongueIcon />
                  </div>
                  <div className='DonationTimelineItemLine'></div>
                  <div className='DonationTimelineItemIcon'>
                    {getIcon(date.date, index)}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default DonationTimeline;
