import { getFunctions, httpsCallable } from "firebase/functions";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Tab, Table, Tabs } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import CardBox from "../../Components/Card/CardBox/CardBox";
import UseLoader from "../../Components/Loader/Loader";
import Modal from "../../Components/Modal/Modal";
import UseTopBar from "../../Components/TopBar/TopBar";
import UseAlert from "../../Components/UseAlert/UseAlert";
import UseButton from "../../Components/UseButton/UseButton";
import UseDropDownWithSearch from "../../Components/UseDropdownWithSearch/UseDropdownWithSearch";
import UseImageUpload from "../../Components/UseImageUpload/UseImageUpload";
import UseImageUploadOne from "../../Components/UseImageUploadOne/UseImageUploadOne";
import WebinarImageUpload from "../../Components/WebinarImageUpload/WebinarImageUpload";
import UseModal from "../../Utils/UseModal/UseModal";
import LazyBreadCrum from "../BreadCrum/BreadCrum";
import "./CompanyProfile.scss";
const CompanyProfile = () => {
  const functions = getFunctions();
  const navigate = useNavigate();
  const locations = useLocation();
  const [openModal, setModalOpen] = useState(false);
  const [event, setEvent] = useState(false);
  const [data, setData] = useState([]);
  const [events, setEvents] = useState([]);
  const [donationCenterList, setDoantionCenteList] = useState([]);
  // const [locationId, setLocationId] = useState([]);
  const [companyId, setcompanyId] = useState([]);
  const [loader, setLoader] = useState(false);

  const [webinars, setWebinars] = useState([]);
  const [webinarStatus, setWebinarStatus] = useState(false);
  const [editWebinarStatus, setEditWebinarStatus] = useState(false);
  const [webinarId, setWebinarId] = useState(0);
  const [eventId, setEventId] = useState(0);
  const [editEventStatus, setEditEventStatus] = useState(false);

  const [getDateTime, setDateTime] = useState(new Date());
  const [pastEvents, setPastEvents] = useState("");
  const [validated, setValidated] = useState(false);
  const [eventValidated, setEventValidated] = useState(false);
  const [webinarValidated, setWebinarValidated] = useState(false);
  const [logoError, setLogoError] = useState(false);
  const [error, setError] = useState({ donationCenter: false, date: false });

  // const {register,handleSubmit,watch,setValue,formState:{errors, isValid, isDirty,isSubmitting,isSubmitted,isSubmitSuccessful}} = useForm({defaultValues:{
  //   name:'',description:'',url:'',image:''
  // },  mode:"onSubmit"});

  // Information
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [email, setEmail] = useState("");
  const [haveDonated, setHaveDonated] = useState("");
  const [company, setCompany] = useState("");
  const [clogo, setClogo] = useState("");
  const [activePro, setActivePro] = useState("");
  const [deactivate, setDeactivate] = useState("");
  const [cid, setCid] = useState("");
  // const [mediaContent, setMediaContent] = useState("");
  const [cname, setCname] = useState("");
  const [surname, setSurname] = useState("");
  const [cemail, setCemail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [notes, setNMotes] = useState("");
  const [status, setStatus] = useState("");
  const [activeKey, setActiveKey] = useState("home");

  // Event
  // const [webinar, setWebinar] = useState("");
  const [eventName, setEventName] = useState("");
  const [linkWebinar, setLinkWebinar] = useState("");
  const [description, setDescription] = useState("");
  const [breakfastInfo, setBreakfastInfo] = useState("");
  const [instructions, setInstructions] = useState("");
  const [date, setDate] = useState("");
  const [wlogo, setWlogo] = useState("");
  const [location, setLocation] = useState("");
  const [donationCenterDate, setDoantionCenterDate] = useState([]);
  const [webCompany, setWebCompany] = useState("");
  const [eventContactPerson, setEventContactPerson] = useState("");
  const [eventPhoneNo, setEventPhoneNo] = useState("");
  const [eventDate, setEventDate] = useState("");
  // webinar
  const [webName, setWebName] = useState("");
  const [webDesc, setWebDesc] = useState("");
  const [url, setUrl] = useState("");
  const [image, setImage] = useState("");
  const [time, setTime] = useState("");
  const [getCompany, setGetCompany] = useState("");
  //Alert
  const [show, setShow] = useState(false);
  const [variant, setVarient] = useState("");
  const [alertMsg, setAlertMsg] = useState("");

  // modal
  const [deleteEventConfirm, setDeleteEventConfirm] = useState(false);
  const [eventIds, setEventIds] = useState(null);

  const [deleteWebinarConfirm, setDeleteWebinarConfirm] = useState(false);
  const [webinarIds, setWebinarIds] = useState(null);
  const [webinarName, setWebinarName] = useState("");

  // search droppdown
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchDropDown, setSearchDropDown] = useState("");
  const [donationCenterLabel, setDonatinCnterLabel] = useState("Seleziona il centro");
  const [slotLabel, setSlotLabel] = useState("Seleziona la data");
  // const [searchDropDownList, setSearchDropDownList] = useState([])
  // const [selectSearchableDropDownLabel, setSelectSearchableDropDownLabel] = useState('')

  const [dateOpen, setDateOpen] = useState(false);
  // const [dateSearchDropDown, setDateSearchDropDown] = useState('')
  // const [selectedDateDropDown, setSelectedDateDropDown] = useState('')

  // const createWebinar = () => {
  //   setModalOpen(true)
  // }

  const loadCompany = () => {
    try {
      const getCompany = httpsCallable(functions, "getCompanies");
      getCompany()
        .then((response) => {
          console.log("get compamy data", response);
          setcompanyId(response.data);
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.log("error", error);
    }
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const crateEvent = () => {
    setEvent(true);
  };

  const getFileName = (e) => {
    // console.log(e.target.files[0])
    // setClogo(e.target.files[0])
    setLogoError(false);
    const file = e.target.files[0];
    if (file) {
      uploadCompanyFile(file);
    }
  };

  const getWlogo = (e) => {
    const file = e.target.files[0];
    if (file) {
      uploadFile(file);
    }
  };

  const webinarLogo = (e) => {
    const file = e.target.files[0];
    if (file) {
      webinarUploadFile(file);
    }
  };

  const uploadFile = async (file) => {
    try {
      setLoader(true);
      const generateUploadUrl = httpsCallable(functions, "uploadFiles");
      const data = {
        contentType: file.type,
        collection: "partnerships",
      };
      const {
        data: { url },
      } = await generateUploadUrl(data);
      const response = await fetch(url, {
        method: "POST",
        body: file,
      });

      if (!response.ok) {
        throw new Error("File upload failed");
      }
      const link = new URL(response?.url);
      const params = new URLSearchParams(link.search);
      const name = params.get("name");
      const fileName = name.split("/")[2];

      const storage = getStorage();

      const fileRef = ref(storage, `/uploads/partnerships/${fileName}`);

      const downloadUrl = await getDownloadURL(fileRef);
      console.log("===Download Url====", downloadUrl);
      // setValue('logo',downloadUrl)
      setLoader(false);
      setWlogo(downloadUrl);
    } catch (error) {
      console.error("error", error);
    }
  };

  const uploadCompanyFile = async (file) => {
    try {
      setLoader(true);
      const generateUploadUrl = httpsCallable(functions, "uploadFiles");
      const data = {
        contentType: file.type,
        collection: "partnerships",
      };
      const {
        data: { url },
      } = await generateUploadUrl(data);
      const response = await fetch(url, {
        method: "POST",
        body: file,
      });

      if (!response.ok) {
        throw new Error("File upload failed");
      }
      const link = new URL(response?.url);
      const params = new URLSearchParams(link.search);
      const name = params.get("name");
      const fileName = name.split("/")[2];

      const storage = getStorage();

      const fileRef = ref(storage, `/uploads/partnerships/${fileName}`);

      const downloadUrl = await getDownloadURL(fileRef);
      console.log("===Download Url====", downloadUrl);
      // setValue('logo',downloadUrl)
      setLoader(false);
      setClogo(downloadUrl);
    } catch (error) {
      console.error("error", error);
    }
  };

  const webinarUploadFile = async (file) => {
    try {
      setLoader(true);
      const generateUploadUrl = httpsCallable(functions, "uploadFiles");
      const data = {
        contentType: file.type,
        collection: "webinar",
      };
      const {
        data: { url },
      } = await generateUploadUrl(data);
      const response = await fetch(url, {
        method: "POST",
        body: file,
      });

      if (!response.ok) {
        throw new Error("File upload failed");
      }
      const link = new URL(response?.url);
      const params = new URLSearchParams(link.search);
      const name = params.get("name");
      const fileName = name.split("/")[2];

      const storage = getStorage();

      const fileRef = ref(storage, `/uploads/webinar/${fileName}`);

      const downloadUrl = await getDownloadURL(fileRef);
      console.log("===Download Url====", downloadUrl);
      // setValue('logo',downloadUrl)
      setLoader(false);
      setImage(downloadUrl);
    } catch (error) {
      console.error("error", error);
    }
  };

  const getInformation = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (!clogo) {
      setLogoError(true);
    }

    if (locations?.state?.editCompany !== true) {
      if (form.checkValidity()) {
        const data = {
          name: name,
          email: email,
          companyDomain: company,
          platformUrl: link,
          logo: clogo,
          isActive: activePro,
          totalDonatedPerson: haveDonated,
          // mediaContent: [mediaContent],
          contactPersonName: cname,
        };
        if (!clogo) {
          setLogoError(true);
          return;
        }
        setLoader(true);
        const setCompany = httpsCallable(functions, "setCompany");
        setCompany(data)
          .then((response) => {
            console.log("response", response);
            setData(response.data);
            setLoader(false);
            navigate("/Aziende");
          })
          .catch((error) => console.error("error", error))
          .finally(() => setLoader(false));
      } else {
        setValidated(true);
      }
    }

    if (locations?.state?.editCompany == true) {
      if (form.checkValidity()) {
        const data = {
          id: locations?.state?.editCompanyId,
          name: name,
          email: email,
          companyDomain: company,
          platformUrl: link,
          logo: clogo,
          isActive: activePro,
          totalDonatedPerson: haveDonated,
          // mediaContent: mediaContent,
          contactPersonName: cname,
        };
        if (!clogo) {
          setLogoError(true);
          return;
        }
        setLoader(true);
        const updateCompany = httpsCallable(functions, "updateCompany");
        updateCompany(data)
          .then((response) => {
            console.log("response", response);
            setData(response.data);
            setLoader(false);
            navigate("/Aziende");
          })
          .catch((error) => console.error("error", error))
          .finally(() => setLoader(false));
      } else {
        setValidated(true);
      }
    }
  };

  const getEvents = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (!location) {
      setError({ ...error, donationCenter: true });
      return;
    }
    if (!eventDate) {
      setError({ ...error, date: true });
      return;
    }

    if (form.checkValidity()) {
      if (editEventStatus === false) {
        setLoader(true);
        const updateCompnayEvent = httpsCallable(functions, "updateCompanyEvent");
        const data = {
          name: eventName,
          description: description,
          breakfast_info: breakfastInfo,
          instructions: instructions,
          image: wlogo,
          donationCenterId: location.id,
          contactPersonName: eventContactPerson,
          contactPersonPhone: eventPhoneNo,
          id: eventDate,
          company: cid,
        };
        updateCompnayEvent(data)
          .then((respose) => {
            console.log("response", respose);
            setLoader(false);
            setActiveKey("profile");
            setShow(true);
            setVarient("success");
            setAlertMsg("Events creato con successo");
            loadEvents();
            // setEvent(false)
            // setEditEventStatus(false)
            setTimeout(() => {
              setEvent(false);
              setEditEventStatus(false);
            }, 2000);
            setEventName("");
            setEventDate("");
            setDescription("");
            setBreakfastInfo("");
            setInstructions("");
            setEventContactPerson("");
            setEventPhoneNo("");
            setWlogo("");
            setLocation("");
            // setSelectedDateDropDown('')
            // setSelectSearchableDropDownLabel('')
            // setWebCompany("");
          })
          .catch((error) => {
            setShow(true);
            setVarient("danger");
            setAlertMsg(error.message);
          })
          .finally(() => setLoader(false));
      }
      if (editEventStatus === true) {
        const updateEvents = httpsCallable(functions, "updateCompanyEvent");
        const data = {
          id: eventId,
          name: eventName,
          description: description,
          breakfast_info: breakfastInfo,
          instructions: instructions,
          image: wlogo,
          donationCenterId: location || location.id,
          contactPersonName: eventContactPerson,
          contactPersonPhone: eventPhoneNo,
        };
        setLoader(true);
        updateEvents(data)
          .then((respose) => {
            console.log("response", respose);
            setShow(true);
            setVarient("success");
            setLoader(false);
            setAlertMsg(respose.data.message);
            setLoader(false);
            setActiveKey("profile");
            loadEvents();
            setTimeout(() => {
              setEvent(false);
              setEditEventStatus(false);
            }, 2000);
            setEventName("");
            setLinkWebinar("");
            setDescription("");
            setBreakfastInfo("");
            setInstructions("");
            setDate(new Date());
            setDateTime(new Date());
            setWlogo("");
            setLocation("");
            setWebCompany("");
            // setSelectedDateDropDown('')
            // setSelectSearchableDropDownLabel('')
            // selectSearchableDropDownLabel('')
          })
          .catch((error) => {
            console.error("error", error);
            setShow(true);
            setVarient("danger");
            setAlertMsg(error.message);
          })
          .finally(() => setLoader(false));
      }
    } else {
      setEventValidated(true);
    }
  };

  useEffect(() => {
    loadDoantionCenter();
    editCompany();
    loadWebinar();
    loadEvents();
    loadCompany();

    getCompanyStatus();
    getPastEvets();
    let id = localStorage.getItem("cid");
    setCid(id);
    // if(locations?.state?.dashboardEditCompanyId && locations?.state?.dashboardEditCompanyStatus){
    //   dashboardEditCompany()
    // }
  }, []);

  const getPastEvets = () => {
    console.log("padt events", locations?.state?.editCompanyId);
    try {
      const pastEvents = httpsCallable(functions, "getPastCompanyEvents");
      pastEvents({ company: locations?.state?.editCompanyId })
        .then((response) => {
          console.log("pase events", response);
          setPastEvents(response.data);
        })
        .catch((error) => console.error("error", error));
    } catch (error) {
      console.error("error", error);
    }
  };

  const getCompanyStatus = () => {
    console.log("get stats company id", locations?.state?.editCompanyId);
    try {
      const getCompanyStatus = httpsCallable(functions, "getCompanyStats");
      getCompanyStatus({ company: locations?.state?.editCompanyId })
        .then((response) => {
          setLoader(false);
          console.log("cmpany status", response);
          setStatus(response.data);
        })
        .catch((error) => console.error("error", error));
    } catch (error) {
      console.error("error", error);
    }
  };

  const loadDoantionCenter = () => {
    try {
      // const getLocations = httpsCallable(functions,"getLocations")
      const getLocations = httpsCallable(functions, "getDonationCenters");
      getLocations()
        .then((response) => {
          console.log("response", response);
          setDoantionCenteList(response.data);
        })
        .catch((error) => console.error("error", error));
    } catch (error) {
      console.log("error", error);
    }
  };

  const editCompany = () => {
    const getCompanyById = httpsCallable(functions, "getCompanyById");
    getCompanyById({ id: locations?.state?.editCompanyId })
      .then((response) => {
        console.log("edit response", response);
        setLoader(false);
        setData(response.data);
        loadData(response.data);
      })
      .catch((error) => console.error("error", error));
    // getCompanyById
  };

  const loadData = (data) => {
    setName(data.name);
    setLink(data.platformUrl);
    setEmail(data.email);
    // setMediaContent(data.mediaContent);
    setCompany(data.companyDomain);
    // setWlogo(data.logo)
    setClogo(data.logo);
    setCname(data.contactPersonName);
    setHaveDonated(data.totalDonatedPerson);
    // setSurname(data.contactPerson.surname)
    // setCemail(data.contactPerson.email)
    // setPhoneNumber(data.contactPerson.phoneNumber)
    // setNMotes(data.contactPerson.notes)
    setActivePro(data.isActive);
  };

  const loadWebinar = () => {
    console.log("webinar company id", locations?.state?.editCompanyId);
    const getWebinar = httpsCallable(functions, "getWebinars");
    getWebinar({ company: locations?.state?.editCompanyId })
      .then((response) => {
        console.log("webinar response", response);
        setWebinars(response.data);
        // setData(response.data)
        // loadData(response.data)
      })
      .catch((error) => console.error("error", error));
  };

  const loadEvents = () => {
    const getEventsList = httpsCallable(functions, "getUpcomingCompanyEvents");
    getEventsList({ company: locations?.state?.editCompanyId })
      .then((response) => {
        console.log("get events response", response);
        setEvents(response.data);
      })
      .catch((error) => console.error("error", error));
  };

  const editEvenets = (id) => {
    console.log("edit eventys id", id);
    try {
      const getEventsById = httpsCallable(functions, "getCompanyEventById");
      getEventsById({ id: id })
        .then((response) => {
          console.log("edit event response", response);
          setEvent(true);
          setEditEventStatus(true);
          loadEditEvents(response.data);
          getCenterDate(response.data.donationCenterId);
        })
        .catch((error) => {
          // console.error('error',error)
          setEvent(false);
          setEditEventStatus(false);
          loadEditEvents(error.message);
        });
    } catch (error) {
      console.error("error", error);
    }
  };

  const loadEditEvents = (data) => {
    console.log("edit events respomse", data);
    setEventId(data.id);
    setEventName(data.name);
    setDescription(data.description);
    setBreakfastInfo(data.breakfast_info);
    setInstructions(data.instructions);
    setEventContactPerson(data.contactPersonName);
    setEventPhoneNo(data.contactPersonPhone);
    setLocation(data.donationCenterId);
    setDonatinCnterLabel(data.donationCenterName);
    setSlotLabel(data.date);
    setEventDate(data.id);

    // setData(new Date(data.date))
    // setDate(data.date)
    // console.log('load date',data?.date)

    var currDate = data?.date.replace(",", "");
    // let currDate =  '16/2/2024, 8:09:00 pm';
    // console.log('currDate',currDate)
    const dateString = currDate; // Note the modified date format
    const parts = dateString.split(/[/: ,]/); // Split the string into parts
    const year = parseInt(parts[2], 10);
    const month = parseInt(parts[1], 10) - 1; // Month is zero-based in JavaScript Date
    const day = parseInt(parts[0], 10);
    const hours = parseInt(parts[3], 10);
    const minutes = parseInt(parts[4], 10);
    const seconds = parseInt(parts[5], 10);
    const dates = new Date(year, month, day, hours, minutes, seconds);
    // setDateTime(dates);
    console.log("fianl dateeeeeeeeee", dates);
    // setDateTime(data?.date)

    setDate(data.date);

    setDateTime(dates);

    setWlogo(data.image);
    // setLocation(data.location);
    // setWebCompany(data.company);
  };

  const removeEvents = (id) => {
    setDeleteEventConfirm(true);
    setEventId(id);
    // setModalOpen(true)
    // const deleteEvents = httpsCallable(functions,"deleteCompanyEvent");
    // deleteEvents({id:id})
    // .then((response)=>{
    //   console.log('get events response',response)
    //   window.location.reload();
    //   // setEvents(response.data)
    // })
    // .catch((error)=>console.error('error',error));
  };

  const removeEventConfirm = () => {
    setLoader(true);
    setDeleteEventConfirm(true);
    const deleteEvents = httpsCallable(functions, "deleteCompanyEvent");
    // setDeleteCompanyConfirm(false);
    // setCompanyToDelete(null)
    deleteEvents({ id: eventId })
      .then((response) => {
        setLoader(false);
        setShow(true);
        setVarient("success");
        setAlertMsg(response.data.message);
        setDeleteEventConfirm(false);
        setEventId(null);
        loadEvents();
        // window.location.reload();
      })
      .catch((error) => {
        setShow(true);
        setVarient("success");
        setLoader(false);
        setAlertMsg(error.message);
      });
  };

  const addWebinar = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    // console.log('Add events : ',data)
    // setLoader(true)
    if (form.checkValidity()) {
      if (editWebinarStatus === false) {
        const data = {
          name: webName,
          description: webDesc,
          url: url,
          time: time,
          image: image,
          company: cid,
        };
        console.log("Add webinar : ", data);
        try {
          setLoader(true);
          const setWebinar = httpsCallable(functions, "setWebinar");
          setWebinar(data)
            .then((response) => {
              console.log("Add Webinar response", response);

              setShow(true);
              setVarient("success");
              setLoader(false);
              setAlertMsg("Webinar creato con successo");

              setTimeout(() => {
                setWebinarStatus(false);
                setEditWebinarStatus(false);
                setActiveKey("contact");
                loadWebinar();
              }, 2000);

              setWebName("");
              setWebDesc("");
              setUrl("");
              setImage("");
              setTime("");
              setGetCompany("");
            })
            .catch((error) => console.error("error", error));
        } catch (error) {
          console.log("error", error);
        }
      }

      if (editWebinarStatus === true) {
        console.log("id", webinarId);
        const data = {
          id: webinarId,
          name: webName,
          description: webDesc,
          url: url,
          image: image,
          time: time,
          company: cid,
        };
        console.log("Add edit : ", data);
        try {
          setLoader(true);
          const updateWebinar = httpsCallable(functions, "updateWebinar");
          updateWebinar(data)
            .then((response) => {
              console.log("edit Webinar response", response);
              setShow(true);
              setVarient("success");
              setLoader(false);
              setAlertMsg(response.data.message);

              setEditWebinarStatus(false);
              setActiveKey("contact");
              loadWebinar();

              setTimeout(() => {
                setWebinarStatus(false);
                setEditWebinarStatus(false);
              }, 2000);
              setWebName("");
              setWebDesc("");
              setUrl("");
              setImage("");
              setTime("");
              setGetCompany("");
            })
            .catch((error) => console.error("error", error));
        } catch (error) {
          console.log("error", error);
        }
      }
    } else {
      setWebinarValidated(true);
    }
  };

  const editWebinar = (id) => {
    console.log(">>>>>>>>>>>>>>>", id);
    setEditWebinarStatus(true);
    try {
      const setWebinarById = httpsCallable(functions, "getWebinarById");
      setWebinarById({ id: id })
        .then((response) => {
          console.log("get Webinar edit response", response);
          setWebinarStatus(true);
          setEditWebinarStatus(true);
          setEditWebinarStatus(true);
          loadEditWebinar(response.data);
        })
        .catch((error) => console.error("error", error));
    } catch (error) {
      console.log("error", error);
    }
  };

  const removeWebinar = (id, name) => {
    // setWebinarId(id)
    console.log("webinar name and idsssssssssss", id, name);
    setDeleteWebinarConfirm(true);
    setWebinarIds(id);
    setWebinarName(name);
  };

  const removeWebinarConfirm = () => {
    setLoader(true);
    setDeleteWebinarConfirm(true);
    const deleteWrbinar = httpsCallable(functions, "deleteWebinar");
    // setDeleteCompanyConfirm(false);
    // setCompanyToDelete(null)
    deleteWrbinar({ id: webinarIds })
      .then((response) => {
        setLoader(false);
        setShow(true);
        setVarient("success");
        setAlertMsg(response.data.message);
        setDeleteWebinarConfirm(false);
        setWebinarIds(null);
        setWebinarStatus(false);
        setEditWebinarStatus(false);
        setActiveKey("contact");
        loadWebinar();
        // window.location.reload();
      })
      .catch((error) => {
        setShow(true);
        setVarient("danger");
        setLoader(false);
        setAlertMsg(error.message);
      });
  };

  const loadEditWebinar = (data) => {
    console.log("load edit data", data);
    setWebinarId(data.id);
    // setValue('name',data.name)
    // setValue('url',data.url)
    // setValue('description',data.description)
    // setValue('image',data.image)
    setTime(data.time);
    setGetCompany(data.company);
    setWebName(data.name);
    setUrl(data.url);
    setImage(data.image);
    setWebDesc(data.description);
  };

  const removeLogo = () => {
    // setValue('logo','')
    setWlogo("");
    // window.location.reload();
  };

  const removeWebinarLogo = () => {
    // setValue('logo','')
    setImage("");
    // window.location.reload();
  };

  const removeCompanyLogo = () => {
    setClogo("");
  };

  const getDateTimes = (e) => {
    console.log("event date 1", e);
    console.log("event date 1", e.toLocaleString());
    let date = e.toLocaleString();
    setDate(date);
    setDateTime(e);
  };

  const EventDateFormate = (dateFormate) => {
    const date = new Date(dateFormate);
    const formattedDate = date.toLocaleDateString();

    if (formattedDate == "Invalid Date") {
      return "orari non disponibili";
    } else {
      return formattedDate;
    }
  };

  const copyLinks = () => {
    navigator.clipboard.writeText(link);
  };

  const handleActiveTabs = (key) => {
    setActiveKey(key);
    setEvent(false);
    setEditEventStatus(false);
    setWebinarStatus(false);
    setEventName("");

    setLinkWebinar("");
    setDescription("");
    setDate(new Date());
    setDateTime(new Date());
    setWlogo("");
    setLocation("");
    setWebCompany("");
  };

  const removeEventCancel = () => {
    setDeleteEventConfirm(false);
    setEventIds(null);
  };

  const removeWebinarCancel = () => {
    setDeleteWebinarConfirm(false);
    // setDeleteEventConfirm(false)
    setWebinarIds(null);
  };

  const selectDonationCenter = (e) => {
    setLocation(e);
    setSearchDropDown("");
    console.log(">>>>>", e);
    setSearchOpen(false);
    setDonatinCnterLabel(e.name);
    getCenterDate(e.id);
  };

  const removeSelectedDonationCenter = (e) => {
    e.stopPropagation();
    setLocation("");
    setSearchDropDown("");
    // console.log('>>>>>',e)
    setSearchOpen(false);
    setDonatinCnterLabel("Seleziona il centro");
    setEventDate("");
    setDateOpen(false);
    setDoantionCenterDate([]);
    setSlotLabel("Seleziona la data");
    // getCenterDate('')
  };

  const getCenterDate = (_data) => {
    try {
      setLoader(true);
      const getDoantionCenterDate = httpsCallable(functions, "getSlotsByDonationCenter");
      getDoantionCenterDate({ donationCenterId: _data })
        .then((response) => {
          console.log("get doantion center date", response);
          setLoader(false);
          setDoantionCenterDate(response.data);
        })
        .catch((error) => console.error("error", error));
    } catch (error) {
      console.error("error", error);
    }
  };
  function formatDateToItalian(dateStr) {
    const months = [
      "Gennaio",
      "Febbraio",
      "Marzo",
      "Aprile",
      "Maggio",
      "Giugno",
      "Luglio",
      "Agosto",
      "Settembre",
      "Ottobre",
      "Novembre",
      "Dicembre",
    ];

    const date = new Date(dateStr);

    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  }
  const getEventDate = (e) => {
    console.log("date eeeeeeeeee", e);
    setEventDate(e.id);
    setSearchDropDown("");
    setSlotLabel(e.date);
    setDateOpen(false);
    console.log("event dateeeeeeee", e);
  };

  const removeSelectedDonationCenterDate = (e) => {
    e.stopPropagation();
    setDateOpen(false);
    setSlotLabel("Seleziona la data");
    setEventDate("");
  };

  const isWebinarFormValid = () => {
    return webName && url;
  };

  const isEventFormValid = () => {
    return eventName && location && eventDate;
  };

  const getTotalDonation = (e) => {
    const value = e.target.value;
    // setHaveDonated(e.target.value)
    const pattern = /^.{0,15}$/;
    if (pattern.test(value)) {
      setHaveDonated(value);
    }
  };

  if (loader) return <UseLoader />;

  return (
    <div className='CompanyProfile' data-testid='CompanyProfile'>
      <UseTopBar back='Aziende' />
      <LazyBreadCrum />
      {locations?.state?.editCompanyId && locations?.state?.editCompany == true && (
        <h2>{locations.state.companyName || "-"}</h2>
      )}
      {locations?.state?.dashboardEditCompanyId &&
        locations?.state?.dashboardEditCompanyStatus == true && (
          <h2>{locations.state.dashboardEditCompanyName || "-"}</h2>
        )}
      <section className='registerProfile'>
        <Tabs
          activeKey={activeKey}
          id='uncontrolled-tab-example'
          onSelect={(key) => handleActiveTabs(key)}
          className='mb-3'
        >
          <Tab eventKey='home' title='Informazioni'>
            <Form onSubmit={getInformation} noValidate validated={validated}>
              {locations.pathname.slice(1) === "Aziende/Modifica-azienda" &&
                !data?.global && (
                  <Row>
                    <Col xs={12} sm={4} md={4} lg={4}>
                      <CardBox
                        value={status?.donorCount}
                        text='Dipendenti registrati'
                        styleBg={{ background: "#EDEDF0" }}
                        color={{ color: "#292A31" }}
                      />
                    </Col>
                    <Col xs={12} sm={4} md={4} lg={4}>
                      <CardBox
                        value={status?.donorWithLastDonationCount}
                        text='Dipendenti donatori'
                        styleBg={{ background: "#EDEDF0" }}
                        color={{ color: "#292A31" }}
                      />
                    </Col>
                    <Col xs={12} sm={4} md={4} lg={4}>
                      <CardBox
                        value={status.percentage}
                        text='Percentuale donatori'
                        styleBg={{ background: "#EDEDF0" }}
                        color={{ color: "#292A31" }}
                      />
                    </Col>
                  </Row>
                )}
              <Row>
                <Col xs={12} sm={6} md={6} lg={6}>
                  <Form.Group className='mb-3' controlId='name'>
                    <Form.Label>Nome azienda</Form.Label>
                    <Form.Control
                      required
                      type='text'
                      name='name'
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                      placeholder='Company Best World Inc'
                      disabled={data?.global}
                    />
                    <Form.Control.Feedback type='invalid'>
                      campo obbligatorio
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6} className='copyLink'>
                  <Form.Group className='mb-3' controlId='setLink'>
                    <Form.Label>Link piattaforma</Form.Label>
                    <Form.Control
                      required
                      type='text'
                      name='setLink'
                      value={link}
                      onChange={(e) => setLink(e.target.value)}
                      placeholder='platform.mariorossi.it'
                      disabled={data?.global}
                    />
                    <Form.Control.Feedback type='invalid'>
                      campo obbligatorio
                    </Form.Control.Feedback>
                  </Form.Group>
                  <span onClick={copyLinks}>
                    <svg
                      width='30'
                      height='30'
                      viewBox='0 0 30 30'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <g clipPath='url(#clip0_15169_178128)'>
                        <path
                          d='M20 1.25H5C3.625 1.25 2.5 2.375 2.5 3.75V21.25H5V3.75H20V1.25ZM23.75 6.25H10C8.625 6.25 7.5 7.375 7.5 8.75V26.25C7.5 27.625 8.625 28.75 10 28.75H23.75C25.125 28.75 26.25 27.625 26.25 26.25V8.75C26.25 7.375 25.125 6.25 23.75 6.25ZM23.75 26.25H10V8.75H23.75V26.25Z'
                          fill='#787885'
                        />
                      </g>
                      <defs>
                        <clipPath id='clip0_15169_178128'>
                          <rect width='30' height='30' fill='white' />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6}>
                  <Form.Group className='mb-3' controlId='cname'>
                    <Form.Label>Nome e cognome referente</Form.Label>
                    <Form.Control
                      required
                      type='text'
                      name='cname'
                      onChange={(e) => setCname(e.target.value)}
                      value={cname}
                      placeholder='Mario Rossi'
                      disabled={data?.global}
                    />
                    <Form.Control.Feedback type='invalid'>
                      campo obbligatorio
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6}>
                  <Form.Group className='mb-3' controlId='haveDonated'>
                    <Form.Label>Mostra sul sito quante persone hanno donato</Form.Label>
                    <Form.Control
                      required
                      type='text'
                      name='haveDonated'
                      onChange={getTotalDonation}
                      value={haveDonated}
                      disabled={data?.global}
                    />
                    <Form.Control.Feedback type='invalid'>
                      campo obbligatorio
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6}>
                  <Form.Group className='mb-3' controlId='email'>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      required
                      type='email'
                      name='email'
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      placeholder='mariorossi@mail.com'
                      disabled={data?.global}
                    />
                    <Form.Control.Feedback type='invalid'>
                      campo obbligatorio
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6} className='imageUpload'>
                  <Form.Group className='mb-3' controlId='formBasicEmail'>
                    <Form.Label>Logo aziendale</Form.Label>
                    <br></br>
                    {!clogo && (
                      <div className='d-flex align-items-center'>
                        <UseImageUpload
                          label='Carica il logo'
                          onChange={getFileName}
                          disabled={data?.global}
                        />
                        {logoError && (
                          <p className='mb-0 mx-2 text-danger fw-medium fs-6'>
                            campo obbligatorio
                          </p>
                        )}
                      </div>
                    )}
                    {clogo && (
                      <div className='logoInformazaioniPreview'>
                        <img src={clogo} alt='Image' />
                        {!data?.global && (
                          <span onClick={removeCompanyLogo}>
                            <svg
                              width='20'
                              height='20'
                              viewBox='0 0 20 20'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M3.50839 13.1064L6.90439 16.5024L16.0844 7.32244L12.6884 3.92644L3.50839 13.1064ZM0.400391 19.5984L5.69239 17.6904L2.32039 14.3424L0.400391 19.5984ZM17.2004 0.398438C16.5404 0.398438 15.9404 0.662437 15.5084 1.10644L13.5284 3.08644L16.9244 6.48244L18.9044 4.50244C19.3364 4.05844 19.6004 3.45844 19.6004 2.79844C19.6004 1.47844 18.5204 0.398438 17.2004 0.398438Z'
                                fill='#F7F7FA'
                              />
                            </svg>
                          </span>
                        )}
                      </div>
                    )}
                  </Form.Group>
                  <Form.Text className={!clogo ? "text-muted" : "event-image-type"}>
                    <svg
                      width='21'
                      height='20'
                      viewBox='0 0 21 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M10.5 0C4.98 0 0.5 4.48 0.5 10C0.5 15.52 4.98 20 10.5 20C16.02 20 20.5 15.52 20.5 10C20.5 4.48 16.02 0 10.5 0ZM11.5 16H9.5V14H11.5V16ZM11.5 13H9.5V4H11.5V13Z'
                        fill='#D2D2D6'
                      />
                    </svg>
                    400px - max 15mb - PNG, JPEG
                  </Form.Text>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6} md={6} lg={6}>
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <Form.Group className='mb-3' controlId='company'>
                        <Form.Label>Dominio aziendale</Form.Label>
                        <Form.Control
                          required
                          type='text'
                          name='company'
                          value={company}
                          onChange={(e) => setCompany(e.target.value)}
                          placeholder='mariorossi.it'
                          disabled={data?.global}
                        />
                        <Form.Control.Feedback type='invalid'>
                          campo obbligatorio
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  className='d-flex align-items-end adminCustomToggle'
                >
                  <Row className='fullWidth'>
                    <Col xs={12} sm={6} md={6} lg={6}>
                      <Form>
                        {/* {activePro === tru  e?'true':'false'} */}
                        <Form.Check // prettier-ignore
                          type='switch'
                          id='PRO active'
                          value={activePro}
                          checked={activePro === true}
                          onChange={(e) => setActivePro(e.target.checked)}
                          label='Piano PRO attivo'
                          disabled={data?.global}
                        />
                      </Form>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6}>
                      <Form>
                        <Form.Check // prettier-ignore
                          type='switch'
                          id='Disattiva account'
                          value={deactivate}
                          onChange={(e) => setDeactivate(e.target.checked)}
                          label='Disattiva account'
                          disabled={data?.global}
                        />
                      </Form>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className='mt-4 d-flex justify-content-end'
                >
                  <UseButton
                    type='submit'
                    name='adminBtnPrimary'
                    variant='primary'
                    labels={
                      locations?.state?.editCompany ? "Salva modifiche" : "Crea azienda"
                    }
                    disabled={data?.global}
                  />
                </Col>
              </Row>
            </Form>
          </Tab>
          {locations.pathname.slice(1) === "Aziende/Modifica-azienda" && (
            <Tab eventKey='profile' title='Eventi'>
              <div className='event'>
                {event === false && (
                  <UseButton
                    onClick={crateEvent}
                    name='adminBtnPrimary eventBtn'
                    variant='primary'
                    labels='Crea evento'
                  />
                )}
                {event === false && (
                  <Row>
                    <Col xs={12} sm={12} md={6} lg={6}>
                      <h4>Eventi in corso</h4>
                      <Table hover responsive>
                        <thead>
                          <tr>
                            <th>Nome evento</th>
                            <th>Luogo</th>
                            <th>Data dell'evento</th>
                            <th>&nbsp;</th>
                          </tr>
                        </thead>
                        <tbody>
                          {events?.length > 0 ? (
                            events?.map((data, index) => {
                              return (
                                <tr key={index}>
                                  <td>{data?.name}</td>
                                  <td>{data?.donationCenterAddress}</td>
                                  <td>
                                    {/* {EventDateFormate(data?.date)} */}
                                    {formatDateToItalian(data?.date)}
                                  </td>
                                  <td width='115'>
                                    <Button
                                      className='delete'
                                      onClick={() => {
                                        navigate(`/eventi/${data.donationCenterId}`, {
                                          state: {
                                            event: data.id,
                                          },
                                        });
                                      }}
                                      style={{ marginRight: "15px" }}
                                    >
                                      {" "}
                                      <svg
                                        width='21'
                                        height='14'
                                        viewBox='0 0 21 14'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'
                                      >
                                        <path
                                          d='M19.8211 6.74687C19.7937 6.68516 19.132 5.21719 17.6609 3.74609C15.7008 1.78594 13.225 0.75 10.5 0.75C7.77499 0.75 5.29921 1.78594 3.33905 3.74609C1.86796 5.21719 1.20312 6.6875 1.1789 6.74687C1.14336 6.82681 1.125 6.91331 1.125 7.00078C1.125 7.08825 1.14336 7.17476 1.1789 7.25469C1.20624 7.31641 1.86796 8.78359 3.33905 10.2547C5.29921 12.2141 7.77499 13.25 10.5 13.25C13.225 13.25 15.7008 12.2141 17.6609 10.2547C19.132 8.78359 19.7937 7.31641 19.8211 7.25469C19.8566 7.17476 19.875 7.08825 19.875 7.00078C19.875 6.91331 19.8566 6.82681 19.8211 6.74687ZM10.5 12C8.0953 12 5.99452 11.1258 4.25546 9.40234C3.5419 8.69273 2.93483 7.88356 2.45312 7C2.9347 6.11636 3.54179 5.30717 4.25546 4.59766C5.99452 2.87422 8.0953 2 10.5 2C12.9047 2 15.0055 2.87422 16.7445 4.59766C17.4595 5.307 18.0679 6.11619 18.5508 7C17.9875 8.05156 15.5336 12 10.5 12ZM10.5 3.25C9.75831 3.25 9.03329 3.46993 8.4166 3.88199C7.79992 4.29404 7.31927 4.87971 7.03544 5.56494C6.75162 6.25016 6.67735 7.00416 6.82205 7.73159C6.96674 8.45902 7.32389 9.1272 7.84834 9.65165C8.37279 10.1761 9.04097 10.5332 9.7684 10.6779C10.4958 10.8226 11.2498 10.7484 11.9351 10.4645C12.6203 10.1807 13.2059 9.70007 13.618 9.08339C14.0301 8.4667 14.25 7.74168 14.25 7C14.249 6.00575 13.8535 5.05253 13.1505 4.34949C12.4475 3.64645 11.4942 3.25103 10.5 3.25ZM10.5 9.5C10.0055 9.5 9.52219 9.35338 9.11107 9.07867C8.69994 8.80397 8.37951 8.41352 8.19029 7.95671C8.00107 7.49989 7.95157 6.99723 8.04803 6.51227C8.14449 6.02732 8.38259 5.58186 8.73222 5.23223C9.08186 4.8826 9.52731 4.6445 10.0123 4.54804C10.4972 4.45157 10.9999 4.50108 11.4567 4.6903C11.9135 4.87952 12.304 5.19995 12.5787 5.61107C12.8534 6.0222 13 6.50555 13 7C13 7.66304 12.7366 8.29892 12.2678 8.76777C11.7989 9.23661 11.163 9.5 10.5 9.5Z'
                                          fill='#2264D1'
                                          stroke='#2264D1'
                                          strokeWidth='0.637037'
                                        />
                                      </svg>
                                    </Button>
                                    <Button
                                      className='edit'
                                      onClick={() => editEvenets(data.id)}
                                    >
                                      {" "}
                                      <svg
                                        width='16'
                                        height='18'
                                        viewBox='0 0 16 18'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'
                                      >
                                        <path
                                          d='M3.345 12.2419L11.7967 3.79026L10.6183 2.61193L2.16667 11.0636V12.2419H3.345ZM4.03583 13.9086H0.5V10.3728L10.0292 0.843598C10.1854 0.687372 10.3974 0.599609 10.6183 0.599609C10.8393 0.599609 11.0512 0.687372 11.2075 0.843598L13.565 3.2011C13.7212 3.35737 13.809 3.56929 13.809 3.79026C13.809 4.01123 13.7212 4.22316 13.565 4.37943L4.03583 13.9086ZM0.5 15.5753H15.5V17.2419H0.5V15.5753Z'
                                          fill='#2264D1'
                                        />
                                      </svg>
                                    </Button>

                                    <Button
                                      className='delete'
                                      onClick={() => removeEvents(data.id)}
                                    >
                                      {" "}
                                      <svg
                                        width='14'
                                        height='16'
                                        viewBox='0 0 14 16'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'
                                      >
                                        <path
                                          d='M2.83398 15.5C2.37565 15.5 1.98343 15.3369 1.65732 15.0108C1.33121 14.6847 1.16787 14.2922 1.16732 13.8333V3H0.333984V1.33333H4.50065V0.5H9.50065V1.33333H13.6673V3H12.834V13.8333C12.834 14.2917 12.6709 14.6842 12.3448 15.0108C12.0187 15.3375 11.6262 15.5006 11.1673 15.5H2.83398ZM11.1673 3H2.83398V13.8333H11.1673V3ZM4.50065 12.1667H6.16732V4.66667H4.50065V12.1667ZM7.83398 12.1667H9.50065V4.66667H7.83398V12.1667Z'
                                          fill='#2264D1'
                                        />
                                      </svg>
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan='4'>Nessun dato</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6}>
                      <h4>Eventi passati</h4>
                      <Table hover responsive>
                        <thead>
                          <tr>
                            <th>Nome evento</th>
                            <th>Luogo</th>
                            <th>Data dell'evento</th>
                          </tr>
                        </thead>
                        <tbody>
                          {pastEvents?.length > 0 ? (
                            pastEvents?.map((data, index) => {
                              return (
                                <tr key={index}>
                                  <td>{data?.name}</td>
                                  <td>{data?.donationCenterAddress}</td>
                                  <td> {formatDateToItalian(data?.date)}</td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan='3'>Nessun dato</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                )}

                {event === true && (
                  <Form onSubmit={getEvents} noValidate validated={eventValidated}>
                    <Row>
                      <Col xs={12} sm={6} md={6} lg={6}>
                        <Form.Group className='mb-3' controlId='webinar'>
                          <Form.Label>Nome evento</Form.Label>
                          <Form.Control
                            required
                            type='text'
                            name='eventName'
                            onChange={(e) => setEventName(e.target.value)}
                            value={eventName}
                          />
                          <Form.Control.Feedback type='invalid'>
                            campo obbligatorio
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col xs={12} sm={6} md={6} lg={6}>
                        <Form.Group
                          className='mb-3 position-relative'
                          controlId='location'
                        >
                          <Form.Label>Centro di donazione</Form.Label>
                          <UseDropDownWithSearch
                            search={searchDropDown}
                            onSearch={setSearchDropDown}
                            list={donationCenterList}
                            // getListValue={setSearchDropDownList}
                            label={donationCenterLabel}
                            getFunction={selectDonationCenter}
                            removeData={removeSelectedDonationCenter}
                            open={searchOpen}
                            setOpen={setSearchOpen}
                            noData='Nessuna corrispondenza trovata'
                            activeClass={location}
                            dynamicKeysOne='name'
                            dynamicKeysTwo='id'
                          />
                          {error.donationCenter && (
                            <p className='mb-0 mx-2 text-danger fw-medium fs-6'>
                              campo obbligatorio
                            </p>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12} sm={6} md={6} lg={6}>
                        <Row>
                          <Col xs={12} sm={12} md={12} lg={12}>
                            <Form.Group className='mb-3' controlId='description'>
                              <Form.Label>Descrizione</Form.Label>
                              <Form.Control
                                as='textarea'
                                rows={3}
                                name='description'
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                              />
                            </Form.Group>
                            <p className='noOfChar'>{description.length}/200 caratteri</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} sm={6} md={6} lg={6}>
                        <Row>
                          <Col xs={12} sm={12} md={12} lg={12}>
                            <Row>
                              <Col xs={12} sm={12} md={12} lg={12}>
                                <Form.Group
                                  className='mb-3 position-relative'
                                  controlId='date'
                                >
                                  <Form.Label>Data dell’evento</Form.Label>
                                  <UseDropDownWithSearch
                                    search={searchDropDown}
                                    onSearch={setSearchDropDown}
                                    label={slotLabel}
                                    list={donationCenterDate}
                                    open={dateOpen}
                                    setOpen={setDateOpen}
                                    noData='Non ci sono date disponibili'
                                    getFunction={getEventDate}
                                    removeData={removeSelectedDonationCenterDate}
                                    activeClass={eventDate}
                                    dynamicKeysOne='date'
                                    dynamicKeysTwo='id'
                                  />
                                  {error.date && (
                                    <p className='mb-0 mx-2 text-danger fw-medium fs-6'>
                                      campo obbligatorio
                                    </p>
                                  )}
                                </Form.Group>
                              </Col>
                              <Col
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                className='imageUpload'
                              >
                                <Form.Group className='mb-3' controlId='formBasicEmail'>
                                  <Form.Label>Copertina evento</Form.Label>
                                  <br></br>
                                  {!wlogo && (
                                    <UseImageUploadOne
                                      label='Carica la copertina'
                                      click={getWlogo}
                                    />
                                  )}
                                  {wlogo && (
                                    <div className='logoEventPreview'>
                                      <img src={wlogo} alt='Image' />
                                      <span onClick={removeLogo}>
                                        <svg
                                          width='24'
                                          height='24'
                                          viewBox='0 0 24 24'
                                          fill='none'
                                          xmlns='http://www.w3.org/2000/svg'
                                        >
                                          <path
                                            fillRule='evenodd'
                                            clipRule='evenodd'
                                            d='M5.50839 15.1084L8.90439 18.5044L18.0844 9.32439L14.6884 5.92839L5.50839 15.1084ZM2.40039 21.6004L7.69239 19.6924L4.32039 16.3444L2.40039 21.6004ZM19.2004 2.40039C18.5404 2.40039 17.9404 2.66439 17.5084 3.10839L15.5284 5.08839L18.9244 8.48439L20.9044 6.50439C21.3364 6.06039 21.6004 5.46039 21.6004 4.80039C21.6004 3.48039 20.5204 2.40039 19.2004 2.40039Z'
                                            fill='#F7F7FA'
                                          />
                                        </svg>
                                      </span>
                                    </div>
                                  )}
                                </Form.Group>
                                <Form.Text
                                  className={!wlogo ? "text-muted" : "event-image-type"}
                                >
                                  <svg
                                    width='21'
                                    height='20'
                                    viewBox='0 0 21 20'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                  >
                                    <path
                                      fillRule='evenodd'
                                      clipRule='evenodd'
                                      d='M10.5 0C4.98 0 0.5 4.48 0.5 10C0.5 15.52 4.98 20 10.5 20C16.02 20 20.5 15.52 20.5 10C20.5 4.48 16.02 0 10.5 0ZM11.5 16H9.5V14H11.5V16ZM11.5 13H9.5V4H11.5V13Z'
                                      fill='#D2D2D6'
                                    />
                                  </svg>
                                  &nbsp;&nbsp; 400px - max 70mb - PNG, JPEG
                                </Form.Text>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={6} md={6} lg={6}>
                        <Form.Group className='mb-3' controlId='breakfast_info'>
                          <Form.Label>Informazioni sulla colazione</Form.Label>
                          <Form.Control
                            as='textarea'
                            rows={3}
                            name='breakfast_info'
                            value={breakfastInfo}
                            onChange={(e) => setBreakfastInfo(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} sm={6} md={6} lg={6}>
                        <Form.Group className='mb-3' controlId='instructions'>
                          <Form.Label>Istruzioni</Form.Label>
                          <Form.Control
                            as='textarea'
                            rows={3}
                            name='instructions'
                            value={instructions}
                            onChange={(e) => setInstructions(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={6} md={6} lg={6}>
                        <Form.Group className='mb-3' controlId='webinar'>
                          <Form.Label>Referente</Form.Label>
                          <Form.Control
                            type='text'
                            name='contactPerson'
                            onChange={(e) => setEventContactPerson(e.target.value)}
                            value={eventContactPerson}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} sm={6} md={6} lg={6}>
                        <Form.Group className='mb-3' controlId='webinar'>
                          <Form.Label>Numero del telefono referente</Form.Label>
                          <Form.Control
                            type='tel'
                            name='eventName'
                            onChange={(e) => setEventPhoneNo(e.target.value)}
                            value={eventPhoneNo}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        className='mt-4 d-flex justify-content-end  align-items-center'
                      >
                        <UseButton
                          disabled={!isEventFormValid()}
                          name='adminBtnPrimary'
                          variant='primary'
                          labels='Pubblica evento'
                        />
                      </Col>
                    </Row>
                  </Form>
                )}
              </div>
            </Tab>
          )}
          {locations.pathname.slice(1) === "Aziende/Modifica-azienda" && (
            <Tab eventKey='contact' title='Webinar'>
              <div>
                {webinarStatus == false && (
                  <div className='webinar'>
                    <UseButton
                      onClick={() => setWebinarStatus(true)}
                      name='adminBtnPrimary'
                      variant='primary'
                      labels='Crea webinar'
                    />
                    <h3>Webinar attivi</h3>
                    <Table hover>
                      <thead>
                        <tr>
                          <th>Nome webinar</th>
                          <th>Data di creazione</th>
                          <th width='70'>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {webinars.length > 0 ? (
                          webinars?.map((data, index) => {
                            return (
                              <tr key={index}>
                                <td>{data?.name} </td>
                                <td>
                                  {new Date(
                                    data?.createdAt._seconds * 1000 +
                                      data?.createdAt._nanoseconds / 1000000,
                                  ).toDateString()}
                                  &nbsp;&nbsp;
                                  {new Date(
                                    data?.createdAt._seconds * 1000 +
                                      data?.createdAt._nanoseconds / 1000000,
                                  ).toLocaleTimeString()}
                                </td>
                                <td>
                                  <Button
                                    onClick={() => editWebinar(data?.id)}
                                    className='edit'
                                  >
                                    <svg
                                      width='16'
                                      height='18'
                                      viewBox='0 0 16 18'
                                      fill='none'
                                      xmlns='http://www.w3.org/2000/svg'
                                    >
                                      <path
                                        d='M3.345 12.2419L11.7967 3.79026L10.6183 2.61193L2.16667 11.0636V12.2419H3.345ZM4.03583 13.9086H0.5V10.3728L10.0292 0.843598C10.1854 0.687372 10.3974 0.599609 10.6183 0.599609C10.8393 0.599609 11.0512 0.687372 11.2075 0.843598L13.565 3.2011C13.7212 3.35737 13.809 3.56929 13.809 3.79026C13.809 4.01123 13.7212 4.22316 13.565 4.37943L4.03583 13.9086ZM0.5 15.5753H15.5V17.2419H0.5V15.5753Z'
                                        fill='#2264D1'
                                      />
                                    </svg>
                                  </Button>
                                  <Button
                                    onClick={() => removeWebinar(data.id, data.name)}
                                    className='delete'
                                  >
                                    <svg
                                      width='14'
                                      height='16'
                                      viewBox='0 0 14 16'
                                      fill='none'
                                      xmlns='http://www.w3.org/2000/svg'
                                    >
                                      <path
                                        d='M2.83398 15.5C2.37565 15.5 1.98343 15.3369 1.65732 15.0108C1.33121 14.6847 1.16787 14.2922 1.16732 13.8333V3H0.333984V1.33333H4.50065V0.5H9.50065V1.33333H13.6673V3H12.834V13.8333C12.834 14.2917 12.6709 14.6842 12.3448 15.0108C12.0187 15.3375 11.6262 15.5006 11.1673 15.5H2.83398ZM11.1673 3H2.83398V13.8333H11.1673V3ZM4.50065 12.1667H6.16732V4.66667H4.50065V12.1667ZM7.83398 12.1667H9.50065V4.66667H7.83398V12.1667Z'
                                        fill='#2264D1'
                                      />
                                    </svg>
                                  </Button>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td style={{ textAlign: "center" }} colSpan='3'>
                              Nessun dato
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                )}
                {webinarStatus === true && (
                  <div>
                    <Form onSubmit={addWebinar} noValidate validated={webinarValidated}>
                      <Row>
                        <Col xs={12} md={6} lg={6}>
                          <Form.Group className='mb-3' controlId='webName'>
                            <Form.Label>Nome webinar</Form.Label>
                            <Form.Control
                              required
                              type='text'
                              name='webName'
                              value={webName}
                              onChange={(e) => setWebName(e.target.value)}
                            />
                            <Form.Control.Feedback type='invalid'>
                              campo obbligatorio
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col xs={12} md={6} lg={6}>
                          <Form.Group className='mb-3' controlId='url'>
                            <Form.Label>Link webinar</Form.Label>
                            <Form.Control
                              required
                              type='text'
                              name='url'
                              value={url}
                              onChange={(e) => setUrl(e.target.value)}
                            />
                            <Form.Control.Feedback type='invalid'>
                              campo obbligatorio
                            </Form.Control.Feedback>
                            {/* <input type="file"  /> */}
                          </Form.Group>
                        </Col>
                        <Col xs={12} md={6} lg={6}>
                          <Form.Group className='mb-3' controlId='url'>
                            <Form.Label>Tempo</Form.Label>
                            <Form.Control
                              type='text'
                              name='time'
                              value={time}
                              onChange={(e) => setTime(e.target.value)}
                            />
                            {/* <input type="file"  /> */}
                          </Form.Group>
                        </Col>
                        <Col xs={12} md={6} lg={6} className='position-relative'>
                          <Form.Group className='mb-3 imageUpload ' controlId='image'>
                            <Form.Label>Copertina webinar</Form.Label>
                            <br></br>
                            {/* <Form.Control type="text" name="image" value={image} onChange={(e)=>setImage(e.target.value)}   />                                       */}
                            {!image && (
                              <WebinarImageUpload
                                label='Carica la copertina'
                                click={webinarLogo}
                              />
                            )}
                            {image && (
                              <div>
                                <div className='logoWebinarPreview'>
                                  <img src={image} alt='Image' />
                                  <span onClick={removeWebinarLogo}>
                                    <svg
                                      width='24'
                                      height='24'
                                      viewBox='0 0 24 24'
                                      fill='none'
                                      xmlns='http://www.w3.org/2000/svg'
                                    >
                                      <path
                                        fillRule='evenodd'
                                        clipRule='evenodd'
                                        d='M5.50839 15.1084L8.90439 18.5044L18.0844 9.32439L14.6884 5.92839L5.50839 15.1084ZM2.40039 21.6004L7.69239 19.6924L4.32039 16.3444L2.40039 21.6004ZM19.2004 2.40039C18.5404 2.40039 17.9404 2.66439 17.5084 3.10839L15.5284 5.08839L18.9244 8.48439L20.9044 6.50439C21.3364 6.06039 21.6004 5.46039 21.6004 4.80039C21.6004 3.48039 20.5204 2.40039 19.2004 2.40039Z'
                                        fill='#F7F7FA'
                                      />
                                    </svg>
                                  </span>
                                </div>
                                <Form.Text className='logo-types'>
                                  <svg
                                    width='21'
                                    height='20'
                                    viewBox='0 0 21 20'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                  >
                                    <path
                                      fillRule='evenodd'
                                      clipRule='evenodd'
                                      d='M10.5 0C4.98 0 0.5 4.48 0.5 10C0.5 15.52 4.98 20 10.5 20C16.02 20 20.5 15.52 20.5 10C20.5 4.48 16.02 0 10.5 0ZM11.5 16H9.5V14H11.5V16ZM11.5 13H9.5V4H11.5V13Z'
                                      fill='#D2D2D6'
                                    />
                                  </svg>
                                  &nbsp;&nbsp; 400px - max 7mb - PNG, JPEG
                                </Form.Text>
                              </div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col xs={12} md={6} lg={6}>
                          <Form.Group className='mb-3' controlId='webDesc'>
                            <Form.Label>Descrizione</Form.Label>
                            <Form.Control
                              as='textarea'
                              name='webDesc'
                              value={webDesc}
                              onChange={(e) => setWebDesc(e.target.value)}
                            />
                            <p className='textarea_count'>
                              {webDesc.length}/200 caratteri
                            </p>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          className='mt-4 d-flex justify-content-end align-items-center'
                        >
                          <UseButton
                            name='adminBtnPrimary'
                            variant='primary'
                            disabled={!isWebinarFormValid()}
                            labels={
                              editWebinarStatus ? "Aggiorna webinar" : "Pubblica webinar"
                            }
                            // labels='Pubblica webinar'
                          />
                        </Col>
                      </Row>
                    </Form>
                  </div>
                )}

                {/* end of webinar */}
              </div>
            </Tab>
          )}
        </Tabs>
        <UseModal
          openModal={openModal}
          closeModal={closeModal}
          webName={webName}
          setWebName={setWebName}
          webDesc={webDesc}
          setWebDesc={setWebDesc}
          url={url}
          setUrl={setUrl}
          image={image}
          setImage={setImage}
          webinarId={webinarId}
        />
        <UseAlert show={show} setShow={setShow} variant={variant} alertMsg={alertMsg} />
        {loader && <UseLoader />}
        {deleteEventConfirm && (
          <Modal
            title='Sei sicuro?'
            onConfirmReturnData={eventIds}
            onCancel={removeEventCancel}
            onConfirm={removeEventConfirm}
          >
            <p>Una volta eliminato non potrai tornare indietro</p>
          </Modal>
        )}

        {deleteWebinarConfirm && (
          <Modal
            onConfirmReturnData={webinarIds}
            onCancel={removeWebinarCancel}
            onConfirm={removeWebinarConfirm}
          >
            <h3>Vuoi davvero eliminare il webinar &ldquo;{webinarName}&rdquo;?</h3>
            <p>Una volta eliminato non potrai tornare indietro</p>
          </Modal>
        )}
      </section>
    </div>
  );
};

CompanyProfile.propTypes = {};

CompanyProfile.defaultProps = {};

export default CompanyProfile;
