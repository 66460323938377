import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Jumbo from "../../Components/JumboTron/JumboTron";
import { useAuth } from "../../Hookes/useAuth";
import LazyAvailableSlot from "../AvailableSlot/AvailableSlot";
import LazyDonate from "../Donate/Donate";
import LazyDonateNow from "../DonateNow/DonateNow";
import LazyDonationTimeline from "../DonationTimeline/DonationTimeline";
import LazyEvents from "../Events/Events";
import LazyFaq from "../FAQ/FAQ";
import LazyReservations from "../Reservations/Reservations";
import LazySeminar from "../Seminar/Seminar";
import "./HomePage.scss";
const HomePage = () => {
  const functions = getFunctions();
  const { uid, role, loading } = useAuth();
  const [user, setUser] = useState();
  const [companyId, setCompanyId] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    if (uid && role === "admin") navigate("/dashboard");
  }, [navigate, role, uid]);

  useEffect(() => {
    const user = localStorage.getItem("user");
    setUser("user", user);

    // navigate('/generali/tPlM6Bb3iVpYl82NHouH')
  }, []);

  useEffect(() => {
    try {
      const getDonorById = httpsCallable(functions, "getDonorById");
      const data = {
        id: uid,
      };
      getDonorById(data)
        .then((response) => {
          console.log(response);
          setCompanyId(response.data.company);
        })
        .catch((error) => console.error("error", error));
    } catch (error) {
      console.log("error", error);
    }
  }, [functions, uid]);

  if (loading) return null;
  return (
    <div className='HomePage' id='Home' data-testid='HomePage'>
      <LazyAvailableSlot />
      <Container>
        <LazyDonationTimeline userId={uid} />
        {companyId && <Jumbo companyId={companyId} />}
        <LazyDonateNow />

        {/* <LazyBookDonation/> */}
        {/* <LazyDonate/> */}
      </Container>
      <LazyReservations />
      <Container>
        <LazyEvents companyId={companyId} />

        {companyId && <LazySeminar companyId={companyId} />}

        {/* <LazyMyDiary/> */}
        <LazyFaq />
      </Container>
      <LazyDonate />
    </div>
  );
};

HomePage.propTypes = {};

HomePage.defaultProps = {};

export default HomePage;
