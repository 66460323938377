import "./UseImageUploadOne.scss";
const UseImageUploadOne = ({ label, click }) => {
  return (
    <div className='useImageUploadOne'>
      <label for='uploadOne'>
        {label}
        <input type='file' accept='image/*' onChange={click} id='uploadOne' />
      </label>
    </div>
  );
};
export default UseImageUploadOne;
