import React from "react";
import { Button, Card } from "react-bootstrap";
import image from "../../../assets/images/noimage.png";
import "./CardTwo.scss";
const CardTwo = ({ data }) => {
  const openurl = () => {
    if (/^https?:\/\//.test(data.url)) {
      window.open(data.url);
    } else {
      window.open(`http://${data.url}`);
    }
  };
  // console.log('dataaaaaaaaaaa for wibinarrr',data)
  return (
    <div className='customCardTwo'>
      {/* <Link to={'#'}> */}
      {/* {data?.length} */}
      <Card>
        <div className='p-2'>
          {data?.image && (
            <Card.Img
              variant='top'
              src={data?.image}
              alt='Image'
              style={{ objectFit: "contain" }}
            />
          )}
          {!data?.image && (
            <Card.Img
              variant='top'
              src={image}
              alt='Image'
              style={{ objectFit: "contain" }}
            />
          )}
        </div>
        <Card.Body>
          <div>
            <Card.Title>{data?.name}</Card.Title>
            <Card.Text>{data?.description}</Card.Text>
            <span>
              <svg
                width='30'
                height='30'
                viewBox='0 0 30 30'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M15 10V15L18.75 18.75L15 10ZM26.25 15C26.25 16.4774 25.959 17.9403 25.3936 19.3052C24.8283 20.6701 23.9996 21.9103 22.955 22.955C21.9103 23.9996 20.6701 24.8283 19.3052 25.3936C17.9403 25.959 16.4774 26.25 15 26.25C13.5226 26.25 12.0597 25.959 10.6948 25.3936C9.3299 24.8283 8.08971 23.9996 7.04505 22.955C6.00039 21.9103 5.17172 20.6701 4.60636 19.3052C4.04099 17.9403 3.75 16.4774 3.75 15C3.75 12.0163 4.93526 9.15483 7.04505 7.04505C9.15483 4.93526 12.0163 3.75 15 3.75C17.9837 3.75 20.8452 4.93526 22.955 7.04505C25.0647 9.15483 26.25 12.0163 26.25 15Z'
                  stroke='#292A31'
                  stroke-width='1.875'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                />
              </svg>{" "}
              <p> {data.time}</p>
            </span>
          </div>
          <Card.Footer>
            {/* <UseButton name="adminBtnPrimary" variant="btn btn-outline-secondary" labels="Vai al webinar" /> */}
            <Button
              onClick={openurl}
              name='adminBtnPrimary'
              variant='btn btn-outline-secondary'
            >
              Vai al webinar{" "}
              <svg
                width='23'
                height='24'
                viewBox='0 0 23 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M1.00015 12.0001H21.5018M21.5018 12.0001L11.251 1.74927M21.5018 12.0001L11.251 22.2509'
                  stroke='#BA0F31'
                  strokeWidth='1.96605'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </Button>
          </Card.Footer>
        </Card.Body>
      </Card>
      {/* </Link> */}
    </div>
  );
};
export default CardTwo;
