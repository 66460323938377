export const years = Array.from({ length: 101 }, (_, i) => 1924 + i); // Generate years from 1990 to 2021
export const months = [
    "Gennaio",
    "Febbraio",
    "Marzo",
    "Aprile",
    "Maggio",
    "Giugno",
    "Luglio",
    "Agosto",
    "Settembre",
    "Ottobre",
    "Novembre",
    "Dicembre",
  ];

  export const cities = [
    {
      "sigla_provincia": "TO",
      "codice_istat": "001001",
      "denominazione_ita_altra": "Agliè",
      "denominazione_ita": "Agliè",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A074",
      "lat": "45.3634368",
      "lon": "7.7685999",
      "superficie_kmq": "13.1462",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001002",
      "denominazione_ita_altra": "Airasca",
      "denominazione_ita": "Airasca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A109",
      "lat": "44.9168832",
      "lon": "7.4844311",
      "superficie_kmq": "15.7393",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001003",
      "denominazione_ita_altra": "Ala di Stura",
      "denominazione_ita": "Ala di Stura",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A117",
      "lat": "45.3151133",
      "lon": "7.3043438",
      "superficie_kmq": "46.3315",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001004",
      "denominazione_ita_altra": "Albiano d'Ivrea",
      "denominazione_ita": "Albiano d'Ivrea",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A157",
      "lat": "45.4338982",
      "lon": "7.9491448",
      "superficie_kmq": "11.7314",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001006",
      "denominazione_ita_altra": "Almese",
      "denominazione_ita": "Almese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A218",
      "lat": "45.1176634",
      "lon": "7.3951819",
      "superficie_kmq": "17.8756",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001007",
      "denominazione_ita_altra": "Alpette",
      "denominazione_ita": "Alpette",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A221",
      "lat": "45.4099867",
      "lon": "7.5785972",
      "superficie_kmq": "5.6261",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001008",
      "denominazione_ita_altra": "Alpignano",
      "denominazione_ita": "Alpignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A222",
      "lat": "45.0944696",
      "lon": "7.5242673",
      "superficie_kmq": "11.9193",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001009",
      "denominazione_ita_altra": "Andezeno",
      "denominazione_ita": "Andezeno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A275",
      "lat": "45.0358599",
      "lon": "7.8699487",
      "superficie_kmq": "7.4860",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001010",
      "denominazione_ita_altra": "Andrate",
      "denominazione_ita": "Andrate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A282",
      "lat": "45.5283810",
      "lon": "7.8738392",
      "superficie_kmq": "9.3085",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001011",
      "denominazione_ita_altra": "Angrogna",
      "denominazione_ita": "Angrogna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A295",
      "lat": "44.8434461",
      "lon": "7.2242923",
      "superficie_kmq": "38.8782",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001012",
      "denominazione_ita_altra": "Arignano",
      "denominazione_ita": "Arignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A405",
      "lat": "45.0440280",
      "lon": "7.9024464",
      "superficie_kmq": "8.1668",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001013",
      "denominazione_ita_altra": "Avigliana",
      "denominazione_ita": "Avigliana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A518",
      "lat": "45.0793571",
      "lon": "7.3963234",
      "superficie_kmq": "23.2172",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001014",
      "denominazione_ita_altra": "Azeglio",
      "denominazione_ita": "Azeglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A525",
      "lat": "45.4248665",
      "lon": "7.9947706",
      "superficie_kmq": "9.9605",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001015",
      "denominazione_ita_altra": "Bairo",
      "denominazione_ita": "Bairo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A584",
      "lat": "45.3862534",
      "lon": "7.7569180",
      "superficie_kmq": "7.0859",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001016",
      "denominazione_ita_altra": "Balangero",
      "denominazione_ita": "Balangero",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A587",
      "lat": "45.2700324",
      "lon": "7.5193095",
      "superficie_kmq": "13.0063",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001017",
      "denominazione_ita_altra": "Baldissero Canavese",
      "denominazione_ita": "Baldissero Canavese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A590",
      "lat": "45.4084080",
      "lon": "7.7444444",
      "superficie_kmq": "4.5064",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001018",
      "denominazione_ita_altra": "Baldissero Torinese",
      "denominazione_ita": "Baldissero Torinese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A591",
      "lat": "45.0684227",
      "lon": "7.8178077",
      "superficie_kmq": "15.4054",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001019",
      "denominazione_ita_altra": "Balme",
      "denominazione_ita": "Balme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A599",
      "lat": "45.3022970",
      "lon": "7.2183140",
      "superficie_kmq": "62.7105",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001020",
      "denominazione_ita_altra": "Banchette",
      "denominazione_ita": "Banchette",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A607",
      "lat": "45.4608553",
      "lon": "7.8565637",
      "superficie_kmq": "2.0289",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001021",
      "denominazione_ita_altra": "Barbania",
      "denominazione_ita": "Barbania",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A625",
      "lat": "45.2905029",
      "lon": "7.6305109",
      "superficie_kmq": "12.8027",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001022",
      "denominazione_ita_altra": "Bardonecchia",
      "denominazione_ita": "Bardonecchia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A651",
      "lat": "45.0781688",
      "lon": "6.7038239",
      "superficie_kmq": "132.2030",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001023",
      "denominazione_ita_altra": "Barone Canavese",
      "denominazione_ita": "Barone Canavese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A673",
      "lat": "45.3277288",
      "lon": "7.8705562",
      "superficie_kmq": "3.9864",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001024",
      "denominazione_ita_altra": "Beinasco",
      "denominazione_ita": "Beinasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A734",
      "lat": "45.0220777",
      "lon": "7.5791581",
      "superficie_kmq": "6.7343",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001025",
      "denominazione_ita_altra": "Bibiana",
      "denominazione_ita": "Bibiana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A853",
      "lat": "44.7975756",
      "lon": "7.2897861",
      "superficie_kmq": "18.5979",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001026",
      "denominazione_ita_altra": "Bobbio Pellice",
      "denominazione_ita": "Bobbio Pellice",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A910",
      "lat": "44.8077018",
      "lon": "7.1179046",
      "superficie_kmq": "94.0819",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001027",
      "denominazione_ita_altra": "Bollengo",
      "denominazione_ita": "Bollengo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A941",
      "lat": "45.4733626",
      "lon": "7.9453487",
      "superficie_kmq": "14.2234",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001028",
      "denominazione_ita_altra": "Borgaro Torinese",
      "denominazione_ita": "Borgaro Torinese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A990",
      "lat": "45.1519831",
      "lon": "7.6578944",
      "superficie_kmq": "11.1039",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001029",
      "denominazione_ita_altra": "Borgiallo",
      "denominazione_ita": "Borgiallo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B003",
      "lat": "45.4168178",
      "lon": "7.6677336",
      "superficie_kmq": "6.9648",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001030",
      "denominazione_ita_altra": "Borgofranco d'Ivrea",
      "denominazione_ita": "Borgofranco d'Ivrea",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B015",
      "lat": "45.5130128",
      "lon": "7.8585220",
      "superficie_kmq": "13.4189",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001031",
      "denominazione_ita_altra": "Borgomasino",
      "denominazione_ita": "Borgomasino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B021",
      "lat": "45.3615550",
      "lon": "7.9887996",
      "superficie_kmq": "12.3712",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001032",
      "denominazione_ita_altra": "Borgone Susa",
      "denominazione_ita": "Borgone Susa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B024",
      "lat": "45.1231397",
      "lon": "7.2412140",
      "superficie_kmq": "4.9617",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001033",
      "denominazione_ita_altra": "Bosconero",
      "denominazione_ita": "Bosconero",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B075",
      "lat": "45.2685663",
      "lon": "7.7650698",
      "superficie_kmq": "10.9190",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001034",
      "denominazione_ita_altra": "Brandizzo",
      "denominazione_ita": "Brandizzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B121",
      "lat": "45.1776535",
      "lon": "7.8414756",
      "superficie_kmq": "6.2914",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001035",
      "denominazione_ita_altra": "Bricherasio",
      "denominazione_ita": "Bricherasio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B171",
      "lat": "44.8255775",
      "lon": "7.3028877",
      "superficie_kmq": "22.7585",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001036",
      "denominazione_ita_altra": "Brosso",
      "denominazione_ita": "Brosso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B205",
      "lat": "45.4927657",
      "lon": "7.8033343",
      "superficie_kmq": "11.1350",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001037",
      "denominazione_ita_altra": "Brozolo",
      "denominazione_ita": "Brozolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B209",
      "lat": "45.1174194",
      "lon": "8.0712784",
      "superficie_kmq": "8.9504",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001038",
      "denominazione_ita_altra": "Bruino",
      "denominazione_ita": "Bruino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B216",
      "lat": "45.0202313",
      "lon": "7.4676911",
      "superficie_kmq": "5.5701",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001039",
      "denominazione_ita_altra": "Brusasco",
      "denominazione_ita": "Brusasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B225",
      "lat": "45.1559558",
      "lon": "8.0600942",
      "superficie_kmq": "14.3592",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001040",
      "denominazione_ita_altra": "Bruzolo",
      "denominazione_ita": "Bruzolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B232",
      "lat": "45.1431255",
      "lon": "7.1939769",
      "superficie_kmq": "12.5598",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001041",
      "denominazione_ita_altra": "Buriasco",
      "denominazione_ita": "Buriasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B278",
      "lat": "44.8733550",
      "lon": "7.4119230",
      "superficie_kmq": "14.6868",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001042",
      "denominazione_ita_altra": "Burolo",
      "denominazione_ita": "Burolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B279",
      "lat": "45.4841252",
      "lon": "7.9320566",
      "superficie_kmq": "5.4760",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001043",
      "denominazione_ita_altra": "Busano",
      "denominazione_ita": "Busano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B284",
      "lat": "45.3288398",
      "lon": "7.6557436",
      "superficie_kmq": "5.0591",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001044",
      "denominazione_ita_altra": "Bussoleno",
      "denominazione_ita": "Bussoleno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B297",
      "lat": "45.1372801",
      "lon": "7.1493187",
      "superficie_kmq": "37.0661",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001045",
      "denominazione_ita_altra": "Buttigliera Alta",
      "denominazione_ita": "Buttigliera Alta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B305",
      "lat": "45.0670146",
      "lon": "7.4367807",
      "superficie_kmq": "8.0973",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001046",
      "denominazione_ita_altra": "Cafasse",
      "denominazione_ita": "Cafasse",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B350",
      "lat": "45.2447136",
      "lon": "7.5181235",
      "superficie_kmq": "10.2312",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001047",
      "denominazione_ita_altra": "Caluso",
      "denominazione_ita": "Caluso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B435",
      "lat": "45.3078650",
      "lon": "7.8915053",
      "superficie_kmq": "39.4925",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001048",
      "denominazione_ita_altra": "Cambiano",
      "denominazione_ita": "Cambiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B462",
      "lat": "44.9734576",
      "lon": "7.7793248",
      "superficie_kmq": "14.1314",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001049",
      "denominazione_ita_altra": "Campiglione Fenile",
      "denominazione_ita": "Campiglione Fenile",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B512",
      "lat": "44.8021199",
      "lon": "7.3250382",
      "superficie_kmq": "11.0863",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001050",
      "denominazione_ita_altra": "Candia Canavese",
      "denominazione_ita": "Candia Canavese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B588",
      "lat": "45.3281713",
      "lon": "7.8866124",
      "superficie_kmq": "9.1318",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001051",
      "denominazione_ita_altra": "Candiolo",
      "denominazione_ita": "Candiolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B592",
      "lat": "44.9602633",
      "lon": "7.6012111",
      "superficie_kmq": "11.8499",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001052",
      "denominazione_ita_altra": "Canischio",
      "denominazione_ita": "Canischio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B605",
      "lat": "45.3743498",
      "lon": "7.5952182",
      "superficie_kmq": "11.9505",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001053",
      "denominazione_ita_altra": "Cantalupa",
      "denominazione_ita": "Cantalupa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B628",
      "lat": "44.9403073",
      "lon": "7.3326039",
      "superficie_kmq": "11.1953",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001054",
      "denominazione_ita_altra": "Cantoira",
      "denominazione_ita": "Cantoira",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B637",
      "lat": "45.3432391",
      "lon": "7.3816797",
      "superficie_kmq": "23.0319",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001055",
      "denominazione_ita_altra": "Caprie",
      "denominazione_ita": "Caprie",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B705",
      "lat": "45.1178654",
      "lon": "7.3325065",
      "superficie_kmq": "16.4078",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001056",
      "denominazione_ita_altra": "Caravino",
      "denominazione_ita": "Caravino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B733",
      "lat": "45.3986096",
      "lon": "7.9602135",
      "superficie_kmq": "11.5371",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001057",
      "denominazione_ita_altra": "Carema",
      "denominazione_ita": "Carema",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B762",
      "lat": "45.5844545",
      "lon": "7.8123043",
      "superficie_kmq": "10.2579",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001058",
      "denominazione_ita_altra": "Carignano",
      "denominazione_ita": "Carignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B777",
      "lat": "44.9066637",
      "lon": "7.6753431",
      "superficie_kmq": "50.6790",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001059",
      "denominazione_ita_altra": "Carmagnola",
      "denominazione_ita": "Carmagnola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B791",
      "lat": "44.8465448",
      "lon": "7.7181575",
      "superficie_kmq": "95.7148",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001060",
      "denominazione_ita_altra": "Casalborgone",
      "denominazione_ita": "Casalborgone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B867",
      "lat": "45.1299630",
      "lon": "7.9404405",
      "superficie_kmq": "20.1250",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001061",
      "denominazione_ita_altra": "Cascinette d'Ivrea",
      "denominazione_ita": "Cascinette d'Ivrea",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B953",
      "lat": "45.4784597",
      "lon": "7.9046983",
      "superficie_kmq": "2.1726",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001062",
      "denominazione_ita_altra": "Caselette",
      "denominazione_ita": "Caselette",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B955",
      "lat": "45.1058811",
      "lon": "7.4832072",
      "superficie_kmq": "14.3136",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001063",
      "denominazione_ita_altra": "Caselle Torinese",
      "denominazione_ita": "Caselle Torinese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B960",
      "lat": "45.1771666",
      "lon": "7.6481863",
      "superficie_kmq": "23.5513",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001064",
      "denominazione_ita_altra": "Castagneto Po",
      "denominazione_ita": "Castagneto Po",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C045",
      "lat": "45.1606091",
      "lon": "7.8869884",
      "superficie_kmq": "11.4732",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001065",
      "denominazione_ita_altra": "Castagnole Piemonte",
      "denominazione_ita": "Castagnole Piemonte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C048",
      "lat": "44.8986165",
      "lon": "7.5665563",
      "superficie_kmq": "17.2796",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001066",
      "denominazione_ita_altra": "Castellamonte",
      "denominazione_ita": "Castellamonte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C133",
      "lat": "45.3833395",
      "lon": "7.7098652",
      "superficie_kmq": "38.7063",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001067",
      "denominazione_ita_altra": "Castelnuovo Nigra",
      "denominazione_ita": "Castelnuovo Nigra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C241",
      "lat": "45.4373279",
      "lon": "7.6951142",
      "superficie_kmq": "28.3756",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001068",
      "denominazione_ita_altra": "Castiglione Torinese",
      "denominazione_ita": "Castiglione Torinese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C307",
      "lat": "45.1233094",
      "lon": "7.8160937",
      "superficie_kmq": "14.1345",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001069",
      "denominazione_ita_altra": "Cavagnolo",
      "denominazione_ita": "Cavagnolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C369",
      "lat": "45.1520751",
      "lon": "8.0513102",
      "superficie_kmq": "12.3285",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001070",
      "denominazione_ita_altra": "Cavour",
      "denominazione_ita": "Cavour",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C404",
      "lat": "44.7840265",
      "lon": "7.3752321",
      "superficie_kmq": "48.9591",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001071",
      "denominazione_ita_altra": "Cercenasco",
      "denominazione_ita": "Cercenasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C487",
      "lat": "44.8612571",
      "lon": "7.5012581",
      "superficie_kmq": "13.1595",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001072",
      "denominazione_ita_altra": "Ceres",
      "denominazione_ita": "Ceres",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C497",
      "lat": "45.3130821",
      "lon": "7.3893433",
      "superficie_kmq": "28.0516",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001073",
      "denominazione_ita_altra": "Ceresole Reale",
      "denominazione_ita": "Ceresole Reale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C505",
      "lat": "45.4326228",
      "lon": "7.2325410",
      "superficie_kmq": "99.8147",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001074",
      "denominazione_ita_altra": "Cesana Torinese",
      "denominazione_ita": "Cesana Torinese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C564",
      "lat": "44.9524088",
      "lon": "6.7942278",
      "superficie_kmq": "121.7001",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001075",
      "denominazione_ita_altra": "Chialamberto",
      "denominazione_ita": "Chialamberto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C604",
      "lat": "45.3628633",
      "lon": "7.3457007",
      "superficie_kmq": "35.4478",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001076",
      "denominazione_ita_altra": "Chianocco",
      "denominazione_ita": "Chianocco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C610",
      "lat": "45.1485865",
      "lon": "7.1693934",
      "superficie_kmq": "18.6134",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001077",
      "denominazione_ita_altra": "Chiaverano",
      "denominazione_ita": "Chiaverano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C624",
      "lat": "45.4994028",
      "lon": "7.9031274",
      "superficie_kmq": "12.0221",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001078",
      "denominazione_ita_altra": "Chieri",
      "denominazione_ita": "Chieri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C627",
      "lat": "45.0131517",
      "lon": "7.8210716",
      "superficie_kmq": "54.1975",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001079",
      "denominazione_ita_altra": "Chiesanuova",
      "denominazione_ita": "Chiesanuova",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C629",
      "lat": "45.4178128",
      "lon": "7.6560592",
      "superficie_kmq": "4.1189",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001080",
      "denominazione_ita_altra": "Chiomonte",
      "denominazione_ita": "Chiomonte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C639",
      "lat": "45.1193861",
      "lon": "6.9833861",
      "superficie_kmq": "26.7549",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001081",
      "denominazione_ita_altra": "Chiusa di San Michele",
      "denominazione_ita": "Chiusa di San Michele",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C655",
      "lat": "45.1015471",
      "lon": "7.3264449",
      "superficie_kmq": "5.9165",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001082",
      "denominazione_ita_altra": "Chivasso",
      "denominazione_ita": "Chivasso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C665",
      "lat": "45.1885564",
      "lon": "7.8871395",
      "superficie_kmq": "51.2378",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001083",
      "denominazione_ita_altra": "Ciconio",
      "denominazione_ita": "Ciconio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C679",
      "lat": "45.3303253",
      "lon": "7.7598721",
      "superficie_kmq": "3.1638",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001084",
      "denominazione_ita_altra": "Cintano",
      "denominazione_ita": "Cintano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C711",
      "lat": "45.4280550",
      "lon": "7.6866210",
      "superficie_kmq": "4.9345",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001085",
      "denominazione_ita_altra": "Cinzano",
      "denominazione_ita": "Cinzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C715",
      "lat": "45.0938984",
      "lon": "7.9211215",
      "superficie_kmq": "6.1962",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001086",
      "denominazione_ita_altra": "Ciriè",
      "denominazione_ita": "Ciriè",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C722",
      "lat": "45.2320252",
      "lon": "7.6037821",
      "superficie_kmq": "17.7329",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001087",
      "denominazione_ita_altra": "Claviere",
      "denominazione_ita": "Claviere",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C793",
      "lat": "44.9386158",
      "lon": "6.7493064",
      "superficie_kmq": "2.6872",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001088",
      "denominazione_ita_altra": "Coassolo Torinese",
      "denominazione_ita": "Coassolo Torinese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C801",
      "lat": "45.3007164",
      "lon": "7.4613293",
      "superficie_kmq": "27.8788",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001089",
      "denominazione_ita_altra": "Coazze",
      "denominazione_ita": "Coazze",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C803",
      "lat": "45.0514173",
      "lon": "7.2946436",
      "superficie_kmq": "56.5727",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001090",
      "denominazione_ita_altra": "Collegno",
      "denominazione_ita": "Collegno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C860",
      "lat": "45.0774011",
      "lon": "7.5691295",
      "superficie_kmq": "18.0993",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001091",
      "denominazione_ita_altra": "Colleretto Castelnuovo",
      "denominazione_ita": "Colleretto Castelnuovo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C867",
      "lat": "45.4214111",
      "lon": "7.6789816",
      "superficie_kmq": "6.3321",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001092",
      "denominazione_ita_altra": "Colleretto Giacosa",
      "denominazione_ita": "Colleretto Giacosa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C868",
      "lat": "45.4328929",
      "lon": "7.7999643",
      "superficie_kmq": "4.5894",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001093",
      "denominazione_ita_altra": "Condove",
      "denominazione_ita": "Condove",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C955",
      "lat": "45.1171436",
      "lon": "7.3107299",
      "superficie_kmq": "71.1131",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001094",
      "denominazione_ita_altra": "Corio",
      "denominazione_ita": "Corio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D008",
      "lat": "45.3127462",
      "lon": "7.5325615",
      "superficie_kmq": "41.4914",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001095",
      "denominazione_ita_altra": "Cossano Canavese",
      "denominazione_ita": "Cossano Canavese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D092",
      "lat": "45.3883351",
      "lon": "7.9933732",
      "superficie_kmq": "3.2384",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001096",
      "denominazione_ita_altra": "Cuceglio",
      "denominazione_ita": "Cuceglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D197",
      "lat": "45.3589326",
      "lon": "7.8148439",
      "superficie_kmq": "6.8709",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001097",
      "denominazione_ita_altra": "Cumiana",
      "denominazione_ita": "Cumiana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D202",
      "lat": "44.9827939",
      "lon": "7.3743768",
      "superficie_kmq": "60.7335",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001098",
      "denominazione_ita_altra": "Cuorgnè",
      "denominazione_ita": "Cuorgnè",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D208",
      "lat": "45.3912313",
      "lon": "7.6489149",
      "superficie_kmq": "19.3134",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001099",
      "denominazione_ita_altra": "Druento",
      "denominazione_ita": "Druento",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D373",
      "lat": "45.1347332",
      "lon": "7.5762042",
      "superficie_kmq": "27.5395",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001100",
      "denominazione_ita_altra": "Exilles",
      "denominazione_ita": "Exilles",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D433",
      "lat": "45.0974909",
      "lon": "6.9296242",
      "superficie_kmq": "46.5474",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001101",
      "denominazione_ita_altra": "Favria",
      "denominazione_ita": "Favria",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D520",
      "lat": "45.3328504",
      "lon": "7.6880143",
      "superficie_kmq": "14.8499",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001102",
      "denominazione_ita_altra": "Feletto",
      "denominazione_ita": "Feletto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D524",
      "lat": "45.3054940",
      "lon": "7.7471079",
      "superficie_kmq": "7.8931",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001103",
      "denominazione_ita_altra": "Fenestrelle",
      "denominazione_ita": "Fenestrelle",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D532",
      "lat": "45.0342952",
      "lon": "7.0518867",
      "superficie_kmq": "49.4091",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001104",
      "denominazione_ita_altra": "Fiano",
      "denominazione_ita": "Fiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D562",
      "lat": "45.2181230",
      "lon": "7.5197835",
      "superficie_kmq": "12.0415",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001105",
      "denominazione_ita_altra": "Fiorano Canavese",
      "denominazione_ita": "Fiorano Canavese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D608",
      "lat": "45.4675752",
      "lon": "7.8289773",
      "superficie_kmq": "4.3457",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001106",
      "denominazione_ita_altra": "Foglizzo",
      "denominazione_ita": "Foglizzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D646",
      "lat": "45.2720198",
      "lon": "7.8231787",
      "superficie_kmq": "15.6427",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001107",
      "denominazione_ita_altra": "Forno Canavese",
      "denominazione_ita": "Forno Canavese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D725",
      "lat": "45.3468407",
      "lon": "7.5867804",
      "superficie_kmq": "16.5003",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001108",
      "denominazione_ita_altra": "Frassinetto",
      "denominazione_ita": "Frassinetto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D781",
      "lat": "45.4360373",
      "lon": "7.6056789",
      "superficie_kmq": "24.8185",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001109",
      "denominazione_ita_altra": "Front",
      "denominazione_ita": "Front",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D805",
      "lat": "45.2795532",
      "lon": "7.6655965",
      "superficie_kmq": "10.9482",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001110",
      "denominazione_ita_altra": "Frossasco",
      "denominazione_ita": "Frossasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D812",
      "lat": "44.9336199",
      "lon": "7.3507705",
      "superficie_kmq": "20.1488",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001111",
      "denominazione_ita_altra": "Garzigliana",
      "denominazione_ita": "Garzigliana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D931",
      "lat": "44.8367717",
      "lon": "7.3747313",
      "superficie_kmq": "7.4018",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001112",
      "denominazione_ita_altra": "Gassino Torinese",
      "denominazione_ita": "Gassino Torinese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D933",
      "lat": "45.1274531",
      "lon": "7.8248966",
      "superficie_kmq": "20.5108",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001113",
      "denominazione_ita_altra": "Germagnano",
      "denominazione_ita": "Germagnano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D983",
      "lat": "45.2621833",
      "lon": "7.4668644",
      "superficie_kmq": "14.4442",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001114",
      "denominazione_ita_altra": "Giaglione",
      "denominazione_ita": "Giaglione",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E009",
      "lat": "45.1396886",
      "lon": "7.0141385",
      "superficie_kmq": "33.3833",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001115",
      "denominazione_ita_altra": "Giaveno",
      "denominazione_ita": "Giaveno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E020",
      "lat": "45.0447522",
      "lon": "7.3530865",
      "superficie_kmq": "71.7376",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001116",
      "denominazione_ita_altra": "Givoletto",
      "denominazione_ita": "Givoletto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E067",
      "lat": "45.1587801",
      "lon": "7.4967729",
      "superficie_kmq": "12.8199",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001117",
      "denominazione_ita_altra": "Gravere",
      "denominazione_ita": "Gravere",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E154",
      "lat": "45.1239188",
      "lon": "7.0210728",
      "superficie_kmq": "18.9870",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001118",
      "denominazione_ita_altra": "Groscavallo",
      "denominazione_ita": "Groscavallo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E199",
      "lat": "45.3689574",
      "lon": "7.2822951",
      "superficie_kmq": "92.0935",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001119",
      "denominazione_ita_altra": "Grosso",
      "denominazione_ita": "Grosso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E203",
      "lat": "45.2563694",
      "lon": "7.5566335",
      "superficie_kmq": "4.3296",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001120",
      "denominazione_ita_altra": "Grugliasco",
      "denominazione_ita": "Grugliasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E216",
      "lat": "45.0621103",
      "lon": "7.5778006",
      "superficie_kmq": "13.1321",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001121",
      "denominazione_ita_altra": "Ingria",
      "denominazione_ita": "Ingria",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E301",
      "lat": "45.4662964",
      "lon": "7.5708626",
      "superficie_kmq": "14.7506",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001122",
      "denominazione_ita_altra": "Inverso Pinasca",
      "denominazione_ita": "Inverso Pinasca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E311",
      "lat": "44.9472274",
      "lon": "7.2159640",
      "superficie_kmq": "8.0292",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001123",
      "denominazione_ita_altra": "Isolabella",
      "denominazione_ita": "Isolabella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E345",
      "lat": "44.9066417",
      "lon": "7.9092678",
      "superficie_kmq": "4.7716",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001124",
      "denominazione_ita_altra": "Issiglio",
      "denominazione_ita": "Issiglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E368",
      "lat": "45.4468144",
      "lon": "7.7541627",
      "superficie_kmq": "5.5042",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001125",
      "denominazione_ita_altra": "Ivrea",
      "denominazione_ita": "Ivrea",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E379",
      "lat": "45.4661941",
      "lon": "7.8759668",
      "superficie_kmq": "30.1075",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001126",
      "denominazione_ita_altra": "La Cassa",
      "denominazione_ita": "La Cassa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E394",
      "lat": "45.1818247",
      "lon": "7.5191300",
      "superficie_kmq": "12.0353",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001127",
      "denominazione_ita_altra": "La Loggia",
      "denominazione_ita": "La Loggia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E423",
      "lat": "44.9566759",
      "lon": "7.6677072",
      "superficie_kmq": "12.7929",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001128",
      "denominazione_ita_altra": "Lanzo Torinese",
      "denominazione_ita": "Lanzo Torinese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E445",
      "lat": "45.2723224",
      "lon": "7.4766155",
      "superficie_kmq": "10.2876",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001129",
      "denominazione_ita_altra": "Lauriano",
      "denominazione_ita": "Lauriano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E484",
      "lat": "45.1584557",
      "lon": "7.9916695",
      "superficie_kmq": "14.2900",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001130",
      "denominazione_ita_altra": "Leini",
      "denominazione_ita": "Leini",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E518",
      "lat": "45.1839381",
      "lon": "7.7151076",
      "superficie_kmq": "31.9191",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001131",
      "denominazione_ita_altra": "Lemie",
      "denominazione_ita": "Lemie",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E520",
      "lat": "45.2285585",
      "lon": "7.2928817",
      "superficie_kmq": "45.6753",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001132",
      "denominazione_ita_altra": "Lessolo",
      "denominazione_ita": "Lessolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E551",
      "lat": "45.4761616",
      "lon": "7.8142197",
      "superficie_kmq": "7.9366",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001133",
      "denominazione_ita_altra": "Levone",
      "denominazione_ita": "Levone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E566",
      "lat": "45.3181072",
      "lon": "7.6059006",
      "superficie_kmq": "5.3912",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001134",
      "denominazione_ita_altra": "Locana",
      "denominazione_ita": "Locana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E635",
      "lat": "45.4169972",
      "lon": "7.4612623",
      "superficie_kmq": "132.5173",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001135",
      "denominazione_ita_altra": "Lombardore",
      "denominazione_ita": "Lombardore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E660",
      "lat": "45.2357094",
      "lon": "7.7362669",
      "superficie_kmq": "12.7158",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001136",
      "denominazione_ita_altra": "Lombriasco",
      "denominazione_ita": "Lombriasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E661",
      "lat": "44.8415984",
      "lon": "7.6357788",
      "superficie_kmq": "7.2096",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001137",
      "denominazione_ita_altra": "Loranzè",
      "denominazione_ita": "Loranzè",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E683",
      "lat": "45.4422362",
      "lon": "7.8108710",
      "superficie_kmq": "4.1914",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001139",
      "denominazione_ita_altra": "Luserna San Giovanni",
      "denominazione_ita": "Luserna San Giovanni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E758",
      "lat": "44.8143175",
      "lon": "7.2457908",
      "superficie_kmq": "17.7414",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001140",
      "denominazione_ita_altra": "Lusernetta",
      "denominazione_ita": "Lusernetta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E759",
      "lat": "44.8023618",
      "lon": "7.2502360",
      "superficie_kmq": "7.0398",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001141",
      "denominazione_ita_altra": "Lusigliè",
      "denominazione_ita": "Lusigliè",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E763",
      "lat": "45.3185684",
      "lon": "7.7664666",
      "superficie_kmq": "5.2585",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001142",
      "denominazione_ita_altra": "Macello",
      "denominazione_ita": "Macello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E782",
      "lat": "44.8511751",
      "lon": "7.4007927",
      "superficie_kmq": "14.1404",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001143",
      "denominazione_ita_altra": "Maglione",
      "denominazione_ita": "Maglione",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E817",
      "lat": "45.3477069",
      "lon": "8.0117445",
      "superficie_kmq": "6.3129",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001144",
      "denominazione_ita_altra": "Marentino",
      "denominazione_ita": "Marentino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E941",
      "lat": "45.0580041",
      "lon": "7.8733297",
      "superficie_kmq": "11.2593",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001145",
      "denominazione_ita_altra": "Massello",
      "denominazione_ita": "Massello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F041",
      "lat": "44.9585595",
      "lon": "7.0563128",
      "superficie_kmq": "38.2587",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001146",
      "denominazione_ita_altra": "Mathi",
      "denominazione_ita": "Mathi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F053",
      "lat": "45.2566436",
      "lon": "7.5413209",
      "superficie_kmq": "7.0706",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001147",
      "denominazione_ita_altra": "Mattie",
      "denominazione_ita": "Mattie",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F058",
      "lat": "45.1187055",
      "lon": "7.1121519",
      "superficie_kmq": "28.6942",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001148",
      "denominazione_ita_altra": "Mazzè",
      "denominazione_ita": "Mazzè",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F067",
      "lat": "45.2985390",
      "lon": "7.9370016",
      "superficie_kmq": "27.3424",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001149",
      "denominazione_ita_altra": "Meana di Susa",
      "denominazione_ita": "Meana di Susa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F074",
      "lat": "45.1207336",
      "lon": "7.0681407",
      "superficie_kmq": "16.5439",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001150",
      "denominazione_ita_altra": "Mercenasco",
      "denominazione_ita": "Mercenasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F140",
      "lat": "45.3569822",
      "lon": "7.8825668",
      "superficie_kmq": "12.6383",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001152",
      "denominazione_ita_altra": "Mezzenile",
      "denominazione_ita": "Mezzenile",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F182",
      "lat": "45.2924068",
      "lon": "7.3950199",
      "superficie_kmq": "29.0871",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001153",
      "denominazione_ita_altra": "Mombello di Torino",
      "denominazione_ita": "Mombello di Torino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F315",
      "lat": "45.0463992",
      "lon": "7.9195483",
      "superficie_kmq": "4.0825",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001154",
      "denominazione_ita_altra": "Mompantero",
      "denominazione_ita": "Mompantero",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F318",
      "lat": "45.1399072",
      "lon": "7.0579733",
      "superficie_kmq": "30.3332",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001155",
      "denominazione_ita_altra": "Monastero di Lanzo",
      "denominazione_ita": "Monastero di Lanzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F327",
      "lat": "45.3014274",
      "lon": "7.4391322",
      "superficie_kmq": "17.6554",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001156",
      "denominazione_ita_altra": "Moncalieri",
      "denominazione_ita": "Moncalieri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F335",
      "lat": "45.0009240",
      "lon": "7.6852479",
      "superficie_kmq": "47.5288",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001157",
      "denominazione_ita_altra": "Moncenisio",
      "denominazione_ita": "Moncenisio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D553",
      "lat": "45.2052902",
      "lon": "6.9835609",
      "superficie_kmq": "4.5007",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001158",
      "denominazione_ita_altra": "Montaldo Torinese",
      "denominazione_ita": "Montaldo Torinese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F407",
      "lat": "45.0663638",
      "lon": "7.8508936",
      "superficie_kmq": "4.6567",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001159",
      "denominazione_ita_altra": "Montalenghe",
      "denominazione_ita": "Montalenghe",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F411",
      "lat": "45.3377263",
      "lon": "7.8398563",
      "superficie_kmq": "6.4681",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001160",
      "denominazione_ita_altra": "Montalto Dora",
      "denominazione_ita": "Montalto Dora",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F420",
      "lat": "45.4936297",
      "lon": "7.8637946",
      "superficie_kmq": "7.3567",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001161",
      "denominazione_ita_altra": "Montanaro",
      "denominazione_ita": "Montanaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F422",
      "lat": "45.2328961",
      "lon": "7.8528256",
      "superficie_kmq": "20.9036",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001162",
      "denominazione_ita_altra": "Monteu da Po",
      "denominazione_ita": "Monteu da Po",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F651",
      "lat": "45.1505427",
      "lon": "8.0147845",
      "superficie_kmq": "7.3896",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001163",
      "denominazione_ita_altra": "Moriondo Torinese",
      "denominazione_ita": "Moriondo Torinese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F733",
      "lat": "45.0390614",
      "lon": "7.9440556",
      "superficie_kmq": "6.4895",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001164",
      "denominazione_ita_altra": "Nichelino",
      "denominazione_ita": "Nichelino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F889",
      "lat": "44.9951889",
      "lon": "7.6473727",
      "superficie_kmq": "20.5627",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001165",
      "denominazione_ita_altra": "Noasca",
      "denominazione_ita": "Noasca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F906",
      "lat": "45.4530560",
      "lon": "7.3135435",
      "superficie_kmq": "78.0481",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001166",
      "denominazione_ita_altra": "Nole",
      "denominazione_ita": "Nole",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F925",
      "lat": "45.2452388",
      "lon": "7.5701212",
      "superficie_kmq": "11.3530",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001167",
      "denominazione_ita_altra": "Nomaglio",
      "denominazione_ita": "Nomaglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F927",
      "lat": "45.5351887",
      "lon": "7.8597152",
      "superficie_kmq": "3.0743",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001168",
      "denominazione_ita_altra": "None",
      "denominazione_ita": "None",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F931",
      "lat": "44.9323104",
      "lon": "7.5412396",
      "superficie_kmq": "24.6422",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001169",
      "denominazione_ita_altra": "Novalesa",
      "denominazione_ita": "Novalesa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F948",
      "lat": "45.1876373",
      "lon": "7.0146657",
      "superficie_kmq": "28.5542",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001170",
      "denominazione_ita_altra": "Oglianico",
      "denominazione_ita": "Oglianico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G010",
      "lat": "45.3425704",
      "lon": "7.6927438",
      "superficie_kmq": "6.3030",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001171",
      "denominazione_ita_altra": "Orbassano",
      "denominazione_ita": "Orbassano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G087",
      "lat": "45.0070316",
      "lon": "7.5369568",
      "superficie_kmq": "22.2078",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001172",
      "denominazione_ita_altra": "Orio Canavese",
      "denominazione_ita": "Orio Canavese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G109",
      "lat": "45.3284928",
      "lon": "7.8602996",
      "superficie_kmq": "7.1541",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001173",
      "denominazione_ita_altra": "Osasco",
      "denominazione_ita": "Osasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G151",
      "lat": "44.8494709",
      "lon": "7.3431663",
      "superficie_kmq": "5.4885",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001174",
      "denominazione_ita_altra": "Osasio",
      "denominazione_ita": "Osasio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G152",
      "lat": "44.8694593",
      "lon": "7.6075282",
      "superficie_kmq": "4.5771",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001175",
      "denominazione_ita_altra": "Oulx",
      "denominazione_ita": "Oulx",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G196",
      "lat": "45.0312711",
      "lon": "6.8322188",
      "superficie_kmq": "99.7857",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001176",
      "denominazione_ita_altra": "Ozegna",
      "denominazione_ita": "Ozegna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G202",
      "lat": "45.3490184",
      "lon": "7.7431342",
      "superficie_kmq": "5.4058",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001177",
      "denominazione_ita_altra": "Palazzo Canavese",
      "denominazione_ita": "Palazzo Canavese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G262",
      "lat": "45.4574832",
      "lon": "7.9780494",
      "superficie_kmq": "5.0819",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001178",
      "denominazione_ita_altra": "Pancalieri",
      "denominazione_ita": "Pancalieri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G303",
      "lat": "44.8304699",
      "lon": "7.5881618",
      "superficie_kmq": "15.8878",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001179",
      "denominazione_ita_altra": "Parella",
      "denominazione_ita": "Parella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G330",
      "lat": "45.4299900",
      "lon": "7.7910274",
      "superficie_kmq": "2.6886",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001180",
      "denominazione_ita_altra": "Pavarolo",
      "denominazione_ita": "Pavarolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G387",
      "lat": "45.0689255",
      "lon": "7.8347580",
      "superficie_kmq": "4.4056",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001181",
      "denominazione_ita_altra": "Pavone Canavese",
      "denominazione_ita": "Pavone Canavese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G392",
      "lat": "45.4352660",
      "lon": "7.8539760",
      "superficie_kmq": "11.5400",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001183",
      "denominazione_ita_altra": "Pecetto Torinese",
      "denominazione_ita": "Pecetto Torinese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G398",
      "lat": "45.0195573",
      "lon": "7.7486999",
      "superficie_kmq": "9.1730",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001184",
      "denominazione_ita_altra": "Perosa Argentina",
      "denominazione_ita": "Perosa Argentina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G463",
      "lat": "44.9580970",
      "lon": "7.1923703",
      "superficie_kmq": "26.0906",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001185",
      "denominazione_ita_altra": "Perosa Canavese",
      "denominazione_ita": "Perosa Canavese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G462",
      "lat": "45.3971612",
      "lon": "7.8292559",
      "superficie_kmq": "4.7080",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001186",
      "denominazione_ita_altra": "Perrero",
      "denominazione_ita": "Perrero",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G465",
      "lat": "44.9374546",
      "lon": "7.1152671",
      "superficie_kmq": "63.1830",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001187",
      "denominazione_ita_altra": "Pertusio",
      "denominazione_ita": "Pertusio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G477",
      "lat": "45.3534027",
      "lon": "7.6387241",
      "superficie_kmq": "4.0041",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001188",
      "denominazione_ita_altra": "Pessinetto",
      "denominazione_ita": "Pessinetto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G505",
      "lat": "45.2905460",
      "lon": "7.4028471",
      "superficie_kmq": "5.3498",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001189",
      "denominazione_ita_altra": "Pianezza",
      "denominazione_ita": "Pianezza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G559",
      "lat": "45.1006232",
      "lon": "7.5463576",
      "superficie_kmq": "16.4634",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001190",
      "denominazione_ita_altra": "Pinasca",
      "denominazione_ita": "Pinasca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G672",
      "lat": "44.9426596",
      "lon": "7.2276729",
      "superficie_kmq": "34.7183",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001191",
      "denominazione_ita_altra": "Pinerolo",
      "denominazione_ita": "Pinerolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G674",
      "lat": "44.8873317",
      "lon": "7.3320011",
      "superficie_kmq": "50.3376",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001192",
      "denominazione_ita_altra": "Pino Torinese",
      "denominazione_ita": "Pino Torinese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G678",
      "lat": "45.0435191",
      "lon": "7.7721652",
      "superficie_kmq": "21.8196",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001193",
      "denominazione_ita_altra": "Piobesi Torinese",
      "denominazione_ita": "Piobesi Torinese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G684",
      "lat": "44.9330545",
      "lon": "7.6119311",
      "superficie_kmq": "19.6488",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001194",
      "denominazione_ita_altra": "Piossasco",
      "denominazione_ita": "Piossasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G691",
      "lat": "44.9906984",
      "lon": "7.4627552",
      "superficie_kmq": "40.1459",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001195",
      "denominazione_ita_altra": "Piscina",
      "denominazione_ita": "Piscina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G705",
      "lat": "44.9194615",
      "lon": "7.4258975",
      "superficie_kmq": "9.9034",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001196",
      "denominazione_ita_altra": "Piverone",
      "denominazione_ita": "Piverone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G719",
      "lat": "45.4475488",
      "lon": "8.0071026",
      "superficie_kmq": "11.0343",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001197",
      "denominazione_ita_altra": "Poirino",
      "denominazione_ita": "Poirino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G777",
      "lat": "44.9211961",
      "lon": "7.8436194",
      "superficie_kmq": "75.6186",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001198",
      "denominazione_ita_altra": "Pomaretto",
      "denominazione_ita": "Pomaretto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G805",
      "lat": "44.9550641",
      "lon": "7.1822031",
      "superficie_kmq": "8.5611",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001199",
      "denominazione_ita_altra": "Pont Canavese",
      "denominazione_ita": "Pont Canavese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G826",
      "lat": "45.4207331",
      "lon": "7.5935708",
      "superficie_kmq": "19.0609",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001200",
      "denominazione_ita_altra": "Porte",
      "denominazione_ita": "Porte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G900",
      "lat": "44.8875621",
      "lon": "7.2679852",
      "superficie_kmq": "4.4476",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001201",
      "denominazione_ita_altra": "Pragelato",
      "denominazione_ita": "Pragelato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G973",
      "lat": "45.0162744",
      "lon": "6.9422934",
      "superficie_kmq": "89.2017",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001202",
      "denominazione_ita_altra": "Prali",
      "denominazione_ita": "Prali",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G978",
      "lat": "44.8904046",
      "lon": "7.0490437",
      "superficie_kmq": "72.6092",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001203",
      "denominazione_ita_altra": "Pralormo",
      "denominazione_ita": "Pralormo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G979",
      "lat": "44.8613542",
      "lon": "7.9006632",
      "superficie_kmq": "29.8455",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001204",
      "denominazione_ita_altra": "Pramollo",
      "denominazione_ita": "Pramollo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G982",
      "lat": "44.9045853",
      "lon": "7.2116986",
      "superficie_kmq": "22.4785",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001205",
      "denominazione_ita_altra": "Prarostino",
      "denominazione_ita": "Prarostino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G986",
      "lat": "44.8658369",
      "lon": "7.2697215",
      "superficie_kmq": "10.5052",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001206",
      "denominazione_ita_altra": "Prascorsano",
      "denominazione_ita": "Prascorsano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G988",
      "lat": "45.3676604",
      "lon": "7.6157085",
      "superficie_kmq": "6.2419",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001207",
      "denominazione_ita_altra": "Pratiglione",
      "denominazione_ita": "Pratiglione",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G997",
      "lat": "45.3536517",
      "lon": "7.5960906",
      "superficie_kmq": "7.8803",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001208",
      "denominazione_ita_altra": "Quagliuzzo",
      "denominazione_ita": "Quagliuzzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H100",
      "lat": "45.4264997",
      "lon": "7.7818365",
      "superficie_kmq": "2.0380",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001209",
      "denominazione_ita_altra": "Quassolo",
      "denominazione_ita": "Quassolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H120",
      "lat": "45.5249408",
      "lon": "7.8324759",
      "superficie_kmq": "3.9559",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001210",
      "denominazione_ita_altra": "Quincinetto",
      "denominazione_ita": "Quincinetto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H127",
      "lat": "45.5608176",
      "lon": "7.8057688",
      "superficie_kmq": "17.7879",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001211",
      "denominazione_ita_altra": "Reano",
      "denominazione_ita": "Reano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H207",
      "lat": "45.0517062",
      "lon": "7.4295540",
      "superficie_kmq": "6.6658",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001212",
      "denominazione_ita_altra": "Ribordone",
      "denominazione_ita": "Ribordone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H270",
      "lat": "45.4316138",
      "lon": "7.5024190",
      "superficie_kmq": "43.6002",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001213",
      "denominazione_ita_altra": "Rivalba",
      "denominazione_ita": "Rivalba",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H333",
      "lat": "45.1188065",
      "lon": "7.8870447",
      "superficie_kmq": "10.8334",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001214",
      "denominazione_ita_altra": "Rivalta di Torino",
      "denominazione_ita": "Rivalta di Torino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H335",
      "lat": "45.0345441",
      "lon": "7.5232926",
      "superficie_kmq": "25.1076",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001215",
      "denominazione_ita_altra": "Riva presso Chieri",
      "denominazione_ita": "Riva presso Chieri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H337",
      "lat": "44.9862792",
      "lon": "7.8691993",
      "superficie_kmq": "35.8318",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001216",
      "denominazione_ita_altra": "Rivara",
      "denominazione_ita": "Rivara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H338",
      "lat": "45.3323186",
      "lon": "7.6250614",
      "superficie_kmq": "12.5838",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001217",
      "denominazione_ita_altra": "Rivarolo Canavese",
      "denominazione_ita": "Rivarolo Canavese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H340",
      "lat": "45.3327982",
      "lon": "7.7252212",
      "superficie_kmq": "32.2459",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001218",
      "denominazione_ita_altra": "Rivarossa",
      "denominazione_ita": "Rivarossa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H344",
      "lat": "45.2512811",
      "lon": "7.7171996",
      "superficie_kmq": "10.8733",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001219",
      "denominazione_ita_altra": "Rivoli",
      "denominazione_ita": "Rivoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H355",
      "lat": "45.0706280",
      "lon": "7.5339449",
      "superficie_kmq": "29.4998",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001220",
      "denominazione_ita_altra": "Robassomero",
      "denominazione_ita": "Robassomero",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H367",
      "lat": "45.2000840",
      "lon": "7.5701592",
      "superficie_kmq": "8.5756",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001221",
      "denominazione_ita_altra": "Rocca Canavese",
      "denominazione_ita": "Rocca Canavese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H386",
      "lat": "45.3094102",
      "lon": "7.5785370",
      "superficie_kmq": "14.1912",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001222",
      "denominazione_ita_altra": "Roletto",
      "denominazione_ita": "Roletto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H498",
      "lat": "44.9252466",
      "lon": "7.3306684",
      "superficie_kmq": "9.7039",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001223",
      "denominazione_ita_altra": "Romano Canavese",
      "denominazione_ita": "Romano Canavese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H511",
      "lat": "45.3913474",
      "lon": "7.8679044",
      "superficie_kmq": "11.2106",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001224",
      "denominazione_ita_altra": "Ronco Canavese",
      "denominazione_ita": "Ronco Canavese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H539",
      "lat": "45.4994349",
      "lon": "7.5456723",
      "superficie_kmq": "96.2686",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001225",
      "denominazione_ita_altra": "Rondissone",
      "denominazione_ita": "Rondissone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H547",
      "lat": "45.2467900",
      "lon": "7.9658163",
      "superficie_kmq": "10.6877",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001226",
      "denominazione_ita_altra": "Rorà",
      "denominazione_ita": "Rorà",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H554",
      "lat": "44.7923004",
      "lon": "7.1984925",
      "superficie_kmq": "12.4075",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001227",
      "denominazione_ita_altra": "Roure",
      "denominazione_ita": "Roure",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H555",
      "lat": "45.0010825",
      "lon": "7.1290852",
      "superficie_kmq": "59.3732",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001228",
      "denominazione_ita_altra": "Rosta",
      "denominazione_ita": "Rosta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H583",
      "lat": "45.0714196",
      "lon": "7.4639672",
      "superficie_kmq": "9.0650",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001229",
      "denominazione_ita_altra": "Rubiana",
      "denominazione_ita": "Rubiana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H627",
      "lat": "45.1392048",
      "lon": "7.3823984",
      "superficie_kmq": "26.9371",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001230",
      "denominazione_ita_altra": "Rueglio",
      "denominazione_ita": "Rueglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H631",
      "lat": "45.4678542",
      "lon": "7.7558219",
      "superficie_kmq": "15.1002",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001231",
      "denominazione_ita_altra": "Salassa",
      "denominazione_ita": "Salassa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H691",
      "lat": "45.3595602",
      "lon": "7.6893918",
      "superficie_kmq": "4.9519",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001232",
      "denominazione_ita_altra": "Salbertrand",
      "denominazione_ita": "Salbertrand",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H684",
      "lat": "45.0723713",
      "lon": "6.8836881",
      "superficie_kmq": "38.3198",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001233",
      "denominazione_ita_altra": "Salerano Canavese",
      "denominazione_ita": "Salerano Canavese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H702",
      "lat": "45.4564477",
      "lon": "7.8499024",
      "superficie_kmq": "2.1034",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001234",
      "denominazione_ita_altra": "Salza di Pinerolo",
      "denominazione_ita": "Salza di Pinerolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H734",
      "lat": "44.9405416",
      "lon": "7.0532450",
      "superficie_kmq": "15.8859",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001235",
      "denominazione_ita_altra": "Samone",
      "denominazione_ita": "Samone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H753",
      "lat": "45.4492906",
      "lon": "7.8456432",
      "superficie_kmq": "2.4330",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001236",
      "denominazione_ita_altra": "San Benigno Canavese",
      "denominazione_ita": "San Benigno Canavese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H775",
      "lat": "45.2239633",
      "lon": "7.7826551",
      "superficie_kmq": "22.2318",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001237",
      "denominazione_ita_altra": "San Carlo Canavese",
      "denominazione_ita": "San Carlo Canavese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H789",
      "lat": "45.2481552",
      "lon": "7.6101615",
      "superficie_kmq": "20.9068",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001238",
      "denominazione_ita_altra": "San Colombano Belmonte",
      "denominazione_ita": "San Colombano Belmonte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H804",
      "lat": "45.3814438",
      "lon": "7.6184510",
      "superficie_kmq": "3.3809",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001239",
      "denominazione_ita_altra": "San Didero",
      "denominazione_ita": "San Didero",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H820",
      "lat": "45.1351631",
      "lon": "7.2151550",
      "superficie_kmq": "3.3037",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001240",
      "denominazione_ita_altra": "San Francesco al Campo",
      "denominazione_ita": "San Francesco al Campo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H847",
      "lat": "45.2255394",
      "lon": "7.6556646",
      "superficie_kmq": "14.9796",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001241",
      "denominazione_ita_altra": "Sangano",
      "denominazione_ita": "Sangano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H855",
      "lat": "45.0262347",
      "lon": "7.4505386",
      "superficie_kmq": "6.6513",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001242",
      "denominazione_ita_altra": "San Germano Chisone",
      "denominazione_ita": "San Germano Chisone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H862",
      "lat": "44.8987128",
      "lon": "7.2408566",
      "superficie_kmq": "15.8616",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001243",
      "denominazione_ita_altra": "San Gillio",
      "denominazione_ita": "San Gillio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H873",
      "lat": "45.1420605",
      "lon": "7.5338325",
      "superficie_kmq": "8.8866",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001244",
      "denominazione_ita_altra": "San Giorgio Canavese",
      "denominazione_ita": "San Giorgio Canavese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H890",
      "lat": "45.3354473",
      "lon": "7.7942594",
      "superficie_kmq": "20.4049",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001245",
      "denominazione_ita_altra": "San Giorio di Susa",
      "denominazione_ita": "San Giorio di Susa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H900",
      "lat": "45.1284155",
      "lon": "7.1775315",
      "superficie_kmq": "19.7366",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001246",
      "denominazione_ita_altra": "San Giusto Canavese",
      "denominazione_ita": "San Giusto Canavese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H936",
      "lat": "45.3122172",
      "lon": "7.8083334",
      "superficie_kmq": "9.6132",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001247",
      "denominazione_ita_altra": "San Martino Canavese",
      "denominazione_ita": "San Martino Canavese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H997",
      "lat": "45.3945985",
      "lon": "7.8183624",
      "superficie_kmq": "9.7933",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001248",
      "denominazione_ita_altra": "San Maurizio Canavese",
      "denominazione_ita": "San Maurizio Canavese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I024",
      "lat": "45.2191199",
      "lon": "7.6307530",
      "superficie_kmq": "17.3442",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001249",
      "denominazione_ita_altra": "San Mauro Torinese",
      "denominazione_ita": "San Mauro Torinese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I030",
      "lat": "45.1030953",
      "lon": "7.7663283",
      "superficie_kmq": "12.5517",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001250",
      "denominazione_ita_altra": "San Pietro Val Lemina",
      "denominazione_ita": "San Pietro Val Lemina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I090",
      "lat": "44.9073254",
      "lon": "7.3135553",
      "superficie_kmq": "12.4446",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001251",
      "denominazione_ita_altra": "San Ponso",
      "denominazione_ita": "San Ponso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I126",
      "lat": "45.3508201",
      "lon": "7.6675166",
      "superficie_kmq": "2.1243",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001252",
      "denominazione_ita_altra": "San Raffaele Cimena",
      "denominazione_ita": "San Raffaele Cimena",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I137",
      "lat": "45.1472256",
      "lon": "7.8486967",
      "superficie_kmq": "11.1520",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001253",
      "denominazione_ita_altra": "San Sebastiano da Po",
      "denominazione_ita": "San Sebastiano da Po",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I152",
      "lat": "45.1664428",
      "lon": "7.9534693",
      "superficie_kmq": "16.5766",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001254",
      "denominazione_ita_altra": "San Secondo di Pinerolo",
      "denominazione_ita": "San Secondo di Pinerolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I154",
      "lat": "44.8665626",
      "lon": "7.2986478",
      "superficie_kmq": "12.5719",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001255",
      "denominazione_ita_altra": "Sant'Ambrogio di Torino",
      "denominazione_ita": "Sant'Ambrogio di Torino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I258",
      "lat": "45.0996877",
      "lon": "7.3593131",
      "superficie_kmq": "8.3683",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001256",
      "denominazione_ita_altra": "Sant'Antonino di Susa",
      "denominazione_ita": "Sant'Antonino di Susa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I296",
      "lat": "45.1070831",
      "lon": "7.2735097",
      "superficie_kmq": "9.7945",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001257",
      "denominazione_ita_altra": "Santena",
      "denominazione_ita": "Santena",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I327",
      "lat": "44.9496848",
      "lon": "7.7731601",
      "superficie_kmq": "16.1983",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001258",
      "denominazione_ita_altra": "Sauze di Cesana",
      "denominazione_ita": "Sauze di Cesana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I465",
      "lat": "44.9404442",
      "lon": "6.8604972",
      "superficie_kmq": "78.2772",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001259",
      "denominazione_ita_altra": "Sauze d'Oulx",
      "denominazione_ita": "Sauze d'Oulx",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I466",
      "lat": "45.0280858",
      "lon": "6.8582494",
      "superficie_kmq": "17.3112",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001260",
      "denominazione_ita_altra": "Scalenghe",
      "denominazione_ita": "Scalenghe",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I490",
      "lat": "44.8883060",
      "lon": "7.4979654",
      "superficie_kmq": "31.6768",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001261",
      "denominazione_ita_altra": "Scarmagno",
      "denominazione_ita": "Scarmagno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I511",
      "lat": "45.3858304",
      "lon": "7.8407105",
      "superficie_kmq": "8.0308",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001262",
      "denominazione_ita_altra": "Sciolze",
      "denominazione_ita": "Sciolze",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I539",
      "lat": "45.0950363",
      "lon": "7.8776624",
      "superficie_kmq": "11.3557",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001263",
      "denominazione_ita_altra": "Sestriere",
      "denominazione_ita": "Sestriere",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I692",
      "lat": "44.9576468",
      "lon": "6.8765512",
      "superficie_kmq": "25.9158",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001264",
      "denominazione_ita_altra": "Settimo Rottaro",
      "denominazione_ita": "Settimo Rottaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I701",
      "lat": "45.4049042",
      "lon": "7.9922308",
      "superficie_kmq": "6.0578",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001265",
      "denominazione_ita_altra": "Settimo Torinese",
      "denominazione_ita": "Settimo Torinese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I703",
      "lat": "45.1369893",
      "lon": "7.7712401",
      "superficie_kmq": "31.4505",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001266",
      "denominazione_ita_altra": "Settimo Vittone",
      "denominazione_ita": "Settimo Vittone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I702",
      "lat": "45.5481131",
      "lon": "7.8317209",
      "superficie_kmq": "23.2604",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001267",
      "denominazione_ita_altra": "Sparone",
      "denominazione_ita": "Sparone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I886",
      "lat": "45.4133052",
      "lon": "7.5442141",
      "superficie_kmq": "29.6785",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001268",
      "denominazione_ita_altra": "Strambinello",
      "denominazione_ita": "Strambinello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I969",
      "lat": "45.4232477",
      "lon": "7.7686620",
      "superficie_kmq": "2.2140",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001269",
      "denominazione_ita_altra": "Strambino",
      "denominazione_ita": "Strambino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I970",
      "lat": "45.3845912",
      "lon": "7.8806282",
      "superficie_kmq": "22.4686",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001270",
      "denominazione_ita_altra": "Susa",
      "denominazione_ita": "Susa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L013",
      "lat": "45.1352513",
      "lon": "7.0464196",
      "superficie_kmq": "10.9879",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001271",
      "denominazione_ita_altra": "Tavagnasco",
      "denominazione_ita": "Tavagnasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L066",
      "lat": "45.5432265",
      "lon": "7.8226103",
      "superficie_kmq": "8.6751",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001272",
      "denominazione_ita_altra": "Torino",
      "denominazione_ita": "Torino",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "L219",
      "lat": "45.0732740",
      "lon": "7.6806875",
      "superficie_kmq": "130.0645",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001273",
      "denominazione_ita_altra": "Torrazza Piemonte",
      "denominazione_ita": "Torrazza Piemonte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L238",
      "lat": "45.2152115",
      "lon": "7.9749012",
      "superficie_kmq": "9.8238",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001274",
      "denominazione_ita_altra": "Torre Canavese",
      "denominazione_ita": "Torre Canavese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L247",
      "lat": "45.3918288",
      "lon": "7.7605391",
      "superficie_kmq": "5.4545",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001275",
      "denominazione_ita_altra": "Torre Pellice",
      "denominazione_ita": "Torre Pellice",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L277",
      "lat": "44.8207373",
      "lon": "7.2239787",
      "superficie_kmq": "21.0981",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001276",
      "denominazione_ita_altra": "Trana",
      "denominazione_ita": "Trana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L327",
      "lat": "45.0370941",
      "lon": "7.4173032",
      "superficie_kmq": "16.4059",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001278",
      "denominazione_ita_altra": "Traversella",
      "denominazione_ita": "Traversella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L345",
      "lat": "45.5094155",
      "lon": "7.7504099",
      "superficie_kmq": "39.3597",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001279",
      "denominazione_ita_altra": "Traves",
      "denominazione_ita": "Traves",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L340",
      "lat": "45.2705812",
      "lon": "7.4297477",
      "superficie_kmq": "10.4544",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001280",
      "denominazione_ita_altra": "Trofarello",
      "denominazione_ita": "Trofarello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L445",
      "lat": "44.9859626",
      "lon": "7.7403033",
      "superficie_kmq": "12.3475",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001281",
      "denominazione_ita_altra": "Usseaux",
      "denominazione_ita": "Usseaux",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L515",
      "lat": "45.0484973",
      "lon": "7.0268735",
      "superficie_kmq": "37.9669",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001282",
      "denominazione_ita_altra": "Usseglio",
      "denominazione_ita": "Usseglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L516",
      "lat": "45.2327269",
      "lon": "7.2172202",
      "superficie_kmq": "98.5375",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001283",
      "denominazione_ita_altra": "Vaie",
      "denominazione_ita": "Vaie",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L538",
      "lat": "45.1018931",
      "lon": "7.2866307",
      "superficie_kmq": "7.2292",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001284",
      "denominazione_ita_altra": "Val della Torre",
      "denominazione_ita": "Val della Torre",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L555",
      "lat": "45.1555656",
      "lon": "7.4494114",
      "superficie_kmq": "36.5253",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001285",
      "denominazione_ita_altra": "Valgioie",
      "denominazione_ita": "Valgioie",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L578",
      "lat": "45.0760079",
      "lon": "7.3396396",
      "superficie_kmq": "9.1200",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001286",
      "denominazione_ita_altra": "Vallo Torinese",
      "denominazione_ita": "Vallo Torinese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L629",
      "lat": "45.2235028",
      "lon": "7.4992892",
      "superficie_kmq": "6.0792",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001287",
      "denominazione_ita_altra": "Valperga",
      "denominazione_ita": "Valperga",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L644",
      "lat": "45.3702749",
      "lon": "7.6567870",
      "superficie_kmq": "11.9094",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001288",
      "denominazione_ita_altra": "Valprato Soana",
      "denominazione_ita": "Valprato Soana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B510",
      "lat": "45.5223800",
      "lon": "7.5489663",
      "superficie_kmq": "71.8527",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001289",
      "denominazione_ita_altra": "Varisella",
      "denominazione_ita": "Varisella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L685",
      "lat": "45.2098424",
      "lon": "7.4890253",
      "superficie_kmq": "22.5579",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001290",
      "denominazione_ita_altra": "Vauda Canavese",
      "denominazione_ita": "Vauda Canavese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L698",
      "lat": "45.2792452",
      "lon": "7.6182078",
      "superficie_kmq": "7.0929",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001291",
      "denominazione_ita_altra": "Venaus",
      "denominazione_ita": "Venaus",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L726",
      "lat": "45.1547026",
      "lon": "7.0119477",
      "superficie_kmq": "19.1435",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001292",
      "denominazione_ita_altra": "Venaria Reale",
      "denominazione_ita": "Venaria Reale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L727",
      "lat": "45.1325378",
      "lon": "7.6314973",
      "superficie_kmq": "20.4424",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001293",
      "denominazione_ita_altra": "Verolengo",
      "denominazione_ita": "Verolengo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L779",
      "lat": "45.1890505",
      "lon": "7.9671578",
      "superficie_kmq": "29.4882",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001294",
      "denominazione_ita_altra": "Verrua Savoia",
      "denominazione_ita": "Verrua Savoia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L787",
      "lat": "45.1565508",
      "lon": "8.0931476",
      "superficie_kmq": "31.9431",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001295",
      "denominazione_ita_altra": "Vestignè",
      "denominazione_ita": "Vestignè",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L811",
      "lat": "45.3867057",
      "lon": "7.9538786",
      "superficie_kmq": "12.0691",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001296",
      "denominazione_ita_altra": "Vialfrè",
      "denominazione_ita": "Vialfrè",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L830",
      "lat": "45.3808430",
      "lon": "7.8181718",
      "superficie_kmq": "4.6453",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001298",
      "denominazione_ita_altra": "Vidracco",
      "denominazione_ita": "Vidracco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L857",
      "lat": "45.4304595",
      "lon": "7.7590151",
      "superficie_kmq": "2.9677",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001299",
      "denominazione_ita_altra": "Vigone",
      "denominazione_ita": "Vigone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L898",
      "lat": "44.8423520",
      "lon": "7.4945243",
      "superficie_kmq": "41.1543",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001300",
      "denominazione_ita_altra": "Villafranca Piemonte",
      "denominazione_ita": "Villafranca Piemonte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L948",
      "lat": "44.7812774",
      "lon": "7.5045086",
      "superficie_kmq": "50.7854",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001301",
      "denominazione_ita_altra": "Villanova Canavese",
      "denominazione_ita": "Villanova Canavese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L982",
      "lat": "45.2429376",
      "lon": "7.5535404",
      "superficie_kmq": "4.0306",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001302",
      "denominazione_ita_altra": "Villarbasse",
      "denominazione_ita": "Villarbasse",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M002",
      "lat": "45.0448707",
      "lon": "7.4680010",
      "superficie_kmq": "10.4088",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001303",
      "denominazione_ita_altra": "Villar Dora",
      "denominazione_ita": "Villar Dora",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L999",
      "lat": "45.1178382",
      "lon": "7.3838222",
      "superficie_kmq": "5.7067",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001304",
      "denominazione_ita_altra": "Villareggia",
      "denominazione_ita": "Villareggia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M004",
      "lat": "45.3110147",
      "lon": "7.9785000",
      "superficie_kmq": "11.4091",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001305",
      "denominazione_ita_altra": "Villar Focchiardo",
      "denominazione_ita": "Villar Focchiardo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M007",
      "lat": "45.1097152",
      "lon": "7.2266896",
      "superficie_kmq": "25.6868",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001306",
      "denominazione_ita_altra": "Villar Pellice",
      "denominazione_ita": "Villar Pellice",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M013",
      "lat": "44.8085876",
      "lon": "7.1582795",
      "superficie_kmq": "60.2927",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001307",
      "denominazione_ita_altra": "Villar Perosa",
      "denominazione_ita": "Villar Perosa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M014",
      "lat": "44.9201904",
      "lon": "7.2492690",
      "superficie_kmq": "11.4228",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001308",
      "denominazione_ita_altra": "Villastellone",
      "denominazione_ita": "Villastellone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M027",
      "lat": "44.9215522",
      "lon": "7.7453533",
      "superficie_kmq": "19.8767",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001309",
      "denominazione_ita_altra": "Vinovo",
      "denominazione_ita": "Vinovo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M060",
      "lat": "44.9487821",
      "lon": "7.6342416",
      "superficie_kmq": "17.6941",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001310",
      "denominazione_ita_altra": "Virle Piemonte",
      "denominazione_ita": "Virle Piemonte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M069",
      "lat": "44.8659384",
      "lon": "7.5688908",
      "superficie_kmq": "14.0573",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001311",
      "denominazione_ita_altra": "Vische",
      "denominazione_ita": "Vische",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M071",
      "lat": "45.3315726",
      "lon": "7.9513803",
      "superficie_kmq": "17.0748",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001312",
      "denominazione_ita_altra": "Vistrorio",
      "denominazione_ita": "Vistrorio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M080",
      "lat": "45.4411020",
      "lon": "7.7694197",
      "superficie_kmq": "4.6817",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001313",
      "denominazione_ita_altra": "Viù",
      "denominazione_ita": "Viù",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M094",
      "lat": "45.2389441",
      "lon": "7.3764679",
      "superficie_kmq": "84.1119",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001314",
      "denominazione_ita_altra": "Volpiano",
      "denominazione_ita": "Volpiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M122",
      "lat": "45.2019004",
      "lon": "7.7755503",
      "superficie_kmq": "32.4629",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001315",
      "denominazione_ita_altra": "Volvera",
      "denominazione_ita": "Volvera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M133",
      "lat": "44.9550226",
      "lon": "7.5123049",
      "superficie_kmq": "20.9827",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001316",
      "denominazione_ita_altra": "Mappano",
      "denominazione_ita": "Mappano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M316",
      "lat": "45.1509961",
      "lon": "7.7037794",
      "superficie_kmq": "9.7589",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001317",
      "denominazione_ita_altra": "Val di Chy",
      "denominazione_ita": "Val di Chy",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M405",
      "lat": "45.4620917",
      "lon": "7.7731370",
      "superficie_kmq": "13.8310",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "TO",
      "codice_istat": "001318",
      "denominazione_ita_altra": "Valchiusa",
      "denominazione_ita": "Valchiusa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M415",
      "lat": "45.5203531",
      "lon": "7.7071195",
      "superficie_kmq": "49.6245",
      "codice_sovracomunale": "201"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002002",
      "denominazione_ita_altra": "Alagna Valsesia",
      "denominazione_ita": "Alagna Valsesia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A119",
      "lat": "45.8533368",
      "lon": "7.9381296",
      "superficie_kmq": "133.1704",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002003",
      "denominazione_ita_altra": "Albano Vercellese",
      "denominazione_ita": "Albano Vercellese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A130",
      "lat": "45.4251196",
      "lon": "8.3805828",
      "superficie_kmq": "13.7804",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002004",
      "denominazione_ita_altra": "Alice Castello",
      "denominazione_ita": "Alice Castello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A198",
      "lat": "45.3665324",
      "lon": "8.0746946",
      "superficie_kmq": "24.5650",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002006",
      "denominazione_ita_altra": "Arborio",
      "denominazione_ita": "Arborio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A358",
      "lat": "45.4960914",
      "lon": "8.3889854",
      "superficie_kmq": "23.3101",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002007",
      "denominazione_ita_altra": "Asigliano Vercellese",
      "denominazione_ita": "Asigliano Vercellese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A466",
      "lat": "45.2611784",
      "lon": "8.4061942",
      "superficie_kmq": "26.3156",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002008",
      "denominazione_ita_altra": "Balmuccia",
      "denominazione_ita": "Balmuccia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A600",
      "lat": "45.8183577",
      "lon": "8.1382562",
      "superficie_kmq": "9.7885",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002009",
      "denominazione_ita_altra": "Balocco",
      "denominazione_ita": "Balocco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A601",
      "lat": "45.4555397",
      "lon": "8.2810335",
      "superficie_kmq": "16.8079",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002011",
      "denominazione_ita_altra": "Bianzè",
      "denominazione_ita": "Bianzè",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A847",
      "lat": "45.3073811",
      "lon": "8.1200641",
      "superficie_kmq": "41.8058",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002014",
      "denominazione_ita_altra": "Boccioleto",
      "denominazione_ita": "Boccioleto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A914",
      "lat": "45.8299973",
      "lon": "8.1132908",
      "superficie_kmq": "33.8706",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002015",
      "denominazione_ita_altra": "Borgo d'Ale",
      "denominazione_ita": "Borgo d'Ale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B009",
      "lat": "45.3499212",
      "lon": "8.0537270",
      "superficie_kmq": "39.5707",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002016",
      "denominazione_ita_altra": "Borgosesia",
      "denominazione_ita": "Borgosesia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B041",
      "lat": "45.7167385",
      "lon": "8.2764104",
      "superficie_kmq": "41.0901",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002017",
      "denominazione_ita_altra": "Borgo Vercelli",
      "denominazione_ita": "Borgo Vercelli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B046",
      "lat": "45.3571618",
      "lon": "8.4663705",
      "superficie_kmq": "19.2949",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002021",
      "denominazione_ita_altra": "Buronzo",
      "denominazione_ita": "Buronzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B280",
      "lat": "45.4821244",
      "lon": "8.2683343",
      "superficie_kmq": "25.0776",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002025",
      "denominazione_ita_altra": "Campertogno",
      "denominazione_ita": "Campertogno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B505",
      "lat": "45.7986834",
      "lon": "8.0321670",
      "superficie_kmq": "34.1364",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002029",
      "denominazione_ita_altra": "Carcoforo",
      "denominazione_ita": "Carcoforo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B752",
      "lat": "45.9089017",
      "lon": "8.0489014",
      "superficie_kmq": "22.8004",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002030",
      "denominazione_ita_altra": "Caresana",
      "denominazione_ita": "Caresana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B767",
      "lat": "45.2210857",
      "lon": "8.5064856",
      "superficie_kmq": "24.1087",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002031",
      "denominazione_ita_altra": "Caresanablot",
      "denominazione_ita": "Caresanablot",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B768",
      "lat": "45.3573559",
      "lon": "8.3887727",
      "superficie_kmq": "11.0218",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002032",
      "denominazione_ita_altra": "Carisio",
      "denominazione_ita": "Carisio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B782",
      "lat": "45.4084654",
      "lon": "8.1985860",
      "superficie_kmq": "30.1062",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002033",
      "denominazione_ita_altra": "Casanova Elvo",
      "denominazione_ita": "Casanova Elvo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B928",
      "lat": "45.3995339",
      "lon": "8.2933068",
      "superficie_kmq": "16.2050",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002035",
      "denominazione_ita_altra": "San Giacomo Vercellese",
      "denominazione_ita": "San Giacomo Vercellese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B952",
      "lat": "45.4974175",
      "lon": "8.3253451",
      "superficie_kmq": "9.6044",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002041",
      "denominazione_ita_altra": "Cervatto",
      "denominazione_ita": "Cervatto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C548",
      "lat": "45.8830922",
      "lon": "8.1623540",
      "superficie_kmq": "9.5380",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002042",
      "denominazione_ita_altra": "Cigliano",
      "denominazione_ita": "Cigliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C680",
      "lat": "45.3095599",
      "lon": "8.0214890",
      "superficie_kmq": "25.3078",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002043",
      "denominazione_ita_altra": "Civiasco",
      "denominazione_ita": "Civiasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C757",
      "lat": "45.8066954",
      "lon": "8.2927871",
      "superficie_kmq": "7.3878",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002045",
      "denominazione_ita_altra": "Collobiano",
      "denominazione_ita": "Collobiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C884",
      "lat": "45.3971293",
      "lon": "8.3485358",
      "superficie_kmq": "9.2234",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002047",
      "denominazione_ita_altra": "Costanzana",
      "denominazione_ita": "Costanzana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D113",
      "lat": "45.2361993",
      "lon": "8.3700654",
      "superficie_kmq": "20.9676",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002048",
      "denominazione_ita_altra": "Cravagliana",
      "denominazione_ita": "Cravagliana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D132",
      "lat": "45.8473583",
      "lon": "8.2011295",
      "superficie_kmq": "34.8563",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002049",
      "denominazione_ita_altra": "Crescentino",
      "denominazione_ita": "Crescentino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D154",
      "lat": "45.1923283",
      "lon": "8.1025827",
      "superficie_kmq": "48.2233",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002052",
      "denominazione_ita_altra": "Crova",
      "denominazione_ita": "Crova",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D187",
      "lat": "45.3300408",
      "lon": "8.2119299",
      "superficie_kmq": "14.0162",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002054",
      "denominazione_ita_altra": "Desana",
      "denominazione_ita": "Desana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D281",
      "lat": "45.2687361",
      "lon": "8.3574505",
      "superficie_kmq": "16.4768",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002057",
      "denominazione_ita_altra": "Fobello",
      "denominazione_ita": "Fobello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D641",
      "lat": "45.8895691",
      "lon": "8.1589187",
      "superficie_kmq": "28.1403",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002058",
      "denominazione_ita_altra": "Fontanetto Po",
      "denominazione_ita": "Fontanetto Po",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D676",
      "lat": "45.1933967",
      "lon": "8.1917165",
      "superficie_kmq": "23.2424",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002059",
      "denominazione_ita_altra": "Formigliana",
      "denominazione_ita": "Formigliana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D712",
      "lat": "45.4287819",
      "lon": "8.2929105",
      "superficie_kmq": "16.7629",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002061",
      "denominazione_ita_altra": "Gattinara",
      "denominazione_ita": "Gattinara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D938",
      "lat": "45.6173520",
      "lon": "8.3712807",
      "superficie_kmq": "33.6659",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002062",
      "denominazione_ita_altra": "Ghislarengo",
      "denominazione_ita": "Ghislarengo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E007",
      "lat": "45.5293231",
      "lon": "8.3843230",
      "superficie_kmq": "12.5030",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002065",
      "denominazione_ita_altra": "Greggio",
      "denominazione_ita": "Greggio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E163",
      "lat": "45.4518081",
      "lon": "8.3824755",
      "superficie_kmq": "11.8817",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002066",
      "denominazione_ita_altra": "Guardabosone",
      "denominazione_ita": "Guardabosone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E237",
      "lat": "45.7009478",
      "lon": "8.2503224",
      "superficie_kmq": "6.0875",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002067",
      "denominazione_ita_altra": "Lamporo",
      "denominazione_ita": "Lamporo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E433",
      "lat": "45.2300833",
      "lon": "8.0975502",
      "superficie_kmq": "9.6440",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002068",
      "denominazione_ita_altra": "Lenta",
      "denominazione_ita": "Lenta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E528",
      "lat": "45.5555702",
      "lon": "8.3844480",
      "superficie_kmq": "18.9701",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002070",
      "denominazione_ita_altra": "Lignana",
      "denominazione_ita": "Lignana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E583",
      "lat": "45.2852984",
      "lon": "8.3460464",
      "superficie_kmq": "22.5740",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002071",
      "denominazione_ita_altra": "Livorno Ferraris",
      "denominazione_ita": "Livorno Ferraris",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E626",
      "lat": "45.2816618",
      "lon": "8.0819976",
      "superficie_kmq": "58.0280",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002072",
      "denominazione_ita_altra": "Lozzolo",
      "denominazione_ita": "Lozzolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E711",
      "lat": "45.6225953",
      "lon": "8.3220794",
      "superficie_kmq": "6.6672",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002078",
      "denominazione_ita_altra": "Mollia",
      "denominazione_ita": "Mollia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F297",
      "lat": "45.8152932",
      "lon": "8.0309665",
      "superficie_kmq": "13.9178",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002079",
      "denominazione_ita_altra": "Moncrivello",
      "denominazione_ita": "Moncrivello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F342",
      "lat": "45.3312332",
      "lon": "7.9958611",
      "superficie_kmq": "20.1818",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002082",
      "denominazione_ita_altra": "Motta de' Conti",
      "denominazione_ita": "Motta de' Conti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F774",
      "lat": "45.1915035",
      "lon": "8.5207710",
      "superficie_kmq": "11.7150",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002088",
      "denominazione_ita_altra": "Olcenengo",
      "denominazione_ita": "Olcenengo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G016",
      "lat": "45.3635979",
      "lon": "8.3113898",
      "superficie_kmq": "16.5035",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002089",
      "denominazione_ita_altra": "Oldenico",
      "denominazione_ita": "Oldenico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G018",
      "lat": "45.4023576",
      "lon": "8.3827208",
      "superficie_kmq": "6.5270",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002090",
      "denominazione_ita_altra": "Palazzolo Vercellese",
      "denominazione_ita": "Palazzolo Vercellese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G266",
      "lat": "45.1845011",
      "lon": "8.2328243",
      "superficie_kmq": "13.9396",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002091",
      "denominazione_ita_altra": "Pertengo",
      "denominazione_ita": "Pertengo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G471",
      "lat": "45.2356219",
      "lon": "8.4176290",
      "superficie_kmq": "8.2949",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002093",
      "denominazione_ita_altra": "Pezzana",
      "denominazione_ita": "Pezzana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G528",
      "lat": "45.2620659",
      "lon": "8.4842019",
      "superficie_kmq": "17.3517",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002096",
      "denominazione_ita_altra": "Pila",
      "denominazione_ita": "Pila",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G666",
      "lat": "45.7702581",
      "lon": "8.0808151",
      "superficie_kmq": "8.6903",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002097",
      "denominazione_ita_altra": "Piode",
      "denominazione_ita": "Piode",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G685",
      "lat": "45.7697758",
      "lon": "8.0530867",
      "superficie_kmq": "13.5976",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002102",
      "denominazione_ita_altra": "Postua",
      "denominazione_ita": "Postua",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G940",
      "lat": "45.7141971",
      "lon": "8.2314962",
      "superficie_kmq": "16.1782",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002104",
      "denominazione_ita_altra": "Prarolo",
      "denominazione_ita": "Prarolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G985",
      "lat": "45.2822302",
      "lon": "8.4793438",
      "superficie_kmq": "11.5388",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002107",
      "denominazione_ita_altra": "Quarona",
      "denominazione_ita": "Quarona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H108",
      "lat": "45.7598619",
      "lon": "8.2654215",
      "superficie_kmq": "16.1560",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002108",
      "denominazione_ita_altra": "Quinto Vercellese",
      "denominazione_ita": "Quinto Vercellese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H132",
      "lat": "45.3804833",
      "lon": "8.3621848",
      "superficie_kmq": "10.9022",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002110",
      "denominazione_ita_altra": "Rassa",
      "denominazione_ita": "Rassa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H188",
      "lat": "45.7686363",
      "lon": "8.0141933",
      "superficie_kmq": "43.2698",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002113",
      "denominazione_ita_altra": "Rimella",
      "denominazione_ita": "Rimella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H293",
      "lat": "45.9079532",
      "lon": "8.1825922",
      "superficie_kmq": "26.2702",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002115",
      "denominazione_ita_altra": "Rive",
      "denominazione_ita": "Rive",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H346",
      "lat": "45.2125683",
      "lon": "8.4183193",
      "superficie_kmq": "9.4089",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002116",
      "denominazione_ita_altra": "Roasio",
      "denominazione_ita": "Roasio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H365",
      "lat": "45.6086313",
      "lon": "8.2889284",
      "superficie_kmq": "27.9160",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002118",
      "denominazione_ita_altra": "Ronsecco",
      "denominazione_ita": "Ronsecco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H549",
      "lat": "45.2535053",
      "lon": "8.2777719",
      "superficie_kmq": "24.4754",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002121",
      "denominazione_ita_altra": "Rossa",
      "denominazione_ita": "Rossa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H577",
      "lat": "45.8335577",
      "lon": "8.1256449",
      "superficie_kmq": "11.8379",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002122",
      "denominazione_ita_altra": "Rovasenda",
      "denominazione_ita": "Rovasenda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H364",
      "lat": "45.5367966",
      "lon": "8.3136149",
      "superficie_kmq": "29.2681",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002126",
      "denominazione_ita_altra": "Salasco",
      "denominazione_ita": "Salasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H690",
      "lat": "45.3252919",
      "lon": "8.2637808",
      "superficie_kmq": "12.1875",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002127",
      "denominazione_ita_altra": "Sali Vercellese",
      "denominazione_ita": "Sali Vercellese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H707",
      "lat": "45.3093341",
      "lon": "8.3296825",
      "superficie_kmq": "8.7766",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002128",
      "denominazione_ita_altra": "Saluggia",
      "denominazione_ita": "Saluggia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H725",
      "lat": "45.2358358",
      "lon": "8.0111381",
      "superficie_kmq": "31.5981",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002131",
      "denominazione_ita_altra": "San Germano Vercellese",
      "denominazione_ita": "San Germano Vercellese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H861",
      "lat": "45.3501865",
      "lon": "8.2509496",
      "superficie_kmq": "30.6327",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002133",
      "denominazione_ita_altra": "Santhià",
      "denominazione_ita": "Santhià",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I337",
      "lat": "45.3669258",
      "lon": "8.1743501",
      "superficie_kmq": "53.1294",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002134",
      "denominazione_ita_altra": "Scopa",
      "denominazione_ita": "Scopa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I544",
      "lat": "45.7917234",
      "lon": "8.1131603",
      "superficie_kmq": "22.5335",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002135",
      "denominazione_ita_altra": "Scopello",
      "denominazione_ita": "Scopello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I545",
      "lat": "45.7736830",
      "lon": "8.0948415",
      "superficie_kmq": "18.2544",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002137",
      "denominazione_ita_altra": "Serravalle Sesia",
      "denominazione_ita": "Serravalle Sesia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I663",
      "lat": "45.6861578",
      "lon": "8.3114752",
      "superficie_kmq": "20.9053",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002142",
      "denominazione_ita_altra": "Stroppiana",
      "denominazione_ita": "Stroppiana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I984",
      "lat": "45.2313972",
      "lon": "8.4534773",
      "superficie_kmq": "18.3140",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002147",
      "denominazione_ita_altra": "Tricerro",
      "denominazione_ita": "Tricerro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L420",
      "lat": "45.2337999",
      "lon": "8.3251494",
      "superficie_kmq": "12.2491",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002148",
      "denominazione_ita_altra": "Trino",
      "denominazione_ita": "Trino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L429",
      "lat": "45.1942074",
      "lon": "8.2969005",
      "superficie_kmq": "70.6054",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002150",
      "denominazione_ita_altra": "Tronzano Vercellese",
      "denominazione_ita": "Tronzano Vercellese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L451",
      "lat": "45.3427938",
      "lon": "8.1746927",
      "superficie_kmq": "44.7508",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002152",
      "denominazione_ita_altra": "Valduggia",
      "denominazione_ita": "Valduggia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L566",
      "lat": "45.7263977",
      "lon": "8.3278565",
      "superficie_kmq": "28.4259",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002156",
      "denominazione_ita_altra": "Varallo",
      "denominazione_ita": "Varallo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L669",
      "lat": "45.8140503",
      "lon": "8.2562118",
      "superficie_kmq": "102.9730",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002158",
      "denominazione_ita_altra": "Vercelli",
      "denominazione_ita": "Vercelli",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "L750",
      "lat": "45.3239845",
      "lon": "8.4232343",
      "superficie_kmq": "79.7743",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002163",
      "denominazione_ita_altra": "Villarboit",
      "denominazione_ita": "Villarboit",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M003",
      "lat": "45.4366580",
      "lon": "8.3368690",
      "superficie_kmq": "25.5109",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002164",
      "denominazione_ita_altra": "Villata",
      "denominazione_ita": "Villata",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M028",
      "lat": "45.3856147",
      "lon": "8.4326028",
      "superficie_kmq": "14.5822",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002166",
      "denominazione_ita_altra": "Vocca",
      "denominazione_ita": "Vocca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M106",
      "lat": "45.8309936",
      "lon": "8.1875302",
      "superficie_kmq": "20.2573",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002170",
      "denominazione_ita_altra": "Alto Sermenza",
      "denominazione_ita": "Alto Sermenza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M389",
      "lat": "45.8766505",
      "lon": "8.0290443",
      "superficie_kmq": "60.3259",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "VC",
      "codice_istat": "002171",
      "denominazione_ita_altra": "Cellio con Breia",
      "denominazione_ita": "Cellio con Breia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M398",
      "lat": "45.7607085",
      "lon": "8.3117469",
      "superficie_kmq": "17.2793",
      "codice_sovracomunale": "002"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003001",
      "denominazione_ita_altra": "Agrate Conturbia",
      "denominazione_ita": "Agrate Conturbia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A088",
      "lat": "45.6759896",
      "lon": "8.5599266",
      "superficie_kmq": "14.5364",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003002",
      "denominazione_ita_altra": "Ameno",
      "denominazione_ita": "Ameno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A264",
      "lat": "45.7887932",
      "lon": "8.4394275",
      "superficie_kmq": "9.9965",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003006",
      "denominazione_ita_altra": "Armeno",
      "denominazione_ita": "Armeno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A414",
      "lat": "45.8219765",
      "lon": "8.4391789",
      "superficie_kmq": "31.5154",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003008",
      "denominazione_ita_altra": "Arona",
      "denominazione_ita": "Arona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A429",
      "lat": "45.7604019",
      "lon": "8.5594897",
      "superficie_kmq": "15.1698",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003012",
      "denominazione_ita_altra": "Barengo",
      "denominazione_ita": "Barengo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A653",
      "lat": "45.5747818",
      "lon": "8.5120132",
      "superficie_kmq": "19.4928",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003016",
      "denominazione_ita_altra": "Bellinzago Novarese",
      "denominazione_ita": "Bellinzago Novarese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A752",
      "lat": "45.5703104",
      "lon": "8.6397311",
      "superficie_kmq": "39.1786",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003018",
      "denominazione_ita_altra": "Biandrate",
      "denominazione_ita": "Biandrate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A844",
      "lat": "45.4537873",
      "lon": "8.4637055",
      "superficie_kmq": "12.4536",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003019",
      "denominazione_ita_altra": "Boca",
      "denominazione_ita": "Boca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A911",
      "lat": "45.6782956",
      "lon": "8.4087054",
      "superficie_kmq": "9.6080",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003021",
      "denominazione_ita_altra": "Bogogno",
      "denominazione_ita": "Bogogno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A929",
      "lat": "45.6671743",
      "lon": "8.5344991",
      "superficie_kmq": "8.5097",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003022",
      "denominazione_ita_altra": "Bolzano Novarese",
      "denominazione_ita": "Bolzano Novarese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A953",
      "lat": "45.7628355",
      "lon": "8.4441526",
      "superficie_kmq": "3.3043",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003023",
      "denominazione_ita_altra": "Borgolavezzaro",
      "denominazione_ita": "Borgolavezzaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B016",
      "lat": "45.3183864",
      "lon": "8.6992676",
      "superficie_kmq": "21.0913",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003024",
      "denominazione_ita_altra": "Borgomanero",
      "denominazione_ita": "Borgomanero",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B019",
      "lat": "45.6988830",
      "lon": "8.4629865",
      "superficie_kmq": "32.2699",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003025",
      "denominazione_ita_altra": "Borgo Ticino",
      "denominazione_ita": "Borgo Ticino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B043",
      "lat": "45.6880765",
      "lon": "8.6039222",
      "superficie_kmq": "13.3738",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003026",
      "denominazione_ita_altra": "Briga Novarese",
      "denominazione_ita": "Briga Novarese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B176",
      "lat": "45.7313926",
      "lon": "8.4515914",
      "superficie_kmq": "4.7479",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003027",
      "denominazione_ita_altra": "Briona",
      "denominazione_ita": "Briona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B183",
      "lat": "45.5422061",
      "lon": "8.4798193",
      "superficie_kmq": "24.7562",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003030",
      "denominazione_ita_altra": "Caltignaga",
      "denominazione_ita": "Caltignaga",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B431",
      "lat": "45.5170920",
      "lon": "8.5879148",
      "superficie_kmq": "22.3213",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003032",
      "denominazione_ita_altra": "Cameri",
      "denominazione_ita": "Cameri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B473",
      "lat": "45.5010303",
      "lon": "8.6622435",
      "superficie_kmq": "39.9850",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003036",
      "denominazione_ita_altra": "Carpignano Sesia",
      "denominazione_ita": "Carpignano Sesia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B823",
      "lat": "45.5353124",
      "lon": "8.4183407",
      "superficie_kmq": "14.6557",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003037",
      "denominazione_ita_altra": "Casalbeltrame",
      "denominazione_ita": "Casalbeltrame",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B864",
      "lat": "45.4362445",
      "lon": "8.4683785",
      "superficie_kmq": "16.0407",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003039",
      "denominazione_ita_altra": "Casaleggio Novara",
      "denominazione_ita": "Casaleggio Novara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B883",
      "lat": "45.4863093",
      "lon": "8.4926659",
      "superficie_kmq": "10.5310",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003040",
      "denominazione_ita_altra": "Casalino",
      "denominazione_ita": "Casalino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B897",
      "lat": "45.3576850",
      "lon": "8.5227352",
      "superficie_kmq": "39.4901",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003041",
      "denominazione_ita_altra": "Casalvolone",
      "denominazione_ita": "Casalvolone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B920",
      "lat": "45.3994628",
      "lon": "8.4643646",
      "superficie_kmq": "17.4849",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003042",
      "denominazione_ita_altra": "Castellazzo Novarese",
      "denominazione_ita": "Castellazzo Novarese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C149",
      "lat": "45.5139988",
      "lon": "8.4887075",
      "superficie_kmq": "10.7918",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003043",
      "denominazione_ita_altra": "Castelletto sopra Ticino",
      "denominazione_ita": "Castelletto sopra Ticino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C166",
      "lat": "45.7140591",
      "lon": "8.6423732",
      "superficie_kmq": "14.6411",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003044",
      "denominazione_ita_altra": "Cavaglietto",
      "denominazione_ita": "Cavaglietto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C364",
      "lat": "45.6028078",
      "lon": "8.5009121",
      "superficie_kmq": "6.4855",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003045",
      "denominazione_ita_altra": "Cavaglio d'Agogna",
      "denominazione_ita": "Cavaglio d'Agogna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C365",
      "lat": "45.6118196",
      "lon": "8.4827860",
      "superficie_kmq": "9.8335",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003047",
      "denominazione_ita_altra": "Cavallirio",
      "denominazione_ita": "Cavallirio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C378",
      "lat": "45.6649690",
      "lon": "8.3967364",
      "superficie_kmq": "8.3261",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003049",
      "denominazione_ita_altra": "Cerano",
      "denominazione_ita": "Cerano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C483",
      "lat": "45.4106786",
      "lon": "8.7808551",
      "superficie_kmq": "32.6332",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003051",
      "denominazione_ita_altra": "Colazza",
      "denominazione_ita": "Colazza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C829",
      "lat": "45.7926355",
      "lon": "8.4990851",
      "superficie_kmq": "3.1618",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003052",
      "denominazione_ita_altra": "Comignago",
      "denominazione_ita": "Comignago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C926",
      "lat": "45.7152483",
      "lon": "8.5651231",
      "superficie_kmq": "4.4493",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003055",
      "denominazione_ita_altra": "Cressa",
      "denominazione_ita": "Cressa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D162",
      "lat": "45.6480020",
      "lon": "8.5074836",
      "superficie_kmq": "7.1035",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003058",
      "denominazione_ita_altra": "Cureggio",
      "denominazione_ita": "Cureggio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D216",
      "lat": "45.6751385",
      "lon": "8.4613609",
      "superficie_kmq": "8.5068",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003060",
      "denominazione_ita_altra": "Divignano",
      "denominazione_ita": "Divignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D309",
      "lat": "45.6622872",
      "lon": "8.6014953",
      "superficie_kmq": "5.0977",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003062",
      "denominazione_ita_altra": "Dormelletto",
      "denominazione_ita": "Dormelletto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D347",
      "lat": "45.7301706",
      "lon": "8.5731841",
      "superficie_kmq": "7.2522",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003065",
      "denominazione_ita_altra": "Fara Novarese",
      "denominazione_ita": "Fara Novarese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D492",
      "lat": "45.5551244",
      "lon": "8.4591121",
      "superficie_kmq": "9.2147",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003066",
      "denominazione_ita_altra": "Fontaneto d'Agogna",
      "denominazione_ita": "Fontaneto d'Agogna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D675",
      "lat": "45.6398670",
      "lon": "8.4845827",
      "superficie_kmq": "21.1702",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003068",
      "denominazione_ita_altra": "Galliate",
      "denominazione_ita": "Galliate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D872",
      "lat": "45.4777007",
      "lon": "8.6952726",
      "superficie_kmq": "29.3662",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003069",
      "denominazione_ita_altra": "Garbagna Novarese",
      "denominazione_ita": "Garbagna Novarese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D911",
      "lat": "45.3866548",
      "lon": "8.6596796",
      "superficie_kmq": "10.0494",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003070",
      "denominazione_ita_altra": "Gargallo",
      "denominazione_ita": "Gargallo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D921",
      "lat": "45.7275134",
      "lon": "8.4292154",
      "superficie_kmq": "3.7542",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003073",
      "denominazione_ita_altra": "Ghemme",
      "denominazione_ita": "Ghemme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E001",
      "lat": "45.5978178",
      "lon": "8.4200910",
      "superficie_kmq": "20.6442",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003076",
      "denominazione_ita_altra": "Gozzano",
      "denominazione_ita": "Gozzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E120",
      "lat": "45.7475661",
      "lon": "8.4346508",
      "superficie_kmq": "12.5821",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003077",
      "denominazione_ita_altra": "Granozzo con Monticello",
      "denominazione_ita": "Granozzo con Monticello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E143",
      "lat": "45.3601442",
      "lon": "8.5741367",
      "superficie_kmq": "19.5208",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003079",
      "denominazione_ita_altra": "Grignasco",
      "denominazione_ita": "Grignasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E177",
      "lat": "45.6819106",
      "lon": "8.3397089",
      "superficie_kmq": "14.3337",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003082",
      "denominazione_ita_altra": "Invorio",
      "denominazione_ita": "Invorio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E314",
      "lat": "45.7559157",
      "lon": "8.4867397",
      "superficie_kmq": "17.3717",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003083",
      "denominazione_ita_altra": "Landiona",
      "denominazione_ita": "Landiona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E436",
      "lat": "45.4969127",
      "lon": "8.4221687",
      "superficie_kmq": "7.2834",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003084",
      "denominazione_ita_altra": "Lesa",
      "denominazione_ita": "Lesa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E544",
      "lat": "45.8301213",
      "lon": "8.5648443",
      "superficie_kmq": "13.5806",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003088",
      "denominazione_ita_altra": "Maggiora",
      "denominazione_ita": "Maggiora",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E803",
      "lat": "45.6907627",
      "lon": "8.4209948",
      "superficie_kmq": "10.6587",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003090",
      "denominazione_ita_altra": "Mandello Vitta",
      "denominazione_ita": "Mandello Vitta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E880",
      "lat": "45.4952380",
      "lon": "8.4591859",
      "superficie_kmq": "5.8541",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003091",
      "denominazione_ita_altra": "Marano Ticino",
      "denominazione_ita": "Marano Ticino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E907",
      "lat": "45.6298317",
      "lon": "8.6319364",
      "superficie_kmq": "7.7879",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003093",
      "denominazione_ita_altra": "Massino Visconti",
      "denominazione_ita": "Massino Visconti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F047",
      "lat": "45.8219143",
      "lon": "8.5388081",
      "superficie_kmq": "6.8639",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003095",
      "denominazione_ita_altra": "Meina",
      "denominazione_ita": "Meina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F093",
      "lat": "45.7900448",
      "lon": "8.5376017",
      "superficie_kmq": "7.5349",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003097",
      "denominazione_ita_altra": "Mezzomerico",
      "denominazione_ita": "Mezzomerico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F188",
      "lat": "45.6202916",
      "lon": "8.6046200",
      "superficie_kmq": "7.7187",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003098",
      "denominazione_ita_altra": "Miasino",
      "denominazione_ita": "Miasino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F191",
      "lat": "45.8021998",
      "lon": "8.4303610",
      "superficie_kmq": "5.5210",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003100",
      "denominazione_ita_altra": "Momo",
      "denominazione_ita": "Momo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F317",
      "lat": "45.5739190",
      "lon": "8.5553705",
      "superficie_kmq": "23.5907",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003103",
      "denominazione_ita_altra": "Nebbiuno",
      "denominazione_ita": "Nebbiuno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F859",
      "lat": "45.8060862",
      "lon": "8.5242387",
      "superficie_kmq": "8.2651",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003104",
      "denominazione_ita_altra": "Nibbiola",
      "denominazione_ita": "Nibbiola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F886",
      "lat": "45.3717892",
      "lon": "8.6563525",
      "superficie_kmq": "11.3421",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003106",
      "denominazione_ita_altra": "Novara",
      "denominazione_ita": "Novara",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "F952",
      "lat": "45.4458900",
      "lon": "8.6219159",
      "superficie_kmq": "103.0492",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003108",
      "denominazione_ita_altra": "Oleggio",
      "denominazione_ita": "Oleggio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G019",
      "lat": "45.5966950",
      "lon": "8.6373638",
      "superficie_kmq": "37.7943",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003109",
      "denominazione_ita_altra": "Oleggio Castello",
      "denominazione_ita": "Oleggio Castello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G020",
      "lat": "45.7493254",
      "lon": "8.5297117",
      "superficie_kmq": "5.9351",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003112",
      "denominazione_ita_altra": "Orta San Giulio",
      "denominazione_ita": "Orta San Giulio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G134",
      "lat": "45.7991727",
      "lon": "8.4056846",
      "superficie_kmq": "6.6500",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003114",
      "denominazione_ita_altra": "Paruzzaro",
      "denominazione_ita": "Paruzzaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G349",
      "lat": "45.7486966",
      "lon": "8.5134575",
      "superficie_kmq": "5.2272",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003115",
      "denominazione_ita_altra": "Pella",
      "denominazione_ita": "Pella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G421",
      "lat": "45.7970808",
      "lon": "8.3831169",
      "superficie_kmq": "8.1345",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003116",
      "denominazione_ita_altra": "Pettenasco",
      "denominazione_ita": "Pettenasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G520",
      "lat": "45.8173318",
      "lon": "8.4085751",
      "superficie_kmq": "7.0713",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003119",
      "denominazione_ita_altra": "Pisano",
      "denominazione_ita": "Pisano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G703",
      "lat": "45.7935586",
      "lon": "8.5104372",
      "superficie_kmq": "2.7659",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003120",
      "denominazione_ita_altra": "Pogno",
      "denominazione_ita": "Pogno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G775",
      "lat": "45.7566818",
      "lon": "8.3830747",
      "superficie_kmq": "9.8653",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003121",
      "denominazione_ita_altra": "Pombia",
      "denominazione_ita": "Pombia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G809",
      "lat": "45.6492746",
      "lon": "8.6323887",
      "superficie_kmq": "12.2966",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003122",
      "denominazione_ita_altra": "Prato Sesia",
      "denominazione_ita": "Prato Sesia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H001",
      "lat": "45.6478829",
      "lon": "8.3737739",
      "superficie_kmq": "12.1260",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003129",
      "denominazione_ita_altra": "Recetto",
      "denominazione_ita": "Recetto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H213",
      "lat": "45.4608115",
      "lon": "8.4360882",
      "superficie_kmq": "8.8526",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003130",
      "denominazione_ita_altra": "Romagnano Sesia",
      "denominazione_ita": "Romagnano Sesia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H502",
      "lat": "45.6333577",
      "lon": "8.3828761",
      "superficie_kmq": "17.9764",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003131",
      "denominazione_ita_altra": "Romentino",
      "denominazione_ita": "Romentino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H518",
      "lat": "45.4626094",
      "lon": "8.7208535",
      "superficie_kmq": "17.6906",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003133",
      "denominazione_ita_altra": "San Maurizio d'Opaglio",
      "denominazione_ita": "San Maurizio d'Opaglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I025",
      "lat": "45.7724817",
      "lon": "8.3909580",
      "superficie_kmq": "8.5099",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003134",
      "denominazione_ita_altra": "San Nazzaro Sesia",
      "denominazione_ita": "San Nazzaro Sesia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I052",
      "lat": "45.4381361",
      "lon": "8.4235666",
      "superficie_kmq": "11.4512",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003135",
      "denominazione_ita_altra": "San Pietro Mosezzo",
      "denominazione_ita": "San Pietro Mosezzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I116",
      "lat": "45.4556152",
      "lon": "8.5434721",
      "superficie_kmq": "34.8992",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003138",
      "denominazione_ita_altra": "Sillavengo",
      "denominazione_ita": "Sillavengo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I736",
      "lat": "45.5204608",
      "lon": "8.4405271",
      "superficie_kmq": "9.5352",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003139",
      "denominazione_ita_altra": "Sizzano",
      "denominazione_ita": "Sizzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I767",
      "lat": "45.5773532",
      "lon": "8.4373333",
      "superficie_kmq": "10.7543",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003140",
      "denominazione_ita_altra": "Soriso",
      "denominazione_ita": "Soriso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I857",
      "lat": "45.7422787",
      "lon": "8.4093648",
      "superficie_kmq": "6.3719",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003141",
      "denominazione_ita_altra": "Sozzago",
      "denominazione_ita": "Sozzago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I880",
      "lat": "45.3978220",
      "lon": "8.7209774",
      "superficie_kmq": "12.9144",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003143",
      "denominazione_ita_altra": "Suno",
      "denominazione_ita": "Suno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L007",
      "lat": "45.6311527",
      "lon": "8.5409244",
      "superficie_kmq": "21.3271",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003144",
      "denominazione_ita_altra": "Terdobbiate",
      "denominazione_ita": "Terdobbiate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L104",
      "lat": "45.3763568",
      "lon": "8.6950970",
      "superficie_kmq": "8.4632",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003146",
      "denominazione_ita_altra": "Tornaco",
      "denominazione_ita": "Tornaco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L223",
      "lat": "45.3573679",
      "lon": "8.7179066",
      "superficie_kmq": "13.2408",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003149",
      "denominazione_ita_altra": "Trecate",
      "denominazione_ita": "Trecate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L356",
      "lat": "45.4319091",
      "lon": "8.7350555",
      "superficie_kmq": "38.2224",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003153",
      "denominazione_ita_altra": "Vaprio d'Agogna",
      "denominazione_ita": "Vaprio d'Agogna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L668",
      "lat": "45.6035421",
      "lon": "8.5540506",
      "superficie_kmq": "10.0123",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003154",
      "denominazione_ita_altra": "Varallo Pombia",
      "denominazione_ita": "Varallo Pombia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L670",
      "lat": "45.6667102",
      "lon": "8.6330527",
      "superficie_kmq": "13.6120",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003158",
      "denominazione_ita_altra": "Vespolate",
      "denominazione_ita": "Vespolate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L808",
      "lat": "45.3494764",
      "lon": "8.6690381",
      "superficie_kmq": "17.7798",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003159",
      "denominazione_ita_altra": "Vicolungo",
      "denominazione_ita": "Vicolungo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L847",
      "lat": "45.4788349",
      "lon": "8.4613171",
      "superficie_kmq": "13.2939",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003164",
      "denominazione_ita_altra": "Vinzaglio",
      "denominazione_ita": "Vinzaglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M062",
      "lat": "45.3236585",
      "lon": "8.5184053",
      "superficie_kmq": "15.4636",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "NO",
      "codice_istat": "003166",
      "denominazione_ita_altra": "Gattico-Veruno",
      "denominazione_ita": "Gattico-Veruno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M416",
      "lat": "45.7052040",
      "lon": "8.5268386",
      "superficie_kmq": "26.1601",
      "codice_sovracomunale": "003"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004001",
      "denominazione_ita_altra": "Acceglio",
      "denominazione_ita": "Acceglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A016",
      "lat": "44.4757985",
      "lon": "6.9912226",
      "superficie_kmq": "151.5276",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004002",
      "denominazione_ita_altra": "Aisone",
      "denominazione_ita": "Aisone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A113",
      "lat": "44.3134369",
      "lon": "7.2208339",
      "superficie_kmq": "36.9173",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004003",
      "denominazione_ita_altra": "Alba",
      "denominazione_ita": "Alba",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A124",
      "lat": "44.7009160",
      "lon": "8.0356990",
      "superficie_kmq": "53.5853",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004004",
      "denominazione_ita_altra": "Albaretto della Torre",
      "denominazione_ita": "Albaretto della Torre",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A139",
      "lat": "44.5959075",
      "lon": "8.0640823",
      "superficie_kmq": "4.5899",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004005",
      "denominazione_ita_altra": "Alto",
      "denominazione_ita": "Alto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A238",
      "lat": "44.1082689",
      "lon": "8.0036858",
      "superficie_kmq": "7.4616",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004006",
      "denominazione_ita_altra": "Argentera",
      "denominazione_ita": "Argentera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A394",
      "lat": "44.3795782",
      "lon": "6.9693782",
      "superficie_kmq": "76.2591",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004007",
      "denominazione_ita_altra": "Arguello",
      "denominazione_ita": "Arguello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A396",
      "lat": "44.5829193",
      "lon": "8.1106798",
      "superficie_kmq": "4.9174",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004008",
      "denominazione_ita_altra": "Bagnasco",
      "denominazione_ita": "Bagnasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A555",
      "lat": "44.3020597",
      "lon": "8.0425832",
      "superficie_kmq": "30.9490",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004009",
      "denominazione_ita_altra": "Bagnolo Piemonte",
      "denominazione_ita": "Bagnolo Piemonte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A571",
      "lat": "44.7598976",
      "lon": "7.3154823",
      "superficie_kmq": "63.2505",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004010",
      "denominazione_ita_altra": "Baldissero d'Alba",
      "denominazione_ita": "Baldissero d'Alba",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A589",
      "lat": "44.7587078",
      "lon": "7.9157493",
      "superficie_kmq": "15.3257",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004011",
      "denominazione_ita_altra": "Barbaresco",
      "denominazione_ita": "Barbaresco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A629",
      "lat": "44.7255426",
      "lon": "8.0804646",
      "superficie_kmq": "7.7637",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004012",
      "denominazione_ita_altra": "Barge",
      "denominazione_ita": "Barge",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A660",
      "lat": "44.7252145",
      "lon": "7.3233938",
      "superficie_kmq": "81.9923",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004013",
      "denominazione_ita_altra": "Barolo",
      "denominazione_ita": "Barolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A671",
      "lat": "44.6098752",
      "lon": "7.9418082",
      "superficie_kmq": "5.6892",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004014",
      "denominazione_ita_altra": "Bastia Mondovì",
      "denominazione_ita": "Bastia Mondovì",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A709",
      "lat": "44.4413762",
      "lon": "7.8933219",
      "superficie_kmq": "12.0006",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004015",
      "denominazione_ita_altra": "Battifollo",
      "denominazione_ita": "Battifollo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A716",
      "lat": "44.3191594",
      "lon": "8.0094814",
      "superficie_kmq": "11.1195",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004016",
      "denominazione_ita_altra": "Beinette",
      "denominazione_ita": "Beinette",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A735",
      "lat": "44.3656571",
      "lon": "7.6457067",
      "superficie_kmq": "17.6865",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004017",
      "denominazione_ita_altra": "Bellino",
      "denominazione_ita": "Bellino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A750",
      "lat": "44.5805979",
      "lon": "7.0171607",
      "superficie_kmq": "62.0700",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004018",
      "denominazione_ita_altra": "Belvedere Langhe",
      "denominazione_ita": "Belvedere Langhe",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A774",
      "lat": "44.4945561",
      "lon": "7.9741677",
      "superficie_kmq": "5.0030",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004019",
      "denominazione_ita_altra": "Bene Vagienna",
      "denominazione_ita": "Bene Vagienna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A779",
      "lat": "44.5452312",
      "lon": "7.8328253",
      "superficie_kmq": "48.9673",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004020",
      "denominazione_ita_altra": "Benevello",
      "denominazione_ita": "Benevello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A782",
      "lat": "44.6281819",
      "lon": "8.1059188",
      "superficie_kmq": "5.4389",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004021",
      "denominazione_ita_altra": "Bergolo",
      "denominazione_ita": "Bergolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A798",
      "lat": "44.5480034",
      "lon": "8.1835260",
      "superficie_kmq": "3.1118",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004022",
      "denominazione_ita_altra": "Bernezzo",
      "denominazione_ita": "Bernezzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A805",
      "lat": "44.3885948",
      "lon": "7.4353103",
      "superficie_kmq": "25.8445",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004023",
      "denominazione_ita_altra": "Bonvicino",
      "denominazione_ita": "Bonvicino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A979",
      "lat": "44.5036111",
      "lon": "8.0159202",
      "superficie_kmq": "7.2409",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004024",
      "denominazione_ita_altra": "Borgomale",
      "denominazione_ita": "Borgomale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B018",
      "lat": "44.6204344",
      "lon": "8.1327556",
      "superficie_kmq": "8.4778",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004025",
      "denominazione_ita_altra": "Borgo San Dalmazzo",
      "denominazione_ita": "Borgo San Dalmazzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B033",
      "lat": "44.3287485",
      "lon": "7.4874639",
      "superficie_kmq": "22.3397",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004026",
      "denominazione_ita_altra": "Bosia",
      "denominazione_ita": "Bosia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B079",
      "lat": "44.6024594",
      "lon": "8.1462032",
      "superficie_kmq": "5.5405",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004027",
      "denominazione_ita_altra": "Bossolasco",
      "denominazione_ita": "Bossolasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B084",
      "lat": "44.5267822",
      "lon": "8.0575028",
      "superficie_kmq": "14.5453",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004028",
      "denominazione_ita_altra": "Boves",
      "denominazione_ita": "Boves",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B101",
      "lat": "44.3298085",
      "lon": "7.5519949",
      "superficie_kmq": "50.9483",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004029",
      "denominazione_ita_altra": "Bra",
      "denominazione_ita": "Bra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B111",
      "lat": "44.6981762",
      "lon": "7.8547477",
      "superficie_kmq": "59.5298",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004030",
      "denominazione_ita_altra": "Briaglia",
      "denominazione_ita": "Briaglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B167",
      "lat": "44.3951070",
      "lon": "7.8769895",
      "superficie_kmq": "6.2376",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004031",
      "denominazione_ita_altra": "Briga Alta",
      "denominazione_ita": "Briga Alta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B175",
      "lat": "44.0832046",
      "lon": "7.7507606",
      "superficie_kmq": "52.1796",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004032",
      "denominazione_ita_altra": "Brondello",
      "denominazione_ita": "Brondello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B200",
      "lat": "44.6000492",
      "lon": "7.4051372",
      "superficie_kmq": "10.1240",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004033",
      "denominazione_ita_altra": "Brossasco",
      "denominazione_ita": "Brossasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B204",
      "lat": "44.5702890",
      "lon": "7.3604423",
      "superficie_kmq": "28.0639",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004034",
      "denominazione_ita_altra": "Busca",
      "denominazione_ita": "Busca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B285",
      "lat": "44.5136882",
      "lon": "7.4735215",
      "superficie_kmq": "76.7643",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004035",
      "denominazione_ita_altra": "Camerana",
      "denominazione_ita": "Camerana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B467",
      "lat": "44.4226542",
      "lon": "8.1407987",
      "superficie_kmq": "23.6378",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004037",
      "denominazione_ita_altra": "Canale",
      "denominazione_ita": "Canale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B573",
      "lat": "44.7972027",
      "lon": "7.9914096",
      "superficie_kmq": "17.8655",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004038",
      "denominazione_ita_altra": "Canosio",
      "denominazione_ita": "Canosio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B621",
      "lat": "44.4553172",
      "lon": "7.0813913",
      "superficie_kmq": "48.4476",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004039",
      "denominazione_ita_altra": "Caprauna",
      "denominazione_ita": "Caprauna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B692",
      "lat": "44.1158086",
      "lon": "7.9549407",
      "superficie_kmq": "11.5018",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004040",
      "denominazione_ita_altra": "Caraglio",
      "denominazione_ita": "Caraglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B719",
      "lat": "44.4177060",
      "lon": "7.4276210",
      "superficie_kmq": "41.6759",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004041",
      "denominazione_ita_altra": "Caramagna Piemonte",
      "denominazione_ita": "Caramagna Piemonte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B720",
      "lat": "44.7804298",
      "lon": "7.7411759",
      "superficie_kmq": "26.3287",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004042",
      "denominazione_ita_altra": "Cardè",
      "denominazione_ita": "Cardè",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B755",
      "lat": "44.7441733",
      "lon": "7.4782160",
      "superficie_kmq": "19.3047",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004043",
      "denominazione_ita_altra": "Carrù",
      "denominazione_ita": "Carrù",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B841",
      "lat": "44.4785823",
      "lon": "7.8800643",
      "superficie_kmq": "25.8380",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004044",
      "denominazione_ita_altra": "Cartignano",
      "denominazione_ita": "Cartignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B845",
      "lat": "44.4770751",
      "lon": "7.2876953",
      "superficie_kmq": "6.4192",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004045",
      "denominazione_ita_altra": "Casalgrasso",
      "denominazione_ita": "Casalgrasso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B894",
      "lat": "44.8194982",
      "lon": "7.6249607",
      "superficie_kmq": "17.8066",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004046",
      "denominazione_ita_altra": "Castagnito",
      "denominazione_ita": "Castagnito",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C046",
      "lat": "44.7564728",
      "lon": "8.0301526",
      "superficie_kmq": "7.1148",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004047",
      "denominazione_ita_altra": "Casteldelfino",
      "denominazione_ita": "Casteldelfino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C081",
      "lat": "44.5909638",
      "lon": "7.0690319",
      "superficie_kmq": "33.9513",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004049",
      "denominazione_ita_altra": "Castelletto Stura",
      "denominazione_ita": "Castelletto Stura",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C165",
      "lat": "44.4442194",
      "lon": "7.6390511",
      "superficie_kmq": "17.1301",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004050",
      "denominazione_ita_altra": "Castelletto Uzzone",
      "denominazione_ita": "Castelletto Uzzone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C167",
      "lat": "44.4925707",
      "lon": "8.1859833",
      "superficie_kmq": "14.8584",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004051",
      "denominazione_ita_altra": "Castellinaldo d'Alba",
      "denominazione_ita": "Castellinaldo d'Alba",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C173",
      "lat": "44.7753259",
      "lon": "8.0315351",
      "superficie_kmq": "7.8028",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004052",
      "denominazione_ita_altra": "Castellino Tanaro",
      "denominazione_ita": "Castellino Tanaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C176",
      "lat": "44.4277639",
      "lon": "7.9818872",
      "superficie_kmq": "11.5178",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004053",
      "denominazione_ita_altra": "Castelmagno",
      "denominazione_ita": "Castelmagno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C205",
      "lat": "44.4091542",
      "lon": "7.2123292",
      "superficie_kmq": "49.3070",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004054",
      "denominazione_ita_altra": "Castelnuovo di Ceva",
      "denominazione_ita": "Castelnuovo di Ceva",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C214",
      "lat": "44.3532974",
      "lon": "8.1301966",
      "superficie_kmq": "6.2621",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004055",
      "denominazione_ita_altra": "Castiglione Falletto",
      "denominazione_ita": "Castiglione Falletto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C314",
      "lat": "44.6233304",
      "lon": "7.9790348",
      "superficie_kmq": "4.7202",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004056",
      "denominazione_ita_altra": "Castiglione Tinella",
      "denominazione_ita": "Castiglione Tinella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C317",
      "lat": "44.7260407",
      "lon": "8.1894313",
      "superficie_kmq": "11.6345",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004057",
      "denominazione_ita_altra": "Castino",
      "denominazione_ita": "Castino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C323",
      "lat": "44.6169882",
      "lon": "8.1812139",
      "superficie_kmq": "15.5227",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004058",
      "denominazione_ita_altra": "Cavallerleone",
      "denominazione_ita": "Cavallerleone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C375",
      "lat": "44.7394377",
      "lon": "7.6627358",
      "superficie_kmq": "16.4378",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004059",
      "denominazione_ita_altra": "Cavallermaggiore",
      "denominazione_ita": "Cavallermaggiore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C376",
      "lat": "44.7078515",
      "lon": "7.6877480",
      "superficie_kmq": "51.5946",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004060",
      "denominazione_ita_altra": "Celle di Macra",
      "denominazione_ita": "Celle di Macra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C441",
      "lat": "44.4825618",
      "lon": "7.1796736",
      "superficie_kmq": "31.1052",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004061",
      "denominazione_ita_altra": "Centallo",
      "denominazione_ita": "Centallo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C466",
      "lat": "44.5041151",
      "lon": "7.5901488",
      "superficie_kmq": "42.4848",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004062",
      "denominazione_ita_altra": "Ceresole Alba",
      "denominazione_ita": "Ceresole Alba",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C504",
      "lat": "44.7995197",
      "lon": "7.8205661",
      "superficie_kmq": "37.0496",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004063",
      "denominazione_ita_altra": "Cerretto Langhe",
      "denominazione_ita": "Cerretto Langhe",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C530",
      "lat": "44.5743318",
      "lon": "8.0978397",
      "superficie_kmq": "10.1423",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004064",
      "denominazione_ita_altra": "Cervasca",
      "denominazione_ita": "Cervasca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C547",
      "lat": "44.3831496",
      "lon": "7.4678883",
      "superficie_kmq": "18.2419",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004065",
      "denominazione_ita_altra": "Cervere",
      "denominazione_ita": "Cervere",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C550",
      "lat": "44.6347449",
      "lon": "7.7931675",
      "superficie_kmq": "18.5961",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004066",
      "denominazione_ita_altra": "Ceva",
      "denominazione_ita": "Ceva",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C589",
      "lat": "44.3862246",
      "lon": "8.0352173",
      "superficie_kmq": "43.1656",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004067",
      "denominazione_ita_altra": "Cherasco",
      "denominazione_ita": "Cherasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C599",
      "lat": "44.6522187",
      "lon": "7.8582036",
      "superficie_kmq": "81.5351",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004068",
      "denominazione_ita_altra": "Chiusa di Pesio",
      "denominazione_ita": "Chiusa di Pesio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C653",
      "lat": "44.3247588",
      "lon": "7.6778844",
      "superficie_kmq": "95.0188",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004069",
      "denominazione_ita_altra": "Cigliè",
      "denominazione_ita": "Cigliè",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C681",
      "lat": "44.4350586",
      "lon": "7.9262261",
      "superficie_kmq": "6.1219",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004070",
      "denominazione_ita_altra": "Cissone",
      "denominazione_ita": "Cissone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C738",
      "lat": "44.5630956",
      "lon": "8.0305096",
      "superficie_kmq": "5.9034",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004071",
      "denominazione_ita_altra": "Clavesana",
      "denominazione_ita": "Clavesana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C792",
      "lat": "44.4821379",
      "lon": "7.9119823",
      "superficie_kmq": "17.1508",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004072",
      "denominazione_ita_altra": "Corneliano d'Alba",
      "denominazione_ita": "Corneliano d'Alba",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D022",
      "lat": "44.7355335",
      "lon": "7.9612917",
      "superficie_kmq": "10.0878",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004073",
      "denominazione_ita_altra": "Cortemilia",
      "denominazione_ita": "Cortemilia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D062",
      "lat": "44.5808537",
      "lon": "8.1918668",
      "superficie_kmq": "24.9874",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004074",
      "denominazione_ita_altra": "Cossano Belbo",
      "denominazione_ita": "Cossano Belbo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D093",
      "lat": "44.6690571",
      "lon": "8.1985593",
      "superficie_kmq": "20.5398",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004075",
      "denominazione_ita_altra": "Costigliole Saluzzo",
      "denominazione_ita": "Costigliole Saluzzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D120",
      "lat": "44.5646044",
      "lon": "7.4849377",
      "superficie_kmq": "15.3423",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004076",
      "denominazione_ita_altra": "Cravanzana",
      "denominazione_ita": "Cravanzana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D133",
      "lat": "44.5731475",
      "lon": "8.1281897",
      "superficie_kmq": "8.1183",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004077",
      "denominazione_ita_altra": "Crissolo",
      "denominazione_ita": "Crissolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D172",
      "lat": "44.6990970",
      "lon": "7.1571897",
      "superficie_kmq": "52.0529",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004078",
      "denominazione_ita_altra": "Cuneo",
      "denominazione_ita": "Cuneo",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "D205",
      "lat": "44.3932857",
      "lon": "7.5511719",
      "superficie_kmq": "119.6691",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004079",
      "denominazione_ita_altra": "Demonte",
      "denominazione_ita": "Demonte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D271",
      "lat": "44.3152499",
      "lon": "7.2975134",
      "superficie_kmq": "127.3063",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004080",
      "denominazione_ita_altra": "Diano d'Alba",
      "denominazione_ita": "Diano d'Alba",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D291",
      "lat": "44.6513852",
      "lon": "8.0276690",
      "superficie_kmq": "17.5432",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004081",
      "denominazione_ita_altra": "Dogliani",
      "denominazione_ita": "Dogliani",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D314",
      "lat": "44.5300819",
      "lon": "7.9451674",
      "superficie_kmq": "35.6829",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004082",
      "denominazione_ita_altra": "Dronero",
      "denominazione_ita": "Dronero",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D372",
      "lat": "44.4669688",
      "lon": "7.3633182",
      "superficie_kmq": "58.9574",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004083",
      "denominazione_ita_altra": "Elva",
      "denominazione_ita": "Elva",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D401",
      "lat": "44.5397567",
      "lon": "7.0896342",
      "superficie_kmq": "26.2196",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004084",
      "denominazione_ita_altra": "Entracque",
      "denominazione_ita": "Entracque",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D410",
      "lat": "44.2407441",
      "lon": "7.3978031",
      "superficie_kmq": "160.7314",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004085",
      "denominazione_ita_altra": "Envie",
      "denominazione_ita": "Envie",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D412",
      "lat": "44.6817468",
      "lon": "7.3721924",
      "superficie_kmq": "24.9305",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004086",
      "denominazione_ita_altra": "Farigliano",
      "denominazione_ita": "Farigliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D499",
      "lat": "44.5116901",
      "lon": "7.9170210",
      "superficie_kmq": "16.4418",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004087",
      "denominazione_ita_altra": "Faule",
      "denominazione_ita": "Faule",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D511",
      "lat": "44.8054219",
      "lon": "7.5806638",
      "superficie_kmq": "7.0300",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004088",
      "denominazione_ita_altra": "Feisoglio",
      "denominazione_ita": "Feisoglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D523",
      "lat": "44.5433513",
      "lon": "8.1041760",
      "superficie_kmq": "7.6666",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004089",
      "denominazione_ita_altra": "Fossano",
      "denominazione_ita": "Fossano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D742",
      "lat": "44.5493897",
      "lon": "7.7248703",
      "superficie_kmq": "130.1434",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004090",
      "denominazione_ita_altra": "Frabosa Soprana",
      "denominazione_ita": "Frabosa Soprana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D751",
      "lat": "44.2874616",
      "lon": "7.8070923",
      "superficie_kmq": "47.1437",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004091",
      "denominazione_ita_altra": "Frabosa Sottana",
      "denominazione_ita": "Frabosa Sottana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D752",
      "lat": "44.3020134",
      "lon": "7.7983106",
      "superficie_kmq": "37.6433",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004092",
      "denominazione_ita_altra": "Frassino",
      "denominazione_ita": "Frassino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D782",
      "lat": "44.5713491",
      "lon": "7.2756444",
      "superficie_kmq": "17.0106",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004093",
      "denominazione_ita_altra": "Gaiola",
      "denominazione_ita": "Gaiola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D856",
      "lat": "44.3352361",
      "lon": "7.4067619",
      "superficie_kmq": "4.9691",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004094",
      "denominazione_ita_altra": "Gambasca",
      "denominazione_ita": "Gambasca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D894",
      "lat": "44.6293666",
      "lon": "7.3474145",
      "superficie_kmq": "5.7412",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004095",
      "denominazione_ita_altra": "Garessio",
      "denominazione_ita": "Garessio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D920",
      "lat": "44.1972793",
      "lon": "8.0298843",
      "superficie_kmq": "131.2810",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004096",
      "denominazione_ita_altra": "Genola",
      "denominazione_ita": "Genola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D967",
      "lat": "44.5876841",
      "lon": "7.6627261",
      "superficie_kmq": "13.7224",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004097",
      "denominazione_ita_altra": "Gorzegno",
      "denominazione_ita": "Gorzegno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E111",
      "lat": "44.5118511",
      "lon": "8.1351176",
      "superficie_kmq": "13.9699",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004098",
      "denominazione_ita_altra": "Gottasecca",
      "denominazione_ita": "Gottasecca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E115",
      "lat": "44.4616859",
      "lon": "8.1672527",
      "superficie_kmq": "13.6769",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004099",
      "denominazione_ita_altra": "Govone",
      "denominazione_ita": "Govone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E118",
      "lat": "44.8048552",
      "lon": "8.0999574",
      "superficie_kmq": "18.9108",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004100",
      "denominazione_ita_altra": "Grinzane Cavour",
      "denominazione_ita": "Grinzane Cavour",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E182",
      "lat": "44.6565312",
      "lon": "7.9822138",
      "superficie_kmq": "3.8082",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004101",
      "denominazione_ita_altra": "Guarene",
      "denominazione_ita": "Guarene",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E251",
      "lat": "44.7377989",
      "lon": "8.0336748",
      "superficie_kmq": "13.4459",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004102",
      "denominazione_ita_altra": "Igliano",
      "denominazione_ita": "Igliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E282",
      "lat": "44.4435230",
      "lon": "8.0125036",
      "superficie_kmq": "3.4042",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004103",
      "denominazione_ita_altra": "Isasca",
      "denominazione_ita": "Isasca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E327",
      "lat": "44.5872868",
      "lon": "7.3815478",
      "superficie_kmq": "5.0784",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004104",
      "denominazione_ita_altra": "Lagnasco",
      "denominazione_ita": "Lagnasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E406",
      "lat": "44.6246535",
      "lon": "7.5553373",
      "superficie_kmq": "17.7124",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004105",
      "denominazione_ita_altra": "La Morra",
      "denominazione_ita": "La Morra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E430",
      "lat": "44.6373388",
      "lon": "7.9334641",
      "superficie_kmq": "24.1658",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004106",
      "denominazione_ita_altra": "Lequio Berria",
      "denominazione_ita": "Lequio Berria",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E540",
      "lat": "44.6045975",
      "lon": "8.0976718",
      "superficie_kmq": "11.8291",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004107",
      "denominazione_ita_altra": "Lequio Tanaro",
      "denominazione_ita": "Lequio Tanaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E539",
      "lat": "44.5602244",
      "lon": "7.8835990",
      "superficie_kmq": "12.2004",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004108",
      "denominazione_ita_altra": "Lesegno",
      "denominazione_ita": "Lesegno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E546",
      "lat": "44.4012231",
      "lon": "7.9665059",
      "superficie_kmq": "14.2414",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004109",
      "denominazione_ita_altra": "Levice",
      "denominazione_ita": "Levice",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E564",
      "lat": "44.5376509",
      "lon": "8.1566196",
      "superficie_kmq": "15.7390",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004110",
      "denominazione_ita_altra": "Limone Piemonte",
      "denominazione_ita": "Limone Piemonte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E597",
      "lat": "44.2012791",
      "lon": "7.5760798",
      "superficie_kmq": "70.8104",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004111",
      "denominazione_ita_altra": "Lisio",
      "denominazione_ita": "Lisio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E615",
      "lat": "44.3066855",
      "lon": "7.9796342",
      "superficie_kmq": "8.2287",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004112",
      "denominazione_ita_altra": "Macra",
      "denominazione_ita": "Macra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E789",
      "lat": "44.4998498",
      "lon": "7.1792040",
      "superficie_kmq": "24.6562",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004113",
      "denominazione_ita_altra": "Magliano Alfieri",
      "denominazione_ita": "Magliano Alfieri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E809",
      "lat": "44.7664631",
      "lon": "8.0678111",
      "superficie_kmq": "9.5000",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004114",
      "denominazione_ita_altra": "Magliano Alpi",
      "denominazione_ita": "Magliano Alpi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E808",
      "lat": "44.4580908",
      "lon": "7.7995525",
      "superficie_kmq": "33.2204",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004115",
      "denominazione_ita_altra": "Mango",
      "denominazione_ita": "Mango",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E887",
      "lat": "44.6846084",
      "lon": "8.1503312",
      "superficie_kmq": "20.0310",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004116",
      "denominazione_ita_altra": "Manta",
      "denominazione_ita": "Manta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E894",
      "lat": "44.6143247",
      "lon": "7.4862618",
      "superficie_kmq": "11.7345",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004117",
      "denominazione_ita_altra": "Marene",
      "denominazione_ita": "Marene",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E939",
      "lat": "44.6614900",
      "lon": "7.7291517",
      "superficie_kmq": "28.9869",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004118",
      "denominazione_ita_altra": "Margarita",
      "denominazione_ita": "Margarita",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E945",
      "lat": "44.4026359",
      "lon": "7.6868579",
      "superficie_kmq": "11.3762",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004119",
      "denominazione_ita_altra": "Marmora",
      "denominazione_ita": "Marmora",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E963",
      "lat": "44.4574854",
      "lon": "7.0944986",
      "superficie_kmq": "41.1258",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004120",
      "denominazione_ita_altra": "Marsaglia",
      "denominazione_ita": "Marsaglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E973",
      "lat": "44.4521745",
      "lon": "7.9829862",
      "superficie_kmq": "13.0258",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004121",
      "denominazione_ita_altra": "Martiniana Po",
      "denominazione_ita": "Martiniana Po",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E988",
      "lat": "44.6264098",
      "lon": "7.3663153",
      "superficie_kmq": "13.2806",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004122",
      "denominazione_ita_altra": "Melle",
      "denominazione_ita": "Melle",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F114",
      "lat": "44.5620471",
      "lon": "7.3205827",
      "superficie_kmq": "27.9056",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004123",
      "denominazione_ita_altra": "Moiola",
      "denominazione_ita": "Moiola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F279",
      "lat": "44.3196790",
      "lon": "7.3888721",
      "superficie_kmq": "15.0682",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004124",
      "denominazione_ita_altra": "Mombarcaro",
      "denominazione_ita": "Mombarcaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F309",
      "lat": "44.4677534",
      "lon": "8.0850933",
      "superficie_kmq": "20.5111",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004125",
      "denominazione_ita_altra": "Mombasiglio",
      "denominazione_ita": "Mombasiglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F312",
      "lat": "44.3633020",
      "lon": "7.9699964",
      "superficie_kmq": "17.3498",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004126",
      "denominazione_ita_altra": "Monastero di Vasco",
      "denominazione_ita": "Monastero di Vasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F326",
      "lat": "44.3401652",
      "lon": "7.8227613",
      "superficie_kmq": "17.4382",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004127",
      "denominazione_ita_altra": "Monasterolo Casotto",
      "denominazione_ita": "Monasterolo Casotto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F329",
      "lat": "44.3308236",
      "lon": "7.9411393",
      "superficie_kmq": "7.6796",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004128",
      "denominazione_ita_altra": "Monasterolo di Savigliano",
      "denominazione_ita": "Monasterolo di Savigliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F330",
      "lat": "44.6863291",
      "lon": "7.6207698",
      "superficie_kmq": "14.9114",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004129",
      "denominazione_ita_altra": "Monchiero",
      "denominazione_ita": "Monchiero",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F338",
      "lat": "44.5730385",
      "lon": "7.9181403",
      "superficie_kmq": "4.9903",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004130",
      "denominazione_ita_altra": "Mondovì",
      "denominazione_ita": "Mondovì",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F351",
      "lat": "44.3871664",
      "lon": "7.8198060",
      "superficie_kmq": "87.0516",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004131",
      "denominazione_ita_altra": "Monesiglio",
      "denominazione_ita": "Monesiglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F355",
      "lat": "44.4648859",
      "lon": "8.1163797",
      "superficie_kmq": "12.9330",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004132",
      "denominazione_ita_altra": "Monforte d'Alba",
      "denominazione_ita": "Monforte d'Alba",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F358",
      "lat": "44.5823276",
      "lon": "7.9684088",
      "superficie_kmq": "25.2745",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004133",
      "denominazione_ita_altra": "Montà",
      "denominazione_ita": "Montà",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F385",
      "lat": "44.8130871",
      "lon": "7.9600148",
      "superficie_kmq": "26.8203",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004134",
      "denominazione_ita_altra": "Montaldo di Mondovì",
      "denominazione_ita": "Montaldo di Mondovì",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F405",
      "lat": "44.3194575",
      "lon": "7.8658404",
      "superficie_kmq": "23.5797",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004135",
      "denominazione_ita_altra": "Montaldo Roero",
      "denominazione_ita": "Montaldo Roero",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F408",
      "lat": "44.7685622",
      "lon": "7.9244658",
      "superficie_kmq": "11.8403",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004136",
      "denominazione_ita_altra": "Montanera",
      "denominazione_ita": "Montanera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F424",
      "lat": "44.4619684",
      "lon": "7.6655736",
      "superficie_kmq": "11.6271",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004137",
      "denominazione_ita_altra": "Montelupo Albese",
      "denominazione_ita": "Montelupo Albese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F550",
      "lat": "44.6230610",
      "lon": "8.0463848",
      "superficie_kmq": "6.4303",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004138",
      "denominazione_ita_altra": "Montemale di Cuneo",
      "denominazione_ita": "Montemale di Cuneo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F558",
      "lat": "44.4376846",
      "lon": "7.3728143",
      "superficie_kmq": "11.1041",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004139",
      "denominazione_ita_altra": "Monterosso Grana",
      "denominazione_ita": "Monterosso Grana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F608",
      "lat": "44.4088779",
      "lon": "7.3239196",
      "superficie_kmq": "42.2211",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004140",
      "denominazione_ita_altra": "Monteu Roero",
      "denominazione_ita": "Monteu Roero",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F654",
      "lat": "44.7802313",
      "lon": "7.9308693",
      "superficie_kmq": "24.6999",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004141",
      "denominazione_ita_altra": "Montezemolo",
      "denominazione_ita": "Montezemolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F666",
      "lat": "44.3782287",
      "lon": "8.1414670",
      "superficie_kmq": "6.9363",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004142",
      "denominazione_ita_altra": "Monticello d'Alba",
      "denominazione_ita": "Monticello d'Alba",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F669",
      "lat": "44.7182847",
      "lon": "7.9410088",
      "superficie_kmq": "10.2358",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004143",
      "denominazione_ita_altra": "Moretta",
      "denominazione_ita": "Moretta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F723",
      "lat": "44.7639001",
      "lon": "7.5369242",
      "superficie_kmq": "23.9934",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004144",
      "denominazione_ita_altra": "Morozzo",
      "denominazione_ita": "Morozzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F743",
      "lat": "44.4230301",
      "lon": "7.7104467",
      "superficie_kmq": "22.1919",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004145",
      "denominazione_ita_altra": "Murazzano",
      "denominazione_ita": "Murazzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F809",
      "lat": "44.4737450",
      "lon": "8.0188472",
      "superficie_kmq": "27.6758",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004146",
      "denominazione_ita_altra": "Murello",
      "denominazione_ita": "Murello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F811",
      "lat": "44.7529544",
      "lon": "7.6009425",
      "superficie_kmq": "17.3282",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004147",
      "denominazione_ita_altra": "Narzole",
      "denominazione_ita": "Narzole",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F846",
      "lat": "44.5941489",
      "lon": "7.8705198",
      "superficie_kmq": "26.1826",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004148",
      "denominazione_ita_altra": "Neive",
      "denominazione_ita": "Neive",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F863",
      "lat": "44.7263100",
      "lon": "8.1154993",
      "superficie_kmq": "21.3028",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004149",
      "denominazione_ita_altra": "Neviglie",
      "denominazione_ita": "Neviglie",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F883",
      "lat": "44.6921174",
      "lon": "8.1159765",
      "superficie_kmq": "7.9261",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004150",
      "denominazione_ita_altra": "Niella Belbo",
      "denominazione_ita": "Niella Belbo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F894",
      "lat": "44.5132813",
      "lon": "8.0791207",
      "superficie_kmq": "11.5183",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004151",
      "denominazione_ita_altra": "Niella Tanaro",
      "denominazione_ita": "Niella Tanaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F895",
      "lat": "44.4148162",
      "lon": "7.9213586",
      "superficie_kmq": "15.7070",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004152",
      "denominazione_ita_altra": "Novello",
      "denominazione_ita": "Novello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F961",
      "lat": "44.5871493",
      "lon": "7.9276554",
      "superficie_kmq": "11.7055",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004153",
      "denominazione_ita_altra": "Nucetto",
      "denominazione_ita": "Nucetto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F972",
      "lat": "44.3393901",
      "lon": "8.0589157",
      "superficie_kmq": "7.8083",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004154",
      "denominazione_ita_altra": "Oncino",
      "denominazione_ita": "Oncino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G066",
      "lat": "44.6764915",
      "lon": "7.1902546",
      "superficie_kmq": "47.3592",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004155",
      "denominazione_ita_altra": "Ormea",
      "denominazione_ita": "Ormea",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G114",
      "lat": "44.1485801",
      "lon": "7.9106908",
      "superficie_kmq": "124.4931",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004156",
      "denominazione_ita_altra": "Ostana",
      "denominazione_ita": "Ostana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G183",
      "lat": "44.6922117",
      "lon": "7.1884822",
      "superficie_kmq": "14.0873",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004157",
      "denominazione_ita_altra": "Paesana",
      "denominazione_ita": "Paesana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G228",
      "lat": "44.6854403",
      "lon": "7.2765580",
      "superficie_kmq": "58.2693",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004158",
      "denominazione_ita_altra": "Pagno",
      "denominazione_ita": "Pagno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G240",
      "lat": "44.6137374",
      "lon": "7.4299360",
      "superficie_kmq": "8.6797",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004159",
      "denominazione_ita_altra": "Pamparato",
      "denominazione_ita": "Pamparato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G302",
      "lat": "44.2771135",
      "lon": "7.9138511",
      "superficie_kmq": "34.5115",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004160",
      "denominazione_ita_altra": "Paroldo",
      "denominazione_ita": "Paroldo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G339",
      "lat": "44.4316646",
      "lon": "8.0713186",
      "superficie_kmq": "12.4249",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004161",
      "denominazione_ita_altra": "Perletto",
      "denominazione_ita": "Perletto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G457",
      "lat": "44.5989889",
      "lon": "8.2140793",
      "superficie_kmq": "9.8932",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004162",
      "denominazione_ita_altra": "Perlo",
      "denominazione_ita": "Perlo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G458",
      "lat": "44.3330791",
      "lon": "8.0836067",
      "superficie_kmq": "10.2155",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004163",
      "denominazione_ita_altra": "Peveragno",
      "denominazione_ita": "Peveragno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G526",
      "lat": "44.3300008",
      "lon": "7.6171395",
      "superficie_kmq": "67.9186",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004164",
      "denominazione_ita_altra": "Pezzolo Valle Uzzone",
      "denominazione_ita": "Pezzolo Valle Uzzone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G532",
      "lat": "44.5392680",
      "lon": "8.1941101",
      "superficie_kmq": "26.5691",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004165",
      "denominazione_ita_altra": "Pianfei",
      "denominazione_ita": "Pianfei",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G561",
      "lat": "44.3704795",
      "lon": "7.7097584",
      "superficie_kmq": "15.3103",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004166",
      "denominazione_ita_altra": "Piasco",
      "denominazione_ita": "Piasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G575",
      "lat": "44.5685502",
      "lon": "7.4574076",
      "superficie_kmq": "10.6214",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004167",
      "denominazione_ita_altra": "Pietraporzio",
      "denominazione_ita": "Pietraporzio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G625",
      "lat": "44.3440005",
      "lon": "7.0319433",
      "superficie_kmq": "55.1856",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004168",
      "denominazione_ita_altra": "Piobesi d'Alba",
      "denominazione_ita": "Piobesi d'Alba",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G683",
      "lat": "44.7349039",
      "lon": "7.9785127",
      "superficie_kmq": "4.0347",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004169",
      "denominazione_ita_altra": "Piozzo",
      "denominazione_ita": "Piozzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G697",
      "lat": "44.5157538",
      "lon": "7.8924222",
      "superficie_kmq": "14.3043",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004170",
      "denominazione_ita_altra": "Pocapaglia",
      "denominazione_ita": "Pocapaglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G742",
      "lat": "44.7194539",
      "lon": "7.8871859",
      "superficie_kmq": "17.4774",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004171",
      "denominazione_ita_altra": "Polonghera",
      "denominazione_ita": "Polonghera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G800",
      "lat": "44.8033469",
      "lon": "7.5960731",
      "superficie_kmq": "10.3146",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004172",
      "denominazione_ita_altra": "Pontechianale",
      "denominazione_ita": "Pontechianale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G837",
      "lat": "44.6219829",
      "lon": "7.0283117",
      "superficie_kmq": "94.9229",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004173",
      "denominazione_ita_altra": "Pradleves",
      "denominazione_ita": "Pradleves",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G970",
      "lat": "44.4185879",
      "lon": "7.2820866",
      "superficie_kmq": "19.2934",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004174",
      "denominazione_ita_altra": "Prazzo",
      "denominazione_ita": "Prazzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H011",
      "lat": "44.4830668",
      "lon": "7.0554308",
      "superficie_kmq": "52.3851",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004175",
      "denominazione_ita_altra": "Priero",
      "denominazione_ita": "Priero",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H059",
      "lat": "44.3766067",
      "lon": "8.0924196",
      "superficie_kmq": "20.0092",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004176",
      "denominazione_ita_altra": "Priocca",
      "denominazione_ita": "Priocca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H068",
      "lat": "44.7913025",
      "lon": "8.0627276",
      "superficie_kmq": "9.0306",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004177",
      "denominazione_ita_altra": "Priola",
      "denominazione_ita": "Priola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H069",
      "lat": "44.2459639",
      "lon": "8.0224240",
      "superficie_kmq": "27.3754",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004178",
      "denominazione_ita_altra": "Prunetto",
      "denominazione_ita": "Prunetto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H085",
      "lat": "44.4894399",
      "lon": "8.1443118",
      "superficie_kmq": "14.3615",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004179",
      "denominazione_ita_altra": "Racconigi",
      "denominazione_ita": "Racconigi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H150",
      "lat": "44.7678184",
      "lon": "7.6759818",
      "superficie_kmq": "48.0562",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004180",
      "denominazione_ita_altra": "Revello",
      "denominazione_ita": "Revello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H247",
      "lat": "44.6546551",
      "lon": "7.3890061",
      "superficie_kmq": "52.4700",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004181",
      "denominazione_ita_altra": "Rifreddo",
      "denominazione_ita": "Rifreddo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H285",
      "lat": "44.6504913",
      "lon": "7.3490980",
      "superficie_kmq": "6.8436",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004182",
      "denominazione_ita_altra": "Rittana",
      "denominazione_ita": "Rittana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H326",
      "lat": "44.3505597",
      "lon": "7.4006354",
      "superficie_kmq": "11.3520",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004183",
      "denominazione_ita_altra": "Roaschia",
      "denominazione_ita": "Roaschia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H362",
      "lat": "44.2701202",
      "lon": "7.4561403",
      "superficie_kmq": "23.8368",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004184",
      "denominazione_ita_altra": "Roascio",
      "denominazione_ita": "Roascio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H363",
      "lat": "44.4171546",
      "lon": "8.0225466",
      "superficie_kmq": "6.4212",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004185",
      "denominazione_ita_altra": "Robilante",
      "denominazione_ita": "Robilante",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H377",
      "lat": "44.2942177",
      "lon": "7.5111630",
      "superficie_kmq": "25.0038",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004186",
      "denominazione_ita_altra": "Roburent",
      "denominazione_ita": "Roburent",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H378",
      "lat": "44.3049837",
      "lon": "7.8902071",
      "superficie_kmq": "29.8104",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004187",
      "denominazione_ita_altra": "Roccabruna",
      "denominazione_ita": "Roccabruna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H385",
      "lat": "44.4762859",
      "lon": "7.3407474",
      "superficie_kmq": "24.2989",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004188",
      "denominazione_ita_altra": "Rocca Cigliè",
      "denominazione_ita": "Rocca Cigliè",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H391",
      "lat": "44.4440815",
      "lon": "7.9511452",
      "superficie_kmq": "7.0152",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004189",
      "denominazione_ita_altra": "Rocca de' Baldi",
      "denominazione_ita": "Rocca de' Baldi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H395",
      "lat": "44.4301673",
      "lon": "7.7456979",
      "superficie_kmq": "26.4023",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004190",
      "denominazione_ita_altra": "Roccaforte Mondovì",
      "denominazione_ita": "Roccaforte Mondovì",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H407",
      "lat": "44.3184143",
      "lon": "7.7458790",
      "superficie_kmq": "84.6105",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004191",
      "denominazione_ita_altra": "Roccasparvera",
      "denominazione_ita": "Roccasparvera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H447",
      "lat": "44.3416448",
      "lon": "7.4413420",
      "superficie_kmq": "11.2407",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004192",
      "denominazione_ita_altra": "Roccavione",
      "denominazione_ita": "Roccavione",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H453",
      "lat": "44.3143982",
      "lon": "7.4812565",
      "superficie_kmq": "19.1492",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004193",
      "denominazione_ita_altra": "Rocchetta Belbo",
      "denominazione_ita": "Rocchetta Belbo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H462",
      "lat": "44.6371262",
      "lon": "8.1752719",
      "superficie_kmq": "4.5076",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004194",
      "denominazione_ita_altra": "Roddi",
      "denominazione_ita": "Roddi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H472",
      "lat": "44.6784469",
      "lon": "7.9761077",
      "superficie_kmq": "9.3530",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004195",
      "denominazione_ita_altra": "Roddino",
      "denominazione_ita": "Roddino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H473",
      "lat": "44.5741003",
      "lon": "8.0187358",
      "superficie_kmq": "10.5849",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004196",
      "denominazione_ita_altra": "Rodello",
      "denominazione_ita": "Rodello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H474",
      "lat": "44.6306059",
      "lon": "8.0593158",
      "superficie_kmq": "8.9004",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004197",
      "denominazione_ita_altra": "Rossana",
      "denominazione_ita": "Rossana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H578",
      "lat": "44.5424915",
      "lon": "7.4326069",
      "superficie_kmq": "19.9166",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004198",
      "denominazione_ita_altra": "Ruffia",
      "denominazione_ita": "Ruffia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H633",
      "lat": "44.7072423",
      "lon": "7.6024441",
      "superficie_kmq": "7.5091",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004199",
      "denominazione_ita_altra": "Sale delle Langhe",
      "denominazione_ita": "Sale delle Langhe",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H695",
      "lat": "44.3970540",
      "lon": "8.0834556",
      "superficie_kmq": "10.5052",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004200",
      "denominazione_ita_altra": "Sale San Giovanni",
      "denominazione_ita": "Sale San Giovanni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H704",
      "lat": "44.3993697",
      "lon": "8.0786067",
      "superficie_kmq": "8.0742",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004201",
      "denominazione_ita_altra": "Saliceto",
      "denominazione_ita": "Saliceto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H710",
      "lat": "44.4132682",
      "lon": "8.1667914",
      "superficie_kmq": "24.3254",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004202",
      "denominazione_ita_altra": "Salmour",
      "denominazione_ita": "Salmour",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H716",
      "lat": "44.5781464",
      "lon": "7.7921231",
      "superficie_kmq": "12.6960",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004203",
      "denominazione_ita_altra": "Saluzzo",
      "denominazione_ita": "Saluzzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H727",
      "lat": "44.6470162",
      "lon": "7.4908476",
      "superficie_kmq": "79.9306",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004204",
      "denominazione_ita_altra": "Sambuco",
      "denominazione_ita": "Sambuco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H746",
      "lat": "44.3373312",
      "lon": "7.0778104",
      "superficie_kmq": "46.1392",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004205",
      "denominazione_ita_altra": "Sampeyre",
      "denominazione_ita": "Sampeyre",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H755",
      "lat": "44.5793754",
      "lon": "7.1888737",
      "superficie_kmq": "98.9069",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004206",
      "denominazione_ita_altra": "San Benedetto Belbo",
      "denominazione_ita": "San Benedetto Belbo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H770",
      "lat": "44.4898837",
      "lon": "8.0578511",
      "superficie_kmq": "4.8454",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004207",
      "denominazione_ita_altra": "San Damiano Macra",
      "denominazione_ita": "San Damiano Macra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H812",
      "lat": "44.4884055",
      "lon": "7.2563092",
      "superficie_kmq": "54.2618",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004208",
      "denominazione_ita_altra": "Sanfrè",
      "denominazione_ita": "Sanfrè",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H851",
      "lat": "44.7521861",
      "lon": "7.8035497",
      "superficie_kmq": "15.5040",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004209",
      "denominazione_ita_altra": "Sanfront",
      "denominazione_ita": "Sanfront",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H852",
      "lat": "44.6458313",
      "lon": "7.3202481",
      "superficie_kmq": "39.7088",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004210",
      "denominazione_ita_altra": "San Michele Mondovì",
      "denominazione_ita": "San Michele Mondovì",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I037",
      "lat": "44.3765173",
      "lon": "7.9082487",
      "superficie_kmq": "18.1119",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004211",
      "denominazione_ita_altra": "Sant'Albano Stura",
      "denominazione_ita": "Sant'Albano Stura",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I210",
      "lat": "44.5099189",
      "lon": "7.7210531",
      "superficie_kmq": "27.4544",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004212",
      "denominazione_ita_altra": "Santa Vittoria d'Alba",
      "denominazione_ita": "Santa Vittoria d'Alba",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I316",
      "lat": "44.6965249",
      "lon": "7.9331557",
      "superficie_kmq": "10.0758",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004213",
      "denominazione_ita_altra": "Santo Stefano Belbo",
      "denominazione_ita": "Santo Stefano Belbo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I367",
      "lat": "44.7074852",
      "lon": "8.2310162",
      "superficie_kmq": "27.1836",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004214",
      "denominazione_ita_altra": "Santo Stefano Roero",
      "denominazione_ita": "Santo Stefano Roero",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I372",
      "lat": "44.7888149",
      "lon": "7.9399096",
      "superficie_kmq": "13.1142",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004215",
      "denominazione_ita_altra": "Savigliano",
      "denominazione_ita": "Savigliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I470",
      "lat": "44.6480119",
      "lon": "7.6574792",
      "superficie_kmq": "110.7876",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004216",
      "denominazione_ita_altra": "Scagnello",
      "denominazione_ita": "Scagnello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I484",
      "lat": "44.3322954",
      "lon": "7.9867937",
      "superficie_kmq": "9.3878",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004217",
      "denominazione_ita_altra": "Scarnafigi",
      "denominazione_ita": "Scarnafigi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I512",
      "lat": "44.6817494",
      "lon": "7.5669268",
      "superficie_kmq": "30.4979",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004218",
      "denominazione_ita_altra": "Serralunga d'Alba",
      "denominazione_ita": "Serralunga d'Alba",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I646",
      "lat": "44.6092145",
      "lon": "8.0006134",
      "superficie_kmq": "8.3908",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004219",
      "denominazione_ita_altra": "Serravalle Langhe",
      "denominazione_ita": "Serravalle Langhe",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I659",
      "lat": "44.5609100",
      "lon": "8.0576620",
      "superficie_kmq": "8.8995",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004220",
      "denominazione_ita_altra": "Sinio",
      "denominazione_ita": "Sinio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I750",
      "lat": "44.5994756",
      "lon": "8.0215610",
      "superficie_kmq": "8.6012",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004221",
      "denominazione_ita_altra": "Somano",
      "denominazione_ita": "Somano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I817",
      "lat": "44.5358849",
      "lon": "8.0114022",
      "superficie_kmq": "11.6848",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004222",
      "denominazione_ita_altra": "Sommariva del Bosco",
      "denominazione_ita": "Sommariva del Bosco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I822",
      "lat": "44.7712808",
      "lon": "7.7842659",
      "superficie_kmq": "35.4155",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004223",
      "denominazione_ita_altra": "Sommariva Perno",
      "denominazione_ita": "Sommariva Perno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I823",
      "lat": "44.7431871",
      "lon": "7.9055758",
      "superficie_kmq": "17.0935",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004224",
      "denominazione_ita_altra": "Stroppo",
      "denominazione_ita": "Stroppo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I985",
      "lat": "44.5056334",
      "lon": "7.1259127",
      "superficie_kmq": "28.0956",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004225",
      "denominazione_ita_altra": "Tarantasca",
      "denominazione_ita": "Tarantasca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L048",
      "lat": "44.4934071",
      "lon": "7.5450606",
      "superficie_kmq": "12.2016",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004226",
      "denominazione_ita_altra": "Torre Bormida",
      "denominazione_ita": "Torre Bormida",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L252",
      "lat": "44.5630946",
      "lon": "8.1556107",
      "superficie_kmq": "7.1829",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004227",
      "denominazione_ita_altra": "Torre Mondovì",
      "denominazione_ita": "Torre Mondovì",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L241",
      "lat": "44.3520291",
      "lon": "7.9003514",
      "superficie_kmq": "18.5376",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004228",
      "denominazione_ita_altra": "Torre San Giorgio",
      "denominazione_ita": "Torre San Giorgio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L278",
      "lat": "44.7358156",
      "lon": "7.5299411",
      "superficie_kmq": "5.3901",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004229",
      "denominazione_ita_altra": "Torresina",
      "denominazione_ita": "Torresina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L281",
      "lat": "44.4321989",
      "lon": "8.0347641",
      "superficie_kmq": "3.8226",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004230",
      "denominazione_ita_altra": "Treiso",
      "denominazione_ita": "Treiso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L367",
      "lat": "44.6893676",
      "lon": "8.0868493",
      "superficie_kmq": "9.5956",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004231",
      "denominazione_ita_altra": "Trezzo Tinella",
      "denominazione_ita": "Trezzo Tinella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L410",
      "lat": "44.6769474",
      "lon": "8.1075834",
      "superficie_kmq": "10.5253",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004232",
      "denominazione_ita_altra": "Trinità",
      "denominazione_ita": "Trinità",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L427",
      "lat": "44.5074601",
      "lon": "7.7580358",
      "superficie_kmq": "28.3367",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004233",
      "denominazione_ita_altra": "Valdieri",
      "denominazione_ita": "Valdieri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L558",
      "lat": "44.2776299",
      "lon": "7.3961360",
      "superficie_kmq": "153.3126",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004234",
      "denominazione_ita_altra": "Valgrana",
      "denominazione_ita": "Valgrana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L580",
      "lat": "44.4095986",
      "lon": "7.3806149",
      "superficie_kmq": "23.1017",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004235",
      "denominazione_ita_altra": "Valloriate",
      "denominazione_ita": "Valloriate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L631",
      "lat": "44.3381374",
      "lon": "7.3745655",
      "superficie_kmq": "16.9559",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004237",
      "denominazione_ita_altra": "Venasca",
      "denominazione_ita": "Venasca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L729",
      "lat": "44.5625348",
      "lon": "7.3949520",
      "superficie_kmq": "20.3938",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004238",
      "denominazione_ita_altra": "Verduno",
      "denominazione_ita": "Verduno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L758",
      "lat": "44.6681141",
      "lon": "7.9337670",
      "superficie_kmq": "7.1571",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004239",
      "denominazione_ita_altra": "Vernante",
      "denominazione_ita": "Vernante",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L771",
      "lat": "44.2455519",
      "lon": "7.5337036",
      "superficie_kmq": "62.0563",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004240",
      "denominazione_ita_altra": "Verzuolo",
      "denominazione_ita": "Verzuolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L804",
      "lat": "44.5995468",
      "lon": "7.4831278",
      "superficie_kmq": "26.1320",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004241",
      "denominazione_ita_altra": "Vezza d'Alba",
      "denominazione_ita": "Vezza d'Alba",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L817",
      "lat": "44.7638950",
      "lon": "7.9960910",
      "superficie_kmq": "14.0688",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004242",
      "denominazione_ita_altra": "Vicoforte",
      "denominazione_ita": "Vicoforte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L841",
      "lat": "44.3749448",
      "lon": "7.8649884",
      "superficie_kmq": "25.7363",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004243",
      "denominazione_ita_altra": "Vignolo",
      "denominazione_ita": "Vignolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L888",
      "lat": "44.3595471",
      "lon": "7.4698938",
      "superficie_kmq": "7.9417",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004244",
      "denominazione_ita_altra": "Villafalletto",
      "denominazione_ita": "Villafalletto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L942",
      "lat": "44.5442728",
      "lon": "7.5388949",
      "superficie_kmq": "29.7324",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004245",
      "denominazione_ita_altra": "Villanova Mondovì",
      "denominazione_ita": "Villanova Mondovì",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L974",
      "lat": "44.3444484",
      "lon": "7.7620933",
      "superficie_kmq": "28.2878",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004246",
      "denominazione_ita_altra": "Villanova Solaro",
      "denominazione_ita": "Villanova Solaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L990",
      "lat": "44.7296321",
      "lon": "7.5760324",
      "superficie_kmq": "14.7884",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004247",
      "denominazione_ita_altra": "Villar San Costanzo",
      "denominazione_ita": "Villar San Costanzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M015",
      "lat": "44.4829972",
      "lon": "7.3802981",
      "superficie_kmq": "19.4958",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004248",
      "denominazione_ita_altra": "Vinadio",
      "denominazione_ita": "Vinadio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M055",
      "lat": "44.3070790",
      "lon": "7.1743390",
      "superficie_kmq": "183.1692",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004249",
      "denominazione_ita_altra": "Viola",
      "denominazione_ita": "Viola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M063",
      "lat": "44.2904658",
      "lon": "7.9662355",
      "superficie_kmq": "21.0672",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "CN",
      "codice_istat": "004250",
      "denominazione_ita_altra": "Vottignasco",
      "denominazione_ita": "Vottignasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M136",
      "lat": "44.5648070",
      "lon": "7.5782137",
      "superficie_kmq": "8.0900",
      "codice_sovracomunale": "004"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005001",
      "denominazione_ita_altra": "Agliano Terme",
      "denominazione_ita": "Agliano Terme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A072",
      "lat": "44.7907000",
      "lon": "8.2511025",
      "superficie_kmq": "15.4485",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005002",
      "denominazione_ita_altra": "Albugnano",
      "denominazione_ita": "Albugnano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A173",
      "lat": "45.0780173",
      "lon": "7.9715283",
      "superficie_kmq": "9.5399",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005003",
      "denominazione_ita_altra": "Antignano",
      "denominazione_ita": "Antignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A312",
      "lat": "44.8454381",
      "lon": "8.1337873",
      "superficie_kmq": "10.8608",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005004",
      "denominazione_ita_altra": "Aramengo",
      "denominazione_ita": "Aramengo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A352",
      "lat": "45.0997329",
      "lon": "8.0019807",
      "superficie_kmq": "11.4124",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005005",
      "denominazione_ita_altra": "Asti",
      "denominazione_ita": "Asti",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "A479",
      "lat": "44.8991260",
      "lon": "8.2041426",
      "superficie_kmq": "151.3113",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005006",
      "denominazione_ita_altra": "Azzano d'Asti",
      "denominazione_ita": "Azzano d'Asti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A527",
      "lat": "44.8746943",
      "lon": "8.2676590",
      "superficie_kmq": "6.4341",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005007",
      "denominazione_ita_altra": "Baldichieri d'Asti",
      "denominazione_ita": "Baldichieri d'Asti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A588",
      "lat": "44.9069940",
      "lon": "8.0911038",
      "superficie_kmq": "5.0660",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005008",
      "denominazione_ita_altra": "Belveglio",
      "denominazione_ita": "Belveglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A770",
      "lat": "44.8304829",
      "lon": "8.3301043",
      "superficie_kmq": "5.2796",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005009",
      "denominazione_ita_altra": "Berzano di San Pietro",
      "denominazione_ita": "Berzano di San Pietro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A812",
      "lat": "45.0949077",
      "lon": "7.9540495",
      "superficie_kmq": "7.3430",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005010",
      "denominazione_ita_altra": "Bruno",
      "denominazione_ita": "Bruno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B221",
      "lat": "44.7906280",
      "lon": "8.4402729",
      "superficie_kmq": "8.9008",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005011",
      "denominazione_ita_altra": "Bubbio",
      "denominazione_ita": "Bubbio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B236",
      "lat": "44.6638362",
      "lon": "8.2936090",
      "superficie_kmq": "15.7621",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005012",
      "denominazione_ita_altra": "Buttigliera d'Asti",
      "denominazione_ita": "Buttigliera d'Asti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B306",
      "lat": "45.0225449",
      "lon": "7.9505128",
      "superficie_kmq": "19.1608",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005013",
      "denominazione_ita_altra": "Calamandrana",
      "denominazione_ita": "Calamandrana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B376",
      "lat": "44.7365437",
      "lon": "8.3356275",
      "superficie_kmq": "12.7884",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005014",
      "denominazione_ita_altra": "Calliano Monferrato",
      "denominazione_ita": "Calliano Monferrato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B418",
      "lat": "45.0062598",
      "lon": "8.2573264",
      "superficie_kmq": "17.2931",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005015",
      "denominazione_ita_altra": "Calosso",
      "denominazione_ita": "Calosso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B425",
      "lat": "44.7394711",
      "lon": "8.2271856",
      "superficie_kmq": "15.7149",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005016",
      "denominazione_ita_altra": "Camerano Casasco",
      "denominazione_ita": "Camerano Casasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B469",
      "lat": "44.9886577",
      "lon": "8.0908805",
      "superficie_kmq": "6.8923",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005017",
      "denominazione_ita_altra": "Canelli",
      "denominazione_ita": "Canelli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B594",
      "lat": "44.7198729",
      "lon": "8.2882031",
      "superficie_kmq": "23.4326",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005018",
      "denominazione_ita_altra": "Cantarana",
      "denominazione_ita": "Cantarana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B633",
      "lat": "44.9003807",
      "lon": "8.0307246",
      "superficie_kmq": "9.7214",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005019",
      "denominazione_ita_altra": "Capriglio",
      "denominazione_ita": "Capriglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B707",
      "lat": "45.0042278",
      "lon": "8.0081642",
      "superficie_kmq": "5.0565",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005020",
      "denominazione_ita_altra": "Casorzo Monferrato",
      "denominazione_ita": "Casorzo Monferrato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B991",
      "lat": "45.0228160",
      "lon": "8.3341398",
      "superficie_kmq": "12.6480",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005021",
      "denominazione_ita_altra": "Cassinasco",
      "denominazione_ita": "Cassinasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C022",
      "lat": "44.6886322",
      "lon": "8.3016610",
      "superficie_kmq": "11.8391",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005022",
      "denominazione_ita_altra": "Castagnole delle Lanze",
      "denominazione_ita": "Castagnole delle Lanze",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C049",
      "lat": "44.7549126",
      "lon": "8.1491783",
      "superficie_kmq": "21.5648",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005023",
      "denominazione_ita_altra": "Castagnole Monferrato",
      "denominazione_ita": "Castagnole Monferrato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C047",
      "lat": "44.9614416",
      "lon": "8.3060920",
      "superficie_kmq": "17.3374",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005024",
      "denominazione_ita_altra": "Castel Boglione",
      "denominazione_ita": "Castel Boglione",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C064",
      "lat": "44.7206138",
      "lon": "8.3811957",
      "superficie_kmq": "11.8622",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005025",
      "denominazione_ita_altra": "Castell'Alfero",
      "denominazione_ita": "Castell'Alfero",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C127",
      "lat": "44.9827081",
      "lon": "8.2094452",
      "superficie_kmq": "20.0881",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005026",
      "denominazione_ita_altra": "Castellero",
      "denominazione_ita": "Castellero",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C154",
      "lat": "44.9240028",
      "lon": "8.0747486",
      "superficie_kmq": "4.2882",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005027",
      "denominazione_ita_altra": "Castelletto Molina",
      "denominazione_ita": "Castelletto Molina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C161",
      "lat": "44.7503030",
      "lon": "8.4328345",
      "superficie_kmq": "3.0662",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005028",
      "denominazione_ita_altra": "Castello di Annone",
      "denominazione_ita": "Castello di Annone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A300",
      "lat": "44.8787617",
      "lon": "8.3136895",
      "superficie_kmq": "23.1785",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005029",
      "denominazione_ita_altra": "Castelnuovo Belbo",
      "denominazione_ita": "Castelnuovo Belbo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C226",
      "lat": "44.8018094",
      "lon": "8.4122613",
      "superficie_kmq": "9.5499",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005030",
      "denominazione_ita_altra": "Castelnuovo Calcea",
      "denominazione_ita": "Castelnuovo Calcea",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C230",
      "lat": "44.7882102",
      "lon": "8.2856362",
      "superficie_kmq": "8.2039",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005031",
      "denominazione_ita_altra": "Castelnuovo Don Bosco",
      "denominazione_ita": "Castelnuovo Don Bosco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C232",
      "lat": "45.0413409",
      "lon": "7.9636245",
      "superficie_kmq": "21.6068",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005032",
      "denominazione_ita_altra": "Castel Rocchero",
      "denominazione_ita": "Castel Rocchero",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C253",
      "lat": "44.7187915",
      "lon": "8.4159658",
      "superficie_kmq": "5.6273",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005033",
      "denominazione_ita_altra": "Cellarengo",
      "denominazione_ita": "Cellarengo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C438",
      "lat": "44.8659930",
      "lon": "7.9450011",
      "superficie_kmq": "10.8477",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005034",
      "denominazione_ita_altra": "Celle Enomondo",
      "denominazione_ita": "Celle Enomondo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C440",
      "lat": "44.8566295",
      "lon": "8.1229731",
      "superficie_kmq": "5.5928",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005035",
      "denominazione_ita_altra": "Cerreto d'Asti",
      "denominazione_ita": "Cerreto d'Asti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C528",
      "lat": "45.0497678",
      "lon": "8.0367647",
      "superficie_kmq": "3.9384",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005036",
      "denominazione_ita_altra": "Cerro Tanaro",
      "denominazione_ita": "Cerro Tanaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C533",
      "lat": "44.8726668",
      "lon": "8.3602087",
      "superficie_kmq": "4.6529",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005037",
      "denominazione_ita_altra": "Cessole",
      "denominazione_ita": "Cessole",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C583",
      "lat": "44.6479857",
      "lon": "8.2432015",
      "superficie_kmq": "11.7779",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005038",
      "denominazione_ita_altra": "Chiusano d'Asti",
      "denominazione_ita": "Chiusano d'Asti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C658",
      "lat": "44.9850527",
      "lon": "8.1178076",
      "superficie_kmq": "2.4170",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005039",
      "denominazione_ita_altra": "Cinaglio",
      "denominazione_ita": "Cinaglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C701",
      "lat": "44.9748418",
      "lon": "8.1001665",
      "superficie_kmq": "5.5045",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005040",
      "denominazione_ita_altra": "Cisterna d'Asti",
      "denominazione_ita": "Cisterna d'Asti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C739",
      "lat": "44.8261717",
      "lon": "8.0048210",
      "superficie_kmq": "10.7326",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005041",
      "denominazione_ita_altra": "Coazzolo",
      "denominazione_ita": "Coazzolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C804",
      "lat": "44.7282092",
      "lon": "8.1451412",
      "superficie_kmq": "4.0489",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005042",
      "denominazione_ita_altra": "Cocconato",
      "denominazione_ita": "Cocconato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C807",
      "lat": "45.0871815",
      "lon": "8.0392394",
      "superficie_kmq": "16.5226",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005044",
      "denominazione_ita_altra": "Corsione",
      "denominazione_ita": "Corsione",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D046",
      "lat": "45.0007454",
      "lon": "8.1451364",
      "superficie_kmq": "5.0824",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005045",
      "denominazione_ita_altra": "Cortandone",
      "denominazione_ita": "Cortandone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D050",
      "lat": "44.9596886",
      "lon": "8.0575712",
      "superficie_kmq": "5.0231",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005046",
      "denominazione_ita_altra": "Cortanze",
      "denominazione_ita": "Cortanze",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D051",
      "lat": "45.0152236",
      "lon": "8.0895934",
      "superficie_kmq": "4.4766",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005047",
      "denominazione_ita_altra": "Cortazzone",
      "denominazione_ita": "Cortazzone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D052",
      "lat": "44.9795332",
      "lon": "8.0619238",
      "superficie_kmq": "10.3333",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005048",
      "denominazione_ita_altra": "Cortiglione",
      "denominazione_ita": "Cortiglione",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D072",
      "lat": "44.8230145",
      "lon": "8.3585651",
      "superficie_kmq": "8.4254",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005049",
      "denominazione_ita_altra": "Cossombrato",
      "denominazione_ita": "Cossombrato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D101",
      "lat": "44.9941207",
      "lon": "8.1375273",
      "superficie_kmq": "5.3987",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005050",
      "denominazione_ita_altra": "Costigliole d'Asti",
      "denominazione_ita": "Costigliole d'Asti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D119",
      "lat": "44.7882167",
      "lon": "8.1806280",
      "superficie_kmq": "36.9432",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005051",
      "denominazione_ita_altra": "Cunico",
      "denominazione_ita": "Cunico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D207",
      "lat": "45.0404348",
      "lon": "8.0972115",
      "superficie_kmq": "6.7531",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005052",
      "denominazione_ita_altra": "Dusino San Michele",
      "denominazione_ita": "Dusino San Michele",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D388",
      "lat": "44.9201841",
      "lon": "7.9722814",
      "superficie_kmq": "11.9251",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005053",
      "denominazione_ita_altra": "Ferrere",
      "denominazione_ita": "Ferrere",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D554",
      "lat": "44.8734007",
      "lon": "7.9938552",
      "superficie_kmq": "13.9270",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005054",
      "denominazione_ita_altra": "Fontanile",
      "denominazione_ita": "Fontanile",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D678",
      "lat": "44.7533737",
      "lon": "8.4221857",
      "superficie_kmq": "8.1300",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005055",
      "denominazione_ita_altra": "Frinco",
      "denominazione_ita": "Frinco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D802",
      "lat": "45.0055853",
      "lon": "8.1753657",
      "superficie_kmq": "7.2168",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005056",
      "denominazione_ita_altra": "Grana Monferrato",
      "denominazione_ita": "Grana Monferrato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E134",
      "lat": "44.9980498",
      "lon": "8.2991228",
      "superficie_kmq": "5.8883",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005057",
      "denominazione_ita_altra": "Grazzano Badoglio",
      "denominazione_ita": "Grazzano Badoglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E159",
      "lat": "45.0389144",
      "lon": "8.3135570",
      "superficie_kmq": "10.4721",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005058",
      "denominazione_ita_altra": "Incisa Scapaccino",
      "denominazione_ita": "Incisa Scapaccino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E295",
      "lat": "44.8083938",
      "lon": "8.3809458",
      "superficie_kmq": "20.7037",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005059",
      "denominazione_ita_altra": "Isola d'Asti",
      "denominazione_ita": "Isola d'Asti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E338",
      "lat": "44.8294713",
      "lon": "8.1772147",
      "superficie_kmq": "13.5026",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005060",
      "denominazione_ita_altra": "Loazzolo",
      "denominazione_ita": "Loazzolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E633",
      "lat": "44.6689613",
      "lon": "8.2585255",
      "superficie_kmq": "14.8219",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005061",
      "denominazione_ita_altra": "Maranzana",
      "denominazione_ita": "Maranzana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E917",
      "lat": "44.7597653",
      "lon": "8.4768203",
      "superficie_kmq": "4.3739",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005062",
      "denominazione_ita_altra": "Maretto",
      "denominazione_ita": "Maretto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E944",
      "lat": "44.9443539",
      "lon": "8.0342359",
      "superficie_kmq": "4.9251",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005063",
      "denominazione_ita_altra": "Moasca",
      "denominazione_ita": "Moasca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F254",
      "lat": "44.7625450",
      "lon": "8.2779396",
      "superficie_kmq": "4.1551",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005064",
      "denominazione_ita_altra": "Mombaldone",
      "denominazione_ita": "Mombaldone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F308",
      "lat": "44.5692293",
      "lon": "8.3292041",
      "superficie_kmq": "11.9588",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005065",
      "denominazione_ita_altra": "Mombaruzzo",
      "denominazione_ita": "Mombaruzzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F311",
      "lat": "44.7710213",
      "lon": "8.4486667",
      "superficie_kmq": "22.3971",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005066",
      "denominazione_ita_altra": "Mombercelli",
      "denominazione_ita": "Mombercelli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F316",
      "lat": "44.8169714",
      "lon": "8.2944274",
      "superficie_kmq": "14.2321",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005067",
      "denominazione_ita_altra": "Monale",
      "denominazione_ita": "Monale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F323",
      "lat": "44.9360941",
      "lon": "8.0716409",
      "superficie_kmq": "9.1104",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005068",
      "denominazione_ita_altra": "Monastero Bormida",
      "denominazione_ita": "Monastero Bormida",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F325",
      "lat": "44.6475161",
      "lon": "8.3246203",
      "superficie_kmq": "14.2057",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005069",
      "denominazione_ita_altra": "Moncalvo",
      "denominazione_ita": "Moncalvo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F336",
      "lat": "45.0513987",
      "lon": "8.2634659",
      "superficie_kmq": "17.4210",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005070",
      "denominazione_ita_altra": "Moncucco Torinese",
      "denominazione_ita": "Moncucco Torinese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F343",
      "lat": "45.0678824",
      "lon": "7.9321561",
      "superficie_kmq": "14.3291",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005071",
      "denominazione_ita_altra": "Mongardino",
      "denominazione_ita": "Mongardino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F361",
      "lat": "44.8488395",
      "lon": "8.2186989",
      "superficie_kmq": "6.8550",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005072",
      "denominazione_ita_altra": "Montabone",
      "denominazione_ita": "Montabone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F386",
      "lat": "44.6985955",
      "lon": "8.3903099",
      "superficie_kmq": "8.5373",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005073",
      "denominazione_ita_altra": "Montafia",
      "denominazione_ita": "Montafia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F390",
      "lat": "44.9891936",
      "lon": "8.0251525",
      "superficie_kmq": "14.4970",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005074",
      "denominazione_ita_altra": "Montaldo Scarampi",
      "denominazione_ita": "Montaldo Scarampi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F409",
      "lat": "44.8285962",
      "lon": "8.2573903",
      "superficie_kmq": "6.6348",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005075",
      "denominazione_ita_altra": "Montechiaro d'Asti",
      "denominazione_ita": "Montechiaro d'Asti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F468",
      "lat": "45.0082137",
      "lon": "8.1123822",
      "superficie_kmq": "10.1408",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005076",
      "denominazione_ita_altra": "Montegrosso d'Asti",
      "denominazione_ita": "Montegrosso d'Asti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F527",
      "lat": "44.8224078",
      "lon": "8.2387429",
      "superficie_kmq": "15.4192",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005077",
      "denominazione_ita_altra": "Montemagno Monferrato",
      "denominazione_ita": "Montemagno Monferrato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F556",
      "lat": "44.9840328",
      "lon": "8.3240069",
      "superficie_kmq": "16.0976",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005080",
      "denominazione_ita_altra": "Nizza Monferrato",
      "denominazione_ita": "Nizza Monferrato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F902",
      "lat": "44.7736140",
      "lon": "8.3565029",
      "superficie_kmq": "30.3632",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005081",
      "denominazione_ita_altra": "Olmo Gentile",
      "denominazione_ita": "Olmo Gentile",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G048",
      "lat": "44.5855501",
      "lon": "8.2470350",
      "superficie_kmq": "5.6170",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005082",
      "denominazione_ita_altra": "Passerano Marmorito",
      "denominazione_ita": "Passerano Marmorito",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G358",
      "lat": "45.0563933",
      "lon": "8.0187926",
      "superficie_kmq": "12.0260",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005083",
      "denominazione_ita_altra": "Penango",
      "denominazione_ita": "Penango",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G430",
      "lat": "45.0332578",
      "lon": "8.2501324",
      "superficie_kmq": "9.5630",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005084",
      "denominazione_ita_altra": "Piea",
      "denominazione_ita": "Piea",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G593",
      "lat": "45.0252897",
      "lon": "8.0720897",
      "superficie_kmq": "9.0013",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005085",
      "denominazione_ita_altra": "Pino d'Asti",
      "denominazione_ita": "Pino d'Asti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G676",
      "lat": "45.0581925",
      "lon": "7.9862550",
      "superficie_kmq": "4.0820",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005086",
      "denominazione_ita_altra": "Piovà Massaia",
      "denominazione_ita": "Piovà Massaia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G692",
      "lat": "45.0544849",
      "lon": "8.0509446",
      "superficie_kmq": "11.0244",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005087",
      "denominazione_ita_altra": "Portacomaro",
      "denominazione_ita": "Portacomaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G894",
      "lat": "44.9571107",
      "lon": "8.2585123",
      "superficie_kmq": "11.0036",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005088",
      "denominazione_ita_altra": "Quaranti",
      "denominazione_ita": "Quaranti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H102",
      "lat": "44.7510416",
      "lon": "8.4490793",
      "superficie_kmq": "2.8582",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005089",
      "denominazione_ita_altra": "Refrancore",
      "denominazione_ita": "Refrancore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H219",
      "lat": "44.9375746",
      "lon": "8.3427129",
      "superficie_kmq": "13.2049",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005090",
      "denominazione_ita_altra": "Revigliasco d'Asti",
      "denominazione_ita": "Revigliasco d'Asti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H250",
      "lat": "44.8587289",
      "lon": "8.1583649",
      "superficie_kmq": "8.8350",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005091",
      "denominazione_ita_altra": "Roatto",
      "denominazione_ita": "Roatto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H366",
      "lat": "44.9514989",
      "lon": "8.0266129",
      "superficie_kmq": "6.4171",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005092",
      "denominazione_ita_altra": "Robella",
      "denominazione_ita": "Robella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H376",
      "lat": "45.1023322",
      "lon": "8.1000560",
      "superficie_kmq": "12.1805",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005093",
      "denominazione_ita_altra": "Rocca d'Arazzo",
      "denominazione_ita": "Rocca d'Arazzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H392",
      "lat": "44.8738739",
      "lon": "8.2858155",
      "superficie_kmq": "12.5631",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005094",
      "denominazione_ita_altra": "Roccaverano",
      "denominazione_ita": "Roccaverano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H451",
      "lat": "44.5929831",
      "lon": "8.2719314",
      "superficie_kmq": "29.9827",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005095",
      "denominazione_ita_altra": "Rocchetta Palafea",
      "denominazione_ita": "Rocchetta Palafea",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H466",
      "lat": "44.7069446",
      "lon": "8.3457240",
      "superficie_kmq": "7.8377",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005096",
      "denominazione_ita_altra": "Rocchetta Tanaro",
      "denominazione_ita": "Rocchetta Tanaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H468",
      "lat": "44.8588644",
      "lon": "8.3443271",
      "superficie_kmq": "15.9109",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005097",
      "denominazione_ita_altra": "San Damiano d'Asti",
      "denominazione_ita": "San Damiano d'Asti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H811",
      "lat": "44.8346357",
      "lon": "8.0652310",
      "superficie_kmq": "47.8656",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005098",
      "denominazione_ita_altra": "San Giorgio Scarampi",
      "denominazione_ita": "San Giorgio Scarampi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H899",
      "lat": "44.6113127",
      "lon": "8.2420238",
      "superficie_kmq": "6.1477",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005099",
      "denominazione_ita_altra": "San Martino Alfieri",
      "denominazione_ita": "San Martino Alfieri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H987",
      "lat": "44.8185157",
      "lon": "8.1093710",
      "superficie_kmq": "7.2094",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005100",
      "denominazione_ita_altra": "San Marzano Oliveto",
      "denominazione_ita": "San Marzano Oliveto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I017",
      "lat": "44.7532191",
      "lon": "8.2961627",
      "superficie_kmq": "9.6838",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005101",
      "denominazione_ita_altra": "San Paolo Solbrito",
      "denominazione_ita": "San Paolo Solbrito",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I076",
      "lat": "44.9522670",
      "lon": "7.9727807",
      "superficie_kmq": "11.8691",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005103",
      "denominazione_ita_altra": "Scurzolengo",
      "denominazione_ita": "Scurzolengo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I555",
      "lat": "44.9657062",
      "lon": "8.2782601",
      "superficie_kmq": "5.3426",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005104",
      "denominazione_ita_altra": "Serole",
      "denominazione_ita": "Serole",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I637",
      "lat": "44.5537462",
      "lon": "8.2597238",
      "superficie_kmq": "12.3285",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005105",
      "denominazione_ita_altra": "Sessame",
      "denominazione_ita": "Sessame",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I678",
      "lat": "44.6689341",
      "lon": "8.3378293",
      "superficie_kmq": "8.4471",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005106",
      "denominazione_ita_altra": "Settime",
      "denominazione_ita": "Settime",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I698",
      "lat": "44.9640872",
      "lon": "8.1128920",
      "superficie_kmq": "6.6804",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005107",
      "denominazione_ita_altra": "Soglio",
      "denominazione_ita": "Soglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I781",
      "lat": "44.9974294",
      "lon": "8.0781248",
      "superficie_kmq": "3.2800",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005108",
      "denominazione_ita_altra": "Tigliole",
      "denominazione_ita": "Tigliole",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L168",
      "lat": "44.8872714",
      "lon": "8.0754687",
      "superficie_kmq": "16.1173",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005109",
      "denominazione_ita_altra": "Tonco",
      "denominazione_ita": "Tonco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L203",
      "lat": "45.0241068",
      "lon": "8.1898420",
      "superficie_kmq": "11.7833",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005111",
      "denominazione_ita_altra": "Vaglio Serra",
      "denominazione_ita": "Vaglio Serra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L531",
      "lat": "44.7965310",
      "lon": "8.3389334",
      "superficie_kmq": "4.7599",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005112",
      "denominazione_ita_altra": "Valfenera",
      "denominazione_ita": "Valfenera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L574",
      "lat": "44.8968709",
      "lon": "7.9650986",
      "superficie_kmq": "22.0383",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005113",
      "denominazione_ita_altra": "Vesime",
      "denominazione_ita": "Vesime",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L807",
      "lat": "44.6371886",
      "lon": "8.2282253",
      "superficie_kmq": "13.1655",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005114",
      "denominazione_ita_altra": "Viale",
      "denominazione_ita": "Viale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L829",
      "lat": "44.9992683",
      "lon": "8.0522905",
      "superficie_kmq": "3.9754",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005115",
      "denominazione_ita_altra": "Viarigi",
      "denominazione_ita": "Viarigi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L834",
      "lat": "44.9804490",
      "lon": "8.3586906",
      "superficie_kmq": "13.6219",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005116",
      "denominazione_ita_altra": "Vigliano d'Asti",
      "denominazione_ita": "Vigliano d'Asti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L879",
      "lat": "44.8351010",
      "lon": "8.2318906",
      "superficie_kmq": "6.6530",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005117",
      "denominazione_ita_altra": "Villafranca d'Asti",
      "denominazione_ita": "Villafranca d'Asti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L945",
      "lat": "44.9136684",
      "lon": "8.0322123",
      "superficie_kmq": "12.8798",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005118",
      "denominazione_ita_altra": "Villanova d'Asti",
      "denominazione_ita": "Villanova d'Asti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L984",
      "lat": "44.9418872",
      "lon": "7.9372218",
      "superficie_kmq": "41.9480",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005119",
      "denominazione_ita_altra": "Villa San Secondo",
      "denominazione_ita": "Villa San Secondo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M019",
      "lat": "45.0051825",
      "lon": "8.1352553",
      "superficie_kmq": "6.1268",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005120",
      "denominazione_ita_altra": "Vinchio",
      "denominazione_ita": "Vinchio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M058",
      "lat": "44.8074021",
      "lon": "8.3173016",
      "superficie_kmq": "9.2863",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005121",
      "denominazione_ita_altra": "Montiglio Monferrato",
      "denominazione_ita": "Montiglio Monferrato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M302",
      "lat": "45.0674124",
      "lon": "8.0977382",
      "superficie_kmq": "26.8624",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AT",
      "codice_istat": "005122",
      "denominazione_ita_altra": "Moransengo-Tonengo",
      "denominazione_ita": "Moransengo-Tonengo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M434",
      "lat": "45.1183415",
      "lon": "8.0164389",
      "superficie_kmq": "11.0005",
      "codice_sovracomunale": "005"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006001",
      "denominazione_ita_altra": "Acqui Terme",
      "denominazione_ita": "Acqui Terme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A052",
      "lat": "44.6766013",
      "lon": "8.4697522",
      "superficie_kmq": "33.2967",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006002",
      "denominazione_ita_altra": "Albera Ligure",
      "denominazione_ita": "Albera Ligure",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A146",
      "lat": "44.7009104",
      "lon": "9.0684268",
      "superficie_kmq": "21.2298",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006003",
      "denominazione_ita_altra": "Alessandria",
      "denominazione_ita": "Alessandria",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "A182",
      "lat": "44.9129705",
      "lon": "8.6154012",
      "superficie_kmq": "203.5657",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006004",
      "denominazione_ita_altra": "Alfiano Natta",
      "denominazione_ita": "Alfiano Natta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A189",
      "lat": "45.0472315",
      "lon": "8.2084598",
      "superficie_kmq": "13.1588",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006005",
      "denominazione_ita_altra": "Alice Bel Colle",
      "denominazione_ita": "Alice Bel Colle",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A197",
      "lat": "44.7257823",
      "lon": "8.4512007",
      "superficie_kmq": "12.2135",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006007",
      "denominazione_ita_altra": "Altavilla Monferrato",
      "denominazione_ita": "Altavilla Monferrato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A227",
      "lat": "44.9928977",
      "lon": "8.3757125",
      "superficie_kmq": "11.3280",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006008",
      "denominazione_ita_altra": "Alzano Scrivia",
      "denominazione_ita": "Alzano Scrivia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A245",
      "lat": "45.0175545",
      "lon": "8.8812870",
      "superficie_kmq": "2.1269",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006009",
      "denominazione_ita_altra": "Arquata Scrivia",
      "denominazione_ita": "Arquata Scrivia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A436",
      "lat": "44.6867333",
      "lon": "8.8857447",
      "superficie_kmq": "29.2434",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006010",
      "denominazione_ita_altra": "Avolasca",
      "denominazione_ita": "Avolasca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A523",
      "lat": "44.8029645",
      "lon": "8.9652382",
      "superficie_kmq": "12.2375",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006011",
      "denominazione_ita_altra": "Balzola",
      "denominazione_ita": "Balzola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A605",
      "lat": "45.1831971",
      "lon": "8.4025470",
      "superficie_kmq": "16.6197",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006012",
      "denominazione_ita_altra": "Basaluzzo",
      "denominazione_ita": "Basaluzzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A689",
      "lat": "44.7682890",
      "lon": "8.7059341",
      "superficie_kmq": "15.0526",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006013",
      "denominazione_ita_altra": "Bassignana",
      "denominazione_ita": "Bassignana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A708",
      "lat": "45.0032111",
      "lon": "8.7359431",
      "superficie_kmq": "28.7122",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006014",
      "denominazione_ita_altra": "Belforte Monferrato",
      "denominazione_ita": "Belforte Monferrato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A738",
      "lat": "44.6243621",
      "lon": "8.6616036",
      "superficie_kmq": "8.3258",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006015",
      "denominazione_ita_altra": "Bergamasco",
      "denominazione_ita": "Bergamasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A793",
      "lat": "44.8271011",
      "lon": "8.4552104",
      "superficie_kmq": "13.4397",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006016",
      "denominazione_ita_altra": "Berzano di Tortona",
      "denominazione_ita": "Berzano di Tortona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A813",
      "lat": "44.8764534",
      "lon": "8.9505959",
      "superficie_kmq": "2.8874",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006017",
      "denominazione_ita_altra": "Bistagno",
      "denominazione_ita": "Bistagno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A889",
      "lat": "44.6617871",
      "lon": "8.3685867",
      "superficie_kmq": "17.5936",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006018",
      "denominazione_ita_altra": "Borghetto di Borbera",
      "denominazione_ita": "Borghetto di Borbera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A998",
      "lat": "44.7310474",
      "lon": "8.9453502",
      "superficie_kmq": "39.3977",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006019",
      "denominazione_ita_altra": "Borgoratto Alessandrino",
      "denominazione_ita": "Borgoratto Alessandrino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B029",
      "lat": "44.8364862",
      "lon": "8.5394206",
      "superficie_kmq": "6.6034",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006020",
      "denominazione_ita_altra": "Borgo San Martino",
      "denominazione_ita": "Borgo San Martino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B037",
      "lat": "45.0890899",
      "lon": "8.5236214",
      "superficie_kmq": "9.7230",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006021",
      "denominazione_ita_altra": "Bosco Marengo",
      "denominazione_ita": "Bosco Marengo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B071",
      "lat": "44.8220868",
      "lon": "8.6759910",
      "superficie_kmq": "44.5297",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006022",
      "denominazione_ita_altra": "Bosio",
      "denominazione_ita": "Bosio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B080",
      "lat": "44.6477824",
      "lon": "8.7919125",
      "superficie_kmq": "67.6133",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006023",
      "denominazione_ita_altra": "Bozzole",
      "denominazione_ita": "Bozzole",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B109",
      "lat": "45.0678753",
      "lon": "8.6048772",
      "superficie_kmq": "9.0235",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006024",
      "denominazione_ita_altra": "Brignano-Frascata",
      "denominazione_ita": "Brignano-Frascata",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B179",
      "lat": "44.8119469",
      "lon": "9.0407960",
      "superficie_kmq": "17.5285",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006025",
      "denominazione_ita_altra": "Cabella Ligure",
      "denominazione_ita": "Cabella Ligure",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B311",
      "lat": "44.6730762",
      "lon": "9.0962927",
      "superficie_kmq": "46.6332",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006026",
      "denominazione_ita_altra": "Camagna Monferrato",
      "denominazione_ita": "Camagna Monferrato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B453",
      "lat": "45.0178457",
      "lon": "8.4303369",
      "superficie_kmq": "9.2493",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006027",
      "denominazione_ita_altra": "Camino",
      "denominazione_ita": "Camino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B482",
      "lat": "45.1622096",
      "lon": "8.2870447",
      "superficie_kmq": "18.4376",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006028",
      "denominazione_ita_altra": "Cantalupo Ligure",
      "denominazione_ita": "Cantalupo Ligure",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B629",
      "lat": "44.7189679",
      "lon": "9.0443591",
      "superficie_kmq": "24.0619",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006029",
      "denominazione_ita_altra": "Capriata d'Orba",
      "denominazione_ita": "Capriata d'Orba",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B701",
      "lat": "44.7299603",
      "lon": "8.6889378",
      "superficie_kmq": "28.4663",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006030",
      "denominazione_ita_altra": "Carbonara Scrivia",
      "denominazione_ita": "Carbonara Scrivia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B736",
      "lat": "44.8497637",
      "lon": "8.8707753",
      "superficie_kmq": "5.0526",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006031",
      "denominazione_ita_altra": "Carentino",
      "denominazione_ita": "Carentino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B765",
      "lat": "44.8288725",
      "lon": "8.4704697",
      "superficie_kmq": "9.7892",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006032",
      "denominazione_ita_altra": "Carezzano",
      "denominazione_ita": "Carezzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B769",
      "lat": "44.8067495",
      "lon": "8.9000082",
      "superficie_kmq": "10.4833",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006033",
      "denominazione_ita_altra": "Carpeneto",
      "denominazione_ita": "Carpeneto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B818",
      "lat": "44.6772490",
      "lon": "8.6063158",
      "superficie_kmq": "13.3394",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006034",
      "denominazione_ita_altra": "Carrega Ligure",
      "denominazione_ita": "Carrega Ligure",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B836",
      "lat": "44.6190667",
      "lon": "9.1756124",
      "superficie_kmq": "55.2555",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006035",
      "denominazione_ita_altra": "Carrosio",
      "denominazione_ita": "Carrosio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B840",
      "lat": "44.6593983",
      "lon": "8.8323311",
      "superficie_kmq": "6.9232",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006036",
      "denominazione_ita_altra": "Cartosio",
      "denominazione_ita": "Cartosio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B847",
      "lat": "44.5911938",
      "lon": "8.4185292",
      "superficie_kmq": "16.3412",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006037",
      "denominazione_ita_altra": "Casal Cermelli",
      "denominazione_ita": "Casal Cermelli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B870",
      "lat": "44.8357007",
      "lon": "8.6224245",
      "superficie_kmq": "12.1635",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006038",
      "denominazione_ita_altra": "Casaleggio Boiro",
      "denominazione_ita": "Casaleggio Boiro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B882",
      "lat": "44.6342933",
      "lon": "8.7316846",
      "superficie_kmq": "12.0104",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006039",
      "denominazione_ita_altra": "Casale Monferrato",
      "denominazione_ita": "Casale Monferrato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B885",
      "lat": "45.1350280",
      "lon": "8.4526060",
      "superficie_kmq": "86.2097",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006040",
      "denominazione_ita_altra": "Casalnoceto",
      "denominazione_ita": "Casalnoceto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B902",
      "lat": "44.9131601",
      "lon": "8.9819855",
      "superficie_kmq": "12.9866",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006041",
      "denominazione_ita_altra": "Casasco",
      "denominazione_ita": "Casasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B941",
      "lat": "44.8282217",
      "lon": "9.0060511",
      "superficie_kmq": "9.0373",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006043",
      "denominazione_ita_altra": "Cassine",
      "denominazione_ita": "Cassine",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C027",
      "lat": "44.7482100",
      "lon": "8.5254830",
      "superficie_kmq": "33.0925",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006044",
      "denominazione_ita_altra": "Cassinelle",
      "denominazione_ita": "Cassinelle",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C030",
      "lat": "44.6068391",
      "lon": "8.5604746",
      "superficie_kmq": "23.7721",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006045",
      "denominazione_ita_altra": "Castellania Coppi",
      "denominazione_ita": "Castellania Coppi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C137",
      "lat": "44.7988419",
      "lon": "8.9304523",
      "superficie_kmq": "7.2089",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006046",
      "denominazione_ita_altra": "Castellar Guidobono",
      "denominazione_ita": "Castellar Guidobono",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C142",
      "lat": "44.9062642",
      "lon": "8.9467351",
      "superficie_kmq": "2.4828",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006047",
      "denominazione_ita_altra": "Castellazzo Bormida",
      "denominazione_ita": "Castellazzo Bormida",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C148",
      "lat": "44.8454696",
      "lon": "8.5771165",
      "superficie_kmq": "45.1283",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006048",
      "denominazione_ita_altra": "Castelletto d'Erro",
      "denominazione_ita": "Castelletto d'Erro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C156",
      "lat": "44.6250784",
      "lon": "8.3944110",
      "superficie_kmq": "4.6600",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006049",
      "denominazione_ita_altra": "Castelletto d'Orba",
      "denominazione_ita": "Castelletto d'Orba",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C158",
      "lat": "44.6855620",
      "lon": "8.7022553",
      "superficie_kmq": "13.9770",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006050",
      "denominazione_ita_altra": "Castelletto Merli",
      "denominazione_ita": "Castelletto Merli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C160",
      "lat": "45.0752003",
      "lon": "8.2405562",
      "superficie_kmq": "11.5908",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006051",
      "denominazione_ita_altra": "Castelletto Monferrato",
      "denominazione_ita": "Castelletto Monferrato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C162",
      "lat": "44.9798675",
      "lon": "8.5662417",
      "superficie_kmq": "9.5776",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006052",
      "denominazione_ita_altra": "Castelnuovo Bormida",
      "denominazione_ita": "Castelnuovo Bormida",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C229",
      "lat": "44.7436825",
      "lon": "8.5462034",
      "superficie_kmq": "13.1107",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006053",
      "denominazione_ita_altra": "Castelnuovo Scrivia",
      "denominazione_ita": "Castelnuovo Scrivia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C243",
      "lat": "44.9794221",
      "lon": "8.8816324",
      "superficie_kmq": "45.4213",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006054",
      "denominazione_ita_altra": "Castelspina",
      "denominazione_ita": "Castelspina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C274",
      "lat": "44.8044664",
      "lon": "8.5824154",
      "superficie_kmq": "5.4872",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006055",
      "denominazione_ita_altra": "Cavatore",
      "denominazione_ita": "Cavatore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C387",
      "lat": "44.6309548",
      "lon": "8.4524130",
      "superficie_kmq": "10.4466",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006056",
      "denominazione_ita_altra": "Cella Monte",
      "denominazione_ita": "Cella Monte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C432",
      "lat": "45.0748007",
      "lon": "8.3906872",
      "superficie_kmq": "5.5524",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006057",
      "denominazione_ita_altra": "Cereseto",
      "denominazione_ita": "Cereseto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C503",
      "lat": "45.0860104",
      "lon": "8.3169408",
      "superficie_kmq": "10.4427",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006058",
      "denominazione_ita_altra": "Cerreto Grue",
      "denominazione_ita": "Cerreto Grue",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C507",
      "lat": "44.8418594",
      "lon": "8.9319766",
      "superficie_kmq": "4.7470",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006059",
      "denominazione_ita_altra": "Cerrina Monferrato",
      "denominazione_ita": "Cerrina Monferrato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C531",
      "lat": "45.1201920",
      "lon": "8.2152477",
      "superficie_kmq": "17.3008",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006060",
      "denominazione_ita_altra": "Coniolo",
      "denominazione_ita": "Coniolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C962",
      "lat": "45.1469086",
      "lon": "8.3720472",
      "superficie_kmq": "10.3002",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006061",
      "denominazione_ita_altra": "Conzano",
      "denominazione_ita": "Conzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C977",
      "lat": "45.0205408",
      "lon": "8.4560585",
      "superficie_kmq": "11.6117",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006062",
      "denominazione_ita_altra": "Costa Vescovato",
      "denominazione_ita": "Costa Vescovato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D102",
      "lat": "44.8182014",
      "lon": "8.9290162",
      "superficie_kmq": "7.8996",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006063",
      "denominazione_ita_altra": "Cremolino",
      "denominazione_ita": "Cremolino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D149",
      "lat": "44.6374906",
      "lon": "8.5858399",
      "superficie_kmq": "14.3874",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006065",
      "denominazione_ita_altra": "Denice",
      "denominazione_ita": "Denice",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D272",
      "lat": "44.5986572",
      "lon": "8.3340115",
      "superficie_kmq": "7.4621",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006066",
      "denominazione_ita_altra": "Dernice",
      "denominazione_ita": "Dernice",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D277",
      "lat": "44.7675981",
      "lon": "9.0500793",
      "superficie_kmq": "18.2763",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006067",
      "denominazione_ita_altra": "Fabbrica Curone",
      "denominazione_ita": "Fabbrica Curone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D447",
      "lat": "44.7548201",
      "lon": "9.1541822",
      "superficie_kmq": "53.8484",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006068",
      "denominazione_ita_altra": "Felizzano",
      "denominazione_ita": "Felizzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D528",
      "lat": "44.8980397",
      "lon": "8.4358029",
      "superficie_kmq": "25.0138",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006069",
      "denominazione_ita_altra": "Fraconalto",
      "denominazione_ita": "Fraconalto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D559",
      "lat": "44.5921496",
      "lon": "8.8779599",
      "superficie_kmq": "17.6161",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006070",
      "denominazione_ita_altra": "Francavilla Bisio",
      "denominazione_ita": "Francavilla Bisio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D759",
      "lat": "44.7358845",
      "lon": "8.7316590",
      "superficie_kmq": "7.7515",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006071",
      "denominazione_ita_altra": "Frascaro",
      "denominazione_ita": "Frascaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D770",
      "lat": "44.8271093",
      "lon": "8.5326506",
      "superficie_kmq": "5.2861",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006072",
      "denominazione_ita_altra": "Frassinello Monferrato",
      "denominazione_ita": "Frassinello Monferrato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D777",
      "lat": "45.0315078",
      "lon": "8.3866096",
      "superficie_kmq": "8.4310",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006073",
      "denominazione_ita_altra": "Frassineto Po",
      "denominazione_ita": "Frassineto Po",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D780",
      "lat": "45.1340187",
      "lon": "8.5361033",
      "superficie_kmq": "29.5609",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006074",
      "denominazione_ita_altra": "Fresonara",
      "denominazione_ita": "Fresonara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D797",
      "lat": "44.7827316",
      "lon": "8.6848407",
      "superficie_kmq": "6.9336",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006075",
      "denominazione_ita_altra": "Frugarolo",
      "denominazione_ita": "Frugarolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D813",
      "lat": "44.8389822",
      "lon": "8.6790623",
      "superficie_kmq": "27.0575",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006076",
      "denominazione_ita_altra": "Fubine Monferrato",
      "denominazione_ita": "Fubine Monferrato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D814",
      "lat": "44.9655624",
      "lon": "8.4254127",
      "superficie_kmq": "25.5333",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006077",
      "denominazione_ita_altra": "Gabiano",
      "denominazione_ita": "Gabiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D835",
      "lat": "45.1589402",
      "lon": "8.1931504",
      "superficie_kmq": "17.7684",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006078",
      "denominazione_ita_altra": "Gamalero",
      "denominazione_ita": "Gamalero",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D890",
      "lat": "44.8089199",
      "lon": "8.5430521",
      "superficie_kmq": "12.1543",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006079",
      "denominazione_ita_altra": "Garbagna",
      "denominazione_ita": "Garbagna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D910",
      "lat": "44.7809357",
      "lon": "8.9990357",
      "superficie_kmq": "20.7165",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006081",
      "denominazione_ita_altra": "Gavi",
      "denominazione_ita": "Gavi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D944",
      "lat": "44.6882933",
      "lon": "8.8036063",
      "superficie_kmq": "45.0432",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006082",
      "denominazione_ita_altra": "Giarole",
      "denominazione_ita": "Giarole",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E015",
      "lat": "45.0606540",
      "lon": "8.5678570",
      "superficie_kmq": "5.4494",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006083",
      "denominazione_ita_altra": "Gremiasco",
      "denominazione_ita": "Gremiasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E164",
      "lat": "44.7970425",
      "lon": "9.1047386",
      "superficie_kmq": "17.3842",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006084",
      "denominazione_ita_altra": "Grognardo",
      "denominazione_ita": "Grognardo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E188",
      "lat": "44.6299278",
      "lon": "8.4915067",
      "superficie_kmq": "9.0802",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006085",
      "denominazione_ita_altra": "Grondona",
      "denominazione_ita": "Grondona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E191",
      "lat": "44.6971918",
      "lon": "8.9625408",
      "superficie_kmq": "25.9429",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006086",
      "denominazione_ita_altra": "Guazzora",
      "denominazione_ita": "Guazzora",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E255",
      "lat": "45.0135967",
      "lon": "8.8449106",
      "superficie_kmq": "2.8021",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006087",
      "denominazione_ita_altra": "Isola Sant'Antonio",
      "denominazione_ita": "Isola Sant'Antonio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E360",
      "lat": "45.0308725",
      "lon": "8.8504086",
      "superficie_kmq": "23.5483",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006088",
      "denominazione_ita_altra": "Lerma",
      "denominazione_ita": "Lerma",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E543",
      "lat": "44.6349675",
      "lon": "8.7125166",
      "superficie_kmq": "14.5358",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006090",
      "denominazione_ita_altra": "Malvicino",
      "denominazione_ita": "Malvicino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E870",
      "lat": "44.5596507",
      "lon": "8.4140666",
      "superficie_kmq": "9.0350",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006091",
      "denominazione_ita_altra": "Masio",
      "denominazione_ita": "Masio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F015",
      "lat": "44.8693908",
      "lon": "8.4080110",
      "superficie_kmq": "22.2319",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006092",
      "denominazione_ita_altra": "Melazzo",
      "denominazione_ita": "Melazzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F096",
      "lat": "44.6439852",
      "lon": "8.4269029",
      "superficie_kmq": "19.7375",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006093",
      "denominazione_ita_altra": "Merana",
      "denominazione_ita": "Merana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F131",
      "lat": "44.5177880",
      "lon": "8.2975246",
      "superficie_kmq": "9.1998",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006094",
      "denominazione_ita_altra": "Mirabello Monferrato",
      "denominazione_ita": "Mirabello Monferrato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F232",
      "lat": "45.0357457",
      "lon": "8.5234501",
      "superficie_kmq": "13.2357",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006095",
      "denominazione_ita_altra": "Molare",
      "denominazione_ita": "Molare",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F281",
      "lat": "44.6206141",
      "lon": "8.6010831",
      "superficie_kmq": "32.5040",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006096",
      "denominazione_ita_altra": "Molino dei Torti",
      "denominazione_ita": "Molino dei Torti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F293",
      "lat": "45.0235405",
      "lon": "8.8920305",
      "superficie_kmq": "2.7527",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006097",
      "denominazione_ita_altra": "Mombello Monferrato",
      "denominazione_ita": "Mombello Monferrato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F313",
      "lat": "45.1330294",
      "lon": "8.2508837",
      "superficie_kmq": "19.6941",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006098",
      "denominazione_ita_altra": "Momperone",
      "denominazione_ita": "Momperone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F320",
      "lat": "44.8386375",
      "lon": "9.0344792",
      "superficie_kmq": "8.5400",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006099",
      "denominazione_ita_altra": "Moncestino",
      "denominazione_ita": "Moncestino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F337",
      "lat": "45.1548520",
      "lon": "8.1584003",
      "superficie_kmq": "6.5185",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006100",
      "denominazione_ita_altra": "Mongiardino Ligure",
      "denominazione_ita": "Mongiardino Ligure",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F365",
      "lat": "44.6385514",
      "lon": "9.0612632",
      "superficie_kmq": "29.0329",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006101",
      "denominazione_ita_altra": "Monleale",
      "denominazione_ita": "Monleale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F374",
      "lat": "44.8850715",
      "lon": "8.9843698",
      "superficie_kmq": "9.6176",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006102",
      "denominazione_ita_altra": "Montacuto",
      "denominazione_ita": "Montacuto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F387",
      "lat": "44.7667481",
      "lon": "9.1068749",
      "superficie_kmq": "23.7473",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006103",
      "denominazione_ita_altra": "Montaldeo",
      "denominazione_ita": "Montaldeo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F403",
      "lat": "44.6665306",
      "lon": "8.7306833",
      "superficie_kmq": "5.3756",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006104",
      "denominazione_ita_altra": "Montaldo Bormida",
      "denominazione_ita": "Montaldo Bormida",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F404",
      "lat": "44.6834491",
      "lon": "8.5890058",
      "superficie_kmq": "5.7241",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006105",
      "denominazione_ita_altra": "Montecastello",
      "denominazione_ita": "Montecastello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F455",
      "lat": "44.9489477",
      "lon": "8.6866065",
      "superficie_kmq": "7.4867",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006106",
      "denominazione_ita_altra": "Montechiaro d'Acqui",
      "denominazione_ita": "Montechiaro d'Acqui",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F469",
      "lat": "44.5949771",
      "lon": "8.3796734",
      "superficie_kmq": "17.5982",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006107",
      "denominazione_ita_altra": "Montegioco",
      "denominazione_ita": "Montegioco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F518",
      "lat": "44.8377939",
      "lon": "8.9540834",
      "superficie_kmq": "5.4513",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006108",
      "denominazione_ita_altra": "Montemarzino",
      "denominazione_ita": "Montemarzino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F562",
      "lat": "44.8484822",
      "lon": "8.9931314",
      "superficie_kmq": "9.8515",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006109",
      "denominazione_ita_altra": "Morano sul Po",
      "denominazione_ita": "Morano sul Po",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F707",
      "lat": "45.1640395",
      "lon": "8.3656208",
      "superficie_kmq": "17.7049",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006110",
      "denominazione_ita_altra": "Morbello",
      "denominazione_ita": "Morbello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F713",
      "lat": "44.6034699",
      "lon": "8.5100103",
      "superficie_kmq": "23.9524",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006111",
      "denominazione_ita_altra": "Mornese",
      "denominazione_ita": "Mornese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F737",
      "lat": "44.6390754",
      "lon": "8.7561914",
      "superficie_kmq": "13.2197",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006112",
      "denominazione_ita_altra": "Morsasco",
      "denominazione_ita": "Morsasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F751",
      "lat": "44.6645589",
      "lon": "8.5490960",
      "superficie_kmq": "10.2943",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006113",
      "denominazione_ita_altra": "Murisengo",
      "denominazione_ita": "Murisengo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F814",
      "lat": "45.0834471",
      "lon": "8.1374660",
      "superficie_kmq": "15.3104",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006114",
      "denominazione_ita_altra": "Novi Ligure",
      "denominazione_ita": "Novi Ligure",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F965",
      "lat": "44.7601792",
      "lon": "8.7870990",
      "superficie_kmq": "55.1948",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006115",
      "denominazione_ita_altra": "Occimiano",
      "denominazione_ita": "Occimiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F995",
      "lat": "45.0601772",
      "lon": "8.5066555",
      "superficie_kmq": "22.4629",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006116",
      "denominazione_ita_altra": "Odalengo Grande",
      "denominazione_ita": "Odalengo Grande",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F997",
      "lat": "45.1095224",
      "lon": "8.1667216",
      "superficie_kmq": "15.4279",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006117",
      "denominazione_ita_altra": "Odalengo Piccolo",
      "denominazione_ita": "Odalengo Piccolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F998",
      "lat": "45.0719277",
      "lon": "8.2095151",
      "superficie_kmq": "7.5707",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006118",
      "denominazione_ita_altra": "Olivola",
      "denominazione_ita": "Olivola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G042",
      "lat": "45.0376938",
      "lon": "8.3669623",
      "superficie_kmq": "2.6908",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006119",
      "denominazione_ita_altra": "Orsara Bormida",
      "denominazione_ita": "Orsara Bormida",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G124",
      "lat": "44.6900822",
      "lon": "8.5624365",
      "superficie_kmq": "5.0996",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006120",
      "denominazione_ita_altra": "Ottiglio",
      "denominazione_ita": "Ottiglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G193",
      "lat": "45.0518339",
      "lon": "8.3391467",
      "superficie_kmq": "14.5434",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006121",
      "denominazione_ita_altra": "Ovada",
      "denominazione_ita": "Ovada",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G197",
      "lat": "44.6374608",
      "lon": "8.6463870",
      "superficie_kmq": "35.3724",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006122",
      "denominazione_ita_altra": "Oviglio",
      "denominazione_ita": "Oviglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G199",
      "lat": "44.8598003",
      "lon": "8.4867247",
      "superficie_kmq": "27.3665",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006123",
      "denominazione_ita_altra": "Ozzano Monferrato",
      "denominazione_ita": "Ozzano Monferrato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G204",
      "lat": "45.1018934",
      "lon": "8.3762262",
      "superficie_kmq": "15.1834",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006124",
      "denominazione_ita_altra": "Paderna",
      "denominazione_ita": "Paderna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G215",
      "lat": "44.8207217",
      "lon": "8.8900021",
      "superficie_kmq": "4.4198",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006125",
      "denominazione_ita_altra": "Pareto",
      "denominazione_ita": "Pareto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G334",
      "lat": "44.5157343",
      "lon": "8.3828668",
      "superficie_kmq": "41.7376",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006126",
      "denominazione_ita_altra": "Parodi Ligure",
      "denominazione_ita": "Parodi Ligure",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G338",
      "lat": "44.6708171",
      "lon": "8.7586180",
      "superficie_kmq": "12.5365",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006127",
      "denominazione_ita_altra": "Pasturana",
      "denominazione_ita": "Pasturana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G367",
      "lat": "44.7506725",
      "lon": "8.7490384",
      "superficie_kmq": "5.2811",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006128",
      "denominazione_ita_altra": "Pecetto di Valenza",
      "denominazione_ita": "Pecetto di Valenza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G397",
      "lat": "44.9897155",
      "lon": "8.6704681",
      "superficie_kmq": "11.3546",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006129",
      "denominazione_ita_altra": "Pietra Marazzi",
      "denominazione_ita": "Pietra Marazzi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G619",
      "lat": "44.9441397",
      "lon": "8.6705542",
      "superficie_kmq": "7.9973",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006131",
      "denominazione_ita_altra": "Pomaro Monferrato",
      "denominazione_ita": "Pomaro Monferrato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G807",
      "lat": "45.0639175",
      "lon": "8.5951919",
      "superficie_kmq": "13.4427",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006132",
      "denominazione_ita_altra": "Pontecurone",
      "denominazione_ita": "Pontecurone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G839",
      "lat": "44.9588391",
      "lon": "8.9359378",
      "superficie_kmq": "29.7041",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006133",
      "denominazione_ita_altra": "Pontestura",
      "denominazione_ita": "Pontestura",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G858",
      "lat": "45.1436421",
      "lon": "8.3325409",
      "superficie_kmq": "18.9177",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006134",
      "denominazione_ita_altra": "Ponti",
      "denominazione_ita": "Ponti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G861",
      "lat": "44.6274099",
      "lon": "8.3652335",
      "superficie_kmq": "11.9679",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006135",
      "denominazione_ita_altra": "Ponzano Monferrato",
      "denominazione_ita": "Ponzano Monferrato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G872",
      "lat": "45.0844601",
      "lon": "8.2662183",
      "superficie_kmq": "11.6495",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006136",
      "denominazione_ita_altra": "Ponzone",
      "denominazione_ita": "Ponzone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G877",
      "lat": "44.5863981",
      "lon": "8.4596074",
      "superficie_kmq": "69.0281",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006137",
      "denominazione_ita_altra": "Pozzol Groppo",
      "denominazione_ita": "Pozzol Groppo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G960",
      "lat": "44.8774560",
      "lon": "9.0293141",
      "superficie_kmq": "14.0866",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006138",
      "denominazione_ita_altra": "Pozzolo Formigaro",
      "denominazione_ita": "Pozzolo Formigaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G961",
      "lat": "44.7938650",
      "lon": "8.7815464",
      "superficie_kmq": "36.1765",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006139",
      "denominazione_ita_altra": "Prasco",
      "denominazione_ita": "Prasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G987",
      "lat": "44.6424071",
      "lon": "8.5528503",
      "superficie_kmq": "5.9724",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006140",
      "denominazione_ita_altra": "Predosa",
      "denominazione_ita": "Predosa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H021",
      "lat": "44.7527244",
      "lon": "8.6592398",
      "superficie_kmq": "33.0071",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006141",
      "denominazione_ita_altra": "Quargnento",
      "denominazione_ita": "Quargnento",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H104",
      "lat": "44.9448388",
      "lon": "8.4877076",
      "superficie_kmq": "36.1651",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006142",
      "denominazione_ita_altra": "Quattordio",
      "denominazione_ita": "Quattordio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H121",
      "lat": "44.8992043",
      "lon": "8.4059871",
      "superficie_kmq": "17.7338",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006143",
      "denominazione_ita_altra": "Ricaldone",
      "denominazione_ita": "Ricaldone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H272",
      "lat": "44.7320841",
      "lon": "8.4696335",
      "superficie_kmq": "10.5153",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006144",
      "denominazione_ita_altra": "Rivalta Bormida",
      "denominazione_ita": "Rivalta Bormida",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H334",
      "lat": "44.7113696",
      "lon": "8.5502585",
      "superficie_kmq": "10.0516",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006145",
      "denominazione_ita_altra": "Rivarone",
      "denominazione_ita": "Rivarone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H343",
      "lat": "44.9776577",
      "lon": "8.7156949",
      "superficie_kmq": "6.0734",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006146",
      "denominazione_ita_altra": "Roccaforte Ligure",
      "denominazione_ita": "Roccaforte Ligure",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H406",
      "lat": "44.6777056",
      "lon": "9.0277694",
      "superficie_kmq": "20.5892",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006147",
      "denominazione_ita_altra": "Rocca Grimalda",
      "denominazione_ita": "Rocca Grimalda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H414",
      "lat": "44.6710935",
      "lon": "8.6472867",
      "superficie_kmq": "15.4612",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006148",
      "denominazione_ita_altra": "Rocchetta Ligure",
      "denominazione_ita": "Rocchetta Ligure",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H465",
      "lat": "44.7065411",
      "lon": "9.0500277",
      "superficie_kmq": "10.1543",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006149",
      "denominazione_ita_altra": "Rosignano Monferrato",
      "denominazione_ita": "Rosignano Monferrato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H569",
      "lat": "45.0798449",
      "lon": "8.3990589",
      "superficie_kmq": "19.2799",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006150",
      "denominazione_ita_altra": "Sala Monferrato",
      "denominazione_ita": "Sala Monferrato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H677",
      "lat": "45.0753978",
      "lon": "8.3581763",
      "superficie_kmq": "7.5754",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006151",
      "denominazione_ita_altra": "Sale",
      "denominazione_ita": "Sale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H694",
      "lat": "44.9847456",
      "lon": "8.8089706",
      "superficie_kmq": "44.9156",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006152",
      "denominazione_ita_altra": "San Cristoforo",
      "denominazione_ita": "San Cristoforo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H810",
      "lat": "44.6924073",
      "lon": "8.7499958",
      "superficie_kmq": "3.5706",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006153",
      "denominazione_ita_altra": "San Giorgio Monferrato",
      "denominazione_ita": "San Giorgio Monferrato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H878",
      "lat": "45.1077863",
      "lon": "8.4148569",
      "superficie_kmq": "7.1156",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006154",
      "denominazione_ita_altra": "San Salvatore Monferrato",
      "denominazione_ita": "San Salvatore Monferrato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I144",
      "lat": "44.9948033",
      "lon": "8.5666904",
      "superficie_kmq": "31.6916",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006155",
      "denominazione_ita_altra": "San Sebastiano Curone",
      "denominazione_ita": "San Sebastiano Curone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I150",
      "lat": "44.7856067",
      "lon": "9.0652433",
      "superficie_kmq": "3.8925",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006156",
      "denominazione_ita_altra": "Sant'Agata Fossili",
      "denominazione_ita": "Sant'Agata Fossili",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I190",
      "lat": "44.7859064",
      "lon": "8.9189347",
      "superficie_kmq": "7.7065",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006157",
      "denominazione_ita_altra": "Sardigliano",
      "denominazione_ita": "Sardigliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I429",
      "lat": "44.7542920",
      "lon": "8.9076741",
      "superficie_kmq": "12.7430",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006158",
      "denominazione_ita_altra": "Sarezzano",
      "denominazione_ita": "Sarezzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I432",
      "lat": "44.8675538",
      "lon": "8.9153611",
      "superficie_kmq": "13.8532",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006159",
      "denominazione_ita_altra": "Serralunga di Crea",
      "denominazione_ita": "Serralunga di Crea",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I645",
      "lat": "45.1007411",
      "lon": "8.2818606",
      "superficie_kmq": "8.8425",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006160",
      "denominazione_ita_altra": "Serravalle Scrivia",
      "denominazione_ita": "Serravalle Scrivia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I657",
      "lat": "44.7194648",
      "lon": "8.8589917",
      "superficie_kmq": "15.9488",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006161",
      "denominazione_ita_altra": "Sezzadio",
      "denominazione_ita": "Sezzadio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I711",
      "lat": "44.7855819",
      "lon": "8.5727297",
      "superficie_kmq": "34.3195",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006162",
      "denominazione_ita_altra": "Silvano d'Orba",
      "denominazione_ita": "Silvano d'Orba",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I738",
      "lat": "44.6855659",
      "lon": "8.6710129",
      "superficie_kmq": "12.1681",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006163",
      "denominazione_ita_altra": "Solero",
      "denominazione_ita": "Solero",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I798",
      "lat": "44.9197586",
      "lon": "8.5077097",
      "superficie_kmq": "22.5538",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006164",
      "denominazione_ita_altra": "Solonghello",
      "denominazione_ita": "Solonghello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I808",
      "lat": "45.1309523",
      "lon": "8.2818377",
      "superficie_kmq": "4.9533",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006165",
      "denominazione_ita_altra": "Spigno Monferrato",
      "denominazione_ita": "Spigno Monferrato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I901",
      "lat": "44.5430240",
      "lon": "8.3353027",
      "superficie_kmq": "54.8544",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006166",
      "denominazione_ita_altra": "Spineto Scrivia",
      "denominazione_ita": "Spineto Scrivia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I911",
      "lat": "44.8376236",
      "lon": "8.8723825",
      "superficie_kmq": "3.9542",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006167",
      "denominazione_ita_altra": "Stazzano",
      "denominazione_ita": "Stazzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I941",
      "lat": "44.7272525",
      "lon": "8.8673933",
      "superficie_kmq": "17.9132",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006168",
      "denominazione_ita_altra": "Strevi",
      "denominazione_ita": "Strevi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I977",
      "lat": "44.6986567",
      "lon": "8.5224600",
      "superficie_kmq": "15.2889",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006169",
      "denominazione_ita_altra": "Tagliolo Monferrato",
      "denominazione_ita": "Tagliolo Monferrato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L027",
      "lat": "44.6390773",
      "lon": "8.6682523",
      "superficie_kmq": "26.2111",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006170",
      "denominazione_ita_altra": "Tassarolo",
      "denominazione_ita": "Tassarolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L059",
      "lat": "44.7265798",
      "lon": "8.7695011",
      "superficie_kmq": "7.0350",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006171",
      "denominazione_ita_altra": "Terruggia",
      "denominazione_ita": "Terruggia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L139",
      "lat": "45.0830560",
      "lon": "8.4421786",
      "superficie_kmq": "7.2664",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006172",
      "denominazione_ita_altra": "Terzo",
      "denominazione_ita": "Terzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L143",
      "lat": "44.6695304",
      "lon": "8.4210536",
      "superficie_kmq": "8.7960",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006173",
      "denominazione_ita_altra": "Ticineto",
      "denominazione_ita": "Ticineto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L165",
      "lat": "45.0974877",
      "lon": "8.5555502",
      "superficie_kmq": "8.0894",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006174",
      "denominazione_ita_altra": "Tortona",
      "denominazione_ita": "Tortona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L304",
      "lat": "44.8947397",
      "lon": "8.8601589",
      "superficie_kmq": "98.8666",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006175",
      "denominazione_ita_altra": "Treville",
      "denominazione_ita": "Treville",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L403",
      "lat": "45.0971413",
      "lon": "8.3597585",
      "superficie_kmq": "4.6032",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006176",
      "denominazione_ita_altra": "Trisobbio",
      "denominazione_ita": "Trisobbio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L432",
      "lat": "44.6622963",
      "lon": "8.5862757",
      "superficie_kmq": "9.2191",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006177",
      "denominazione_ita_altra": "Valenza",
      "denominazione_ita": "Valenza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L570",
      "lat": "45.0144656",
      "lon": "8.6462262",
      "superficie_kmq": "48.4945",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006178",
      "denominazione_ita_altra": "Valmacca",
      "denominazione_ita": "Valmacca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L633",
      "lat": "45.1004646",
      "lon": "8.5823372",
      "superficie_kmq": "12.2936",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006179",
      "denominazione_ita_altra": "Vignale Monferrato",
      "denominazione_ita": "Vignale Monferrato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L881",
      "lat": "45.0109305",
      "lon": "8.3965366",
      "superficie_kmq": "18.7258",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006180",
      "denominazione_ita_altra": "Vignole Borbera",
      "denominazione_ita": "Vignole Borbera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L887",
      "lat": "44.7082847",
      "lon": "8.8905588",
      "superficie_kmq": "8.6455",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006181",
      "denominazione_ita_altra": "Viguzzolo",
      "denominazione_ita": "Viguzzolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L904",
      "lat": "44.9049194",
      "lon": "8.9200804",
      "superficie_kmq": "18.3072",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006182",
      "denominazione_ita_altra": "Villadeati",
      "denominazione_ita": "Villadeati",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L931",
      "lat": "45.0724416",
      "lon": "8.1696989",
      "superficie_kmq": "14.6091",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006183",
      "denominazione_ita_altra": "Villalvernia",
      "denominazione_ita": "Villalvernia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L963",
      "lat": "44.8176011",
      "lon": "8.8566406",
      "superficie_kmq": "4.4744",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006184",
      "denominazione_ita_altra": "Villamiroglio",
      "denominazione_ita": "Villamiroglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L970",
      "lat": "45.1344466",
      "lon": "8.1697573",
      "superficie_kmq": "9.8688",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006185",
      "denominazione_ita_altra": "Villanova Monferrato",
      "denominazione_ita": "Villanova Monferrato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L972",
      "lat": "45.1817906",
      "lon": "8.4784678",
      "superficie_kmq": "16.5563",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006186",
      "denominazione_ita_altra": "Villaromagnano",
      "denominazione_ita": "Villaromagnano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M009",
      "lat": "44.8510879",
      "lon": "8.8871349",
      "superficie_kmq": "6.0672",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006187",
      "denominazione_ita_altra": "Visone",
      "denominazione_ita": "Visone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M077",
      "lat": "44.6620556",
      "lon": "8.5032776",
      "superficie_kmq": "12.5617",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006188",
      "denominazione_ita_altra": "Volpedo",
      "denominazione_ita": "Volpedo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M120",
      "lat": "44.8888012",
      "lon": "8.9833830",
      "superficie_kmq": "10.4833",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006189",
      "denominazione_ita_altra": "Volpeglino",
      "denominazione_ita": "Volpeglino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M121",
      "lat": "44.8930471",
      "lon": "8.9589640",
      "superficie_kmq": "3.2458",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006190",
      "denominazione_ita_altra": "Voltaggio",
      "denominazione_ita": "Voltaggio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M123",
      "lat": "44.6201255",
      "lon": "8.8426570",
      "superficie_kmq": "52.1828",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006191",
      "denominazione_ita_altra": "Cassano Spinola",
      "denominazione_ita": "Cassano Spinola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M388",
      "lat": "44.7705659",
      "lon": "8.8696333",
      "superficie_kmq": "17.1263",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006192",
      "denominazione_ita_altra": "Alluvioni Piovera",
      "denominazione_ita": "Alluvioni Piovera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M397",
      "lat": "44.9677769",
      "lon": "8.7536049",
      "superficie_kmq": "24.7837",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AL",
      "codice_istat": "006193",
      "denominazione_ita_altra": "Lu e Cuccaro Monferrato",
      "denominazione_ita": "Lu e Cuccaro Monferrato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M420",
      "lat": "45.0023858",
      "lon": "8.4837614",
      "superficie_kmq": "27.0950",
      "codice_sovracomunale": "006"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007001",
      "denominazione_ita_altra": "Allein",
      "denominazione_ita": "Allein",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A205",
      "lat": "45.8130096",
      "lon": "7.2724783",
      "superficie_kmq": "7.9640",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007002",
      "denominazione_ita_altra": "Antey-Saint-André",
      "denominazione_ita": "Antey-Saint-André",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A305",
      "lat": "45.8049657",
      "lon": "7.5932085",
      "superficie_kmq": "11.8080",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007003",
      "denominazione_ita_altra": "Aosta",
      "denominazione_ita": "Aosta",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "A326",
      "lat": "45.7375122",
      "lon": "7.3201491",
      "superficie_kmq": "21.3923",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007004",
      "denominazione_ita_altra": "Arnad",
      "denominazione_ita": "Arnad",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A424",
      "lat": "45.6449847",
      "lon": "7.7212755",
      "superficie_kmq": "28.8405",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007005",
      "denominazione_ita_altra": "Arvier",
      "denominazione_ita": "Arvier",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A452",
      "lat": "45.7035684",
      "lon": "7.1693041",
      "superficie_kmq": "33.3596",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007006",
      "denominazione_ita_altra": "Avise",
      "denominazione_ita": "Avise",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A521",
      "lat": "45.7087107",
      "lon": "7.1413297",
      "superficie_kmq": "52.6166",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007007",
      "denominazione_ita_altra": "Ayas",
      "denominazione_ita": "Ayas",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A094",
      "lat": "45.8140546",
      "lon": "7.6881569",
      "superficie_kmq": "129.4208",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007008",
      "denominazione_ita_altra": "Aymavilles",
      "denominazione_ita": "Aymavilles",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A108",
      "lat": "45.7013767",
      "lon": "7.2405449",
      "superficie_kmq": "53.2436",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007009",
      "denominazione_ita_altra": "Bard",
      "denominazione_ita": "Bard",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A643",
      "lat": "45.6079645",
      "lon": "7.7466341",
      "superficie_kmq": "3.0315",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007010",
      "denominazione_ita_altra": "Bionaz",
      "denominazione_ita": "Bionaz",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A877",
      "lat": "45.8740793",
      "lon": "7.4230709",
      "superficie_kmq": "142.0905",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007011",
      "denominazione_ita_altra": "Brissogne",
      "denominazione_ita": "Brissogne",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B192",
      "lat": "45.7271899",
      "lon": "7.4045742",
      "superficie_kmq": "25.5133",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007012",
      "denominazione_ita_altra": "Brusson",
      "denominazione_ita": "Brusson",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B230",
      "lat": "45.7586279",
      "lon": "7.7328122",
      "superficie_kmq": "55.2569",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007013",
      "denominazione_ita_altra": "Challand-Saint-Anselme",
      "denominazione_ita": "Challand-Saint-Anselme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C593",
      "lat": "45.7164084",
      "lon": "7.7365646",
      "superficie_kmq": "27.9864",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007014",
      "denominazione_ita_altra": "Challand-Saint-Victor",
      "denominazione_ita": "Challand-Saint-Victor",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C594",
      "lat": "45.6897830",
      "lon": "7.7064615",
      "superficie_kmq": "25.1589",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007015",
      "denominazione_ita_altra": "Chambave",
      "denominazione_ita": "Chambave",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C595",
      "lat": "45.7443453",
      "lon": "7.5499413",
      "superficie_kmq": "21.5416",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007016",
      "denominazione_ita_altra": "Chamois",
      "denominazione_ita": "Chamois",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B491",
      "lat": "45.8383677",
      "lon": "7.6202844",
      "superficie_kmq": "14.5283",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007017",
      "denominazione_ita_altra": "Champdepraz",
      "denominazione_ita": "Champdepraz",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C596",
      "lat": "45.6857490",
      "lon": "7.6577543",
      "superficie_kmq": "48.7924",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007018",
      "denominazione_ita_altra": "Champorcher",
      "denominazione_ita": "Champorcher",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B540",
      "lat": "45.6236202",
      "lon": "7.6207939",
      "superficie_kmq": "68.4352",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007019",
      "denominazione_ita_altra": "Charvensod",
      "denominazione_ita": "Charvensod",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C598",
      "lat": "45.7204438",
      "lon": "7.3254073",
      "superficie_kmq": "25.8619",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007020",
      "denominazione_ita_altra": "Châtillon",
      "denominazione_ita": "Châtillon",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C294",
      "lat": "45.7495761",
      "lon": "7.6132484",
      "superficie_kmq": "39.6811",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007021",
      "denominazione_ita_altra": "Cogne",
      "denominazione_ita": "Cogne",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C821",
      "lat": "45.6081108",
      "lon": "7.3558966",
      "superficie_kmq": "213.0372",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007022",
      "denominazione_ita_altra": "Courmayeur",
      "denominazione_ita": "Courmayeur",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D012",
      "lat": "45.7930597",
      "lon": "6.9721103",
      "superficie_kmq": "209.6059",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007023",
      "denominazione_ita_altra": "Donnas",
      "denominazione_ita": "Donnas",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D338",
      "lat": "45.6034918",
      "lon": "7.7674393",
      "superficie_kmq": "33.9770",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007024",
      "denominazione_ita_altra": "Doues",
      "denominazione_ita": "Doues",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D356",
      "lat": "45.8192565",
      "lon": "7.3066494",
      "superficie_kmq": "16.2542",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007025",
      "denominazione_ita_altra": "Emarèse",
      "denominazione_ita": "Emarèse",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D402",
      "lat": "45.7247084",
      "lon": "7.7004409",
      "superficie_kmq": "10.2330",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007026",
      "denominazione_ita_altra": "Etroubles",
      "denominazione_ita": "Etroubles",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D444",
      "lat": "45.8197115",
      "lon": "7.2329540",
      "superficie_kmq": "39.5692",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007027",
      "denominazione_ita_altra": "Fénis",
      "denominazione_ita": "Fénis",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D537",
      "lat": "45.7361087",
      "lon": "7.4942990",
      "superficie_kmq": "68.1198",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007028",
      "denominazione_ita_altra": "Fontainemore",
      "denominazione_ita": "Fontainemore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D666",
      "lat": "45.6490086",
      "lon": "7.8609733",
      "superficie_kmq": "31.7089",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007029",
      "denominazione_ita_altra": "Gaby",
      "denominazione_ita": "Gaby",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D839",
      "lat": "45.7128719",
      "lon": "7.8823494",
      "superficie_kmq": "32.8828",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007030",
      "denominazione_ita_altra": "Gignod",
      "denominazione_ita": "Gignod",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E029",
      "lat": "45.7782620",
      "lon": "7.2961777",
      "superficie_kmq": "25.9833",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007031",
      "denominazione_ita_altra": "Gressan",
      "denominazione_ita": "Gressan",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E165",
      "lat": "45.7216299",
      "lon": "7.2894405",
      "superficie_kmq": "25.2932",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007032",
      "denominazione_ita_altra": "Gressoney-La-Trinité",
      "denominazione_ita": "Gressoney-La-Trinité",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E167",
      "lat": "45.8272526",
      "lon": "7.8230854",
      "superficie_kmq": "66.5161",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007033",
      "denominazione_ita_altra": "Gressoney-Saint-Jean",
      "denominazione_ita": "Gressoney-Saint-Jean",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E168",
      "lat": "45.7770071",
      "lon": "7.8275858",
      "superficie_kmq": "69.6471",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007034",
      "denominazione_ita_altra": "Hône",
      "denominazione_ita": "Hône",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E273",
      "lat": "45.6118625",
      "lon": "7.7357330",
      "superficie_kmq": "12.6384",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007035",
      "denominazione_ita_altra": "Introd",
      "denominazione_ita": "Introd",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E306",
      "lat": "45.6914789",
      "lon": "7.1872175",
      "superficie_kmq": "20.0381",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007036",
      "denominazione_ita_altra": "Issime",
      "denominazione_ita": "Issime",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E369",
      "lat": "45.6854598",
      "lon": "7.8542158",
      "superficie_kmq": "34.6715",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007037",
      "denominazione_ita_altra": "Issogne",
      "denominazione_ita": "Issogne",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E371",
      "lat": "45.6539580",
      "lon": "7.6844172",
      "superficie_kmq": "23.6103",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007038",
      "denominazione_ita_altra": "Jovençan",
      "denominazione_ita": "Jovençan",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E391",
      "lat": "45.7139721",
      "lon": "7.2749773",
      "superficie_kmq": "7.0129",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007039",
      "denominazione_ita_altra": "La Magdeleine",
      "denominazione_ita": "La Magdeleine",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A308",
      "lat": "45.8108078",
      "lon": "7.6190993",
      "superficie_kmq": "8.9365",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007040",
      "denominazione_ita_altra": "La Salle",
      "denominazione_ita": "La Salle",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E458",
      "lat": "45.7468981",
      "lon": "7.0725960",
      "superficie_kmq": "83.9427",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007041",
      "denominazione_ita_altra": "La Thuile",
      "denominazione_ita": "La Thuile",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E470",
      "lat": "45.7168438",
      "lon": "6.9490717",
      "superficie_kmq": "125.6666",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007042",
      "denominazione_ita_altra": "Lillianes",
      "denominazione_ita": "Lillianes",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E587",
      "lat": "45.6327735",
      "lon": "7.8450707",
      "superficie_kmq": "18.5463",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007043",
      "denominazione_ita_altra": "Montjovet",
      "denominazione_ita": "Montjovet",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F367",
      "lat": "45.7042245",
      "lon": "7.6741872",
      "superficie_kmq": "18.7597",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007044",
      "denominazione_ita_altra": "Morgex",
      "denominazione_ita": "Morgex",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F726",
      "lat": "45.7569917",
      "lon": "7.0360689",
      "superficie_kmq": "43.6277",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007045",
      "denominazione_ita_altra": "Nus",
      "denominazione_ita": "Nus",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F987",
      "lat": "45.7392154",
      "lon": "7.4669414",
      "superficie_kmq": "57.3544",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007046",
      "denominazione_ita_altra": "Ollomont",
      "denominazione_ita": "Ollomont",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G045",
      "lat": "45.8491128",
      "lon": "7.3109029",
      "superficie_kmq": "53.4825",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007047",
      "denominazione_ita_altra": "Oyace",
      "denominazione_ita": "Oyace",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G012",
      "lat": "45.8507095",
      "lon": "7.3825057",
      "superficie_kmq": "30.5598",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007048",
      "denominazione_ita_altra": "Perloz",
      "denominazione_ita": "Perloz",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G459",
      "lat": "45.6131213",
      "lon": "7.8086028",
      "superficie_kmq": "23.2665",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007049",
      "denominazione_ita_altra": "Pollein",
      "denominazione_ita": "Pollein",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G794",
      "lat": "45.7280585",
      "lon": "7.3571718",
      "superficie_kmq": "15.3295",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007050",
      "denominazione_ita_altra": "Pontboset",
      "denominazione_ita": "Pontboset",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G545",
      "lat": "45.6064380",
      "lon": "7.6876522",
      "superficie_kmq": "33.5599",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007051",
      "denominazione_ita_altra": "Pontey",
      "denominazione_ita": "Pontey",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G860",
      "lat": "45.7379093",
      "lon": "7.5913491",
      "superficie_kmq": "15.7533",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007052",
      "denominazione_ita_altra": "Pont-Saint-Martin",
      "denominazione_ita": "Pont-Saint-Martin",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G854",
      "lat": "45.5998198",
      "lon": "7.7977978",
      "superficie_kmq": "6.9224",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007053",
      "denominazione_ita_altra": "Pré-Saint-Didier",
      "denominazione_ita": "Pré-Saint-Didier",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H042",
      "lat": "45.7637740",
      "lon": "6.9863526",
      "superficie_kmq": "33.4018",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007054",
      "denominazione_ita_altra": "Quart",
      "denominazione_ita": "Quart",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H110",
      "lat": "45.7406890",
      "lon": "7.4156026",
      "superficie_kmq": "62.0520",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007055",
      "denominazione_ita_altra": "Rhêmes-Notre-Dame",
      "denominazione_ita": "Rhêmes-Notre-Dame",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H262",
      "lat": "45.5697142",
      "lon": "7.1185287",
      "superficie_kmq": "86.8333",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007056",
      "denominazione_ita_altra": "Rhêmes-Saint-Georges",
      "denominazione_ita": "Rhêmes-Saint-Georges",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H263",
      "lat": "45.6552641",
      "lon": "7.1552473",
      "superficie_kmq": "36.3385",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007057",
      "denominazione_ita_altra": "Roisan",
      "denominazione_ita": "Roisan",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H497",
      "lat": "45.7844334",
      "lon": "7.3105788",
      "superficie_kmq": "14.6410",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007058",
      "denominazione_ita_altra": "Saint-Christophe",
      "denominazione_ita": "Saint-Christophe",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H669",
      "lat": "45.7483400",
      "lon": "7.3578141",
      "superficie_kmq": "14.7364",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007059",
      "denominazione_ita_altra": "Saint-Denis",
      "denominazione_ita": "Saint-Denis",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H670",
      "lat": "45.7521873",
      "lon": "7.5571919",
      "superficie_kmq": "11.3872",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007060",
      "denominazione_ita_altra": "Saint-Marcel",
      "denominazione_ita": "Saint-Marcel",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H671",
      "lat": "45.7324249",
      "lon": "7.4482864",
      "superficie_kmq": "42.3820",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007061",
      "denominazione_ita_altra": "Saint-Nicolas",
      "denominazione_ita": "Saint-Nicolas",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H672",
      "lat": "45.7166266",
      "lon": "7.1678868",
      "superficie_kmq": "15.4576",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007062",
      "denominazione_ita_altra": "Saint-Oyen",
      "denominazione_ita": "Saint-Oyen",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H673",
      "lat": "45.8246330",
      "lon": "7.2124075",
      "superficie_kmq": "9.4206",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007063",
      "denominazione_ita_altra": "Saint-Pierre",
      "denominazione_ita": "Saint-Pierre",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H674",
      "lat": "45.7104427",
      "lon": "7.2262887",
      "superficie_kmq": "26.1772",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007064",
      "denominazione_ita_altra": "Saint-Rhémy-en-Bosses",
      "denominazione_ita": "Saint-Rhémy-en-Bosses",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H675",
      "lat": "45.8230695",
      "lon": "7.1820635",
      "superficie_kmq": "64.8770",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007065",
      "denominazione_ita_altra": "Saint-Vincent",
      "denominazione_ita": "Saint-Vincent",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H676",
      "lat": "45.7502297",
      "lon": "7.6475021",
      "superficie_kmq": "20.5721",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007066",
      "denominazione_ita_altra": "Sarre",
      "denominazione_ita": "Sarre",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I442",
      "lat": "45.7179534",
      "lon": "7.2576449",
      "superficie_kmq": "28.2902",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007067",
      "denominazione_ita_altra": "Torgnon",
      "denominazione_ita": "Torgnon",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L217",
      "lat": "45.8014819",
      "lon": "7.5712055",
      "superficie_kmq": "42.4629",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007068",
      "denominazione_ita_altra": "Valgrisenche",
      "denominazione_ita": "Valgrisenche",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L582",
      "lat": "45.6303453",
      "lon": "7.0647555",
      "superficie_kmq": "113.4757",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007069",
      "denominazione_ita_altra": "Valpelline",
      "denominazione_ita": "Valpelline",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L643",
      "lat": "45.8264336",
      "lon": "7.3285534",
      "superficie_kmq": "31.4468",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007070",
      "denominazione_ita_altra": "Valsavarenche",
      "denominazione_ita": "Valsavarenche",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L647",
      "lat": "45.5919986",
      "lon": "7.2083118",
      "superficie_kmq": "138.2088",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007071",
      "denominazione_ita_altra": "Valtournenche",
      "denominazione_ita": "Valtournenche",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L654",
      "lat": "45.8770960",
      "lon": "7.6227088",
      "superficie_kmq": "116.1501",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007072",
      "denominazione_ita_altra": "Verrayes",
      "denominazione_ita": "Verrayes",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L783",
      "lat": "45.7614537",
      "lon": "7.5322539",
      "superficie_kmq": "22.3546",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007073",
      "denominazione_ita_altra": "Verrès",
      "denominazione_ita": "Verrès",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C282",
      "lat": "45.6687354",
      "lon": "7.6899406",
      "superficie_kmq": "8.3607",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "AO",
      "codice_istat": "007074",
      "denominazione_ita_altra": "Villeneuve",
      "denominazione_ita": "Villeneuve",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L981",
      "lat": "45.7027608",
      "lon": "7.2077395",
      "superficie_kmq": "8.7979",
      "codice_sovracomunale": "007"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008001",
      "denominazione_ita_altra": "Airole",
      "denominazione_ita": "Airole",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A111",
      "lat": "43.8719140",
      "lon": "7.5537342",
      "superficie_kmq": "14.6268",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008002",
      "denominazione_ita_altra": "Apricale",
      "denominazione_ita": "Apricale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A338",
      "lat": "43.8804478",
      "lon": "7.6604748",
      "superficie_kmq": "19.9364",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008003",
      "denominazione_ita_altra": "Aquila d'Arroscia",
      "denominazione_ita": "Aquila d'Arroscia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A344",
      "lat": "44.0836723",
      "lon": "8.0053808",
      "superficie_kmq": "10.0601",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008004",
      "denominazione_ita_altra": "Armo",
      "denominazione_ita": "Armo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A418",
      "lat": "44.0873600",
      "lon": "7.9140091",
      "superficie_kmq": "10.0925",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008005",
      "denominazione_ita_altra": "Aurigo",
      "denominazione_ita": "Aurigo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A499",
      "lat": "43.9829970",
      "lon": "7.9225641",
      "superficie_kmq": "9.1429",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008006",
      "denominazione_ita_altra": "Badalucco",
      "denominazione_ita": "Badalucco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A536",
      "lat": "43.9156189",
      "lon": "7.8470478",
      "superficie_kmq": "16.0999",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008007",
      "denominazione_ita_altra": "Bajardo",
      "denominazione_ita": "Bajardo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A581",
      "lat": "43.9034868",
      "lon": "7.7182324",
      "superficie_kmq": "24.3196",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008008",
      "denominazione_ita_altra": "Bordighera",
      "denominazione_ita": "Bordighera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A984",
      "lat": "43.7797422",
      "lon": "7.6718889",
      "superficie_kmq": "10.6456",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008009",
      "denominazione_ita_altra": "Borghetto d'Arroscia",
      "denominazione_ita": "Borghetto d'Arroscia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A993",
      "lat": "44.0574515",
      "lon": "7.9818271",
      "superficie_kmq": "25.9387",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008010",
      "denominazione_ita_altra": "Borgomaro",
      "denominazione_ita": "Borgomaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B020",
      "lat": "43.9755854",
      "lon": "7.9448964",
      "superficie_kmq": "23.4357",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008011",
      "denominazione_ita_altra": "Camporosso",
      "denominazione_ita": "Camporosso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B559",
      "lat": "43.8151628",
      "lon": "7.6283324",
      "superficie_kmq": "17.9447",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008012",
      "denominazione_ita_altra": "Caravonica",
      "denominazione_ita": "Caravonica",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B734",
      "lat": "43.9923250",
      "lon": "7.9588782",
      "superficie_kmq": "4.4708",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008014",
      "denominazione_ita_altra": "Castellaro",
      "denominazione_ita": "Castellaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C143",
      "lat": "43.8646810",
      "lon": "7.8683164",
      "superficie_kmq": "7.8606",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008015",
      "denominazione_ita_altra": "Castel Vittorio",
      "denominazione_ita": "Castel Vittorio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C110",
      "lat": "43.9287266",
      "lon": "7.6731857",
      "superficie_kmq": "25.9282",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008016",
      "denominazione_ita_altra": "Ceriana",
      "denominazione_ita": "Ceriana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C511",
      "lat": "43.8809296",
      "lon": "7.7729467",
      "superficie_kmq": "31.7924",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008017",
      "denominazione_ita_altra": "Cervo",
      "denominazione_ita": "Cervo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C559",
      "lat": "43.9253209",
      "lon": "8.1143923",
      "superficie_kmq": "3.5850",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008018",
      "denominazione_ita_altra": "Cesio",
      "denominazione_ita": "Cesio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C578",
      "lat": "44.0076981",
      "lon": "7.9749721",
      "superficie_kmq": "8.8570",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008019",
      "denominazione_ita_altra": "Chiusanico",
      "denominazione_ita": "Chiusanico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C657",
      "lat": "43.9732241",
      "lon": "7.9916404",
      "superficie_kmq": "13.5068",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008020",
      "denominazione_ita_altra": "Chiusavecchia",
      "denominazione_ita": "Chiusavecchia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C660",
      "lat": "43.9678747",
      "lon": "7.9833273",
      "superficie_kmq": "4.0918",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008021",
      "denominazione_ita_altra": "Cipressa",
      "denominazione_ita": "Cipressa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C718",
      "lat": "43.8529346",
      "lon": "7.9340957",
      "superficie_kmq": "9.3930",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008022",
      "denominazione_ita_altra": "Civezza",
      "denominazione_ita": "Civezza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C755",
      "lat": "43.8793189",
      "lon": "7.9512847",
      "superficie_kmq": "3.8809",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008023",
      "denominazione_ita_altra": "Cosio d'Arroscia",
      "denominazione_ita": "Cosio d'Arroscia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D087",
      "lat": "44.0758019",
      "lon": "7.8311678",
      "superficie_kmq": "40.5548",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008024",
      "denominazione_ita_altra": "Costarainera",
      "denominazione_ita": "Costarainera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D114",
      "lat": "43.8545168",
      "lon": "7.9412404",
      "superficie_kmq": "2.5190",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008025",
      "denominazione_ita_altra": "Diano Arentino",
      "denominazione_ita": "Diano Arentino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D293",
      "lat": "43.9479812",
      "lon": "8.0422747",
      "superficie_kmq": "8.3343",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008026",
      "denominazione_ita_altra": "Diano Castello",
      "denominazione_ita": "Diano Castello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D296",
      "lat": "43.9241588",
      "lon": "8.0666595",
      "superficie_kmq": "6.1136",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008027",
      "denominazione_ita_altra": "Diano Marina",
      "denominazione_ita": "Diano Marina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D297",
      "lat": "43.9100167",
      "lon": "8.0817928",
      "superficie_kmq": "6.6729",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008028",
      "denominazione_ita_altra": "Diano San Pietro",
      "denominazione_ita": "Diano San Pietro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D298",
      "lat": "43.9311400",
      "lon": "8.0687894",
      "superficie_kmq": "11.9117",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008029",
      "denominazione_ita_altra": "Dolceacqua",
      "denominazione_ita": "Dolceacqua",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D318",
      "lat": "43.8481004",
      "lon": "7.6234657",
      "superficie_kmq": "20.2846",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008030",
      "denominazione_ita_altra": "Dolcedo",
      "denominazione_ita": "Dolcedo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D319",
      "lat": "43.9069224",
      "lon": "7.9517765",
      "superficie_kmq": "19.7953",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008031",
      "denominazione_ita_altra": "Imperia",
      "denominazione_ita": "Imperia",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "E290",
      "lat": "43.8856884",
      "lon": "8.0278506",
      "superficie_kmq": "45.3823",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008032",
      "denominazione_ita_altra": "Isolabona",
      "denominazione_ita": "Isolabona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E346",
      "lat": "43.8799725",
      "lon": "7.6388632",
      "superficie_kmq": "12.3465",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008033",
      "denominazione_ita_altra": "Lucinasco",
      "denominazione_ita": "Lucinasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E719",
      "lat": "43.9677587",
      "lon": "7.9627524",
      "superficie_kmq": "7.8969",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008034",
      "denominazione_ita_altra": "Mendatica",
      "denominazione_ita": "Mendatica",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F123",
      "lat": "44.0763582",
      "lon": "7.8055599",
      "superficie_kmq": "30.6912",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008035",
      "denominazione_ita_altra": "Molini di Triora",
      "denominazione_ita": "Molini di Triora",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F290",
      "lat": "43.9901246",
      "lon": "7.7743299",
      "superficie_kmq": "58.0472",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008037",
      "denominazione_ita_altra": "Montegrosso Pian Latte",
      "denominazione_ita": "Montegrosso Pian Latte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F528",
      "lat": "44.0665945",
      "lon": "7.8178446",
      "superficie_kmq": "10.0261",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008038",
      "denominazione_ita_altra": "Olivetta San Michele",
      "denominazione_ita": "Olivetta San Michele",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G041",
      "lat": "43.8797903",
      "lon": "7.5155031",
      "superficie_kmq": "13.8375",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008039",
      "denominazione_ita_altra": "Ospedaletti",
      "denominazione_ita": "Ospedaletti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G164",
      "lat": "43.8007230",
      "lon": "7.7177018",
      "superficie_kmq": "5.4462",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008040",
      "denominazione_ita_altra": "Perinaldo",
      "denominazione_ita": "Perinaldo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G454",
      "lat": "43.8661809",
      "lon": "7.6742398",
      "superficie_kmq": "20.2994",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008041",
      "denominazione_ita_altra": "Pietrabruna",
      "denominazione_ita": "Pietrabruna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G607",
      "lat": "43.8899565",
      "lon": "7.9034762",
      "superficie_kmq": "10.2246",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008042",
      "denominazione_ita_altra": "Pieve di Teco",
      "denominazione_ita": "Pieve di Teco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G632",
      "lat": "44.0469822",
      "lon": "7.9152154",
      "superficie_kmq": "40.5045",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008043",
      "denominazione_ita_altra": "Pigna",
      "denominazione_ita": "Pigna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G660",
      "lat": "43.9329923",
      "lon": "7.6633807",
      "superficie_kmq": "53.2305",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008044",
      "denominazione_ita_altra": "Pompeiana",
      "denominazione_ita": "Pompeiana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G814",
      "lat": "43.8527632",
      "lon": "7.8885285",
      "superficie_kmq": "5.3802",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008045",
      "denominazione_ita_altra": "Pontedassio",
      "denominazione_ita": "Pontedassio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G840",
      "lat": "43.9404276",
      "lon": "8.0126944",
      "superficie_kmq": "13.3128",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008046",
      "denominazione_ita_altra": "Pornassio",
      "denominazione_ita": "Pornassio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G890",
      "lat": "44.0702531",
      "lon": "7.8709539",
      "superficie_kmq": "27.2114",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008047",
      "denominazione_ita_altra": "Prelà",
      "denominazione_ita": "Prelà",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H027",
      "lat": "43.9262062",
      "lon": "7.9369917",
      "superficie_kmq": "14.8026",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008048",
      "denominazione_ita_altra": "Ranzo",
      "denominazione_ita": "Ranzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H180",
      "lat": "44.0595458",
      "lon": "8.0144682",
      "superficie_kmq": "10.8563",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008049",
      "denominazione_ita_altra": "Rezzo",
      "denominazione_ita": "Rezzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H257",
      "lat": "44.0202976",
      "lon": "7.8721575",
      "superficie_kmq": "37.3683",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008050",
      "denominazione_ita_altra": "Riva Ligure",
      "denominazione_ita": "Riva Ligure",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H328",
      "lat": "43.8375052",
      "lon": "7.8817125",
      "superficie_kmq": "2.0712",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008051",
      "denominazione_ita_altra": "Rocchetta Nervina",
      "denominazione_ita": "Rocchetta Nervina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H460",
      "lat": "43.8901585",
      "lon": "7.6002952",
      "superficie_kmq": "15.2848",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008052",
      "denominazione_ita_altra": "San Bartolomeo al Mare",
      "denominazione_ita": "San Bartolomeo al Mare",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H763",
      "lat": "43.9284954",
      "lon": "8.1021686",
      "superficie_kmq": "10.8533",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008053",
      "denominazione_ita_altra": "San Biagio della Cima",
      "denominazione_ita": "San Biagio della Cima",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H780",
      "lat": "43.8187688",
      "lon": "7.6498503",
      "superficie_kmq": "4.3070",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008054",
      "denominazione_ita_altra": "San Lorenzo al Mare",
      "denominazione_ita": "San Lorenzo al Mare",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H957",
      "lat": "43.8535908",
      "lon": "7.9638818",
      "superficie_kmq": "1.2886",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008055",
      "denominazione_ita_altra": "Sanremo",
      "denominazione_ita": "Sanremo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I138",
      "lat": "43.8209810",
      "lon": "7.7846330",
      "superficie_kmq": "55.9577",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008056",
      "denominazione_ita_altra": "Santo Stefano al Mare",
      "denominazione_ita": "Santo Stefano al Mare",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I365",
      "lat": "43.8372295",
      "lon": "7.8990784",
      "superficie_kmq": "2.6886",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008057",
      "denominazione_ita_altra": "Seborga",
      "denominazione_ita": "Seborga",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I556",
      "lat": "43.8258641",
      "lon": "7.6941607",
      "superficie_kmq": "4.8705",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008058",
      "denominazione_ita_altra": "Soldano",
      "denominazione_ita": "Soldano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I796",
      "lat": "43.8267039",
      "lon": "7.6540800",
      "superficie_kmq": "3.4660",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008059",
      "denominazione_ita_altra": "Taggia",
      "denominazione_ita": "Taggia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L024",
      "lat": "43.8436087",
      "lon": "7.8505232",
      "superficie_kmq": "31.3583",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008060",
      "denominazione_ita_altra": "Terzorio",
      "denominazione_ita": "Terzorio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L146",
      "lat": "43.8528218",
      "lon": "7.8982479",
      "superficie_kmq": "1.9254",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008061",
      "denominazione_ita_altra": "Triora",
      "denominazione_ita": "Triora",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L430",
      "lat": "43.9937968",
      "lon": "7.7621127",
      "superficie_kmq": "67.6060",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008062",
      "denominazione_ita_altra": "Vallebona",
      "denominazione_ita": "Vallebona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L596",
      "lat": "43.8132615",
      "lon": "7.6668365",
      "superficie_kmq": "5.8808",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008063",
      "denominazione_ita_altra": "Vallecrosia",
      "denominazione_ita": "Vallecrosia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L599",
      "lat": "43.7931629",
      "lon": "7.6437876",
      "superficie_kmq": "3.6815",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008064",
      "denominazione_ita_altra": "Vasia",
      "denominazione_ita": "Vasia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L693",
      "lat": "43.9330058",
      "lon": "7.9541079",
      "superficie_kmq": "11.1513",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008065",
      "denominazione_ita_altra": "Ventimiglia",
      "denominazione_ita": "Ventimiglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L741",
      "lat": "43.7900180",
      "lon": "7.6086353",
      "superficie_kmq": "53.7257",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008066",
      "denominazione_ita_altra": "Vessalico",
      "denominazione_ita": "Vessalico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L809",
      "lat": "44.0462117",
      "lon": "7.9622468",
      "superficie_kmq": "10.4645",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008067",
      "denominazione_ita_altra": "Villa Faraldi",
      "denominazione_ita": "Villa Faraldi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L943",
      "lat": "43.9670748",
      "lon": "8.0914672",
      "superficie_kmq": "9.5157",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "IM",
      "codice_istat": "008068",
      "denominazione_ita_altra": "Montalto Carpasio",
      "denominazione_ita": "Montalto Carpasio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M387",
      "lat": "43.9461741",
      "lon": "7.8503602",
      "superficie_kmq": "30.0037",
      "codice_sovracomunale": "008"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009001",
      "denominazione_ita_altra": "Alassio",
      "denominazione_ita": "Alassio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A122",
      "lat": "44.0080222",
      "lon": "8.1729613",
      "superficie_kmq": "17.2476",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009002",
      "denominazione_ita_altra": "Albenga",
      "denominazione_ita": "Albenga",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A145",
      "lat": "44.0490915",
      "lon": "8.2129474",
      "superficie_kmq": "36.5751",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009003",
      "denominazione_ita_altra": "Albissola Marina",
      "denominazione_ita": "Albissola Marina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A165",
      "lat": "44.3272366",
      "lon": "8.5031814",
      "superficie_kmq": "3.2457",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009004",
      "denominazione_ita_altra": "Albisola Superiore",
      "denominazione_ita": "Albisola Superiore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A166",
      "lat": "44.3396755",
      "lon": "8.5103396",
      "superficie_kmq": "28.6771",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009005",
      "denominazione_ita_altra": "Altare",
      "denominazione_ita": "Altare",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A226",
      "lat": "44.3347713",
      "lon": "8.3439315",
      "superficie_kmq": "11.2964",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009006",
      "denominazione_ita_altra": "Andora",
      "denominazione_ita": "Andora",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A278",
      "lat": "43.9582305",
      "lon": "8.1400348",
      "superficie_kmq": "31.7987",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009007",
      "denominazione_ita_altra": "Arnasco",
      "denominazione_ita": "Arnasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A422",
      "lat": "44.0779834",
      "lon": "8.1069681",
      "superficie_kmq": "6.0930",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009008",
      "denominazione_ita_altra": "Balestrino",
      "denominazione_ita": "Balestrino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A593",
      "lat": "44.1248827",
      "lon": "8.1745869",
      "superficie_kmq": "11.2733",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009009",
      "denominazione_ita_altra": "Bardineto",
      "denominazione_ita": "Bardineto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A647",
      "lat": "44.1920828",
      "lon": "8.1293889",
      "superficie_kmq": "29.7933",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009010",
      "denominazione_ita_altra": "Bergeggi",
      "denominazione_ita": "Bergeggi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A796",
      "lat": "44.2490483",
      "lon": "8.4441010",
      "superficie_kmq": "3.6899",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009011",
      "denominazione_ita_altra": "Boissano",
      "denominazione_ita": "Boissano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A931",
      "lat": "44.1342280",
      "lon": "8.2212316",
      "superficie_kmq": "8.3522",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009012",
      "denominazione_ita_altra": "Borghetto Santo Spirito",
      "denominazione_ita": "Borghetto Santo Spirito",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A999",
      "lat": "44.1153694",
      "lon": "8.2419882",
      "superficie_kmq": "5.3910",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009013",
      "denominazione_ita_altra": "Borgio Verezzi",
      "denominazione_ita": "Borgio Verezzi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B005",
      "lat": "44.1598605",
      "lon": "8.3045611",
      "superficie_kmq": "2.7326",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009014",
      "denominazione_ita_altra": "Bormida",
      "denominazione_ita": "Bormida",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B048",
      "lat": "44.2786447",
      "lon": "8.2326114",
      "superficie_kmq": "22.4709",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009015",
      "denominazione_ita_altra": "Cairo Montenotte",
      "denominazione_ita": "Cairo Montenotte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B369",
      "lat": "44.3997074",
      "lon": "8.2741727",
      "superficie_kmq": "100.4026",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009016",
      "denominazione_ita_altra": "Calice Ligure",
      "denominazione_ita": "Calice Ligure",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B409",
      "lat": "44.2049045",
      "lon": "8.2942695",
      "superficie_kmq": "20.6034",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009017",
      "denominazione_ita_altra": "Calizzano",
      "denominazione_ita": "Calizzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B416",
      "lat": "44.2351830",
      "lon": "8.1153716",
      "superficie_kmq": "62.7437",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009018",
      "denominazione_ita_altra": "Carcare",
      "denominazione_ita": "Carcare",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B748",
      "lat": "44.3579556",
      "lon": "8.2908282",
      "superficie_kmq": "10.4005",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009019",
      "denominazione_ita_altra": "Casanova Lerrone",
      "denominazione_ita": "Casanova Lerrone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B927",
      "lat": "44.0315782",
      "lon": "8.0482772",
      "superficie_kmq": "24.2297",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009020",
      "denominazione_ita_altra": "Castelbianco",
      "denominazione_ita": "Castelbianco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C063",
      "lat": "44.1132008",
      "lon": "8.0747994",
      "superficie_kmq": "14.7042",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009021",
      "denominazione_ita_altra": "Castelvecchio di Rocca Barbena",
      "denominazione_ita": "Castelvecchio di Rocca Barbena",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C276",
      "lat": "44.1303687",
      "lon": "8.1155061",
      "superficie_kmq": "16.1434",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009022",
      "denominazione_ita_altra": "Celle Ligure",
      "denominazione_ita": "Celle Ligure",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C443",
      "lat": "44.3420502",
      "lon": "8.5452236",
      "superficie_kmq": "9.5606",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009023",
      "denominazione_ita_altra": "Cengio",
      "denominazione_ita": "Cengio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C463",
      "lat": "44.3875528",
      "lon": "8.2084098",
      "superficie_kmq": "18.9617",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009024",
      "denominazione_ita_altra": "Ceriale",
      "denominazione_ita": "Ceriale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C510",
      "lat": "44.0963417",
      "lon": "8.2320661",
      "superficie_kmq": "11.1527",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009025",
      "denominazione_ita_altra": "Cisano sul Neva",
      "denominazione_ita": "Cisano sul Neva",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C729",
      "lat": "44.0872783",
      "lon": "8.1449606",
      "superficie_kmq": "12.2727",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009026",
      "denominazione_ita_altra": "Cosseria",
      "denominazione_ita": "Cosseria",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D095",
      "lat": "44.3685020",
      "lon": "8.2344564",
      "superficie_kmq": "12.4065",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009027",
      "denominazione_ita_altra": "Dego",
      "denominazione_ita": "Dego",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D264",
      "lat": "44.4495854",
      "lon": "8.3103820",
      "superficie_kmq": "66.8145",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009028",
      "denominazione_ita_altra": "Erli",
      "denominazione_ita": "Erli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D424",
      "lat": "44.1365482",
      "lon": "8.1053312",
      "superficie_kmq": "16.7298",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009029",
      "denominazione_ita_altra": "Finale Ligure",
      "denominazione_ita": "Finale Ligure",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D600",
      "lat": "44.1690091",
      "lon": "8.3436046",
      "superficie_kmq": "35.5254",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009030",
      "denominazione_ita_altra": "Garlenda",
      "denominazione_ita": "Garlenda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D927",
      "lat": "44.0335846",
      "lon": "8.0967961",
      "superficie_kmq": "8.0315",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009031",
      "denominazione_ita_altra": "Giustenice",
      "denominazione_ita": "Giustenice",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E064",
      "lat": "44.1723119",
      "lon": "8.2462160",
      "superficie_kmq": "17.2237",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009032",
      "denominazione_ita_altra": "Giusvalla",
      "denominazione_ita": "Giusvalla",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E066",
      "lat": "44.4494693",
      "lon": "8.3937269",
      "superficie_kmq": "19.7021",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009033",
      "denominazione_ita_altra": "Laigueglia",
      "denominazione_ita": "Laigueglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E414",
      "lat": "43.9746022",
      "lon": "8.1581444",
      "superficie_kmq": "2.7242",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009034",
      "denominazione_ita_altra": "Loano",
      "denominazione_ita": "Loano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E632",
      "lat": "44.1291586",
      "lon": "8.2597773",
      "superficie_kmq": "13.4774",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009035",
      "denominazione_ita_altra": "Magliolo",
      "denominazione_ita": "Magliolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E816",
      "lat": "44.1916230",
      "lon": "8.2501862",
      "superficie_kmq": "19.5718",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009036",
      "denominazione_ita_altra": "Mallare",
      "denominazione_ita": "Mallare",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E860",
      "lat": "44.2914539",
      "lon": "8.2975768",
      "superficie_kmq": "31.7301",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009037",
      "denominazione_ita_altra": "Massimino",
      "denominazione_ita": "Massimino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F046",
      "lat": "44.2993814",
      "lon": "8.0711602",
      "superficie_kmq": "7.8507",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009038",
      "denominazione_ita_altra": "Millesimo",
      "denominazione_ita": "Millesimo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F213",
      "lat": "44.3644121",
      "lon": "8.2058184",
      "superficie_kmq": "15.9575",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009039",
      "denominazione_ita_altra": "Mioglia",
      "denominazione_ita": "Mioglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F226",
      "lat": "44.4898520",
      "lon": "8.4112729",
      "superficie_kmq": "19.2980",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009040",
      "denominazione_ita_altra": "Murialdo",
      "denominazione_ita": "Murialdo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F813",
      "lat": "44.3161602",
      "lon": "8.1613570",
      "superficie_kmq": "39.2213",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009041",
      "denominazione_ita_altra": "Nasino",
      "denominazione_ita": "Nasino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F847",
      "lat": "44.1150991",
      "lon": "8.0278418",
      "superficie_kmq": "22.1769",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009042",
      "denominazione_ita_altra": "Noli",
      "denominazione_ita": "Noli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F926",
      "lat": "44.2056498",
      "lon": "8.4161844",
      "superficie_kmq": "9.6682",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009043",
      "denominazione_ita_altra": "Onzo",
      "denominazione_ita": "Onzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G076",
      "lat": "44.0695763",
      "lon": "8.0514115",
      "superficie_kmq": "8.2263",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009044",
      "denominazione_ita_altra": "Orco Feglino",
      "denominazione_ita": "Orco Feglino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D522",
      "lat": "44.2195535",
      "lon": "8.3251620",
      "superficie_kmq": "17.3130",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009045",
      "denominazione_ita_altra": "Ortovero",
      "denominazione_ita": "Ortovero",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G144",
      "lat": "44.0538113",
      "lon": "8.0910885",
      "superficie_kmq": "9.6586",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009046",
      "denominazione_ita_altra": "Osiglia",
      "denominazione_ita": "Osiglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G155",
      "lat": "44.2807790",
      "lon": "8.2006191",
      "superficie_kmq": "28.1736",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009047",
      "denominazione_ita_altra": "Pallare",
      "denominazione_ita": "Pallare",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G281",
      "lat": "44.3276203",
      "lon": "8.2749643",
      "superficie_kmq": "21.3321",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009048",
      "denominazione_ita_altra": "Piana Crixia",
      "denominazione_ita": "Piana Crixia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G542",
      "lat": "44.4852627",
      "lon": "8.3075121",
      "superficie_kmq": "30.4451",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009049",
      "denominazione_ita_altra": "Pietra Ligure",
      "denominazione_ita": "Pietra Ligure",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G605",
      "lat": "44.1488471",
      "lon": "8.2828272",
      "superficie_kmq": "9.8781",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009050",
      "denominazione_ita_altra": "Plodio",
      "denominazione_ita": "Plodio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G741",
      "lat": "44.3565203",
      "lon": "8.2435229",
      "superficie_kmq": "8.6454",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009051",
      "denominazione_ita_altra": "Pontinvrea",
      "denominazione_ita": "Pontinvrea",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G866",
      "lat": "44.4448870",
      "lon": "8.4345702",
      "superficie_kmq": "24.9456",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009052",
      "denominazione_ita_altra": "Quiliano",
      "denominazione_ita": "Quiliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H126",
      "lat": "44.2934099",
      "lon": "8.4113798",
      "superficie_kmq": "49.9186",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009053",
      "denominazione_ita_altra": "Rialto",
      "denominazione_ita": "Rialto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H266",
      "lat": "44.2195947",
      "lon": "8.2747214",
      "superficie_kmq": "19.5971",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009054",
      "denominazione_ita_altra": "Roccavignale",
      "denominazione_ita": "Roccavignale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H452",
      "lat": "44.3618745",
      "lon": "8.1920123",
      "superficie_kmq": "17.7066",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009055",
      "denominazione_ita_altra": "Sassello",
      "denominazione_ita": "Sassello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I453",
      "lat": "44.4790272",
      "lon": "8.4899453",
      "superficie_kmq": "100.6560",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009056",
      "denominazione_ita_altra": "Savona",
      "denominazione_ita": "Savona",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "I480",
      "lat": "44.3074927",
      "lon": "8.4811088",
      "superficie_kmq": "65.3174",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009057",
      "denominazione_ita_altra": "Spotorno",
      "denominazione_ita": "Spotorno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I926",
      "lat": "44.2271587",
      "lon": "8.4194628",
      "superficie_kmq": "8.0198",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009058",
      "denominazione_ita_altra": "Stella",
      "denominazione_ita": "Stella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I946",
      "lat": "44.3968814",
      "lon": "8.4930781",
      "superficie_kmq": "43.6807",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009059",
      "denominazione_ita_altra": "Stellanello",
      "denominazione_ita": "Stellanello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I947",
      "lat": "44.0003014",
      "lon": "8.0593307",
      "superficie_kmq": "17.8062",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009060",
      "denominazione_ita_altra": "Testico",
      "denominazione_ita": "Testico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L152",
      "lat": "44.0053528",
      "lon": "8.0309741",
      "superficie_kmq": "10.2868",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009061",
      "denominazione_ita_altra": "Toirano",
      "denominazione_ita": "Toirano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L190",
      "lat": "44.1269032",
      "lon": "8.2071936",
      "superficie_kmq": "18.9652",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009062",
      "denominazione_ita_altra": "Tovo San Giacomo",
      "denominazione_ita": "Tovo San Giacomo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L315",
      "lat": "44.1766880",
      "lon": "8.2685381",
      "superficie_kmq": "9.4467",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009063",
      "denominazione_ita_altra": "Urbe",
      "denominazione_ita": "Urbe",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L499",
      "lat": "44.4877447",
      "lon": "8.5923872",
      "superficie_kmq": "31.1689",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009064",
      "denominazione_ita_altra": "Vado Ligure",
      "denominazione_ita": "Vado Ligure",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L528",
      "lat": "44.2693257",
      "lon": "8.4362807",
      "superficie_kmq": "23.7846",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009065",
      "denominazione_ita_altra": "Varazze",
      "denominazione_ita": "Varazze",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L675",
      "lat": "44.3599878",
      "lon": "8.5763981",
      "superficie_kmq": "47.9963",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009066",
      "denominazione_ita_altra": "Vendone",
      "denominazione_ita": "Vendone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L730",
      "lat": "44.0739960",
      "lon": "8.0742597",
      "superficie_kmq": "9.9150",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009067",
      "denominazione_ita_altra": "Vezzi Portio",
      "denominazione_ita": "Vezzi Portio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L823",
      "lat": "44.2289334",
      "lon": "8.3619475",
      "superficie_kmq": "8.7616",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009068",
      "denominazione_ita_altra": "Villanova d'Albenga",
      "denominazione_ita": "Villanova d'Albenga",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L975",
      "lat": "44.0458850",
      "lon": "8.1430809",
      "superficie_kmq": "15.8914",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "SV",
      "codice_istat": "009069",
      "denominazione_ita_altra": "Zuccarello",
      "denominazione_ita": "Zuccarello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M197",
      "lat": "44.1113325",
      "lon": "8.1156583",
      "superficie_kmq": "10.8070",
      "codice_sovracomunale": "009"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010001",
      "denominazione_ita_altra": "Arenzano",
      "denominazione_ita": "Arenzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A388",
      "lat": "44.4017827",
      "lon": "8.6822606",
      "superficie_kmq": "24.2972",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010002",
      "denominazione_ita_altra": "Avegno",
      "denominazione_ita": "Avegno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A506",
      "lat": "44.3822961",
      "lon": "9.1587177",
      "superficie_kmq": "10.9322",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010003",
      "denominazione_ita_altra": "Bargagli",
      "denominazione_ita": "Bargagli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A658",
      "lat": "44.4446331",
      "lon": "9.0883387",
      "superficie_kmq": "16.2765",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010004",
      "denominazione_ita_altra": "Bogliasco",
      "denominazione_ita": "Bogliasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A922",
      "lat": "44.3782107",
      "lon": "9.0697813",
      "superficie_kmq": "4.4242",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010005",
      "denominazione_ita_altra": "Borzonasca",
      "denominazione_ita": "Borzonasca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B067",
      "lat": "44.4221928",
      "lon": "9.3867974",
      "superficie_kmq": "80.5133",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010006",
      "denominazione_ita_altra": "Busalla",
      "denominazione_ita": "Busalla",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B282",
      "lat": "44.5707931",
      "lon": "8.9449805",
      "superficie_kmq": "17.0618",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010007",
      "denominazione_ita_altra": "Camogli",
      "denominazione_ita": "Camogli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B490",
      "lat": "44.3467936",
      "lon": "9.1571384",
      "superficie_kmq": "10.0652",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010008",
      "denominazione_ita_altra": "Campo Ligure",
      "denominazione_ita": "Campo Ligure",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B538",
      "lat": "44.5383796",
      "lon": "8.6976292",
      "superficie_kmq": "23.7401",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010009",
      "denominazione_ita_altra": "Campomorone",
      "denominazione_ita": "Campomorone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B551",
      "lat": "44.5066865",
      "lon": "8.8926758",
      "superficie_kmq": "25.9059",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010010",
      "denominazione_ita_altra": "Carasco",
      "denominazione_ita": "Carasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B726",
      "lat": "44.3513034",
      "lon": "9.3440404",
      "superficie_kmq": "8.4579",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010011",
      "denominazione_ita_altra": "Casarza Ligure",
      "denominazione_ita": "Casarza Ligure",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B939",
      "lat": "44.2746082",
      "lon": "9.4534569",
      "superficie_kmq": "27.8222",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010012",
      "denominazione_ita_altra": "Casella",
      "denominazione_ita": "Casella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B956",
      "lat": "44.5349873",
      "lon": "8.9991988",
      "superficie_kmq": "8.0656",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010013",
      "denominazione_ita_altra": "Castiglione Chiavarese",
      "denominazione_ita": "Castiglione Chiavarese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C302",
      "lat": "44.2746986",
      "lon": "9.5135480",
      "superficie_kmq": "29.7498",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010014",
      "denominazione_ita_altra": "Ceranesi",
      "denominazione_ita": "Ceranesi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C481",
      "lat": "44.5039261",
      "lon": "8.8945971",
      "superficie_kmq": "30.6952",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010015",
      "denominazione_ita_altra": "Chiavari",
      "denominazione_ita": "Chiavari",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C621",
      "lat": "44.3166989",
      "lon": "9.3240170",
      "superficie_kmq": "12.2310",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010016",
      "denominazione_ita_altra": "Cicagna",
      "denominazione_ita": "Cicagna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C673",
      "lat": "44.4085895",
      "lon": "9.2359489",
      "superficie_kmq": "11.2837",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010017",
      "denominazione_ita_altra": "Cogoleto",
      "denominazione_ita": "Cogoleto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C823",
      "lat": "44.3894582",
      "lon": "8.6463299",
      "superficie_kmq": "20.7228",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010018",
      "denominazione_ita_altra": "Cogorno",
      "denominazione_ita": "Cogorno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C826",
      "lat": "44.3286344",
      "lon": "9.3521727",
      "superficie_kmq": "9.0821",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010019",
      "denominazione_ita_altra": "Coreglia Ligure",
      "denominazione_ita": "Coreglia Ligure",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C995",
      "lat": "44.3920341",
      "lon": "9.2669873",
      "superficie_kmq": "8.0024",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010020",
      "denominazione_ita_altra": "Crocefieschi",
      "denominazione_ita": "Crocefieschi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D175",
      "lat": "44.5848167",
      "lon": "9.0228175",
      "superficie_kmq": "11.7248",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010021",
      "denominazione_ita_altra": "Davagna",
      "denominazione_ita": "Davagna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D255",
      "lat": "44.4647349",
      "lon": "9.0866897",
      "superficie_kmq": "20.5252",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010022",
      "denominazione_ita_altra": "Fascia",
      "denominazione_ita": "Fascia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D509",
      "lat": "44.5644819",
      "lon": "9.2362667",
      "superficie_kmq": "11.2540",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010023",
      "denominazione_ita_altra": "Favale di Malvaro",
      "denominazione_ita": "Favale di Malvaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D512",
      "lat": "44.4520292",
      "lon": "9.2580251",
      "superficie_kmq": "16.6164",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010024",
      "denominazione_ita_altra": "Fontanigorda",
      "denominazione_ita": "Fontanigorda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D677",
      "lat": "44.5465479",
      "lon": "9.3049480",
      "superficie_kmq": "16.1628",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010025",
      "denominazione_ita_altra": "Genova",
      "denominazione_ita": "Genova",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "D969",
      "lat": "44.4114827",
      "lon": "8.9326992",
      "superficie_kmq": "240.2876",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010026",
      "denominazione_ita_altra": "Gorreto",
      "denominazione_ita": "Gorreto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E109",
      "lat": "44.6049614",
      "lon": "9.2920949",
      "superficie_kmq": "18.8760",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010027",
      "denominazione_ita_altra": "Isola del Cantone",
      "denominazione_ita": "Isola del Cantone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E341",
      "lat": "44.6458385",
      "lon": "8.9559704",
      "superficie_kmq": "47.9672",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010028",
      "denominazione_ita_altra": "Lavagna",
      "denominazione_ita": "Lavagna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E488",
      "lat": "44.3071457",
      "lon": "9.3440367",
      "superficie_kmq": "13.8763",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010029",
      "denominazione_ita_altra": "Leivi",
      "denominazione_ita": "Leivi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E519",
      "lat": "44.3455120",
      "lon": "9.3182163",
      "superficie_kmq": "9.7099",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010030",
      "denominazione_ita_altra": "Lorsica",
      "denominazione_ita": "Lorsica",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E695",
      "lat": "44.4323345",
      "lon": "9.2743344",
      "superficie_kmq": "17.7184",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010031",
      "denominazione_ita_altra": "Lumarzo",
      "denominazione_ita": "Lumarzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E737",
      "lat": "44.4435587",
      "lon": "9.1373208",
      "superficie_kmq": "25.5078",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010032",
      "denominazione_ita_altra": "Masone",
      "denominazione_ita": "Masone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F020",
      "lat": "44.5036272",
      "lon": "8.7116929",
      "superficie_kmq": "29.4412",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010033",
      "denominazione_ita_altra": "Mele",
      "denominazione_ita": "Mele",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F098",
      "lat": "44.4453930",
      "lon": "8.7480287",
      "superficie_kmq": "16.9335",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010034",
      "denominazione_ita_altra": "Mezzanego",
      "denominazione_ita": "Mezzanego",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F173",
      "lat": "44.3836866",
      "lon": "9.3769056",
      "superficie_kmq": "28.6498",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010035",
      "denominazione_ita_altra": "Mignanego",
      "denominazione_ita": "Mignanego",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F202",
      "lat": "44.5276349",
      "lon": "8.9146833",
      "superficie_kmq": "16.2743",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010036",
      "denominazione_ita_altra": "Moconesi",
      "denominazione_ita": "Moconesi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F256",
      "lat": "44.4195185",
      "lon": "9.2123474",
      "superficie_kmq": "16.2718",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010037",
      "denominazione_ita_altra": "Moneglia",
      "denominazione_ita": "Moneglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F354",
      "lat": "44.2385357",
      "lon": "9.4901268",
      "superficie_kmq": "15.6107",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010038",
      "denominazione_ita_altra": "Montebruno",
      "denominazione_ita": "Montebruno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F445",
      "lat": "44.5270846",
      "lon": "9.2497904",
      "superficie_kmq": "17.6787",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010039",
      "denominazione_ita_altra": "Montoggio",
      "denominazione_ita": "Montoggio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F682",
      "lat": "44.5155717",
      "lon": "9.0441989",
      "superficie_kmq": "47.7246",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010040",
      "denominazione_ita_altra": "Ne",
      "denominazione_ita": "Ne",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F858",
      "lat": "44.3443558",
      "lon": "9.3984203",
      "superficie_kmq": "63.5214",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010041",
      "denominazione_ita_altra": "Neirone",
      "denominazione_ita": "Neirone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F862",
      "lat": "44.4542452",
      "lon": "9.1909010",
      "superficie_kmq": "30.2369",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010042",
      "denominazione_ita_altra": "Orero",
      "denominazione_ita": "Orero",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G093",
      "lat": "44.4072238",
      "lon": "9.2746015",
      "superficie_kmq": "15.9884",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010043",
      "denominazione_ita_altra": "Pieve Ligure",
      "denominazione_ita": "Pieve Ligure",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G646",
      "lat": "44.3746594",
      "lon": "9.0868903",
      "superficie_kmq": "3.5565",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010044",
      "denominazione_ita_altra": "Portofino",
      "denominazione_ita": "Portofino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G913",
      "lat": "44.3040798",
      "lon": "9.2072288",
      "superficie_kmq": "2.5311",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010045",
      "denominazione_ita_altra": "Propata",
      "denominazione_ita": "Propata",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H073",
      "lat": "44.5647089",
      "lon": "9.1848828",
      "superficie_kmq": "16.9310",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010046",
      "denominazione_ita_altra": "Rapallo",
      "denominazione_ita": "Rapallo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H183",
      "lat": "44.3501632",
      "lon": "9.2317468",
      "superficie_kmq": "33.6210",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010047",
      "denominazione_ita_altra": "Recco",
      "denominazione_ita": "Recco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H212",
      "lat": "44.3621819",
      "lon": "9.1435490",
      "superficie_kmq": "9.7716",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010048",
      "denominazione_ita_altra": "Rezzoaglio",
      "denominazione_ita": "Rezzoaglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H258",
      "lat": "44.5256979",
      "lon": "9.3866581",
      "superficie_kmq": "104.7190",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010049",
      "denominazione_ita_altra": "Ronco Scrivia",
      "denominazione_ita": "Ronco Scrivia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H536",
      "lat": "44.6081958",
      "lon": "8.9549880",
      "superficie_kmq": "30.1115",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010050",
      "denominazione_ita_altra": "Rondanina",
      "denominazione_ita": "Rondanina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H546",
      "lat": "44.5623489",
      "lon": "9.2176090",
      "superficie_kmq": "12.8079",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010051",
      "denominazione_ita_altra": "Rossiglione",
      "denominazione_ita": "Rossiglione",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H581",
      "lat": "44.5621053",
      "lon": "8.6705351",
      "superficie_kmq": "47.5932",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010052",
      "denominazione_ita_altra": "Rovegno",
      "denominazione_ita": "Rovegno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H599",
      "lat": "44.5771060",
      "lon": "9.2786730",
      "superficie_kmq": "44.0858",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010053",
      "denominazione_ita_altra": "San Colombano Certenoli",
      "denominazione_ita": "San Colombano Certenoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H802",
      "lat": "44.3717209",
      "lon": "9.3131235",
      "superficie_kmq": "41.5747",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010054",
      "denominazione_ita_altra": "Santa Margherita Ligure",
      "denominazione_ita": "Santa Margherita Ligure",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I225",
      "lat": "44.3348582",
      "lon": "9.2099246",
      "superficie_kmq": "10.0436",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010055",
      "denominazione_ita_altra": "Sant'Olcese",
      "denominazione_ita": "Sant'Olcese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I346",
      "lat": "44.4836564",
      "lon": "8.9676797",
      "superficie_kmq": "21.9016",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010056",
      "denominazione_ita_altra": "Santo Stefano d'Aveto",
      "denominazione_ita": "Santo Stefano d'Aveto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I368",
      "lat": "44.5478205",
      "lon": "9.4509266",
      "superficie_kmq": "54.7734",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010057",
      "denominazione_ita_altra": "Savignone",
      "denominazione_ita": "Savignone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I475",
      "lat": "44.5637275",
      "lon": "8.9879556",
      "superficie_kmq": "21.7440",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010058",
      "denominazione_ita_altra": "Serra Riccò",
      "denominazione_ita": "Serra Riccò",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I640",
      "lat": "44.5078406",
      "lon": "8.9360450",
      "superficie_kmq": "26.2045",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010059",
      "denominazione_ita_altra": "Sestri Levante",
      "denominazione_ita": "Sestri Levante",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I693",
      "lat": "44.2698880",
      "lon": "9.3931623",
      "superficie_kmq": "33.6163",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010060",
      "denominazione_ita_altra": "Sori",
      "denominazione_ita": "Sori",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I852",
      "lat": "44.3727770",
      "lon": "9.1043651",
      "superficie_kmq": "13.0661",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010061",
      "denominazione_ita_altra": "Tiglieto",
      "denominazione_ita": "Tiglieto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L167",
      "lat": "44.5253484",
      "lon": "8.6204455",
      "superficie_kmq": "24.5372",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010062",
      "denominazione_ita_altra": "Torriglia",
      "denominazione_ita": "Torriglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L298",
      "lat": "44.5190106",
      "lon": "9.1577778",
      "superficie_kmq": "60.0146",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010063",
      "denominazione_ita_altra": "Tribogna",
      "denominazione_ita": "Tribogna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L416",
      "lat": "44.4162641",
      "lon": "9.1955997",
      "superficie_kmq": "7.1383",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010064",
      "denominazione_ita_altra": "Uscio",
      "denominazione_ita": "Uscio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L507",
      "lat": "44.4131909",
      "lon": "9.1632877",
      "superficie_kmq": "9.6332",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010065",
      "denominazione_ita_altra": "Valbrevenna",
      "denominazione_ita": "Valbrevenna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L546",
      "lat": "44.5565213",
      "lon": "9.0650260",
      "superficie_kmq": "34.6707",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010066",
      "denominazione_ita_altra": "Vobbia",
      "denominazione_ita": "Vobbia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M105",
      "lat": "44.5997590",
      "lon": "9.0398234",
      "superficie_kmq": "33.4257",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "GE",
      "codice_istat": "010067",
      "denominazione_ita_altra": "Zoagli",
      "denominazione_ita": "Zoagli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M182",
      "lat": "44.3370226",
      "lon": "9.2672431",
      "superficie_kmq": "7.7878",
      "codice_sovracomunale": "210"
    },
    {
      "sigla_provincia": "SP",
      "codice_istat": "011001",
      "denominazione_ita_altra": "Ameglia",
      "denominazione_ita": "Ameglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A261",
      "lat": "44.0657315",
      "lon": "9.9572684",
      "superficie_kmq": "14.1732",
      "codice_sovracomunale": "011"
    },
    {
      "sigla_provincia": "SP",
      "codice_istat": "011002",
      "denominazione_ita_altra": "Arcola",
      "denominazione_ita": "Arcola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A373",
      "lat": "44.1145998",
      "lon": "9.9058502",
      "superficie_kmq": "16.5379",
      "codice_sovracomunale": "011"
    },
    {
      "sigla_provincia": "SP",
      "codice_istat": "011003",
      "denominazione_ita_altra": "Beverino",
      "denominazione_ita": "Beverino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A836",
      "lat": "44.1940556",
      "lon": "9.7692788",
      "superficie_kmq": "34.9450",
      "codice_sovracomunale": "011"
    },
    {
      "sigla_provincia": "SP",
      "codice_istat": "011004",
      "denominazione_ita_altra": "Bolano",
      "denominazione_ita": "Bolano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A932",
      "lat": "44.1888528",
      "lon": "9.8952884",
      "superficie_kmq": "14.5663",
      "codice_sovracomunale": "011"
    },
    {
      "sigla_provincia": "SP",
      "codice_istat": "011005",
      "denominazione_ita_altra": "Bonassola",
      "denominazione_ita": "Bonassola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A961",
      "lat": "44.1833461",
      "lon": "9.5841020",
      "superficie_kmq": "9.1896",
      "codice_sovracomunale": "011"
    },
    {
      "sigla_provincia": "SP",
      "codice_istat": "011006",
      "denominazione_ita_altra": "Borghetto di Vara",
      "denominazione_ita": "Borghetto di Vara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A992",
      "lat": "44.2235064",
      "lon": "9.7216093",
      "superficie_kmq": "27.3369",
      "codice_sovracomunale": "011"
    },
    {
      "sigla_provincia": "SP",
      "codice_istat": "011007",
      "denominazione_ita_altra": "Brugnato",
      "denominazione_ita": "Brugnato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B214",
      "lat": "44.2368525",
      "lon": "9.7250221",
      "superficie_kmq": "11.9013",
      "codice_sovracomunale": "011"
    },
    {
      "sigla_provincia": "SP",
      "codice_istat": "011008",
      "denominazione_ita_altra": "Calice al Cornoviglio",
      "denominazione_ita": "Calice al Cornoviglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B410",
      "lat": "44.2424653",
      "lon": "9.8358309",
      "superficie_kmq": "33.7544",
      "codice_sovracomunale": "011"
    },
    {
      "sigla_provincia": "SP",
      "codice_istat": "011009",
      "denominazione_ita_altra": "Carro",
      "denominazione_ita": "Carro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B838",
      "lat": "44.2726995",
      "lon": "9.6082586",
      "superficie_kmq": "31.7917",
      "codice_sovracomunale": "011"
    },
    {
      "sigla_provincia": "SP",
      "codice_istat": "011010",
      "denominazione_ita_altra": "Carrodano",
      "denominazione_ita": "Carrodano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B839",
      "lat": "44.2420650",
      "lon": "9.6561590",
      "superficie_kmq": "21.8632",
      "codice_sovracomunale": "011"
    },
    {
      "sigla_provincia": "SP",
      "codice_istat": "011011",
      "denominazione_ita_altra": "Castelnuovo Magra",
      "denominazione_ita": "Castelnuovo Magra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C240",
      "lat": "44.0984517",
      "lon": "10.0182022",
      "superficie_kmq": "15.0170",
      "codice_sovracomunale": "011"
    },
    {
      "sigla_provincia": "SP",
      "codice_istat": "011012",
      "denominazione_ita_altra": "Deiva Marina",
      "denominazione_ita": "Deiva Marina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D265",
      "lat": "44.2188503",
      "lon": "9.5205607",
      "superficie_kmq": "14.0856",
      "codice_sovracomunale": "011"
    },
    {
      "sigla_provincia": "SP",
      "codice_istat": "011013",
      "denominazione_ita_altra": "Follo",
      "denominazione_ita": "Follo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D655",
      "lat": "44.1626933",
      "lon": "9.8621476",
      "superficie_kmq": "23.2703",
      "codice_sovracomunale": "011"
    },
    {
      "sigla_provincia": "SP",
      "codice_istat": "011014",
      "denominazione_ita_altra": "Framura",
      "denominazione_ita": "Framura",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D758",
      "lat": "44.2089052",
      "lon": "9.5543579",
      "superficie_kmq": "19.2599",
      "codice_sovracomunale": "011"
    },
    {
      "sigla_provincia": "SP",
      "codice_istat": "011015",
      "denominazione_ita_altra": "La Spezia",
      "denominazione_ita": "La Spezia",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "E463",
      "lat": "44.1070350",
      "lon": "9.8281895",
      "superficie_kmq": "51.3951",
      "codice_sovracomunale": "011"
    },
    {
      "sigla_provincia": "SP",
      "codice_istat": "011016",
      "denominazione_ita_altra": "Lerici",
      "denominazione_ita": "Lerici",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E542",
      "lat": "44.0760175",
      "lon": "9.9109827",
      "superficie_kmq": "16.0078",
      "codice_sovracomunale": "011"
    },
    {
      "sigla_provincia": "SP",
      "codice_istat": "011017",
      "denominazione_ita_altra": "Levanto",
      "denominazione_ita": "Levanto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E560",
      "lat": "44.1699612",
      "lon": "9.6124648",
      "superficie_kmq": "36.8140",
      "codice_sovracomunale": "011"
    },
    {
      "sigla_provincia": "SP",
      "codice_istat": "011018",
      "denominazione_ita_altra": "Maissana",
      "denominazione_ita": "Maissana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E842",
      "lat": "44.3366603",
      "lon": "9.5350179",
      "superficie_kmq": "45.4274",
      "codice_sovracomunale": "011"
    },
    {
      "sigla_provincia": "SP",
      "codice_istat": "011019",
      "denominazione_ita_altra": "Monterosso al Mare",
      "denominazione_ita": "Monterosso al Mare",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F609",
      "lat": "44.1462812",
      "lon": "9.6561365",
      "superficie_kmq": "10.9431",
      "codice_sovracomunale": "011"
    },
    {
      "sigla_provincia": "SP",
      "codice_istat": "011020",
      "denominazione_ita_altra": "Luni",
      "denominazione_ita": "Luni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G143",
      "lat": "44.0849556",
      "lon": "10.0420289",
      "superficie_kmq": "13.8558",
      "codice_sovracomunale": "011"
    },
    {
      "sigla_provincia": "SP",
      "codice_istat": "011021",
      "denominazione_ita_altra": "Pignone",
      "denominazione_ita": "Pignone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G664",
      "lat": "44.1791457",
      "lon": "9.7240459",
      "superficie_kmq": "17.7462",
      "codice_sovracomunale": "011"
    },
    {
      "sigla_provincia": "SP",
      "codice_istat": "011022",
      "denominazione_ita_altra": "Portovenere",
      "denominazione_ita": "Portovenere",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G925",
      "lat": "44.0557766",
      "lon": "9.8370049",
      "superficie_kmq": "7.6646",
      "codice_sovracomunale": "011"
    },
    {
      "sigla_provincia": "SP",
      "codice_istat": "011023",
      "denominazione_ita_altra": "Riccò del Golfo di Spezia",
      "denominazione_ita": "Riccò del Golfo di Spezia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H275",
      "lat": "44.1555442",
      "lon": "9.7634234",
      "superficie_kmq": "37.7634",
      "codice_sovracomunale": "011"
    },
    {
      "sigla_provincia": "SP",
      "codice_istat": "011024",
      "denominazione_ita_altra": "Riomaggiore",
      "denominazione_ita": "Riomaggiore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H304",
      "lat": "44.0997167",
      "lon": "9.7381498",
      "superficie_kmq": "10.2899",
      "codice_sovracomunale": "011"
    },
    {
      "sigla_provincia": "SP",
      "codice_istat": "011025",
      "denominazione_ita_altra": "Rocchetta di Vara",
      "denominazione_ita": "Rocchetta di Vara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H461",
      "lat": "44.2511775",
      "lon": "9.7568904",
      "superficie_kmq": "32.6636",
      "codice_sovracomunale": "011"
    },
    {
      "sigla_provincia": "SP",
      "codice_istat": "011026",
      "denominazione_ita_altra": "Santo Stefano di Magra",
      "denominazione_ita": "Santo Stefano di Magra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I363",
      "lat": "44.1623971",
      "lon": "9.9150973",
      "superficie_kmq": "13.8524",
      "codice_sovracomunale": "011"
    },
    {
      "sigla_provincia": "SP",
      "codice_istat": "011027",
      "denominazione_ita_altra": "Sarzana",
      "denominazione_ita": "Sarzana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I449",
      "lat": "44.1129151",
      "lon": "9.9596760",
      "superficie_kmq": "34.5374",
      "codice_sovracomunale": "011"
    },
    {
      "sigla_provincia": "SP",
      "codice_istat": "011028",
      "denominazione_ita_altra": "Sesta Godano",
      "denominazione_ita": "Sesta Godano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E070",
      "lat": "44.2939830",
      "lon": "9.6757239",
      "superficie_kmq": "67.7758",
      "codice_sovracomunale": "011"
    },
    {
      "sigla_provincia": "SP",
      "codice_istat": "011029",
      "denominazione_ita_altra": "Varese Ligure",
      "denominazione_ita": "Varese Ligure",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L681",
      "lat": "44.3777200",
      "lon": "9.5956794",
      "superficie_kmq": "137.5884",
      "codice_sovracomunale": "011"
    },
    {
      "sigla_provincia": "SP",
      "codice_istat": "011030",
      "denominazione_ita_altra": "Vernazza",
      "denominazione_ita": "Vernazza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L774",
      "lat": "44.1352155",
      "lon": "9.6837322",
      "superficie_kmq": "12.2925",
      "codice_sovracomunale": "011"
    },
    {
      "sigla_provincia": "SP",
      "codice_istat": "011031",
      "denominazione_ita_altra": "Vezzano Ligure",
      "denominazione_ita": "Vezzano Ligure",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L819",
      "lat": "44.1413455",
      "lon": "9.8844786",
      "superficie_kmq": "18.3684",
      "codice_sovracomunale": "011"
    },
    {
      "sigla_provincia": "SP",
      "codice_istat": "011032",
      "denominazione_ita_altra": "Zignago",
      "denominazione_ita": "Zignago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M177",
      "lat": "44.2775289",
      "lon": "9.7449583",
      "superficie_kmq": "28.7008",
      "codice_sovracomunale": "011"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012001",
      "denominazione_ita_altra": "Agra",
      "denominazione_ita": "Agra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A085",
      "lat": "46.0341012",
      "lon": "8.7714462",
      "superficie_kmq": "2.7953",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012002",
      "denominazione_ita_altra": "Albizzate",
      "denominazione_ita": "Albizzate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A167",
      "lat": "45.7237452",
      "lon": "8.8035480",
      "superficie_kmq": "3.8821",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012003",
      "denominazione_ita_altra": "Angera",
      "denominazione_ita": "Angera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A290",
      "lat": "45.7724905",
      "lon": "8.5750547",
      "superficie_kmq": "17.7261",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012004",
      "denominazione_ita_altra": "Arcisate",
      "denominazione_ita": "Arcisate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A371",
      "lat": "45.8593769",
      "lon": "8.8597960",
      "superficie_kmq": "12.1264",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012005",
      "denominazione_ita_altra": "Arsago Seprio",
      "denominazione_ita": "Arsago Seprio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A441",
      "lat": "45.6879998",
      "lon": "8.7310294",
      "superficie_kmq": "10.5059",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012006",
      "denominazione_ita_altra": "Azzate",
      "denominazione_ita": "Azzate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A531",
      "lat": "45.7819851",
      "lon": "8.7968612",
      "superficie_kmq": "4.5062",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012007",
      "denominazione_ita_altra": "Azzio",
      "denominazione_ita": "Azzio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A532",
      "lat": "45.8853729",
      "lon": "8.7062968",
      "superficie_kmq": "2.1722",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012008",
      "denominazione_ita_altra": "Barasso",
      "denominazione_ita": "Barasso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A619",
      "lat": "45.8404787",
      "lon": "8.7581038",
      "superficie_kmq": "3.9232",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012010",
      "denominazione_ita_altra": "Bedero Valcuvia",
      "denominazione_ita": "Bedero Valcuvia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A728",
      "lat": "45.9126476",
      "lon": "8.7944129",
      "superficie_kmq": "2.5636",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012011",
      "denominazione_ita_altra": "Besano",
      "denominazione_ita": "Besano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A819",
      "lat": "45.8892429",
      "lon": "8.8899486",
      "superficie_kmq": "3.4320",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012012",
      "denominazione_ita_altra": "Besnate",
      "denominazione_ita": "Besnate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A825",
      "lat": "45.7003636",
      "lon": "8.7694001",
      "superficie_kmq": "7.4769",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012013",
      "denominazione_ita_altra": "Besozzo",
      "denominazione_ita": "Besozzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A826",
      "lat": "45.8516271",
      "lon": "8.6651545",
      "superficie_kmq": "13.9454",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012014",
      "denominazione_ita_altra": "Biandronno",
      "denominazione_ita": "Biandronno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A845",
      "lat": "45.8194045",
      "lon": "8.7123263",
      "superficie_kmq": "9.5209",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012015",
      "denominazione_ita_altra": "Bisuschio",
      "denominazione_ita": "Bisuschio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A891",
      "lat": "45.8724256",
      "lon": "8.8703583",
      "superficie_kmq": "7.0300",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012016",
      "denominazione_ita_altra": "Bodio Lomnago",
      "denominazione_ita": "Bodio Lomnago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A918",
      "lat": "45.7898998",
      "lon": "8.7514329",
      "superficie_kmq": "4.0386",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012017",
      "denominazione_ita_altra": "Brebbia",
      "denominazione_ita": "Brebbia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B126",
      "lat": "45.8285779",
      "lon": "8.6498690",
      "superficie_kmq": "6.8686",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012019",
      "denominazione_ita_altra": "Brenta",
      "denominazione_ita": "Brenta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B150",
      "lat": "45.8952206",
      "lon": "8.6841501",
      "superficie_kmq": "4.1820",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012020",
      "denominazione_ita_altra": "Brezzo di Bedero",
      "denominazione_ita": "Brezzo di Bedero",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B166",
      "lat": "45.9779019",
      "lon": "8.7183917",
      "superficie_kmq": "9.9542",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012021",
      "denominazione_ita_altra": "Brinzio",
      "denominazione_ita": "Brinzio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B182",
      "lat": "45.8891470",
      "lon": "8.7868065",
      "superficie_kmq": "6.3963",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012022",
      "denominazione_ita_altra": "Brissago-Valtravaglia",
      "denominazione_ita": "Brissago-Valtravaglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B191",
      "lat": "45.9496335",
      "lon": "8.7464112",
      "superficie_kmq": "6.1189",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012023",
      "denominazione_ita_altra": "Brunello",
      "denominazione_ita": "Brunello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B219",
      "lat": "45.7661671",
      "lon": "8.7958022",
      "superficie_kmq": "1.6200",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012024",
      "denominazione_ita_altra": "Brusimpiano",
      "denominazione_ita": "Brusimpiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B228",
      "lat": "45.9472013",
      "lon": "8.8896034",
      "superficie_kmq": "5.9058",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012025",
      "denominazione_ita_altra": "Buguggiate",
      "denominazione_ita": "Buguggiate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B258",
      "lat": "45.7852677",
      "lon": "8.8067573",
      "superficie_kmq": "2.5045",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012026",
      "denominazione_ita_altra": "Busto Arsizio",
      "denominazione_ita": "Busto Arsizio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B300",
      "lat": "45.6150915",
      "lon": "8.8549419",
      "superficie_kmq": "30.6597",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012027",
      "denominazione_ita_altra": "Cadegliano-Viconago",
      "denominazione_ita": "Cadegliano-Viconago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B326",
      "lat": "45.9587903",
      "lon": "8.8446650",
      "superficie_kmq": "10.2718",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012029",
      "denominazione_ita_altra": "Cairate",
      "denominazione_ita": "Cairate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B368",
      "lat": "45.6920361",
      "lon": "8.8708130",
      "superficie_kmq": "11.2635",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012030",
      "denominazione_ita_altra": "Cantello",
      "denominazione_ita": "Cantello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B634",
      "lat": "45.8210683",
      "lon": "8.8969693",
      "superficie_kmq": "9.1334",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012031",
      "denominazione_ita_altra": "Caravate",
      "denominazione_ita": "Caravate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B732",
      "lat": "45.8795169",
      "lon": "8.6554768",
      "superficie_kmq": "5.1270",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012032",
      "denominazione_ita_altra": "Cardano al Campo",
      "denominazione_ita": "Cardano al Campo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B754",
      "lat": "45.6466210",
      "lon": "8.7745785",
      "superficie_kmq": "9.4198",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012033",
      "denominazione_ita_altra": "Carnago",
      "denominazione_ita": "Carnago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B796",
      "lat": "45.7182926",
      "lon": "8.8362717",
      "superficie_kmq": "6.2131",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012034",
      "denominazione_ita_altra": "Caronno Pertusella",
      "denominazione_ita": "Caronno Pertusella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B805",
      "lat": "45.5968257",
      "lon": "9.0478680",
      "superficie_kmq": "8.5453",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012035",
      "denominazione_ita_altra": "Caronno Varesino",
      "denominazione_ita": "Caronno Varesino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B807",
      "lat": "45.7388018",
      "lon": "8.8309885",
      "superficie_kmq": "5.7494",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012036",
      "denominazione_ita_altra": "Casale Litta",
      "denominazione_ita": "Casale Litta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B875",
      "lat": "45.7674002",
      "lon": "8.7403368",
      "superficie_kmq": "10.5879",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012037",
      "denominazione_ita_altra": "Casalzuigno",
      "denominazione_ita": "Casalzuigno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B921",
      "lat": "45.9032813",
      "lon": "8.7055078",
      "superficie_kmq": "7.3228",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012038",
      "denominazione_ita_altra": "Casciago",
      "denominazione_ita": "Casciago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B949",
      "lat": "45.8347850",
      "lon": "8.7843793",
      "superficie_kmq": "4.0454",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012039",
      "denominazione_ita_altra": "Casorate Sempione",
      "denominazione_ita": "Casorate Sempione",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B987",
      "lat": "45.6717219",
      "lon": "8.7403179",
      "superficie_kmq": "6.9092",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012040",
      "denominazione_ita_altra": "Cassano Magnago",
      "denominazione_ita": "Cassano Magnago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C004",
      "lat": "45.6743893",
      "lon": "8.8208252",
      "superficie_kmq": "12.3442",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012041",
      "denominazione_ita_altra": "Cassano Valcuvia",
      "denominazione_ita": "Cassano Valcuvia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B999",
      "lat": "45.9325891",
      "lon": "8.7681767",
      "superficie_kmq": "3.9547",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012042",
      "denominazione_ita_altra": "Castellanza",
      "denominazione_ita": "Castellanza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C139",
      "lat": "45.6114782",
      "lon": "8.8975879",
      "superficie_kmq": "6.9206",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012043",
      "denominazione_ita_altra": "Castello Cabiaglio",
      "denominazione_ita": "Castello Cabiaglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B312",
      "lat": "45.8925597",
      "lon": "8.7548188",
      "superficie_kmq": "6.9824",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012044",
      "denominazione_ita_altra": "Castelseprio",
      "denominazione_ita": "Castelseprio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C273",
      "lat": "45.7169623",
      "lon": "8.8612739",
      "superficie_kmq": "3.7509",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012045",
      "denominazione_ita_altra": "Castelveccana",
      "denominazione_ita": "Castelveccana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C181",
      "lat": "45.9465457",
      "lon": "8.6648318",
      "superficie_kmq": "20.7887",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012046",
      "denominazione_ita_altra": "Castiglione Olona",
      "denominazione_ita": "Castiglione Olona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C300",
      "lat": "45.7563943",
      "lon": "8.8680809",
      "superficie_kmq": "6.9003",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012047",
      "denominazione_ita_altra": "Castronno",
      "denominazione_ita": "Castronno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C343",
      "lat": "45.7469707",
      "lon": "8.8128895",
      "superficie_kmq": "3.7590",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012048",
      "denominazione_ita_altra": "Cavaria con Premezzo",
      "denominazione_ita": "Cavaria con Premezzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C382",
      "lat": "45.6945576",
      "lon": "8.7976803",
      "superficie_kmq": "3.3176",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012049",
      "denominazione_ita_altra": "Cazzago Brabbia",
      "denominazione_ita": "Cazzago Brabbia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C409",
      "lat": "45.7953614",
      "lon": "8.7352758",
      "superficie_kmq": "4.0039",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012050",
      "denominazione_ita_altra": "Cislago",
      "denominazione_ita": "Cislago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C732",
      "lat": "45.6617568",
      "lon": "8.9706213",
      "superficie_kmq": "11.1336",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012051",
      "denominazione_ita_altra": "Cittiglio",
      "denominazione_ita": "Cittiglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C751",
      "lat": "45.8946743",
      "lon": "8.6687130",
      "superficie_kmq": "11.1074",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012052",
      "denominazione_ita_altra": "Clivio",
      "denominazione_ita": "Clivio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C796",
      "lat": "45.8644697",
      "lon": "8.9335900",
      "superficie_kmq": "2.9838",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012053",
      "denominazione_ita_altra": "Cocquio-Trevisago",
      "denominazione_ita": "Cocquio-Trevisago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C810",
      "lat": "45.8578906",
      "lon": "8.6965464",
      "superficie_kmq": "9.8056",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012054",
      "denominazione_ita_altra": "Comabbio",
      "denominazione_ita": "Comabbio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C911",
      "lat": "45.7726464",
      "lon": "8.6746659",
      "superficie_kmq": "4.6874",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012055",
      "denominazione_ita_altra": "Comerio",
      "denominazione_ita": "Comerio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C922",
      "lat": "45.8426331",
      "lon": "8.7470937",
      "superficie_kmq": "5.5493",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012056",
      "denominazione_ita_altra": "Cremenaga",
      "denominazione_ita": "Cremenaga",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D144",
      "lat": "45.9892569",
      "lon": "8.8065921",
      "superficie_kmq": "4.5510",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012057",
      "denominazione_ita_altra": "Crosio della Valle",
      "denominazione_ita": "Crosio della Valle",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D185",
      "lat": "45.7616444",
      "lon": "8.7675690",
      "superficie_kmq": "1.4407",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012058",
      "denominazione_ita_altra": "Cuasso al Monte",
      "denominazione_ita": "Cuasso al Monte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D192",
      "lat": "45.9144160",
      "lon": "8.8798154",
      "superficie_kmq": "16.1837",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012059",
      "denominazione_ita_altra": "Cugliate-Fabiasco",
      "denominazione_ita": "Cugliate-Fabiasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D199",
      "lat": "45.9470426",
      "lon": "8.8206542",
      "superficie_kmq": "6.5438",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012060",
      "denominazione_ita_altra": "Cunardo",
      "denominazione_ita": "Cunardo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D204",
      "lat": "45.9378271",
      "lon": "8.8022540",
      "superficie_kmq": "6.0591",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012061",
      "denominazione_ita_altra": "Curiglia con Monteviasco",
      "denominazione_ita": "Curiglia con Monteviasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D217",
      "lat": "46.0606636",
      "lon": "8.8048167",
      "superficie_kmq": "10.8543",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012062",
      "denominazione_ita_altra": "Cuveglio",
      "denominazione_ita": "Cuveglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D238",
      "lat": "45.9059775",
      "lon": "8.7358352",
      "superficie_kmq": "7.5272",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012063",
      "denominazione_ita_altra": "Cuvio",
      "denominazione_ita": "Cuvio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D239",
      "lat": "45.8980427",
      "lon": "8.7313870",
      "superficie_kmq": "5.9590",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012064",
      "denominazione_ita_altra": "Daverio",
      "denominazione_ita": "Daverio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D256",
      "lat": "45.7771193",
      "lon": "8.7715058",
      "superficie_kmq": "4.0249",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012065",
      "denominazione_ita_altra": "Dumenza",
      "denominazione_ita": "Dumenza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D384",
      "lat": "46.0227849",
      "lon": "8.7848557",
      "superficie_kmq": "18.3987",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012066",
      "denominazione_ita_altra": "Duno",
      "denominazione_ita": "Duno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D385",
      "lat": "45.9140695",
      "lon": "8.7385415",
      "superficie_kmq": "2.4905",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012067",
      "denominazione_ita_altra": "Fagnano Olona",
      "denominazione_ita": "Fagnano Olona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D467",
      "lat": "45.6696083",
      "lon": "8.8741081",
      "superficie_kmq": "8.6809",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012068",
      "denominazione_ita_altra": "Ferno",
      "denominazione_ita": "Ferno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D543",
      "lat": "45.6102761",
      "lon": "8.7588499",
      "superficie_kmq": "8.6579",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012069",
      "denominazione_ita_altra": "Ferrera di Varese",
      "denominazione_ita": "Ferrera di Varese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D551",
      "lat": "45.9323411",
      "lon": "8.7881832",
      "superficie_kmq": "1.5265",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012070",
      "denominazione_ita_altra": "Gallarate",
      "denominazione_ita": "Gallarate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D869",
      "lat": "45.6601911",
      "lon": "8.7932992",
      "superficie_kmq": "20.9823",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012071",
      "denominazione_ita_altra": "Galliate Lombardo",
      "denominazione_ita": "Galliate Lombardo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D871",
      "lat": "45.7863017",
      "lon": "8.7685334",
      "superficie_kmq": "3.2748",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012072",
      "denominazione_ita_altra": "Gavirate",
      "denominazione_ita": "Gavirate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D946",
      "lat": "45.8411429",
      "lon": "8.7247710",
      "superficie_kmq": "12.0086",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012073",
      "denominazione_ita_altra": "Gazzada Schianno",
      "denominazione_ita": "Gazzada Schianno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D951",
      "lat": "45.7802605",
      "lon": "8.8255835",
      "superficie_kmq": "4.8411",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012074",
      "denominazione_ita_altra": "Gemonio",
      "denominazione_ita": "Gemonio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D963",
      "lat": "45.8774311",
      "lon": "8.6788489",
      "superficie_kmq": "3.6747",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012075",
      "denominazione_ita_altra": "Gerenzano",
      "denominazione_ita": "Gerenzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D981",
      "lat": "45.6384452",
      "lon": "8.9985806",
      "superficie_kmq": "9.7868",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012076",
      "denominazione_ita_altra": "Germignaga",
      "denominazione_ita": "Germignaga",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D987",
      "lat": "45.9934149",
      "lon": "8.7268042",
      "superficie_kmq": "4.6598",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012077",
      "denominazione_ita_altra": "Golasecca",
      "denominazione_ita": "Golasecca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E079",
      "lat": "45.6968656",
      "lon": "8.6550979",
      "superficie_kmq": "7.4364",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012078",
      "denominazione_ita_altra": "Gorla Maggiore",
      "denominazione_ita": "Gorla Maggiore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E101",
      "lat": "45.6660994",
      "lon": "8.8935248",
      "superficie_kmq": "5.1580",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012079",
      "denominazione_ita_altra": "Gorla Minore",
      "denominazione_ita": "Gorla Minore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E102",
      "lat": "45.6432687",
      "lon": "8.8965086",
      "superficie_kmq": "7.4805",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012080",
      "denominazione_ita_altra": "Gornate Olona",
      "denominazione_ita": "Gornate Olona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E104",
      "lat": "45.7377585",
      "lon": "8.8591011",
      "superficie_kmq": "4.6957",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012081",
      "denominazione_ita_altra": "Grantola",
      "denominazione_ita": "Grantola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E144",
      "lat": "45.9496193",
      "lon": "8.7707666",
      "superficie_kmq": "2.0456",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012082",
      "denominazione_ita_altra": "Inarzo",
      "denominazione_ita": "Inarzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E292",
      "lat": "45.7867861",
      "lon": "8.7364261",
      "superficie_kmq": "2.4338",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012083",
      "denominazione_ita_altra": "Induno Olona",
      "denominazione_ita": "Induno Olona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E299",
      "lat": "45.8519775",
      "lon": "8.8385657",
      "superficie_kmq": "12.3655",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012084",
      "denominazione_ita_altra": "Ispra",
      "denominazione_ita": "Ispra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E367",
      "lat": "45.8161833",
      "lon": "8.6102656",
      "superficie_kmq": "15.9092",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012085",
      "denominazione_ita_altra": "Jerago con Orago",
      "denominazione_ita": "Jerago con Orago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E386",
      "lat": "45.7052582",
      "lon": "8.7925382",
      "superficie_kmq": "3.8742",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012086",
      "denominazione_ita_altra": "Lavena Ponte Tresa",
      "denominazione_ita": "Lavena Ponte Tresa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E494",
      "lat": "45.9609377",
      "lon": "8.8610751",
      "superficie_kmq": "4.4421",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012087",
      "denominazione_ita_altra": "Laveno-Mombello",
      "denominazione_ita": "Laveno-Mombello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E496",
      "lat": "45.9157982",
      "lon": "8.6153561",
      "superficie_kmq": "23.5301",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012088",
      "denominazione_ita_altra": "Leggiuno",
      "denominazione_ita": "Leggiuno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E510",
      "lat": "45.8756383",
      "lon": "8.6193311",
      "superficie_kmq": "13.1894",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012089",
      "denominazione_ita_altra": "Lonate Ceppino",
      "denominazione_ita": "Lonate Ceppino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E665",
      "lat": "45.7052966",
      "lon": "8.8762724",
      "superficie_kmq": "4.8367",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012090",
      "denominazione_ita_altra": "Lonate Pozzolo",
      "denominazione_ita": "Lonate Pozzolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E666",
      "lat": "45.6000663",
      "lon": "8.7523738",
      "superficie_kmq": "29.2406",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012091",
      "denominazione_ita_altra": "Lozza",
      "denominazione_ita": "Lozza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E707",
      "lat": "45.7754898",
      "lon": "8.8570558",
      "superficie_kmq": "1.7099",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012092",
      "denominazione_ita_altra": "Luino",
      "denominazione_ita": "Luino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E734",
      "lat": "46.0025042",
      "lon": "8.7424293",
      "superficie_kmq": "21.0057",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012093",
      "denominazione_ita_altra": "Luvinate",
      "denominazione_ita": "Luvinate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E769",
      "lat": "45.8395657",
      "lon": "8.7724643",
      "superficie_kmq": "4.0713",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012096",
      "denominazione_ita_altra": "Malnate",
      "denominazione_ita": "Malnate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E863",
      "lat": "45.7995364",
      "lon": "8.8811109",
      "superficie_kmq": "9.0012",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012097",
      "denominazione_ita_altra": "Marchirolo",
      "denominazione_ita": "Marchirolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E929",
      "lat": "45.9516011",
      "lon": "8.8357696",
      "superficie_kmq": "5.4904",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012098",
      "denominazione_ita_altra": "Marnate",
      "denominazione_ita": "Marnate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E965",
      "lat": "45.6284846",
      "lon": "8.9038706",
      "superficie_kmq": "4.8464",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012099",
      "denominazione_ita_altra": "Marzio",
      "denominazione_ita": "Marzio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F002",
      "lat": "45.9378545",
      "lon": "8.8577694",
      "superficie_kmq": "1.8580",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012100",
      "denominazione_ita_altra": "Masciago Primo",
      "denominazione_ita": "Masciago Primo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F007",
      "lat": "45.9164334",
      "lon": "8.7808034",
      "superficie_kmq": "1.8082",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012101",
      "denominazione_ita_altra": "Mercallo",
      "denominazione_ita": "Mercallo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F134",
      "lat": "45.7467346",
      "lon": "8.6721033",
      "superficie_kmq": "5.4816",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012102",
      "denominazione_ita_altra": "Mesenzana",
      "denominazione_ita": "Mesenzana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F154",
      "lat": "45.9470310",
      "lon": "8.7588133",
      "superficie_kmq": "4.8831",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012103",
      "denominazione_ita_altra": "Montegrino Valtravaglia",
      "denominazione_ita": "Montegrino Valtravaglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F526",
      "lat": "45.9734668",
      "lon": "8.7681214",
      "superficie_kmq": "10.1012",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012104",
      "denominazione_ita_altra": "Monvalle",
      "denominazione_ita": "Monvalle",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F703",
      "lat": "45.8576040",
      "lon": "8.6320465",
      "superficie_kmq": "4.5406",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012105",
      "denominazione_ita_altra": "Morazzone",
      "denominazione_ita": "Morazzone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F711",
      "lat": "45.7691712",
      "lon": "8.8293743",
      "superficie_kmq": "5.6025",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012106",
      "denominazione_ita_altra": "Mornago",
      "denominazione_ita": "Mornago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F736",
      "lat": "45.7476032",
      "lon": "8.7523972",
      "superficie_kmq": "12.2356",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012107",
      "denominazione_ita_altra": "Oggiona con Santo Stefano",
      "denominazione_ita": "Oggiona con Santo Stefano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G008",
      "lat": "45.6973111",
      "lon": "8.8184658",
      "superficie_kmq": "2.7451",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012108",
      "denominazione_ita_altra": "Olgiate Olona",
      "denominazione_ita": "Olgiate Olona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G028",
      "lat": "45.6277727",
      "lon": "8.8907926",
      "superficie_kmq": "7.2066",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012109",
      "denominazione_ita_altra": "Origgio",
      "denominazione_ita": "Origgio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G103",
      "lat": "45.5996387",
      "lon": "9.0178211",
      "superficie_kmq": "7.9149",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012110",
      "denominazione_ita_altra": "Orino",
      "denominazione_ita": "Orino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G105",
      "lat": "45.8818011",
      "lon": "8.7129266",
      "superficie_kmq": "3.7246",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012113",
      "denominazione_ita_altra": "Porto Ceresio",
      "denominazione_ita": "Porto Ceresio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G906",
      "lat": "45.9040290",
      "lon": "8.9062794",
      "superficie_kmq": "5.3374",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012114",
      "denominazione_ita_altra": "Porto Valtravaglia",
      "denominazione_ita": "Porto Valtravaglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G907",
      "lat": "45.9608340",
      "lon": "8.6803437",
      "superficie_kmq": "16.3739",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012115",
      "denominazione_ita_altra": "Rancio Valcuvia",
      "denominazione_ita": "Rancio Valcuvia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H173",
      "lat": "45.9163377",
      "lon": "8.7701960",
      "superficie_kmq": "4.4481",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012116",
      "denominazione_ita_altra": "Ranco",
      "denominazione_ita": "Ranco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H174",
      "lat": "45.7984649",
      "lon": "8.5747294",
      "superficie_kmq": "6.7632",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012117",
      "denominazione_ita_altra": "Saltrio",
      "denominazione_ita": "Saltrio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H723",
      "lat": "45.8735712",
      "lon": "8.9243819",
      "superficie_kmq": "3.4390",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012118",
      "denominazione_ita_altra": "Samarate",
      "denominazione_ita": "Samarate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H736",
      "lat": "45.6309360",
      "lon": "8.7825987",
      "superficie_kmq": "16.0140",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012119",
      "denominazione_ita_altra": "Saronno",
      "denominazione_ita": "Saronno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I441",
      "lat": "45.6237471",
      "lon": "9.0364046",
      "superficie_kmq": "11.0575",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012120",
      "denominazione_ita_altra": "Sesto Calende",
      "denominazione_ita": "Sesto Calende",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I688",
      "lat": "45.7238026",
      "lon": "8.6316423",
      "superficie_kmq": "25.0379",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012121",
      "denominazione_ita_altra": "Solbiate Arno",
      "denominazione_ita": "Solbiate Arno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I793",
      "lat": "45.7205781",
      "lon": "8.8134670",
      "superficie_kmq": "3.0347",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012122",
      "denominazione_ita_altra": "Solbiate Olona",
      "denominazione_ita": "Solbiate Olona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I794",
      "lat": "45.6536043",
      "lon": "8.8873411",
      "superficie_kmq": "4.9318",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012123",
      "denominazione_ita_altra": "Somma Lombardo",
      "denominazione_ita": "Somma Lombardo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I819",
      "lat": "45.6837851",
      "lon": "8.7043524",
      "superficie_kmq": "30.5053",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012124",
      "denominazione_ita_altra": "Sumirago",
      "denominazione_ita": "Sumirago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L003",
      "lat": "45.7402939",
      "lon": "8.7793726",
      "superficie_kmq": "11.7498",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012125",
      "denominazione_ita_altra": "Taino",
      "denominazione_ita": "Taino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L032",
      "lat": "45.7649981",
      "lon": "8.6165900",
      "superficie_kmq": "7.6311",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012126",
      "denominazione_ita_altra": "Ternate",
      "denominazione_ita": "Ternate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L115",
      "lat": "45.7823885",
      "lon": "8.6947981",
      "superficie_kmq": "4.6798",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012127",
      "denominazione_ita_altra": "Tradate",
      "denominazione_ita": "Tradate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L319",
      "lat": "45.7137688",
      "lon": "8.9056948",
      "superficie_kmq": "21.4775",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012128",
      "denominazione_ita_altra": "Travedona-Monate",
      "denominazione_ita": "Travedona-Monate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L342",
      "lat": "45.8057153",
      "lon": "8.6772608",
      "superficie_kmq": "9.5968",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012129",
      "denominazione_ita_altra": "Tronzano Lago Maggiore",
      "denominazione_ita": "Tronzano Lago Maggiore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A705",
      "lat": "46.0914805",
      "lon": "8.7355186",
      "superficie_kmq": "11.0619",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012130",
      "denominazione_ita_altra": "Uboldo",
      "denominazione_ita": "Uboldo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L480",
      "lat": "45.6107202",
      "lon": "9.0030687",
      "superficie_kmq": "10.7362",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012131",
      "denominazione_ita_altra": "Valganna",
      "denominazione_ita": "Valganna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L577",
      "lat": "45.9027787",
      "lon": "8.8239474",
      "superficie_kmq": "12.4185",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012132",
      "denominazione_ita_altra": "Varano Borghi",
      "denominazione_ita": "Varano Borghi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L671",
      "lat": "45.7778003",
      "lon": "8.7019374",
      "superficie_kmq": "3.3321",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012133",
      "denominazione_ita_altra": "Varese",
      "denominazione_ita": "Varese",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "L682",
      "lat": "45.8170272",
      "lon": "8.8228683",
      "superficie_kmq": "54.8393",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012134",
      "denominazione_ita_altra": "Vedano Olona",
      "denominazione_ita": "Vedano Olona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L703",
      "lat": "45.7767219",
      "lon": "8.8863565",
      "superficie_kmq": "7.0848",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012136",
      "denominazione_ita_altra": "Venegono Inferiore",
      "denominazione_ita": "Venegono Inferiore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L733",
      "lat": "45.7358888",
      "lon": "8.8994330",
      "superficie_kmq": "5.8788",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012137",
      "denominazione_ita_altra": "Venegono Superiore",
      "denominazione_ita": "Venegono Superiore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L734",
      "lat": "45.7491339",
      "lon": "8.9017164",
      "superficie_kmq": "6.7338",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012138",
      "denominazione_ita_altra": "Vergiate",
      "denominazione_ita": "Vergiate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L765",
      "lat": "45.7245445",
      "lon": "8.6954015",
      "superficie_kmq": "21.7832",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012139",
      "denominazione_ita_altra": "Viggiù",
      "denominazione_ita": "Viggiù",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L876",
      "lat": "45.8720220",
      "lon": "8.9070404",
      "superficie_kmq": "9.2627",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012140",
      "denominazione_ita_altra": "Vizzola Ticino",
      "denominazione_ita": "Vizzola Ticino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M101",
      "lat": "45.6312425",
      "lon": "8.6850507",
      "superficie_kmq": "7.6059",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012141",
      "denominazione_ita_altra": "Sangiano",
      "denominazione_ita": "Sangiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H872",
      "lat": "45.8763025",
      "lon": "8.6317904",
      "superficie_kmq": "2.2206",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012142",
      "denominazione_ita_altra": "Maccagno con Pino e Veddasca",
      "denominazione_ita": "Maccagno con Pino e Veddasca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M339",
      "lat": "46.0724448",
      "lon": "8.7658249",
      "superficie_kmq": "41.9571",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012143",
      "denominazione_ita_altra": "Cadrezzate con Osmate",
      "denominazione_ita": "Cadrezzate con Osmate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M425",
      "lat": "45.7914590",
      "lon": "8.6480888",
      "superficie_kmq": "8.2455",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "VA",
      "codice_istat": "012144",
      "denominazione_ita_altra": "Bardello con Malgesso e Bregano",
      "denominazione_ita": "Bardello con Malgesso e Bregano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M433",
      "lat": "45.8267044",
      "lon": "8.6847709",
      "superficie_kmq": "7.5808",
      "codice_sovracomunale": "012"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013003",
      "denominazione_ita_altra": "Albavilla",
      "denominazione_ita": "Albavilla",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A143",
      "lat": "45.8011049",
      "lon": "9.1893001",
      "superficie_kmq": "10.3792",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013004",
      "denominazione_ita_altra": "Albese con Cassano",
      "denominazione_ita": "Albese con Cassano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A153",
      "lat": "45.7954437",
      "lon": "9.1647435",
      "superficie_kmq": "7.9506",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013005",
      "denominazione_ita_altra": "Albiolo",
      "denominazione_ita": "Albiolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A164",
      "lat": "45.8068111",
      "lon": "8.9373625",
      "superficie_kmq": "2.8386",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013006",
      "denominazione_ita_altra": "Alserio",
      "denominazione_ita": "Alserio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A224",
      "lat": "45.7786987",
      "lon": "9.1983729",
      "superficie_kmq": "1.9921",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013007",
      "denominazione_ita_altra": "Alzate Brianza",
      "denominazione_ita": "Alzate Brianza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A249",
      "lat": "45.7669897",
      "lon": "9.1820258",
      "superficie_kmq": "7.5847",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013009",
      "denominazione_ita_altra": "Anzano del Parco",
      "denominazione_ita": "Anzano del Parco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A319",
      "lat": "45.7708742",
      "lon": "9.1987596",
      "superficie_kmq": "3.2450",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013010",
      "denominazione_ita_altra": "Appiano Gentile",
      "denominazione_ita": "Appiano Gentile",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A333",
      "lat": "45.7350135",
      "lon": "8.9794917",
      "superficie_kmq": "12.8076",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013011",
      "denominazione_ita_altra": "Argegno",
      "denominazione_ita": "Argegno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A391",
      "lat": "45.9450958",
      "lon": "9.1274029",
      "superficie_kmq": "4.1121",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013012",
      "denominazione_ita_altra": "Arosio",
      "denominazione_ita": "Arosio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A430",
      "lat": "45.7166563",
      "lon": "9.2076673",
      "superficie_kmq": "2.5822",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013013",
      "denominazione_ita_altra": "Asso",
      "denominazione_ita": "Asso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A476",
      "lat": "45.8616663",
      "lon": "9.2694189",
      "superficie_kmq": "6.5106",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013015",
      "denominazione_ita_altra": "Barni",
      "denominazione_ita": "Barni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A670",
      "lat": "45.9108874",
      "lon": "9.2639669",
      "superficie_kmq": "5.7157",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013021",
      "denominazione_ita_altra": "Bene Lario",
      "denominazione_ita": "Bene Lario",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A778",
      "lat": "46.0286387",
      "lon": "9.1836036",
      "superficie_kmq": "5.5854",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013022",
      "denominazione_ita_altra": "Beregazzo con Figliaro",
      "denominazione_ita": "Beregazzo con Figliaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A791",
      "lat": "45.7758893",
      "lon": "8.9508768",
      "superficie_kmq": "3.8047",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013023",
      "denominazione_ita_altra": "Binago",
      "denominazione_ita": "Binago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A870",
      "lat": "45.7846959",
      "lon": "8.9225656",
      "superficie_kmq": "7.1194",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013024",
      "denominazione_ita_altra": "Bizzarone",
      "denominazione_ita": "Bizzarone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A898",
      "lat": "45.8350499",
      "lon": "8.9459647",
      "superficie_kmq": "2.6705",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013025",
      "denominazione_ita_altra": "Blessagno",
      "denominazione_ita": "Blessagno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A904",
      "lat": "45.9599064",
      "lon": "9.0973910",
      "superficie_kmq": "3.5601",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013026",
      "denominazione_ita_altra": "Blevio",
      "denominazione_ita": "Blevio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A905",
      "lat": "45.8406517",
      "lon": "9.1048648",
      "superficie_kmq": "5.4718",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013028",
      "denominazione_ita_altra": "Bregnano",
      "denominazione_ita": "Bregnano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B134",
      "lat": "45.6989713",
      "lon": "9.0625241",
      "superficie_kmq": "6.1737",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013029",
      "denominazione_ita_altra": "Brenna",
      "denominazione_ita": "Brenna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B144",
      "lat": "45.7416511",
      "lon": "9.1877530",
      "superficie_kmq": "4.8311",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013030",
      "denominazione_ita_altra": "Brienno",
      "denominazione_ita": "Brienno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B172",
      "lat": "45.9117541",
      "lon": "9.1313905",
      "superficie_kmq": "8.9720",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013032",
      "denominazione_ita_altra": "Brunate",
      "denominazione_ita": "Brunate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B218",
      "lat": "45.8197822",
      "lon": "9.0964159",
      "superficie_kmq": "2.0251",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013034",
      "denominazione_ita_altra": "Bulgarograsso",
      "denominazione_ita": "Bulgarograsso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B262",
      "lat": "45.7481439",
      "lon": "9.0110125",
      "superficie_kmq": "3.7733",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013035",
      "denominazione_ita_altra": "Cabiate",
      "denominazione_ita": "Cabiate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B313",
      "lat": "45.6732062",
      "lon": "9.1667028",
      "superficie_kmq": "3.1784",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013036",
      "denominazione_ita_altra": "Cadorago",
      "denominazione_ita": "Cadorago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B346",
      "lat": "45.7239128",
      "lon": "9.0337473",
      "superficie_kmq": "7.1615",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013037",
      "denominazione_ita_altra": "Caglio",
      "denominazione_ita": "Caglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B355",
      "lat": "45.8705835",
      "lon": "9.2363157",
      "superficie_kmq": "6.5169",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013040",
      "denominazione_ita_altra": "Campione d'Italia",
      "denominazione_ita": "Campione d'Italia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B513",
      "lat": "45.9700921",
      "lon": "8.9707540",
      "superficie_kmq": "2.6757",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013041",
      "denominazione_ita_altra": "Cantù",
      "denominazione_ita": "Cantù",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B639",
      "lat": "45.7393958",
      "lon": "9.1265807",
      "superficie_kmq": "23.2534",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013042",
      "denominazione_ita_altra": "Canzo",
      "denominazione_ita": "Canzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B641",
      "lat": "45.8500484",
      "lon": "9.2735022",
      "superficie_kmq": "11.1053",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013043",
      "denominazione_ita_altra": "Capiago Intimiano",
      "denominazione_ita": "Capiago Intimiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B653",
      "lat": "45.7721325",
      "lon": "9.1243087",
      "superficie_kmq": "5.7175",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013044",
      "denominazione_ita_altra": "Carate Urio",
      "denominazione_ita": "Carate Urio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B730",
      "lat": "45.8719776",
      "lon": "9.1223970",
      "superficie_kmq": "6.9442",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013045",
      "denominazione_ita_altra": "Carbonate",
      "denominazione_ita": "Carbonate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B742",
      "lat": "45.6850697",
      "lon": "8.9404021",
      "superficie_kmq": "4.9237",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013046",
      "denominazione_ita_altra": "Carimate",
      "denominazione_ita": "Carimate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B778",
      "lat": "45.7026944",
      "lon": "9.1152510",
      "superficie_kmq": "5.1719",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013047",
      "denominazione_ita_altra": "Carlazzo",
      "denominazione_ita": "Carlazzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B785",
      "lat": "46.0492099",
      "lon": "9.1564941",
      "superficie_kmq": "12.7329",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013048",
      "denominazione_ita_altra": "Carugo",
      "denominazione_ita": "Carugo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B851",
      "lat": "45.7099780",
      "lon": "9.1891544",
      "superficie_kmq": "4.1945",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013052",
      "denominazione_ita_altra": "Caslino d'Erba",
      "denominazione_ita": "Caslino d'Erba",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B974",
      "lat": "45.8375713",
      "lon": "9.2271218",
      "superficie_kmq": "6.8896",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013053",
      "denominazione_ita_altra": "Casnate con Bernate",
      "denominazione_ita": "Casnate con Bernate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B977",
      "lat": "45.7568018",
      "lon": "9.0746556",
      "superficie_kmq": "5.2221",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013055",
      "denominazione_ita_altra": "Cassina Rizzardi",
      "denominazione_ita": "Cassina Rizzardi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C020",
      "lat": "45.7510493",
      "lon": "9.0303899",
      "superficie_kmq": "3.5073",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013058",
      "denominazione_ita_altra": "Castelmarte",
      "denominazione_ita": "Castelmarte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C206",
      "lat": "45.8325195",
      "lon": "9.2333598",
      "superficie_kmq": "1.9723",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013059",
      "denominazione_ita_altra": "Castelnuovo Bozzente",
      "denominazione_ita": "Castelnuovo Bozzente",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C220",
      "lat": "45.7634608",
      "lon": "8.9413797",
      "superficie_kmq": "3.6220",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013062",
      "denominazione_ita_altra": "Cavargna",
      "denominazione_ita": "Cavargna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C381",
      "lat": "46.0906899",
      "lon": "9.1120259",
      "superficie_kmq": "14.9748",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013063",
      "denominazione_ita_altra": "Cerano d'Intelvi",
      "denominazione_ita": "Cerano d'Intelvi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C482",
      "lat": "45.9444763",
      "lon": "9.0876430",
      "superficie_kmq": "5.5479",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013064",
      "denominazione_ita_altra": "Cermenate",
      "denominazione_ita": "Cermenate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C516",
      "lat": "45.6985756",
      "lon": "9.0826873",
      "superficie_kmq": "8.1831",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013065",
      "denominazione_ita_altra": "Cernobbio",
      "denominazione_ita": "Cernobbio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C520",
      "lat": "45.8396353",
      "lon": "9.0743534",
      "superficie_kmq": "12.2813",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013068",
      "denominazione_ita_altra": "Cirimido",
      "denominazione_ita": "Cirimido",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C724",
      "lat": "45.6988224",
      "lon": "9.0110864",
      "superficie_kmq": "2.6263",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013071",
      "denominazione_ita_altra": "Claino con Osteno",
      "denominazione_ita": "Claino con Osteno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C787",
      "lat": "46.0069312",
      "lon": "9.0843408",
      "superficie_kmq": "12.9034",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013074",
      "denominazione_ita_altra": "Colonno",
      "denominazione_ita": "Colonno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C902",
      "lat": "45.9577463",
      "lon": "9.1540999",
      "superficie_kmq": "5.6171",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013075",
      "denominazione_ita_altra": "Como",
      "denominazione_ita": "Como",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "C933",
      "lat": "45.8100015",
      "lon": "9.0851596",
      "superficie_kmq": "37.1412",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013077",
      "denominazione_ita_altra": "Corrido",
      "denominazione_ita": "Corrido",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D041",
      "lat": "46.0474332",
      "lon": "9.1370139",
      "superficie_kmq": "6.1917",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013083",
      "denominazione_ita_altra": "Cremia",
      "denominazione_ita": "Cremia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D147",
      "lat": "46.0858119",
      "lon": "9.2707258",
      "superficie_kmq": "10.1382",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013084",
      "denominazione_ita_altra": "Cucciago",
      "denominazione_ita": "Cucciago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D196",
      "lat": "45.7388979",
      "lon": "9.0915033",
      "superficie_kmq": "4.9305",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013085",
      "denominazione_ita_altra": "Cusino",
      "denominazione_ita": "Cusino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D232",
      "lat": "46.0743922",
      "lon": "9.1527515",
      "superficie_kmq": "9.6466",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013087",
      "denominazione_ita_altra": "Dizzasco",
      "denominazione_ita": "Dizzasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D310",
      "lat": "45.9438846",
      "lon": "9.1014646",
      "superficie_kmq": "3.6082",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013089",
      "denominazione_ita_altra": "Domaso",
      "denominazione_ita": "Domaso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D329",
      "lat": "46.1519614",
      "lon": "9.3284669",
      "superficie_kmq": "6.2764",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013090",
      "denominazione_ita_altra": "Dongo",
      "denominazione_ita": "Dongo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D341",
      "lat": "46.1230420",
      "lon": "9.2794985",
      "superficie_kmq": "7.0436",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013092",
      "denominazione_ita_altra": "Dosso del Liro",
      "denominazione_ita": "Dosso del Liro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D355",
      "lat": "46.1636987",
      "lon": "9.2736963",
      "superficie_kmq": "23.4878",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013095",
      "denominazione_ita_altra": "Erba",
      "denominazione_ita": "Erba",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D416",
      "lat": "45.8119454",
      "lon": "9.2231832",
      "superficie_kmq": "17.8017",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013097",
      "denominazione_ita_altra": "Eupilio",
      "denominazione_ita": "Eupilio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D445",
      "lat": "45.8169334",
      "lon": "9.2677027",
      "superficie_kmq": "6.9373",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013098",
      "denominazione_ita_altra": "Faggeto Lario",
      "denominazione_ita": "Faggeto Lario",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D462",
      "lat": "45.8580908",
      "lon": "9.1598377",
      "superficie_kmq": "17.5230",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013099",
      "denominazione_ita_altra": "Faloppio",
      "denominazione_ita": "Faloppio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D482",
      "lat": "45.8121849",
      "lon": "8.9632013",
      "superficie_kmq": "4.1414",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013100",
      "denominazione_ita_altra": "Fenegrò",
      "denominazione_ita": "Fenegrò",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D531",
      "lat": "45.7016083",
      "lon": "9.0027309",
      "superficie_kmq": "5.3603",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013101",
      "denominazione_ita_altra": "Figino Serenza",
      "denominazione_ita": "Figino Serenza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D579",
      "lat": "45.7116402",
      "lon": "9.1271939",
      "superficie_kmq": "4.9561",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013102",
      "denominazione_ita_altra": "Fino Mornasco",
      "denominazione_ita": "Fino Mornasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D605",
      "lat": "45.7461259",
      "lon": "9.0485789",
      "superficie_kmq": "7.2711",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013106",
      "denominazione_ita_altra": "Garzeno",
      "denominazione_ita": "Garzeno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D930",
      "lat": "46.1341057",
      "lon": "9.2498564",
      "superficie_kmq": "28.7547",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013107",
      "denominazione_ita_altra": "Gera Lario",
      "denominazione_ita": "Gera Lario",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D974",
      "lat": "46.1706407",
      "lon": "9.3711020",
      "superficie_kmq": "7.1792",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013110",
      "denominazione_ita_altra": "Grandate",
      "denominazione_ita": "Grandate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E139",
      "lat": "45.7739035",
      "lon": "9.0583000",
      "superficie_kmq": "2.8250",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013111",
      "denominazione_ita_altra": "Grandola ed Uniti",
      "denominazione_ita": "Grandola ed Uniti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E141",
      "lat": "46.0313309",
      "lon": "9.2071327",
      "superficie_kmq": "16.8991",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013113",
      "denominazione_ita_altra": "Griante",
      "denominazione_ita": "Griante",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E172",
      "lat": "45.9969568",
      "lon": "9.2350095",
      "superficie_kmq": "6.5458",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013114",
      "denominazione_ita_altra": "Guanzate",
      "denominazione_ita": "Guanzate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E235",
      "lat": "45.7243223",
      "lon": "9.0109487",
      "superficie_kmq": "6.9053",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013118",
      "denominazione_ita_altra": "Inverigo",
      "denominazione_ita": "Inverigo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E309",
      "lat": "45.7329364",
      "lon": "9.2149353",
      "superficie_kmq": "9.9865",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013119",
      "denominazione_ita_altra": "Laglio",
      "denominazione_ita": "Laglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E405",
      "lat": "45.8835331",
      "lon": "9.1400701",
      "superficie_kmq": "6.1961",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013120",
      "denominazione_ita_altra": "Laino",
      "denominazione_ita": "Laino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E416",
      "lat": "45.9833100",
      "lon": "9.0738553",
      "superficie_kmq": "6.6825",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013121",
      "denominazione_ita_altra": "Lambrugo",
      "denominazione_ita": "Lambrugo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E428",
      "lat": "45.7584749",
      "lon": "9.2415145",
      "superficie_kmq": "1.8360",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013123",
      "denominazione_ita_altra": "Lasnigo",
      "denominazione_ita": "Lasnigo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E462",
      "lat": "45.8806980",
      "lon": "9.2640702",
      "superficie_kmq": "5.5255",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013126",
      "denominazione_ita_altra": "Lezzeno",
      "denominazione_ita": "Lezzeno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E569",
      "lat": "45.9454753",
      "lon": "9.1910858",
      "superficie_kmq": "20.7039",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013128",
      "denominazione_ita_altra": "Limido Comasco",
      "denominazione_ita": "Limido Comasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E593",
      "lat": "45.6897117",
      "lon": "8.9827078",
      "superficie_kmq": "4.5636",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013129",
      "denominazione_ita_altra": "Lipomo",
      "denominazione_ita": "Lipomo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E607",
      "lat": "45.7948100",
      "lon": "9.1214792",
      "superficie_kmq": "2.2977",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013130",
      "denominazione_ita_altra": "Livo",
      "denominazione_ita": "Livo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E623",
      "lat": "46.1689230",
      "lon": "9.3041966",
      "superficie_kmq": "33.1337",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013131",
      "denominazione_ita_altra": "Locate Varesino",
      "denominazione_ita": "Locate Varesino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E638",
      "lat": "45.6903606",
      "lon": "8.9308176",
      "superficie_kmq": "6.0364",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013133",
      "denominazione_ita_altra": "Lomazzo",
      "denominazione_ita": "Lomazzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E659",
      "lat": "45.6990334",
      "lon": "9.0401493",
      "superficie_kmq": "9.4770",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013134",
      "denominazione_ita_altra": "Longone al Segrino",
      "denominazione_ita": "Longone al Segrino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E679",
      "lat": "45.8172582",
      "lon": "9.2509977",
      "superficie_kmq": "1.6045",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013135",
      "denominazione_ita_altra": "Luisago",
      "denominazione_ita": "Luisago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E735",
      "lat": "45.7612267",
      "lon": "9.0434567",
      "superficie_kmq": "2.1613",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013136",
      "denominazione_ita_altra": "Lurago d'Erba",
      "denominazione_ita": "Lurago d'Erba",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E749",
      "lat": "45.7534946",
      "lon": "9.2247363",
      "superficie_kmq": "4.6988",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013137",
      "denominazione_ita_altra": "Lurago Marinone",
      "denominazione_ita": "Lurago Marinone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E750",
      "lat": "45.7078155",
      "lon": "8.9839463",
      "superficie_kmq": "3.8854",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013138",
      "denominazione_ita_altra": "Lurate Caccivio",
      "denominazione_ita": "Lurate Caccivio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E753",
      "lat": "45.7655639",
      "lon": "9.0006797",
      "superficie_kmq": "5.9357",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013139",
      "denominazione_ita_altra": "Magreglio",
      "denominazione_ita": "Magreglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E830",
      "lat": "45.9200358",
      "lon": "9.2618948",
      "superficie_kmq": "3.0815",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013143",
      "denominazione_ita_altra": "Mariano Comense",
      "denominazione_ita": "Mariano Comense",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E951",
      "lat": "45.6971062",
      "lon": "9.1825110",
      "superficie_kmq": "13.8027",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013144",
      "denominazione_ita_altra": "Maslianico",
      "denominazione_ita": "Maslianico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F017",
      "lat": "45.8425747",
      "lon": "9.0484652",
      "superficie_kmq": "1.2884",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013145",
      "denominazione_ita_altra": "Menaggio",
      "denominazione_ita": "Menaggio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F120",
      "lat": "46.0211166",
      "lon": "9.2389108",
      "superficie_kmq": "11.7701",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013147",
      "denominazione_ita_altra": "Merone",
      "denominazione_ita": "Merone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F151",
      "lat": "45.7863946",
      "lon": "9.2451163",
      "superficie_kmq": "3.2814",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013152",
      "denominazione_ita_altra": "Moltrasio",
      "denominazione_ita": "Moltrasio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F305",
      "lat": "45.8591629",
      "lon": "9.0958434",
      "superficie_kmq": "8.8973",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013153",
      "denominazione_ita_altra": "Monguzzo",
      "denominazione_ita": "Monguzzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F372",
      "lat": "45.7828364",
      "lon": "9.2321691",
      "superficie_kmq": "3.7270",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013154",
      "denominazione_ita_altra": "Montano Lucino",
      "denominazione_ita": "Montano Lucino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F427",
      "lat": "45.7863113",
      "lon": "9.0412798",
      "superficie_kmq": "5.2231",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013155",
      "denominazione_ita_altra": "Montemezzo",
      "denominazione_ita": "Montemezzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F564",
      "lat": "46.1785069",
      "lon": "9.3769808",
      "superficie_kmq": "9.0152",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013157",
      "denominazione_ita_altra": "Montorfano",
      "denominazione_ita": "Montorfano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F688",
      "lat": "45.7854131",
      "lon": "9.1444955",
      "superficie_kmq": "3.5169",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013159",
      "denominazione_ita_altra": "Mozzate",
      "denominazione_ita": "Mozzate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F788",
      "lat": "45.6749979",
      "lon": "8.9559433",
      "superficie_kmq": "10.6815",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013160",
      "denominazione_ita_altra": "Musso",
      "denominazione_ita": "Musso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F828",
      "lat": "46.1108337",
      "lon": "9.2736782",
      "superficie_kmq": "3.7055",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013161",
      "denominazione_ita_altra": "Nesso",
      "denominazione_ita": "Nesso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F877",
      "lat": "45.9107392",
      "lon": "9.1590016",
      "superficie_kmq": "15.0301",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013163",
      "denominazione_ita_altra": "Novedrate",
      "denominazione_ita": "Novedrate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F958",
      "lat": "45.6975852",
      "lon": "9.1234867",
      "superficie_kmq": "2.9210",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013165",
      "denominazione_ita_altra": "Olgiate Comasco",
      "denominazione_ita": "Olgiate Comasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G025",
      "lat": "45.7858386",
      "lon": "8.9674848",
      "superficie_kmq": "10.9644",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013169",
      "denominazione_ita_altra": "Oltrona di San Mamette",
      "denominazione_ita": "Oltrona di San Mamette",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G056",
      "lat": "45.7580646",
      "lon": "8.9759751",
      "superficie_kmq": "2.6880",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013170",
      "denominazione_ita_altra": "Orsenigo",
      "denominazione_ita": "Orsenigo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G126",
      "lat": "45.7783093",
      "lon": "9.1850620",
      "superficie_kmq": "4.4607",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013178",
      "denominazione_ita_altra": "Peglio",
      "denominazione_ita": "Peglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G415",
      "lat": "46.1601953",
      "lon": "9.2947905",
      "superficie_kmq": "10.5747",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013183",
      "denominazione_ita_altra": "Pianello del Lario",
      "denominazione_ita": "Pianello del Lario",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G556",
      "lat": "46.1003994",
      "lon": "9.2764746",
      "superficie_kmq": "9.7955",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013184",
      "denominazione_ita_altra": "Pigra",
      "denominazione_ita": "Pigra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G665",
      "lat": "45.9573594",
      "lon": "9.1264638",
      "superficie_kmq": "4.5334",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013185",
      "denominazione_ita_altra": "Plesio",
      "denominazione_ita": "Plesio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G737",
      "lat": "46.0441912",
      "lon": "9.2278636",
      "superficie_kmq": "16.9040",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013186",
      "denominazione_ita_altra": "Pognana Lario",
      "denominazione_ita": "Pognana Lario",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G773",
      "lat": "45.8774081",
      "lon": "9.1578372",
      "superficie_kmq": "5.0749",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013187",
      "denominazione_ita_altra": "Ponna",
      "denominazione_ita": "Ponna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G821",
      "lat": "45.9896518",
      "lon": "9.0940120",
      "superficie_kmq": "5.8112",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013188",
      "denominazione_ita_altra": "Ponte Lambro",
      "denominazione_ita": "Ponte Lambro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G847",
      "lat": "45.8270040",
      "lon": "9.2249175",
      "superficie_kmq": "3.3802",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013189",
      "denominazione_ita_altra": "Porlezza",
      "denominazione_ita": "Porlezza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G889",
      "lat": "46.0364264",
      "lon": "9.1198142",
      "superficie_kmq": "18.6399",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013192",
      "denominazione_ita_altra": "Proserpio",
      "denominazione_ita": "Proserpio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H074",
      "lat": "45.8276614",
      "lon": "9.2488655",
      "superficie_kmq": "2.2991",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013193",
      "denominazione_ita_altra": "Pusiano",
      "denominazione_ita": "Pusiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H094",
      "lat": "45.8147887",
      "lon": "9.2821438",
      "superficie_kmq": "3.2026",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013195",
      "denominazione_ita_altra": "Rezzago",
      "denominazione_ita": "Rezzago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H255",
      "lat": "45.8667379",
      "lon": "9.2486022",
      "superficie_kmq": "4.0752",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013197",
      "denominazione_ita_altra": "Rodero",
      "denominazione_ita": "Rodero",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H478",
      "lat": "45.8226411",
      "lon": "8.9133882",
      "superficie_kmq": "2.5208",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013201",
      "denominazione_ita_altra": "Rovellasca",
      "denominazione_ita": "Rovellasca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H601",
      "lat": "45.6679528",
      "lon": "9.0522964",
      "superficie_kmq": "3.5679",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013202",
      "denominazione_ita_altra": "Rovello Porro",
      "denominazione_ita": "Rovello Porro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H602",
      "lat": "45.6544073",
      "lon": "9.0398195",
      "superficie_kmq": "5.5316",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013203",
      "denominazione_ita_altra": "Sala Comacina",
      "denominazione_ita": "Sala Comacina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H679",
      "lat": "45.9642748",
      "lon": "9.1659959",
      "superficie_kmq": "4.7342",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013204",
      "denominazione_ita_altra": "San Bartolomeo Val Cavargna",
      "denominazione_ita": "San Bartolomeo Val Cavargna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H760",
      "lat": "46.0825193",
      "lon": "9.1462228",
      "superficie_kmq": "10.5076",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013206",
      "denominazione_ita_altra": "San Fermo della Battaglia",
      "denominazione_ita": "San Fermo della Battaglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H840",
      "lat": "45.8084780",
      "lon": "9.0462075",
      "superficie_kmq": "5.7797",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013207",
      "denominazione_ita_altra": "San Nazzaro Val Cavargna",
      "denominazione_ita": "San Nazzaro Val Cavargna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I051",
      "lat": "46.0892395",
      "lon": "9.1306470",
      "superficie_kmq": "12.9930",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013211",
      "denominazione_ita_altra": "Schignano",
      "denominazione_ita": "Schignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I529",
      "lat": "45.9279193",
      "lon": "9.1023646",
      "superficie_kmq": "10.1171",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013212",
      "denominazione_ita_altra": "Senna Comasco",
      "denominazione_ita": "Senna Comasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I611",
      "lat": "45.7633347",
      "lon": "9.1073261",
      "superficie_kmq": "2.7868",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013216",
      "denominazione_ita_altra": "Sorico",
      "denominazione_ita": "Sorico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I856",
      "lat": "46.1721370",
      "lon": "9.3797872",
      "superficie_kmq": "24.4370",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013217",
      "denominazione_ita_altra": "Sormano",
      "denominazione_ita": "Sormano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I860",
      "lat": "45.8794504",
      "lon": "9.2470768",
      "superficie_kmq": "10.7425",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013218",
      "denominazione_ita_altra": "Stazzona",
      "denominazione_ita": "Stazzona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I943",
      "lat": "46.1390012",
      "lon": "9.2772597",
      "superficie_kmq": "7.2943",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013222",
      "denominazione_ita_altra": "Tavernerio",
      "denominazione_ita": "Tavernerio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L071",
      "lat": "45.7975626",
      "lon": "9.1504284",
      "superficie_kmq": "11.8815",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013223",
      "denominazione_ita_altra": "Torno",
      "denominazione_ita": "Torno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L228",
      "lat": "45.8587257",
      "lon": "9.1189214",
      "superficie_kmq": "7.5288",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013226",
      "denominazione_ita_altra": "Trezzone",
      "denominazione_ita": "Trezzone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L413",
      "lat": "46.1714783",
      "lon": "9.3553354",
      "superficie_kmq": "3.9091",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013227",
      "denominazione_ita_altra": "Turate",
      "denominazione_ita": "Turate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L470",
      "lat": "45.6550764",
      "lon": "9.0012140",
      "superficie_kmq": "10.2824",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013229",
      "denominazione_ita_altra": "Valbrona",
      "denominazione_ita": "Valbrona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L547",
      "lat": "45.8777991",
      "lon": "9.2967727",
      "superficie_kmq": "13.6474",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013232",
      "denominazione_ita_altra": "Valmorea",
      "denominazione_ita": "Valmorea",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L640",
      "lat": "45.8132993",
      "lon": "8.9306308",
      "superficie_kmq": "3.1320",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013233",
      "denominazione_ita_altra": "Val Rezzo",
      "denominazione_ita": "Val Rezzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H259",
      "lat": "46.0723040",
      "lon": "9.1106218",
      "superficie_kmq": "6.6115",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013234",
      "denominazione_ita_altra": "Valsolda",
      "denominazione_ita": "Valsolda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C936",
      "lat": "46.0244740",
      "lon": "9.0561327",
      "superficie_kmq": "31.7405",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013236",
      "denominazione_ita_altra": "Veleso",
      "denominazione_ita": "Veleso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L715",
      "lat": "45.9087576",
      "lon": "9.1808764",
      "superficie_kmq": "5.8606",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013238",
      "denominazione_ita_altra": "Veniano",
      "denominazione_ita": "Veniano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L737",
      "lat": "45.7162981",
      "lon": "8.9849856",
      "superficie_kmq": "3.1517",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013239",
      "denominazione_ita_altra": "Vercana",
      "denominazione_ita": "Vercana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L748",
      "lat": "46.1600305",
      "lon": "9.3304331",
      "superficie_kmq": "15.0078",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013242",
      "denominazione_ita_altra": "Vertemate con Minoprio",
      "denominazione_ita": "Vertemate con Minoprio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L792",
      "lat": "45.7251762",
      "lon": "9.0721673",
      "superficie_kmq": "5.7469",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013245",
      "denominazione_ita_altra": "Villa Guardia",
      "denominazione_ita": "Villa Guardia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L956",
      "lat": "45.7747385",
      "lon": "9.0259151",
      "superficie_kmq": "7.8577",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013246",
      "denominazione_ita_altra": "Zelbio",
      "denominazione_ita": "Zelbio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M156",
      "lat": "45.9050423",
      "lon": "9.1808920",
      "superficie_kmq": "4.5952",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013248",
      "denominazione_ita_altra": "San Siro",
      "denominazione_ita": "San Siro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I162",
      "lat": "46.0552303",
      "lon": "9.2585106",
      "superficie_kmq": "18.7866",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013249",
      "denominazione_ita_altra": "Gravedona ed Uniti",
      "denominazione_ita": "Gravedona ed Uniti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M315",
      "lat": "46.1481228",
      "lon": "9.3062297",
      "superficie_kmq": "39.8520",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013250",
      "denominazione_ita_altra": "Bellagio",
      "denominazione_ita": "Bellagio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M335",
      "lat": "45.9556379",
      "lon": "9.2495086",
      "superficie_kmq": "29.0589",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013251",
      "denominazione_ita_altra": "Colverde",
      "denominazione_ita": "Colverde",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M336",
      "lat": "45.8057001",
      "lon": "9.0010932",
      "superficie_kmq": "8.5814",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013252",
      "denominazione_ita_altra": "Tremezzina",
      "denominazione_ita": "Tremezzina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M341",
      "lat": "45.9845564",
      "lon": "9.1874225",
      "superficie_kmq": "29.4097",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013253",
      "denominazione_ita_altra": "Alta Valle Intelvi",
      "denominazione_ita": "Alta Valle Intelvi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M383",
      "lat": "45.9756684",
      "lon": "9.0363648",
      "superficie_kmq": "24.9508",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013254",
      "denominazione_ita_altra": "Centro Valle Intelvi",
      "denominazione_ita": "Centro Valle Intelvi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M394",
      "lat": "45.9469799",
      "lon": "9.0612123",
      "superficie_kmq": "19.6566",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013255",
      "denominazione_ita_altra": "Solbiate con Cagno",
      "denominazione_ita": "Solbiate con Cagno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M412",
      "lat": "45.8005365",
      "lon": "8.9197058",
      "superficie_kmq": "7.6232",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "CO",
      "codice_istat": "013256",
      "denominazione_ita_altra": "Uggiate con Ronago ",
      "denominazione_ita": "Uggiate con Ronago ",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M435",
      "lat": "45.8269418",
      "lon": "8.9741899",
      "superficie_kmq": "7.8708",
      "codice_sovracomunale": "013"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014001",
      "denominazione_ita_altra": "Albaredo per San Marco",
      "denominazione_ita": "Albaredo per San Marco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A135",
      "lat": "46.1029401",
      "lon": "9.5904391",
      "superficie_kmq": "18.9604",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014002",
      "denominazione_ita_altra": "Albosaggia",
      "denominazione_ita": "Albosaggia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A172",
      "lat": "46.1474133",
      "lon": "9.8546542",
      "superficie_kmq": "34.3464",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014003",
      "denominazione_ita_altra": "Andalo Valtellino",
      "denominazione_ita": "Andalo Valtellino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A273",
      "lat": "46.1344942",
      "lon": "9.4730135",
      "superficie_kmq": "6.7750",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014004",
      "denominazione_ita_altra": "Aprica",
      "denominazione_ita": "Aprica",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A337",
      "lat": "46.1533634",
      "lon": "10.1520638",
      "superficie_kmq": "20.3652",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014005",
      "denominazione_ita_altra": "Ardenno",
      "denominazione_ita": "Ardenno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A382",
      "lat": "46.1727761",
      "lon": "9.6517225",
      "superficie_kmq": "17.1442",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014006",
      "denominazione_ita_altra": "Bema",
      "denominazione_ita": "Bema",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A777",
      "lat": "46.1083329",
      "lon": "9.5645647",
      "superficie_kmq": "19.2215",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014007",
      "denominazione_ita_altra": "Berbenno di Valtellina",
      "denominazione_ita": "Berbenno di Valtellina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A787",
      "lat": "46.1687384",
      "lon": "9.7431898",
      "superficie_kmq": "35.5949",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014008",
      "denominazione_ita_altra": "Bianzone",
      "denominazione_ita": "Bianzone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A848",
      "lat": "46.1839501",
      "lon": "10.1093025",
      "superficie_kmq": "17.1065",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014009",
      "denominazione_ita_altra": "Bormio",
      "denominazione_ita": "Bormio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B049",
      "lat": "46.4701801",
      "lon": "10.3755956",
      "superficie_kmq": "41.4364",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014010",
      "denominazione_ita_altra": "Buglio in Monte",
      "denominazione_ita": "Buglio in Monte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B255",
      "lat": "46.1833699",
      "lon": "9.6753916",
      "superficie_kmq": "27.7070",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014011",
      "denominazione_ita_altra": "Caiolo",
      "denominazione_ita": "Caiolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B366",
      "lat": "46.1523337",
      "lon": "9.8170570",
      "superficie_kmq": "32.9723",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014012",
      "denominazione_ita_altra": "Campodolcino",
      "denominazione_ita": "Campodolcino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B530",
      "lat": "46.4021525",
      "lon": "9.3543111",
      "superficie_kmq": "48.4884",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014013",
      "denominazione_ita_altra": "Caspoggio",
      "denominazione_ita": "Caspoggio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B993",
      "lat": "46.2647432",
      "lon": "9.8617702",
      "superficie_kmq": "7.3122",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014014",
      "denominazione_ita_altra": "Castello dell'Acqua",
      "denominazione_ita": "Castello dell'Acqua",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C186",
      "lat": "46.1455007",
      "lon": "10.0157901",
      "superficie_kmq": "14.0700",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014015",
      "denominazione_ita_altra": "Castione Andevenno",
      "denominazione_ita": "Castione Andevenno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C325",
      "lat": "46.1728778",
      "lon": "9.8002975",
      "superficie_kmq": "17.0329",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014016",
      "denominazione_ita_altra": "Cedrasco",
      "denominazione_ita": "Cedrasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C418",
      "lat": "46.1493299",
      "lon": "9.7680043",
      "superficie_kmq": "14.4204",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014017",
      "denominazione_ita_altra": "Cercino",
      "denominazione_ita": "Cercino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C493",
      "lat": "46.1577930",
      "lon": "9.5081831",
      "superficie_kmq": "5.6946",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014018",
      "denominazione_ita_altra": "Chiavenna",
      "denominazione_ita": "Chiavenna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C623",
      "lat": "46.3210455",
      "lon": "9.4054877",
      "superficie_kmq": "10.7742",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014019",
      "denominazione_ita_altra": "Chiesa in Valmalenco",
      "denominazione_ita": "Chiesa in Valmalenco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C628",
      "lat": "46.2637861",
      "lon": "9.8483266",
      "superficie_kmq": "107.5975",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014020",
      "denominazione_ita_altra": "Chiuro",
      "denominazione_ita": "Chiuro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C651",
      "lat": "46.1695724",
      "lon": "9.9892126",
      "superficie_kmq": "51.7567",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014021",
      "denominazione_ita_altra": "Cino",
      "denominazione_ita": "Cino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C709",
      "lat": "46.1584440",
      "lon": "9.4853110",
      "superficie_kmq": "5.0707",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014022",
      "denominazione_ita_altra": "Civo",
      "denominazione_ita": "Civo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C785",
      "lat": "46.1563611",
      "lon": "9.5812143",
      "superficie_kmq": "25.1361",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014023",
      "denominazione_ita_altra": "Colorina",
      "denominazione_ita": "Colorina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C903",
      "lat": "46.1518606",
      "lon": "9.7334634",
      "superficie_kmq": "17.8352",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014024",
      "denominazione_ita_altra": "Cosio Valtellino",
      "denominazione_ita": "Cosio Valtellino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D088",
      "lat": "46.1303982",
      "lon": "9.5498684",
      "superficie_kmq": "23.9902",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014025",
      "denominazione_ita_altra": "Dazio",
      "denominazione_ita": "Dazio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D258",
      "lat": "46.1607399",
      "lon": "9.6002021",
      "superficie_kmq": "3.7335",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014026",
      "denominazione_ita_altra": "Delebio",
      "denominazione_ita": "Delebio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D266",
      "lat": "46.1371715",
      "lon": "9.4592551",
      "superficie_kmq": "22.4380",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014027",
      "denominazione_ita_altra": "Dubino",
      "denominazione_ita": "Dubino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D377",
      "lat": "46.1530131",
      "lon": "9.4608813",
      "superficie_kmq": "13.2392",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014028",
      "denominazione_ita_altra": "Faedo Valtellino",
      "denominazione_ita": "Faedo Valtellino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D456",
      "lat": "46.1530976",
      "lon": "9.9063272",
      "superficie_kmq": "4.7990",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014029",
      "denominazione_ita_altra": "Forcola",
      "denominazione_ita": "Forcola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D694",
      "lat": "46.1582458",
      "lon": "9.6610695",
      "superficie_kmq": "15.8975",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014030",
      "denominazione_ita_altra": "Fusine",
      "denominazione_ita": "Fusine",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D830",
      "lat": "46.1494822",
      "lon": "9.7501077",
      "superficie_kmq": "37.5950",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014031",
      "denominazione_ita_altra": "Gerola Alta",
      "denominazione_ita": "Gerola Alta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D990",
      "lat": "46.0600416",
      "lon": "9.5502256",
      "superficie_kmq": "37.4263",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014032",
      "denominazione_ita_altra": "Gordona",
      "denominazione_ita": "Gordona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E090",
      "lat": "46.2917028",
      "lon": "9.3685863",
      "superficie_kmq": "62.7928",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014033",
      "denominazione_ita_altra": "Grosio",
      "denominazione_ita": "Grosio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E200",
      "lat": "46.2973198",
      "lon": "10.2757185",
      "superficie_kmq": "126.9158",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014034",
      "denominazione_ita_altra": "Grosotto",
      "denominazione_ita": "Grosotto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E201",
      "lat": "46.2787973",
      "lon": "10.2571319",
      "superficie_kmq": "53.1233",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014035",
      "denominazione_ita_altra": "Madesimo",
      "denominazione_ita": "Madesimo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E342",
      "lat": "46.4281701",
      "lon": "9.3441756",
      "superficie_kmq": "85.6544",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014036",
      "denominazione_ita_altra": "Lanzada",
      "denominazione_ita": "Lanzada",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E443",
      "lat": "46.2694072",
      "lon": "9.8739146",
      "superficie_kmq": "117.1650",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014037",
      "denominazione_ita_altra": "Livigno",
      "denominazione_ita": "Livigno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E621",
      "lat": "46.5386945",
      "lon": "10.1348109",
      "superficie_kmq": "227.2927",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014038",
      "denominazione_ita_altra": "Lovero",
      "denominazione_ita": "Lovero",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E705",
      "lat": "46.2308568",
      "lon": "10.2302963",
      "superficie_kmq": "13.4606",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014039",
      "denominazione_ita_altra": "Mantello",
      "denominazione_ita": "Mantello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E896",
      "lat": "46.1529184",
      "lon": "9.4880478",
      "superficie_kmq": "3.7844",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014040",
      "denominazione_ita_altra": "Mazzo di Valtellina",
      "denominazione_ita": "Mazzo di Valtellina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F070",
      "lat": "46.2564998",
      "lon": "10.2548034",
      "superficie_kmq": "15.3245",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014041",
      "denominazione_ita_altra": "Mello",
      "denominazione_ita": "Mello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F115",
      "lat": "46.1546441",
      "lon": "9.5442646",
      "superficie_kmq": "11.4294",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014043",
      "denominazione_ita_altra": "Mese",
      "denominazione_ita": "Mese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F153",
      "lat": "46.3044710",
      "lon": "9.3794568",
      "superficie_kmq": "4.1455",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014044",
      "denominazione_ita_altra": "Montagna in Valtellina",
      "denominazione_ita": "Montagna in Valtellina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F393",
      "lat": "46.1782622",
      "lon": "9.9006501",
      "superficie_kmq": "44.9738",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014045",
      "denominazione_ita_altra": "Morbegno",
      "denominazione_ita": "Morbegno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F712",
      "lat": "46.1304709",
      "lon": "9.5697810",
      "superficie_kmq": "14.8160",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014046",
      "denominazione_ita_altra": "Novate Mezzola",
      "denominazione_ita": "Novate Mezzola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F956",
      "lat": "46.2219989",
      "lon": "9.4468225",
      "superficie_kmq": "99.7437",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014047",
      "denominazione_ita_altra": "Pedesina",
      "denominazione_ita": "Pedesina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G410",
      "lat": "46.0822359",
      "lon": "9.5495254",
      "superficie_kmq": "6.2997",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014048",
      "denominazione_ita_altra": "Piantedo",
      "denominazione_ita": "Piantedo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G572",
      "lat": "46.1339410",
      "lon": "9.4315046",
      "superficie_kmq": "6.8047",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014049",
      "denominazione_ita_altra": "Piateda",
      "denominazione_ita": "Piateda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G576",
      "lat": "46.1597678",
      "lon": "9.9388871",
      "superficie_kmq": "70.7998",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014050",
      "denominazione_ita_altra": "Piuro",
      "denominazione_ita": "Piuro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G718",
      "lat": "46.3294864",
      "lon": "9.4208522",
      "superficie_kmq": "84.2534",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014051",
      "denominazione_ita_altra": "Poggiridenti",
      "denominazione_ita": "Poggiridenti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G431",
      "lat": "46.1758831",
      "lon": "9.9286571",
      "superficie_kmq": "2.8988",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014052",
      "denominazione_ita_altra": "Ponte in Valtellina",
      "denominazione_ita": "Ponte in Valtellina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G829",
      "lat": "46.1744056",
      "lon": "9.9774899",
      "superficie_kmq": "67.7265",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014053",
      "denominazione_ita_altra": "Postalesio",
      "denominazione_ita": "Postalesio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G937",
      "lat": "46.1737998",
      "lon": "9.7765617",
      "superficie_kmq": "10.5844",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014054",
      "denominazione_ita_altra": "Prata Camportaccio",
      "denominazione_ita": "Prata Camportaccio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G993",
      "lat": "46.3064092",
      "lon": "9.3949984",
      "superficie_kmq": "27.9382",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014055",
      "denominazione_ita_altra": "Rasura",
      "denominazione_ita": "Rasura",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H192",
      "lat": "46.1007579",
      "lon": "9.5529222",
      "superficie_kmq": "6.0003",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014056",
      "denominazione_ita_altra": "Rogolo",
      "denominazione_ita": "Rogolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H493",
      "lat": "46.1363146",
      "lon": "9.4879280",
      "superficie_kmq": "12.8248",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014057",
      "denominazione_ita_altra": "Samolaco",
      "denominazione_ita": "Samolaco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H752",
      "lat": "46.2442356",
      "lon": "9.3951233",
      "superficie_kmq": "45.7953",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014058",
      "denominazione_ita_altra": "San Giacomo Filippo",
      "denominazione_ita": "San Giacomo Filippo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H868",
      "lat": "46.3371595",
      "lon": "9.3704278",
      "superficie_kmq": "61.8501",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014059",
      "denominazione_ita_altra": "Sernio",
      "denominazione_ita": "Sernio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I636",
      "lat": "46.2251250",
      "lon": "10.2033257",
      "superficie_kmq": "9.5233",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014060",
      "denominazione_ita_altra": "Sondalo",
      "denominazione_ita": "Sondalo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I828",
      "lat": "46.3320463",
      "lon": "10.3236782",
      "superficie_kmq": "95.4525",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014061",
      "denominazione_ita_altra": "Sondrio",
      "denominazione_ita": "Sondrio",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "I829",
      "lat": "46.1710083",
      "lon": "9.8714751",
      "superficie_kmq": "20.8748",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014062",
      "denominazione_ita_altra": "Spriana",
      "denominazione_ita": "Spriana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I928",
      "lat": "46.2201649",
      "lon": "9.8644696",
      "superficie_kmq": "7.6849",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014063",
      "denominazione_ita_altra": "Talamona",
      "denominazione_ita": "Talamona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L035",
      "lat": "46.1375259",
      "lon": "9.6094728",
      "superficie_kmq": "21.0512",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014064",
      "denominazione_ita_altra": "Tartano",
      "denominazione_ita": "Tartano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L056",
      "lat": "46.1053332",
      "lon": "9.6793121",
      "superficie_kmq": "47.2736",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014065",
      "denominazione_ita_altra": "Teglio",
      "denominazione_ita": "Teglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L084",
      "lat": "46.1722774",
      "lon": "10.0632805",
      "superficie_kmq": "115.3175",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014066",
      "denominazione_ita_altra": "Tirano",
      "denominazione_ita": "Tirano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L175",
      "lat": "46.2168870",
      "lon": "10.1753154",
      "superficie_kmq": "32.3745",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014067",
      "denominazione_ita_altra": "Torre di Santa Maria",
      "denominazione_ita": "Torre di Santa Maria",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L244",
      "lat": "46.2326327",
      "lon": "9.8520960",
      "superficie_kmq": "44.2368",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014068",
      "denominazione_ita_altra": "Tovo di Sant'Agata",
      "denominazione_ita": "Tovo di Sant'Agata",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L316",
      "lat": "46.2450604",
      "lon": "10.2466713",
      "superficie_kmq": "11.1463",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014069",
      "denominazione_ita_altra": "Traona",
      "denominazione_ita": "Traona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L330",
      "lat": "46.1468937",
      "lon": "9.5267826",
      "superficie_kmq": "6.3664",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014070",
      "denominazione_ita_altra": "Tresivio",
      "denominazione_ita": "Tresivio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L392",
      "lat": "46.1747113",
      "lon": "9.9453816",
      "superficie_kmq": "15.0134",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014071",
      "denominazione_ita_altra": "Valdidentro",
      "denominazione_ita": "Valdidentro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L557",
      "lat": "46.4893533",
      "lon": "10.2968061",
      "superficie_kmq": "226.7227",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014072",
      "denominazione_ita_altra": "Valdisotto",
      "denominazione_ita": "Valdisotto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L563",
      "lat": "46.4316911",
      "lon": "10.3551110",
      "superficie_kmq": "89.5706",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014073",
      "denominazione_ita_altra": "Valfurva",
      "denominazione_ita": "Valfurva",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L576",
      "lat": "46.4631000",
      "lon": "10.4108609",
      "superficie_kmq": "214.9861",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014074",
      "denominazione_ita_altra": "Val Masino",
      "denominazione_ita": "Val Masino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L638",
      "lat": "46.2110998",
      "lon": "9.6366362",
      "superficie_kmq": "116.7085",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014075",
      "denominazione_ita_altra": "Verceia",
      "denominazione_ita": "Verceia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L749",
      "lat": "46.1966487",
      "lon": "9.4527608",
      "superficie_kmq": "11.3774",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014076",
      "denominazione_ita_altra": "Vervio",
      "denominazione_ita": "Vervio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L799",
      "lat": "46.2528679",
      "lon": "10.2405321",
      "superficie_kmq": "12.4053",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014077",
      "denominazione_ita_altra": "Villa di Chiavenna",
      "denominazione_ita": "Villa di Chiavenna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L907",
      "lat": "46.3312557",
      "lon": "9.4889699",
      "superficie_kmq": "32.5223",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "SO",
      "codice_istat": "014078",
      "denominazione_ita_altra": "Villa di Tirano",
      "denominazione_ita": "Villa di Tirano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L908",
      "lat": "46.2026993",
      "lon": "10.1332071",
      "superficie_kmq": "24.7393",
      "codice_sovracomunale": "014"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015002",
      "denominazione_ita_altra": "Abbiategrasso",
      "denominazione_ita": "Abbiategrasso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A010",
      "lat": "45.3985804",
      "lon": "8.9174856",
      "superficie_kmq": "47.7776",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015005",
      "denominazione_ita_altra": "Albairate",
      "denominazione_ita": "Albairate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A127",
      "lat": "45.4187106",
      "lon": "8.9351739",
      "superficie_kmq": "14.9817",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015007",
      "denominazione_ita_altra": "Arconate",
      "denominazione_ita": "Arconate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A375",
      "lat": "45.5415619",
      "lon": "8.8515151",
      "superficie_kmq": "8.4210",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015009",
      "denominazione_ita_altra": "Arese",
      "denominazione_ita": "Arese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A389",
      "lat": "45.5498785",
      "lon": "9.0785758",
      "superficie_kmq": "6.5763",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015010",
      "denominazione_ita_altra": "Arluno",
      "denominazione_ita": "Arluno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A413",
      "lat": "45.5089990",
      "lon": "8.9500299",
      "superficie_kmq": "12.3585",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015011",
      "denominazione_ita_altra": "Assago",
      "denominazione_ita": "Assago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A473",
      "lat": "45.4043650",
      "lon": "9.1311488",
      "superficie_kmq": "8.0523",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015012",
      "denominazione_ita_altra": "Bareggio",
      "denominazione_ita": "Bareggio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A652",
      "lat": "45.4764643",
      "lon": "8.9938189",
      "superficie_kmq": "11.3842",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015014",
      "denominazione_ita_altra": "Basiano",
      "denominazione_ita": "Basiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A697",
      "lat": "45.5744278",
      "lon": "9.4682020",
      "superficie_kmq": "4.5856",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015015",
      "denominazione_ita_altra": "Basiglio",
      "denominazione_ita": "Basiglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A699",
      "lat": "45.3627207",
      "lon": "9.1614742",
      "superficie_kmq": "8.4922",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015016",
      "denominazione_ita_altra": "Bellinzago Lombardo",
      "denominazione_ita": "Bellinzago Lombardo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A751",
      "lat": "45.5408366",
      "lon": "9.4459706",
      "superficie_kmq": "4.5903",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015019",
      "denominazione_ita_altra": "Bernate Ticino",
      "denominazione_ita": "Bernate Ticino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A804",
      "lat": "45.4793351",
      "lon": "8.8159259",
      "superficie_kmq": "12.1619",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015022",
      "denominazione_ita_altra": "Besate",
      "denominazione_ita": "Besate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A820",
      "lat": "45.3122495",
      "lon": "8.9673176",
      "superficie_kmq": "12.7426",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015024",
      "denominazione_ita_altra": "Binasco",
      "denominazione_ita": "Binasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A872",
      "lat": "45.3322013",
      "lon": "9.0996586",
      "superficie_kmq": "3.8715",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015026",
      "denominazione_ita_altra": "Boffalora sopra Ticino",
      "denominazione_ita": "Boffalora sopra Ticino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A920",
      "lat": "45.4643644",
      "lon": "8.8284907",
      "superficie_kmq": "7.6536",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015027",
      "denominazione_ita_altra": "Bollate",
      "denominazione_ita": "Bollate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A940",
      "lat": "45.5438496",
      "lon": "9.1176551",
      "superficie_kmq": "13.1096",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015032",
      "denominazione_ita_altra": "Bresso",
      "denominazione_ita": "Bresso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B162",
      "lat": "45.5376064",
      "lon": "9.1876298",
      "superficie_kmq": "3.3824",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015035",
      "denominazione_ita_altra": "Bubbiano",
      "denominazione_ita": "Bubbiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B235",
      "lat": "45.3273282",
      "lon": "9.0133698",
      "superficie_kmq": "2.9490",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015036",
      "denominazione_ita_altra": "Buccinasco",
      "denominazione_ita": "Buccinasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B240",
      "lat": "45.4212735",
      "lon": "9.1075751",
      "superficie_kmq": "12.0056",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015038",
      "denominazione_ita_altra": "Buscate",
      "denominazione_ita": "Buscate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B286",
      "lat": "45.5460078",
      "lon": "8.8125899",
      "superficie_kmq": "7.8322",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015040",
      "denominazione_ita_altra": "Bussero",
      "denominazione_ita": "Bussero",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B292",
      "lat": "45.5379245",
      "lon": "9.3720514",
      "superficie_kmq": "4.5863",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015041",
      "denominazione_ita_altra": "Busto Garolfo",
      "denominazione_ita": "Busto Garolfo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B301",
      "lat": "45.5460642",
      "lon": "8.8834087",
      "superficie_kmq": "12.9872",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015042",
      "denominazione_ita_altra": "Calvignasco",
      "denominazione_ita": "Calvignasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B448",
      "lat": "45.3317983",
      "lon": "9.0208667",
      "superficie_kmq": "1.7272",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015044",
      "denominazione_ita_altra": "Cambiago",
      "denominazione_ita": "Cambiago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B461",
      "lat": "45.5733733",
      "lon": "9.4241660",
      "superficie_kmq": "7.1802",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015046",
      "denominazione_ita_altra": "Canegrate",
      "denominazione_ita": "Canegrate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B593",
      "lat": "45.5725563",
      "lon": "8.9245733",
      "superficie_kmq": "5.2538",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015050",
      "denominazione_ita_altra": "Carpiano",
      "denominazione_ita": "Carpiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B820",
      "lat": "45.3416450",
      "lon": "9.2746398",
      "superficie_kmq": "17.2436",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015051",
      "denominazione_ita_altra": "Carugate",
      "denominazione_ita": "Carugate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B850",
      "lat": "45.5496097",
      "lon": "9.3384342",
      "superficie_kmq": "5.3860",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015055",
      "denominazione_ita_altra": "Casarile",
      "denominazione_ita": "Casarile",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B938",
      "lat": "45.3175950",
      "lon": "9.1032045",
      "superficie_kmq": "7.3249",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015058",
      "denominazione_ita_altra": "Casorezzo",
      "denominazione_ita": "Casorezzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B989",
      "lat": "45.5242071",
      "lon": "8.8990494",
      "superficie_kmq": "6.5951",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015059",
      "denominazione_ita_altra": "Cassano d'Adda",
      "denominazione_ita": "Cassano d'Adda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C003",
      "lat": "45.5265266",
      "lon": "9.5205462",
      "superficie_kmq": "18.6010",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015060",
      "denominazione_ita_altra": "Cassina de' Pecchi",
      "denominazione_ita": "Cassina de' Pecchi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C014",
      "lat": "45.5199717",
      "lon": "9.3644541",
      "superficie_kmq": "7.2095",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015061",
      "denominazione_ita_altra": "Cassinetta di Lugagnano",
      "denominazione_ita": "Cassinetta di Lugagnano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C033",
      "lat": "45.4221330",
      "lon": "8.9068009",
      "superficie_kmq": "3.3175",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015062",
      "denominazione_ita_altra": "Castano Primo",
      "denominazione_ita": "Castano Primo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C052",
      "lat": "45.5512181",
      "lon": "8.7763583",
      "superficie_kmq": "19.1729",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015070",
      "denominazione_ita_altra": "Cernusco sul Naviglio",
      "denominazione_ita": "Cernusco sul Naviglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C523",
      "lat": "45.5242093",
      "lon": "9.3350275",
      "superficie_kmq": "13.2206",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015071",
      "denominazione_ita_altra": "Cerro al Lambro",
      "denominazione_ita": "Cerro al Lambro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C536",
      "lat": "45.3315755",
      "lon": "9.3384644",
      "superficie_kmq": "9.9631",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015072",
      "denominazione_ita_altra": "Cerro Maggiore",
      "denominazione_ita": "Cerro Maggiore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C537",
      "lat": "45.5934519",
      "lon": "8.9507118",
      "superficie_kmq": "10.1212",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015074",
      "denominazione_ita_altra": "Cesano Boscone",
      "denominazione_ita": "Cesano Boscone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C565",
      "lat": "45.4459427",
      "lon": "9.0932735",
      "superficie_kmq": "3.9388",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015076",
      "denominazione_ita_altra": "Cesate",
      "denominazione_ita": "Cesate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C569",
      "lat": "45.5950169",
      "lon": "9.0754417",
      "superficie_kmq": "5.7681",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015077",
      "denominazione_ita_altra": "Cinisello Balsamo",
      "denominazione_ita": "Cinisello Balsamo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C707",
      "lat": "45.5583701",
      "lon": "9.2135737",
      "superficie_kmq": "12.7241",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015078",
      "denominazione_ita_altra": "Cisliano",
      "denominazione_ita": "Cisliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C733",
      "lat": "45.4460499",
      "lon": "8.9860794",
      "superficie_kmq": "14.6764",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015081",
      "denominazione_ita_altra": "Cologno Monzese",
      "denominazione_ita": "Cologno Monzese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C895",
      "lat": "45.5298028",
      "lon": "9.2751666",
      "superficie_kmq": "8.4021",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015082",
      "denominazione_ita_altra": "Colturano",
      "denominazione_ita": "Colturano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C908",
      "lat": "45.3819031",
      "lon": "9.3492293",
      "superficie_kmq": "4.1644",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015085",
      "denominazione_ita_altra": "Corbetta",
      "denominazione_ita": "Corbetta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C986",
      "lat": "45.4675383",
      "lon": "8.9218979",
      "superficie_kmq": "18.6867",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015086",
      "denominazione_ita_altra": "Cormano",
      "denominazione_ita": "Cormano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D013",
      "lat": "45.5430188",
      "lon": "9.1725422",
      "superficie_kmq": "4.4734",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015087",
      "denominazione_ita_altra": "Cornaredo",
      "denominazione_ita": "Cornaredo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D018",
      "lat": "45.5019741",
      "lon": "9.0258315",
      "superficie_kmq": "11.0674",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015093",
      "denominazione_ita_altra": "Corsico",
      "denominazione_ita": "Corsico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D045",
      "lat": "45.4318534",
      "lon": "9.1086310",
      "superficie_kmq": "5.3556",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015096",
      "denominazione_ita_altra": "Cuggiono",
      "denominazione_ita": "Cuggiono",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D198",
      "lat": "45.5069354",
      "lon": "8.8215738",
      "superficie_kmq": "14.9253",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015097",
      "denominazione_ita_altra": "Cusago",
      "denominazione_ita": "Cusago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D229",
      "lat": "45.4466719",
      "lon": "9.0335063",
      "superficie_kmq": "11.4585",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015098",
      "denominazione_ita_altra": "Cusano Milanino",
      "denominazione_ita": "Cusano Milanino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D231",
      "lat": "45.5507396",
      "lon": "9.1815704",
      "superficie_kmq": "3.0817",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015099",
      "denominazione_ita_altra": "Dairago",
      "denominazione_ita": "Dairago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D244",
      "lat": "45.5681113",
      "lon": "8.8661588",
      "superficie_kmq": "5.6355",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015101",
      "denominazione_ita_altra": "Dresano",
      "denominazione_ita": "Dresano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D367",
      "lat": "45.3738734",
      "lon": "9.3612188",
      "superficie_kmq": "3.4790",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015103",
      "denominazione_ita_altra": "Gaggiano",
      "denominazione_ita": "Gaggiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D845",
      "lat": "45.4057216",
      "lon": "9.0345172",
      "superficie_kmq": "26.2559",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015105",
      "denominazione_ita_altra": "Garbagnate Milanese",
      "denominazione_ita": "Garbagnate Milanese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D912",
      "lat": "45.5756135",
      "lon": "9.0774868",
      "superficie_kmq": "8.9131",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015106",
      "denominazione_ita_altra": "Gessate",
      "denominazione_ita": "Gessate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D995",
      "lat": "45.5554925",
      "lon": "9.4367550",
      "superficie_kmq": "7.7593",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015108",
      "denominazione_ita_altra": "Gorgonzola",
      "denominazione_ita": "Gorgonzola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E094",
      "lat": "45.5302681",
      "lon": "9.4054116",
      "superficie_kmq": "10.5801",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015110",
      "denominazione_ita_altra": "Grezzago",
      "denominazione_ita": "Grezzago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E170",
      "lat": "45.5919841",
      "lon": "9.4944242",
      "superficie_kmq": "2.4595",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015112",
      "denominazione_ita_altra": "Gudo Visconti",
      "denominazione_ita": "Gudo Visconti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E258",
      "lat": "45.3732087",
      "lon": "8.9992495",
      "superficie_kmq": "6.1012",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015113",
      "denominazione_ita_altra": "Inveruno",
      "denominazione_ita": "Inveruno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E313",
      "lat": "45.5129505",
      "lon": "8.8536048",
      "superficie_kmq": "12.1348",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015114",
      "denominazione_ita_altra": "Inzago",
      "denominazione_ita": "Inzago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E317",
      "lat": "45.5411539",
      "lon": "9.4822498",
      "superficie_kmq": "12.2054",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015115",
      "denominazione_ita_altra": "Lacchiarella",
      "denominazione_ita": "Lacchiarella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E395",
      "lat": "45.3211788",
      "lon": "9.1373739",
      "superficie_kmq": "24.0401",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015116",
      "denominazione_ita_altra": "Lainate",
      "denominazione_ita": "Lainate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E415",
      "lat": "45.5714627",
      "lon": "9.0271448",
      "superficie_kmq": "12.8701",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015118",
      "denominazione_ita_altra": "Legnano",
      "denominazione_ita": "Legnano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E514",
      "lat": "45.5941442",
      "lon": "8.9185499",
      "superficie_kmq": "17.6970",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015122",
      "denominazione_ita_altra": "Liscate",
      "denominazione_ita": "Liscate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E610",
      "lat": "45.4813278",
      "lon": "9.4094695",
      "superficie_kmq": "9.4068",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015125",
      "denominazione_ita_altra": "Locate di Triulzi",
      "denominazione_ita": "Locate di Triulzi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E639",
      "lat": "45.3576643",
      "lon": "9.2211737",
      "superficie_kmq": "12.6103",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015130",
      "denominazione_ita_altra": "Magenta",
      "denominazione_ita": "Magenta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E801",
      "lat": "45.4661112",
      "lon": "8.8834256",
      "superficie_kmq": "21.9920",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015131",
      "denominazione_ita_altra": "Magnago",
      "denominazione_ita": "Magnago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E819",
      "lat": "45.5806040",
      "lon": "8.8005253",
      "superficie_kmq": "11.2300",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015134",
      "denominazione_ita_altra": "Marcallo con Casone",
      "denominazione_ita": "Marcallo con Casone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E921",
      "lat": "45.4843765",
      "lon": "8.8675014",
      "superficie_kmq": "8.2083",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015136",
      "denominazione_ita_altra": "Masate",
      "denominazione_ita": "Masate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F003",
      "lat": "45.5684287",
      "lon": "9.4665083",
      "superficie_kmq": "4.3852",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015139",
      "denominazione_ita_altra": "Mediglia",
      "denominazione_ita": "Mediglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F084",
      "lat": "45.4012305",
      "lon": "9.3237740",
      "superficie_kmq": "21.9547",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015140",
      "denominazione_ita_altra": "Melegnano",
      "denominazione_ita": "Melegnano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F100",
      "lat": "45.3575343",
      "lon": "9.3242522",
      "superficie_kmq": "4.9985",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015142",
      "denominazione_ita_altra": "Melzo",
      "denominazione_ita": "Melzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F119",
      "lat": "45.4979382",
      "lon": "9.4239803",
      "superficie_kmq": "9.8204",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015144",
      "denominazione_ita_altra": "Mesero",
      "denominazione_ita": "Mesero",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F155",
      "lat": "45.4982741",
      "lon": "8.8538784",
      "superficie_kmq": "5.6397",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015146",
      "denominazione_ita_altra": "Milano",
      "denominazione_ita": "Milano",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "F205",
      "lat": "45.4667993",
      "lon": "9.1903474",
      "superficie_kmq": "181.6783",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015150",
      "denominazione_ita_altra": "Morimondo",
      "denominazione_ita": "Morimondo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D033",
      "lat": "45.3537965",
      "lon": "8.9560782",
      "superficie_kmq": "26.0014",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015151",
      "denominazione_ita_altra": "Motta Visconti",
      "denominazione_ita": "Motta Visconti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F783",
      "lat": "45.2901948",
      "lon": "8.9929980",
      "superficie_kmq": "10.5128",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015154",
      "denominazione_ita_altra": "Nerviano",
      "denominazione_ita": "Nerviano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F874",
      "lat": "45.5539941",
      "lon": "8.9708500",
      "superficie_kmq": "13.2430",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015155",
      "denominazione_ita_altra": "Nosate",
      "denominazione_ita": "Nosate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F939",
      "lat": "45.5507878",
      "lon": "8.7264700",
      "superficie_kmq": "4.8763",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015157",
      "denominazione_ita_altra": "Novate Milanese",
      "denominazione_ita": "Novate Milanese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F955",
      "lat": "45.5328287",
      "lon": "9.1358808",
      "superficie_kmq": "5.4619",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015158",
      "denominazione_ita_altra": "Noviglio",
      "denominazione_ita": "Noviglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F968",
      "lat": "45.3560937",
      "lon": "9.0707831",
      "superficie_kmq": "15.8582",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015159",
      "denominazione_ita_altra": "Opera",
      "denominazione_ita": "Opera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G078",
      "lat": "45.3779144",
      "lon": "9.2114589",
      "superficie_kmq": "7.6385",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015164",
      "denominazione_ita_altra": "Ossona",
      "denominazione_ita": "Ossona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G181",
      "lat": "45.5039944",
      "lon": "8.8945827",
      "superficie_kmq": "5.9834",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015165",
      "denominazione_ita_altra": "Ozzero",
      "denominazione_ita": "Ozzero",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G206",
      "lat": "45.3687765",
      "lon": "8.9229259",
      "superficie_kmq": "10.9731",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015166",
      "denominazione_ita_altra": "Paderno Dugnano",
      "denominazione_ita": "Paderno Dugnano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G220",
      "lat": "45.5685583",
      "lon": "9.1660677",
      "superficie_kmq": "14.1139",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015167",
      "denominazione_ita_altra": "Pantigliate",
      "denominazione_ita": "Pantigliate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G316",
      "lat": "45.4396988",
      "lon": "9.3539288",
      "superficie_kmq": "5.6921",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015168",
      "denominazione_ita_altra": "Parabiago",
      "denominazione_ita": "Parabiago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G324",
      "lat": "45.5580207",
      "lon": "8.9523869",
      "superficie_kmq": "14.3077",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015169",
      "denominazione_ita_altra": "Paullo",
      "denominazione_ita": "Paullo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G385",
      "lat": "45.4170261",
      "lon": "9.3993961",
      "superficie_kmq": "8.8211",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015170",
      "denominazione_ita_altra": "Pero",
      "denominazione_ita": "Pero",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C013",
      "lat": "45.5108107",
      "lon": "9.0890221",
      "superficie_kmq": "4.9782",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015171",
      "denominazione_ita_altra": "Peschiera Borromeo",
      "denominazione_ita": "Peschiera Borromeo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G488",
      "lat": "45.4317734",
      "lon": "9.3110941",
      "superficie_kmq": "23.2212",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015172",
      "denominazione_ita_altra": "Pessano con Bornago",
      "denominazione_ita": "Pessano con Bornago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G502",
      "lat": "45.5502828",
      "lon": "9.3876228",
      "superficie_kmq": "6.6624",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015173",
      "denominazione_ita_altra": "Pieve Emanuele",
      "denominazione_ita": "Pieve Emanuele",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G634",
      "lat": "45.3511822",
      "lon": "9.1996870",
      "superficie_kmq": "12.9138",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015175",
      "denominazione_ita_altra": "Pioltello",
      "denominazione_ita": "Pioltello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G686",
      "lat": "45.4967807",
      "lon": "9.3281379",
      "superficie_kmq": "13.0937",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015176",
      "denominazione_ita_altra": "Pogliano Milanese",
      "denominazione_ita": "Pogliano Milanese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G772",
      "lat": "45.5372635",
      "lon": "8.9935507",
      "superficie_kmq": "4.7848",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015177",
      "denominazione_ita_altra": "Pozzo d'Adda",
      "denominazione_ita": "Pozzo d'Adda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G955",
      "lat": "45.5739554",
      "lon": "9.4995501",
      "superficie_kmq": "4.1648",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015178",
      "denominazione_ita_altra": "Pozzuolo Martesana",
      "denominazione_ita": "Pozzuolo Martesana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G965",
      "lat": "45.5124082",
      "lon": "9.4519954",
      "superficie_kmq": "12.1394",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015179",
      "denominazione_ita_altra": "Pregnana Milanese",
      "denominazione_ita": "Pregnana Milanese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H026",
      "lat": "45.5174436",
      "lon": "9.0100195",
      "superficie_kmq": "5.0744",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015181",
      "denominazione_ita_altra": "Rescaldina",
      "denominazione_ita": "Rescaldina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H240",
      "lat": "45.6165548",
      "lon": "8.9475460",
      "superficie_kmq": "8.0299",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015182",
      "denominazione_ita_altra": "Rho",
      "denominazione_ita": "Rho",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H264",
      "lat": "45.5289120",
      "lon": "9.0402170",
      "superficie_kmq": "22.2431",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015183",
      "denominazione_ita_altra": "Robecchetto con Induno",
      "denominazione_ita": "Robecchetto con Induno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H371",
      "lat": "45.5349511",
      "lon": "8.7629165",
      "superficie_kmq": "13.9280",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015184",
      "denominazione_ita_altra": "Robecco sul Naviglio",
      "denominazione_ita": "Robecco sul Naviglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H373",
      "lat": "45.4375702",
      "lon": "8.8904062",
      "superficie_kmq": "19.7849",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015185",
      "denominazione_ita_altra": "Rodano",
      "denominazione_ita": "Rodano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H470",
      "lat": "45.4731871",
      "lon": "9.3570837",
      "superficie_kmq": "13.0728",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015188",
      "denominazione_ita_altra": "Rosate",
      "denominazione_ita": "Rosate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H560",
      "lat": "45.3483542",
      "lon": "9.0168051",
      "superficie_kmq": "18.6816",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015189",
      "denominazione_ita_altra": "Rozzano",
      "denominazione_ita": "Rozzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H623",
      "lat": "45.3810236",
      "lon": "9.1529270",
      "superficie_kmq": "12.2433",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015191",
      "denominazione_ita_altra": "San Colombano al Lambro",
      "denominazione_ita": "San Colombano al Lambro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H803",
      "lat": "45.1813305",
      "lon": "9.4892504",
      "superficie_kmq": "16.5504",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015192",
      "denominazione_ita_altra": "San Donato Milanese",
      "denominazione_ita": "San Donato Milanese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H827",
      "lat": "45.4169666",
      "lon": "9.2661491",
      "superficie_kmq": "12.8731",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015194",
      "denominazione_ita_altra": "San Giorgio su Legnano",
      "denominazione_ita": "San Giorgio su Legnano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H884",
      "lat": "45.5765403",
      "lon": "8.9164757",
      "superficie_kmq": "2.1680",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015195",
      "denominazione_ita_altra": "San Giuliano Milanese",
      "denominazione_ita": "San Giuliano Milanese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H930",
      "lat": "45.4002326",
      "lon": "9.2849245",
      "superficie_kmq": "30.8676",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015200",
      "denominazione_ita_altra": "Santo Stefano Ticino",
      "denominazione_ita": "Santo Stefano Ticino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I361",
      "lat": "45.4877867",
      "lon": "8.9193522",
      "superficie_kmq": "4.9662",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015201",
      "denominazione_ita_altra": "San Vittore Olona",
      "denominazione_ita": "San Vittore Olona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I409",
      "lat": "45.5876110",
      "lon": "8.9438239",
      "superficie_kmq": "3.4807",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015202",
      "denominazione_ita_altra": "San Zenone al Lambro",
      "denominazione_ita": "San Zenone al Lambro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I415",
      "lat": "45.3264825",
      "lon": "9.3583631",
      "superficie_kmq": "7.2355",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015204",
      "denominazione_ita_altra": "Sedriano",
      "denominazione_ita": "Sedriano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I566",
      "lat": "45.4875610",
      "lon": "8.9708821",
      "superficie_kmq": "7.7527",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015205",
      "denominazione_ita_altra": "Segrate",
      "denominazione_ita": "Segrate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I577",
      "lat": "45.4908297",
      "lon": "9.2929324",
      "superficie_kmq": "17.4936",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015206",
      "denominazione_ita_altra": "Senago",
      "denominazione_ita": "Senago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I602",
      "lat": "45.5752059",
      "lon": "9.1174316",
      "superficie_kmq": "8.5991",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015209",
      "denominazione_ita_altra": "Sesto San Giovanni",
      "denominazione_ita": "Sesto San Giovanni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I690",
      "lat": "45.5335358",
      "lon": "9.2365125",
      "superficie_kmq": "11.6992",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015210",
      "denominazione_ita_altra": "Settala",
      "denominazione_ita": "Settala",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I696",
      "lat": "45.4546902",
      "lon": "9.3868814",
      "superficie_kmq": "17.4236",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015211",
      "denominazione_ita_altra": "Settimo Milanese",
      "denominazione_ita": "Settimo Milanese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I700",
      "lat": "45.4807469",
      "lon": "9.0567744",
      "superficie_kmq": "10.7192",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015213",
      "denominazione_ita_altra": "Solaro",
      "denominazione_ita": "Solaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I786",
      "lat": "45.6167799",
      "lon": "9.0795197",
      "superficie_kmq": "6.6760",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015219",
      "denominazione_ita_altra": "Trezzano Rosa",
      "denominazione_ita": "Trezzano Rosa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L408",
      "lat": "45.5849868",
      "lon": "9.4895411",
      "superficie_kmq": "3.4356",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015220",
      "denominazione_ita_altra": "Trezzano sul Naviglio",
      "denominazione_ita": "Trezzano sul Naviglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L409",
      "lat": "45.4186810",
      "lon": "9.0691358",
      "superficie_kmq": "10.7662",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015221",
      "denominazione_ita_altra": "Trezzo sull'Adda",
      "denominazione_ita": "Trezzo sull'Adda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L411",
      "lat": "45.6101605",
      "lon": "9.5192441",
      "superficie_kmq": "13.0534",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015222",
      "denominazione_ita_altra": "Tribiano",
      "denominazione_ita": "Tribiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L415",
      "lat": "45.4042024",
      "lon": "9.3682631",
      "superficie_kmq": "6.9957",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015224",
      "denominazione_ita_altra": "Truccazzano",
      "denominazione_ita": "Truccazzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L454",
      "lat": "45.4835766",
      "lon": "9.4662620",
      "superficie_kmq": "21.9838",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015226",
      "denominazione_ita_altra": "Turbigo",
      "denominazione_ita": "Turbigo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L471",
      "lat": "45.5284775",
      "lon": "8.7352084",
      "superficie_kmq": "8.5193",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015229",
      "denominazione_ita_altra": "Vanzago",
      "denominazione_ita": "Vanzago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L665",
      "lat": "45.5265009",
      "lon": "9.0006544",
      "superficie_kmq": "6.0535",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015230",
      "denominazione_ita_altra": "Vaprio d'Adda",
      "denominazione_ita": "Vaprio d'Adda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L667",
      "lat": "45.5764651",
      "lon": "9.5334681",
      "superficie_kmq": "7.1545",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015236",
      "denominazione_ita_altra": "Vernate",
      "denominazione_ita": "Vernate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L773",
      "lat": "45.3158837",
      "lon": "9.0604256",
      "superficie_kmq": "14.6541",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015237",
      "denominazione_ita_altra": "Vignate",
      "denominazione_ita": "Vignate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L883",
      "lat": "45.4963739",
      "lon": "9.3770372",
      "superficie_kmq": "8.5527",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015242",
      "denominazione_ita_altra": "Vimodrone",
      "denominazione_ita": "Vimodrone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M053",
      "lat": "45.5143338",
      "lon": "9.2870922",
      "superficie_kmq": "4.7367",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015243",
      "denominazione_ita_altra": "Vittuone",
      "denominazione_ita": "Vittuone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M091",
      "lat": "45.4877796",
      "lon": "8.9533035",
      "superficie_kmq": "6.1334",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015244",
      "denominazione_ita_altra": "Vizzolo Predabissi",
      "denominazione_ita": "Vizzolo Predabissi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M102",
      "lat": "45.3570040",
      "lon": "9.3465762",
      "superficie_kmq": "5.6505",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015247",
      "denominazione_ita_altra": "Zibido San Giacomo",
      "denominazione_ita": "Zibido San Giacomo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M176",
      "lat": "45.3596685",
      "lon": "9.1058771",
      "superficie_kmq": "24.5779",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015248",
      "denominazione_ita_altra": "Villa Cortese",
      "denominazione_ita": "Villa Cortese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L928",
      "lat": "45.5655071",
      "lon": "8.8856896",
      "superficie_kmq": "3.5480",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015249",
      "denominazione_ita_altra": "Vanzaghello",
      "denominazione_ita": "Vanzaghello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L664",
      "lat": "45.5777658",
      "lon": "8.7855560",
      "superficie_kmq": "5.5630",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015250",
      "denominazione_ita_altra": "Baranzate",
      "denominazione_ita": "Baranzate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A618",
      "lat": "45.5273019",
      "lon": "9.1138676",
      "superficie_kmq": "2.7721",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "MI",
      "codice_istat": "015251",
      "denominazione_ita_altra": "Vermezzo con Zelo",
      "denominazione_ita": "Vermezzo con Zelo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M424",
      "lat": "45.3911135",
      "lon": "8.9807739",
      "superficie_kmq": "10.7368",
      "codice_sovracomunale": "215"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016001",
      "denominazione_ita_altra": "Adrara San Martino",
      "denominazione_ita": "Adrara San Martino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A057",
      "lat": "45.6982079",
      "lon": "9.9482498",
      "superficie_kmq": "12.6055",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016002",
      "denominazione_ita_altra": "Adrara San Rocco",
      "denominazione_ita": "Adrara San Rocco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A058",
      "lat": "45.7121816",
      "lon": "9.9577147",
      "superficie_kmq": "9.2249",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016003",
      "denominazione_ita_altra": "Albano Sant'Alessandro",
      "denominazione_ita": "Albano Sant'Alessandro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A129",
      "lat": "45.6864634",
      "lon": "9.7662363",
      "superficie_kmq": "5.3528",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016004",
      "denominazione_ita_altra": "Albino",
      "denominazione_ita": "Albino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A163",
      "lat": "45.7606007",
      "lon": "9.7969346",
      "superficie_kmq": "31.8094",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016005",
      "denominazione_ita_altra": "Almè",
      "denominazione_ita": "Almè",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A214",
      "lat": "45.7400736",
      "lon": "9.6175670",
      "superficie_kmq": "2.0003",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016006",
      "denominazione_ita_altra": "Almenno San Bartolomeo",
      "denominazione_ita": "Almenno San Bartolomeo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A216",
      "lat": "45.7483039",
      "lon": "9.5782692",
      "superficie_kmq": "10.6064",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016007",
      "denominazione_ita_altra": "Almenno San Salvatore",
      "denominazione_ita": "Almenno San Salvatore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A217",
      "lat": "45.7497475",
      "lon": "9.5873847",
      "superficie_kmq": "4.7305",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016008",
      "denominazione_ita_altra": "Alzano Lombardo",
      "denominazione_ita": "Alzano Lombardo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A246",
      "lat": "45.7274799",
      "lon": "9.7258064",
      "superficie_kmq": "13.6844",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016009",
      "denominazione_ita_altra": "Ambivere",
      "denominazione_ita": "Ambivere",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A259",
      "lat": "45.7186028",
      "lon": "9.5472255",
      "superficie_kmq": "3.2756",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016010",
      "denominazione_ita_altra": "Antegnate",
      "denominazione_ita": "Antegnate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A304",
      "lat": "45.4844481",
      "lon": "9.7924514",
      "superficie_kmq": "9.7270",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016011",
      "denominazione_ita_altra": "Arcene",
      "denominazione_ita": "Arcene",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A365",
      "lat": "45.5755201",
      "lon": "9.6136789",
      "superficie_kmq": "4.3472",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016012",
      "denominazione_ita_altra": "Ardesio",
      "denominazione_ita": "Ardesio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A383",
      "lat": "45.9382102",
      "lon": "9.9296492",
      "superficie_kmq": "54.4369",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016013",
      "denominazione_ita_altra": "Arzago d'Adda",
      "denominazione_ita": "Arzago d'Adda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A440",
      "lat": "45.4797058",
      "lon": "9.5642953",
      "superficie_kmq": "9.3077",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016014",
      "denominazione_ita_altra": "Averara",
      "denominazione_ita": "Averara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A511",
      "lat": "45.9885637",
      "lon": "9.6311547",
      "superficie_kmq": "10.6932",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016015",
      "denominazione_ita_altra": "Aviatico",
      "denominazione_ita": "Aviatico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A517",
      "lat": "45.7928152",
      "lon": "9.7689219",
      "superficie_kmq": "8.4898",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016016",
      "denominazione_ita_altra": "Azzano San Paolo",
      "denominazione_ita": "Azzano San Paolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A528",
      "lat": "45.6619238",
      "lon": "9.6763244",
      "superficie_kmq": "4.2922",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016017",
      "denominazione_ita_altra": "Azzone",
      "denominazione_ita": "Azzone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A533",
      "lat": "45.9790397",
      "lon": "10.1128150",
      "superficie_kmq": "17.2892",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016018",
      "denominazione_ita_altra": "Bagnatica",
      "denominazione_ita": "Bagnatica",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A557",
      "lat": "45.6636822",
      "lon": "9.7813789",
      "superficie_kmq": "6.5491",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016019",
      "denominazione_ita_altra": "Barbata",
      "denominazione_ita": "Barbata",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A631",
      "lat": "45.4749114",
      "lon": "9.7762338",
      "superficie_kmq": "7.9806",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016020",
      "denominazione_ita_altra": "Bariano",
      "denominazione_ita": "Bariano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A664",
      "lat": "45.5147841",
      "lon": "9.7070151",
      "superficie_kmq": "7.0711",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016021",
      "denominazione_ita_altra": "Barzana",
      "denominazione_ita": "Barzana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A684",
      "lat": "45.7336542",
      "lon": "9.5670357",
      "superficie_kmq": "2.0744",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016022",
      "denominazione_ita_altra": "Bedulita",
      "denominazione_ita": "Bedulita",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A732",
      "lat": "45.7906501",
      "lon": "9.5509237",
      "superficie_kmq": "4.2680",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016023",
      "denominazione_ita_altra": "Berbenno",
      "denominazione_ita": "Berbenno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A786",
      "lat": "45.8117225",
      "lon": "9.5683236",
      "superficie_kmq": "6.1447",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016024",
      "denominazione_ita_altra": "Bergamo",
      "denominazione_ita": "Bergamo",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "A794",
      "lat": "45.6944223",
      "lon": "9.6684246",
      "superficie_kmq": "40.1515",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016025",
      "denominazione_ita_altra": "Berzo San Fermo",
      "denominazione_ita": "Berzo San Fermo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A815",
      "lat": "45.7181790",
      "lon": "9.9002969",
      "superficie_kmq": "5.8641",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016026",
      "denominazione_ita_altra": "Bianzano",
      "denominazione_ita": "Bianzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A846",
      "lat": "45.7733885",
      "lon": "9.9177880",
      "superficie_kmq": "6.6704",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016027",
      "denominazione_ita_altra": "Blello",
      "denominazione_ita": "Blello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A903",
      "lat": "45.8369285",
      "lon": "9.5643371",
      "superficie_kmq": "2.2008",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016028",
      "denominazione_ita_altra": "Bolgare",
      "denominazione_ita": "Bolgare",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A937",
      "lat": "45.6318669",
      "lon": "9.8202384",
      "superficie_kmq": "8.5910",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016029",
      "denominazione_ita_altra": "Boltiere",
      "denominazione_ita": "Boltiere",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A950",
      "lat": "45.5997836",
      "lon": "9.5782339",
      "superficie_kmq": "4.2082",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016030",
      "denominazione_ita_altra": "Bonate Sopra",
      "denominazione_ita": "Bonate Sopra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A963",
      "lat": "45.6830967",
      "lon": "9.5592365",
      "superficie_kmq": "6.1510",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016031",
      "denominazione_ita_altra": "Bonate Sotto",
      "denominazione_ita": "Bonate Sotto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A962",
      "lat": "45.6663712",
      "lon": "9.5613052",
      "superficie_kmq": "6.4678",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016032",
      "denominazione_ita_altra": "Borgo di Terzo",
      "denominazione_ita": "Borgo di Terzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B010",
      "lat": "45.7209643",
      "lon": "9.8914111",
      "superficie_kmq": "1.8306",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016033",
      "denominazione_ita_altra": "Bossico",
      "denominazione_ita": "Bossico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B083",
      "lat": "45.8270213",
      "lon": "10.0448686",
      "superficie_kmq": "7.0906",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016034",
      "denominazione_ita_altra": "Bottanuco",
      "denominazione_ita": "Bottanuco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B088",
      "lat": "45.6408190",
      "lon": "9.5035943",
      "superficie_kmq": "5.7727",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016035",
      "denominazione_ita_altra": "Bracca",
      "denominazione_ita": "Bracca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B112",
      "lat": "45.8226727",
      "lon": "9.7078135",
      "superficie_kmq": "5.4653",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016036",
      "denominazione_ita_altra": "Branzi",
      "denominazione_ita": "Branzi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B123",
      "lat": "46.0049613",
      "lon": "9.7586803",
      "superficie_kmq": "26.1864",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016037",
      "denominazione_ita_altra": "Brembate",
      "denominazione_ita": "Brembate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B137",
      "lat": "45.6038919",
      "lon": "9.5551626",
      "superficie_kmq": "5.5323",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016038",
      "denominazione_ita_altra": "Brembate di Sopra",
      "denominazione_ita": "Brembate di Sopra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B138",
      "lat": "45.7162874",
      "lon": "9.5775872",
      "superficie_kmq": "4.1390",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016040",
      "denominazione_ita_altra": "Brignano Gera d'Adda",
      "denominazione_ita": "Brignano Gera d'Adda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B178",
      "lat": "45.5445211",
      "lon": "9.6478964",
      "superficie_kmq": "12.1106",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016041",
      "denominazione_ita_altra": "Brumano",
      "denominazione_ita": "Brumano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B217",
      "lat": "45.8546389",
      "lon": "9.5006128",
      "superficie_kmq": "8.1408",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016042",
      "denominazione_ita_altra": "Brusaporto",
      "denominazione_ita": "Brusaporto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B223",
      "lat": "45.6708349",
      "lon": "9.7612609",
      "superficie_kmq": "4.9947",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016043",
      "denominazione_ita_altra": "Calcinate",
      "denominazione_ita": "Calcinate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B393",
      "lat": "45.6197787",
      "lon": "9.8015521",
      "superficie_kmq": "15.0832",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016044",
      "denominazione_ita_altra": "Calcio",
      "denominazione_ita": "Calcio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B395",
      "lat": "45.5076681",
      "lon": "9.8550844",
      "superficie_kmq": "15.6678",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016046",
      "denominazione_ita_altra": "Calusco d'Adda",
      "denominazione_ita": "Calusco d'Adda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B434",
      "lat": "45.6883556",
      "lon": "9.4713848",
      "superficie_kmq": "8.3336",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016047",
      "denominazione_ita_altra": "Calvenzano",
      "denominazione_ita": "Calvenzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B442",
      "lat": "45.4946388",
      "lon": "9.5981576",
      "superficie_kmq": "6.7240",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016048",
      "denominazione_ita_altra": "Camerata Cornello",
      "denominazione_ita": "Camerata Cornello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B471",
      "lat": "45.8995720",
      "lon": "9.6556647",
      "superficie_kmq": "12.9440",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016049",
      "denominazione_ita_altra": "Canonica d'Adda",
      "denominazione_ita": "Canonica d'Adda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B618",
      "lat": "45.5745705",
      "lon": "9.5417590",
      "superficie_kmq": "3.2052",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016050",
      "denominazione_ita_altra": "Capizzone",
      "denominazione_ita": "Capizzone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B661",
      "lat": "45.7845331",
      "lon": "9.5685266",
      "superficie_kmq": "4.6789",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016051",
      "denominazione_ita_altra": "Capriate San Gervasio",
      "denominazione_ita": "Capriate San Gervasio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B703",
      "lat": "45.6091756",
      "lon": "9.5273011",
      "superficie_kmq": "5.7846",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016052",
      "denominazione_ita_altra": "Caprino Bergamasco",
      "denominazione_ita": "Caprino Bergamasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B710",
      "lat": "45.7464772",
      "lon": "9.4815818",
      "superficie_kmq": "8.7838",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016053",
      "denominazione_ita_altra": "Caravaggio",
      "denominazione_ita": "Caravaggio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B731",
      "lat": "45.4971433",
      "lon": "9.6431062",
      "superficie_kmq": "33.3883",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016055",
      "denominazione_ita_altra": "Carobbio degli Angeli",
      "denominazione_ita": "Carobbio degli Angeli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B801",
      "lat": "45.6704645",
      "lon": "9.8286259",
      "superficie_kmq": "6.8209",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016056",
      "denominazione_ita_altra": "Carona",
      "denominazione_ita": "Carona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B803",
      "lat": "46.0218522",
      "lon": "9.7854936",
      "superficie_kmq": "44.1535",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016057",
      "denominazione_ita_altra": "Carvico",
      "denominazione_ita": "Carvico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B854",
      "lat": "45.7068647",
      "lon": "9.4798282",
      "superficie_kmq": "4.5891",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016058",
      "denominazione_ita_altra": "Casazza",
      "denominazione_ita": "Casazza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B947",
      "lat": "45.7515221",
      "lon": "9.9063154",
      "superficie_kmq": "7.1131",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016059",
      "denominazione_ita_altra": "Casirate d'Adda",
      "denominazione_ita": "Casirate d'Adda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B971",
      "lat": "45.4923560",
      "lon": "9.5665997",
      "superficie_kmq": "10.1746",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016060",
      "denominazione_ita_altra": "Casnigo",
      "denominazione_ita": "Casnigo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B978",
      "lat": "45.8163561",
      "lon": "9.8674536",
      "superficie_kmq": "13.6219",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016061",
      "denominazione_ita_altra": "Cassiglio",
      "denominazione_ita": "Cassiglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C007",
      "lat": "45.9668711",
      "lon": "9.6121273",
      "superficie_kmq": "13.6773",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016062",
      "denominazione_ita_altra": "Castelli Calepio",
      "denominazione_ita": "Castelli Calepio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C079",
      "lat": "45.6415344",
      "lon": "9.9022538",
      "superficie_kmq": "10.1528",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016063",
      "denominazione_ita_altra": "Castel Rozzone",
      "denominazione_ita": "Castel Rozzone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C255",
      "lat": "45.5554434",
      "lon": "9.6237377",
      "superficie_kmq": "1.7066",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016064",
      "denominazione_ita_altra": "Castione della Presolana",
      "denominazione_ita": "Castione della Presolana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C324",
      "lat": "45.9077578",
      "lon": "10.0364097",
      "superficie_kmq": "42.5001",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016065",
      "denominazione_ita_altra": "Castro",
      "denominazione_ita": "Castro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C337",
      "lat": "45.8038140",
      "lon": "10.0687741",
      "superficie_kmq": "2.5891",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016066",
      "denominazione_ita_altra": "Cavernago",
      "denominazione_ita": "Cavernago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C396",
      "lat": "45.6238105",
      "lon": "9.7637336",
      "superficie_kmq": "7.6516",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016067",
      "denominazione_ita_altra": "Cazzano Sant'Andrea",
      "denominazione_ita": "Cazzano Sant'Andrea",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C410",
      "lat": "45.8112321",
      "lon": "9.8822313",
      "superficie_kmq": "2.0244",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016068",
      "denominazione_ita_altra": "Cenate Sopra",
      "denominazione_ita": "Cenate Sopra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C456",
      "lat": "45.7117527",
      "lon": "9.8241759",
      "superficie_kmq": "6.9724",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016069",
      "denominazione_ita_altra": "Cenate Sotto",
      "denominazione_ita": "Cenate Sotto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C457",
      "lat": "45.6984360",
      "lon": "9.8239545",
      "superficie_kmq": "4.6167",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016070",
      "denominazione_ita_altra": "Cene",
      "denominazione_ita": "Cene",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C459",
      "lat": "45.7794424",
      "lon": "9.8255721",
      "superficie_kmq": "8.5992",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016071",
      "denominazione_ita_altra": "Cerete",
      "denominazione_ita": "Cerete",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C506",
      "lat": "45.8677010",
      "lon": "9.9949749",
      "superficie_kmq": "14.0705",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016072",
      "denominazione_ita_altra": "Chignolo d'Isola",
      "denominazione_ita": "Chignolo d'Isola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C635",
      "lat": "45.6651336",
      "lon": "9.5272274",
      "superficie_kmq": "5.5456",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016073",
      "denominazione_ita_altra": "Chiuduno",
      "denominazione_ita": "Chiuduno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C649",
      "lat": "45.6469105",
      "lon": "9.8537626",
      "superficie_kmq": "6.8847",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016074",
      "denominazione_ita_altra": "Cisano Bergamasco",
      "denominazione_ita": "Cisano Bergamasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C728",
      "lat": "45.7423945",
      "lon": "9.4743616",
      "superficie_kmq": "7.8160",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016075",
      "denominazione_ita_altra": "Ciserano",
      "denominazione_ita": "Ciserano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C730",
      "lat": "45.5866775",
      "lon": "9.6007264",
      "superficie_kmq": "5.3074",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016076",
      "denominazione_ita_altra": "Cividate al Piano",
      "denominazione_ita": "Cividate al Piano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C759",
      "lat": "45.5542635",
      "lon": "9.8297952",
      "superficie_kmq": "9.7280",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016077",
      "denominazione_ita_altra": "Clusone",
      "denominazione_ita": "Clusone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C800",
      "lat": "45.8915811",
      "lon": "9.9469733",
      "superficie_kmq": "26.1901",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016078",
      "denominazione_ita_altra": "Colere",
      "denominazione_ita": "Colere",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C835",
      "lat": "45.9739150",
      "lon": "10.0857222",
      "superficie_kmq": "18.6268",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016079",
      "denominazione_ita_altra": "Cologno al Serio",
      "denominazione_ita": "Cologno al Serio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C894",
      "lat": "45.5794165",
      "lon": "9.7061020",
      "superficie_kmq": "18.5187",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016080",
      "denominazione_ita_altra": "Colzate",
      "denominazione_ita": "Colzate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C910",
      "lat": "45.8159686",
      "lon": "9.8563115",
      "superficie_kmq": "6.7485",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016081",
      "denominazione_ita_altra": "Comun Nuovo",
      "denominazione_ita": "Comun Nuovo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C937",
      "lat": "45.6232583",
      "lon": "9.6647366",
      "superficie_kmq": "6.4452",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016082",
      "denominazione_ita_altra": "Corna Imagna",
      "denominazione_ita": "Corna Imagna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D015",
      "lat": "45.8315354",
      "lon": "9.5444752",
      "superficie_kmq": "4.5017",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016083",
      "denominazione_ita_altra": "Cortenuova",
      "denominazione_ita": "Cortenuova",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D066",
      "lat": "45.5386316",
      "lon": "9.7857977",
      "superficie_kmq": "7.3490",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016084",
      "denominazione_ita_altra": "Costa di Mezzate",
      "denominazione_ita": "Costa di Mezzate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D110",
      "lat": "45.6629362",
      "lon": "9.7944556",
      "superficie_kmq": "5.2228",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016085",
      "denominazione_ita_altra": "Costa Valle Imagna",
      "denominazione_ita": "Costa Valle Imagna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D103",
      "lat": "45.8008051",
      "lon": "9.5056613",
      "superficie_kmq": "4.2120",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016086",
      "denominazione_ita_altra": "Costa Volpino",
      "denominazione_ita": "Costa Volpino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D117",
      "lat": "45.8279528",
      "lon": "10.0939934",
      "superficie_kmq": "18.6664",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016087",
      "denominazione_ita_altra": "Covo",
      "denominazione_ita": "Covo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D126",
      "lat": "45.5011377",
      "lon": "9.7685432",
      "superficie_kmq": "12.9435",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016088",
      "denominazione_ita_altra": "Credaro",
      "denominazione_ita": "Credaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D139",
      "lat": "45.6584568",
      "lon": "9.9290128",
      "superficie_kmq": "3.4135",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016089",
      "denominazione_ita_altra": "Curno",
      "denominazione_ita": "Curno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D221",
      "lat": "45.6915509",
      "lon": "9.6113226",
      "superficie_kmq": "4.7049",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016090",
      "denominazione_ita_altra": "Cusio",
      "denominazione_ita": "Cusio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D233",
      "lat": "45.9894438",
      "lon": "9.6034790",
      "superficie_kmq": "9.4071",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016091",
      "denominazione_ita_altra": "Dalmine",
      "denominazione_ita": "Dalmine",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D245",
      "lat": "45.6479735",
      "lon": "9.6011735",
      "superficie_kmq": "11.8075",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016092",
      "denominazione_ita_altra": "Dossena",
      "denominazione_ita": "Dossena",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D352",
      "lat": "45.8791838",
      "lon": "9.6969651",
      "superficie_kmq": "19.5582",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016093",
      "denominazione_ita_altra": "Endine Gaiano",
      "denominazione_ita": "Endine Gaiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D406",
      "lat": "45.7901961",
      "lon": "9.9764933",
      "superficie_kmq": "21.0690",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016094",
      "denominazione_ita_altra": "Entratico",
      "denominazione_ita": "Entratico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D411",
      "lat": "45.7054922",
      "lon": "9.8747656",
      "superficie_kmq": "4.1452",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016096",
      "denominazione_ita_altra": "Fara Gera d'Adda",
      "denominazione_ita": "Fara Gera d'Adda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D490",
      "lat": "45.5508704",
      "lon": "9.5307477",
      "superficie_kmq": "10.7926",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016097",
      "denominazione_ita_altra": "Fara Olivana con Sola",
      "denominazione_ita": "Fara Olivana con Sola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D491",
      "lat": "45.4951677",
      "lon": "9.7497397",
      "superficie_kmq": "5.0407",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016098",
      "denominazione_ita_altra": "Filago",
      "denominazione_ita": "Filago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D588",
      "lat": "45.6362776",
      "lon": "9.5538530",
      "superficie_kmq": "5.4233",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016099",
      "denominazione_ita_altra": "Fino del Monte",
      "denominazione_ita": "Fino del Monte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D604",
      "lat": "45.8904648",
      "lon": "9.9921548",
      "superficie_kmq": "4.2877",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016100",
      "denominazione_ita_altra": "Fiorano al Serio",
      "denominazione_ita": "Fiorano al Serio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D606",
      "lat": "45.7994930",
      "lon": "9.8416639",
      "superficie_kmq": "1.0601",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016101",
      "denominazione_ita_altra": "Fontanella",
      "denominazione_ita": "Fontanella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D672",
      "lat": "45.4722282",
      "lon": "9.8004476",
      "superficie_kmq": "17.7970",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016102",
      "denominazione_ita_altra": "Fonteno",
      "denominazione_ita": "Fonteno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D684",
      "lat": "45.7578737",
      "lon": "10.0192157",
      "superficie_kmq": "10.9290",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016103",
      "denominazione_ita_altra": "Foppolo",
      "denominazione_ita": "Foppolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D688",
      "lat": "46.0439550",
      "lon": "9.7549792",
      "superficie_kmq": "16.1428",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016104",
      "denominazione_ita_altra": "Foresto Sparso",
      "denominazione_ita": "Foresto Sparso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D697",
      "lat": "45.6951209",
      "lon": "9.9136789",
      "superficie_kmq": "7.8683",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016105",
      "denominazione_ita_altra": "Fornovo San Giovanni",
      "denominazione_ita": "Fornovo San Giovanni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D727",
      "lat": "45.4961536",
      "lon": "9.6751027",
      "superficie_kmq": "7.0448",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016106",
      "denominazione_ita_altra": "Fuipiano Valle Imagna",
      "denominazione_ita": "Fuipiano Valle Imagna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D817",
      "lat": "45.8571130",
      "lon": "9.5249739",
      "superficie_kmq": "4.2797",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016107",
      "denominazione_ita_altra": "Gandellino",
      "denominazione_ita": "Gandellino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D903",
      "lat": "45.9906695",
      "lon": "9.9459534",
      "superficie_kmq": "25.1342",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016108",
      "denominazione_ita_altra": "Gandino",
      "denominazione_ita": "Gandino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D905",
      "lat": "45.8112992",
      "lon": "9.9040051",
      "superficie_kmq": "29.0319",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016109",
      "denominazione_ita_altra": "Gandosso",
      "denominazione_ita": "Gandosso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D906",
      "lat": "45.6623686",
      "lon": "9.8898964",
      "superficie_kmq": "3.1267",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016110",
      "denominazione_ita_altra": "Gaverina Terme",
      "denominazione_ita": "Gaverina Terme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D943",
      "lat": "45.7583512",
      "lon": "9.8861576",
      "superficie_kmq": "5.1962",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016111",
      "denominazione_ita_altra": "Gazzaniga",
      "denominazione_ita": "Gazzaniga",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D952",
      "lat": "45.7974417",
      "lon": "9.8357261",
      "superficie_kmq": "14.4134",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016113",
      "denominazione_ita_altra": "Ghisalba",
      "denominazione_ita": "Ghisalba",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E006",
      "lat": "45.5916269",
      "lon": "9.7544351",
      "superficie_kmq": "10.5881",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016114",
      "denominazione_ita_altra": "Gorlago",
      "denominazione_ita": "Gorlago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E100",
      "lat": "45.6765405",
      "lon": "9.8291697",
      "superficie_kmq": "5.6976",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016115",
      "denominazione_ita_altra": "Gorle",
      "denominazione_ita": "Gorle",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E103",
      "lat": "45.7024374",
      "lon": "9.7187797",
      "superficie_kmq": "2.5200",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016116",
      "denominazione_ita_altra": "Gorno",
      "denominazione_ita": "Gorno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E106",
      "lat": "45.8628120",
      "lon": "9.8434004",
      "superficie_kmq": "10.0000",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016117",
      "denominazione_ita_altra": "Grassobbio",
      "denominazione_ita": "Grassobbio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E148",
      "lat": "45.6548477",
      "lon": "9.7277015",
      "superficie_kmq": "8.7413",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016118",
      "denominazione_ita_altra": "Gromo",
      "denominazione_ita": "Gromo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E189",
      "lat": "45.9703982",
      "lon": "9.9299019",
      "superficie_kmq": "20.0692",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016119",
      "denominazione_ita_altra": "Grone",
      "denominazione_ita": "Grone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E192",
      "lat": "45.7272216",
      "lon": "9.9088218",
      "superficie_kmq": "7.7773",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016120",
      "denominazione_ita_altra": "Grumello del Monte",
      "denominazione_ita": "Grumello del Monte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E219",
      "lat": "45.6340243",
      "lon": "9.8697713",
      "superficie_kmq": "9.9408",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016121",
      "denominazione_ita_altra": "Isola di Fondra",
      "denominazione_ita": "Isola di Fondra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E353",
      "lat": "45.9808019",
      "lon": "9.7475073",
      "superficie_kmq": "12.8327",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016122",
      "denominazione_ita_altra": "Isso",
      "denominazione_ita": "Isso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E370",
      "lat": "45.4763004",
      "lon": "9.7589750",
      "superficie_kmq": "5.0586",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016123",
      "denominazione_ita_altra": "Lallio",
      "denominazione_ita": "Lallio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E422",
      "lat": "45.6673243",
      "lon": "9.6268813",
      "superficie_kmq": "2.1560",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016124",
      "denominazione_ita_altra": "Leffe",
      "denominazione_ita": "Leffe",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E509",
      "lat": "45.7998054",
      "lon": "9.8852564",
      "superficie_kmq": "6.6909",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016125",
      "denominazione_ita_altra": "Lenna",
      "denominazione_ita": "Lenna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E524",
      "lat": "45.9431966",
      "lon": "9.6773027",
      "superficie_kmq": "12.7383",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016126",
      "denominazione_ita_altra": "Levate",
      "denominazione_ita": "Levate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E562",
      "lat": "45.6252312",
      "lon": "9.6244717",
      "superficie_kmq": "5.5314",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016127",
      "denominazione_ita_altra": "Locatello",
      "denominazione_ita": "Locatello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E640",
      "lat": "45.8322310",
      "lon": "9.5325495",
      "superficie_kmq": "3.7896",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016128",
      "denominazione_ita_altra": "Lovere",
      "denominazione_ita": "Lovere",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E704",
      "lat": "45.8128959",
      "lon": "10.0716014",
      "superficie_kmq": "7.9247",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016129",
      "denominazione_ita_altra": "Lurano",
      "denominazione_ita": "Lurano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E751",
      "lat": "45.5650345",
      "lon": "9.6394422",
      "superficie_kmq": "4.0469",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016130",
      "denominazione_ita_altra": "Luzzana",
      "denominazione_ita": "Luzzana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E770",
      "lat": "45.7159874",
      "lon": "9.8798652",
      "superficie_kmq": "3.4846",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016131",
      "denominazione_ita_altra": "Madone",
      "denominazione_ita": "Madone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E794",
      "lat": "45.6520037",
      "lon": "9.5499489",
      "superficie_kmq": "3.0689",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016132",
      "denominazione_ita_altra": "Mapello",
      "denominazione_ita": "Mapello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E901",
      "lat": "45.7094071",
      "lon": "9.5457327",
      "superficie_kmq": "8.6573",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016133",
      "denominazione_ita_altra": "Martinengo",
      "denominazione_ita": "Martinengo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E987",
      "lat": "45.5716798",
      "lon": "9.7673690",
      "superficie_kmq": "22.0512",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016134",
      "denominazione_ita_altra": "Mezzoldo",
      "denominazione_ita": "Mezzoldo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F186",
      "lat": "46.0122180",
      "lon": "9.6656686",
      "superficie_kmq": "18.8383",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016135",
      "denominazione_ita_altra": "Misano di Gera d'Adda",
      "denominazione_ita": "Misano di Gera d'Adda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F243",
      "lat": "45.4685564",
      "lon": "9.6205996",
      "superficie_kmq": "6.1053",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016136",
      "denominazione_ita_altra": "Moio de' Calvi",
      "denominazione_ita": "Moio de' Calvi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F276",
      "lat": "45.9514541",
      "lon": "9.7005843",
      "superficie_kmq": "6.3564",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016137",
      "denominazione_ita_altra": "Monasterolo del Castello",
      "denominazione_ita": "Monasterolo del Castello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F328",
      "lat": "45.7632708",
      "lon": "9.9313560",
      "superficie_kmq": "8.7535",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016139",
      "denominazione_ita_altra": "Montello",
      "denominazione_ita": "Montello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F547",
      "lat": "45.6709202",
      "lon": "9.8050650",
      "superficie_kmq": "1.8156",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016140",
      "denominazione_ita_altra": "Morengo",
      "denominazione_ita": "Morengo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F720",
      "lat": "45.5320083",
      "lon": "9.7022098",
      "superficie_kmq": "9.5662",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016141",
      "denominazione_ita_altra": "Mornico al Serio",
      "denominazione_ita": "Mornico al Serio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F738",
      "lat": "45.5905561",
      "lon": "9.8087122",
      "superficie_kmq": "6.9226",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016142",
      "denominazione_ita_altra": "Mozzanica",
      "denominazione_ita": "Mozzanica",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F786",
      "lat": "45.4771014",
      "lon": "9.6937561",
      "superficie_kmq": "9.4646",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016143",
      "denominazione_ita_altra": "Mozzo",
      "denominazione_ita": "Mozzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F791",
      "lat": "45.7001858",
      "lon": "9.6090251",
      "superficie_kmq": "3.6417",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016144",
      "denominazione_ita_altra": "Nembro",
      "denominazione_ita": "Nembro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F864",
      "lat": "45.7435425",
      "lon": "9.7589265",
      "superficie_kmq": "15.2348",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016145",
      "denominazione_ita_altra": "Olmo al Brembo",
      "denominazione_ita": "Olmo al Brembo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G049",
      "lat": "45.9695499",
      "lon": "9.6489395",
      "superficie_kmq": "7.9033",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016146",
      "denominazione_ita_altra": "Oltre il Colle",
      "denominazione_ita": "Oltre il Colle",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G050",
      "lat": "45.8927360",
      "lon": "9.7668433",
      "superficie_kmq": "32.8919",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016147",
      "denominazione_ita_altra": "Oltressenda Alta",
      "denominazione_ita": "Oltressenda Alta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G054",
      "lat": "45.9144942",
      "lon": "9.9442164",
      "superficie_kmq": "17.3262",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016148",
      "denominazione_ita_altra": "Oneta",
      "denominazione_ita": "Oneta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G068",
      "lat": "45.8714357",
      "lon": "9.8192769",
      "superficie_kmq": "18.6636",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016149",
      "denominazione_ita_altra": "Onore",
      "denominazione_ita": "Onore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G075",
      "lat": "45.8925905",
      "lon": "10.0099781",
      "superficie_kmq": "11.7820",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016150",
      "denominazione_ita_altra": "Orio al Serio",
      "denominazione_ita": "Orio al Serio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G108",
      "lat": "45.6757413",
      "lon": "9.6915135",
      "superficie_kmq": "3.0417",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016151",
      "denominazione_ita_altra": "Ornica",
      "denominazione_ita": "Ornica",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G118",
      "lat": "45.9889253",
      "lon": "9.5784550",
      "superficie_kmq": "15.0963",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016152",
      "denominazione_ita_altra": "Osio Sopra",
      "denominazione_ita": "Osio Sopra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G159",
      "lat": "45.6297797",
      "lon": "9.5919246",
      "superficie_kmq": "5.1822",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016153",
      "denominazione_ita_altra": "Osio Sotto",
      "denominazione_ita": "Osio Sotto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G160",
      "lat": "45.6161896",
      "lon": "9.5927084",
      "superficie_kmq": "7.5856",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016154",
      "denominazione_ita_altra": "Pagazzano",
      "denominazione_ita": "Pagazzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G233",
      "lat": "45.5336003",
      "lon": "9.6733149",
      "superficie_kmq": "5.2349",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016155",
      "denominazione_ita_altra": "Paladina",
      "denominazione_ita": "Paladina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G249",
      "lat": "45.7272197",
      "lon": "9.6063799",
      "superficie_kmq": "2.0866",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016156",
      "denominazione_ita_altra": "Palazzago",
      "denominazione_ita": "Palazzago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G259",
      "lat": "45.7512358",
      "lon": "9.5373058",
      "superficie_kmq": "13.9600",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016157",
      "denominazione_ita_altra": "Palosco",
      "denominazione_ita": "Palosco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G295",
      "lat": "45.5834819",
      "lon": "9.8378742",
      "superficie_kmq": "10.7937",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016158",
      "denominazione_ita_altra": "Parre",
      "denominazione_ita": "Parre",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G346",
      "lat": "45.8770799",
      "lon": "9.8918976",
      "superficie_kmq": "22.2794",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016159",
      "denominazione_ita_altra": "Parzanica",
      "denominazione_ita": "Parzanica",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G350",
      "lat": "45.7381206",
      "lon": "10.0358986",
      "superficie_kmq": "9.8187",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016160",
      "denominazione_ita_altra": "Pedrengo",
      "denominazione_ita": "Pedrengo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G412",
      "lat": "45.6984384",
      "lon": "9.7341356",
      "superficie_kmq": "3.5999",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016161",
      "denominazione_ita_altra": "Peia",
      "denominazione_ita": "Peia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G418",
      "lat": "45.7996841",
      "lon": "9.8970757",
      "superficie_kmq": "4.4799",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016162",
      "denominazione_ita_altra": "Pianico",
      "denominazione_ita": "Pianico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G564",
      "lat": "45.8110056",
      "lon": "10.0435365",
      "superficie_kmq": "2.7022",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016163",
      "denominazione_ita_altra": "Piario",
      "denominazione_ita": "Piario",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G574",
      "lat": "45.8959754",
      "lon": "9.9264393",
      "superficie_kmq": "1.5451",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016164",
      "denominazione_ita_altra": "Piazza Brembana",
      "denominazione_ita": "Piazza Brembana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G579",
      "lat": "45.9463278",
      "lon": "9.6773500",
      "superficie_kmq": "6.7653",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016165",
      "denominazione_ita_altra": "Piazzatorre",
      "denominazione_ita": "Piazzatorre",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G583",
      "lat": "45.9900254",
      "lon": "9.6855722",
      "superficie_kmq": "24.2379",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016166",
      "denominazione_ita_altra": "Piazzolo",
      "denominazione_ita": "Piazzolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G588",
      "lat": "45.9790870",
      "lon": "9.6722811",
      "superficie_kmq": "4.1521",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016167",
      "denominazione_ita_altra": "Pognano",
      "denominazione_ita": "Pognano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G774",
      "lat": "45.5859076",
      "lon": "9.6419948",
      "superficie_kmq": "3.2861",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016168",
      "denominazione_ita_altra": "Ponte Nossa",
      "denominazione_ita": "Ponte Nossa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F941",
      "lat": "45.8695540",
      "lon": "9.8873956",
      "superficie_kmq": "5.5898",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016169",
      "denominazione_ita_altra": "Ponteranica",
      "denominazione_ita": "Ponteranica",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G853",
      "lat": "45.7307462",
      "lon": "9.6547592",
      "superficie_kmq": "8.4769",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016170",
      "denominazione_ita_altra": "Ponte San Pietro",
      "denominazione_ita": "Ponte San Pietro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G856",
      "lat": "45.6989982",
      "lon": "9.5869043",
      "superficie_kmq": "4.5861",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016171",
      "denominazione_ita_altra": "Pontida",
      "denominazione_ita": "Pontida",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G864",
      "lat": "45.7314869",
      "lon": "9.5004532",
      "superficie_kmq": "10.3776",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016172",
      "denominazione_ita_altra": "Pontirolo Nuovo",
      "denominazione_ita": "Pontirolo Nuovo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G867",
      "lat": "45.5686792",
      "lon": "9.5678078",
      "superficie_kmq": "11.0976",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016173",
      "denominazione_ita_altra": "Pradalunga",
      "denominazione_ita": "Pradalunga",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G968",
      "lat": "45.7479758",
      "lon": "9.7843275",
      "superficie_kmq": "8.1982",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016174",
      "denominazione_ita_altra": "Predore",
      "denominazione_ita": "Predore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H020",
      "lat": "45.6806716",
      "lon": "10.0195040",
      "superficie_kmq": "10.9556",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016175",
      "denominazione_ita_altra": "Premolo",
      "denominazione_ita": "Premolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H036",
      "lat": "45.8686072",
      "lon": "9.8753350",
      "superficie_kmq": "17.6248",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016176",
      "denominazione_ita_altra": "Presezzo",
      "denominazione_ita": "Presezzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H046",
      "lat": "45.6916308",
      "lon": "9.5667055",
      "superficie_kmq": "2.2818",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016177",
      "denominazione_ita_altra": "Pumenengo",
      "denominazione_ita": "Pumenengo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H091",
      "lat": "45.4803072",
      "lon": "9.8677251",
      "superficie_kmq": "10.1151",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016178",
      "denominazione_ita_altra": "Ranica",
      "denominazione_ita": "Ranica",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H176",
      "lat": "45.7269735",
      "lon": "9.7146052",
      "superficie_kmq": "4.0613",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016179",
      "denominazione_ita_altra": "Ranzanico",
      "denominazione_ita": "Ranzanico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H177",
      "lat": "45.7886146",
      "lon": "9.9361243",
      "superficie_kmq": "7.2137",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016180",
      "denominazione_ita_altra": "Riva di Solto",
      "denominazione_ita": "Riva di Solto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H331",
      "lat": "45.7741847",
      "lon": "10.0393837",
      "superficie_kmq": "8.5213",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016182",
      "denominazione_ita_altra": "Rogno",
      "denominazione_ita": "Rogno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H492",
      "lat": "45.8578311",
      "lon": "10.1334397",
      "superficie_kmq": "15.8105",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016183",
      "denominazione_ita_altra": "Romano di Lombardia",
      "denominazione_ita": "Romano di Lombardia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H509",
      "lat": "45.5194573",
      "lon": "9.7531694",
      "superficie_kmq": "19.3831",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016184",
      "denominazione_ita_altra": "Roncobello",
      "denominazione_ita": "Roncobello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H535",
      "lat": "45.9564504",
      "lon": "9.7478239",
      "superficie_kmq": "25.3866",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016185",
      "denominazione_ita_altra": "Roncola",
      "denominazione_ita": "Roncola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H544",
      "lat": "45.7692600",
      "lon": "9.5610290",
      "superficie_kmq": "5.0675",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016186",
      "denominazione_ita_altra": "Rota d'Imagna",
      "denominazione_ita": "Rota d'Imagna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H584",
      "lat": "45.8341585",
      "lon": "9.5115283",
      "superficie_kmq": "6.0302",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016187",
      "denominazione_ita_altra": "Rovetta",
      "denominazione_ita": "Rovetta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H615",
      "lat": "45.8911228",
      "lon": "9.9849028",
      "superficie_kmq": "24.5339",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016188",
      "denominazione_ita_altra": "San Giovanni Bianco",
      "denominazione_ita": "San Giovanni Bianco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H910",
      "lat": "45.8745760",
      "lon": "9.6518233",
      "superficie_kmq": "31.0260",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016189",
      "denominazione_ita_altra": "San Paolo d'Argon",
      "denominazione_ita": "San Paolo d'Argon",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B310",
      "lat": "45.6876437",
      "lon": "9.8017486",
      "superficie_kmq": "5.2465",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016190",
      "denominazione_ita_altra": "San Pellegrino Terme",
      "denominazione_ita": "San Pellegrino Terme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I079",
      "lat": "45.8383439",
      "lon": "9.6633055",
      "superficie_kmq": "22.9533",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016191",
      "denominazione_ita_altra": "Santa Brigida",
      "denominazione_ita": "Santa Brigida",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I168",
      "lat": "45.9812972",
      "lon": "9.6234369",
      "superficie_kmq": "13.8036",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016193",
      "denominazione_ita_altra": "Sarnico",
      "denominazione_ita": "Sarnico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I437",
      "lat": "45.6680063",
      "lon": "9.9583532",
      "superficie_kmq": "6.6621",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016194",
      "denominazione_ita_altra": "Scanzorosciate",
      "denominazione_ita": "Scanzorosciate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I506",
      "lat": "45.7118778",
      "lon": "9.7367965",
      "superficie_kmq": "10.6892",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016195",
      "denominazione_ita_altra": "Schilpario",
      "denominazione_ita": "Schilpario",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I530",
      "lat": "46.0095781",
      "lon": "10.1545159",
      "superficie_kmq": "63.9693",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016196",
      "denominazione_ita_altra": "Sedrina",
      "denominazione_ita": "Sedrina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I567",
      "lat": "45.7799251",
      "lon": "9.6234583",
      "superficie_kmq": "5.8461",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016197",
      "denominazione_ita_altra": "Selvino",
      "denominazione_ita": "Selvino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I597",
      "lat": "45.7807583",
      "lon": "9.7501274",
      "superficie_kmq": "6.5279",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016198",
      "denominazione_ita_altra": "Seriate",
      "denominazione_ita": "Seriate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I628",
      "lat": "45.6835442",
      "lon": "9.7176423",
      "superficie_kmq": "12.5348",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016199",
      "denominazione_ita_altra": "Serina",
      "denominazione_ita": "Serina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I629",
      "lat": "45.8713949",
      "lon": "9.7300916",
      "superficie_kmq": "27.3384",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016200",
      "denominazione_ita_altra": "Solto Collina",
      "denominazione_ita": "Solto Collina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I812",
      "lat": "45.7822496",
      "lon": "10.0259289",
      "superficie_kmq": "11.7570",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016201",
      "denominazione_ita_altra": "Songavazzo",
      "denominazione_ita": "Songavazzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I830",
      "lat": "45.8809276",
      "lon": "9.9898181",
      "superficie_kmq": "12.9447",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016202",
      "denominazione_ita_altra": "Sorisole",
      "denominazione_ita": "Sorisole",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I858",
      "lat": "45.7447495",
      "lon": "9.6625371",
      "superficie_kmq": "12.2515",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016203",
      "denominazione_ita_altra": "Sotto il Monte Giovanni XXIII",
      "denominazione_ita": "Sotto il Monte Giovanni XXIII",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I869",
      "lat": "45.7057066",
      "lon": "9.4988747",
      "superficie_kmq": "5.0233",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016204",
      "denominazione_ita_altra": "Sovere",
      "denominazione_ita": "Sovere",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I873",
      "lat": "45.8241668",
      "lon": "10.0255270",
      "superficie_kmq": "18.0243",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016205",
      "denominazione_ita_altra": "Spinone al Lago",
      "denominazione_ita": "Spinone al Lago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I916",
      "lat": "45.7647697",
      "lon": "9.9215426",
      "superficie_kmq": "1.9736",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016206",
      "denominazione_ita_altra": "Spirano",
      "denominazione_ita": "Spirano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I919",
      "lat": "45.5821794",
      "lon": "9.6709873",
      "superficie_kmq": "9.6107",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016207",
      "denominazione_ita_altra": "Stezzano",
      "denominazione_ita": "Stezzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I951",
      "lat": "45.6497646",
      "lon": "9.6510249",
      "superficie_kmq": "9.3738",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016208",
      "denominazione_ita_altra": "Strozza",
      "denominazione_ita": "Strozza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I986",
      "lat": "45.7742571",
      "lon": "9.5778599",
      "superficie_kmq": "3.8250",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016209",
      "denominazione_ita_altra": "Suisio",
      "denominazione_ita": "Suisio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I997",
      "lat": "45.6597980",
      "lon": "9.5012742",
      "superficie_kmq": "4.5411",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016210",
      "denominazione_ita_altra": "Taleggio",
      "denominazione_ita": "Taleggio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L037",
      "lat": "45.8940416",
      "lon": "9.5648084",
      "superficie_kmq": "47.1257",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016211",
      "denominazione_ita_altra": "Tavernola Bergamasca",
      "denominazione_ita": "Tavernola Bergamasca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L073",
      "lat": "45.7092326",
      "lon": "10.0453289",
      "superficie_kmq": "11.1708",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016212",
      "denominazione_ita_altra": "Telgate",
      "denominazione_ita": "Telgate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L087",
      "lat": "45.6273016",
      "lon": "9.8534241",
      "superficie_kmq": "8.3026",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016213",
      "denominazione_ita_altra": "Terno d'Isola",
      "denominazione_ita": "Terno d'Isola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L118",
      "lat": "45.6836078",
      "lon": "9.5314333",
      "superficie_kmq": "4.1331",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016214",
      "denominazione_ita_altra": "Torre Boldone",
      "denominazione_ita": "Torre Boldone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L251",
      "lat": "45.7160993",
      "lon": "9.7077326",
      "superficie_kmq": "3.4836",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016215",
      "denominazione_ita_altra": "Torre de' Busi",
      "denominazione_ita": "Torre de' Busi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L257",
      "lat": "45.7805900",
      "lon": "9.4849905",
      "superficie_kmq": "8.9668",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016216",
      "denominazione_ita_altra": "Torre de' Roveri",
      "denominazione_ita": "Torre de' Roveri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L265",
      "lat": "45.6995195",
      "lon": "9.7722071",
      "superficie_kmq": "2.6902",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016217",
      "denominazione_ita_altra": "Torre Pallavicina",
      "denominazione_ita": "Torre Pallavicina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L276",
      "lat": "45.4414628",
      "lon": "9.8626253",
      "superficie_kmq": "10.6241",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016218",
      "denominazione_ita_altra": "Trescore Balneario",
      "denominazione_ita": "Trescore Balneario",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L388",
      "lat": "45.6950970",
      "lon": "9.8427744",
      "superficie_kmq": "13.5138",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016219",
      "denominazione_ita_altra": "Treviglio",
      "denominazione_ita": "Treviglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L400",
      "lat": "45.5217708",
      "lon": "9.5926500",
      "superficie_kmq": "32.2198",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016220",
      "denominazione_ita_altra": "Treviolo",
      "denominazione_ita": "Treviolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L404",
      "lat": "45.6716265",
      "lon": "9.6112711",
      "superficie_kmq": "8.4874",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016221",
      "denominazione_ita_altra": "Ubiale Clanezzo",
      "denominazione_ita": "Ubiale Clanezzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C789",
      "lat": "45.7834034",
      "lon": "9.6166922",
      "superficie_kmq": "7.3454",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016222",
      "denominazione_ita_altra": "Urgnano",
      "denominazione_ita": "Urgnano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L502",
      "lat": "45.5985486",
      "lon": "9.6891779",
      "superficie_kmq": "14.7781",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016223",
      "denominazione_ita_altra": "Valbondione",
      "denominazione_ita": "Valbondione",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L544",
      "lat": "46.0379983",
      "lon": "10.0126199",
      "superficie_kmq": "96.8899",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016224",
      "denominazione_ita_altra": "Valbrembo",
      "denominazione_ita": "Valbrembo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L545",
      "lat": "45.7164413",
      "lon": "9.6083693",
      "superficie_kmq": "3.7966",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016225",
      "denominazione_ita_altra": "Valgoglio",
      "denominazione_ita": "Valgoglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L579",
      "lat": "45.9756081",
      "lon": "9.9139424",
      "superficie_kmq": "31.8905",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016226",
      "denominazione_ita_altra": "Valleve",
      "denominazione_ita": "Valleve",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L623",
      "lat": "46.0282102",
      "lon": "9.7432291",
      "superficie_kmq": "14.7640",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016227",
      "denominazione_ita_altra": "Valnegra",
      "denominazione_ita": "Valnegra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L642",
      "lat": "45.9491302",
      "lon": "9.6894701",
      "superficie_kmq": "2.2302",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016229",
      "denominazione_ita_altra": "Valtorta",
      "denominazione_ita": "Valtorta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L655",
      "lat": "45.9775905",
      "lon": "9.5350069",
      "superficie_kmq": "30.8975",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016230",
      "denominazione_ita_altra": "Vedeseta",
      "denominazione_ita": "Vedeseta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L707",
      "lat": "45.8894705",
      "lon": "9.5426616",
      "superficie_kmq": "19.2916",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016232",
      "denominazione_ita_altra": "Verdellino",
      "denominazione_ita": "Verdellino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L752",
      "lat": "45.6041921",
      "lon": "9.6153419",
      "superficie_kmq": "3.8218",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016233",
      "denominazione_ita_altra": "Verdello",
      "denominazione_ita": "Verdello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L753",
      "lat": "45.6065292",
      "lon": "9.6270239",
      "superficie_kmq": "7.3439",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016234",
      "denominazione_ita_altra": "Vertova",
      "denominazione_ita": "Vertova",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L795",
      "lat": "45.8085966",
      "lon": "9.8498727",
      "superficie_kmq": "15.6945",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016235",
      "denominazione_ita_altra": "Viadanica",
      "denominazione_ita": "Viadanica",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L827",
      "lat": "45.6855366",
      "lon": "9.9613245",
      "superficie_kmq": "5.4483",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016236",
      "denominazione_ita_altra": "Vigano San Martino",
      "denominazione_ita": "Vigano San Martino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L865",
      "lat": "45.7261027",
      "lon": "9.8957643",
      "superficie_kmq": "3.7561",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016237",
      "denominazione_ita_altra": "Vigolo",
      "denominazione_ita": "Vigolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L894",
      "lat": "45.7164288",
      "lon": "10.0263148",
      "superficie_kmq": "12.3097",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016238",
      "denominazione_ita_altra": "Villa d'Adda",
      "denominazione_ita": "Villa d'Adda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L929",
      "lat": "45.7128416",
      "lon": "9.4646143",
      "superficie_kmq": "5.9822",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016239",
      "denominazione_ita_altra": "Villa d'Almè",
      "denominazione_ita": "Villa d'Almè",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A215",
      "lat": "45.7463542",
      "lon": "9.6179203",
      "superficie_kmq": "6.4860",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016240",
      "denominazione_ita_altra": "Villa di Serio",
      "denominazione_ita": "Villa di Serio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L936",
      "lat": "45.7201235",
      "lon": "9.7341971",
      "superficie_kmq": "4.6029",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016241",
      "denominazione_ita_altra": "Villa d'Ogna",
      "denominazione_ita": "Villa d'Ogna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L938",
      "lat": "45.9075535",
      "lon": "9.9305305",
      "superficie_kmq": "5.1595",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016242",
      "denominazione_ita_altra": "Villongo",
      "denominazione_ita": "Villongo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M045",
      "lat": "45.6724023",
      "lon": "9.9347514",
      "superficie_kmq": "6.0419",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016243",
      "denominazione_ita_altra": "Vilminore di Scalve",
      "denominazione_ita": "Vilminore di Scalve",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M050",
      "lat": "45.9979627",
      "lon": "10.0952867",
      "superficie_kmq": "41.0010",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016244",
      "denominazione_ita_altra": "Zandobbio",
      "denominazione_ita": "Zandobbio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M144",
      "lat": "45.6865507",
      "lon": "9.8524150",
      "superficie_kmq": "6.4317",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016245",
      "denominazione_ita_altra": "Zanica",
      "denominazione_ita": "Zanica",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M147",
      "lat": "45.6419635",
      "lon": "9.6809736",
      "superficie_kmq": "14.9523",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016246",
      "denominazione_ita_altra": "Zogno",
      "denominazione_ita": "Zogno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M184",
      "lat": "45.7948317",
      "lon": "9.6679188",
      "superficie_kmq": "35.2123",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016247",
      "denominazione_ita_altra": "Costa Serina",
      "denominazione_ita": "Costa Serina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D111",
      "lat": "45.8310164",
      "lon": "9.7392232",
      "superficie_kmq": "12.2970",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016248",
      "denominazione_ita_altra": "Algua",
      "denominazione_ita": "Algua",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A193",
      "lat": "45.8258476",
      "lon": "9.7222294",
      "superficie_kmq": "8.3232",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016249",
      "denominazione_ita_altra": "Cornalba",
      "denominazione_ita": "Cornalba",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D016",
      "lat": "45.8502552",
      "lon": "9.7446449",
      "superficie_kmq": "9.2506",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016250",
      "denominazione_ita_altra": "Medolago",
      "denominazione_ita": "Medolago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F085",
      "lat": "45.6727822",
      "lon": "9.4935420",
      "superficie_kmq": "3.7995",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016251",
      "denominazione_ita_altra": "Solza",
      "denominazione_ita": "Solza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I813",
      "lat": "45.6777791",
      "lon": "9.4903091",
      "superficie_kmq": "1.2277",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016252",
      "denominazione_ita_altra": "Sant'Omobono Terme",
      "denominazione_ita": "Sant'Omobono Terme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M333",
      "lat": "45.8176911",
      "lon": "9.5149540",
      "superficie_kmq": "16.4334",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BG",
      "codice_istat": "016253",
      "denominazione_ita_altra": "Val Brembilla",
      "denominazione_ita": "Val Brembilla",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M334",
      "lat": "45.8293893",
      "lon": "9.5988707",
      "superficie_kmq": "31.4376",
      "codice_sovracomunale": "016"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017001",
      "denominazione_ita_altra": "Acquafredda",
      "denominazione_ita": "Acquafredda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A034",
      "lat": "45.3071716",
      "lon": "10.4119005",
      "superficie_kmq": "9.5463",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017002",
      "denominazione_ita_altra": "Adro",
      "denominazione_ita": "Adro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A060",
      "lat": "45.6251503",
      "lon": "9.9553539",
      "superficie_kmq": "14.2895",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017003",
      "denominazione_ita_altra": "Agnosine",
      "denominazione_ita": "Agnosine",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A082",
      "lat": "45.6477188",
      "lon": "10.3576845",
      "superficie_kmq": "13.5546",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017004",
      "denominazione_ita_altra": "Alfianello",
      "denominazione_ita": "Alfianello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A188",
      "lat": "45.2675884",
      "lon": "10.1482500",
      "superficie_kmq": "13.7523",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017005",
      "denominazione_ita_altra": "Anfo",
      "denominazione_ita": "Anfo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A288",
      "lat": "45.7657598",
      "lon": "10.4937981",
      "superficie_kmq": "23.8313",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017006",
      "denominazione_ita_altra": "Angolo Terme",
      "denominazione_ita": "Angolo Terme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A293",
      "lat": "45.8929707",
      "lon": "10.1468777",
      "superficie_kmq": "30.5601",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017007",
      "denominazione_ita_altra": "Artogne",
      "denominazione_ita": "Artogne",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A451",
      "lat": "45.8483324",
      "lon": "10.1645246",
      "superficie_kmq": "21.0191",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017008",
      "denominazione_ita_altra": "Azzano Mella",
      "denominazione_ita": "Azzano Mella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A529",
      "lat": "45.4548287",
      "lon": "10.1135945",
      "superficie_kmq": "10.5695",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017009",
      "denominazione_ita_altra": "Bagnolo Mella",
      "denominazione_ita": "Bagnolo Mella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A569",
      "lat": "45.4290261",
      "lon": "10.1792804",
      "superficie_kmq": "31.3457",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017010",
      "denominazione_ita_altra": "Bagolino",
      "denominazione_ita": "Bagolino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A578",
      "lat": "45.8212397",
      "lon": "10.4689385",
      "superficie_kmq": "109.1983",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017011",
      "denominazione_ita_altra": "Barbariga",
      "denominazione_ita": "Barbariga",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A630",
      "lat": "45.4039765",
      "lon": "10.0567439",
      "superficie_kmq": "11.3363",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017012",
      "denominazione_ita_altra": "Barghe",
      "denominazione_ita": "Barghe",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A661",
      "lat": "45.6780039",
      "lon": "10.4061274",
      "superficie_kmq": "5.4918",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017013",
      "denominazione_ita_altra": "Bassano Bresciano",
      "denominazione_ita": "Bassano Bresciano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A702",
      "lat": "45.3301945",
      "lon": "10.1268460",
      "superficie_kmq": "9.4204",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017014",
      "denominazione_ita_altra": "Bedizzole",
      "denominazione_ita": "Bedizzole",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A729",
      "lat": "45.5103648",
      "lon": "10.4227785",
      "superficie_kmq": "26.4434",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017015",
      "denominazione_ita_altra": "Berlingo",
      "denominazione_ita": "Berlingo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A799",
      "lat": "45.5027756",
      "lon": "10.0329538",
      "superficie_kmq": "4.5925",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017016",
      "denominazione_ita_altra": "Berzo Demo",
      "denominazione_ita": "Berzo Demo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A816",
      "lat": "46.0928851",
      "lon": "10.3330086",
      "superficie_kmq": "15.4633",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017017",
      "denominazione_ita_altra": "Berzo Inferiore",
      "denominazione_ita": "Berzo Inferiore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A817",
      "lat": "45.9322715",
      "lon": "10.2786735",
      "superficie_kmq": "21.9148",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017018",
      "denominazione_ita_altra": "Bienno",
      "denominazione_ita": "Bienno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A861",
      "lat": "45.9355668",
      "lon": "10.2954695",
      "superficie_kmq": "46.8065",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017019",
      "denominazione_ita_altra": "Bione",
      "denominazione_ita": "Bione",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A878",
      "lat": "45.6740377",
      "lon": "10.3405717",
      "superficie_kmq": "17.2870",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017020",
      "denominazione_ita_altra": "Borgo San Giacomo",
      "denominazione_ita": "Borgo San Giacomo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B035",
      "lat": "45.3501202",
      "lon": "9.9710178",
      "superficie_kmq": "29.5299",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017021",
      "denominazione_ita_altra": "Borgosatollo",
      "denominazione_ita": "Borgosatollo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B040",
      "lat": "45.4750819",
      "lon": "10.2360359",
      "superficie_kmq": "8.4199",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017022",
      "denominazione_ita_altra": "Borno",
      "denominazione_ita": "Borno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B054",
      "lat": "45.9468066",
      "lon": "10.2058081",
      "superficie_kmq": "30.4960",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017023",
      "denominazione_ita_altra": "Botticino",
      "denominazione_ita": "Botticino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B091",
      "lat": "45.5433021",
      "lon": "10.3232992",
      "superficie_kmq": "18.4824",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017024",
      "denominazione_ita_altra": "Bovegno",
      "denominazione_ita": "Bovegno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B100",
      "lat": "45.7923576",
      "lon": "10.2722155",
      "superficie_kmq": "47.9887",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017025",
      "denominazione_ita_altra": "Bovezzo",
      "denominazione_ita": "Bovezzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B102",
      "lat": "45.5916399",
      "lon": "10.2434938",
      "superficie_kmq": "6.4114",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017026",
      "denominazione_ita_altra": "Brandico",
      "denominazione_ita": "Brandico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B120",
      "lat": "45.4542772",
      "lon": "10.0509205",
      "superficie_kmq": "8.3802",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017027",
      "denominazione_ita_altra": "Braone",
      "denominazione_ita": "Braone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B124",
      "lat": "45.9902250",
      "lon": "10.3424307",
      "superficie_kmq": "13.3567",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017028",
      "denominazione_ita_altra": "Breno",
      "denominazione_ita": "Breno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B149",
      "lat": "45.9560254",
      "lon": "10.3020412",
      "superficie_kmq": "59.9347",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017029",
      "denominazione_ita_altra": "Brescia",
      "denominazione_ita": "Brescia",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "B157",
      "lat": "45.5399369",
      "lon": "10.2191034",
      "superficie_kmq": "90.3337",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017030",
      "denominazione_ita_altra": "Brione",
      "denominazione_ita": "Brione",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B184",
      "lat": "45.6407225",
      "lon": "10.1498078",
      "superficie_kmq": "6.8968",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017031",
      "denominazione_ita_altra": "Caino",
      "denominazione_ita": "Caino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B365",
      "lat": "45.6125216",
      "lon": "10.3157571",
      "superficie_kmq": "17.3082",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017032",
      "denominazione_ita_altra": "Calcinato",
      "denominazione_ita": "Calcinato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B394",
      "lat": "45.4574756",
      "lon": "10.4165178",
      "superficie_kmq": "33.2958",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017033",
      "denominazione_ita_altra": "Calvagese della Riviera",
      "denominazione_ita": "Calvagese della Riviera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B436",
      "lat": "45.5392259",
      "lon": "10.4460235",
      "superficie_kmq": "11.7369",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017034",
      "denominazione_ita_altra": "Calvisano",
      "denominazione_ita": "Calvisano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B450",
      "lat": "45.3490817",
      "lon": "10.3428756",
      "superficie_kmq": "44.8271",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017035",
      "denominazione_ita_altra": "Capo di Ponte",
      "denominazione_ita": "Capo di Ponte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B664",
      "lat": "46.0300123",
      "lon": "10.3428020",
      "superficie_kmq": "18.1074",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017036",
      "denominazione_ita_altra": "Capovalle",
      "denominazione_ita": "Capovalle",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B676",
      "lat": "45.7540770",
      "lon": "10.5448430",
      "superficie_kmq": "22.9465",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017037",
      "denominazione_ita_altra": "Capriano del Colle",
      "denominazione_ita": "Capriano del Colle",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B698",
      "lat": "45.4551676",
      "lon": "10.1264097",
      "superficie_kmq": "13.9676",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017038",
      "denominazione_ita_altra": "Capriolo",
      "denominazione_ita": "Capriolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B711",
      "lat": "45.6398057",
      "lon": "9.9360230",
      "superficie_kmq": "10.6009",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017039",
      "denominazione_ita_altra": "Carpenedolo",
      "denominazione_ita": "Carpenedolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B817",
      "lat": "45.3611924",
      "lon": "10.4315259",
      "superficie_kmq": "29.8418",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017040",
      "denominazione_ita_altra": "Castegnato",
      "denominazione_ita": "Castegnato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C055",
      "lat": "45.5621236",
      "lon": "10.1168384",
      "superficie_kmq": "9.2048",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017041",
      "denominazione_ita_altra": "Castelcovati",
      "denominazione_ita": "Castelcovati",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C072",
      "lat": "45.5060801",
      "lon": "9.9431918",
      "superficie_kmq": "6.1396",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017042",
      "denominazione_ita_altra": "Castel Mella",
      "denominazione_ita": "Castel Mella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C208",
      "lat": "45.4976045",
      "lon": "10.1418818",
      "superficie_kmq": "7.5285",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017043",
      "denominazione_ita_altra": "Castenedolo",
      "denominazione_ita": "Castenedolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C293",
      "lat": "45.4713644",
      "lon": "10.3004393",
      "superficie_kmq": "26.1950",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017044",
      "denominazione_ita_altra": "Casto",
      "denominazione_ita": "Casto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C330",
      "lat": "45.6950948",
      "lon": "10.3205977",
      "superficie_kmq": "21.3362",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017045",
      "denominazione_ita_altra": "Castrezzato",
      "denominazione_ita": "Castrezzato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C332",
      "lat": "45.5110933",
      "lon": "9.9767156",
      "superficie_kmq": "13.6321",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017046",
      "denominazione_ita_altra": "Cazzago San Martino",
      "denominazione_ita": "Cazzago San Martino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C408",
      "lat": "45.5810203",
      "lon": "10.0250151",
      "superficie_kmq": "22.3415",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017047",
      "denominazione_ita_altra": "Cedegolo",
      "denominazione_ita": "Cedegolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C417",
      "lat": "46.0757766",
      "lon": "10.3499948",
      "superficie_kmq": "11.0830",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017048",
      "denominazione_ita_altra": "Cellatica",
      "denominazione_ita": "Cellatica",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C439",
      "lat": "45.5826954",
      "lon": "10.1777722",
      "superficie_kmq": "6.5461",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017049",
      "denominazione_ita_altra": "Cerveno",
      "denominazione_ita": "Cerveno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C549",
      "lat": "46.0023297",
      "lon": "10.3255080",
      "superficie_kmq": "21.5525",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017050",
      "denominazione_ita_altra": "Ceto",
      "denominazione_ita": "Ceto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C585",
      "lat": "46.0023713",
      "lon": "10.3518501",
      "superficie_kmq": "32.3011",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017051",
      "denominazione_ita_altra": "Cevo",
      "denominazione_ita": "Cevo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C591",
      "lat": "46.0802053",
      "lon": "10.3703765",
      "superficie_kmq": "35.4688",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017052",
      "denominazione_ita_altra": "Chiari",
      "denominazione_ita": "Chiari",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C618",
      "lat": "45.5368499",
      "lon": "9.9305287",
      "superficie_kmq": "37.9592",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017053",
      "denominazione_ita_altra": "Cigole",
      "denominazione_ita": "Cigole",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C685",
      "lat": "45.3099375",
      "lon": "10.1913683",
      "superficie_kmq": "9.9309",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017054",
      "denominazione_ita_altra": "Cimbergo",
      "denominazione_ita": "Cimbergo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C691",
      "lat": "46.0238669",
      "lon": "10.3630765",
      "superficie_kmq": "24.7130",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017055",
      "denominazione_ita_altra": "Cividate Camuno",
      "denominazione_ita": "Cividate Camuno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C760",
      "lat": "45.9435022",
      "lon": "10.2795478",
      "superficie_kmq": "3.3068",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017056",
      "denominazione_ita_altra": "Coccaglio",
      "denominazione_ita": "Coccaglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C806",
      "lat": "45.5623913",
      "lon": "9.9769612",
      "superficie_kmq": "12.0465",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017057",
      "denominazione_ita_altra": "Collebeato",
      "denominazione_ita": "Collebeato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C850",
      "lat": "45.5856790",
      "lon": "10.2138879",
      "superficie_kmq": "5.2698",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017058",
      "denominazione_ita_altra": "Collio",
      "denominazione_ita": "Collio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C883",
      "lat": "45.8111772",
      "lon": "10.3333331",
      "superficie_kmq": "53.4749",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017059",
      "denominazione_ita_altra": "Cologne",
      "denominazione_ita": "Cologne",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C893",
      "lat": "45.5856136",
      "lon": "9.9430535",
      "superficie_kmq": "13.7875",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017060",
      "denominazione_ita_altra": "Comezzano-Cizzago",
      "denominazione_ita": "Comezzano-Cizzago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C925",
      "lat": "45.4725998",
      "lon": "9.9490444",
      "superficie_kmq": "15.4370",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017061",
      "denominazione_ita_altra": "Concesio",
      "denominazione_ita": "Concesio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C948",
      "lat": "45.6043794",
      "lon": "10.2184612",
      "superficie_kmq": "19.0748",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017062",
      "denominazione_ita_altra": "Corte Franca",
      "denominazione_ita": "Corte Franca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D058",
      "lat": "45.6372065",
      "lon": "10.0069825",
      "superficie_kmq": "13.9733",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017063",
      "denominazione_ita_altra": "Corteno Golgi",
      "denominazione_ita": "Corteno Golgi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D064",
      "lat": "46.1659846",
      "lon": "10.2422415",
      "superficie_kmq": "82.6046",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017064",
      "denominazione_ita_altra": "Corzano",
      "denominazione_ita": "Corzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D082",
      "lat": "45.4442512",
      "lon": "10.0077910",
      "superficie_kmq": "12.2960",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017065",
      "denominazione_ita_altra": "Darfo Boario Terme",
      "denominazione_ita": "Darfo Boario Terme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D251",
      "lat": "45.8834851",
      "lon": "10.1804841",
      "superficie_kmq": "36.0712",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017066",
      "denominazione_ita_altra": "Dello",
      "denominazione_ita": "Dello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D270",
      "lat": "45.4165545",
      "lon": "10.0797716",
      "superficie_kmq": "23.3209",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017067",
      "denominazione_ita_altra": "Desenzano del Garda",
      "denominazione_ita": "Desenzano del Garda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D284",
      "lat": "45.4685879",
      "lon": "10.5415884",
      "superficie_kmq": "59.2622",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017068",
      "denominazione_ita_altra": "Edolo",
      "denominazione_ita": "Edolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D391",
      "lat": "46.1782472",
      "lon": "10.3371123",
      "superficie_kmq": "88.9031",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017069",
      "denominazione_ita_altra": "Erbusco",
      "denominazione_ita": "Erbusco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D421",
      "lat": "45.5995255",
      "lon": "9.9732414",
      "superficie_kmq": "16.2398",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017070",
      "denominazione_ita_altra": "Esine",
      "denominazione_ita": "Esine",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D434",
      "lat": "45.9240316",
      "lon": "10.2591767",
      "superficie_kmq": "30.3061",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017071",
      "denominazione_ita_altra": "Fiesse",
      "denominazione_ita": "Fiesse",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D576",
      "lat": "45.2333432",
      "lon": "10.3217037",
      "superficie_kmq": "16.0159",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017072",
      "denominazione_ita_altra": "Flero",
      "denominazione_ita": "Flero",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D634",
      "lat": "45.4823616",
      "lon": "10.1758922",
      "superficie_kmq": "9.8367",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017073",
      "denominazione_ita_altra": "Gambara",
      "denominazione_ita": "Gambara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D891",
      "lat": "45.2538624",
      "lon": "10.2977894",
      "superficie_kmq": "31.5861",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017074",
      "denominazione_ita_altra": "Gardone Riviera",
      "denominazione_ita": "Gardone Riviera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D917",
      "lat": "45.6200986",
      "lon": "10.5579951",
      "superficie_kmq": "21.3930",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017075",
      "denominazione_ita_altra": "Gardone Val Trompia",
      "denominazione_ita": "Gardone Val Trompia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D918",
      "lat": "45.6891027",
      "lon": "10.1822232",
      "superficie_kmq": "26.6562",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017076",
      "denominazione_ita_altra": "Gargnano",
      "denominazione_ita": "Gargnano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D924",
      "lat": "45.6898191",
      "lon": "10.6599449",
      "superficie_kmq": "76.7514",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017077",
      "denominazione_ita_altra": "Gavardo",
      "denominazione_ita": "Gavardo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D940",
      "lat": "45.5867418",
      "lon": "10.4346791",
      "superficie_kmq": "29.7959",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017078",
      "denominazione_ita_altra": "Ghedi",
      "denominazione_ita": "Ghedi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D999",
      "lat": "45.4022021",
      "lon": "10.2769255",
      "superficie_kmq": "60.8401",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017079",
      "denominazione_ita_altra": "Gianico",
      "denominazione_ita": "Gianico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E010",
      "lat": "45.8653245",
      "lon": "10.1839735",
      "superficie_kmq": "13.3811",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017080",
      "denominazione_ita_altra": "Gottolengo",
      "denominazione_ita": "Gottolengo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E116",
      "lat": "45.2917344",
      "lon": "10.2715177",
      "superficie_kmq": "29.2837",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017081",
      "denominazione_ita_altra": "Gussago",
      "denominazione_ita": "Gussago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E271",
      "lat": "45.5924321",
      "lon": "10.1531485",
      "superficie_kmq": "25.0891",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017082",
      "denominazione_ita_altra": "Idro",
      "denominazione_ita": "Idro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E280",
      "lat": "45.7370290",
      "lon": "10.4740142",
      "superficie_kmq": "22.8902",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017083",
      "denominazione_ita_altra": "Incudine",
      "denominazione_ita": "Incudine",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E297",
      "lat": "46.2221313",
      "lon": "10.3581937",
      "superficie_kmq": "19.6657",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017084",
      "denominazione_ita_altra": "Irma",
      "denominazione_ita": "Irma",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E325",
      "lat": "45.7712068",
      "lon": "10.2838456",
      "superficie_kmq": "4.9347",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017085",
      "denominazione_ita_altra": "Iseo",
      "denominazione_ita": "Iseo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E333",
      "lat": "45.6598374",
      "lon": "10.0500441",
      "superficie_kmq": "28.4194",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017086",
      "denominazione_ita_altra": "Isorella",
      "denominazione_ita": "Isorella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E364",
      "lat": "45.3075657",
      "lon": "10.3166576",
      "superficie_kmq": "15.3283",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017087",
      "denominazione_ita_altra": "Lavenone",
      "denominazione_ita": "Lavenone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E497",
      "lat": "45.7390221",
      "lon": "10.4379684",
      "superficie_kmq": "31.8235",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017088",
      "denominazione_ita_altra": "Leno",
      "denominazione_ita": "Leno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E526",
      "lat": "45.3675035",
      "lon": "10.2174187",
      "superficie_kmq": "58.4516",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017089",
      "denominazione_ita_altra": "Limone sul Garda",
      "denominazione_ita": "Limone sul Garda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E596",
      "lat": "45.8133408",
      "lon": "10.7929024",
      "superficie_kmq": "23.0259",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017090",
      "denominazione_ita_altra": "Lodrino",
      "denominazione_ita": "Lodrino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E652",
      "lat": "45.7177525",
      "lon": "10.2776022",
      "superficie_kmq": "16.4987",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017091",
      "denominazione_ita_altra": "Lograto",
      "denominazione_ita": "Lograto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E654",
      "lat": "45.4831912",
      "lon": "10.0574386",
      "superficie_kmq": "12.4335",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017092",
      "denominazione_ita_altra": "Lonato del Garda",
      "denominazione_ita": "Lonato del Garda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M312",
      "lat": "45.4608970",
      "lon": "10.4842930",
      "superficie_kmq": "68.1954",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017093",
      "denominazione_ita_altra": "Longhena",
      "denominazione_ita": "Longhena",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E673",
      "lat": "45.4381457",
      "lon": "10.0596561",
      "superficie_kmq": "3.4707",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017094",
      "denominazione_ita_altra": "Losine",
      "denominazione_ita": "Losine",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E698",
      "lat": "45.9836025",
      "lon": "10.3175090",
      "superficie_kmq": "6.2553",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017095",
      "denominazione_ita_altra": "Lozio",
      "denominazione_ita": "Lozio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E706",
      "lat": "45.9856512",
      "lon": "10.2608911",
      "superficie_kmq": "23.7425",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017096",
      "denominazione_ita_altra": "Lumezzane",
      "denominazione_ita": "Lumezzane",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E738",
      "lat": "45.6501578",
      "lon": "10.2617562",
      "superficie_kmq": "31.7179",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017097",
      "denominazione_ita_altra": "Maclodio",
      "denominazione_ita": "Maclodio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E787",
      "lat": "45.4767455",
      "lon": "10.0417830",
      "superficie_kmq": "5.0958",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017098",
      "denominazione_ita_altra": "Magasa",
      "denominazione_ita": "Magasa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E800",
      "lat": "45.7814861",
      "lon": "10.6164037",
      "superficie_kmq": "19.1109",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017099",
      "denominazione_ita_altra": "Mairano",
      "denominazione_ita": "Mairano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E841",
      "lat": "45.4502054",
      "lon": "10.0763078",
      "superficie_kmq": "11.5281",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017100",
      "denominazione_ita_altra": "Malegno",
      "denominazione_ita": "Malegno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E851",
      "lat": "45.9507222",
      "lon": "10.2724114",
      "superficie_kmq": "6.8941",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017101",
      "denominazione_ita_altra": "Malonno",
      "denominazione_ita": "Malonno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E865",
      "lat": "46.1186804",
      "lon": "10.3147832",
      "superficie_kmq": "31.4567",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017102",
      "denominazione_ita_altra": "Manerba del Garda",
      "denominazione_ita": "Manerba del Garda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E883",
      "lat": "45.5501027",
      "lon": "10.5514259",
      "superficie_kmq": "36.6294",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017103",
      "denominazione_ita_altra": "Manerbio",
      "denominazione_ita": "Manerbio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E884",
      "lat": "45.3543058",
      "lon": "10.1390219",
      "superficie_kmq": "27.8839",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017104",
      "denominazione_ita_altra": "Marcheno",
      "denominazione_ita": "Marcheno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E928",
      "lat": "45.7060065",
      "lon": "10.2141957",
      "superficie_kmq": "22.7392",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017105",
      "denominazione_ita_altra": "Marmentino",
      "denominazione_ita": "Marmentino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E961",
      "lat": "45.7528370",
      "lon": "10.2819435",
      "superficie_kmq": "18.0348",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017106",
      "denominazione_ita_altra": "Marone",
      "denominazione_ita": "Marone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E967",
      "lat": "45.7370996",
      "lon": "10.0915623",
      "superficie_kmq": "23.9307",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017107",
      "denominazione_ita_altra": "Mazzano",
      "denominazione_ita": "Mazzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F063",
      "lat": "45.5036093",
      "lon": "10.3597906",
      "superficie_kmq": "15.7265",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017108",
      "denominazione_ita_altra": "Milzano",
      "denominazione_ita": "Milzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F216",
      "lat": "45.2755440",
      "lon": "10.2004387",
      "superficie_kmq": "8.4888",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017109",
      "denominazione_ita_altra": "Moniga del Garda",
      "denominazione_ita": "Moniga del Garda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F373",
      "lat": "45.5305230",
      "lon": "10.5370433",
      "superficie_kmq": "14.6529",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017110",
      "denominazione_ita_altra": "Monno",
      "denominazione_ita": "Monno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F375",
      "lat": "46.2126317",
      "lon": "10.3380378",
      "superficie_kmq": "31.0259",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017111",
      "denominazione_ita_altra": "Monte Isola",
      "denominazione_ita": "Monte Isola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F532",
      "lat": "45.7149950",
      "lon": "10.0792971",
      "superficie_kmq": "12.6051",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017112",
      "denominazione_ita_altra": "Monticelli Brusati",
      "denominazione_ita": "Monticelli Brusati",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F672",
      "lat": "45.6342680",
      "lon": "10.0978986",
      "superficie_kmq": "10.8908",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017113",
      "denominazione_ita_altra": "Montichiari",
      "denominazione_ita": "Montichiari",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F471",
      "lat": "45.4122508",
      "lon": "10.3952829",
      "superficie_kmq": "81.6613",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017114",
      "denominazione_ita_altra": "Montirone",
      "denominazione_ita": "Montirone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F680",
      "lat": "45.4437779",
      "lon": "10.2311107",
      "superficie_kmq": "10.5181",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017115",
      "denominazione_ita_altra": "Mura",
      "denominazione_ita": "Mura",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F806",
      "lat": "45.7130760",
      "lon": "10.3420142",
      "superficie_kmq": "12.5067",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017116",
      "denominazione_ita_altra": "Muscoline",
      "denominazione_ita": "Muscoline",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F820",
      "lat": "45.5643963",
      "lon": "10.4603272",
      "superficie_kmq": "10.0780",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017117",
      "denominazione_ita_altra": "Nave",
      "denominazione_ita": "Nave",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F851",
      "lat": "45.5846825",
      "lon": "10.2790455",
      "superficie_kmq": "27.2115",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017118",
      "denominazione_ita_altra": "Niardo",
      "denominazione_ita": "Niardo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F884",
      "lat": "45.9787401",
      "lon": "10.3359001",
      "superficie_kmq": "22.1574",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017119",
      "denominazione_ita_altra": "Nuvolento",
      "denominazione_ita": "Nuvolento",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F989",
      "lat": "45.5478412",
      "lon": "10.3832798",
      "superficie_kmq": "7.4576",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017120",
      "denominazione_ita_altra": "Nuvolera",
      "denominazione_ita": "Nuvolera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F990",
      "lat": "45.5333319",
      "lon": "10.3701592",
      "superficie_kmq": "13.3116",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017121",
      "denominazione_ita_altra": "Odolo",
      "denominazione_ita": "Odolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G001",
      "lat": "45.6485688",
      "lon": "10.3882460",
      "superficie_kmq": "6.5439",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017122",
      "denominazione_ita_altra": "Offlaga",
      "denominazione_ita": "Offlaga",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G006",
      "lat": "45.3865277",
      "lon": "10.1163079",
      "superficie_kmq": "23.0333",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017123",
      "denominazione_ita_altra": "Ome",
      "denominazione_ita": "Ome",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G061",
      "lat": "45.6249029",
      "lon": "10.1241605",
      "superficie_kmq": "9.8482",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017124",
      "denominazione_ita_altra": "Ono San Pietro",
      "denominazione_ita": "Ono San Pietro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G074",
      "lat": "46.0164650",
      "lon": "10.3292443",
      "superficie_kmq": "13.7797",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017125",
      "denominazione_ita_altra": "Orzinuovi",
      "denominazione_ita": "Orzinuovi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G149",
      "lat": "45.4025022",
      "lon": "9.9217669",
      "superficie_kmq": "47.8684",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017126",
      "denominazione_ita_altra": "Orzivecchi",
      "denominazione_ita": "Orzivecchi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G150",
      "lat": "45.4208931",
      "lon": "9.9604542",
      "superficie_kmq": "9.9376",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017127",
      "denominazione_ita_altra": "Ospitaletto",
      "denominazione_ita": "Ospitaletto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G170",
      "lat": "45.5568683",
      "lon": "10.0751704",
      "superficie_kmq": "9.2903",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017128",
      "denominazione_ita_altra": "Ossimo",
      "denominazione_ita": "Ossimo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G179",
      "lat": "45.9457654",
      "lon": "10.2284763",
      "superficie_kmq": "14.8578",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017129",
      "denominazione_ita_altra": "Padenghe sul Garda",
      "denominazione_ita": "Padenghe sul Garda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G213",
      "lat": "45.5094566",
      "lon": "10.5083632",
      "superficie_kmq": "26.8091",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017130",
      "denominazione_ita_altra": "Paderno Franciacorta",
      "denominazione_ita": "Paderno Franciacorta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G217",
      "lat": "45.5904159",
      "lon": "10.0777619",
      "superficie_kmq": "5.6080",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017131",
      "denominazione_ita_altra": "Paisco Loveno",
      "denominazione_ita": "Paisco Loveno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G247",
      "lat": "46.0788870",
      "lon": "10.2927575",
      "superficie_kmq": "35.8656",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017132",
      "denominazione_ita_altra": "Paitone",
      "denominazione_ita": "Paitone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G248",
      "lat": "45.5530114",
      "lon": "10.3999437",
      "superficie_kmq": "8.0034",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017133",
      "denominazione_ita_altra": "Palazzolo sull'Oglio",
      "denominazione_ita": "Palazzolo sull'Oglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G264",
      "lat": "45.5984848",
      "lon": "9.8858216",
      "superficie_kmq": "23.0393",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017134",
      "denominazione_ita_altra": "Paratico",
      "denominazione_ita": "Paratico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G327",
      "lat": "45.6616033",
      "lon": "9.9576200",
      "superficie_kmq": "6.1816",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017135",
      "denominazione_ita_altra": "Paspardo",
      "denominazione_ita": "Paspardo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G354",
      "lat": "46.0298835",
      "lon": "10.3710638",
      "superficie_kmq": "11.1537",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017136",
      "denominazione_ita_altra": "Passirano",
      "denominazione_ita": "Passirano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G361",
      "lat": "45.5981604",
      "lon": "10.0640274",
      "superficie_kmq": "13.3907",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017137",
      "denominazione_ita_altra": "Pavone del Mella",
      "denominazione_ita": "Pavone del Mella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G391",
      "lat": "45.3033246",
      "lon": "10.2080486",
      "superficie_kmq": "11.6134",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017138",
      "denominazione_ita_altra": "San Paolo",
      "denominazione_ita": "San Paolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G407",
      "lat": "45.3713005",
      "lon": "10.0246218",
      "superficie_kmq": "18.8177",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017139",
      "denominazione_ita_altra": "Pertica Alta",
      "denominazione_ita": "Pertica Alta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G474",
      "lat": "45.7426584",
      "lon": "10.3442924",
      "superficie_kmq": "20.9152",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017140",
      "denominazione_ita_altra": "Pertica Bassa",
      "denominazione_ita": "Pertica Bassa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G475",
      "lat": "45.7533440",
      "lon": "10.3730407",
      "superficie_kmq": "30.1258",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017141",
      "denominazione_ita_altra": "Pezzaze",
      "denominazione_ita": "Pezzaze",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G529",
      "lat": "45.7749413",
      "lon": "10.2392164",
      "superficie_kmq": "21.4894",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017142",
      "denominazione_ita_altra": "Pian Camuno",
      "denominazione_ita": "Pian Camuno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G546",
      "lat": "45.8437283",
      "lon": "10.1574811",
      "superficie_kmq": "10.9450",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017143",
      "denominazione_ita_altra": "Pisogne",
      "denominazione_ita": "Pisogne",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G710",
      "lat": "45.8048131",
      "lon": "10.1088982",
      "superficie_kmq": "49.2273",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017144",
      "denominazione_ita_altra": "Polaveno",
      "denominazione_ita": "Polaveno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G779",
      "lat": "45.6612042",
      "lon": "10.1242484",
      "superficie_kmq": "9.2011",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017145",
      "denominazione_ita_altra": "Polpenazze del Garda",
      "denominazione_ita": "Polpenazze del Garda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G801",
      "lat": "45.5471938",
      "lon": "10.5046351",
      "superficie_kmq": "9.1195",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017146",
      "denominazione_ita_altra": "Pompiano",
      "denominazione_ita": "Pompiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G815",
      "lat": "45.4327860",
      "lon": "9.9871550",
      "superficie_kmq": "15.2734",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017147",
      "denominazione_ita_altra": "Poncarale",
      "denominazione_ita": "Poncarale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G818",
      "lat": "45.4605171",
      "lon": "10.1739626",
      "superficie_kmq": "12.6432",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017148",
      "denominazione_ita_altra": "Ponte di Legno",
      "denominazione_ita": "Ponte di Legno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G844",
      "lat": "46.2564891",
      "lon": "10.5114591",
      "superficie_kmq": "100.4260",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017149",
      "denominazione_ita_altra": "Pontevico",
      "denominazione_ita": "Pontevico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G859",
      "lat": "45.2735997",
      "lon": "10.0850301",
      "superficie_kmq": "29.2070",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017150",
      "denominazione_ita_altra": "Pontoglio",
      "denominazione_ita": "Pontoglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G869",
      "lat": "45.5706298",
      "lon": "9.8526524",
      "superficie_kmq": "11.0859",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017151",
      "denominazione_ita_altra": "Pozzolengo",
      "denominazione_ita": "Pozzolengo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G959",
      "lat": "45.4028612",
      "lon": "10.6270948",
      "superficie_kmq": "21.3264",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017152",
      "denominazione_ita_altra": "Pralboino",
      "denominazione_ita": "Pralboino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G977",
      "lat": "45.2680966",
      "lon": "10.2148817",
      "superficie_kmq": "17.1622",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017153",
      "denominazione_ita_altra": "Preseglie",
      "denominazione_ita": "Preseglie",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H043",
      "lat": "45.6653532",
      "lon": "10.3929300",
      "superficie_kmq": "11.4524",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017155",
      "denominazione_ita_altra": "Prevalle",
      "denominazione_ita": "Prevalle",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H055",
      "lat": "45.5470618",
      "lon": "10.4212165",
      "superficie_kmq": "9.9891",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017156",
      "denominazione_ita_altra": "Provaglio d'Iseo",
      "denominazione_ita": "Provaglio d'Iseo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H078",
      "lat": "45.6377841",
      "lon": "10.0421498",
      "superficie_kmq": "16.1597",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017157",
      "denominazione_ita_altra": "Provaglio Val Sabbia",
      "denominazione_ita": "Provaglio Val Sabbia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H077",
      "lat": "45.6885853",
      "lon": "10.4339283",
      "superficie_kmq": "14.8506",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017158",
      "denominazione_ita_altra": "Puegnago del Garda",
      "denominazione_ita": "Puegnago del Garda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H086",
      "lat": "45.5704793",
      "lon": "10.5099115",
      "superficie_kmq": "10.9716",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017159",
      "denominazione_ita_altra": "Quinzano d'Oglio",
      "denominazione_ita": "Quinzano d'Oglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H140",
      "lat": "45.3136885",
      "lon": "10.0087226",
      "superficie_kmq": "21.4527",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017160",
      "denominazione_ita_altra": "Remedello",
      "denominazione_ita": "Remedello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H230",
      "lat": "45.2788984",
      "lon": "10.3719640",
      "superficie_kmq": "21.4624",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017161",
      "denominazione_ita_altra": "Rezzato",
      "denominazione_ita": "Rezzato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H256",
      "lat": "45.5134187",
      "lon": "10.3184925",
      "superficie_kmq": "18.2064",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017162",
      "denominazione_ita_altra": "Roccafranca",
      "denominazione_ita": "Roccafranca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H410",
      "lat": "45.4641478",
      "lon": "9.9119847",
      "superficie_kmq": "19.1322",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017163",
      "denominazione_ita_altra": "Rodengo Saiano",
      "denominazione_ita": "Rodengo Saiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H477",
      "lat": "45.6005948",
      "lon": "10.1092960",
      "superficie_kmq": "12.8593",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017164",
      "denominazione_ita_altra": "Roè Volciano",
      "denominazione_ita": "Roè Volciano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H484",
      "lat": "45.6240172",
      "lon": "10.4940402",
      "superficie_kmq": "5.8207",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017165",
      "denominazione_ita_altra": "Roncadelle",
      "denominazione_ita": "Roncadelle",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H525",
      "lat": "45.5280683",
      "lon": "10.1513088",
      "superficie_kmq": "9.3899",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017166",
      "denominazione_ita_altra": "Rovato",
      "denominazione_ita": "Rovato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H598",
      "lat": "45.5680921",
      "lon": "9.9976380",
      "superficie_kmq": "26.0941",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017167",
      "denominazione_ita_altra": "Rudiano",
      "denominazione_ita": "Rudiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H630",
      "lat": "45.4885241",
      "lon": "9.8842323",
      "superficie_kmq": "9.8454",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017168",
      "denominazione_ita_altra": "Sabbio Chiese",
      "denominazione_ita": "Sabbio Chiese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H650",
      "lat": "45.6567709",
      "lon": "10.4155328",
      "superficie_kmq": "18.4472",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017169",
      "denominazione_ita_altra": "Sale Marasino",
      "denominazione_ita": "Sale Marasino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H699",
      "lat": "45.7141013",
      "lon": "10.1128875",
      "superficie_kmq": "16.5869",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017170",
      "denominazione_ita_altra": "Salò",
      "denominazione_ita": "Salò",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H717",
      "lat": "45.6071451",
      "lon": "10.5323116",
      "superficie_kmq": "27.3048",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017171",
      "denominazione_ita_altra": "San Felice del Benaco",
      "denominazione_ita": "San Felice del Benaco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H838",
      "lat": "45.5827585",
      "lon": "10.5537724",
      "superficie_kmq": "20.2166",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017172",
      "denominazione_ita_altra": "San Gervasio Bresciano",
      "denominazione_ita": "San Gervasio Bresciano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H865",
      "lat": "45.3060226",
      "lon": "10.1496704",
      "superficie_kmq": "10.5028",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017173",
      "denominazione_ita_altra": "San Zeno Naviglio",
      "denominazione_ita": "San Zeno Naviglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I412",
      "lat": "45.4913482",
      "lon": "10.2145537",
      "superficie_kmq": "6.2531",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017174",
      "denominazione_ita_altra": "Sarezzo",
      "denominazione_ita": "Sarezzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I433",
      "lat": "45.6537910",
      "lon": "10.2029771",
      "superficie_kmq": "17.6790",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017175",
      "denominazione_ita_altra": "Saviore dell'Adamello",
      "denominazione_ita": "Saviore dell'Adamello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I476",
      "lat": "46.0805051",
      "lon": "10.3982077",
      "superficie_kmq": "84.2677",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017176",
      "denominazione_ita_altra": "Sellero",
      "denominazione_ita": "Sellero",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I588",
      "lat": "46.0687366",
      "lon": "10.3381184",
      "superficie_kmq": "14.4711",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017177",
      "denominazione_ita_altra": "Seniga",
      "denominazione_ita": "Seniga",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I607",
      "lat": "45.2436159",
      "lon": "10.1771603",
      "superficie_kmq": "13.5719",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017178",
      "denominazione_ita_altra": "Serle",
      "denominazione_ita": "Serle",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I631",
      "lat": "45.5617507",
      "lon": "10.3688675",
      "superficie_kmq": "18.4273",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017179",
      "denominazione_ita_altra": "Sirmione",
      "denominazione_ita": "Sirmione",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I633",
      "lat": "45.4623202",
      "lon": "10.6094685",
      "superficie_kmq": "26.2480",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017180",
      "denominazione_ita_altra": "Soiano del Lago",
      "denominazione_ita": "Soiano del Lago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I782",
      "lat": "45.5366778",
      "lon": "10.5136330",
      "superficie_kmq": "5.7734",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017181",
      "denominazione_ita_altra": "Sonico",
      "denominazione_ita": "Sonico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I831",
      "lat": "46.1633289",
      "lon": "10.3521123",
      "superficie_kmq": "60.8920",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017182",
      "denominazione_ita_altra": "Sulzano",
      "denominazione_ita": "Sulzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L002",
      "lat": "45.6907467",
      "lon": "10.1027822",
      "superficie_kmq": "10.4396",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017183",
      "denominazione_ita_altra": "Tavernole sul Mella",
      "denominazione_ita": "Tavernole sul Mella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C698",
      "lat": "45.7480845",
      "lon": "10.2402485",
      "superficie_kmq": "19.8075",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017184",
      "denominazione_ita_altra": "Temù",
      "denominazione_ita": "Temù",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L094",
      "lat": "46.2497680",
      "lon": "10.4704718",
      "superficie_kmq": "43.2570",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017185",
      "denominazione_ita_altra": "Tignale",
      "denominazione_ita": "Tignale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L169",
      "lat": "45.7395252",
      "lon": "10.7218510",
      "superficie_kmq": "45.8569",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017186",
      "denominazione_ita_altra": "Torbole Casaglia",
      "denominazione_ita": "Torbole Casaglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L210",
      "lat": "45.5083158",
      "lon": "10.1050264",
      "superficie_kmq": "13.4393",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017187",
      "denominazione_ita_altra": "Toscolano-Maderno",
      "denominazione_ita": "Toscolano-Maderno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L312",
      "lat": "45.6416831",
      "lon": "10.6062475",
      "superficie_kmq": "58.1706",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017188",
      "denominazione_ita_altra": "Travagliato",
      "denominazione_ita": "Travagliato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L339",
      "lat": "45.5233823",
      "lon": "10.0802190",
      "superficie_kmq": "17.7358",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017189",
      "denominazione_ita_altra": "Tremosine sul Garda",
      "denominazione_ita": "Tremosine sul Garda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L372",
      "lat": "45.7669664",
      "lon": "10.7538110",
      "superficie_kmq": "72.6815",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017190",
      "denominazione_ita_altra": "Trenzano",
      "denominazione_ita": "Trenzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L380",
      "lat": "45.4759571",
      "lon": "10.0157115",
      "superficie_kmq": "20.1018",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017191",
      "denominazione_ita_altra": "Treviso Bresciano",
      "denominazione_ita": "Treviso Bresciano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L406",
      "lat": "45.7127510",
      "lon": "10.4619966",
      "superficie_kmq": "17.7332",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017192",
      "denominazione_ita_altra": "Urago d'Oglio",
      "denominazione_ita": "Urago d'Oglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L494",
      "lat": "45.5133406",
      "lon": "9.8703536",
      "superficie_kmq": "10.6801",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017193",
      "denominazione_ita_altra": "Vallio Terme",
      "denominazione_ita": "Vallio Terme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L626",
      "lat": "45.6092339",
      "lon": "10.3957802",
      "superficie_kmq": "14.8574",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017194",
      "denominazione_ita_altra": "Valvestino",
      "denominazione_ita": "Valvestino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L468",
      "lat": "45.7618515",
      "lon": "10.5948237",
      "superficie_kmq": "31.1204",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017195",
      "denominazione_ita_altra": "Verolanuova",
      "denominazione_ita": "Verolanuova",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L777",
      "lat": "45.3257798",
      "lon": "10.0766529",
      "superficie_kmq": "25.7595",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017196",
      "denominazione_ita_altra": "Verolavecchia",
      "denominazione_ita": "Verolavecchia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L778",
      "lat": "45.3305251",
      "lon": "10.0518126",
      "superficie_kmq": "21.0635",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017197",
      "denominazione_ita_altra": "Vestone",
      "denominazione_ita": "Vestone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L812",
      "lat": "45.7096126",
      "lon": "10.4036957",
      "superficie_kmq": "12.9638",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017198",
      "denominazione_ita_altra": "Vezza d'Oglio",
      "denominazione_ita": "Vezza d'Oglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L816",
      "lat": "46.2401370",
      "lon": "10.4000816",
      "superficie_kmq": "54.1472",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017199",
      "denominazione_ita_altra": "Villa Carcina",
      "denominazione_ita": "Villa Carcina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L919",
      "lat": "45.6314273",
      "lon": "10.1952722",
      "superficie_kmq": "14.2195",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017200",
      "denominazione_ita_altra": "Villachiara",
      "denominazione_ita": "Villachiara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L923",
      "lat": "45.3568768",
      "lon": "9.9296057",
      "superficie_kmq": "16.8650",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017201",
      "denominazione_ita_altra": "Villanuova sul Clisi",
      "denominazione_ita": "Villanuova sul Clisi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L995",
      "lat": "45.6016703",
      "lon": "10.4537094",
      "superficie_kmq": "9.1011",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017202",
      "denominazione_ita_altra": "Vione",
      "denominazione_ita": "Vione",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M065",
      "lat": "46.2488249",
      "lon": "10.4477983",
      "superficie_kmq": "35.2649",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017203",
      "denominazione_ita_altra": "Visano",
      "denominazione_ita": "Visano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M070",
      "lat": "45.3162635",
      "lon": "10.3690838",
      "superficie_kmq": "11.2237",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017204",
      "denominazione_ita_altra": "Vobarno",
      "denominazione_ita": "Vobarno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M104",
      "lat": "45.6470038",
      "lon": "10.4965582",
      "superficie_kmq": "53.2214",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017205",
      "denominazione_ita_altra": "Zone",
      "denominazione_ita": "Zone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M188",
      "lat": "45.7622084",
      "lon": "10.1166138",
      "superficie_kmq": "19.6815",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "BS",
      "codice_istat": "017206",
      "denominazione_ita_altra": "Piancogno",
      "denominazione_ita": "Piancogno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G549",
      "lat": "45.9188162",
      "lon": "10.2205143",
      "superficie_kmq": "14.3021",
      "codice_sovracomunale": "017"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018001",
      "denominazione_ita_altra": "Alagna",
      "denominazione_ita": "Alagna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A118",
      "lat": "45.1692847",
      "lon": "8.8894522",
      "superficie_kmq": "8.3442",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018003",
      "denominazione_ita_altra": "Albonese",
      "denominazione_ita": "Albonese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A171",
      "lat": "45.2928530",
      "lon": "8.7061279",
      "superficie_kmq": "4.3279",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018004",
      "denominazione_ita_altra": "Albuzzano",
      "denominazione_ita": "Albuzzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A175",
      "lat": "45.1875481",
      "lon": "9.2771775",
      "superficie_kmq": "15.4488",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018005",
      "denominazione_ita_altra": "Arena Po",
      "denominazione_ita": "Arena Po",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A387",
      "lat": "45.0944320",
      "lon": "9.3618697",
      "superficie_kmq": "22.4871",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018006",
      "denominazione_ita_altra": "Badia Pavese",
      "denominazione_ita": "Badia Pavese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A538",
      "lat": "45.1211743",
      "lon": "9.4685490",
      "superficie_kmq": "5.0572",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018007",
      "denominazione_ita_altra": "Bagnaria",
      "denominazione_ita": "Bagnaria",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A550",
      "lat": "44.8260717",
      "lon": "9.1243389",
      "superficie_kmq": "16.6641",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018008",
      "denominazione_ita_altra": "Barbianello",
      "denominazione_ita": "Barbianello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A634",
      "lat": "45.0757026",
      "lon": "9.2059973",
      "superficie_kmq": "11.7124",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018009",
      "denominazione_ita_altra": "Bascapè",
      "denominazione_ita": "Bascapè",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A690",
      "lat": "45.3046469",
      "lon": "9.3154215",
      "superficie_kmq": "13.3380",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018011",
      "denominazione_ita_altra": "Bastida Pancarana",
      "denominazione_ita": "Bastida Pancarana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A712",
      "lat": "45.0863030",
      "lon": "9.0915536",
      "superficie_kmq": "12.5000",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018012",
      "denominazione_ita_altra": "Battuda",
      "denominazione_ita": "Battuda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A718",
      "lat": "45.2735783",
      "lon": "9.0774076",
      "superficie_kmq": "7.1426",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018013",
      "denominazione_ita_altra": "Belgioioso",
      "denominazione_ita": "Belgioioso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A741",
      "lat": "45.1627375",
      "lon": "9.3160264",
      "superficie_kmq": "24.6867",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018014",
      "denominazione_ita_altra": "Bereguardo",
      "denominazione_ita": "Bereguardo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A792",
      "lat": "45.2574486",
      "lon": "9.0244955",
      "superficie_kmq": "17.8636",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018015",
      "denominazione_ita_altra": "Borgarello",
      "denominazione_ita": "Borgarello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A989",
      "lat": "45.2394306",
      "lon": "9.1406891",
      "superficie_kmq": "4.8364",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018016",
      "denominazione_ita_altra": "Borgo Priolo",
      "denominazione_ita": "Borgo Priolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B028",
      "lat": "44.9665058",
      "lon": "9.1484105",
      "superficie_kmq": "28.8094",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018017",
      "denominazione_ita_altra": "Borgoratto Mormorolo",
      "denominazione_ita": "Borgoratto Mormorolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B030",
      "lat": "44.9301101",
      "lon": "9.1932211",
      "superficie_kmq": "16.1043",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018018",
      "denominazione_ita_altra": "Borgo San Siro",
      "denominazione_ita": "Borgo San Siro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B038",
      "lat": "45.2348379",
      "lon": "8.9126928",
      "superficie_kmq": "17.6391",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018019",
      "denominazione_ita_altra": "Bornasco",
      "denominazione_ita": "Bornasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B051",
      "lat": "45.2663794",
      "lon": "9.2183550",
      "superficie_kmq": "12.9286",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018020",
      "denominazione_ita_altra": "Bosnasco",
      "denominazione_ita": "Bosnasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B082",
      "lat": "45.0639979",
      "lon": "9.3568954",
      "superficie_kmq": "4.8363",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018021",
      "denominazione_ita_altra": "Brallo di Pregola",
      "denominazione_ita": "Brallo di Pregola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B117",
      "lat": "44.7384499",
      "lon": "9.2818906",
      "superficie_kmq": "46.1441",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018022",
      "denominazione_ita_altra": "Breme",
      "denominazione_ita": "Breme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B142",
      "lat": "45.1279025",
      "lon": "8.6233204",
      "superficie_kmq": "18.8137",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018023",
      "denominazione_ita_altra": "Bressana Bottarone",
      "denominazione_ita": "Bressana Bottarone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B159",
      "lat": "45.0736421",
      "lon": "9.1350806",
      "superficie_kmq": "12.6873",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018024",
      "denominazione_ita_altra": "Broni",
      "denominazione_ita": "Broni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B201",
      "lat": "45.0619693",
      "lon": "9.2612981",
      "superficie_kmq": "20.8467",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018025",
      "denominazione_ita_altra": "Calvignano",
      "denominazione_ita": "Calvignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B447",
      "lat": "44.9826696",
      "lon": "9.1688433",
      "superficie_kmq": "6.9837",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018026",
      "denominazione_ita_altra": "Campospinoso Albaredo",
      "denominazione_ita": "Campospinoso Albaredo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B567",
      "lat": "45.0936042",
      "lon": "9.2466949",
      "superficie_kmq": "3.6864",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018027",
      "denominazione_ita_altra": "Candia Lomellina",
      "denominazione_ita": "Candia Lomellina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B587",
      "lat": "45.1747378",
      "lon": "8.5981645",
      "superficie_kmq": "27.8967",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018029",
      "denominazione_ita_altra": "Canneto Pavese",
      "denominazione_ita": "Canneto Pavese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B613",
      "lat": "45.0511568",
      "lon": "9.2809802",
      "superficie_kmq": "5.8147",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018030",
      "denominazione_ita_altra": "Carbonara al Ticino",
      "denominazione_ita": "Carbonara al Ticino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B741",
      "lat": "45.1653180",
      "lon": "9.0590497",
      "superficie_kmq": "14.7807",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018031",
      "denominazione_ita_altra": "Casanova Lonati",
      "denominazione_ita": "Casanova Lonati",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B929",
      "lat": "45.0944157",
      "lon": "9.2137360",
      "superficie_kmq": "4.6302",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018032",
      "denominazione_ita_altra": "Casatisma",
      "denominazione_ita": "Casatisma",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B945",
      "lat": "45.0456877",
      "lon": "9.1309639",
      "superficie_kmq": "5.4774",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018033",
      "denominazione_ita_altra": "Casei Gerola",
      "denominazione_ita": "Casei Gerola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B954",
      "lat": "45.0063063",
      "lon": "8.9270731",
      "superficie_kmq": "24.8050",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018034",
      "denominazione_ita_altra": "Casorate Primo",
      "denominazione_ita": "Casorate Primo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B988",
      "lat": "45.3119272",
      "lon": "9.0181390",
      "superficie_kmq": "9.7359",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018035",
      "denominazione_ita_altra": "Cassolnovo",
      "denominazione_ita": "Cassolnovo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C038",
      "lat": "45.3658308",
      "lon": "8.8089459",
      "superficie_kmq": "31.7358",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018036",
      "denominazione_ita_altra": "Castana",
      "denominazione_ita": "Castana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C050",
      "lat": "45.0270248",
      "lon": "9.2718980",
      "superficie_kmq": "5.2772",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018037",
      "denominazione_ita_altra": "Casteggio",
      "denominazione_ita": "Casteggio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C053",
      "lat": "45.0123980",
      "lon": "9.1249487",
      "superficie_kmq": "17.6617",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018038",
      "denominazione_ita_altra": "Castelletto di Branduzzo",
      "denominazione_ita": "Castelletto di Branduzzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C157",
      "lat": "45.0701867",
      "lon": "9.0976664",
      "superficie_kmq": "11.7724",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018039",
      "denominazione_ita_altra": "Castello d'Agogna",
      "denominazione_ita": "Castello d'Agogna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C184",
      "lat": "45.2360065",
      "lon": "8.6893785",
      "superficie_kmq": "10.7444",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018040",
      "denominazione_ita_altra": "Castelnovetto",
      "denominazione_ita": "Castelnovetto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C213",
      "lat": "45.2543773",
      "lon": "8.6113990",
      "superficie_kmq": "18.2141",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018041",
      "denominazione_ita_altra": "Cava Manara",
      "denominazione_ita": "Cava Manara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C360",
      "lat": "45.1388744",
      "lon": "9.1071057",
      "superficie_kmq": "17.2590",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018042",
      "denominazione_ita_altra": "Cecima",
      "denominazione_ita": "Cecima",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C414",
      "lat": "44.8478108",
      "lon": "9.0816860",
      "superficie_kmq": "10.1244",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018043",
      "denominazione_ita_altra": "Ceranova",
      "denominazione_ita": "Ceranova",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C484",
      "lat": "45.2600141",
      "lon": "9.2422605",
      "superficie_kmq": "4.5977",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018044",
      "denominazione_ita_altra": "Ceretto Lomellina",
      "denominazione_ita": "Ceretto Lomellina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C508",
      "lat": "45.2448215",
      "lon": "8.6724128",
      "superficie_kmq": "7.3786",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018045",
      "denominazione_ita_altra": "Cergnago",
      "denominazione_ita": "Cergnago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C509",
      "lat": "45.1971732",
      "lon": "8.7690863",
      "superficie_kmq": "13.5598",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018046",
      "denominazione_ita_altra": "Certosa di Pavia",
      "denominazione_ita": "Certosa di Pavia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C541",
      "lat": "45.2543468",
      "lon": "9.1274166",
      "superficie_kmq": "10.8579",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018047",
      "denominazione_ita_altra": "Cervesina",
      "denominazione_ita": "Cervesina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C551",
      "lat": "45.0611730",
      "lon": "9.0177590",
      "superficie_kmq": "12.4109",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018048",
      "denominazione_ita_altra": "Chignolo Po",
      "denominazione_ita": "Chignolo Po",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C637",
      "lat": "45.1525153",
      "lon": "9.4882052",
      "superficie_kmq": "23.3884",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018049",
      "denominazione_ita_altra": "Cigognola",
      "denominazione_ita": "Cigognola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C684",
      "lat": "45.0330934",
      "lon": "9.2437713",
      "superficie_kmq": "7.8757",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018050",
      "denominazione_ita_altra": "Cilavegna",
      "denominazione_ita": "Cilavegna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C686",
      "lat": "45.3104323",
      "lon": "8.7436772",
      "superficie_kmq": "18.0511",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018051",
      "denominazione_ita_altra": "Codevilla",
      "denominazione_ita": "Codevilla",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C813",
      "lat": "44.9639970",
      "lon": "9.0554950",
      "superficie_kmq": "12.9551",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018052",
      "denominazione_ita_altra": "Confienza",
      "denominazione_ita": "Confienza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C958",
      "lat": "45.3319184",
      "lon": "8.5576023",
      "superficie_kmq": "26.8065",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018053",
      "denominazione_ita_altra": "Copiano",
      "denominazione_ita": "Copiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C979",
      "lat": "45.1974791",
      "lon": "9.3209068",
      "superficie_kmq": "4.3401",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018054",
      "denominazione_ita_altra": "Corana",
      "denominazione_ita": "Corana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C982",
      "lat": "45.0628848",
      "lon": "8.9694905",
      "superficie_kmq": "12.8710",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018057",
      "denominazione_ita_altra": "Corvino San Quirico",
      "denominazione_ita": "Corvino San Quirico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D081",
      "lat": "45.0155620",
      "lon": "9.1589225",
      "superficie_kmq": "4.3728",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018058",
      "denominazione_ita_altra": "Costa de' Nobili",
      "denominazione_ita": "Costa de' Nobili",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D109",
      "lat": "45.1324210",
      "lon": "9.3787899",
      "superficie_kmq": "11.8242",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018059",
      "denominazione_ita_altra": "Cozzo",
      "denominazione_ita": "Cozzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D127",
      "lat": "45.1921259",
      "lon": "8.6110973",
      "superficie_kmq": "17.6095",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018060",
      "denominazione_ita_altra": "Cura Carpignano",
      "denominazione_ita": "Cura Carpignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B824",
      "lat": "45.2117956",
      "lon": "9.2570704",
      "superficie_kmq": "11.0854",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018061",
      "denominazione_ita_altra": "Dorno",
      "denominazione_ita": "Dorno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D348",
      "lat": "45.1548543",
      "lon": "8.9519547",
      "superficie_kmq": "30.5665",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018062",
      "denominazione_ita_altra": "Ferrera Erbognone",
      "denominazione_ita": "Ferrera Erbognone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D552",
      "lat": "45.1132852",
      "lon": "8.8637691",
      "superficie_kmq": "19.1708",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018063",
      "denominazione_ita_altra": "Filighera",
      "denominazione_ita": "Filighera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D594",
      "lat": "45.1773418",
      "lon": "9.3140723",
      "superficie_kmq": "8.2478",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018064",
      "denominazione_ita_altra": "Fortunago",
      "denominazione_ita": "Fortunago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D732",
      "lat": "44.9215930",
      "lon": "9.1846855",
      "superficie_kmq": "17.8248",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018065",
      "denominazione_ita_altra": "Frascarolo",
      "denominazione_ita": "Frascarolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D771",
      "lat": "45.0469011",
      "lon": "8.6827985",
      "superficie_kmq": "24.1753",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018066",
      "denominazione_ita_altra": "Galliavola",
      "denominazione_ita": "Galliavola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D873",
      "lat": "45.0971837",
      "lon": "8.8186262",
      "superficie_kmq": "9.2313",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018067",
      "denominazione_ita_altra": "Gambarana",
      "denominazione_ita": "Gambarana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D892",
      "lat": "45.0287335",
      "lon": "8.7627975",
      "superficie_kmq": "11.7761",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018068",
      "denominazione_ita_altra": "Gambolò",
      "denominazione_ita": "Gambolò",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D901",
      "lat": "45.2497998",
      "lon": "8.8615853",
      "superficie_kmq": "51.7030",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018069",
      "denominazione_ita_altra": "Garlasco",
      "denominazione_ita": "Garlasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D925",
      "lat": "45.1963850",
      "lon": "8.9235376",
      "superficie_kmq": "39.1760",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018071",
      "denominazione_ita_altra": "Gerenzago",
      "denominazione_ita": "Gerenzago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D980",
      "lat": "45.2035574",
      "lon": "9.3622049",
      "superficie_kmq": "5.4113",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018072",
      "denominazione_ita_altra": "Giussago",
      "denominazione_ita": "Giussago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E062",
      "lat": "45.2842958",
      "lon": "9.1399374",
      "superficie_kmq": "24.7226",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018073",
      "denominazione_ita_altra": "Godiasco Salice Terme",
      "denominazione_ita": "Godiasco Salice Terme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E072",
      "lat": "44.8975690",
      "lon": "9.0579721",
      "superficie_kmq": "20.6103",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018074",
      "denominazione_ita_altra": "Golferenzo",
      "denominazione_ita": "Golferenzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E081",
      "lat": "44.9617555",
      "lon": "9.3069685",
      "superficie_kmq": "4.4207",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018075",
      "denominazione_ita_altra": "Gravellona Lomellina",
      "denominazione_ita": "Gravellona Lomellina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E152",
      "lat": "45.3288521",
      "lon": "8.7639962",
      "superficie_kmq": "20.3439",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018076",
      "denominazione_ita_altra": "Gropello Cairoli",
      "denominazione_ita": "Gropello Cairoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E195",
      "lat": "45.1771779",
      "lon": "8.9921476",
      "superficie_kmq": "26.2160",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018077",
      "denominazione_ita_altra": "Inverno e Monteleone",
      "denominazione_ita": "Inverno e Monteleone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E310",
      "lat": "45.1985823",
      "lon": "9.3842643",
      "superficie_kmq": "9.6414",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018078",
      "denominazione_ita_altra": "Landriano",
      "denominazione_ita": "Landriano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E437",
      "lat": "45.3112849",
      "lon": "9.2595871",
      "superficie_kmq": "15.5872",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018079",
      "denominazione_ita_altra": "Langosco",
      "denominazione_ita": "Langosco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E439",
      "lat": "45.2153345",
      "lon": "8.5628439",
      "superficie_kmq": "15.8206",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018080",
      "denominazione_ita_altra": "Lardirago",
      "denominazione_ita": "Lardirago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E454",
      "lat": "45.2354389",
      "lon": "9.2343757",
      "superficie_kmq": "5.3416",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018081",
      "denominazione_ita_altra": "Linarolo",
      "denominazione_ita": "Linarolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E600",
      "lat": "45.1602124",
      "lon": "9.2678855",
      "superficie_kmq": "13.1660",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018082",
      "denominazione_ita_altra": "Lirio",
      "denominazione_ita": "Lirio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E608",
      "lat": "44.9940107",
      "lon": "9.2555359",
      "superficie_kmq": "1.7457",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018083",
      "denominazione_ita_altra": "Lomello",
      "denominazione_ita": "Lomello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E662",
      "lat": "45.1217667",
      "lon": "8.7926524",
      "superficie_kmq": "22.3585",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018084",
      "denominazione_ita_altra": "Lungavilla",
      "denominazione_ita": "Lungavilla",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B387",
      "lat": "45.0449046",
      "lon": "9.0785878",
      "superficie_kmq": "6.8204",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018085",
      "denominazione_ita_altra": "Magherno",
      "denominazione_ita": "Magherno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E804",
      "lat": "45.2224514",
      "lon": "9.3294956",
      "superficie_kmq": "5.2532",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018086",
      "denominazione_ita_altra": "Marcignago",
      "denominazione_ita": "Marcignago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E934",
      "lat": "45.2543542",
      "lon": "9.0773690",
      "superficie_kmq": "10.1177",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018087",
      "denominazione_ita_altra": "Marzano",
      "denominazione_ita": "Marzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E999",
      "lat": "45.2475880",
      "lon": "9.2945764",
      "superficie_kmq": "9.2895",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018088",
      "denominazione_ita_altra": "Mede",
      "denominazione_ita": "Mede",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F080",
      "lat": "45.0969380",
      "lon": "8.7366136",
      "superficie_kmq": "32.8944",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018089",
      "denominazione_ita_altra": "Menconico",
      "denominazione_ita": "Menconico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F122",
      "lat": "44.7959454",
      "lon": "9.2790612",
      "superficie_kmq": "28.1384",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018090",
      "denominazione_ita_altra": "Mezzana Bigli",
      "denominazione_ita": "Mezzana Bigli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F170",
      "lat": "45.0608793",
      "lon": "8.8459606",
      "superficie_kmq": "19.0204",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018091",
      "denominazione_ita_altra": "Mezzana Rabattone",
      "denominazione_ita": "Mezzana Rabattone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F171",
      "lat": "45.0947054",
      "lon": "9.0322926",
      "superficie_kmq": "7.0642",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018092",
      "denominazione_ita_altra": "Mezzanino",
      "denominazione_ita": "Mezzanino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F175",
      "lat": "45.1263866",
      "lon": "9.2068132",
      "superficie_kmq": "12.5106",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018093",
      "denominazione_ita_altra": "Miradolo Terme",
      "denominazione_ita": "Miradolo Terme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F238",
      "lat": "45.1716359",
      "lon": "9.4431700",
      "superficie_kmq": "9.5559",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018094",
      "denominazione_ita_altra": "Montalto Pavese",
      "denominazione_ita": "Montalto Pavese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F417",
      "lat": "44.9796095",
      "lon": "9.2073355",
      "superficie_kmq": "19.0651",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018095",
      "denominazione_ita_altra": "Montebello della Battaglia",
      "denominazione_ita": "Montebello della Battaglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F440",
      "lat": "44.9996991",
      "lon": "9.1010073",
      "superficie_kmq": "15.7416",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018096",
      "denominazione_ita_altra": "Montecalvo Versiggia",
      "denominazione_ita": "Montecalvo Versiggia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F449",
      "lat": "44.9704020",
      "lon": "9.2839867",
      "superficie_kmq": "11.4035",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018097",
      "denominazione_ita_altra": "Montescano",
      "denominazione_ita": "Montescano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F638",
      "lat": "45.0318023",
      "lon": "9.2973354",
      "superficie_kmq": "2.4046",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018098",
      "denominazione_ita_altra": "Montesegale",
      "denominazione_ita": "Montesegale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F644",
      "lat": "44.9063687",
      "lon": "9.1268967",
      "superficie_kmq": "14.9744",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018099",
      "denominazione_ita_altra": "Monticelli Pavese",
      "denominazione_ita": "Monticelli Pavese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F670",
      "lat": "45.1105066",
      "lon": "9.5127402",
      "superficie_kmq": "20.1925",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018100",
      "denominazione_ita_altra": "Montù Beccaria",
      "denominazione_ita": "Montù Beccaria",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F701",
      "lat": "45.0388337",
      "lon": "9.3124184",
      "superficie_kmq": "15.4933",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018101",
      "denominazione_ita_altra": "Mornico Losana",
      "denominazione_ita": "Mornico Losana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F739",
      "lat": "45.0092180",
      "lon": "9.2064709",
      "superficie_kmq": "8.2991",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018102",
      "denominazione_ita_altra": "Mortara",
      "denominazione_ita": "Mortara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F754",
      "lat": "45.2518398",
      "lon": "8.7380653",
      "superficie_kmq": "51.9694",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018103",
      "denominazione_ita_altra": "Nicorvo",
      "denominazione_ita": "Nicorvo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F891",
      "lat": "45.2851490",
      "lon": "8.6671885",
      "superficie_kmq": "8.0764",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018104",
      "denominazione_ita_altra": "Olevano di Lomellina",
      "denominazione_ita": "Olevano di Lomellina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G021",
      "lat": "45.2132192",
      "lon": "8.7174748",
      "superficie_kmq": "15.3810",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018105",
      "denominazione_ita_altra": "Oliva Gessi",
      "denominazione_ita": "Oliva Gessi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G032",
      "lat": "45.0053316",
      "lon": "9.1736267",
      "superficie_kmq": "3.9101",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018106",
      "denominazione_ita_altra": "Ottobiano",
      "denominazione_ita": "Ottobiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G194",
      "lat": "45.1512716",
      "lon": "8.8321528",
      "superficie_kmq": "24.9793",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018107",
      "denominazione_ita_altra": "Palestro",
      "denominazione_ita": "Palestro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G275",
      "lat": "45.3058435",
      "lon": "8.5346774",
      "superficie_kmq": "18.8064",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018108",
      "denominazione_ita_altra": "Pancarana",
      "denominazione_ita": "Pancarana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G304",
      "lat": "45.0752287",
      "lon": "9.0510646",
      "superficie_kmq": "6.1018",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018109",
      "denominazione_ita_altra": "Parona",
      "denominazione_ita": "Parona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G342",
      "lat": "45.2826156",
      "lon": "8.7498184",
      "superficie_kmq": "9.3012",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018110",
      "denominazione_ita_altra": "Pavia",
      "denominazione_ita": "Pavia",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "G388",
      "lat": "45.1850937",
      "lon": "9.1601572",
      "superficie_kmq": "63.2453",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018111",
      "denominazione_ita_altra": "Pietra de' Giorgi",
      "denominazione_ita": "Pietra de' Giorgi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G612",
      "lat": "45.0208244",
      "lon": "9.2297646",
      "superficie_kmq": "11.2007",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018112",
      "denominazione_ita_altra": "Pieve Albignola",
      "denominazione_ita": "Pieve Albignola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G635",
      "lat": "45.1131266",
      "lon": "8.9603134",
      "superficie_kmq": "18.1530",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018113",
      "denominazione_ita_altra": "Pieve del Cairo",
      "denominazione_ita": "Pieve del Cairo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G639",
      "lat": "45.0489735",
      "lon": "8.8051010",
      "superficie_kmq": "25.1059",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018114",
      "denominazione_ita_altra": "Pieve Porto Morone",
      "denominazione_ita": "Pieve Porto Morone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G650",
      "lat": "45.1125098",
      "lon": "9.4384574",
      "superficie_kmq": "16.3963",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018115",
      "denominazione_ita_altra": "Pinarolo Po",
      "denominazione_ita": "Pinarolo Po",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G671",
      "lat": "45.0660292",
      "lon": "9.1648143",
      "superficie_kmq": "11.3078",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018116",
      "denominazione_ita_altra": "Pizzale",
      "denominazione_ita": "Pizzale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G720",
      "lat": "45.0343128",
      "lon": "9.0473148",
      "superficie_kmq": "7.0903",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018117",
      "denominazione_ita_altra": "Ponte Nizza",
      "denominazione_ita": "Ponte Nizza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G851",
      "lat": "44.8518701",
      "lon": "9.0973700",
      "superficie_kmq": "22.9572",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018118",
      "denominazione_ita_altra": "Portalbera",
      "denominazione_ita": "Portalbera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G895",
      "lat": "45.0994067",
      "lon": "9.3225955",
      "superficie_kmq": "4.4798",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018119",
      "denominazione_ita_altra": "Rea",
      "denominazione_ita": "Rea",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H204",
      "lat": "45.1138690",
      "lon": "9.1543400",
      "superficie_kmq": "2.1597",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018120",
      "denominazione_ita_altra": "Redavalle",
      "denominazione_ita": "Redavalle",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H216",
      "lat": "45.0377308",
      "lon": "9.2040177",
      "superficie_kmq": "5.4149",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018121",
      "denominazione_ita_altra": "Retorbido",
      "denominazione_ita": "Retorbido",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H246",
      "lat": "44.9492256",
      "lon": "9.0363454",
      "superficie_kmq": "11.6677",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018122",
      "denominazione_ita_altra": "Rivanazzano Terme",
      "denominazione_ita": "Rivanazzano Terme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H336",
      "lat": "44.9305283",
      "lon": "9.0164472",
      "superficie_kmq": "28.9155",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018123",
      "denominazione_ita_altra": "Robbio",
      "denominazione_ita": "Robbio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H369",
      "lat": "45.2891186",
      "lon": "8.5939575",
      "superficie_kmq": "40.5407",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018124",
      "denominazione_ita_altra": "Robecco Pavese",
      "denominazione_ita": "Robecco Pavese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H375",
      "lat": "45.0484201",
      "lon": "9.1486543",
      "superficie_kmq": "6.9345",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018125",
      "denominazione_ita_altra": "Rocca de' Giorgi",
      "denominazione_ita": "Rocca de' Giorgi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H396",
      "lat": "44.9715822",
      "lon": "9.2521665",
      "superficie_kmq": "10.4966",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018126",
      "denominazione_ita_altra": "Rocca Susella",
      "denominazione_ita": "Rocca Susella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H450",
      "lat": "44.9127556",
      "lon": "9.0959654",
      "superficie_kmq": "12.7641",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018127",
      "denominazione_ita_altra": "Rognano",
      "denominazione_ita": "Rognano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H491",
      "lat": "45.2888182",
      "lon": "9.0899221",
      "superficie_kmq": "9.3647",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018128",
      "denominazione_ita_altra": "Romagnese",
      "denominazione_ita": "Romagnese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H505",
      "lat": "44.8372256",
      "lon": "9.3294808",
      "superficie_kmq": "29.7174",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018129",
      "denominazione_ita_altra": "Roncaro",
      "denominazione_ita": "Roncaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H527",
      "lat": "45.2257293",
      "lon": "9.2769236",
      "superficie_kmq": "5.0510",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018130",
      "denominazione_ita_altra": "Rosasco",
      "denominazione_ita": "Rosasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H559",
      "lat": "45.2503045",
      "lon": "8.5792724",
      "superficie_kmq": "19.5525",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018131",
      "denominazione_ita_altra": "Rovescala",
      "denominazione_ita": "Rovescala",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H614",
      "lat": "45.0137448",
      "lon": "9.3501270",
      "superficie_kmq": "8.4128",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018133",
      "denominazione_ita_altra": "San Cipriano Po",
      "denominazione_ita": "San Cipriano Po",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H799",
      "lat": "45.1090875",
      "lon": "9.2808622",
      "superficie_kmq": "8.5045",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018134",
      "denominazione_ita_altra": "San Damiano al Colle",
      "denominazione_ita": "San Damiano al Colle",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H814",
      "lat": "45.0271198",
      "lon": "9.3485722",
      "superficie_kmq": "6.4309",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018135",
      "denominazione_ita_altra": "San Genesio ed Uniti",
      "denominazione_ita": "San Genesio ed Uniti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H859",
      "lat": "45.2328507",
      "lon": "9.1798733",
      "superficie_kmq": "9.2687",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018136",
      "denominazione_ita_altra": "San Giorgio di Lomellina",
      "denominazione_ita": "San Giorgio di Lomellina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H885",
      "lat": "45.1738080",
      "lon": "8.7911179",
      "superficie_kmq": "25.4521",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018137",
      "denominazione_ita_altra": "San Martino Siccomario",
      "denominazione_ita": "San Martino Siccomario",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I014",
      "lat": "45.1571602",
      "lon": "9.1345198",
      "superficie_kmq": "14.2906",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018138",
      "denominazione_ita_altra": "Sannazzaro de' Burgondi",
      "denominazione_ita": "Sannazzaro de' Burgondi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I048",
      "lat": "45.1031629",
      "lon": "8.9082898",
      "superficie_kmq": "23.3323",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018139",
      "denominazione_ita_altra": "Santa Cristina e Bissone",
      "denominazione_ita": "Santa Cristina e Bissone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I175",
      "lat": "45.1567951",
      "lon": "9.4011357",
      "superficie_kmq": "22.4207",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018140",
      "denominazione_ita_altra": "Santa Giuletta",
      "denominazione_ita": "Santa Giuletta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I203",
      "lat": "45.0338391",
      "lon": "9.1815945",
      "superficie_kmq": "11.5912",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018141",
      "denominazione_ita_altra": "Sant'Alessio con Vialone",
      "denominazione_ita": "Sant'Alessio con Vialone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I213",
      "lat": "45.2220993",
      "lon": "9.2250626",
      "superficie_kmq": "6.5572",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018142",
      "denominazione_ita_altra": "Santa Margherita di Staffora",
      "denominazione_ita": "Santa Margherita di Staffora",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I230",
      "lat": "44.7707863",
      "lon": "9.2405581",
      "superficie_kmq": "36.9011",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018143",
      "denominazione_ita_altra": "Santa Maria della Versa",
      "denominazione_ita": "Santa Maria della Versa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I237",
      "lat": "44.9846876",
      "lon": "9.2999448",
      "superficie_kmq": "18.4738",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018144",
      "denominazione_ita_altra": "Sant'Angelo Lomellina",
      "denominazione_ita": "Sant'Angelo Lomellina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I276",
      "lat": "45.2466233",
      "lon": "8.6431448",
      "superficie_kmq": "10.4973",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018145",
      "denominazione_ita_altra": "San Zenone al Po",
      "denominazione_ita": "San Zenone al Po",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I416",
      "lat": "45.1085453",
      "lon": "9.3611446",
      "superficie_kmq": "6.8927",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018146",
      "denominazione_ita_altra": "Sartirana Lomellina",
      "denominazione_ita": "Sartirana Lomellina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I447",
      "lat": "45.1143405",
      "lon": "8.6618549",
      "superficie_kmq": "29.5419",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018147",
      "denominazione_ita_altra": "Scaldasole",
      "denominazione_ita": "Scaldasole",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I487",
      "lat": "45.1249381",
      "lon": "8.9101903",
      "superficie_kmq": "11.5741",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018148",
      "denominazione_ita_altra": "Semiana",
      "denominazione_ita": "Semiana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I599",
      "lat": "45.1373702",
      "lon": "8.7297051",
      "superficie_kmq": "9.7208",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018149",
      "denominazione_ita_altra": "Silvano Pietra",
      "denominazione_ita": "Silvano Pietra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I739",
      "lat": "45.0399646",
      "lon": "8.9481787",
      "superficie_kmq": "13.6711",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018150",
      "denominazione_ita_altra": "Siziano",
      "denominazione_ita": "Siziano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E265",
      "lat": "45.3201701",
      "lon": "9.1987249",
      "superficie_kmq": "11.7879",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018151",
      "denominazione_ita_altra": "Sommo",
      "denominazione_ita": "Sommo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I825",
      "lat": "45.1322351",
      "lon": "9.0837755",
      "superficie_kmq": "14.8724",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018152",
      "denominazione_ita_altra": "Spessa",
      "denominazione_ita": "Spessa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I894",
      "lat": "45.1137385",
      "lon": "9.3492271",
      "superficie_kmq": "12.2314",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018153",
      "denominazione_ita_altra": "Stradella",
      "denominazione_ita": "Stradella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I968",
      "lat": "45.0753053",
      "lon": "9.2951673",
      "superficie_kmq": "18.8352",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018154",
      "denominazione_ita_altra": "Suardi",
      "denominazione_ita": "Suardi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B014",
      "lat": "45.0294513",
      "lon": "8.7430481",
      "superficie_kmq": "9.8480",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018155",
      "denominazione_ita_altra": "Torrazza Coste",
      "denominazione_ita": "Torrazza Coste",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L237",
      "lat": "44.9765479",
      "lon": "9.0864455",
      "superficie_kmq": "16.2281",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018156",
      "denominazione_ita_altra": "Torre Beretti e Castellaro",
      "denominazione_ita": "Torre Beretti e Castellaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L250",
      "lat": "45.0599661",
      "lon": "8.6703055",
      "superficie_kmq": "17.6600",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018157",
      "denominazione_ita_altra": "Torre d'Arese",
      "denominazione_ita": "Torre d'Arese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L256",
      "lat": "45.2424033",
      "lon": "9.3168167",
      "superficie_kmq": "4.4934",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018158",
      "denominazione_ita_altra": "Torre de' Negri",
      "denominazione_ita": "Torre de' Negri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L262",
      "lat": "45.1506635",
      "lon": "9.3356021",
      "superficie_kmq": "4.0091",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018159",
      "denominazione_ita_altra": "Torre d'Isola",
      "denominazione_ita": "Torre d'Isola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L269",
      "lat": "45.2174665",
      "lon": "9.0763969",
      "superficie_kmq": "16.4385",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018160",
      "denominazione_ita_altra": "Torrevecchia Pia",
      "denominazione_ita": "Torrevecchia Pia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L285",
      "lat": "45.2828724",
      "lon": "9.2979266",
      "superficie_kmq": "16.5019",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018161",
      "denominazione_ita_altra": "Torricella Verzate",
      "denominazione_ita": "Torricella Verzate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L292",
      "lat": "45.0155940",
      "lon": "9.1758421",
      "superficie_kmq": "3.6271",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018162",
      "denominazione_ita_altra": "Travacò Siccomario",
      "denominazione_ita": "Travacò Siccomario",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I236",
      "lat": "45.1524826",
      "lon": "9.1615162",
      "superficie_kmq": "17.0449",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018163",
      "denominazione_ita_altra": "Trivolzio",
      "denominazione_ita": "Trivolzio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L440",
      "lat": "45.2609455",
      "lon": "9.0470812",
      "superficie_kmq": "3.8312",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018164",
      "denominazione_ita_altra": "Tromello",
      "denominazione_ita": "Tromello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L449",
      "lat": "45.2118397",
      "lon": "8.8695975",
      "superficie_kmq": "35.4982",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018165",
      "denominazione_ita_altra": "Trovo",
      "denominazione_ita": "Trovo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L453",
      "lat": "45.2828521",
      "lon": "9.0357682",
      "superficie_kmq": "8.1549",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018166",
      "denominazione_ita_altra": "Val di Nizza",
      "denominazione_ita": "Val di Nizza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L562",
      "lat": "44.8793294",
      "lon": "9.1668330",
      "superficie_kmq": "29.6826",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018167",
      "denominazione_ita_altra": "Valeggio",
      "denominazione_ita": "Valeggio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L568",
      "lat": "45.1506438",
      "lon": "8.8612911",
      "superficie_kmq": "9.8538",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018168",
      "denominazione_ita_altra": "Valle Lomellina",
      "denominazione_ita": "Valle Lomellina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L593",
      "lat": "45.1471176",
      "lon": "8.6649555",
      "superficie_kmq": "27.2357",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018169",
      "denominazione_ita_altra": "Valle Salimbene",
      "denominazione_ita": "Valle Salimbene",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L617",
      "lat": "45.1696282",
      "lon": "9.2329822",
      "superficie_kmq": "7.1611",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018171",
      "denominazione_ita_altra": "Varzi",
      "denominazione_ita": "Varzi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L690",
      "lat": "44.8219952",
      "lon": "9.1962725",
      "superficie_kmq": "57.6150",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018172",
      "denominazione_ita_altra": "Velezzo Lomellina",
      "denominazione_ita": "Velezzo Lomellina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L716",
      "lat": "45.1637078",
      "lon": "8.7372101",
      "superficie_kmq": "8.1723",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018173",
      "denominazione_ita_altra": "Vellezzo Bellini",
      "denominazione_ita": "Vellezzo Bellini",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L720",
      "lat": "45.2695306",
      "lon": "9.1021328",
      "superficie_kmq": "8.1961",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018174",
      "denominazione_ita_altra": "Verretto",
      "denominazione_ita": "Verretto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L784",
      "lat": "45.0400395",
      "lon": "9.1076969",
      "superficie_kmq": "2.7086",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018175",
      "denominazione_ita_altra": "Verrua Po",
      "denominazione_ita": "Verrua Po",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L788",
      "lat": "45.1113296",
      "lon": "9.1731670",
      "superficie_kmq": "11.4404",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018176",
      "denominazione_ita_altra": "Vidigulfo",
      "denominazione_ita": "Vidigulfo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L854",
      "lat": "45.2918770",
      "lon": "9.2348314",
      "superficie_kmq": "16.1425",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018177",
      "denominazione_ita_altra": "Vigevano",
      "denominazione_ita": "Vigevano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L872",
      "lat": "45.3176169",
      "lon": "8.8598756",
      "superficie_kmq": "81.3639",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018178",
      "denominazione_ita_altra": "Villa Biscossi",
      "denominazione_ita": "Villa Biscossi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L917",
      "lat": "45.0899572",
      "lon": "8.7866890",
      "superficie_kmq": "4.8781",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018179",
      "denominazione_ita_altra": "Villanova d'Ardenghi",
      "denominazione_ita": "Villanova d'Ardenghi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L983",
      "lat": "45.1713375",
      "lon": "9.0432448",
      "superficie_kmq": "6.6085",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018180",
      "denominazione_ita_altra": "Villanterio",
      "denominazione_ita": "Villanterio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L994",
      "lat": "45.2207201",
      "lon": "9.3604107",
      "superficie_kmq": "14.7674",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018181",
      "denominazione_ita_altra": "Vistarino",
      "denominazione_ita": "Vistarino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M079",
      "lat": "45.2114800",
      "lon": "9.3064586",
      "superficie_kmq": "9.4896",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018182",
      "denominazione_ita_altra": "Voghera",
      "denominazione_ita": "Voghera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M109",
      "lat": "44.9931233",
      "lon": "9.0085236",
      "superficie_kmq": "63.4415",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018183",
      "denominazione_ita_altra": "Volpara",
      "denominazione_ita": "Volpara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M119",
      "lat": "44.9537863",
      "lon": "9.2974762",
      "superficie_kmq": "3.7730",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018184",
      "denominazione_ita_altra": "Zavattarello",
      "denominazione_ita": "Zavattarello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M150",
      "lat": "44.8687413",
      "lon": "9.2654367",
      "superficie_kmq": "28.4013",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018185",
      "denominazione_ita_altra": "Zeccone",
      "denominazione_ita": "Zeccone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M152",
      "lat": "45.2609326",
      "lon": "9.1984115",
      "superficie_kmq": "5.5293",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018186",
      "denominazione_ita_altra": "Zeme",
      "denominazione_ita": "Zeme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M161",
      "lat": "45.1949305",
      "lon": "8.6663117",
      "superficie_kmq": "24.5828",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018187",
      "denominazione_ita_altra": "Zenevredo",
      "denominazione_ita": "Zenevredo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M162",
      "lat": "45.0539025",
      "lon": "9.3266431",
      "superficie_kmq": "5.4037",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018188",
      "denominazione_ita_altra": "Zerbo",
      "denominazione_ita": "Zerbo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M166",
      "lat": "45.1105528",
      "lon": "9.3954525",
      "superficie_kmq": "6.3614",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018189",
      "denominazione_ita_altra": "Zerbolò",
      "denominazione_ita": "Zerbolò",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M167",
      "lat": "45.2067620",
      "lon": "9.0143866",
      "superficie_kmq": "37.1915",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018190",
      "denominazione_ita_altra": "Zinasco",
      "denominazione_ita": "Zinasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M180",
      "lat": "45.1282468",
      "lon": "9.0284967",
      "superficie_kmq": "29.7366",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018191",
      "denominazione_ita_altra": "Cornale e Bastida",
      "denominazione_ita": "Cornale e Bastida",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M338",
      "lat": "45.0462930",
      "lon": "8.9144711",
      "superficie_kmq": "3.8218",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018192",
      "denominazione_ita_altra": "Corteolona e Genzone",
      "denominazione_ita": "Corteolona e Genzone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M372",
      "lat": "45.1669851",
      "lon": "9.3643986",
      "superficie_kmq": "14.0864",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "PV",
      "codice_istat": "018193",
      "denominazione_ita_altra": "Colli Verdi",
      "denominazione_ita": "Colli Verdi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M419",
      "lat": "44.9048501",
      "lon": "9.2493694",
      "superficie_kmq": "41.2469",
      "codice_sovracomunale": "018"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019001",
      "denominazione_ita_altra": "Acquanegra Cremonese",
      "denominazione_ita": "Acquanegra Cremonese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A039",
      "lat": "45.1683161",
      "lon": "9.8887855",
      "superficie_kmq": "9.2161",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019002",
      "denominazione_ita_altra": "Agnadello",
      "denominazione_ita": "Agnadello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A076",
      "lat": "45.4471402",
      "lon": "9.5592059",
      "superficie_kmq": "12.0775",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019003",
      "denominazione_ita_altra": "Annicco",
      "denominazione_ita": "Annicco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A299",
      "lat": "45.2447455",
      "lon": "9.8818508",
      "superficie_kmq": "19.1983",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019004",
      "denominazione_ita_altra": "Azzanello",
      "denominazione_ita": "Azzanello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A526",
      "lat": "45.3131220",
      "lon": "9.9197044",
      "superficie_kmq": "11.1210",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019005",
      "denominazione_ita_altra": "Bagnolo Cremasco",
      "denominazione_ita": "Bagnolo Cremasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A570",
      "lat": "45.3615405",
      "lon": "9.6091632",
      "superficie_kmq": "10.3878",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019006",
      "denominazione_ita_altra": "Bonemerse",
      "denominazione_ita": "Bonemerse",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A972",
      "lat": "45.1140480",
      "lon": "10.0783681",
      "superficie_kmq": "5.9007",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019007",
      "denominazione_ita_altra": "Bordolano",
      "denominazione_ita": "Bordolano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A986",
      "lat": "45.2928537",
      "lon": "9.9870515",
      "superficie_kmq": "8.1424",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019009",
      "denominazione_ita_altra": "Calvatone",
      "denominazione_ita": "Calvatone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B439",
      "lat": "45.1288312",
      "lon": "10.4420622",
      "superficie_kmq": "13.6997",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019010",
      "denominazione_ita_altra": "Camisano",
      "denominazione_ita": "Camisano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B484",
      "lat": "45.4425955",
      "lon": "9.7461930",
      "superficie_kmq": "10.9506",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019011",
      "denominazione_ita_altra": "Campagnola Cremasca",
      "denominazione_ita": "Campagnola Cremasca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B498",
      "lat": "45.3991381",
      "lon": "9.6694417",
      "superficie_kmq": "4.6407",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019012",
      "denominazione_ita_altra": "Capergnanica",
      "denominazione_ita": "Capergnanica",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B650",
      "lat": "45.3380857",
      "lon": "9.6441485",
      "superficie_kmq": "6.8381",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019013",
      "denominazione_ita_altra": "Cappella Cantone",
      "denominazione_ita": "Cappella Cantone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B679",
      "lat": "45.2465246",
      "lon": "9.8381050",
      "superficie_kmq": "13.1471",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019014",
      "denominazione_ita_altra": "Cappella de' Picenardi",
      "denominazione_ita": "Cappella de' Picenardi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B680",
      "lat": "45.1591269",
      "lon": "10.2301254",
      "superficie_kmq": "14.1977",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019015",
      "denominazione_ita_altra": "Capralba",
      "denominazione_ita": "Capralba",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B686",
      "lat": "45.4422793",
      "lon": "9.6424382",
      "superficie_kmq": "13.4543",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019016",
      "denominazione_ita_altra": "Casalbuttano ed Uniti",
      "denominazione_ita": "Casalbuttano ed Uniti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B869",
      "lat": "45.2520170",
      "lon": "9.9616825",
      "superficie_kmq": "22.8750",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019017",
      "denominazione_ita_altra": "Casale Cremasco-Vidolasco",
      "denominazione_ita": "Casale Cremasco-Vidolasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B881",
      "lat": "45.4316130",
      "lon": "9.7139029",
      "superficie_kmq": "9.1935",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019018",
      "denominazione_ita_altra": "Casaletto Ceredano",
      "denominazione_ita": "Casaletto Ceredano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B889",
      "lat": "45.3181307",
      "lon": "9.6167922",
      "superficie_kmq": "6.5180",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019019",
      "denominazione_ita_altra": "Casaletto di Sopra",
      "denominazione_ita": "Casaletto di Sopra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B890",
      "lat": "45.4186217",
      "lon": "9.7813023",
      "superficie_kmq": "8.6570",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019020",
      "denominazione_ita_altra": "Casaletto Vaprio",
      "denominazione_ita": "Casaletto Vaprio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B891",
      "lat": "45.4072795",
      "lon": "9.6290426",
      "superficie_kmq": "5.4019",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019021",
      "denominazione_ita_altra": "Casalmaggiore",
      "denominazione_ita": "Casalmaggiore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B898",
      "lat": "44.9874203",
      "lon": "10.4137807",
      "superficie_kmq": "64.5301",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019022",
      "denominazione_ita_altra": "Casalmorano",
      "denominazione_ita": "Casalmorano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B900",
      "lat": "45.2870925",
      "lon": "9.8978410",
      "superficie_kmq": "12.2823",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019023",
      "denominazione_ita_altra": "Casteldidone",
      "denominazione_ita": "Casteldidone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C089",
      "lat": "45.0706779",
      "lon": "10.4057821",
      "superficie_kmq": "10.7893",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019024",
      "denominazione_ita_altra": "Castel Gabbiano",
      "denominazione_ita": "Castel Gabbiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C115",
      "lat": "45.4684691",
      "lon": "9.7176235",
      "superficie_kmq": "5.7876",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019025",
      "denominazione_ita_altra": "Castelleone",
      "denominazione_ita": "Castelleone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C153",
      "lat": "45.2935058",
      "lon": "9.7648944",
      "superficie_kmq": "45.0774",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019026",
      "denominazione_ita_altra": "Castelverde",
      "denominazione_ita": "Castelverde",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B129",
      "lat": "45.1857086",
      "lon": "9.9969628",
      "superficie_kmq": "30.8909",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019027",
      "denominazione_ita_altra": "Castelvisconti",
      "denominazione_ita": "Castelvisconti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C290",
      "lat": "45.3056780",
      "lon": "9.9422075",
      "superficie_kmq": "9.7571",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019028",
      "denominazione_ita_altra": "Cella Dati",
      "denominazione_ita": "Cella Dati",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C435",
      "lat": "45.0961571",
      "lon": "10.2212638",
      "superficie_kmq": "18.9178",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019029",
      "denominazione_ita_altra": "Chieve",
      "denominazione_ita": "Chieve",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C634",
      "lat": "45.3375064",
      "lon": "9.6161229",
      "superficie_kmq": "6.1934",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019030",
      "denominazione_ita_altra": "Cicognolo",
      "denominazione_ita": "Cicognolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C678",
      "lat": "45.1676185",
      "lon": "10.1940419",
      "superficie_kmq": "6.9629",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019031",
      "denominazione_ita_altra": "Cingia de' Botti",
      "denominazione_ita": "Cingia de' Botti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C703",
      "lat": "45.0841598",
      "lon": "10.2752772",
      "superficie_kmq": "14.3599",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019032",
      "denominazione_ita_altra": "Corte de' Cortesi con Cignone",
      "denominazione_ita": "Corte de' Cortesi con Cignone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D056",
      "lat": "45.2726489",
      "lon": "10.0073511",
      "superficie_kmq": "12.8526",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019033",
      "denominazione_ita_altra": "Corte de' Frati",
      "denominazione_ita": "Corte de' Frati",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D057",
      "lat": "45.2200651",
      "lon": "10.0969580",
      "superficie_kmq": "20.1762",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019034",
      "denominazione_ita_altra": "Credera Rubbiano",
      "denominazione_ita": "Credera Rubbiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D141",
      "lat": "45.3028657",
      "lon": "9.6551275",
      "superficie_kmq": "14.1284",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019035",
      "denominazione_ita_altra": "Crema",
      "denominazione_ita": "Crema",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D142",
      "lat": "45.3632951",
      "lon": "9.6873619",
      "superficie_kmq": "34.5182",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019036",
      "denominazione_ita_altra": "Cremona",
      "denominazione_ita": "Cremona",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "D150",
      "lat": "45.1333671",
      "lon": "10.0242087",
      "superficie_kmq": "70.4916",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019037",
      "denominazione_ita_altra": "Cremosano",
      "denominazione_ita": "Cremosano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D151",
      "lat": "45.3957927",
      "lon": "9.6402809",
      "superficie_kmq": "5.7577",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019038",
      "denominazione_ita_altra": "Crotta d'Adda",
      "denominazione_ita": "Crotta d'Adda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D186",
      "lat": "45.1613690",
      "lon": "9.8525277",
      "superficie_kmq": "12.9377",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019039",
      "denominazione_ita_altra": "Cumignano sul Naviglio",
      "denominazione_ita": "Cumignano sul Naviglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D203",
      "lat": "45.3545582",
      "lon": "9.8363184",
      "superficie_kmq": "6.7740",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019040",
      "denominazione_ita_altra": "Derovere",
      "denominazione_ita": "Derovere",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D278",
      "lat": "45.1101422",
      "lon": "10.2483065",
      "superficie_kmq": "9.9906",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019041",
      "denominazione_ita_altra": "Dovera",
      "denominazione_ita": "Dovera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D358",
      "lat": "45.3635064",
      "lon": "9.5381977",
      "superficie_kmq": "20.6525",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019043",
      "denominazione_ita_altra": "Fiesco",
      "denominazione_ita": "Fiesco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D574",
      "lat": "45.3364009",
      "lon": "9.7785535",
      "superficie_kmq": "8.1938",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019044",
      "denominazione_ita_altra": "Formigara",
      "denominazione_ita": "Formigara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D710",
      "lat": "45.2230638",
      "lon": "9.7707379",
      "superficie_kmq": "12.6430",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019045",
      "denominazione_ita_altra": "Gabbioneta-Binanuova",
      "denominazione_ita": "Gabbioneta-Binanuova",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D834",
      "lat": "45.2158166",
      "lon": "10.2216801",
      "superficie_kmq": "15.7072",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019046",
      "denominazione_ita_altra": "Gadesco-Pieve Delmona",
      "denominazione_ita": "Gadesco-Pieve Delmona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D841",
      "lat": "45.1552968",
      "lon": "10.1160344",
      "superficie_kmq": "17.1013",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019047",
      "denominazione_ita_altra": "Genivolta",
      "denominazione_ita": "Genivolta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D966",
      "lat": "45.3348777",
      "lon": "9.8777812",
      "superficie_kmq": "18.5732",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019048",
      "denominazione_ita_altra": "Gerre de' Caprioli",
      "denominazione_ita": "Gerre de' Caprioli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D993",
      "lat": "45.1068077",
      "lon": "10.0383808",
      "superficie_kmq": "7.7187",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019049",
      "denominazione_ita_altra": "Gombito",
      "denominazione_ita": "Gombito",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E082",
      "lat": "45.2622310",
      "lon": "9.7300216",
      "superficie_kmq": "9.2764",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019050",
      "denominazione_ita_altra": "Grontardo",
      "denominazione_ita": "Grontardo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E193",
      "lat": "45.2014063",
      "lon": "10.1516175",
      "superficie_kmq": "12.2582",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019051",
      "denominazione_ita_altra": "Grumello Cremonese ed Uniti",
      "denominazione_ita": "Grumello Cremonese ed Uniti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E217",
      "lat": "45.1932490",
      "lon": "9.8673626",
      "superficie_kmq": "22.2897",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019052",
      "denominazione_ita_altra": "Gussola",
      "denominazione_ita": "Gussola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E272",
      "lat": "45.0098787",
      "lon": "10.3463579",
      "superficie_kmq": "25.2261",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019053",
      "denominazione_ita_altra": "Isola Dovarese",
      "denominazione_ita": "Isola Dovarese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E356",
      "lat": "45.1734619",
      "lon": "10.3118392",
      "superficie_kmq": "9.4655",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019054",
      "denominazione_ita_altra": "Izano",
      "denominazione_ita": "Izano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E380",
      "lat": "45.3557354",
      "lon": "9.7550930",
      "superficie_kmq": "6.1916",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019055",
      "denominazione_ita_altra": "Madignano",
      "denominazione_ita": "Madignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E793",
      "lat": "45.3433500",
      "lon": "9.7276238",
      "superficie_kmq": "10.7616",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019056",
      "denominazione_ita_altra": "Malagnino",
      "denominazione_ita": "Malagnino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E843",
      "lat": "45.1349493",
      "lon": "10.1142269",
      "superficie_kmq": "10.8226",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019057",
      "denominazione_ita_altra": "Martignana di Po",
      "denominazione_ita": "Martignana di Po",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E983",
      "lat": "45.0109020",
      "lon": "10.3792743",
      "superficie_kmq": "14.9198",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019058",
      "denominazione_ita_altra": "Monte Cremasco",
      "denominazione_ita": "Monte Cremasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F434",
      "lat": "45.3722409",
      "lon": "9.5728578",
      "superficie_kmq": "2.3428",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019059",
      "denominazione_ita_altra": "Montodine",
      "denominazione_ita": "Montodine",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F681",
      "lat": "45.2862671",
      "lon": "9.7107997",
      "superficie_kmq": "11.3943",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019060",
      "denominazione_ita_altra": "Moscazzano",
      "denominazione_ita": "Moscazzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F761",
      "lat": "45.2935813",
      "lon": "9.6813228",
      "superficie_kmq": "8.1532",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019061",
      "denominazione_ita_altra": "Motta Baluffi",
      "denominazione_ita": "Motta Baluffi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F771",
      "lat": "45.0544637",
      "lon": "10.2595911",
      "superficie_kmq": "16.4676",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019062",
      "denominazione_ita_altra": "Offanengo",
      "denominazione_ita": "Offanengo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G004",
      "lat": "45.3816075",
      "lon": "9.7413169",
      "superficie_kmq": "12.5835",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019063",
      "denominazione_ita_altra": "Olmeneta",
      "denominazione_ita": "Olmeneta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G047",
      "lat": "45.2344691",
      "lon": "10.0247433",
      "superficie_kmq": "9.1534",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019064",
      "denominazione_ita_altra": "Ostiano",
      "denominazione_ita": "Ostiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G185",
      "lat": "45.2218051",
      "lon": "10.2487326",
      "superficie_kmq": "19.4890",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019065",
      "denominazione_ita_altra": "Paderno Ponchielli",
      "denominazione_ita": "Paderno Ponchielli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G222",
      "lat": "45.2387624",
      "lon": "9.9275834",
      "superficie_kmq": "23.9619",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019066",
      "denominazione_ita_altra": "Palazzo Pignano",
      "denominazione_ita": "Palazzo Pignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G260",
      "lat": "45.3890541",
      "lon": "9.5779331",
      "superficie_kmq": "8.8208",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019067",
      "denominazione_ita_altra": "Pandino",
      "denominazione_ita": "Pandino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G306",
      "lat": "45.4066858",
      "lon": "9.5526910",
      "superficie_kmq": "22.2953",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019068",
      "denominazione_ita_altra": "Persico Dosimo",
      "denominazione_ita": "Persico Dosimo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G469",
      "lat": "45.1846226",
      "lon": "10.1033553",
      "superficie_kmq": "20.6368",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019069",
      "denominazione_ita_altra": "Pescarolo ed Uniti",
      "denominazione_ita": "Pescarolo ed Uniti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G483",
      "lat": "45.1970435",
      "lon": "10.1850960",
      "superficie_kmq": "16.5434",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019070",
      "denominazione_ita_altra": "Pessina Cremonese",
      "denominazione_ita": "Pessina Cremonese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G504",
      "lat": "45.1850441",
      "lon": "10.2481209",
      "superficie_kmq": "22.0166",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019072",
      "denominazione_ita_altra": "Pianengo",
      "denominazione_ita": "Pianengo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G558",
      "lat": "45.4004711",
      "lon": "9.6928093",
      "superficie_kmq": "5.7631",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019073",
      "denominazione_ita_altra": "Pieranica",
      "denominazione_ita": "Pieranica",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G603",
      "lat": "45.4251688",
      "lon": "9.6107235",
      "superficie_kmq": "2.7288",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019074",
      "denominazione_ita_altra": "Pieve d'Olmi",
      "denominazione_ita": "Pieve d'Olmi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G647",
      "lat": "45.0918272",
      "lon": "10.1220583",
      "superficie_kmq": "19.4371",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019075",
      "denominazione_ita_altra": "Pieve San Giacomo",
      "denominazione_ita": "Pieve San Giacomo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G651",
      "lat": "45.1280815",
      "lon": "10.1867445",
      "superficie_kmq": "14.8500",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019076",
      "denominazione_ita_altra": "Pizzighettone",
      "denominazione_ita": "Pizzighettone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G721",
      "lat": "45.1866487",
      "lon": "9.7831317",
      "superficie_kmq": "32.0610",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019077",
      "denominazione_ita_altra": "Pozzaglio ed Uniti",
      "denominazione_ita": "Pozzaglio ed Uniti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B914",
      "lat": "45.2009672",
      "lon": "10.0484699",
      "superficie_kmq": "20.5728",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019078",
      "denominazione_ita_altra": "Quintano",
      "denominazione_ita": "Quintano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H130",
      "lat": "45.4202463",
      "lon": "9.6184367",
      "superficie_kmq": "2.8622",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019079",
      "denominazione_ita_altra": "Ricengo",
      "denominazione_ita": "Ricengo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H276",
      "lat": "45.4055524",
      "lon": "9.7236603",
      "superficie_kmq": "12.5383",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019080",
      "denominazione_ita_altra": "Ripalta Arpina",
      "denominazione_ita": "Ripalta Arpina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H314",
      "lat": "45.3016350",
      "lon": "9.7273041",
      "superficie_kmq": "6.9564",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019081",
      "denominazione_ita_altra": "Ripalta Cremasca",
      "denominazione_ita": "Ripalta Cremasca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H315",
      "lat": "45.3354896",
      "lon": "9.6908597",
      "superficie_kmq": "11.7794",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019082",
      "denominazione_ita_altra": "Ripalta Guerina",
      "denominazione_ita": "Ripalta Guerina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H316",
      "lat": "45.3059938",
      "lon": "9.7045437",
      "superficie_kmq": "2.9739",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019083",
      "denominazione_ita_altra": "Rivarolo del Re ed Uniti",
      "denominazione_ita": "Rivarolo del Re ed Uniti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H341",
      "lat": "45.0278571",
      "lon": "10.4687891",
      "superficie_kmq": "27.3332",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019084",
      "denominazione_ita_altra": "Rivolta d'Adda",
      "denominazione_ita": "Rivolta d'Adda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H357",
      "lat": "45.4696452",
      "lon": "9.5135681",
      "superficie_kmq": "30.4011",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019085",
      "denominazione_ita_altra": "Robecco d'Oglio",
      "denominazione_ita": "Robecco d'Oglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H372",
      "lat": "45.2600702",
      "lon": "10.0786002",
      "superficie_kmq": "17.9951",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019086",
      "denominazione_ita_altra": "Romanengo",
      "denominazione_ita": "Romanengo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H508",
      "lat": "45.3773537",
      "lon": "9.7854580",
      "superficie_kmq": "15.0470",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019087",
      "denominazione_ita_altra": "Salvirola",
      "denominazione_ita": "Salvirola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H731",
      "lat": "45.3545834",
      "lon": "9.7803576",
      "superficie_kmq": "7.3647",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019088",
      "denominazione_ita_altra": "San Bassano",
      "denominazione_ita": "San Bassano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H767",
      "lat": "45.2465117",
      "lon": "9.8134882",
      "superficie_kmq": "13.9264",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019089",
      "denominazione_ita_altra": "San Daniele Po",
      "denominazione_ita": "San Daniele Po",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H815",
      "lat": "45.0672655",
      "lon": "10.1763439",
      "superficie_kmq": "22.6817",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019090",
      "denominazione_ita_altra": "San Giovanni in Croce",
      "denominazione_ita": "San Giovanni in Croce",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H918",
      "lat": "45.0774496",
      "lon": "10.3784110",
      "superficie_kmq": "16.2144",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019091",
      "denominazione_ita_altra": "San Martino del Lago",
      "denominazione_ita": "San Martino del Lago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I007",
      "lat": "45.0724282",
      "lon": "10.3156599",
      "superficie_kmq": "10.3799",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019092",
      "denominazione_ita_altra": "Scandolara Ravara",
      "denominazione_ita": "Scandolara Ravara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I497",
      "lat": "45.0530843",
      "lon": "10.3021294",
      "superficie_kmq": "17.0761",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019093",
      "denominazione_ita_altra": "Scandolara Ripa d'Oglio",
      "denominazione_ita": "Scandolara Ripa d'Oglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I498",
      "lat": "45.2217060",
      "lon": "10.1571446",
      "superficie_kmq": "5.7202",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019094",
      "denominazione_ita_altra": "Sergnano",
      "denominazione_ita": "Sergnano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I627",
      "lat": "45.4281202",
      "lon": "9.7047883",
      "superficie_kmq": "12.3092",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019095",
      "denominazione_ita_altra": "Sesto ed Uniti",
      "denominazione_ita": "Sesto ed Uniti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I683",
      "lat": "45.1755039",
      "lon": "9.9167083",
      "superficie_kmq": "26.4937",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019096",
      "denominazione_ita_altra": "Solarolo Rainerio",
      "denominazione_ita": "Solarolo Rainerio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I790",
      "lat": "45.0812599",
      "lon": "10.3570868",
      "superficie_kmq": "11.4254",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019097",
      "denominazione_ita_altra": "Soncino",
      "denominazione_ita": "Soncino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I827",
      "lat": "45.3998939",
      "lon": "9.8735603",
      "superficie_kmq": "45.3201",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019098",
      "denominazione_ita_altra": "Soresina",
      "denominazione_ita": "Soresina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I849",
      "lat": "45.2886377",
      "lon": "9.8542567",
      "superficie_kmq": "28.5680",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019099",
      "denominazione_ita_altra": "Sospiro",
      "denominazione_ita": "Sospiro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I865",
      "lat": "45.1056561",
      "lon": "10.1592560",
      "superficie_kmq": "18.9595",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019100",
      "denominazione_ita_altra": "Spinadesco",
      "denominazione_ita": "Spinadesco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I906",
      "lat": "45.1482239",
      "lon": "9.9282851",
      "superficie_kmq": "17.2122",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019101",
      "denominazione_ita_altra": "Spineda",
      "denominazione_ita": "Spineda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I909",
      "lat": "45.0603941",
      "lon": "10.5165106",
      "superficie_kmq": "10.1073",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019102",
      "denominazione_ita_altra": "Spino d'Adda",
      "denominazione_ita": "Spino d'Adda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I914",
      "lat": "45.4005279",
      "lon": "9.4924327",
      "superficie_kmq": "20.0196",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019103",
      "denominazione_ita_altra": "Stagno Lombardo",
      "denominazione_ita": "Stagno Lombardo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I935",
      "lat": "45.0732325",
      "lon": "10.0879584",
      "superficie_kmq": "40.1949",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019104",
      "denominazione_ita_altra": "Ticengo",
      "denominazione_ita": "Ticengo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L164",
      "lat": "45.3691572",
      "lon": "9.8282097",
      "superficie_kmq": "7.9819",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019105",
      "denominazione_ita_altra": "Torlino Vimercati",
      "denominazione_ita": "Torlino Vimercati",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L221",
      "lat": "45.4185149",
      "lon": "9.5968114",
      "superficie_kmq": "5.7693",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019106",
      "denominazione_ita_altra": "Tornata",
      "denominazione_ita": "Tornata",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L225",
      "lat": "45.1046671",
      "lon": "10.4308500",
      "superficie_kmq": "10.1654",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019107",
      "denominazione_ita_altra": "Torre de' Picenardi",
      "denominazione_ita": "Torre de' Picenardi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L258",
      "lat": "45.1440012",
      "lon": "10.2868615",
      "superficie_kmq": "34.2286",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019108",
      "denominazione_ita_altra": "Torricella del Pizzo",
      "denominazione_ita": "Torricella del Pizzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L296",
      "lat": "45.0200415",
      "lon": "10.2956463",
      "superficie_kmq": "23.2227",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019109",
      "denominazione_ita_altra": "Trescore Cremasco",
      "denominazione_ita": "Trescore Cremasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L389",
      "lat": "45.4016635",
      "lon": "9.6254423",
      "superficie_kmq": "5.9179",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019110",
      "denominazione_ita_altra": "Trigolo",
      "denominazione_ita": "Trigolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L426",
      "lat": "45.3292682",
      "lon": "9.8116200",
      "superficie_kmq": "16.0610",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019111",
      "denominazione_ita_altra": "Vaiano Cremasco",
      "denominazione_ita": "Vaiano Cremasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L535",
      "lat": "45.3725016",
      "lon": "9.5912722",
      "superficie_kmq": "6.1516",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019112",
      "denominazione_ita_altra": "Vailate",
      "denominazione_ita": "Vailate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L539",
      "lat": "45.4616277",
      "lon": "9.6050101",
      "superficie_kmq": "9.6926",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019113",
      "denominazione_ita_altra": "Vescovato",
      "denominazione_ita": "Vescovato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L806",
      "lat": "45.1763662",
      "lon": "10.1681198",
      "superficie_kmq": "17.4365",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019114",
      "denominazione_ita_altra": "Volongo",
      "denominazione_ita": "Volongo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M116",
      "lat": "45.2115466",
      "lon": "10.3025064",
      "superficie_kmq": "8.1205",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019115",
      "denominazione_ita_altra": "Voltido",
      "denominazione_ita": "Voltido",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M127",
      "lat": "45.1117594",
      "lon": "10.3333936",
      "superficie_kmq": "12.2425",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "CR",
      "codice_istat": "019116",
      "denominazione_ita_altra": "Piadena Drizzona",
      "denominazione_ita": "Piadena Drizzona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M418",
      "lat": "45.1313811",
      "lon": "10.3698499",
      "superficie_kmq": "31.6906",
      "codice_sovracomunale": "019"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020001",
      "denominazione_ita_altra": "Acquanegra sul Chiese",
      "denominazione_ita": "Acquanegra sul Chiese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A038",
      "lat": "45.1604963",
      "lon": "10.4333852",
      "superficie_kmq": "28.0142",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020002",
      "denominazione_ita_altra": "Asola",
      "denominazione_ita": "Asola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A470",
      "lat": "45.2205545",
      "lon": "10.4143214",
      "superficie_kmq": "73.4814",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020003",
      "denominazione_ita_altra": "Bagnolo San Vito",
      "denominazione_ita": "Bagnolo San Vito",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A575",
      "lat": "45.0924892",
      "lon": "10.8814128",
      "superficie_kmq": "49.2032",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020007",
      "denominazione_ita_altra": "Bozzolo",
      "denominazione_ita": "Bozzolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B110",
      "lat": "45.1053135",
      "lon": "10.4845164",
      "superficie_kmq": "18.8219",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020008",
      "denominazione_ita_altra": "Canneto sull'Oglio",
      "denominazione_ita": "Canneto sull'Oglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B612",
      "lat": "45.1502907",
      "lon": "10.3754053",
      "superficie_kmq": "25.8665",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020010",
      "denominazione_ita_altra": "Casalmoro",
      "denominazione_ita": "Casalmoro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B901",
      "lat": "45.2589980",
      "lon": "10.4079816",
      "superficie_kmq": "13.6958",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020011",
      "denominazione_ita_altra": "Casaloldo",
      "denominazione_ita": "Casaloldo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B907",
      "lat": "45.2525738",
      "lon": "10.4821399",
      "superficie_kmq": "16.8495",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020012",
      "denominazione_ita_altra": "Casalromano",
      "denominazione_ita": "Casalromano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B911",
      "lat": "45.1973002",
      "lon": "10.3651088",
      "superficie_kmq": "12.0306",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020013",
      "denominazione_ita_altra": "Castelbelforte",
      "denominazione_ita": "Castelbelforte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C059",
      "lat": "45.2140827",
      "lon": "10.8921984",
      "superficie_kmq": "22.3420",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020014",
      "denominazione_ita_altra": "Castel d'Ario",
      "denominazione_ita": "Castel d'Ario",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C076",
      "lat": "45.1857224",
      "lon": "10.9714046",
      "superficie_kmq": "22.5780",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020015",
      "denominazione_ita_altra": "Castel Goffredo",
      "denominazione_ita": "Castel Goffredo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C118",
      "lat": "45.2979535",
      "lon": "10.4746264",
      "superficie_kmq": "42.4042",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020016",
      "denominazione_ita_altra": "Castellucchio",
      "denominazione_ita": "Castellucchio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C195",
      "lat": "45.1467355",
      "lon": "10.6445101",
      "superficie_kmq": "46.3392",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020017",
      "denominazione_ita_altra": "Castiglione delle Stiviere",
      "denominazione_ita": "Castiglione delle Stiviere",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C312",
      "lat": "45.3865001",
      "lon": "10.4910495",
      "superficie_kmq": "42.0224",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020018",
      "denominazione_ita_altra": "Cavriana",
      "denominazione_ita": "Cavriana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C406",
      "lat": "45.3499148",
      "lon": "10.6009974",
      "superficie_kmq": "36.9097",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020019",
      "denominazione_ita_altra": "Ceresara",
      "denominazione_ita": "Ceresara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C502",
      "lat": "45.2633252",
      "lon": "10.5656226",
      "superficie_kmq": "37.3082",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020020",
      "denominazione_ita_altra": "Commessaggio",
      "denominazione_ita": "Commessaggio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C930",
      "lat": "45.0346010",
      "lon": "10.5453423",
      "superficie_kmq": "11.6487",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020021",
      "denominazione_ita_altra": "Curtatone",
      "denominazione_ita": "Curtatone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D227",
      "lat": "45.1337674",
      "lon": "10.7162709",
      "superficie_kmq": "67.4705",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020022",
      "denominazione_ita_altra": "Dosolo",
      "denominazione_ita": "Dosolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D351",
      "lat": "44.9537462",
      "lon": "10.6414114",
      "superficie_kmq": "25.5363",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020024",
      "denominazione_ita_altra": "Gazoldo degli Ippoliti",
      "denominazione_ita": "Gazoldo degli Ippoliti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D949",
      "lat": "45.1998936",
      "lon": "10.5795783",
      "superficie_kmq": "13.0314",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020025",
      "denominazione_ita_altra": "Gazzuolo",
      "denominazione_ita": "Gazzuolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D959",
      "lat": "45.0688516",
      "lon": "10.5818461",
      "superficie_kmq": "22.4871",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020026",
      "denominazione_ita_altra": "Goito",
      "denominazione_ita": "Goito",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E078",
      "lat": "45.2552213",
      "lon": "10.6754704",
      "superficie_kmq": "79.2204",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020027",
      "denominazione_ita_altra": "Gonzaga",
      "denominazione_ita": "Gonzaga",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E089",
      "lat": "44.9547467",
      "lon": "10.8211936",
      "superficie_kmq": "49.8828",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020028",
      "denominazione_ita_altra": "Guidizzolo",
      "denominazione_ita": "Guidizzolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E261",
      "lat": "45.3180344",
      "lon": "10.5835292",
      "superficie_kmq": "22.3782",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020029",
      "denominazione_ita_altra": "Magnacavallo",
      "denominazione_ita": "Magnacavallo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E818",
      "lat": "45.0046219",
      "lon": "11.1847366",
      "superficie_kmq": "28.2002",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020030",
      "denominazione_ita_altra": "Mantova",
      "denominazione_ita": "Mantova",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "E897",
      "lat": "45.1572685",
      "lon": "10.7927737",
      "superficie_kmq": "63.8062",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020031",
      "denominazione_ita_altra": "Marcaria",
      "denominazione_ita": "Marcaria",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E922",
      "lat": "45.1196189",
      "lon": "10.5335885",
      "superficie_kmq": "89.7889",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020032",
      "denominazione_ita_altra": "Mariana Mantovana",
      "denominazione_ita": "Mariana Mantovana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E949",
      "lat": "45.1940348",
      "lon": "10.4876164",
      "superficie_kmq": "8.9126",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020033",
      "denominazione_ita_altra": "Marmirolo",
      "denominazione_ita": "Marmirolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E962",
      "lat": "45.2223418",
      "lon": "10.7573136",
      "superficie_kmq": "42.0156",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020034",
      "denominazione_ita_altra": "Medole",
      "denominazione_ita": "Medole",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F086",
      "lat": "45.3270761",
      "lon": "10.5074541",
      "superficie_kmq": "25.7286",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020035",
      "denominazione_ita_altra": "Moglia",
      "denominazione_ita": "Moglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F267",
      "lat": "44.9303366",
      "lon": "10.9119256",
      "superficie_kmq": "31.8488",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020036",
      "denominazione_ita_altra": "Monzambano",
      "denominazione_ita": "Monzambano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F705",
      "lat": "45.3871190",
      "lon": "10.6931382",
      "superficie_kmq": "30.0185",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020037",
      "denominazione_ita_altra": "Motteggiana",
      "denominazione_ita": "Motteggiana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B012",
      "lat": "45.0314397",
      "lon": "10.7644404",
      "superficie_kmq": "24.7898",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020038",
      "denominazione_ita_altra": "Ostiglia",
      "denominazione_ita": "Ostiglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G186",
      "lat": "45.0689084",
      "lon": "11.1314332",
      "superficie_kmq": "39.8312",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020039",
      "denominazione_ita_altra": "Pegognaga",
      "denominazione_ita": "Pegognaga",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G417",
      "lat": "44.9960998",
      "lon": "10.8590490",
      "superficie_kmq": "46.5686",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020041",
      "denominazione_ita_altra": "Piubega",
      "denominazione_ita": "Piubega",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G717",
      "lat": "45.2275742",
      "lon": "10.5350279",
      "superficie_kmq": "16.5877",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020042",
      "denominazione_ita_altra": "Poggio Rusco",
      "denominazione_ita": "Poggio Rusco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G753",
      "lat": "44.9774255",
      "lon": "11.1188223",
      "superficie_kmq": "42.2867",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020043",
      "denominazione_ita_altra": "Pomponesco",
      "denominazione_ita": "Pomponesco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G816",
      "lat": "44.9280677",
      "lon": "10.5904151",
      "superficie_kmq": "12.5587",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020044",
      "denominazione_ita_altra": "Ponti sul Mincio",
      "denominazione_ita": "Ponti sul Mincio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G862",
      "lat": "45.4115600",
      "lon": "10.6874628",
      "superficie_kmq": "11.7200",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020045",
      "denominazione_ita_altra": "Porto Mantovano",
      "denominazione_ita": "Porto Mantovano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G917",
      "lat": "45.1887764",
      "lon": "10.7900132",
      "superficie_kmq": "37.4430",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020046",
      "denominazione_ita_altra": "Quingentole",
      "denominazione_ita": "Quingentole",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H129",
      "lat": "45.0398804",
      "lon": "11.0457520",
      "superficie_kmq": "14.3788",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020047",
      "denominazione_ita_altra": "Quistello",
      "denominazione_ita": "Quistello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H143",
      "lat": "45.0090659",
      "lon": "10.9804364",
      "superficie_kmq": "45.4336",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020048",
      "denominazione_ita_altra": "Redondesco",
      "denominazione_ita": "Redondesco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H218",
      "lat": "45.1666715",
      "lon": "10.5128497",
      "superficie_kmq": "19.0358",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020050",
      "denominazione_ita_altra": "Rivarolo Mantovano",
      "denominazione_ita": "Rivarolo Mantovano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H342",
      "lat": "45.0705605",
      "lon": "10.4310119",
      "superficie_kmq": "25.5452",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020051",
      "denominazione_ita_altra": "Rodigo",
      "denominazione_ita": "Rodigo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H481",
      "lat": "45.1993582",
      "lon": "10.6243027",
      "superficie_kmq": "41.6105",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020052",
      "denominazione_ita_altra": "Roncoferraro",
      "denominazione_ita": "Roncoferraro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H541",
      "lat": "45.1332423",
      "lon": "10.9510059",
      "superficie_kmq": "63.4333",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020053",
      "denominazione_ita_altra": "Roverbella",
      "denominazione_ita": "Roverbella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H604",
      "lat": "45.2650411",
      "lon": "10.7645122",
      "superficie_kmq": "62.9802",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020054",
      "denominazione_ita_altra": "Sabbioneta",
      "denominazione_ita": "Sabbioneta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H652",
      "lat": "44.9997584",
      "lon": "10.4899136",
      "superficie_kmq": "37.2701",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020055",
      "denominazione_ita_altra": "San Benedetto Po",
      "denominazione_ita": "San Benedetto Po",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H771",
      "lat": "45.0410727",
      "lon": "10.9284876",
      "superficie_kmq": "69.9405",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020056",
      "denominazione_ita_altra": "San Giacomo delle Segnate",
      "denominazione_ita": "San Giacomo delle Segnate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H870",
      "lat": "44.9718084",
      "lon": "11.0331641",
      "superficie_kmq": "15.9779",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020057",
      "denominazione_ita_altra": "San Giorgio Bigarello",
      "denominazione_ita": "San Giorgio Bigarello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H883",
      "lat": "45.1652694",
      "lon": "10.8458985",
      "superficie_kmq": "51.5259",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020058",
      "denominazione_ita_altra": "San Giovanni del Dosso",
      "denominazione_ita": "San Giovanni del Dosso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H912",
      "lat": "44.9660844",
      "lon": "11.0811234",
      "superficie_kmq": "15.3962",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020059",
      "denominazione_ita_altra": "San Martino dall'Argine",
      "denominazione_ita": "San Martino dall'Argine",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I005",
      "lat": "45.1007026",
      "lon": "10.5201490",
      "superficie_kmq": "16.9393",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020060",
      "denominazione_ita_altra": "Schivenoglia",
      "denominazione_ita": "Schivenoglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I532",
      "lat": "44.9911506",
      "lon": "11.0774775",
      "superficie_kmq": "12.9551",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020061",
      "denominazione_ita_altra": "Sermide e Felonica",
      "denominazione_ita": "Sermide e Felonica",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I632",
      "lat": "45.0060283",
      "lon": "11.2989691",
      "superficie_kmq": "79.8282",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020062",
      "denominazione_ita_altra": "Serravalle a Po",
      "denominazione_ita": "Serravalle a Po",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I662",
      "lat": "45.0656519",
      "lon": "11.0798106",
      "superficie_kmq": "26.2024",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020063",
      "denominazione_ita_altra": "Solferino",
      "denominazione_ita": "Solferino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I801",
      "lat": "45.3718169",
      "lon": "10.5729791",
      "superficie_kmq": "13.0834",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020064",
      "denominazione_ita_altra": "Sustinente",
      "denominazione_ita": "Sustinente",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L015",
      "lat": "45.0688098",
      "lon": "11.0181437",
      "superficie_kmq": "26.2721",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020065",
      "denominazione_ita_altra": "Suzzara",
      "denominazione_ita": "Suzzara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L020",
      "lat": "44.9902315",
      "lon": "10.7456189",
      "superficie_kmq": "61.0987",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020066",
      "denominazione_ita_altra": "Viadana",
      "denominazione_ita": "Viadana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L826",
      "lat": "44.9261163",
      "lon": "10.5214319",
      "superficie_kmq": "103.8408",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020068",
      "denominazione_ita_altra": "Villimpenta",
      "denominazione_ita": "Villimpenta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M044",
      "lat": "45.1449420",
      "lon": "11.0293895",
      "superficie_kmq": "14.8432",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020070",
      "denominazione_ita_altra": "Volta Mantovana",
      "denominazione_ita": "Volta Mantovana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M125",
      "lat": "45.3195791",
      "lon": "10.6594935",
      "superficie_kmq": "50.4845",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020071",
      "denominazione_ita_altra": "Borgo Virgilio",
      "denominazione_ita": "Borgo Virgilio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M340",
      "lat": "45.0817236",
      "lon": "10.7864451",
      "superficie_kmq": "69.9866",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020072",
      "denominazione_ita_altra": "Borgo Mantovano",
      "denominazione_ita": "Borgo Mantovano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M396",
      "lat": "45.0268541",
      "lon": "11.1151988",
      "superficie_kmq": "41.1687",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "MN",
      "codice_istat": "020073",
      "denominazione_ita_altra": "Borgocarbonara",
      "denominazione_ita": "Borgocarbonara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M406",
      "lat": "45.0325287",
      "lon": "11.2181531",
      "superficie_kmq": "30.4948",
      "codice_sovracomunale": "020"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021001",
      "denominazione_ita_altra": "Aldino/Aldein",
      "denominazione_ita": "Aldino",
      "denominazione_altra": "Aldein",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A179",
      "lat": "46.3678548",
      "lon": "11.3540297",
      "superficie_kmq": "62.6899",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021002",
      "denominazione_ita_altra": "Andriano/Andrian",
      "denominazione_ita": "Andriano",
      "denominazione_altra": "Andrian",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A286",
      "lat": "46.5202542",
      "lon": "11.2329321",
      "superficie_kmq": "4.8912",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021003",
      "denominazione_ita_altra": "Anterivo/Altrei",
      "denominazione_ita": "Anterivo",
      "denominazione_altra": "Altrei",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A306",
      "lat": "46.2775121",
      "lon": "11.3667517",
      "superficie_kmq": "11.0641",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021004",
      "denominazione_ita_altra": "Appiano sulla strada del vino/Eppan an der Weinstraße",
      "denominazione_ita": "Appiano sulla strada del vino",
      "denominazione_altra": "Eppan an der Weinstraße",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A332",
      "lat": "46.4552960",
      "lon": "11.2588574",
      "superficie_kmq": "59.4501",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021005",
      "denominazione_ita_altra": "Avelengo/Hafling",
      "denominazione_ita": "Avelengo",
      "denominazione_altra": "Hafling",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A507",
      "lat": "46.6467350",
      "lon": "11.2247528",
      "superficie_kmq": "27.3952",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021006",
      "denominazione_ita_altra": "Badia/Abtei",
      "denominazione_ita": "Badia",
      "denominazione_altra": "Abtei",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A537",
      "lat": "46.6106981",
      "lon": "11.8934220",
      "superficie_kmq": "83.0703",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021007",
      "denominazione_ita_altra": "Barbiano/Barbian",
      "denominazione_ita": "Barbiano",
      "denominazione_altra": "Barbian",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A635",
      "lat": "46.6036714",
      "lon": "11.5198720",
      "superficie_kmq": "24.5112",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021008",
      "denominazione_ita_altra": "Bolzano/Bozen",
      "denominazione_ita": "Bolzano",
      "denominazione_altra": "Bozen",
      "flag_capoluogo": "SI",
      "codice_belfiore": "A952",
      "lat": "46.4993553",
      "lon": "11.3566251",
      "superficie_kmq": "52.2910",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021009",
      "denominazione_ita_altra": "Braies/Prags",
      "denominazione_ita": "Braies",
      "denominazione_altra": "Prags",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B116",
      "lat": "46.7197584",
      "lon": "12.1325709",
      "superficie_kmq": "90.2130",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021010",
      "denominazione_ita_altra": "Brennero/Brenner",
      "denominazione_ita": "Brennero",
      "denominazione_altra": "Brenner",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B145",
      "lat": "46.9377454",
      "lon": "11.4432300",
      "superficie_kmq": "114.2919",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021011",
      "denominazione_ita_altra": "Bressanone/Brixen",
      "denominazione_ita": "Bressanone",
      "denominazione_altra": "Brixen",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B160",
      "lat": "46.7164634",
      "lon": "11.6569961",
      "superficie_kmq": "84.6986",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021012",
      "denominazione_ita_altra": "Bronzolo/Branzoll",
      "denominazione_ita": "Bronzolo",
      "denominazione_altra": "Branzoll",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B203",
      "lat": "46.4046228",
      "lon": "11.3198947",
      "superficie_kmq": "7.5395",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021013",
      "denominazione_ita_altra": "Brunico/Bruneck",
      "denominazione_ita": "Brunico",
      "denominazione_altra": "Bruneck",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B220",
      "lat": "46.7974094",
      "lon": "11.9372998",
      "superficie_kmq": "44.9974",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021014",
      "denominazione_ita_altra": "Caines/Kuens",
      "denominazione_ita": "Caines",
      "denominazione_altra": "Kuens",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B364",
      "lat": "46.6965401",
      "lon": "11.1753003",
      "superficie_kmq": "1.6344",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021015",
      "denominazione_ita_altra": "Caldaro sulla strada del vino/Kaltern an der Weinstraße",
      "denominazione_ita": "Caldaro sulla strada del vino",
      "denominazione_altra": "Kaltern an der Weinstraße",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B397",
      "lat": "46.4131941",
      "lon": "11.2451597",
      "superficie_kmq": "48.0442",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021016",
      "denominazione_ita_altra": "Campo di Trens/Freienfeld",
      "denominazione_ita": "Campo di Trens",
      "denominazione_altra": "Freienfeld",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B529",
      "lat": "46.8711448",
      "lon": "11.4839562",
      "superficie_kmq": "95.3854",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021017",
      "denominazione_ita_altra": "Campo Tures/Sand in Taufers",
      "denominazione_ita": "Campo Tures",
      "denominazione_altra": "Sand in Taufers",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B570",
      "lat": "46.9199509",
      "lon": "11.9518819",
      "superficie_kmq": "163.9801",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021018",
      "denominazione_ita_altra": "Castelbello-Ciardes/Kastelbell-Tschars",
      "denominazione_ita": "Castelbello-Ciardes",
      "denominazione_altra": "Kastelbell-Tschars",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C062",
      "lat": "46.6283348",
      "lon": "10.9011438",
      "superficie_kmq": "53.7910",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021019",
      "denominazione_ita_altra": "Castelrotto/Kastelruth",
      "denominazione_ita": "Castelrotto",
      "denominazione_altra": "Kastelruth",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C254",
      "lat": "46.5678281",
      "lon": "11.5594623",
      "superficie_kmq": "117.8970",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021020",
      "denominazione_ita_altra": "Cermes/Tscherms",
      "denominazione_ita": "Cermes",
      "denominazione_altra": "Tscherms",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A022",
      "lat": "46.6328156",
      "lon": "11.1450339",
      "superficie_kmq": "6.6239",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021021",
      "denominazione_ita_altra": "Chienes/Kiens",
      "denominazione_ita": "Chienes",
      "denominazione_altra": "Kiens",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C625",
      "lat": "46.8064722",
      "lon": "11.8363206",
      "superficie_kmq": "33.6801",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021022",
      "denominazione_ita_altra": "Chiusa/Klausen",
      "denominazione_ita": "Chiusa",
      "denominazione_altra": "Klausen",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C652",
      "lat": "46.6416935",
      "lon": "11.5680932",
      "superficie_kmq": "51.2890",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021023",
      "denominazione_ita_altra": "Cornedo all'Isarco/Karneid",
      "denominazione_ita": "Cornedo all'Isarco",
      "denominazione_altra": "Karneid",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B799",
      "lat": "46.4942750",
      "lon": "11.3926758",
      "superficie_kmq": "40.6107",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021024",
      "denominazione_ita_altra": "Cortaccia sulla strada del vino/Kurtatsch an der Weinstraße",
      "denominazione_ita": "Cortaccia sulla strada del vino",
      "denominazione_altra": "Kurtatsch an der Weinstraße",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D048",
      "lat": "46.3133357",
      "lon": "11.2235522",
      "superficie_kmq": "29.2676",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021025",
      "denominazione_ita_altra": "Cortina sulla strada del vino/Kurtinig an der Weinstraße",
      "denominazione_ita": "Cortina sulla strada del vino",
      "denominazione_altra": "Kurtinig an der Weinstraße",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D075",
      "lat": "46.2687065",
      "lon": "11.2225944",
      "superficie_kmq": "1.9990",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021026",
      "denominazione_ita_altra": "Corvara in Badia/Corvara",
      "denominazione_ita": "Corvara in Badia",
      "denominazione_altra": "Corvara",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D079",
      "lat": "46.5529322",
      "lon": "11.8745245",
      "superficie_kmq": "38.8358",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021027",
      "denominazione_ita_altra": "Curon Venosta/Graun im Vinschgau",
      "denominazione_ita": "Curon Venosta",
      "denominazione_altra": "Graun im Vinschgau",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D222",
      "lat": "46.8067167",
      "lon": "10.5421689",
      "superficie_kmq": "209.6475",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021028",
      "denominazione_ita_altra": "Dobbiaco/Toblach",
      "denominazione_ita": "Dobbiaco",
      "denominazione_altra": "Toblach",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D311",
      "lat": "46.7355351",
      "lon": "12.2201996",
      "superficie_kmq": "125.3018",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021029",
      "denominazione_ita_altra": "Egna/Neumarkt",
      "denominazione_ita": "Egna",
      "denominazione_altra": "Neumarkt",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D392",
      "lat": "46.3138924",
      "lon": "11.2728262",
      "superficie_kmq": "23.5693",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021030",
      "denominazione_ita_altra": "Falzes/Pfalzen",
      "denominazione_ita": "Falzes",
      "denominazione_altra": "Pfalzen",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D484",
      "lat": "46.8117753",
      "lon": "11.8826546",
      "superficie_kmq": "33.1315",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021031",
      "denominazione_ita_altra": "Fiè allo Sciliar/Völs am Schlern",
      "denominazione_ita": "Fiè allo Sciliar",
      "denominazione_altra": "Völs am Schlern",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D571",
      "lat": "46.5175569",
      "lon": "11.5013989",
      "superficie_kmq": "43.9578",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021032",
      "denominazione_ita_altra": "Fortezza/Franzensfeste",
      "denominazione_ita": "Fortezza",
      "denominazione_altra": "Franzensfeste",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D731",
      "lat": "46.7864704",
      "lon": "11.6125630",
      "superficie_kmq": "61.7720",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021033",
      "denominazione_ita_altra": "Funes/Villnöß",
      "denominazione_ita": "Funes",
      "denominazione_altra": "Villnöß",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D821",
      "lat": "46.6422905",
      "lon": "11.6805757",
      "superficie_kmq": "81.3811",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021034",
      "denominazione_ita_altra": "Gais/Gais",
      "denominazione_ita": "Gais",
      "denominazione_altra": "Gais",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D860",
      "lat": "46.8353029",
      "lon": "11.9477349",
      "superficie_kmq": "60.6146",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021035",
      "denominazione_ita_altra": "Gargazzone/Gargazon",
      "denominazione_ita": "Gargazzone",
      "denominazione_altra": "Gargazon",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D923",
      "lat": "46.5847388",
      "lon": "11.2013183",
      "superficie_kmq": "4.9136",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021036",
      "denominazione_ita_altra": "Glorenza/Glurns",
      "denominazione_ita": "Glorenza",
      "denominazione_altra": "Glurns",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E069",
      "lat": "46.6711883",
      "lon": "10.5556790",
      "superficie_kmq": "13.2189",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021037",
      "denominazione_ita_altra": "Laces/Latsch",
      "denominazione_ita": "Laces",
      "denominazione_altra": "Latsch",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E398",
      "lat": "46.6176594",
      "lon": "10.8594659",
      "superficie_kmq": "78.7048",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021038",
      "denominazione_ita_altra": "Lagundo/Algund",
      "denominazione_ita": "Lagundo",
      "denominazione_altra": "Algund",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E412",
      "lat": "46.6775607",
      "lon": "11.1177566",
      "superficie_kmq": "23.6837",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021039",
      "denominazione_ita_altra": "Laion/Lajen",
      "denominazione_ita": "Laion",
      "denominazione_altra": "Lajen",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E420",
      "lat": "46.6087862",
      "lon": "11.5646020",
      "superficie_kmq": "37.5250",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021040",
      "denominazione_ita_altra": "Laives/Leifers",
      "denominazione_ita": "Laives",
      "denominazione_altra": "Leifers",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E421",
      "lat": "46.4265078",
      "lon": "11.3384659",
      "superficie_kmq": "24.1083",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021041",
      "denominazione_ita_altra": "Lana/Lana",
      "denominazione_ita": "Lana",
      "denominazione_altra": "Lana",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E434",
      "lat": "46.6161351",
      "lon": "11.1452925",
      "superficie_kmq": "36.1238",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021042",
      "denominazione_ita_altra": "Lasa/Laas",
      "denominazione_ita": "Lasa",
      "denominazione_altra": "Laas",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E457",
      "lat": "46.6178961",
      "lon": "10.6975654",
      "superficie_kmq": "110.2235",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021043",
      "denominazione_ita_altra": "Lauregno/Laurein",
      "denominazione_ita": "Lauregno",
      "denominazione_altra": "Laurein",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E481",
      "lat": "46.4542252",
      "lon": "11.0622481",
      "superficie_kmq": "13.8848",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021044",
      "denominazione_ita_altra": "Luson/Lüsen",
      "denominazione_ita": "Luson",
      "denominazione_altra": "Lüsen",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E764",
      "lat": "46.7459154",
      "lon": "11.7607228",
      "superficie_kmq": "74.4127",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021045",
      "denominazione_ita_altra": "Magrè sulla strada del vino/Margreid an der Weinstraße",
      "denominazione_ita": "Magrè sulla strada del vino",
      "denominazione_altra": "Margreid an der Weinstraße",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E829",
      "lat": "46.2868850",
      "lon": "11.2089721",
      "superficie_kmq": "13.8641",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021046",
      "denominazione_ita_altra": "Malles Venosta/Mals",
      "denominazione_ita": "Malles Venosta",
      "denominazione_altra": "Mals",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E862",
      "lat": "46.6895626",
      "lon": "10.5448008",
      "superficie_kmq": "247.4250",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021047",
      "denominazione_ita_altra": "Marebbe/Enneberg",
      "denominazione_ita": "Marebbe",
      "denominazione_altra": "Enneberg",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E938",
      "lat": "46.6975686",
      "lon": "11.9327035",
      "superficie_kmq": "160.2279",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021048",
      "denominazione_ita_altra": "Marlengo/Marling",
      "denominazione_ita": "Marlengo",
      "denominazione_altra": "Marling",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E959",
      "lat": "46.6548170",
      "lon": "11.1404190",
      "superficie_kmq": "12.8631",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021049",
      "denominazione_ita_altra": "Martello/Martell",
      "denominazione_ita": "Martello",
      "denominazione_altra": "Martell",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E981",
      "lat": "46.5663453",
      "lon": "10.7826022",
      "superficie_kmq": "142.7992",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021050",
      "denominazione_ita_altra": "Meltina/Mölten",
      "denominazione_ita": "Meltina",
      "denominazione_altra": "Mölten",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F118",
      "lat": "46.5863976",
      "lon": "11.2555758",
      "superficie_kmq": "36.9539",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021051",
      "denominazione_ita_altra": "Merano/Meran",
      "denominazione_ita": "Merano",
      "denominazione_altra": "Meran",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F132",
      "lat": "46.6717874",
      "lon": "11.1618387",
      "superficie_kmq": "26.3399",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021052",
      "denominazione_ita_altra": "Monguelfo-Tesido/Welsberg-Taisten",
      "denominazione_ita": "Monguelfo-Tesido",
      "denominazione_altra": "Welsberg-Taisten",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F371",
      "lat": "46.7569605",
      "lon": "12.1047821",
      "superficie_kmq": "46.4364",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021053",
      "denominazione_ita_altra": "Montagna sulla Strada del Vino/Montan an der Weinstraße",
      "denominazione_ita": "Montagna sulla Strada del Vino",
      "denominazione_altra": "Montan an der Weinstraße",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F392",
      "lat": "46.3313659",
      "lon": "11.3027303",
      "superficie_kmq": "19.5102",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021054",
      "denominazione_ita_altra": "Moso in Passiria/Moos in Passeier",
      "denominazione_ita": "Moso in Passiria",
      "denominazione_altra": "Moos in Passeier",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F766",
      "lat": "46.8301488",
      "lon": "11.1690037",
      "superficie_kmq": "193.5284",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021055",
      "denominazione_ita_altra": "Nalles/Nals",
      "denominazione_ita": "Nalles",
      "denominazione_altra": "Nals",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F836",
      "lat": "46.5411178",
      "lon": "11.2042267",
      "superficie_kmq": "12.2399",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021056",
      "denominazione_ita_altra": "Naturno/Naturns",
      "denominazione_ita": "Naturno",
      "denominazione_altra": "Naturns",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F849",
      "lat": "46.6510588",
      "lon": "11.0053337",
      "superficie_kmq": "67.1127",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021057",
      "denominazione_ita_altra": "Naz-Sciaves/Natz-Schabs",
      "denominazione_ita": "Naz-Sciaves",
      "denominazione_altra": "Natz-Schabs",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F856",
      "lat": "46.7705575",
      "lon": "11.6658506",
      "superficie_kmq": "15.9599",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021058",
      "denominazione_ita_altra": "Nova Levante/Welschnofen",
      "denominazione_ita": "Nova Levante",
      "denominazione_altra": "Welschnofen",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F949",
      "lat": "46.4298073",
      "lon": "11.5355551",
      "superficie_kmq": "51.0956",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021059",
      "denominazione_ita_altra": "Nova Ponente/Deutschnofen",
      "denominazione_ita": "Nova Ponente",
      "denominazione_altra": "Deutschnofen",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F950",
      "lat": "46.4141339",
      "lon": "11.4255964",
      "superficie_kmq": "112.4933",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021060",
      "denominazione_ita_altra": "Ora/Auer",
      "denominazione_ita": "Ora",
      "denominazione_altra": "Auer",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G083",
      "lat": "46.3481339",
      "lon": "11.2983195",
      "superficie_kmq": "11.7880",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021061",
      "denominazione_ita_altra": "Ortisei/St. Ulrich",
      "denominazione_ita": "Ortisei",
      "denominazione_altra": "St. Ulrich",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G140",
      "lat": "46.5761360",
      "lon": "11.6718036",
      "superficie_kmq": "24.1564",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021062",
      "denominazione_ita_altra": "Parcines/Partschins",
      "denominazione_ita": "Parcines",
      "denominazione_altra": "Partschins",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G328",
      "lat": "46.6844457",
      "lon": "11.0735103",
      "superficie_kmq": "55.3974",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021063",
      "denominazione_ita_altra": "Perca/Percha",
      "denominazione_ita": "Perca",
      "denominazione_altra": "Percha",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G443",
      "lat": "46.7914826",
      "lon": "11.9832236",
      "superficie_kmq": "30.3640",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021064",
      "denominazione_ita_altra": "Plaus/Plaus",
      "denominazione_ita": "Plaus",
      "denominazione_altra": "Plaus",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G299",
      "lat": "46.6557170",
      "lon": "11.0423698",
      "superficie_kmq": "4.8660",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021065",
      "denominazione_ita_altra": "Ponte Gardena/Waidbruck",
      "denominazione_ita": "Ponte Gardena",
      "denominazione_altra": "Waidbruck",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G830",
      "lat": "46.5970900",
      "lon": "11.5303405",
      "superficie_kmq": "2.3282",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021066",
      "denominazione_ita_altra": "Postal/Burgstall",
      "denominazione_ita": "Postal",
      "denominazione_altra": "Burgstall",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G936",
      "lat": "46.6096517",
      "lon": "11.1915490",
      "superficie_kmq": "6.6921",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021067",
      "denominazione_ita_altra": "Prato allo Stelvio/Prad am Stilfserjoch",
      "denominazione_ita": "Prato allo Stelvio",
      "denominazione_altra": "Prad am Stilfserjoch",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H004",
      "lat": "46.6175873",
      "lon": "10.5895953",
      "superficie_kmq": "51.0018",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021068",
      "denominazione_ita_altra": "Predoi/Prettau",
      "denominazione_ita": "Predoi",
      "denominazione_altra": "Prettau",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H019",
      "lat": "47.0385277",
      "lon": "12.1028685",
      "superficie_kmq": "86.3567",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021069",
      "denominazione_ita_altra": "Proves/Proveis",
      "denominazione_ita": "Proves",
      "denominazione_altra": "Proveis",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H081",
      "lat": "46.4761972",
      "lon": "11.0223191",
      "superficie_kmq": "18.3685",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021070",
      "denominazione_ita_altra": "Racines/Ratschings",
      "denominazione_ita": "Racines",
      "denominazione_altra": "Ratschings",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H152",
      "lat": "46.8827376",
      "lon": "11.3785089",
      "superficie_kmq": "203.2838",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021071",
      "denominazione_ita_altra": "Rasun-Anterselva/Rasen-Antholz",
      "denominazione_ita": "Rasun-Anterselva",
      "denominazione_altra": "Rasen-Antholz",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H189",
      "lat": "46.7807965",
      "lon": "12.0493362",
      "superficie_kmq": "121.5728",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021072",
      "denominazione_ita_altra": "Renon/Ritten",
      "denominazione_ita": "Renon",
      "denominazione_altra": "Ritten",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H236",
      "lat": "46.5367264",
      "lon": "11.4630212",
      "superficie_kmq": "111.3601",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021073",
      "denominazione_ita_altra": "Rifiano/Riffian",
      "denominazione_ita": "Rifiano",
      "denominazione_altra": "Riffian",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H284",
      "lat": "46.7016155",
      "lon": "11.1803386",
      "superficie_kmq": "35.9426",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021074",
      "denominazione_ita_altra": "Rio di Pusteria/Mühlbach",
      "denominazione_ita": "Rio di Pusteria",
      "denominazione_altra": "Mühlbach",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H299",
      "lat": "46.7970530",
      "lon": "11.6678020",
      "superficie_kmq": "83.8233",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021075",
      "denominazione_ita_altra": "Rodengo/Rodeneck",
      "denominazione_ita": "Rodengo",
      "denominazione_altra": "Rodeneck",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H475",
      "lat": "46.7793456",
      "lon": "11.6949569",
      "superficie_kmq": "29.6182",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021076",
      "denominazione_ita_altra": "Salorno sulla strada del vino/Salurn an der Weinstraße",
      "denominazione_ita": "Salorno sulla strada del vino",
      "denominazione_altra": "Salurn an der Weinstraße",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H719",
      "lat": "46.2391248",
      "lon": "11.2129717",
      "superficie_kmq": "33.1250",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021077",
      "denominazione_ita_altra": "San Candido/Innichen",
      "denominazione_ita": "San Candido",
      "denominazione_altra": "Innichen",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H786",
      "lat": "46.7324488",
      "lon": "12.2820432",
      "superficie_kmq": "79.8468",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021079",
      "denominazione_ita_altra": "San Genesio Atesino/Jenesien",
      "denominazione_ita": "San Genesio Atesino",
      "denominazione_altra": "Jenesien",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H858",
      "lat": "46.5356700",
      "lon": "11.3315829",
      "superficie_kmq": "68.8368",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021080",
      "denominazione_ita_altra": "San Leonardo in Passiria/St. Leonhard in Passeier",
      "denominazione_ita": "San Leonardo in Passiria",
      "denominazione_altra": "St. Leonhard in Passeier",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H952",
      "lat": "46.8117013",
      "lon": "11.2432903",
      "superficie_kmq": "89.0273",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021081",
      "denominazione_ita_altra": "San Lorenzo di Sebato/St. Lorenzen",
      "denominazione_ita": "San Lorenzo di Sebato",
      "denominazione_altra": "St. Lorenzen",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H956",
      "lat": "46.7835530",
      "lon": "11.9017751",
      "superficie_kmq": "51.4599",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021082",
      "denominazione_ita_altra": "San Martino in Badia/St. Martin in Thurn",
      "denominazione_ita": "San Martino in Badia",
      "denominazione_altra": "St. Martin in Thurn",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H988",
      "lat": "46.6819127",
      "lon": "11.8980186",
      "superficie_kmq": "75.9429",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021083",
      "denominazione_ita_altra": "San Martino in Passiria/St. Martin in Passeier",
      "denominazione_ita": "San Martino in Passiria",
      "denominazione_altra": "St. Martin in Passeier",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H989",
      "lat": "46.7826345",
      "lon": "11.2269570",
      "superficie_kmq": "29.9913",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021084",
      "denominazione_ita_altra": "San Pancrazio/St. Pankraz",
      "denominazione_ita": "San Pancrazio",
      "denominazione_altra": "St. Pankraz",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I065",
      "lat": "46.5869235",
      "lon": "11.0850440",
      "superficie_kmq": "63.1725",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021085",
      "denominazione_ita_altra": "Santa Cristina Valgardena/St. Christina in Gröden",
      "denominazione_ita": "Santa Cristina Valgardena",
      "denominazione_altra": "St. Christina in Gröden",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I173",
      "lat": "46.5585519",
      "lon": "11.7213304",
      "superficie_kmq": "31.9231",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021086",
      "denominazione_ita_altra": "Sarentino/Sarntal",
      "denominazione_ita": "Sarentino",
      "denominazione_altra": "Sarntal",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I431",
      "lat": "46.6427247",
      "lon": "11.3562424",
      "superficie_kmq": "302.2703",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021087",
      "denominazione_ita_altra": "Scena/Schenna",
      "denominazione_ita": "Scena",
      "denominazione_altra": "Schenna",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I519",
      "lat": "46.6900773",
      "lon": "11.1872866",
      "superficie_kmq": "48.1303",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021088",
      "denominazione_ita_altra": "Selva dei Molini/Mühlwald",
      "denominazione_ita": "Selva dei Molini",
      "denominazione_altra": "Mühlwald",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I593",
      "lat": "46.8913279",
      "lon": "11.8605928",
      "superficie_kmq": "104.7871",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021089",
      "denominazione_ita_altra": "Selva di Val Gardena/Wolkenstein in Gröden",
      "denominazione_ita": "Selva di Val Gardena",
      "denominazione_altra": "Wolkenstein in Gröden",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I591",
      "lat": "46.5560150",
      "lon": "11.7588081",
      "superficie_kmq": "56.2399",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021091",
      "denominazione_ita_altra": "Senales/Schnals",
      "denominazione_ita": "Senales",
      "denominazione_altra": "Schnals",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I604",
      "lat": "46.7054191",
      "lon": "10.9095729",
      "superficie_kmq": "209.8369",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021092",
      "denominazione_ita_altra": "Sesto/Sexten",
      "denominazione_ita": "Sesto",
      "denominazione_altra": "Sexten",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I687",
      "lat": "46.7021194",
      "lon": "12.3503168",
      "superficie_kmq": "80.4290",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021093",
      "denominazione_ita_altra": "Silandro/Schlanders",
      "denominazione_ita": "Silandro",
      "denominazione_altra": "Schlanders",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I729",
      "lat": "46.6279539",
      "lon": "10.7731425",
      "superficie_kmq": "115.1716",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021094",
      "denominazione_ita_altra": "Sluderno/Schluderns",
      "denominazione_ita": "Sluderno",
      "denominazione_altra": "Schluderns",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I771",
      "lat": "46.6642506",
      "lon": "10.5853375",
      "superficie_kmq": "20.7177",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021095",
      "denominazione_ita_altra": "Stelvio/Stilfs",
      "denominazione_ita": "Stelvio",
      "denominazione_altra": "Stilfs",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I948",
      "lat": "46.5977197",
      "lon": "10.5455542",
      "superficie_kmq": "141.6216",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021096",
      "denominazione_ita_altra": "Terento/Terenten",
      "denominazione_ita": "Terento",
      "denominazione_altra": "Terenten",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L106",
      "lat": "46.8289724",
      "lon": "11.7765711",
      "superficie_kmq": "42.1614",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021097",
      "denominazione_ita_altra": "Terlano/Terlan",
      "denominazione_ita": "Terlano",
      "denominazione_altra": "Terlan",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L108",
      "lat": "46.5288529",
      "lon": "11.2496429",
      "superficie_kmq": "18.5685",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021098",
      "denominazione_ita_altra": "Termeno sulla strada del vino/Tramin an der Weinstraße",
      "denominazione_ita": "Termeno sulla strada del vino",
      "denominazione_altra": "Tramin an der Weinstraße",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L111",
      "lat": "46.3414233",
      "lon": "11.2393492",
      "superficie_kmq": "19.4389",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021099",
      "denominazione_ita_altra": "Tesimo/Tisens",
      "denominazione_ita": "Tesimo",
      "denominazione_altra": "Tisens",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L149",
      "lat": "46.5641130",
      "lon": "11.1691310",
      "superficie_kmq": "38.1250",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021100",
      "denominazione_ita_altra": "Tires/Tiers",
      "denominazione_ita": "Tires",
      "denominazione_altra": "Tiers",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L176",
      "lat": "46.4671925",
      "lon": "11.5276972",
      "superficie_kmq": "42.1810",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021101",
      "denominazione_ita_altra": "Tirolo/Tirol",
      "denominazione_ita": "Tirolo",
      "denominazione_altra": "Tirol",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L178",
      "lat": "46.6904738",
      "lon": "11.1535767",
      "superficie_kmq": "25.6182",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021102",
      "denominazione_ita_altra": "Trodena nel parco naturale/Truden im Naturpark",
      "denominazione_ita": "Trodena nel parco naturale",
      "denominazione_altra": "Truden im Naturpark",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L444",
      "lat": "46.3229446",
      "lon": "11.3493380",
      "superficie_kmq": "20.5608",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021103",
      "denominazione_ita_altra": "Tubre/Taufers im Münstertal",
      "denominazione_ita": "Tubre",
      "denominazione_altra": "Taufers im Münstertal",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L455",
      "lat": "46.6460447",
      "lon": "10.4643616",
      "superficie_kmq": "46.2665",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021104",
      "denominazione_ita_altra": "Ultimo/Ulten",
      "denominazione_ita": "Ultimo",
      "denominazione_altra": "Ulten",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L490",
      "lat": "46.5488515",
      "lon": "11.0030668",
      "superficie_kmq": "208.1174",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021105",
      "denominazione_ita_altra": "Vadena/Pfatten",
      "denominazione_ita": "Vadena",
      "denominazione_altra": "Pfatten",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L527",
      "lat": "46.4145164",
      "lon": "11.3051806",
      "superficie_kmq": "13.7358",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021106",
      "denominazione_ita_altra": "Valdaora/Olang",
      "denominazione_ita": "Valdaora",
      "denominazione_altra": "Olang",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L552",
      "lat": "46.7614318",
      "lon": "12.0309757",
      "superficie_kmq": "49.0760",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021107",
      "denominazione_ita_altra": "Val di Vizze/Pfitsch",
      "denominazione_ita": "Val di Vizze",
      "denominazione_altra": "Pfitsch",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L564",
      "lat": "46.8997402",
      "lon": "11.4624690",
      "superficie_kmq": "142.1207",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021108",
      "denominazione_ita_altra": "Valle Aurina/Ahrntal",
      "denominazione_ita": "Valle Aurina",
      "denominazione_altra": "Ahrntal",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L595",
      "lat": "46.9968921",
      "lon": "11.9808039",
      "superficie_kmq": "187.8882",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021109",
      "denominazione_ita_altra": "Valle di Casies/Gsies",
      "denominazione_ita": "Valle di Casies",
      "denominazione_altra": "Gsies",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L601",
      "lat": "46.8110535",
      "lon": "12.2272014",
      "superficie_kmq": "110.1339",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021110",
      "denominazione_ita_altra": "Vandoies/Vintl",
      "denominazione_ita": "Vandoies",
      "denominazione_altra": "Vintl",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L660",
      "lat": "46.8161708",
      "lon": "11.7221332",
      "superficie_kmq": "110.8179",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021111",
      "denominazione_ita_altra": "Varna/Vahrn",
      "denominazione_ita": "Varna",
      "denominazione_altra": "Vahrn",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L687",
      "lat": "46.7400849",
      "lon": "11.6358407",
      "superficie_kmq": "70.3417",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021112",
      "denominazione_ita_altra": "Verano/Vöran",
      "denominazione_ita": "Verano",
      "denominazione_altra": "Vöran",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L745",
      "lat": "46.6051330",
      "lon": "11.2257427",
      "superficie_kmq": "22.0090",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021113",
      "denominazione_ita_altra": "Villabassa/Niederdorf",
      "denominazione_ita": "Villabassa",
      "denominazione_altra": "Niederdorf",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L915",
      "lat": "46.7377843",
      "lon": "12.1690684",
      "superficie_kmq": "18.0325",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021114",
      "denominazione_ita_altra": "Villandro/Villanders",
      "denominazione_ita": "Villandro",
      "denominazione_altra": "Villanders",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L971",
      "lat": "46.6314279",
      "lon": "11.5398262",
      "superficie_kmq": "43.9501",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021115",
      "denominazione_ita_altra": "Vipiteno/Sterzing",
      "denominazione_ita": "Vipiteno",
      "denominazione_altra": "Sterzing",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M067",
      "lat": "46.8979989",
      "lon": "11.4317959",
      "superficie_kmq": "32.9648",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021116",
      "denominazione_ita_altra": "Velturno/Feldthurns",
      "denominazione_ita": "Velturno",
      "denominazione_altra": "Feldthurns",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L724",
      "lat": "46.6694945",
      "lon": "11.6000972",
      "superficie_kmq": "24.5844",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021117",
      "denominazione_ita_altra": "La Valle/Wengen",
      "denominazione_ita": "La Valle",
      "denominazione_altra": "Wengen",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E491",
      "lat": "46.6566399",
      "lon": "11.9258114",
      "superficie_kmq": "38.9177",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "BZ",
      "codice_istat": "021118",
      "denominazione_ita_altra": "Senale-San Felice/Unsere Liebe Frau im Walde-St. Felix",
      "denominazione_ita": "Senale-San Felice",
      "denominazione_altra": "Unsere Liebe Frau im Walde-St. Felix",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I603",
      "lat": "46.4921787",
      "lon": "11.1324799",
      "superficie_kmq": "27.6313",
      "codice_sovracomunale": "021"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022001",
      "denominazione_ita_altra": "Ala",
      "denominazione_ita": "Ala",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A116",
      "lat": "45.7567768",
      "lon": "11.0047537",
      "superficie_kmq": "119.8550",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022002",
      "denominazione_ita_altra": "Albiano",
      "denominazione_ita": "Albiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A158",
      "lat": "46.1452239",
      "lon": "11.1937683",
      "superficie_kmq": "9.9642",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022003",
      "denominazione_ita_altra": "Aldeno",
      "denominazione_ita": "Aldeno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A178",
      "lat": "45.9773008",
      "lon": "11.0913187",
      "superficie_kmq": "8.9704",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022005",
      "denominazione_ita_altra": "Andalo",
      "denominazione_ita": "Andalo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A274",
      "lat": "46.1648940",
      "lon": "11.0032879",
      "superficie_kmq": "11.3783",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022006",
      "denominazione_ita_altra": "Arco",
      "denominazione_ita": "Arco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A372",
      "lat": "45.9192987",
      "lon": "10.8861937",
      "superficie_kmq": "63.2175",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022007",
      "denominazione_ita_altra": "Avio",
      "denominazione_ita": "Avio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A520",
      "lat": "45.7354627",
      "lon": "10.9415430",
      "superficie_kmq": "68.8883",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022009",
      "denominazione_ita_altra": "Baselga di Pinè",
      "denominazione_ita": "Baselga di Pinè",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A694",
      "lat": "46.1300045",
      "lon": "11.2445275",
      "superficie_kmq": "41.0709",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022011",
      "denominazione_ita_altra": "Bedollo",
      "denominazione_ita": "Bedollo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A730",
      "lat": "46.1614401",
      "lon": "11.3032187",
      "superficie_kmq": "27.4595",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022013",
      "denominazione_ita_altra": "Besenello",
      "denominazione_ita": "Besenello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A821",
      "lat": "45.9456999",
      "lon": "11.1061880",
      "superficie_kmq": "25.9369",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022015",
      "denominazione_ita_altra": "Bieno",
      "denominazione_ita": "Bieno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A863",
      "lat": "46.0802618",
      "lon": "11.5536892",
      "superficie_kmq": "11.7110",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022017",
      "denominazione_ita_altra": "Bleggio Superiore",
      "denominazione_ita": "Bleggio Superiore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A902",
      "lat": "46.0253412",
      "lon": "10.8370600",
      "superficie_kmq": "32.6740",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022018",
      "denominazione_ita_altra": "Bocenago",
      "denominazione_ita": "Bocenago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A916",
      "lat": "46.1184457",
      "lon": "10.7590868",
      "superficie_kmq": "8.4527",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022021",
      "denominazione_ita_altra": "Bondone",
      "denominazione_ita": "Bondone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A968",
      "lat": "45.8054311",
      "lon": "10.5512560",
      "superficie_kmq": "19.1849",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022022",
      "denominazione_ita_altra": "Borgo Valsugana",
      "denominazione_ita": "Borgo Valsugana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B006",
      "lat": "46.0530557",
      "lon": "11.4564863",
      "superficie_kmq": "52.3633",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022025",
      "denominazione_ita_altra": "Brentonico",
      "denominazione_ita": "Brentonico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B153",
      "lat": "45.8188797",
      "lon": "10.9549341",
      "superficie_kmq": "62.7400",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022026",
      "denominazione_ita_altra": "Bresimo",
      "denominazione_ita": "Bresimo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B158",
      "lat": "46.4104634",
      "lon": "10.9686137",
      "superficie_kmq": "41.0139",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022029",
      "denominazione_ita_altra": "Caderzone Terme",
      "denominazione_ita": "Caderzone Terme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B335",
      "lat": "46.1294278",
      "lon": "10.7569238",
      "superficie_kmq": "18.6070",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022032",
      "denominazione_ita_altra": "Calceranica al Lago",
      "denominazione_ita": "Calceranica al Lago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B389",
      "lat": "46.0037672",
      "lon": "11.2423759",
      "superficie_kmq": "3.3945",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022033",
      "denominazione_ita_altra": "Caldes",
      "denominazione_ita": "Caldes",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B400",
      "lat": "46.3651519",
      "lon": "10.9428132",
      "superficie_kmq": "20.8086",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022034",
      "denominazione_ita_altra": "Caldonazzo",
      "denominazione_ita": "Caldonazzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B404",
      "lat": "45.9914181",
      "lon": "11.2618477",
      "superficie_kmq": "21.4132",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022035",
      "denominazione_ita_altra": "Calliano",
      "denominazione_ita": "Calliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B419",
      "lat": "45.9342837",
      "lon": "11.0943287",
      "superficie_kmq": "10.1988",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022036",
      "denominazione_ita_altra": "Campitello di Fassa",
      "denominazione_ita": "Campitello di Fassa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B514",
      "lat": "46.4757685",
      "lon": "11.7400939",
      "superficie_kmq": "25.0233",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022037",
      "denominazione_ita_altra": "Campodenno",
      "denominazione_ita": "Campodenno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B525",
      "lat": "46.2573286",
      "lon": "11.0333681",
      "superficie_kmq": "25.0230",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022038",
      "denominazione_ita_altra": "Canal San Bovo",
      "denominazione_ita": "Canal San Bovo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B577",
      "lat": "46.1563305",
      "lon": "11.7313806",
      "superficie_kmq": "125.6823",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022039",
      "denominazione_ita_altra": "Canazei",
      "denominazione_ita": "Canazei",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B579",
      "lat": "46.4768552",
      "lon": "11.7713006",
      "superficie_kmq": "67.0177",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022040",
      "denominazione_ita_altra": "Capriana",
      "denominazione_ita": "Capriana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B697",
      "lat": "46.2629886",
      "lon": "11.3391712",
      "superficie_kmq": "12.8188",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022042",
      "denominazione_ita_altra": "Carisolo",
      "denominazione_ita": "Carisolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B783",
      "lat": "46.1690637",
      "lon": "10.7638650",
      "superficie_kmq": "25.1224",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022043",
      "denominazione_ita_altra": "Carzano",
      "denominazione_ita": "Carzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B856",
      "lat": "46.0708909",
      "lon": "11.4936298",
      "superficie_kmq": "1.8202",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022045",
      "denominazione_ita_altra": "Castel Condino",
      "denominazione_ita": "Castel Condino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C183",
      "lat": "45.9152234",
      "lon": "10.6028401",
      "superficie_kmq": "11.1004",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022047",
      "denominazione_ita_altra": "Castello-Molina di Fiemme",
      "denominazione_ita": "Castello-Molina di Fiemme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C189",
      "lat": "46.2812673",
      "lon": "11.4347073",
      "superficie_kmq": "54.5589",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022048",
      "denominazione_ita_altra": "Castello Tesino",
      "denominazione_ita": "Castello Tesino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C194",
      "lat": "46.0624302",
      "lon": "11.6337060",
      "superficie_kmq": "112.8343",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022049",
      "denominazione_ita_altra": "Castelnuovo",
      "denominazione_ita": "Castelnuovo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C216",
      "lat": "46.0522767",
      "lon": "11.4942003",
      "superficie_kmq": "13.1441",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022050",
      "denominazione_ita_altra": "Cavalese",
      "denominazione_ita": "Cavalese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C372",
      "lat": "46.2913160",
      "lon": "11.4604344",
      "superficie_kmq": "45.3757",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022051",
      "denominazione_ita_altra": "Cavareno",
      "denominazione_ita": "Cavareno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C380",
      "lat": "46.4079345",
      "lon": "11.1366035",
      "superficie_kmq": "9.4842",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022052",
      "denominazione_ita_altra": "Cavedago",
      "denominazione_ita": "Cavedago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C392",
      "lat": "46.1835452",
      "lon": "11.0280570",
      "superficie_kmq": "10.0280",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022053",
      "denominazione_ita_altra": "Cavedine",
      "denominazione_ita": "Cavedine",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C393",
      "lat": "45.9939285",
      "lon": "10.9708231",
      "superficie_kmq": "38.2265",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022054",
      "denominazione_ita_altra": "Cavizzana",
      "denominazione_ita": "Cavizzana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C400",
      "lat": "46.3682074",
      "lon": "10.9583729",
      "superficie_kmq": "3.3770",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022058",
      "denominazione_ita_altra": "Cimone",
      "denominazione_ita": "Cimone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C700",
      "lat": "45.9782037",
      "lon": "11.0684666",
      "superficie_kmq": "9.8070",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022059",
      "denominazione_ita_altra": "Cinte Tesino",
      "denominazione_ita": "Cinte Tesino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C712",
      "lat": "46.0543835",
      "lon": "11.6161950",
      "superficie_kmq": "30.2967",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022060",
      "denominazione_ita_altra": "Cis",
      "denominazione_ita": "Cis",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C727",
      "lat": "46.4000737",
      "lon": "11.0023392",
      "superficie_kmq": "5.4976",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022061",
      "denominazione_ita_altra": "Civezzano",
      "denominazione_ita": "Civezzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C756",
      "lat": "46.0900733",
      "lon": "11.1796326",
      "superficie_kmq": "15.6663",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022062",
      "denominazione_ita_altra": "Cles",
      "denominazione_ita": "Cles",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C794",
      "lat": "46.3644234",
      "lon": "11.0356444",
      "superficie_kmq": "39.1656",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022064",
      "denominazione_ita_altra": "Commezzadura",
      "denominazione_ita": "Commezzadura",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C931",
      "lat": "46.3212412",
      "lon": "10.8392626",
      "superficie_kmq": "22.0249",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022068",
      "denominazione_ita_altra": "Croviana",
      "denominazione_ita": "Croviana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D188",
      "lat": "46.3446547",
      "lon": "10.9045479",
      "superficie_kmq": "4.9892",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022071",
      "denominazione_ita_altra": "Dambel",
      "denominazione_ita": "Dambel",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D246",
      "lat": "46.4049342",
      "lon": "11.0930234",
      "superficie_kmq": "5.1451",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022074",
      "denominazione_ita_altra": "Denno",
      "denominazione_ita": "Denno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D273",
      "lat": "46.2743717",
      "lon": "11.0513268",
      "superficie_kmq": "10.6440",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022078",
      "denominazione_ita_altra": "Drena",
      "denominazione_ita": "Drena",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D365",
      "lat": "45.9711820",
      "lon": "10.9445968",
      "superficie_kmq": "8.3367",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022079",
      "denominazione_ita_altra": "Dro",
      "denominazione_ita": "Dro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D371",
      "lat": "45.9625620",
      "lon": "10.9118161",
      "superficie_kmq": "27.9534",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022081",
      "denominazione_ita_altra": "Fai della Paganella",
      "denominazione_ita": "Fai della Paganella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D468",
      "lat": "46.1784003",
      "lon": "11.0695614",
      "superficie_kmq": "12.1293",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022083",
      "denominazione_ita_altra": "Fiavè",
      "denominazione_ita": "Fiavè",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D565",
      "lat": "46.0048203",
      "lon": "10.8420252",
      "superficie_kmq": "24.2822",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022085",
      "denominazione_ita_altra": "Fierozzo",
      "denominazione_ita": "Fierozzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D573",
      "lat": "46.1117957",
      "lon": "11.3182365",
      "superficie_kmq": "17.9425",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022087",
      "denominazione_ita_altra": "Folgaria",
      "denominazione_ita": "Folgaria",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D651",
      "lat": "45.9166454",
      "lon": "11.1701415",
      "superficie_kmq": "71.6258",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022089",
      "denominazione_ita_altra": "Fornace",
      "denominazione_ita": "Fornace",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D714",
      "lat": "46.1172488",
      "lon": "11.2058014",
      "superficie_kmq": "7.2241",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022090",
      "denominazione_ita_altra": "Frassilongo",
      "denominazione_ita": "Frassilongo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D775",
      "lat": "46.0856735",
      "lon": "11.2921236",
      "superficie_kmq": "16.6807",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022091",
      "denominazione_ita_altra": "Garniga Terme",
      "denominazione_ita": "Garniga Terme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D928",
      "lat": "46.0048432",
      "lon": "11.0858884",
      "superficie_kmq": "13.1251",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022092",
      "denominazione_ita_altra": "Giovo",
      "denominazione_ita": "Giovo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E048",
      "lat": "46.1568452",
      "lon": "11.1516813",
      "superficie_kmq": "20.8129",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022093",
      "denominazione_ita_altra": "Giustino",
      "denominazione_ita": "Giustino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E065",
      "lat": "46.1487958",
      "lon": "10.7684783",
      "superficie_kmq": "39.3866",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022095",
      "denominazione_ita_altra": "Grigno",
      "denominazione_ita": "Grigno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E178",
      "lat": "46.0171889",
      "lon": "11.6356184",
      "superficie_kmq": "46.3809",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022097",
      "denominazione_ita_altra": "Imer",
      "denominazione_ita": "Imer",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E288",
      "lat": "46.1511103",
      "lon": "11.7968022",
      "superficie_kmq": "27.7294",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022098",
      "denominazione_ita_altra": "Isera",
      "denominazione_ita": "Isera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E334",
      "lat": "45.8870874",
      "lon": "11.0083525",
      "superficie_kmq": "14.0920",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022102",
      "denominazione_ita_altra": "Lavarone",
      "denominazione_ita": "Lavarone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E492",
      "lat": "45.9394019",
      "lon": "11.2697045",
      "superficie_kmq": "26.3125",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022103",
      "denominazione_ita_altra": "Lavis",
      "denominazione_ita": "Lavis",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E500",
      "lat": "46.1397306",
      "lon": "11.1117347",
      "superficie_kmq": "12.1781",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022104",
      "denominazione_ita_altra": "Levico Terme",
      "denominazione_ita": "Levico Terme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E565",
      "lat": "46.0110264",
      "lon": "11.3023282",
      "superficie_kmq": "62.8282",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022106",
      "denominazione_ita_altra": "Livo",
      "denominazione_ita": "Livo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E624",
      "lat": "46.4055691",
      "lon": "11.0191694",
      "superficie_kmq": "15.2178",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022108",
      "denominazione_ita_altra": "Lona-Lases",
      "denominazione_ita": "Lona-Lases",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E664",
      "lat": "46.1468112",
      "lon": "11.2188251",
      "superficie_kmq": "11.3726",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022109",
      "denominazione_ita_altra": "Luserna",
      "denominazione_ita": "Luserna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E757",
      "lat": "45.9219445",
      "lon": "11.3243916",
      "superficie_kmq": "8.1971",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022110",
      "denominazione_ita_altra": "Malé",
      "denominazione_ita": "Malé",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E850",
      "lat": "46.3527169",
      "lon": "10.9124832",
      "superficie_kmq": "26.5277",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022112",
      "denominazione_ita_altra": "Massimeno",
      "denominazione_ita": "Massimeno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F045",
      "lat": "46.1423204",
      "lon": "10.7730062",
      "superficie_kmq": "21.0322",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022113",
      "denominazione_ita_altra": "Mazzin",
      "denominazione_ita": "Mazzin",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F068",
      "lat": "46.4703841",
      "lon": "11.7284110",
      "superficie_kmq": "23.6281",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022114",
      "denominazione_ita_altra": "Mezzana",
      "denominazione_ita": "Mezzana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F168",
      "lat": "46.3158271",
      "lon": "10.8027707",
      "superficie_kmq": "27.3498",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022115",
      "denominazione_ita_altra": "Mezzano",
      "denominazione_ita": "Mezzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F176",
      "lat": "46.1545431",
      "lon": "11.8059539",
      "superficie_kmq": "48.8455",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022116",
      "denominazione_ita_altra": "Mezzocorona",
      "denominazione_ita": "Mezzocorona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F183",
      "lat": "46.2165070",
      "lon": "11.1185005",
      "superficie_kmq": "25.3530",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022117",
      "denominazione_ita_altra": "Mezzolombardo",
      "denominazione_ita": "Mezzolombardo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F187",
      "lat": "46.2135310",
      "lon": "11.0905186",
      "superficie_kmq": "13.8790",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022118",
      "denominazione_ita_altra": "Moena",
      "denominazione_ita": "Moena",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F263",
      "lat": "46.3766256",
      "lon": "11.6602202",
      "superficie_kmq": "82.5944",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022120",
      "denominazione_ita_altra": "Molveno",
      "denominazione_ita": "Molveno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F307",
      "lat": "46.1429050",
      "lon": "10.9662540",
      "superficie_kmq": "34.1156",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022123",
      "denominazione_ita_altra": "Mori",
      "denominazione_ita": "Mori",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F728",
      "lat": "45.8519617",
      "lon": "10.9754949",
      "superficie_kmq": "34.4834",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022124",
      "denominazione_ita_altra": "Nago-Torbole",
      "denominazione_ita": "Nago-Torbole",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F835",
      "lat": "45.8705869",
      "lon": "10.8760539",
      "superficie_kmq": "28.3842",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022127",
      "denominazione_ita_altra": "Nogaredo",
      "denominazione_ita": "Nogaredo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F920",
      "lat": "45.9120223",
      "lon": "11.0230911",
      "superficie_kmq": "3.6147",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022128",
      "denominazione_ita_altra": "Nomi",
      "denominazione_ita": "Nomi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F929",
      "lat": "45.9289690",
      "lon": "11.0712576",
      "superficie_kmq": "6.4887",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022129",
      "denominazione_ita_altra": "Novaledo",
      "denominazione_ita": "Novaledo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F947",
      "lat": "46.0225896",
      "lon": "11.3657999",
      "superficie_kmq": "7.9653",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022130",
      "denominazione_ita_altra": "Ospedaletto",
      "denominazione_ita": "Ospedaletto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G168",
      "lat": "46.0436469",
      "lon": "11.5529037",
      "superficie_kmq": "16.7474",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022131",
      "denominazione_ita_altra": "Ossana",
      "denominazione_ita": "Ossana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G173",
      "lat": "46.3062946",
      "lon": "10.7380438",
      "superficie_kmq": "25.2486",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022133",
      "denominazione_ita_altra": "Palù del Fersina",
      "denominazione_ita": "Palù del Fersina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G296",
      "lat": "46.1299237",
      "lon": "11.3521348",
      "superficie_kmq": "16.6481",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022134",
      "denominazione_ita_altra": "Panchià",
      "denominazione_ita": "Panchià",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G305",
      "lat": "46.2856971",
      "lon": "11.5422330",
      "superficie_kmq": "20.2112",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022135",
      "denominazione_ita_altra": "Ronzo-Chienis",
      "denominazione_ita": "Ronzo-Chienis",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M303",
      "lat": "45.8908110",
      "lon": "10.9499071",
      "superficie_kmq": "13.2084",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022136",
      "denominazione_ita_altra": "Peio",
      "denominazione_ita": "Peio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G419",
      "lat": "46.3553062",
      "lon": "10.6890785",
      "superficie_kmq": "162.3024",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022137",
      "denominazione_ita_altra": "Pellizzano",
      "denominazione_ita": "Pellizzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G428",
      "lat": "46.3094042",
      "lon": "10.7605657",
      "superficie_kmq": "48.3606",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022138",
      "denominazione_ita_altra": "Pelugo",
      "denominazione_ita": "Pelugo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G429",
      "lat": "46.0881259",
      "lon": "10.7233422",
      "superficie_kmq": "22.9782",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022139",
      "denominazione_ita_altra": "Pergine Valsugana",
      "denominazione_ita": "Pergine Valsugana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G452",
      "lat": "46.0603318",
      "lon": "11.2404944",
      "superficie_kmq": "54.3284",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022142",
      "denominazione_ita_altra": "Pieve Tesino",
      "denominazione_ita": "Pieve Tesino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G656",
      "lat": "46.0690258",
      "lon": "11.6105744",
      "superficie_kmq": "69.2335",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022143",
      "denominazione_ita_altra": "Pinzolo",
      "denominazione_ita": "Pinzolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G681",
      "lat": "46.1600268",
      "lon": "10.7666064",
      "superficie_kmq": "69.3210",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022144",
      "denominazione_ita_altra": "Pomarolo",
      "denominazione_ita": "Pomarolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G808",
      "lat": "45.9268110",
      "lon": "11.0430555",
      "superficie_kmq": "9.2285",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022147",
      "denominazione_ita_altra": "Predazzo",
      "denominazione_ita": "Predazzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H018",
      "lat": "46.3125820",
      "lon": "11.6027825",
      "superficie_kmq": "109.9648",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022150",
      "denominazione_ita_altra": "Rabbi",
      "denominazione_ita": "Rabbi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H146",
      "lat": "46.3982719",
      "lon": "10.8506561",
      "superficie_kmq": "132.7874",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022153",
      "denominazione_ita_altra": "Riva del Garda",
      "denominazione_ita": "Riva del Garda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H330",
      "lat": "45.8850557",
      "lon": "10.8388886",
      "superficie_kmq": "40.7248",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022155",
      "denominazione_ita_altra": "Romeno",
      "denominazione_ita": "Romeno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H517",
      "lat": "46.3944168",
      "lon": "11.1201905",
      "superficie_kmq": "9.1279",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022156",
      "denominazione_ita_altra": "Roncegno Terme",
      "denominazione_ita": "Roncegno Terme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H528",
      "lat": "46.0509999",
      "lon": "11.4096893",
      "superficie_kmq": "38.0833",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022157",
      "denominazione_ita_altra": "Ronchi Valsugana",
      "denominazione_ita": "Ronchi Valsugana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H532",
      "lat": "46.0671515",
      "lon": "11.4342980",
      "superficie_kmq": "10.0030",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022159",
      "denominazione_ita_altra": "Ronzone",
      "denominazione_ita": "Ronzone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H552",
      "lat": "46.4233594",
      "lon": "11.1499998",
      "superficie_kmq": "5.2973",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022160",
      "denominazione_ita_altra": "Roverè della Luna",
      "denominazione_ita": "Roverè della Luna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H607",
      "lat": "46.2500050",
      "lon": "11.1696090",
      "superficie_kmq": "10.4144",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022161",
      "denominazione_ita_altra": "Rovereto",
      "denominazione_ita": "Rovereto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H612",
      "lat": "45.8864957",
      "lon": "11.0451756",
      "superficie_kmq": "50.9842",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022162",
      "denominazione_ita_altra": "Ruffrè-Mendola",
      "denominazione_ita": "Ruffrè-Mendola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H634",
      "lat": "46.4145248",
      "lon": "11.1774696",
      "superficie_kmq": "6.5800",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022163",
      "denominazione_ita_altra": "Rumo",
      "denominazione_ita": "Rumo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H639",
      "lat": "46.4410893",
      "lon": "11.0173275",
      "superficie_kmq": "30.8540",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022164",
      "denominazione_ita_altra": "Sagron Mis",
      "denominazione_ita": "Sagron Mis",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H666",
      "lat": "46.1949421",
      "lon": "11.9432772",
      "superficie_kmq": "11.0617",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022165",
      "denominazione_ita_altra": "Samone",
      "denominazione_ita": "Samone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H754",
      "lat": "46.0818885",
      "lon": "11.5203766",
      "superficie_kmq": "4.9020",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022167",
      "denominazione_ita_altra": "San Michele all'Adige",
      "denominazione_ita": "San Michele all'Adige",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I042",
      "lat": "46.1926808",
      "lon": "11.1340516",
      "superficie_kmq": "15.9962",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022168",
      "denominazione_ita_altra": "Sant'Orsola Terme",
      "denominazione_ita": "Sant'Orsola Terme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I354",
      "lat": "46.1082151",
      "lon": "11.3025585",
      "superficie_kmq": "15.3639",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022169",
      "denominazione_ita_altra": "Sanzeno",
      "denominazione_ita": "Sanzeno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I411",
      "lat": "46.3744370",
      "lon": "11.0680600",
      "superficie_kmq": "7.8749",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022170",
      "denominazione_ita_altra": "Sarnonico",
      "denominazione_ita": "Sarnonico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I439",
      "lat": "46.4200639",
      "lon": "11.1414097",
      "superficie_kmq": "12.1898",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022171",
      "denominazione_ita_altra": "Scurelle",
      "denominazione_ita": "Scurelle",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I554",
      "lat": "46.0644982",
      "lon": "11.5075872",
      "superficie_kmq": "29.9965",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022172",
      "denominazione_ita_altra": "Segonzano",
      "denominazione_ita": "Segonzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I576",
      "lat": "46.1903769",
      "lon": "11.2587665",
      "superficie_kmq": "20.7121",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022173",
      "denominazione_ita_altra": "Sfruz",
      "denominazione_ita": "Sfruz",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I714",
      "lat": "46.3371866",
      "lon": "11.1154727",
      "superficie_kmq": "11.8085",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022176",
      "denominazione_ita_altra": "Soraga di Fassa",
      "denominazione_ita": "Soraga di Fassa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I839",
      "lat": "46.3960560",
      "lon": "11.6688133",
      "superficie_kmq": "19.7447",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022177",
      "denominazione_ita_altra": "Sover",
      "denominazione_ita": "Sover",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I871",
      "lat": "46.2211995",
      "lon": "11.3153280",
      "superficie_kmq": "14.8220",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022179",
      "denominazione_ita_altra": "Spiazzo",
      "denominazione_ita": "Spiazzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I899",
      "lat": "46.1028648",
      "lon": "10.7367996",
      "superficie_kmq": "71.0600",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022180",
      "denominazione_ita_altra": "Spormaggiore",
      "denominazione_ita": "Spormaggiore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I924",
      "lat": "46.2185938",
      "lon": "11.0482281",
      "superficie_kmq": "30.2000",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022181",
      "denominazione_ita_altra": "Sporminore",
      "denominazione_ita": "Sporminore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I925",
      "lat": "46.2371347",
      "lon": "11.0311912",
      "superficie_kmq": "17.4709",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022182",
      "denominazione_ita_altra": "Stenico",
      "denominazione_ita": "Stenico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I949",
      "lat": "46.0530275",
      "lon": "10.8536000",
      "superficie_kmq": "49.1459",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022183",
      "denominazione_ita_altra": "Storo",
      "denominazione_ita": "Storo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I964",
      "lat": "45.8492846",
      "lon": "10.5801864",
      "superficie_kmq": "62.9318",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022184",
      "denominazione_ita_altra": "Strembo",
      "denominazione_ita": "Strembo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I975",
      "lat": "46.1200272",
      "lon": "10.7516154",
      "superficie_kmq": "38.3312",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022188",
      "denominazione_ita_altra": "Telve",
      "denominazione_ita": "Telve",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L089",
      "lat": "46.0714607",
      "lon": "11.4788898",
      "superficie_kmq": "64.7474",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022189",
      "denominazione_ita_altra": "Telve di Sopra",
      "denominazione_ita": "Telve di Sopra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L090",
      "lat": "46.0723273",
      "lon": "11.4725564",
      "superficie_kmq": "17.7162",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022190",
      "denominazione_ita_altra": "Tenna",
      "denominazione_ita": "Tenna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L096",
      "lat": "46.0145975",
      "lon": "11.2662957",
      "superficie_kmq": "3.1147",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022191",
      "denominazione_ita_altra": "Tenno",
      "denominazione_ita": "Tenno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L097",
      "lat": "45.9186130",
      "lon": "10.8326445",
      "superficie_kmq": "28.3011",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022193",
      "denominazione_ita_altra": "Terragnolo",
      "denominazione_ita": "Terragnolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L121",
      "lat": "45.8787136",
      "lon": "11.1530399",
      "superficie_kmq": "39.5662",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022195",
      "denominazione_ita_altra": "Terzolas",
      "denominazione_ita": "Terzolas",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L145",
      "lat": "46.3613894",
      "lon": "10.9261198",
      "superficie_kmq": "5.5888",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022196",
      "denominazione_ita_altra": "Tesero",
      "denominazione_ita": "Tesero",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L147",
      "lat": "46.2893568",
      "lon": "11.5111892",
      "superficie_kmq": "50.5452",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022199",
      "denominazione_ita_altra": "Tione di Trento",
      "denominazione_ita": "Tione di Trento",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L174",
      "lat": "46.0317278",
      "lon": "10.7232647",
      "superficie_kmq": "33.4464",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022200",
      "denominazione_ita_altra": "Ton",
      "denominazione_ita": "Ton",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L200",
      "lat": "46.2659159",
      "lon": "11.0857047",
      "superficie_kmq": "26.2845",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022202",
      "denominazione_ita_altra": "Torcegno",
      "denominazione_ita": "Torcegno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L211",
      "lat": "46.0732971",
      "lon": "11.4500167",
      "superficie_kmq": "15.1927",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022203",
      "denominazione_ita_altra": "Trambileno",
      "denominazione_ita": "Trambileno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L322",
      "lat": "45.8697101",
      "lon": "11.0734094",
      "superficie_kmq": "50.6989",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022205",
      "denominazione_ita_altra": "Trento",
      "denominazione_ita": "Trento",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "L378",
      "lat": "46.0689495",
      "lon": "11.1212315",
      "superficie_kmq": "157.8748",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022209",
      "denominazione_ita_altra": "Valfloriana",
      "denominazione_ita": "Valfloriana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L575",
      "lat": "46.2489829",
      "lon": "11.3421408",
      "superficie_kmq": "39.3284",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022210",
      "denominazione_ita_altra": "Vallarsa",
      "denominazione_ita": "Vallarsa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L588",
      "lat": "45.7826025",
      "lon": "11.1176321",
      "superficie_kmq": "77.8636",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022213",
      "denominazione_ita_altra": "Vermiglio",
      "denominazione_ita": "Vermiglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L769",
      "lat": "46.2988443",
      "lon": "10.6928514",
      "superficie_kmq": "95.6345",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022216",
      "denominazione_ita_altra": "Vignola-Falesina",
      "denominazione_ita": "Vignola-Falesina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L886",
      "lat": "46.0439325",
      "lon": "11.2781676",
      "superficie_kmq": "11.9514",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022222",
      "denominazione_ita_altra": "Villa Lagarina",
      "denominazione_ita": "Villa Lagarina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L957",
      "lat": "45.9160536",
      "lon": "11.0318473",
      "superficie_kmq": "24.1287",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022224",
      "denominazione_ita_altra": "Volano",
      "denominazione_ita": "Volano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M113",
      "lat": "45.9172053",
      "lon": "11.0607911",
      "superficie_kmq": "10.7401",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022226",
      "denominazione_ita_altra": "Ziano di Fiemme",
      "denominazione_ita": "Ziano di Fiemme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M173",
      "lat": "46.2864865",
      "lon": "11.5611239",
      "superficie_kmq": "35.7467",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022228",
      "denominazione_ita_altra": "Comano Terme",
      "denominazione_ita": "Comano Terme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M314",
      "lat": "46.0316748",
      "lon": "10.8688527",
      "superficie_kmq": "68.1133",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022229",
      "denominazione_ita_altra": "Ledro",
      "denominazione_ita": "Ledro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M313",
      "lat": "45.8887202",
      "lon": "10.7331136",
      "superficie_kmq": "156.3723",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022230",
      "denominazione_ita_altra": "Predaia",
      "denominazione_ita": "Predaia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M344",
      "lat": "46.3278223",
      "lon": "11.1195865",
      "superficie_kmq": "80.0485",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022231",
      "denominazione_ita_altra": "San Lorenzo Dorsino",
      "denominazione_ita": "San Lorenzo Dorsino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M345",
      "lat": "46.1089291",
      "lon": "10.9024926",
      "superficie_kmq": "73.9123",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022232",
      "denominazione_ita_altra": "Valdaone",
      "denominazione_ita": "Valdaone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M343",
      "lat": "46.0165068",
      "lon": "10.5448387",
      "superficie_kmq": "177.0828",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022233",
      "denominazione_ita_altra": "Dimaro Folgarida",
      "denominazione_ita": "Dimaro Folgarida",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M366",
      "lat": "46.2976962",
      "lon": "10.8823745",
      "superficie_kmq": "36.5328",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022234",
      "denominazione_ita_altra": "Pieve di Bono-Prezzo",
      "denominazione_ita": "Pieve di Bono-Prezzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M365",
      "lat": "45.9402286",
      "lon": "10.6560368",
      "superficie_kmq": "24.6809",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022235",
      "denominazione_ita_altra": "Altavalle",
      "denominazione_ita": "Altavalle",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M349",
      "lat": "46.2235484",
      "lon": "11.2676346",
      "superficie_kmq": "33.5472",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022236",
      "denominazione_ita_altra": "Altopiano della Vigolana",
      "denominazione_ita": "Altopiano della Vigolana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M350",
      "lat": "45.9872127",
      "lon": "11.2002969",
      "superficie_kmq": "45.0271",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022237",
      "denominazione_ita_altra": "Amblar-Don",
      "denominazione_ita": "Amblar-Don",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M351",
      "lat": "46.3856432",
      "lon": "11.1715541",
      "superficie_kmq": "19.9640",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022238",
      "denominazione_ita_altra": "Borgo Chiese",
      "denominazione_ita": "Borgo Chiese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M352",
      "lat": "45.8999944",
      "lon": "10.5701925",
      "superficie_kmq": "53.7123",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022239",
      "denominazione_ita_altra": "Borgo Lares",
      "denominazione_ita": "Borgo Lares",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M353",
      "lat": "46.0052134",
      "lon": "10.7358174",
      "superficie_kmq": "22.6200",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022240",
      "denominazione_ita_altra": "Castel Ivano",
      "denominazione_ita": "Castel Ivano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M354",
      "lat": "46.0648995",
      "lon": "11.5351952",
      "superficie_kmq": "34.8203",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022241",
      "denominazione_ita_altra": "Cembra Lisignago",
      "denominazione_ita": "Cembra Lisignago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M355",
      "lat": "46.1829305",
      "lon": "11.2056360",
      "superficie_kmq": "24.1056",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022242",
      "denominazione_ita_altra": "Contà",
      "denominazione_ita": "Contà",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M356",
      "lat": "46.2949085",
      "lon": "11.0176060",
      "superficie_kmq": "19.4692",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022243",
      "denominazione_ita_altra": "Madruzzo",
      "denominazione_ita": "Madruzzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M357",
      "lat": "46.0358847",
      "lon": "10.9838367",
      "superficie_kmq": "28.9303",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022244",
      "denominazione_ita_altra": "Porte di Rendena",
      "denominazione_ita": "Porte di Rendena",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M358",
      "lat": "46.0745463",
      "lon": "10.6607265",
      "superficie_kmq": "40.7133",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022245",
      "denominazione_ita_altra": "Primiero San Martino di Castrozza",
      "denominazione_ita": "Primiero San Martino di Castrozza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M359",
      "lat": "46.2422263",
      "lon": "11.8266877",
      "superficie_kmq": "200.0489",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022246",
      "denominazione_ita_altra": "Sella Giudicarie",
      "denominazione_ita": "Sella Giudicarie",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M360",
      "lat": "46.0130409",
      "lon": "10.6367163",
      "superficie_kmq": "85.7572",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022247",
      "denominazione_ita_altra": "Tre Ville",
      "denominazione_ita": "Tre Ville",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M361",
      "lat": "46.1564636",
      "lon": "10.8315197",
      "superficie_kmq": "81.4868",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022248",
      "denominazione_ita_altra": "Vallelaghi",
      "denominazione_ita": "Vallelaghi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M362",
      "lat": "46.1006562",
      "lon": "11.0092469",
      "superficie_kmq": "72.4548",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022249",
      "denominazione_ita_altra": "Ville d'Anaunia",
      "denominazione_ita": "Ville d'Anaunia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M363",
      "lat": "46.2769501",
      "lon": "10.9617812",
      "superficie_kmq": "89.1318",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022250",
      "denominazione_ita_altra": "San Giovanni di Fassa-Sèn Jan",
      "denominazione_ita": "San Giovanni di Fassa",
      "denominazione_altra": "Sèn Jan",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M390",
      "lat": "46.4237965",
      "lon": "11.7040934",
      "superficie_kmq": "99.8223",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022251",
      "denominazione_ita_altra": "Terre d'Adige",
      "denominazione_ita": "Terre d'Adige",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M407",
      "lat": "46.1615656",
      "lon": "11.0689747",
      "superficie_kmq": "16.5774",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022252",
      "denominazione_ita_altra": "Borgo d'Anaunia",
      "denominazione_ita": "Borgo d'Anaunia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M429",
      "lat": "46.4732483",
      "lon": "11.1383516",
      "superficie_kmq": "63.2287",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022253",
      "denominazione_ita_altra": "Novella",
      "denominazione_ita": "Novella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M430",
      "lat": "46.4306969",
      "lon": "11.0706461",
      "superficie_kmq": "46.5889",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "TN",
      "codice_istat": "022254",
      "denominazione_ita_altra": "Ville di Fiemme",
      "denominazione_ita": "Ville di Fiemme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M431",
      "lat": "46.3175860",
      "lon": "11.4490126",
      "superficie_kmq": "46.1493",
      "codice_sovracomunale": "022"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023001",
      "denominazione_ita_altra": "Affi",
      "denominazione_ita": "Affi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A061",
      "lat": "45.5515850",
      "lon": "10.7731547",
      "superficie_kmq": "9.8814",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023002",
      "denominazione_ita_altra": "Albaredo d'Adige",
      "denominazione_ita": "Albaredo d'Adige",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A137",
      "lat": "45.3173915",
      "lon": "11.2675831",
      "superficie_kmq": "28.2532",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023003",
      "denominazione_ita_altra": "Angiari",
      "denominazione_ita": "Angiari",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A292",
      "lat": "45.2180222",
      "lon": "11.2837225",
      "superficie_kmq": "13.4706",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023004",
      "denominazione_ita_altra": "Arcole",
      "denominazione_ita": "Arcole",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A374",
      "lat": "45.3576839",
      "lon": "11.2869510",
      "superficie_kmq": "18.8657",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023005",
      "denominazione_ita_altra": "Badia Calavena",
      "denominazione_ita": "Badia Calavena",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A540",
      "lat": "45.5639016",
      "lon": "11.1563573",
      "superficie_kmq": "26.9382",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023006",
      "denominazione_ita_altra": "Bardolino",
      "denominazione_ita": "Bardolino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A650",
      "lat": "45.5471007",
      "lon": "10.7196679",
      "superficie_kmq": "57.3221",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023007",
      "denominazione_ita_altra": "Belfiore",
      "denominazione_ita": "Belfiore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A737",
      "lat": "45.3803719",
      "lon": "11.2084219",
      "superficie_kmq": "26.4474",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023008",
      "denominazione_ita_altra": "Bevilacqua",
      "denominazione_ita": "Bevilacqua",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A837",
      "lat": "45.2334487",
      "lon": "11.3981931",
      "superficie_kmq": "12.2007",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023009",
      "denominazione_ita_altra": "Bonavigo",
      "denominazione_ita": "Bonavigo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A964",
      "lat": "45.2575469",
      "lon": "11.2797079",
      "superficie_kmq": "17.9947",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023010",
      "denominazione_ita_altra": "Boschi Sant'Anna",
      "denominazione_ita": "Boschi Sant'Anna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B070",
      "lat": "45.2194591",
      "lon": "11.3579303",
      "superficie_kmq": "8.9664",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023011",
      "denominazione_ita_altra": "Bosco Chiesanuova",
      "denominazione_ita": "Bosco Chiesanuova",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B073",
      "lat": "45.6221409",
      "lon": "11.0294200",
      "superficie_kmq": "64.7987",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023012",
      "denominazione_ita_altra": "Bovolone",
      "denominazione_ita": "Bovolone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B107",
      "lat": "45.2579906",
      "lon": "11.1170615",
      "superficie_kmq": "41.2729",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023013",
      "denominazione_ita_altra": "Brentino Belluno",
      "denominazione_ita": "Brentino Belluno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B152",
      "lat": "45.6561769",
      "lon": "10.8934651",
      "superficie_kmq": "25.9838",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023014",
      "denominazione_ita_altra": "Brenzone sul Garda",
      "denominazione_ita": "Brenzone sul Garda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B154",
      "lat": "45.7089124",
      "lon": "10.7674737",
      "superficie_kmq": "51.5829",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023015",
      "denominazione_ita_altra": "Bussolengo",
      "denominazione_ita": "Bussolengo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B296",
      "lat": "45.4745982",
      "lon": "10.8464192",
      "superficie_kmq": "24.2258",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023016",
      "denominazione_ita_altra": "Buttapietra",
      "denominazione_ita": "Buttapietra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B304",
      "lat": "45.3419890",
      "lon": "10.9984971",
      "superficie_kmq": "17.2692",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023017",
      "denominazione_ita_altra": "Caldiero",
      "denominazione_ita": "Caldiero",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B402",
      "lat": "45.4121006",
      "lon": "11.1837799",
      "superficie_kmq": "10.3727",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023018",
      "denominazione_ita_altra": "Caprino Veronese",
      "denominazione_ita": "Caprino Veronese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B709",
      "lat": "45.6065760",
      "lon": "10.7937796",
      "superficie_kmq": "47.3173",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023019",
      "denominazione_ita_altra": "Casaleone",
      "denominazione_ita": "Casaleone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B886",
      "lat": "45.1697738",
      "lon": "11.2002218",
      "superficie_kmq": "38.6028",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023020",
      "denominazione_ita_altra": "Castagnaro",
      "denominazione_ita": "Castagnaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C041",
      "lat": "45.1204998",
      "lon": "11.4108341",
      "superficie_kmq": "34.7978",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023021",
      "denominazione_ita_altra": "Castel d'Azzano",
      "denominazione_ita": "Castel d'Azzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C078",
      "lat": "45.3535914",
      "lon": "10.9440043",
      "superficie_kmq": "9.7241",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023022",
      "denominazione_ita_altra": "Castelnuovo del Garda",
      "denominazione_ita": "Castelnuovo del Garda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C225",
      "lat": "45.4403864",
      "lon": "10.7637003",
      "superficie_kmq": "34.4290",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023023",
      "denominazione_ita_altra": "Cavaion Veronese",
      "denominazione_ita": "Cavaion Veronese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C370",
      "lat": "45.5382949",
      "lon": "10.7716833",
      "superficie_kmq": "12.9092",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023024",
      "denominazione_ita_altra": "Cazzano di Tramigna",
      "denominazione_ita": "Cazzano di Tramigna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C412",
      "lat": "45.4724447",
      "lon": "11.2034857",
      "superficie_kmq": "12.2708",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023025",
      "denominazione_ita_altra": "Cerea",
      "denominazione_ita": "Cerea",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C498",
      "lat": "45.1920220",
      "lon": "11.2128731",
      "superficie_kmq": "70.2947",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023026",
      "denominazione_ita_altra": "Cerro Veronese",
      "denominazione_ita": "Cerro Veronese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C538",
      "lat": "45.5741911",
      "lon": "11.0415906",
      "superficie_kmq": "10.0567",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023027",
      "denominazione_ita_altra": "Cologna Veneta",
      "denominazione_ita": "Cologna Veneta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C890",
      "lat": "45.3106900",
      "lon": "11.3843943",
      "superficie_kmq": "42.8339",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023028",
      "denominazione_ita_altra": "Colognola ai Colli",
      "denominazione_ita": "Colognola ai Colli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C897",
      "lat": "45.4343994",
      "lon": "11.1867113",
      "superficie_kmq": "20.8968",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023029",
      "denominazione_ita_altra": "Concamarise",
      "denominazione_ita": "Concamarise",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C943",
      "lat": "45.2073569",
      "lon": "11.1388336",
      "superficie_kmq": "7.9136",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023030",
      "denominazione_ita_altra": "Costermano sul Garda",
      "denominazione_ita": "Costermano sul Garda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D118",
      "lat": "45.5865745",
      "lon": "10.7384887",
      "superficie_kmq": "16.7441",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023031",
      "denominazione_ita_altra": "Dolcè",
      "denominazione_ita": "Dolcè",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D317",
      "lat": "45.6000526",
      "lon": "10.8527032",
      "superficie_kmq": "30.9499",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023032",
      "denominazione_ita_altra": "Erbè",
      "denominazione_ita": "Erbè",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D419",
      "lat": "45.2424654",
      "lon": "10.9673400",
      "superficie_kmq": "16.1025",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023033",
      "denominazione_ita_altra": "Erbezzo",
      "denominazione_ita": "Erbezzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D420",
      "lat": "45.6417195",
      "lon": "11.0005555",
      "superficie_kmq": "31.9690",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023034",
      "denominazione_ita_altra": "Ferrara di Monte Baldo",
      "denominazione_ita": "Ferrara di Monte Baldo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D549",
      "lat": "45.6756757",
      "lon": "10.8542851",
      "superficie_kmq": "26.8895",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023035",
      "denominazione_ita_altra": "Fumane",
      "denominazione_ita": "Fumane",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D818",
      "lat": "45.5431728",
      "lon": "10.8821133",
      "superficie_kmq": "34.2113",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023036",
      "denominazione_ita_altra": "Garda",
      "denominazione_ita": "Garda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D915",
      "lat": "45.5749759",
      "lon": "10.7070211",
      "superficie_kmq": "14.3691",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023037",
      "denominazione_ita_altra": "Gazzo Veronese",
      "denominazione_ita": "Gazzo Veronese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D957",
      "lat": "45.1424989",
      "lon": "11.1036212",
      "superficie_kmq": "56.6542",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023038",
      "denominazione_ita_altra": "Grezzana",
      "denominazione_ita": "Grezzana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E171",
      "lat": "45.5463728",
      "lon": "11.0476339",
      "superficie_kmq": "49.4866",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023039",
      "denominazione_ita_altra": "Illasi",
      "denominazione_ita": "Illasi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E284",
      "lat": "45.4659381",
      "lon": "11.1841870",
      "superficie_kmq": "25.0029",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023040",
      "denominazione_ita_altra": "Isola della Scala",
      "denominazione_ita": "Isola della Scala",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E349",
      "lat": "45.2736659",
      "lon": "11.0075362",
      "superficie_kmq": "69.8295",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023041",
      "denominazione_ita_altra": "Isola Rizza",
      "denominazione_ita": "Isola Rizza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E358",
      "lat": "45.2914429",
      "lon": "11.1987100",
      "superficie_kmq": "16.6774",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023042",
      "denominazione_ita_altra": "Lavagno",
      "denominazione_ita": "Lavagno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E489",
      "lat": "45.4406723",
      "lon": "11.1316711",
      "superficie_kmq": "14.6372",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023043",
      "denominazione_ita_altra": "Lazise",
      "denominazione_ita": "Lazise",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E502",
      "lat": "45.5050770",
      "lon": "10.7324709",
      "superficie_kmq": "63.1465",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023044",
      "denominazione_ita_altra": "Legnago",
      "denominazione_ita": "Legnago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E512",
      "lat": "45.1913910",
      "lon": "11.3075182",
      "superficie_kmq": "79.2725",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023045",
      "denominazione_ita_altra": "Malcesine",
      "denominazione_ita": "Malcesine",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E848",
      "lat": "45.7628563",
      "lon": "10.8078188",
      "superficie_kmq": "69.2779",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023046",
      "denominazione_ita_altra": "Marano di Valpolicella",
      "denominazione_ita": "Marano di Valpolicella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E911",
      "lat": "45.5538495",
      "lon": "10.9154144",
      "superficie_kmq": "18.6220",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023047",
      "denominazione_ita_altra": "Mezzane di Sotto",
      "denominazione_ita": "Mezzane di Sotto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F172",
      "lat": "45.4810773",
      "lon": "11.1275579",
      "superficie_kmq": "19.7080",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023048",
      "denominazione_ita_altra": "Minerbe",
      "denominazione_ita": "Minerbe",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F218",
      "lat": "45.2417587",
      "lon": "11.3406971",
      "superficie_kmq": "29.6505",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023049",
      "denominazione_ita_altra": "Montecchia di Crosara",
      "denominazione_ita": "Montecchia di Crosara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F461",
      "lat": "45.4854028",
      "lon": "11.2531852",
      "superficie_kmq": "21.0625",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023050",
      "denominazione_ita_altra": "Monteforte d'Alpone",
      "denominazione_ita": "Monteforte d'Alpone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F508",
      "lat": "45.4222602",
      "lon": "11.2850528",
      "superficie_kmq": "20.4705",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023051",
      "denominazione_ita_altra": "Mozzecane",
      "denominazione_ita": "Mozzecane",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F789",
      "lat": "45.3097085",
      "lon": "10.8177109",
      "superficie_kmq": "24.8517",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023052",
      "denominazione_ita_altra": "Negrar di Valpolicella",
      "denominazione_ita": "Negrar di Valpolicella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F861",
      "lat": "45.5306618",
      "lon": "10.9378253",
      "superficie_kmq": "40.4219",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023053",
      "denominazione_ita_altra": "Nogara",
      "denominazione_ita": "Nogara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F918",
      "lat": "45.1778411",
      "lon": "11.0609056",
      "superficie_kmq": "38.7778",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023054",
      "denominazione_ita_altra": "Nogarole Rocca",
      "denominazione_ita": "Nogarole Rocca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F921",
      "lat": "45.2916441",
      "lon": "10.8837123",
      "superficie_kmq": "29.1355",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023055",
      "denominazione_ita_altra": "Oppeano",
      "denominazione_ita": "Oppeano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G080",
      "lat": "45.3079014",
      "lon": "11.1783500",
      "superficie_kmq": "46.7257",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023056",
      "denominazione_ita_altra": "Palù",
      "denominazione_ita": "Palù",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G297",
      "lat": "45.3246951",
      "lon": "11.1545685",
      "superficie_kmq": "13.6103",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023057",
      "denominazione_ita_altra": "Pastrengo",
      "denominazione_ita": "Pastrengo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G365",
      "lat": "45.4921820",
      "lon": "10.8002736",
      "superficie_kmq": "9.0015",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023058",
      "denominazione_ita_altra": "Pescantina",
      "denominazione_ita": "Pescantina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G481",
      "lat": "45.4825671",
      "lon": "10.8672444",
      "superficie_kmq": "19.7259",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023059",
      "denominazione_ita_altra": "Peschiera del Garda",
      "denominazione_ita": "Peschiera del Garda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G489",
      "lat": "45.4391437",
      "lon": "10.6919409",
      "superficie_kmq": "18.2606",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023060",
      "denominazione_ita_altra": "Povegliano Veronese",
      "denominazione_ita": "Povegliano Veronese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G945",
      "lat": "45.3472657",
      "lon": "10.8809296",
      "superficie_kmq": "18.5283",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023061",
      "denominazione_ita_altra": "Pressana",
      "denominazione_ita": "Pressana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H048",
      "lat": "45.2847894",
      "lon": "11.4019799",
      "superficie_kmq": "17.3894",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023062",
      "denominazione_ita_altra": "Rivoli Veronese",
      "denominazione_ita": "Rivoli Veronese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H356",
      "lat": "45.5711526",
      "lon": "10.8125056",
      "superficie_kmq": "18.4271",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023063",
      "denominazione_ita_altra": "Roncà",
      "denominazione_ita": "Roncà",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H522",
      "lat": "45.4794599",
      "lon": "11.2901440",
      "superficie_kmq": "18.1485",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023064",
      "denominazione_ita_altra": "Ronco all'Adige",
      "denominazione_ita": "Ronco all'Adige",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H540",
      "lat": "45.3357070",
      "lon": "11.2464117",
      "superficie_kmq": "42.8144",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023065",
      "denominazione_ita_altra": "Roverchiara",
      "denominazione_ita": "Roverchiara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H606",
      "lat": "45.2724209",
      "lon": "11.2478902",
      "superficie_kmq": "19.6466",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023066",
      "denominazione_ita_altra": "Roveredo di Guà",
      "denominazione_ita": "Roveredo di Guà",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H610",
      "lat": "45.2727284",
      "lon": "11.4426760",
      "superficie_kmq": "10.1597",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023067",
      "denominazione_ita_altra": "Roverè Veronese",
      "denominazione_ita": "Roverè Veronese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H608",
      "lat": "45.5933473",
      "lon": "11.0656557",
      "superficie_kmq": "36.5476",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023068",
      "denominazione_ita_altra": "Salizzole",
      "denominazione_ita": "Salizzole",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H714",
      "lat": "45.2427745",
      "lon": "11.0838143",
      "superficie_kmq": "30.6954",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023069",
      "denominazione_ita_altra": "San Bonifacio",
      "denominazione_ita": "San Bonifacio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H783",
      "lat": "45.3959274",
      "lon": "11.2699236",
      "superficie_kmq": "33.7920",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023070",
      "denominazione_ita_altra": "San Giovanni Ilarione",
      "denominazione_ita": "San Giovanni Ilarione",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H916",
      "lat": "45.5209396",
      "lon": "11.2357763",
      "superficie_kmq": "25.4033",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023071",
      "denominazione_ita_altra": "San Giovanni Lupatoto",
      "denominazione_ita": "San Giovanni Lupatoto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H924",
      "lat": "45.3800913",
      "lon": "11.0501949",
      "superficie_kmq": "19.0108",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023072",
      "denominazione_ita_altra": "Sanguinetto",
      "denominazione_ita": "Sanguinetto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H944",
      "lat": "45.1827474",
      "lon": "11.1511797",
      "superficie_kmq": "13.5055",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023073",
      "denominazione_ita_altra": "San Martino Buon Albergo",
      "denominazione_ita": "San Martino Buon Albergo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I003",
      "lat": "45.4221506",
      "lon": "11.0931341",
      "superficie_kmq": "34.7507",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023074",
      "denominazione_ita_altra": "San Mauro di Saline",
      "denominazione_ita": "San Mauro di Saline",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H712",
      "lat": "45.5652169",
      "lon": "11.1124703",
      "superficie_kmq": "11.2398",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023075",
      "denominazione_ita_altra": "San Pietro di Morubio",
      "denominazione_ita": "San Pietro di Morubio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I105",
      "lat": "45.2481866",
      "lon": "11.2322385",
      "superficie_kmq": "16.1169",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023076",
      "denominazione_ita_altra": "San Pietro in Cariano",
      "denominazione_ita": "San Pietro in Cariano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I109",
      "lat": "45.5173162",
      "lon": "10.8864615",
      "superficie_kmq": "20.2358",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023077",
      "denominazione_ita_altra": "Sant'Ambrogio di Valpolicella",
      "denominazione_ita": "Sant'Ambrogio di Valpolicella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I259",
      "lat": "45.5246315",
      "lon": "10.8336797",
      "superficie_kmq": "23.4999",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023078",
      "denominazione_ita_altra": "Sant'Anna d'Alfaedo",
      "denominazione_ita": "Sant'Anna d'Alfaedo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I292",
      "lat": "45.6271413",
      "lon": "10.9510820",
      "superficie_kmq": "43.4279",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023079",
      "denominazione_ita_altra": "San Zeno di Montagna",
      "denominazione_ita": "San Zeno di Montagna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I414",
      "lat": "45.6379575",
      "lon": "10.7323587",
      "superficie_kmq": "28.2389",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023080",
      "denominazione_ita_altra": "Selva di Progno",
      "denominazione_ita": "Selva di Progno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I594",
      "lat": "45.6123273",
      "lon": "11.1388202",
      "superficie_kmq": "41.3327",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023081",
      "denominazione_ita_altra": "Soave",
      "denominazione_ita": "Soave",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I775",
      "lat": "45.4226206",
      "lon": "11.2486226",
      "superficie_kmq": "22.7206",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023082",
      "denominazione_ita_altra": "Sommacampagna",
      "denominazione_ita": "Sommacampagna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I821",
      "lat": "45.4071062",
      "lon": "10.8404049",
      "superficie_kmq": "40.8265",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023083",
      "denominazione_ita_altra": "Sona",
      "denominazione_ita": "Sona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I826",
      "lat": "45.4340319",
      "lon": "10.8326655",
      "superficie_kmq": "41.1449",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023084",
      "denominazione_ita_altra": "Sorgà",
      "denominazione_ita": "Sorgà",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I850",
      "lat": "45.2120299",
      "lon": "10.9805370",
      "superficie_kmq": "31.5347",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023085",
      "denominazione_ita_altra": "Terrazzo",
      "denominazione_ita": "Terrazzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L136",
      "lat": "45.1733282",
      "lon": "11.3984721",
      "superficie_kmq": "20.5271",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023086",
      "denominazione_ita_altra": "Torri del Benaco",
      "denominazione_ita": "Torri del Benaco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L287",
      "lat": "45.6080874",
      "lon": "10.6852181",
      "superficie_kmq": "46.2913",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023087",
      "denominazione_ita_altra": "Tregnago",
      "denominazione_ita": "Tregnago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L364",
      "lat": "45.5166442",
      "lon": "11.1646210",
      "superficie_kmq": "37.3481",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023088",
      "denominazione_ita_altra": "Trevenzuolo",
      "denominazione_ita": "Trevenzuolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L396",
      "lat": "45.2713078",
      "lon": "10.9330415",
      "superficie_kmq": "26.9416",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023089",
      "denominazione_ita_altra": "Valeggio sul Mincio",
      "denominazione_ita": "Valeggio sul Mincio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L567",
      "lat": "45.3536434",
      "lon": "10.7344474",
      "superficie_kmq": "63.9590",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023090",
      "denominazione_ita_altra": "Velo Veronese",
      "denominazione_ita": "Velo Veronese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L722",
      "lat": "45.6052877",
      "lon": "11.0958909",
      "superficie_kmq": "18.9017",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023091",
      "denominazione_ita_altra": "Verona",
      "denominazione_ita": "Verona",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "L781",
      "lat": "45.4383955",
      "lon": "10.9935270",
      "superficie_kmq": "198.9134",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023092",
      "denominazione_ita_altra": "Veronella",
      "denominazione_ita": "Veronella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D193",
      "lat": "45.3230875",
      "lon": "11.3204931",
      "superficie_kmq": "20.8801",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023093",
      "denominazione_ita_altra": "Vestenanova",
      "denominazione_ita": "Vestenanova",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L810",
      "lat": "45.5734601",
      "lon": "11.2276202",
      "superficie_kmq": "24.1820",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023094",
      "denominazione_ita_altra": "Vigasio",
      "denominazione_ita": "Vigasio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L869",
      "lat": "45.3174562",
      "lon": "10.9433446",
      "superficie_kmq": "30.7626",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023095",
      "denominazione_ita_altra": "Villa Bartolomea",
      "denominazione_ita": "Villa Bartolomea",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L912",
      "lat": "45.1592835",
      "lon": "11.3533289",
      "superficie_kmq": "52.9886",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023096",
      "denominazione_ita_altra": "Villafranca di Verona",
      "denominazione_ita": "Villafranca di Verona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L949",
      "lat": "45.3508082",
      "lon": "10.8467369",
      "superficie_kmq": "57.3407",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023097",
      "denominazione_ita_altra": "Zevio",
      "denominazione_ita": "Zevio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M172",
      "lat": "45.3755619",
      "lon": "11.1353356",
      "superficie_kmq": "54.8687",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VR",
      "codice_istat": "023098",
      "denominazione_ita_altra": "Zimella",
      "denominazione_ita": "Zimella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M178",
      "lat": "45.3635266",
      "lon": "11.3392062",
      "superficie_kmq": "20.0992",
      "codice_sovracomunale": "023"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024001",
      "denominazione_ita_altra": "Agugliaro",
      "denominazione_ita": "Agugliaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A093",
      "lat": "45.3239815",
      "lon": "11.5849046",
      "superficie_kmq": "14.6954",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024002",
      "denominazione_ita_altra": "Albettone",
      "denominazione_ita": "Albettone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A154",
      "lat": "45.3583312",
      "lon": "11.5851888",
      "superficie_kmq": "20.2106",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024003",
      "denominazione_ita_altra": "Alonte",
      "denominazione_ita": "Alonte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A220",
      "lat": "45.3658540",
      "lon": "11.4266195",
      "superficie_kmq": "11.1452",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024004",
      "denominazione_ita_altra": "Altavilla Vicentina",
      "denominazione_ita": "Altavilla Vicentina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A231",
      "lat": "45.5128100",
      "lon": "11.4646057",
      "superficie_kmq": "16.7234",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024005",
      "denominazione_ita_altra": "Altissimo",
      "denominazione_ita": "Altissimo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A236",
      "lat": "45.6151388",
      "lon": "11.2512340",
      "superficie_kmq": "15.0861",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024006",
      "denominazione_ita_altra": "Arcugnano",
      "denominazione_ita": "Arcugnano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A377",
      "lat": "45.4961276",
      "lon": "11.5507514",
      "superficie_kmq": "41.5682",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024007",
      "denominazione_ita_altra": "Arsiero",
      "denominazione_ita": "Arsiero",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A444",
      "lat": "45.8036216",
      "lon": "11.3503463",
      "superficie_kmq": "41.3952",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024008",
      "denominazione_ita_altra": "Arzignano",
      "denominazione_ita": "Arzignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A459",
      "lat": "45.5210497",
      "lon": "11.3322743",
      "superficie_kmq": "34.1902",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024009",
      "denominazione_ita_altra": "Asiago",
      "denominazione_ita": "Asiago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A465",
      "lat": "45.8757130",
      "lon": "11.5091183",
      "superficie_kmq": "162.9334",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024010",
      "denominazione_ita_altra": "Asigliano Veneto",
      "denominazione_ita": "Asigliano Veneto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A467",
      "lat": "45.3044917",
      "lon": "11.4461782",
      "superficie_kmq": "8.0656",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024012",
      "denominazione_ita_altra": "Bassano del Grappa",
      "denominazione_ita": "Bassano del Grappa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A703",
      "lat": "45.7671465",
      "lon": "11.7341748",
      "superficie_kmq": "47.0617",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024013",
      "denominazione_ita_altra": "Bolzano Vicentino",
      "denominazione_ita": "Bolzano Vicentino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A954",
      "lat": "45.6007356",
      "lon": "11.6220685",
      "superficie_kmq": "19.8367",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024014",
      "denominazione_ita_altra": "Breganze",
      "denominazione_ita": "Breganze",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B132",
      "lat": "45.7082691",
      "lon": "11.5653071",
      "superficie_kmq": "21.7608",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024015",
      "denominazione_ita_altra": "Brendola",
      "denominazione_ita": "Brendola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B143",
      "lat": "45.4693693",
      "lon": "11.4552170",
      "superficie_kmq": "25.5675",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024016",
      "denominazione_ita_altra": "Bressanvido",
      "denominazione_ita": "Bressanvido",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B161",
      "lat": "45.6463366",
      "lon": "11.6333753",
      "superficie_kmq": "8.4405",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024017",
      "denominazione_ita_altra": "Brogliano",
      "denominazione_ita": "Brogliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B196",
      "lat": "45.5884100",
      "lon": "11.3644508",
      "superficie_kmq": "12.1551",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024018",
      "denominazione_ita_altra": "Caldogno",
      "denominazione_ita": "Caldogno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B403",
      "lat": "45.6119874",
      "lon": "11.5004602",
      "superficie_kmq": "15.8845",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024019",
      "denominazione_ita_altra": "Caltrano",
      "denominazione_ita": "Caltrano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B433",
      "lat": "45.7703248",
      "lon": "11.4605607",
      "superficie_kmq": "22.7111",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024020",
      "denominazione_ita_altra": "Calvene",
      "denominazione_ita": "Calvene",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B441",
      "lat": "45.7659016",
      "lon": "11.5122287",
      "superficie_kmq": "11.4722",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024021",
      "denominazione_ita_altra": "Camisano Vicentino",
      "denominazione_ita": "Camisano Vicentino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B485",
      "lat": "45.5220766",
      "lon": "11.7133182",
      "superficie_kmq": "30.0209",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024022",
      "denominazione_ita_altra": "Campiglia dei Berici",
      "denominazione_ita": "Campiglia dei Berici",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B511",
      "lat": "45.3366984",
      "lon": "11.5390235",
      "superficie_kmq": "11.0401",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024024",
      "denominazione_ita_altra": "Carrè",
      "denominazione_ita": "Carrè",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B835",
      "lat": "45.7474169",
      "lon": "11.4587682",
      "superficie_kmq": "8.7389",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024025",
      "denominazione_ita_altra": "Cartigliano",
      "denominazione_ita": "Cartigliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B844",
      "lat": "45.7135142",
      "lon": "11.6940821",
      "superficie_kmq": "7.3770",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024026",
      "denominazione_ita_altra": "Cassola",
      "denominazione_ita": "Cassola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C037",
      "lat": "45.7325980",
      "lon": "11.7989321",
      "superficie_kmq": "12.7395",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024027",
      "denominazione_ita_altra": "Castegnero",
      "denominazione_ita": "Castegnero",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C056",
      "lat": "45.4363006",
      "lon": "11.5991727",
      "superficie_kmq": "11.6202",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024028",
      "denominazione_ita_altra": "Castelgomberto",
      "denominazione_ita": "Castelgomberto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C119",
      "lat": "45.5859421",
      "lon": "11.3944663",
      "superficie_kmq": "17.4369",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024029",
      "denominazione_ita_altra": "Chiampo",
      "denominazione_ita": "Chiampo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C605",
      "lat": "45.5448555",
      "lon": "11.2816614",
      "superficie_kmq": "22.6040",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024030",
      "denominazione_ita_altra": "Chiuppano",
      "denominazione_ita": "Chiuppano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C650",
      "lat": "45.7613121",
      "lon": "11.4640911",
      "superficie_kmq": "4.7093",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024032",
      "denominazione_ita_altra": "Cogollo del Cengio",
      "denominazione_ita": "Cogollo del Cengio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C824",
      "lat": "45.7863451",
      "lon": "11.4206769",
      "superficie_kmq": "36.2167",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024034",
      "denominazione_ita_altra": "Cornedo Vicentino",
      "denominazione_ita": "Cornedo Vicentino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D020",
      "lat": "45.6141368",
      "lon": "11.3431961",
      "superficie_kmq": "23.5599",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024035",
      "denominazione_ita_altra": "Costabissara",
      "denominazione_ita": "Costabissara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D107",
      "lat": "45.5854565",
      "lon": "11.4876628",
      "superficie_kmq": "13.1320",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024036",
      "denominazione_ita_altra": "Creazzo",
      "denominazione_ita": "Creazzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D136",
      "lat": "45.5318603",
      "lon": "11.4789136",
      "superficie_kmq": "10.5285",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024037",
      "denominazione_ita_altra": "Crespadoro",
      "denominazione_ita": "Crespadoro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D156",
      "lat": "45.6194300",
      "lon": "11.2260336",
      "superficie_kmq": "30.2015",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024038",
      "denominazione_ita_altra": "Dueville",
      "denominazione_ita": "Dueville",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D379",
      "lat": "45.6356977",
      "lon": "11.5487654",
      "superficie_kmq": "20.0095",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024039",
      "denominazione_ita_altra": "Enego",
      "denominazione_ita": "Enego",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D407",
      "lat": "45.9399791",
      "lon": "11.7097181",
      "superficie_kmq": "52.6032",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024040",
      "denominazione_ita_altra": "Fara Vicentino",
      "denominazione_ita": "Fara Vicentino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D496",
      "lat": "45.7404623",
      "lon": "11.5462268",
      "superficie_kmq": "15.1748",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024041",
      "denominazione_ita_altra": "Foza",
      "denominazione_ita": "Foza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D750",
      "lat": "45.8962617",
      "lon": "11.6307566",
      "superficie_kmq": "35.2089",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024042",
      "denominazione_ita_altra": "Gallio",
      "denominazione_ita": "Gallio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D882",
      "lat": "45.8923787",
      "lon": "11.5491642",
      "superficie_kmq": "47.8665",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024043",
      "denominazione_ita_altra": "Gambellara",
      "denominazione_ita": "Gambellara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D897",
      "lat": "45.4602541",
      "lon": "11.3414461",
      "superficie_kmq": "12.9966",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024046",
      "denominazione_ita_altra": "Grisignano di Zocco",
      "denominazione_ita": "Grisignano di Zocco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E184",
      "lat": "45.4753673",
      "lon": "11.6988178",
      "superficie_kmq": "17.0202",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024047",
      "denominazione_ita_altra": "Grumolo delle Abbadesse",
      "denominazione_ita": "Grumolo delle Abbadesse",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E226",
      "lat": "45.5162318",
      "lon": "11.6589790",
      "superficie_kmq": "15.0136",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024048",
      "denominazione_ita_altra": "Isola Vicentina",
      "denominazione_ita": "Isola Vicentina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E354",
      "lat": "45.6296511",
      "lon": "11.4415129",
      "superficie_kmq": "26.4789",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024049",
      "denominazione_ita_altra": "Laghi",
      "denominazione_ita": "Laghi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E403",
      "lat": "45.8242341",
      "lon": "11.2732362",
      "superficie_kmq": "22.2370",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024050",
      "denominazione_ita_altra": "Lastebasse",
      "denominazione_ita": "Lastebasse",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E465",
      "lat": "45.9161601",
      "lon": "11.2697303",
      "superficie_kmq": "18.7970",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024051",
      "denominazione_ita_altra": "Longare",
      "denominazione_ita": "Longare",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E671",
      "lat": "45.4787639",
      "lon": "11.6070217",
      "superficie_kmq": "22.7741",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024052",
      "denominazione_ita_altra": "Lonigo",
      "denominazione_ita": "Lonigo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E682",
      "lat": "45.3888326",
      "lon": "11.3874323",
      "superficie_kmq": "49.4205",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024053",
      "denominazione_ita_altra": "Lugo di Vicenza",
      "denominazione_ita": "Lugo di Vicenza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E731",
      "lat": "45.7531427",
      "lon": "11.5228578",
      "superficie_kmq": "14.5640",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024055",
      "denominazione_ita_altra": "Malo",
      "denominazione_ita": "Malo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E864",
      "lat": "45.6586502",
      "lon": "11.4044358",
      "superficie_kmq": "30.5281",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024056",
      "denominazione_ita_altra": "Marano Vicentino",
      "denominazione_ita": "Marano Vicentino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E912",
      "lat": "45.6924011",
      "lon": "11.4302884",
      "superficie_kmq": "12.7254",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024057",
      "denominazione_ita_altra": "Marostica",
      "denominazione_ita": "Marostica",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E970",
      "lat": "45.7474007",
      "lon": "11.6559484",
      "superficie_kmq": "36.5289",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024060",
      "denominazione_ita_altra": "Montebello Vicentino",
      "denominazione_ita": "Montebello Vicentino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F442",
      "lat": "45.4575951",
      "lon": "11.3858406",
      "superficie_kmq": "21.4752",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024061",
      "denominazione_ita_altra": "Montecchio Maggiore",
      "denominazione_ita": "Montecchio Maggiore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F464",
      "lat": "45.5058238",
      "lon": "11.4073374",
      "superficie_kmq": "30.5401",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024062",
      "denominazione_ita_altra": "Montecchio Precalcino",
      "denominazione_ita": "Montecchio Precalcino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F465",
      "lat": "45.6639573",
      "lon": "11.5616338",
      "superficie_kmq": "14.4203",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024063",
      "denominazione_ita_altra": "Monte di Malo",
      "denominazione_ita": "Monte di Malo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F486",
      "lat": "45.6603783",
      "lon": "11.3614146",
      "superficie_kmq": "23.7485",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024064",
      "denominazione_ita_altra": "Montegalda",
      "denominazione_ita": "Montegalda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F514",
      "lat": "45.4435206",
      "lon": "11.6728620",
      "superficie_kmq": "17.4127",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024065",
      "denominazione_ita_altra": "Montegaldella",
      "denominazione_ita": "Montegaldella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F515",
      "lat": "45.4360846",
      "lon": "11.6740895",
      "superficie_kmq": "13.5735",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024066",
      "denominazione_ita_altra": "Monteviale",
      "denominazione_ita": "Monteviale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F662",
      "lat": "45.5607632",
      "lon": "11.4578993",
      "superficie_kmq": "8.4385",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024067",
      "denominazione_ita_altra": "Monticello Conte Otto",
      "denominazione_ita": "Monticello Conte Otto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F675",
      "lat": "45.5952702",
      "lon": "11.5804684",
      "superficie_kmq": "10.2417",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024068",
      "denominazione_ita_altra": "Montorso Vicentino",
      "denominazione_ita": "Montorso Vicentino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F696",
      "lat": "45.4896535",
      "lon": "11.3622171",
      "superficie_kmq": "9.2946",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024070",
      "denominazione_ita_altra": "Mussolente",
      "denominazione_ita": "Mussolente",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F829",
      "lat": "45.7789805",
      "lon": "11.8011812",
      "superficie_kmq": "15.4308",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024071",
      "denominazione_ita_altra": "Nanto",
      "denominazione_ita": "Nanto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F838",
      "lat": "45.4252604",
      "lon": "11.5915640",
      "superficie_kmq": "14.3508",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024072",
      "denominazione_ita_altra": "Nogarole Vicentino",
      "denominazione_ita": "Nogarole Vicentino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F922",
      "lat": "45.5594827",
      "lon": "11.2879315",
      "superficie_kmq": "9.0933",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024073",
      "denominazione_ita_altra": "Nove",
      "denominazione_ita": "Nove",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F957",
      "lat": "45.7246219",
      "lon": "11.6792510",
      "superficie_kmq": "8.1511",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024074",
      "denominazione_ita_altra": "Noventa Vicentina",
      "denominazione_ita": "Noventa Vicentina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F964",
      "lat": "45.2909098",
      "lon": "11.5404613",
      "superficie_kmq": "22.8794",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024075",
      "denominazione_ita_altra": "Orgiano",
      "denominazione_ita": "Orgiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G095",
      "lat": "45.3515944",
      "lon": "11.4664833",
      "superficie_kmq": "18.0844",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024076",
      "denominazione_ita_altra": "Pedemonte",
      "denominazione_ita": "Pedemonte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G406",
      "lat": "45.9085362",
      "lon": "11.3058765",
      "superficie_kmq": "12.5920",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024077",
      "denominazione_ita_altra": "Pianezze",
      "denominazione_ita": "Pianezze",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G560",
      "lat": "45.7387328",
      "lon": "11.6248566",
      "superficie_kmq": "5.0235",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024078",
      "denominazione_ita_altra": "Piovene Rocchette",
      "denominazione_ita": "Piovene Rocchette",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G694",
      "lat": "45.7587928",
      "lon": "11.4302115",
      "superficie_kmq": "12.9065",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024079",
      "denominazione_ita_altra": "Pojana Maggiore",
      "denominazione_ita": "Pojana Maggiore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G776",
      "lat": "45.2920712",
      "lon": "11.5013777",
      "superficie_kmq": "28.6152",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024080",
      "denominazione_ita_altra": "Posina",
      "denominazione_ita": "Posina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G931",
      "lat": "45.7903982",
      "lon": "11.2611934",
      "superficie_kmq": "43.6354",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024081",
      "denominazione_ita_altra": "Pove del Grappa",
      "denominazione_ita": "Pove del Grappa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G943",
      "lat": "45.7989134",
      "lon": "11.7290517",
      "superficie_kmq": "9.8406",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024082",
      "denominazione_ita_altra": "Pozzoleone",
      "denominazione_ita": "Pozzoleone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G957",
      "lat": "45.6491328",
      "lon": "11.6792595",
      "superficie_kmq": "11.2548",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024083",
      "denominazione_ita_altra": "Quinto Vicentino",
      "denominazione_ita": "Quinto Vicentino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H134",
      "lat": "45.5730635",
      "lon": "11.6250271",
      "superficie_kmq": "17.3964",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024084",
      "denominazione_ita_altra": "Recoaro Terme",
      "denominazione_ita": "Recoaro Terme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H214",
      "lat": "45.7051271",
      "lon": "11.2260270",
      "superficie_kmq": "60.1484",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024085",
      "denominazione_ita_altra": "Roana",
      "denominazione_ita": "Roana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H361",
      "lat": "45.8685762",
      "lon": "11.4782990",
      "superficie_kmq": "78.1257",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024086",
      "denominazione_ita_altra": "Romano d'Ezzelino",
      "denominazione_ita": "Romano d'Ezzelino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H512",
      "lat": "45.7745630",
      "lon": "11.7707041",
      "superficie_kmq": "21.3495",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024087",
      "denominazione_ita_altra": "Rosà",
      "denominazione_ita": "Rosà",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H556",
      "lat": "45.7247726",
      "lon": "11.7615478",
      "superficie_kmq": "24.3153",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024088",
      "denominazione_ita_altra": "Rossano Veneto",
      "denominazione_ita": "Rossano Veneto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H580",
      "lat": "45.7050778",
      "lon": "11.7999001",
      "superficie_kmq": "10.5966",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024089",
      "denominazione_ita_altra": "Rotzo",
      "denominazione_ita": "Rotzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H594",
      "lat": "45.8639098",
      "lon": "11.4004165",
      "superficie_kmq": "28.2505",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024090",
      "denominazione_ita_altra": "Salcedo",
      "denominazione_ita": "Salcedo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F810",
      "lat": "45.7584711",
      "lon": "11.5657602",
      "superficie_kmq": "6.1160",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024091",
      "denominazione_ita_altra": "Sandrigo",
      "denominazione_ita": "Sandrigo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H829",
      "lat": "45.6613314",
      "lon": "11.6027818",
      "superficie_kmq": "27.9910",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024094",
      "denominazione_ita_altra": "San Pietro Mussolino",
      "denominazione_ita": "San Pietro Mussolino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I117",
      "lat": "45.5873121",
      "lon": "11.2581450",
      "superficie_kmq": "4.1081",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024095",
      "denominazione_ita_altra": "Santorso",
      "denominazione_ita": "Santorso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I353",
      "lat": "45.7377008",
      "lon": "11.3887270",
      "superficie_kmq": "13.2085",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024096",
      "denominazione_ita_altra": "San Vito di Leguzzano",
      "denominazione_ita": "San Vito di Leguzzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I401",
      "lat": "45.6817603",
      "lon": "11.3761856",
      "superficie_kmq": "6.1264",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024097",
      "denominazione_ita_altra": "Sarcedo",
      "denominazione_ita": "Sarcedo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I425",
      "lat": "45.7080812",
      "lon": "11.5261873",
      "superficie_kmq": "13.8528",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024098",
      "denominazione_ita_altra": "Sarego",
      "denominazione_ita": "Sarego",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I430",
      "lat": "45.4071375",
      "lon": "11.4050182",
      "superficie_kmq": "23.9223",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024099",
      "denominazione_ita_altra": "Schiavon",
      "denominazione_ita": "Schiavon",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I527",
      "lat": "45.6977581",
      "lon": "11.6442791",
      "superficie_kmq": "12.0019",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024100",
      "denominazione_ita_altra": "Schio",
      "denominazione_ita": "Schio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I531",
      "lat": "45.7130676",
      "lon": "11.3569367",
      "superficie_kmq": "66.2059",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024101",
      "denominazione_ita_altra": "Solagna",
      "denominazione_ita": "Solagna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I783",
      "lat": "45.8169292",
      "lon": "11.7179968",
      "superficie_kmq": "15.8119",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024102",
      "denominazione_ita_altra": "Sossano",
      "denominazione_ita": "Sossano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I867",
      "lat": "45.3585701",
      "lon": "11.5099379",
      "superficie_kmq": "20.8975",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024104",
      "denominazione_ita_altra": "Tezze sul Brenta",
      "denominazione_ita": "Tezze sul Brenta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L156",
      "lat": "45.6874438",
      "lon": "11.7043850",
      "superficie_kmq": "17.9344",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024105",
      "denominazione_ita_altra": "Thiene",
      "denominazione_ita": "Thiene",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L157",
      "lat": "45.7073201",
      "lon": "11.4783497",
      "superficie_kmq": "19.7031",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024106",
      "denominazione_ita_altra": "Tonezza del Cimone",
      "denominazione_ita": "Tonezza del Cimone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D717",
      "lat": "45.8581852",
      "lon": "11.3462455",
      "superficie_kmq": "13.9407",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024107",
      "denominazione_ita_altra": "Torrebelvicino",
      "denominazione_ita": "Torrebelvicino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L248",
      "lat": "45.7166486",
      "lon": "11.3065945",
      "superficie_kmq": "20.7397",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024108",
      "denominazione_ita_altra": "Torri di Quartesolo",
      "denominazione_ita": "Torri di Quartesolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L297",
      "lat": "45.5195680",
      "lon": "11.6162920",
      "superficie_kmq": "18.6733",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024110",
      "denominazione_ita_altra": "Trissino",
      "denominazione_ita": "Trissino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L433",
      "lat": "45.5651475",
      "lon": "11.3742187",
      "superficie_kmq": "21.9571",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024111",
      "denominazione_ita_altra": "Valdagno",
      "denominazione_ita": "Valdagno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L551",
      "lat": "45.6453863",
      "lon": "11.2994781",
      "superficie_kmq": "50.2157",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024112",
      "denominazione_ita_altra": "Valdastico",
      "denominazione_ita": "Valdastico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L554",
      "lat": "45.8832567",
      "lon": "11.3633515",
      "superficie_kmq": "23.9487",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024113",
      "denominazione_ita_altra": "Valli del Pasubio",
      "denominazione_ita": "Valli del Pasubio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L624",
      "lat": "45.7418054",
      "lon": "11.2598566",
      "superficie_kmq": "49.3448",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024115",
      "denominazione_ita_altra": "Velo d'Astico",
      "denominazione_ita": "Velo d'Astico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L723",
      "lat": "45.7886571",
      "lon": "11.3661878",
      "superficie_kmq": "21.9041",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024116",
      "denominazione_ita_altra": "Vicenza",
      "denominazione_ita": "Vicenza",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "L840",
      "lat": "45.5475038",
      "lon": "11.5459714",
      "superficie_kmq": "80.5798",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024117",
      "denominazione_ita_altra": "Villaga",
      "denominazione_ita": "Villaga",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L952",
      "lat": "45.4022901",
      "lon": "11.5339910",
      "superficie_kmq": "23.2250",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024118",
      "denominazione_ita_altra": "Villaverla",
      "denominazione_ita": "Villaverla",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M032",
      "lat": "45.6549909",
      "lon": "11.4935854",
      "superficie_kmq": "15.7881",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024119",
      "denominazione_ita_altra": "Zanè",
      "denominazione_ita": "Zanè",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M145",
      "lat": "45.7206228",
      "lon": "11.4540763",
      "superficie_kmq": "7.6450",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024120",
      "denominazione_ita_altra": "Zermeghedo",
      "denominazione_ita": "Zermeghedo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M170",
      "lat": "45.4754404",
      "lon": "11.3688941",
      "superficie_kmq": "2.9709",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024121",
      "denominazione_ita_altra": "Zovencedo",
      "denominazione_ita": "Zovencedo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M194",
      "lat": "45.4280029",
      "lon": "11.5026586",
      "superficie_kmq": "9.0441",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024122",
      "denominazione_ita_altra": "Zugliano",
      "denominazione_ita": "Zugliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M199",
      "lat": "45.7336211",
      "lon": "11.5214295",
      "superficie_kmq": "13.7301",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024123",
      "denominazione_ita_altra": "Val Liona",
      "denominazione_ita": "Val Liona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M384",
      "lat": "45.4039053",
      "lon": "11.4688387",
      "superficie_kmq": "27.8442",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024124",
      "denominazione_ita_altra": "Barbarano Mossano",
      "denominazione_ita": "Barbarano Mossano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M401",
      "lat": "45.4087769",
      "lon": "11.5647122",
      "superficie_kmq": "33.4857",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024125",
      "denominazione_ita_altra": "Valbrenta",
      "denominazione_ita": "Valbrenta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M423",
      "lat": "45.8781100",
      "lon": "11.7017416",
      "superficie_kmq": "93.3681",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024126",
      "denominazione_ita_altra": "Colceresa",
      "denominazione_ita": "Colceresa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M426",
      "lat": "45.7220963",
      "lon": "11.6148671",
      "superficie_kmq": "19.4004",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024127",
      "denominazione_ita_altra": "Lusiana Conco",
      "denominazione_ita": "Lusiana Conco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M427",
      "lat": "45.8057817",
      "lon": "11.5909839",
      "superficie_kmq": "61.1912",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "VI",
      "codice_istat": "024128",
      "denominazione_ita_altra": "Sovizzo",
      "denominazione_ita": "Sovizzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M436",
      "lat": "45.5611031",
      "lon": "11.4355846",
      "superficie_kmq": "23.6117",
      "codice_sovracomunale": "024"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025001",
      "denominazione_ita_altra": "Agordo",
      "denominazione_ita": "Agordo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A083",
      "lat": "46.2814854",
      "lon": "12.0333464",
      "superficie_kmq": "23.7425",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025003",
      "denominazione_ita_altra": "Alleghe",
      "denominazione_ita": "Alleghe",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A206",
      "lat": "46.4063035",
      "lon": "12.0205462",
      "superficie_kmq": "29.7145",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025004",
      "denominazione_ita_altra": "Arsiè",
      "denominazione_ita": "Arsiè",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A443",
      "lat": "45.9844327",
      "lon": "11.7571389",
      "superficie_kmq": "64.7612",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025005",
      "denominazione_ita_altra": "Auronzo di Cadore",
      "denominazione_ita": "Auronzo di Cadore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A501",
      "lat": "46.5502445",
      "lon": "12.4448433",
      "superficie_kmq": "220.5522",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025006",
      "denominazione_ita_altra": "Belluno",
      "denominazione_ita": "Belluno",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "A757",
      "lat": "46.1383911",
      "lon": "12.2170426",
      "superficie_kmq": "147.2211",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025007",
      "denominazione_ita_altra": "Borca di Cadore",
      "denominazione_ita": "Borca di Cadore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A982",
      "lat": "46.4381421",
      "lon": "12.2187683",
      "superficie_kmq": "26.7552",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025008",
      "denominazione_ita_altra": "Calalzo di Cadore",
      "denominazione_ita": "Calalzo di Cadore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B375",
      "lat": "46.4473009",
      "lon": "12.3805734",
      "superficie_kmq": "43.5069",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025010",
      "denominazione_ita_altra": "Cencenighe Agordino",
      "denominazione_ita": "Cencenighe Agordino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C458",
      "lat": "46.3512724",
      "lon": "11.9662638",
      "superficie_kmq": "18.1305",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025011",
      "denominazione_ita_altra": "Cesiomaggiore",
      "denominazione_ita": "Cesiomaggiore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C577",
      "lat": "46.0887054",
      "lon": "11.9871705",
      "superficie_kmq": "82.0893",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025012",
      "denominazione_ita_altra": "Chies d'Alpago",
      "denominazione_ita": "Chies d'Alpago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C630",
      "lat": "46.1726716",
      "lon": "12.3868415",
      "superficie_kmq": "44.9711",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025013",
      "denominazione_ita_altra": "Cibiana di Cadore",
      "denominazione_ita": "Cibiana di Cadore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C672",
      "lat": "46.3917224",
      "lon": "12.2907730",
      "superficie_kmq": "21.5866",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025014",
      "denominazione_ita_altra": "Colle Santa Lucia",
      "denominazione_ita": "Colle Santa Lucia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C872",
      "lat": "46.4473097",
      "lon": "12.0142872",
      "superficie_kmq": "15.3388",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025015",
      "denominazione_ita_altra": "Comelico Superiore",
      "denominazione_ita": "Comelico Superiore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C920",
      "lat": "46.5887766",
      "lon": "12.5160155",
      "superficie_kmq": "96.0918",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025016",
      "denominazione_ita_altra": "Cortina d'Ampezzo",
      "denominazione_ita": "Cortina d'Ampezzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A266",
      "lat": "46.5366792",
      "lon": "12.1384996",
      "superficie_kmq": "252.8033",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025017",
      "denominazione_ita_altra": "Danta di Cadore",
      "denominazione_ita": "Danta di Cadore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D247",
      "lat": "46.5669763",
      "lon": "12.5200533",
      "superficie_kmq": "7.9544",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025018",
      "denominazione_ita_altra": "Domegge di Cadore",
      "denominazione_ita": "Domegge di Cadore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D330",
      "lat": "46.4603161",
      "lon": "12.4157751",
      "superficie_kmq": "50.3570",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025019",
      "denominazione_ita_altra": "Falcade",
      "denominazione_ita": "Falcade",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D470",
      "lat": "46.3582878",
      "lon": "11.8727561",
      "superficie_kmq": "52.7950",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025021",
      "denominazione_ita_altra": "Feltre",
      "denominazione_ita": "Feltre",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D530",
      "lat": "46.0178396",
      "lon": "11.9099074",
      "superficie_kmq": "99.7878",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025022",
      "denominazione_ita_altra": "Fonzaso",
      "denominazione_ita": "Fonzaso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D686",
      "lat": "46.0178112",
      "lon": "11.7995987",
      "superficie_kmq": "27.6181",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025023",
      "denominazione_ita_altra": "Canale d'Agordo",
      "denominazione_ita": "Canale d'Agordo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B574",
      "lat": "46.3604841",
      "lon": "11.9148515",
      "superficie_kmq": "45.9550",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025025",
      "denominazione_ita_altra": "Gosaldo",
      "denominazione_ita": "Gosaldo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E113",
      "lat": "46.2205660",
      "lon": "11.9562235",
      "superficie_kmq": "48.4729",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025026",
      "denominazione_ita_altra": "Lamon",
      "denominazione_ita": "Lamon",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E429",
      "lat": "46.0472889",
      "lon": "11.7486337",
      "superficie_kmq": "54.3520",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025027",
      "denominazione_ita_altra": "La Valle Agordina",
      "denominazione_ita": "La Valle Agordina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E490",
      "lat": "46.2821794",
      "lon": "12.0680963",
      "superficie_kmq": "48.6721",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025029",
      "denominazione_ita_altra": "Limana",
      "denominazione_ita": "Limana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E588",
      "lat": "46.0991516",
      "lon": "12.1802738",
      "superficie_kmq": "39.1203",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025030",
      "denominazione_ita_altra": "Livinallongo del Col di Lana",
      "denominazione_ita": "Livinallongo del Col di Lana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E622",
      "lat": "46.4815016",
      "lon": "11.9543926",
      "superficie_kmq": "100.0052",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025032",
      "denominazione_ita_altra": "Lorenzago di Cadore",
      "denominazione_ita": "Lorenzago di Cadore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E687",
      "lat": "46.4801269",
      "lon": "12.4590469",
      "superficie_kmq": "27.3486",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025033",
      "denominazione_ita_altra": "Lozzo di Cadore",
      "denominazione_ita": "Lozzo di Cadore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E708",
      "lat": "46.4847010",
      "lon": "12.4429706",
      "superficie_kmq": "30.4042",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025035",
      "denominazione_ita_altra": "Ospitale di Cadore",
      "denominazione_ita": "Ospitale di Cadore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G169",
      "lat": "46.3291216",
      "lon": "12.3234818",
      "superficie_kmq": "39.7816",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025036",
      "denominazione_ita_altra": "Pedavena",
      "denominazione_ita": "Pedavena",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G404",
      "lat": "46.0394026",
      "lon": "11.8814371",
      "superficie_kmq": "25.0553",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025037",
      "denominazione_ita_altra": "Perarolo di Cadore",
      "denominazione_ita": "Perarolo di Cadore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G442",
      "lat": "46.3974134",
      "lon": "12.3552993",
      "superficie_kmq": "43.9431",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025039",
      "denominazione_ita_altra": "Pieve di Cadore",
      "denominazione_ita": "Pieve di Cadore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G642",
      "lat": "46.4293897",
      "lon": "12.3748445",
      "superficie_kmq": "67.1701",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025040",
      "denominazione_ita_altra": "Ponte nelle Alpi",
      "denominazione_ita": "Ponte nelle Alpi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B662",
      "lat": "46.1730144",
      "lon": "12.3013560",
      "superficie_kmq": "58.1400",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025043",
      "denominazione_ita_altra": "Rivamonte Agordino",
      "denominazione_ita": "Rivamonte Agordino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H327",
      "lat": "46.2534538",
      "lon": "12.0244770",
      "superficie_kmq": "23.3040",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025044",
      "denominazione_ita_altra": "Rocca Pietore",
      "denominazione_ita": "Rocca Pietore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H379",
      "lat": "46.4347266",
      "lon": "11.9775407",
      "superficie_kmq": "73.2883",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025045",
      "denominazione_ita_altra": "San Gregorio nelle Alpi",
      "denominazione_ita": "San Gregorio nelle Alpi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H938",
      "lat": "46.1032595",
      "lon": "12.0262827",
      "superficie_kmq": "19.1176",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025046",
      "denominazione_ita_altra": "San Nicolò di Comelico",
      "denominazione_ita": "San Nicolò di Comelico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I063",
      "lat": "46.5822526",
      "lon": "12.5275762",
      "superficie_kmq": "24.1594",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025047",
      "denominazione_ita_altra": "San Pietro di Cadore",
      "denominazione_ita": "San Pietro di Cadore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I088",
      "lat": "46.5707041",
      "lon": "12.5859964",
      "superficie_kmq": "52.1269",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025048",
      "denominazione_ita_altra": "Santa Giustina",
      "denominazione_ita": "Santa Giustina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I206",
      "lat": "46.0833965",
      "lon": "12.0424555",
      "superficie_kmq": "35.9234",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025049",
      "denominazione_ita_altra": "San Tomaso Agordino",
      "denominazione_ita": "San Tomaso Agordino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I347",
      "lat": "46.3812151",
      "lon": "11.9752705",
      "superficie_kmq": "19.1806",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025050",
      "denominazione_ita_altra": "Santo Stefano di Cadore",
      "denominazione_ita": "Santo Stefano di Cadore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C919",
      "lat": "46.5577877",
      "lon": "12.5496724",
      "superficie_kmq": "100.6204",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025051",
      "denominazione_ita_altra": "San Vito di Cadore",
      "denominazione_ita": "San Vito di Cadore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I392",
      "lat": "46.4601690",
      "lon": "12.2047934",
      "superficie_kmq": "61.6156",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025053",
      "denominazione_ita_altra": "Sedico",
      "denominazione_ita": "Sedico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I563",
      "lat": "46.1108809",
      "lon": "12.0970944",
      "superficie_kmq": "91.2029",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025054",
      "denominazione_ita_altra": "Selva di Cadore",
      "denominazione_ita": "Selva di Cadore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I592",
      "lat": "46.4515415",
      "lon": "12.0344170",
      "superficie_kmq": "33.3266",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025055",
      "denominazione_ita_altra": "Seren del Grappa",
      "denominazione_ita": "Seren del Grappa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I626",
      "lat": "45.9887998",
      "lon": "11.8435813",
      "superficie_kmq": "62.5288",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025056",
      "denominazione_ita_altra": "Sospirolo",
      "denominazione_ita": "Sospirolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I866",
      "lat": "46.1420120",
      "lon": "12.0731601",
      "superficie_kmq": "65.8581",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025057",
      "denominazione_ita_altra": "Soverzene",
      "denominazione_ita": "Soverzene",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I876",
      "lat": "46.2037819",
      "lon": "12.3037118",
      "superficie_kmq": "14.7922",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025058",
      "denominazione_ita_altra": "Sovramonte",
      "denominazione_ita": "Sovramonte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I673",
      "lat": "46.0566038",
      "lon": "11.7865557",
      "superficie_kmq": "50.5416",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025059",
      "denominazione_ita_altra": "Taibon Agordino",
      "denominazione_ita": "Taibon Agordino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L030",
      "lat": "46.2988137",
      "lon": "12.0133098",
      "superficie_kmq": "90.0587",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025060",
      "denominazione_ita_altra": "Tambre",
      "denominazione_ita": "Tambre",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L040",
      "lat": "46.1293569",
      "lon": "12.4231198",
      "superficie_kmq": "45.2770",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025062",
      "denominazione_ita_altra": "Vallada Agordina",
      "denominazione_ita": "Vallada Agordina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L584",
      "lat": "46.3681666",
      "lon": "11.9309205",
      "superficie_kmq": "12.9989",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025063",
      "denominazione_ita_altra": "Valle di Cadore",
      "denominazione_ita": "Valle di Cadore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L590",
      "lat": "46.4170642",
      "lon": "12.3278253",
      "superficie_kmq": "40.6394",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025065",
      "denominazione_ita_altra": "Vigo di Cadore",
      "denominazione_ita": "Vigo di Cadore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L890",
      "lat": "46.4996912",
      "lon": "12.4712244",
      "superficie_kmq": "70.0659",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025066",
      "denominazione_ita_altra": "Vodo Cadore",
      "denominazione_ita": "Vodo Cadore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M108",
      "lat": "46.4181205",
      "lon": "12.2503196",
      "superficie_kmq": "46.8767",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025067",
      "denominazione_ita_altra": "Voltago Agordino",
      "denominazione_ita": "Voltago Agordino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M124",
      "lat": "46.2720005",
      "lon": "12.0049308",
      "superficie_kmq": "23.4694",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025069",
      "denominazione_ita_altra": "Zoppè di Cadore",
      "denominazione_ita": "Zoppè di Cadore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M189",
      "lat": "46.3864113",
      "lon": "12.1726446",
      "superficie_kmq": "4.3319",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025071",
      "denominazione_ita_altra": "Longarone",
      "denominazione_ita": "Longarone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M342",
      "lat": "46.2639519",
      "lon": "12.2544641",
      "superficie_kmq": "122.3607",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025072",
      "denominazione_ita_altra": "Alpago",
      "denominazione_ita": "Alpago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M375",
      "lat": "46.1299299",
      "lon": "12.3639731",
      "superficie_kmq": "80.3408",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025073",
      "denominazione_ita_altra": "Val di Zoldo",
      "denominazione_ita": "Val di Zoldo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M374",
      "lat": "46.3528396",
      "lon": "12.1520661",
      "superficie_kmq": "141.6513",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025074",
      "denominazione_ita_altra": "Borgo Valbelluna",
      "denominazione_ita": "Borgo Valbelluna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M421",
      "lat": "46.0284756",
      "lon": "12.0947734",
      "superficie_kmq": "167.6916",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "BL",
      "codice_istat": "025075",
      "denominazione_ita_altra": "Setteville",
      "denominazione_ita": "Setteville",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M437",
      "lat": "45.9300414",
      "lon": "11.9047837",
      "superficie_kmq": "82.4355",
      "codice_sovracomunale": "025"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026001",
      "denominazione_ita_altra": "Altivole",
      "denominazione_ita": "Altivole",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A237",
      "lat": "45.7540004",
      "lon": "11.9557912",
      "superficie_kmq": "21.9467",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026002",
      "denominazione_ita_altra": "Arcade",
      "denominazione_ita": "Arcade",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A360",
      "lat": "45.7850208",
      "lon": "12.2190293",
      "superficie_kmq": "8.2724",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026003",
      "denominazione_ita_altra": "Asolo",
      "denominazione_ita": "Asolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A471",
      "lat": "45.8021837",
      "lon": "11.9123677",
      "superficie_kmq": "25.3668",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026004",
      "denominazione_ita_altra": "Borso del Grappa",
      "denominazione_ita": "Borso del Grappa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B061",
      "lat": "45.8210027",
      "lon": "11.7993074",
      "superficie_kmq": "33.1390",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026005",
      "denominazione_ita_altra": "Breda di Piave",
      "denominazione_ita": "Breda di Piave",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B128",
      "lat": "45.7241096",
      "lon": "12.3309956",
      "superficie_kmq": "25.7608",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026006",
      "denominazione_ita_altra": "Caerano di San Marco",
      "denominazione_ita": "Caerano di San Marco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B349",
      "lat": "45.7852795",
      "lon": "12.0011599",
      "superficie_kmq": "12.0885",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026007",
      "denominazione_ita_altra": "Cappella Maggiore",
      "denominazione_ita": "Cappella Maggiore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B678",
      "lat": "45.9690184",
      "lon": "12.3625957",
      "superficie_kmq": "11.0891",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026008",
      "denominazione_ita_altra": "Carbonera",
      "denominazione_ita": "Carbonera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B744",
      "lat": "45.6815801",
      "lon": "12.2890230",
      "superficie_kmq": "19.8826",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026009",
      "denominazione_ita_altra": "Casale sul Sile",
      "denominazione_ita": "Casale sul Sile",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B879",
      "lat": "45.5974789",
      "lon": "12.3256285",
      "superficie_kmq": "26.9182",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026010",
      "denominazione_ita_altra": "Casier",
      "denominazione_ita": "Casier",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B965",
      "lat": "45.6342286",
      "lon": "12.2549751",
      "superficie_kmq": "13.4217",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026011",
      "denominazione_ita_altra": "Castelcucco",
      "denominazione_ita": "Castelcucco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C073",
      "lat": "45.8331942",
      "lon": "11.8838281",
      "superficie_kmq": "8.7916",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026012",
      "denominazione_ita_altra": "Castelfranco Veneto",
      "denominazione_ita": "Castelfranco Veneto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C111",
      "lat": "45.6713683",
      "lon": "11.9263970",
      "superficie_kmq": "51.6090",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026013",
      "denominazione_ita_altra": "Castello di Godego",
      "denominazione_ita": "Castello di Godego",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C190",
      "lat": "45.6921484",
      "lon": "11.8810193",
      "superficie_kmq": "18.1318",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026014",
      "denominazione_ita_altra": "Cavaso del Tomba",
      "denominazione_ita": "Cavaso del Tomba",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C384",
      "lat": "45.8603560",
      "lon": "11.8952122",
      "superficie_kmq": "18.9730",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026015",
      "denominazione_ita_altra": "Cessalto",
      "denominazione_ita": "Cessalto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C580",
      "lat": "45.7127643",
      "lon": "12.6137977",
      "superficie_kmq": "28.1773",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026016",
      "denominazione_ita_altra": "Chiarano",
      "denominazione_ita": "Chiarano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C614",
      "lat": "45.7284203",
      "lon": "12.5821372",
      "superficie_kmq": "19.9235",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026017",
      "denominazione_ita_altra": "Cimadolmo",
      "denominazione_ita": "Cimadolmo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C689",
      "lat": "45.7877120",
      "lon": "12.3612963",
      "superficie_kmq": "17.8957",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026018",
      "denominazione_ita_altra": "Cison di Valmarino",
      "denominazione_ita": "Cison di Valmarino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C735",
      "lat": "45.9700250",
      "lon": "12.1432224",
      "superficie_kmq": "28.8120",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026019",
      "denominazione_ita_altra": "Codognè",
      "denominazione_ita": "Codognè",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C815",
      "lat": "45.8703425",
      "lon": "12.4296034",
      "superficie_kmq": "21.7507",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026020",
      "denominazione_ita_altra": "Colle Umberto",
      "denominazione_ita": "Colle Umberto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C848",
      "lat": "45.9405264",
      "lon": "12.3415145",
      "superficie_kmq": "13.5797",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026021",
      "denominazione_ita_altra": "Conegliano",
      "denominazione_ita": "Conegliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C957",
      "lat": "45.8871851",
      "lon": "12.2977272",
      "superficie_kmq": "36.3983",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026022",
      "denominazione_ita_altra": "Cordignano",
      "denominazione_ita": "Cordignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C992",
      "lat": "45.9489670",
      "lon": "12.4154533",
      "superficie_kmq": "26.2483",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026023",
      "denominazione_ita_altra": "Cornuda",
      "denominazione_ita": "Cornuda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D030",
      "lat": "45.8318407",
      "lon": "12.0082210",
      "superficie_kmq": "12.5101",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026025",
      "denominazione_ita_altra": "Crocetta del Montello",
      "denominazione_ita": "Crocetta del Montello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C670",
      "lat": "45.8249468",
      "lon": "12.0352458",
      "superficie_kmq": "26.5683",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026026",
      "denominazione_ita_altra": "Farra di Soligo",
      "denominazione_ita": "Farra di Soligo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D505",
      "lat": "45.9054462",
      "lon": "12.1265844",
      "superficie_kmq": "28.3399",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026027",
      "denominazione_ita_altra": "Follina",
      "denominazione_ita": "Follina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D654",
      "lat": "45.9528186",
      "lon": "12.1189507",
      "superficie_kmq": "24.0814",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026028",
      "denominazione_ita_altra": "Fontanelle",
      "denominazione_ita": "Fontanelle",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D674",
      "lat": "45.8309332",
      "lon": "12.4682448",
      "superficie_kmq": "35.3529",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026029",
      "denominazione_ita_altra": "Fonte",
      "denominazione_ita": "Fonte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D680",
      "lat": "45.7879483",
      "lon": "11.8672814",
      "superficie_kmq": "14.5967",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026030",
      "denominazione_ita_altra": "Fregona",
      "denominazione_ita": "Fregona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D794",
      "lat": "46.0074833",
      "lon": "12.3394184",
      "superficie_kmq": "42.7229",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026031",
      "denominazione_ita_altra": "Gaiarine",
      "denominazione_ita": "Gaiarine",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D854",
      "lat": "45.8804726",
      "lon": "12.4816787",
      "superficie_kmq": "28.7778",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026032",
      "denominazione_ita_altra": "Giavera del Montello",
      "denominazione_ita": "Giavera del Montello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E021",
      "lat": "45.7938034",
      "lon": "12.1692900",
      "superficie_kmq": "20.1877",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026033",
      "denominazione_ita_altra": "Godega di Sant'Urbano",
      "denominazione_ita": "Godega di Sant'Urbano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E071",
      "lat": "45.9317425",
      "lon": "12.3977998",
      "superficie_kmq": "24.3420",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026034",
      "denominazione_ita_altra": "Gorgo al Monticano",
      "denominazione_ita": "Gorgo al Monticano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E092",
      "lat": "45.7854777",
      "lon": "12.5543453",
      "superficie_kmq": "27.0883",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026035",
      "denominazione_ita_altra": "Istrana",
      "denominazione_ita": "Istrana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E373",
      "lat": "45.6776796",
      "lon": "12.1032838",
      "superficie_kmq": "26.4776",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026036",
      "denominazione_ita_altra": "Loria",
      "denominazione_ita": "Loria",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E692",
      "lat": "45.7283686",
      "lon": "11.8665345",
      "superficie_kmq": "23.2505",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026037",
      "denominazione_ita_altra": "Mansuè",
      "denominazione_ita": "Mansuè",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E893",
      "lat": "45.8221268",
      "lon": "12.5375877",
      "superficie_kmq": "27.0947",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026038",
      "denominazione_ita_altra": "Mareno di Piave",
      "denominazione_ita": "Mareno di Piave",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E940",
      "lat": "45.8408674",
      "lon": "12.3521870",
      "superficie_kmq": "27.7706",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026039",
      "denominazione_ita_altra": "Maser",
      "denominazione_ita": "Maser",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F009",
      "lat": "45.8074641",
      "lon": "11.9719977",
      "superficie_kmq": "25.8539",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026040",
      "denominazione_ita_altra": "Maserada sul Piave",
      "denominazione_ita": "Maserada sul Piave",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F012",
      "lat": "45.7497050",
      "lon": "12.3184932",
      "superficie_kmq": "28.7696",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026041",
      "denominazione_ita_altra": "Meduna di Livenza",
      "denominazione_ita": "Meduna di Livenza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F088",
      "lat": "45.8052577",
      "lon": "12.6135013",
      "superficie_kmq": "15.3788",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026042",
      "denominazione_ita_altra": "Miane",
      "denominazione_ita": "Miane",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F190",
      "lat": "45.9429180",
      "lon": "12.0909750",
      "superficie_kmq": "30.8836",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026043",
      "denominazione_ita_altra": "Mogliano Veneto",
      "denominazione_ita": "Mogliano Veneto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F269",
      "lat": "45.5615937",
      "lon": "12.2364542",
      "superficie_kmq": "46.2562",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026044",
      "denominazione_ita_altra": "Monastier di Treviso",
      "denominazione_ita": "Monastier di Treviso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F332",
      "lat": "45.6495294",
      "lon": "12.4357421",
      "superficie_kmq": "25.2613",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026045",
      "denominazione_ita_altra": "Monfumo",
      "denominazione_ita": "Monfumo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F360",
      "lat": "45.8304846",
      "lon": "11.9203542",
      "superficie_kmq": "11.4548",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026046",
      "denominazione_ita_altra": "Montebelluna",
      "denominazione_ita": "Montebelluna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F443",
      "lat": "45.7761362",
      "lon": "12.0451119",
      "superficie_kmq": "49.0063",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026047",
      "denominazione_ita_altra": "Morgano",
      "denominazione_ita": "Morgano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F725",
      "lat": "45.6343640",
      "lon": "12.0834802",
      "superficie_kmq": "11.7610",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026048",
      "denominazione_ita_altra": "Moriago della Battaglia",
      "denominazione_ita": "Moriago della Battaglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F729",
      "lat": "45.8669187",
      "lon": "12.1066020",
      "superficie_kmq": "13.7573",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026049",
      "denominazione_ita_altra": "Motta di Livenza",
      "denominazione_ita": "Motta di Livenza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F770",
      "lat": "45.7766509",
      "lon": "12.6102553",
      "superficie_kmq": "37.7775",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026050",
      "denominazione_ita_altra": "Nervesa della Battaglia",
      "denominazione_ita": "Nervesa della Battaglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F872",
      "lat": "45.8244997",
      "lon": "12.2074232",
      "superficie_kmq": "34.9727",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026051",
      "denominazione_ita_altra": "Oderzo",
      "denominazione_ita": "Oderzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F999",
      "lat": "45.7808962",
      "lon": "12.4931288",
      "superficie_kmq": "42.3532",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026052",
      "denominazione_ita_altra": "Ormelle",
      "denominazione_ita": "Ormelle",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G115",
      "lat": "45.7787549",
      "lon": "12.4201444",
      "superficie_kmq": "18.8269",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026053",
      "denominazione_ita_altra": "Orsago",
      "denominazione_ita": "Orsago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G123",
      "lat": "45.9308906",
      "lon": "12.4235969",
      "superficie_kmq": "10.7066",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026055",
      "denominazione_ita_altra": "Paese",
      "denominazione_ita": "Paese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G229",
      "lat": "45.6716501",
      "lon": "12.1535359",
      "superficie_kmq": "38.0890",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026056",
      "denominazione_ita_altra": "Pederobba",
      "denominazione_ita": "Pederobba",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G408",
      "lat": "45.8608508",
      "lon": "11.9858204",
      "superficie_kmq": "27.3165",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026057",
      "denominazione_ita_altra": "Pieve di Soligo",
      "denominazione_ita": "Pieve di Soligo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G645",
      "lat": "45.9000197",
      "lon": "12.1746799",
      "superficie_kmq": "19.0228",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026058",
      "denominazione_ita_altra": "Ponte di Piave",
      "denominazione_ita": "Ponte di Piave",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G846",
      "lat": "45.7160255",
      "lon": "12.4639586",
      "superficie_kmq": "32.4357",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026059",
      "denominazione_ita_altra": "Ponzano Veneto",
      "denominazione_ita": "Ponzano Veneto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G875",
      "lat": "45.7191004",
      "lon": "12.2071102",
      "superficie_kmq": "22.2704",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026060",
      "denominazione_ita_altra": "Portobuffolè",
      "denominazione_ita": "Portobuffolè",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G909",
      "lat": "45.8539325",
      "lon": "12.5384471",
      "superficie_kmq": "5.0807",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026061",
      "denominazione_ita_altra": "Possagno",
      "denominazione_ita": "Possagno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G933",
      "lat": "45.8547007",
      "lon": "11.8814435",
      "superficie_kmq": "12.1102",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026062",
      "denominazione_ita_altra": "Povegliano",
      "denominazione_ita": "Povegliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G944",
      "lat": "45.7599714",
      "lon": "12.2058953",
      "superficie_kmq": "12.9146",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026063",
      "denominazione_ita_altra": "Preganziol",
      "denominazione_ita": "Preganziol",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H022",
      "lat": "45.6014318",
      "lon": "12.2350932",
      "superficie_kmq": "23.1084",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026064",
      "denominazione_ita_altra": "Quinto di Treviso",
      "denominazione_ita": "Quinto di Treviso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H131",
      "lat": "45.6460069",
      "lon": "12.1660223",
      "superficie_kmq": "19.0403",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026065",
      "denominazione_ita_altra": "Refrontolo",
      "denominazione_ita": "Refrontolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H220",
      "lat": "45.9251525",
      "lon": "12.2079313",
      "superficie_kmq": "13.0349",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026066",
      "denominazione_ita_altra": "Resana",
      "denominazione_ita": "Resana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H238",
      "lat": "45.6343278",
      "lon": "11.9555314",
      "superficie_kmq": "24.8911",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026067",
      "denominazione_ita_altra": "Revine Lago",
      "denominazione_ita": "Revine Lago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H253",
      "lat": "45.9921046",
      "lon": "12.2317467",
      "superficie_kmq": "18.7927",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026068",
      "denominazione_ita_altra": "Riese Pio X",
      "denominazione_ita": "Riese Pio X",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H280",
      "lat": "45.7296833",
      "lon": "11.9167718",
      "superficie_kmq": "30.6437",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026069",
      "denominazione_ita_altra": "Roncade",
      "denominazione_ita": "Roncade",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H523",
      "lat": "45.6303927",
      "lon": "12.3739537",
      "superficie_kmq": "61.7762",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026070",
      "denominazione_ita_altra": "Salgareda",
      "denominazione_ita": "Salgareda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H706",
      "lat": "45.7034385",
      "lon": "12.4946767",
      "superficie_kmq": "27.5510",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026071",
      "denominazione_ita_altra": "San Biagio di Callalta",
      "denominazione_ita": "San Biagio di Callalta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H781",
      "lat": "45.6847692",
      "lon": "12.3774931",
      "superficie_kmq": "48.5044",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026072",
      "denominazione_ita_altra": "San Fior",
      "denominazione_ita": "San Fior",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H843",
      "lat": "45.9208517",
      "lon": "12.3584088",
      "superficie_kmq": "17.8168",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026073",
      "denominazione_ita_altra": "San Pietro di Feletto",
      "denominazione_ita": "San Pietro di Feletto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I103",
      "lat": "45.9130547",
      "lon": "12.2516278",
      "superficie_kmq": "19.2525",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026074",
      "denominazione_ita_altra": "San Polo di Piave",
      "denominazione_ita": "San Polo di Piave",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I124",
      "lat": "45.7911983",
      "lon": "12.3941515",
      "superficie_kmq": "20.9825",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026075",
      "denominazione_ita_altra": "Santa Lucia di Piave",
      "denominazione_ita": "Santa Lucia di Piave",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I221",
      "lat": "45.8491896",
      "lon": "12.2842717",
      "superficie_kmq": "19.8098",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026076",
      "denominazione_ita_altra": "San Vendemiano",
      "denominazione_ita": "San Vendemiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I382",
      "lat": "45.8912639",
      "lon": "12.3390228",
      "superficie_kmq": "18.4963",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026077",
      "denominazione_ita_altra": "San Zenone degli Ezzelini",
      "denominazione_ita": "San Zenone degli Ezzelini",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I417",
      "lat": "45.7789280",
      "lon": "11.8403394",
      "superficie_kmq": "19.9661",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026078",
      "denominazione_ita_altra": "Sarmede",
      "denominazione_ita": "Sarmede",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I435",
      "lat": "45.9785148",
      "lon": "12.3863618",
      "superficie_kmq": "18.0133",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026079",
      "denominazione_ita_altra": "Segusino",
      "denominazione_ita": "Segusino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I578",
      "lat": "45.9167105",
      "lon": "11.9540709",
      "superficie_kmq": "18.2274",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026080",
      "denominazione_ita_altra": "Sernaglia della Battaglia",
      "denominazione_ita": "Sernaglia della Battaglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I635",
      "lat": "45.8727810",
      "lon": "12.1318258",
      "superficie_kmq": "20.1512",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026081",
      "denominazione_ita_altra": "Silea",
      "denominazione_ita": "Silea",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F116",
      "lat": "45.6550609",
      "lon": "12.2987552",
      "superficie_kmq": "18.9531",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026082",
      "denominazione_ita_altra": "Spresiano",
      "denominazione_ita": "Spresiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I927",
      "lat": "45.7794392",
      "lon": "12.2592992",
      "superficie_kmq": "25.7247",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026083",
      "denominazione_ita_altra": "Susegana",
      "denominazione_ita": "Susegana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L014",
      "lat": "45.8509098",
      "lon": "12.2500454",
      "superficie_kmq": "44.0980",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026084",
      "denominazione_ita_altra": "Tarzo",
      "denominazione_ita": "Tarzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L058",
      "lat": "45.9729800",
      "lon": "12.2321801",
      "superficie_kmq": "23.9059",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026085",
      "denominazione_ita_altra": "Trevignano",
      "denominazione_ita": "Trevignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L402",
      "lat": "45.7426692",
      "lon": "12.0890659",
      "superficie_kmq": "26.4946",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026086",
      "denominazione_ita_altra": "Treviso",
      "denominazione_ita": "Treviso",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "L407",
      "lat": "45.6675545",
      "lon": "12.2450742",
      "superficie_kmq": "55.5781",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026087",
      "denominazione_ita_altra": "Valdobbiadene",
      "denominazione_ita": "Valdobbiadene",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L565",
      "lat": "45.9013101",
      "lon": "11.9952375",
      "superficie_kmq": "62.8942",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026088",
      "denominazione_ita_altra": "Vazzola",
      "denominazione_ita": "Vazzola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L700",
      "lat": "45.8378521",
      "lon": "12.3791878",
      "superficie_kmq": "26.1591",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026089",
      "denominazione_ita_altra": "Vedelago",
      "denominazione_ita": "Vedelago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L706",
      "lat": "45.6870820",
      "lon": "12.0183216",
      "superficie_kmq": "61.8489",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026090",
      "denominazione_ita_altra": "Vidor",
      "denominazione_ita": "Vidor",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L856",
      "lat": "45.8634757",
      "lon": "12.0388276",
      "superficie_kmq": "13.4259",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026091",
      "denominazione_ita_altra": "Villorba",
      "denominazione_ita": "Villorba",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M048",
      "lat": "45.7128778",
      "lon": "12.2603650",
      "superficie_kmq": "30.5337",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026092",
      "denominazione_ita_altra": "Vittorio Veneto",
      "denominazione_ita": "Vittorio Veneto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M089",
      "lat": "45.9899382",
      "lon": "12.2961446",
      "superficie_kmq": "82.8023",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026093",
      "denominazione_ita_altra": "Volpago del Montello",
      "denominazione_ita": "Volpago del Montello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M118",
      "lat": "45.7774625",
      "lon": "12.1186620",
      "superficie_kmq": "44.8222",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026094",
      "denominazione_ita_altra": "Zenson di Piave",
      "denominazione_ita": "Zenson di Piave",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M163",
      "lat": "45.6782483",
      "lon": "12.4926008",
      "superficie_kmq": "9.4961",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026095",
      "denominazione_ita_altra": "Zero Branco",
      "denominazione_ita": "Zero Branco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M171",
      "lat": "45.5993429",
      "lon": "12.1641057",
      "superficie_kmq": "26.0637",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "TV",
      "codice_istat": "026096",
      "denominazione_ita_altra": "Pieve del Grappa",
      "denominazione_ita": "Pieve del Grappa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M422",
      "lat": "45.8497927",
      "lon": "11.8326558",
      "superficie_kmq": "37.3385",
      "codice_sovracomunale": "026"
    },
    {
      "sigla_provincia": "VE",
      "codice_istat": "027001",
      "denominazione_ita_altra": "Annone Veneto",
      "denominazione_ita": "Annone Veneto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A302",
      "lat": "45.7943853",
      "lon": "12.6830166",
      "superficie_kmq": "25.9339",
      "codice_sovracomunale": "227"
    },
    {
      "sigla_provincia": "VE",
      "codice_istat": "027002",
      "denominazione_ita_altra": "Campagna Lupia",
      "denominazione_ita": "Campagna Lupia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B493",
      "lat": "45.3534453",
      "lon": "12.0981255",
      "superficie_kmq": "87.5910",
      "codice_sovracomunale": "227"
    },
    {
      "sigla_provincia": "VE",
      "codice_istat": "027003",
      "denominazione_ita_altra": "Campolongo Maggiore",
      "denominazione_ita": "Campolongo Maggiore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B546",
      "lat": "45.3314155",
      "lon": "12.0480926",
      "superficie_kmq": "23.6146",
      "codice_sovracomunale": "227"
    },
    {
      "sigla_provincia": "VE",
      "codice_istat": "027004",
      "denominazione_ita_altra": "Camponogara",
      "denominazione_ita": "Camponogara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B554",
      "lat": "45.3850911",
      "lon": "12.0726191",
      "superficie_kmq": "21.2990",
      "codice_sovracomunale": "227"
    },
    {
      "sigla_provincia": "VE",
      "codice_istat": "027005",
      "denominazione_ita_altra": "Caorle",
      "denominazione_ita": "Caorle",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B642",
      "lat": "45.5980311",
      "lon": "12.8876236",
      "superficie_kmq": "153.8340",
      "codice_sovracomunale": "227"
    },
    {
      "sigla_provincia": "VE",
      "codice_istat": "027006",
      "denominazione_ita_altra": "Cavarzere",
      "denominazione_ita": "Cavarzere",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C383",
      "lat": "45.1368050",
      "lon": "12.0837138",
      "superficie_kmq": "140.4375",
      "codice_sovracomunale": "227"
    },
    {
      "sigla_provincia": "VE",
      "codice_istat": "027007",
      "denominazione_ita_altra": "Ceggia",
      "denominazione_ita": "Ceggia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C422",
      "lat": "45.6863181",
      "lon": "12.6374159",
      "superficie_kmq": "22.1039",
      "codice_sovracomunale": "227"
    },
    {
      "sigla_provincia": "VE",
      "codice_istat": "027008",
      "denominazione_ita_altra": "Chioggia",
      "denominazione_ita": "Chioggia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C638",
      "lat": "45.2200730",
      "lon": "12.2794383",
      "superficie_kmq": "187.9113",
      "codice_sovracomunale": "227"
    },
    {
      "sigla_provincia": "VE",
      "codice_istat": "027009",
      "denominazione_ita_altra": "Cinto Caomaggiore",
      "denominazione_ita": "Cinto Caomaggiore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C714",
      "lat": "45.8257753",
      "lon": "12.7856403",
      "superficie_kmq": "21.3196",
      "codice_sovracomunale": "227"
    },
    {
      "sigla_provincia": "VE",
      "codice_istat": "027010",
      "denominazione_ita_altra": "Cona",
      "denominazione_ita": "Cona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C938",
      "lat": "45.1929591",
      "lon": "12.0391204",
      "superficie_kmq": "65.1112",
      "codice_sovracomunale": "227"
    },
    {
      "sigla_provincia": "VE",
      "codice_istat": "027011",
      "denominazione_ita_altra": "Concordia Sagittaria",
      "denominazione_ita": "Concordia Sagittaria",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C950",
      "lat": "45.7558326",
      "lon": "12.8459861",
      "superficie_kmq": "66.8349",
      "codice_sovracomunale": "227"
    },
    {
      "sigla_provincia": "VE",
      "codice_istat": "027012",
      "denominazione_ita_altra": "Dolo",
      "denominazione_ita": "Dolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D325",
      "lat": "45.4259641",
      "lon": "12.0743287",
      "superficie_kmq": "24.2840",
      "codice_sovracomunale": "227"
    },
    {
      "sigla_provincia": "VE",
      "codice_istat": "027013",
      "denominazione_ita_altra": "Eraclea",
      "denominazione_ita": "Eraclea",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D415",
      "lat": "45.5755233",
      "lon": "12.6735335",
      "superficie_kmq": "95.4535",
      "codice_sovracomunale": "227"
    },
    {
      "sigla_provincia": "VE",
      "codice_istat": "027014",
      "denominazione_ita_altra": "Fiesso d'Artico",
      "denominazione_ita": "Fiesso d'Artico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D578",
      "lat": "45.4165843",
      "lon": "12.0308059",
      "superficie_kmq": "6.3104",
      "codice_sovracomunale": "227"
    },
    {
      "sigla_provincia": "VE",
      "codice_istat": "027015",
      "denominazione_ita_altra": "Fossalta di Piave",
      "denominazione_ita": "Fossalta di Piave",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D740",
      "lat": "45.6458388",
      "lon": "12.5112250",
      "superficie_kmq": "9.6402",
      "codice_sovracomunale": "227"
    },
    {
      "sigla_provincia": "VE",
      "codice_istat": "027016",
      "denominazione_ita_altra": "Fossalta di Portogruaro",
      "denominazione_ita": "Fossalta di Portogruaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D741",
      "lat": "45.7929389",
      "lon": "12.9074508",
      "superficie_kmq": "31.1041",
      "codice_sovracomunale": "227"
    },
    {
      "sigla_provincia": "VE",
      "codice_istat": "027017",
      "denominazione_ita_altra": "Fossò",
      "denominazione_ita": "Fossò",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D748",
      "lat": "45.3858626",
      "lon": "12.0480233",
      "superficie_kmq": "10.1823",
      "codice_sovracomunale": "227"
    },
    {
      "sigla_provincia": "VE",
      "codice_istat": "027018",
      "denominazione_ita_altra": "Gruaro",
      "denominazione_ita": "Gruaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E215",
      "lat": "45.8307338",
      "lon": "12.8400268",
      "superficie_kmq": "17.4881",
      "codice_sovracomunale": "227"
    },
    {
      "sigla_provincia": "VE",
      "codice_istat": "027019",
      "denominazione_ita_altra": "Jesolo",
      "denominazione_ita": "Jesolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C388",
      "lat": "45.5335087",
      "lon": "12.6364625",
      "superficie_kmq": "96.3967",
      "codice_sovracomunale": "227"
    },
    {
      "sigla_provincia": "VE",
      "codice_istat": "027020",
      "denominazione_ita_altra": "Marcon",
      "denominazione_ita": "Marcon",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E936",
      "lat": "45.5544740",
      "lon": "12.2993703",
      "superficie_kmq": "25.5472",
      "codice_sovracomunale": "227"
    },
    {
      "sigla_provincia": "VE",
      "codice_istat": "027021",
      "denominazione_ita_altra": "Martellago",
      "denominazione_ita": "Martellago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E980",
      "lat": "45.5461398",
      "lon": "12.1598923",
      "superficie_kmq": "20.1706",
      "codice_sovracomunale": "227"
    },
    {
      "sigla_provincia": "VE",
      "codice_istat": "027022",
      "denominazione_ita_altra": "Meolo",
      "denominazione_ita": "Meolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F130",
      "lat": "45.6190414",
      "lon": "12.4526562",
      "superficie_kmq": "26.6120",
      "codice_sovracomunale": "227"
    },
    {
      "sigla_provincia": "VE",
      "codice_istat": "027023",
      "denominazione_ita_altra": "Mira",
      "denominazione_ita": "Mira",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F229",
      "lat": "45.4328864",
      "lon": "12.1233677",
      "superficie_kmq": "99.1344",
      "codice_sovracomunale": "227"
    },
    {
      "sigla_provincia": "VE",
      "codice_istat": "027024",
      "denominazione_ita_altra": "Mirano",
      "denominazione_ita": "Mirano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F241",
      "lat": "45.4929267",
      "lon": "12.1091699",
      "superficie_kmq": "45.6303",
      "codice_sovracomunale": "227"
    },
    {
      "sigla_provincia": "VE",
      "codice_istat": "027025",
      "denominazione_ita_altra": "Musile di Piave",
      "denominazione_ita": "Musile di Piave",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F826",
      "lat": "45.6223185",
      "lon": "12.5633387",
      "superficie_kmq": "44.8684",
      "codice_sovracomunale": "227"
    },
    {
      "sigla_provincia": "VE",
      "codice_istat": "027026",
      "denominazione_ita_altra": "Noale",
      "denominazione_ita": "Noale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F904",
      "lat": "45.5505611",
      "lon": "12.0704105",
      "superficie_kmq": "24.6898",
      "codice_sovracomunale": "227"
    },
    {
      "sigla_provincia": "VE",
      "codice_istat": "027027",
      "denominazione_ita_altra": "Noventa di Piave",
      "denominazione_ita": "Noventa di Piave",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F963",
      "lat": "45.6628721",
      "lon": "12.5296765",
      "superficie_kmq": "17.9961",
      "codice_sovracomunale": "227"
    },
    {
      "sigla_provincia": "VE",
      "codice_istat": "027028",
      "denominazione_ita_altra": "Pianiga",
      "denominazione_ita": "Pianiga",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G565",
      "lat": "45.4556061",
      "lon": "12.0300451",
      "superficie_kmq": "20.0674",
      "codice_sovracomunale": "227"
    },
    {
      "sigla_provincia": "VE",
      "codice_istat": "027029",
      "denominazione_ita_altra": "Portogruaro",
      "denominazione_ita": "Portogruaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G914",
      "lat": "45.7757764",
      "lon": "12.8374106",
      "superficie_kmq": "102.3109",
      "codice_sovracomunale": "227"
    },
    {
      "sigla_provincia": "VE",
      "codice_istat": "027030",
      "denominazione_ita_altra": "Pramaggiore",
      "denominazione_ita": "Pramaggiore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G981",
      "lat": "45.8122018",
      "lon": "12.7346186",
      "superficie_kmq": "24.2151",
      "codice_sovracomunale": "227"
    },
    {
      "sigla_provincia": "VE",
      "codice_istat": "027031",
      "denominazione_ita_altra": "Quarto d'Altino",
      "denominazione_ita": "Quarto d'Altino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H117",
      "lat": "45.5815620",
      "lon": "12.3714284",
      "superficie_kmq": "28.3286",
      "codice_sovracomunale": "227"
    },
    {
      "sigla_provincia": "VE",
      "codice_istat": "027032",
      "denominazione_ita_altra": "Salzano",
      "denominazione_ita": "Salzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H735",
      "lat": "45.5212793",
      "lon": "12.0977133",
      "superficie_kmq": "17.1836",
      "codice_sovracomunale": "227"
    },
    {
      "sigla_provincia": "VE",
      "codice_istat": "027033",
      "denominazione_ita_altra": "San Donà di Piave",
      "denominazione_ita": "San Donà di Piave",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H823",
      "lat": "45.6294718",
      "lon": "12.5642831",
      "superficie_kmq": "78.8769",
      "codice_sovracomunale": "227"
    },
    {
      "sigla_provincia": "VE",
      "codice_istat": "027034",
      "denominazione_ita_altra": "San Michele al Tagliamento",
      "denominazione_ita": "San Michele al Tagliamento",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I040",
      "lat": "45.7638842",
      "lon": "12.9949404",
      "superficie_kmq": "114.4092",
      "codice_sovracomunale": "227"
    },
    {
      "sigla_provincia": "VE",
      "codice_istat": "027035",
      "denominazione_ita_altra": "Santa Maria di Sala",
      "denominazione_ita": "Santa Maria di Sala",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I242",
      "lat": "45.5047588",
      "lon": "12.0354704",
      "superficie_kmq": "28.0510",
      "codice_sovracomunale": "227"
    },
    {
      "sigla_provincia": "VE",
      "codice_istat": "027036",
      "denominazione_ita_altra": "San Stino di Livenza",
      "denominazione_ita": "San Stino di Livenza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I373",
      "lat": "45.7305483",
      "lon": "12.6795642",
      "superficie_kmq": "67.9666",
      "codice_sovracomunale": "227"
    },
    {
      "sigla_provincia": "VE",
      "codice_istat": "027037",
      "denominazione_ita_altra": "Scorzè",
      "denominazione_ita": "Scorzè",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I551",
      "lat": "45.5720492",
      "lon": "12.1088669",
      "superficie_kmq": "33.2872",
      "codice_sovracomunale": "227"
    },
    {
      "sigla_provincia": "VE",
      "codice_istat": "027038",
      "denominazione_ita_altra": "Spinea",
      "denominazione_ita": "Spinea",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I908",
      "lat": "45.4917208",
      "lon": "12.1647013",
      "superficie_kmq": "14.9588",
      "codice_sovracomunale": "227"
    },
    {
      "sigla_provincia": "VE",
      "codice_istat": "027039",
      "denominazione_ita_altra": "Stra",
      "denominazione_ita": "Stra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I965",
      "lat": "45.4099131",
      "lon": "12.0048803",
      "superficie_kmq": "8.8154",
      "codice_sovracomunale": "227"
    },
    {
      "sigla_provincia": "VE",
      "codice_istat": "027040",
      "denominazione_ita_altra": "Teglio Veneto",
      "denominazione_ita": "Teglio Veneto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L085",
      "lat": "45.8160293",
      "lon": "12.8843276",
      "superficie_kmq": "11.4427",
      "codice_sovracomunale": "227"
    },
    {
      "sigla_provincia": "VE",
      "codice_istat": "027041",
      "denominazione_ita_altra": "Torre di Mosto",
      "denominazione_ita": "Torre di Mosto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L267",
      "lat": "45.6907274",
      "lon": "12.7106378",
      "superficie_kmq": "37.9981",
      "codice_sovracomunale": "227"
    },
    {
      "sigla_provincia": "VE",
      "codice_istat": "027042",
      "denominazione_ita_altra": "Venezia",
      "denominazione_ita": "Venezia",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "L736",
      "lat": "45.4349103",
      "lon": "12.3384525",
      "superficie_kmq": "415.8927",
      "codice_sovracomunale": "227"
    },
    {
      "sigla_provincia": "VE",
      "codice_istat": "027043",
      "denominazione_ita_altra": "Vigonovo",
      "denominazione_ita": "Vigonovo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L899",
      "lat": "45.3854391",
      "lon": "12.0077464",
      "superficie_kmq": "12.8657",
      "codice_sovracomunale": "227"
    },
    {
      "sigla_provincia": "VE",
      "codice_istat": "027044",
      "denominazione_ita_altra": "Cavallino-Treporti",
      "denominazione_ita": "Cavallino-Treporti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M308",
      "lat": "45.4534814",
      "lon": "12.4537184",
      "superficie_kmq": "44.7138",
      "codice_sovracomunale": "227"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028001",
      "denominazione_ita_altra": "Abano Terme",
      "denominazione_ita": "Abano Terme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A001",
      "lat": "45.3593911",
      "lon": "11.7887132",
      "superficie_kmq": "21.4081",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028002",
      "denominazione_ita_altra": "Agna",
      "denominazione_ita": "Agna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A075",
      "lat": "45.1697792",
      "lon": "11.9567281",
      "superficie_kmq": "18.8041",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028003",
      "denominazione_ita_altra": "Albignasego",
      "denominazione_ita": "Albignasego",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A161",
      "lat": "45.3464181",
      "lon": "11.8683773",
      "superficie_kmq": "21.1553",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028004",
      "denominazione_ita_altra": "Anguillara Veneta",
      "denominazione_ita": "Anguillara Veneta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A296",
      "lat": "45.1387363",
      "lon": "11.8860307",
      "superficie_kmq": "21.6650",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028005",
      "denominazione_ita_altra": "Arquà Petrarca",
      "denominazione_ita": "Arquà Petrarca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A434",
      "lat": "45.2700408",
      "lon": "11.7163951",
      "superficie_kmq": "12.5158",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028006",
      "denominazione_ita_altra": "Arre",
      "denominazione_ita": "Arre",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A438",
      "lat": "45.2160979",
      "lon": "11.9318390",
      "superficie_kmq": "12.3439",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028007",
      "denominazione_ita_altra": "Arzergrande",
      "denominazione_ita": "Arzergrande",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A458",
      "lat": "45.2734160",
      "lon": "12.0464961",
      "superficie_kmq": "13.6428",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028008",
      "denominazione_ita_altra": "Bagnoli di Sopra",
      "denominazione_ita": "Bagnoli di Sopra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A568",
      "lat": "45.1851544",
      "lon": "11.8813723",
      "superficie_kmq": "34.9770",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028009",
      "denominazione_ita_altra": "Baone",
      "denominazione_ita": "Baone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A613",
      "lat": "45.2442501",
      "lon": "11.6884443",
      "superficie_kmq": "24.4195",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028010",
      "denominazione_ita_altra": "Barbona",
      "denominazione_ita": "Barbona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A637",
      "lat": "45.1065625",
      "lon": "11.6605800",
      "superficie_kmq": "8.5865",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028011",
      "denominazione_ita_altra": "Battaglia Terme",
      "denominazione_ita": "Battaglia Terme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A714",
      "lat": "45.2888944",
      "lon": "11.7737506",
      "superficie_kmq": "6.2291",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028012",
      "denominazione_ita_altra": "Boara Pisani",
      "denominazione_ita": "Boara Pisani",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A906",
      "lat": "45.1072921",
      "lon": "11.7834761",
      "superficie_kmq": "16.6614",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028013",
      "denominazione_ita_altra": "Borgoricco",
      "denominazione_ita": "Borgoricco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B031",
      "lat": "45.5328242",
      "lon": "11.9685041",
      "superficie_kmq": "20.3938",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028014",
      "denominazione_ita_altra": "Bovolenta",
      "denominazione_ita": "Bovolenta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B106",
      "lat": "45.2686800",
      "lon": "11.9420904",
      "superficie_kmq": "22.7798",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028015",
      "denominazione_ita_altra": "Brugine",
      "denominazione_ita": "Brugine",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B213",
      "lat": "45.2983049",
      "lon": "11.9926908",
      "superficie_kmq": "19.5470",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028016",
      "denominazione_ita_altra": "Cadoneghe",
      "denominazione_ita": "Cadoneghe",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B345",
      "lat": "45.4478010",
      "lon": "11.9100660",
      "superficie_kmq": "12.9249",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028017",
      "denominazione_ita_altra": "Campodarsego",
      "denominazione_ita": "Campodarsego",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B524",
      "lat": "45.5023134",
      "lon": "11.9072817",
      "superficie_kmq": "25.7182",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028018",
      "denominazione_ita_altra": "Campodoro",
      "denominazione_ita": "Campodoro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B531",
      "lat": "45.4931650",
      "lon": "11.7516505",
      "superficie_kmq": "11.2241",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028019",
      "denominazione_ita_altra": "Camposampiero",
      "denominazione_ita": "Camposampiero",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B563",
      "lat": "45.5718473",
      "lon": "11.9319879",
      "superficie_kmq": "21.1245",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028020",
      "denominazione_ita_altra": "Campo San Martino",
      "denominazione_ita": "Campo San Martino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B564",
      "lat": "45.5478628",
      "lon": "11.8119468",
      "superficie_kmq": "13.1599",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028021",
      "denominazione_ita_altra": "Candiana",
      "denominazione_ita": "Candiana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B589",
      "lat": "45.2204117",
      "lon": "11.9912273",
      "superficie_kmq": "22.2680",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028023",
      "denominazione_ita_altra": "Carmignano di Brenta",
      "denominazione_ita": "Carmignano di Brenta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B795",
      "lat": "45.6287653",
      "lon": "11.7001613",
      "superficie_kmq": "14.6815",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028026",
      "denominazione_ita_altra": "Cartura",
      "denominazione_ita": "Cartura",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B848",
      "lat": "45.2681397",
      "lon": "11.8626107",
      "superficie_kmq": "16.2785",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028027",
      "denominazione_ita_altra": "Casale di Scodosia",
      "denominazione_ita": "Casale di Scodosia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B877",
      "lat": "45.1878031",
      "lon": "11.4663674",
      "superficie_kmq": "21.3152",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028028",
      "denominazione_ita_altra": "Casalserugo",
      "denominazione_ita": "Casalserugo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B912",
      "lat": "45.3163237",
      "lon": "11.9148072",
      "superficie_kmq": "15.5043",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028029",
      "denominazione_ita_altra": "Castelbaldo",
      "denominazione_ita": "Castelbaldo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C057",
      "lat": "45.1211400",
      "lon": "11.4526799",
      "superficie_kmq": "15.1745",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028030",
      "denominazione_ita_altra": "Cervarese Santa Croce",
      "denominazione_ita": "Cervarese Santa Croce",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C544",
      "lat": "45.4029389",
      "lon": "11.6919019",
      "superficie_kmq": "17.7049",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028031",
      "denominazione_ita_altra": "Cinto Euganeo",
      "denominazione_ita": "Cinto Euganeo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C713",
      "lat": "45.2913072",
      "lon": "11.6611214",
      "superficie_kmq": "19.7569",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028032",
      "denominazione_ita_altra": "Cittadella",
      "denominazione_ita": "Cittadella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C743",
      "lat": "45.6485919",
      "lon": "11.7836545",
      "superficie_kmq": "36.6783",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028033",
      "denominazione_ita_altra": "Codevigo",
      "denominazione_ita": "Codevigo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C812",
      "lat": "45.2679579",
      "lon": "12.1033197",
      "superficie_kmq": "70.0181",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028034",
      "denominazione_ita_altra": "Conselve",
      "denominazione_ita": "Conselve",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C964",
      "lat": "45.2322415",
      "lon": "11.8756943",
      "superficie_kmq": "24.2861",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028035",
      "denominazione_ita_altra": "Correzzola",
      "denominazione_ita": "Correzzola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D040",
      "lat": "45.2352994",
      "lon": "12.0670952",
      "superficie_kmq": "42.3257",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028036",
      "denominazione_ita_altra": "Curtarolo",
      "denominazione_ita": "Curtarolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D226",
      "lat": "45.5221245",
      "lon": "11.8322253",
      "superficie_kmq": "14.7290",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028037",
      "denominazione_ita_altra": "Este",
      "denominazione_ita": "Este",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D442",
      "lat": "45.2279228",
      "lon": "11.6560500",
      "superficie_kmq": "32.8074",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028038",
      "denominazione_ita_altra": "Fontaniva",
      "denominazione_ita": "Fontaniva",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D679",
      "lat": "45.6363917",
      "lon": "11.7528643",
      "superficie_kmq": "20.6111",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028039",
      "denominazione_ita_altra": "Galliera Veneta",
      "denominazione_ita": "Galliera Veneta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D879",
      "lat": "45.6628263",
      "lon": "11.8294491",
      "superficie_kmq": "8.9542",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028040",
      "denominazione_ita_altra": "Galzignano Terme",
      "denominazione_ita": "Galzignano Terme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D889",
      "lat": "45.3085827",
      "lon": "11.7282548",
      "superficie_kmq": "18.2007",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028041",
      "denominazione_ita_altra": "Gazzo",
      "denominazione_ita": "Gazzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D956",
      "lat": "45.5822895",
      "lon": "11.7073368",
      "superficie_kmq": "22.7096",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028042",
      "denominazione_ita_altra": "Grantorto",
      "denominazione_ita": "Grantorto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E145",
      "lat": "45.5992837",
      "lon": "11.7310619",
      "superficie_kmq": "14.1029",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028043",
      "denominazione_ita_altra": "Granze",
      "denominazione_ita": "Granze",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E146",
      "lat": "45.1543061",
      "lon": "11.7132847",
      "superficie_kmq": "11.4734",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028044",
      "denominazione_ita_altra": "Legnaro",
      "denominazione_ita": "Legnaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E515",
      "lat": "45.3439963",
      "lon": "11.9635699",
      "superficie_kmq": "14.9138",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028045",
      "denominazione_ita_altra": "Limena",
      "denominazione_ita": "Limena",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E592",
      "lat": "45.4789185",
      "lon": "11.8436607",
      "superficie_kmq": "15.1553",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028046",
      "denominazione_ita_altra": "Loreggia",
      "denominazione_ita": "Loreggia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E684",
      "lat": "45.5882080",
      "lon": "11.9440320",
      "superficie_kmq": "19.1159",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028047",
      "denominazione_ita_altra": "Lozzo Atestino",
      "denominazione_ita": "Lozzo Atestino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E709",
      "lat": "45.2911139",
      "lon": "11.6313653",
      "superficie_kmq": "24.0731",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028048",
      "denominazione_ita_altra": "Maserà di Padova",
      "denominazione_ita": "Maserà di Padova",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F011",
      "lat": "45.3201759",
      "lon": "11.8635304",
      "superficie_kmq": "17.5821",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028049",
      "denominazione_ita_altra": "Masi",
      "denominazione_ita": "Masi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F013",
      "lat": "45.1084224",
      "lon": "11.4917478",
      "superficie_kmq": "13.7649",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028050",
      "denominazione_ita_altra": "Massanzago",
      "denominazione_ita": "Massanzago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F033",
      "lat": "45.5553247",
      "lon": "12.0042579",
      "superficie_kmq": "13.2228",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028052",
      "denominazione_ita_altra": "Megliadino San Vitale",
      "denominazione_ita": "Megliadino San Vitale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F092",
      "lat": "45.1923683",
      "lon": "11.5241182",
      "superficie_kmq": "15.2476",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028053",
      "denominazione_ita_altra": "Merlara",
      "denominazione_ita": "Merlara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F148",
      "lat": "45.1669900",
      "lon": "11.4422381",
      "superficie_kmq": "21.3456",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028054",
      "denominazione_ita_altra": "Mestrino",
      "denominazione_ita": "Mestrino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F161",
      "lat": "45.4435492",
      "lon": "11.7587875",
      "superficie_kmq": "19.2180",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028055",
      "denominazione_ita_altra": "Monselice",
      "denominazione_ita": "Monselice",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F382",
      "lat": "45.2394701",
      "lon": "11.7501150",
      "superficie_kmq": "50.5708",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028056",
      "denominazione_ita_altra": "Montagnana",
      "denominazione_ita": "Montagnana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F394",
      "lat": "45.2319848",
      "lon": "11.4671487",
      "superficie_kmq": "45.0279",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028057",
      "denominazione_ita_altra": "Montegrotto Terme",
      "denominazione_ita": "Montegrotto Terme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F529",
      "lat": "45.3317395",
      "lon": "11.7836378",
      "superficie_kmq": "15.3700",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028058",
      "denominazione_ita_altra": "Noventa Padovana",
      "denominazione_ita": "Noventa Padovana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F962",
      "lat": "45.4160386",
      "lon": "11.9528858",
      "superficie_kmq": "7.0781",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028059",
      "denominazione_ita_altra": "Ospedaletto Euganeo",
      "denominazione_ita": "Ospedaletto Euganeo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G167",
      "lat": "45.2225290",
      "lon": "11.6105852",
      "superficie_kmq": "21.4782",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028060",
      "denominazione_ita_altra": "Padova",
      "denominazione_ita": "Padova",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "G224",
      "lat": "45.4069347",
      "lon": "11.8760874",
      "superficie_kmq": "93.0257",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028061",
      "denominazione_ita_altra": "Pernumia",
      "denominazione_ita": "Pernumia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G461",
      "lat": "45.2570490",
      "lon": "11.7869386",
      "superficie_kmq": "13.1797",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028062",
      "denominazione_ita_altra": "Piacenza d'Adige",
      "denominazione_ita": "Piacenza d'Adige",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G534",
      "lat": "45.1274259",
      "lon": "11.5471605",
      "superficie_kmq": "18.4889",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028063",
      "denominazione_ita_altra": "Piazzola sul Brenta",
      "denominazione_ita": "Piazzola sul Brenta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G587",
      "lat": "45.5417414",
      "lon": "11.7864534",
      "superficie_kmq": "40.9328",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028064",
      "denominazione_ita_altra": "Piombino Dese",
      "denominazione_ita": "Piombino Dese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G688",
      "lat": "45.6076508",
      "lon": "11.9985096",
      "superficie_kmq": "29.6247",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028065",
      "denominazione_ita_altra": "Piove di Sacco",
      "denominazione_ita": "Piove di Sacco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G693",
      "lat": "45.2961149",
      "lon": "12.0351552",
      "superficie_kmq": "35.7342",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028066",
      "denominazione_ita_altra": "Polverara",
      "denominazione_ita": "Polverara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G802",
      "lat": "45.3106182",
      "lon": "11.9574010",
      "superficie_kmq": "9.8439",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028067",
      "denominazione_ita_altra": "Ponso",
      "denominazione_ita": "Ponso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G823",
      "lat": "45.1921468",
      "lon": "11.5863571",
      "superficie_kmq": "10.8489",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028068",
      "denominazione_ita_altra": "Pontelongo",
      "denominazione_ita": "Pontelongo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G850",
      "lat": "45.2502936",
      "lon": "12.0191136",
      "superficie_kmq": "10.8862",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028069",
      "denominazione_ita_altra": "Ponte San Nicolò",
      "denominazione_ita": "Ponte San Nicolò",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G855",
      "lat": "45.3641205",
      "lon": "11.9306556",
      "superficie_kmq": "13.5210",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028070",
      "denominazione_ita_altra": "Pozzonovo",
      "denominazione_ita": "Pozzonovo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G963",
      "lat": "45.1982588",
      "lon": "11.7901478",
      "superficie_kmq": "24.4761",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028071",
      "denominazione_ita_altra": "Rovolon",
      "denominazione_ita": "Rovolon",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H622",
      "lat": "45.3857569",
      "lon": "11.6521655",
      "superficie_kmq": "27.6862",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028072",
      "denominazione_ita_altra": "Rubano",
      "denominazione_ita": "Rubano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H625",
      "lat": "45.4267115",
      "lon": "11.7866017",
      "superficie_kmq": "14.5124",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028073",
      "denominazione_ita_altra": "Saccolongo",
      "denominazione_ita": "Saccolongo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H655",
      "lat": "45.4056864",
      "lon": "11.7435624",
      "superficie_kmq": "13.7992",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028075",
      "denominazione_ita_altra": "San Giorgio delle Pertiche",
      "denominazione_ita": "San Giorgio delle Pertiche",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H893",
      "lat": "45.5420114",
      "lon": "11.9099256",
      "superficie_kmq": "18.8582",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028076",
      "denominazione_ita_altra": "San Giorgio in Bosco",
      "denominazione_ita": "San Giorgio in Bosco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H897",
      "lat": "45.5931801",
      "lon": "11.8065547",
      "superficie_kmq": "28.3502",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028077",
      "denominazione_ita_altra": "San Martino di Lupari",
      "denominazione_ita": "San Martino di Lupari",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I008",
      "lat": "45.6506910",
      "lon": "11.8532684",
      "superficie_kmq": "24.1222",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028078",
      "denominazione_ita_altra": "San Pietro in Gu",
      "denominazione_ita": "San Pietro in Gu",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I107",
      "lat": "45.6134981",
      "lon": "11.6692254",
      "superficie_kmq": "17.9003",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028079",
      "denominazione_ita_altra": "San Pietro Viminario",
      "denominazione_ita": "San Pietro Viminario",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I120",
      "lat": "45.2450219",
      "lon": "11.8183416",
      "superficie_kmq": "13.3064",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028080",
      "denominazione_ita_altra": "Santa Giustina in Colle",
      "denominazione_ita": "Santa Giustina in Colle",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I207",
      "lat": "45.5636488",
      "lon": "11.9061769",
      "superficie_kmq": "17.9675",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028082",
      "denominazione_ita_altra": "Sant'Angelo di Piove di Sacco",
      "denominazione_ita": "Sant'Angelo di Piove di Sacco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I275",
      "lat": "45.3461606",
      "lon": "12.0077357",
      "superficie_kmq": "13.9648",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028083",
      "denominazione_ita_altra": "Sant'Elena",
      "denominazione_ita": "Sant'Elena",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I319",
      "lat": "45.1861641",
      "lon": "11.7095659",
      "superficie_kmq": "8.9164",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028084",
      "denominazione_ita_altra": "Sant'Urbano",
      "denominazione_ita": "Sant'Urbano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I375",
      "lat": "45.1133776",
      "lon": "11.6197488",
      "superficie_kmq": "31.9186",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028085",
      "denominazione_ita_altra": "Saonara",
      "denominazione_ita": "Saonara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I418",
      "lat": "45.3719943",
      "lon": "11.9849183",
      "superficie_kmq": "13.5443",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028086",
      "denominazione_ita_altra": "Selvazzano Dentro",
      "denominazione_ita": "Selvazzano Dentro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I595",
      "lat": "45.3945233",
      "lon": "11.7809812",
      "superficie_kmq": "19.5168",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028087",
      "denominazione_ita_altra": "Solesino",
      "denominazione_ita": "Solesino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I799",
      "lat": "45.1777349",
      "lon": "11.7422542",
      "superficie_kmq": "10.1429",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028088",
      "denominazione_ita_altra": "Stanghella",
      "denominazione_ita": "Stanghella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I938",
      "lat": "45.1349307",
      "lon": "11.7564142",
      "superficie_kmq": "19.8106",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028089",
      "denominazione_ita_altra": "Teolo",
      "denominazione_ita": "Teolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L100",
      "lat": "45.3667964",
      "lon": "11.7086317",
      "superficie_kmq": "31.2006",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028090",
      "denominazione_ita_altra": "Terrassa Padovana",
      "denominazione_ita": "Terrassa Padovana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L132",
      "lat": "45.2454303",
      "lon": "11.9035804",
      "superficie_kmq": "14.8125",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028091",
      "denominazione_ita_altra": "Tombolo",
      "denominazione_ita": "Tombolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L199",
      "lat": "45.6472002",
      "lon": "11.8252905",
      "superficie_kmq": "11.0202",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028092",
      "denominazione_ita_altra": "Torreglia",
      "denominazione_ita": "Torreglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L270",
      "lat": "45.3360303",
      "lon": "11.7246633",
      "superficie_kmq": "18.8543",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028093",
      "denominazione_ita_altra": "Trebaseleghe",
      "denominazione_ita": "Trebaseleghe",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L349",
      "lat": "45.5916201",
      "lon": "12.0513975",
      "superficie_kmq": "30.6561",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028094",
      "denominazione_ita_altra": "Tribano",
      "denominazione_ita": "Tribano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L414",
      "lat": "45.2106025",
      "lon": "11.8332569",
      "superficie_kmq": "19.2263",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028095",
      "denominazione_ita_altra": "Urbana",
      "denominazione_ita": "Urbana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L497",
      "lat": "45.1908541",
      "lon": "11.4435552",
      "superficie_kmq": "17.0220",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028096",
      "denominazione_ita_altra": "Veggiano",
      "denominazione_ita": "Veggiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L710",
      "lat": "45.4478963",
      "lon": "11.7137107",
      "superficie_kmq": "16.4121",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028097",
      "denominazione_ita_altra": "Vescovana",
      "denominazione_ita": "Vescovana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L805",
      "lat": "45.1339530",
      "lon": "11.7080365",
      "superficie_kmq": "22.2473",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028099",
      "denominazione_ita_altra": "Vigodarzere",
      "denominazione_ita": "Vigodarzere",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L892",
      "lat": "45.4606349",
      "lon": "11.8788281",
      "superficie_kmq": "19.9160",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028100",
      "denominazione_ita_altra": "Vigonza",
      "denominazione_ita": "Vigonza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L900",
      "lat": "45.4436443",
      "lon": "11.9830620",
      "superficie_kmq": "33.3229",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028101",
      "denominazione_ita_altra": "Villa del Conte",
      "denominazione_ita": "Villa del Conte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L934",
      "lat": "45.5835009",
      "lon": "11.8593478",
      "superficie_kmq": "17.3484",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028102",
      "denominazione_ita_altra": "Villa Estense",
      "denominazione_ita": "Villa Estense",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L937",
      "lat": "45.1721993",
      "lon": "11.6671868",
      "superficie_kmq": "16.0063",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028103",
      "denominazione_ita_altra": "Villafranca Padovana",
      "denominazione_ita": "Villafranca Padovana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L947",
      "lat": "45.4949938",
      "lon": "11.7971633",
      "superficie_kmq": "23.9549",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028104",
      "denominazione_ita_altra": "Villanova di Camposampiero",
      "denominazione_ita": "Villanova di Camposampiero",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L979",
      "lat": "45.4896507",
      "lon": "11.9727726",
      "superficie_kmq": "12.2262",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028105",
      "denominazione_ita_altra": "Vo'",
      "denominazione_ita": "Vo'",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M103",
      "lat": "45.3285463",
      "lon": "11.6409791",
      "superficie_kmq": "20.3663",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028106",
      "denominazione_ita_altra": "Due Carrare",
      "denominazione_ita": "Due Carrare",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M300",
      "lat": "45.2915405",
      "lon": "11.8208982",
      "superficie_kmq": "26.5644",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028107",
      "denominazione_ita_altra": "Borgo Veneto",
      "denominazione_ita": "Borgo Veneto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M402",
      "lat": "45.2192462",
      "lon": "11.5428899",
      "superficie_kmq": "39.1705",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "PD",
      "codice_istat": "028108",
      "denominazione_ita_altra": "Santa Caterina d'Este ",
      "denominazione_ita": "Santa Caterina d'Este ",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M438",
      "lat": "45.1776715",
      "lon": "11.6191802",
      "superficie_kmq": "26.8906",
      "codice_sovracomunale": "028"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029001",
      "denominazione_ita_altra": "Adria",
      "denominazione_ita": "Adria",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A059",
      "lat": "45.0546550",
      "lon": "12.0564464",
      "superficie_kmq": "113.3883",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029002",
      "denominazione_ita_altra": "Ariano nel Polesine",
      "denominazione_ita": "Ariano nel Polesine",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A400",
      "lat": "44.9452107",
      "lon": "12.1210845",
      "superficie_kmq": "81.4577",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029003",
      "denominazione_ita_altra": "Arquà Polesine",
      "denominazione_ita": "Arquà Polesine",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A435",
      "lat": "45.0093688",
      "lon": "11.7413688",
      "superficie_kmq": "19.9278",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029004",
      "denominazione_ita_altra": "Badia Polesine",
      "denominazione_ita": "Badia Polesine",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A539",
      "lat": "45.0943102",
      "lon": "11.4913770",
      "superficie_kmq": "44.5341",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029005",
      "denominazione_ita_altra": "Bagnolo di Po",
      "denominazione_ita": "Bagnolo di Po",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A574",
      "lat": "45.0151624",
      "lon": "11.4999648",
      "superficie_kmq": "21.3580",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029006",
      "denominazione_ita_altra": "Bergantino",
      "denominazione_ita": "Bergantino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A795",
      "lat": "45.0603784",
      "lon": "11.2530207",
      "superficie_kmq": "17.9675",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029007",
      "denominazione_ita_altra": "Bosaro",
      "denominazione_ita": "Bosaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B069",
      "lat": "45.0006221",
      "lon": "11.7645465",
      "superficie_kmq": "6.1227",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029008",
      "denominazione_ita_altra": "Calto",
      "denominazione_ita": "Calto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B432",
      "lat": "44.9916844",
      "lon": "11.3560008",
      "superficie_kmq": "10.8475",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029009",
      "denominazione_ita_altra": "Canaro",
      "denominazione_ita": "Canaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B578",
      "lat": "44.9353433",
      "lon": "11.6765324",
      "superficie_kmq": "32.6473",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029010",
      "denominazione_ita_altra": "Canda",
      "denominazione_ita": "Canda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B582",
      "lat": "45.0339022",
      "lon": "11.5085883",
      "superficie_kmq": "14.3743",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029011",
      "denominazione_ita_altra": "Castelguglielmo",
      "denominazione_ita": "Castelguglielmo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C122",
      "lat": "45.0256991",
      "lon": "11.5372810",
      "superficie_kmq": "22.1291",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029012",
      "denominazione_ita_altra": "Castelmassa",
      "denominazione_ita": "Castelmassa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C207",
      "lat": "45.0176864",
      "lon": "11.3104039",
      "superficie_kmq": "11.8404",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029013",
      "denominazione_ita_altra": "Castelnovo Bariano",
      "denominazione_ita": "Castelnovo Bariano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C215",
      "lat": "45.0266929",
      "lon": "11.2862644",
      "superficie_kmq": "37.9133",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029014",
      "denominazione_ita_altra": "Ceneselli",
      "denominazione_ita": "Ceneselli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C461",
      "lat": "45.0130830",
      "lon": "11.3694204",
      "superficie_kmq": "28.6176",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029015",
      "denominazione_ita_altra": "Ceregnano",
      "denominazione_ita": "Ceregnano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C500",
      "lat": "45.0500744",
      "lon": "11.8705802",
      "superficie_kmq": "30.1699",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029017",
      "denominazione_ita_altra": "Corbola",
      "denominazione_ita": "Corbola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C987",
      "lat": "45.0056310",
      "lon": "12.0774245",
      "superficie_kmq": "18.5470",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029018",
      "denominazione_ita_altra": "Costa di Rovigo",
      "denominazione_ita": "Costa di Rovigo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D105",
      "lat": "45.0488099",
      "lon": "11.6940750",
      "superficie_kmq": "16.0651",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029019",
      "denominazione_ita_altra": "Crespino",
      "denominazione_ita": "Crespino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D161",
      "lat": "44.9833211",
      "lon": "11.8864474",
      "superficie_kmq": "31.8550",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029021",
      "denominazione_ita_altra": "Ficarolo",
      "denominazione_ita": "Ficarolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D568",
      "lat": "44.9519734",
      "lon": "11.4325605",
      "superficie_kmq": "18.0738",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029022",
      "denominazione_ita_altra": "Fiesso Umbertiano",
      "denominazione_ita": "Fiesso Umbertiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D577",
      "lat": "44.9617583",
      "lon": "11.6078539",
      "superficie_kmq": "27.5396",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029023",
      "denominazione_ita_altra": "Frassinelle Polesine",
      "denominazione_ita": "Frassinelle Polesine",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D776",
      "lat": "44.9967222",
      "lon": "11.6976978",
      "superficie_kmq": "21.9767",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029024",
      "denominazione_ita_altra": "Fratta Polesine",
      "denominazione_ita": "Fratta Polesine",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D788",
      "lat": "45.0316861",
      "lon": "11.6410003",
      "superficie_kmq": "20.9677",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029025",
      "denominazione_ita_altra": "Gaiba",
      "denominazione_ita": "Gaiba",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D855",
      "lat": "44.9482960",
      "lon": "11.4815972",
      "superficie_kmq": "11.9896",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029026",
      "denominazione_ita_altra": "Gavello",
      "denominazione_ita": "Gavello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D942",
      "lat": "45.0215116",
      "lon": "11.9142379",
      "superficie_kmq": "24.3701",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029027",
      "denominazione_ita_altra": "Giacciano con Baruchella",
      "denominazione_ita": "Giacciano con Baruchella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E008",
      "lat": "45.0653644",
      "lon": "11.4506428",
      "superficie_kmq": "18.4203",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029028",
      "denominazione_ita_altra": "Guarda Veneta",
      "denominazione_ita": "Guarda Veneta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E240",
      "lat": "44.9797916",
      "lon": "11.8026364",
      "superficie_kmq": "17.2052",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029029",
      "denominazione_ita_altra": "Lendinara",
      "denominazione_ita": "Lendinara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E522",
      "lat": "45.0834699",
      "lon": "11.5986879",
      "superficie_kmq": "55.0601",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029030",
      "denominazione_ita_altra": "Loreo",
      "denominazione_ita": "Loreo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E689",
      "lat": "45.0636010",
      "lon": "12.1892303",
      "superficie_kmq": "39.8436",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029031",
      "denominazione_ita_altra": "Lusia",
      "denominazione_ita": "Lusia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E761",
      "lat": "45.1010420",
      "lon": "11.6630188",
      "superficie_kmq": "17.6764",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029032",
      "denominazione_ita_altra": "Melara",
      "denominazione_ita": "Melara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F095",
      "lat": "45.0618479",
      "lon": "11.1977299",
      "superficie_kmq": "17.5809",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029033",
      "denominazione_ita_altra": "Occhiobello",
      "denominazione_ita": "Occhiobello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F994",
      "lat": "44.9193687",
      "lon": "11.5794507",
      "superficie_kmq": "32.3261",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029034",
      "denominazione_ita_altra": "Papozze",
      "denominazione_ita": "Papozze",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G323",
      "lat": "44.9878672",
      "lon": "12.0310607",
      "superficie_kmq": "21.4869",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029035",
      "denominazione_ita_altra": "Pettorazza Grimani",
      "denominazione_ita": "Pettorazza Grimani",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G525",
      "lat": "45.1360221",
      "lon": "11.9887952",
      "superficie_kmq": "21.4544",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029036",
      "denominazione_ita_altra": "Pincara",
      "denominazione_ita": "Pincara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G673",
      "lat": "44.9930512",
      "lon": "11.6115419",
      "superficie_kmq": "17.5061",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029037",
      "denominazione_ita_altra": "Polesella",
      "denominazione_ita": "Polesella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G782",
      "lat": "44.9625472",
      "lon": "11.7550947",
      "superficie_kmq": "16.4116",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029038",
      "denominazione_ita_altra": "Pontecchio Polesine",
      "denominazione_ita": "Pontecchio Polesine",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G836",
      "lat": "45.0191651",
      "lon": "11.8115673",
      "superficie_kmq": "11.5313",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029039",
      "denominazione_ita_altra": "Porto Tolle",
      "denominazione_ita": "Porto Tolle",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G923",
      "lat": "44.9525321",
      "lon": "12.3345762",
      "superficie_kmq": "257.0646",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029040",
      "denominazione_ita_altra": "Rosolina",
      "denominazione_ita": "Rosolina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H573",
      "lat": "45.0758882",
      "lon": "12.2461296",
      "superficie_kmq": "74.6930",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029041",
      "denominazione_ita_altra": "Rovigo",
      "denominazione_ita": "Rovigo",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "H620",
      "lat": "45.0710727",
      "lon": "11.7900700",
      "superficie_kmq": "108.8039",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029042",
      "denominazione_ita_altra": "Salara",
      "denominazione_ita": "Salara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H689",
      "lat": "44.9842201",
      "lon": "11.4260742",
      "superficie_kmq": "14.1558",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029043",
      "denominazione_ita_altra": "San Bellino",
      "denominazione_ita": "San Bellino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H768",
      "lat": "45.0284907",
      "lon": "11.5890383",
      "superficie_kmq": "15.8257",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029044",
      "denominazione_ita_altra": "San Martino di Venezze",
      "denominazione_ita": "San Martino di Venezze",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H996",
      "lat": "45.1313084",
      "lon": "11.8685270",
      "superficie_kmq": "31.0456",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029045",
      "denominazione_ita_altra": "Stienta",
      "denominazione_ita": "Stienta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I953",
      "lat": "44.9381978",
      "lon": "11.5440367",
      "superficie_kmq": "24.0221",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029046",
      "denominazione_ita_altra": "Taglio di Po",
      "denominazione_ita": "Taglio di Po",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L026",
      "lat": "45.0080448",
      "lon": "12.2091683",
      "superficie_kmq": "78.6785",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029047",
      "denominazione_ita_altra": "Trecenta",
      "denominazione_ita": "Trecenta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L359",
      "lat": "45.0308606",
      "lon": "11.4604615",
      "superficie_kmq": "35.0778",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029048",
      "denominazione_ita_altra": "Villadose",
      "denominazione_ita": "Villadose",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L939",
      "lat": "45.0736711",
      "lon": "11.8896686",
      "superficie_kmq": "32.0740",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029049",
      "denominazione_ita_altra": "Villamarzana",
      "denominazione_ita": "Villamarzana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L967",
      "lat": "45.0109605",
      "lon": "11.6922740",
      "superficie_kmq": "14.1513",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029050",
      "denominazione_ita_altra": "Villanova del Ghebbo",
      "denominazione_ita": "Villanova del Ghebbo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L985",
      "lat": "45.0556128",
      "lon": "11.6443945",
      "superficie_kmq": "11.7258",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029051",
      "denominazione_ita_altra": "Villanova Marchesana",
      "denominazione_ita": "Villanova Marchesana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L988",
      "lat": "44.9912558",
      "lon": "11.9670234",
      "superficie_kmq": "18.0497",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "RO",
      "codice_istat": "029052",
      "denominazione_ita_altra": "Porto Viro",
      "denominazione_ita": "Porto Viro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G926",
      "lat": "45.0259739",
      "lon": "12.2181184",
      "superficie_kmq": "133.3075",
      "codice_sovracomunale": "029"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030001",
      "denominazione_ita_altra": "Aiello del Friuli",
      "denominazione_ita": "Aiello del Friuli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A103",
      "lat": "45.8706954",
      "lon": "13.3624658",
      "superficie_kmq": "13.3473",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030002",
      "denominazione_ita_altra": "Amaro",
      "denominazione_ita": "Amaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A254",
      "lat": "46.3736363",
      "lon": "13.0947421",
      "superficie_kmq": "33.2611",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030003",
      "denominazione_ita_altra": "Ampezzo",
      "denominazione_ita": "Ampezzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A267",
      "lat": "46.4166846",
      "lon": "12.7945795",
      "superficie_kmq": "73.6275",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030004",
      "denominazione_ita_altra": "Aquileia",
      "denominazione_ita": "Aquileia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A346",
      "lat": "45.7664502",
      "lon": "13.3649405",
      "superficie_kmq": "37.4382",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030005",
      "denominazione_ita_altra": "Arta Terme",
      "denominazione_ita": "Arta Terme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A447",
      "lat": "46.4729032",
      "lon": "13.0248617",
      "superficie_kmq": "42.7698",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030006",
      "denominazione_ita_altra": "Artegna",
      "denominazione_ita": "Artegna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A448",
      "lat": "46.2418709",
      "lon": "13.1559829",
      "superficie_kmq": "11.2209",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030007",
      "denominazione_ita_altra": "Attimis",
      "denominazione_ita": "Attimis",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A491",
      "lat": "46.1888288",
      "lon": "13.3051195",
      "superficie_kmq": "33.2372",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030008",
      "denominazione_ita_altra": "Bagnaria Arsa",
      "denominazione_ita": "Bagnaria Arsa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A553",
      "lat": "45.8874182",
      "lon": "13.3017012",
      "superficie_kmq": "19.2330",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030009",
      "denominazione_ita_altra": "Basiliano",
      "denominazione_ita": "Basiliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A700",
      "lat": "46.0162127",
      "lon": "13.1066978",
      "superficie_kmq": "43.0279",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030010",
      "denominazione_ita_altra": "Bertiolo",
      "denominazione_ita": "Bertiolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A810",
      "lat": "45.9433105",
      "lon": "13.0553158",
      "superficie_kmq": "26.0516",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030011",
      "denominazione_ita_altra": "Bicinicco",
      "denominazione_ita": "Bicinicco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A855",
      "lat": "45.9310105",
      "lon": "13.2499086",
      "superficie_kmq": "16.0085",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030012",
      "denominazione_ita_altra": "Bordano",
      "denominazione_ita": "Bordano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A983",
      "lat": "46.3154008",
      "lon": "13.1060119",
      "superficie_kmq": "14.9043",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030013",
      "denominazione_ita_altra": "Buja",
      "denominazione_ita": "Buja",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B259",
      "lat": "46.2089458",
      "lon": "13.1257450",
      "superficie_kmq": "25.5056",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030014",
      "denominazione_ita_altra": "Buttrio",
      "denominazione_ita": "Buttrio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B309",
      "lat": "46.0131008",
      "lon": "13.3304004",
      "superficie_kmq": "17.7768",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030015",
      "denominazione_ita_altra": "Camino al Tagliamento",
      "denominazione_ita": "Camino al Tagliamento",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B483",
      "lat": "45.9274585",
      "lon": "12.9446497",
      "superficie_kmq": "22.3179",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030016",
      "denominazione_ita_altra": "Campoformido",
      "denominazione_ita": "Campoformido",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B536",
      "lat": "46.0198490",
      "lon": "13.1600492",
      "superficie_kmq": "21.9325",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030018",
      "denominazione_ita_altra": "Carlino",
      "denominazione_ita": "Carlino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B788",
      "lat": "45.8022913",
      "lon": "13.1881070",
      "superficie_kmq": "30.2261",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030019",
      "denominazione_ita_altra": "Cassacco",
      "denominazione_ita": "Cassacco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B994",
      "lat": "46.1738665",
      "lon": "13.1861087",
      "superficie_kmq": "11.6785",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030020",
      "denominazione_ita_altra": "Castions di Strada",
      "denominazione_ita": "Castions di Strada",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C327",
      "lat": "45.9084608",
      "lon": "13.1848610",
      "superficie_kmq": "32.8328",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030021",
      "denominazione_ita_altra": "Cavazzo Carnico",
      "denominazione_ita": "Cavazzo Carnico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C389",
      "lat": "46.3677033",
      "lon": "13.0404593",
      "superficie_kmq": "39.4345",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030022",
      "denominazione_ita_altra": "Cercivento",
      "denominazione_ita": "Cercivento",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C494",
      "lat": "46.5271562",
      "lon": "12.9877240",
      "superficie_kmq": "15.7819",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030023",
      "denominazione_ita_altra": "Cervignano del Friuli",
      "denominazione_ita": "Cervignano del Friuli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C556",
      "lat": "45.8232790",
      "lon": "13.3344161",
      "superficie_kmq": "29.1668",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030024",
      "denominazione_ita_altra": "Chiopris-Viscone",
      "denominazione_ita": "Chiopris-Viscone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C641",
      "lat": "45.9245330",
      "lon": "13.4025007",
      "superficie_kmq": "9.2130",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030025",
      "denominazione_ita_altra": "Chiusaforte",
      "denominazione_ita": "Chiusaforte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C656",
      "lat": "46.4082427",
      "lon": "13.3104885",
      "superficie_kmq": "100.1977",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030026",
      "denominazione_ita_altra": "Cividale del Friuli",
      "denominazione_ita": "Cividale del Friuli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C758",
      "lat": "46.0935901",
      "lon": "13.4320295",
      "superficie_kmq": "50.6458",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030027",
      "denominazione_ita_altra": "Codroipo",
      "denominazione_ita": "Codroipo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C817",
      "lat": "45.9618251",
      "lon": "12.9769315",
      "superficie_kmq": "75.2010",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030028",
      "denominazione_ita_altra": "Colloredo di Monte Albano",
      "denominazione_ita": "Colloredo di Monte Albano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C885",
      "lat": "46.1660224",
      "lon": "13.1379670",
      "superficie_kmq": "21.7505",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030029",
      "denominazione_ita_altra": "Comeglians",
      "denominazione_ita": "Comeglians",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C918",
      "lat": "46.5136016",
      "lon": "12.8679182",
      "superficie_kmq": "19.4140",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030030",
      "denominazione_ita_altra": "Corno di Rosazzo",
      "denominazione_ita": "Corno di Rosazzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D027",
      "lat": "45.9968361",
      "lon": "13.4416853",
      "superficie_kmq": "12.6215",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030031",
      "denominazione_ita_altra": "Coseano",
      "denominazione_ita": "Coseano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D085",
      "lat": "46.0968229",
      "lon": "13.0186875",
      "superficie_kmq": "23.7787",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030032",
      "denominazione_ita_altra": "Dignano",
      "denominazione_ita": "Dignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D300",
      "lat": "46.0877097",
      "lon": "12.9386390",
      "superficie_kmq": "27.6037",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030033",
      "denominazione_ita_altra": "Dogna",
      "denominazione_ita": "Dogna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D316",
      "lat": "46.4480703",
      "lon": "13.3154076",
      "superficie_kmq": "70.3683",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030034",
      "denominazione_ita_altra": "Drenchia",
      "denominazione_ita": "Drenchia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D366",
      "lat": "46.1751869",
      "lon": "13.6220685",
      "superficie_kmq": "12.0083",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030035",
      "denominazione_ita_altra": "Enemonzo",
      "denominazione_ita": "Enemonzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D408",
      "lat": "46.4103245",
      "lon": "12.8793445",
      "superficie_kmq": "23.7602",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030036",
      "denominazione_ita_altra": "Faedis",
      "denominazione_ita": "Faedis",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D455",
      "lat": "46.1524587",
      "lon": "13.3441119",
      "superficie_kmq": "46.7805",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030037",
      "denominazione_ita_altra": "Fagagna",
      "denominazione_ita": "Fagagna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D461",
      "lat": "46.1132752",
      "lon": "13.0839365",
      "superficie_kmq": "37.1864",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030039",
      "denominazione_ita_altra": "Flaibano",
      "denominazione_ita": "Flaibano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D630",
      "lat": "46.0600667",
      "lon": "12.9811442",
      "superficie_kmq": "17.3415",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030040",
      "denominazione_ita_altra": "Forni Avoltri",
      "denominazione_ita": "Forni Avoltri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D718",
      "lat": "46.5852019",
      "lon": "12.7769122",
      "superficie_kmq": "80.7485",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030041",
      "denominazione_ita_altra": "Forni di Sopra",
      "denominazione_ita": "Forni di Sopra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D719",
      "lat": "46.4238375",
      "lon": "12.5784564",
      "superficie_kmq": "81.6557",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030042",
      "denominazione_ita_altra": "Forni di Sotto",
      "denominazione_ita": "Forni di Sotto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D720",
      "lat": "46.3936516",
      "lon": "12.6718098",
      "superficie_kmq": "93.5943",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030043",
      "denominazione_ita_altra": "Gemona del Friuli",
      "denominazione_ita": "Gemona del Friuli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D962",
      "lat": "46.2770234",
      "lon": "13.1397373",
      "superficie_kmq": "56.0573",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030044",
      "denominazione_ita_altra": "Gonars",
      "denominazione_ita": "Gonars",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E083",
      "lat": "45.8965810",
      "lon": "13.2350771",
      "superficie_kmq": "19.8169",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030045",
      "denominazione_ita_altra": "Grimacco",
      "denominazione_ita": "Grimacco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E179",
      "lat": "46.1564358",
      "lon": "13.5933865",
      "superficie_kmq": "16.1058",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030046",
      "denominazione_ita_altra": "Latisana",
      "denominazione_ita": "Latisana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E473",
      "lat": "45.7759682",
      "lon": "12.9952955",
      "superficie_kmq": "37.7963",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030047",
      "denominazione_ita_altra": "Lauco",
      "denominazione_ita": "Lauco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E476",
      "lat": "46.4234291",
      "lon": "12.9341686",
      "superficie_kmq": "34.7578",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030048",
      "denominazione_ita_altra": "Lestizza",
      "denominazione_ita": "Lestizza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E553",
      "lat": "45.9576212",
      "lon": "13.1408432",
      "superficie_kmq": "34.3383",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030049",
      "denominazione_ita_altra": "Lignano Sabbiadoro",
      "denominazione_ita": "Lignano Sabbiadoro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E584",
      "lat": "45.6882596",
      "lon": "13.1298204",
      "superficie_kmq": "15.7064",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030051",
      "denominazione_ita_altra": "Lusevera",
      "denominazione_ita": "Lusevera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E760",
      "lat": "46.2647019",
      "lon": "13.2604026",
      "superficie_kmq": "53.0467",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030052",
      "denominazione_ita_altra": "Magnano in Riviera",
      "denominazione_ita": "Magnano in Riviera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E820",
      "lat": "46.2296441",
      "lon": "13.1766001",
      "superficie_kmq": "8.3404",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030053",
      "denominazione_ita_altra": "Majano",
      "denominazione_ita": "Majano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E833",
      "lat": "46.1853832",
      "lon": "13.0755378",
      "superficie_kmq": "28.2752",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030054",
      "denominazione_ita_altra": "Malborghetto Valbruna",
      "denominazione_ita": "Malborghetto Valbruna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E847",
      "lat": "46.5067875",
      "lon": "13.4400224",
      "superficie_kmq": "124.2110",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030055",
      "denominazione_ita_altra": "Manzano",
      "denominazione_ita": "Manzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E899",
      "lat": "45.9900053",
      "lon": "13.3847982",
      "superficie_kmq": "31.0425",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030056",
      "denominazione_ita_altra": "Marano Lagunare",
      "denominazione_ita": "Marano Lagunare",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E910",
      "lat": "45.7671884",
      "lon": "13.1676239",
      "superficie_kmq": "85.8033",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030057",
      "denominazione_ita_altra": "Martignacco",
      "denominazione_ita": "Martignacco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E982",
      "lat": "46.0982226",
      "lon": "13.1303939",
      "superficie_kmq": "26.6770",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030058",
      "denominazione_ita_altra": "Mereto di Tomba",
      "denominazione_ita": "Mereto di Tomba",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F144",
      "lat": "46.0511398",
      "lon": "13.0478421",
      "superficie_kmq": "27.2869",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030059",
      "denominazione_ita_altra": "Moggio Udinese",
      "denominazione_ita": "Moggio Udinese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F266",
      "lat": "46.4099352",
      "lon": "13.1949250",
      "superficie_kmq": "142.4341",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030060",
      "denominazione_ita_altra": "Moimacco",
      "denominazione_ita": "Moimacco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F275",
      "lat": "46.0916865",
      "lon": "13.3811425",
      "superficie_kmq": "11.7658",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030061",
      "denominazione_ita_altra": "Montenars",
      "denominazione_ita": "Montenars",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F574",
      "lat": "46.2567445",
      "lon": "13.1803736",
      "superficie_kmq": "20.5915",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030062",
      "denominazione_ita_altra": "Mortegliano",
      "denominazione_ita": "Mortegliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F756",
      "lat": "45.9449054",
      "lon": "13.1720930",
      "superficie_kmq": "30.0528",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030063",
      "denominazione_ita_altra": "Moruzzo",
      "denominazione_ita": "Moruzzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F760",
      "lat": "46.1197008",
      "lon": "13.1237256",
      "superficie_kmq": "17.7760",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030064",
      "denominazione_ita_altra": "Muzzana del Turgnano",
      "denominazione_ita": "Muzzana del Turgnano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F832",
      "lat": "45.8174419",
      "lon": "13.1277958",
      "superficie_kmq": "24.2909",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030065",
      "denominazione_ita_altra": "Nimis",
      "denominazione_ita": "Nimis",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F898",
      "lat": "46.2003534",
      "lon": "13.2653090",
      "superficie_kmq": "33.8948",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030066",
      "denominazione_ita_altra": "Osoppo",
      "denominazione_ita": "Osoppo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G163",
      "lat": "46.2542309",
      "lon": "13.0807648",
      "superficie_kmq": "22.4040",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030067",
      "denominazione_ita_altra": "Ovaro",
      "denominazione_ita": "Ovaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G198",
      "lat": "46.4842779",
      "lon": "12.8651930",
      "superficie_kmq": "57.9039",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030068",
      "denominazione_ita_altra": "Pagnacco",
      "denominazione_ita": "Pagnacco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G238",
      "lat": "46.1242001",
      "lon": "13.1881183",
      "superficie_kmq": "14.9331",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030069",
      "denominazione_ita_altra": "Palazzolo dello Stella",
      "denominazione_ita": "Palazzolo dello Stella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G268",
      "lat": "45.8043857",
      "lon": "13.0794615",
      "superficie_kmq": "34.5500",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030070",
      "denominazione_ita_altra": "Palmanova",
      "denominazione_ita": "Palmanova",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G284",
      "lat": "45.9047707",
      "lon": "13.3098681",
      "superficie_kmq": "13.2774",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030071",
      "denominazione_ita_altra": "Paluzza",
      "denominazione_ita": "Paluzza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G300",
      "lat": "46.5289723",
      "lon": "13.0154351",
      "superficie_kmq": "69.7487",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030072",
      "denominazione_ita_altra": "Pasian di Prato",
      "denominazione_ita": "Pasian di Prato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G352",
      "lat": "46.0492624",
      "lon": "13.1907965",
      "superficie_kmq": "15.4140",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030073",
      "denominazione_ita_altra": "Paularo",
      "denominazione_ita": "Paularo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G381",
      "lat": "46.5303435",
      "lon": "13.1166958",
      "superficie_kmq": "84.2423",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030074",
      "denominazione_ita_altra": "Pavia di Udine",
      "denominazione_ita": "Pavia di Udine",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G389",
      "lat": "45.9782087",
      "lon": "13.2812184",
      "superficie_kmq": "34.3360",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030075",
      "denominazione_ita_altra": "Pocenia",
      "denominazione_ita": "Pocenia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G743",
      "lat": "45.8372397",
      "lon": "13.0984932",
      "superficie_kmq": "23.9831",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030076",
      "denominazione_ita_altra": "Pontebba",
      "denominazione_ita": "Pontebba",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G831",
      "lat": "46.5066627",
      "lon": "13.3038381",
      "superficie_kmq": "99.6625",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030077",
      "denominazione_ita_altra": "Porpetto",
      "denominazione_ita": "Porpetto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G891",
      "lat": "45.8592464",
      "lon": "13.2154661",
      "superficie_kmq": "18.0541",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030078",
      "denominazione_ita_altra": "Povoletto",
      "denominazione_ita": "Povoletto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G949",
      "lat": "46.1181206",
      "lon": "13.2984573",
      "superficie_kmq": "38.4131",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030079",
      "denominazione_ita_altra": "Pozzuolo del Friuli",
      "denominazione_ita": "Pozzuolo del Friuli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G966",
      "lat": "45.9840958",
      "lon": "13.1965684",
      "superficie_kmq": "34.3697",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030080",
      "denominazione_ita_altra": "Pradamano",
      "denominazione_ita": "Pradamano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G969",
      "lat": "46.0312613",
      "lon": "13.2994287",
      "superficie_kmq": "15.9086",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030081",
      "denominazione_ita_altra": "Prato Carnico",
      "denominazione_ita": "Prato Carnico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H002",
      "lat": "46.5201735",
      "lon": "12.7976591",
      "superficie_kmq": "81.7207",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030082",
      "denominazione_ita_altra": "Precenicco",
      "denominazione_ita": "Precenicco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H014",
      "lat": "45.7897230",
      "lon": "13.0787107",
      "superficie_kmq": "27.2302",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030083",
      "denominazione_ita_altra": "Premariacco",
      "denominazione_ita": "Premariacco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H029",
      "lat": "46.0589839",
      "lon": "13.3942543",
      "superficie_kmq": "39.8850",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030084",
      "denominazione_ita_altra": "Preone",
      "denominazione_ita": "Preone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H038",
      "lat": "46.3952048",
      "lon": "12.8679858",
      "superficie_kmq": "22.4728",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030085",
      "denominazione_ita_altra": "Prepotto",
      "denominazione_ita": "Prepotto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H040",
      "lat": "46.0459432",
      "lon": "13.4794891",
      "superficie_kmq": "33.2345",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030086",
      "denominazione_ita_altra": "Pulfero",
      "denominazione_ita": "Pulfero",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H089",
      "lat": "46.1732571",
      "lon": "13.4842717",
      "superficie_kmq": "48.6760",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030087",
      "denominazione_ita_altra": "Ragogna",
      "denominazione_ita": "Ragogna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H161",
      "lat": "46.1771453",
      "lon": "12.9782286",
      "superficie_kmq": "22.0271",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030088",
      "denominazione_ita_altra": "Ravascletto",
      "denominazione_ita": "Ravascletto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H196",
      "lat": "46.5256450",
      "lon": "12.9222295",
      "superficie_kmq": "26.4796",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030089",
      "denominazione_ita_altra": "Raveo",
      "denominazione_ita": "Raveo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H200",
      "lat": "46.4347142",
      "lon": "12.8700139",
      "superficie_kmq": "12.5973",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030090",
      "denominazione_ita_altra": "Reana del Rojale",
      "denominazione_ita": "Reana del Rojale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H206",
      "lat": "46.1493726",
      "lon": "13.2436853",
      "superficie_kmq": "20.3320",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030091",
      "denominazione_ita_altra": "Remanzacco",
      "denominazione_ita": "Remanzacco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H229",
      "lat": "46.0852574",
      "lon": "13.3247878",
      "superficie_kmq": "30.9872",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030092",
      "denominazione_ita_altra": "Resia",
      "denominazione_ita": "Resia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H242",
      "lat": "46.3734934",
      "lon": "13.3057528",
      "superficie_kmq": "119.3121",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030093",
      "denominazione_ita_altra": "Resiutta",
      "denominazione_ita": "Resiutta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H244",
      "lat": "46.3932541",
      "lon": "13.2201004",
      "superficie_kmq": "20.3611",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030094",
      "denominazione_ita_altra": "Rigolato",
      "denominazione_ita": "Rigolato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H289",
      "lat": "46.5496174",
      "lon": "12.8540711",
      "superficie_kmq": "30.7703",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030095",
      "denominazione_ita_altra": "Rive d'Arcano",
      "denominazione_ita": "Rive d'Arcano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H347",
      "lat": "46.1259680",
      "lon": "13.0309816",
      "superficie_kmq": "22.5658",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030097",
      "denominazione_ita_altra": "Ronchis",
      "denominazione_ita": "Ronchis",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H533",
      "lat": "45.8058254",
      "lon": "12.9960747",
      "superficie_kmq": "18.3968",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030098",
      "denominazione_ita_altra": "Ruda",
      "denominazione_ita": "Ruda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H629",
      "lat": "45.8369886",
      "lon": "13.3996951",
      "superficie_kmq": "19.4636",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030099",
      "denominazione_ita_altra": "San Daniele del Friuli",
      "denominazione_ita": "San Daniele del Friuli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H816",
      "lat": "46.1609913",
      "lon": "13.0113264",
      "superficie_kmq": "34.7795",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030100",
      "denominazione_ita_altra": "San Giorgio di Nogaro",
      "denominazione_ita": "San Giorgio di Nogaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H895",
      "lat": "45.8330330",
      "lon": "13.2069917",
      "superficie_kmq": "25.9373",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030101",
      "denominazione_ita_altra": "San Giovanni al Natisone",
      "denominazione_ita": "San Giovanni al Natisone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H906",
      "lat": "45.9809302",
      "lon": "13.4039632",
      "superficie_kmq": "24.0601",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030102",
      "denominazione_ita_altra": "San Leonardo",
      "denominazione_ita": "San Leonardo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H951",
      "lat": "46.1216822",
      "lon": "13.5245169",
      "superficie_kmq": "26.9138",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030103",
      "denominazione_ita_altra": "San Pietro al Natisone",
      "denominazione_ita": "San Pietro al Natisone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I092",
      "lat": "46.1276055",
      "lon": "13.4854265",
      "superficie_kmq": "23.9685",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030104",
      "denominazione_ita_altra": "Santa Maria la Longa",
      "denominazione_ita": "Santa Maria la Longa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I248",
      "lat": "45.9330846",
      "lon": "13.2886089",
      "superficie_kmq": "19.6031",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030105",
      "denominazione_ita_altra": "San Vito al Torre",
      "denominazione_ita": "San Vito al Torre",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I404",
      "lat": "45.8957225",
      "lon": "13.3702097",
      "superficie_kmq": "11.9224",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030106",
      "denominazione_ita_altra": "San Vito di Fagagna",
      "denominazione_ita": "San Vito di Fagagna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I405",
      "lat": "46.0882167",
      "lon": "13.0569368",
      "superficie_kmq": "8.5705",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030107",
      "denominazione_ita_altra": "Sauris",
      "denominazione_ita": "Sauris",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I464",
      "lat": "46.4666586",
      "lon": "12.7081299",
      "superficie_kmq": "41.4911",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030108",
      "denominazione_ita_altra": "Savogna",
      "denominazione_ita": "Savogna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I478",
      "lat": "46.1597239",
      "lon": "13.5336971",
      "superficie_kmq": "22.1649",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030109",
      "denominazione_ita_altra": "Sedegliano",
      "denominazione_ita": "Sedegliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I562",
      "lat": "46.0135067",
      "lon": "12.9760707",
      "superficie_kmq": "50.4220",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030110",
      "denominazione_ita_altra": "Socchieve",
      "denominazione_ita": "Socchieve",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I777",
      "lat": "46.4033799",
      "lon": "12.8235136",
      "superficie_kmq": "66.1145",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030111",
      "denominazione_ita_altra": "Stregna",
      "denominazione_ita": "Stregna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I974",
      "lat": "46.1269536",
      "lon": "13.5779432",
      "superficie_kmq": "19.6913",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030112",
      "denominazione_ita_altra": "Sutrio",
      "denominazione_ita": "Sutrio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L018",
      "lat": "46.5123018",
      "lon": "12.9899357",
      "superficie_kmq": "20.7456",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030113",
      "denominazione_ita_altra": "Taipana",
      "denominazione_ita": "Taipana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G736",
      "lat": "46.2530235",
      "lon": "13.3452585",
      "superficie_kmq": "65.4414",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030114",
      "denominazione_ita_altra": "Talmassons",
      "denominazione_ita": "Talmassons",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L039",
      "lat": "45.9296788",
      "lon": "13.1159150",
      "superficie_kmq": "43.0497",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030116",
      "denominazione_ita_altra": "Tarcento",
      "denominazione_ita": "Tarcento",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L050",
      "lat": "46.2155746",
      "lon": "13.2220001",
      "superficie_kmq": "35.4193",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030117",
      "denominazione_ita_altra": "Tarvisio",
      "denominazione_ita": "Tarvisio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L057",
      "lat": "46.5061360",
      "lon": "13.5733212",
      "superficie_kmq": "208.3559",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030118",
      "denominazione_ita_altra": "Tavagnacco",
      "denominazione_ita": "Tavagnacco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L065",
      "lat": "46.1011488",
      "lon": "13.2148053",
      "superficie_kmq": "15.3441",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030120",
      "denominazione_ita_altra": "Terzo d'Aquileia",
      "denominazione_ita": "Terzo d'Aquileia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L144",
      "lat": "45.8006183",
      "lon": "13.3463345",
      "superficie_kmq": "28.3598",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030121",
      "denominazione_ita_altra": "Tolmezzo",
      "denominazione_ita": "Tolmezzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L195",
      "lat": "46.4058022",
      "lon": "13.0148857",
      "superficie_kmq": "64.6226",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030122",
      "denominazione_ita_altra": "Torreano",
      "denominazione_ita": "Torreano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L246",
      "lat": "46.1302217",
      "lon": "13.4324827",
      "superficie_kmq": "34.9904",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030123",
      "denominazione_ita_altra": "Torviscosa",
      "denominazione_ita": "Torviscosa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L309",
      "lat": "45.8234942",
      "lon": "13.2746057",
      "superficie_kmq": "48.6221",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030124",
      "denominazione_ita_altra": "Trasaghis",
      "denominazione_ita": "Trasaghis",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L335",
      "lat": "46.2823740",
      "lon": "13.0753827",
      "superficie_kmq": "77.8492",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030126",
      "denominazione_ita_altra": "Treppo Grande",
      "denominazione_ita": "Treppo Grande",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L382",
      "lat": "46.1909938",
      "lon": "13.1570227",
      "superficie_kmq": "11.3221",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030127",
      "denominazione_ita_altra": "Tricesimo",
      "denominazione_ita": "Tricesimo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L421",
      "lat": "46.1622398",
      "lon": "13.2125254",
      "superficie_kmq": "17.6830",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030128",
      "denominazione_ita_altra": "Trivignano Udinese",
      "denominazione_ita": "Trivignano Udinese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L438",
      "lat": "45.9414985",
      "lon": "13.3474934",
      "superficie_kmq": "18.4599",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030129",
      "denominazione_ita_altra": "Udine",
      "denominazione_ita": "Udine",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "L483",
      "lat": "46.0625703",
      "lon": "13.2348395",
      "superficie_kmq": "57.1924",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030130",
      "denominazione_ita_altra": "Varmo",
      "denominazione_ita": "Varmo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L686",
      "lat": "45.8867500",
      "lon": "12.9881540",
      "superficie_kmq": "34.9183",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030131",
      "denominazione_ita_altra": "Venzone",
      "denominazione_ita": "Venzone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L743",
      "lat": "46.3337100",
      "lon": "13.1390192",
      "superficie_kmq": "54.5509",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030132",
      "denominazione_ita_altra": "Verzegnis",
      "denominazione_ita": "Verzegnis",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L801",
      "lat": "46.3896288",
      "lon": "12.9935675",
      "superficie_kmq": "39.3306",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030133",
      "denominazione_ita_altra": "Villa Santina",
      "denominazione_ita": "Villa Santina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L909",
      "lat": "46.4154808",
      "lon": "12.9240149",
      "superficie_kmq": "12.9947",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030135",
      "denominazione_ita_altra": "Visco",
      "denominazione_ita": "Visco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M073",
      "lat": "45.8915517",
      "lon": "13.3478801",
      "superficie_kmq": "3.5386",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030136",
      "denominazione_ita_altra": "Zuglio",
      "denominazione_ita": "Zuglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M200",
      "lat": "46.4609745",
      "lon": "13.0264300",
      "superficie_kmq": "18.2113",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030137",
      "denominazione_ita_altra": "Forgaria nel Friuli",
      "denominazione_ita": "Forgaria nel Friuli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D700",
      "lat": "46.2240096",
      "lon": "12.9682002",
      "superficie_kmq": "28.9391",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030138",
      "denominazione_ita_altra": "Campolongo Tapogliano",
      "denominazione_ita": "Campolongo Tapogliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M311",
      "lat": "45.8643415",
      "lon": "13.3940465",
      "superficie_kmq": "11.0193",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030188",
      "denominazione_ita_altra": "Rivignano Teor",
      "denominazione_ita": "Rivignano Teor",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M317",
      "lat": "45.8643762",
      "lon": "13.0612382",
      "superficie_kmq": "47.7536",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030189",
      "denominazione_ita_altra": "Sappada",
      "denominazione_ita": "Sappada",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I421",
      "lat": "46.5643424",
      "lon": "12.6856392",
      "superficie_kmq": "62.0628",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030190",
      "denominazione_ita_altra": "Fiumicello Villa Vicentina",
      "denominazione_ita": "Fiumicello Villa Vicentina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M400",
      "lat": "45.7844494",
      "lon": "13.4093351",
      "superficie_kmq": "28.7989",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "UD",
      "codice_istat": "030191",
      "denominazione_ita_altra": "Treppo Ligosullo",
      "denominazione_ita": "Treppo Ligosullo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M399",
      "lat": "46.5465686",
      "lon": "13.0661227",
      "superficie_kmq": "35.5839",
      "codice_sovracomunale": "030"
    },
    {
      "sigla_provincia": "GO",
      "codice_istat": "031001",
      "denominazione_ita_altra": "Capriva del Friuli",
      "denominazione_ita": "Capriva del Friuli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B712",
      "lat": "45.9421221",
      "lon": "13.5145940",
      "superficie_kmq": "6.3200",
      "codice_sovracomunale": "031"
    },
    {
      "sigla_provincia": "GO",
      "codice_istat": "031002",
      "denominazione_ita_altra": "Cormons",
      "denominazione_ita": "Cormons",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D014",
      "lat": "45.9606717",
      "lon": "13.4736461",
      "superficie_kmq": "35.0850",
      "codice_sovracomunale": "031"
    },
    {
      "sigla_provincia": "GO",
      "codice_istat": "031003",
      "denominazione_ita_altra": "Doberdò del Lago-Doberdob",
      "denominazione_ita": "Doberdò del Lago",
      "denominazione_altra": "Doberdob",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D312",
      "lat": "45.8445273",
      "lon": "13.5402271",
      "superficie_kmq": "27.0526",
      "codice_sovracomunale": "031"
    },
    {
      "sigla_provincia": "GO",
      "codice_istat": "031004",
      "denominazione_ita_altra": "Dolegna del Collio",
      "denominazione_ita": "Dolegna del Collio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D321",
      "lat": "46.0317168",
      "lon": "13.4792604",
      "superficie_kmq": "12.8765",
      "codice_sovracomunale": "031"
    },
    {
      "sigla_provincia": "GO",
      "codice_istat": "031005",
      "denominazione_ita_altra": "Farra d'Isonzo",
      "denominazione_ita": "Farra d'Isonzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D504",
      "lat": "45.9071454",
      "lon": "13.5179372",
      "superficie_kmq": "10.2510",
      "codice_sovracomunale": "031"
    },
    {
      "sigla_provincia": "GO",
      "codice_istat": "031006",
      "denominazione_ita_altra": "Fogliano Redipuglia",
      "denominazione_ita": "Fogliano Redipuglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D645",
      "lat": "45.8665380",
      "lon": "13.4809780",
      "superficie_kmq": "7.9241",
      "codice_sovracomunale": "031"
    },
    {
      "sigla_provincia": "GO",
      "codice_istat": "031007",
      "denominazione_ita_altra": "Gorizia",
      "denominazione_ita": "Gorizia",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "E098",
      "lat": "45.9415117",
      "lon": "13.6221262",
      "superficie_kmq": "41.2626",
      "codice_sovracomunale": "031"
    },
    {
      "sigla_provincia": "GO",
      "codice_istat": "031008",
      "denominazione_ita_altra": "Gradisca d'Isonzo",
      "denominazione_ita": "Gradisca d'Isonzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E124",
      "lat": "45.8888978",
      "lon": "13.5037630",
      "superficie_kmq": "11.2200",
      "codice_sovracomunale": "031"
    },
    {
      "sigla_provincia": "GO",
      "codice_istat": "031009",
      "denominazione_ita_altra": "Grado",
      "denominazione_ita": "Grado",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E125",
      "lat": "45.6754768",
      "lon": "13.3864671",
      "superficie_kmq": "119.5156",
      "codice_sovracomunale": "031"
    },
    {
      "sigla_provincia": "GO",
      "codice_istat": "031010",
      "denominazione_ita_altra": "Mariano del Friuli",
      "denominazione_ita": "Mariano del Friuli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E952",
      "lat": "45.9167216",
      "lon": "13.4611055",
      "superficie_kmq": "8.5915",
      "codice_sovracomunale": "031"
    },
    {
      "sigla_provincia": "GO",
      "codice_istat": "031011",
      "denominazione_ita_altra": "Medea",
      "denominazione_ita": "Medea",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F081",
      "lat": "45.9167161",
      "lon": "13.4239347",
      "superficie_kmq": "7.3593",
      "codice_sovracomunale": "031"
    },
    {
      "sigla_provincia": "GO",
      "codice_istat": "031012",
      "denominazione_ita_altra": "Monfalcone",
      "denominazione_ita": "Monfalcone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F356",
      "lat": "45.8092448",
      "lon": "13.5326476",
      "superficie_kmq": "20.6446",
      "codice_sovracomunale": "031"
    },
    {
      "sigla_provincia": "GO",
      "codice_istat": "031013",
      "denominazione_ita_altra": "Moraro",
      "denominazione_ita": "Moraro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F710",
      "lat": "45.9302347",
      "lon": "13.4952874",
      "superficie_kmq": "3.5654",
      "codice_sovracomunale": "031"
    },
    {
      "sigla_provincia": "GO",
      "codice_istat": "031014",
      "denominazione_ita_altra": "Mossa",
      "denominazione_ita": "Mossa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F767",
      "lat": "45.9384447",
      "lon": "13.5478980",
      "superficie_kmq": "6.2051",
      "codice_sovracomunale": "031"
    },
    {
      "sigla_provincia": "GO",
      "codice_istat": "031015",
      "denominazione_ita_altra": "Romans d'Isonzo",
      "denominazione_ita": "Romans d'Isonzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H514",
      "lat": "45.8904995",
      "lon": "13.4390373",
      "superficie_kmq": "15.4983",
      "codice_sovracomunale": "031"
    },
    {
      "sigla_provincia": "GO",
      "codice_istat": "031016",
      "denominazione_ita_altra": "Ronchi dei Legionari",
      "denominazione_ita": "Ronchi dei Legionari",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H531",
      "lat": "45.8260148",
      "lon": "13.5007120",
      "superficie_kmq": "17.1084",
      "codice_sovracomunale": "031"
    },
    {
      "sigla_provincia": "GO",
      "codice_istat": "031017",
      "denominazione_ita_altra": "Sagrado",
      "denominazione_ita": "Sagrado",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H665",
      "lat": "45.8764760",
      "lon": "13.4852109",
      "superficie_kmq": "13.9395",
      "codice_sovracomunale": "031"
    },
    {
      "sigla_provincia": "GO",
      "codice_istat": "031018",
      "denominazione_ita_altra": "San Canzian d'Isonzo",
      "denominazione_ita": "San Canzian d'Isonzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H787",
      "lat": "45.8095631",
      "lon": "13.4434009",
      "superficie_kmq": "33.8903",
      "codice_sovracomunale": "031"
    },
    {
      "sigla_provincia": "GO",
      "codice_istat": "031019",
      "denominazione_ita_altra": "San Floriano del Collio-Števerjan",
      "denominazione_ita": "San Floriano del Collio",
      "denominazione_altra": "Števerjan",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H845",
      "lat": "45.9822147",
      "lon": "13.5853312",
      "superficie_kmq": "10.6311",
      "codice_sovracomunale": "031"
    },
    {
      "sigla_provincia": "GO",
      "codice_istat": "031020",
      "denominazione_ita_altra": "San Lorenzo Isontino",
      "denominazione_ita": "San Lorenzo Isontino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H964",
      "lat": "45.9327054",
      "lon": "13.5267226",
      "superficie_kmq": "4.4013",
      "codice_sovracomunale": "031"
    },
    {
      "sigla_provincia": "GO",
      "codice_istat": "031021",
      "denominazione_ita_altra": "San Pier d'Isonzo",
      "denominazione_ita": "San Pier d'Isonzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I082",
      "lat": "45.8459594",
      "lon": "13.4606645",
      "superficie_kmq": "9.0280",
      "codice_sovracomunale": "031"
    },
    {
      "sigla_provincia": "GO",
      "codice_istat": "031022",
      "denominazione_ita_altra": "Savogna d'Isonzo-Sovodnje ob Soči",
      "denominazione_ita": "Savogna d'Isonzo",
      "denominazione_altra": "Sovodnje ob Soči",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I479",
      "lat": "45.9017574",
      "lon": "13.5709698",
      "superficie_kmq": "16.9815",
      "codice_sovracomunale": "031"
    },
    {
      "sigla_provincia": "GO",
      "codice_istat": "031023",
      "denominazione_ita_altra": "Staranzano",
      "denominazione_ita": "Staranzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I939",
      "lat": "45.8055207",
      "lon": "13.5002782",
      "superficie_kmq": "18.8163",
      "codice_sovracomunale": "031"
    },
    {
      "sigla_provincia": "GO",
      "codice_istat": "031024",
      "denominazione_ita_altra": "Turriaco",
      "denominazione_ita": "Turriaco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L474",
      "lat": "45.8208990",
      "lon": "13.4448201",
      "superficie_kmq": "5.1749",
      "codice_sovracomunale": "031"
    },
    {
      "sigla_provincia": "GO",
      "codice_istat": "031025",
      "denominazione_ita_altra": "Villesse",
      "denominazione_ita": "Villesse",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M043",
      "lat": "45.8597972",
      "lon": "13.4381336",
      "superficie_kmq": "12.0535",
      "codice_sovracomunale": "031"
    },
    {
      "sigla_provincia": "TS",
      "codice_istat": "032001",
      "denominazione_ita_altra": "Duino Aurisina-Devin Nabrežina",
      "denominazione_ita": "Duino Aurisina",
      "denominazione_altra": "Devin Nabrežina",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D383",
      "lat": "45.7542419",
      "lon": "13.6596943",
      "superficie_kmq": "45.3135",
      "codice_sovracomunale": "032"
    },
    {
      "sigla_provincia": "TS",
      "codice_istat": "032002",
      "denominazione_ita_altra": "Monrupino-Repentabor",
      "denominazione_ita": "Monrupino",
      "denominazione_altra": "Repentabor",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F378",
      "lat": "45.7188831",
      "lon": "13.8011568",
      "superficie_kmq": "12.6103",
      "codice_sovracomunale": "032"
    },
    {
      "sigla_provincia": "TS",
      "codice_istat": "032003",
      "denominazione_ita_altra": "Muggia",
      "denominazione_ita": "Muggia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F795",
      "lat": "45.6044029",
      "lon": "13.7672993",
      "superficie_kmq": "13.8518",
      "codice_sovracomunale": "032"
    },
    {
      "sigla_provincia": "TS",
      "codice_istat": "032004",
      "denominazione_ita_altra": "San Dorligo della Valle-Dolina",
      "denominazione_ita": "San Dorligo della Valle",
      "denominazione_altra": "Dolina",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D324",
      "lat": "45.6079509",
      "lon": "13.8567533",
      "superficie_kmq": "24.2218",
      "codice_sovracomunale": "032"
    },
    {
      "sigla_provincia": "TS",
      "codice_istat": "032005",
      "denominazione_ita_altra": "Sgonico-Zgonik",
      "denominazione_ita": "Sgonico",
      "denominazione_altra": "Zgonik",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I715",
      "lat": "45.7365883",
      "lon": "13.7495753",
      "superficie_kmq": "31.4024",
      "codice_sovracomunale": "032"
    },
    {
      "sigla_provincia": "TS",
      "codice_istat": "032006",
      "denominazione_ita_altra": "Trieste",
      "denominazione_ita": "Trieste",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "L424",
      "lat": "45.6494447",
      "lon": "13.7681374",
      "superficie_kmq": "85.1039",
      "codice_sovracomunale": "032"
    },
    {
      "sigla_provincia": "PC",
      "codice_istat": "033001",
      "denominazione_ita_altra": "Agazzano",
      "denominazione_ita": "Agazzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A067",
      "lat": "44.9468045",
      "lon": "9.5206141",
      "superficie_kmq": "36.1450",
      "codice_sovracomunale": "033"
    },
    {
      "sigla_provincia": "PC",
      "codice_istat": "033002",
      "denominazione_ita_altra": "Alseno",
      "denominazione_ita": "Alseno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A223",
      "lat": "44.8963303",
      "lon": "9.9645531",
      "superficie_kmq": "55.2695",
      "codice_sovracomunale": "033"
    },
    {
      "sigla_provincia": "PC",
      "codice_istat": "033003",
      "denominazione_ita_altra": "Besenzone",
      "denominazione_ita": "Besenzone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A823",
      "lat": "44.9875634",
      "lon": "9.9536153",
      "superficie_kmq": "23.9471",
      "codice_sovracomunale": "033"
    },
    {
      "sigla_provincia": "PC",
      "codice_istat": "033004",
      "denominazione_ita_altra": "Bettola",
      "denominazione_ita": "Bettola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A831",
      "lat": "44.7761566",
      "lon": "9.6053788",
      "superficie_kmq": "122.3705",
      "codice_sovracomunale": "033"
    },
    {
      "sigla_provincia": "PC",
      "codice_istat": "033005",
      "denominazione_ita_altra": "Bobbio",
      "denominazione_ita": "Bobbio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A909",
      "lat": "44.7664023",
      "lon": "9.3866028",
      "superficie_kmq": "106.5214",
      "codice_sovracomunale": "033"
    },
    {
      "sigla_provincia": "PC",
      "codice_istat": "033006",
      "denominazione_ita_altra": "Borgonovo Val Tidone",
      "denominazione_ita": "Borgonovo Val Tidone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B025",
      "lat": "45.0180346",
      "lon": "9.4468590",
      "superficie_kmq": "51.2167",
      "codice_sovracomunale": "033"
    },
    {
      "sigla_provincia": "PC",
      "codice_istat": "033007",
      "denominazione_ita_altra": "Cadeo",
      "denominazione_ita": "Cadeo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B332",
      "lat": "44.9654600",
      "lon": "9.8522365",
      "superficie_kmq": "38.4792",
      "codice_sovracomunale": "033"
    },
    {
      "sigla_provincia": "PC",
      "codice_istat": "033008",
      "denominazione_ita_altra": "Calendasco",
      "denominazione_ita": "Calendasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B405",
      "lat": "45.0877376",
      "lon": "9.5938203",
      "superficie_kmq": "36.9357",
      "codice_sovracomunale": "033"
    },
    {
      "sigla_provincia": "PC",
      "codice_istat": "033010",
      "denominazione_ita_altra": "Caorso",
      "denominazione_ita": "Caorso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B643",
      "lat": "45.0496054",
      "lon": "9.8719295",
      "superficie_kmq": "40.9806",
      "codice_sovracomunale": "033"
    },
    {
      "sigla_provincia": "PC",
      "codice_istat": "033011",
      "denominazione_ita_altra": "Carpaneto Piacentino",
      "denominazione_ita": "Carpaneto Piacentino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B812",
      "lat": "44.9150458",
      "lon": "9.7884491",
      "superficie_kmq": "63.0763",
      "codice_sovracomunale": "033"
    },
    {
      "sigla_provincia": "PC",
      "codice_istat": "033012",
      "denominazione_ita_altra": "Castell'Arquato",
      "denominazione_ita": "Castell'Arquato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C145",
      "lat": "44.8511936",
      "lon": "9.8676043",
      "superficie_kmq": "52.7485",
      "codice_sovracomunale": "033"
    },
    {
      "sigla_provincia": "PC",
      "codice_istat": "033013",
      "denominazione_ita_altra": "Castel San Giovanni",
      "denominazione_ita": "Castel San Giovanni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C261",
      "lat": "45.0585212",
      "lon": "9.4340457",
      "superficie_kmq": "44.0397",
      "codice_sovracomunale": "033"
    },
    {
      "sigla_provincia": "PC",
      "codice_istat": "033014",
      "denominazione_ita_altra": "Castelvetro Piacentino",
      "denominazione_ita": "Castelvetro Piacentino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C288",
      "lat": "45.0966549",
      "lon": "9.9880277",
      "superficie_kmq": "35.0518",
      "codice_sovracomunale": "033"
    },
    {
      "sigla_provincia": "PC",
      "codice_istat": "033015",
      "denominazione_ita_altra": "Cerignale",
      "denominazione_ita": "Cerignale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C513",
      "lat": "44.6774869",
      "lon": "9.3509691",
      "superficie_kmq": "30.8180",
      "codice_sovracomunale": "033"
    },
    {
      "sigla_provincia": "PC",
      "codice_istat": "033016",
      "denominazione_ita_altra": "Coli",
      "denominazione_ita": "Coli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C838",
      "lat": "44.7434163",
      "lon": "9.4128247",
      "superficie_kmq": "71.6896",
      "codice_sovracomunale": "033"
    },
    {
      "sigla_provincia": "PC",
      "codice_istat": "033017",
      "denominazione_ita_altra": "Corte Brugnatella",
      "denominazione_ita": "Corte Brugnatella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D054",
      "lat": "44.7124597",
      "lon": "9.3797224",
      "superficie_kmq": "46.3089",
      "codice_sovracomunale": "033"
    },
    {
      "sigla_provincia": "PC",
      "codice_istat": "033018",
      "denominazione_ita_altra": "Cortemaggiore",
      "denominazione_ita": "Cortemaggiore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D061",
      "lat": "44.9967645",
      "lon": "9.9310288",
      "superficie_kmq": "36.4718",
      "codice_sovracomunale": "033"
    },
    {
      "sigla_provincia": "PC",
      "codice_istat": "033019",
      "denominazione_ita_altra": "Farini",
      "denominazione_ita": "Farini",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D502",
      "lat": "44.7133304",
      "lon": "9.5707136",
      "superficie_kmq": "112.3548",
      "codice_sovracomunale": "033"
    },
    {
      "sigla_provincia": "PC",
      "codice_istat": "033020",
      "denominazione_ita_altra": "Ferriere",
      "denominazione_ita": "Ferriere",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D555",
      "lat": "44.6456613",
      "lon": "9.4980815",
      "superficie_kmq": "178.4949",
      "codice_sovracomunale": "033"
    },
    {
      "sigla_provincia": "PC",
      "codice_istat": "033021",
      "denominazione_ita_altra": "Fiorenzuola d'Arda",
      "denominazione_ita": "Fiorenzuola d'Arda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D611",
      "lat": "44.9286521",
      "lon": "9.9075306",
      "superficie_kmq": "59.7690",
      "codice_sovracomunale": "033"
    },
    {
      "sigla_provincia": "PC",
      "codice_istat": "033022",
      "denominazione_ita_altra": "Gazzola",
      "denominazione_ita": "Gazzola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D958",
      "lat": "44.9592980",
      "lon": "9.5471349",
      "superficie_kmq": "44.4768",
      "codice_sovracomunale": "033"
    },
    {
      "sigla_provincia": "PC",
      "codice_istat": "033023",
      "denominazione_ita_altra": "Gossolengo",
      "denominazione_ita": "Gossolengo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E114",
      "lat": "45.0044079",
      "lon": "9.6140515",
      "superficie_kmq": "31.0994",
      "codice_sovracomunale": "033"
    },
    {
      "sigla_provincia": "PC",
      "codice_istat": "033024",
      "denominazione_ita_altra": "Gragnano Trebbiense",
      "denominazione_ita": "Gragnano Trebbiense",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E132",
      "lat": "45.0121297",
      "lon": "9.5691183",
      "superficie_kmq": "34.6120",
      "codice_sovracomunale": "033"
    },
    {
      "sigla_provincia": "PC",
      "codice_istat": "033025",
      "denominazione_ita_altra": "Gropparello",
      "denominazione_ita": "Gropparello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E196",
      "lat": "44.8322806",
      "lon": "9.7303298",
      "superficie_kmq": "56.3300",
      "codice_sovracomunale": "033"
    },
    {
      "sigla_provincia": "PC",
      "codice_istat": "033026",
      "denominazione_ita_altra": "Lugagnano Val d'Arda",
      "denominazione_ita": "Lugagnano Val d'Arda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E726",
      "lat": "44.8225304",
      "lon": "9.8264537",
      "superficie_kmq": "54.3974",
      "codice_sovracomunale": "033"
    },
    {
      "sigla_provincia": "PC",
      "codice_istat": "033027",
      "denominazione_ita_altra": "Monticelli d'Ongina",
      "denominazione_ita": "Monticelli d'Ongina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F671",
      "lat": "45.0898514",
      "lon": "9.9332673",
      "superficie_kmq": "46.3298",
      "codice_sovracomunale": "033"
    },
    {
      "sigla_provincia": "PC",
      "codice_istat": "033028",
      "denominazione_ita_altra": "Morfasso",
      "denominazione_ita": "Morfasso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F724",
      "lat": "44.7216639",
      "lon": "9.7032519",
      "superficie_kmq": "83.9336",
      "codice_sovracomunale": "033"
    },
    {
      "sigla_provincia": "PC",
      "codice_istat": "033030",
      "denominazione_ita_altra": "Ottone",
      "denominazione_ita": "Ottone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G195",
      "lat": "44.6224085",
      "lon": "9.3321160",
      "superficie_kmq": "98.9494",
      "codice_sovracomunale": "033"
    },
    {
      "sigla_provincia": "PC",
      "codice_istat": "033032",
      "denominazione_ita_altra": "Piacenza",
      "denominazione_ita": "Piacenza",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "G535",
      "lat": "45.0519337",
      "lon": "9.6926326",
      "superficie_kmq": "118.2281",
      "codice_sovracomunale": "033"
    },
    {
      "sigla_provincia": "PC",
      "codice_istat": "033033",
      "denominazione_ita_altra": "Pianello Val Tidone",
      "denominazione_ita": "Pianello Val Tidone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G557",
      "lat": "44.9483722",
      "lon": "9.4047882",
      "superficie_kmq": "36.2849",
      "codice_sovracomunale": "033"
    },
    {
      "sigla_provincia": "PC",
      "codice_istat": "033034",
      "denominazione_ita_altra": "Piozzano",
      "denominazione_ita": "Piozzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G696",
      "lat": "44.9254005",
      "lon": "9.4943659",
      "superficie_kmq": "43.6068",
      "codice_sovracomunale": "033"
    },
    {
      "sigla_provincia": "PC",
      "codice_istat": "033035",
      "denominazione_ita_altra": "Podenzano",
      "denominazione_ita": "Podenzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G747",
      "lat": "44.9548621",
      "lon": "9.6853100",
      "superficie_kmq": "44.3354",
      "codice_sovracomunale": "033"
    },
    {
      "sigla_provincia": "PC",
      "codice_istat": "033036",
      "denominazione_ita_altra": "Ponte dell'Olio",
      "denominazione_ita": "Ponte dell'Olio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G842",
      "lat": "44.8678943",
      "lon": "9.6428502",
      "superficie_kmq": "43.9148",
      "codice_sovracomunale": "033"
    },
    {
      "sigla_provincia": "PC",
      "codice_istat": "033037",
      "denominazione_ita_altra": "Pontenure",
      "denominazione_ita": "Pontenure",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G852",
      "lat": "44.9996380",
      "lon": "9.7861500",
      "superficie_kmq": "33.8481",
      "codice_sovracomunale": "033"
    },
    {
      "sigla_provincia": "PC",
      "codice_istat": "033038",
      "denominazione_ita_altra": "Rivergaro",
      "denominazione_ita": "Rivergaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H350",
      "lat": "44.9107051",
      "lon": "9.5975640",
      "superficie_kmq": "43.8291",
      "codice_sovracomunale": "033"
    },
    {
      "sigla_provincia": "PC",
      "codice_istat": "033039",
      "denominazione_ita_altra": "Rottofreno",
      "denominazione_ita": "Rottofreno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H593",
      "lat": "45.0570447",
      "lon": "9.5504134",
      "superficie_kmq": "35.1646",
      "codice_sovracomunale": "033"
    },
    {
      "sigla_provincia": "PC",
      "codice_istat": "033040",
      "denominazione_ita_altra": "San Giorgio Piacentino",
      "denominazione_ita": "San Giorgio Piacentino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H887",
      "lat": "44.9541096",
      "lon": "9.7375510",
      "superficie_kmq": "49.1940",
      "codice_sovracomunale": "033"
    },
    {
      "sigla_provincia": "PC",
      "codice_istat": "033041",
      "denominazione_ita_altra": "San Pietro in Cerro",
      "denominazione_ita": "San Pietro in Cerro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G788",
      "lat": "45.0213265",
      "lon": "9.9513817",
      "superficie_kmq": "27.3456",
      "codice_sovracomunale": "033"
    },
    {
      "sigla_provincia": "PC",
      "codice_istat": "033042",
      "denominazione_ita_altra": "Sarmato",
      "denominazione_ita": "Sarmato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I434",
      "lat": "45.0604159",
      "lon": "9.4914865",
      "superficie_kmq": "27.2585",
      "codice_sovracomunale": "033"
    },
    {
      "sigla_provincia": "PC",
      "codice_istat": "033043",
      "denominazione_ita_altra": "Travo",
      "denominazione_ita": "Travo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L348",
      "lat": "44.8610336",
      "lon": "9.5428379",
      "superficie_kmq": "81.0121",
      "codice_sovracomunale": "033"
    },
    {
      "sigla_provincia": "PC",
      "codice_istat": "033044",
      "denominazione_ita_altra": "Vernasca",
      "denominazione_ita": "Vernasca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L772",
      "lat": "44.7999243",
      "lon": "9.8295901",
      "superficie_kmq": "72.5658",
      "codice_sovracomunale": "033"
    },
    {
      "sigla_provincia": "PC",
      "codice_istat": "033045",
      "denominazione_ita_altra": "Vigolzone",
      "denominazione_ita": "Vigolzone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L897",
      "lat": "44.9115192",
      "lon": "9.6697900",
      "superficie_kmq": "42.0366",
      "codice_sovracomunale": "033"
    },
    {
      "sigla_provincia": "PC",
      "codice_istat": "033046",
      "denominazione_ita_altra": "Villanova sull'Arda",
      "denominazione_ita": "Villanova sull'Arda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L980",
      "lat": "45.0258217",
      "lon": "9.9974177",
      "superficie_kmq": "36.5647",
      "codice_sovracomunale": "033"
    },
    {
      "sigla_provincia": "PC",
      "codice_istat": "033047",
      "denominazione_ita_altra": "Zerba",
      "denominazione_ita": "Zerba",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M165",
      "lat": "44.6656099",
      "lon": "9.2877081",
      "superficie_kmq": "24.1270",
      "codice_sovracomunale": "033"
    },
    {
      "sigla_provincia": "PC",
      "codice_istat": "033048",
      "denominazione_ita_altra": "Ziano Piacentino",
      "denominazione_ita": "Ziano Piacentino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L848",
      "lat": "45.0022292",
      "lon": "9.4026376",
      "superficie_kmq": "32.7755",
      "codice_sovracomunale": "033"
    },
    {
      "sigla_provincia": "PC",
      "codice_istat": "033049",
      "denominazione_ita_altra": "Alta Val Tidone",
      "denominazione_ita": "Alta Val Tidone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M386",
      "lat": "44.8991009",
      "lon": "9.3590611",
      "superficie_kmq": "100.8556",
      "codice_sovracomunale": "033"
    },
    {
      "sigla_provincia": "PR",
      "codice_istat": "034001",
      "denominazione_ita_altra": "Albareto",
      "denominazione_ita": "Albareto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A138",
      "lat": "44.4458054",
      "lon": "9.7005241",
      "superficie_kmq": "104.1033",
      "codice_sovracomunale": "034"
    },
    {
      "sigla_provincia": "PR",
      "codice_istat": "034002",
      "denominazione_ita_altra": "Bardi",
      "denominazione_ita": "Bardi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A646",
      "lat": "44.6328059",
      "lon": "9.7319948",
      "superficie_kmq": "189.8940",
      "codice_sovracomunale": "034"
    },
    {
      "sigla_provincia": "PR",
      "codice_istat": "034003",
      "denominazione_ita_altra": "Bedonia",
      "denominazione_ita": "Bedonia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A731",
      "lat": "44.5034907",
      "lon": "9.6313141",
      "superficie_kmq": "169.5556",
      "codice_sovracomunale": "034"
    },
    {
      "sigla_provincia": "PR",
      "codice_istat": "034004",
      "denominazione_ita_altra": "Berceto",
      "denominazione_ita": "Berceto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A788",
      "lat": "44.5095197",
      "lon": "9.9894023",
      "superficie_kmq": "131.7046",
      "codice_sovracomunale": "034"
    },
    {
      "sigla_provincia": "PR",
      "codice_istat": "034005",
      "denominazione_ita_altra": "Bore",
      "denominazione_ita": "Bore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A987",
      "lat": "44.7190038",
      "lon": "9.7943565",
      "superficie_kmq": "43.0043",
      "codice_sovracomunale": "034"
    },
    {
      "sigla_provincia": "PR",
      "codice_istat": "034006",
      "denominazione_ita_altra": "Borgo Val di Taro",
      "denominazione_ita": "Borgo Val di Taro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B042",
      "lat": "44.4880211",
      "lon": "9.7676671",
      "superficie_kmq": "151.4879",
      "codice_sovracomunale": "034"
    },
    {
      "sigla_provincia": "PR",
      "codice_istat": "034007",
      "denominazione_ita_altra": "Busseto",
      "denominazione_ita": "Busseto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B293",
      "lat": "44.9813270",
      "lon": "10.0413231",
      "superficie_kmq": "76.5911",
      "codice_sovracomunale": "034"
    },
    {
      "sigla_provincia": "PR",
      "codice_istat": "034008",
      "denominazione_ita_altra": "Calestano",
      "denominazione_ita": "Calestano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B408",
      "lat": "44.6006578",
      "lon": "10.1221490",
      "superficie_kmq": "57.3542",
      "codice_sovracomunale": "034"
    },
    {
      "sigla_provincia": "PR",
      "codice_istat": "034009",
      "denominazione_ita_altra": "Collecchio",
      "denominazione_ita": "Collecchio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C852",
      "lat": "44.7527953",
      "lon": "10.2157962",
      "superficie_kmq": "58.8310",
      "codice_sovracomunale": "034"
    },
    {
      "sigla_provincia": "PR",
      "codice_istat": "034010",
      "denominazione_ita_altra": "Colorno",
      "denominazione_ita": "Colorno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C904",
      "lat": "44.9287926",
      "lon": "10.3756497",
      "superficie_kmq": "48.4061",
      "codice_sovracomunale": "034"
    },
    {
      "sigla_provincia": "PR",
      "codice_istat": "034011",
      "denominazione_ita_altra": "Compiano",
      "denominazione_ita": "Compiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C934",
      "lat": "44.4959538",
      "lon": "9.6624252",
      "superficie_kmq": "37.5320",
      "codice_sovracomunale": "034"
    },
    {
      "sigla_provincia": "PR",
      "codice_istat": "034012",
      "denominazione_ita_altra": "Corniglio",
      "denominazione_ita": "Corniglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D026",
      "lat": "44.4761908",
      "lon": "10.0886869",
      "superficie_kmq": "165.6975",
      "codice_sovracomunale": "034"
    },
    {
      "sigla_provincia": "PR",
      "codice_istat": "034013",
      "denominazione_ita_altra": "Felino",
      "denominazione_ita": "Felino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D526",
      "lat": "44.6955175",
      "lon": "10.2407207",
      "superficie_kmq": "38.3458",
      "codice_sovracomunale": "034"
    },
    {
      "sigla_provincia": "PR",
      "codice_istat": "034014",
      "denominazione_ita_altra": "Fidenza",
      "denominazione_ita": "Fidenza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B034",
      "lat": "44.8670844",
      "lon": "10.0614142",
      "superficie_kmq": "95.1166",
      "codice_sovracomunale": "034"
    },
    {
      "sigla_provincia": "PR",
      "codice_istat": "034015",
      "denominazione_ita_altra": "Fontanellato",
      "denominazione_ita": "Fontanellato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D673",
      "lat": "44.8824587",
      "lon": "10.1734164",
      "superficie_kmq": "53.9754",
      "codice_sovracomunale": "034"
    },
    {
      "sigla_provincia": "PR",
      "codice_istat": "034016",
      "denominazione_ita_altra": "Fontevivo",
      "denominazione_ita": "Fontevivo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D685",
      "lat": "44.8564232",
      "lon": "10.1753779",
      "superficie_kmq": "26.0045",
      "codice_sovracomunale": "034"
    },
    {
      "sigla_provincia": "PR",
      "codice_istat": "034017",
      "denominazione_ita_altra": "Fornovo di Taro",
      "denominazione_ita": "Fornovo di Taro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D728",
      "lat": "44.6892488",
      "lon": "10.0987286",
      "superficie_kmq": "57.5224",
      "codice_sovracomunale": "034"
    },
    {
      "sigla_provincia": "PR",
      "codice_istat": "034018",
      "denominazione_ita_altra": "Langhirano",
      "denominazione_ita": "Langhirano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E438",
      "lat": "44.6144446",
      "lon": "10.2664239",
      "superficie_kmq": "70.8424",
      "codice_sovracomunale": "034"
    },
    {
      "sigla_provincia": "PR",
      "codice_istat": "034019",
      "denominazione_ita_altra": "Lesignano de' Bagni",
      "denominazione_ita": "Lesignano de' Bagni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E547",
      "lat": "44.6440272",
      "lon": "10.3016618",
      "superficie_kmq": "47.4850",
      "codice_sovracomunale": "034"
    },
    {
      "sigla_provincia": "PR",
      "codice_istat": "034020",
      "denominazione_ita_altra": "Medesano",
      "denominazione_ita": "Medesano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F082",
      "lat": "44.7568122",
      "lon": "10.1399611",
      "superficie_kmq": "88.7682",
      "codice_sovracomunale": "034"
    },
    {
      "sigla_provincia": "PR",
      "codice_istat": "034022",
      "denominazione_ita_altra": "Monchio delle Corti",
      "denominazione_ita": "Monchio delle Corti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F340",
      "lat": "44.4123644",
      "lon": "10.1232045",
      "superficie_kmq": "69.0416",
      "codice_sovracomunale": "034"
    },
    {
      "sigla_provincia": "PR",
      "codice_istat": "034023",
      "denominazione_ita_altra": "Montechiarugolo",
      "denominazione_ita": "Montechiarugolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F473",
      "lat": "44.6935727",
      "lon": "10.4224618",
      "superficie_kmq": "48.2015",
      "codice_sovracomunale": "034"
    },
    {
      "sigla_provincia": "PR",
      "codice_istat": "034024",
      "denominazione_ita_altra": "Neviano degli Arduini",
      "denominazione_ita": "Neviano degli Arduini",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F882",
      "lat": "44.5836448",
      "lon": "10.3177523",
      "superficie_kmq": "105.9563",
      "codice_sovracomunale": "034"
    },
    {
      "sigla_provincia": "PR",
      "codice_istat": "034025",
      "denominazione_ita_altra": "Noceto",
      "denominazione_ita": "Noceto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F914",
      "lat": "44.8102574",
      "lon": "10.1771929",
      "superficie_kmq": "79.1728",
      "codice_sovracomunale": "034"
    },
    {
      "sigla_provincia": "PR",
      "codice_istat": "034026",
      "denominazione_ita_altra": "Palanzano",
      "denominazione_ita": "Palanzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G255",
      "lat": "44.4353432",
      "lon": "10.1924034",
      "superficie_kmq": "69.8016",
      "codice_sovracomunale": "034"
    },
    {
      "sigla_provincia": "PR",
      "codice_istat": "034027",
      "denominazione_ita_altra": "Parma",
      "denominazione_ita": "Parma",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "G337",
      "lat": "44.8010694",
      "lon": "10.3283497",
      "superficie_kmq": "260.5979",
      "codice_sovracomunale": "034"
    },
    {
      "sigla_provincia": "PR",
      "codice_istat": "034028",
      "denominazione_ita_altra": "Pellegrino Parmense",
      "denominazione_ita": "Pellegrino Parmense",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G424",
      "lat": "44.7333220",
      "lon": "9.9268382",
      "superficie_kmq": "82.0786",
      "codice_sovracomunale": "034"
    },
    {
      "sigla_provincia": "PR",
      "codice_istat": "034030",
      "denominazione_ita_altra": "Roccabianca",
      "denominazione_ita": "Roccabianca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H384",
      "lat": "45.0081170",
      "lon": "10.2181206",
      "superficie_kmq": "40.4590",
      "codice_sovracomunale": "034"
    },
    {
      "sigla_provincia": "PR",
      "codice_istat": "034031",
      "denominazione_ita_altra": "Sala Baganza",
      "denominazione_ita": "Sala Baganza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H682",
      "lat": "44.7155193",
      "lon": "10.2282384",
      "superficie_kmq": "30.7549",
      "codice_sovracomunale": "034"
    },
    {
      "sigla_provincia": "PR",
      "codice_istat": "034032",
      "denominazione_ita_altra": "Salsomaggiore Terme",
      "denominazione_ita": "Salsomaggiore Terme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H720",
      "lat": "44.8156789",
      "lon": "9.9780944",
      "superficie_kmq": "81.4989",
      "codice_sovracomunale": "034"
    },
    {
      "sigla_provincia": "PR",
      "codice_istat": "034033",
      "denominazione_ita_altra": "San Secondo Parmense",
      "denominazione_ita": "San Secondo Parmense",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I153",
      "lat": "44.9191498",
      "lon": "10.2269061",
      "superficie_kmq": "37.7094",
      "codice_sovracomunale": "034"
    },
    {
      "sigla_provincia": "PR",
      "codice_istat": "034035",
      "denominazione_ita_altra": "Solignano",
      "denominazione_ita": "Solignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I803",
      "lat": "44.6151027",
      "lon": "9.9794018",
      "superficie_kmq": "73.1352",
      "codice_sovracomunale": "034"
    },
    {
      "sigla_provincia": "PR",
      "codice_istat": "034036",
      "denominazione_ita_altra": "Soragna",
      "denominazione_ita": "Soragna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I840",
      "lat": "44.9268946",
      "lon": "10.1223225",
      "superficie_kmq": "45.3863",
      "codice_sovracomunale": "034"
    },
    {
      "sigla_provincia": "PR",
      "codice_istat": "034038",
      "denominazione_ita_altra": "Terenzo",
      "denominazione_ita": "Terenzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E548",
      "lat": "44.6099981",
      "lon": "10.0876366",
      "superficie_kmq": "72.6961",
      "codice_sovracomunale": "034"
    },
    {
      "sigla_provincia": "PR",
      "codice_istat": "034039",
      "denominazione_ita_altra": "Tizzano Val Parma",
      "denominazione_ita": "Tizzano Val Parma",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L183",
      "lat": "44.5208507",
      "lon": "10.1989396",
      "superficie_kmq": "78.3864",
      "codice_sovracomunale": "034"
    },
    {
      "sigla_provincia": "PR",
      "codice_istat": "034040",
      "denominazione_ita_altra": "Tornolo",
      "denominazione_ita": "Tornolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L229",
      "lat": "44.4849015",
      "lon": "9.6244886",
      "superficie_kmq": "67.4779",
      "codice_sovracomunale": "034"
    },
    {
      "sigla_provincia": "PR",
      "codice_istat": "034041",
      "denominazione_ita_altra": "Torrile",
      "denominazione_ita": "Torrile",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L299",
      "lat": "44.8906035",
      "lon": "10.3550765",
      "superficie_kmq": "37.1470",
      "codice_sovracomunale": "034"
    },
    {
      "sigla_provincia": "PR",
      "codice_istat": "034042",
      "denominazione_ita_altra": "Traversetolo",
      "denominazione_ita": "Traversetolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L346",
      "lat": "44.6397422",
      "lon": "10.3816393",
      "superficie_kmq": "54.8602",
      "codice_sovracomunale": "034"
    },
    {
      "sigla_provincia": "PR",
      "codice_istat": "034044",
      "denominazione_ita_altra": "Valmozzola",
      "denominazione_ita": "Valmozzola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L641",
      "lat": "44.5682557",
      "lon": "9.8848820",
      "superficie_kmq": "67.6414",
      "codice_sovracomunale": "034"
    },
    {
      "sigla_provincia": "PR",
      "codice_istat": "034045",
      "denominazione_ita_altra": "Varano de' Melegari",
      "denominazione_ita": "Varano de' Melegari",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L672",
      "lat": "44.6870678",
      "lon": "10.0142820",
      "superficie_kmq": "64.9214",
      "codice_sovracomunale": "034"
    },
    {
      "sigla_provincia": "PR",
      "codice_istat": "034046",
      "denominazione_ita_altra": "Varsi",
      "denominazione_ita": "Varsi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L689",
      "lat": "44.6629056",
      "lon": "9.8456827",
      "superficie_kmq": "80.0644",
      "codice_sovracomunale": "034"
    },
    {
      "sigla_provincia": "PR",
      "codice_istat": "034049",
      "denominazione_ita_altra": "Sissa Trecasali",
      "denominazione_ita": "Sissa Trecasali",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M325",
      "lat": "44.9482700",
      "lon": "10.2798593",
      "superficie_kmq": "72.7144",
      "codice_sovracomunale": "034"
    },
    {
      "sigla_provincia": "PR",
      "codice_istat": "034050",
      "denominazione_ita_altra": "Polesine Zibello",
      "denominazione_ita": "Polesine Zibello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M367",
      "lat": "45.0127918",
      "lon": "10.1178921",
      "superficie_kmq": "48.5037",
      "codice_sovracomunale": "034"
    },
    {
      "sigla_provincia": "PR",
      "codice_istat": "034051",
      "denominazione_ita_altra": "Sorbolo Mezzani",
      "denominazione_ita": "Sorbolo Mezzani",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M411",
      "lat": "44.8871914",
      "lon": "10.4389188",
      "superficie_kmq": "66.9713",
      "codice_sovracomunale": "034"
    },
    {
      "sigla_provincia": "RE",
      "codice_istat": "035001",
      "denominazione_ita_altra": "Albinea",
      "denominazione_ita": "Albinea",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A162",
      "lat": "44.6196185",
      "lon": "10.6020588",
      "superficie_kmq": "43.8883",
      "codice_sovracomunale": "035"
    },
    {
      "sigla_provincia": "RE",
      "codice_istat": "035002",
      "denominazione_ita_altra": "Bagnolo in Piano",
      "denominazione_ita": "Bagnolo in Piano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A573",
      "lat": "44.7614248",
      "lon": "10.6729037",
      "superficie_kmq": "26.9392",
      "codice_sovracomunale": "035"
    },
    {
      "sigla_provincia": "RE",
      "codice_istat": "035003",
      "denominazione_ita_altra": "Baiso",
      "denominazione_ita": "Baiso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A586",
      "lat": "44.4952415",
      "lon": "10.5987930",
      "superficie_kmq": "75.5530",
      "codice_sovracomunale": "035"
    },
    {
      "sigla_provincia": "RE",
      "codice_istat": "035004",
      "denominazione_ita_altra": "Bibbiano",
      "denominazione_ita": "Bibbiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A850",
      "lat": "44.6632545",
      "lon": "10.4733696",
      "superficie_kmq": "28.1622",
      "codice_sovracomunale": "035"
    },
    {
      "sigla_provincia": "RE",
      "codice_istat": "035005",
      "denominazione_ita_altra": "Boretto",
      "denominazione_ita": "Boretto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A988",
      "lat": "44.9045344",
      "lon": "10.5534413",
      "superficie_kmq": "18.0943",
      "codice_sovracomunale": "035"
    },
    {
      "sigla_provincia": "RE",
      "codice_istat": "035006",
      "denominazione_ita_altra": "Brescello",
      "denominazione_ita": "Brescello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B156",
      "lat": "44.9015327",
      "lon": "10.5160119",
      "superficie_kmq": "24.0376",
      "codice_sovracomunale": "035"
    },
    {
      "sigla_provincia": "RE",
      "codice_istat": "035008",
      "denominazione_ita_altra": "Cadelbosco di Sopra",
      "denominazione_ita": "Cadelbosco di Sopra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B328",
      "lat": "44.7629238",
      "lon": "10.5943812",
      "superficie_kmq": "43.5957",
      "codice_sovracomunale": "035"
    },
    {
      "sigla_provincia": "RE",
      "codice_istat": "035009",
      "denominazione_ita_altra": "Campagnola Emilia",
      "denominazione_ita": "Campagnola Emilia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B499",
      "lat": "44.8413094",
      "lon": "10.7591702",
      "superficie_kmq": "24.3929",
      "codice_sovracomunale": "035"
    },
    {
      "sigla_provincia": "RE",
      "codice_istat": "035010",
      "denominazione_ita_altra": "Campegine",
      "denominazione_ita": "Campegine",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B502",
      "lat": "44.7813780",
      "lon": "10.5311156",
      "superficie_kmq": "22.6234",
      "codice_sovracomunale": "035"
    },
    {
      "sigla_provincia": "RE",
      "codice_istat": "035011",
      "denominazione_ita_altra": "Carpineti",
      "denominazione_ita": "Carpineti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B825",
      "lat": "44.4546749",
      "lon": "10.5195490",
      "superficie_kmq": "89.5670",
      "codice_sovracomunale": "035"
    },
    {
      "sigla_provincia": "RE",
      "codice_istat": "035012",
      "denominazione_ita_altra": "Casalgrande",
      "denominazione_ita": "Casalgrande",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B893",
      "lat": "44.5898660",
      "lon": "10.7392670",
      "superficie_kmq": "37.7068",
      "codice_sovracomunale": "035"
    },
    {
      "sigla_provincia": "RE",
      "codice_istat": "035013",
      "denominazione_ita_altra": "Casina",
      "denominazione_ita": "Casina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B967",
      "lat": "44.5116272",
      "lon": "10.5004644",
      "superficie_kmq": "63.8037",
      "codice_sovracomunale": "035"
    },
    {
      "sigla_provincia": "RE",
      "codice_istat": "035014",
      "denominazione_ita_altra": "Castellarano",
      "denominazione_ita": "Castellarano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C141",
      "lat": "44.5095444",
      "lon": "10.7306429",
      "superficie_kmq": "58.0580",
      "codice_sovracomunale": "035"
    },
    {
      "sigla_provincia": "RE",
      "codice_istat": "035015",
      "denominazione_ita_altra": "Castelnovo di Sotto",
      "denominazione_ita": "Castelnovo di Sotto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C218",
      "lat": "44.8115980",
      "lon": "10.5636799",
      "superficie_kmq": "35.0143",
      "codice_sovracomunale": "035"
    },
    {
      "sigla_provincia": "RE",
      "codice_istat": "035016",
      "denominazione_ita_altra": "Castelnovo ne' Monti",
      "denominazione_ita": "Castelnovo ne' Monti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C219",
      "lat": "44.4374775",
      "lon": "10.4032631",
      "superficie_kmq": "96.6802",
      "codice_sovracomunale": "035"
    },
    {
      "sigla_provincia": "RE",
      "codice_istat": "035017",
      "denominazione_ita_altra": "Cavriago",
      "denominazione_ita": "Cavriago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C405",
      "lat": "44.6948525",
      "lon": "10.5267335",
      "superficie_kmq": "16.9945",
      "codice_sovracomunale": "035"
    },
    {
      "sigla_provincia": "RE",
      "codice_istat": "035018",
      "denominazione_ita_altra": "Canossa",
      "denominazione_ita": "Canossa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C669",
      "lat": "44.5924938",
      "lon": "10.4084181",
      "superficie_kmq": "53.0823",
      "codice_sovracomunale": "035"
    },
    {
      "sigla_provincia": "RE",
      "codice_istat": "035020",
      "denominazione_ita_altra": "Correggio",
      "denominazione_ita": "Correggio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D037",
      "lat": "44.7713375",
      "lon": "10.7812149",
      "superficie_kmq": "77.5093",
      "codice_sovracomunale": "035"
    },
    {
      "sigla_provincia": "RE",
      "codice_istat": "035021",
      "denominazione_ita_altra": "Fabbrico",
      "denominazione_ita": "Fabbrico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D450",
      "lat": "44.8715944",
      "lon": "10.8095041",
      "superficie_kmq": "23.6332",
      "codice_sovracomunale": "035"
    },
    {
      "sigla_provincia": "RE",
      "codice_istat": "035022",
      "denominazione_ita_altra": "Gattatico",
      "denominazione_ita": "Gattatico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D934",
      "lat": "44.8062215",
      "lon": "10.4737507",
      "superficie_kmq": "42.1447",
      "codice_sovracomunale": "035"
    },
    {
      "sigla_provincia": "RE",
      "codice_istat": "035023",
      "denominazione_ita_altra": "Gualtieri",
      "denominazione_ita": "Gualtieri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E232",
      "lat": "44.9049582",
      "lon": "10.6294922",
      "superficie_kmq": "35.6438",
      "codice_sovracomunale": "035"
    },
    {
      "sigla_provincia": "RE",
      "codice_istat": "035024",
      "denominazione_ita_altra": "Guastalla",
      "denominazione_ita": "Guastalla",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E253",
      "lat": "44.9223955",
      "lon": "10.6540389",
      "superficie_kmq": "52.9206",
      "codice_sovracomunale": "035"
    },
    {
      "sigla_provincia": "RE",
      "codice_istat": "035026",
      "denominazione_ita_altra": "Luzzara",
      "denominazione_ita": "Luzzara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E772",
      "lat": "44.9600907",
      "lon": "10.6858751",
      "superficie_kmq": "38.5245",
      "codice_sovracomunale": "035"
    },
    {
      "sigla_provincia": "RE",
      "codice_istat": "035027",
      "denominazione_ita_altra": "Montecchio Emilia",
      "denominazione_ita": "Montecchio Emilia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F463",
      "lat": "44.7001335",
      "lon": "10.4465800",
      "superficie_kmq": "24.3868",
      "codice_sovracomunale": "035"
    },
    {
      "sigla_provincia": "RE",
      "codice_istat": "035028",
      "denominazione_ita_altra": "Novellara",
      "denominazione_ita": "Novellara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F960",
      "lat": "44.8451219",
      "lon": "10.7318890",
      "superficie_kmq": "58.1064",
      "codice_sovracomunale": "035"
    },
    {
      "sigla_provincia": "RE",
      "codice_istat": "035029",
      "denominazione_ita_altra": "Poviglio",
      "denominazione_ita": "Poviglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G947",
      "lat": "44.8408030",
      "lon": "10.5415167",
      "superficie_kmq": "43.5507",
      "codice_sovracomunale": "035"
    },
    {
      "sigla_provincia": "RE",
      "codice_istat": "035030",
      "denominazione_ita_altra": "Quattro Castella",
      "denominazione_ita": "Quattro Castella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H122",
      "lat": "44.6357653",
      "lon": "10.4726942",
      "superficie_kmq": "46.3056",
      "codice_sovracomunale": "035"
    },
    {
      "sigla_provincia": "RE",
      "codice_istat": "035032",
      "denominazione_ita_altra": "Reggiolo",
      "denominazione_ita": "Reggiolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H225",
      "lat": "44.9185889",
      "lon": "10.8042841",
      "superficie_kmq": "42.6623",
      "codice_sovracomunale": "035"
    },
    {
      "sigla_provincia": "RE",
      "codice_istat": "035033",
      "denominazione_ita_altra": "Reggio nell'Emilia",
      "denominazione_ita": "Reggio nell'Emilia",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "H223",
      "lat": "44.6973469",
      "lon": "10.6300796",
      "superficie_kmq": "230.6805",
      "codice_sovracomunale": "035"
    },
    {
      "sigla_provincia": "RE",
      "codice_istat": "035034",
      "denominazione_ita_altra": "Rio Saliceto",
      "denominazione_ita": "Rio Saliceto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H298",
      "lat": "44.8093156",
      "lon": "10.8029010",
      "superficie_kmq": "22.5618",
      "codice_sovracomunale": "035"
    },
    {
      "sigla_provincia": "RE",
      "codice_istat": "035035",
      "denominazione_ita_altra": "Rolo",
      "denominazione_ita": "Rolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H500",
      "lat": "44.8871987",
      "lon": "10.8563492",
      "superficie_kmq": "14.1703",
      "codice_sovracomunale": "035"
    },
    {
      "sigla_provincia": "RE",
      "codice_istat": "035036",
      "denominazione_ita_altra": "Rubiera",
      "denominazione_ita": "Rubiera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H628",
      "lat": "44.6532936",
      "lon": "10.7832583",
      "superficie_kmq": "25.1941",
      "codice_sovracomunale": "035"
    },
    {
      "sigla_provincia": "RE",
      "codice_istat": "035037",
      "denominazione_ita_altra": "San Martino in Rio",
      "denominazione_ita": "San Martino in Rio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I011",
      "lat": "44.7326923",
      "lon": "10.7846575",
      "superficie_kmq": "22.7176",
      "codice_sovracomunale": "035"
    },
    {
      "sigla_provincia": "RE",
      "codice_istat": "035038",
      "denominazione_ita_altra": "San Polo d'Enza",
      "denominazione_ita": "San Polo d'Enza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I123",
      "lat": "44.6264918",
      "lon": "10.4209023",
      "superficie_kmq": "32.2932",
      "codice_sovracomunale": "035"
    },
    {
      "sigla_provincia": "RE",
      "codice_istat": "035039",
      "denominazione_ita_altra": "Sant'Ilario d'Enza",
      "denominazione_ita": "Sant'Ilario d'Enza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I342",
      "lat": "44.7605928",
      "lon": "10.4514302",
      "superficie_kmq": "20.2331",
      "codice_sovracomunale": "035"
    },
    {
      "sigla_provincia": "RE",
      "codice_istat": "035040",
      "denominazione_ita_altra": "Scandiano",
      "denominazione_ita": "Scandiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I496",
      "lat": "44.5993823",
      "lon": "10.6882160",
      "superficie_kmq": "50.0478",
      "codice_sovracomunale": "035"
    },
    {
      "sigla_provincia": "RE",
      "codice_istat": "035041",
      "denominazione_ita_altra": "Toano",
      "denominazione_ita": "Toano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L184",
      "lat": "44.3763699",
      "lon": "10.5589473",
      "superficie_kmq": "67.2538",
      "codice_sovracomunale": "035"
    },
    {
      "sigla_provincia": "RE",
      "codice_istat": "035042",
      "denominazione_ita_altra": "Vetto",
      "denominazione_ita": "Vetto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L815",
      "lat": "44.4840384",
      "lon": "10.3392422",
      "superficie_kmq": "53.3728",
      "codice_sovracomunale": "035"
    },
    {
      "sigla_provincia": "RE",
      "codice_istat": "035043",
      "denominazione_ita_altra": "Vezzano sul Crostolo",
      "denominazione_ita": "Vezzano sul Crostolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L820",
      "lat": "44.6009077",
      "lon": "10.5464413",
      "superficie_kmq": "37.8178",
      "codice_sovracomunale": "035"
    },
    {
      "sigla_provincia": "RE",
      "codice_istat": "035044",
      "denominazione_ita_altra": "Viano",
      "denominazione_ita": "Viano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L831",
      "lat": "44.5482798",
      "lon": "10.6204073",
      "superficie_kmq": "44.9712",
      "codice_sovracomunale": "035"
    },
    {
      "sigla_provincia": "RE",
      "codice_istat": "035045",
      "denominazione_ita_altra": "Villa Minozzo",
      "denominazione_ita": "Villa Minozzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L969",
      "lat": "44.3652023",
      "lon": "10.4671922",
      "superficie_kmq": "168.0773",
      "codice_sovracomunale": "035"
    },
    {
      "sigla_provincia": "RE",
      "codice_istat": "035046",
      "denominazione_ita_altra": "Ventasso",
      "denominazione_ita": "Ventasso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M364",
      "lat": "44.3525619",
      "lon": "10.2809425",
      "superficie_kmq": "258.1724",
      "codice_sovracomunale": "035"
    },
    {
      "sigla_provincia": "MO",
      "codice_istat": "036001",
      "denominazione_ita_altra": "Bastiglia",
      "denominazione_ita": "Bastiglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A713",
      "lat": "44.7264445",
      "lon": "10.9998115",
      "superficie_kmq": "10.4662",
      "codice_sovracomunale": "036"
    },
    {
      "sigla_provincia": "MO",
      "codice_istat": "036002",
      "denominazione_ita_altra": "Bomporto",
      "denominazione_ita": "Bomporto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A959",
      "lat": "44.7273700",
      "lon": "11.0427868",
      "superficie_kmq": "38.8735",
      "codice_sovracomunale": "036"
    },
    {
      "sigla_provincia": "MO",
      "codice_istat": "036003",
      "denominazione_ita_altra": "Campogalliano",
      "denominazione_ita": "Campogalliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B539",
      "lat": "44.6904351",
      "lon": "10.8412577",
      "superficie_kmq": "35.6891",
      "codice_sovracomunale": "036"
    },
    {
      "sigla_provincia": "MO",
      "codice_istat": "036004",
      "denominazione_ita_altra": "Camposanto",
      "denominazione_ita": "Camposanto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B566",
      "lat": "44.7878997",
      "lon": "11.1382788",
      "superficie_kmq": "22.7072",
      "codice_sovracomunale": "036"
    },
    {
      "sigla_provincia": "MO",
      "codice_istat": "036005",
      "denominazione_ita_altra": "Carpi",
      "denominazione_ita": "Carpi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B819",
      "lat": "44.7824747",
      "lon": "10.8852626",
      "superficie_kmq": "131.5407",
      "codice_sovracomunale": "036"
    },
    {
      "sigla_provincia": "MO",
      "codice_istat": "036006",
      "denominazione_ita_altra": "Castelfranco Emilia",
      "denominazione_ita": "Castelfranco Emilia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C107",
      "lat": "44.5963590",
      "lon": "11.0529895",
      "superficie_kmq": "102.5111",
      "codice_sovracomunale": "036"
    },
    {
      "sigla_provincia": "MO",
      "codice_istat": "036007",
      "denominazione_ita_altra": "Castelnuovo Rangone",
      "denominazione_ita": "Castelnuovo Rangone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C242",
      "lat": "44.5491235",
      "lon": "10.9396487",
      "superficie_kmq": "22.4407",
      "codice_sovracomunale": "036"
    },
    {
      "sigla_provincia": "MO",
      "codice_istat": "036008",
      "denominazione_ita_altra": "Castelvetro di Modena",
      "denominazione_ita": "Castelvetro di Modena",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C287",
      "lat": "44.5034575",
      "lon": "10.9430322",
      "superficie_kmq": "49.7830",
      "codice_sovracomunale": "036"
    },
    {
      "sigla_provincia": "MO",
      "codice_istat": "036009",
      "denominazione_ita_altra": "Cavezzo",
      "denominazione_ita": "Cavezzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C398",
      "lat": "44.8376965",
      "lon": "11.0299045",
      "superficie_kmq": "26.7719",
      "codice_sovracomunale": "036"
    },
    {
      "sigla_provincia": "MO",
      "codice_istat": "036010",
      "denominazione_ita_altra": "Concordia sulla Secchia",
      "denominazione_ita": "Concordia sulla Secchia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C951",
      "lat": "44.9122338",
      "lon": "10.9828119",
      "superficie_kmq": "40.9448",
      "codice_sovracomunale": "036"
    },
    {
      "sigla_provincia": "MO",
      "codice_istat": "036011",
      "denominazione_ita_altra": "Fanano",
      "denominazione_ita": "Fanano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D486",
      "lat": "44.2073898",
      "lon": "10.7951399",
      "superficie_kmq": "89.9056",
      "codice_sovracomunale": "036"
    },
    {
      "sigla_provincia": "MO",
      "codice_istat": "036012",
      "denominazione_ita_altra": "Finale Emilia",
      "denominazione_ita": "Finale Emilia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D599",
      "lat": "44.8322096",
      "lon": "11.2957331",
      "superficie_kmq": "105.1257",
      "codice_sovracomunale": "036"
    },
    {
      "sigla_provincia": "MO",
      "codice_istat": "036013",
      "denominazione_ita_altra": "Fiorano Modenese",
      "denominazione_ita": "Fiorano Modenese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D607",
      "lat": "44.5390796",
      "lon": "10.8114201",
      "superficie_kmq": "26.2317",
      "codice_sovracomunale": "036"
    },
    {
      "sigla_provincia": "MO",
      "codice_istat": "036014",
      "denominazione_ita_altra": "Fiumalbo",
      "denominazione_ita": "Fiumalbo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D617",
      "lat": "44.1795037",
      "lon": "10.6473972",
      "superficie_kmq": "39.1349",
      "codice_sovracomunale": "036"
    },
    {
      "sigla_provincia": "MO",
      "codice_istat": "036015",
      "denominazione_ita_altra": "Formigine",
      "denominazione_ita": "Formigine",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D711",
      "lat": "44.5759749",
      "lon": "10.8401887",
      "superficie_kmq": "46.7584",
      "codice_sovracomunale": "036"
    },
    {
      "sigla_provincia": "MO",
      "codice_istat": "036016",
      "denominazione_ita_altra": "Frassinoro",
      "denominazione_ita": "Frassinoro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D783",
      "lat": "44.2953600",
      "lon": "10.5731314",
      "superficie_kmq": "95.4568",
      "codice_sovracomunale": "036"
    },
    {
      "sigla_provincia": "MO",
      "codice_istat": "036017",
      "denominazione_ita_altra": "Guiglia",
      "denominazione_ita": "Guiglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E264",
      "lat": "44.4279710",
      "lon": "10.9572710",
      "superficie_kmq": "48.2960",
      "codice_sovracomunale": "036"
    },
    {
      "sigla_provincia": "MO",
      "codice_istat": "036018",
      "denominazione_ita_altra": "Lama Mocogno",
      "denominazione_ita": "Lama Mocogno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E426",
      "lat": "44.3082965",
      "lon": "10.7306227",
      "superficie_kmq": "63.9070",
      "codice_sovracomunale": "036"
    },
    {
      "sigla_provincia": "MO",
      "codice_istat": "036019",
      "denominazione_ita_altra": "Maranello",
      "denominazione_ita": "Maranello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E904",
      "lat": "44.5251129",
      "lon": "10.8674476",
      "superficie_kmq": "32.5808",
      "codice_sovracomunale": "036"
    },
    {
      "sigla_provincia": "MO",
      "codice_istat": "036020",
      "denominazione_ita_altra": "Marano sul Panaro",
      "denominazione_ita": "Marano sul Panaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E905",
      "lat": "44.4561066",
      "lon": "10.9662869",
      "superficie_kmq": "45.4654",
      "codice_sovracomunale": "036"
    },
    {
      "sigla_provincia": "MO",
      "codice_istat": "036021",
      "denominazione_ita_altra": "Medolla",
      "denominazione_ita": "Medolla",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F087",
      "lat": "44.8489411",
      "lon": "11.0714767",
      "superficie_kmq": "26.9959",
      "codice_sovracomunale": "036"
    },
    {
      "sigla_provincia": "MO",
      "codice_istat": "036022",
      "denominazione_ita_altra": "Mirandola",
      "denominazione_ita": "Mirandola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F240",
      "lat": "44.8870744",
      "lon": "11.0660771",
      "superficie_kmq": "137.0459",
      "codice_sovracomunale": "036"
    },
    {
      "sigla_provincia": "MO",
      "codice_istat": "036023",
      "denominazione_ita_altra": "Modena",
      "denominazione_ita": "Modena",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "F257",
      "lat": "44.6459934",
      "lon": "10.9261547",
      "superficie_kmq": "183.1730",
      "codice_sovracomunale": "036"
    },
    {
      "sigla_provincia": "MO",
      "codice_istat": "036024",
      "denominazione_ita_altra": "Montecreto",
      "denominazione_ita": "Montecreto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F484",
      "lat": "44.2489434",
      "lon": "10.7207343",
      "superficie_kmq": "31.2213",
      "codice_sovracomunale": "036"
    },
    {
      "sigla_provincia": "MO",
      "codice_istat": "036025",
      "denominazione_ita_altra": "Montefiorino",
      "denominazione_ita": "Montefiorino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F503",
      "lat": "44.3594383",
      "lon": "10.6253257",
      "superficie_kmq": "45.2770",
      "codice_sovracomunale": "036"
    },
    {
      "sigla_provincia": "MO",
      "codice_istat": "036026",
      "denominazione_ita_altra": "Montese",
      "denominazione_ita": "Montese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F642",
      "lat": "44.2674579",
      "lon": "10.9403682",
      "superficie_kmq": "81.0046",
      "codice_sovracomunale": "036"
    },
    {
      "sigla_provincia": "MO",
      "codice_istat": "036027",
      "denominazione_ita_altra": "Nonantola",
      "denominazione_ita": "Nonantola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F930",
      "lat": "44.6777761",
      "lon": "11.0431176",
      "superficie_kmq": "55.3200",
      "codice_sovracomunale": "036"
    },
    {
      "sigla_provincia": "MO",
      "codice_istat": "036028",
      "denominazione_ita_altra": "Novi di Modena",
      "denominazione_ita": "Novi di Modena",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F966",
      "lat": "44.8935641",
      "lon": "10.9013425",
      "superficie_kmq": "51.8080",
      "codice_sovracomunale": "036"
    },
    {
      "sigla_provincia": "MO",
      "codice_istat": "036029",
      "denominazione_ita_altra": "Palagano",
      "denominazione_ita": "Palagano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G250",
      "lat": "44.3207127",
      "lon": "10.6477528",
      "superficie_kmq": "60.4110",
      "codice_sovracomunale": "036"
    },
    {
      "sigla_provincia": "MO",
      "codice_istat": "036030",
      "denominazione_ita_altra": "Pavullo nel Frignano",
      "denominazione_ita": "Pavullo nel Frignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G393",
      "lat": "44.3394593",
      "lon": "10.8339861",
      "superficie_kmq": "143.7304",
      "codice_sovracomunale": "036"
    },
    {
      "sigla_provincia": "MO",
      "codice_istat": "036031",
      "denominazione_ita_altra": "Pievepelago",
      "denominazione_ita": "Pievepelago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G649",
      "lat": "44.2070147",
      "lon": "10.6169755",
      "superficie_kmq": "76.5344",
      "codice_sovracomunale": "036"
    },
    {
      "sigla_provincia": "MO",
      "codice_istat": "036032",
      "denominazione_ita_altra": "Polinago",
      "denominazione_ita": "Polinago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G789",
      "lat": "44.3443484",
      "lon": "10.7231114",
      "superficie_kmq": "53.7389",
      "codice_sovracomunale": "036"
    },
    {
      "sigla_provincia": "MO",
      "codice_istat": "036033",
      "denominazione_ita_altra": "Prignano sulla Secchia",
      "denominazione_ita": "Prignano sulla Secchia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H061",
      "lat": "44.4359805",
      "lon": "10.6895240",
      "superficie_kmq": "79.6652",
      "codice_sovracomunale": "036"
    },
    {
      "sigla_provincia": "MO",
      "codice_istat": "036034",
      "denominazione_ita_altra": "Ravarino",
      "denominazione_ita": "Ravarino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H195",
      "lat": "44.7244638",
      "lon": "11.0999978",
      "superficie_kmq": "28.5303",
      "codice_sovracomunale": "036"
    },
    {
      "sigla_provincia": "MO",
      "codice_istat": "036035",
      "denominazione_ita_altra": "Riolunato",
      "denominazione_ita": "Riolunato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H303",
      "lat": "44.2304787",
      "lon": "10.6530283",
      "superficie_kmq": "44.9129",
      "codice_sovracomunale": "036"
    },
    {
      "sigla_provincia": "MO",
      "codice_istat": "036036",
      "denominazione_ita_altra": "San Cesario sul Panaro",
      "denominazione_ita": "San Cesario sul Panaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H794",
      "lat": "44.5615594",
      "lon": "11.0341677",
      "superficie_kmq": "27.3097",
      "codice_sovracomunale": "036"
    },
    {
      "sigla_provincia": "MO",
      "codice_istat": "036037",
      "denominazione_ita_altra": "San Felice sul Panaro",
      "denominazione_ita": "San Felice sul Panaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H835",
      "lat": "44.8393157",
      "lon": "11.1414042",
      "superficie_kmq": "51.6636",
      "codice_sovracomunale": "036"
    },
    {
      "sigla_provincia": "MO",
      "codice_istat": "036038",
      "denominazione_ita_altra": "San Possidonio",
      "denominazione_ita": "San Possidonio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I128",
      "lat": "44.8909913",
      "lon": "10.9967862",
      "superficie_kmq": "17.0582",
      "codice_sovracomunale": "036"
    },
    {
      "sigla_provincia": "MO",
      "codice_istat": "036039",
      "denominazione_ita_altra": "San Prospero",
      "denominazione_ita": "San Prospero",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I133",
      "lat": "44.7878956",
      "lon": "11.0228925",
      "superficie_kmq": "34.5570",
      "codice_sovracomunale": "036"
    },
    {
      "sigla_provincia": "MO",
      "codice_istat": "036040",
      "denominazione_ita_altra": "Sassuolo",
      "denominazione_ita": "Sassuolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I462",
      "lat": "44.5403172",
      "lon": "10.7827016",
      "superficie_kmq": "38.4032",
      "codice_sovracomunale": "036"
    },
    {
      "sigla_provincia": "MO",
      "codice_istat": "036041",
      "denominazione_ita_altra": "Savignano sul Panaro",
      "denominazione_ita": "Savignano sul Panaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I473",
      "lat": "44.4795846",
      "lon": "11.0337550",
      "superficie_kmq": "25.5540",
      "codice_sovracomunale": "036"
    },
    {
      "sigla_provincia": "MO",
      "codice_istat": "036042",
      "denominazione_ita_altra": "Serramazzoni",
      "denominazione_ita": "Serramazzoni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F357",
      "lat": "44.4212256",
      "lon": "10.7901899",
      "superficie_kmq": "93.9602",
      "codice_sovracomunale": "036"
    },
    {
      "sigla_provincia": "MO",
      "codice_istat": "036043",
      "denominazione_ita_altra": "Sestola",
      "denominazione_ita": "Sestola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I689",
      "lat": "44.2301353",
      "lon": "10.7713807",
      "superficie_kmq": "52.4711",
      "codice_sovracomunale": "036"
    },
    {
      "sigla_provincia": "MO",
      "codice_istat": "036044",
      "denominazione_ita_altra": "Soliera",
      "denominazione_ita": "Soliera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I802",
      "lat": "44.7381348",
      "lon": "10.9247311",
      "superficie_kmq": "50.9283",
      "codice_sovracomunale": "036"
    },
    {
      "sigla_provincia": "MO",
      "codice_istat": "036045",
      "denominazione_ita_altra": "Spilamberto",
      "denominazione_ita": "Spilamberto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I903",
      "lat": "44.5337060",
      "lon": "11.0237403",
      "superficie_kmq": "29.7918",
      "codice_sovracomunale": "036"
    },
    {
      "sigla_provincia": "MO",
      "codice_istat": "036046",
      "denominazione_ita_altra": "Vignola",
      "denominazione_ita": "Vignola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L885",
      "lat": "44.4795276",
      "lon": "11.0107571",
      "superficie_kmq": "22.8613",
      "codice_sovracomunale": "036"
    },
    {
      "sigla_provincia": "MO",
      "codice_istat": "036047",
      "denominazione_ita_altra": "Zocca",
      "denominazione_ita": "Zocca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M183",
      "lat": "44.3450538",
      "lon": "10.9952748",
      "superficie_kmq": "69.3646",
      "codice_sovracomunale": "036"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037001",
      "denominazione_ita_altra": "Anzola dell'Emilia",
      "denominazione_ita": "Anzola dell'Emilia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A324",
      "lat": "44.5464749",
      "lon": "11.1932691",
      "superficie_kmq": "36.5961",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037002",
      "denominazione_ita_altra": "Argelato",
      "denominazione_ita": "Argelato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A392",
      "lat": "44.6423203",
      "lon": "11.3476452",
      "superficie_kmq": "35.1033",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037003",
      "denominazione_ita_altra": "Baricella",
      "denominazione_ita": "Baricella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A665",
      "lat": "44.6470541",
      "lon": "11.5345498",
      "superficie_kmq": "45.4839",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037005",
      "denominazione_ita_altra": "Bentivoglio",
      "denominazione_ita": "Bentivoglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A785",
      "lat": "44.6351422",
      "lon": "11.4178636",
      "superficie_kmq": "51.1055",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037006",
      "denominazione_ita_altra": "Bologna",
      "denominazione_ita": "Bologna",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "A944",
      "lat": "44.4943580",
      "lon": "11.3417204",
      "superficie_kmq": "140.8566",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037007",
      "denominazione_ita_altra": "Borgo Tossignano",
      "denominazione_ita": "Borgo Tossignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B044",
      "lat": "44.2772963",
      "lon": "11.5892280",
      "superficie_kmq": "29.2729",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037008",
      "denominazione_ita_altra": "Budrio",
      "denominazione_ita": "Budrio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B249",
      "lat": "44.5375079",
      "lon": "11.5356394",
      "superficie_kmq": "120.1849",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037009",
      "denominazione_ita_altra": "Calderara di Reno",
      "denominazione_ita": "Calderara di Reno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B399",
      "lat": "44.5660739",
      "lon": "11.2720236",
      "superficie_kmq": "40.7517",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037010",
      "denominazione_ita_altra": "Camugnano",
      "denominazione_ita": "Camugnano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B572",
      "lat": "44.1689513",
      "lon": "11.0873923",
      "superficie_kmq": "96.5976",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037011",
      "denominazione_ita_altra": "Casalecchio di Reno",
      "denominazione_ita": "Casalecchio di Reno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B880",
      "lat": "44.4811892",
      "lon": "11.2824684",
      "superficie_kmq": "17.3334",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037012",
      "denominazione_ita_altra": "Casalfiumanese",
      "denominazione_ita": "Casalfiumanese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B892",
      "lat": "44.2976172",
      "lon": "11.6162053",
      "superficie_kmq": "82.0283",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037013",
      "denominazione_ita_altra": "Castel d'Aiano",
      "denominazione_ita": "Castel d'Aiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C075",
      "lat": "44.2806404",
      "lon": "11.0013052",
      "superficie_kmq": "45.2605",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037014",
      "denominazione_ita_altra": "Castel del Rio",
      "denominazione_ita": "Castel del Rio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C086",
      "lat": "44.2131424",
      "lon": "11.5044276",
      "superficie_kmq": "52.5914",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037015",
      "denominazione_ita_altra": "Castel di Casio",
      "denominazione_ita": "Castel di Casio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B969",
      "lat": "44.1630466",
      "lon": "11.0363696",
      "superficie_kmq": "47.3257",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037016",
      "denominazione_ita_altra": "Castel Guelfo di Bologna",
      "denominazione_ita": "Castel Guelfo di Bologna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C121",
      "lat": "44.4318063",
      "lon": "11.6789537",
      "superficie_kmq": "28.6088",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037017",
      "denominazione_ita_altra": "Castello d'Argile",
      "denominazione_ita": "Castello d'Argile",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C185",
      "lat": "44.6812258",
      "lon": "11.2965999",
      "superficie_kmq": "29.0706",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037019",
      "denominazione_ita_altra": "Castel Maggiore",
      "denominazione_ita": "Castel Maggiore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C204",
      "lat": "44.5749890",
      "lon": "11.3648913",
      "superficie_kmq": "30.9004",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037020",
      "denominazione_ita_altra": "Castel San Pietro Terme",
      "denominazione_ita": "Castel San Pietro Terme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C265",
      "lat": "44.3981124",
      "lon": "11.5891780",
      "superficie_kmq": "148.4147",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037021",
      "denominazione_ita_altra": "Castenaso",
      "denominazione_ita": "Castenaso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C292",
      "lat": "44.5089089",
      "lon": "11.4687868",
      "superficie_kmq": "35.7333",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037022",
      "denominazione_ita_altra": "Castiglione dei Pepoli",
      "denominazione_ita": "Castiglione dei Pepoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C296",
      "lat": "44.1416417",
      "lon": "11.1539116",
      "superficie_kmq": "65.7611",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037024",
      "denominazione_ita_altra": "Crevalcore",
      "denominazione_ita": "Crevalcore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D166",
      "lat": "44.7226389",
      "lon": "11.1470344",
      "superficie_kmq": "102.7526",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037025",
      "denominazione_ita_altra": "Dozza",
      "denominazione_ita": "Dozza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D360",
      "lat": "44.3594281",
      "lon": "11.6286774",
      "superficie_kmq": "24.2317",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037026",
      "denominazione_ita_altra": "Fontanelice",
      "denominazione_ita": "Fontanelice",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D668",
      "lat": "44.2574463",
      "lon": "11.5583694",
      "superficie_kmq": "36.5572",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037027",
      "denominazione_ita_altra": "Gaggio Montano",
      "denominazione_ita": "Gaggio Montano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D847",
      "lat": "44.1989913",
      "lon": "10.9362460",
      "superficie_kmq": "58.6697",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037028",
      "denominazione_ita_altra": "Galliera",
      "denominazione_ita": "Galliera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D878",
      "lat": "44.7447349",
      "lon": "11.4359415",
      "superficie_kmq": "37.1536",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037030",
      "denominazione_ita_altra": "Granarolo dell'Emilia",
      "denominazione_ita": "Granarolo dell'Emilia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E136",
      "lat": "44.5534148",
      "lon": "11.4476949",
      "superficie_kmq": "34.3693",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037031",
      "denominazione_ita_altra": "Grizzana Morandi",
      "denominazione_ita": "Grizzana Morandi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E187",
      "lat": "44.2555004",
      "lon": "11.1507505",
      "superficie_kmq": "77.4024",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037032",
      "denominazione_ita_altra": "Imola",
      "denominazione_ita": "Imola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E289",
      "lat": "44.3530631",
      "lon": "11.7141554",
      "superficie_kmq": "205.0213",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037033",
      "denominazione_ita_altra": "Lizzano in Belvedere",
      "denominazione_ita": "Lizzano in Belvedere",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A771",
      "lat": "44.1616298",
      "lon": "10.8936621",
      "superficie_kmq": "85.4470",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037034",
      "denominazione_ita_altra": "Loiano",
      "denominazione_ita": "Loiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E655",
      "lat": "44.2701677",
      "lon": "11.3217287",
      "superficie_kmq": "52.4056",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037035",
      "denominazione_ita_altra": "Malalbergo",
      "denominazione_ita": "Malalbergo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E844",
      "lat": "44.7188996",
      "lon": "11.5339784",
      "superficie_kmq": "53.8163",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037036",
      "denominazione_ita_altra": "Marzabotto",
      "denominazione_ita": "Marzabotto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B689",
      "lat": "44.3403739",
      "lon": "11.2051399",
      "superficie_kmq": "74.5263",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037037",
      "denominazione_ita_altra": "Medicina",
      "denominazione_ita": "Medicina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F083",
      "lat": "44.4764351",
      "lon": "11.6404752",
      "superficie_kmq": "159.1056",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037038",
      "denominazione_ita_altra": "Minerbio",
      "denominazione_ita": "Minerbio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F219",
      "lat": "44.6251943",
      "lon": "11.4908026",
      "superficie_kmq": "43.0663",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037039",
      "denominazione_ita_altra": "Molinella",
      "denominazione_ita": "Molinella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F288",
      "lat": "44.6203624",
      "lon": "11.6703213",
      "superficie_kmq": "127.8402",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037040",
      "denominazione_ita_altra": "Monghidoro",
      "denominazione_ita": "Monghidoro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F363",
      "lat": "44.2194312",
      "lon": "11.3166301",
      "superficie_kmq": "48.2812",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037041",
      "denominazione_ita_altra": "Monterenzio",
      "denominazione_ita": "Monterenzio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F597",
      "lat": "44.3252676",
      "lon": "11.4050062",
      "superficie_kmq": "105.2554",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037042",
      "denominazione_ita_altra": "Monte San Pietro",
      "denominazione_ita": "Monte San Pietro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F627",
      "lat": "44.4577524",
      "lon": "11.1996848",
      "superficie_kmq": "74.6857",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037044",
      "denominazione_ita_altra": "Monzuno",
      "denominazione_ita": "Monzuno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F706",
      "lat": "44.2767965",
      "lon": "11.2714434",
      "superficie_kmq": "65.0081",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037045",
      "denominazione_ita_altra": "Mordano",
      "denominazione_ita": "Mordano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F718",
      "lat": "44.3977144",
      "lon": "11.8105339",
      "superficie_kmq": "21.4513",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037046",
      "denominazione_ita_altra": "Ozzano dell'Emilia",
      "denominazione_ita": "Ozzano dell'Emilia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G205",
      "lat": "44.4448270",
      "lon": "11.4782017",
      "superficie_kmq": "64.9471",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037047",
      "denominazione_ita_altra": "Pianoro",
      "denominazione_ita": "Pianoro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G570",
      "lat": "44.3850990",
      "lon": "11.3432594",
      "superficie_kmq": "107.1290",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037048",
      "denominazione_ita_altra": "Pieve di Cento",
      "denominazione_ita": "Pieve di Cento",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G643",
      "lat": "44.7136271",
      "lon": "11.3070034",
      "superficie_kmq": "15.9368",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037050",
      "denominazione_ita_altra": "Sala Bolognese",
      "denominazione_ita": "Sala Bolognese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H678",
      "lat": "44.6297905",
      "lon": "11.2768679",
      "superficie_kmq": "45.6367",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037051",
      "denominazione_ita_altra": "San Benedetto Val di Sambro",
      "denominazione_ita": "San Benedetto Val di Sambro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G566",
      "lat": "44.2155617",
      "lon": "11.2330665",
      "superficie_kmq": "66.4675",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037052",
      "denominazione_ita_altra": "San Giorgio di Piano",
      "denominazione_ita": "San Giorgio di Piano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H896",
      "lat": "44.6469770",
      "lon": "11.3751383",
      "superficie_kmq": "30.4326",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037053",
      "denominazione_ita_altra": "San Giovanni in Persiceto",
      "denominazione_ita": "San Giovanni in Persiceto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G467",
      "lat": "44.6387648",
      "lon": "11.1877983",
      "superficie_kmq": "114.4106",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037054",
      "denominazione_ita_altra": "San Lazzaro di Savena",
      "denominazione_ita": "San Lazzaro di Savena",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H945",
      "lat": "44.4704676",
      "lon": "11.4080571",
      "superficie_kmq": "44.7241",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037055",
      "denominazione_ita_altra": "San Pietro in Casale",
      "denominazione_ita": "San Pietro in Casale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I110",
      "lat": "44.7026195",
      "lon": "11.4061939",
      "superficie_kmq": "65.8561",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037056",
      "denominazione_ita_altra": "Sant'Agata Bolognese",
      "denominazione_ita": "Sant'Agata Bolognese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I191",
      "lat": "44.6645377",
      "lon": "11.1338101",
      "superficie_kmq": "34.7917",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037057",
      "denominazione_ita_altra": "Sasso Marconi",
      "denominazione_ita": "Sasso Marconi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G972",
      "lat": "44.3943649",
      "lon": "11.2449169",
      "superficie_kmq": "96.4485",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037059",
      "denominazione_ita_altra": "Vergato",
      "denominazione_ita": "Vergato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L762",
      "lat": "44.2839283",
      "lon": "11.1127830",
      "superficie_kmq": "59.9401",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037060",
      "denominazione_ita_altra": "Zola Predosa",
      "denominazione_ita": "Zola Predosa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M185",
      "lat": "44.4922290",
      "lon": "11.2181831",
      "superficie_kmq": "37.7472",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037061",
      "denominazione_ita_altra": "Valsamoggia",
      "denominazione_ita": "Valsamoggia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M320",
      "lat": "44.4441679",
      "lon": "11.0912529",
      "superficie_kmq": "178.1313",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "BO",
      "codice_istat": "037062",
      "denominazione_ita_altra": "Alto Reno Terme",
      "denominazione_ita": "Alto Reno Terme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M369",
      "lat": "44.1277412",
      "lon": "10.9361750",
      "superficie_kmq": "73.6224",
      "codice_sovracomunale": "237"
    },
    {
      "sigla_provincia": "FE",
      "codice_istat": "038001",
      "denominazione_ita_altra": "Argenta",
      "denominazione_ita": "Argenta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A393",
      "lat": "44.6144289",
      "lon": "11.8370423",
      "superficie_kmq": "311.6630",
      "codice_sovracomunale": "038"
    },
    {
      "sigla_provincia": "FE",
      "codice_istat": "038003",
      "denominazione_ita_altra": "Bondeno",
      "denominazione_ita": "Bondeno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A965",
      "lat": "44.8852131",
      "lon": "11.4184641",
      "superficie_kmq": "174.7530",
      "codice_sovracomunale": "038"
    },
    {
      "sigla_provincia": "FE",
      "codice_istat": "038004",
      "denominazione_ita_altra": "Cento",
      "denominazione_ita": "Cento",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C469",
      "lat": "44.7275511",
      "lon": "11.2906097",
      "superficie_kmq": "64.7415",
      "codice_sovracomunale": "038"
    },
    {
      "sigla_provincia": "FE",
      "codice_istat": "038005",
      "denominazione_ita_altra": "Codigoro",
      "denominazione_ita": "Codigoro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C814",
      "lat": "44.8318358",
      "lon": "12.1050145",
      "superficie_kmq": "169.2711",
      "codice_sovracomunale": "038"
    },
    {
      "sigla_provincia": "FE",
      "codice_istat": "038006",
      "denominazione_ita_altra": "Comacchio",
      "denominazione_ita": "Comacchio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C912",
      "lat": "44.6946183",
      "lon": "12.1838722",
      "superficie_kmq": "283.7461",
      "codice_sovracomunale": "038"
    },
    {
      "sigla_provincia": "FE",
      "codice_istat": "038007",
      "denominazione_ita_altra": "Copparo",
      "denominazione_ita": "Copparo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C980",
      "lat": "44.8954730",
      "lon": "11.8287199",
      "superficie_kmq": "157.0042",
      "codice_sovracomunale": "038"
    },
    {
      "sigla_provincia": "FE",
      "codice_istat": "038008",
      "denominazione_ita_altra": "Ferrara",
      "denominazione_ita": "Ferrara",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "D548",
      "lat": "44.8359872",
      "lon": "11.6186892",
      "superficie_kmq": "405.1396",
      "codice_sovracomunale": "038"
    },
    {
      "sigla_provincia": "FE",
      "codice_istat": "038010",
      "denominazione_ita_altra": "Jolanda di Savoia",
      "denominazione_ita": "Jolanda di Savoia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E320",
      "lat": "44.8861514",
      "lon": "11.9745084",
      "superficie_kmq": "108.3382",
      "codice_sovracomunale": "038"
    },
    {
      "sigla_provincia": "FE",
      "codice_istat": "038011",
      "denominazione_ita_altra": "Lagosanto",
      "denominazione_ita": "Lagosanto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E410",
      "lat": "44.7654101",
      "lon": "12.1410012",
      "superficie_kmq": "34.4368",
      "codice_sovracomunale": "038"
    },
    {
      "sigla_provincia": "FE",
      "codice_istat": "038012",
      "denominazione_ita_altra": "Masi Torello",
      "denominazione_ita": "Masi Torello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F016",
      "lat": "44.7940324",
      "lon": "11.7952730",
      "superficie_kmq": "22.7071",
      "codice_sovracomunale": "038"
    },
    {
      "sigla_provincia": "FE",
      "codice_istat": "038014",
      "denominazione_ita_altra": "Mesola",
      "denominazione_ita": "Mesola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F156",
      "lat": "44.9224848",
      "lon": "12.2292448",
      "superficie_kmq": "84.2986",
      "codice_sovracomunale": "038"
    },
    {
      "sigla_provincia": "FE",
      "codice_istat": "038017",
      "denominazione_ita_altra": "Ostellato",
      "denominazione_ita": "Ostellato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G184",
      "lat": "44.7454054",
      "lon": "11.9408256",
      "superficie_kmq": "173.3408",
      "codice_sovracomunale": "038"
    },
    {
      "sigla_provincia": "FE",
      "codice_istat": "038018",
      "denominazione_ita_altra": "Poggio Renatico",
      "denominazione_ita": "Poggio Renatico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G768",
      "lat": "44.7647097",
      "lon": "11.4854536",
      "superficie_kmq": "80.2322",
      "codice_sovracomunale": "038"
    },
    {
      "sigla_provincia": "FE",
      "codice_istat": "038019",
      "denominazione_ita_altra": "Portomaggiore",
      "denominazione_ita": "Portomaggiore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G916",
      "lat": "44.6977998",
      "lon": "11.8057779",
      "superficie_kmq": "126.6429",
      "codice_sovracomunale": "038"
    },
    {
      "sigla_provincia": "FE",
      "codice_istat": "038022",
      "denominazione_ita_altra": "Vigarano Mainarda",
      "denominazione_ita": "Vigarano Mainarda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L868",
      "lat": "44.8417583",
      "lon": "11.5003155",
      "superficie_kmq": "42.0182",
      "codice_sovracomunale": "038"
    },
    {
      "sigla_provincia": "FE",
      "codice_istat": "038023",
      "denominazione_ita_altra": "Voghiera",
      "denominazione_ita": "Voghiera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M110",
      "lat": "44.7581357",
      "lon": "11.7512453",
      "superficie_kmq": "40.3268",
      "codice_sovracomunale": "038"
    },
    {
      "sigla_provincia": "FE",
      "codice_istat": "038025",
      "denominazione_ita_altra": "Goro",
      "denominazione_ita": "Goro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E107",
      "lat": "44.8538126",
      "lon": "12.3027611",
      "superficie_kmq": "26.6198",
      "codice_sovracomunale": "038"
    },
    {
      "sigla_provincia": "FE",
      "codice_istat": "038027",
      "denominazione_ita_altra": "Fiscaglia",
      "denominazione_ita": "Fiscaglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M323",
      "lat": "44.7888547",
      "lon": "12.0143302",
      "superficie_kmq": "116.1817",
      "codice_sovracomunale": "038"
    },
    {
      "sigla_provincia": "FE",
      "codice_istat": "038028",
      "denominazione_ita_altra": "Terre del Reno",
      "denominazione_ita": "Terre del Reno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M381",
      "lat": "44.8069233",
      "lon": "11.4047066",
      "superficie_kmq": "51.0347",
      "codice_sovracomunale": "038"
    },
    {
      "sigla_provincia": "FE",
      "codice_istat": "038029",
      "denominazione_ita_altra": "Riva del Po",
      "denominazione_ita": "Riva del Po",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M410",
      "lat": "44.9540305",
      "lon": "11.9173534",
      "superficie_kmq": "111.8218",
      "codice_sovracomunale": "038"
    },
    {
      "sigla_provincia": "FE",
      "codice_istat": "038030",
      "denominazione_ita_altra": "Tresignana",
      "denominazione_ita": "Tresignana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M409",
      "lat": "44.8256964",
      "lon": "11.8830539",
      "superficie_kmq": "43.0586",
      "codice_sovracomunale": "038"
    },
    {
      "sigla_provincia": "RA",
      "codice_istat": "039001",
      "denominazione_ita_altra": "Alfonsine",
      "denominazione_ita": "Alfonsine",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A191",
      "lat": "44.5063098",
      "lon": "12.0406474",
      "superficie_kmq": "106.7905",
      "codice_sovracomunale": "039"
    },
    {
      "sigla_provincia": "RA",
      "codice_istat": "039002",
      "denominazione_ita_altra": "Bagnacavallo",
      "denominazione_ita": "Bagnacavallo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A547",
      "lat": "44.4162904",
      "lon": "11.9769723",
      "superficie_kmq": "79.5793",
      "codice_sovracomunale": "039"
    },
    {
      "sigla_provincia": "RA",
      "codice_istat": "039003",
      "denominazione_ita_altra": "Bagnara di Romagna",
      "denominazione_ita": "Bagnara di Romagna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A551",
      "lat": "44.3888176",
      "lon": "11.8258174",
      "superficie_kmq": "9.9578",
      "codice_sovracomunale": "039"
    },
    {
      "sigla_provincia": "RA",
      "codice_istat": "039004",
      "denominazione_ita_altra": "Brisighella",
      "denominazione_ita": "Brisighella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B188",
      "lat": "44.2213337",
      "lon": "11.7690198",
      "superficie_kmq": "194.3190",
      "codice_sovracomunale": "039"
    },
    {
      "sigla_provincia": "RA",
      "codice_istat": "039005",
      "denominazione_ita_altra": "Casola Valsenio",
      "denominazione_ita": "Casola Valsenio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B982",
      "lat": "44.2235063",
      "lon": "11.6230757",
      "superficie_kmq": "84.4135",
      "codice_sovracomunale": "039"
    },
    {
      "sigla_provincia": "RA",
      "codice_istat": "039006",
      "denominazione_ita_altra": "Castel Bolognese",
      "denominazione_ita": "Castel Bolognese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C065",
      "lat": "44.3191010",
      "lon": "11.7989481",
      "superficie_kmq": "32.3734",
      "codice_sovracomunale": "039"
    },
    {
      "sigla_provincia": "RA",
      "codice_istat": "039007",
      "denominazione_ita_altra": "Cervia",
      "denominazione_ita": "Cervia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C553",
      "lat": "44.2609163",
      "lon": "12.3498146",
      "superficie_kmq": "82.2725",
      "codice_sovracomunale": "039"
    },
    {
      "sigla_provincia": "RA",
      "codice_istat": "039008",
      "denominazione_ita_altra": "Conselice",
      "denominazione_ita": "Conselice",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C963",
      "lat": "44.5126565",
      "lon": "11.8290642",
      "superficie_kmq": "60.1994",
      "codice_sovracomunale": "039"
    },
    {
      "sigla_provincia": "RA",
      "codice_istat": "039009",
      "denominazione_ita_altra": "Cotignola",
      "denominazione_ita": "Cotignola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D121",
      "lat": "44.3843802",
      "lon": "11.9422156",
      "superficie_kmq": "35.1390",
      "codice_sovracomunale": "039"
    },
    {
      "sigla_provincia": "RA",
      "codice_istat": "039010",
      "denominazione_ita_altra": "Faenza",
      "denominazione_ita": "Faenza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D458",
      "lat": "44.2854254",
      "lon": "11.8827966",
      "superficie_kmq": "215.7569",
      "codice_sovracomunale": "039"
    },
    {
      "sigla_provincia": "RA",
      "codice_istat": "039011",
      "denominazione_ita_altra": "Fusignano",
      "denominazione_ita": "Fusignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D829",
      "lat": "44.4680153",
      "lon": "11.9592999",
      "superficie_kmq": "24.5496",
      "codice_sovracomunale": "039"
    },
    {
      "sigla_provincia": "RA",
      "codice_istat": "039012",
      "denominazione_ita_altra": "Lugo",
      "denominazione_ita": "Lugo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E730",
      "lat": "44.4208242",
      "lon": "11.9114820",
      "superficie_kmq": "117.0548",
      "codice_sovracomunale": "039"
    },
    {
      "sigla_provincia": "RA",
      "codice_istat": "039013",
      "denominazione_ita_altra": "Massa Lombarda",
      "denominazione_ita": "Massa Lombarda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F029",
      "lat": "44.4465613",
      "lon": "11.8282127",
      "superficie_kmq": "37.2479",
      "codice_sovracomunale": "039"
    },
    {
      "sigla_provincia": "RA",
      "codice_istat": "039014",
      "denominazione_ita_altra": "Ravenna",
      "denominazione_ita": "Ravenna",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "H199",
      "lat": "44.4172153",
      "lon": "12.1991388",
      "superficie_kmq": "653.8119",
      "codice_sovracomunale": "039"
    },
    {
      "sigla_provincia": "RA",
      "codice_istat": "039015",
      "denominazione_ita_altra": "Riolo Terme",
      "denominazione_ita": "Riolo Terme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H302",
      "lat": "44.2761462",
      "lon": "11.7245473",
      "superficie_kmq": "44.2582",
      "codice_sovracomunale": "039"
    },
    {
      "sigla_provincia": "RA",
      "codice_istat": "039016",
      "denominazione_ita_altra": "Russi",
      "denominazione_ita": "Russi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H642",
      "lat": "44.3724117",
      "lon": "12.0328920",
      "superficie_kmq": "46.2594",
      "codice_sovracomunale": "039"
    },
    {
      "sigla_provincia": "RA",
      "codice_istat": "039017",
      "denominazione_ita_altra": "Sant'Agata sul Santerno",
      "denominazione_ita": "Sant'Agata sul Santerno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I196",
      "lat": "44.4423224",
      "lon": "11.8616541",
      "superficie_kmq": "9.3707",
      "codice_sovracomunale": "039"
    },
    {
      "sigla_provincia": "RA",
      "codice_istat": "039018",
      "denominazione_ita_altra": "Solarolo",
      "denominazione_ita": "Solarolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I787",
      "lat": "44.3601603",
      "lon": "11.8437520",
      "superficie_kmq": "26.0383",
      "codice_sovracomunale": "039"
    },
    {
      "sigla_provincia": "FC",
      "codice_istat": "040001",
      "denominazione_ita_altra": "Bagno di Romagna",
      "denominazione_ita": "Bagno di Romagna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A565",
      "lat": "43.8577896",
      "lon": "11.9768614",
      "superficie_kmq": "233.5005",
      "codice_sovracomunale": "040"
    },
    {
      "sigla_provincia": "FC",
      "codice_istat": "040003",
      "denominazione_ita_altra": "Bertinoro",
      "denominazione_ita": "Bertinoro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A809",
      "lat": "44.1488999",
      "lon": "12.1342503",
      "superficie_kmq": "57.2533",
      "codice_sovracomunale": "040"
    },
    {
      "sigla_provincia": "FC",
      "codice_istat": "040004",
      "denominazione_ita_altra": "Borghi",
      "denominazione_ita": "Borghi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B001",
      "lat": "44.0314491",
      "lon": "12.3545688",
      "superficie_kmq": "30.2325",
      "codice_sovracomunale": "040"
    },
    {
      "sigla_provincia": "FC",
      "codice_istat": "040005",
      "denominazione_ita_altra": "Castrocaro Terme e Terra del Sole",
      "denominazione_ita": "Castrocaro Terme e Terra del Sole",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C339",
      "lat": "44.1760548",
      "lon": "11.9500265",
      "superficie_kmq": "38.9501",
      "codice_sovracomunale": "040"
    },
    {
      "sigla_provincia": "FC",
      "codice_istat": "040007",
      "denominazione_ita_altra": "Cesena",
      "denominazione_ita": "Cesena",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C573",
      "lat": "44.1365352",
      "lon": "12.2421742",
      "superficie_kmq": "249.4614",
      "codice_sovracomunale": "040"
    },
    {
      "sigla_provincia": "FC",
      "codice_istat": "040008",
      "denominazione_ita_altra": "Cesenatico",
      "denominazione_ita": "Cesenatico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C574",
      "lat": "44.1995224",
      "lon": "12.3954409",
      "superficie_kmq": "45.1634",
      "codice_sovracomunale": "040"
    },
    {
      "sigla_provincia": "FC",
      "codice_istat": "040009",
      "denominazione_ita_altra": "Civitella di Romagna",
      "denominazione_ita": "Civitella di Romagna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C777",
      "lat": "44.0068447",
      "lon": "11.9382572",
      "superficie_kmq": "117.9290",
      "codice_sovracomunale": "040"
    },
    {
      "sigla_provincia": "FC",
      "codice_istat": "040011",
      "denominazione_ita_altra": "Dovadola",
      "denominazione_ita": "Dovadola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D357",
      "lat": "44.1216054",
      "lon": "11.8874671",
      "superficie_kmq": "38.9702",
      "codice_sovracomunale": "040"
    },
    {
      "sigla_provincia": "FC",
      "codice_istat": "040012",
      "denominazione_ita_altra": "Forlì",
      "denominazione_ita": "Forlì",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "D704",
      "lat": "44.2226730",
      "lon": "12.0406854",
      "superficie_kmq": "228.1972",
      "codice_sovracomunale": "040"
    },
    {
      "sigla_provincia": "FC",
      "codice_istat": "040013",
      "denominazione_ita_altra": "Forlimpopoli",
      "denominazione_ita": "Forlimpopoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D705",
      "lat": "44.1872153",
      "lon": "12.1266370",
      "superficie_kmq": "24.4588",
      "codice_sovracomunale": "040"
    },
    {
      "sigla_provincia": "FC",
      "codice_istat": "040014",
      "denominazione_ita_altra": "Galeata",
      "denominazione_ita": "Galeata",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D867",
      "lat": "43.9964072",
      "lon": "11.9103695",
      "superficie_kmq": "63.1294",
      "codice_sovracomunale": "040"
    },
    {
      "sigla_provincia": "FC",
      "codice_istat": "040015",
      "denominazione_ita_altra": "Gambettola",
      "denominazione_ita": "Gambettola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D899",
      "lat": "44.1199924",
      "lon": "12.3390715",
      "superficie_kmq": "7.7713",
      "codice_sovracomunale": "040"
    },
    {
      "sigla_provincia": "FC",
      "codice_istat": "040016",
      "denominazione_ita_altra": "Gatteo",
      "denominazione_ita": "Gatteo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D935",
      "lat": "44.1096031",
      "lon": "12.3861093",
      "superficie_kmq": "14.1380",
      "codice_sovracomunale": "040"
    },
    {
      "sigla_provincia": "FC",
      "codice_istat": "040018",
      "denominazione_ita_altra": "Longiano",
      "denominazione_ita": "Longiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E675",
      "lat": "44.0740787",
      "lon": "12.3269215",
      "superficie_kmq": "23.5760",
      "codice_sovracomunale": "040"
    },
    {
      "sigla_provincia": "FC",
      "codice_istat": "040019",
      "denominazione_ita_altra": "Meldola",
      "denominazione_ita": "Meldola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F097",
      "lat": "44.1269607",
      "lon": "12.0606161",
      "superficie_kmq": "79.0775",
      "codice_sovracomunale": "040"
    },
    {
      "sigla_provincia": "FC",
      "codice_istat": "040020",
      "denominazione_ita_altra": "Mercato Saraceno",
      "denominazione_ita": "Mercato Saraceno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F139",
      "lat": "43.9569970",
      "lon": "12.1958436",
      "superficie_kmq": "99.3304",
      "codice_sovracomunale": "040"
    },
    {
      "sigla_provincia": "FC",
      "codice_istat": "040022",
      "denominazione_ita_altra": "Modigliana",
      "denominazione_ita": "Modigliana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F259",
      "lat": "44.1562598",
      "lon": "11.7926003",
      "superficie_kmq": "101.1628",
      "codice_sovracomunale": "040"
    },
    {
      "sigla_provincia": "FC",
      "codice_istat": "040028",
      "denominazione_ita_altra": "Montiano",
      "denominazione_ita": "Montiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F668",
      "lat": "44.0834975",
      "lon": "12.3042419",
      "superficie_kmq": "9.2571",
      "codice_sovracomunale": "040"
    },
    {
      "sigla_provincia": "FC",
      "codice_istat": "040031",
      "denominazione_ita_altra": "Portico e San Benedetto",
      "denominazione_ita": "Portico e San Benedetto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G904",
      "lat": "44.0258495",
      "lon": "11.7809188",
      "superficie_kmq": "61.0511",
      "codice_sovracomunale": "040"
    },
    {
      "sigla_provincia": "FC",
      "codice_istat": "040032",
      "denominazione_ita_altra": "Predappio",
      "denominazione_ita": "Predappio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H017",
      "lat": "44.1017999",
      "lon": "11.9805002",
      "superficie_kmq": "91.3883",
      "codice_sovracomunale": "040"
    },
    {
      "sigla_provincia": "FC",
      "codice_istat": "040033",
      "denominazione_ita_altra": "Premilcuore",
      "denominazione_ita": "Premilcuore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H034",
      "lat": "43.9763994",
      "lon": "11.7756923",
      "superficie_kmq": "98.5567",
      "codice_sovracomunale": "040"
    },
    {
      "sigla_provincia": "FC",
      "codice_istat": "040036",
      "denominazione_ita_altra": "Rocca San Casciano",
      "denominazione_ita": "Rocca San Casciano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H437",
      "lat": "44.0614396",
      "lon": "11.8422324",
      "superficie_kmq": "50.5580",
      "codice_sovracomunale": "040"
    },
    {
      "sigla_provincia": "FC",
      "codice_istat": "040037",
      "denominazione_ita_altra": "Roncofreddo",
      "denominazione_ita": "Roncofreddo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H542",
      "lat": "44.0418676",
      "lon": "12.3180857",
      "superficie_kmq": "51.5260",
      "codice_sovracomunale": "040"
    },
    {
      "sigla_provincia": "FC",
      "codice_istat": "040041",
      "denominazione_ita_altra": "San Mauro Pascoli",
      "denominazione_ita": "San Mauro Pascoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I027",
      "lat": "44.1070182",
      "lon": "12.4154914",
      "superficie_kmq": "17.2873",
      "codice_sovracomunale": "040"
    },
    {
      "sigla_provincia": "FC",
      "codice_istat": "040043",
      "denominazione_ita_altra": "Santa Sofia",
      "denominazione_ita": "Santa Sofia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I310",
      "lat": "43.9468820",
      "lon": "11.9079553",
      "superficie_kmq": "148.8602",
      "codice_sovracomunale": "040"
    },
    {
      "sigla_provincia": "FC",
      "codice_istat": "040044",
      "denominazione_ita_altra": "Sarsina",
      "denominazione_ita": "Sarsina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I444",
      "lat": "43.9185663",
      "lon": "12.1408612",
      "superficie_kmq": "100.7182",
      "codice_sovracomunale": "040"
    },
    {
      "sigla_provincia": "FC",
      "codice_istat": "040045",
      "denominazione_ita_altra": "Savignano sul Rubicone",
      "denominazione_ita": "Savignano sul Rubicone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I472",
      "lat": "44.0920840",
      "lon": "12.3990833",
      "superficie_kmq": "23.3027",
      "codice_sovracomunale": "040"
    },
    {
      "sigla_provincia": "FC",
      "codice_istat": "040046",
      "denominazione_ita_altra": "Sogliano al Rubicone",
      "denominazione_ita": "Sogliano al Rubicone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I779",
      "lat": "44.0048669",
      "lon": "12.3001350",
      "superficie_kmq": "93.4291",
      "codice_sovracomunale": "040"
    },
    {
      "sigla_provincia": "FC",
      "codice_istat": "040049",
      "denominazione_ita_altra": "Tredozio",
      "denominazione_ita": "Tredozio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L361",
      "lat": "44.0793392",
      "lon": "11.7437031",
      "superficie_kmq": "62.1988",
      "codice_sovracomunale": "040"
    },
    {
      "sigla_provincia": "FC",
      "codice_istat": "040050",
      "denominazione_ita_altra": "Verghereto",
      "denominazione_ita": "Verghereto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L764",
      "lat": "43.7956295",
      "lon": "12.0058767",
      "superficie_kmq": "117.8823",
      "codice_sovracomunale": "040"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041001",
      "denominazione_ita_altra": "Acqualagna",
      "denominazione_ita": "Acqualagna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A035",
      "lat": "43.6167088",
      "lon": "12.6708705",
      "superficie_kmq": "50.6846",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041002",
      "denominazione_ita_altra": "Apecchio",
      "denominazione_ita": "Apecchio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A327",
      "lat": "43.5582798",
      "lon": "12.4196389",
      "superficie_kmq": "103.1077",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041005",
      "denominazione_ita_altra": "Belforte all'Isauro",
      "denominazione_ita": "Belforte all'Isauro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A740",
      "lat": "43.7169908",
      "lon": "12.3756246",
      "superficie_kmq": "12.2898",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041006",
      "denominazione_ita_altra": "Borgo Pace",
      "denominazione_ita": "Borgo Pace",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B026",
      "lat": "43.6581493",
      "lon": "12.2946183",
      "superficie_kmq": "56.2216",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041007",
      "denominazione_ita_altra": "Cagli",
      "denominazione_ita": "Cagli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B352",
      "lat": "43.5463595",
      "lon": "12.6480136",
      "superficie_kmq": "226.4575",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041008",
      "denominazione_ita_altra": "Cantiano",
      "denominazione_ita": "Cantiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B636",
      "lat": "43.4736345",
      "lon": "12.6272316",
      "superficie_kmq": "83.2457",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041009",
      "denominazione_ita_altra": "Carpegna",
      "denominazione_ita": "Carpegna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B816",
      "lat": "43.7811962",
      "lon": "12.3359932",
      "superficie_kmq": "28.9389",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041010",
      "denominazione_ita_altra": "Cartoceto",
      "denominazione_ita": "Cartoceto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B846",
      "lat": "43.7645620",
      "lon": "12.8837669",
      "superficie_kmq": "23.2029",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041013",
      "denominazione_ita_altra": "Fano",
      "denominazione_ita": "Fano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D488",
      "lat": "43.8433832",
      "lon": "13.0196595",
      "superficie_kmq": "120.3167",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041014",
      "denominazione_ita_altra": "Fermignano",
      "denominazione_ita": "Fermignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D541",
      "lat": "43.6764701",
      "lon": "12.6452801",
      "superficie_kmq": "43.7022",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041015",
      "denominazione_ita_altra": "Fossombrone",
      "denominazione_ita": "Fossombrone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D749",
      "lat": "43.6888324",
      "lon": "12.8057110",
      "superficie_kmq": "106.8757",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041016",
      "denominazione_ita_altra": "Fratte Rosa",
      "denominazione_ita": "Fratte Rosa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D791",
      "lat": "43.6319408",
      "lon": "12.9009725",
      "superficie_kmq": "15.6300",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041017",
      "denominazione_ita_altra": "Frontino",
      "denominazione_ita": "Frontino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D807",
      "lat": "43.7645631",
      "lon": "12.3774372",
      "superficie_kmq": "10.3673",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041018",
      "denominazione_ita_altra": "Frontone",
      "denominazione_ita": "Frontone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D808",
      "lat": "43.5145992",
      "lon": "12.7389091",
      "superficie_kmq": "36.0741",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041019",
      "denominazione_ita_altra": "Gabicce Mare",
      "denominazione_ita": "Gabicce Mare",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D836",
      "lat": "43.9665717",
      "lon": "12.7564792",
      "superficie_kmq": "4.9349",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041020",
      "denominazione_ita_altra": "Gradara",
      "denominazione_ita": "Gradara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E122",
      "lat": "43.9407597",
      "lon": "12.7730036",
      "superficie_kmq": "17.5257",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041021",
      "denominazione_ita_altra": "Isola del Piano",
      "denominazione_ita": "Isola del Piano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E351",
      "lat": "43.7371374",
      "lon": "12.7829328",
      "superficie_kmq": "23.3024",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041022",
      "denominazione_ita_altra": "Lunano",
      "denominazione_ita": "Lunano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E743",
      "lat": "43.7304503",
      "lon": "12.4409850",
      "superficie_kmq": "15.0143",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041023",
      "denominazione_ita_altra": "Macerata Feltria",
      "denominazione_ita": "Macerata Feltria",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E785",
      "lat": "43.8030642",
      "lon": "12.4427742",
      "superficie_kmq": "40.0743",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041025",
      "denominazione_ita_altra": "Mercatello sul Metauro",
      "denominazione_ita": "Mercatello sul Metauro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F135",
      "lat": "43.6474886",
      "lon": "12.3367525",
      "superficie_kmq": "68.3532",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041026",
      "denominazione_ita_altra": "Mercatino Conca",
      "denominazione_ita": "Mercatino Conca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F136",
      "lat": "43.8704090",
      "lon": "12.4935032",
      "superficie_kmq": "13.9454",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041027",
      "denominazione_ita_altra": "Mombaroccio",
      "denominazione_ita": "Mombaroccio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F310",
      "lat": "43.7968182",
      "lon": "12.8548783",
      "superficie_kmq": "28.2059",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041028",
      "denominazione_ita_altra": "Mondavio",
      "denominazione_ita": "Mondavio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F347",
      "lat": "43.6745153",
      "lon": "12.9685999",
      "superficie_kmq": "29.6355",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041029",
      "denominazione_ita_altra": "Mondolfo",
      "denominazione_ita": "Mondolfo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F348",
      "lat": "43.7515001",
      "lon": "13.0954608",
      "superficie_kmq": "24.3413",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041030",
      "denominazione_ita_altra": "Montecalvo in Foglia",
      "denominazione_ita": "Montecalvo in Foglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F450",
      "lat": "43.8115272",
      "lon": "12.6315787",
      "superficie_kmq": "18.2470",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041031",
      "denominazione_ita_altra": "Monte Cerignone",
      "denominazione_ita": "Monte Cerignone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F467",
      "lat": "43.8409706",
      "lon": "12.4126317",
      "superficie_kmq": "18.2355",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041034",
      "denominazione_ita_altra": "Montefelcino",
      "denominazione_ita": "Montefelcino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F497",
      "lat": "43.7350471",
      "lon": "12.8332027",
      "superficie_kmq": "39.0064",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041035",
      "denominazione_ita_altra": "Monte Grimano Terme",
      "denominazione_ita": "Monte Grimano Terme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F524",
      "lat": "43.8654251",
      "lon": "12.4728470",
      "superficie_kmq": "23.9678",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041036",
      "denominazione_ita_altra": "Montelabbate",
      "denominazione_ita": "Montelabbate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F533",
      "lat": "43.8490546",
      "lon": "12.7907613",
      "superficie_kmq": "19.5650",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041038",
      "denominazione_ita_altra": "Monte Porzio",
      "denominazione_ita": "Monte Porzio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F589",
      "lat": "43.6898455",
      "lon": "13.0451839",
      "superficie_kmq": "18.2874",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041041",
      "denominazione_ita_altra": "Peglio",
      "denominazione_ita": "Peglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G416",
      "lat": "43.6960190",
      "lon": "12.4960371",
      "superficie_kmq": "21.3607",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041043",
      "denominazione_ita_altra": "Pergola",
      "denominazione_ita": "Pergola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G453",
      "lat": "43.5628603",
      "lon": "12.8359884",
      "superficie_kmq": "112.3954",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041044",
      "denominazione_ita_altra": "Pesaro",
      "denominazione_ita": "Pesaro",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "G479",
      "lat": "43.9101234",
      "lon": "12.9134588",
      "superficie_kmq": "152.8116",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041045",
      "denominazione_ita_altra": "Petriano",
      "denominazione_ita": "Petriano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G514",
      "lat": "43.7802956",
      "lon": "12.7333529",
      "superficie_kmq": "11.2713",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041047",
      "denominazione_ita_altra": "Piandimeleto",
      "denominazione_ita": "Piandimeleto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G551",
      "lat": "43.7241410",
      "lon": "12.4151338",
      "superficie_kmq": "39.9011",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041048",
      "denominazione_ita_altra": "Pietrarubbia",
      "denominazione_ita": "Pietrarubbia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G627",
      "lat": "43.8041840",
      "lon": "12.3773996",
      "superficie_kmq": "13.2885",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041049",
      "denominazione_ita_altra": "Piobbico",
      "denominazione_ita": "Piobbico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G682",
      "lat": "43.5888025",
      "lon": "12.5103479",
      "superficie_kmq": "48.1975",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041051",
      "denominazione_ita_altra": "San Costanzo",
      "denominazione_ita": "San Costanzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H809",
      "lat": "43.7630991",
      "lon": "13.0695087",
      "superficie_kmq": "40.8860",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041054",
      "denominazione_ita_altra": "San Lorenzo in Campo",
      "denominazione_ita": "San Lorenzo in Campo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H958",
      "lat": "43.6034010",
      "lon": "12.9454320",
      "superficie_kmq": "28.7980",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041057",
      "denominazione_ita_altra": "Sant'Angelo in Vado",
      "denominazione_ita": "Sant'Angelo in Vado",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I287",
      "lat": "43.6648772",
      "lon": "12.4132666",
      "superficie_kmq": "67.3367",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041058",
      "denominazione_ita_altra": "Sant'Ippolito",
      "denominazione_ita": "Sant'Ippolito",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I344",
      "lat": "43.6848064",
      "lon": "12.8710336",
      "superficie_kmq": "19.8757",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041061",
      "denominazione_ita_altra": "Serra Sant'Abbondio",
      "denominazione_ita": "Serra Sant'Abbondio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I654",
      "lat": "43.4908273",
      "lon": "12.7706166",
      "superficie_kmq": "32.8009",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041064",
      "denominazione_ita_altra": "Tavoleto",
      "denominazione_ita": "Tavoleto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L078",
      "lat": "43.8462146",
      "lon": "12.5926362",
      "superficie_kmq": "12.4065",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041065",
      "denominazione_ita_altra": "Tavullia",
      "denominazione_ita": "Tavullia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L081",
      "lat": "43.8979252",
      "lon": "12.7531967",
      "superficie_kmq": "42.0639",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041066",
      "denominazione_ita_altra": "Urbania",
      "denominazione_ita": "Urbania",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L498",
      "lat": "43.6683566",
      "lon": "12.5242662",
      "superficie_kmq": "77.5329",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041067",
      "denominazione_ita_altra": "Urbino",
      "denominazione_ita": "Urbino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L500",
      "lat": "43.7251026",
      "lon": "12.6372978",
      "superficie_kmq": "226.4923",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041068",
      "denominazione_ita_altra": "Vallefoglia",
      "denominazione_ita": "Vallefoglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M331",
      "lat": "43.8276687",
      "lon": "12.7369513",
      "superficie_kmq": "39.5691",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041069",
      "denominazione_ita_altra": "Colli al Metauro",
      "denominazione_ita": "Colli al Metauro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M380",
      "lat": "43.7488690",
      "lon": "12.8989031",
      "superficie_kmq": "46.1679",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041070",
      "denominazione_ita_altra": "Terre Roveresche",
      "denominazione_ita": "Terre Roveresche",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M379",
      "lat": "43.7053736",
      "lon": "12.9591843",
      "superficie_kmq": "70.3655",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "PU",
      "codice_istat": "041071",
      "denominazione_ita_altra": "Sassocorvaro Auditore",
      "denominazione_ita": "Sassocorvaro Auditore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M413",
      "lat": "43.8000414",
      "lon": "12.5231164",
      "superficie_kmq": "87.5458",
      "codice_sovracomunale": "041"
    },
    {
      "sigla_provincia": "AN",
      "codice_istat": "042001",
      "denominazione_ita_altra": "Agugliano",
      "denominazione_ita": "Agugliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A092",
      "lat": "43.5433546",
      "lon": "13.3858022",
      "superficie_kmq": "21.8901",
      "codice_sovracomunale": "042"
    },
    {
      "sigla_provincia": "AN",
      "codice_istat": "042002",
      "denominazione_ita_altra": "Ancona",
      "denominazione_ita": "Ancona",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "A271",
      "lat": "43.6167390",
      "lon": "13.5188738",
      "superficie_kmq": "124.8683",
      "codice_sovracomunale": "042"
    },
    {
      "sigla_provincia": "AN",
      "codice_istat": "042003",
      "denominazione_ita_altra": "Arcevia",
      "denominazione_ita": "Arcevia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A366",
      "lat": "43.4999756",
      "lon": "12.9395971",
      "superficie_kmq": "128.3310",
      "codice_sovracomunale": "042"
    },
    {
      "sigla_provincia": "AN",
      "codice_istat": "042004",
      "denominazione_ita_altra": "Barbara",
      "denominazione_ita": "Barbara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A626",
      "lat": "43.5807791",
      "lon": "13.0247378",
      "superficie_kmq": "11.0386",
      "codice_sovracomunale": "042"
    },
    {
      "sigla_provincia": "AN",
      "codice_istat": "042005",
      "denominazione_ita_altra": "Belvedere Ostrense",
      "denominazione_ita": "Belvedere Ostrense",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A769",
      "lat": "43.5804158",
      "lon": "13.1668226",
      "superficie_kmq": "29.4501",
      "codice_sovracomunale": "042"
    },
    {
      "sigla_provincia": "AN",
      "codice_istat": "042006",
      "denominazione_ita_altra": "Camerano",
      "denominazione_ita": "Camerano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B468",
      "lat": "43.5309056",
      "lon": "13.5530271",
      "superficie_kmq": "20.0041",
      "codice_sovracomunale": "042"
    },
    {
      "sigla_provincia": "AN",
      "codice_istat": "042007",
      "denominazione_ita_altra": "Camerata Picena",
      "denominazione_ita": "Camerata Picena",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B470",
      "lat": "43.5781832",
      "lon": "13.3527525",
      "superficie_kmq": "11.8916",
      "codice_sovracomunale": "042"
    },
    {
      "sigla_provincia": "AN",
      "codice_istat": "042008",
      "denominazione_ita_altra": "Castelbellino",
      "denominazione_ita": "Castelbellino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C060",
      "lat": "43.4878126",
      "lon": "13.1460649",
      "superficie_kmq": "6.0471",
      "codice_sovracomunale": "042"
    },
    {
      "sigla_provincia": "AN",
      "codice_istat": "042010",
      "denominazione_ita_altra": "Castelfidardo",
      "denominazione_ita": "Castelfidardo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C100",
      "lat": "43.4645035",
      "lon": "13.5465946",
      "superficie_kmq": "33.3901",
      "codice_sovracomunale": "042"
    },
    {
      "sigla_provincia": "AN",
      "codice_istat": "042011",
      "denominazione_ita_altra": "Castelleone di Suasa",
      "denominazione_ita": "Castelleone di Suasa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C152",
      "lat": "43.6072219",
      "lon": "12.9767670",
      "superficie_kmq": "15.9198",
      "codice_sovracomunale": "042"
    },
    {
      "sigla_provincia": "AN",
      "codice_istat": "042012",
      "denominazione_ita_altra": "Castelplanio",
      "denominazione_ita": "Castelplanio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C248",
      "lat": "43.4945667",
      "lon": "13.0813510",
      "superficie_kmq": "15.3181",
      "codice_sovracomunale": "042"
    },
    {
      "sigla_provincia": "AN",
      "codice_istat": "042013",
      "denominazione_ita_altra": "Cerreto d'Esi",
      "denominazione_ita": "Cerreto d'Esi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C524",
      "lat": "43.3207154",
      "lon": "12.9851517",
      "superficie_kmq": "16.9129",
      "codice_sovracomunale": "042"
    },
    {
      "sigla_provincia": "AN",
      "codice_istat": "042014",
      "denominazione_ita_altra": "Chiaravalle",
      "denominazione_ita": "Chiaravalle",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C615",
      "lat": "43.5997118",
      "lon": "13.3255503",
      "superficie_kmq": "17.6020",
      "codice_sovracomunale": "042"
    },
    {
      "sigla_provincia": "AN",
      "codice_istat": "042015",
      "denominazione_ita_altra": "Corinaldo",
      "denominazione_ita": "Corinaldo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D007",
      "lat": "43.6488281",
      "lon": "13.0477262",
      "superficie_kmq": "49.2789",
      "codice_sovracomunale": "042"
    },
    {
      "sigla_provincia": "AN",
      "codice_istat": "042016",
      "denominazione_ita_altra": "Cupramontana",
      "denominazione_ita": "Cupramontana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D211",
      "lat": "43.4455294",
      "lon": "13.1156713",
      "superficie_kmq": "27.4029",
      "codice_sovracomunale": "042"
    },
    {
      "sigla_provincia": "AN",
      "codice_istat": "042017",
      "denominazione_ita_altra": "Fabriano",
      "denominazione_ita": "Fabriano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D451",
      "lat": "43.3362434",
      "lon": "12.9047222",
      "superficie_kmq": "272.0776",
      "codice_sovracomunale": "042"
    },
    {
      "sigla_provincia": "AN",
      "codice_istat": "042018",
      "denominazione_ita_altra": "Falconara Marittima",
      "denominazione_ita": "Falconara Marittima",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D472",
      "lat": "43.6232703",
      "lon": "13.3939252",
      "superficie_kmq": "25.8148",
      "codice_sovracomunale": "042"
    },
    {
      "sigla_provincia": "AN",
      "codice_istat": "042019",
      "denominazione_ita_altra": "Filottrano",
      "denominazione_ita": "Filottrano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D597",
      "lat": "43.4356539",
      "lon": "13.3512814",
      "superficie_kmq": "71.2015",
      "codice_sovracomunale": "042"
    },
    {
      "sigla_provincia": "AN",
      "codice_istat": "042020",
      "denominazione_ita_altra": "Genga",
      "denominazione_ita": "Genga",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D965",
      "lat": "43.4295753",
      "lon": "12.9351022",
      "superficie_kmq": "73.1543",
      "codice_sovracomunale": "042"
    },
    {
      "sigla_provincia": "AN",
      "codice_istat": "042021",
      "denominazione_ita_altra": "Jesi",
      "denominazione_ita": "Jesi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E388",
      "lat": "43.5227970",
      "lon": "13.2438253",
      "superficie_kmq": "108.9032",
      "codice_sovracomunale": "042"
    },
    {
      "sigla_provincia": "AN",
      "codice_istat": "042022",
      "denominazione_ita_altra": "Loreto",
      "denominazione_ita": "Loreto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E690",
      "lat": "43.4402603",
      "lon": "13.6079270",
      "superficie_kmq": "17.8954",
      "codice_sovracomunale": "042"
    },
    {
      "sigla_provincia": "AN",
      "codice_istat": "042023",
      "denominazione_ita_altra": "Maiolati Spontini",
      "denominazione_ita": "Maiolati Spontini",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E837",
      "lat": "43.4773147",
      "lon": "13.1191043",
      "superficie_kmq": "21.4923",
      "codice_sovracomunale": "042"
    },
    {
      "sigla_provincia": "AN",
      "codice_istat": "042024",
      "denominazione_ita_altra": "Mergo",
      "denominazione_ita": "Mergo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F145",
      "lat": "43.4714014",
      "lon": "13.0373856",
      "superficie_kmq": "7.2775",
      "codice_sovracomunale": "042"
    },
    {
      "sigla_provincia": "AN",
      "codice_istat": "042025",
      "denominazione_ita_altra": "Monsano",
      "denominazione_ita": "Monsano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F381",
      "lat": "43.5634614",
      "lon": "13.2503073",
      "superficie_kmq": "14.6644",
      "codice_sovracomunale": "042"
    },
    {
      "sigla_provincia": "AN",
      "codice_istat": "042026",
      "denominazione_ita_altra": "Montecarotto",
      "denominazione_ita": "Montecarotto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F453",
      "lat": "43.5263416",
      "lon": "13.0645257",
      "superficie_kmq": "24.3854",
      "codice_sovracomunale": "042"
    },
    {
      "sigla_provincia": "AN",
      "codice_istat": "042027",
      "denominazione_ita_altra": "Montemarciano",
      "denominazione_ita": "Montemarciano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F560",
      "lat": "43.6400648",
      "lon": "13.3102266",
      "superficie_kmq": "22.3106",
      "codice_sovracomunale": "042"
    },
    {
      "sigla_provincia": "AN",
      "codice_istat": "042029",
      "denominazione_ita_altra": "Monte Roberto",
      "denominazione_ita": "Monte Roberto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F600",
      "lat": "43.4814171",
      "lon": "13.1385117",
      "superficie_kmq": "13.5694",
      "codice_sovracomunale": "042"
    },
    {
      "sigla_provincia": "AN",
      "codice_istat": "042030",
      "denominazione_ita_altra": "Monte San Vito",
      "denominazione_ita": "Monte San Vito",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F634",
      "lat": "43.6005627",
      "lon": "13.2709708",
      "superficie_kmq": "21.8135",
      "codice_sovracomunale": "042"
    },
    {
      "sigla_provincia": "AN",
      "codice_istat": "042031",
      "denominazione_ita_altra": "Morro d'Alba",
      "denominazione_ita": "Morro d'Alba",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F745",
      "lat": "43.6011086",
      "lon": "13.2140516",
      "superficie_kmq": "19.4605",
      "codice_sovracomunale": "042"
    },
    {
      "sigla_provincia": "AN",
      "codice_istat": "042032",
      "denominazione_ita_altra": "Numana",
      "denominazione_ita": "Numana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F978",
      "lat": "43.5134498",
      "lon": "13.6215119",
      "superficie_kmq": "10.9349",
      "codice_sovracomunale": "042"
    },
    {
      "sigla_provincia": "AN",
      "codice_istat": "042033",
      "denominazione_ita_altra": "Offagna",
      "denominazione_ita": "Offagna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G003",
      "lat": "43.5272342",
      "lon": "13.4414789",
      "superficie_kmq": "10.6305",
      "codice_sovracomunale": "042"
    },
    {
      "sigla_provincia": "AN",
      "codice_istat": "042034",
      "denominazione_ita_altra": "Osimo",
      "denominazione_ita": "Osimo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G157",
      "lat": "43.4861663",
      "lon": "13.4819051",
      "superficie_kmq": "106.7377",
      "codice_sovracomunale": "042"
    },
    {
      "sigla_provincia": "AN",
      "codice_istat": "042035",
      "denominazione_ita_altra": "Ostra",
      "denominazione_ita": "Ostra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F401",
      "lat": "43.6126600",
      "lon": "13.1583871",
      "superficie_kmq": "47.2517",
      "codice_sovracomunale": "042"
    },
    {
      "sigla_provincia": "AN",
      "codice_istat": "042036",
      "denominazione_ita_altra": "Ostra Vetere",
      "denominazione_ita": "Ostra Vetere",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F581",
      "lat": "43.6036926",
      "lon": "13.0573619",
      "superficie_kmq": "30.0164",
      "codice_sovracomunale": "042"
    },
    {
      "sigla_provincia": "AN",
      "codice_istat": "042037",
      "denominazione_ita_altra": "Poggio San Marcello",
      "denominazione_ita": "Poggio San Marcello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G771",
      "lat": "43.5115740",
      "lon": "13.0770490",
      "superficie_kmq": "13.3629",
      "codice_sovracomunale": "042"
    },
    {
      "sigla_provincia": "AN",
      "codice_istat": "042038",
      "denominazione_ita_altra": "Polverigi",
      "denominazione_ita": "Polverigi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G803",
      "lat": "43.5251834",
      "lon": "13.3941208",
      "superficie_kmq": "24.9832",
      "codice_sovracomunale": "042"
    },
    {
      "sigla_provincia": "AN",
      "codice_istat": "042040",
      "denominazione_ita_altra": "Rosora",
      "denominazione_ita": "Rosora",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H575",
      "lat": "43.4827323",
      "lon": "13.0691910",
      "superficie_kmq": "9.4061",
      "codice_sovracomunale": "042"
    },
    {
      "sigla_provincia": "AN",
      "codice_istat": "042041",
      "denominazione_ita_altra": "San Marcello",
      "denominazione_ita": "San Marcello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H979",
      "lat": "43.5767174",
      "lon": "13.2062068",
      "superficie_kmq": "25.7778",
      "codice_sovracomunale": "042"
    },
    {
      "sigla_provincia": "AN",
      "codice_istat": "042042",
      "denominazione_ita_altra": "San Paolo di Jesi",
      "denominazione_ita": "San Paolo di Jesi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I071",
      "lat": "43.4548576",
      "lon": "13.1735666",
      "superficie_kmq": "10.1118",
      "codice_sovracomunale": "042"
    },
    {
      "sigla_provincia": "AN",
      "codice_istat": "042043",
      "denominazione_ita_altra": "Santa Maria Nuova",
      "denominazione_ita": "Santa Maria Nuova",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I251",
      "lat": "43.4959218",
      "lon": "13.3105321",
      "superficie_kmq": "18.2869",
      "codice_sovracomunale": "042"
    },
    {
      "sigla_provincia": "AN",
      "codice_istat": "042044",
      "denominazione_ita_altra": "Sassoferrato",
      "denominazione_ita": "Sassoferrato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I461",
      "lat": "43.4359751",
      "lon": "12.8586508",
      "superficie_kmq": "137.2273",
      "codice_sovracomunale": "042"
    },
    {
      "sigla_provincia": "AN",
      "codice_istat": "042045",
      "denominazione_ita_altra": "Senigallia",
      "denominazione_ita": "Senigallia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I608",
      "lat": "43.7148084",
      "lon": "13.2177788",
      "superficie_kmq": "117.7689",
      "codice_sovracomunale": "042"
    },
    {
      "sigla_provincia": "AN",
      "codice_istat": "042046",
      "denominazione_ita_altra": "Serra de' Conti",
      "denominazione_ita": "Serra de' Conti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I643",
      "lat": "43.5425575",
      "lon": "13.0377712",
      "superficie_kmq": "24.5430",
      "codice_sovracomunale": "042"
    },
    {
      "sigla_provincia": "AN",
      "codice_istat": "042047",
      "denominazione_ita_altra": "Serra San Quirico",
      "denominazione_ita": "Serra San Quirico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I653",
      "lat": "43.4475564",
      "lon": "13.0148311",
      "superficie_kmq": "49.3330",
      "codice_sovracomunale": "042"
    },
    {
      "sigla_provincia": "AN",
      "codice_istat": "042048",
      "denominazione_ita_altra": "Sirolo",
      "denominazione_ita": "Sirolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I758",
      "lat": "43.5259967",
      "lon": "13.6160384",
      "superficie_kmq": "16.6769",
      "codice_sovracomunale": "042"
    },
    {
      "sigla_provincia": "AN",
      "codice_istat": "042049",
      "denominazione_ita_altra": "Staffolo",
      "denominazione_ita": "Staffolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I932",
      "lat": "43.4328371",
      "lon": "13.1863169",
      "superficie_kmq": "27.4964",
      "codice_sovracomunale": "042"
    },
    {
      "sigla_provincia": "AN",
      "codice_istat": "042050",
      "denominazione_ita_altra": "Trecastelli",
      "denominazione_ita": "Trecastelli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M318",
      "lat": "43.6858578",
      "lon": "13.1075242",
      "superficie_kmq": "39.3019",
      "codice_sovracomunale": "042"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043002",
      "denominazione_ita_altra": "Apiro",
      "denominazione_ita": "Apiro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A329",
      "lat": "43.3924489",
      "lon": "13.1310117",
      "superficie_kmq": "53.7792",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043003",
      "denominazione_ita_altra": "Appignano",
      "denominazione_ita": "Appignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A334",
      "lat": "43.3640451",
      "lon": "13.3462364",
      "superficie_kmq": "22.6722",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043004",
      "denominazione_ita_altra": "Belforte del Chienti",
      "denominazione_ita": "Belforte del Chienti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A739",
      "lat": "43.1642271",
      "lon": "13.2377232",
      "superficie_kmq": "16.0545",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043005",
      "denominazione_ita_altra": "Bolognola",
      "denominazione_ita": "Bolognola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A947",
      "lat": "42.9942310",
      "lon": "13.2268443",
      "superficie_kmq": "25.8647",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043006",
      "denominazione_ita_altra": "Caldarola",
      "denominazione_ita": "Caldarola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B398",
      "lat": "43.1384268",
      "lon": "13.2262685",
      "superficie_kmq": "29.2218",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043007",
      "denominazione_ita_altra": "Camerino",
      "denominazione_ita": "Camerino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B474",
      "lat": "43.1353366",
      "lon": "13.0676612",
      "superficie_kmq": "129.8821",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043008",
      "denominazione_ita_altra": "Camporotondo di Fiastrone",
      "denominazione_ita": "Camporotondo di Fiastrone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B562",
      "lat": "43.1317679",
      "lon": "13.2655238",
      "superficie_kmq": "8.8058",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043009",
      "denominazione_ita_altra": "Castelraimondo",
      "denominazione_ita": "Castelraimondo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C251",
      "lat": "43.2099756",
      "lon": "13.0576979",
      "superficie_kmq": "44.8463",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043010",
      "denominazione_ita_altra": "Castelsantangelo sul Nera",
      "denominazione_ita": "Castelsantangelo sul Nera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C267",
      "lat": "42.8944433",
      "lon": "13.1530740",
      "superficie_kmq": "70.6713",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043011",
      "denominazione_ita_altra": "Cessapalombo",
      "denominazione_ita": "Cessapalombo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C582",
      "lat": "43.1090721",
      "lon": "13.2584077",
      "superficie_kmq": "27.5803",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043012",
      "denominazione_ita_altra": "Cingoli",
      "denominazione_ita": "Cingoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C704",
      "lat": "43.3742563",
      "lon": "13.2164666",
      "superficie_kmq": "148.1969",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043013",
      "denominazione_ita_altra": "Civitanova Marche",
      "denominazione_ita": "Civitanova Marche",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C770",
      "lat": "43.3066568",
      "lon": "13.7280280",
      "superficie_kmq": "46.0658",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043014",
      "denominazione_ita_altra": "Colmurano",
      "denominazione_ita": "Colmurano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C886",
      "lat": "43.1638441",
      "lon": "13.3567034",
      "superficie_kmq": "11.1987",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043015",
      "denominazione_ita_altra": "Corridonia",
      "denominazione_ita": "Corridonia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D042",
      "lat": "43.2481590",
      "lon": "13.5088662",
      "superficie_kmq": "61.9643",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043016",
      "denominazione_ita_altra": "Esanatoglia",
      "denominazione_ita": "Esanatoglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D429",
      "lat": "43.2514296",
      "lon": "12.9461674",
      "superficie_kmq": "47.9090",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043017",
      "denominazione_ita_altra": "Fiastra",
      "denominazione_ita": "Fiastra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D564",
      "lat": "43.0357431",
      "lon": "13.1560052",
      "superficie_kmq": "84.4799",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043019",
      "denominazione_ita_altra": "Fiuminata",
      "denominazione_ita": "Fiuminata",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D628",
      "lat": "43.1886170",
      "lon": "12.9316810",
      "superficie_kmq": "76.2168",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043020",
      "denominazione_ita_altra": "Gagliole",
      "denominazione_ita": "Gagliole",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D853",
      "lat": "43.2394353",
      "lon": "13.0680812",
      "superficie_kmq": "24.0501",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043021",
      "denominazione_ita_altra": "Gualdo",
      "denominazione_ita": "Gualdo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E228",
      "lat": "43.0673302",
      "lon": "13.3379417",
      "superficie_kmq": "22.2234",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043022",
      "denominazione_ita_altra": "Loro Piceno",
      "denominazione_ita": "Loro Piceno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E694",
      "lat": "43.1660753",
      "lon": "13.4161895",
      "superficie_kmq": "32.5783",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043023",
      "denominazione_ita_altra": "Macerata",
      "denominazione_ita": "Macerata",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "E783",
      "lat": "43.3002138",
      "lon": "13.4530700",
      "superficie_kmq": "92.5263",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043024",
      "denominazione_ita_altra": "Matelica",
      "denominazione_ita": "Matelica",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F051",
      "lat": "43.2565875",
      "lon": "13.0095161",
      "superficie_kmq": "81.1000",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043025",
      "denominazione_ita_altra": "Mogliano",
      "denominazione_ita": "Mogliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F268",
      "lat": "43.1859764",
      "lon": "13.4808207",
      "superficie_kmq": "29.2582",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043026",
      "denominazione_ita_altra": "Montecassiano",
      "denominazione_ita": "Montecassiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F454",
      "lat": "43.3637291",
      "lon": "13.4359356",
      "superficie_kmq": "33.3569",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043027",
      "denominazione_ita_altra": "Monte Cavallo",
      "denominazione_ita": "Monte Cavallo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F460",
      "lat": "42.9935330",
      "lon": "13.0011551",
      "superficie_kmq": "38.5079",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043028",
      "denominazione_ita_altra": "Montecosaro",
      "denominazione_ita": "Montecosaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F482",
      "lat": "43.3168216",
      "lon": "13.6352507",
      "superficie_kmq": "21.8779",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043029",
      "denominazione_ita_altra": "Montefano",
      "denominazione_ita": "Montefano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F496",
      "lat": "43.4118948",
      "lon": "13.4397496",
      "superficie_kmq": "33.9412",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043030",
      "denominazione_ita_altra": "Montelupone",
      "denominazione_ita": "Montelupone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F552",
      "lat": "43.3443602",
      "lon": "13.5671218",
      "superficie_kmq": "32.6715",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043031",
      "denominazione_ita_altra": "Monte San Giusto",
      "denominazione_ita": "Monte San Giusto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F621",
      "lat": "43.2359884",
      "lon": "13.5942744",
      "superficie_kmq": "20.0369",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043032",
      "denominazione_ita_altra": "Monte San Martino",
      "denominazione_ita": "Monte San Martino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F622",
      "lat": "43.0311429",
      "lon": "13.4393690",
      "superficie_kmq": "18.4669",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043033",
      "denominazione_ita_altra": "Morrovalle",
      "denominazione_ita": "Morrovalle",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F749",
      "lat": "43.3146636",
      "lon": "13.5800242",
      "superficie_kmq": "42.5803",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043034",
      "denominazione_ita_altra": "Muccia",
      "denominazione_ita": "Muccia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F793",
      "lat": "43.0820663",
      "lon": "13.0440499",
      "superficie_kmq": "25.9085",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043035",
      "denominazione_ita_altra": "Penna San Giovanni",
      "denominazione_ita": "Penna San Giovanni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G436",
      "lat": "43.0565456",
      "lon": "13.4237424",
      "superficie_kmq": "28.0835",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043036",
      "denominazione_ita_altra": "Petriolo",
      "denominazione_ita": "Petriolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G515",
      "lat": "43.2210000",
      "lon": "13.4656263",
      "superficie_kmq": "15.6459",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043038",
      "denominazione_ita_altra": "Pieve Torina",
      "denominazione_ita": "Pieve Torina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G657",
      "lat": "43.0442989",
      "lon": "13.0503853",
      "superficie_kmq": "74.7979",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043039",
      "denominazione_ita_altra": "Pioraco",
      "denominazione_ita": "Pioraco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G690",
      "lat": "43.1787552",
      "lon": "12.9848424",
      "superficie_kmq": "19.4522",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043040",
      "denominazione_ita_altra": "Poggio San Vicino",
      "denominazione_ita": "Poggio San Vicino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D566",
      "lat": "43.3746855",
      "lon": "13.0788374",
      "superficie_kmq": "13.0308",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043041",
      "denominazione_ita_altra": "Pollenza",
      "denominazione_ita": "Pollenza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F567",
      "lat": "43.2677962",
      "lon": "13.3476021",
      "superficie_kmq": "39.5473",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043042",
      "denominazione_ita_altra": "Porto Recanati",
      "denominazione_ita": "Porto Recanati",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G919",
      "lat": "43.4298113",
      "lon": "13.6648926",
      "superficie_kmq": "17.2489",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043043",
      "denominazione_ita_altra": "Potenza Picena",
      "denominazione_ita": "Potenza Picena",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F632",
      "lat": "43.3662334",
      "lon": "13.6202567",
      "superficie_kmq": "48.5522",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043044",
      "denominazione_ita_altra": "Recanati",
      "denominazione_ita": "Recanati",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H211",
      "lat": "43.4038105",
      "lon": "13.5488358",
      "superficie_kmq": "103.4588",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043045",
      "denominazione_ita_altra": "Ripe San Ginesio",
      "denominazione_ita": "Ripe San Ginesio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H323",
      "lat": "43.1430211",
      "lon": "13.3674937",
      "superficie_kmq": "10.1694",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043046",
      "denominazione_ita_altra": "San Ginesio",
      "denominazione_ita": "San Ginesio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H876",
      "lat": "43.1079828",
      "lon": "13.3167269",
      "superficie_kmq": "78.0195",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043047",
      "denominazione_ita_altra": "San Severino Marche",
      "denominazione_ita": "San Severino Marche",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I156",
      "lat": "43.2288309",
      "lon": "13.1771113",
      "superficie_kmq": "194.2547",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043048",
      "denominazione_ita_altra": "Sant'Angelo in Pontano",
      "denominazione_ita": "Sant'Angelo in Pontano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I286",
      "lat": "43.0992628",
      "lon": "13.3954761",
      "superficie_kmq": "27.3837",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043049",
      "denominazione_ita_altra": "Sarnano",
      "denominazione_ita": "Sarnano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I436",
      "lat": "43.0354476",
      "lon": "13.3010647",
      "superficie_kmq": "63.1659",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043050",
      "denominazione_ita_altra": "Sefro",
      "denominazione_ita": "Sefro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I569",
      "lat": "43.1462185",
      "lon": "12.9492539",
      "superficie_kmq": "42.5370",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043051",
      "denominazione_ita_altra": "Serrapetrona",
      "denominazione_ita": "Serrapetrona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I651",
      "lat": "43.1768922",
      "lon": "13.1893710",
      "superficie_kmq": "37.6468",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043052",
      "denominazione_ita_altra": "Serravalle di Chienti",
      "denominazione_ita": "Serravalle di Chienti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I661",
      "lat": "43.0718174",
      "lon": "12.9520207",
      "superficie_kmq": "95.9871",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043053",
      "denominazione_ita_altra": "Tolentino",
      "denominazione_ita": "Tolentino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L191",
      "lat": "43.2086997",
      "lon": "13.2842980",
      "superficie_kmq": "95.1181",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043054",
      "denominazione_ita_altra": "Treia",
      "denominazione_ita": "Treia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L366",
      "lat": "43.3112549",
      "lon": "13.3123024",
      "superficie_kmq": "93.5356",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043055",
      "denominazione_ita_altra": "Urbisaglia",
      "denominazione_ita": "Urbisaglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L501",
      "lat": "43.1970172",
      "lon": "13.3767884",
      "superficie_kmq": "22.8633",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043056",
      "denominazione_ita_altra": "Ussita",
      "denominazione_ita": "Ussita",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L517",
      "lat": "42.9440529",
      "lon": "13.1384546",
      "superficie_kmq": "55.2948",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043057",
      "denominazione_ita_altra": "Visso",
      "denominazione_ita": "Visso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M078",
      "lat": "42.9307271",
      "lon": "13.0883056",
      "superficie_kmq": "100.4148",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "MC",
      "codice_istat": "043058",
      "denominazione_ita_altra": "Valfornace",
      "denominazione_ita": "Valfornace",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M382",
      "lat": "43.0546210",
      "lon": "13.1122565",
      "superficie_kmq": "48.6127",
      "codice_sovracomunale": "043"
    },
    {
      "sigla_provincia": "AP",
      "codice_istat": "044001",
      "denominazione_ita_altra": "Acquasanta Terme",
      "denominazione_ita": "Acquasanta Terme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A044",
      "lat": "42.7690896",
      "lon": "13.4093022",
      "superficie_kmq": "138.3914",
      "codice_sovracomunale": "044"
    },
    {
      "sigla_provincia": "AP",
      "codice_istat": "044002",
      "denominazione_ita_altra": "Acquaviva Picena",
      "denominazione_ita": "Acquaviva Picena",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A047",
      "lat": "42.9447002",
      "lon": "13.8146411",
      "superficie_kmq": "21.0624",
      "codice_sovracomunale": "044"
    },
    {
      "sigla_provincia": "AP",
      "codice_istat": "044005",
      "denominazione_ita_altra": "Appignano del Tronto",
      "denominazione_ita": "Appignano del Tronto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A335",
      "lat": "42.8990550",
      "lon": "13.6660396",
      "superficie_kmq": "23.1943",
      "codice_sovracomunale": "044"
    },
    {
      "sigla_provincia": "AP",
      "codice_istat": "044006",
      "denominazione_ita_altra": "Arquata del Tronto",
      "denominazione_ita": "Arquata del Tronto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A437",
      "lat": "42.7726076",
      "lon": "13.2967706",
      "superficie_kmq": "92.1821",
      "codice_sovracomunale": "044"
    },
    {
      "sigla_provincia": "AP",
      "codice_istat": "044007",
      "denominazione_ita_altra": "Ascoli Piceno",
      "denominazione_ita": "Ascoli Piceno",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "A462",
      "lat": "42.8531911",
      "lon": "13.5769090",
      "superficie_kmq": "158.0203",
      "codice_sovracomunale": "044"
    },
    {
      "sigla_provincia": "AP",
      "codice_istat": "044010",
      "denominazione_ita_altra": "Carassai",
      "denominazione_ita": "Carassai",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B727",
      "lat": "43.0321180",
      "lon": "13.6837282",
      "superficie_kmq": "22.2382",
      "codice_sovracomunale": "044"
    },
    {
      "sigla_provincia": "AP",
      "codice_istat": "044011",
      "denominazione_ita_altra": "Castel di Lama",
      "denominazione_ita": "Castel di Lama",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C093",
      "lat": "42.8688814",
      "lon": "13.7128933",
      "superficie_kmq": "10.9762",
      "codice_sovracomunale": "044"
    },
    {
      "sigla_provincia": "AP",
      "codice_istat": "044012",
      "denominazione_ita_altra": "Castignano",
      "denominazione_ita": "Castignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C321",
      "lat": "42.9380320",
      "lon": "13.6215543",
      "superficie_kmq": "38.7952",
      "codice_sovracomunale": "044"
    },
    {
      "sigla_provincia": "AP",
      "codice_istat": "044013",
      "denominazione_ita_altra": "Castorano",
      "denominazione_ita": "Castorano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C331",
      "lat": "42.8983024",
      "lon": "13.7276201",
      "superficie_kmq": "14.0833",
      "codice_sovracomunale": "044"
    },
    {
      "sigla_provincia": "AP",
      "codice_istat": "044014",
      "denominazione_ita_altra": "Colli del Tronto",
      "denominazione_ita": "Colli del Tronto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C877",
      "lat": "42.8779784",
      "lon": "13.7481570",
      "superficie_kmq": "5.9390",
      "codice_sovracomunale": "044"
    },
    {
      "sigla_provincia": "AP",
      "codice_istat": "044015",
      "denominazione_ita_altra": "Comunanza",
      "denominazione_ita": "Comunanza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C935",
      "lat": "42.9575529",
      "lon": "13.4139156",
      "superficie_kmq": "54.3954",
      "codice_sovracomunale": "044"
    },
    {
      "sigla_provincia": "AP",
      "codice_istat": "044016",
      "denominazione_ita_altra": "Cossignano",
      "denominazione_ita": "Cossignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D096",
      "lat": "42.9841180",
      "lon": "13.6879079",
      "superficie_kmq": "14.9507",
      "codice_sovracomunale": "044"
    },
    {
      "sigla_provincia": "AP",
      "codice_istat": "044017",
      "denominazione_ita_altra": "Cupra Marittima",
      "denominazione_ita": "Cupra Marittima",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D210",
      "lat": "43.0250583",
      "lon": "13.8590131",
      "superficie_kmq": "17.3366",
      "codice_sovracomunale": "044"
    },
    {
      "sigla_provincia": "AP",
      "codice_istat": "044020",
      "denominazione_ita_altra": "Folignano",
      "denominazione_ita": "Folignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D652",
      "lat": "42.8210075",
      "lon": "13.6325818",
      "superficie_kmq": "14.8568",
      "codice_sovracomunale": "044"
    },
    {
      "sigla_provincia": "AP",
      "codice_istat": "044021",
      "denominazione_ita_altra": "Force",
      "denominazione_ita": "Force",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D691",
      "lat": "42.9629737",
      "lon": "13.4910459",
      "superficie_kmq": "34.3072",
      "codice_sovracomunale": "044"
    },
    {
      "sigla_provincia": "AP",
      "codice_istat": "044023",
      "denominazione_ita_altra": "Grottammare",
      "denominazione_ita": "Grottammare",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E207",
      "lat": "42.9884340",
      "lon": "13.8683388",
      "superficie_kmq": "17.9947",
      "codice_sovracomunale": "044"
    },
    {
      "sigla_provincia": "AP",
      "codice_istat": "044027",
      "denominazione_ita_altra": "Maltignano",
      "denominazione_ita": "Maltignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E868",
      "lat": "42.8316002",
      "lon": "13.6871760",
      "superficie_kmq": "8.1726",
      "codice_sovracomunale": "044"
    },
    {
      "sigla_provincia": "AP",
      "codice_istat": "044029",
      "denominazione_ita_altra": "Massignano",
      "denominazione_ita": "Massignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F044",
      "lat": "43.0508282",
      "lon": "13.7970219",
      "superficie_kmq": "16.3038",
      "codice_sovracomunale": "044"
    },
    {
      "sigla_provincia": "AP",
      "codice_istat": "044031",
      "denominazione_ita_altra": "Monsampolo del Tronto",
      "denominazione_ita": "Monsampolo del Tronto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F380",
      "lat": "42.8974386",
      "lon": "13.7909508",
      "superficie_kmq": "15.4334",
      "codice_sovracomunale": "044"
    },
    {
      "sigla_provincia": "AP",
      "codice_istat": "044032",
      "denominazione_ita_altra": "Montalto delle Marche",
      "denominazione_ita": "Montalto delle Marche",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F415",
      "lat": "42.9873383",
      "lon": "13.6071308",
      "superficie_kmq": "33.9400",
      "codice_sovracomunale": "044"
    },
    {
      "sigla_provincia": "AP",
      "codice_istat": "044034",
      "denominazione_ita_altra": "Montedinove",
      "denominazione_ita": "Montedinove",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F487",
      "lat": "42.9707143",
      "lon": "13.5869646",
      "superficie_kmq": "11.9333",
      "codice_sovracomunale": "044"
    },
    {
      "sigla_provincia": "AP",
      "codice_istat": "044036",
      "denominazione_ita_altra": "Montefiore dell'Aso",
      "denominazione_ita": "Montefiore dell'Aso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F501",
      "lat": "43.0515871",
      "lon": "13.7508962",
      "superficie_kmq": "28.2074",
      "codice_sovracomunale": "044"
    },
    {
      "sigla_provincia": "AP",
      "codice_istat": "044038",
      "denominazione_ita_altra": "Montegallo",
      "denominazione_ita": "Montegallo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F516",
      "lat": "42.8421628",
      "lon": "13.3329888",
      "superficie_kmq": "48.4639",
      "codice_sovracomunale": "044"
    },
    {
      "sigla_provincia": "AP",
      "codice_istat": "044044",
      "denominazione_ita_altra": "Montemonaco",
      "denominazione_ita": "Montemonaco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F570",
      "lat": "42.8994640",
      "lon": "13.3269576",
      "superficie_kmq": "67.7926",
      "codice_sovracomunale": "044"
    },
    {
      "sigla_provincia": "AP",
      "codice_istat": "044045",
      "denominazione_ita_altra": "Monteprandone",
      "denominazione_ita": "Monteprandone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F591",
      "lat": "42.9200686",
      "lon": "13.8357344",
      "superficie_kmq": "26.3736",
      "codice_sovracomunale": "044"
    },
    {
      "sigla_provincia": "AP",
      "codice_istat": "044054",
      "denominazione_ita_altra": "Offida",
      "denominazione_ita": "Offida",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G005",
      "lat": "42.9351691",
      "lon": "13.6919287",
      "superficie_kmq": "49.6025",
      "codice_sovracomunale": "044"
    },
    {
      "sigla_provincia": "AP",
      "codice_istat": "044056",
      "denominazione_ita_altra": "Palmiano",
      "denominazione_ita": "Palmiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G289",
      "lat": "42.8992889",
      "lon": "13.4585508",
      "superficie_kmq": "12.6977",
      "codice_sovracomunale": "044"
    },
    {
      "sigla_provincia": "AP",
      "codice_istat": "044063",
      "denominazione_ita_altra": "Ripatransone",
      "denominazione_ita": "Ripatransone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H321",
      "lat": "43.0001367",
      "lon": "13.7626448",
      "superficie_kmq": "74.2773",
      "codice_sovracomunale": "044"
    },
    {
      "sigla_provincia": "AP",
      "codice_istat": "044064",
      "denominazione_ita_altra": "Roccafluvione",
      "denominazione_ita": "Roccafluvione",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H390",
      "lat": "42.8612355",
      "lon": "13.4752134",
      "superficie_kmq": "60.6319",
      "codice_sovracomunale": "044"
    },
    {
      "sigla_provincia": "AP",
      "codice_istat": "044065",
      "denominazione_ita_altra": "Rotella",
      "denominazione_ita": "Rotella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H588",
      "lat": "42.9544968",
      "lon": "13.5609301",
      "superficie_kmq": "27.4373",
      "codice_sovracomunale": "044"
    },
    {
      "sigla_provincia": "AP",
      "codice_istat": "044066",
      "denominazione_ita_altra": "San Benedetto del Tronto",
      "denominazione_ita": "San Benedetto del Tronto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H769",
      "lat": "42.9436023",
      "lon": "13.8829086",
      "superficie_kmq": "25.4053",
      "codice_sovracomunale": "044"
    },
    {
      "sigla_provincia": "AP",
      "codice_istat": "044071",
      "denominazione_ita_altra": "Spinetoli",
      "denominazione_ita": "Spinetoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I912",
      "lat": "42.8893074",
      "lon": "13.7734080",
      "superficie_kmq": "12.5824",
      "codice_sovracomunale": "044"
    },
    {
      "sigla_provincia": "AP",
      "codice_istat": "044073",
      "denominazione_ita_altra": "Venarotta",
      "denominazione_ita": "Venarotta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L728",
      "lat": "42.8811534",
      "lon": "13.4928206",
      "superficie_kmq": "30.2096",
      "codice_sovracomunale": "044"
    },
    {
      "sigla_provincia": "MS",
      "codice_istat": "045001",
      "denominazione_ita_altra": "Aulla",
      "denominazione_ita": "Aulla",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A496",
      "lat": "44.2093308",
      "lon": "9.9681547",
      "superficie_kmq": "59.9874",
      "codice_sovracomunale": "045"
    },
    {
      "sigla_provincia": "MS",
      "codice_istat": "045002",
      "denominazione_ita_altra": "Bagnone",
      "denominazione_ita": "Bagnone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A576",
      "lat": "44.3153772",
      "lon": "9.9944278",
      "superficie_kmq": "73.9351",
      "codice_sovracomunale": "045"
    },
    {
      "sigla_provincia": "MS",
      "codice_istat": "045003",
      "denominazione_ita_altra": "Carrara",
      "denominazione_ita": "Carrara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B832",
      "lat": "44.0772840",
      "lon": "10.0967828",
      "superficie_kmq": "71.2778",
      "codice_sovracomunale": "045"
    },
    {
      "sigla_provincia": "MS",
      "codice_istat": "045004",
      "denominazione_ita_altra": "Casola in Lunigiana",
      "denominazione_ita": "Casola in Lunigiana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B979",
      "lat": "44.2011580",
      "lon": "10.1757178",
      "superficie_kmq": "41.5412",
      "codice_sovracomunale": "045"
    },
    {
      "sigla_provincia": "MS",
      "codice_istat": "045005",
      "denominazione_ita_altra": "Comano",
      "denominazione_ita": "Comano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C914",
      "lat": "44.2972141",
      "lon": "10.1348789",
      "superficie_kmq": "53.8314",
      "codice_sovracomunale": "045"
    },
    {
      "sigla_provincia": "MS",
      "codice_istat": "045006",
      "denominazione_ita_altra": "Filattiera",
      "denominazione_ita": "Filattiera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D590",
      "lat": "44.3312670",
      "lon": "9.9368775",
      "superficie_kmq": "48.7783",
      "codice_sovracomunale": "045"
    },
    {
      "sigla_provincia": "MS",
      "codice_istat": "045007",
      "denominazione_ita_altra": "Fivizzano",
      "denominazione_ita": "Fivizzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D629",
      "lat": "44.2370480",
      "lon": "10.1228324",
      "superficie_kmq": "181.1155",
      "codice_sovracomunale": "045"
    },
    {
      "sigla_provincia": "MS",
      "codice_istat": "045008",
      "denominazione_ita_altra": "Fosdinovo",
      "denominazione_ita": "Fosdinovo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D735",
      "lat": "44.1343660",
      "lon": "10.0190587",
      "superficie_kmq": "48.3949",
      "codice_sovracomunale": "045"
    },
    {
      "sigla_provincia": "MS",
      "codice_istat": "045009",
      "denominazione_ita_altra": "Licciana Nardi",
      "denominazione_ita": "Licciana Nardi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E574",
      "lat": "44.2649720",
      "lon": "10.0385393",
      "superficie_kmq": "55.6777",
      "codice_sovracomunale": "045"
    },
    {
      "sigla_provincia": "MS",
      "codice_istat": "045010",
      "denominazione_ita_altra": "Massa",
      "denominazione_ita": "Massa",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "F023",
      "lat": "44.0367284",
      "lon": "10.1417380",
      "superficie_kmq": "93.8397",
      "codice_sovracomunale": "045"
    },
    {
      "sigla_provincia": "MS",
      "codice_istat": "045011",
      "denominazione_ita_altra": "Montignoso",
      "denominazione_ita": "Montignoso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F679",
      "lat": "44.0181301",
      "lon": "10.1764828",
      "superficie_kmq": "16.7091",
      "codice_sovracomunale": "045"
    },
    {
      "sigla_provincia": "MS",
      "codice_istat": "045012",
      "denominazione_ita_altra": "Mulazzo",
      "denominazione_ita": "Mulazzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F802",
      "lat": "44.3216520",
      "lon": "9.9070753",
      "superficie_kmq": "62.5051",
      "codice_sovracomunale": "045"
    },
    {
      "sigla_provincia": "MS",
      "codice_istat": "045013",
      "denominazione_ita_altra": "Podenzana",
      "denominazione_ita": "Podenzana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G746",
      "lat": "44.2063609",
      "lon": "9.9412224",
      "superficie_kmq": "17.1035",
      "codice_sovracomunale": "045"
    },
    {
      "sigla_provincia": "MS",
      "codice_istat": "045014",
      "denominazione_ita_altra": "Pontremoli",
      "denominazione_ita": "Pontremoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G870",
      "lat": "44.3773761",
      "lon": "9.8829482",
      "superficie_kmq": "182.5158",
      "codice_sovracomunale": "045"
    },
    {
      "sigla_provincia": "MS",
      "codice_istat": "045015",
      "denominazione_ita_altra": "Tresana",
      "denominazione_ita": "Tresana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L386",
      "lat": "44.2382312",
      "lon": "9.9515586",
      "superficie_kmq": "44.4507",
      "codice_sovracomunale": "045"
    },
    {
      "sigla_provincia": "MS",
      "codice_istat": "045016",
      "denominazione_ita_altra": "Villafranca in Lunigiana",
      "denominazione_ita": "Villafranca in Lunigiana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L946",
      "lat": "44.2919726",
      "lon": "9.9510258",
      "superficie_kmq": "29.3197",
      "codice_sovracomunale": "045"
    },
    {
      "sigla_provincia": "MS",
      "codice_istat": "045017",
      "denominazione_ita_altra": "Zeri",
      "denominazione_ita": "Zeri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M169",
      "lat": "44.3529056",
      "lon": "9.7635024",
      "superficie_kmq": "73.6187",
      "codice_sovracomunale": "045"
    },
    {
      "sigla_provincia": "LU",
      "codice_istat": "046001",
      "denominazione_ita_altra": "Altopascio",
      "denominazione_ita": "Altopascio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A241",
      "lat": "43.8150175",
      "lon": "10.6747442",
      "superficie_kmq": "28.5991",
      "codice_sovracomunale": "046"
    },
    {
      "sigla_provincia": "LU",
      "codice_istat": "046002",
      "denominazione_ita_altra": "Bagni di Lucca",
      "denominazione_ita": "Bagni di Lucca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A560",
      "lat": "44.0105317",
      "lon": "10.5912028",
      "superficie_kmq": "164.7064",
      "codice_sovracomunale": "046"
    },
    {
      "sigla_provincia": "LU",
      "codice_istat": "046003",
      "denominazione_ita_altra": "Barga",
      "denominazione_ita": "Barga",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A657",
      "lat": "44.0730326",
      "lon": "10.4847758",
      "superficie_kmq": "66.4641",
      "codice_sovracomunale": "046"
    },
    {
      "sigla_provincia": "LU",
      "codice_istat": "046004",
      "denominazione_ita_altra": "Borgo a Mozzano",
      "denominazione_ita": "Borgo a Mozzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B007",
      "lat": "43.9785065",
      "lon": "10.5436003",
      "superficie_kmq": "72.2028",
      "codice_sovracomunale": "046"
    },
    {
      "sigla_provincia": "LU",
      "codice_istat": "046005",
      "denominazione_ita_altra": "Camaiore",
      "denominazione_ita": "Camaiore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B455",
      "lat": "43.9385799",
      "lon": "10.3041741",
      "superficie_kmq": "85.4275",
      "codice_sovracomunale": "046"
    },
    {
      "sigla_provincia": "LU",
      "codice_istat": "046006",
      "denominazione_ita_altra": "Camporgiano",
      "denominazione_ita": "Camporgiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B557",
      "lat": "44.1586319",
      "lon": "10.3337985",
      "superficie_kmq": "27.0912",
      "codice_sovracomunale": "046"
    },
    {
      "sigla_provincia": "LU",
      "codice_istat": "046007",
      "denominazione_ita_altra": "Capannori",
      "denominazione_ita": "Capannori",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B648",
      "lat": "43.8488983",
      "lon": "10.5747991",
      "superficie_kmq": "156.1777",
      "codice_sovracomunale": "046"
    },
    {
      "sigla_provincia": "LU",
      "codice_istat": "046008",
      "denominazione_ita_altra": "Careggine",
      "denominazione_ita": "Careggine",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B760",
      "lat": "44.1207979",
      "lon": "10.3243864",
      "superficie_kmq": "24.0810",
      "codice_sovracomunale": "046"
    },
    {
      "sigla_provincia": "LU",
      "codice_istat": "046009",
      "denominazione_ita_altra": "Castelnuovo di Garfagnana",
      "denominazione_ita": "Castelnuovo di Garfagnana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C236",
      "lat": "44.1103010",
      "lon": "10.4116603",
      "superficie_kmq": "28.4824",
      "codice_sovracomunale": "046"
    },
    {
      "sigla_provincia": "LU",
      "codice_istat": "046010",
      "denominazione_ita_altra": "Castiglione di Garfagnana",
      "denominazione_ita": "Castiglione di Garfagnana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C303",
      "lat": "44.1490744",
      "lon": "10.4112675",
      "superficie_kmq": "48.5253",
      "codice_sovracomunale": "046"
    },
    {
      "sigla_provincia": "LU",
      "codice_istat": "046011",
      "denominazione_ita_altra": "Coreglia Antelminelli",
      "denominazione_ita": "Coreglia Antelminelli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C996",
      "lat": "44.0645115",
      "lon": "10.5253015",
      "superficie_kmq": "52.9404",
      "codice_sovracomunale": "046"
    },
    {
      "sigla_provincia": "LU",
      "codice_istat": "046013",
      "denominazione_ita_altra": "Forte dei Marmi",
      "denominazione_ita": "Forte dei Marmi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D730",
      "lat": "43.9601590",
      "lon": "10.1667052",
      "superficie_kmq": "9.1872",
      "codice_sovracomunale": "046"
    },
    {
      "sigla_provincia": "LU",
      "codice_istat": "046014",
      "denominazione_ita_altra": "Fosciandora",
      "denominazione_ita": "Fosciandora",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D734",
      "lat": "44.1152232",
      "lon": "10.4554079",
      "superficie_kmq": "19.8594",
      "codice_sovracomunale": "046"
    },
    {
      "sigla_provincia": "LU",
      "codice_istat": "046015",
      "denominazione_ita_altra": "Gallicano",
      "denominazione_ita": "Gallicano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D874",
      "lat": "44.0604532",
      "lon": "10.4356553",
      "superficie_kmq": "31.0403",
      "codice_sovracomunale": "046"
    },
    {
      "sigla_provincia": "LU",
      "codice_istat": "046017",
      "denominazione_ita_altra": "Lucca",
      "denominazione_ita": "Lucca",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "E715",
      "lat": "43.8443042",
      "lon": "10.5015132",
      "superficie_kmq": "185.7334",
      "codice_sovracomunale": "046"
    },
    {
      "sigla_provincia": "LU",
      "codice_istat": "046018",
      "denominazione_ita_altra": "Massarosa",
      "denominazione_ita": "Massarosa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F035",
      "lat": "43.8684779",
      "lon": "10.3418058",
      "superficie_kmq": "68.2379",
      "codice_sovracomunale": "046"
    },
    {
      "sigla_provincia": "LU",
      "codice_istat": "046019",
      "denominazione_ita_altra": "Minucciano",
      "denominazione_ita": "Minucciano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F225",
      "lat": "44.1702969",
      "lon": "10.2075641",
      "superficie_kmq": "57.2823",
      "codice_sovracomunale": "046"
    },
    {
      "sigla_provincia": "LU",
      "codice_istat": "046020",
      "denominazione_ita_altra": "Molazzana",
      "denominazione_ita": "Molazzana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F283",
      "lat": "44.0725780",
      "lon": "10.4184376",
      "superficie_kmq": "31.3297",
      "codice_sovracomunale": "046"
    },
    {
      "sigla_provincia": "LU",
      "codice_istat": "046021",
      "denominazione_ita_altra": "Montecarlo",
      "denominazione_ita": "Montecarlo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F452",
      "lat": "43.8503455",
      "lon": "10.6685703",
      "superficie_kmq": "15.6300",
      "codice_sovracomunale": "046"
    },
    {
      "sigla_provincia": "LU",
      "codice_istat": "046022",
      "denominazione_ita_altra": "Pescaglia",
      "denominazione_ita": "Pescaglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G480",
      "lat": "43.9664067",
      "lon": "10.4112828",
      "superficie_kmq": "70.5479",
      "codice_sovracomunale": "046"
    },
    {
      "sigla_provincia": "LU",
      "codice_istat": "046023",
      "denominazione_ita_altra": "Piazza al Serchio",
      "denominazione_ita": "Piazza al Serchio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G582",
      "lat": "44.1825514",
      "lon": "10.3000167",
      "superficie_kmq": "27.0295",
      "codice_sovracomunale": "046"
    },
    {
      "sigla_provincia": "LU",
      "codice_istat": "046024",
      "denominazione_ita_altra": "Pietrasanta",
      "denominazione_ita": "Pietrasanta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G628",
      "lat": "43.9595385",
      "lon": "10.2276878",
      "superficie_kmq": "42.1153",
      "codice_sovracomunale": "046"
    },
    {
      "sigla_provincia": "LU",
      "codice_istat": "046025",
      "denominazione_ita_altra": "Pieve Fosciana",
      "denominazione_ita": "Pieve Fosciana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G648",
      "lat": "44.1306135",
      "lon": "10.4106854",
      "superficie_kmq": "28.7546",
      "codice_sovracomunale": "046"
    },
    {
      "sigla_provincia": "LU",
      "codice_istat": "046026",
      "denominazione_ita_altra": "Porcari",
      "denominazione_ita": "Porcari",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G882",
      "lat": "43.8396435",
      "lon": "10.6167062",
      "superficie_kmq": "17.8894",
      "codice_sovracomunale": "046"
    },
    {
      "sigla_provincia": "LU",
      "codice_istat": "046027",
      "denominazione_ita_altra": "San Romano in Garfagnana",
      "denominazione_ita": "San Romano in Garfagnana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I142",
      "lat": "44.1689503",
      "lon": "10.3454385",
      "superficie_kmq": "26.1567",
      "codice_sovracomunale": "046"
    },
    {
      "sigla_provincia": "LU",
      "codice_istat": "046028",
      "denominazione_ita_altra": "Seravezza",
      "denominazione_ita": "Seravezza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I622",
      "lat": "43.9951732",
      "lon": "10.2284374",
      "superficie_kmq": "39.4678",
      "codice_sovracomunale": "046"
    },
    {
      "sigla_provincia": "LU",
      "codice_istat": "046030",
      "denominazione_ita_altra": "Stazzema",
      "denominazione_ita": "Stazzema",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I942",
      "lat": "43.9970986",
      "lon": "10.2956298",
      "superficie_kmq": "80.1265",
      "codice_sovracomunale": "046"
    },
    {
      "sigla_provincia": "LU",
      "codice_istat": "046031",
      "denominazione_ita_altra": "Vagli Sotto",
      "denominazione_ita": "Vagli Sotto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L533",
      "lat": "44.1143747",
      "lon": "10.2803963",
      "superficie_kmq": "41.2192",
      "codice_sovracomunale": "046"
    },
    {
      "sigla_provincia": "LU",
      "codice_istat": "046033",
      "denominazione_ita_altra": "Viareggio",
      "denominazione_ita": "Viareggio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L833",
      "lat": "43.8669243",
      "lon": "10.2498112",
      "superficie_kmq": "32.5275",
      "codice_sovracomunale": "046"
    },
    {
      "sigla_provincia": "LU",
      "codice_istat": "046034",
      "denominazione_ita_altra": "Villa Basilica",
      "denominazione_ita": "Villa Basilica",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L913",
      "lat": "43.9277583",
      "lon": "10.6450966",
      "superficie_kmq": "36.5731",
      "codice_sovracomunale": "046"
    },
    {
      "sigla_provincia": "LU",
      "codice_istat": "046035",
      "denominazione_ita_altra": "Villa Collemandina",
      "denominazione_ita": "Villa Collemandina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L926",
      "lat": "44.1595627",
      "lon": "10.3975720",
      "superficie_kmq": "34.7900",
      "codice_sovracomunale": "046"
    },
    {
      "sigla_provincia": "LU",
      "codice_istat": "046036",
      "denominazione_ita_altra": "Fabbriche di Vergemoli",
      "denominazione_ita": "Fabbriche di Vergemoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M319",
      "lat": "44.0233962",
      "lon": "10.3995593",
      "superficie_kmq": "42.5469",
      "codice_sovracomunale": "046"
    },
    {
      "sigla_provincia": "LU",
      "codice_istat": "046037",
      "denominazione_ita_altra": "Sillano Giuncugnano",
      "denominazione_ita": "Sillano Giuncugnano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M347",
      "lat": "44.2421177",
      "lon": "10.3117516",
      "superficie_kmq": "81.2949",
      "codice_sovracomunale": "046"
    },
    {
      "sigla_provincia": "PT",
      "codice_istat": "047002",
      "denominazione_ita_altra": "Agliana",
      "denominazione_ita": "Agliana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A071",
      "lat": "43.9027674",
      "lon": "11.0065334",
      "superficie_kmq": "11.6792",
      "codice_sovracomunale": "047"
    },
    {
      "sigla_provincia": "PT",
      "codice_istat": "047003",
      "denominazione_ita_altra": "Buggiano",
      "denominazione_ita": "Buggiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B251",
      "lat": "43.8799148",
      "lon": "10.7275360",
      "superficie_kmq": "16.0642",
      "codice_sovracomunale": "047"
    },
    {
      "sigla_provincia": "PT",
      "codice_istat": "047005",
      "denominazione_ita_altra": "Lamporecchio",
      "denominazione_ita": "Lamporecchio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E432",
      "lat": "43.8169545",
      "lon": "10.8967870",
      "superficie_kmq": "22.2518",
      "codice_sovracomunale": "047"
    },
    {
      "sigla_provincia": "PT",
      "codice_istat": "047006",
      "denominazione_ita_altra": "Larciano",
      "denominazione_ita": "Larciano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E451",
      "lat": "43.8246429",
      "lon": "10.8771935",
      "superficie_kmq": "24.9667",
      "codice_sovracomunale": "047"
    },
    {
      "sigla_provincia": "PT",
      "codice_istat": "047007",
      "denominazione_ita_altra": "Marliana",
      "denominazione_ita": "Marliana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E960",
      "lat": "43.9340432",
      "lon": "10.7700546",
      "superficie_kmq": "43.0435",
      "codice_sovracomunale": "047"
    },
    {
      "sigla_provincia": "PT",
      "codice_istat": "047008",
      "denominazione_ita_altra": "Massa e Cozzile",
      "denominazione_ita": "Massa e Cozzile",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F025",
      "lat": "43.9098976",
      "lon": "10.7446720",
      "superficie_kmq": "16.0119",
      "codice_sovracomunale": "047"
    },
    {
      "sigla_provincia": "PT",
      "codice_istat": "047009",
      "denominazione_ita_altra": "Monsummano Terme",
      "denominazione_ita": "Monsummano Terme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F384",
      "lat": "43.8715780",
      "lon": "10.8144222",
      "superficie_kmq": "32.6232",
      "codice_sovracomunale": "047"
    },
    {
      "sigla_provincia": "PT",
      "codice_istat": "047010",
      "denominazione_ita_altra": "Montale",
      "denominazione_ita": "Montale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F410",
      "lat": "43.9347220",
      "lon": "11.0222660",
      "superficie_kmq": "32.1174",
      "codice_sovracomunale": "047"
    },
    {
      "sigla_provincia": "PT",
      "codice_istat": "047011",
      "denominazione_ita_altra": "Montecatini-Terme",
      "denominazione_ita": "Montecatini-Terme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A561",
      "lat": "43.8840784",
      "lon": "10.7727837",
      "superficie_kmq": "17.6864",
      "codice_sovracomunale": "047"
    },
    {
      "sigla_provincia": "PT",
      "codice_istat": "047012",
      "denominazione_ita_altra": "Pescia",
      "denominazione_ita": "Pescia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G491",
      "lat": "43.9031412",
      "lon": "10.6885925",
      "superficie_kmq": "79.1246",
      "codice_sovracomunale": "047"
    },
    {
      "sigla_provincia": "PT",
      "codice_istat": "047013",
      "denominazione_ita_altra": "Pieve a Nievole",
      "denominazione_ita": "Pieve a Nievole",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G636",
      "lat": "43.8789574",
      "lon": "10.7946583",
      "superficie_kmq": "12.6668",
      "codice_sovracomunale": "047"
    },
    {
      "sigla_provincia": "PT",
      "codice_istat": "047014",
      "denominazione_ita_altra": "Pistoia",
      "denominazione_ita": "Pistoia",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "G713",
      "lat": "43.9334478",
      "lon": "10.9173409",
      "superficie_kmq": "236.3969",
      "codice_sovracomunale": "047"
    },
    {
      "sigla_provincia": "PT",
      "codice_istat": "047016",
      "denominazione_ita_altra": "Ponte Buggianese",
      "denominazione_ita": "Ponte Buggianese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G833",
      "lat": "43.8427404",
      "lon": "10.7474459",
      "superficie_kmq": "29.5227",
      "codice_sovracomunale": "047"
    },
    {
      "sigla_provincia": "PT",
      "codice_istat": "047017",
      "denominazione_ita_altra": "Quarrata",
      "denominazione_ita": "Quarrata",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H109",
      "lat": "43.8475752",
      "lon": "10.9786123",
      "superficie_kmq": "45.9127",
      "codice_sovracomunale": "047"
    },
    {
      "sigla_provincia": "PT",
      "codice_istat": "047018",
      "denominazione_ita_altra": "Sambuca Pistoiese",
      "denominazione_ita": "Sambuca Pistoiese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H744",
      "lat": "44.1040317",
      "lon": "10.9989948",
      "superficie_kmq": "77.2396",
      "codice_sovracomunale": "047"
    },
    {
      "sigla_provincia": "PT",
      "codice_istat": "047020",
      "denominazione_ita_altra": "Serravalle Pistoiese",
      "denominazione_ita": "Serravalle Pistoiese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I660",
      "lat": "43.9055767",
      "lon": "10.8319803",
      "superficie_kmq": "42.0349",
      "codice_sovracomunale": "047"
    },
    {
      "sigla_provincia": "PT",
      "codice_istat": "047021",
      "denominazione_ita_altra": "Uzzano",
      "denominazione_ita": "Uzzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L522",
      "lat": "43.8816983",
      "lon": "10.7090767",
      "superficie_kmq": "7.8501",
      "codice_sovracomunale": "047"
    },
    {
      "sigla_provincia": "PT",
      "codice_istat": "047022",
      "denominazione_ita_altra": "Chiesina Uzzanese",
      "denominazione_ita": "Chiesina Uzzanese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C631",
      "lat": "43.8391554",
      "lon": "10.7195066",
      "superficie_kmq": "7.2510",
      "codice_sovracomunale": "047"
    },
    {
      "sigla_provincia": "PT",
      "codice_istat": "047023",
      "denominazione_ita_altra": "Abetone Cutigliano",
      "denominazione_ita": "Abetone Cutigliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M376",
      "lat": "44.1248467",
      "lon": "10.7057064",
      "superficie_kmq": "74.9918",
      "codice_sovracomunale": "047"
    },
    {
      "sigla_provincia": "PT",
      "codice_istat": "047024",
      "denominazione_ita_altra": "San Marcello Piteglio",
      "denominazione_ita": "San Marcello Piteglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M377",
      "lat": "44.0528573",
      "lon": "10.7965409",
      "superficie_kmq": "134.7274",
      "codice_sovracomunale": "047"
    },
    {
      "sigla_provincia": "FI",
      "codice_istat": "048001",
      "denominazione_ita_altra": "Bagno a Ripoli",
      "denominazione_ita": "Bagno a Ripoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A564",
      "lat": "43.7513975",
      "lon": "11.3193900",
      "superficie_kmq": "74.0913",
      "codice_sovracomunale": "248"
    },
    {
      "sigla_provincia": "FI",
      "codice_istat": "048002",
      "denominazione_ita_altra": "Barberino di Mugello",
      "denominazione_ita": "Barberino di Mugello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A632",
      "lat": "44.0004238",
      "lon": "11.2385793",
      "superficie_kmq": "133.3254",
      "codice_sovracomunale": "248"
    },
    {
      "sigla_provincia": "FI",
      "codice_istat": "048004",
      "denominazione_ita_altra": "Borgo San Lorenzo",
      "denominazione_ita": "Borgo San Lorenzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B036",
      "lat": "43.9547706",
      "lon": "11.3876835",
      "superficie_kmq": "146.3627",
      "codice_sovracomunale": "248"
    },
    {
      "sigla_provincia": "FI",
      "codice_istat": "048005",
      "denominazione_ita_altra": "Calenzano",
      "denominazione_ita": "Calenzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B406",
      "lat": "43.8650656",
      "lon": "11.1671937",
      "superficie_kmq": "76.9699",
      "codice_sovracomunale": "248"
    },
    {
      "sigla_provincia": "FI",
      "codice_istat": "048006",
      "denominazione_ita_altra": "Campi Bisenzio",
      "denominazione_ita": "Campi Bisenzio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B507",
      "lat": "43.8205483",
      "lon": "11.1378351",
      "superficie_kmq": "28.7516",
      "codice_sovracomunale": "248"
    },
    {
      "sigla_provincia": "FI",
      "codice_istat": "048008",
      "denominazione_ita_altra": "Capraia e Limite",
      "denominazione_ita": "Capraia e Limite",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B684",
      "lat": "43.7424415",
      "lon": "10.9764272",
      "superficie_kmq": "24.9167",
      "codice_sovracomunale": "248"
    },
    {
      "sigla_provincia": "FI",
      "codice_istat": "048010",
      "denominazione_ita_altra": "Castelfiorentino",
      "denominazione_ita": "Castelfiorentino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C101",
      "lat": "43.6057123",
      "lon": "10.9713086",
      "superficie_kmq": "66.3437",
      "codice_sovracomunale": "248"
    },
    {
      "sigla_provincia": "FI",
      "codice_istat": "048011",
      "denominazione_ita_altra": "Cerreto Guidi",
      "denominazione_ita": "Cerreto Guidi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C529",
      "lat": "43.7596494",
      "lon": "10.8775747",
      "superficie_kmq": "49.3050",
      "codice_sovracomunale": "248"
    },
    {
      "sigla_provincia": "FI",
      "codice_istat": "048012",
      "denominazione_ita_altra": "Certaldo",
      "denominazione_ita": "Certaldo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C540",
      "lat": "43.5477561",
      "lon": "11.0407636",
      "superficie_kmq": "75.2749",
      "codice_sovracomunale": "248"
    },
    {
      "sigla_provincia": "FI",
      "codice_istat": "048013",
      "denominazione_ita_altra": "Dicomano",
      "denominazione_ita": "Dicomano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D299",
      "lat": "43.8914844",
      "lon": "11.5236193",
      "superficie_kmq": "61.6319",
      "codice_sovracomunale": "248"
    },
    {
      "sigla_provincia": "FI",
      "codice_istat": "048014",
      "denominazione_ita_altra": "Empoli",
      "denominazione_ita": "Empoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D403",
      "lat": "43.7190603",
      "lon": "10.9471833",
      "superficie_kmq": "62.4217",
      "codice_sovracomunale": "248"
    },
    {
      "sigla_provincia": "FI",
      "codice_istat": "048015",
      "denominazione_ita_altra": "Fiesole",
      "denominazione_ita": "Fiesole",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D575",
      "lat": "43.8068454",
      "lon": "11.2931756",
      "superficie_kmq": "42.1928",
      "codice_sovracomunale": "248"
    },
    {
      "sigla_provincia": "FI",
      "codice_istat": "048017",
      "denominazione_ita_altra": "Firenze",
      "denominazione_ita": "Firenze",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "D612",
      "lat": "43.7692112",
      "lon": "11.2558881",
      "superficie_kmq": "102.3156",
      "codice_sovracomunale": "248"
    },
    {
      "sigla_provincia": "FI",
      "codice_istat": "048018",
      "denominazione_ita_altra": "Firenzuola",
      "denominazione_ita": "Firenzuola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D613",
      "lat": "44.1175902",
      "lon": "11.3788277",
      "superficie_kmq": "271.9594",
      "codice_sovracomunale": "248"
    },
    {
      "sigla_provincia": "FI",
      "codice_istat": "048019",
      "denominazione_ita_altra": "Fucecchio",
      "denominazione_ita": "Fucecchio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D815",
      "lat": "43.7285930",
      "lon": "10.8100863",
      "superficie_kmq": "65.1757",
      "codice_sovracomunale": "248"
    },
    {
      "sigla_provincia": "FI",
      "codice_istat": "048020",
      "denominazione_ita_altra": "Gambassi Terme",
      "denominazione_ita": "Gambassi Terme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D895",
      "lat": "43.5385944",
      "lon": "10.9531594",
      "superficie_kmq": "83.2314",
      "codice_sovracomunale": "248"
    },
    {
      "sigla_provincia": "FI",
      "codice_istat": "048021",
      "denominazione_ita_altra": "Greve in Chianti",
      "denominazione_ita": "Greve in Chianti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E169",
      "lat": "43.5832265",
      "lon": "11.3171568",
      "superficie_kmq": "169.3743",
      "codice_sovracomunale": "248"
    },
    {
      "sigla_provincia": "FI",
      "codice_istat": "048022",
      "denominazione_ita_altra": "Impruneta",
      "denominazione_ita": "Impruneta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E291",
      "lat": "43.6857156",
      "lon": "11.2544070",
      "superficie_kmq": "48.7144",
      "codice_sovracomunale": "248"
    },
    {
      "sigla_provincia": "FI",
      "codice_istat": "048024",
      "denominazione_ita_altra": "Lastra a Signa",
      "denominazione_ita": "Lastra a Signa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E466",
      "lat": "43.7701042",
      "lon": "11.1060631",
      "superficie_kmq": "42.8991",
      "codice_sovracomunale": "248"
    },
    {
      "sigla_provincia": "FI",
      "codice_istat": "048025",
      "denominazione_ita_altra": "Londa",
      "denominazione_ita": "Londa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E668",
      "lat": "43.8600845",
      "lon": "11.5672041",
      "superficie_kmq": "59.2885",
      "codice_sovracomunale": "248"
    },
    {
      "sigla_provincia": "FI",
      "codice_istat": "048026",
      "denominazione_ita_altra": "Marradi",
      "denominazione_ita": "Marradi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E971",
      "lat": "44.0754494",
      "lon": "11.6127649",
      "superficie_kmq": "154.0525",
      "codice_sovracomunale": "248"
    },
    {
      "sigla_provincia": "FI",
      "codice_istat": "048027",
      "denominazione_ita_altra": "Montaione",
      "denominazione_ita": "Montaione",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F398",
      "lat": "43.5522560",
      "lon": "10.9122503",
      "superficie_kmq": "104.7585",
      "codice_sovracomunale": "248"
    },
    {
      "sigla_provincia": "FI",
      "codice_istat": "048028",
      "denominazione_ita_altra": "Montelupo Fiorentino",
      "denominazione_ita": "Montelupo Fiorentino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F551",
      "lat": "43.7294523",
      "lon": "11.0207597",
      "superficie_kmq": "24.6575",
      "codice_sovracomunale": "248"
    },
    {
      "sigla_provincia": "FI",
      "codice_istat": "048030",
      "denominazione_ita_altra": "Montespertoli",
      "denominazione_ita": "Montespertoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F648",
      "lat": "43.6431084",
      "lon": "11.0753318",
      "superficie_kmq": "124.8777",
      "codice_sovracomunale": "248"
    },
    {
      "sigla_provincia": "FI",
      "codice_istat": "048031",
      "denominazione_ita_altra": "Palazzuolo sul Senio",
      "denominazione_ita": "Palazzuolo sul Senio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G270",
      "lat": "44.1122528",
      "lon": "11.5472917",
      "superficie_kmq": "109.1026",
      "codice_sovracomunale": "248"
    },
    {
      "sigla_provincia": "FI",
      "codice_istat": "048032",
      "denominazione_ita_altra": "Pelago",
      "denominazione_ita": "Pelago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G420",
      "lat": "43.7702262",
      "lon": "11.5063596",
      "superficie_kmq": "54.5602",
      "codice_sovracomunale": "248"
    },
    {
      "sigla_provincia": "FI",
      "codice_istat": "048033",
      "denominazione_ita_altra": "Pontassieve",
      "denominazione_ita": "Pontassieve",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G825",
      "lat": "43.7757937",
      "lon": "11.4398655",
      "superficie_kmq": "114.3984",
      "codice_sovracomunale": "248"
    },
    {
      "sigla_provincia": "FI",
      "codice_istat": "048035",
      "denominazione_ita_altra": "Reggello",
      "denominazione_ita": "Reggello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H222",
      "lat": "43.6825288",
      "lon": "11.5363824",
      "superficie_kmq": "121.6458",
      "codice_sovracomunale": "248"
    },
    {
      "sigla_provincia": "FI",
      "codice_istat": "048036",
      "denominazione_ita_altra": "Rignano sull'Arno",
      "denominazione_ita": "Rignano sull'Arno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H286",
      "lat": "43.7220412",
      "lon": "11.4535241",
      "superficie_kmq": "54.1439",
      "codice_sovracomunale": "248"
    },
    {
      "sigla_provincia": "FI",
      "codice_istat": "048037",
      "denominazione_ita_altra": "Rufina",
      "denominazione_ita": "Rufina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H635",
      "lat": "43.8266192",
      "lon": "11.4879161",
      "superficie_kmq": "45.8797",
      "codice_sovracomunale": "248"
    },
    {
      "sigla_provincia": "FI",
      "codice_istat": "048038",
      "denominazione_ita_altra": "San Casciano in Val di Pesa",
      "denominazione_ita": "San Casciano in Val di Pesa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H791",
      "lat": "43.6565411",
      "lon": "11.1843918",
      "superficie_kmq": "107.8317",
      "codice_sovracomunale": "248"
    },
    {
      "sigla_provincia": "FI",
      "codice_istat": "048039",
      "denominazione_ita_altra": "San Godenzo",
      "denominazione_ita": "San Godenzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H937",
      "lat": "43.9262600",
      "lon": "11.6196166",
      "superficie_kmq": "99.1989",
      "codice_sovracomunale": "248"
    },
    {
      "sigla_provincia": "FI",
      "codice_istat": "048041",
      "denominazione_ita_altra": "Scandicci",
      "denominazione_ita": "Scandicci",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B962",
      "lat": "43.7582062",
      "lon": "11.1813296",
      "superficie_kmq": "59.6923",
      "codice_sovracomunale": "248"
    },
    {
      "sigla_provincia": "FI",
      "codice_istat": "048043",
      "denominazione_ita_altra": "Sesto Fiorentino",
      "denominazione_ita": "Sesto Fiorentino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I684",
      "lat": "43.8321718",
      "lon": "11.1984270",
      "superficie_kmq": "48.8110",
      "codice_sovracomunale": "248"
    },
    {
      "sigla_provincia": "FI",
      "codice_istat": "048044",
      "denominazione_ita_altra": "Signa",
      "denominazione_ita": "Signa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I728",
      "lat": "43.7823687",
      "lon": "11.0975570",
      "superficie_kmq": "18.8084",
      "codice_sovracomunale": "248"
    },
    {
      "sigla_provincia": "FI",
      "codice_istat": "048046",
      "denominazione_ita_altra": "Vaglia",
      "denominazione_ita": "Vaglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L529",
      "lat": "43.9065511",
      "lon": "11.2813561",
      "superficie_kmq": "56.9411",
      "codice_sovracomunale": "248"
    },
    {
      "sigla_provincia": "FI",
      "codice_istat": "048049",
      "denominazione_ita_altra": "Vicchio",
      "denominazione_ita": "Vicchio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L838",
      "lat": "43.9337660",
      "lon": "11.4654354",
      "superficie_kmq": "138.8610",
      "codice_sovracomunale": "248"
    },
    {
      "sigla_provincia": "FI",
      "codice_istat": "048050",
      "denominazione_ita_altra": "Vinci",
      "denominazione_ita": "Vinci",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M059",
      "lat": "43.7871172",
      "lon": "10.9264862",
      "superficie_kmq": "54.1848",
      "codice_sovracomunale": "248"
    },
    {
      "sigla_provincia": "FI",
      "codice_istat": "048052",
      "denominazione_ita_altra": "Figline e Incisa Valdarno",
      "denominazione_ita": "Figline e Incisa Valdarno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M321",
      "lat": "43.6243543",
      "lon": "11.4347373",
      "superficie_kmq": "97.9012",
      "codice_sovracomunale": "248"
    },
    {
      "sigla_provincia": "FI",
      "codice_istat": "048053",
      "denominazione_ita_altra": "Scarperia e San Piero",
      "denominazione_ita": "Scarperia e San Piero",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M326",
      "lat": "44.0047194",
      "lon": "11.3247553",
      "superficie_kmq": "115.8099",
      "codice_sovracomunale": "248"
    },
    {
      "sigla_provincia": "FI",
      "codice_istat": "048054",
      "denominazione_ita_altra": "Barberino Tavarnelle",
      "denominazione_ita": "Barberino Tavarnelle",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M408",
      "lat": "43.5394227",
      "lon": "11.1907978",
      "superficie_kmq": "122.9905",
      "codice_sovracomunale": "248"
    },
    {
      "sigla_provincia": "LI",
      "codice_istat": "049001",
      "denominazione_ita_altra": "Bibbona",
      "denominazione_ita": "Bibbona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A852",
      "lat": "43.2694120",
      "lon": "10.5981838",
      "superficie_kmq": "65.6114",
      "codice_sovracomunale": "049"
    },
    {
      "sigla_provincia": "LI",
      "codice_istat": "049002",
      "denominazione_ita_altra": "Campiglia Marittima",
      "denominazione_ita": "Campiglia Marittima",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B509",
      "lat": "43.0589976",
      "lon": "10.6168783",
      "superficie_kmq": "83.2760",
      "codice_sovracomunale": "049"
    },
    {
      "sigla_provincia": "LI",
      "codice_istat": "049003",
      "denominazione_ita_altra": "Campo nell'Elba",
      "denominazione_ita": "Campo nell'Elba",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B553",
      "lat": "42.7448024",
      "lon": "10.2336280",
      "superficie_kmq": "55.7876",
      "codice_sovracomunale": "049"
    },
    {
      "sigla_provincia": "LI",
      "codice_istat": "049004",
      "denominazione_ita_altra": "Capoliveri",
      "denominazione_ita": "Capoliveri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B669",
      "lat": "42.7430932",
      "lon": "10.3803315",
      "superficie_kmq": "39.5606",
      "codice_sovracomunale": "049"
    },
    {
      "sigla_provincia": "LI",
      "codice_istat": "049005",
      "denominazione_ita_altra": "Capraia Isola",
      "denominazione_ita": "Capraia Isola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B685",
      "lat": "43.0498936",
      "lon": "9.8422641",
      "superficie_kmq": "19.3267",
      "codice_sovracomunale": "049"
    },
    {
      "sigla_provincia": "LI",
      "codice_istat": "049006",
      "denominazione_ita_altra": "Castagneto Carducci",
      "denominazione_ita": "Castagneto Carducci",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C044",
      "lat": "43.1606302",
      "lon": "10.6099729",
      "superficie_kmq": "142.3149",
      "codice_sovracomunale": "049"
    },
    {
      "sigla_provincia": "LI",
      "codice_istat": "049007",
      "denominazione_ita_altra": "Cecina",
      "denominazione_ita": "Cecina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C415",
      "lat": "43.3121576",
      "lon": "10.5193646",
      "superficie_kmq": "42.4945",
      "codice_sovracomunale": "049"
    },
    {
      "sigla_provincia": "LI",
      "codice_istat": "049008",
      "denominazione_ita_altra": "Collesalvetti",
      "denominazione_ita": "Collesalvetti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C869",
      "lat": "43.5891955",
      "lon": "10.4760639",
      "superficie_kmq": "107.9895",
      "codice_sovracomunale": "049"
    },
    {
      "sigla_provincia": "LI",
      "codice_istat": "049009",
      "denominazione_ita_altra": "Livorno",
      "denominazione_ita": "Livorno",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "E625",
      "lat": "43.5523257",
      "lon": "10.3086776",
      "superficie_kmq": "104.7138",
      "codice_sovracomunale": "049"
    },
    {
      "sigla_provincia": "LI",
      "codice_istat": "049010",
      "denominazione_ita_altra": "Marciana",
      "denominazione_ita": "Marciana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E930",
      "lat": "42.7899659",
      "lon": "10.1679645",
      "superficie_kmq": "45.4509",
      "codice_sovracomunale": "049"
    },
    {
      "sigla_provincia": "LI",
      "codice_istat": "049011",
      "denominazione_ita_altra": "Marciana Marina",
      "denominazione_ita": "Marciana Marina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E931",
      "lat": "42.8046822",
      "lon": "10.1968296",
      "superficie_kmq": "5.8590",
      "codice_sovracomunale": "049"
    },
    {
      "sigla_provincia": "LI",
      "codice_istat": "049012",
      "denominazione_ita_altra": "Piombino",
      "denominazione_ita": "Piombino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G687",
      "lat": "42.9219385",
      "lon": "10.5274135",
      "superficie_kmq": "130.3251",
      "codice_sovracomunale": "049"
    },
    {
      "sigla_provincia": "LI",
      "codice_istat": "049013",
      "denominazione_ita_altra": "Porto Azzurro",
      "denominazione_ita": "Porto Azzurro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E680",
      "lat": "42.7645478",
      "lon": "10.3987256",
      "superficie_kmq": "13.3301",
      "codice_sovracomunale": "049"
    },
    {
      "sigla_provincia": "LI",
      "codice_istat": "049014",
      "denominazione_ita_altra": "Portoferraio",
      "denominazione_ita": "Portoferraio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G912",
      "lat": "42.8152328",
      "lon": "10.3311984",
      "superficie_kmq": "48.0293",
      "codice_sovracomunale": "049"
    },
    {
      "sigla_provincia": "LI",
      "codice_istat": "049017",
      "denominazione_ita_altra": "Rosignano Marittimo",
      "denominazione_ita": "Rosignano Marittimo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H570",
      "lat": "43.4053060",
      "lon": "10.4726913",
      "superficie_kmq": "120.6514",
      "codice_sovracomunale": "049"
    },
    {
      "sigla_provincia": "LI",
      "codice_istat": "049018",
      "denominazione_ita_altra": "San Vincenzo",
      "denominazione_ita": "San Vincenzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I390",
      "lat": "43.1008164",
      "lon": "10.5382344",
      "superficie_kmq": "33.1539",
      "codice_sovracomunale": "049"
    },
    {
      "sigla_provincia": "LI",
      "codice_istat": "049019",
      "denominazione_ita_altra": "Sassetta",
      "denominazione_ita": "Sassetta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I454",
      "lat": "43.1283344",
      "lon": "10.6439865",
      "superficie_kmq": "26.7469",
      "codice_sovracomunale": "049"
    },
    {
      "sigla_provincia": "LI",
      "codice_istat": "049020",
      "denominazione_ita_altra": "Suvereto",
      "denominazione_ita": "Suvereto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L019",
      "lat": "43.0792214",
      "lon": "10.6791042",
      "superficie_kmq": "92.3809",
      "codice_sovracomunale": "049"
    },
    {
      "sigla_provincia": "LI",
      "codice_istat": "049021",
      "denominazione_ita_altra": "Rio",
      "denominazione_ita": "Rio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M391",
      "lat": "42.8239161",
      "lon": "10.4093150",
      "superficie_kmq": "36.5182",
      "codice_sovracomunale": "049"
    },
    {
      "sigla_provincia": "PI",
      "codice_istat": "050001",
      "denominazione_ita_altra": "Bientina",
      "denominazione_ita": "Bientina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A864",
      "lat": "43.7100930",
      "lon": "10.6198370",
      "superficie_kmq": "29.4899",
      "codice_sovracomunale": "050"
    },
    {
      "sigla_provincia": "PI",
      "codice_istat": "050002",
      "denominazione_ita_altra": "Buti",
      "denominazione_ita": "Buti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B303",
      "lat": "43.7272919",
      "lon": "10.5873559",
      "superficie_kmq": "23.0257",
      "codice_sovracomunale": "050"
    },
    {
      "sigla_provincia": "PI",
      "codice_istat": "050003",
      "denominazione_ita_altra": "Calci",
      "denominazione_ita": "Calci",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B390",
      "lat": "43.7255319",
      "lon": "10.5156858",
      "superficie_kmq": "25.1051",
      "codice_sovracomunale": "050"
    },
    {
      "sigla_provincia": "PI",
      "codice_istat": "050004",
      "denominazione_ita_altra": "Calcinaia",
      "denominazione_ita": "Calcinaia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B392",
      "lat": "43.6829738",
      "lon": "10.6156660",
      "superficie_kmq": "14.8907",
      "codice_sovracomunale": "050"
    },
    {
      "sigla_provincia": "PI",
      "codice_istat": "050005",
      "denominazione_ita_altra": "Capannoli",
      "denominazione_ita": "Capannoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B647",
      "lat": "43.5838437",
      "lon": "10.6786476",
      "superficie_kmq": "22.7250",
      "codice_sovracomunale": "050"
    },
    {
      "sigla_provincia": "PI",
      "codice_istat": "050006",
      "denominazione_ita_altra": "Casale Marittimo",
      "denominazione_ita": "Casale Marittimo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B878",
      "lat": "43.2972239",
      "lon": "10.6155285",
      "superficie_kmq": "14.3589",
      "codice_sovracomunale": "050"
    },
    {
      "sigla_provincia": "PI",
      "codice_istat": "050008",
      "denominazione_ita_altra": "Cascina",
      "denominazione_ita": "Cascina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B950",
      "lat": "43.6765203",
      "lon": "10.5492765",
      "superficie_kmq": "78.6061",
      "codice_sovracomunale": "050"
    },
    {
      "sigla_provincia": "PI",
      "codice_istat": "050009",
      "denominazione_ita_altra": "Castelfranco di Sotto",
      "denominazione_ita": "Castelfranco di Sotto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C113",
      "lat": "43.6996753",
      "lon": "10.7452392",
      "superficie_kmq": "48.2483",
      "codice_sovracomunale": "050"
    },
    {
      "sigla_provincia": "PI",
      "codice_istat": "050010",
      "denominazione_ita_altra": "Castellina Marittima",
      "denominazione_ita": "Castellina Marittima",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C174",
      "lat": "43.4119488",
      "lon": "10.5754877",
      "superficie_kmq": "45.5735",
      "codice_sovracomunale": "050"
    },
    {
      "sigla_provincia": "PI",
      "codice_istat": "050011",
      "denominazione_ita_altra": "Castelnuovo di Val di Cecina",
      "denominazione_ita": "Castelnuovo di Val di Cecina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C244",
      "lat": "43.2068727",
      "lon": "10.9060056",
      "superficie_kmq": "89.0162",
      "codice_sovracomunale": "050"
    },
    {
      "sigla_provincia": "PI",
      "codice_istat": "050012",
      "denominazione_ita_altra": "Chianni",
      "denominazione_ita": "Chianni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C609",
      "lat": "43.4858066",
      "lon": "10.6425542",
      "superficie_kmq": "61.9916",
      "codice_sovracomunale": "050"
    },
    {
      "sigla_provincia": "PI",
      "codice_istat": "050014",
      "denominazione_ita_altra": "Fauglia",
      "denominazione_ita": "Fauglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D510",
      "lat": "43.5669798",
      "lon": "10.5160403",
      "superficie_kmq": "42.4313",
      "codice_sovracomunale": "050"
    },
    {
      "sigla_provincia": "PI",
      "codice_istat": "050015",
      "denominazione_ita_altra": "Guardistallo",
      "denominazione_ita": "Guardistallo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E250",
      "lat": "43.3132989",
      "lon": "10.6329907",
      "superficie_kmq": "23.6101",
      "codice_sovracomunale": "050"
    },
    {
      "sigla_provincia": "PI",
      "codice_istat": "050016",
      "denominazione_ita_altra": "Lajatico",
      "denominazione_ita": "Lajatico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E413",
      "lat": "43.4750514",
      "lon": "10.7285119",
      "superficie_kmq": "72.6651",
      "codice_sovracomunale": "050"
    },
    {
      "sigla_provincia": "PI",
      "codice_istat": "050019",
      "denominazione_ita_altra": "Montecatini Val di Cecina",
      "denominazione_ita": "Montecatini Val di Cecina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F458",
      "lat": "43.3919835",
      "lon": "10.7481589",
      "superficie_kmq": "154.9495",
      "codice_sovracomunale": "050"
    },
    {
      "sigla_provincia": "PI",
      "codice_istat": "050020",
      "denominazione_ita_altra": "Montescudaio",
      "denominazione_ita": "Montescudaio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F640",
      "lat": "43.3263275",
      "lon": "10.6277863",
      "superficie_kmq": "20.1518",
      "codice_sovracomunale": "050"
    },
    {
      "sigla_provincia": "PI",
      "codice_istat": "050021",
      "denominazione_ita_altra": "Monteverdi Marittimo",
      "denominazione_ita": "Monteverdi Marittimo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F661",
      "lat": "43.1769768",
      "lon": "10.7152486",
      "superficie_kmq": "98.0741",
      "codice_sovracomunale": "050"
    },
    {
      "sigla_provincia": "PI",
      "codice_istat": "050022",
      "denominazione_ita_altra": "Montopoli in Val d'Arno",
      "denominazione_ita": "Montopoli in Val d'Arno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F686",
      "lat": "43.6685666",
      "lon": "10.7604257",
      "superficie_kmq": "30.1734",
      "codice_sovracomunale": "050"
    },
    {
      "sigla_provincia": "PI",
      "codice_istat": "050023",
      "denominazione_ita_altra": "Orciano Pisano",
      "denominazione_ita": "Orciano Pisano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G090",
      "lat": "43.4912411",
      "lon": "10.5101093",
      "superficie_kmq": "11.6218",
      "codice_sovracomunale": "050"
    },
    {
      "sigla_provincia": "PI",
      "codice_istat": "050024",
      "denominazione_ita_altra": "Palaia",
      "denominazione_ita": "Palaia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G254",
      "lat": "43.6048010",
      "lon": "10.7730782",
      "superficie_kmq": "73.6440",
      "codice_sovracomunale": "050"
    },
    {
      "sigla_provincia": "PI",
      "codice_istat": "050025",
      "denominazione_ita_altra": "Peccioli",
      "denominazione_ita": "Peccioli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G395",
      "lat": "43.5479830",
      "lon": "10.7210472",
      "superficie_kmq": "92.5368",
      "codice_sovracomunale": "050"
    },
    {
      "sigla_provincia": "PI",
      "codice_istat": "050026",
      "denominazione_ita_altra": "Pisa",
      "denominazione_ita": "Pisa",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "G702",
      "lat": "43.7155115",
      "lon": "10.4012721",
      "superficie_kmq": "185.0690",
      "codice_sovracomunale": "050"
    },
    {
      "sigla_provincia": "PI",
      "codice_istat": "050027",
      "denominazione_ita_altra": "Pomarance",
      "denominazione_ita": "Pomarance",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G804",
      "lat": "43.3000927",
      "lon": "10.8738165",
      "superficie_kmq": "227.8437",
      "codice_sovracomunale": "050"
    },
    {
      "sigla_provincia": "PI",
      "codice_istat": "050028",
      "denominazione_ita_altra": "Ponsacco",
      "denominazione_ita": "Ponsacco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G822",
      "lat": "43.6201897",
      "lon": "10.6336059",
      "superficie_kmq": "19.8828",
      "codice_sovracomunale": "050"
    },
    {
      "sigla_provincia": "PI",
      "codice_istat": "050029",
      "denominazione_ita_altra": "Pontedera",
      "denominazione_ita": "Pontedera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G843",
      "lat": "43.6639038",
      "lon": "10.6360093",
      "superficie_kmq": "46.0346",
      "codice_sovracomunale": "050"
    },
    {
      "sigla_provincia": "PI",
      "codice_istat": "050030",
      "denominazione_ita_altra": "Riparbella",
      "denominazione_ita": "Riparbella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H319",
      "lat": "43.3646575",
      "lon": "10.5997029",
      "superficie_kmq": "58.9604",
      "codice_sovracomunale": "050"
    },
    {
      "sigla_provincia": "PI",
      "codice_istat": "050031",
      "denominazione_ita_altra": "San Giuliano Terme",
      "denominazione_ita": "San Giuliano Terme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A562",
      "lat": "43.7633217",
      "lon": "10.4424890",
      "superficie_kmq": "91.9400",
      "codice_sovracomunale": "050"
    },
    {
      "sigla_provincia": "PI",
      "codice_istat": "050032",
      "denominazione_ita_altra": "San Miniato",
      "denominazione_ita": "San Miniato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I046",
      "lat": "43.6793861",
      "lon": "10.8523899",
      "superficie_kmq": "102.5828",
      "codice_sovracomunale": "050"
    },
    {
      "sigla_provincia": "PI",
      "codice_istat": "050033",
      "denominazione_ita_altra": "Santa Croce sull'Arno",
      "denominazione_ita": "Santa Croce sull'Arno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I177",
      "lat": "43.7114189",
      "lon": "10.7783903",
      "superficie_kmq": "16.7885",
      "codice_sovracomunale": "050"
    },
    {
      "sigla_provincia": "PI",
      "codice_istat": "050034",
      "denominazione_ita_altra": "Santa Luce",
      "denominazione_ita": "Santa Luce",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I217",
      "lat": "43.4724786",
      "lon": "10.5648185",
      "superficie_kmq": "66.6216",
      "codice_sovracomunale": "050"
    },
    {
      "sigla_provincia": "PI",
      "codice_istat": "050035",
      "denominazione_ita_altra": "Santa Maria a Monte",
      "denominazione_ita": "Santa Maria a Monte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I232",
      "lat": "43.6986444",
      "lon": "10.6923676",
      "superficie_kmq": "38.0351",
      "codice_sovracomunale": "050"
    },
    {
      "sigla_provincia": "PI",
      "codice_istat": "050036",
      "denominazione_ita_altra": "Terricciola",
      "denominazione_ita": "Terricciola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L138",
      "lat": "43.5246158",
      "lon": "10.6760378",
      "superficie_kmq": "43.2830",
      "codice_sovracomunale": "050"
    },
    {
      "sigla_provincia": "PI",
      "codice_istat": "050037",
      "denominazione_ita_altra": "Vecchiano",
      "denominazione_ita": "Vecchiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L702",
      "lat": "43.7815783",
      "lon": "10.3865988",
      "superficie_kmq": "67.5491",
      "codice_sovracomunale": "050"
    },
    {
      "sigla_provincia": "PI",
      "codice_istat": "050038",
      "denominazione_ita_altra": "Vicopisano",
      "denominazione_ita": "Vicopisano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L850",
      "lat": "43.6997341",
      "lon": "10.5843905",
      "superficie_kmq": "26.8681",
      "codice_sovracomunale": "050"
    },
    {
      "sigla_provincia": "PI",
      "codice_istat": "050039",
      "denominazione_ita_altra": "Volterra",
      "denominazione_ita": "Volterra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M126",
      "lat": "43.4016830",
      "lon": "10.8594125",
      "superficie_kmq": "252.6378",
      "codice_sovracomunale": "050"
    },
    {
      "sigla_provincia": "PI",
      "codice_istat": "050040",
      "denominazione_ita_altra": "Casciana Terme Lari",
      "denominazione_ita": "Casciana Terme Lari",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M327",
      "lat": "43.5558002",
      "lon": "10.5963310",
      "superficie_kmq": "81.3978",
      "codice_sovracomunale": "050"
    },
    {
      "sigla_provincia": "PI",
      "codice_istat": "050041",
      "denominazione_ita_altra": "Crespina Lorenzana",
      "denominazione_ita": "Crespina Lorenzana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M328",
      "lat": "43.5697374",
      "lon": "10.5406727",
      "superficie_kmq": "46.4332",
      "codice_sovracomunale": "050"
    },
    {
      "sigla_provincia": "AR",
      "codice_istat": "051001",
      "denominazione_ita_altra": "Anghiari",
      "denominazione_ita": "Anghiari",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A291",
      "lat": "43.5405065",
      "lon": "12.0568387",
      "superficie_kmq": "130.9192",
      "codice_sovracomunale": "051"
    },
    {
      "sigla_provincia": "AR",
      "codice_istat": "051002",
      "denominazione_ita_altra": "Arezzo",
      "denominazione_ita": "Arezzo",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "A390",
      "lat": "43.4664037",
      "lon": "11.8822873",
      "superficie_kmq": "384.7537",
      "codice_sovracomunale": "051"
    },
    {
      "sigla_provincia": "AR",
      "codice_istat": "051003",
      "denominazione_ita_altra": "Badia Tedalda",
      "denominazione_ita": "Badia Tedalda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A541",
      "lat": "43.7063888",
      "lon": "12.1855778",
      "superficie_kmq": "118.7171",
      "codice_sovracomunale": "051"
    },
    {
      "sigla_provincia": "AR",
      "codice_istat": "051004",
      "denominazione_ita_altra": "Bibbiena",
      "denominazione_ita": "Bibbiena",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A851",
      "lat": "43.6944369",
      "lon": "11.8181405",
      "superficie_kmq": "86.5122",
      "codice_sovracomunale": "051"
    },
    {
      "sigla_provincia": "AR",
      "codice_istat": "051005",
      "denominazione_ita_altra": "Bucine",
      "denominazione_ita": "Bucine",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B243",
      "lat": "43.4790571",
      "lon": "11.6168825",
      "superficie_kmq": "131.6157",
      "codice_sovracomunale": "051"
    },
    {
      "sigla_provincia": "AR",
      "codice_istat": "051006",
      "denominazione_ita_altra": "Capolona",
      "denominazione_ita": "Capolona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B670",
      "lat": "43.5634070",
      "lon": "11.8598429",
      "superficie_kmq": "47.5561",
      "codice_sovracomunale": "051"
    },
    {
      "sigla_provincia": "AR",
      "codice_istat": "051007",
      "denominazione_ita_altra": "Caprese Michelangelo",
      "denominazione_ita": "Caprese Michelangelo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B693",
      "lat": "43.6439734",
      "lon": "11.9857730",
      "superficie_kmq": "66.5302",
      "codice_sovracomunale": "051"
    },
    {
      "sigla_provincia": "AR",
      "codice_istat": "051008",
      "denominazione_ita_altra": "Castel Focognano",
      "denominazione_ita": "Castel Focognano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C102",
      "lat": "43.6515736",
      "lon": "11.8356385",
      "superficie_kmq": "56.6283",
      "codice_sovracomunale": "051"
    },
    {
      "sigla_provincia": "AR",
      "codice_istat": "051010",
      "denominazione_ita_altra": "Castel San Niccolò",
      "denominazione_ita": "Castel San Niccolò",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C263",
      "lat": "43.7410238",
      "lon": "11.7045904",
      "superficie_kmq": "83.2702",
      "codice_sovracomunale": "051"
    },
    {
      "sigla_provincia": "AR",
      "codice_istat": "051011",
      "denominazione_ita_altra": "Castiglion Fibocchi",
      "denominazione_ita": "Castiglion Fibocchi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C318",
      "lat": "43.5275431",
      "lon": "11.7632146",
      "superficie_kmq": "25.4628",
      "codice_sovracomunale": "051"
    },
    {
      "sigla_provincia": "AR",
      "codice_istat": "051012",
      "denominazione_ita_altra": "Castiglion Fiorentino",
      "denominazione_ita": "Castiglion Fiorentino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C319",
      "lat": "43.3416168",
      "lon": "11.9238646",
      "superficie_kmq": "111.4256",
      "codice_sovracomunale": "051"
    },
    {
      "sigla_provincia": "AR",
      "codice_istat": "051013",
      "denominazione_ita_altra": "Cavriglia",
      "denominazione_ita": "Cavriglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C407",
      "lat": "43.5236707",
      "lon": "11.4890615",
      "superficie_kmq": "60.8681",
      "codice_sovracomunale": "051"
    },
    {
      "sigla_provincia": "AR",
      "codice_istat": "051014",
      "denominazione_ita_altra": "Chitignano",
      "denominazione_ita": "Chitignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C648",
      "lat": "43.6613609",
      "lon": "11.8802634",
      "superficie_kmq": "14.8936",
      "codice_sovracomunale": "051"
    },
    {
      "sigla_provincia": "AR",
      "codice_istat": "051015",
      "denominazione_ita_altra": "Chiusi della Verna",
      "denominazione_ita": "Chiusi della Verna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C663",
      "lat": "43.6996976",
      "lon": "11.9350084",
      "superficie_kmq": "102.3216",
      "codice_sovracomunale": "051"
    },
    {
      "sigla_provincia": "AR",
      "codice_istat": "051016",
      "denominazione_ita_altra": "Civitella in Val di Chiana",
      "denominazione_ita": "Civitella in Val di Chiana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C774",
      "lat": "43.4031888",
      "lon": "11.7709098",
      "superficie_kmq": "100.3260",
      "codice_sovracomunale": "051"
    },
    {
      "sigla_provincia": "AR",
      "codice_istat": "051017",
      "denominazione_ita_altra": "Cortona",
      "denominazione_ita": "Cortona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D077",
      "lat": "43.2750868",
      "lon": "11.9850545",
      "superficie_kmq": "342.7316",
      "codice_sovracomunale": "051"
    },
    {
      "sigla_provincia": "AR",
      "codice_istat": "051018",
      "denominazione_ita_altra": "Foiano della Chiana",
      "denominazione_ita": "Foiano della Chiana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D649",
      "lat": "43.2524584",
      "lon": "11.8184711",
      "superficie_kmq": "40.7826",
      "codice_sovracomunale": "051"
    },
    {
      "sigla_provincia": "AR",
      "codice_istat": "051020",
      "denominazione_ita_altra": "Loro Ciuffenna",
      "denominazione_ita": "Loro Ciuffenna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E693",
      "lat": "43.5932904",
      "lon": "11.6315368",
      "superficie_kmq": "86.5219",
      "codice_sovracomunale": "051"
    },
    {
      "sigla_provincia": "AR",
      "codice_istat": "051021",
      "denominazione_ita_altra": "Lucignano",
      "denominazione_ita": "Lucignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E718",
      "lat": "43.2754680",
      "lon": "11.7470731",
      "superficie_kmq": "44.9309",
      "codice_sovracomunale": "051"
    },
    {
      "sigla_provincia": "AR",
      "codice_istat": "051022",
      "denominazione_ita_altra": "Marciano della Chiana",
      "denominazione_ita": "Marciano della Chiana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E933",
      "lat": "43.3051058",
      "lon": "11.7863550",
      "superficie_kmq": "23.6944",
      "codice_sovracomunale": "051"
    },
    {
      "sigla_provincia": "AR",
      "codice_istat": "051023",
      "denominazione_ita_altra": "Montemignaio",
      "denominazione_ita": "Montemignaio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F565",
      "lat": "43.7401038",
      "lon": "11.6199087",
      "superficie_kmq": "25.9430",
      "codice_sovracomunale": "051"
    },
    {
      "sigla_provincia": "AR",
      "codice_istat": "051024",
      "denominazione_ita_altra": "Monterchi",
      "denominazione_ita": "Monterchi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F594",
      "lat": "43.4855760",
      "lon": "12.1118963",
      "superficie_kmq": "29.4156",
      "codice_sovracomunale": "051"
    },
    {
      "sigla_provincia": "AR",
      "codice_istat": "051025",
      "denominazione_ita_altra": "Monte San Savino",
      "denominazione_ita": "Monte San Savino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F628",
      "lat": "43.3313595",
      "lon": "11.7253349",
      "superficie_kmq": "89.8062",
      "codice_sovracomunale": "051"
    },
    {
      "sigla_provincia": "AR",
      "codice_istat": "051026",
      "denominazione_ita_altra": "Montevarchi",
      "denominazione_ita": "Montevarchi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F656",
      "lat": "43.5235828",
      "lon": "11.5674690",
      "superficie_kmq": "56.6641",
      "codice_sovracomunale": "051"
    },
    {
      "sigla_provincia": "AR",
      "codice_istat": "051027",
      "denominazione_ita_altra": "Ortignano Raggiolo",
      "denominazione_ita": "Ortignano Raggiolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G139",
      "lat": "43.6814932",
      "lon": "11.7525081",
      "superficie_kmq": "36.3016",
      "codice_sovracomunale": "051"
    },
    {
      "sigla_provincia": "AR",
      "codice_istat": "051030",
      "denominazione_ita_altra": "Pieve Santo Stefano",
      "denominazione_ita": "Pieve Santo Stefano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G653",
      "lat": "43.6684756",
      "lon": "12.0417445",
      "superficie_kmq": "156.0867",
      "codice_sovracomunale": "051"
    },
    {
      "sigla_provincia": "AR",
      "codice_istat": "051031",
      "denominazione_ita_altra": "Poppi",
      "denominazione_ita": "Poppi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G879",
      "lat": "43.7245171",
      "lon": "11.7653041",
      "superficie_kmq": "97.0817",
      "codice_sovracomunale": "051"
    },
    {
      "sigla_provincia": "AR",
      "codice_istat": "051033",
      "denominazione_ita_altra": "San Giovanni Valdarno",
      "denominazione_ita": "San Giovanni Valdarno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H901",
      "lat": "43.5667136",
      "lon": "11.5296269",
      "superficie_kmq": "21.4497",
      "codice_sovracomunale": "051"
    },
    {
      "sigla_provincia": "AR",
      "codice_istat": "051034",
      "denominazione_ita_altra": "Sansepolcro",
      "denominazione_ita": "Sansepolcro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I155",
      "lat": "43.5701235",
      "lon": "12.1413210",
      "superficie_kmq": "91.1928",
      "codice_sovracomunale": "051"
    },
    {
      "sigla_provincia": "AR",
      "codice_istat": "051035",
      "denominazione_ita_altra": "Sestino",
      "denominazione_ita": "Sestino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I681",
      "lat": "43.7083966",
      "lon": "12.2969681",
      "superficie_kmq": "80.2296",
      "codice_sovracomunale": "051"
    },
    {
      "sigla_provincia": "AR",
      "codice_istat": "051037",
      "denominazione_ita_altra": "Subbiano",
      "denominazione_ita": "Subbiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I991",
      "lat": "43.5755368",
      "lon": "11.8702798",
      "superficie_kmq": "77.8418",
      "codice_sovracomunale": "051"
    },
    {
      "sigla_provincia": "AR",
      "codice_istat": "051038",
      "denominazione_ita_altra": "Talla",
      "denominazione_ita": "Talla",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L038",
      "lat": "43.6019828",
      "lon": "11.7871276",
      "superficie_kmq": "59.8867",
      "codice_sovracomunale": "051"
    },
    {
      "sigla_provincia": "AR",
      "codice_istat": "051039",
      "denominazione_ita_altra": "Terranuova Bracciolini",
      "denominazione_ita": "Terranuova Bracciolini",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L123",
      "lat": "43.5509247",
      "lon": "11.5858277",
      "superficie_kmq": "85.8786",
      "codice_sovracomunale": "051"
    },
    {
      "sigla_provincia": "AR",
      "codice_istat": "051040",
      "denominazione_ita_altra": "Castelfranco Piandiscò",
      "denominazione_ita": "Castelfranco Piandiscò",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M322",
      "lat": "43.6383193",
      "lon": "11.5596410",
      "superficie_kmq": "55.9910",
      "codice_sovracomunale": "051"
    },
    {
      "sigla_provincia": "AR",
      "codice_istat": "051041",
      "denominazione_ita_altra": "Pratovecchio Stia",
      "denominazione_ita": "Pratovecchio Stia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M329",
      "lat": "43.8168850",
      "lon": "11.7054718",
      "superficie_kmq": "138.2261",
      "codice_sovracomunale": "051"
    },
    {
      "sigla_provincia": "AR",
      "codice_istat": "051042",
      "denominazione_ita_altra": "Laterina Pergine Valdarno",
      "denominazione_ita": "Laterina Pergine Valdarno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M392",
      "lat": "43.4781881",
      "lon": "11.6879760",
      "superficie_kmq": "70.5358",
      "codice_sovracomunale": "051"
    },
    {
      "sigla_provincia": "SI",
      "codice_istat": "052001",
      "denominazione_ita_altra": "Abbadia San Salvatore",
      "denominazione_ita": "Abbadia San Salvatore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A006",
      "lat": "42.8809367",
      "lon": "11.6775051",
      "superficie_kmq": "58.9933",
      "codice_sovracomunale": "052"
    },
    {
      "sigla_provincia": "SI",
      "codice_istat": "052002",
      "denominazione_ita_altra": "Asciano",
      "denominazione_ita": "Asciano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A461",
      "lat": "43.2349140",
      "lon": "11.5594448",
      "superficie_kmq": "215.6315",
      "codice_sovracomunale": "052"
    },
    {
      "sigla_provincia": "SI",
      "codice_istat": "052003",
      "denominazione_ita_altra": "Buonconvento",
      "denominazione_ita": "Buonconvento",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B269",
      "lat": "43.1379030",
      "lon": "11.4819049",
      "superficie_kmq": "64.8436",
      "codice_sovracomunale": "052"
    },
    {
      "sigla_provincia": "SI",
      "codice_istat": "052004",
      "denominazione_ita_altra": "Casole d'Elsa",
      "denominazione_ita": "Casole d'Elsa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B984",
      "lat": "43.3404788",
      "lon": "11.0431284",
      "superficie_kmq": "148.7041",
      "codice_sovracomunale": "052"
    },
    {
      "sigla_provincia": "SI",
      "codice_istat": "052005",
      "denominazione_ita_altra": "Castellina in Chianti",
      "denominazione_ita": "Castellina in Chianti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C172",
      "lat": "43.4699878",
      "lon": "11.2856570",
      "superficie_kmq": "99.7990",
      "codice_sovracomunale": "052"
    },
    {
      "sigla_provincia": "SI",
      "codice_istat": "052006",
      "denominazione_ita_altra": "Castelnuovo Berardenga",
      "denominazione_ita": "Castelnuovo Berardenga",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C227",
      "lat": "43.3454656",
      "lon": "11.5031356",
      "superficie_kmq": "177.1077",
      "codice_sovracomunale": "052"
    },
    {
      "sigla_provincia": "SI",
      "codice_istat": "052007",
      "denominazione_ita_altra": "Castiglione d'Orcia",
      "denominazione_ita": "Castiglione d'Orcia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C313",
      "lat": "43.0043472",
      "lon": "11.6163919",
      "superficie_kmq": "141.5389",
      "codice_sovracomunale": "052"
    },
    {
      "sigla_provincia": "SI",
      "codice_istat": "052008",
      "denominazione_ita_altra": "Cetona",
      "denominazione_ita": "Cetona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C587",
      "lat": "42.9628611",
      "lon": "11.9006440",
      "superficie_kmq": "53.5433",
      "codice_sovracomunale": "052"
    },
    {
      "sigla_provincia": "SI",
      "codice_istat": "052009",
      "denominazione_ita_altra": "Chianciano Terme",
      "denominazione_ita": "Chianciano Terme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C608",
      "lat": "43.0588097",
      "lon": "11.8318073",
      "superficie_kmq": "36.5614",
      "codice_sovracomunale": "052"
    },
    {
      "sigla_provincia": "SI",
      "codice_istat": "052010",
      "denominazione_ita_altra": "Chiusdino",
      "denominazione_ita": "Chiusdino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C661",
      "lat": "43.1550532",
      "lon": "11.0881234",
      "superficie_kmq": "141.5667",
      "codice_sovracomunale": "052"
    },
    {
      "sigla_provincia": "SI",
      "codice_istat": "052011",
      "denominazione_ita_altra": "Chiusi",
      "denominazione_ita": "Chiusi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C662",
      "lat": "43.0167030",
      "lon": "11.9476196",
      "superficie_kmq": "58.0687",
      "codice_sovracomunale": "052"
    },
    {
      "sigla_provincia": "SI",
      "codice_istat": "052012",
      "denominazione_ita_altra": "Colle di Val d'Elsa",
      "denominazione_ita": "Colle di Val d'Elsa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C847",
      "lat": "43.4211893",
      "lon": "11.1177509",
      "superficie_kmq": "92.0349",
      "codice_sovracomunale": "052"
    },
    {
      "sigla_provincia": "SI",
      "codice_istat": "052013",
      "denominazione_ita_altra": "Gaiole in Chianti",
      "denominazione_ita": "Gaiole in Chianti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D858",
      "lat": "43.4687370",
      "lon": "11.4334795",
      "superficie_kmq": "128.8832",
      "codice_sovracomunale": "052"
    },
    {
      "sigla_provincia": "SI",
      "codice_istat": "052015",
      "denominazione_ita_altra": "Montepulciano",
      "denominazione_ita": "Montepulciano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F592",
      "lat": "43.0925253",
      "lon": "11.7807007",
      "superficie_kmq": "165.5399",
      "codice_sovracomunale": "052"
    },
    {
      "sigla_provincia": "SI",
      "codice_istat": "052016",
      "denominazione_ita_altra": "Monteriggioni",
      "denominazione_ita": "Monteriggioni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F598",
      "lat": "43.3930374",
      "lon": "11.2186101",
      "superficie_kmq": "99.7309",
      "codice_sovracomunale": "052"
    },
    {
      "sigla_provincia": "SI",
      "codice_istat": "052017",
      "denominazione_ita_altra": "Monteroni d'Arbia",
      "denominazione_ita": "Monteroni d'Arbia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F605",
      "lat": "43.2310602",
      "lon": "11.4218708",
      "superficie_kmq": "105.8556",
      "codice_sovracomunale": "052"
    },
    {
      "sigla_provincia": "SI",
      "codice_istat": "052018",
      "denominazione_ita_altra": "Monticiano",
      "denominazione_ita": "Monticiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F676",
      "lat": "43.1393392",
      "lon": "11.1797241",
      "superficie_kmq": "109.5005",
      "codice_sovracomunale": "052"
    },
    {
      "sigla_provincia": "SI",
      "codice_istat": "052019",
      "denominazione_ita_altra": "Murlo",
      "denominazione_ita": "Murlo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F815",
      "lat": "43.1684603",
      "lon": "11.3911023",
      "superficie_kmq": "114.6246",
      "codice_sovracomunale": "052"
    },
    {
      "sigla_provincia": "SI",
      "codice_istat": "052020",
      "denominazione_ita_altra": "Piancastagnaio",
      "denominazione_ita": "Piancastagnaio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G547",
      "lat": "42.8494033",
      "lon": "11.6918200",
      "superficie_kmq": "69.6264",
      "codice_sovracomunale": "052"
    },
    {
      "sigla_provincia": "SI",
      "codice_istat": "052021",
      "denominazione_ita_altra": "Pienza",
      "denominazione_ita": "Pienza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G602",
      "lat": "43.0762975",
      "lon": "11.6799600",
      "superficie_kmq": "122.8667",
      "codice_sovracomunale": "052"
    },
    {
      "sigla_provincia": "SI",
      "codice_istat": "052022",
      "denominazione_ita_altra": "Poggibonsi",
      "denominazione_ita": "Poggibonsi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G752",
      "lat": "43.4668489",
      "lon": "11.1466271",
      "superficie_kmq": "70.6019",
      "codice_sovracomunale": "052"
    },
    {
      "sigla_provincia": "SI",
      "codice_istat": "052023",
      "denominazione_ita_altra": "Radda in Chianti",
      "denominazione_ita": "Radda in Chianti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H153",
      "lat": "43.4865168",
      "lon": "11.3744093",
      "superficie_kmq": "80.4148",
      "codice_sovracomunale": "052"
    },
    {
      "sigla_provincia": "SI",
      "codice_istat": "052024",
      "denominazione_ita_altra": "Radicofani",
      "denominazione_ita": "Radicofani",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H156",
      "lat": "42.8969576",
      "lon": "11.7663784",
      "superficie_kmq": "117.9750",
      "codice_sovracomunale": "052"
    },
    {
      "sigla_provincia": "SI",
      "codice_istat": "052025",
      "denominazione_ita_altra": "Radicondoli",
      "denominazione_ita": "Radicondoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H157",
      "lat": "43.2610945",
      "lon": "11.0413167",
      "superficie_kmq": "132.5264",
      "codice_sovracomunale": "052"
    },
    {
      "sigla_provincia": "SI",
      "codice_istat": "052026",
      "denominazione_ita_altra": "Rapolano Terme",
      "denominazione_ita": "Rapolano Terme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H185",
      "lat": "43.2865083",
      "lon": "11.6041574",
      "superficie_kmq": "83.0208",
      "codice_sovracomunale": "052"
    },
    {
      "sigla_provincia": "SI",
      "codice_istat": "052027",
      "denominazione_ita_altra": "San Casciano dei Bagni",
      "denominazione_ita": "San Casciano dei Bagni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H790",
      "lat": "42.8712867",
      "lon": "11.8753615",
      "superficie_kmq": "92.1425",
      "codice_sovracomunale": "052"
    },
    {
      "sigla_provincia": "SI",
      "codice_istat": "052028",
      "denominazione_ita_altra": "San Gimignano",
      "denominazione_ita": "San Gimignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H875",
      "lat": "43.4677438",
      "lon": "11.0431776",
      "superficie_kmq": "138.5687",
      "codice_sovracomunale": "052"
    },
    {
      "sigla_provincia": "SI",
      "codice_istat": "052030",
      "denominazione_ita_altra": "San Quirico d'Orcia",
      "denominazione_ita": "San Quirico d'Orcia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I135",
      "lat": "43.0602398",
      "lon": "11.6032805",
      "superficie_kmq": "42.2351",
      "codice_sovracomunale": "052"
    },
    {
      "sigla_provincia": "SI",
      "codice_istat": "052031",
      "denominazione_ita_altra": "Sarteano",
      "denominazione_ita": "Sarteano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I445",
      "lat": "42.9895709",
      "lon": "11.8691688",
      "superficie_kmq": "84.8432",
      "codice_sovracomunale": "052"
    },
    {
      "sigla_provincia": "SI",
      "codice_istat": "052032",
      "denominazione_ita_altra": "Siena",
      "denominazione_ita": "Siena",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "I726",
      "lat": "43.3181376",
      "lon": "11.3319089",
      "superficie_kmq": "118.5306",
      "codice_sovracomunale": "052"
    },
    {
      "sigla_provincia": "SI",
      "codice_istat": "052033",
      "denominazione_ita_altra": "Sinalunga",
      "denominazione_ita": "Sinalunga",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A468",
      "lat": "43.2123635",
      "lon": "11.7360604",
      "superficie_kmq": "78.6758",
      "codice_sovracomunale": "052"
    },
    {
      "sigla_provincia": "SI",
      "codice_istat": "052034",
      "denominazione_ita_altra": "Sovicille",
      "denominazione_ita": "Sovicille",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I877",
      "lat": "43.2801610",
      "lon": "11.2283419",
      "superficie_kmq": "143.5768",
      "codice_sovracomunale": "052"
    },
    {
      "sigla_provincia": "SI",
      "codice_istat": "052035",
      "denominazione_ita_altra": "Torrita di Siena",
      "denominazione_ita": "Torrita di Siena",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L303",
      "lat": "43.1666089",
      "lon": "11.7718886",
      "superficie_kmq": "58.3390",
      "codice_sovracomunale": "052"
    },
    {
      "sigla_provincia": "SI",
      "codice_istat": "052036",
      "denominazione_ita_altra": "Trequanda",
      "denominazione_ita": "Trequanda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L384",
      "lat": "43.1886255",
      "lon": "11.6677722",
      "superficie_kmq": "63.9492",
      "codice_sovracomunale": "052"
    },
    {
      "sigla_provincia": "SI",
      "codice_istat": "052037",
      "denominazione_ita_altra": "Montalcino",
      "denominazione_ita": "Montalcino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M378",
      "lat": "43.0593726",
      "lon": "11.4881245",
      "superficie_kmq": "310.3936",
      "codice_sovracomunale": "052"
    },
    {
      "sigla_provincia": "GR",
      "codice_istat": "053001",
      "denominazione_ita_altra": "Arcidosso",
      "denominazione_ita": "Arcidosso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A369",
      "lat": "42.8714300",
      "lon": "11.5387930",
      "superficie_kmq": "93.2541",
      "codice_sovracomunale": "053"
    },
    {
      "sigla_provincia": "GR",
      "codice_istat": "053002",
      "denominazione_ita_altra": "Campagnatico",
      "denominazione_ita": "Campagnatico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B497",
      "lat": "42.8827536",
      "lon": "11.2749408",
      "superficie_kmq": "162.2885",
      "codice_sovracomunale": "053"
    },
    {
      "sigla_provincia": "GR",
      "codice_istat": "053003",
      "denominazione_ita_altra": "Capalbio",
      "denominazione_ita": "Capalbio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B646",
      "lat": "42.4532172",
      "lon": "11.4220123",
      "superficie_kmq": "187.3488",
      "codice_sovracomunale": "053"
    },
    {
      "sigla_provincia": "GR",
      "codice_istat": "053004",
      "denominazione_ita_altra": "Castel del Piano",
      "denominazione_ita": "Castel del Piano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C085",
      "lat": "42.8930193",
      "lon": "11.5377390",
      "superficie_kmq": "67.7697",
      "codice_sovracomunale": "053"
    },
    {
      "sigla_provincia": "GR",
      "codice_istat": "053005",
      "denominazione_ita_altra": "Castell'Azzara",
      "denominazione_ita": "Castell'Azzara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C147",
      "lat": "42.7735063",
      "lon": "11.6977734",
      "superficie_kmq": "64.2308",
      "codice_sovracomunale": "053"
    },
    {
      "sigla_provincia": "GR",
      "codice_istat": "053006",
      "denominazione_ita_altra": "Castiglione della Pescaia",
      "denominazione_ita": "Castiglione della Pescaia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C310",
      "lat": "42.7619953",
      "lon": "10.8788282",
      "superficie_kmq": "209.1009",
      "codice_sovracomunale": "053"
    },
    {
      "sigla_provincia": "GR",
      "codice_istat": "053007",
      "denominazione_ita_altra": "Cinigiano",
      "denominazione_ita": "Cinigiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C705",
      "lat": "42.8910518",
      "lon": "11.3941704",
      "superficie_kmq": "161.5518",
      "codice_sovracomunale": "053"
    },
    {
      "sigla_provincia": "GR",
      "codice_istat": "053008",
      "denominazione_ita_altra": "Civitella Paganico",
      "denominazione_ita": "Civitella Paganico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C782",
      "lat": "42.9939429",
      "lon": "11.2816156",
      "superficie_kmq": "192.9001",
      "codice_sovracomunale": "053"
    },
    {
      "sigla_provincia": "GR",
      "codice_istat": "053009",
      "denominazione_ita_altra": "Follonica",
      "denominazione_ita": "Follonica",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D656",
      "lat": "42.9225695",
      "lon": "10.7598806",
      "superficie_kmq": "56.0491",
      "codice_sovracomunale": "053"
    },
    {
      "sigla_provincia": "GR",
      "codice_istat": "053010",
      "denominazione_ita_altra": "Gavorrano",
      "denominazione_ita": "Gavorrano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D948",
      "lat": "42.9251595",
      "lon": "10.9056809",
      "superficie_kmq": "164.0818",
      "codice_sovracomunale": "053"
    },
    {
      "sigla_provincia": "GR",
      "codice_istat": "053011",
      "denominazione_ita_altra": "Grosseto",
      "denominazione_ita": "Grosseto",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "E202",
      "lat": "42.7602332",
      "lon": "11.1135628",
      "superficie_kmq": "473.5252",
      "codice_sovracomunale": "053"
    },
    {
      "sigla_provincia": "GR",
      "codice_istat": "053012",
      "denominazione_ita_altra": "Isola del Giglio",
      "denominazione_ita": "Isola del Giglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E348",
      "lat": "42.3656696",
      "lon": "10.9008994",
      "superficie_kmq": "24.0114",
      "codice_sovracomunale": "053"
    },
    {
      "sigla_provincia": "GR",
      "codice_istat": "053013",
      "denominazione_ita_altra": "Magliano in Toscana",
      "denominazione_ita": "Magliano in Toscana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E810",
      "lat": "42.5985197",
      "lon": "11.2935352",
      "superficie_kmq": "250.8568",
      "codice_sovracomunale": "053"
    },
    {
      "sigla_provincia": "GR",
      "codice_istat": "053014",
      "denominazione_ita_altra": "Manciano",
      "denominazione_ita": "Manciano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E875",
      "lat": "42.5873032",
      "lon": "11.5154592",
      "superficie_kmq": "372.4982",
      "codice_sovracomunale": "053"
    },
    {
      "sigla_provincia": "GR",
      "codice_istat": "053015",
      "denominazione_ita_altra": "Massa Marittima",
      "denominazione_ita": "Massa Marittima",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F032",
      "lat": "43.0501698",
      "lon": "10.8879336",
      "superficie_kmq": "283.4428",
      "codice_sovracomunale": "053"
    },
    {
      "sigla_provincia": "GR",
      "codice_istat": "053016",
      "denominazione_ita_altra": "Monte Argentario",
      "denominazione_ita": "Monte Argentario",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F437",
      "lat": "42.4391142",
      "lon": "11.1158415",
      "superficie_kmq": "60.4003",
      "codice_sovracomunale": "053"
    },
    {
      "sigla_provincia": "GR",
      "codice_istat": "053017",
      "denominazione_ita_altra": "Montieri",
      "denominazione_ita": "Montieri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F677",
      "lat": "43.1304644",
      "lon": "11.0162132",
      "superficie_kmq": "108.2049",
      "codice_sovracomunale": "053"
    },
    {
      "sigla_provincia": "GR",
      "codice_istat": "053018",
      "denominazione_ita_altra": "Orbetello",
      "denominazione_ita": "Orbetello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G088",
      "lat": "42.4373334",
      "lon": "11.2094266",
      "superficie_kmq": "226.8069",
      "codice_sovracomunale": "053"
    },
    {
      "sigla_provincia": "GR",
      "codice_istat": "053019",
      "denominazione_ita_altra": "Pitigliano",
      "denominazione_ita": "Pitigliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G716",
      "lat": "42.6348800",
      "lon": "11.6694148",
      "superficie_kmq": "101.9591",
      "codice_sovracomunale": "053"
    },
    {
      "sigla_provincia": "GR",
      "codice_istat": "053020",
      "denominazione_ita_altra": "Roccalbegna",
      "denominazione_ita": "Roccalbegna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H417",
      "lat": "42.7841087",
      "lon": "11.5092531",
      "superficie_kmq": "124.8586",
      "codice_sovracomunale": "053"
    },
    {
      "sigla_provincia": "GR",
      "codice_istat": "053021",
      "denominazione_ita_altra": "Roccastrada",
      "denominazione_ita": "Roccastrada",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H449",
      "lat": "43.0111828",
      "lon": "11.1674272",
      "superficie_kmq": "284.4636",
      "codice_sovracomunale": "053"
    },
    {
      "sigla_provincia": "GR",
      "codice_istat": "053022",
      "denominazione_ita_altra": "Santa Fiora",
      "denominazione_ita": "Santa Fiora",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I187",
      "lat": "42.8313275",
      "lon": "11.5857482",
      "superficie_kmq": "63.4512",
      "codice_sovracomunale": "053"
    },
    {
      "sigla_provincia": "GR",
      "codice_istat": "053023",
      "denominazione_ita_altra": "Scansano",
      "denominazione_ita": "Scansano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I504",
      "lat": "42.6878579",
      "lon": "11.3331490",
      "superficie_kmq": "273.5600",
      "codice_sovracomunale": "053"
    },
    {
      "sigla_provincia": "GR",
      "codice_istat": "053024",
      "denominazione_ita_altra": "Scarlino",
      "denominazione_ita": "Scarlino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I510",
      "lat": "42.9072699",
      "lon": "10.8519362",
      "superficie_kmq": "88.2265",
      "codice_sovracomunale": "053"
    },
    {
      "sigla_provincia": "GR",
      "codice_istat": "053025",
      "denominazione_ita_altra": "Seggiano",
      "denominazione_ita": "Seggiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I571",
      "lat": "42.9279557",
      "lon": "11.5573127",
      "superficie_kmq": "49.4287",
      "codice_sovracomunale": "053"
    },
    {
      "sigla_provincia": "GR",
      "codice_istat": "053026",
      "denominazione_ita_altra": "Sorano",
      "denominazione_ita": "Sorano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I841",
      "lat": "42.6816970",
      "lon": "11.7138281",
      "superficie_kmq": "174.5585",
      "codice_sovracomunale": "053"
    },
    {
      "sigla_provincia": "GR",
      "codice_istat": "053027",
      "denominazione_ita_altra": "Monterotondo Marittimo",
      "denominazione_ita": "Monterotondo Marittimo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F612",
      "lat": "43.1454784",
      "lon": "10.8558467",
      "superficie_kmq": "102.6929",
      "codice_sovracomunale": "053"
    },
    {
      "sigla_provincia": "GR",
      "codice_istat": "053028",
      "denominazione_ita_altra": "Semproniano",
      "denominazione_ita": "Semproniano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I601",
      "lat": "42.7302137",
      "lon": "11.5416900",
      "superficie_kmq": "81.6534",
      "codice_sovracomunale": "053"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054001",
      "denominazione_ita_altra": "Assisi",
      "denominazione_ita": "Assisi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A475",
      "lat": "43.0707984",
      "lon": "12.6154022",
      "superficie_kmq": "187.1913",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054002",
      "denominazione_ita_altra": "Bastia Umbra",
      "denominazione_ita": "Bastia Umbra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A710",
      "lat": "43.0664771",
      "lon": "12.5492581",
      "superficie_kmq": "27.6014",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054003",
      "denominazione_ita_altra": "Bettona",
      "denominazione_ita": "Bettona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A832",
      "lat": "43.0129345",
      "lon": "12.4849300",
      "superficie_kmq": "45.0747",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054004",
      "denominazione_ita_altra": "Bevagna",
      "denominazione_ita": "Bevagna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A835",
      "lat": "42.9339041",
      "lon": "12.6091241",
      "superficie_kmq": "56.2226",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054005",
      "denominazione_ita_altra": "Campello sul Clitunno",
      "denominazione_ita": "Campello sul Clitunno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B504",
      "lat": "42.8200440",
      "lon": "12.7769265",
      "superficie_kmq": "49.7616",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054006",
      "denominazione_ita_altra": "Cannara",
      "denominazione_ita": "Cannara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B609",
      "lat": "42.9954109",
      "lon": "12.5832541",
      "superficie_kmq": "32.8087",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054007",
      "denominazione_ita_altra": "Cascia",
      "denominazione_ita": "Cascia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B948",
      "lat": "42.7182121",
      "lon": "13.0131372",
      "superficie_kmq": "180.8336",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054008",
      "denominazione_ita_altra": "Castel Ritaldi",
      "denominazione_ita": "Castel Ritaldi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C252",
      "lat": "42.8230190",
      "lon": "12.6722572",
      "superficie_kmq": "22.4414",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054009",
      "denominazione_ita_altra": "Castiglione del Lago",
      "denominazione_ita": "Castiglione del Lago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C309",
      "lat": "43.1272492",
      "lon": "12.0535753",
      "superficie_kmq": "205.2661",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054010",
      "denominazione_ita_altra": "Cerreto di Spoleto",
      "denominazione_ita": "Cerreto di Spoleto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C527",
      "lat": "42.8193017",
      "lon": "12.9163868",
      "superficie_kmq": "74.7826",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054011",
      "denominazione_ita_altra": "Citerna",
      "denominazione_ita": "Citerna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C742",
      "lat": "43.4982439",
      "lon": "12.1164689",
      "superficie_kmq": "23.5288",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054012",
      "denominazione_ita_altra": "Città della Pieve",
      "denominazione_ita": "Città della Pieve",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C744",
      "lat": "42.9527860",
      "lon": "12.0050617",
      "superficie_kmq": "110.9444",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054013",
      "denominazione_ita_altra": "Città di Castello",
      "denominazione_ita": "Città di Castello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C745",
      "lat": "43.4573549",
      "lon": "12.2381457",
      "superficie_kmq": "387.3170",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054014",
      "denominazione_ita_altra": "Collazzone",
      "denominazione_ita": "Collazzone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C845",
      "lat": "42.8942311",
      "lon": "12.4378851",
      "superficie_kmq": "55.5632",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054015",
      "denominazione_ita_altra": "Corciano",
      "denominazione_ita": "Corciano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C990",
      "lat": "43.1285205",
      "lon": "12.2863992",
      "superficie_kmq": "63.7230",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054016",
      "denominazione_ita_altra": "Costacciaro",
      "denominazione_ita": "Costacciaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D108",
      "lat": "43.3588834",
      "lon": "12.7119188",
      "superficie_kmq": "41.0597",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054017",
      "denominazione_ita_altra": "Deruta",
      "denominazione_ita": "Deruta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D279",
      "lat": "42.9822073",
      "lon": "12.4198955",
      "superficie_kmq": "44.5600",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054018",
      "denominazione_ita_altra": "Foligno",
      "denominazione_ita": "Foligno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D653",
      "lat": "42.9556643",
      "lon": "12.7035851",
      "superficie_kmq": "264.6560",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054019",
      "denominazione_ita_altra": "Fossato di Vico",
      "denominazione_ita": "Fossato di Vico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D745",
      "lat": "43.2943128",
      "lon": "12.7616461",
      "superficie_kmq": "35.3910",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054020",
      "denominazione_ita_altra": "Fratta Todina",
      "denominazione_ita": "Fratta Todina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D787",
      "lat": "42.8575064",
      "lon": "12.3664319",
      "superficie_kmq": "17.4281",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054021",
      "denominazione_ita_altra": "Giano dell'Umbria",
      "denominazione_ita": "Giano dell'Umbria",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E012",
      "lat": "42.8336802",
      "lon": "12.5771779",
      "superficie_kmq": "44.4751",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054022",
      "denominazione_ita_altra": "Gualdo Cattaneo",
      "denominazione_ita": "Gualdo Cattaneo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E229",
      "lat": "42.9091622",
      "lon": "12.5558388",
      "superficie_kmq": "96.6236",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054023",
      "denominazione_ita_altra": "Gualdo Tadino",
      "denominazione_ita": "Gualdo Tadino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E230",
      "lat": "43.2309229",
      "lon": "12.7848258",
      "superficie_kmq": "124.2833",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054024",
      "denominazione_ita_altra": "Gubbio",
      "denominazione_ita": "Gubbio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E256",
      "lat": "43.3534958",
      "lon": "12.5787539",
      "superficie_kmq": "525.7677",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054025",
      "denominazione_ita_altra": "Lisciano Niccone",
      "denominazione_ita": "Lisciano Niccone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E613",
      "lat": "43.2459971",
      "lon": "12.1433664",
      "superficie_kmq": "35.1821",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054026",
      "denominazione_ita_altra": "Magione",
      "denominazione_ita": "Magione",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E805",
      "lat": "43.1425785",
      "lon": "12.2054071",
      "superficie_kmq": "129.7327",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054027",
      "denominazione_ita_altra": "Marsciano",
      "denominazione_ita": "Marsciano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E975",
      "lat": "42.9101679",
      "lon": "12.3395399",
      "superficie_kmq": "161.5281",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054028",
      "denominazione_ita_altra": "Massa Martana",
      "denominazione_ita": "Massa Martana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F024",
      "lat": "42.7755290",
      "lon": "12.5230116",
      "superficie_kmq": "78.4082",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054029",
      "denominazione_ita_altra": "Monte Castello di Vibio",
      "denominazione_ita": "Monte Castello di Vibio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F456",
      "lat": "42.8402868",
      "lon": "12.3522639",
      "superficie_kmq": "31.9491",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054030",
      "denominazione_ita_altra": "Montefalco",
      "denominazione_ita": "Montefalco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F492",
      "lat": "42.8938884",
      "lon": "12.6525181",
      "superficie_kmq": "69.5123",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054031",
      "denominazione_ita_altra": "Monteleone di Spoleto",
      "denominazione_ita": "Monteleone di Spoleto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F540",
      "lat": "42.6510894",
      "lon": "12.9515866",
      "superficie_kmq": "62.1650",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054032",
      "denominazione_ita_altra": "Monte Santa Maria Tiberina",
      "denominazione_ita": "Monte Santa Maria Tiberina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F629",
      "lat": "43.4367895",
      "lon": "12.1629211",
      "superficie_kmq": "72.5288",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054033",
      "denominazione_ita_altra": "Montone",
      "denominazione_ita": "Montone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F685",
      "lat": "43.3636926",
      "lon": "12.3268374",
      "superficie_kmq": "51.0941",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054034",
      "denominazione_ita_altra": "Nocera Umbra",
      "denominazione_ita": "Nocera Umbra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F911",
      "lat": "43.1109193",
      "lon": "12.7903168",
      "superficie_kmq": "157.1641",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054035",
      "denominazione_ita_altra": "Norcia",
      "denominazione_ita": "Norcia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F935",
      "lat": "42.7923285",
      "lon": "13.0928507",
      "superficie_kmq": "275.5700",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054036",
      "denominazione_ita_altra": "Paciano",
      "denominazione_ita": "Paciano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G212",
      "lat": "43.0220641",
      "lon": "12.0710066",
      "superficie_kmq": "16.9058",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054037",
      "denominazione_ita_altra": "Panicale",
      "denominazione_ita": "Panicale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G308",
      "lat": "43.0293261",
      "lon": "12.1006278",
      "superficie_kmq": "79.2582",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054038",
      "denominazione_ita_altra": "Passignano sul Trasimeno",
      "denominazione_ita": "Passignano sul Trasimeno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G359",
      "lat": "43.1849230",
      "lon": "12.1368822",
      "superficie_kmq": "81.3321",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054039",
      "denominazione_ita_altra": "Perugia",
      "denominazione_ita": "Perugia",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "G478",
      "lat": "43.1067296",
      "lon": "12.3882454",
      "superficie_kmq": "449.6117",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054040",
      "denominazione_ita_altra": "Piegaro",
      "denominazione_ita": "Piegaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G601",
      "lat": "42.9704799",
      "lon": "12.0858198",
      "superficie_kmq": "99.0764",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054041",
      "denominazione_ita_altra": "Pietralunga",
      "denominazione_ita": "Pietralunga",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G618",
      "lat": "43.4421654",
      "lon": "12.4368416",
      "superficie_kmq": "140.4153",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054042",
      "denominazione_ita_altra": "Poggiodomo",
      "denominazione_ita": "Poggiodomo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G758",
      "lat": "42.7116423",
      "lon": "12.9347211",
      "superficie_kmq": "40.0939",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054043",
      "denominazione_ita_altra": "Preci",
      "denominazione_ita": "Preci",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H015",
      "lat": "42.8786491",
      "lon": "13.0387165",
      "superficie_kmq": "82.0201",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054044",
      "denominazione_ita_altra": "San Giustino",
      "denominazione_ita": "San Giustino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H935",
      "lat": "43.5486262",
      "lon": "12.1767513",
      "superficie_kmq": "79.9788",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054045",
      "denominazione_ita_altra": "Sant'Anatolia di Narco",
      "denominazione_ita": "Sant'Anatolia di Narco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I263",
      "lat": "42.7330825",
      "lon": "12.8360060",
      "superficie_kmq": "46.5485",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054046",
      "denominazione_ita_altra": "Scheggia e Pascelupo",
      "denominazione_ita": "Scheggia e Pascelupo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I522",
      "lat": "43.4037120",
      "lon": "12.6679993",
      "superficie_kmq": "64.1501",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054047",
      "denominazione_ita_altra": "Scheggino",
      "denominazione_ita": "Scheggino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I523",
      "lat": "42.7125529",
      "lon": "12.8311874",
      "superficie_kmq": "35.8493",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054048",
      "denominazione_ita_altra": "Sellano",
      "denominazione_ita": "Sellano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I585",
      "lat": "42.8887961",
      "lon": "12.9270671",
      "superficie_kmq": "85.8384",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054049",
      "denominazione_ita_altra": "Sigillo",
      "denominazione_ita": "Sigillo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I727",
      "lat": "43.3312582",
      "lon": "12.7407679",
      "superficie_kmq": "26.4743",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054050",
      "denominazione_ita_altra": "Spello",
      "denominazione_ita": "Spello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I888",
      "lat": "42.9924790",
      "lon": "12.6712599",
      "superficie_kmq": "61.6445",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054051",
      "denominazione_ita_altra": "Spoleto",
      "denominazione_ita": "Spoleto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I921",
      "lat": "42.7344494",
      "lon": "12.7382991",
      "superficie_kmq": "348.1366",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054052",
      "denominazione_ita_altra": "Todi",
      "denominazione_ita": "Todi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L188",
      "lat": "42.7822152",
      "lon": "12.4068949",
      "superficie_kmq": "222.8556",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054053",
      "denominazione_ita_altra": "Torgiano",
      "denominazione_ita": "Torgiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L216",
      "lat": "43.0256964",
      "lon": "12.4339788",
      "superficie_kmq": "37.6662",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054054",
      "denominazione_ita_altra": "Trevi",
      "denominazione_ita": "Trevi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L397",
      "lat": "42.8770975",
      "lon": "12.7476975",
      "superficie_kmq": "71.1853",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054055",
      "denominazione_ita_altra": "Tuoro sul Trasimeno",
      "denominazione_ita": "Tuoro sul Trasimeno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L466",
      "lat": "43.2087310",
      "lon": "12.0717346",
      "superficie_kmq": "55.8849",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054056",
      "denominazione_ita_altra": "Umbertide",
      "denominazione_ita": "Umbertide",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D786",
      "lat": "43.3064364",
      "lon": "12.3269335",
      "superficie_kmq": "200.8295",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054057",
      "denominazione_ita_altra": "Valfabbrica",
      "denominazione_ita": "Valfabbrica",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L573",
      "lat": "43.1593786",
      "lon": "12.6012928",
      "superficie_kmq": "92.3024",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054058",
      "denominazione_ita_altra": "Vallo di Nera",
      "denominazione_ita": "Vallo di Nera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L627",
      "lat": "42.7692824",
      "lon": "12.8622033",
      "superficie_kmq": "36.2211",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "PG",
      "codice_istat": "054059",
      "denominazione_ita_altra": "Valtopina",
      "denominazione_ita": "Valtopina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L653",
      "lat": "43.0590073",
      "lon": "12.7544713",
      "superficie_kmq": "40.5734",
      "codice_sovracomunale": "054"
    },
    {
      "sigla_provincia": "TR",
      "codice_istat": "055001",
      "denominazione_ita_altra": "Acquasparta",
      "denominazione_ita": "Acquasparta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A045",
      "lat": "42.6905926",
      "lon": "12.5463127",
      "superficie_kmq": "81.6058",
      "codice_sovracomunale": "055"
    },
    {
      "sigla_provincia": "TR",
      "codice_istat": "055002",
      "denominazione_ita_altra": "Allerona",
      "denominazione_ita": "Allerona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A207",
      "lat": "42.8116706",
      "lon": "11.9738573",
      "superficie_kmq": "82.6127",
      "codice_sovracomunale": "055"
    },
    {
      "sigla_provincia": "TR",
      "codice_istat": "055003",
      "denominazione_ita_altra": "Alviano",
      "denominazione_ita": "Alviano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A242",
      "lat": "42.5876907",
      "lon": "12.2956463",
      "superficie_kmq": "23.8952",
      "codice_sovracomunale": "055"
    },
    {
      "sigla_provincia": "TR",
      "codice_istat": "055004",
      "denominazione_ita_altra": "Amelia",
      "denominazione_ita": "Amelia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A262",
      "lat": "42.5588602",
      "lon": "12.4119159",
      "superficie_kmq": "132.4964",
      "codice_sovracomunale": "055"
    },
    {
      "sigla_provincia": "TR",
      "codice_istat": "055005",
      "denominazione_ita_altra": "Arrone",
      "denominazione_ita": "Arrone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A439",
      "lat": "42.5831104",
      "lon": "12.7696230",
      "superficie_kmq": "41.0320",
      "codice_sovracomunale": "055"
    },
    {
      "sigla_provincia": "TR",
      "codice_istat": "055006",
      "denominazione_ita_altra": "Attigliano",
      "denominazione_ita": "Attigliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A490",
      "lat": "42.5149005",
      "lon": "12.2894226",
      "superficie_kmq": "10.5120",
      "codice_sovracomunale": "055"
    },
    {
      "sigla_provincia": "TR",
      "codice_istat": "055007",
      "denominazione_ita_altra": "Baschi",
      "denominazione_ita": "Baschi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A691",
      "lat": "42.6700959",
      "lon": "12.2162746",
      "superficie_kmq": "68.5686",
      "codice_sovracomunale": "055"
    },
    {
      "sigla_provincia": "TR",
      "codice_istat": "055008",
      "denominazione_ita_altra": "Calvi dell'Umbria",
      "denominazione_ita": "Calvi dell'Umbria",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B446",
      "lat": "42.4011021",
      "lon": "12.5667841",
      "superficie_kmq": "46.0063",
      "codice_sovracomunale": "055"
    },
    {
      "sigla_provincia": "TR",
      "codice_istat": "055009",
      "denominazione_ita_altra": "Castel Giorgio",
      "denominazione_ita": "Castel Giorgio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C117",
      "lat": "42.7077579",
      "lon": "11.9796003",
      "superficie_kmq": "42.1444",
      "codice_sovracomunale": "055"
    },
    {
      "sigla_provincia": "TR",
      "codice_istat": "055010",
      "denominazione_ita_altra": "Castel Viscardo",
      "denominazione_ita": "Castel Viscardo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C289",
      "lat": "42.7557082",
      "lon": "12.0015334",
      "superficie_kmq": "26.2156",
      "codice_sovracomunale": "055"
    },
    {
      "sigla_provincia": "TR",
      "codice_istat": "055011",
      "denominazione_ita_altra": "Fabro",
      "denominazione_ita": "Fabro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D454",
      "lat": "42.8634143",
      "lon": "12.0129403",
      "superficie_kmq": "34.5504",
      "codice_sovracomunale": "055"
    },
    {
      "sigla_provincia": "TR",
      "codice_istat": "055012",
      "denominazione_ita_altra": "Ferentillo",
      "denominazione_ita": "Ferentillo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D538",
      "lat": "42.6204972",
      "lon": "12.7898650",
      "superficie_kmq": "69.5861",
      "codice_sovracomunale": "055"
    },
    {
      "sigla_provincia": "TR",
      "codice_istat": "055013",
      "denominazione_ita_altra": "Ficulle",
      "denominazione_ita": "Ficulle",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D570",
      "lat": "42.8362872",
      "lon": "12.0663414",
      "superficie_kmq": "64.6187",
      "codice_sovracomunale": "055"
    },
    {
      "sigla_provincia": "TR",
      "codice_istat": "055014",
      "denominazione_ita_altra": "Giove",
      "denominazione_ita": "Giove",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E045",
      "lat": "42.5092485",
      "lon": "12.3250483",
      "superficie_kmq": "15.0882",
      "codice_sovracomunale": "055"
    },
    {
      "sigla_provincia": "TR",
      "codice_istat": "055015",
      "denominazione_ita_altra": "Guardea",
      "denominazione_ita": "Guardea",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E241",
      "lat": "42.6234948",
      "lon": "12.2972366",
      "superficie_kmq": "39.3808",
      "codice_sovracomunale": "055"
    },
    {
      "sigla_provincia": "TR",
      "codice_istat": "055016",
      "denominazione_ita_altra": "Lugnano in Teverina",
      "denominazione_ita": "Lugnano in Teverina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E729",
      "lat": "42.5742504",
      "lon": "12.3303199",
      "superficie_kmq": "29.8297",
      "codice_sovracomunale": "055"
    },
    {
      "sigla_provincia": "TR",
      "codice_istat": "055017",
      "denominazione_ita_altra": "Montecastrilli",
      "denominazione_ita": "Montecastrilli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F457",
      "lat": "42.6497567",
      "lon": "12.4877848",
      "superficie_kmq": "62.4282",
      "codice_sovracomunale": "055"
    },
    {
      "sigla_provincia": "TR",
      "codice_istat": "055018",
      "denominazione_ita_altra": "Montecchio",
      "denominazione_ita": "Montecchio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F462",
      "lat": "42.6623415",
      "lon": "12.2879973",
      "superficie_kmq": "49.2172",
      "codice_sovracomunale": "055"
    },
    {
      "sigla_provincia": "TR",
      "codice_istat": "055019",
      "denominazione_ita_altra": "Montefranco",
      "denominazione_ita": "Montefranco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F510",
      "lat": "42.5970124",
      "lon": "12.7666068",
      "superficie_kmq": "10.0869",
      "codice_sovracomunale": "055"
    },
    {
      "sigla_provincia": "TR",
      "codice_istat": "055020",
      "denominazione_ita_altra": "Montegabbione",
      "denominazione_ita": "Montegabbione",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F513",
      "lat": "42.9212065",
      "lon": "12.0925243",
      "superficie_kmq": "51.0591",
      "codice_sovracomunale": "055"
    },
    {
      "sigla_provincia": "TR",
      "codice_istat": "055021",
      "denominazione_ita_altra": "Monteleone d'Orvieto",
      "denominazione_ita": "Monteleone d'Orvieto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F543",
      "lat": "42.9164288",
      "lon": "12.0515608",
      "superficie_kmq": "24.0978",
      "codice_sovracomunale": "055"
    },
    {
      "sigla_provincia": "TR",
      "codice_istat": "055022",
      "denominazione_ita_altra": "Narni",
      "denominazione_ita": "Narni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F844",
      "lat": "42.5192207",
      "lon": "12.5152876",
      "superficie_kmq": "197.9836",
      "codice_sovracomunale": "055"
    },
    {
      "sigla_provincia": "TR",
      "codice_istat": "055023",
      "denominazione_ita_altra": "Orvieto",
      "denominazione_ita": "Orvieto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G148",
      "lat": "42.7181431",
      "lon": "12.1083084",
      "superficie_kmq": "281.2704",
      "codice_sovracomunale": "055"
    },
    {
      "sigla_provincia": "TR",
      "codice_istat": "055024",
      "denominazione_ita_altra": "Otricoli",
      "denominazione_ita": "Otricoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G189",
      "lat": "42.4233041",
      "lon": "12.4782950",
      "superficie_kmq": "27.4114",
      "codice_sovracomunale": "055"
    },
    {
      "sigla_provincia": "TR",
      "codice_istat": "055025",
      "denominazione_ita_altra": "Parrano",
      "denominazione_ita": "Parrano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G344",
      "lat": "42.8634989",
      "lon": "12.1056952",
      "superficie_kmq": "40.0911",
      "codice_sovracomunale": "055"
    },
    {
      "sigla_provincia": "TR",
      "codice_istat": "055026",
      "denominazione_ita_altra": "Penna in Teverina",
      "denominazione_ita": "Penna in Teverina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G432",
      "lat": "42.4929778",
      "lon": "12.3553835",
      "superficie_kmq": "10.0033",
      "codice_sovracomunale": "055"
    },
    {
      "sigla_provincia": "TR",
      "codice_istat": "055027",
      "denominazione_ita_altra": "Polino",
      "denominazione_ita": "Polino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G790",
      "lat": "42.5847734",
      "lon": "12.8437990",
      "superficie_kmq": "19.5678",
      "codice_sovracomunale": "055"
    },
    {
      "sigla_provincia": "TR",
      "codice_istat": "055028",
      "denominazione_ita_altra": "Porano",
      "denominazione_ita": "Porano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G881",
      "lat": "42.6869983",
      "lon": "12.1019921",
      "superficie_kmq": "13.6040",
      "codice_sovracomunale": "055"
    },
    {
      "sigla_provincia": "TR",
      "codice_istat": "055029",
      "denominazione_ita_altra": "San Gemini",
      "denominazione_ita": "San Gemini",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H857",
      "lat": "42.6133444",
      "lon": "12.5462453",
      "superficie_kmq": "27.8956",
      "codice_sovracomunale": "055"
    },
    {
      "sigla_provincia": "TR",
      "codice_istat": "055030",
      "denominazione_ita_altra": "San Venanzo",
      "denominazione_ita": "San Venanzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I381",
      "lat": "42.8688221",
      "lon": "12.2662037",
      "superficie_kmq": "169.4518",
      "codice_sovracomunale": "055"
    },
    {
      "sigla_provincia": "TR",
      "codice_istat": "055031",
      "denominazione_ita_altra": "Stroncone",
      "denominazione_ita": "Stroncone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I981",
      "lat": "42.4982130",
      "lon": "12.6627444",
      "superficie_kmq": "71.1557",
      "codice_sovracomunale": "055"
    },
    {
      "sigla_provincia": "TR",
      "codice_istat": "055032",
      "denominazione_ita_altra": "Terni",
      "denominazione_ita": "Terni",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "L117",
      "lat": "42.5606759",
      "lon": "12.6466854",
      "superficie_kmq": "212.4261",
      "codice_sovracomunale": "055"
    },
    {
      "sigla_provincia": "TR",
      "codice_istat": "055033",
      "denominazione_ita_altra": "Avigliano Umbro",
      "denominazione_ita": "Avigliano Umbro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M258",
      "lat": "42.6550464",
      "lon": "12.4280934",
      "superficie_kmq": "51.3387",
      "codice_sovracomunale": "055"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056001",
      "denominazione_ita_altra": "Acquapendente",
      "denominazione_ita": "Acquapendente",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A040",
      "lat": "42.7440093",
      "lon": "11.8650524",
      "superficie_kmq": "131.6022",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056002",
      "denominazione_ita_altra": "Arlena di Castro",
      "denominazione_ita": "Arlena di Castro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A412",
      "lat": "42.4629642",
      "lon": "11.8235817",
      "superficie_kmq": "21.8706",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056003",
      "denominazione_ita_altra": "Bagnoregio",
      "denominazione_ita": "Bagnoregio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A577",
      "lat": "42.6260390",
      "lon": "12.0985015",
      "superficie_kmq": "72.8081",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056004",
      "denominazione_ita_altra": "Barbarano Romano",
      "denominazione_ita": "Barbarano Romano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A628",
      "lat": "42.2522076",
      "lon": "12.0659608",
      "superficie_kmq": "37.5635",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056005",
      "denominazione_ita_altra": "Bassano Romano",
      "denominazione_ita": "Bassano Romano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A704",
      "lat": "42.2182406",
      "lon": "12.1926568",
      "superficie_kmq": "37.5457",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056006",
      "denominazione_ita_altra": "Bassano in Teverina",
      "denominazione_ita": "Bassano in Teverina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A706",
      "lat": "42.4630442",
      "lon": "12.3083984",
      "superficie_kmq": "12.1676",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056007",
      "denominazione_ita_altra": "Blera",
      "denominazione_ita": "Blera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A857",
      "lat": "42.2741501",
      "lon": "12.0257601",
      "superficie_kmq": "92.9142",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056008",
      "denominazione_ita_altra": "Bolsena",
      "denominazione_ita": "Bolsena",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A949",
      "lat": "42.6447006",
      "lon": "11.9875839",
      "superficie_kmq": "63.5714",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056009",
      "denominazione_ita_altra": "Bomarzo",
      "denominazione_ita": "Bomarzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A955",
      "lat": "42.4909835",
      "lon": "12.2513706",
      "superficie_kmq": "39.6448",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056010",
      "denominazione_ita_altra": "Calcata",
      "denominazione_ita": "Calcata",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B388",
      "lat": "42.2197163",
      "lon": "12.4257178",
      "superficie_kmq": "7.6322",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056011",
      "denominazione_ita_altra": "Canepina",
      "denominazione_ita": "Canepina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B597",
      "lat": "42.3807634",
      "lon": "12.2336493",
      "superficie_kmq": "20.8477",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056012",
      "denominazione_ita_altra": "Canino",
      "denominazione_ita": "Canino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B604",
      "lat": "42.4641355",
      "lon": "11.7490717",
      "superficie_kmq": "124.0375",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056013",
      "denominazione_ita_altra": "Capodimonte",
      "denominazione_ita": "Capodimonte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B663",
      "lat": "42.5489031",
      "lon": "11.9062971",
      "superficie_kmq": "61.2907",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056014",
      "denominazione_ita_altra": "Capranica",
      "denominazione_ita": "Capranica",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B688",
      "lat": "42.2566375",
      "lon": "12.1780595",
      "superficie_kmq": "40.9683",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056015",
      "denominazione_ita_altra": "Caprarola",
      "denominazione_ita": "Caprarola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B691",
      "lat": "42.3275312",
      "lon": "12.2379655",
      "superficie_kmq": "57.5769",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056016",
      "denominazione_ita_altra": "Carbognano",
      "denominazione_ita": "Carbognano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B735",
      "lat": "42.3320706",
      "lon": "12.2667704",
      "superficie_kmq": "17.4110",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056017",
      "denominazione_ita_altra": "Castel Sant'Elia",
      "denominazione_ita": "Castel Sant'Elia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C269",
      "lat": "42.2492831",
      "lon": "12.3680909",
      "superficie_kmq": "23.9151",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056018",
      "denominazione_ita_altra": "Castiglione in Teverina",
      "denominazione_ita": "Castiglione in Teverina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C315",
      "lat": "42.6449203",
      "lon": "12.2041114",
      "superficie_kmq": "19.8891",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056019",
      "denominazione_ita_altra": "Celleno",
      "denominazione_ita": "Celleno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C446",
      "lat": "42.5597664",
      "lon": "12.1264036",
      "superficie_kmq": "23.8237",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056020",
      "denominazione_ita_altra": "Cellere",
      "denominazione_ita": "Cellere",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C447",
      "lat": "42.5079518",
      "lon": "11.7678396",
      "superficie_kmq": "37.1950",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056021",
      "denominazione_ita_altra": "Civita Castellana",
      "denominazione_ita": "Civita Castellana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C765",
      "lat": "42.2891360",
      "lon": "12.4117642",
      "superficie_kmq": "84.2211",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056022",
      "denominazione_ita_altra": "Civitella d'Agliano",
      "denominazione_ita": "Civitella d'Agliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C780",
      "lat": "42.6061903",
      "lon": "12.1879567",
      "superficie_kmq": "32.9552",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056023",
      "denominazione_ita_altra": "Corchiano",
      "denominazione_ita": "Corchiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C988",
      "lat": "42.3455492",
      "lon": "12.3566382",
      "superficie_kmq": "33.0306",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056024",
      "denominazione_ita_altra": "Fabrica di Roma",
      "denominazione_ita": "Fabrica di Roma",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D452",
      "lat": "42.3346941",
      "lon": "12.2951749",
      "superficie_kmq": "34.7859",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056025",
      "denominazione_ita_altra": "Faleria",
      "denominazione_ita": "Faleria",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D475",
      "lat": "42.2262128",
      "lon": "12.4431904",
      "superficie_kmq": "25.6785",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056026",
      "denominazione_ita_altra": "Farnese",
      "denominazione_ita": "Farnese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D503",
      "lat": "42.5501430",
      "lon": "11.7244955",
      "superficie_kmq": "52.3752",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056027",
      "denominazione_ita_altra": "Gallese",
      "denominazione_ita": "Gallese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D870",
      "lat": "42.3723749",
      "lon": "12.4044898",
      "superficie_kmq": "37.1735",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056028",
      "denominazione_ita_altra": "Gradoli",
      "denominazione_ita": "Gradoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E126",
      "lat": "42.6437027",
      "lon": "11.8560902",
      "superficie_kmq": "43.8083",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056029",
      "denominazione_ita_altra": "Graffignano",
      "denominazione_ita": "Graffignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E128",
      "lat": "42.5731564",
      "lon": "12.2047993",
      "superficie_kmq": "29.1023",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056030",
      "denominazione_ita_altra": "Grotte di Castro",
      "denominazione_ita": "Grotte di Castro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E210",
      "lat": "42.6739128",
      "lon": "11.8702363",
      "superficie_kmq": "33.4200",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056031",
      "denominazione_ita_altra": "Ischia di Castro",
      "denominazione_ita": "Ischia di Castro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E330",
      "lat": "42.5441330",
      "lon": "11.7578129",
      "superficie_kmq": "104.9432",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056032",
      "denominazione_ita_altra": "Latera",
      "denominazione_ita": "Latera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E467",
      "lat": "42.6263697",
      "lon": "11.8284282",
      "superficie_kmq": "22.4294",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056033",
      "denominazione_ita_altra": "Lubriano",
      "denominazione_ita": "Lubriano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E713",
      "lat": "42.6353124",
      "lon": "12.1071207",
      "superficie_kmq": "16.6891",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056034",
      "denominazione_ita_altra": "Marta",
      "denominazione_ita": "Marta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E978",
      "lat": "42.5349636",
      "lon": "11.9251552",
      "superficie_kmq": "33.5404",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056035",
      "denominazione_ita_altra": "Montalto di Castro",
      "denominazione_ita": "Montalto di Castro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F419",
      "lat": "42.3531894",
      "lon": "11.6041766",
      "superficie_kmq": "189.6215",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056036",
      "denominazione_ita_altra": "Montefiascone",
      "denominazione_ita": "Montefiascone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F499",
      "lat": "42.5375771",
      "lon": "12.0303669",
      "superficie_kmq": "104.9300",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056037",
      "denominazione_ita_altra": "Monte Romano",
      "denominazione_ita": "Monte Romano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F603",
      "lat": "42.2678754",
      "lon": "11.8987755",
      "superficie_kmq": "86.1400",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056038",
      "denominazione_ita_altra": "Monterosi",
      "denominazione_ita": "Monterosi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F606",
      "lat": "42.1955504",
      "lon": "12.3099393",
      "superficie_kmq": "10.6834",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056039",
      "denominazione_ita_altra": "Nepi",
      "denominazione_ita": "Nepi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F868",
      "lat": "42.2423287",
      "lon": "12.3488111",
      "superficie_kmq": "83.7096",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056040",
      "denominazione_ita_altra": "Onano",
      "denominazione_ita": "Onano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G065",
      "lat": "42.6936890",
      "lon": "11.8149825",
      "superficie_kmq": "24.5077",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056041",
      "denominazione_ita_altra": "Oriolo Romano",
      "denominazione_ita": "Oriolo Romano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G111",
      "lat": "42.1584204",
      "lon": "12.1380652",
      "superficie_kmq": "19.3110",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056042",
      "denominazione_ita_altra": "Orte",
      "denominazione_ita": "Orte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G135",
      "lat": "42.4604068",
      "lon": "12.3863899",
      "superficie_kmq": "69.5589",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056043",
      "denominazione_ita_altra": "Piansano",
      "denominazione_ita": "Piansano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G571",
      "lat": "42.5178492",
      "lon": "11.8282457",
      "superficie_kmq": "26.6051",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056044",
      "denominazione_ita_altra": "Proceno",
      "denominazione_ita": "Proceno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H071",
      "lat": "42.7571721",
      "lon": "11.8304339",
      "superficie_kmq": "42.0138",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056045",
      "denominazione_ita_altra": "Ronciglione",
      "denominazione_ita": "Ronciglione",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H534",
      "lat": "42.2916782",
      "lon": "12.2157313",
      "superficie_kmq": "52.5258",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056046",
      "denominazione_ita_altra": "Villa San Giovanni in Tuscia",
      "denominazione_ita": "Villa San Giovanni in Tuscia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H913",
      "lat": "42.2834795",
      "lon": "12.0529384",
      "superficie_kmq": "5.2792",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056047",
      "denominazione_ita_altra": "San Lorenzo Nuovo",
      "denominazione_ita": "San Lorenzo Nuovo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H969",
      "lat": "42.6855579",
      "lon": "11.9060956",
      "superficie_kmq": "26.7350",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056048",
      "denominazione_ita_altra": "Soriano nel Cimino",
      "denominazione_ita": "Soriano nel Cimino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I855",
      "lat": "42.4188892",
      "lon": "12.2347203",
      "superficie_kmq": "78.5355",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056049",
      "denominazione_ita_altra": "Sutri",
      "denominazione_ita": "Sutri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L017",
      "lat": "42.2419795",
      "lon": "12.2248410",
      "superficie_kmq": "60.9413",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056050",
      "denominazione_ita_altra": "Tarquinia",
      "denominazione_ita": "Tarquinia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D024",
      "lat": "42.2544085",
      "lon": "11.7582318",
      "superficie_kmq": "279.3305",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056051",
      "denominazione_ita_altra": "Tessennano",
      "denominazione_ita": "Tessennano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L150",
      "lat": "42.4803747",
      "lon": "11.7923215",
      "superficie_kmq": "14.7309",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056052",
      "denominazione_ita_altra": "Tuscania",
      "denominazione_ita": "Tuscania",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L310",
      "lat": "42.4166776",
      "lon": "11.8756413",
      "superficie_kmq": "208.6910",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056053",
      "denominazione_ita_altra": "Valentano",
      "denominazione_ita": "Valentano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L569",
      "lat": "42.5691637",
      "lon": "11.8183788",
      "superficie_kmq": "43.4947",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056054",
      "denominazione_ita_altra": "Vallerano",
      "denominazione_ita": "Vallerano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L612",
      "lat": "42.3837421",
      "lon": "12.2639560",
      "superficie_kmq": "15.4516",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056055",
      "denominazione_ita_altra": "Vasanello",
      "denominazione_ita": "Vasanello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A701",
      "lat": "42.4181192",
      "lon": "12.3464925",
      "superficie_kmq": "28.9623",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056056",
      "denominazione_ita_altra": "Vejano",
      "denominazione_ita": "Vejano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L713",
      "lat": "42.2162606",
      "lon": "12.0952742",
      "superficie_kmq": "44.3071",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056057",
      "denominazione_ita_altra": "Vetralla",
      "denominazione_ita": "Vetralla",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L814",
      "lat": "42.3207785",
      "lon": "12.0535184",
      "superficie_kmq": "113.7710",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056058",
      "denominazione_ita_altra": "Vignanello",
      "denominazione_ita": "Vignanello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L882",
      "lat": "42.3838040",
      "lon": "12.2780681",
      "superficie_kmq": "20.5289",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056059",
      "denominazione_ita_altra": "Viterbo",
      "denominazione_ita": "Viterbo",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "M082",
      "lat": "42.4173438",
      "lon": "12.1047322",
      "superficie_kmq": "406.2267",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "VT",
      "codice_istat": "056060",
      "denominazione_ita_altra": "Vitorchiano",
      "denominazione_ita": "Vitorchiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M086",
      "lat": "42.4702721",
      "lon": "12.1742215",
      "superficie_kmq": "30.1430",
      "codice_sovracomunale": "056"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057001",
      "denominazione_ita_altra": "Accumoli",
      "denominazione_ita": "Accumoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A019",
      "lat": "42.6947504",
      "lon": "13.2476838",
      "superficie_kmq": "87.3430",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057002",
      "denominazione_ita_altra": "Amatrice",
      "denominazione_ita": "Amatrice",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A258",
      "lat": "42.6293491",
      "lon": "13.2883840",
      "superficie_kmq": "174.3849",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057003",
      "denominazione_ita_altra": "Antrodoco",
      "denominazione_ita": "Antrodoco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A315",
      "lat": "42.4174959",
      "lon": "13.0802015",
      "superficie_kmq": "63.9041",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057004",
      "denominazione_ita_altra": "Ascrea",
      "denominazione_ita": "Ascrea",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A464",
      "lat": "42.1956580",
      "lon": "12.9967194",
      "superficie_kmq": "13.9761",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057005",
      "denominazione_ita_altra": "Belmonte in Sabina",
      "denominazione_ita": "Belmonte in Sabina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A765",
      "lat": "42.3182167",
      "lon": "12.8926885",
      "superficie_kmq": "23.6446",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057006",
      "denominazione_ita_altra": "Borbona",
      "denominazione_ita": "Borbona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A981",
      "lat": "42.5122088",
      "lon": "13.1325445",
      "superficie_kmq": "47.9485",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057007",
      "denominazione_ita_altra": "Borgorose",
      "denominazione_ita": "Borgorose",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B008",
      "lat": "42.1933450",
      "lon": "13.2325683",
      "superficie_kmq": "145.8039",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057008",
      "denominazione_ita_altra": "Borgo Velino",
      "denominazione_ita": "Borgo Velino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A996",
      "lat": "42.4055645",
      "lon": "13.0584893",
      "superficie_kmq": "18.2929",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057009",
      "denominazione_ita_altra": "Cantalice",
      "denominazione_ita": "Cantalice",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B627",
      "lat": "42.4682596",
      "lon": "12.9035475",
      "superficie_kmq": "37.6240",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057010",
      "denominazione_ita_altra": "Cantalupo in Sabina",
      "denominazione_ita": "Cantalupo in Sabina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B631",
      "lat": "42.3078330",
      "lon": "12.6467711",
      "superficie_kmq": "10.6212",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057011",
      "denominazione_ita_altra": "Casaprota",
      "denominazione_ita": "Casaprota",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B934",
      "lat": "42.2518052",
      "lon": "12.8036600",
      "superficie_kmq": "14.5461",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057012",
      "denominazione_ita_altra": "Casperia",
      "denominazione_ita": "Casperia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A472",
      "lat": "42.3379192",
      "lon": "12.6703001",
      "superficie_kmq": "25.3087",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057013",
      "denominazione_ita_altra": "Castel di Tora",
      "denominazione_ita": "Castel di Tora",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C098",
      "lat": "42.2154516",
      "lon": "12.9643310",
      "superficie_kmq": "15.4912",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057014",
      "denominazione_ita_altra": "Castelnuovo di Farfa",
      "denominazione_ita": "Castelnuovo di Farfa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C224",
      "lat": "42.2318691",
      "lon": "12.7426975",
      "superficie_kmq": "8.8422",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057015",
      "denominazione_ita_altra": "Castel Sant'Angelo",
      "denominazione_ita": "Castel Sant'Angelo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C268",
      "lat": "42.3947384",
      "lon": "13.0299329",
      "superficie_kmq": "31.2697",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057016",
      "denominazione_ita_altra": "Cittaducale",
      "denominazione_ita": "Cittaducale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C746",
      "lat": "42.3866215",
      "lon": "12.9481480",
      "superficie_kmq": "71.2532",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057017",
      "denominazione_ita_altra": "Cittareale",
      "denominazione_ita": "Cittareale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C749",
      "lat": "42.6175800",
      "lon": "13.1598322",
      "superficie_kmq": "59.6601",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057018",
      "denominazione_ita_altra": "Collalto Sabino",
      "denominazione_ita": "Collalto Sabino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C841",
      "lat": "42.1363859",
      "lon": "13.0482815",
      "superficie_kmq": "22.3698",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057019",
      "denominazione_ita_altra": "Colle di Tora",
      "denominazione_ita": "Colle di Tora",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C857",
      "lat": "42.2120428",
      "lon": "12.9477745",
      "superficie_kmq": "14.3718",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057020",
      "denominazione_ita_altra": "Collegiove",
      "denominazione_ita": "Collegiove",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C859",
      "lat": "42.1748417",
      "lon": "13.0370687",
      "superficie_kmq": "10.6126",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057021",
      "denominazione_ita_altra": "Collevecchio",
      "denominazione_ita": "Collevecchio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C876",
      "lat": "42.3354010",
      "lon": "12.5527178",
      "superficie_kmq": "26.9475",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057022",
      "denominazione_ita_altra": "Colli sul Velino",
      "denominazione_ita": "Colli sul Velino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C880",
      "lat": "42.4989462",
      "lon": "12.7797056",
      "superficie_kmq": "12.7551",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057023",
      "denominazione_ita_altra": "Concerviano",
      "denominazione_ita": "Concerviano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C946",
      "lat": "42.3234083",
      "lon": "12.9857207",
      "superficie_kmq": "21.3896",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057024",
      "denominazione_ita_altra": "Configni",
      "denominazione_ita": "Configni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C959",
      "lat": "42.4235866",
      "lon": "12.6423454",
      "superficie_kmq": "22.9256",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057025",
      "denominazione_ita_altra": "Contigliano",
      "denominazione_ita": "Contigliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C969",
      "lat": "42.4107085",
      "lon": "12.7665456",
      "superficie_kmq": "53.5447",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057026",
      "denominazione_ita_altra": "Cottanello",
      "denominazione_ita": "Cottanello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D124",
      "lat": "42.4061429",
      "lon": "12.6864363",
      "superficie_kmq": "36.6915",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057027",
      "denominazione_ita_altra": "Fara in Sabina",
      "denominazione_ita": "Fara in Sabina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D493",
      "lat": "42.2094854",
      "lon": "12.7292489",
      "superficie_kmq": "54.9624",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057028",
      "denominazione_ita_altra": "Fiamignano",
      "denominazione_ita": "Fiamignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D560",
      "lat": "42.2647813",
      "lon": "13.1255024",
      "superficie_kmq": "100.6180",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057029",
      "denominazione_ita_altra": "Forano",
      "denominazione_ita": "Forano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D689",
      "lat": "42.2978742",
      "lon": "12.5950277",
      "superficie_kmq": "17.6853",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057030",
      "denominazione_ita_altra": "Frasso Sabino",
      "denominazione_ita": "Frasso Sabino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D785",
      "lat": "42.2296240",
      "lon": "12.8053108",
      "superficie_kmq": "4.3898",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057031",
      "denominazione_ita_altra": "Greccio",
      "denominazione_ita": "Greccio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E160",
      "lat": "42.4536515",
      "lon": "12.7691026",
      "superficie_kmq": "17.8535",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057032",
      "denominazione_ita_altra": "Labro",
      "denominazione_ita": "Labro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E393",
      "lat": "42.5257335",
      "lon": "12.8001690",
      "superficie_kmq": "11.7424",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057033",
      "denominazione_ita_altra": "Leonessa",
      "denominazione_ita": "Leonessa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E535",
      "lat": "42.5614500",
      "lon": "12.9638968",
      "superficie_kmq": "204.0152",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057034",
      "denominazione_ita_altra": "Longone Sabino",
      "denominazione_ita": "Longone Sabino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E681",
      "lat": "42.2723862",
      "lon": "12.9654921",
      "superficie_kmq": "34.3338",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057035",
      "denominazione_ita_altra": "Magliano Sabina",
      "denominazione_ita": "Magliano Sabina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E812",
      "lat": "42.3612912",
      "lon": "12.4809921",
      "superficie_kmq": "43.1617",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057036",
      "denominazione_ita_altra": "Marcetelli",
      "denominazione_ita": "Marcetelli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E927",
      "lat": "42.2267467",
      "lon": "13.0472252",
      "superficie_kmq": "11.0838",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057037",
      "denominazione_ita_altra": "Micigliano",
      "denominazione_ita": "Micigliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F193",
      "lat": "42.4509085",
      "lon": "13.0549301",
      "superficie_kmq": "36.8517",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057038",
      "denominazione_ita_altra": "Mompeo",
      "denominazione_ita": "Mompeo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F319",
      "lat": "42.2476710",
      "lon": "12.7511683",
      "superficie_kmq": "10.8922",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057039",
      "denominazione_ita_altra": "Montasola",
      "denominazione_ita": "Montasola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F430",
      "lat": "42.3855221",
      "lon": "12.6809894",
      "superficie_kmq": "12.7472",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057040",
      "denominazione_ita_altra": "Montebuono",
      "denominazione_ita": "Montebuono",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F446",
      "lat": "42.3658685",
      "lon": "12.5977049",
      "superficie_kmq": "19.6813",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057041",
      "denominazione_ita_altra": "Monteleone Sabino",
      "denominazione_ita": "Monteleone Sabino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F541",
      "lat": "42.2325378",
      "lon": "12.8590201",
      "superficie_kmq": "19.0398",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057042",
      "denominazione_ita_altra": "Montenero Sabino",
      "denominazione_ita": "Montenero Sabino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F579",
      "lat": "42.2812005",
      "lon": "12.8131626",
      "superficie_kmq": "22.5865",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057043",
      "denominazione_ita_altra": "Monte San Giovanni in Sabina",
      "denominazione_ita": "Monte San Giovanni in Sabina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F619",
      "lat": "42.3281418",
      "lon": "12.7776814",
      "superficie_kmq": "30.7570",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057044",
      "denominazione_ita_altra": "Montopoli di Sabina",
      "denominazione_ita": "Montopoli di Sabina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F687",
      "lat": "42.2446657",
      "lon": "12.6919086",
      "superficie_kmq": "37.9405",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057045",
      "denominazione_ita_altra": "Morro Reatino",
      "denominazione_ita": "Morro Reatino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F746",
      "lat": "42.5258125",
      "lon": "12.8321614",
      "superficie_kmq": "15.7311",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057046",
      "denominazione_ita_altra": "Nespolo",
      "denominazione_ita": "Nespolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F876",
      "lat": "42.1557034",
      "lon": "13.0694804",
      "superficie_kmq": "8.6446",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057047",
      "denominazione_ita_altra": "Orvinio",
      "denominazione_ita": "Orvinio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B595",
      "lat": "42.1315141",
      "lon": "12.9374701",
      "superficie_kmq": "24.6887",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057048",
      "denominazione_ita_altra": "Paganico Sabino",
      "denominazione_ita": "Paganico Sabino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G232",
      "lat": "42.1895072",
      "lon": "12.9974842",
      "superficie_kmq": "9.3138",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057049",
      "denominazione_ita_altra": "Pescorocchiano",
      "denominazione_ita": "Pescorocchiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G498",
      "lat": "42.2060179",
      "lon": "13.1484965",
      "superficie_kmq": "94.7727",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057050",
      "denominazione_ita_altra": "Petrella Salto",
      "denominazione_ita": "Petrella Salto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G513",
      "lat": "42.2945223",
      "lon": "13.0683579",
      "superficie_kmq": "102.9304",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057051",
      "denominazione_ita_altra": "Poggio Bustone",
      "denominazione_ita": "Poggio Bustone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G756",
      "lat": "42.5021003",
      "lon": "12.8857951",
      "superficie_kmq": "22.3815",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057052",
      "denominazione_ita_altra": "Poggio Catino",
      "denominazione_ita": "Poggio Catino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G757",
      "lat": "42.2950320",
      "lon": "12.6918076",
      "superficie_kmq": "14.9813",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057053",
      "denominazione_ita_altra": "Poggio Mirteto",
      "denominazione_ita": "Poggio Mirteto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G763",
      "lat": "42.2645494",
      "lon": "12.6836521",
      "superficie_kmq": "26.3948",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057054",
      "denominazione_ita_altra": "Poggio Moiano",
      "denominazione_ita": "Poggio Moiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G764",
      "lat": "42.2018287",
      "lon": "12.8771134",
      "superficie_kmq": "26.9469",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057055",
      "denominazione_ita_altra": "Poggio Nativo",
      "denominazione_ita": "Poggio Nativo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G765",
      "lat": "42.2157823",
      "lon": "12.7953262",
      "superficie_kmq": "16.4995",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057056",
      "denominazione_ita_altra": "Poggio San Lorenzo",
      "denominazione_ita": "Poggio San Lorenzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G770",
      "lat": "42.2528625",
      "lon": "12.8455085",
      "superficie_kmq": "8.6696",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057057",
      "denominazione_ita_altra": "Posta",
      "denominazione_ita": "Posta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G934",
      "lat": "42.5234158",
      "lon": "13.0962421",
      "superficie_kmq": "66.0132",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057058",
      "denominazione_ita_altra": "Pozzaglia Sabina",
      "denominazione_ita": "Pozzaglia Sabina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G951",
      "lat": "42.1597522",
      "lon": "12.9645490",
      "superficie_kmq": "24.9787",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057059",
      "denominazione_ita_altra": "Rieti",
      "denominazione_ita": "Rieti",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "H282",
      "lat": "42.4048456",
      "lon": "12.8620570",
      "superficie_kmq": "206.4550",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057060",
      "denominazione_ita_altra": "Rivodutri",
      "denominazione_ita": "Rivodutri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H354",
      "lat": "42.5160011",
      "lon": "12.8551500",
      "superficie_kmq": "26.7897",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057061",
      "denominazione_ita_altra": "Roccantica",
      "denominazione_ita": "Roccantica",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H427",
      "lat": "42.3207610",
      "lon": "12.6923321",
      "superficie_kmq": "16.7231",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057062",
      "denominazione_ita_altra": "Rocca Sinibalda",
      "denominazione_ita": "Rocca Sinibalda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H446",
      "lat": "42.2741784",
      "lon": "12.9257611",
      "superficie_kmq": "49.5551",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057063",
      "denominazione_ita_altra": "Salisano",
      "denominazione_ita": "Salisano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H713",
      "lat": "42.2574420",
      "lon": "12.7456134",
      "superficie_kmq": "17.5958",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057064",
      "denominazione_ita_altra": "Scandriglia",
      "denominazione_ita": "Scandriglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I499",
      "lat": "42.1650203",
      "lon": "12.8421008",
      "superficie_kmq": "63.3505",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057065",
      "denominazione_ita_altra": "Selci",
      "denominazione_ita": "Selci",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I581",
      "lat": "42.3196779",
      "lon": "12.6226668",
      "superficie_kmq": "7.7281",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057066",
      "denominazione_ita_altra": "Stimigliano",
      "denominazione_ita": "Stimigliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I959",
      "lat": "42.2977185",
      "lon": "12.5656860",
      "superficie_kmq": "11.3837",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057067",
      "denominazione_ita_altra": "Tarano",
      "denominazione_ita": "Tarano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L046",
      "lat": "42.3558010",
      "lon": "12.5948910",
      "superficie_kmq": "19.9796",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057068",
      "denominazione_ita_altra": "Toffia",
      "denominazione_ita": "Toffia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L189",
      "lat": "42.2124698",
      "lon": "12.7523655",
      "superficie_kmq": "11.3292",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057069",
      "denominazione_ita_altra": "Torricella in Sabina",
      "denominazione_ita": "Torricella in Sabina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L293",
      "lat": "42.2607054",
      "lon": "12.8697198",
      "superficie_kmq": "25.7914",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057070",
      "denominazione_ita_altra": "Torri in Sabina",
      "denominazione_ita": "Torri in Sabina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L286",
      "lat": "42.3530418",
      "lon": "12.6384652",
      "superficie_kmq": "26.3048",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057071",
      "denominazione_ita_altra": "Turania",
      "denominazione_ita": "Turania",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G507",
      "lat": "42.1373884",
      "lon": "13.0097719",
      "superficie_kmq": "8.5099",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057072",
      "denominazione_ita_altra": "Vacone",
      "denominazione_ita": "Vacone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L525",
      "lat": "42.3862289",
      "lon": "12.6357916",
      "superficie_kmq": "9.1848",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RI",
      "codice_istat": "057073",
      "denominazione_ita_altra": "Varco Sabino",
      "denominazione_ita": "Varco Sabino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L676",
      "lat": "42.2395906",
      "lon": "13.0199591",
      "superficie_kmq": "24.7527",
      "codice_sovracomunale": "057"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058001",
      "denominazione_ita_altra": "Affile",
      "denominazione_ita": "Affile",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A062",
      "lat": "41.8843334",
      "lon": "13.0972828",
      "superficie_kmq": "15.1123",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058002",
      "denominazione_ita_altra": "Agosta",
      "denominazione_ita": "Agosta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A084",
      "lat": "41.9819572",
      "lon": "13.0320800",
      "superficie_kmq": "9.4997",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058003",
      "denominazione_ita_altra": "Albano Laziale",
      "denominazione_ita": "Albano Laziale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A132",
      "lat": "41.7290631",
      "lon": "12.6587006",
      "superficie_kmq": "23.8082",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058004",
      "denominazione_ita_altra": "Allumiere",
      "denominazione_ita": "Allumiere",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A210",
      "lat": "42.1564965",
      "lon": "11.9021370",
      "superficie_kmq": "92.1659",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058005",
      "denominazione_ita_altra": "Anguillara Sabazia",
      "denominazione_ita": "Anguillara Sabazia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A297",
      "lat": "42.0928712",
      "lon": "12.2697870",
      "superficie_kmq": "75.2338",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058006",
      "denominazione_ita_altra": "Anticoli Corrado",
      "denominazione_ita": "Anticoli Corrado",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A309",
      "lat": "42.0092939",
      "lon": "12.9903816",
      "superficie_kmq": "16.2155",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058007",
      "denominazione_ita_altra": "Anzio",
      "denominazione_ita": "Anzio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A323",
      "lat": "41.4480021",
      "lon": "12.6287150",
      "superficie_kmq": "43.6586",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058008",
      "denominazione_ita_altra": "Arcinazzo Romano",
      "denominazione_ita": "Arcinazzo Romano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A370",
      "lat": "41.8801657",
      "lon": "13.1140978",
      "superficie_kmq": "28.3109",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058009",
      "denominazione_ita_altra": "Ariccia",
      "denominazione_ita": "Ariccia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A401",
      "lat": "41.7199715",
      "lon": "12.6705785",
      "superficie_kmq": "18.5919",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058010",
      "denominazione_ita_altra": "Arsoli",
      "denominazione_ita": "Arsoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A446",
      "lat": "42.0413650",
      "lon": "13.0165231",
      "superficie_kmq": "12.1991",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058011",
      "denominazione_ita_altra": "Artena",
      "denominazione_ita": "Artena",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A449",
      "lat": "41.7386142",
      "lon": "12.9153588",
      "superficie_kmq": "54.7970",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058012",
      "denominazione_ita_altra": "Bellegra",
      "denominazione_ita": "Bellegra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A749",
      "lat": "41.8810320",
      "lon": "13.0280445",
      "superficie_kmq": "18.7794",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058013",
      "denominazione_ita_altra": "Bracciano",
      "denominazione_ita": "Bracciano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B114",
      "lat": "42.1033072",
      "lon": "12.1756218",
      "superficie_kmq": "143.0603",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058014",
      "denominazione_ita_altra": "Camerata Nuova",
      "denominazione_ita": "Camerata Nuova",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B472",
      "lat": "42.0184047",
      "lon": "13.1081315",
      "superficie_kmq": "40.4932",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058015",
      "denominazione_ita_altra": "Campagnano di Roma",
      "denominazione_ita": "Campagnano di Roma",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B496",
      "lat": "42.1407724",
      "lon": "12.3832509",
      "superficie_kmq": "46.9371",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058016",
      "denominazione_ita_altra": "Canale Monterano",
      "denominazione_ita": "Canale Monterano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B576",
      "lat": "42.1366230",
      "lon": "12.1035328",
      "superficie_kmq": "36.9174",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058017",
      "denominazione_ita_altra": "Canterano",
      "denominazione_ita": "Canterano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B635",
      "lat": "41.9418075",
      "lon": "13.0375648",
      "superficie_kmq": "7.3730",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058018",
      "denominazione_ita_altra": "Capena",
      "denominazione_ita": "Capena",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B649",
      "lat": "42.1407405",
      "lon": "12.5392775",
      "superficie_kmq": "29.5069",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058019",
      "denominazione_ita_altra": "Capranica Prenestina",
      "denominazione_ita": "Capranica Prenestina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B687",
      "lat": "41.8620257",
      "lon": "12.9523458",
      "superficie_kmq": "20.3617",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058020",
      "denominazione_ita_altra": "Carpineto Romano",
      "denominazione_ita": "Carpineto Romano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B828",
      "lat": "41.6043679",
      "lon": "13.0852998",
      "superficie_kmq": "86.2892",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058021",
      "denominazione_ita_altra": "Casape",
      "denominazione_ita": "Casape",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B932",
      "lat": "41.9063598",
      "lon": "12.8861357",
      "superficie_kmq": "5.3793",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058022",
      "denominazione_ita_altra": "Castel Gandolfo",
      "denominazione_ita": "Castel Gandolfo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C116",
      "lat": "41.7467112",
      "lon": "12.6504581",
      "superficie_kmq": "14.1906",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058023",
      "denominazione_ita_altra": "Castel Madama",
      "denominazione_ita": "Castel Madama",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C203",
      "lat": "41.9756162",
      "lon": "12.8685711",
      "superficie_kmq": "28.8029",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058024",
      "denominazione_ita_altra": "Castelnuovo di Porto",
      "denominazione_ita": "Castelnuovo di Porto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C237",
      "lat": "42.1257515",
      "lon": "12.5004464",
      "superficie_kmq": "30.5654",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058025",
      "denominazione_ita_altra": "Castel San Pietro Romano",
      "denominazione_ita": "Castel San Pietro Romano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C266",
      "lat": "41.8463254",
      "lon": "12.8947498",
      "superficie_kmq": "15.2923",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058026",
      "denominazione_ita_altra": "Cave",
      "denominazione_ita": "Cave",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C390",
      "lat": "41.8180368",
      "lon": "12.9317845",
      "superficie_kmq": "17.8793",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058027",
      "denominazione_ita_altra": "Cerreto Laziale",
      "denominazione_ita": "Cerreto Laziale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C518",
      "lat": "41.9443198",
      "lon": "12.9816475",
      "superficie_kmq": "12.0754",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058028",
      "denominazione_ita_altra": "Cervara di Roma",
      "denominazione_ita": "Cervara di Roma",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C543",
      "lat": "41.9880401",
      "lon": "13.0676950",
      "superficie_kmq": "31.7432",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058029",
      "denominazione_ita_altra": "Cerveteri",
      "denominazione_ita": "Cerveteri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C552",
      "lat": "41.9981974",
      "lon": "12.0989662",
      "superficie_kmq": "134.3187",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058030",
      "denominazione_ita_altra": "Ciciliano",
      "denominazione_ita": "Ciciliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C677",
      "lat": "41.9611933",
      "lon": "12.9416341",
      "superficie_kmq": "18.8473",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058031",
      "denominazione_ita_altra": "Cineto Romano",
      "denominazione_ita": "Cineto Romano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C702",
      "lat": "42.0501965",
      "lon": "12.9639210",
      "superficie_kmq": "10.3668",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058032",
      "denominazione_ita_altra": "Civitavecchia",
      "denominazione_ita": "Civitavecchia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C773",
      "lat": "42.0920861",
      "lon": "11.7933771",
      "superficie_kmq": "73.7376",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058033",
      "denominazione_ita_altra": "Civitella San Paolo",
      "denominazione_ita": "Civitella San Paolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C784",
      "lat": "42.1958439",
      "lon": "12.5825005",
      "superficie_kmq": "20.7493",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058034",
      "denominazione_ita_altra": "Colleferro",
      "denominazione_ita": "Colleferro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C858",
      "lat": "41.7303639",
      "lon": "13.0055885",
      "superficie_kmq": "26.9893",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058035",
      "denominazione_ita_altra": "Colonna",
      "denominazione_ita": "Colonna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C900",
      "lat": "41.8342590",
      "lon": "12.7511130",
      "superficie_kmq": "3.5491",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058036",
      "denominazione_ita_altra": "Fiano Romano",
      "denominazione_ita": "Fiano Romano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D561",
      "lat": "42.1719618",
      "lon": "12.5924406",
      "superficie_kmq": "41.1862",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058037",
      "denominazione_ita_altra": "Filacciano",
      "denominazione_ita": "Filacciano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D586",
      "lat": "42.2551379",
      "lon": "12.5977077",
      "superficie_kmq": "5.6584",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058038",
      "denominazione_ita_altra": "Formello",
      "denominazione_ita": "Formello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D707",
      "lat": "42.0801776",
      "lon": "12.4008447",
      "superficie_kmq": "31.1449",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058039",
      "denominazione_ita_altra": "Frascati",
      "denominazione_ita": "Frascati",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D773",
      "lat": "41.8059422",
      "lon": "12.6807897",
      "superficie_kmq": "22.4759",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058040",
      "denominazione_ita_altra": "Gallicano nel Lazio",
      "denominazione_ita": "Gallicano nel Lazio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D875",
      "lat": "41.8699913",
      "lon": "12.8225604",
      "superficie_kmq": "25.6995",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058041",
      "denominazione_ita_altra": "Gavignano",
      "denominazione_ita": "Gavignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D945",
      "lat": "41.6980336",
      "lon": "13.0525996",
      "superficie_kmq": "15.0431",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058042",
      "denominazione_ita_altra": "Genazzano",
      "denominazione_ita": "Genazzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D964",
      "lat": "41.8316711",
      "lon": "12.9723511",
      "superficie_kmq": "32.0650",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058043",
      "denominazione_ita_altra": "Genzano di Roma",
      "denominazione_ita": "Genzano di Roma",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D972",
      "lat": "41.7068551",
      "lon": "12.6913193",
      "superficie_kmq": "17.9002",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058044",
      "denominazione_ita_altra": "Gerano",
      "denominazione_ita": "Gerano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D978",
      "lat": "41.9337719",
      "lon": "12.9939697",
      "superficie_kmq": "10.1242",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058045",
      "denominazione_ita_altra": "Gorga",
      "denominazione_ita": "Gorga",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E091",
      "lat": "41.6558928",
      "lon": "13.1107864",
      "superficie_kmq": "26.1895",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058046",
      "denominazione_ita_altra": "Grottaferrata",
      "denominazione_ita": "Grottaferrata",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E204",
      "lat": "41.7884131",
      "lon": "12.6677260",
      "superficie_kmq": "18.3960",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058047",
      "denominazione_ita_altra": "Guidonia Montecelio",
      "denominazione_ita": "Guidonia Montecelio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E263",
      "lat": "42.0000332",
      "lon": "12.7263837",
      "superficie_kmq": "79.4718",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058048",
      "denominazione_ita_altra": "Jenne",
      "denominazione_ita": "Jenne",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E382",
      "lat": "41.8874242",
      "lon": "13.1678075",
      "superficie_kmq": "31.4509",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058049",
      "denominazione_ita_altra": "Labico",
      "denominazione_ita": "Labico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E392",
      "lat": "41.7855817",
      "lon": "12.8860622",
      "superficie_kmq": "11.7531",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058050",
      "denominazione_ita_altra": "Lanuvio",
      "denominazione_ita": "Lanuvio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C767",
      "lat": "41.6797204",
      "lon": "12.7029755",
      "superficie_kmq": "43.7630",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058051",
      "denominazione_ita_altra": "Licenza",
      "denominazione_ita": "Licenza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E576",
      "lat": "42.0743172",
      "lon": "12.9017208",
      "superficie_kmq": "17.9850",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058052",
      "denominazione_ita_altra": "Magliano Romano",
      "denominazione_ita": "Magliano Romano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E813",
      "lat": "42.1598213",
      "lon": "12.4362942",
      "superficie_kmq": "20.5174",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058053",
      "denominazione_ita_altra": "Mandela",
      "denominazione_ita": "Mandela",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B632",
      "lat": "42.0318996",
      "lon": "12.9237507",
      "superficie_kmq": "13.7166",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058054",
      "denominazione_ita_altra": "Manziana",
      "denominazione_ita": "Manziana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E900",
      "lat": "42.1294094",
      "lon": "12.1282436",
      "superficie_kmq": "23.9991",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058055",
      "denominazione_ita_altra": "Marano Equo",
      "denominazione_ita": "Marano Equo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E908",
      "lat": "41.9946144",
      "lon": "13.0168592",
      "superficie_kmq": "7.6536",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058056",
      "denominazione_ita_altra": "Marcellina",
      "denominazione_ita": "Marcellina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E924",
      "lat": "42.0229519",
      "lon": "12.8072428",
      "superficie_kmq": "15.3560",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058057",
      "denominazione_ita_altra": "Marino",
      "denominazione_ita": "Marino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E958",
      "lat": "41.7696536",
      "lon": "12.6586071",
      "superficie_kmq": "24.1913",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058058",
      "denominazione_ita_altra": "Mazzano Romano",
      "denominazione_ita": "Mazzano Romano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F064",
      "lat": "42.2046272",
      "lon": "12.3994318",
      "superficie_kmq": "29.0667",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058059",
      "denominazione_ita_altra": "Mentana",
      "denominazione_ita": "Mentana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F127",
      "lat": "42.0336610",
      "lon": "12.6372219",
      "superficie_kmq": "24.2684",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058060",
      "denominazione_ita_altra": "Monte Compatri",
      "denominazione_ita": "Monte Compatri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F477",
      "lat": "41.8082320",
      "lon": "12.7369966",
      "superficie_kmq": "24.5711",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058061",
      "denominazione_ita_altra": "Monteflavio",
      "denominazione_ita": "Monteflavio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F504",
      "lat": "42.1109472",
      "lon": "12.8306999",
      "superficie_kmq": "16.8416",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058062",
      "denominazione_ita_altra": "Montelanico",
      "denominazione_ita": "Montelanico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F534",
      "lat": "41.6494932",
      "lon": "13.0395446",
      "superficie_kmq": "35.1398",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058063",
      "denominazione_ita_altra": "Montelibretti",
      "denominazione_ita": "Montelibretti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F545",
      "lat": "42.1348995",
      "lon": "12.7388755",
      "superficie_kmq": "45.4257",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058064",
      "denominazione_ita_altra": "Monte Porzio Catone",
      "denominazione_ita": "Monte Porzio Catone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F590",
      "lat": "41.8155529",
      "lon": "12.7166589",
      "superficie_kmq": "9.1250",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058065",
      "denominazione_ita_altra": "Monterotondo",
      "denominazione_ita": "Monterotondo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F611",
      "lat": "42.0512766",
      "lon": "12.6162456",
      "superficie_kmq": "40.9352",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058066",
      "denominazione_ita_altra": "Montorio Romano",
      "denominazione_ita": "Montorio Romano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F692",
      "lat": "42.1372260",
      "lon": "12.8059320",
      "superficie_kmq": "23.3928",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058067",
      "denominazione_ita_altra": "Moricone",
      "denominazione_ita": "Moricone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F730",
      "lat": "42.1165542",
      "lon": "12.7739343",
      "superficie_kmq": "19.5872",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058068",
      "denominazione_ita_altra": "Morlupo",
      "denominazione_ita": "Morlupo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F734",
      "lat": "42.1427883",
      "lon": "12.5044107",
      "superficie_kmq": "24.0841",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058069",
      "denominazione_ita_altra": "Nazzano",
      "denominazione_ita": "Nazzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F857",
      "lat": "42.2286082",
      "lon": "12.5972486",
      "superficie_kmq": "12.3969",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058070",
      "denominazione_ita_altra": "Nemi",
      "denominazione_ita": "Nemi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F865",
      "lat": "41.7201107",
      "lon": "12.7144233",
      "superficie_kmq": "7.3249",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058071",
      "denominazione_ita_altra": "Nerola",
      "denominazione_ita": "Nerola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F871",
      "lat": "42.1619804",
      "lon": "12.7866488",
      "superficie_kmq": "17.0960",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058072",
      "denominazione_ita_altra": "Nettuno",
      "denominazione_ita": "Nettuno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F880",
      "lat": "41.4574362",
      "lon": "12.6614125",
      "superficie_kmq": "71.6278",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058073",
      "denominazione_ita_altra": "Olevano Romano",
      "denominazione_ita": "Olevano Romano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G022",
      "lat": "41.8606785",
      "lon": "13.0324742",
      "superficie_kmq": "26.1597",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058074",
      "denominazione_ita_altra": "Palestrina",
      "denominazione_ita": "Palestrina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G274",
      "lat": "41.8393784",
      "lon": "12.8891535",
      "superficie_kmq": "47.0165",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058075",
      "denominazione_ita_altra": "Palombara Sabina",
      "denominazione_ita": "Palombara Sabina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G293",
      "lat": "42.0665022",
      "lon": "12.7651096",
      "superficie_kmq": "75.8028",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058076",
      "denominazione_ita_altra": "Percile",
      "denominazione_ita": "Percile",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G444",
      "lat": "42.0946183",
      "lon": "12.9104067",
      "superficie_kmq": "17.7577",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058077",
      "denominazione_ita_altra": "Pisoniano",
      "denominazione_ita": "Pisoniano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G704",
      "lat": "41.9081009",
      "lon": "12.9589980",
      "superficie_kmq": "12.9200",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058078",
      "denominazione_ita_altra": "Poli",
      "denominazione_ita": "Poli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G784",
      "lat": "41.8871251",
      "lon": "12.8932862",
      "superficie_kmq": "21.7449",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058079",
      "denominazione_ita_altra": "Pomezia",
      "denominazione_ita": "Pomezia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G811",
      "lat": "41.6699818",
      "lon": "12.5016320",
      "superficie_kmq": "86.5727",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058080",
      "denominazione_ita_altra": "Ponzano Romano",
      "denominazione_ita": "Ponzano Romano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G874",
      "lat": "42.2581616",
      "lon": "12.5718605",
      "superficie_kmq": "19.5196",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058081",
      "denominazione_ita_altra": "Riano",
      "denominazione_ita": "Riano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H267",
      "lat": "42.0913443",
      "lon": "12.5227285",
      "superficie_kmq": "25.4484",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058082",
      "denominazione_ita_altra": "Rignano Flaminio",
      "denominazione_ita": "Rignano Flaminio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H288",
      "lat": "42.2097027",
      "lon": "12.4807057",
      "superficie_kmq": "38.5618",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058083",
      "denominazione_ita_altra": "Riofreddo",
      "denominazione_ita": "Riofreddo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H300",
      "lat": "42.0613260",
      "lon": "12.9994922",
      "superficie_kmq": "12.3841",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058084",
      "denominazione_ita_altra": "Rocca Canterano",
      "denominazione_ita": "Rocca Canterano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H387",
      "lat": "41.9549288",
      "lon": "13.0238649",
      "superficie_kmq": "15.8371",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058085",
      "denominazione_ita_altra": "Rocca di Cave",
      "denominazione_ita": "Rocca di Cave",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H401",
      "lat": "41.8462936",
      "lon": "12.9455885",
      "superficie_kmq": "11.0854",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058086",
      "denominazione_ita_altra": "Rocca di Papa",
      "denominazione_ita": "Rocca di Papa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H404",
      "lat": "41.7619502",
      "lon": "12.7084211",
      "superficie_kmq": "39.7161",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058087",
      "denominazione_ita_altra": "Roccagiovine",
      "denominazione_ita": "Roccagiovine",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H411",
      "lat": "42.0501877",
      "lon": "12.8985993",
      "superficie_kmq": "8.4072",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058088",
      "denominazione_ita_altra": "Rocca Priora",
      "denominazione_ita": "Rocca Priora",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H432",
      "lat": "41.7941759",
      "lon": "12.7632457",
      "superficie_kmq": "28.2646",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058089",
      "denominazione_ita_altra": "Rocca Santo Stefano",
      "denominazione_ita": "Rocca Santo Stefano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H441",
      "lat": "41.9104763",
      "lon": "13.0244668",
      "superficie_kmq": "9.5724",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058090",
      "denominazione_ita_altra": "Roiate",
      "denominazione_ita": "Roiate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H494",
      "lat": "41.8740780",
      "lon": "13.0644491",
      "superficie_kmq": "10.3501",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058091",
      "denominazione_ita_altra": "Roma",
      "denominazione_ita": "Roma",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "H501",
      "lat": "41.8927240",
      "lon": "12.4836647",
      "superficie_kmq": "1287.2413",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058092",
      "denominazione_ita_altra": "Roviano",
      "denominazione_ita": "Roviano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H618",
      "lat": "42.0260994",
      "lon": "12.9945252",
      "superficie_kmq": "8.5016",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058093",
      "denominazione_ita_altra": "Sacrofano",
      "denominazione_ita": "Sacrofano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H658",
      "lat": "42.1057091",
      "lon": "12.4478132",
      "superficie_kmq": "28.5587",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058094",
      "denominazione_ita_altra": "Sambuci",
      "denominazione_ita": "Sambuci",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H745",
      "lat": "41.9862296",
      "lon": "12.9374013",
      "superficie_kmq": "8.3046",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058095",
      "denominazione_ita_altra": "San Gregorio da Sassola",
      "denominazione_ita": "San Gregorio da Sassola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H942",
      "lat": "41.9181116",
      "lon": "12.8725295",
      "superficie_kmq": "35.4501",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058096",
      "denominazione_ita_altra": "San Polo dei Cavalieri",
      "denominazione_ita": "San Polo dei Cavalieri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I125",
      "lat": "42.0122632",
      "lon": "12.8400432",
      "superficie_kmq": "42.5287",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058097",
      "denominazione_ita_altra": "Santa Marinella",
      "denominazione_ita": "Santa Marinella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I255",
      "lat": "42.0335208",
      "lon": "11.8541420",
      "superficie_kmq": "48.9057",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058098",
      "denominazione_ita_altra": "Sant'Angelo Romano",
      "denominazione_ita": "Sant'Angelo Romano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I284",
      "lat": "42.0339739",
      "lon": "12.7130232",
      "superficie_kmq": "21.3566",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058099",
      "denominazione_ita_altra": "Sant'Oreste",
      "denominazione_ita": "Sant'Oreste",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I352",
      "lat": "42.2340087",
      "lon": "12.5198785",
      "superficie_kmq": "43.8935",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058100",
      "denominazione_ita_altra": "San Vito Romano",
      "denominazione_ita": "San Vito Romano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I400",
      "lat": "41.8827194",
      "lon": "12.9833394",
      "superficie_kmq": "12.6619",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058101",
      "denominazione_ita_altra": "Saracinesco",
      "denominazione_ita": "Saracinesco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I424",
      "lat": "42.0040266",
      "lon": "12.9522946",
      "superficie_kmq": "11.1597",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058102",
      "denominazione_ita_altra": "Segni",
      "denominazione_ita": "Segni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I573",
      "lat": "41.6914033",
      "lon": "13.0231707",
      "superficie_kmq": "60.8581",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058103",
      "denominazione_ita_altra": "Subiaco",
      "denominazione_ita": "Subiaco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I992",
      "lat": "41.9251850",
      "lon": "13.0997345",
      "superficie_kmq": "63.2313",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058104",
      "denominazione_ita_altra": "Tivoli",
      "denominazione_ita": "Tivoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L182",
      "lat": "41.9634531",
      "lon": "12.7984664",
      "superficie_kmq": "68.6440",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058105",
      "denominazione_ita_altra": "Tolfa",
      "denominazione_ita": "Tolfa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L192",
      "lat": "42.1495343",
      "lon": "11.9377563",
      "superficie_kmq": "168.2671",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058106",
      "denominazione_ita_altra": "Torrita Tiberina",
      "denominazione_ita": "Torrita Tiberina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L302",
      "lat": "42.2367410",
      "lon": "12.6174051",
      "superficie_kmq": "10.7793",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058107",
      "denominazione_ita_altra": "Trevignano Romano",
      "denominazione_ita": "Trevignano Romano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L401",
      "lat": "42.1557171",
      "lon": "12.2451488",
      "superficie_kmq": "38.9862",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058108",
      "denominazione_ita_altra": "Vallepietra",
      "denominazione_ita": "Vallepietra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L611",
      "lat": "41.9267495",
      "lon": "13.2308468",
      "superficie_kmq": "52.9396",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058109",
      "denominazione_ita_altra": "Vallinfreda",
      "denominazione_ita": "Vallinfreda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L625",
      "lat": "42.0844465",
      "lon": "12.9954046",
      "superficie_kmq": "16.7183",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058110",
      "denominazione_ita_altra": "Valmontone",
      "denominazione_ita": "Valmontone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L639",
      "lat": "41.7768371",
      "lon": "12.9177431",
      "superficie_kmq": "40.9131",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058111",
      "denominazione_ita_altra": "Velletri",
      "denominazione_ita": "Velletri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L719",
      "lat": "41.6863324",
      "lon": "12.7771939",
      "superficie_kmq": "118.2667",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058112",
      "denominazione_ita_altra": "Vicovaro",
      "denominazione_ita": "Vicovaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L851",
      "lat": "42.0144392",
      "lon": "12.8915313",
      "superficie_kmq": "35.9435",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058113",
      "denominazione_ita_altra": "Vivaro Romano",
      "denominazione_ita": "Vivaro Romano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M095",
      "lat": "42.1002735",
      "lon": "13.0066394",
      "superficie_kmq": "12.5375",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058114",
      "denominazione_ita_altra": "Zagarolo",
      "denominazione_ita": "Zagarolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M141",
      "lat": "41.8398184",
      "lon": "12.8296354",
      "superficie_kmq": "28.0351",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058115",
      "denominazione_ita_altra": "Lariano",
      "denominazione_ita": "Lariano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M207",
      "lat": "41.7278742",
      "lon": "12.8326112",
      "superficie_kmq": "22.4985",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058116",
      "denominazione_ita_altra": "Ladispoli",
      "denominazione_ita": "Ladispoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M212",
      "lat": "41.9548341",
      "lon": "12.0699837",
      "superficie_kmq": "25.9515",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058117",
      "denominazione_ita_altra": "Ardea",
      "denominazione_ita": "Ardea",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M213",
      "lat": "41.6092400",
      "lon": "12.5453720",
      "superficie_kmq": "72.0684",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058118",
      "denominazione_ita_altra": "Ciampino",
      "denominazione_ita": "Ciampino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M272",
      "lat": "41.8028358",
      "lon": "12.6016862",
      "superficie_kmq": "12.9981",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058119",
      "denominazione_ita_altra": "San Cesareo",
      "denominazione_ita": "San Cesareo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M295",
      "lat": "41.8214186",
      "lon": "12.8007039",
      "superficie_kmq": "23.6431",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058120",
      "denominazione_ita_altra": "Fiumicino",
      "denominazione_ita": "Fiumicino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M297",
      "lat": "41.7721637",
      "lon": "12.2368657",
      "superficie_kmq": "213.8908",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "RM",
      "codice_istat": "058122",
      "denominazione_ita_altra": "Fonte Nuova",
      "denominazione_ita": "Fonte Nuova",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M309",
      "lat": "41.9962284",
      "lon": "12.6188700",
      "superficie_kmq": "19.9404",
      "codice_sovracomunale": "258"
    },
    {
      "sigla_provincia": "LT",
      "codice_istat": "059001",
      "denominazione_ita_altra": "Aprilia",
      "denominazione_ita": "Aprilia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A341",
      "lat": "41.5941031",
      "lon": "12.6481664",
      "superficie_kmq": "178.1230",
      "codice_sovracomunale": "059"
    },
    {
      "sigla_provincia": "LT",
      "codice_istat": "059002",
      "denominazione_ita_altra": "Bassiano",
      "denominazione_ita": "Bassiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A707",
      "lat": "41.5492885",
      "lon": "13.0322513",
      "superficie_kmq": "32.4003",
      "codice_sovracomunale": "059"
    },
    {
      "sigla_provincia": "LT",
      "codice_istat": "059003",
      "denominazione_ita_altra": "Campodimele",
      "denominazione_ita": "Campodimele",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B527",
      "lat": "41.3897463",
      "lon": "13.5302267",
      "superficie_kmq": "38.3826",
      "codice_sovracomunale": "059"
    },
    {
      "sigla_provincia": "LT",
      "codice_istat": "059004",
      "denominazione_ita_altra": "Castelforte",
      "denominazione_ita": "Castelforte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C104",
      "lat": "41.3001761",
      "lon": "13.8227446",
      "superficie_kmq": "29.7046",
      "codice_sovracomunale": "059"
    },
    {
      "sigla_provincia": "LT",
      "codice_istat": "059005",
      "denominazione_ita_altra": "Cisterna di Latina",
      "denominazione_ita": "Cisterna di Latina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C740",
      "lat": "41.5927198",
      "lon": "12.8282715",
      "superficie_kmq": "144.1568",
      "codice_sovracomunale": "059"
    },
    {
      "sigla_provincia": "LT",
      "codice_istat": "059006",
      "denominazione_ita_altra": "Cori",
      "denominazione_ita": "Cori",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D003",
      "lat": "41.6423652",
      "lon": "12.9132003",
      "superficie_kmq": "85.3114",
      "codice_sovracomunale": "059"
    },
    {
      "sigla_provincia": "LT",
      "codice_istat": "059007",
      "denominazione_ita_altra": "Fondi",
      "denominazione_ita": "Fondi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D662",
      "lat": "41.3568569",
      "lon": "13.4299044",
      "superficie_kmq": "143.9130",
      "codice_sovracomunale": "059"
    },
    {
      "sigla_provincia": "LT",
      "codice_istat": "059008",
      "denominazione_ita_altra": "Formia",
      "denominazione_ita": "Formia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D708",
      "lat": "41.2556339",
      "lon": "13.6059227",
      "superficie_kmq": "74.1642",
      "codice_sovracomunale": "059"
    },
    {
      "sigla_provincia": "LT",
      "codice_istat": "059009",
      "denominazione_ita_altra": "Gaeta",
      "denominazione_ita": "Gaeta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D843",
      "lat": "41.2133087",
      "lon": "13.5719528",
      "superficie_kmq": "29.2033",
      "codice_sovracomunale": "059"
    },
    {
      "sigla_provincia": "LT",
      "codice_istat": "059010",
      "denominazione_ita_altra": "Itri",
      "denominazione_ita": "Itri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E375",
      "lat": "41.2908646",
      "lon": "13.5312527",
      "superficie_kmq": "101.0970",
      "codice_sovracomunale": "059"
    },
    {
      "sigla_provincia": "LT",
      "codice_istat": "059011",
      "denominazione_ita_altra": "Latina",
      "denominazione_ita": "Latina",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "E472",
      "lat": "41.4675423",
      "lon": "12.9036817",
      "superficie_kmq": "277.6174",
      "codice_sovracomunale": "059"
    },
    {
      "sigla_provincia": "LT",
      "codice_istat": "059012",
      "denominazione_ita_altra": "Lenola",
      "denominazione_ita": "Lenola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E527",
      "lat": "41.4094377",
      "lon": "13.4618195",
      "superficie_kmq": "45.2423",
      "codice_sovracomunale": "059"
    },
    {
      "sigla_provincia": "LT",
      "codice_istat": "059013",
      "denominazione_ita_altra": "Maenza",
      "denominazione_ita": "Maenza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E798",
      "lat": "41.5227026",
      "lon": "13.1806726",
      "superficie_kmq": "42.1331",
      "codice_sovracomunale": "059"
    },
    {
      "sigla_provincia": "LT",
      "codice_istat": "059014",
      "denominazione_ita_altra": "Minturno",
      "denominazione_ita": "Minturno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F224",
      "lat": "41.2623991",
      "lon": "13.7465604",
      "superficie_kmq": "42.1345",
      "codice_sovracomunale": "059"
    },
    {
      "sigla_provincia": "LT",
      "codice_istat": "059015",
      "denominazione_ita_altra": "Monte San Biagio",
      "denominazione_ita": "Monte San Biagio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F616",
      "lat": "41.3529141",
      "lon": "13.3511468",
      "superficie_kmq": "65.1013",
      "codice_sovracomunale": "059"
    },
    {
      "sigla_provincia": "LT",
      "codice_istat": "059016",
      "denominazione_ita_altra": "Norma",
      "denominazione_ita": "Norma",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F937",
      "lat": "41.5852339",
      "lon": "12.9725191",
      "superficie_kmq": "31.2214",
      "codice_sovracomunale": "059"
    },
    {
      "sigla_provincia": "LT",
      "codice_istat": "059017",
      "denominazione_ita_altra": "Pontinia",
      "denominazione_ita": "Pontinia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G865",
      "lat": "41.4081761",
      "lon": "13.0440243",
      "superficie_kmq": "112.1023",
      "codice_sovracomunale": "059"
    },
    {
      "sigla_provincia": "LT",
      "codice_istat": "059018",
      "denominazione_ita_altra": "Ponza",
      "denominazione_ita": "Ponza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G871",
      "lat": "40.8948079",
      "lon": "12.9686810",
      "superficie_kmq": "10.1563",
      "codice_sovracomunale": "059"
    },
    {
      "sigla_provincia": "LT",
      "codice_istat": "059019",
      "denominazione_ita_altra": "Priverno",
      "denominazione_ita": "Priverno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G698",
      "lat": "41.4739501",
      "lon": "13.1806495",
      "superficie_kmq": "56.9808",
      "codice_sovracomunale": "059"
    },
    {
      "sigla_provincia": "LT",
      "codice_istat": "059020",
      "denominazione_ita_altra": "Prossedi",
      "denominazione_ita": "Prossedi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H076",
      "lat": "41.5168592",
      "lon": "13.2610557",
      "superficie_kmq": "35.3661",
      "codice_sovracomunale": "059"
    },
    {
      "sigla_provincia": "LT",
      "codice_istat": "059021",
      "denominazione_ita_altra": "Roccagorga",
      "denominazione_ita": "Roccagorga",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H413",
      "lat": "41.5260604",
      "lon": "13.1553367",
      "superficie_kmq": "24.4927",
      "codice_sovracomunale": "059"
    },
    {
      "sigla_provincia": "LT",
      "codice_istat": "059022",
      "denominazione_ita_altra": "Rocca Massima",
      "denominazione_ita": "Rocca Massima",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H421",
      "lat": "41.6783168",
      "lon": "12.9198607",
      "superficie_kmq": "18.1656",
      "codice_sovracomunale": "059"
    },
    {
      "sigla_provincia": "LT",
      "codice_istat": "059023",
      "denominazione_ita_altra": "Roccasecca dei Volsci",
      "denominazione_ita": "Roccasecca dei Volsci",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H444",
      "lat": "41.4797770",
      "lon": "13.2124914",
      "superficie_kmq": "23.5035",
      "codice_sovracomunale": "059"
    },
    {
      "sigla_provincia": "LT",
      "codice_istat": "059024",
      "denominazione_ita_altra": "Sabaudia",
      "denominazione_ita": "Sabaudia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H647",
      "lat": "41.3000693",
      "lon": "13.0249029",
      "superficie_kmq": "145.3721",
      "codice_sovracomunale": "059"
    },
    {
      "sigla_provincia": "LT",
      "codice_istat": "059025",
      "denominazione_ita_altra": "San Felice Circeo",
      "denominazione_ita": "San Felice Circeo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H836",
      "lat": "41.2334267",
      "lon": "13.0886938",
      "superficie_kmq": "32.6307",
      "codice_sovracomunale": "059"
    },
    {
      "sigla_provincia": "LT",
      "codice_istat": "059026",
      "denominazione_ita_altra": "Santi Cosma e Damiano",
      "denominazione_ita": "Santi Cosma e Damiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I339",
      "lat": "41.2997779",
      "lon": "13.8140132",
      "superficie_kmq": "31.6104",
      "codice_sovracomunale": "059"
    },
    {
      "sigla_provincia": "LT",
      "codice_istat": "059027",
      "denominazione_ita_altra": "Sermoneta",
      "denominazione_ita": "Sermoneta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I634",
      "lat": "41.5496825",
      "lon": "12.9838288",
      "superficie_kmq": "44.9962",
      "codice_sovracomunale": "059"
    },
    {
      "sigla_provincia": "LT",
      "codice_istat": "059028",
      "denominazione_ita_altra": "Sezze",
      "denominazione_ita": "Sezze",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I712",
      "lat": "41.4978907",
      "lon": "13.0587649",
      "superficie_kmq": "100.4710",
      "codice_sovracomunale": "059"
    },
    {
      "sigla_provincia": "LT",
      "codice_istat": "059029",
      "denominazione_ita_altra": "Sonnino",
      "denominazione_ita": "Sonnino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I832",
      "lat": "41.4141912",
      "lon": "13.2416821",
      "superficie_kmq": "63.8186",
      "codice_sovracomunale": "059"
    },
    {
      "sigla_provincia": "LT",
      "codice_istat": "059030",
      "denominazione_ita_altra": "Sperlonga",
      "denominazione_ita": "Sperlonga",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I892",
      "lat": "41.2572940",
      "lon": "13.4361497",
      "superficie_kmq": "19.4897",
      "codice_sovracomunale": "059"
    },
    {
      "sigla_provincia": "LT",
      "codice_istat": "059031",
      "denominazione_ita_altra": "Spigno Saturnia",
      "denominazione_ita": "Spigno Saturnia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I902",
      "lat": "41.3054861",
      "lon": "13.7342253",
      "superficie_kmq": "38.7401",
      "codice_sovracomunale": "059"
    },
    {
      "sigla_provincia": "LT",
      "codice_istat": "059032",
      "denominazione_ita_altra": "Terracina",
      "denominazione_ita": "Terracina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L120",
      "lat": "41.2918426",
      "lon": "13.2487838",
      "superficie_kmq": "136.5876",
      "codice_sovracomunale": "059"
    },
    {
      "sigla_provincia": "LT",
      "codice_istat": "059033",
      "denominazione_ita_altra": "Ventotene",
      "denominazione_ita": "Ventotene",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L742",
      "lat": "40.7963000",
      "lon": "13.4330428",
      "superficie_kmq": "1.7454",
      "codice_sovracomunale": "059"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060001",
      "denominazione_ita_altra": "Acquafondata",
      "denominazione_ita": "Acquafondata",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A032",
      "lat": "41.5430711",
      "lon": "13.9521630",
      "superficie_kmq": "25.3174",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060002",
      "denominazione_ita_altra": "Acuto",
      "denominazione_ita": "Acuto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A054",
      "lat": "41.7918186",
      "lon": "13.1730232",
      "superficie_kmq": "13.4662",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060003",
      "denominazione_ita_altra": "Alatri",
      "denominazione_ita": "Alatri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A123",
      "lat": "41.7264229",
      "lon": "13.3421041",
      "superficie_kmq": "96.9573",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060004",
      "denominazione_ita_altra": "Alvito",
      "denominazione_ita": "Alvito",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A244",
      "lat": "41.6877146",
      "lon": "13.7426332",
      "superficie_kmq": "51.7134",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060005",
      "denominazione_ita_altra": "Amaseno",
      "denominazione_ita": "Amaseno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A256",
      "lat": "41.4680722",
      "lon": "13.3357787",
      "superficie_kmq": "77.7258",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060006",
      "denominazione_ita_altra": "Anagni",
      "denominazione_ita": "Anagni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A269",
      "lat": "41.7438881",
      "lon": "13.1545173",
      "superficie_kmq": "112.8135",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060007",
      "denominazione_ita_altra": "Aquino",
      "denominazione_ita": "Aquino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A348",
      "lat": "41.4924344",
      "lon": "13.7039938",
      "superficie_kmq": "19.2368",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060008",
      "denominazione_ita_altra": "Arce",
      "denominazione_ita": "Arce",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A363",
      "lat": "41.5877293",
      "lon": "13.5740586",
      "superficie_kmq": "39.5217",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060009",
      "denominazione_ita_altra": "Arnara",
      "denominazione_ita": "Arnara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A421",
      "lat": "41.5837194",
      "lon": "13.3876733",
      "superficie_kmq": "12.2884",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060010",
      "denominazione_ita_altra": "Arpino",
      "denominazione_ita": "Arpino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A433",
      "lat": "41.6480786",
      "lon": "13.6107874",
      "superficie_kmq": "56.2398",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060011",
      "denominazione_ita_altra": "Atina",
      "denominazione_ita": "Atina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A486",
      "lat": "41.6197789",
      "lon": "13.8003516",
      "superficie_kmq": "29.8889",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060012",
      "denominazione_ita_altra": "Ausonia",
      "denominazione_ita": "Ausonia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A502",
      "lat": "41.3541921",
      "lon": "13.7488525",
      "superficie_kmq": "19.6376",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060013",
      "denominazione_ita_altra": "Belmonte Castello",
      "denominazione_ita": "Belmonte Castello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A763",
      "lat": "41.5782058",
      "lon": "13.8117379",
      "superficie_kmq": "14.0535",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060014",
      "denominazione_ita_altra": "Boville Ernica",
      "denominazione_ita": "Boville Ernica",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A720",
      "lat": "41.6430806",
      "lon": "13.4708585",
      "superficie_kmq": "28.1885",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060015",
      "denominazione_ita_altra": "Broccostella",
      "denominazione_ita": "Broccostella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B195",
      "lat": "41.7034663",
      "lon": "13.6344528",
      "superficie_kmq": "11.7934",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060016",
      "denominazione_ita_altra": "Campoli Appennino",
      "denominazione_ita": "Campoli Appennino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B543",
      "lat": "41.7378142",
      "lon": "13.6788185",
      "superficie_kmq": "32.4273",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060017",
      "denominazione_ita_altra": "Casalattico",
      "denominazione_ita": "Casalattico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B862",
      "lat": "41.6214167",
      "lon": "13.7246635",
      "superficie_kmq": "28.3769",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060018",
      "denominazione_ita_altra": "Casalvieri",
      "denominazione_ita": "Casalvieri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B919",
      "lat": "41.6313669",
      "lon": "13.7131534",
      "superficie_kmq": "27.2708",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060019",
      "denominazione_ita_altra": "Cassino",
      "denominazione_ita": "Cassino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C034",
      "lat": "41.4926247",
      "lon": "13.8301244",
      "superficie_kmq": "83.4187",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060020",
      "denominazione_ita_altra": "Castelliri",
      "denominazione_ita": "Castelliri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C177",
      "lat": "41.6792626",
      "lon": "13.5504779",
      "superficie_kmq": "15.3207",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060021",
      "denominazione_ita_altra": "Castelnuovo Parano",
      "denominazione_ita": "Castelnuovo Parano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C223",
      "lat": "41.3777076",
      "lon": "13.7553316",
      "superficie_kmq": "9.8819",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060022",
      "denominazione_ita_altra": "Castrocielo",
      "denominazione_ita": "Castrocielo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C340",
      "lat": "41.5311272",
      "lon": "13.6962853",
      "superficie_kmq": "27.9148",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060023",
      "denominazione_ita_altra": "Castro dei Volsci",
      "denominazione_ita": "Castro dei Volsci",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C338",
      "lat": "41.5086066",
      "lon": "13.4066708",
      "superficie_kmq": "58.4500",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060024",
      "denominazione_ita_altra": "Ceccano",
      "denominazione_ita": "Ceccano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C413",
      "lat": "41.5685141",
      "lon": "13.3336338",
      "superficie_kmq": "61.0610",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060025",
      "denominazione_ita_altra": "Ceprano",
      "denominazione_ita": "Ceprano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C479",
      "lat": "41.5456383",
      "lon": "13.5194198",
      "superficie_kmq": "38.0302",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060026",
      "denominazione_ita_altra": "Cervaro",
      "denominazione_ita": "Cervaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C545",
      "lat": "41.4809874",
      "lon": "13.9050484",
      "superficie_kmq": "39.4090",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060027",
      "denominazione_ita_altra": "Colfelice",
      "denominazione_ita": "Colfelice",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C836",
      "lat": "41.5558306",
      "lon": "13.6056563",
      "superficie_kmq": "14.5176",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060028",
      "denominazione_ita_altra": "Collepardo",
      "denominazione_ita": "Collepardo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C864",
      "lat": "41.7611393",
      "lon": "13.3677105",
      "superficie_kmq": "24.6784",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060029",
      "denominazione_ita_altra": "Colle San Magno",
      "denominazione_ita": "Colle San Magno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C870",
      "lat": "41.5497844",
      "lon": "13.6939885",
      "superficie_kmq": "44.9886",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060030",
      "denominazione_ita_altra": "Coreno Ausonio",
      "denominazione_ita": "Coreno Ausonio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C998",
      "lat": "41.3462369",
      "lon": "13.7773152",
      "superficie_kmq": "26.3838",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060031",
      "denominazione_ita_altra": "Esperia",
      "denominazione_ita": "Esperia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D440",
      "lat": "41.3851372",
      "lon": "13.6849314",
      "superficie_kmq": "108.5646",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060032",
      "denominazione_ita_altra": "Falvaterra",
      "denominazione_ita": "Falvaterra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D483",
      "lat": "41.5038099",
      "lon": "13.5225870",
      "superficie_kmq": "12.7328",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060033",
      "denominazione_ita_altra": "Ferentino",
      "denominazione_ita": "Ferentino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D539",
      "lat": "41.6922668",
      "lon": "13.2528449",
      "superficie_kmq": "80.9996",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060034",
      "denominazione_ita_altra": "Filettino",
      "denominazione_ita": "Filettino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D591",
      "lat": "41.8901963",
      "lon": "13.3241187",
      "superficie_kmq": "78.0689",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060035",
      "denominazione_ita_altra": "Fiuggi",
      "denominazione_ita": "Fiuggi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A310",
      "lat": "41.8042102",
      "lon": "13.2212451",
      "superficie_kmq": "32.9774",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060036",
      "denominazione_ita_altra": "Fontana Liri",
      "denominazione_ita": "Fontana Liri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D667",
      "lat": "41.6110192",
      "lon": "13.5499443",
      "superficie_kmq": "16.1111",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060037",
      "denominazione_ita_altra": "Fontechiari",
      "denominazione_ita": "Fontechiari",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D682",
      "lat": "41.6699827",
      "lon": "13.6765442",
      "superficie_kmq": "16.1479",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060038",
      "denominazione_ita_altra": "Frosinone",
      "denominazione_ita": "Frosinone",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "D810",
      "lat": "41.6395961",
      "lon": "13.3511683",
      "superficie_kmq": "46.8443",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060039",
      "denominazione_ita_altra": "Fumone",
      "denominazione_ita": "Fumone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D819",
      "lat": "41.7279033",
      "lon": "13.2894606",
      "superficie_kmq": "14.8358",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060040",
      "denominazione_ita_altra": "Gallinaro",
      "denominazione_ita": "Gallinaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D881",
      "lat": "41.6577358",
      "lon": "13.7986353",
      "superficie_kmq": "17.7370",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060041",
      "denominazione_ita_altra": "Giuliano di Roma",
      "denominazione_ita": "Giuliano di Roma",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E057",
      "lat": "41.5397809",
      "lon": "13.2797080",
      "superficie_kmq": "33.5444",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060042",
      "denominazione_ita_altra": "Guarcino",
      "denominazione_ita": "Guarcino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E236",
      "lat": "41.7994691",
      "lon": "13.3138956",
      "superficie_kmq": "40.3728",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060043",
      "denominazione_ita_altra": "Isola del Liri",
      "denominazione_ita": "Isola del Liri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E340",
      "lat": "41.6783701",
      "lon": "13.5749681",
      "superficie_kmq": "16.0140",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060044",
      "denominazione_ita_altra": "Monte San Giovanni Campano",
      "denominazione_ita": "Monte San Giovanni Campano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F620",
      "lat": "41.6389958",
      "lon": "13.5158567",
      "superficie_kmq": "48.7118",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060045",
      "denominazione_ita_altra": "Morolo",
      "denominazione_ita": "Morolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F740",
      "lat": "41.6377878",
      "lon": "13.1970185",
      "superficie_kmq": "26.5710",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060046",
      "denominazione_ita_altra": "Paliano",
      "denominazione_ita": "Paliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G276",
      "lat": "41.8048460",
      "lon": "13.0593387",
      "superficie_kmq": "70.6362",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060047",
      "denominazione_ita_altra": "Pastena",
      "denominazione_ita": "Pastena",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G362",
      "lat": "41.4678936",
      "lon": "13.4920154",
      "superficie_kmq": "42.1606",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060048",
      "denominazione_ita_altra": "Patrica",
      "denominazione_ita": "Patrica",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G374",
      "lat": "41.5920796",
      "lon": "13.2438699",
      "superficie_kmq": "27.3072",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060049",
      "denominazione_ita_altra": "Pescosolido",
      "denominazione_ita": "Pescosolido",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G500",
      "lat": "41.7496718",
      "lon": "13.6557251",
      "superficie_kmq": "44.8889",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060050",
      "denominazione_ita_altra": "Picinisco",
      "denominazione_ita": "Picinisco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G591",
      "lat": "41.6467783",
      "lon": "13.8671692",
      "superficie_kmq": "62.1469",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060051",
      "denominazione_ita_altra": "Pico",
      "denominazione_ita": "Pico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G592",
      "lat": "41.4516563",
      "lon": "13.5587094",
      "superficie_kmq": "32.9312",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060052",
      "denominazione_ita_altra": "Piedimonte San Germano",
      "denominazione_ita": "Piedimonte San Germano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G598",
      "lat": "41.4973201",
      "lon": "13.7503375",
      "superficie_kmq": "17.3244",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060053",
      "denominazione_ita_altra": "Piglio",
      "denominazione_ita": "Piglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G659",
      "lat": "41.8301111",
      "lon": "13.1455421",
      "superficie_kmq": "35.3812",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060054",
      "denominazione_ita_altra": "Pignataro Interamna",
      "denominazione_ita": "Pignataro Interamna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G662",
      "lat": "41.4372295",
      "lon": "13.7889489",
      "superficie_kmq": "24.4058",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060055",
      "denominazione_ita_altra": "Pofi",
      "denominazione_ita": "Pofi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G749",
      "lat": "41.5654022",
      "lon": "13.4152747",
      "superficie_kmq": "30.6809",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060056",
      "denominazione_ita_altra": "Pontecorvo",
      "denominazione_ita": "Pontecorvo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G838",
      "lat": "41.4573502",
      "lon": "13.6653676",
      "superficie_kmq": "88.8006",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060057",
      "denominazione_ita_altra": "Posta Fibreno",
      "denominazione_ita": "Posta Fibreno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G935",
      "lat": "41.6933426",
      "lon": "13.6968728",
      "superficie_kmq": "9.8043",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060058",
      "denominazione_ita_altra": "Ripi",
      "denominazione_ita": "Ripi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H324",
      "lat": "41.6126695",
      "lon": "13.4264857",
      "superficie_kmq": "31.6134",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060059",
      "denominazione_ita_altra": "Rocca d'Arce",
      "denominazione_ita": "Rocca d'Arce",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H393",
      "lat": "41.5874833",
      "lon": "13.5837328",
      "superficie_kmq": "11.5828",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060060",
      "denominazione_ita_altra": "Roccasecca",
      "denominazione_ita": "Roccasecca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H443",
      "lat": "41.5517888",
      "lon": "13.6686125",
      "superficie_kmq": "43.3317",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060061",
      "denominazione_ita_altra": "San Biagio Saracinisco",
      "denominazione_ita": "San Biagio Saracinisco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H779",
      "lat": "41.6134471",
      "lon": "13.9276632",
      "superficie_kmq": "31.2138",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060062",
      "denominazione_ita_altra": "San Donato Val di Comino",
      "denominazione_ita": "San Donato Val di Comino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H824",
      "lat": "41.7081014",
      "lon": "13.8137761",
      "superficie_kmq": "37.6342",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060063",
      "denominazione_ita_altra": "San Giorgio a Liri",
      "denominazione_ita": "San Giorgio a Liri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H880",
      "lat": "41.4068134",
      "lon": "13.7666143",
      "superficie_kmq": "15.7054",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060064",
      "denominazione_ita_altra": "San Giovanni Incarico",
      "denominazione_ita": "San Giovanni Incarico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H917",
      "lat": "41.5029694",
      "lon": "13.5571840",
      "superficie_kmq": "24.7104",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060065",
      "denominazione_ita_altra": "Sant'Ambrogio sul Garigliano",
      "denominazione_ita": "Sant'Ambrogio sul Garigliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I256",
      "lat": "41.3903178",
      "lon": "13.8691130",
      "superficie_kmq": "9.0257",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060066",
      "denominazione_ita_altra": "Sant'Andrea del Garigliano",
      "denominazione_ita": "Sant'Andrea del Garigliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I265",
      "lat": "41.3657954",
      "lon": "13.8419058",
      "superficie_kmq": "17.1068",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060067",
      "denominazione_ita_altra": "Sant'Apollinare",
      "denominazione_ita": "Sant'Apollinare",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I302",
      "lat": "41.4018954",
      "lon": "13.8306408",
      "superficie_kmq": "18.0186",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060068",
      "denominazione_ita_altra": "Sant'Elia Fiumerapido",
      "denominazione_ita": "Sant'Elia Fiumerapido",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I321",
      "lat": "41.5396136",
      "lon": "13.8654044",
      "superficie_kmq": "41.1030",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060069",
      "denominazione_ita_altra": "Santopadre",
      "denominazione_ita": "Santopadre",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I351",
      "lat": "41.6030178",
      "lon": "13.6348314",
      "superficie_kmq": "21.5978",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060070",
      "denominazione_ita_altra": "San Vittore del Lazio",
      "denominazione_ita": "San Vittore del Lazio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I408",
      "lat": "41.4642717",
      "lon": "13.9355311",
      "superficie_kmq": "27.5042",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060071",
      "denominazione_ita_altra": "Serrone",
      "denominazione_ita": "Serrone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I669",
      "lat": "41.8406187",
      "lon": "13.0965443",
      "superficie_kmq": "15.3938",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060072",
      "denominazione_ita_altra": "Settefrati",
      "denominazione_ita": "Settefrati",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I697",
      "lat": "41.6702316",
      "lon": "13.8502186",
      "superficie_kmq": "50.6770",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060073",
      "denominazione_ita_altra": "Sgurgola",
      "denominazione_ita": "Sgurgola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I716",
      "lat": "41.6706752",
      "lon": "13.1497826",
      "superficie_kmq": "19.2210",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060074",
      "denominazione_ita_altra": "Sora",
      "denominazione_ita": "Sora",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I838",
      "lat": "41.7200317",
      "lon": "13.6136241",
      "superficie_kmq": "72.1249",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060075",
      "denominazione_ita_altra": "Strangolagalli",
      "denominazione_ita": "Strangolagalli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I973",
      "lat": "41.6010507",
      "lon": "13.4918926",
      "superficie_kmq": "10.5679",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060076",
      "denominazione_ita_altra": "Supino",
      "denominazione_ita": "Supino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L009",
      "lat": "41.6089363",
      "lon": "13.2233235",
      "superficie_kmq": "35.5901",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060077",
      "denominazione_ita_altra": "Terelle",
      "denominazione_ita": "Terelle",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L105",
      "lat": "41.5535159",
      "lon": "13.7789364",
      "superficie_kmq": "31.6444",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060078",
      "denominazione_ita_altra": "Torre Cajetani",
      "denominazione_ita": "Torre Cajetani",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L243",
      "lat": "41.7891679",
      "lon": "13.2630337",
      "superficie_kmq": "11.9926",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060079",
      "denominazione_ita_altra": "Torrice",
      "denominazione_ita": "Torrice",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L290",
      "lat": "41.6294590",
      "lon": "13.3981061",
      "superficie_kmq": "18.0598",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060080",
      "denominazione_ita_altra": "Trevi nel Lazio",
      "denominazione_ita": "Trevi nel Lazio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L398",
      "lat": "41.8621836",
      "lon": "13.2481022",
      "superficie_kmq": "54.3218",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060081",
      "denominazione_ita_altra": "Trivigliano",
      "denominazione_ita": "Trivigliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L437",
      "lat": "41.7759048",
      "lon": "13.2728173",
      "superficie_kmq": "12.6404",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060082",
      "denominazione_ita_altra": "Vallecorsa",
      "denominazione_ita": "Vallecorsa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L598",
      "lat": "41.4446044",
      "lon": "13.4057634",
      "superficie_kmq": "39.2762",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060083",
      "denominazione_ita_altra": "Vallemaio",
      "denominazione_ita": "Vallemaio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L605",
      "lat": "41.3660483",
      "lon": "13.8115463",
      "superficie_kmq": "18.5375",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060084",
      "denominazione_ita_altra": "Vallerotonda",
      "denominazione_ita": "Vallerotonda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L614",
      "lat": "41.5521203",
      "lon": "13.9125956",
      "superficie_kmq": "59.6558",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060085",
      "denominazione_ita_altra": "Veroli",
      "denominazione_ita": "Veroli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L780",
      "lat": "41.6909554",
      "lon": "13.4178744",
      "superficie_kmq": "119.6404",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060086",
      "denominazione_ita_altra": "Vicalvi",
      "denominazione_ita": "Vicalvi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L836",
      "lat": "41.6764548",
      "lon": "13.7059293",
      "superficie_kmq": "8.2083",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060087",
      "denominazione_ita_altra": "Vico nel Lazio",
      "denominazione_ita": "Vico nel Lazio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L843",
      "lat": "41.7767455",
      "lon": "13.3415941",
      "superficie_kmq": "45.8421",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060088",
      "denominazione_ita_altra": "Villa Latina",
      "denominazione_ita": "Villa Latina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A081",
      "lat": "41.6137671",
      "lon": "13.8373523",
      "superficie_kmq": "17.0226",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060089",
      "denominazione_ita_altra": "Villa Santa Lucia",
      "denominazione_ita": "Villa Santa Lucia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L905",
      "lat": "41.5090514",
      "lon": "13.7697897",
      "superficie_kmq": "17.7672",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060090",
      "denominazione_ita_altra": "Villa Santo Stefano",
      "denominazione_ita": "Villa Santo Stefano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I364",
      "lat": "41.5168657",
      "lon": "13.3109572",
      "superficie_kmq": "20.0989",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "FR",
      "codice_istat": "060091",
      "denominazione_ita_altra": "Viticuso",
      "denominazione_ita": "Viticuso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M083",
      "lat": "41.5245620",
      "lon": "13.9686767",
      "superficie_kmq": "20.8595",
      "codice_sovracomunale": "060"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061001",
      "denominazione_ita_altra": "Ailano",
      "denominazione_ita": "Ailano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A106",
      "lat": "41.3907088",
      "lon": "14.2035151",
      "superficie_kmq": "16.0556",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061002",
      "denominazione_ita_altra": "Alife",
      "denominazione_ita": "Alife",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A200",
      "lat": "41.3270840",
      "lon": "14.3361567",
      "superficie_kmq": "64.3172",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061003",
      "denominazione_ita_altra": "Alvignano",
      "denominazione_ita": "Alvignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A243",
      "lat": "41.2448650",
      "lon": "14.3367627",
      "superficie_kmq": "38.1268",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061004",
      "denominazione_ita_altra": "Arienzo",
      "denominazione_ita": "Arienzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A403",
      "lat": "41.0254871",
      "lon": "14.4937922",
      "superficie_kmq": "14.0079",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061005",
      "denominazione_ita_altra": "Aversa",
      "denominazione_ita": "Aversa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A512",
      "lat": "40.9730196",
      "lon": "14.2062666",
      "superficie_kmq": "8.8518",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061006",
      "denominazione_ita_altra": "Baia e Latina",
      "denominazione_ita": "Baia e Latina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A579",
      "lat": "41.3004802",
      "lon": "14.2503360",
      "superficie_kmq": "24.4328",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061007",
      "denominazione_ita_altra": "Bellona",
      "denominazione_ita": "Bellona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A755",
      "lat": "41.1602726",
      "lon": "14.2284862",
      "superficie_kmq": "11.7783",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061008",
      "denominazione_ita_altra": "Caianello",
      "denominazione_ita": "Caianello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B361",
      "lat": "41.3042111",
      "lon": "14.0780686",
      "superficie_kmq": "15.6782",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061009",
      "denominazione_ita_altra": "Caiazzo",
      "denominazione_ita": "Caiazzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B362",
      "lat": "41.1775086",
      "lon": "14.3656700",
      "superficie_kmq": "37.0404",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061010",
      "denominazione_ita_altra": "Calvi Risorta",
      "denominazione_ita": "Calvi Risorta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B445",
      "lat": "41.2155419",
      "lon": "14.1310059",
      "superficie_kmq": "15.9577",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061011",
      "denominazione_ita_altra": "Camigliano",
      "denominazione_ita": "Camigliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B477",
      "lat": "41.1828387",
      "lon": "14.2103519",
      "superficie_kmq": "6.0171",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061012",
      "denominazione_ita_altra": "Cancello ed Arnone",
      "denominazione_ita": "Cancello ed Arnone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B581",
      "lat": "41.0734756",
      "lon": "14.0259700",
      "superficie_kmq": "49.3014",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061013",
      "denominazione_ita_altra": "Capodrise",
      "denominazione_ita": "Capodrise",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B667",
      "lat": "41.0419033",
      "lon": "14.3007499",
      "superficie_kmq": "3.4356",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061014",
      "denominazione_ita_altra": "Capriati a Volturno",
      "denominazione_ita": "Capriati a Volturno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B704",
      "lat": "41.4692132",
      "lon": "14.1461053",
      "superficie_kmq": "18.3864",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061015",
      "denominazione_ita_altra": "Capua",
      "denominazione_ita": "Capua",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B715",
      "lat": "41.1075713",
      "lon": "14.2108461",
      "superficie_kmq": "48.6030",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061016",
      "denominazione_ita_altra": "Carinaro",
      "denominazione_ita": "Carinaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B779",
      "lat": "40.9888998",
      "lon": "14.2169998",
      "superficie_kmq": "6.3159",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061017",
      "denominazione_ita_altra": "Carinola",
      "denominazione_ita": "Carinola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B781",
      "lat": "41.1873823",
      "lon": "13.9818086",
      "superficie_kmq": "59.2291",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061018",
      "denominazione_ita_altra": "Casagiove",
      "denominazione_ita": "Casagiove",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B860",
      "lat": "41.0821288",
      "lon": "14.3111949",
      "superficie_kmq": "6.3569",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061019",
      "denominazione_ita_altra": "Casal di Principe",
      "denominazione_ita": "Casal di Principe",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B872",
      "lat": "41.0072944",
      "lon": "14.1305543",
      "superficie_kmq": "23.5127",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061020",
      "denominazione_ita_altra": "Casaluce",
      "denominazione_ita": "Casaluce",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B916",
      "lat": "41.0022864",
      "lon": "14.1943705",
      "superficie_kmq": "9.5584",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061021",
      "denominazione_ita_altra": "Casapulla",
      "denominazione_ita": "Casapulla",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B935",
      "lat": "41.0747169",
      "lon": "14.2885526",
      "superficie_kmq": "2.9044",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061022",
      "denominazione_ita_altra": "Caserta",
      "denominazione_ita": "Caserta",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "B963",
      "lat": "41.0746018",
      "lon": "14.3324002",
      "superficie_kmq": "54.0685",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061023",
      "denominazione_ita_altra": "Castel Campagnano",
      "denominazione_ita": "Castel Campagnano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B494",
      "lat": "41.1823429",
      "lon": "14.4529983",
      "superficie_kmq": "17.4785",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061024",
      "denominazione_ita_altra": "Castel di Sasso",
      "denominazione_ita": "Castel di Sasso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C097",
      "lat": "41.1976368",
      "lon": "14.2843519",
      "superficie_kmq": "20.3231",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061025",
      "denominazione_ita_altra": "Castello del Matese",
      "denominazione_ita": "Castello del Matese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C178",
      "lat": "41.3666998",
      "lon": "14.3779086",
      "superficie_kmq": "21.7729",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061026",
      "denominazione_ita_altra": "Castel Morrone",
      "denominazione_ita": "Castel Morrone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C211",
      "lat": "41.1202321",
      "lon": "14.3576194",
      "superficie_kmq": "25.3439",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061027",
      "denominazione_ita_altra": "Castel Volturno",
      "denominazione_ita": "Castel Volturno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C291",
      "lat": "41.0345474",
      "lon": "13.9409399",
      "superficie_kmq": "73.9413",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061028",
      "denominazione_ita_altra": "Cervino",
      "denominazione_ita": "Cervino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C558",
      "lat": "41.0396526",
      "lon": "14.4243386",
      "superficie_kmq": "8.2077",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061029",
      "denominazione_ita_altra": "Cesa",
      "denominazione_ita": "Cesa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C561",
      "lat": "40.9624857",
      "lon": "14.2304411",
      "superficie_kmq": "2.7370",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061030",
      "denominazione_ita_altra": "Ciorlano",
      "denominazione_ita": "Ciorlano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C716",
      "lat": "41.4504190",
      "lon": "14.1580438",
      "superficie_kmq": "28.6508",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061031",
      "denominazione_ita_altra": "Conca della Campania",
      "denominazione_ita": "Conca della Campania",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C939",
      "lat": "41.3316840",
      "lon": "13.9914183",
      "superficie_kmq": "26.4670",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061032",
      "denominazione_ita_altra": "Curti",
      "denominazione_ita": "Curti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D228",
      "lat": "41.0744369",
      "lon": "14.2765515",
      "superficie_kmq": "1.6894",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061033",
      "denominazione_ita_altra": "Dragoni",
      "denominazione_ita": "Dragoni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D361",
      "lat": "41.2733427",
      "lon": "14.3084526",
      "superficie_kmq": "25.7843",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061034",
      "denominazione_ita_altra": "Fontegreca",
      "denominazione_ita": "Fontegreca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D683",
      "lat": "41.4564734",
      "lon": "14.1838444",
      "superficie_kmq": "9.7113",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061035",
      "denominazione_ita_altra": "Formicola",
      "denominazione_ita": "Formicola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D709",
      "lat": "41.2106620",
      "lon": "14.2329874",
      "superficie_kmq": "15.6781",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061036",
      "denominazione_ita_altra": "Francolise",
      "denominazione_ita": "Francolise",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D769",
      "lat": "41.1851613",
      "lon": "14.0561847",
      "superficie_kmq": "40.9244",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061037",
      "denominazione_ita_altra": "Frignano",
      "denominazione_ita": "Frignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D799",
      "lat": "40.9949108",
      "lon": "14.1794948",
      "superficie_kmq": "9.8580",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061038",
      "denominazione_ita_altra": "Gallo Matese",
      "denominazione_ita": "Gallo Matese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D884",
      "lat": "41.4649359",
      "lon": "14.2251818",
      "superficie_kmq": "31.1290",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061039",
      "denominazione_ita_altra": "Galluccio",
      "denominazione_ita": "Galluccio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D886",
      "lat": "41.3414296",
      "lon": "13.9561260",
      "superficie_kmq": "32.1131",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061040",
      "denominazione_ita_altra": "Giano Vetusto",
      "denominazione_ita": "Giano Vetusto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E011",
      "lat": "41.2040212",
      "lon": "14.1931010",
      "superficie_kmq": "10.9275",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061041",
      "denominazione_ita_altra": "Gioia Sannitica",
      "denominazione_ita": "Gioia Sannitica",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E039",
      "lat": "41.2995979",
      "lon": "14.4438042",
      "superficie_kmq": "54.4234",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061042",
      "denominazione_ita_altra": "Grazzanise",
      "denominazione_ita": "Grazzanise",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E158",
      "lat": "41.0901459",
      "lon": "14.0982235",
      "superficie_kmq": "47.0500",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061043",
      "denominazione_ita_altra": "Gricignano di Aversa",
      "denominazione_ita": "Gricignano di Aversa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E173",
      "lat": "40.9814639",
      "lon": "14.2328513",
      "superficie_kmq": "9.9776",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061044",
      "denominazione_ita_altra": "Letino",
      "denominazione_ita": "Letino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E554",
      "lat": "41.4522823",
      "lon": "14.2499783",
      "superficie_kmq": "31.5888",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061045",
      "denominazione_ita_altra": "Liberi",
      "denominazione_ita": "Liberi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E570",
      "lat": "41.2227102",
      "lon": "14.2822043",
      "superficie_kmq": "17.5891",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061046",
      "denominazione_ita_altra": "Lusciano",
      "denominazione_ita": "Lusciano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E754",
      "lat": "40.9696188",
      "lon": "14.1916853",
      "superficie_kmq": "4.5573",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061047",
      "denominazione_ita_altra": "Macerata Campania",
      "denominazione_ita": "Macerata Campania",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E784",
      "lat": "41.0630405",
      "lon": "14.2744378",
      "superficie_kmq": "7.6330",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061048",
      "denominazione_ita_altra": "Maddaloni",
      "denominazione_ita": "Maddaloni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E791",
      "lat": "41.0385539",
      "lon": "14.3838146",
      "superficie_kmq": "36.6723",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061049",
      "denominazione_ita_altra": "Marcianise",
      "denominazione_ita": "Marcianise",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E932",
      "lat": "41.0354875",
      "lon": "14.2994487",
      "superficie_kmq": "30.2360",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061050",
      "denominazione_ita_altra": "Marzano Appio",
      "denominazione_ita": "Marzano Appio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E998",
      "lat": "41.3162295",
      "lon": "14.0393264",
      "superficie_kmq": "28.3040",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061051",
      "denominazione_ita_altra": "Mignano Monte Lungo",
      "denominazione_ita": "Mignano Monte Lungo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F203",
      "lat": "41.4066789",
      "lon": "13.9874269",
      "superficie_kmq": "53.0952",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061052",
      "denominazione_ita_altra": "Mondragone",
      "denominazione_ita": "Mondragone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F352",
      "lat": "41.1128806",
      "lon": "13.8871570",
      "superficie_kmq": "55.7257",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061053",
      "denominazione_ita_altra": "Orta di Atella",
      "denominazione_ita": "Orta di Atella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G130",
      "lat": "40.9663929",
      "lon": "14.2693502",
      "superficie_kmq": "10.8339",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061054",
      "denominazione_ita_altra": "Parete",
      "denominazione_ita": "Parete",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G333",
      "lat": "40.9564469",
      "lon": "14.1642115",
      "superficie_kmq": "5.6084",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061055",
      "denominazione_ita_altra": "Pastorano",
      "denominazione_ita": "Pastorano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G364",
      "lat": "41.1822287",
      "lon": "14.2014783",
      "superficie_kmq": "14.0206",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061056",
      "denominazione_ita_altra": "Piana di Monte Verna",
      "denominazione_ita": "Piana di Monte Verna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G541",
      "lat": "41.1675036",
      "lon": "14.3340437",
      "superficie_kmq": "23.4954",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061057",
      "denominazione_ita_altra": "Piedimonte Matese",
      "denominazione_ita": "Piedimonte Matese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G596",
      "lat": "41.3558901",
      "lon": "14.3742118",
      "superficie_kmq": "41.4291",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061058",
      "denominazione_ita_altra": "Pietramelara",
      "denominazione_ita": "Pietramelara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G620",
      "lat": "41.2668082",
      "lon": "14.1861375",
      "superficie_kmq": "23.9339",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061059",
      "denominazione_ita_altra": "Pietravairano",
      "denominazione_ita": "Pietravairano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G630",
      "lat": "41.3218961",
      "lon": "14.1622130",
      "superficie_kmq": "33.4929",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061060",
      "denominazione_ita_altra": "Pignataro Maggiore",
      "denominazione_ita": "Pignataro Maggiore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G661",
      "lat": "41.1892350",
      "lon": "14.1712465",
      "superficie_kmq": "32.3783",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061061",
      "denominazione_ita_altra": "Pontelatone",
      "denominazione_ita": "Pontelatone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G849",
      "lat": "41.1933888",
      "lon": "14.2510434",
      "superficie_kmq": "32.2451",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061062",
      "denominazione_ita_altra": "Portico di Caserta",
      "denominazione_ita": "Portico di Caserta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G903",
      "lat": "41.0564332",
      "lon": "14.2805331",
      "superficie_kmq": "1.9126",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061063",
      "denominazione_ita_altra": "Prata Sannita",
      "denominazione_ita": "Prata Sannita",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G991",
      "lat": "41.4322194",
      "lon": "14.2034506",
      "superficie_kmq": "21.2092",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061064",
      "denominazione_ita_altra": "Pratella",
      "denominazione_ita": "Pratella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G995",
      "lat": "41.4057359",
      "lon": "14.1791271",
      "superficie_kmq": "33.7444",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061065",
      "denominazione_ita_altra": "Presenzano",
      "denominazione_ita": "Presenzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H045",
      "lat": "41.3774083",
      "lon": "14.0785668",
      "superficie_kmq": "31.8895",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061066",
      "denominazione_ita_altra": "Raviscanina",
      "denominazione_ita": "Raviscanina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H202",
      "lat": "41.3704709",
      "lon": "14.2428121",
      "superficie_kmq": "24.6402",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061067",
      "denominazione_ita_altra": "Recale",
      "denominazione_ita": "Recale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H210",
      "lat": "41.0563288",
      "lon": "14.3030164",
      "superficie_kmq": "3.2195",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061068",
      "denominazione_ita_altra": "Riardo",
      "denominazione_ita": "Riardo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H268",
      "lat": "41.2639349",
      "lon": "14.1490378",
      "superficie_kmq": "16.4795",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061069",
      "denominazione_ita_altra": "Rocca d'Evandro",
      "denominazione_ita": "Rocca d'Evandro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H398",
      "lat": "41.3946494",
      "lon": "13.8966185",
      "superficie_kmq": "49.5320",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061070",
      "denominazione_ita_altra": "Roccamonfina",
      "denominazione_ita": "Roccamonfina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H423",
      "lat": "41.2867799",
      "lon": "13.9790508",
      "superficie_kmq": "31.0384",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061071",
      "denominazione_ita_altra": "Roccaromana",
      "denominazione_ita": "Roccaromana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H436",
      "lat": "41.2739476",
      "lon": "14.2231910",
      "superficie_kmq": "27.7120",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061072",
      "denominazione_ita_altra": "Rocchetta e Croce",
      "denominazione_ita": "Rocchetta e Croce",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H459",
      "lat": "41.2371050",
      "lon": "14.1576418",
      "superficie_kmq": "13.0055",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061073",
      "denominazione_ita_altra": "Ruviano",
      "denominazione_ita": "Ruviano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H165",
      "lat": "41.2105717",
      "lon": "14.4098287",
      "superficie_kmq": "24.1506",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061074",
      "denominazione_ita_altra": "San Cipriano d'Aversa",
      "denominazione_ita": "San Cipriano d'Aversa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H798",
      "lat": "40.9996289",
      "lon": "14.1345624",
      "superficie_kmq": "6.1901",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061075",
      "denominazione_ita_altra": "San Felice a Cancello",
      "denominazione_ita": "San Felice a Cancello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H834",
      "lat": "41.0117311",
      "lon": "14.4809692",
      "superficie_kmq": "27.1790",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061076",
      "denominazione_ita_altra": "San Gregorio Matese",
      "denominazione_ita": "San Gregorio Matese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H939",
      "lat": "41.3855072",
      "lon": "14.3721677",
      "superficie_kmq": "56.4989",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061077",
      "denominazione_ita_altra": "San Marcellino",
      "denominazione_ita": "San Marcellino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H978",
      "lat": "40.9866558",
      "lon": "14.1739006",
      "superficie_kmq": "4.6099",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061078",
      "denominazione_ita_altra": "San Nicola la Strada",
      "denominazione_ita": "San Nicola la Strada",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I056",
      "lat": "41.0544374",
      "lon": "14.3313497",
      "superficie_kmq": "4.7271",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061079",
      "denominazione_ita_altra": "San Pietro Infine",
      "denominazione_ita": "San Pietro Infine",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I113",
      "lat": "41.4458393",
      "lon": "13.9569738",
      "superficie_kmq": "13.7174",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061080",
      "denominazione_ita_altra": "San Potito Sannitico",
      "denominazione_ita": "San Potito Sannitico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I130",
      "lat": "41.3382686",
      "lon": "14.3929741",
      "superficie_kmq": "23.1312",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061081",
      "denominazione_ita_altra": "San Prisco",
      "denominazione_ita": "San Prisco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I131",
      "lat": "41.0858424",
      "lon": "14.2786920",
      "superficie_kmq": "7.7852",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061082",
      "denominazione_ita_altra": "Santa Maria a Vico",
      "denominazione_ita": "Santa Maria a Vico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I233",
      "lat": "41.0271851",
      "lon": "14.4634906",
      "superficie_kmq": "10.8351",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061083",
      "denominazione_ita_altra": "Santa Maria Capua Vetere",
      "denominazione_ita": "Santa Maria Capua Vetere",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I234",
      "lat": "41.0774977",
      "lon": "14.2615772",
      "superficie_kmq": "15.9201",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061084",
      "denominazione_ita_altra": "Santa Maria la Fossa",
      "denominazione_ita": "Santa Maria la Fossa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I247",
      "lat": "41.0932329",
      "lon": "14.1310929",
      "superficie_kmq": "29.7297",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061085",
      "denominazione_ita_altra": "San Tammaro",
      "denominazione_ita": "San Tammaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I261",
      "lat": "41.0766313",
      "lon": "14.2320175",
      "superficie_kmq": "36.9668",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061086",
      "denominazione_ita_altra": "Sant'Angelo d'Alife",
      "denominazione_ita": "Sant'Angelo d'Alife",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I273",
      "lat": "41.3625917",
      "lon": "14.2596536",
      "superficie_kmq": "33.5227",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061087",
      "denominazione_ita_altra": "Sant'Arpino",
      "denominazione_ita": "Sant'Arpino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I306",
      "lat": "40.9578225",
      "lon": "14.2523107",
      "superficie_kmq": "3.2007",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061088",
      "denominazione_ita_altra": "Sessa Aurunca",
      "denominazione_ita": "Sessa Aurunca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I676",
      "lat": "41.2430589",
      "lon": "13.9327857",
      "superficie_kmq": "162.1870",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061089",
      "denominazione_ita_altra": "Sparanise",
      "denominazione_ita": "Sparanise",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I885",
      "lat": "41.1905184",
      "lon": "14.0963699",
      "superficie_kmq": "18.7652",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061090",
      "denominazione_ita_altra": "Succivo",
      "denominazione_ita": "Succivo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I993",
      "lat": "40.9654478",
      "lon": "14.2531683",
      "superficie_kmq": "7.2119",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061091",
      "denominazione_ita_altra": "Teano",
      "denominazione_ita": "Teano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L083",
      "lat": "41.2496177",
      "lon": "14.0686736",
      "superficie_kmq": "89.4301",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061092",
      "denominazione_ita_altra": "Teverola",
      "denominazione_ita": "Teverola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L155",
      "lat": "40.9932660",
      "lon": "14.2083447",
      "superficie_kmq": "6.6991",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061093",
      "denominazione_ita_altra": "Tora e Piccilli",
      "denominazione_ita": "Tora e Piccilli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L205",
      "lat": "41.3382938",
      "lon": "14.0219507",
      "superficie_kmq": "12.3939",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061094",
      "denominazione_ita_altra": "Trentola Ducenta",
      "denominazione_ita": "Trentola Ducenta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L379",
      "lat": "40.9755947",
      "lon": "14.1756127",
      "superficie_kmq": "6.6551",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061095",
      "denominazione_ita_altra": "Vairano Patenora",
      "denominazione_ita": "Vairano Patenora",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L540",
      "lat": "41.3330117",
      "lon": "14.1317224",
      "superficie_kmq": "43.5233",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061096",
      "denominazione_ita_altra": "Valle Agricola",
      "denominazione_ita": "Valle Agricola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L594",
      "lat": "41.4242275",
      "lon": "14.2559269",
      "superficie_kmq": "24.4196",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061097",
      "denominazione_ita_altra": "Valle di Maddaloni",
      "denominazione_ita": "Valle di Maddaloni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L591",
      "lat": "41.0800578",
      "lon": "14.4167901",
      "superficie_kmq": "10.9014",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061098",
      "denominazione_ita_altra": "Villa di Briano",
      "denominazione_ita": "Villa di Briano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D801",
      "lat": "40.9982857",
      "lon": "14.1684766",
      "superficie_kmq": "8.5469",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061099",
      "denominazione_ita_altra": "Villa Literno",
      "denominazione_ita": "Villa Literno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L844",
      "lat": "41.0096784",
      "lon": "14.0741036",
      "superficie_kmq": "61.8249",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061100",
      "denominazione_ita_altra": "Vitulazio",
      "denominazione_ita": "Vitulazio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M092",
      "lat": "41.1640731",
      "lon": "14.2189831",
      "superficie_kmq": "22.9698",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061101",
      "denominazione_ita_altra": "Falciano del Massico",
      "denominazione_ita": "Falciano del Massico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D471",
      "lat": "41.1674905",
      "lon": "13.9488582",
      "superficie_kmq": "46.7200",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061102",
      "denominazione_ita_altra": "Cellole",
      "denominazione_ita": "Cellole",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M262",
      "lat": "41.2043881",
      "lon": "13.8548945",
      "superficie_kmq": "36.7814",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061103",
      "denominazione_ita_altra": "Casapesenna",
      "denominazione_ita": "Casapesenna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M260",
      "lat": "40.9889802",
      "lon": "14.1330241",
      "superficie_kmq": "3.0481",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "CE",
      "codice_istat": "061104",
      "denominazione_ita_altra": "San Marco Evangelista",
      "denominazione_ita": "San Marco Evangelista",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F043",
      "lat": "41.0375531",
      "lon": "14.3432044",
      "superficie_kmq": "5.6867",
      "codice_sovracomunale": "061"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062001",
      "denominazione_ita_altra": "Airola",
      "denominazione_ita": "Airola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A110",
      "lat": "41.0662705",
      "lon": "14.5614021",
      "superficie_kmq": "14.8972",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062002",
      "denominazione_ita_altra": "Amorosi",
      "denominazione_ita": "Amorosi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A265",
      "lat": "41.2052534",
      "lon": "14.4611548",
      "superficie_kmq": "11.2218",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062003",
      "denominazione_ita_altra": "Apice",
      "denominazione_ita": "Apice",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A328",
      "lat": "41.1185202",
      "lon": "14.9310677",
      "superficie_kmq": "49.0381",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062004",
      "denominazione_ita_altra": "Apollosa",
      "denominazione_ita": "Apollosa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A330",
      "lat": "41.0933667",
      "lon": "14.7045197",
      "superficie_kmq": "21.1195",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062005",
      "denominazione_ita_altra": "Arpaia",
      "denominazione_ita": "Arpaia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A431",
      "lat": "41.0368001",
      "lon": "14.5517633",
      "superficie_kmq": "4.9610",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062006",
      "denominazione_ita_altra": "Arpaise",
      "denominazione_ita": "Arpaise",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A432",
      "lat": "41.0305747",
      "lon": "14.7437032",
      "superficie_kmq": "6.6568",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062007",
      "denominazione_ita_altra": "Baselice",
      "denominazione_ita": "Baselice",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A696",
      "lat": "41.3973726",
      "lon": "14.9725695",
      "superficie_kmq": "47.8169",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062008",
      "denominazione_ita_altra": "Benevento",
      "denominazione_ita": "Benevento",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "A783",
      "lat": "41.1296440",
      "lon": "14.7815123",
      "superficie_kmq": "130.8340",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062009",
      "denominazione_ita_altra": "Bonea",
      "denominazione_ita": "Bonea",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A970",
      "lat": "41.0748876",
      "lon": "14.6185555",
      "superficie_kmq": "11.4626",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062010",
      "denominazione_ita_altra": "Bucciano",
      "denominazione_ita": "Bucciano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B239",
      "lat": "41.0769887",
      "lon": "14.5699646",
      "superficie_kmq": "7.9402",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062011",
      "denominazione_ita_altra": "Buonalbergo",
      "denominazione_ita": "Buonalbergo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B267",
      "lat": "41.2212541",
      "lon": "14.9773494",
      "superficie_kmq": "25.0788",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062012",
      "denominazione_ita_altra": "Calvi",
      "denominazione_ita": "Calvi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B444",
      "lat": "41.0723688",
      "lon": "14.8669037",
      "superficie_kmq": "22.3059",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062013",
      "denominazione_ita_altra": "Campolattaro",
      "denominazione_ita": "Campolattaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B541",
      "lat": "41.2868788",
      "lon": "14.7304305",
      "superficie_kmq": "17.5858",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062014",
      "denominazione_ita_altra": "Campoli del Monte Taburno",
      "denominazione_ita": "Campoli del Monte Taburno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B542",
      "lat": "41.1306859",
      "lon": "14.6464899",
      "superficie_kmq": "9.8038",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062015",
      "denominazione_ita_altra": "Casalduni",
      "denominazione_ita": "Casalduni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B873",
      "lat": "41.2602325",
      "lon": "14.6952078",
      "superficie_kmq": "23.3398",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062016",
      "denominazione_ita_altra": "Castelfranco in Miscano",
      "denominazione_ita": "Castelfranco in Miscano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C106",
      "lat": "41.2968145",
      "lon": "15.0825659",
      "superficie_kmq": "43.3975",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062017",
      "denominazione_ita_altra": "Castelpagano",
      "denominazione_ita": "Castelpagano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C245",
      "lat": "41.4024631",
      "lon": "14.8066753",
      "superficie_kmq": "38.2547",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062018",
      "denominazione_ita_altra": "Castelpoto",
      "denominazione_ita": "Castelpoto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C250",
      "lat": "41.1409131",
      "lon": "14.6997060",
      "superficie_kmq": "11.7795",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062019",
      "denominazione_ita_altra": "Castelvenere",
      "denominazione_ita": "Castelvenere",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C280",
      "lat": "41.2345568",
      "lon": "14.5474305",
      "superficie_kmq": "15.4357",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062020",
      "denominazione_ita_altra": "Castelvetere in Val Fortore",
      "denominazione_ita": "Castelvetere in Val Fortore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C284",
      "lat": "41.4419178",
      "lon": "14.9421275",
      "superficie_kmq": "34.5730",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062021",
      "denominazione_ita_altra": "Cautano",
      "denominazione_ita": "Cautano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C359",
      "lat": "41.1491476",
      "lon": "14.6379618",
      "superficie_kmq": "19.7176",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062022",
      "denominazione_ita_altra": "Ceppaloni",
      "denominazione_ita": "Ceppaloni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C476",
      "lat": "41.0455122",
      "lon": "14.7611199",
      "superficie_kmq": "23.8035",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062023",
      "denominazione_ita_altra": "Cerreto Sannita",
      "denominazione_ita": "Cerreto Sannita",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C525",
      "lat": "41.2831651",
      "lon": "14.5568863",
      "superficie_kmq": "33.3530",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062024",
      "denominazione_ita_altra": "Circello",
      "denominazione_ita": "Circello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C719",
      "lat": "41.3559765",
      "lon": "14.8082805",
      "superficie_kmq": "45.6637",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062025",
      "denominazione_ita_altra": "Colle Sannita",
      "denominazione_ita": "Colle Sannita",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C846",
      "lat": "41.3637168",
      "lon": "14.8329537",
      "superficie_kmq": "37.2750",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062026",
      "denominazione_ita_altra": "Cusano Mutri",
      "denominazione_ita": "Cusano Mutri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D230",
      "lat": "41.3375086",
      "lon": "14.5087892",
      "superficie_kmq": "58.8586",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062027",
      "denominazione_ita_altra": "Dugenta",
      "denominazione_ita": "Dugenta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D380",
      "lat": "41.1351316",
      "lon": "14.4537214",
      "superficie_kmq": "16.0522",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062028",
      "denominazione_ita_altra": "Durazzano",
      "denominazione_ita": "Durazzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D386",
      "lat": "41.0613417",
      "lon": "14.4468967",
      "superficie_kmq": "12.9049",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062029",
      "denominazione_ita_altra": "Faicchio",
      "denominazione_ita": "Faicchio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D469",
      "lat": "41.2783490",
      "lon": "14.4794823",
      "superficie_kmq": "43.9892",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062030",
      "denominazione_ita_altra": "Foglianise",
      "denominazione_ita": "Foglianise",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D644",
      "lat": "41.1569098",
      "lon": "14.6715517",
      "superficie_kmq": "11.7656",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062031",
      "denominazione_ita_altra": "Foiano di Val Fortore",
      "denominazione_ita": "Foiano di Val Fortore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D650",
      "lat": "41.3524017",
      "lon": "14.9740264",
      "superficie_kmq": "41.3090",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062032",
      "denominazione_ita_altra": "Forchia",
      "denominazione_ita": "Forchia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D693",
      "lat": "41.0301541",
      "lon": "14.5368337",
      "superficie_kmq": "5.4545",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062033",
      "denominazione_ita_altra": "Fragneto l'Abate",
      "denominazione_ita": "Fragneto l'Abate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D755",
      "lat": "41.2555583",
      "lon": "14.7819969",
      "superficie_kmq": "20.5738",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062034",
      "denominazione_ita_altra": "Fragneto Monforte",
      "denominazione_ita": "Fragneto Monforte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D756",
      "lat": "41.2462813",
      "lon": "14.7634196",
      "superficie_kmq": "24.4921",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062035",
      "denominazione_ita_altra": "Frasso Telesino",
      "denominazione_ita": "Frasso Telesino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D784",
      "lat": "41.1574703",
      "lon": "14.5273956",
      "superficie_kmq": "21.8164",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062036",
      "denominazione_ita_altra": "Ginestra degli Schiavoni",
      "denominazione_ita": "Ginestra degli Schiavoni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E034",
      "lat": "41.2793773",
      "lon": "15.0423777",
      "superficie_kmq": "14.7871",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062037",
      "denominazione_ita_altra": "Guardia Sanframondi",
      "denominazione_ita": "Guardia Sanframondi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E249",
      "lat": "41.2564630",
      "lon": "14.6000031",
      "superficie_kmq": "21.0975",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062038",
      "denominazione_ita_altra": "Limatola",
      "denominazione_ita": "Limatola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E589",
      "lat": "41.1408703",
      "lon": "14.3943720",
      "superficie_kmq": "18.3809",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062039",
      "denominazione_ita_altra": "Melizzano",
      "denominazione_ita": "Melizzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F113",
      "lat": "41.1612755",
      "lon": "14.5044379",
      "superficie_kmq": "17.5907",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062040",
      "denominazione_ita_altra": "Moiano",
      "denominazione_ita": "Moiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F274",
      "lat": "41.0781839",
      "lon": "14.5463587",
      "superficie_kmq": "20.2035",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062041",
      "denominazione_ita_altra": "Molinara",
      "denominazione_ita": "Molinara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F287",
      "lat": "41.2937069",
      "lon": "14.9098972",
      "superficie_kmq": "24.1636",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062042",
      "denominazione_ita_altra": "Montefalcone di Val Fortore",
      "denominazione_ita": "Montefalcone di Val Fortore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F494",
      "lat": "41.3260432",
      "lon": "15.0092501",
      "superficie_kmq": "41.9380",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062043",
      "denominazione_ita_altra": "Montesarchio",
      "denominazione_ita": "Montesarchio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F636",
      "lat": "41.0635803",
      "lon": "14.6405360",
      "superficie_kmq": "26.5073",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062044",
      "denominazione_ita_altra": "Morcone",
      "denominazione_ita": "Morcone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F717",
      "lat": "41.3401512",
      "lon": "14.6640441",
      "superficie_kmq": "101.3315",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062045",
      "denominazione_ita_altra": "Paduli",
      "denominazione_ita": "Paduli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G227",
      "lat": "41.1685433",
      "lon": "14.8872978",
      "superficie_kmq": "45.2987",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062046",
      "denominazione_ita_altra": "Pago Veiano",
      "denominazione_ita": "Pago Veiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G243",
      "lat": "41.2464738",
      "lon": "14.8735081",
      "superficie_kmq": "23.7490",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062047",
      "denominazione_ita_altra": "Pannarano",
      "denominazione_ita": "Pannarano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G311",
      "lat": "41.0112361",
      "lon": "14.7024155",
      "superficie_kmq": "11.7988",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062048",
      "denominazione_ita_altra": "Paolisi",
      "denominazione_ita": "Paolisi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G318",
      "lat": "41.0372489",
      "lon": "14.5791747",
      "superficie_kmq": "6.0010",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062049",
      "denominazione_ita_altra": "Paupisi",
      "denominazione_ita": "Paupisi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G386",
      "lat": "41.1965643",
      "lon": "14.6645686",
      "superficie_kmq": "6.8327",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062050",
      "denominazione_ita_altra": "Pesco Sannita",
      "denominazione_ita": "Pesco Sannita",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G494",
      "lat": "41.2343019",
      "lon": "14.8109152",
      "superficie_kmq": "24.1537",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062051",
      "denominazione_ita_altra": "Pietraroja",
      "denominazione_ita": "Pietraroja",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G626",
      "lat": "41.3472656",
      "lon": "14.5498656",
      "superficie_kmq": "35.8130",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062052",
      "denominazione_ita_altra": "Pietrelcina",
      "denominazione_ita": "Pietrelcina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G631",
      "lat": "41.1968252",
      "lon": "14.8487431",
      "superficie_kmq": "28.2535",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062053",
      "denominazione_ita_altra": "Ponte",
      "denominazione_ita": "Ponte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G827",
      "lat": "41.2129604",
      "lon": "14.6899697",
      "superficie_kmq": "17.9153",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062054",
      "denominazione_ita_altra": "Pontelandolfo",
      "denominazione_ita": "Pontelandolfo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G848",
      "lat": "41.2884417",
      "lon": "14.6907351",
      "superficie_kmq": "29.0289",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062055",
      "denominazione_ita_altra": "Puglianello",
      "denominazione_ita": "Puglianello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H087",
      "lat": "41.2217346",
      "lon": "14.4502304",
      "superficie_kmq": "8.7611",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062056",
      "denominazione_ita_altra": "Reino",
      "denominazione_ita": "Reino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H227",
      "lat": "41.2922287",
      "lon": "14.8225778",
      "superficie_kmq": "23.6384",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062057",
      "denominazione_ita_altra": "San Bartolomeo in Galdo",
      "denominazione_ita": "San Bartolomeo in Galdo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H764",
      "lat": "41.4097318",
      "lon": "15.0150742",
      "superficie_kmq": "82.6623",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062058",
      "denominazione_ita_altra": "San Giorgio del Sannio",
      "denominazione_ita": "San Giorgio del Sannio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H894",
      "lat": "41.0623531",
      "lon": "14.8535546",
      "superficie_kmq": "22.3386",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062059",
      "denominazione_ita_altra": "San Giorgio La Molara",
      "denominazione_ita": "San Giorgio La Molara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H898",
      "lat": "41.2711151",
      "lon": "14.9176374",
      "superficie_kmq": "65.7653",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062060",
      "denominazione_ita_altra": "San Leucio del Sannio",
      "denominazione_ita": "San Leucio del Sannio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H953",
      "lat": "41.0726381",
      "lon": "14.7576713",
      "superficie_kmq": "9.9590",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062061",
      "denominazione_ita_altra": "San Lorenzello",
      "denominazione_ita": "San Lorenzello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H955",
      "lat": "41.2759782",
      "lon": "14.5411668",
      "superficie_kmq": "13.8833",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062062",
      "denominazione_ita_altra": "San Lorenzo Maggiore",
      "denominazione_ita": "San Lorenzo Maggiore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H967",
      "lat": "41.2502495",
      "lon": "14.6256678",
      "superficie_kmq": "16.2955",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062063",
      "denominazione_ita_altra": "San Lupo",
      "denominazione_ita": "San Lupo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H973",
      "lat": "41.2603929",
      "lon": "14.6351008",
      "superficie_kmq": "15.3043",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062064",
      "denominazione_ita_altra": "San Marco dei Cavoti",
      "denominazione_ita": "San Marco dei Cavoti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H984",
      "lat": "41.3059578",
      "lon": "14.8806795",
      "superficie_kmq": "49.1870",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062065",
      "denominazione_ita_altra": "San Martino Sannita",
      "denominazione_ita": "San Martino Sannita",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I002",
      "lat": "41.0654021",
      "lon": "14.8349179",
      "superficie_kmq": "6.1840",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062066",
      "denominazione_ita_altra": "San Nazzaro",
      "denominazione_ita": "San Nazzaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I049",
      "lat": "41.0496570",
      "lon": "14.8567888",
      "superficie_kmq": "2.0421",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062067",
      "denominazione_ita_altra": "San Nicola Manfredi",
      "denominazione_ita": "San Nicola Manfredi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I062",
      "lat": "41.0724110",
      "lon": "14.8244232",
      "superficie_kmq": "19.2242",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062068",
      "denominazione_ita_altra": "San Salvatore Telesino",
      "denominazione_ita": "San Salvatore Telesino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I145",
      "lat": "41.2357441",
      "lon": "14.4948754",
      "superficie_kmq": "18.3108",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062069",
      "denominazione_ita_altra": "Santa Croce del Sannio",
      "denominazione_ita": "Santa Croce del Sannio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I179",
      "lat": "41.3878749",
      "lon": "14.7322883",
      "superficie_kmq": "16.2410",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062070",
      "denominazione_ita_altra": "Sant'Agata de' Goti",
      "denominazione_ita": "Sant'Agata de' Goti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I197",
      "lat": "41.0907393",
      "lon": "14.5038050",
      "superficie_kmq": "63.3786",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062071",
      "denominazione_ita_altra": "Sant'Angelo a Cupolo",
      "denominazione_ita": "Sant'Angelo a Cupolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I277",
      "lat": "41.0679212",
      "lon": "14.8035014",
      "superficie_kmq": "11.0134",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062072",
      "denominazione_ita_altra": "Sassinoro",
      "denominazione_ita": "Sassinoro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I455",
      "lat": "41.3746160",
      "lon": "14.6623029",
      "superficie_kmq": "13.2448",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062073",
      "denominazione_ita_altra": "Solopaca",
      "denominazione_ita": "Solopaca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I809",
      "lat": "41.1939578",
      "lon": "14.5529191",
      "superficie_kmq": "31.1266",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062074",
      "denominazione_ita_altra": "Telese Terme",
      "denominazione_ita": "Telese Terme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L086",
      "lat": "41.2194500",
      "lon": "14.5311160",
      "superficie_kmq": "9.9987",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062075",
      "denominazione_ita_altra": "Tocco Caudio",
      "denominazione_ita": "Tocco Caudio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L185",
      "lat": "41.1250910",
      "lon": "14.6324753",
      "superficie_kmq": "27.4911",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062076",
      "denominazione_ita_altra": "Torrecuso",
      "denominazione_ita": "Torrecuso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L254",
      "lat": "41.1858381",
      "lon": "14.6810137",
      "superficie_kmq": "29.1549",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062077",
      "denominazione_ita_altra": "Vitulano",
      "denominazione_ita": "Vitulano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M093",
      "lat": "41.1727913",
      "lon": "14.6500406",
      "superficie_kmq": "35.9905",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "BN",
      "codice_istat": "062078",
      "denominazione_ita_altra": "Sant'Arcangelo Trimonte",
      "denominazione_ita": "Sant'Arcangelo Trimonte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F557",
      "lat": "41.1689563",
      "lon": "14.9388232",
      "superficie_kmq": "9.7986",
      "codice_sovracomunale": "062"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063001",
      "denominazione_ita_altra": "Acerra",
      "denominazione_ita": "Acerra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A024",
      "lat": "40.9458052",
      "lon": "14.3711793",
      "superficie_kmq": "54.7117",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063002",
      "denominazione_ita_altra": "Afragola",
      "denominazione_ita": "Afragola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A064",
      "lat": "40.9223013",
      "lon": "14.3098614",
      "superficie_kmq": "17.9106",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063003",
      "denominazione_ita_altra": "Agerola",
      "denominazione_ita": "Agerola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A068",
      "lat": "40.6379205",
      "lon": "14.5449112",
      "superficie_kmq": "19.8316",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063004",
      "denominazione_ita_altra": "Anacapri",
      "denominazione_ita": "Anacapri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A268",
      "lat": "40.5513594",
      "lon": "14.2165317",
      "superficie_kmq": "6.4666",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063005",
      "denominazione_ita_altra": "Arzano",
      "denominazione_ita": "Arzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A455",
      "lat": "40.9105657",
      "lon": "14.2692036",
      "superficie_kmq": "4.7310",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063006",
      "denominazione_ita_altra": "Bacoli",
      "denominazione_ita": "Bacoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A535",
      "lat": "40.7970243",
      "lon": "14.0783992",
      "superficie_kmq": "13.4675",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063007",
      "denominazione_ita_altra": "Barano d'Ischia",
      "denominazione_ita": "Barano d'Ischia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A617",
      "lat": "40.7105700",
      "lon": "13.9162428",
      "superficie_kmq": "10.9609",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063008",
      "denominazione_ita_altra": "Boscoreale",
      "denominazione_ita": "Boscoreale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B076",
      "lat": "40.7752571",
      "lon": "14.4752678",
      "superficie_kmq": "11.3981",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063009",
      "denominazione_ita_altra": "Boscotrecase",
      "denominazione_ita": "Boscotrecase",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B077",
      "lat": "40.7762079",
      "lon": "14.4610690",
      "superficie_kmq": "7.5305",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063010",
      "denominazione_ita_altra": "Brusciano",
      "denominazione_ita": "Brusciano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B227",
      "lat": "40.9205555",
      "lon": "14.4225313",
      "superficie_kmq": "5.6161",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063011",
      "denominazione_ita_altra": "Caivano",
      "denominazione_ita": "Caivano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B371",
      "lat": "40.9566999",
      "lon": "14.3010593",
      "superficie_kmq": "27.2212",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063012",
      "denominazione_ita_altra": "Calvizzano",
      "denominazione_ita": "Calvizzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B452",
      "lat": "40.9074589",
      "lon": "14.1895854",
      "superficie_kmq": "3.9831",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063013",
      "denominazione_ita_altra": "Camposano",
      "denominazione_ita": "Camposano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B565",
      "lat": "40.9558417",
      "lon": "14.5249782",
      "superficie_kmq": "3.3276",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063014",
      "denominazione_ita_altra": "Capri",
      "denominazione_ita": "Capri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B696",
      "lat": "40.5509040",
      "lon": "14.2430414",
      "superficie_kmq": "4.0628",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063015",
      "denominazione_ita_altra": "Carbonara di Nola",
      "denominazione_ita": "Carbonara di Nola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B740",
      "lat": "40.8750556",
      "lon": "14.5760793",
      "superficie_kmq": "3.6495",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063016",
      "denominazione_ita_altra": "Cardito",
      "denominazione_ita": "Cardito",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B759",
      "lat": "40.9464174",
      "lon": "14.2953331",
      "superficie_kmq": "3.1983",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063017",
      "denominazione_ita_altra": "Casalnuovo di Napoli",
      "denominazione_ita": "Casalnuovo di Napoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B905",
      "lat": "40.9147515",
      "lon": "14.3511814",
      "superficie_kmq": "7.8354",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063018",
      "denominazione_ita_altra": "Casamarciano",
      "denominazione_ita": "Casamarciano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B922",
      "lat": "40.9328326",
      "lon": "14.5535800",
      "superficie_kmq": "6.3842",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063019",
      "denominazione_ita_altra": "Casamicciola Terme",
      "denominazione_ita": "Casamicciola Terme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B924",
      "lat": "40.7466508",
      "lon": "13.9074743",
      "superficie_kmq": "5.8488",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063020",
      "denominazione_ita_altra": "Casandrino",
      "denominazione_ita": "Casandrino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B925",
      "lat": "40.9356783",
      "lon": "14.2513745",
      "superficie_kmq": "3.1823",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063021",
      "denominazione_ita_altra": "Casavatore",
      "denominazione_ita": "Casavatore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B946",
      "lat": "40.9000581",
      "lon": "14.2745230",
      "superficie_kmq": "1.5267",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063022",
      "denominazione_ita_altra": "Casola di Napoli",
      "denominazione_ita": "Casola di Napoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B980",
      "lat": "40.6942964",
      "lon": "14.5286312",
      "superficie_kmq": "2.5932",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063023",
      "denominazione_ita_altra": "Casoria",
      "denominazione_ita": "Casoria",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B990",
      "lat": "40.9056011",
      "lon": "14.2902554",
      "superficie_kmq": "12.1384",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063024",
      "denominazione_ita_altra": "Castellammare di Stabia",
      "denominazione_ita": "Castellammare di Stabia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C129",
      "lat": "40.6944630",
      "lon": "14.4803190",
      "superficie_kmq": "17.8099",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063025",
      "denominazione_ita_altra": "Castello di Cisterna",
      "denominazione_ita": "Castello di Cisterna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C188",
      "lat": "40.9166369",
      "lon": "14.4078895",
      "superficie_kmq": "3.9214",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063026",
      "denominazione_ita_altra": "Cercola",
      "denominazione_ita": "Cercola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C495",
      "lat": "40.8599879",
      "lon": "14.3589097",
      "superficie_kmq": "4.2290",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063027",
      "denominazione_ita_altra": "Cicciano",
      "denominazione_ita": "Cicciano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C675",
      "lat": "40.9635758",
      "lon": "14.5431972",
      "superficie_kmq": "7.3311",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063028",
      "denominazione_ita_altra": "Cimitile",
      "denominazione_ita": "Cimitile",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C697",
      "lat": "40.9396599",
      "lon": "14.5267265",
      "superficie_kmq": "2.7406",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063029",
      "denominazione_ita_altra": "Comiziano",
      "denominazione_ita": "Comiziano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C929",
      "lat": "40.9524515",
      "lon": "14.5493434",
      "superficie_kmq": "2.4494",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063030",
      "denominazione_ita_altra": "Crispano",
      "denominazione_ita": "Crispano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D170",
      "lat": "40.9525536",
      "lon": "14.2849170",
      "superficie_kmq": "2.2191",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063031",
      "denominazione_ita_altra": "Forio",
      "denominazione_ita": "Forio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D702",
      "lat": "40.7377238",
      "lon": "13.8572580",
      "superficie_kmq": "13.0814",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063032",
      "denominazione_ita_altra": "Frattamaggiore",
      "denominazione_ita": "Frattamaggiore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D789",
      "lat": "40.9411434",
      "lon": "14.2725756",
      "superficie_kmq": "5.3882",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063033",
      "denominazione_ita_altra": "Frattaminore",
      "denominazione_ita": "Frattaminore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D790",
      "lat": "40.9555911",
      "lon": "14.2708967",
      "superficie_kmq": "2.0417",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063034",
      "denominazione_ita_altra": "Giugliano in Campania",
      "denominazione_ita": "Giugliano in Campania",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E054",
      "lat": "40.9282750",
      "lon": "14.2010842",
      "superficie_kmq": "94.6321",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063035",
      "denominazione_ita_altra": "Gragnano",
      "denominazione_ita": "Gragnano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E131",
      "lat": "40.6897652",
      "lon": "14.5209766",
      "superficie_kmq": "14.6424",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063036",
      "denominazione_ita_altra": "Grumo Nevano",
      "denominazione_ita": "Grumo Nevano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E224",
      "lat": "40.9381916",
      "lon": "14.2597478",
      "superficie_kmq": "2.8769",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063037",
      "denominazione_ita_altra": "Ischia",
      "denominazione_ita": "Ischia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E329",
      "lat": "40.7413664",
      "lon": "13.9413297",
      "superficie_kmq": "8.1402",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063038",
      "denominazione_ita_altra": "Lacco Ameno",
      "denominazione_ita": "Lacco Ameno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E396",
      "lat": "40.7544534",
      "lon": "13.8843837",
      "superficie_kmq": "2.0768",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063039",
      "denominazione_ita_altra": "Lettere",
      "denominazione_ita": "Lettere",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E557",
      "lat": "40.7043513",
      "lon": "14.5446078",
      "superficie_kmq": "12.0191",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063040",
      "denominazione_ita_altra": "Liveri",
      "denominazione_ita": "Liveri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E620",
      "lat": "40.9023033",
      "lon": "14.5671297",
      "superficie_kmq": "2.7109",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063041",
      "denominazione_ita_altra": "Marano di Napoli",
      "denominazione_ita": "Marano di Napoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E906",
      "lat": "40.8985673",
      "lon": "14.1903595",
      "superficie_kmq": "15.6525",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063042",
      "denominazione_ita_altra": "Mariglianella",
      "denominazione_ita": "Mariglianella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E954",
      "lat": "40.9265659",
      "lon": "14.4385194",
      "superficie_kmq": "3.2623",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063043",
      "denominazione_ita_altra": "Marigliano",
      "denominazione_ita": "Marigliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E955",
      "lat": "40.9251760",
      "lon": "14.4570140",
      "superficie_kmq": "22.5752",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063044",
      "denominazione_ita_altra": "Massa Lubrense",
      "denominazione_ita": "Massa Lubrense",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F030",
      "lat": "40.6110566",
      "lon": "14.3440304",
      "superficie_kmq": "19.8362",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063045",
      "denominazione_ita_altra": "Melito di Napoli",
      "denominazione_ita": "Melito di Napoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F111",
      "lat": "40.9239662",
      "lon": "14.2326960",
      "superficie_kmq": "3.7992",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063046",
      "denominazione_ita_altra": "Meta",
      "denominazione_ita": "Meta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F162",
      "lat": "40.6439705",
      "lon": "14.4126803",
      "superficie_kmq": "2.2511",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063047",
      "denominazione_ita_altra": "Monte di Procida",
      "denominazione_ita": "Monte di Procida",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F488",
      "lat": "40.7949446",
      "lon": "14.0538925",
      "superficie_kmq": "3.7043",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063048",
      "denominazione_ita_altra": "Mugnano di Napoli",
      "denominazione_ita": "Mugnano di Napoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F799",
      "lat": "40.9094505",
      "lon": "14.2064866",
      "superficie_kmq": "5.2636",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063049",
      "denominazione_ita_altra": "Napoli",
      "denominazione_ita": "Napoli",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "F839",
      "lat": "40.8395051",
      "lon": "14.2508452",
      "superficie_kmq": "118.9441",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063050",
      "denominazione_ita_altra": "Nola",
      "denominazione_ita": "Nola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F924",
      "lat": "40.9262694",
      "lon": "14.5268277",
      "superficie_kmq": "39.1853",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063051",
      "denominazione_ita_altra": "Ottaviano",
      "denominazione_ita": "Ottaviano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G190",
      "lat": "40.8493002",
      "lon": "14.4774368",
      "superficie_kmq": "20.0227",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063052",
      "denominazione_ita_altra": "Palma Campania",
      "denominazione_ita": "Palma Campania",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G283",
      "lat": "40.8669718",
      "lon": "14.5563321",
      "superficie_kmq": "20.6708",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063053",
      "denominazione_ita_altra": "Piano di Sorrento",
      "denominazione_ita": "Piano di Sorrento",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G568",
      "lat": "40.6338375",
      "lon": "14.4132166",
      "superficie_kmq": "7.3354",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063054",
      "denominazione_ita_altra": "Pimonte",
      "denominazione_ita": "Pimonte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G670",
      "lat": "40.6736243",
      "lon": "14.5134490",
      "superficie_kmq": "12.5394",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063055",
      "denominazione_ita_altra": "Poggiomarino",
      "denominazione_ita": "Poggiomarino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G762",
      "lat": "40.7986052",
      "lon": "14.5408004",
      "superficie_kmq": "13.2007",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063056",
      "denominazione_ita_altra": "Pollena Trocchia",
      "denominazione_ita": "Pollena Trocchia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G795",
      "lat": "40.8580341",
      "lon": "14.3787372",
      "superficie_kmq": "8.0156",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063057",
      "denominazione_ita_altra": "Pomigliano d'Arco",
      "denominazione_ita": "Pomigliano d'Arco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G812",
      "lat": "40.9068362",
      "lon": "14.3933510",
      "superficie_kmq": "11.7093",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063058",
      "denominazione_ita_altra": "Pompei",
      "denominazione_ita": "Pompei",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G813",
      "lat": "40.7483563",
      "lon": "14.5000092",
      "superficie_kmq": "12.4303",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063059",
      "denominazione_ita_altra": "Portici",
      "denominazione_ita": "Portici",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G902",
      "lat": "40.8236965",
      "lon": "14.3542097",
      "superficie_kmq": "4.6013",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063060",
      "denominazione_ita_altra": "Pozzuoli",
      "denominazione_ita": "Pozzuoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G964",
      "lat": "40.8467581",
      "lon": "14.0908902",
      "superficie_kmq": "43.4339",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063061",
      "denominazione_ita_altra": "Procida",
      "denominazione_ita": "Procida",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H072",
      "lat": "40.7657251",
      "lon": "14.0240350",
      "superficie_kmq": "4.2559",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063062",
      "denominazione_ita_altra": "Qualiano",
      "denominazione_ita": "Qualiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H101",
      "lat": "40.9201413",
      "lon": "14.1508624",
      "superficie_kmq": "7.4345",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063063",
      "denominazione_ita_altra": "Quarto",
      "denominazione_ita": "Quarto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H114",
      "lat": "40.8776408",
      "lon": "14.1435051",
      "superficie_kmq": "14.1590",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063064",
      "denominazione_ita_altra": "Ercolano",
      "denominazione_ita": "Ercolano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H243",
      "lat": "40.8092169",
      "lon": "14.3457100",
      "superficie_kmq": "19.8936",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063065",
      "denominazione_ita_altra": "Roccarainola",
      "denominazione_ita": "Roccarainola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H433",
      "lat": "40.9723638",
      "lon": "14.5620532",
      "superficie_kmq": "28.3315",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063066",
      "denominazione_ita_altra": "San Gennaro Vesuviano",
      "denominazione_ita": "San Gennaro Vesuviano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H860",
      "lat": "40.8611291",
      "lon": "14.5292867",
      "superficie_kmq": "7.0144",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063067",
      "denominazione_ita_altra": "San Giorgio a Cremano",
      "denominazione_ita": "San Giorgio a Cremano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H892",
      "lat": "40.8291512",
      "lon": "14.3339209",
      "superficie_kmq": "4.1538",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063068",
      "denominazione_ita_altra": "San Giuseppe Vesuviano",
      "denominazione_ita": "San Giuseppe Vesuviano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H931",
      "lat": "40.8341969",
      "lon": "14.5061720",
      "superficie_kmq": "14.1713",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063069",
      "denominazione_ita_altra": "San Paolo Bel Sito",
      "denominazione_ita": "San Paolo Bel Sito",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I073",
      "lat": "40.9126589",
      "lon": "14.5483835",
      "superficie_kmq": "2.9480",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063070",
      "denominazione_ita_altra": "San Sebastiano al Vesuvio",
      "denominazione_ita": "San Sebastiano al Vesuvio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I151",
      "lat": "40.8409951",
      "lon": "14.3717760",
      "superficie_kmq": "2.6395",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063071",
      "denominazione_ita_altra": "Sant'Agnello",
      "denominazione_ita": "Sant'Agnello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I208",
      "lat": "40.6346812",
      "lon": "14.3976350",
      "superficie_kmq": "4.1482",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063072",
      "denominazione_ita_altra": "Sant'Anastasia",
      "denominazione_ita": "Sant'Anastasia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I262",
      "lat": "40.8669478",
      "lon": "14.4021147",
      "superficie_kmq": "18.7405",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063073",
      "denominazione_ita_altra": "Sant'Antimo",
      "denominazione_ita": "Sant'Antimo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I293",
      "lat": "40.9332123",
      "lon": "14.2360241",
      "superficie_kmq": "5.9133",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063074",
      "denominazione_ita_altra": "Sant'Antonio Abate",
      "denominazione_ita": "Sant'Antonio Abate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I300",
      "lat": "40.7224101",
      "lon": "14.5469493",
      "superficie_kmq": "7.9318",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063075",
      "denominazione_ita_altra": "San Vitaliano",
      "denominazione_ita": "San Vitaliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I391",
      "lat": "40.9228684",
      "lon": "14.4821887",
      "superficie_kmq": "5.3696",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063076",
      "denominazione_ita_altra": "Saviano",
      "denominazione_ita": "Saviano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I469",
      "lat": "40.9110690",
      "lon": "14.5096173",
      "superficie_kmq": "13.8775",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063077",
      "denominazione_ita_altra": "Scisciano",
      "denominazione_ita": "Scisciano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I540",
      "lat": "40.9173494",
      "lon": "14.4828108",
      "superficie_kmq": "5.4791",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063078",
      "denominazione_ita_altra": "Serrara Fontana",
      "denominazione_ita": "Serrara Fontana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I652",
      "lat": "40.7117964",
      "lon": "13.8931037",
      "superficie_kmq": "6.4432",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063079",
      "denominazione_ita_altra": "Somma Vesuviana",
      "denominazione_ita": "Somma Vesuviana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I820",
      "lat": "40.8682520",
      "lon": "14.4397470",
      "superficie_kmq": "30.6664",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063080",
      "denominazione_ita_altra": "Sorrento",
      "denominazione_ita": "Sorrento",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I862",
      "lat": "40.6281640",
      "lon": "14.3785279",
      "superficie_kmq": "9.9556",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063081",
      "denominazione_ita_altra": "Striano",
      "denominazione_ita": "Striano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I978",
      "lat": "40.8137992",
      "lon": "14.5778809",
      "superficie_kmq": "7.6518",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063082",
      "denominazione_ita_altra": "Terzigno",
      "denominazione_ita": "Terzigno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L142",
      "lat": "40.8076215",
      "lon": "14.4976697",
      "superficie_kmq": "23.4648",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063083",
      "denominazione_ita_altra": "Torre Annunziata",
      "denominazione_ita": "Torre Annunziata",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L245",
      "lat": "40.7581117",
      "lon": "14.4475811",
      "superficie_kmq": "7.5080",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063084",
      "denominazione_ita_altra": "Torre del Greco",
      "denominazione_ita": "Torre del Greco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L259",
      "lat": "40.7866290",
      "lon": "14.3655194",
      "superficie_kmq": "30.6611",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063085",
      "denominazione_ita_altra": "Tufino",
      "denominazione_ita": "Tufino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L460",
      "lat": "40.9555309",
      "lon": "14.5651620",
      "superficie_kmq": "5.2070",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063086",
      "denominazione_ita_altra": "Vico Equense",
      "denominazione_ita": "Vico Equense",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L845",
      "lat": "40.6643709",
      "lon": "14.4253308",
      "superficie_kmq": "29.3794",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063087",
      "denominazione_ita_altra": "Villaricca",
      "denominazione_ita": "Villaricca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G309",
      "lat": "40.9217537",
      "lon": "14.1948136",
      "superficie_kmq": "6.8729",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063088",
      "denominazione_ita_altra": "Visciano",
      "denominazione_ita": "Visciano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M072",
      "lat": "40.9236666",
      "lon": "14.5872393",
      "superficie_kmq": "10.9036",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063089",
      "denominazione_ita_altra": "Volla",
      "denominazione_ita": "Volla",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M115",
      "lat": "40.8794487",
      "lon": "14.3463177",
      "superficie_kmq": "6.2063",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063090",
      "denominazione_ita_altra": "Santa Maria la Carità",
      "denominazione_ita": "Santa Maria la Carità",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M273",
      "lat": "40.7209386",
      "lon": "14.5129363",
      "superficie_kmq": "3.9788",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063091",
      "denominazione_ita_altra": "Trecase",
      "denominazione_ita": "Trecase",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M280",
      "lat": "40.7698059",
      "lon": "14.4359466",
      "superficie_kmq": "6.2131",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "NA",
      "codice_istat": "063092",
      "denominazione_ita_altra": "Massa di Somma",
      "denominazione_ita": "Massa di Somma",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M289",
      "lat": "40.8465478",
      "lon": "14.3773931",
      "superficie_kmq": "3.0421",
      "codice_sovracomunale": "263"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064001",
      "denominazione_ita_altra": "Aiello del Sabato",
      "denominazione_ita": "Aiello del Sabato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A101",
      "lat": "40.8891012",
      "lon": "14.8198237",
      "superficie_kmq": "10.8723",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064002",
      "denominazione_ita_altra": "Altavilla Irpina",
      "denominazione_ita": "Altavilla Irpina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A228",
      "lat": "41.0057724",
      "lon": "14.7774535",
      "superficie_kmq": "14.0831",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064003",
      "denominazione_ita_altra": "Andretta",
      "denominazione_ita": "Andretta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A284",
      "lat": "40.9324902",
      "lon": "15.3214667",
      "superficie_kmq": "43.6485",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064004",
      "denominazione_ita_altra": "Aquilonia",
      "denominazione_ita": "Aquilonia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A347",
      "lat": "40.9865502",
      "lon": "15.4717515",
      "superficie_kmq": "56.1450",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064005",
      "denominazione_ita_altra": "Ariano Irpino",
      "denominazione_ita": "Ariano Irpino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A399",
      "lat": "41.1538035",
      "lon": "15.0900627",
      "superficie_kmq": "186.7365",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064006",
      "denominazione_ita_altra": "Atripalda",
      "denominazione_ita": "Atripalda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A489",
      "lat": "40.9179409",
      "lon": "14.8366467",
      "superficie_kmq": "8.5931",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064007",
      "denominazione_ita_altra": "Avella",
      "denominazione_ita": "Avella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A508",
      "lat": "40.9602777",
      "lon": "14.5999409",
      "superficie_kmq": "29.3890",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064008",
      "denominazione_ita_altra": "Avellino",
      "denominazione_ita": "Avellino",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "A509",
      "lat": "40.9139880",
      "lon": "14.7952832",
      "superficie_kmq": "30.5500",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064009",
      "denominazione_ita_altra": "Bagnoli Irpino",
      "denominazione_ita": "Bagnoli Irpino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A566",
      "lat": "40.8326021",
      "lon": "15.0698173",
      "superficie_kmq": "68.8043",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064010",
      "denominazione_ita_altra": "Baiano",
      "denominazione_ita": "Baiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A580",
      "lat": "40.9513580",
      "lon": "14.6171241",
      "superficie_kmq": "12.2951",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064011",
      "denominazione_ita_altra": "Bisaccia",
      "denominazione_ita": "Bisaccia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A881",
      "lat": "41.0131244",
      "lon": "15.3750147",
      "superficie_kmq": "102.1573",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064012",
      "denominazione_ita_altra": "Bonito",
      "denominazione_ita": "Bonito",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A975",
      "lat": "41.1022566",
      "lon": "15.0028667",
      "superficie_kmq": "18.7774",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064013",
      "denominazione_ita_altra": "Cairano",
      "denominazione_ita": "Cairano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B367",
      "lat": "40.8962871",
      "lon": "15.3701454",
      "superficie_kmq": "13.8104",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064014",
      "denominazione_ita_altra": "Calabritto",
      "denominazione_ita": "Calabritto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B374",
      "lat": "40.7835353",
      "lon": "15.2197477",
      "superficie_kmq": "56.3340",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064015",
      "denominazione_ita_altra": "Calitri",
      "denominazione_ita": "Calitri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B415",
      "lat": "40.9003957",
      "lon": "15.4386549",
      "superficie_kmq": "101.0517",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064016",
      "denominazione_ita_altra": "Candida",
      "denominazione_ita": "Candida",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B590",
      "lat": "40.9412492",
      "lon": "14.8737109",
      "superficie_kmq": "5.3488",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064017",
      "denominazione_ita_altra": "Caposele",
      "denominazione_ita": "Caposele",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B674",
      "lat": "40.8135663",
      "lon": "15.2261405",
      "superficie_kmq": "41.2828",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064018",
      "denominazione_ita_altra": "Capriglia Irpina",
      "denominazione_ita": "Capriglia Irpina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B706",
      "lat": "40.9557436",
      "lon": "14.7754339",
      "superficie_kmq": "7.4879",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064019",
      "denominazione_ita_altra": "Carife",
      "denominazione_ita": "Carife",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B776",
      "lat": "41.0273840",
      "lon": "15.2081239",
      "superficie_kmq": "16.7239",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064020",
      "denominazione_ita_altra": "Casalbore",
      "denominazione_ita": "Casalbore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B866",
      "lat": "41.2341543",
      "lon": "15.0040536",
      "superficie_kmq": "28.0937",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064021",
      "denominazione_ita_altra": "Cassano Irpino",
      "denominazione_ita": "Cassano Irpino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B997",
      "lat": "40.8701291",
      "lon": "15.0261157",
      "superficie_kmq": "13.0746",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064022",
      "denominazione_ita_altra": "Castel Baronia",
      "denominazione_ita": "Castel Baronia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C058",
      "lat": "41.0471423",
      "lon": "15.1883560",
      "superficie_kmq": "15.3663",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064023",
      "denominazione_ita_altra": "Castelfranci",
      "denominazione_ita": "Castelfranci",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C105",
      "lat": "40.9306184",
      "lon": "15.0409831",
      "superficie_kmq": "11.6888",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064024",
      "denominazione_ita_altra": "Castelvetere sul Calore",
      "denominazione_ita": "Castelvetere sul Calore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C283",
      "lat": "40.9298574",
      "lon": "14.9860694",
      "superficie_kmq": "17.1700",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064025",
      "denominazione_ita_altra": "Cervinara",
      "denominazione_ita": "Cervinara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C557",
      "lat": "41.0206779",
      "lon": "14.6172145",
      "superficie_kmq": "29.3430",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064026",
      "denominazione_ita_altra": "Cesinali",
      "denominazione_ita": "Cesinali",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C576",
      "lat": "40.8960685",
      "lon": "14.8297527",
      "superficie_kmq": "3.7261",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064027",
      "denominazione_ita_altra": "Chianche",
      "denominazione_ita": "Chianche",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C606",
      "lat": "41.0436467",
      "lon": "14.7908963",
      "superficie_kmq": "6.6074",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064028",
      "denominazione_ita_altra": "Chiusano di San Domenico",
      "denominazione_ita": "Chiusano di San Domenico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C659",
      "lat": "40.9318551",
      "lon": "14.9167226",
      "superficie_kmq": "24.6024",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064029",
      "denominazione_ita_altra": "Contrada",
      "denominazione_ita": "Contrada",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C971",
      "lat": "40.8671170",
      "lon": "14.7732807",
      "superficie_kmq": "10.3106",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064030",
      "denominazione_ita_altra": "Conza della Campania",
      "denominazione_ita": "Conza della Campania",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C976",
      "lat": "40.8571884",
      "lon": "15.3356976",
      "superficie_kmq": "51.6343",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064031",
      "denominazione_ita_altra": "Domicella",
      "denominazione_ita": "Domicella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D331",
      "lat": "40.8792570",
      "lon": "14.5867670",
      "superficie_kmq": "6.3990",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064032",
      "denominazione_ita_altra": "Flumeri",
      "denominazione_ita": "Flumeri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D638",
      "lat": "41.0733564",
      "lon": "15.1535189",
      "superficie_kmq": "34.5501",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064033",
      "denominazione_ita_altra": "Fontanarosa",
      "denominazione_ita": "Fontanarosa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D671",
      "lat": "41.0203484",
      "lon": "15.0205175",
      "superficie_kmq": "16.7042",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064034",
      "denominazione_ita_altra": "Forino",
      "denominazione_ita": "Forino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D701",
      "lat": "40.8616764",
      "lon": "14.7371648",
      "superficie_kmq": "20.3896",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064035",
      "denominazione_ita_altra": "Frigento",
      "denominazione_ita": "Frigento",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D798",
      "lat": "41.0119295",
      "lon": "15.0976834",
      "superficie_kmq": "38.0394",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064036",
      "denominazione_ita_altra": "Gesualdo",
      "denominazione_ita": "Gesualdo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D998",
      "lat": "41.0065288",
      "lon": "15.0704285",
      "superficie_kmq": "27.3349",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064037",
      "denominazione_ita_altra": "Greci",
      "denominazione_ita": "Greci",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E161",
      "lat": "41.2496046",
      "lon": "15.1697210",
      "superficie_kmq": "30.2633",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064038",
      "denominazione_ita_altra": "Grottaminarda",
      "denominazione_ita": "Grottaminarda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E206",
      "lat": "41.0709174",
      "lon": "15.0600909",
      "superficie_kmq": "29.1148",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064039",
      "denominazione_ita_altra": "Grottolella",
      "denominazione_ita": "Grottolella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E214",
      "lat": "40.9722717",
      "lon": "14.7861551",
      "superficie_kmq": "7.1256",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064040",
      "denominazione_ita_altra": "Guardia Lombardi",
      "denominazione_ita": "Guardia Lombardi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E245",
      "lat": "40.9545978",
      "lon": "15.2076438",
      "superficie_kmq": "55.8729",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064041",
      "denominazione_ita_altra": "Lacedonia",
      "denominazione_ita": "Lacedonia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E397",
      "lat": "41.0493888",
      "lon": "15.4247553",
      "superficie_kmq": "82.0825",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064042",
      "denominazione_ita_altra": "Lapio",
      "denominazione_ita": "Lapio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E448",
      "lat": "40.9844295",
      "lon": "14.9478566",
      "superficie_kmq": "15.2465",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064043",
      "denominazione_ita_altra": "Lauro",
      "denominazione_ita": "Lauro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E487",
      "lat": "40.8782204",
      "lon": "14.6323414",
      "superficie_kmq": "11.2934",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064044",
      "denominazione_ita_altra": "Lioni",
      "denominazione_ita": "Lioni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E605",
      "lat": "40.8774335",
      "lon": "15.1884392",
      "superficie_kmq": "46.5111",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064045",
      "denominazione_ita_altra": "Luogosano",
      "denominazione_ita": "Luogosano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E746",
      "lat": "40.9877092",
      "lon": "14.9903624",
      "superficie_kmq": "6.0681",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064046",
      "denominazione_ita_altra": "Manocalzati",
      "denominazione_ita": "Manocalzati",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E891",
      "lat": "40.9413751",
      "lon": "14.8491270",
      "superficie_kmq": "8.7453",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064047",
      "denominazione_ita_altra": "Marzano di Nola",
      "denominazione_ita": "Marzano di Nola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E997",
      "lat": "40.9001499",
      "lon": "14.5817815",
      "superficie_kmq": "4.7196",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064048",
      "denominazione_ita_altra": "Melito Irpino",
      "denominazione_ita": "Melito Irpino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F110",
      "lat": "41.1028430",
      "lon": "15.0538801",
      "superficie_kmq": "20.6840",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064049",
      "denominazione_ita_altra": "Mercogliano",
      "denominazione_ita": "Mercogliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F141",
      "lat": "40.9200562",
      "lon": "14.7361268",
      "superficie_kmq": "19.9169",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064050",
      "denominazione_ita_altra": "Mirabella Eclano",
      "denominazione_ita": "Mirabella Eclano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F230",
      "lat": "41.0413229",
      "lon": "14.9921712",
      "superficie_kmq": "33.9585",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064051",
      "denominazione_ita_altra": "Montaguto",
      "denominazione_ita": "Montaguto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F397",
      "lat": "41.2489994",
      "lon": "15.2501512",
      "superficie_kmq": "18.3786",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064052",
      "denominazione_ita_altra": "Montecalvo Irpino",
      "denominazione_ita": "Montecalvo Irpino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F448",
      "lat": "41.1941405",
      "lon": "15.0316142",
      "superficie_kmq": "54.0056",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064053",
      "denominazione_ita_altra": "Montefalcione",
      "denominazione_ita": "Montefalcione",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F491",
      "lat": "40.9640091",
      "lon": "14.8845015",
      "superficie_kmq": "15.2940",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064054",
      "denominazione_ita_altra": "Monteforte Irpino",
      "denominazione_ita": "Monteforte Irpino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F506",
      "lat": "40.8899536",
      "lon": "14.7138706",
      "superficie_kmq": "26.9575",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064055",
      "denominazione_ita_altra": "Montefredane",
      "denominazione_ita": "Montefredane",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F511",
      "lat": "40.9602750",
      "lon": "14.8129802",
      "superficie_kmq": "9.4461",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064056",
      "denominazione_ita_altra": "Montefusco",
      "denominazione_ita": "Montefusco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F512",
      "lat": "41.0376343",
      "lon": "14.8552078",
      "superficie_kmq": "8.2382",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064057",
      "denominazione_ita_altra": "Montella",
      "denominazione_ita": "Montella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F546",
      "lat": "40.8422263",
      "lon": "15.0166522",
      "superficie_kmq": "82.9536",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064058",
      "denominazione_ita_altra": "Montemarano",
      "denominazione_ita": "Montemarano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F559",
      "lat": "40.9197644",
      "lon": "14.9969159",
      "superficie_kmq": "34.0089",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064059",
      "denominazione_ita_altra": "Montemiletto",
      "denominazione_ita": "Montemiletto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F566",
      "lat": "41.0114092",
      "lon": "14.9071890",
      "superficie_kmq": "21.6348",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064060",
      "denominazione_ita_altra": "Monteverde",
      "denominazione_ita": "Monteverde",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F660",
      "lat": "40.9997546",
      "lon": "15.5327332",
      "superficie_kmq": "39.5660",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064063",
      "denominazione_ita_altra": "Morra De Sanctis",
      "denominazione_ita": "Morra De Sanctis",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F744",
      "lat": "40.9230615",
      "lon": "15.2439610",
      "superficie_kmq": "30.4120",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064064",
      "denominazione_ita_altra": "Moschiano",
      "denominazione_ita": "Moschiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F762",
      "lat": "40.8746090",
      "lon": "14.6594787",
      "superficie_kmq": "13.4535",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064065",
      "denominazione_ita_altra": "Mugnano del Cardinale",
      "denominazione_ita": "Mugnano del Cardinale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F798",
      "lat": "40.9395673",
      "lon": "14.6425566",
      "superficie_kmq": "12.2983",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064066",
      "denominazione_ita_altra": "Nusco",
      "denominazione_ita": "Nusco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F988",
      "lat": "40.8860807",
      "lon": "15.0844667",
      "superficie_kmq": "53.6004",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064067",
      "denominazione_ita_altra": "Ospedaletto d'Alpinolo",
      "denominazione_ita": "Ospedaletto d'Alpinolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G165",
      "lat": "40.9391334",
      "lon": "14.7455412",
      "superficie_kmq": "5.6764",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064068",
      "denominazione_ita_altra": "Pago del Vallo di Lauro",
      "denominazione_ita": "Pago del Vallo di Lauro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G242",
      "lat": "40.8967238",
      "lon": "14.6051817",
      "superficie_kmq": "4.6255",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064069",
      "denominazione_ita_altra": "Parolise",
      "denominazione_ita": "Parolise",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G340",
      "lat": "40.9308331",
      "lon": "14.8824112",
      "superficie_kmq": "3.2187",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064070",
      "denominazione_ita_altra": "Paternopoli",
      "denominazione_ita": "Paternopoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G370",
      "lat": "40.9741107",
      "lon": "15.0349249",
      "superficie_kmq": "18.4254",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064071",
      "denominazione_ita_altra": "Petruro Irpino",
      "denominazione_ita": "Petruro Irpino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G519",
      "lat": "41.0315305",
      "lon": "14.7972854",
      "superficie_kmq": "3.1398",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064072",
      "denominazione_ita_altra": "Pietradefusi",
      "denominazione_ita": "Pietradefusi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G611",
      "lat": "41.0373503",
      "lon": "14.8893062",
      "superficie_kmq": "9.2415",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064073",
      "denominazione_ita_altra": "Pietrastornina",
      "denominazione_ita": "Pietrastornina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G629",
      "lat": "40.9919800",
      "lon": "14.7295608",
      "superficie_kmq": "15.7281",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064074",
      "denominazione_ita_altra": "Prata di Principato Ultra",
      "denominazione_ita": "Prata di Principato Ultra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G990",
      "lat": "40.9864374",
      "lon": "14.8370766",
      "superficie_kmq": "10.9911",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064075",
      "denominazione_ita_altra": "Pratola Serra",
      "denominazione_ita": "Pratola Serra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H006",
      "lat": "40.9853379",
      "lon": "14.8523189",
      "superficie_kmq": "8.8428",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064076",
      "denominazione_ita_altra": "Quadrelle",
      "denominazione_ita": "Quadrelle",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H097",
      "lat": "40.9470427",
      "lon": "14.6381847",
      "superficie_kmq": "6.9311",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064077",
      "denominazione_ita_altra": "Quindici",
      "denominazione_ita": "Quindici",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H128",
      "lat": "40.8624460",
      "lon": "14.6482347",
      "superficie_kmq": "23.9082",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064078",
      "denominazione_ita_altra": "Roccabascerana",
      "denominazione_ita": "Roccabascerana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H382",
      "lat": "41.0180425",
      "lon": "14.7164558",
      "superficie_kmq": "12.4554",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064079",
      "denominazione_ita_altra": "Rocca San Felice",
      "denominazione_ita": "Rocca San Felice",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H438",
      "lat": "40.9509568",
      "lon": "15.1651182",
      "superficie_kmq": "14.4116",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064080",
      "denominazione_ita_altra": "Rotondi",
      "denominazione_ita": "Rotondi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H592",
      "lat": "41.0339702",
      "lon": "14.5963958",
      "superficie_kmq": "7.8084",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064081",
      "denominazione_ita_altra": "Salza Irpina",
      "denominazione_ita": "Salza Irpina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H733",
      "lat": "40.9179734",
      "lon": "14.8910929",
      "superficie_kmq": "4.9594",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064082",
      "denominazione_ita_altra": "San Mango sul Calore",
      "denominazione_ita": "San Mango sul Calore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H975",
      "lat": "40.9588408",
      "lon": "14.9725078",
      "superficie_kmq": "14.5865",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064083",
      "denominazione_ita_altra": "San Martino Valle Caudina",
      "denominazione_ita": "San Martino Valle Caudina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I016",
      "lat": "41.0254060",
      "lon": "14.6648549",
      "superficie_kmq": "22.9153",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064084",
      "denominazione_ita_altra": "San Michele di Serino",
      "denominazione_ita": "San Michele di Serino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I034",
      "lat": "40.8771117",
      "lon": "14.8554986",
      "superficie_kmq": "4.4659",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064085",
      "denominazione_ita_altra": "San Nicola Baronia",
      "denominazione_ita": "San Nicola Baronia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I061",
      "lat": "41.0593967",
      "lon": "15.1981260",
      "superficie_kmq": "6.8989",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064086",
      "denominazione_ita_altra": "San Potito Ultra",
      "denominazione_ita": "San Potito Ultra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I129",
      "lat": "40.9278991",
      "lon": "14.8712310",
      "superficie_kmq": "4.5378",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064087",
      "denominazione_ita_altra": "San Sossio Baronia",
      "denominazione_ita": "San Sossio Baronia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I163",
      "lat": "41.0668836",
      "lon": "15.2032873",
      "superficie_kmq": "19.1929",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064088",
      "denominazione_ita_altra": "Santa Lucia di Serino",
      "denominazione_ita": "Santa Lucia di Serino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I219",
      "lat": "40.8732183",
      "lon": "14.8720083",
      "superficie_kmq": "3.9333",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064089",
      "denominazione_ita_altra": "Sant'Andrea di Conza",
      "denominazione_ita": "Sant'Andrea di Conza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I264",
      "lat": "40.8440682",
      "lon": "15.3717320",
      "superficie_kmq": "7.0432",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064090",
      "denominazione_ita_altra": "Sant'Angelo all'Esca",
      "denominazione_ita": "Sant'Angelo all'Esca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I279",
      "lat": "41.0064656",
      "lon": "14.9931592",
      "superficie_kmq": "5.4602",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064091",
      "denominazione_ita_altra": "Sant'Angelo a Scala",
      "denominazione_ita": "Sant'Angelo a Scala",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I280",
      "lat": "40.9758647",
      "lon": "14.7396905",
      "superficie_kmq": "10.7449",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064092",
      "denominazione_ita_altra": "Sant'Angelo dei Lombardi",
      "denominazione_ita": "Sant'Angelo dei Lombardi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I281",
      "lat": "40.9273475",
      "lon": "15.1764967",
      "superficie_kmq": "55.1046",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064093",
      "denominazione_ita_altra": "Santa Paolina",
      "denominazione_ita": "Santa Paolina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I301",
      "lat": "41.0219610",
      "lon": "14.8462950",
      "superficie_kmq": "8.4343",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064095",
      "denominazione_ita_altra": "Santo Stefano del Sole",
      "denominazione_ita": "Santo Stefano del Sole",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I357",
      "lat": "40.8940815",
      "lon": "14.8667325",
      "superficie_kmq": "10.7802",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064096",
      "denominazione_ita_altra": "Savignano Irpino",
      "denominazione_ita": "Savignano Irpino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I471",
      "lat": "41.2281882",
      "lon": "15.1774368",
      "superficie_kmq": "38.4722",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064097",
      "denominazione_ita_altra": "Scampitella",
      "denominazione_ita": "Scampitella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I493",
      "lat": "41.0921820",
      "lon": "15.2964411",
      "superficie_kmq": "15.1053",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064098",
      "denominazione_ita_altra": "Senerchia",
      "denominazione_ita": "Senerchia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I606",
      "lat": "40.7412716",
      "lon": "15.2050099",
      "superficie_kmq": "32.0250",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064099",
      "denominazione_ita_altra": "Serino",
      "denominazione_ita": "Serino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I630",
      "lat": "40.8551300",
      "lon": "14.8723022",
      "superficie_kmq": "52.4994",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064100",
      "denominazione_ita_altra": "Sirignano",
      "denominazione_ita": "Sirignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I756",
      "lat": "40.9476264",
      "lon": "14.6288195",
      "superficie_kmq": "6.1867",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064101",
      "denominazione_ita_altra": "Solofra",
      "denominazione_ita": "Solofra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I805",
      "lat": "40.8305002",
      "lon": "14.8465009",
      "superficie_kmq": "22.2135",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064102",
      "denominazione_ita_altra": "Sorbo Serpico",
      "denominazione_ita": "Sorbo Serpico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I843",
      "lat": "40.9167919",
      "lon": "14.8870897",
      "superficie_kmq": "8.1034",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064103",
      "denominazione_ita_altra": "Sperone",
      "denominazione_ita": "Sperone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I893",
      "lat": "40.9518568",
      "lon": "14.6028188",
      "superficie_kmq": "4.6968",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064104",
      "denominazione_ita_altra": "Sturno",
      "denominazione_ita": "Sturno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I990",
      "lat": "41.0221143",
      "lon": "15.1124746",
      "superficie_kmq": "16.6733",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064105",
      "denominazione_ita_altra": "Summonte",
      "denominazione_ita": "Summonte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L004",
      "lat": "40.9500289",
      "lon": "14.7469700",
      "superficie_kmq": "12.3678",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064106",
      "denominazione_ita_altra": "Taurano",
      "denominazione_ita": "Taurano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L061",
      "lat": "40.8839645",
      "lon": "14.6361076",
      "superficie_kmq": "9.7662",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064107",
      "denominazione_ita_altra": "Taurasi",
      "denominazione_ita": "Taurasi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L062",
      "lat": "41.0067473",
      "lon": "14.9578836",
      "superficie_kmq": "14.4115",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064108",
      "denominazione_ita_altra": "Teora",
      "denominazione_ita": "Teora",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L102",
      "lat": "40.8510312",
      "lon": "15.2527802",
      "superficie_kmq": "23.2061",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064109",
      "denominazione_ita_altra": "Torella dei Lombardi",
      "denominazione_ita": "Torella dei Lombardi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L214",
      "lat": "40.9421266",
      "lon": "15.1145453",
      "superficie_kmq": "26.5685",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064110",
      "denominazione_ita_altra": "Torre Le Nocelle",
      "denominazione_ita": "Torre Le Nocelle",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L272",
      "lat": "41.0206987",
      "lon": "14.9126275",
      "superficie_kmq": "10.0416",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064111",
      "denominazione_ita_altra": "Torrioni",
      "denominazione_ita": "Torrioni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L301",
      "lat": "41.0340432",
      "lon": "14.8135078",
      "superficie_kmq": "4.2199",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064112",
      "denominazione_ita_altra": "Trevico",
      "denominazione_ita": "Trevico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L399",
      "lat": "41.0480237",
      "lon": "15.2329126",
      "superficie_kmq": "11.0025",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064113",
      "denominazione_ita_altra": "Tufo",
      "denominazione_ita": "Tufo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L461",
      "lat": "41.0098953",
      "lon": "14.8242491",
      "superficie_kmq": "5.9641",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064114",
      "denominazione_ita_altra": "Vallata",
      "denominazione_ita": "Vallata",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L589",
      "lat": "41.0368682",
      "lon": "15.2513508",
      "superficie_kmq": "47.9136",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064115",
      "denominazione_ita_altra": "Vallesaccarda",
      "denominazione_ita": "Vallesaccarda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L616",
      "lat": "41.0643538",
      "lon": "15.2519640",
      "superficie_kmq": "14.1237",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064116",
      "denominazione_ita_altra": "Venticano",
      "denominazione_ita": "Venticano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L739",
      "lat": "41.0460980",
      "lon": "14.9175171",
      "superficie_kmq": "14.1566",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064117",
      "denominazione_ita_altra": "Villamaina",
      "denominazione_ita": "Villamaina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L965",
      "lat": "40.9711609",
      "lon": "15.0881741",
      "superficie_kmq": "9.0366",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064118",
      "denominazione_ita_altra": "Villanova del Battista",
      "denominazione_ita": "Villanova del Battista",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L973",
      "lat": "41.1223952",
      "lon": "15.1584677",
      "superficie_kmq": "19.9992",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064119",
      "denominazione_ita_altra": "Volturara Irpina",
      "denominazione_ita": "Volturara Irpina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M130",
      "lat": "40.8787613",
      "lon": "14.9171644",
      "superficie_kmq": "32.4183",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064120",
      "denominazione_ita_altra": "Zungoli",
      "denominazione_ita": "Zungoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M203",
      "lat": "41.1276047",
      "lon": "15.2025503",
      "superficie_kmq": "19.2142",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "AV",
      "codice_istat": "064121",
      "denominazione_ita_altra": "Montoro",
      "denominazione_ita": "Montoro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M330",
      "lat": "40.8243290",
      "lon": "14.7864696",
      "superficie_kmq": "40.1409",
      "codice_sovracomunale": "064"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065001",
      "denominazione_ita_altra": "Acerno",
      "denominazione_ita": "Acerno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A023",
      "lat": "40.7382418",
      "lon": "15.0594336",
      "superficie_kmq": "72.4968",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065002",
      "denominazione_ita_altra": "Agropoli",
      "denominazione_ita": "Agropoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A091",
      "lat": "40.3495610",
      "lon": "14.9915832",
      "superficie_kmq": "32.7665",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065003",
      "denominazione_ita_altra": "Albanella",
      "denominazione_ita": "Albanella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A128",
      "lat": "40.4797121",
      "lon": "15.1151469",
      "superficie_kmq": "40.2302",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065004",
      "denominazione_ita_altra": "Alfano",
      "denominazione_ita": "Alfano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A186",
      "lat": "40.1775979",
      "lon": "15.4259076",
      "superficie_kmq": "4.8222",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065005",
      "denominazione_ita_altra": "Altavilla Silentina",
      "denominazione_ita": "Altavilla Silentina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A230",
      "lat": "40.5271097",
      "lon": "15.1375859",
      "superficie_kmq": "52.4817",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065006",
      "denominazione_ita_altra": "Amalfi",
      "denominazione_ita": "Amalfi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A251",
      "lat": "40.6341771",
      "lon": "14.6052049",
      "superficie_kmq": "5.7001",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065007",
      "denominazione_ita_altra": "Angri",
      "denominazione_ita": "Angri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A294",
      "lat": "40.7431563",
      "lon": "14.5684361",
      "superficie_kmq": "13.7655",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065008",
      "denominazione_ita_altra": "Aquara",
      "denominazione_ita": "Aquara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A343",
      "lat": "40.4427427",
      "lon": "15.2532038",
      "superficie_kmq": "32.7339",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065009",
      "denominazione_ita_altra": "Ascea",
      "denominazione_ita": "Ascea",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A460",
      "lat": "40.1433058",
      "lon": "15.1840090",
      "superficie_kmq": "37.4528",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065010",
      "denominazione_ita_altra": "Atena Lucana",
      "denominazione_ita": "Atena Lucana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A484",
      "lat": "40.4545738",
      "lon": "15.5581528",
      "superficie_kmq": "26.0072",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065011",
      "denominazione_ita_altra": "Atrani",
      "denominazione_ita": "Atrani",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A487",
      "lat": "40.6364545",
      "lon": "14.6084206",
      "superficie_kmq": "0.1206",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065012",
      "denominazione_ita_altra": "Auletta",
      "denominazione_ita": "Auletta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A495",
      "lat": "40.5580785",
      "lon": "15.4241012",
      "superficie_kmq": "35.6828",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065013",
      "denominazione_ita_altra": "Baronissi",
      "denominazione_ita": "Baronissi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A674",
      "lat": "40.7463693",
      "lon": "14.7702092",
      "superficie_kmq": "17.9298",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065014",
      "denominazione_ita_altra": "Battipaglia",
      "denominazione_ita": "Battipaglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A717",
      "lat": "40.6076141",
      "lon": "14.9834355",
      "superficie_kmq": "56.8494",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065015",
      "denominazione_ita_altra": "Bellosguardo",
      "denominazione_ita": "Bellosguardo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A756",
      "lat": "40.4217515",
      "lon": "15.3102087",
      "superficie_kmq": "16.7467",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065016",
      "denominazione_ita_altra": "Bracigliano",
      "denominazione_ita": "Bracigliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B115",
      "lat": "40.8246780",
      "lon": "14.7110431",
      "superficie_kmq": "14.4087",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065017",
      "denominazione_ita_altra": "Buccino",
      "denominazione_ita": "Buccino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B242",
      "lat": "40.6309971",
      "lon": "15.3673167",
      "superficie_kmq": "65.9179",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065018",
      "denominazione_ita_altra": "Buonabitacolo",
      "denominazione_ita": "Buonabitacolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B266",
      "lat": "40.2687424",
      "lon": "15.6193565",
      "superficie_kmq": "15.5398",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065019",
      "denominazione_ita_altra": "Caggiano",
      "denominazione_ita": "Caggiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B351",
      "lat": "40.5670952",
      "lon": "15.4964760",
      "superficie_kmq": "35.4297",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065020",
      "denominazione_ita_altra": "Calvanico",
      "denominazione_ita": "Calvanico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B437",
      "lat": "40.7761275",
      "lon": "14.8293831",
      "superficie_kmq": "14.9092",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065021",
      "denominazione_ita_altra": "Camerota",
      "denominazione_ita": "Camerota",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B476",
      "lat": "40.0331201",
      "lon": "15.3701513",
      "superficie_kmq": "70.5768",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065022",
      "denominazione_ita_altra": "Campagna",
      "denominazione_ita": "Campagna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B492",
      "lat": "40.6655429",
      "lon": "15.1070177",
      "superficie_kmq": "136.3035",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065023",
      "denominazione_ita_altra": "Campora",
      "denominazione_ita": "Campora",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B555",
      "lat": "40.3051870",
      "lon": "15.2927405",
      "superficie_kmq": "29.1530",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065024",
      "denominazione_ita_altra": "Cannalonga",
      "denominazione_ita": "Cannalonga",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B608",
      "lat": "40.2435165",
      "lon": "15.2925990",
      "superficie_kmq": "17.7503",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065025",
      "denominazione_ita_altra": "Capaccio Paestum",
      "denominazione_ita": "Capaccio Paestum",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B644",
      "lat": "40.4229669",
      "lon": "15.0807443",
      "superficie_kmq": "113.0263",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065026",
      "denominazione_ita_altra": "Casalbuono",
      "denominazione_ita": "Casalbuono",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B868",
      "lat": "40.2143878",
      "lon": "15.6829609",
      "superficie_kmq": "34.8232",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065027",
      "denominazione_ita_altra": "Casaletto Spartano",
      "denominazione_ita": "Casaletto Spartano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B888",
      "lat": "40.1515859",
      "lon": "15.6185565",
      "superficie_kmq": "86.5703",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065028",
      "denominazione_ita_altra": "Casal Velino",
      "denominazione_ita": "Casal Velino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B895",
      "lat": "40.1888814",
      "lon": "15.1095775",
      "superficie_kmq": "31.7096",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065029",
      "denominazione_ita_altra": "Caselle in Pittari",
      "denominazione_ita": "Caselle in Pittari",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B959",
      "lat": "40.1723689",
      "lon": "15.5439124",
      "superficie_kmq": "45.5545",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065030",
      "denominazione_ita_altra": "Castelcivita",
      "denominazione_ita": "Castelcivita",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C069",
      "lat": "40.4929064",
      "lon": "15.2329900",
      "superficie_kmq": "57.6340",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065031",
      "denominazione_ita_altra": "Castellabate",
      "denominazione_ita": "Castellabate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C125",
      "lat": "40.2863702",
      "lon": "14.9491910",
      "superficie_kmq": "37.4332",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065032",
      "denominazione_ita_altra": "Castelnuovo Cilento",
      "denominazione_ita": "Castelnuovo Cilento",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C231",
      "lat": "40.2183923",
      "lon": "15.1780166",
      "superficie_kmq": "18.0593",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065033",
      "denominazione_ita_altra": "Castelnuovo di Conza",
      "denominazione_ita": "Castelnuovo di Conza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C235",
      "lat": "40.8181018",
      "lon": "15.3212557",
      "superficie_kmq": "14.0638",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065034",
      "denominazione_ita_altra": "Castel San Giorgio",
      "denominazione_ita": "Castel San Giorgio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C259",
      "lat": "40.7800940",
      "lon": "14.6977612",
      "superficie_kmq": "13.5851",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065035",
      "denominazione_ita_altra": "Castel San Lorenzo",
      "denominazione_ita": "Castel San Lorenzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C262",
      "lat": "40.4167683",
      "lon": "15.2277633",
      "superficie_kmq": "14.2937",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065036",
      "denominazione_ita_altra": "Castiglione del Genovesi",
      "denominazione_ita": "Castiglione del Genovesi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C306",
      "lat": "40.7249842",
      "lon": "14.8482209",
      "superficie_kmq": "10.4074",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065037",
      "denominazione_ita_altra": "Cava de' Tirreni",
      "denominazione_ita": "Cava de' Tirreni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C361",
      "lat": "40.7007439",
      "lon": "14.7059673",
      "superficie_kmq": "36.5311",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065038",
      "denominazione_ita_altra": "Celle di Bulgheria",
      "denominazione_ita": "Celle di Bulgheria",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C444",
      "lat": "40.0948471",
      "lon": "15.4057290",
      "superficie_kmq": "31.6166",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065039",
      "denominazione_ita_altra": "Centola",
      "denominazione_ita": "Centola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C470",
      "lat": "40.0657423",
      "lon": "15.3098463",
      "superficie_kmq": "47.7508",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065040",
      "denominazione_ita_altra": "Ceraso",
      "denominazione_ita": "Ceraso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C485",
      "lat": "40.1928887",
      "lon": "15.2585429",
      "superficie_kmq": "46.4579",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065041",
      "denominazione_ita_altra": "Cetara",
      "denominazione_ita": "Cetara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C584",
      "lat": "40.6466944",
      "lon": "14.7009101",
      "superficie_kmq": "4.9681",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065042",
      "denominazione_ita_altra": "Cicerale",
      "denominazione_ita": "Cicerale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C676",
      "lat": "40.3436122",
      "lon": "15.1267119",
      "superficie_kmq": "41.3734",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065043",
      "denominazione_ita_altra": "Colliano",
      "denominazione_ita": "Colliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C879",
      "lat": "40.7251290",
      "lon": "15.2924249",
      "superficie_kmq": "55.1580",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065044",
      "denominazione_ita_altra": "Conca dei Marini",
      "denominazione_ita": "Conca dei Marini",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C940",
      "lat": "40.6178160",
      "lon": "14.5742437",
      "superficie_kmq": "1.1281",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065045",
      "denominazione_ita_altra": "Controne",
      "denominazione_ita": "Controne",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C973",
      "lat": "40.5096728",
      "lon": "15.2038158",
      "superficie_kmq": "7.7461",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065046",
      "denominazione_ita_altra": "Contursi Terme",
      "denominazione_ita": "Contursi Terme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C974",
      "lat": "40.6490866",
      "lon": "15.2410821",
      "superficie_kmq": "28.9342",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065047",
      "denominazione_ita_altra": "Corbara",
      "denominazione_ita": "Corbara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C984",
      "lat": "40.7227211",
      "lon": "14.5925212",
      "superficie_kmq": "6.7318",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065048",
      "denominazione_ita_altra": "Corleto Monforte",
      "denominazione_ita": "Corleto Monforte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D011",
      "lat": "40.4375278",
      "lon": "15.3808257",
      "superficie_kmq": "58.9663",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065049",
      "denominazione_ita_altra": "Cuccaro Vetere",
      "denominazione_ita": "Cuccaro Vetere",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D195",
      "lat": "40.1639727",
      "lon": "15.3067692",
      "superficie_kmq": "17.6631",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065050",
      "denominazione_ita_altra": "Eboli",
      "denominazione_ita": "Eboli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D390",
      "lat": "40.6181238",
      "lon": "15.0544483",
      "superficie_kmq": "137.5727",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065051",
      "denominazione_ita_altra": "Felitto",
      "denominazione_ita": "Felitto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D527",
      "lat": "40.3706311",
      "lon": "15.2479585",
      "superficie_kmq": "41.5341",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065052",
      "denominazione_ita_altra": "Fisciano",
      "denominazione_ita": "Fisciano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D615",
      "lat": "40.7714092",
      "lon": "14.7991410",
      "superficie_kmq": "31.6874",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065053",
      "denominazione_ita_altra": "Furore",
      "denominazione_ita": "Furore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D826",
      "lat": "40.6201675",
      "lon": "14.5501763",
      "superficie_kmq": "1.8817",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065054",
      "denominazione_ita_altra": "Futani",
      "denominazione_ita": "Futani",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D832",
      "lat": "40.1514895",
      "lon": "15.3236503",
      "superficie_kmq": "14.8506",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065055",
      "denominazione_ita_altra": "Giffoni Sei Casali",
      "denominazione_ita": "Giffoni Sei Casali",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E026",
      "lat": "40.7187889",
      "lon": "14.9036571",
      "superficie_kmq": "35.0750",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065056",
      "denominazione_ita_altra": "Giffoni Valle Piana",
      "denominazione_ita": "Giffoni Valle Piana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E027",
      "lat": "40.7174374",
      "lon": "14.9433015",
      "superficie_kmq": "88.6125",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065057",
      "denominazione_ita_altra": "Gioi",
      "denominazione_ita": "Gioi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E037",
      "lat": "40.2898192",
      "lon": "15.2174994",
      "superficie_kmq": "27.9883",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065058",
      "denominazione_ita_altra": "Giungano",
      "denominazione_ita": "Giungano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E060",
      "lat": "40.3944303",
      "lon": "15.1079107",
      "superficie_kmq": "11.7006",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065059",
      "denominazione_ita_altra": "Ispani",
      "denominazione_ita": "Ispani",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E365",
      "lat": "40.0857147",
      "lon": "15.5617417",
      "superficie_kmq": "8.3430",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065060",
      "denominazione_ita_altra": "Laureana Cilento",
      "denominazione_ita": "Laureana Cilento",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E480",
      "lat": "40.3008289",
      "lon": "15.0389081",
      "superficie_kmq": "13.7442",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065061",
      "denominazione_ita_altra": "Laurino",
      "denominazione_ita": "Laurino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E485",
      "lat": "40.3366691",
      "lon": "15.3366445",
      "superficie_kmq": "70.4538",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065062",
      "denominazione_ita_altra": "Laurito",
      "denominazione_ita": "Laurito",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E486",
      "lat": "40.1684565",
      "lon": "15.4082678",
      "superficie_kmq": "20.2220",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065063",
      "denominazione_ita_altra": "Laviano",
      "denominazione_ita": "Laviano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E498",
      "lat": "40.7845502",
      "lon": "15.3050664",
      "superficie_kmq": "55.6766",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065064",
      "denominazione_ita_altra": "Lustra",
      "denominazione_ita": "Lustra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E767",
      "lat": "40.2883346",
      "lon": "15.0683136",
      "superficie_kmq": "15.2415",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065065",
      "denominazione_ita_altra": "Magliano Vetere",
      "denominazione_ita": "Magliano Vetere",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E814",
      "lat": "40.3469549",
      "lon": "15.2363463",
      "superficie_kmq": "23.2957",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065066",
      "denominazione_ita_altra": "Maiori",
      "denominazione_ita": "Maiori",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E839",
      "lat": "40.6508022",
      "lon": "14.6422284",
      "superficie_kmq": "16.6714",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065067",
      "denominazione_ita_altra": "Mercato San Severino",
      "denominazione_ita": "Mercato San Severino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F138",
      "lat": "40.7862338",
      "lon": "14.7535721",
      "superficie_kmq": "30.3331",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065068",
      "denominazione_ita_altra": "Minori",
      "denominazione_ita": "Minori",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F223",
      "lat": "40.6496060",
      "lon": "14.6261469",
      "superficie_kmq": "2.6624",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065069",
      "denominazione_ita_altra": "Moio della Civitella",
      "denominazione_ita": "Moio della Civitella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F278",
      "lat": "40.2449773",
      "lon": "15.2703939",
      "superficie_kmq": "17.1887",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065070",
      "denominazione_ita_altra": "Montano Antilia",
      "denominazione_ita": "Montano Antilia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F426",
      "lat": "40.1623154",
      "lon": "15.3615031",
      "superficie_kmq": "33.4423",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065071",
      "denominazione_ita_altra": "Montecorice",
      "denominazione_ita": "Montecorice",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F479",
      "lat": "40.2343758",
      "lon": "14.9850369",
      "superficie_kmq": "22.2510",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065072",
      "denominazione_ita_altra": "Montecorvino Pugliano",
      "denominazione_ita": "Montecorvino Pugliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F480",
      "lat": "40.6760898",
      "lon": "14.9302497",
      "superficie_kmq": "28.8816",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065073",
      "denominazione_ita_altra": "Montecorvino Rovella",
      "denominazione_ita": "Montecorvino Rovella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F481",
      "lat": "40.6957334",
      "lon": "14.9769366",
      "superficie_kmq": "42.1614",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065074",
      "denominazione_ita_altra": "Monteforte Cilento",
      "denominazione_ita": "Monteforte Cilento",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F507",
      "lat": "40.3646196",
      "lon": "15.1946071",
      "superficie_kmq": "22.1659",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065075",
      "denominazione_ita_altra": "Monte San Giacomo",
      "denominazione_ita": "Monte San Giacomo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F618",
      "lat": "40.3413252",
      "lon": "15.5436676",
      "superficie_kmq": "51.6911",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065076",
      "denominazione_ita_altra": "Montesano sulla Marcellana",
      "denominazione_ita": "Montesano sulla Marcellana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F625",
      "lat": "40.2755112",
      "lon": "15.7011784",
      "superficie_kmq": "110.2217",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065077",
      "denominazione_ita_altra": "Morigerati",
      "denominazione_ita": "Morigerati",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F731",
      "lat": "40.1404880",
      "lon": "15.5550739",
      "superficie_kmq": "21.1938",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065078",
      "denominazione_ita_altra": "Nocera Inferiore",
      "denominazione_ita": "Nocera Inferiore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F912",
      "lat": "40.7435217",
      "lon": "14.6414178",
      "superficie_kmq": "20.9447",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065079",
      "denominazione_ita_altra": "Nocera Superiore",
      "denominazione_ita": "Nocera Superiore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F913",
      "lat": "40.7415084",
      "lon": "14.6711736",
      "superficie_kmq": "14.6625",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065080",
      "denominazione_ita_altra": "Novi Velia",
      "denominazione_ita": "Novi Velia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F967",
      "lat": "40.2245028",
      "lon": "15.2870996",
      "superficie_kmq": "34.7132",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065081",
      "denominazione_ita_altra": "Ogliastro Cilento",
      "denominazione_ita": "Ogliastro Cilento",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G011",
      "lat": "40.3510615",
      "lon": "15.0463739",
      "superficie_kmq": "13.2426",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065082",
      "denominazione_ita_altra": "Olevano sul Tusciano",
      "denominazione_ita": "Olevano sul Tusciano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G023",
      "lat": "40.6572955",
      "lon": "15.0219861",
      "superficie_kmq": "26.7239",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065083",
      "denominazione_ita_altra": "Oliveto Citra",
      "denominazione_ita": "Oliveto Citra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G039",
      "lat": "40.6915633",
      "lon": "15.2361161",
      "superficie_kmq": "31.6214",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065084",
      "denominazione_ita_altra": "Omignano",
      "denominazione_ita": "Omignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G063",
      "lat": "40.2492535",
      "lon": "15.0840593",
      "superficie_kmq": "10.1008",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065085",
      "denominazione_ita_altra": "Orria",
      "denominazione_ita": "Orria",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G121",
      "lat": "40.2993685",
      "lon": "15.1714032",
      "superficie_kmq": "26.5503",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065086",
      "denominazione_ita_altra": "Ottati",
      "denominazione_ita": "Ottati",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G192",
      "lat": "40.4632566",
      "lon": "15.3153294",
      "superficie_kmq": "53.6115",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065087",
      "denominazione_ita_altra": "Padula",
      "denominazione_ita": "Padula",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G226",
      "lat": "40.3409853",
      "lon": "15.6595138",
      "superficie_kmq": "67.1141",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065088",
      "denominazione_ita_altra": "Pagani",
      "denominazione_ita": "Pagani",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G230",
      "lat": "40.7410120",
      "lon": "14.6194352",
      "superficie_kmq": "11.9763",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065089",
      "denominazione_ita_altra": "Palomonte",
      "denominazione_ita": "Palomonte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G292",
      "lat": "40.6600931",
      "lon": "15.2917337",
      "superficie_kmq": "28.2980",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065090",
      "denominazione_ita_altra": "Pellezzano",
      "denominazione_ita": "Pellezzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G426",
      "lat": "40.7258113",
      "lon": "14.7578243",
      "superficie_kmq": "14.0374",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065091",
      "denominazione_ita_altra": "Perdifumo",
      "denominazione_ita": "Perdifumo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G447",
      "lat": "40.2668739",
      "lon": "15.0165135",
      "superficie_kmq": "23.8138",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065092",
      "denominazione_ita_altra": "Perito",
      "denominazione_ita": "Perito",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G455",
      "lat": "40.2984713",
      "lon": "15.1474495",
      "superficie_kmq": "23.9994",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065093",
      "denominazione_ita_altra": "Pertosa",
      "denominazione_ita": "Pertosa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G476",
      "lat": "40.5431423",
      "lon": "15.4485212",
      "superficie_kmq": "6.1585",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065094",
      "denominazione_ita_altra": "Petina",
      "denominazione_ita": "Petina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G509",
      "lat": "40.5313403",
      "lon": "15.3701778",
      "superficie_kmq": "35.4697",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065095",
      "denominazione_ita_altra": "Piaggine",
      "denominazione_ita": "Piaggine",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G538",
      "lat": "40.3431106",
      "lon": "15.3772381",
      "superficie_kmq": "62.7654",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065096",
      "denominazione_ita_altra": "Pisciotta",
      "denominazione_ita": "Pisciotta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G707",
      "lat": "40.1076650",
      "lon": "15.2337028",
      "superficie_kmq": "31.2380",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065097",
      "denominazione_ita_altra": "Polla",
      "denominazione_ita": "Polla",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G793",
      "lat": "40.5141351",
      "lon": "15.4932509",
      "superficie_kmq": "48.0811",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065098",
      "denominazione_ita_altra": "Pollica",
      "denominazione_ita": "Pollica",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G796",
      "lat": "40.1906365",
      "lon": "15.0573351",
      "superficie_kmq": "28.1653",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065099",
      "denominazione_ita_altra": "Pontecagnano Faiano",
      "denominazione_ita": "Pontecagnano Faiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G834",
      "lat": "40.6429058",
      "lon": "14.8733983",
      "superficie_kmq": "37.1891",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065100",
      "denominazione_ita_altra": "Positano",
      "denominazione_ita": "Positano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G932",
      "lat": "40.6288239",
      "lon": "14.4836122",
      "superficie_kmq": "8.6531",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065101",
      "denominazione_ita_altra": "Postiglione",
      "denominazione_ita": "Postiglione",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G939",
      "lat": "40.5571778",
      "lon": "15.2330364",
      "superficie_kmq": "48.2433",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065102",
      "denominazione_ita_altra": "Praiano",
      "denominazione_ita": "Praiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G976",
      "lat": "40.6113841",
      "lon": "14.5325710",
      "superficie_kmq": "2.6706",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065103",
      "denominazione_ita_altra": "Prignano Cilento",
      "denominazione_ita": "Prignano Cilento",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H062",
      "lat": "40.3297990",
      "lon": "15.0692246",
      "superficie_kmq": "12.0409",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065104",
      "denominazione_ita_altra": "Ravello",
      "denominazione_ita": "Ravello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H198",
      "lat": "40.6527820",
      "lon": "14.6136420",
      "superficie_kmq": "7.9350",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065105",
      "denominazione_ita_altra": "Ricigliano",
      "denominazione_ita": "Ricigliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H277",
      "lat": "40.6680248",
      "lon": "15.4835997",
      "superficie_kmq": "27.9286",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065106",
      "denominazione_ita_altra": "Roccadaspide",
      "denominazione_ita": "Roccadaspide",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H394",
      "lat": "40.4259806",
      "lon": "15.1942787",
      "superficie_kmq": "64.1579",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065107",
      "denominazione_ita_altra": "Roccagloriosa",
      "denominazione_ita": "Roccagloriosa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H412",
      "lat": "40.1059850",
      "lon": "15.4375719",
      "superficie_kmq": "40.5575",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065108",
      "denominazione_ita_altra": "Roccapiemonte",
      "denominazione_ita": "Roccapiemonte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H431",
      "lat": "40.7623072",
      "lon": "14.6926759",
      "superficie_kmq": "5.3133",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065109",
      "denominazione_ita_altra": "Rofrano",
      "denominazione_ita": "Rofrano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H485",
      "lat": "40.2104002",
      "lon": "15.4272267",
      "superficie_kmq": "63.5882",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065110",
      "denominazione_ita_altra": "Romagnano al Monte",
      "denominazione_ita": "Romagnano al Monte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H503",
      "lat": "40.6278858",
      "lon": "15.4345281",
      "superficie_kmq": "9.6700",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065111",
      "denominazione_ita_altra": "Roscigno",
      "denominazione_ita": "Roscigno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H564",
      "lat": "40.4003052",
      "lon": "15.3445331",
      "superficie_kmq": "15.1813",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065112",
      "denominazione_ita_altra": "Rutino",
      "denominazione_ita": "Rutino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H644",
      "lat": "40.2998150",
      "lon": "15.0700889",
      "superficie_kmq": "9.6883",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065113",
      "denominazione_ita_altra": "Sacco",
      "denominazione_ita": "Sacco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H654",
      "lat": "40.3817153",
      "lon": "15.3729056",
      "superficie_kmq": "23.6570",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065114",
      "denominazione_ita_altra": "Sala Consilina",
      "denominazione_ita": "Sala Consilina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H683",
      "lat": "40.4025958",
      "lon": "15.5908366",
      "superficie_kmq": "59.6928",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065115",
      "denominazione_ita_altra": "Salento",
      "denominazione_ita": "Salento",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H686",
      "lat": "40.2489246",
      "lon": "15.1884108",
      "superficie_kmq": "23.7851",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065116",
      "denominazione_ita_altra": "Salerno",
      "denominazione_ita": "Salerno",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "H703",
      "lat": "40.6781573",
      "lon": "14.7593976",
      "superficie_kmq": "59.8517",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065117",
      "denominazione_ita_altra": "Salvitelle",
      "denominazione_ita": "Salvitelle",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H732",
      "lat": "40.5909989",
      "lon": "15.4593077",
      "superficie_kmq": "9.5976",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065118",
      "denominazione_ita_altra": "San Cipriano Picentino",
      "denominazione_ita": "San Cipriano Picentino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H800",
      "lat": "40.7203402",
      "lon": "14.8723499",
      "superficie_kmq": "17.3872",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065119",
      "denominazione_ita_altra": "San Giovanni a Piro",
      "denominazione_ita": "San Giovanni a Piro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H907",
      "lat": "40.0503652",
      "lon": "15.4498356",
      "superficie_kmq": "37.9015",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065120",
      "denominazione_ita_altra": "San Gregorio Magno",
      "denominazione_ita": "San Gregorio Magno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H943",
      "lat": "40.6561004",
      "lon": "15.3994033",
      "superficie_kmq": "50.0525",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065121",
      "denominazione_ita_altra": "San Mango Piemonte",
      "denominazione_ita": "San Mango Piemonte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H977",
      "lat": "40.7001789",
      "lon": "14.8391831",
      "superficie_kmq": "6.0237",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065122",
      "denominazione_ita_altra": "San Marzano sul Sarno",
      "denominazione_ita": "San Marzano sul Sarno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I019",
      "lat": "40.7745469",
      "lon": "14.5879770",
      "superficie_kmq": "5.1894",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065123",
      "denominazione_ita_altra": "San Mauro Cilento",
      "denominazione_ita": "San Mauro Cilento",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I031",
      "lat": "40.2262138",
      "lon": "15.0447582",
      "superficie_kmq": "15.2807",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065124",
      "denominazione_ita_altra": "San Mauro la Bruca",
      "denominazione_ita": "San Mauro la Bruca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I032",
      "lat": "40.1215218",
      "lon": "15.2859937",
      "superficie_kmq": "19.0544",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065125",
      "denominazione_ita_altra": "San Pietro al Tanagro",
      "denominazione_ita": "San Pietro al Tanagro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I089",
      "lat": "40.4539728",
      "lon": "15.4875348",
      "superficie_kmq": "15.5134",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065126",
      "denominazione_ita_altra": "San Rufo",
      "denominazione_ita": "San Rufo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I143",
      "lat": "40.4342837",
      "lon": "15.4616507",
      "superficie_kmq": "31.9585",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065127",
      "denominazione_ita_altra": "Santa Marina",
      "denominazione_ita": "Santa Marina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I253",
      "lat": "40.1051334",
      "lon": "15.5415218",
      "superficie_kmq": "28.3605",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065128",
      "denominazione_ita_altra": "Sant'Angelo a Fasanella",
      "denominazione_ita": "Sant'Angelo a Fasanella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I278",
      "lat": "40.4569780",
      "lon": "15.3436544",
      "superficie_kmq": "32.6091",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065129",
      "denominazione_ita_altra": "Sant'Arsenio",
      "denominazione_ita": "Sant'Arsenio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I307",
      "lat": "40.4710376",
      "lon": "15.4863993",
      "superficie_kmq": "20.1435",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065130",
      "denominazione_ita_altra": "Sant'Egidio del Monte Albino",
      "denominazione_ita": "Sant'Egidio del Monte Albino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I317",
      "lat": "40.7358611",
      "lon": "14.5989484",
      "superficie_kmq": "7.2451",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065131",
      "denominazione_ita_altra": "Santomenna",
      "denominazione_ita": "Santomenna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I260",
      "lat": "40.8070226",
      "lon": "15.3202582",
      "superficie_kmq": "8.9193",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065132",
      "denominazione_ita_altra": "San Valentino Torio",
      "denominazione_ita": "San Valentino Torio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I377",
      "lat": "40.7917607",
      "lon": "14.6009378",
      "superficie_kmq": "9.1563",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065133",
      "denominazione_ita_altra": "Sanza",
      "denominazione_ita": "Sanza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I410",
      "lat": "40.2445708",
      "lon": "15.5532996",
      "superficie_kmq": "128.7442",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065134",
      "denominazione_ita_altra": "Sapri",
      "denominazione_ita": "Sapri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I422",
      "lat": "40.0738571",
      "lon": "15.6307248",
      "superficie_kmq": "14.2001",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065135",
      "denominazione_ita_altra": "Sarno",
      "denominazione_ita": "Sarno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I438",
      "lat": "40.8152734",
      "lon": "14.6188943",
      "superficie_kmq": "40.0006",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065136",
      "denominazione_ita_altra": "Sassano",
      "denominazione_ita": "Sassano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I451",
      "lat": "40.3420160",
      "lon": "15.5659357",
      "superficie_kmq": "47.7559",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065137",
      "denominazione_ita_altra": "Scafati",
      "denominazione_ita": "Scafati",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I483",
      "lat": "40.7480019",
      "lon": "14.5262299",
      "superficie_kmq": "19.8723",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065138",
      "denominazione_ita_altra": "Scala",
      "denominazione_ita": "Scala",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I486",
      "lat": "40.6558793",
      "lon": "14.6070603",
      "superficie_kmq": "13.8627",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065139",
      "denominazione_ita_altra": "Serramezzana",
      "denominazione_ita": "Serramezzana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I648",
      "lat": "40.2444734",
      "lon": "15.0322797",
      "superficie_kmq": "7.2296",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065140",
      "denominazione_ita_altra": "Serre",
      "denominazione_ita": "Serre",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I666",
      "lat": "40.5811250",
      "lon": "15.1877976",
      "superficie_kmq": "67.0330",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065141",
      "denominazione_ita_altra": "Sessa Cilento",
      "denominazione_ita": "Sessa Cilento",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I677",
      "lat": "40.2592218",
      "lon": "15.0752665",
      "superficie_kmq": "18.0424",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065142",
      "denominazione_ita_altra": "Siano",
      "denominazione_ita": "Siano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I720",
      "lat": "40.8009256",
      "lon": "14.6936191",
      "superficie_kmq": "8.5692",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065143",
      "denominazione_ita_altra": "Sicignano degli Alburni",
      "denominazione_ita": "Sicignano degli Alburni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M253",
      "lat": "40.5592758",
      "lon": "15.3059494",
      "superficie_kmq": "81.1133",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065144",
      "denominazione_ita_altra": "Stella Cilento",
      "denominazione_ita": "Stella Cilento",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G887",
      "lat": "40.2315387",
      "lon": "15.0932951",
      "superficie_kmq": "14.5173",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065145",
      "denominazione_ita_altra": "Stio",
      "denominazione_ita": "Stio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I960",
      "lat": "40.3107472",
      "lon": "15.2552034",
      "superficie_kmq": "24.2800",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065146",
      "denominazione_ita_altra": "Teggiano",
      "denominazione_ita": "Teggiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D292",
      "lat": "40.3795875",
      "lon": "15.5404022",
      "superficie_kmq": "61.8655",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065147",
      "denominazione_ita_altra": "Torchiara",
      "denominazione_ita": "Torchiara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L212",
      "lat": "40.3215483",
      "lon": "15.0536903",
      "superficie_kmq": "8.4617",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065148",
      "denominazione_ita_altra": "Torraca",
      "denominazione_ita": "Torraca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L233",
      "lat": "40.1109183",
      "lon": "15.6363104",
      "superficie_kmq": "16.0132",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065149",
      "denominazione_ita_altra": "Torre Orsaia",
      "denominazione_ita": "Torre Orsaia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L274",
      "lat": "40.1308746",
      "lon": "15.4714327",
      "superficie_kmq": "21.0329",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065150",
      "denominazione_ita_altra": "Tortorella",
      "denominazione_ita": "Tortorella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L306",
      "lat": "40.1422878",
      "lon": "15.6062465",
      "superficie_kmq": "34.2173",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065151",
      "denominazione_ita_altra": "Tramonti",
      "denominazione_ita": "Tramonti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L323",
      "lat": "40.6947770",
      "lon": "14.6401871",
      "superficie_kmq": "24.8260",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065152",
      "denominazione_ita_altra": "Trentinara",
      "denominazione_ita": "Trentinara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L377",
      "lat": "40.4006168",
      "lon": "15.1073527",
      "superficie_kmq": "23.4361",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065153",
      "denominazione_ita_altra": "Valle dell'Angelo",
      "denominazione_ita": "Valle dell'Angelo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G540",
      "lat": "40.3437082",
      "lon": "15.3686815",
      "superficie_kmq": "36.6021",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065154",
      "denominazione_ita_altra": "Vallo della Lucania",
      "denominazione_ita": "Vallo della Lucania",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L628",
      "lat": "40.2287073",
      "lon": "15.2670656",
      "superficie_kmq": "25.3214",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065155",
      "denominazione_ita_altra": "Valva",
      "denominazione_ita": "Valva",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L656",
      "lat": "40.7396484",
      "lon": "15.2698831",
      "superficie_kmq": "26.7850",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065156",
      "denominazione_ita_altra": "Vibonati",
      "denominazione_ita": "Vibonati",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L835",
      "lat": "40.1031305",
      "lon": "15.5799300",
      "superficie_kmq": "20.5428",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065157",
      "denominazione_ita_altra": "Vietri sul Mare",
      "denominazione_ita": "Vietri sul Mare",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L860",
      "lat": "40.6719669",
      "lon": "14.7281514",
      "superficie_kmq": "9.5175",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "SA",
      "codice_istat": "065158",
      "denominazione_ita_altra": "Bellizzi",
      "denominazione_ita": "Bellizzi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M294",
      "lat": "40.6221228",
      "lon": "14.9460221",
      "superficie_kmq": "8.0220",
      "codice_sovracomunale": "065"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066001",
      "denominazione_ita_altra": "Acciano",
      "denominazione_ita": "Acciano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A018",
      "lat": "42.1766756",
      "lon": "13.7183455",
      "superficie_kmq": "32.2232",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066002",
      "denominazione_ita_altra": "Aielli",
      "denominazione_ita": "Aielli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A100",
      "lat": "42.0801762",
      "lon": "13.5902803",
      "superficie_kmq": "37.5166",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066003",
      "denominazione_ita_altra": "Alfedena",
      "denominazione_ita": "Alfedena",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A187",
      "lat": "41.7339220",
      "lon": "14.0343514",
      "superficie_kmq": "39.9500",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066004",
      "denominazione_ita_altra": "Anversa degli Abruzzi",
      "denominazione_ita": "Anversa degli Abruzzi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A318",
      "lat": "41.9945907",
      "lon": "13.8035784",
      "superficie_kmq": "32.4329",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066005",
      "denominazione_ita_altra": "Ateleta",
      "denominazione_ita": "Ateleta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A481",
      "lat": "41.8549891",
      "lon": "14.1977797",
      "superficie_kmq": "41.9252",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066006",
      "denominazione_ita_altra": "Avezzano",
      "denominazione_ita": "Avezzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A515",
      "lat": "42.0306364",
      "lon": "13.4267476",
      "superficie_kmq": "104.0845",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066007",
      "denominazione_ita_altra": "Balsorano",
      "denominazione_ita": "Balsorano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A603",
      "lat": "41.8035652",
      "lon": "13.5633447",
      "superficie_kmq": "58.8420",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066008",
      "denominazione_ita_altra": "Barete",
      "denominazione_ita": "Barete",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A656",
      "lat": "42.4509235",
      "lon": "13.2812671",
      "superficie_kmq": "24.5883",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066009",
      "denominazione_ita_altra": "Barisciano",
      "denominazione_ita": "Barisciano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A667",
      "lat": "42.3269805",
      "lon": "13.5907751",
      "superficie_kmq": "78.4864",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066010",
      "denominazione_ita_altra": "Barrea",
      "denominazione_ita": "Barrea",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A678",
      "lat": "41.7575366",
      "lon": "13.9915250",
      "superficie_kmq": "87.1069",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066011",
      "denominazione_ita_altra": "Bisegna",
      "denominazione_ita": "Bisegna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A884",
      "lat": "41.9209420",
      "lon": "13.7574820",
      "superficie_kmq": "46.5929",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066012",
      "denominazione_ita_altra": "Bugnara",
      "denominazione_ita": "Bugnara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B256",
      "lat": "42.0221153",
      "lon": "13.8587090",
      "superficie_kmq": "25.1210",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066013",
      "denominazione_ita_altra": "Cagnano Amiterno",
      "denominazione_ita": "Cagnano Amiterno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B358",
      "lat": "42.4590754",
      "lon": "13.2246006",
      "superficie_kmq": "61.3144",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066014",
      "denominazione_ita_altra": "Calascio",
      "denominazione_ita": "Calascio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B382",
      "lat": "42.3254811",
      "lon": "13.6948005",
      "superficie_kmq": "39.4433",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066015",
      "denominazione_ita_altra": "Campo di Giove",
      "denominazione_ita": "Campo di Giove",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B526",
      "lat": "42.0118820",
      "lon": "14.0445397",
      "superficie_kmq": "28.9040",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066016",
      "denominazione_ita_altra": "Campotosto",
      "denominazione_ita": "Campotosto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B569",
      "lat": "42.5573210",
      "lon": "13.3698893",
      "superficie_kmq": "51.7245",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066017",
      "denominazione_ita_altra": "Canistro",
      "denominazione_ita": "Canistro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B606",
      "lat": "41.9411658",
      "lon": "13.4114611",
      "superficie_kmq": "15.9030",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066018",
      "denominazione_ita_altra": "Cansano",
      "denominazione_ita": "Cansano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B624",
      "lat": "42.0045683",
      "lon": "14.0109545",
      "superficie_kmq": "37.6995",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066019",
      "denominazione_ita_altra": "Capestrano",
      "denominazione_ita": "Capestrano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B651",
      "lat": "42.2673830",
      "lon": "13.7682726",
      "superficie_kmq": "43.6574",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066020",
      "denominazione_ita_altra": "Capistrello",
      "denominazione_ita": "Capistrello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B656",
      "lat": "41.9655083",
      "lon": "13.3896650",
      "superficie_kmq": "60.9626",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066021",
      "denominazione_ita_altra": "Capitignano",
      "denominazione_ita": "Capitignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B658",
      "lat": "42.5201955",
      "lon": "13.3007987",
      "superficie_kmq": "30.6391",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066022",
      "denominazione_ita_altra": "Caporciano",
      "denominazione_ita": "Caporciano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B672",
      "lat": "42.2518174",
      "lon": "13.6713387",
      "superficie_kmq": "18.6151",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066023",
      "denominazione_ita_altra": "Cappadocia",
      "denominazione_ita": "Cappadocia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B677",
      "lat": "42.0070128",
      "lon": "13.2792239",
      "superficie_kmq": "68.5707",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066024",
      "denominazione_ita_altra": "Carapelle Calvisio",
      "denominazione_ita": "Carapelle Calvisio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B725",
      "lat": "42.2991085",
      "lon": "13.6832037",
      "superficie_kmq": "14.7863",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066025",
      "denominazione_ita_altra": "Carsoli",
      "denominazione_ita": "Carsoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B842",
      "lat": "42.0963735",
      "lon": "13.0826848",
      "superficie_kmq": "95.7935",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066026",
      "denominazione_ita_altra": "Castel del Monte",
      "denominazione_ita": "Castel del Monte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C083",
      "lat": "42.3648075",
      "lon": "13.7264726",
      "superficie_kmq": "58.0313",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066027",
      "denominazione_ita_altra": "Castel di Ieri",
      "denominazione_ita": "Castel di Ieri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C090",
      "lat": "42.1140208",
      "lon": "13.7407542",
      "superficie_kmq": "18.8838",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066028",
      "denominazione_ita_altra": "Castel di Sangro",
      "denominazione_ita": "Castel di Sangro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C096",
      "lat": "41.7851637",
      "lon": "14.1084821",
      "superficie_kmq": "84.4298",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066029",
      "denominazione_ita_altra": "Castellafiume",
      "denominazione_ita": "Castellafiume",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C126",
      "lat": "41.9879188",
      "lon": "13.3346829",
      "superficie_kmq": "24.1005",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066030",
      "denominazione_ita_altra": "Castelvecchio Calvisio",
      "denominazione_ita": "Castelvecchio Calvisio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C278",
      "lat": "42.3101140",
      "lon": "13.6887312",
      "superficie_kmq": "15.3194",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066031",
      "denominazione_ita_altra": "Castelvecchio Subequo",
      "denominazione_ita": "Castelvecchio Subequo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C279",
      "lat": "42.1314307",
      "lon": "13.7288270",
      "superficie_kmq": "19.2942",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066032",
      "denominazione_ita_altra": "Celano",
      "denominazione_ita": "Celano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C426",
      "lat": "42.0847898",
      "lon": "13.5482189",
      "superficie_kmq": "82.7991",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066033",
      "denominazione_ita_altra": "Cerchio",
      "denominazione_ita": "Cerchio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C492",
      "lat": "42.0640733",
      "lon": "13.6005096",
      "superficie_kmq": "20.1647",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066034",
      "denominazione_ita_altra": "Civita d'Antino",
      "denominazione_ita": "Civita d'Antino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C766",
      "lat": "41.8863277",
      "lon": "13.4721247",
      "superficie_kmq": "28.3454",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066035",
      "denominazione_ita_altra": "Civitella Alfedena",
      "denominazione_ita": "Civitella Alfedena",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C778",
      "lat": "41.7652736",
      "lon": "13.9405443",
      "superficie_kmq": "29.4906",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066036",
      "denominazione_ita_altra": "Civitella Roveto",
      "denominazione_ita": "Civitella Roveto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C783",
      "lat": "41.9161698",
      "lon": "13.4268733",
      "superficie_kmq": "45.4518",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066037",
      "denominazione_ita_altra": "Cocullo",
      "denominazione_ita": "Cocullo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C811",
      "lat": "42.0331107",
      "lon": "13.7747142",
      "superficie_kmq": "31.6044",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066038",
      "denominazione_ita_altra": "Collarmele",
      "denominazione_ita": "Collarmele",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C844",
      "lat": "42.0612858",
      "lon": "13.6264268",
      "superficie_kmq": "23.9435",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066039",
      "denominazione_ita_altra": "Collelongo",
      "denominazione_ita": "Collelongo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C862",
      "lat": "41.8863460",
      "lon": "13.5835033",
      "superficie_kmq": "54.0194",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066040",
      "denominazione_ita_altra": "Collepietro",
      "denominazione_ita": "Collepietro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C866",
      "lat": "42.2209144",
      "lon": "13.7805597",
      "superficie_kmq": "15.2092",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066041",
      "denominazione_ita_altra": "Corfinio",
      "denominazione_ita": "Corfinio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C999",
      "lat": "42.1241148",
      "lon": "13.8438613",
      "superficie_kmq": "17.9499",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066042",
      "denominazione_ita_altra": "Fagnano Alto",
      "denominazione_ita": "Fagnano Alto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D465",
      "lat": "42.2536801",
      "lon": "13.5749867",
      "superficie_kmq": "24.6437",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066043",
      "denominazione_ita_altra": "Fontecchio",
      "denominazione_ita": "Fontecchio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D681",
      "lat": "42.2315981",
      "lon": "13.6065873",
      "superficie_kmq": "16.8595",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066044",
      "denominazione_ita_altra": "Fossa",
      "denominazione_ita": "Fossa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D736",
      "lat": "42.2932599",
      "lon": "13.4878130",
      "superficie_kmq": "8.7066",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066045",
      "denominazione_ita_altra": "Gagliano Aterno",
      "denominazione_ita": "Gagliano Aterno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D850",
      "lat": "42.1262210",
      "lon": "13.7008615",
      "superficie_kmq": "32.1490",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066046",
      "denominazione_ita_altra": "Gioia dei Marsi",
      "denominazione_ita": "Gioia dei Marsi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E040",
      "lat": "41.9541869",
      "lon": "13.6932202",
      "superficie_kmq": "58.4035",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066047",
      "denominazione_ita_altra": "Goriano Sicoli",
      "denominazione_ita": "Goriano Sicoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E096",
      "lat": "42.0797846",
      "lon": "13.7733876",
      "superficie_kmq": "20.2376",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066048",
      "denominazione_ita_altra": "Introdacqua",
      "denominazione_ita": "Introdacqua",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E307",
      "lat": "42.0076494",
      "lon": "13.8991889",
      "superficie_kmq": "37.1068",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066049",
      "denominazione_ita_altra": "L'Aquila",
      "denominazione_ita": "L'Aquila",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "A345",
      "lat": "42.3511826",
      "lon": "13.3984355",
      "superficie_kmq": "473.9064",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066050",
      "denominazione_ita_altra": "Lecce nei Marsi",
      "denominazione_ita": "Lecce nei Marsi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E505",
      "lat": "41.9329019",
      "lon": "13.6871940",
      "superficie_kmq": "66.4694",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066051",
      "denominazione_ita_altra": "Luco dei Marsi",
      "denominazione_ita": "Luco dei Marsi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E723",
      "lat": "41.9618439",
      "lon": "13.4689831",
      "superficie_kmq": "44.8647",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066052",
      "denominazione_ita_altra": "Lucoli",
      "denominazione_ita": "Lucoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E724",
      "lat": "42.2909825",
      "lon": "13.3377147",
      "superficie_kmq": "103.4425",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066053",
      "denominazione_ita_altra": "Magliano de' Marsi",
      "denominazione_ita": "Magliano de' Marsi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E811",
      "lat": "42.0922420",
      "lon": "13.3647858",
      "superficie_kmq": "70.9185",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066054",
      "denominazione_ita_altra": "Massa d'Albe",
      "denominazione_ita": "Massa d'Albe",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F022",
      "lat": "42.1089499",
      "lon": "13.3921998",
      "superficie_kmq": "68.5331",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066055",
      "denominazione_ita_altra": "Molina Aterno",
      "denominazione_ita": "Molina Aterno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M255",
      "lat": "42.1480041",
      "lon": "13.7357461",
      "superficie_kmq": "12.2078",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066056",
      "denominazione_ita_altra": "Montereale",
      "denominazione_ita": "Montereale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F595",
      "lat": "42.5240375",
      "lon": "13.2450842",
      "superficie_kmq": "104.4084",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066057",
      "denominazione_ita_altra": "Morino",
      "denominazione_ita": "Morino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F732",
      "lat": "41.8647167",
      "lon": "13.4575388",
      "superficie_kmq": "51.2701",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066058",
      "denominazione_ita_altra": "Navelli",
      "denominazione_ita": "Navelli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F852",
      "lat": "42.2362294",
      "lon": "13.7303112",
      "superficie_kmq": "42.0036",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066059",
      "denominazione_ita_altra": "Ocre",
      "denominazione_ita": "Ocre",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F996",
      "lat": "42.2837778",
      "lon": "13.4730275",
      "superficie_kmq": "23.6036",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066060",
      "denominazione_ita_altra": "Ofena",
      "denominazione_ita": "Ofena",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G002",
      "lat": "42.3281147",
      "lon": "13.7588574",
      "superficie_kmq": "36.8978",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066061",
      "denominazione_ita_altra": "Opi",
      "denominazione_ita": "Opi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G079",
      "lat": "41.7794248",
      "lon": "13.8299935",
      "superficie_kmq": "49.9042",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066062",
      "denominazione_ita_altra": "Oricola",
      "denominazione_ita": "Oricola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G102",
      "lat": "42.0498340",
      "lon": "13.0388992",
      "superficie_kmq": "18.3576",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066063",
      "denominazione_ita_altra": "Ortona dei Marsi",
      "denominazione_ita": "Ortona dei Marsi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G142",
      "lat": "41.9977692",
      "lon": "13.7291633",
      "superficie_kmq": "57.1661",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066064",
      "denominazione_ita_altra": "Ortucchio",
      "denominazione_ita": "Ortucchio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G145",
      "lat": "41.9548392",
      "lon": "13.6454060",
      "superficie_kmq": "38.9993",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066065",
      "denominazione_ita_altra": "Ovindoli",
      "denominazione_ita": "Ovindoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G200",
      "lat": "42.1330772",
      "lon": "13.5176778",
      "superficie_kmq": "61.3767",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066066",
      "denominazione_ita_altra": "Pacentro",
      "denominazione_ita": "Pacentro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G210",
      "lat": "42.0510319",
      "lon": "13.9933879",
      "superficie_kmq": "72.5874",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066067",
      "denominazione_ita_altra": "Pereto",
      "denominazione_ita": "Pereto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G449",
      "lat": "42.0580298",
      "lon": "13.1015110",
      "superficie_kmq": "41.1547",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066068",
      "denominazione_ita_altra": "Pescasseroli",
      "denominazione_ita": "Pescasseroli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G484",
      "lat": "41.8085862",
      "lon": "13.7892159",
      "superficie_kmq": "91.1585",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066069",
      "denominazione_ita_altra": "Pescina",
      "denominazione_ita": "Pescina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G492",
      "lat": "42.0260276",
      "lon": "13.6600607",
      "superficie_kmq": "48.7943",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066070",
      "denominazione_ita_altra": "Pescocostanzo",
      "denominazione_ita": "Pescocostanzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G493",
      "lat": "41.8902289",
      "lon": "14.0657743",
      "superficie_kmq": "55.0623",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066071",
      "denominazione_ita_altra": "Pettorano sul Gizio",
      "denominazione_ita": "Pettorano sul Gizio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G524",
      "lat": "41.9727639",
      "lon": "13.9603478",
      "superficie_kmq": "62.8484",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066072",
      "denominazione_ita_altra": "Pizzoli",
      "denominazione_ita": "Pizzoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G726",
      "lat": "42.4361733",
      "lon": "13.2953981",
      "superficie_kmq": "56.4355",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066073",
      "denominazione_ita_altra": "Poggio Picenze",
      "denominazione_ita": "Poggio Picenze",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G766",
      "lat": "42.3206377",
      "lon": "13.5421933",
      "superficie_kmq": "11.4565",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066074",
      "denominazione_ita_altra": "Prata d'Ansidonia",
      "denominazione_ita": "Prata d'Ansidonia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G992",
      "lat": "42.2782003",
      "lon": "13.6062783",
      "superficie_kmq": "19.6544",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066075",
      "denominazione_ita_altra": "Pratola Peligna",
      "denominazione_ita": "Pratola Peligna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H007",
      "lat": "42.0973192",
      "lon": "13.8724315",
      "superficie_kmq": "28.6736",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066076",
      "denominazione_ita_altra": "Prezza",
      "denominazione_ita": "Prezza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H056",
      "lat": "42.0577046",
      "lon": "13.8331946",
      "superficie_kmq": "21.5962",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066077",
      "denominazione_ita_altra": "Raiano",
      "denominazione_ita": "Raiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H166",
      "lat": "42.1027616",
      "lon": "13.8083308",
      "superficie_kmq": "28.9925",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066078",
      "denominazione_ita_altra": "Rivisondoli",
      "denominazione_ita": "Rivisondoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H353",
      "lat": "41.8703492",
      "lon": "14.0663591",
      "superficie_kmq": "32.0009",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066079",
      "denominazione_ita_altra": "Roccacasale",
      "denominazione_ita": "Roccacasale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H389",
      "lat": "42.1222677",
      "lon": "13.8899798",
      "superficie_kmq": "17.3068",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066080",
      "denominazione_ita_altra": "Rocca di Botte",
      "denominazione_ita": "Rocca di Botte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H399",
      "lat": "42.0262231",
      "lon": "13.0682141",
      "superficie_kmq": "31.1067",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066081",
      "denominazione_ita_altra": "Rocca di Cambio",
      "denominazione_ita": "Rocca di Cambio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H400",
      "lat": "42.2367440",
      "lon": "13.4893930",
      "superficie_kmq": "27.6186",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066082",
      "denominazione_ita_altra": "Rocca di Mezzo",
      "denominazione_ita": "Rocca di Mezzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H402",
      "lat": "42.2077447",
      "lon": "13.5186592",
      "superficie_kmq": "90.5452",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066083",
      "denominazione_ita_altra": "Rocca Pia",
      "denominazione_ita": "Rocca Pia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H429",
      "lat": "41.9347431",
      "lon": "13.9769406",
      "superficie_kmq": "44.9610",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066084",
      "denominazione_ita_altra": "Roccaraso",
      "denominazione_ita": "Roccaraso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H434",
      "lat": "41.8462215",
      "lon": "14.0785534",
      "superficie_kmq": "49.9100",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066085",
      "denominazione_ita_altra": "San Benedetto dei Marsi",
      "denominazione_ita": "San Benedetto dei Marsi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H772",
      "lat": "42.0064091",
      "lon": "13.6233026",
      "superficie_kmq": "16.7609",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066086",
      "denominazione_ita_altra": "San Benedetto in Perillis",
      "denominazione_ita": "San Benedetto in Perillis",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H773",
      "lat": "42.1827422",
      "lon": "13.7697549",
      "superficie_kmq": "19.0984",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066087",
      "denominazione_ita_altra": "San Demetrio ne' Vestini",
      "denominazione_ita": "San Demetrio ne' Vestini",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H819",
      "lat": "42.2880145",
      "lon": "13.5587637",
      "superficie_kmq": "16.4918",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066088",
      "denominazione_ita_altra": "San Pio delle Camere",
      "denominazione_ita": "San Pio delle Camere",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I121",
      "lat": "42.2861937",
      "lon": "13.6548036",
      "superficie_kmq": "17.2122",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066089",
      "denominazione_ita_altra": "Sante Marie",
      "denominazione_ita": "Sante Marie",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I326",
      "lat": "42.1028172",
      "lon": "13.2029813",
      "superficie_kmq": "40.8133",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066090",
      "denominazione_ita_altra": "Sant'Eusanio Forconese",
      "denominazione_ita": "Sant'Eusanio Forconese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I336",
      "lat": "42.2886602",
      "lon": "13.5243885",
      "superficie_kmq": "7.9441",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066091",
      "denominazione_ita_altra": "Santo Stefano di Sessanio",
      "denominazione_ita": "Santo Stefano di Sessanio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I360",
      "lat": "42.3421982",
      "lon": "13.6465138",
      "superficie_kmq": "33.6988",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066092",
      "denominazione_ita_altra": "San Vincenzo Valle Roveto",
      "denominazione_ita": "San Vincenzo Valle Roveto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I389",
      "lat": "41.8336841",
      "lon": "13.5237828",
      "superficie_kmq": "46.0389",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066093",
      "denominazione_ita_altra": "Scanno",
      "denominazione_ita": "Scanno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I501",
      "lat": "41.9022886",
      "lon": "13.8811570",
      "superficie_kmq": "134.6765",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066094",
      "denominazione_ita_altra": "Scontrone",
      "denominazione_ita": "Scontrone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I543",
      "lat": "41.7461547",
      "lon": "14.0390470",
      "superficie_kmq": "21.3541",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066095",
      "denominazione_ita_altra": "Scoppito",
      "denominazione_ita": "Scoppito",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I546",
      "lat": "42.3710272",
      "lon": "13.2564760",
      "superficie_kmq": "53.0036",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066096",
      "denominazione_ita_altra": "Scurcola Marsicana",
      "denominazione_ita": "Scurcola Marsicana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I553",
      "lat": "42.0644124",
      "lon": "13.3424015",
      "superficie_kmq": "30.3815",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066097",
      "denominazione_ita_altra": "Secinaro",
      "denominazione_ita": "Secinaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I558",
      "lat": "42.1524849",
      "lon": "13.6790223",
      "superficie_kmq": "33.3410",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066098",
      "denominazione_ita_altra": "Sulmona",
      "denominazione_ita": "Sulmona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I804",
      "lat": "42.0477632",
      "lon": "13.9240519",
      "superficie_kmq": "57.9278",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066099",
      "denominazione_ita_altra": "Tagliacozzo",
      "denominazione_ita": "Tagliacozzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L025",
      "lat": "42.0686686",
      "lon": "13.2554887",
      "superficie_kmq": "87.4574",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066100",
      "denominazione_ita_altra": "Tione degli Abruzzi",
      "denominazione_ita": "Tione degli Abruzzi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L173",
      "lat": "42.2038489",
      "lon": "13.6357427",
      "superficie_kmq": "39.6548",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066101",
      "denominazione_ita_altra": "Tornimparte",
      "denominazione_ita": "Tornimparte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L227",
      "lat": "42.2909139",
      "lon": "13.3003285",
      "superficie_kmq": "65.9607",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066102",
      "denominazione_ita_altra": "Trasacco",
      "denominazione_ita": "Trasacco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L334",
      "lat": "41.9580503",
      "lon": "13.5388051",
      "superficie_kmq": "51.4430",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066103",
      "denominazione_ita_altra": "Villalago",
      "denominazione_ita": "Villalago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L958",
      "lat": "41.9359654",
      "lon": "13.8357704",
      "superficie_kmq": "33.1994",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066104",
      "denominazione_ita_altra": "Villa Santa Lucia degli Abruzzi",
      "denominazione_ita": "Villa Santa Lucia degli Abruzzi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M021",
      "lat": "42.3329885",
      "lon": "13.7781898",
      "superficie_kmq": "26.9897",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066105",
      "denominazione_ita_altra": "Villa Sant'Angelo",
      "denominazione_ita": "Villa Sant'Angelo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M023",
      "lat": "42.2699884",
      "lon": "13.5375388",
      "superficie_kmq": "5.2153",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066106",
      "denominazione_ita_altra": "Villavallelonga",
      "denominazione_ita": "Villavallelonga",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M031",
      "lat": "41.8673033",
      "lon": "13.6245334",
      "superficie_kmq": "73.7329",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066107",
      "denominazione_ita_altra": "Villetta Barrea",
      "denominazione_ita": "Villetta Barrea",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M041",
      "lat": "41.7765534",
      "lon": "13.9277487",
      "superficie_kmq": "20.5246",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "AQ",
      "codice_istat": "066108",
      "denominazione_ita_altra": "Vittorito",
      "denominazione_ita": "Vittorito",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M090",
      "lat": "42.1254072",
      "lon": "13.8161646",
      "superficie_kmq": "14.1945",
      "codice_sovracomunale": "066"
    },
    {
      "sigla_provincia": "TE",
      "codice_istat": "067001",
      "denominazione_ita_altra": "Alba Adriatica",
      "denominazione_ita": "Alba Adriatica",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A125",
      "lat": "42.8345658",
      "lon": "13.9209494",
      "superficie_kmq": "9.5964",
      "codice_sovracomunale": "067"
    },
    {
      "sigla_provincia": "TE",
      "codice_istat": "067002",
      "denominazione_ita_altra": "Ancarano",
      "denominazione_ita": "Ancarano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A270",
      "lat": "42.8367769",
      "lon": "13.7413936",
      "superficie_kmq": "13.9192",
      "codice_sovracomunale": "067"
    },
    {
      "sigla_provincia": "TE",
      "codice_istat": "067003",
      "denominazione_ita_altra": "Arsita",
      "denominazione_ita": "Arsita",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A445",
      "lat": "42.5030408",
      "lon": "13.7859483",
      "superficie_kmq": "34.1360",
      "codice_sovracomunale": "067"
    },
    {
      "sigla_provincia": "TE",
      "codice_istat": "067004",
      "denominazione_ita_altra": "Atri",
      "denominazione_ita": "Atri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A488",
      "lat": "42.5796750",
      "lon": "13.9754153",
      "superficie_kmq": "92.1814",
      "codice_sovracomunale": "067"
    },
    {
      "sigla_provincia": "TE",
      "codice_istat": "067005",
      "denominazione_ita_altra": "Basciano",
      "denominazione_ita": "Basciano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A692",
      "lat": "42.5956736",
      "lon": "13.7386287",
      "superficie_kmq": "18.8534",
      "codice_sovracomunale": "067"
    },
    {
      "sigla_provincia": "TE",
      "codice_istat": "067006",
      "denominazione_ita_altra": "Bellante",
      "denominazione_ita": "Bellante",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A746",
      "lat": "42.7445679",
      "lon": "13.8053564",
      "superficie_kmq": "50.0440",
      "codice_sovracomunale": "067"
    },
    {
      "sigla_provincia": "TE",
      "codice_istat": "067007",
      "denominazione_ita_altra": "Bisenti",
      "denominazione_ita": "Bisenti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A885",
      "lat": "42.5287395",
      "lon": "13.8036833",
      "superficie_kmq": "30.8746",
      "codice_sovracomunale": "067"
    },
    {
      "sigla_provincia": "TE",
      "codice_istat": "067008",
      "denominazione_ita_altra": "Campli",
      "denominazione_ita": "Campli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B515",
      "lat": "42.7263267",
      "lon": "13.6863533",
      "superficie_kmq": "73.4239",
      "codice_sovracomunale": "067"
    },
    {
      "sigla_provincia": "TE",
      "codice_istat": "067009",
      "denominazione_ita_altra": "Canzano",
      "denominazione_ita": "Canzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B640",
      "lat": "42.6471469",
      "lon": "13.8031094",
      "superficie_kmq": "16.7371",
      "codice_sovracomunale": "067"
    },
    {
      "sigla_provincia": "TE",
      "codice_istat": "067010",
      "denominazione_ita_altra": "Castel Castagna",
      "denominazione_ita": "Castel Castagna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C040",
      "lat": "42.5429135",
      "lon": "13.7170813",
      "superficie_kmq": "18.1590",
      "codice_sovracomunale": "067"
    },
    {
      "sigla_provincia": "TE",
      "codice_istat": "067011",
      "denominazione_ita_altra": "Castellalto",
      "denominazione_ita": "Castellalto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C128",
      "lat": "42.6753610",
      "lon": "13.8174612",
      "superficie_kmq": "34.1757",
      "codice_sovracomunale": "067"
    },
    {
      "sigla_provincia": "TE",
      "codice_istat": "067012",
      "denominazione_ita_altra": "Castelli",
      "denominazione_ita": "Castelli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C169",
      "lat": "42.4893732",
      "lon": "13.7117134",
      "superficie_kmq": "49.6808",
      "codice_sovracomunale": "067"
    },
    {
      "sigla_provincia": "TE",
      "codice_istat": "067013",
      "denominazione_ita_altra": "Castiglione Messer Raimondo",
      "denominazione_ita": "Castiglione Messer Raimondo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C316",
      "lat": "42.5313957",
      "lon": "13.8823331",
      "superficie_kmq": "30.6851",
      "codice_sovracomunale": "067"
    },
    {
      "sigla_provincia": "TE",
      "codice_istat": "067014",
      "denominazione_ita_altra": "Castilenti",
      "denominazione_ita": "Castilenti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C322",
      "lat": "42.5333277",
      "lon": "13.9177635",
      "superficie_kmq": "23.7942",
      "codice_sovracomunale": "067"
    },
    {
      "sigla_provincia": "TE",
      "codice_istat": "067015",
      "denominazione_ita_altra": "Cellino Attanasio",
      "denominazione_ita": "Cellino Attanasio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C449",
      "lat": "42.5862367",
      "lon": "13.8595194",
      "superficie_kmq": "43.9394",
      "codice_sovracomunale": "067"
    },
    {
      "sigla_provincia": "TE",
      "codice_istat": "067016",
      "denominazione_ita_altra": "Cermignano",
      "denominazione_ita": "Cermignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C517",
      "lat": "42.5885892",
      "lon": "13.7923730",
      "superficie_kmq": "26.3628",
      "codice_sovracomunale": "067"
    },
    {
      "sigla_provincia": "TE",
      "codice_istat": "067017",
      "denominazione_ita_altra": "Civitella del Tronto",
      "denominazione_ita": "Civitella del Tronto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C781",
      "lat": "42.7716760",
      "lon": "13.6654890",
      "superficie_kmq": "77.7369",
      "codice_sovracomunale": "067"
    },
    {
      "sigla_provincia": "TE",
      "codice_istat": "067018",
      "denominazione_ita_altra": "Colledara",
      "denominazione_ita": "Colledara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C311",
      "lat": "42.5396345",
      "lon": "13.6789197",
      "superficie_kmq": "18.0131",
      "codice_sovracomunale": "067"
    },
    {
      "sigla_provincia": "TE",
      "codice_istat": "067019",
      "denominazione_ita_altra": "Colonnella",
      "denominazione_ita": "Colonnella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C901",
      "lat": "42.8717356",
      "lon": "13.8675645",
      "superficie_kmq": "21.6331",
      "codice_sovracomunale": "067"
    },
    {
      "sigla_provincia": "TE",
      "codice_istat": "067020",
      "denominazione_ita_altra": "Controguerra",
      "denominazione_ita": "Controguerra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C972",
      "lat": "42.8556383",
      "lon": "13.8205665",
      "superficie_kmq": "22.8145",
      "codice_sovracomunale": "067"
    },
    {
      "sigla_provincia": "TE",
      "codice_istat": "067021",
      "denominazione_ita_altra": "Corropoli",
      "denominazione_ita": "Corropoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D043",
      "lat": "42.8275812",
      "lon": "13.8348580",
      "superficie_kmq": "22.1135",
      "codice_sovracomunale": "067"
    },
    {
      "sigla_provincia": "TE",
      "codice_istat": "067022",
      "denominazione_ita_altra": "Cortino",
      "denominazione_ita": "Cortino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D076",
      "lat": "42.6216578",
      "lon": "13.5077213",
      "superficie_kmq": "62.9429",
      "codice_sovracomunale": "067"
    },
    {
      "sigla_provincia": "TE",
      "codice_istat": "067023",
      "denominazione_ita_altra": "Crognaleto",
      "denominazione_ita": "Crognaleto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D179",
      "lat": "42.5476866",
      "lon": "13.4790529",
      "superficie_kmq": "124.3022",
      "codice_sovracomunale": "067"
    },
    {
      "sigla_provincia": "TE",
      "codice_istat": "067024",
      "denominazione_ita_altra": "Fano Adriano",
      "denominazione_ita": "Fano Adriano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D489",
      "lat": "42.5513058",
      "lon": "13.5370219",
      "superficie_kmq": "35.7722",
      "codice_sovracomunale": "067"
    },
    {
      "sigla_provincia": "TE",
      "codice_istat": "067025",
      "denominazione_ita_altra": "Giulianova",
      "denominazione_ita": "Giulianova",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E058",
      "lat": "42.7526246",
      "lon": "13.9567160",
      "superficie_kmq": "28.0024",
      "codice_sovracomunale": "067"
    },
    {
      "sigla_provincia": "TE",
      "codice_istat": "067026",
      "denominazione_ita_altra": "Isola del Gran Sasso d'Italia",
      "denominazione_ita": "Isola del Gran Sasso d'Italia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E343",
      "lat": "42.5025036",
      "lon": "13.6620108",
      "superficie_kmq": "84.0450",
      "codice_sovracomunale": "067"
    },
    {
      "sigla_provincia": "TE",
      "codice_istat": "067027",
      "denominazione_ita_altra": "Montefino",
      "denominazione_ita": "Montefino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F500",
      "lat": "42.5447770",
      "lon": "13.8844258",
      "superficie_kmq": "18.5937",
      "codice_sovracomunale": "067"
    },
    {
      "sigla_provincia": "TE",
      "codice_istat": "067028",
      "denominazione_ita_altra": "Montorio al Vomano",
      "denominazione_ita": "Montorio al Vomano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F690",
      "lat": "42.5830152",
      "lon": "13.6313176",
      "superficie_kmq": "53.5649",
      "codice_sovracomunale": "067"
    },
    {
      "sigla_provincia": "TE",
      "codice_istat": "067029",
      "denominazione_ita_altra": "Morro d'Oro",
      "denominazione_ita": "Morro d'Oro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F747",
      "lat": "42.6639959",
      "lon": "13.9196177",
      "superficie_kmq": "28.7269",
      "codice_sovracomunale": "067"
    },
    {
      "sigla_provincia": "TE",
      "codice_istat": "067030",
      "denominazione_ita_altra": "Mosciano Sant'Angelo",
      "denominazione_ita": "Mosciano Sant'Angelo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F764",
      "lat": "42.7499482",
      "lon": "13.8878877",
      "superficie_kmq": "48.4475",
      "codice_sovracomunale": "067"
    },
    {
      "sigla_provincia": "TE",
      "codice_istat": "067031",
      "denominazione_ita_altra": "Nereto",
      "denominazione_ita": "Nereto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F870",
      "lat": "42.8203104",
      "lon": "13.8179959",
      "superficie_kmq": "7.0112",
      "codice_sovracomunale": "067"
    },
    {
      "sigla_provincia": "TE",
      "codice_istat": "067032",
      "denominazione_ita_altra": "Notaresco",
      "denominazione_ita": "Notaresco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F942",
      "lat": "42.6589950",
      "lon": "13.8940413",
      "superficie_kmq": "38.1502",
      "codice_sovracomunale": "067"
    },
    {
      "sigla_provincia": "TE",
      "codice_istat": "067033",
      "denominazione_ita_altra": "Penna Sant'Andrea",
      "denominazione_ita": "Penna Sant'Andrea",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G437",
      "lat": "42.5935925",
      "lon": "13.7715035",
      "superficie_kmq": "11.0982",
      "codice_sovracomunale": "067"
    },
    {
      "sigla_provincia": "TE",
      "codice_istat": "067034",
      "denominazione_ita_altra": "Pietracamela",
      "denominazione_ita": "Pietracamela",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G608",
      "lat": "42.5231884",
      "lon": "13.5537565",
      "superficie_kmq": "44.4851",
      "codice_sovracomunale": "067"
    },
    {
      "sigla_provincia": "TE",
      "codice_istat": "067035",
      "denominazione_ita_altra": "Pineto",
      "denominazione_ita": "Pineto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F831",
      "lat": "42.6082438",
      "lon": "14.0674691",
      "superficie_kmq": "38.1092",
      "codice_sovracomunale": "067"
    },
    {
      "sigla_provincia": "TE",
      "codice_istat": "067036",
      "denominazione_ita_altra": "Rocca Santa Maria",
      "denominazione_ita": "Rocca Santa Maria",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H440",
      "lat": "42.6858073",
      "lon": "13.5268909",
      "superficie_kmq": "61.7982",
      "codice_sovracomunale": "067"
    },
    {
      "sigla_provincia": "TE",
      "codice_istat": "067037",
      "denominazione_ita_altra": "Roseto degli Abruzzi",
      "denominazione_ita": "Roseto degli Abruzzi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F585",
      "lat": "42.6801315",
      "lon": "14.0115523",
      "superficie_kmq": "53.2664",
      "codice_sovracomunale": "067"
    },
    {
      "sigla_provincia": "TE",
      "codice_istat": "067038",
      "denominazione_ita_altra": "Sant'Egidio alla Vibrata",
      "denominazione_ita": "Sant'Egidio alla Vibrata",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I318",
      "lat": "42.8254622",
      "lon": "13.7164884",
      "superficie_kmq": "18.3618",
      "codice_sovracomunale": "067"
    },
    {
      "sigla_provincia": "TE",
      "codice_istat": "067039",
      "denominazione_ita_altra": "Sant'Omero",
      "denominazione_ita": "Sant'Omero",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I348",
      "lat": "42.7866241",
      "lon": "13.8028931",
      "superficie_kmq": "34.2024",
      "codice_sovracomunale": "067"
    },
    {
      "sigla_provincia": "TE",
      "codice_istat": "067040",
      "denominazione_ita_altra": "Silvi",
      "denominazione_ita": "Silvi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I741",
      "lat": "42.5567719",
      "lon": "14.1139529",
      "superficie_kmq": "20.6259",
      "codice_sovracomunale": "067"
    },
    {
      "sigla_provincia": "TE",
      "codice_istat": "067041",
      "denominazione_ita_altra": "Teramo",
      "denominazione_ita": "Teramo",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "L103",
      "lat": "42.6588830",
      "lon": "13.7043972",
      "superficie_kmq": "152.8396",
      "codice_sovracomunale": "067"
    },
    {
      "sigla_provincia": "TE",
      "codice_istat": "067042",
      "denominazione_ita_altra": "Torano Nuovo",
      "denominazione_ita": "Torano Nuovo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L207",
      "lat": "42.8233426",
      "lon": "13.7770216",
      "superficie_kmq": "10.2171",
      "codice_sovracomunale": "067"
    },
    {
      "sigla_provincia": "TE",
      "codice_istat": "067043",
      "denominazione_ita_altra": "Torricella Sicura",
      "denominazione_ita": "Torricella Sicura",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L295",
      "lat": "42.6568717",
      "lon": "13.6550818",
      "superficie_kmq": "54.3803",
      "codice_sovracomunale": "067"
    },
    {
      "sigla_provincia": "TE",
      "codice_istat": "067044",
      "denominazione_ita_altra": "Tortoreto",
      "denominazione_ita": "Tortoreto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L307",
      "lat": "42.8039504",
      "lon": "13.9133303",
      "superficie_kmq": "22.9731",
      "codice_sovracomunale": "067"
    },
    {
      "sigla_provincia": "TE",
      "codice_istat": "067045",
      "denominazione_ita_altra": "Tossicia",
      "denominazione_ita": "Tossicia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L314",
      "lat": "42.5457196",
      "lon": "13.6488100",
      "superficie_kmq": "27.1359",
      "codice_sovracomunale": "067"
    },
    {
      "sigla_provincia": "TE",
      "codice_istat": "067046",
      "denominazione_ita_altra": "Valle Castellana",
      "denominazione_ita": "Valle Castellana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L597",
      "lat": "42.7353733",
      "lon": "13.4976240",
      "superficie_kmq": "131.7532",
      "codice_sovracomunale": "067"
    },
    {
      "sigla_provincia": "TE",
      "codice_istat": "067047",
      "denominazione_ita_altra": "Martinsicuro",
      "denominazione_ita": "Martinsicuro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E989",
      "lat": "42.8855260",
      "lon": "13.9157388",
      "superficie_kmq": "14.6579",
      "codice_sovracomunale": "067"
    },
    {
      "sigla_provincia": "PE",
      "codice_istat": "068001",
      "denominazione_ita_altra": "Abbateggio",
      "denominazione_ita": "Abbateggio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A008",
      "lat": "42.2244267",
      "lon": "14.0100696",
      "superficie_kmq": "15.4014",
      "codice_sovracomunale": "068"
    },
    {
      "sigla_provincia": "PE",
      "codice_istat": "068002",
      "denominazione_ita_altra": "Alanno",
      "denominazione_ita": "Alanno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A120",
      "lat": "42.2943931",
      "lon": "13.9693297",
      "superficie_kmq": "32.5275",
      "codice_sovracomunale": "068"
    },
    {
      "sigla_provincia": "PE",
      "codice_istat": "068003",
      "denominazione_ita_altra": "Bolognano",
      "denominazione_ita": "Bolognano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A945",
      "lat": "42.2169822",
      "lon": "13.9598168",
      "superficie_kmq": "16.9583",
      "codice_sovracomunale": "068"
    },
    {
      "sigla_provincia": "PE",
      "codice_istat": "068004",
      "denominazione_ita_altra": "Brittoli",
      "denominazione_ita": "Brittoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B193",
      "lat": "42.3164443",
      "lon": "13.8609338",
      "superficie_kmq": "15.9862",
      "codice_sovracomunale": "068"
    },
    {
      "sigla_provincia": "PE",
      "codice_istat": "068005",
      "denominazione_ita_altra": "Bussi sul Tirino",
      "denominazione_ita": "Bussi sul Tirino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B294",
      "lat": "42.2103621",
      "lon": "13.8266488",
      "superficie_kmq": "25.9116",
      "codice_sovracomunale": "068"
    },
    {
      "sigla_provincia": "PE",
      "codice_istat": "068006",
      "denominazione_ita_altra": "Cappelle sul Tavo",
      "denominazione_ita": "Cappelle sul Tavo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B681",
      "lat": "42.4651473",
      "lon": "14.1033154",
      "superficie_kmq": "5.4127",
      "codice_sovracomunale": "068"
    },
    {
      "sigla_provincia": "PE",
      "codice_istat": "068007",
      "denominazione_ita_altra": "Caramanico Terme",
      "denominazione_ita": "Caramanico Terme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B722",
      "lat": "42.1572011",
      "lon": "14.0031360",
      "superficie_kmq": "84.9893",
      "codice_sovracomunale": "068"
    },
    {
      "sigla_provincia": "PE",
      "codice_istat": "068008",
      "denominazione_ita_altra": "Carpineto della Nora",
      "denominazione_ita": "Carpineto della Nora",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B827",
      "lat": "42.3327677",
      "lon": "13.8593121",
      "superficie_kmq": "24.0819",
      "codice_sovracomunale": "068"
    },
    {
      "sigla_provincia": "PE",
      "codice_istat": "068009",
      "denominazione_ita_altra": "Castiglione a Casauria",
      "denominazione_ita": "Castiglione a Casauria",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C308",
      "lat": "42.2362379",
      "lon": "13.8939829",
      "superficie_kmq": "16.5745",
      "codice_sovracomunale": "068"
    },
    {
      "sigla_provincia": "PE",
      "codice_istat": "068010",
      "denominazione_ita_altra": "Catignano",
      "denominazione_ita": "Catignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C354",
      "lat": "42.3459960",
      "lon": "13.9497638",
      "superficie_kmq": "17.0253",
      "codice_sovracomunale": "068"
    },
    {
      "sigla_provincia": "PE",
      "codice_istat": "068011",
      "denominazione_ita_altra": "Cepagatti",
      "denominazione_ita": "Cepagatti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C474",
      "lat": "42.3645836",
      "lon": "14.0730392",
      "superficie_kmq": "30.8178",
      "codice_sovracomunale": "068"
    },
    {
      "sigla_provincia": "PE",
      "codice_istat": "068012",
      "denominazione_ita_altra": "Città Sant'Angelo",
      "denominazione_ita": "Città Sant'Angelo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C750",
      "lat": "42.5182870",
      "lon": "14.0586767",
      "superficie_kmq": "62.0216",
      "codice_sovracomunale": "068"
    },
    {
      "sigla_provincia": "PE",
      "codice_istat": "068013",
      "denominazione_ita_altra": "Civitaquana",
      "denominazione_ita": "Civitaquana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C771",
      "lat": "42.3261957",
      "lon": "13.8972791",
      "superficie_kmq": "21.8798",
      "codice_sovracomunale": "068"
    },
    {
      "sigla_provincia": "PE",
      "codice_istat": "068014",
      "denominazione_ita_altra": "Civitella Casanova",
      "denominazione_ita": "Civitella Casanova",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C779",
      "lat": "42.3646831",
      "lon": "13.8889420",
      "superficie_kmq": "31.0959",
      "codice_sovracomunale": "068"
    },
    {
      "sigla_provincia": "PE",
      "codice_istat": "068015",
      "denominazione_ita_altra": "Collecorvino",
      "denominazione_ita": "Collecorvino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C853",
      "lat": "42.4581866",
      "lon": "14.0143629",
      "superficie_kmq": "31.9847",
      "codice_sovracomunale": "068"
    },
    {
      "sigla_provincia": "PE",
      "codice_istat": "068016",
      "denominazione_ita_altra": "Corvara",
      "denominazione_ita": "Corvara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D078",
      "lat": "42.2770054",
      "lon": "13.8879140",
      "superficie_kmq": "13.7324",
      "codice_sovracomunale": "068"
    },
    {
      "sigla_provincia": "PE",
      "codice_istat": "068017",
      "denominazione_ita_altra": "Cugnoli",
      "denominazione_ita": "Cugnoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D201",
      "lat": "42.3081594",
      "lon": "13.9330609",
      "superficie_kmq": "15.9615",
      "codice_sovracomunale": "068"
    },
    {
      "sigla_provincia": "PE",
      "codice_istat": "068018",
      "denominazione_ita_altra": "Elice",
      "denominazione_ita": "Elice",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D394",
      "lat": "42.5186550",
      "lon": "13.9682652",
      "superficie_kmq": "14.3097",
      "codice_sovracomunale": "068"
    },
    {
      "sigla_provincia": "PE",
      "codice_istat": "068019",
      "denominazione_ita_altra": "Farindola",
      "denominazione_ita": "Farindola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D501",
      "lat": "42.4415180",
      "lon": "13.8219715",
      "superficie_kmq": "45.4721",
      "codice_sovracomunale": "068"
    },
    {
      "sigla_provincia": "PE",
      "codice_istat": "068020",
      "denominazione_ita_altra": "Lettomanoppello",
      "denominazione_ita": "Lettomanoppello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E558",
      "lat": "42.2368427",
      "lon": "14.0361838",
      "superficie_kmq": "15.0704",
      "codice_sovracomunale": "068"
    },
    {
      "sigla_provincia": "PE",
      "codice_istat": "068021",
      "denominazione_ita_altra": "Loreto Aprutino",
      "denominazione_ita": "Loreto Aprutino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E691",
      "lat": "42.4323077",
      "lon": "13.9874804",
      "superficie_kmq": "59.4993",
      "codice_sovracomunale": "068"
    },
    {
      "sigla_provincia": "PE",
      "codice_istat": "068022",
      "denominazione_ita_altra": "Manoppello",
      "denominazione_ita": "Manoppello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E892",
      "lat": "42.2610219",
      "lon": "14.0604501",
      "superficie_kmq": "39.2626",
      "codice_sovracomunale": "068"
    },
    {
      "sigla_provincia": "PE",
      "codice_istat": "068023",
      "denominazione_ita_altra": "Montebello di Bertona",
      "denominazione_ita": "Montebello di Bertona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F441",
      "lat": "42.4164794",
      "lon": "13.8696433",
      "superficie_kmq": "21.5012",
      "codice_sovracomunale": "068"
    },
    {
      "sigla_provincia": "PE",
      "codice_istat": "068024",
      "denominazione_ita_altra": "Montesilvano",
      "denominazione_ita": "Montesilvano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F646",
      "lat": "42.5140906",
      "lon": "14.1493799",
      "superficie_kmq": "23.5815",
      "codice_sovracomunale": "068"
    },
    {
      "sigla_provincia": "PE",
      "codice_istat": "068025",
      "denominazione_ita_altra": "Moscufo",
      "denominazione_ita": "Moscufo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F765",
      "lat": "42.4284743",
      "lon": "14.0550753",
      "superficie_kmq": "20.2564",
      "codice_sovracomunale": "068"
    },
    {
      "sigla_provincia": "PE",
      "codice_istat": "068026",
      "denominazione_ita_altra": "Nocciano",
      "denominazione_ita": "Nocciano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F908",
      "lat": "42.3339632",
      "lon": "13.9840921",
      "superficie_kmq": "13.7621",
      "codice_sovracomunale": "068"
    },
    {
      "sigla_provincia": "PE",
      "codice_istat": "068027",
      "denominazione_ita_altra": "Penne",
      "denominazione_ita": "Penne",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G438",
      "lat": "42.4580020",
      "lon": "13.9273822",
      "superficie_kmq": "91.1943",
      "codice_sovracomunale": "068"
    },
    {
      "sigla_provincia": "PE",
      "codice_istat": "068028",
      "denominazione_ita_altra": "Pescara",
      "denominazione_ita": "Pescara",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "G482",
      "lat": "42.4645468",
      "lon": "14.2136453",
      "superficie_kmq": "34.3332",
      "codice_sovracomunale": "068"
    },
    {
      "sigla_provincia": "PE",
      "codice_istat": "068029",
      "denominazione_ita_altra": "Pescosansonesco",
      "denominazione_ita": "Pescosansonesco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G499",
      "lat": "42.2540007",
      "lon": "13.8843688",
      "superficie_kmq": "18.3464",
      "codice_sovracomunale": "068"
    },
    {
      "sigla_provincia": "PE",
      "codice_istat": "068030",
      "denominazione_ita_altra": "Pianella",
      "denominazione_ita": "Pianella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G555",
      "lat": "42.3962724",
      "lon": "14.0460456",
      "superficie_kmq": "47.0484",
      "codice_sovracomunale": "068"
    },
    {
      "sigla_provincia": "PE",
      "codice_istat": "068031",
      "denominazione_ita_altra": "Picciano",
      "denominazione_ita": "Picciano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G589",
      "lat": "42.4747707",
      "lon": "13.9913434",
      "superficie_kmq": "7.5580",
      "codice_sovracomunale": "068"
    },
    {
      "sigla_provincia": "PE",
      "codice_istat": "068032",
      "denominazione_ita_altra": "Pietranico",
      "denominazione_ita": "Pietranico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G621",
      "lat": "42.2762008",
      "lon": "13.9108485",
      "superficie_kmq": "14.7710",
      "codice_sovracomunale": "068"
    },
    {
      "sigla_provincia": "PE",
      "codice_istat": "068033",
      "denominazione_ita_altra": "Popoli Terme",
      "denominazione_ita": "Popoli Terme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G878",
      "lat": "42.1711253",
      "lon": "13.8331088",
      "superficie_kmq": "35.0393",
      "codice_sovracomunale": "068"
    },
    {
      "sigla_provincia": "PE",
      "codice_istat": "068034",
      "denominazione_ita_altra": "Roccamorice",
      "denominazione_ita": "Roccamorice",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H425",
      "lat": "42.2137262",
      "lon": "14.0238749",
      "superficie_kmq": "25.0563",
      "codice_sovracomunale": "068"
    },
    {
      "sigla_provincia": "PE",
      "codice_istat": "068035",
      "denominazione_ita_altra": "Rosciano",
      "denominazione_ita": "Rosciano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H562",
      "lat": "42.3224375",
      "lon": "14.0471954",
      "superficie_kmq": "27.7943",
      "codice_sovracomunale": "068"
    },
    {
      "sigla_provincia": "PE",
      "codice_istat": "068036",
      "denominazione_ita_altra": "Salle",
      "denominazione_ita": "Salle",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H715",
      "lat": "42.1770001",
      "lon": "13.9592401",
      "superficie_kmq": "21.8034",
      "codice_sovracomunale": "068"
    },
    {
      "sigla_provincia": "PE",
      "codice_istat": "068037",
      "denominazione_ita_altra": "Sant'Eufemia a Maiella",
      "denominazione_ita": "Sant'Eufemia a Maiella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I332",
      "lat": "42.1253466",
      "lon": "14.0277033",
      "superficie_kmq": "40.4182",
      "codice_sovracomunale": "068"
    },
    {
      "sigla_provincia": "PE",
      "codice_istat": "068038",
      "denominazione_ita_altra": "San Valentino in Abruzzo Citeriore",
      "denominazione_ita": "San Valentino in Abruzzo Citeriore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I376",
      "lat": "42.2326625",
      "lon": "13.9872049",
      "superficie_kmq": "16.3973",
      "codice_sovracomunale": "068"
    },
    {
      "sigla_provincia": "PE",
      "codice_istat": "068039",
      "denominazione_ita_altra": "Scafa",
      "denominazione_ita": "Scafa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I482",
      "lat": "42.2678298",
      "lon": "14.0021288",
      "superficie_kmq": "10.3407",
      "codice_sovracomunale": "068"
    },
    {
      "sigla_provincia": "PE",
      "codice_istat": "068040",
      "denominazione_ita_altra": "Serramonacesca",
      "denominazione_ita": "Serramonacesca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I649",
      "lat": "42.2476750",
      "lon": "14.0936912",
      "superficie_kmq": "23.8874",
      "codice_sovracomunale": "068"
    },
    {
      "sigla_provincia": "PE",
      "codice_istat": "068041",
      "denominazione_ita_altra": "Spoltore",
      "denominazione_ita": "Spoltore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I922",
      "lat": "42.4556507",
      "lon": "14.1414797",
      "superficie_kmq": "37.0138",
      "codice_sovracomunale": "068"
    },
    {
      "sigla_provincia": "PE",
      "codice_istat": "068042",
      "denominazione_ita_altra": "Tocco da Casauria",
      "denominazione_ita": "Tocco da Casauria",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L186",
      "lat": "42.2178881",
      "lon": "13.9138433",
      "superficie_kmq": "29.6676",
      "codice_sovracomunale": "068"
    },
    {
      "sigla_provincia": "PE",
      "codice_istat": "068043",
      "denominazione_ita_altra": "Torre de' Passeri",
      "denominazione_ita": "Torre de' Passeri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L263",
      "lat": "42.2449205",
      "lon": "13.9278786",
      "superficie_kmq": "5.9177",
      "codice_sovracomunale": "068"
    },
    {
      "sigla_provincia": "PE",
      "codice_istat": "068044",
      "denominazione_ita_altra": "Turrivalignani",
      "denominazione_ita": "Turrivalignani",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L475",
      "lat": "42.2629155",
      "lon": "14.0286752",
      "superficie_kmq": "6.1122",
      "codice_sovracomunale": "068"
    },
    {
      "sigla_provincia": "PE",
      "codice_istat": "068045",
      "denominazione_ita_altra": "Vicoli",
      "denominazione_ita": "Vicoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L846",
      "lat": "42.3398101",
      "lon": "13.8951470",
      "superficie_kmq": "9.3297",
      "codice_sovracomunale": "068"
    },
    {
      "sigla_provincia": "PE",
      "codice_istat": "068046",
      "denominazione_ita_altra": "Villa Celiera",
      "denominazione_ita": "Villa Celiera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L922",
      "lat": "42.3806499",
      "lon": "13.8592004",
      "superficie_kmq": "13.1818",
      "codice_sovracomunale": "068"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069001",
      "denominazione_ita_altra": "Altino",
      "denominazione_ita": "Altino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A235",
      "lat": "42.1023784",
      "lon": "14.3326063",
      "superficie_kmq": "15.3302",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069002",
      "denominazione_ita_altra": "Archi",
      "denominazione_ita": "Archi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A367",
      "lat": "42.0890983",
      "lon": "14.3815706",
      "superficie_kmq": "28.5443",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069003",
      "denominazione_ita_altra": "Ari",
      "denominazione_ita": "Ari",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A398",
      "lat": "42.2910730",
      "lon": "14.2601333",
      "superficie_kmq": "11.3915",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069004",
      "denominazione_ita_altra": "Arielli",
      "denominazione_ita": "Arielli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A402",
      "lat": "42.2649570",
      "lon": "14.3047748",
      "superficie_kmq": "11.7167",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069005",
      "denominazione_ita_altra": "Atessa",
      "denominazione_ita": "Atessa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A485",
      "lat": "42.0673058",
      "lon": "14.4430823",
      "superficie_kmq": "110.9761",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069006",
      "denominazione_ita_altra": "Bomba",
      "denominazione_ita": "Bomba",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A956",
      "lat": "42.0351386",
      "lon": "14.3662294",
      "superficie_kmq": "17.2605",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069007",
      "denominazione_ita_altra": "Borrello",
      "denominazione_ita": "Borrello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B057",
      "lat": "41.9203649",
      "lon": "14.3032517",
      "superficie_kmq": "14.5091",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069008",
      "denominazione_ita_altra": "Bucchianico",
      "denominazione_ita": "Bucchianico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B238",
      "lat": "42.3043044",
      "lon": "14.1806999",
      "superficie_kmq": "38.0774",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069009",
      "denominazione_ita_altra": "Montebello sul Sangro",
      "denominazione_ita": "Montebello sul Sangro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B268",
      "lat": "41.9865887",
      "lon": "14.3250395",
      "superficie_kmq": "5.3819",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069010",
      "denominazione_ita_altra": "Canosa Sannita",
      "denominazione_ita": "Canosa Sannita",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B620",
      "lat": "42.2952492",
      "lon": "14.3018665",
      "superficie_kmq": "13.9124",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069011",
      "denominazione_ita_altra": "Carpineto Sinello",
      "denominazione_ita": "Carpineto Sinello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B826",
      "lat": "42.0135921",
      "lon": "14.5077708",
      "superficie_kmq": "29.8539",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069012",
      "denominazione_ita_altra": "Carunchio",
      "denominazione_ita": "Carunchio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B853",
      "lat": "41.9169387",
      "lon": "14.5267067",
      "superficie_kmq": "32.5642",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069013",
      "denominazione_ita_altra": "Casacanditella",
      "denominazione_ita": "Casacanditella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B859",
      "lat": "42.2482837",
      "lon": "14.1988308",
      "superficie_kmq": "12.5381",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069014",
      "denominazione_ita_altra": "Casalanguida",
      "denominazione_ita": "Casalanguida",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B861",
      "lat": "42.0372257",
      "lon": "14.4963925",
      "superficie_kmq": "13.6687",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069015",
      "denominazione_ita_altra": "Casalbordino",
      "denominazione_ita": "Casalbordino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B865",
      "lat": "42.1506812",
      "lon": "14.5848814",
      "superficie_kmq": "46.0149",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069016",
      "denominazione_ita_altra": "Casalincontrada",
      "denominazione_ita": "Casalincontrada",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B896",
      "lat": "42.2862068",
      "lon": "14.1318594",
      "superficie_kmq": "16.0004",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069017",
      "denominazione_ita_altra": "Casoli",
      "denominazione_ita": "Casoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B985",
      "lat": "42.1150004",
      "lon": "14.2904356",
      "superficie_kmq": "67.0350",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069018",
      "denominazione_ita_altra": "Castel Frentano",
      "denominazione_ita": "Castel Frentano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C114",
      "lat": "42.1961824",
      "lon": "14.3566145",
      "superficie_kmq": "21.8861",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069019",
      "denominazione_ita_altra": "Castelguidone",
      "denominazione_ita": "Castelguidone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C123",
      "lat": "41.8234443",
      "lon": "14.5229895",
      "superficie_kmq": "15.0721",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069020",
      "denominazione_ita_altra": "Castiglione Messer Marino",
      "denominazione_ita": "Castiglione Messer Marino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C298",
      "lat": "41.8668900",
      "lon": "14.4516977",
      "superficie_kmq": "47.9829",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069021",
      "denominazione_ita_altra": "Celenza sul Trigno",
      "denominazione_ita": "Celenza sul Trigno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C428",
      "lat": "41.8717275",
      "lon": "14.5811304",
      "superficie_kmq": "22.6778",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069022",
      "denominazione_ita_altra": "Chieti",
      "denominazione_ita": "Chieti",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "C632",
      "lat": "42.3509928",
      "lon": "14.1675427",
      "superficie_kmq": "59.5668",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069023",
      "denominazione_ita_altra": "Civitaluparella",
      "denominazione_ita": "Civitaluparella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C768",
      "lat": "41.9447948",
      "lon": "14.3030472",
      "superficie_kmq": "22.4613",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069024",
      "denominazione_ita_altra": "Civitella Messer Raimondo",
      "denominazione_ita": "Civitella Messer Raimondo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C776",
      "lat": "42.0878301",
      "lon": "14.2155518",
      "superficie_kmq": "12.7214",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069025",
      "denominazione_ita_altra": "Colledimacine",
      "denominazione_ita": "Colledimacine",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C855",
      "lat": "42.0045636",
      "lon": "14.2015878",
      "superficie_kmq": "11.2952",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069026",
      "denominazione_ita_altra": "Colledimezzo",
      "denominazione_ita": "Colledimezzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C856",
      "lat": "41.9856522",
      "lon": "14.3810692",
      "superficie_kmq": "11.0465",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069027",
      "denominazione_ita_altra": "Crecchio",
      "denominazione_ita": "Crecchio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D137",
      "lat": "42.2985327",
      "lon": "14.3267537",
      "superficie_kmq": "19.2341",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069028",
      "denominazione_ita_altra": "Cupello",
      "denominazione_ita": "Cupello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D209",
      "lat": "42.0722024",
      "lon": "14.6708961",
      "superficie_kmq": "48.3922",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069029",
      "denominazione_ita_altra": "Dogliola",
      "denominazione_ita": "Dogliola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D315",
      "lat": "41.9418729",
      "lon": "14.6364665",
      "superficie_kmq": "11.8476",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069030",
      "denominazione_ita_altra": "Fara Filiorum Petri",
      "denominazione_ita": "Fara Filiorum Petri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D494",
      "lat": "42.2489322",
      "lon": "14.1861169",
      "superficie_kmq": "14.9639",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069031",
      "denominazione_ita_altra": "Fara San Martino",
      "denominazione_ita": "Fara San Martino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D495",
      "lat": "42.0902051",
      "lon": "14.2054452",
      "superficie_kmq": "44.6920",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069032",
      "denominazione_ita_altra": "Filetto",
      "denominazione_ita": "Filetto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D592",
      "lat": "42.2258918",
      "lon": "14.2442852",
      "superficie_kmq": "13.5328",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069033",
      "denominazione_ita_altra": "Fossacesia",
      "denominazione_ita": "Fossacesia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D738",
      "lat": "42.2453383",
      "lon": "14.4800572",
      "superficie_kmq": "30.1407",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069034",
      "denominazione_ita_altra": "Fraine",
      "denominazione_ita": "Fraine",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D757",
      "lat": "41.9047109",
      "lon": "14.4869377",
      "superficie_kmq": "16.0895",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069035",
      "denominazione_ita_altra": "Francavilla al Mare",
      "denominazione_ita": "Francavilla al Mare",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D763",
      "lat": "42.4176287",
      "lon": "14.2918139",
      "superficie_kmq": "23.1125",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069036",
      "denominazione_ita_altra": "Fresagrandinaria",
      "denominazione_ita": "Fresagrandinaria",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D796",
      "lat": "41.9755349",
      "lon": "14.6631571",
      "superficie_kmq": "25.1486",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069037",
      "denominazione_ita_altra": "Frisa",
      "denominazione_ita": "Frisa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D803",
      "lat": "42.2609281",
      "lon": "14.3706172",
      "superficie_kmq": "11.4882",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069038",
      "denominazione_ita_altra": "Furci",
      "denominazione_ita": "Furci",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D823",
      "lat": "42.0084484",
      "lon": "14.5865437",
      "superficie_kmq": "25.9896",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069039",
      "denominazione_ita_altra": "Gamberale",
      "denominazione_ita": "Gamberale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D898",
      "lat": "41.9054241",
      "lon": "14.2075345",
      "superficie_kmq": "15.5604",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069040",
      "denominazione_ita_altra": "Gessopalena",
      "denominazione_ita": "Gessopalena",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D996",
      "lat": "42.0563059",
      "lon": "14.2737486",
      "superficie_kmq": "31.4701",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069041",
      "denominazione_ita_altra": "Gissi",
      "denominazione_ita": "Gissi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E052",
      "lat": "42.0207749",
      "lon": "14.5465259",
      "superficie_kmq": "36.6462",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069042",
      "denominazione_ita_altra": "Giuliano Teatino",
      "denominazione_ita": "Giuliano Teatino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E056",
      "lat": "42.2931027",
      "lon": "14.2754313",
      "superficie_kmq": "9.8874",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069043",
      "denominazione_ita_altra": "Guardiagrele",
      "denominazione_ita": "Guardiagrele",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E243",
      "lat": "42.1911000",
      "lon": "14.2202034",
      "superficie_kmq": "56.5009",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069044",
      "denominazione_ita_altra": "Guilmi",
      "denominazione_ita": "Guilmi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E266",
      "lat": "41.9969909",
      "lon": "14.4759707",
      "superficie_kmq": "12.5631",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069045",
      "denominazione_ita_altra": "Lama dei Peligni",
      "denominazione_ita": "Lama dei Peligni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E424",
      "lat": "42.0424409",
      "lon": "14.1887597",
      "superficie_kmq": "31.3710",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069046",
      "denominazione_ita_altra": "Lanciano",
      "denominazione_ita": "Lanciano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E435",
      "lat": "42.2312935",
      "lon": "14.3904040",
      "superficie_kmq": "66.9494",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069047",
      "denominazione_ita_altra": "Lentella",
      "denominazione_ita": "Lentella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E531",
      "lat": "41.9971620",
      "lon": "14.6765382",
      "superficie_kmq": "12.6165",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069048",
      "denominazione_ita_altra": "Lettopalena",
      "denominazione_ita": "Lettopalena",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E559",
      "lat": "42.0030239",
      "lon": "14.1580959",
      "superficie_kmq": "21.1285",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069049",
      "denominazione_ita_altra": "Liscia",
      "denominazione_ita": "Liscia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E611",
      "lat": "41.9554173",
      "lon": "14.5550092",
      "superficie_kmq": "8.1795",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069050",
      "denominazione_ita_altra": "Miglianico",
      "denominazione_ita": "Miglianico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F196",
      "lat": "42.3594706",
      "lon": "14.2915348",
      "superficie_kmq": "22.7343",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069051",
      "denominazione_ita_altra": "Montazzoli",
      "denominazione_ita": "Montazzoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F433",
      "lat": "41.9481414",
      "lon": "14.4293575",
      "superficie_kmq": "39.4634",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069052",
      "denominazione_ita_altra": "Monteferrante",
      "denominazione_ita": "Monteferrante",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F498",
      "lat": "41.9545246",
      "lon": "14.3881874",
      "superficie_kmq": "15.2862",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069053",
      "denominazione_ita_altra": "Montelapiano",
      "denominazione_ita": "Montelapiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F535",
      "lat": "41.9612370",
      "lon": "14.3423427",
      "superficie_kmq": "8.2680",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069054",
      "denominazione_ita_altra": "Montenerodomo",
      "denominazione_ita": "Montenerodomo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F578",
      "lat": "41.9765579",
      "lon": "14.2517992",
      "superficie_kmq": "29.9986",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069055",
      "denominazione_ita_altra": "Monteodorisio",
      "denominazione_ita": "Monteodorisio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F582",
      "lat": "42.0880354",
      "lon": "14.6527305",
      "superficie_kmq": "25.2122",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069056",
      "denominazione_ita_altra": "Mozzagrogna",
      "denominazione_ita": "Mozzagrogna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F785",
      "lat": "42.2127898",
      "lon": "14.4446756",
      "superficie_kmq": "14.1032",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069057",
      "denominazione_ita_altra": "Orsogna",
      "denominazione_ita": "Orsogna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G128",
      "lat": "42.2186311",
      "lon": "14.2820962",
      "superficie_kmq": "25.4460",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069058",
      "denominazione_ita_altra": "Ortona",
      "denominazione_ita": "Ortona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G141",
      "lat": "42.3555991",
      "lon": "14.4034735",
      "superficie_kmq": "70.8737",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069059",
      "denominazione_ita_altra": "Paglieta",
      "denominazione_ita": "Paglieta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G237",
      "lat": "42.1626658",
      "lon": "14.4985142",
      "superficie_kmq": "33.7797",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069060",
      "denominazione_ita_altra": "Palena",
      "denominazione_ita": "Palena",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G271",
      "lat": "41.9834042",
      "lon": "14.1377704",
      "superficie_kmq": "93.6299",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069061",
      "denominazione_ita_altra": "Palmoli",
      "denominazione_ita": "Palmoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G290",
      "lat": "41.9387238",
      "lon": "14.5807263",
      "superficie_kmq": "32.7780",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069062",
      "denominazione_ita_altra": "Palombaro",
      "denominazione_ita": "Palombaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G294",
      "lat": "42.1240954",
      "lon": "14.2299536",
      "superficie_kmq": "17.1930",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069063",
      "denominazione_ita_altra": "Pennadomo",
      "denominazione_ita": "Pennadomo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G434",
      "lat": "42.0044275",
      "lon": "14.3232572",
      "superficie_kmq": "11.0200",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069064",
      "denominazione_ita_altra": "Pennapiedimonte",
      "denominazione_ita": "Pennapiedimonte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G435",
      "lat": "42.1519408",
      "lon": "14.1949153",
      "superficie_kmq": "47.0312",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069065",
      "denominazione_ita_altra": "Perano",
      "denominazione_ita": "Perano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G441",
      "lat": "42.1077969",
      "lon": "14.3964966",
      "superficie_kmq": "6.4826",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069066",
      "denominazione_ita_altra": "Pizzoferrato",
      "denominazione_ita": "Pizzoferrato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G724",
      "lat": "41.9222311",
      "lon": "14.2372650",
      "superficie_kmq": "30.9206",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069067",
      "denominazione_ita_altra": "Poggiofiorito",
      "denominazione_ita": "Poggiofiorito",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G760",
      "lat": "42.2540659",
      "lon": "14.3212784",
      "superficie_kmq": "9.9473",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069068",
      "denominazione_ita_altra": "Pollutri",
      "denominazione_ita": "Pollutri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G799",
      "lat": "42.1372603",
      "lon": "14.5941240",
      "superficie_kmq": "26.1680",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069069",
      "denominazione_ita_altra": "Pretoro",
      "denominazione_ita": "Pretoro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H052",
      "lat": "42.2167421",
      "lon": "14.1425008",
      "superficie_kmq": "26.1259",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069070",
      "denominazione_ita_altra": "Quadri",
      "denominazione_ita": "Quadri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H098",
      "lat": "41.9257718",
      "lon": "14.2883738",
      "superficie_kmq": "7.4453",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069071",
      "denominazione_ita_altra": "Rapino",
      "denominazione_ita": "Rapino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H184",
      "lat": "42.2116638",
      "lon": "14.1875866",
      "superficie_kmq": "20.2985",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069072",
      "denominazione_ita_altra": "Ripa Teatina",
      "denominazione_ita": "Ripa Teatina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H320",
      "lat": "42.3574421",
      "lon": "14.2336515",
      "superficie_kmq": "20.1629",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069073",
      "denominazione_ita_altra": "Roccamontepiano",
      "denominazione_ita": "Roccamontepiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H424",
      "lat": "42.2475996",
      "lon": "14.1218687",
      "superficie_kmq": "18.2198",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069074",
      "denominazione_ita_altra": "Rocca San Giovanni",
      "denominazione_ita": "Rocca San Giovanni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H439",
      "lat": "42.2510638",
      "lon": "14.4649481",
      "superficie_kmq": "21.6995",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069075",
      "denominazione_ita_altra": "Roccascalegna",
      "denominazione_ita": "Roccascalegna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H442",
      "lat": "42.0621075",
      "lon": "14.3066987",
      "superficie_kmq": "23.0060",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069076",
      "denominazione_ita_altra": "Roccaspinalveti",
      "denominazione_ita": "Roccaspinalveti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H448",
      "lat": "41.9380335",
      "lon": "14.4712046",
      "superficie_kmq": "33.0066",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069077",
      "denominazione_ita_altra": "Roio del Sangro",
      "denominazione_ita": "Roio del Sangro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H495",
      "lat": "41.9115927",
      "lon": "14.3728022",
      "superficie_kmq": "11.8107",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069078",
      "denominazione_ita_altra": "Rosello",
      "denominazione_ita": "Rosello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H566",
      "lat": "41.9015275",
      "lon": "14.3490005",
      "superficie_kmq": "19.2305",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069079",
      "denominazione_ita_altra": "San Buono",
      "denominazione_ita": "San Buono",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H784",
      "lat": "41.9805744",
      "lon": "14.5693434",
      "superficie_kmq": "25.2693",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069080",
      "denominazione_ita_altra": "San Giovanni Lipioni",
      "denominazione_ita": "San Giovanni Lipioni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H923",
      "lat": "41.8427396",
      "lon": "14.5619809",
      "superficie_kmq": "8.6722",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069081",
      "denominazione_ita_altra": "San Giovanni Teatino",
      "denominazione_ita": "San Giovanni Teatino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D690",
      "lat": "42.4219390",
      "lon": "14.1867475",
      "superficie_kmq": "17.7311",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069082",
      "denominazione_ita_altra": "San Martino sulla Marrucina",
      "denominazione_ita": "San Martino sulla Marrucina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H991",
      "lat": "42.2250016",
      "lon": "14.2141216",
      "superficie_kmq": "7.4080",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069083",
      "denominazione_ita_altra": "San Salvo",
      "denominazione_ita": "San Salvo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I148",
      "lat": "42.0455131",
      "lon": "14.7314022",
      "superficie_kmq": "19.6996",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069084",
      "denominazione_ita_altra": "Santa Maria Imbaro",
      "denominazione_ita": "Santa Maria Imbaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I244",
      "lat": "42.2199023",
      "lon": "14.4470662",
      "superficie_kmq": "5.7079",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069085",
      "denominazione_ita_altra": "Sant'Eusanio del Sangro",
      "denominazione_ita": "Sant'Eusanio del Sangro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I335",
      "lat": "42.1681739",
      "lon": "14.3319803",
      "superficie_kmq": "23.8273",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069086",
      "denominazione_ita_altra": "San Vito Chietino",
      "denominazione_ita": "San Vito Chietino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I394",
      "lat": "42.3025662",
      "lon": "14.4457125",
      "superficie_kmq": "17.0004",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069087",
      "denominazione_ita_altra": "Scerni",
      "denominazione_ita": "Scerni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I520",
      "lat": "42.1117248",
      "lon": "14.5690771",
      "superficie_kmq": "41.2545",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069088",
      "denominazione_ita_altra": "Schiavi di Abruzzo",
      "denominazione_ita": "Schiavi di Abruzzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I526",
      "lat": "41.8156607",
      "lon": "14.4853918",
      "superficie_kmq": "45.5688",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069089",
      "denominazione_ita_altra": "Taranta Peligna",
      "denominazione_ita": "Taranta Peligna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L047",
      "lat": "42.0198390",
      "lon": "14.1691205",
      "superficie_kmq": "21.8984",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069090",
      "denominazione_ita_altra": "Tollo",
      "denominazione_ita": "Tollo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L194",
      "lat": "42.3394573",
      "lon": "14.3177348",
      "superficie_kmq": "14.9591",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069091",
      "denominazione_ita_altra": "Torino di Sangro",
      "denominazione_ita": "Torino di Sangro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L218",
      "lat": "42.1880782",
      "lon": "14.5408890",
      "superficie_kmq": "32.1175",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069092",
      "denominazione_ita_altra": "Tornareccio",
      "denominazione_ita": "Tornareccio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L224",
      "lat": "42.0363357",
      "lon": "14.4128082",
      "superficie_kmq": "27.5279",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069093",
      "denominazione_ita_altra": "Torrebruna",
      "denominazione_ita": "Torrebruna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L253",
      "lat": "41.8679569",
      "lon": "14.5411417",
      "superficie_kmq": "23.2887",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069094",
      "denominazione_ita_altra": "Torrevecchia Teatina",
      "denominazione_ita": "Torrevecchia Teatina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L284",
      "lat": "42.3794956",
      "lon": "14.2060680",
      "superficie_kmq": "14.6762",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069095",
      "denominazione_ita_altra": "Torricella Peligna",
      "denominazione_ita": "Torricella Peligna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L291",
      "lat": "42.0217834",
      "lon": "14.2611116",
      "superficie_kmq": "36.1143",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069096",
      "denominazione_ita_altra": "Treglio",
      "denominazione_ita": "Treglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L363",
      "lat": "42.2673045",
      "lon": "14.4248693",
      "superficie_kmq": "4.8695",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069097",
      "denominazione_ita_altra": "Tufillo",
      "denominazione_ita": "Tufillo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L459",
      "lat": "41.9162846",
      "lon": "14.6263523",
      "superficie_kmq": "21.4377",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069098",
      "denominazione_ita_altra": "Vacri",
      "denominazione_ita": "Vacri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L526",
      "lat": "42.2977362",
      "lon": "14.2327846",
      "superficie_kmq": "12.2727",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069099",
      "denominazione_ita_altra": "Vasto",
      "denominazione_ita": "Vasto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E372",
      "lat": "42.1121688",
      "lon": "14.7079160",
      "superficie_kmq": "71.3449",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069100",
      "denominazione_ita_altra": "Villalfonsina",
      "denominazione_ita": "Villalfonsina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L961",
      "lat": "42.1647613",
      "lon": "14.5740857",
      "superficie_kmq": "9.1285",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069101",
      "denominazione_ita_altra": "Villamagna",
      "denominazione_ita": "Villamagna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L964",
      "lat": "42.3331925",
      "lon": "14.2367289",
      "superficie_kmq": "12.7324",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069102",
      "denominazione_ita_altra": "Villa Santa Maria",
      "denominazione_ita": "Villa Santa Maria",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M022",
      "lat": "41.9511636",
      "lon": "14.3506496",
      "superficie_kmq": "16.2298",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069103",
      "denominazione_ita_altra": "Pietraferrazzana",
      "denominazione_ita": "Pietraferrazzana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G613",
      "lat": "41.9695864",
      "lon": "14.3737067",
      "superficie_kmq": "4.3672",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CH",
      "codice_istat": "069104",
      "denominazione_ita_altra": "Fallo",
      "denominazione_ita": "Fallo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D480",
      "lat": "41.9389239",
      "lon": "14.3223857",
      "superficie_kmq": "6.0991",
      "codice_sovracomunale": "069"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070001",
      "denominazione_ita_altra": "Acquaviva Collecroce",
      "denominazione_ita": "Acquaviva Collecroce",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A050",
      "lat": "41.8678554",
      "lon": "14.7467318",
      "superficie_kmq": "28.5993",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070002",
      "denominazione_ita_altra": "Baranello",
      "denominazione_ita": "Baranello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A616",
      "lat": "41.5267691",
      "lon": "14.5605145",
      "superficie_kmq": "24.9947",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070003",
      "denominazione_ita_altra": "Bojano",
      "denominazione_ita": "Bojano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A930",
      "lat": "41.4827718",
      "lon": "14.4714323",
      "superficie_kmq": "52.6298",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070004",
      "denominazione_ita_altra": "Bonefro",
      "denominazione_ita": "Bonefro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A971",
      "lat": "41.7026469",
      "lon": "14.9341919",
      "superficie_kmq": "31.2796",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070005",
      "denominazione_ita_altra": "Busso",
      "denominazione_ita": "Busso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B295",
      "lat": "41.5547371",
      "lon": "14.5620990",
      "superficie_kmq": "23.8102",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070006",
      "denominazione_ita_altra": "Campobasso",
      "denominazione_ita": "Campobasso",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "B519",
      "lat": "41.5576976",
      "lon": "14.6591565",
      "superficie_kmq": "56.1117",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070007",
      "denominazione_ita_altra": "Campochiaro",
      "denominazione_ita": "Campochiaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B522",
      "lat": "41.4486460",
      "lon": "14.5056370",
      "superficie_kmq": "35.7010",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070008",
      "denominazione_ita_altra": "Campodipietra",
      "denominazione_ita": "Campodipietra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B528",
      "lat": "41.5575437",
      "lon": "14.7469254",
      "superficie_kmq": "19.7217",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070009",
      "denominazione_ita_altra": "Campolieto",
      "denominazione_ita": "Campolieto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B544",
      "lat": "41.6351235",
      "lon": "14.7669469",
      "superficie_kmq": "24.4253",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070010",
      "denominazione_ita_altra": "Campomarino",
      "denominazione_ita": "Campomarino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B550",
      "lat": "41.9526660",
      "lon": "15.0358544",
      "superficie_kmq": "76.6760",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070011",
      "denominazione_ita_altra": "Casacalenda",
      "denominazione_ita": "Casacalenda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B858",
      "lat": "41.7395088",
      "lon": "14.8471289",
      "superficie_kmq": "67.2764",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070012",
      "denominazione_ita_altra": "Casalciprano",
      "denominazione_ita": "Casalciprano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B871",
      "lat": "41.5816249",
      "lon": "14.5287206",
      "superficie_kmq": "19.0649",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070013",
      "denominazione_ita_altra": "Castelbottaccio",
      "denominazione_ita": "Castelbottaccio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C066",
      "lat": "41.7529217",
      "lon": "14.7071045",
      "superficie_kmq": "11.2235",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070014",
      "denominazione_ita_altra": "Castellino del Biferno",
      "denominazione_ita": "Castellino del Biferno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C175",
      "lat": "41.7021564",
      "lon": "14.7313777",
      "superficie_kmq": "15.5442",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070015",
      "denominazione_ita_altra": "Castelmauro",
      "denominazione_ita": "Castelmauro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C197",
      "lat": "41.8293580",
      "lon": "14.7104208",
      "superficie_kmq": "43.6196",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070016",
      "denominazione_ita_altra": "Castropignano",
      "denominazione_ita": "Castropignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C346",
      "lat": "41.6197696",
      "lon": "14.5616963",
      "superficie_kmq": "26.9572",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070017",
      "denominazione_ita_altra": "Cercemaggiore",
      "denominazione_ita": "Cercemaggiore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C486",
      "lat": "41.4602558",
      "lon": "14.7220906",
      "superficie_kmq": "56.9107",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070018",
      "denominazione_ita_altra": "Cercepiccola",
      "denominazione_ita": "Cercepiccola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C488",
      "lat": "41.4592684",
      "lon": "14.6680374",
      "superficie_kmq": "16.7935",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070019",
      "denominazione_ita_altra": "Civitacampomarano",
      "denominazione_ita": "Civitacampomarano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C764",
      "lat": "41.7806281",
      "lon": "14.6904205",
      "superficie_kmq": "38.8933",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070020",
      "denominazione_ita_altra": "Colle d'Anchise",
      "denominazione_ita": "Colle d'Anchise",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C854",
      "lat": "41.5090732",
      "lon": "14.5183406",
      "superficie_kmq": "15.6946",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070021",
      "denominazione_ita_altra": "Colletorto",
      "denominazione_ita": "Colletorto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C875",
      "lat": "41.6631347",
      "lon": "14.9729380",
      "superficie_kmq": "35.9050",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070022",
      "denominazione_ita_altra": "Duronia",
      "denominazione_ita": "Duronia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C772",
      "lat": "41.6582496",
      "lon": "14.4588185",
      "superficie_kmq": "22.4681",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070023",
      "denominazione_ita_altra": "Ferrazzano",
      "denominazione_ita": "Ferrazzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D550",
      "lat": "41.5306140",
      "lon": "14.6709416",
      "superficie_kmq": "16.7727",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070024",
      "denominazione_ita_altra": "Fossalto",
      "denominazione_ita": "Fossalto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D737",
      "lat": "41.6731407",
      "lon": "14.5451905",
      "superficie_kmq": "28.3337",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070025",
      "denominazione_ita_altra": "Gambatesa",
      "denominazione_ita": "Gambatesa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D896",
      "lat": "41.5092171",
      "lon": "14.9140274",
      "superficie_kmq": "43.6825",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070026",
      "denominazione_ita_altra": "Gildone",
      "denominazione_ita": "Gildone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E030",
      "lat": "41.5094829",
      "lon": "14.7402300",
      "superficie_kmq": "29.7548",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070027",
      "denominazione_ita_altra": "Guardialfiera",
      "denominazione_ita": "Guardialfiera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E244",
      "lat": "41.8024605",
      "lon": "14.7932980",
      "superficie_kmq": "43.5261",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070028",
      "denominazione_ita_altra": "Guardiaregia",
      "denominazione_ita": "Guardiaregia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E248",
      "lat": "41.4351566",
      "lon": "14.5413728",
      "superficie_kmq": "43.7156",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070029",
      "denominazione_ita_altra": "Guglionesi",
      "denominazione_ita": "Guglionesi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E259",
      "lat": "41.9106511",
      "lon": "14.9139320",
      "superficie_kmq": "100.9461",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070030",
      "denominazione_ita_altra": "Jelsi",
      "denominazione_ita": "Jelsi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E381",
      "lat": "41.5183636",
      "lon": "14.7960911",
      "superficie_kmq": "28.7729",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070031",
      "denominazione_ita_altra": "Larino",
      "denominazione_ita": "Larino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E456",
      "lat": "41.8001534",
      "lon": "14.9107311",
      "superficie_kmq": "88.7652",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070032",
      "denominazione_ita_altra": "Limosano",
      "denominazione_ita": "Limosano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E599",
      "lat": "41.6765840",
      "lon": "14.6211674",
      "superficie_kmq": "28.2662",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070033",
      "denominazione_ita_altra": "Lucito",
      "denominazione_ita": "Lucito",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E722",
      "lat": "41.7317552",
      "lon": "14.6882159",
      "superficie_kmq": "31.5628",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070034",
      "denominazione_ita_altra": "Lupara",
      "denominazione_ita": "Lupara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E748",
      "lat": "41.7620938",
      "lon": "14.7332277",
      "superficie_kmq": "25.8724",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070035",
      "denominazione_ita_altra": "Macchia Valfortore",
      "denominazione_ita": "Macchia Valfortore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E780",
      "lat": "41.5932855",
      "lon": "14.9112110",
      "superficie_kmq": "26.7323",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070036",
      "denominazione_ita_altra": "Mafalda",
      "denominazione_ita": "Mafalda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E799",
      "lat": "41.9414617",
      "lon": "14.7125297",
      "superficie_kmq": "32.5053",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070037",
      "denominazione_ita_altra": "Matrice",
      "denominazione_ita": "Matrice",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F055",
      "lat": "41.6123547",
      "lon": "14.7109973",
      "superficie_kmq": "20.4211",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070038",
      "denominazione_ita_altra": "Mirabello Sannitico",
      "denominazione_ita": "Mirabello Sannitico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F233",
      "lat": "41.5152361",
      "lon": "14.6720715",
      "superficie_kmq": "21.4272",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070039",
      "denominazione_ita_altra": "Molise",
      "denominazione_ita": "Molise",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F294",
      "lat": "41.6305278",
      "lon": "14.4938125",
      "superficie_kmq": "5.1999",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070040",
      "denominazione_ita_altra": "Monacilioni",
      "denominazione_ita": "Monacilioni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F322",
      "lat": "41.6103851",
      "lon": "14.8104488",
      "superficie_kmq": "27.2140",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070041",
      "denominazione_ita_altra": "Montagano",
      "denominazione_ita": "Montagano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F391",
      "lat": "41.6463045",
      "lon": "14.6737615",
      "superficie_kmq": "26.6236",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070042",
      "denominazione_ita_altra": "Montecilfone",
      "denominazione_ita": "Montecilfone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F475",
      "lat": "41.9018544",
      "lon": "14.8376021",
      "superficie_kmq": "22.9190",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070043",
      "denominazione_ita_altra": "Montefalcone nel Sannio",
      "denominazione_ita": "Montefalcone nel Sannio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F495",
      "lat": "41.8648293",
      "lon": "14.6389102",
      "superficie_kmq": "32.5674",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070044",
      "denominazione_ita_altra": "Montelongo",
      "denominazione_ita": "Montelongo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F548",
      "lat": "41.7357892",
      "lon": "14.9486685",
      "superficie_kmq": "12.7631",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070045",
      "denominazione_ita_altra": "Montemitro",
      "denominazione_ita": "Montemitro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F569",
      "lat": "41.8879811",
      "lon": "14.6467532",
      "superficie_kmq": "16.2928",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070046",
      "denominazione_ita_altra": "Montenero di Bisaccia",
      "denominazione_ita": "Montenero di Bisaccia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F576",
      "lat": "41.9572034",
      "lon": "14.7812101",
      "superficie_kmq": "93.3125",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070047",
      "denominazione_ita_altra": "Montorio nei Frentani",
      "denominazione_ita": "Montorio nei Frentani",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F689",
      "lat": "41.7588973",
      "lon": "14.9322451",
      "superficie_kmq": "31.6629",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070048",
      "denominazione_ita_altra": "Morrone del Sannio",
      "denominazione_ita": "Morrone del Sannio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F748",
      "lat": "41.7121107",
      "lon": "14.7782522",
      "superficie_kmq": "45.8400",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070049",
      "denominazione_ita_altra": "Oratino",
      "denominazione_ita": "Oratino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G086",
      "lat": "41.5859930",
      "lon": "14.5845195",
      "superficie_kmq": "17.8849",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070050",
      "denominazione_ita_altra": "Palata",
      "denominazione_ita": "Palata",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G257",
      "lat": "41.8884308",
      "lon": "14.7867324",
      "superficie_kmq": "43.8179",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070051",
      "denominazione_ita_altra": "Petacciato",
      "denominazione_ita": "Petacciato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G506",
      "lat": "42.0134093",
      "lon": "14.8600995",
      "superficie_kmq": "35.4006",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070052",
      "denominazione_ita_altra": "Petrella Tifernina",
      "denominazione_ita": "Petrella Tifernina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G512",
      "lat": "41.6912845",
      "lon": "14.6949256",
      "superficie_kmq": "26.5154",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070053",
      "denominazione_ita_altra": "Pietracatella",
      "denominazione_ita": "Pietracatella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G609",
      "lat": "41.5815189",
      "lon": "14.8734490",
      "superficie_kmq": "50.2809",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070054",
      "denominazione_ita_altra": "Pietracupa",
      "denominazione_ita": "Pietracupa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G610",
      "lat": "41.6822424",
      "lon": "14.5211499",
      "superficie_kmq": "10.0789",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070055",
      "denominazione_ita_altra": "Portocannone",
      "denominazione_ita": "Portocannone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G910",
      "lat": "41.9142126",
      "lon": "15.0121922",
      "superficie_kmq": "13.1136",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070056",
      "denominazione_ita_altra": "Provvidenti",
      "denominazione_ita": "Provvidenti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H083",
      "lat": "41.7185150",
      "lon": "14.8223558",
      "superficie_kmq": "14.0324",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070057",
      "denominazione_ita_altra": "Riccia",
      "denominazione_ita": "Riccia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H273",
      "lat": "41.4864949",
      "lon": "14.8363775",
      "superficie_kmq": "70.0393",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070058",
      "denominazione_ita_altra": "Ripabottoni",
      "denominazione_ita": "Ripabottoni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H311",
      "lat": "41.6884988",
      "lon": "14.8065279",
      "superficie_kmq": "31.9604",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070059",
      "denominazione_ita_altra": "Ripalimosani",
      "denominazione_ita": "Ripalimosani",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H313",
      "lat": "41.6112131",
      "lon": "14.6627963",
      "superficie_kmq": "33.8270",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070060",
      "denominazione_ita_altra": "Roccavivara",
      "denominazione_ita": "Roccavivara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H454",
      "lat": "41.8347871",
      "lon": "14.5992665",
      "superficie_kmq": "21.0439",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070061",
      "denominazione_ita_altra": "Rotello",
      "denominazione_ita": "Rotello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H589",
      "lat": "41.7488629",
      "lon": "15.0052365",
      "superficie_kmq": "70.7399",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070062",
      "denominazione_ita_altra": "Salcito",
      "denominazione_ita": "Salcito",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H693",
      "lat": "41.7473116",
      "lon": "14.5108161",
      "superficie_kmq": "28.2595",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070063",
      "denominazione_ita_altra": "San Biase",
      "denominazione_ita": "San Biase",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H782",
      "lat": "41.7148862",
      "lon": "14.5894337",
      "superficie_kmq": "11.8460",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070064",
      "denominazione_ita_altra": "San Felice del Molise",
      "denominazione_ita": "San Felice del Molise",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H833",
      "lat": "41.8907528",
      "lon": "14.7006592",
      "superficie_kmq": "24.3719",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070065",
      "denominazione_ita_altra": "San Giacomo degli Schiavoni",
      "denominazione_ita": "San Giacomo degli Schiavoni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H867",
      "lat": "41.9648852",
      "lon": "14.9476197",
      "superficie_kmq": "11.0822",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070066",
      "denominazione_ita_altra": "San Giovanni in Galdo",
      "denominazione_ita": "San Giovanni in Galdo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H920",
      "lat": "41.5883459",
      "lon": "14.7475311",
      "superficie_kmq": "19.4484",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070067",
      "denominazione_ita_altra": "San Giuliano del Sannio",
      "denominazione_ita": "San Giuliano del Sannio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H928",
      "lat": "41.4560750",
      "lon": "14.6396348",
      "superficie_kmq": "24.0508",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070068",
      "denominazione_ita_altra": "San Giuliano di Puglia",
      "denominazione_ita": "San Giuliano di Puglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H929",
      "lat": "41.6852102",
      "lon": "14.9636816",
      "superficie_kmq": "42.0477",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070069",
      "denominazione_ita_altra": "San Martino in Pensilis",
      "denominazione_ita": "San Martino in Pensilis",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H990",
      "lat": "41.8699899",
      "lon": "15.0105734",
      "superficie_kmq": "100.6520",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070070",
      "denominazione_ita_altra": "San Massimo",
      "denominazione_ita": "San Massimo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I023",
      "lat": "41.4926145",
      "lon": "14.4098098",
      "superficie_kmq": "27.3299",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070071",
      "denominazione_ita_altra": "San Polo Matese",
      "denominazione_ita": "San Polo Matese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I122",
      "lat": "41.4593127",
      "lon": "14.4937516",
      "superficie_kmq": "15.2841",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070072",
      "denominazione_ita_altra": "Santa Croce di Magliano",
      "denominazione_ita": "Santa Croce di Magliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I181",
      "lat": "41.7124764",
      "lon": "14.9911230",
      "superficie_kmq": "53.3670",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070073",
      "denominazione_ita_altra": "Sant'Angelo Limosano",
      "denominazione_ita": "Sant'Angelo Limosano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I289",
      "lat": "41.6927416",
      "lon": "14.6037322",
      "superficie_kmq": "16.8688",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070074",
      "denominazione_ita_altra": "Sant'Elia a Pianisi",
      "denominazione_ita": "Sant'Elia a Pianisi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I320",
      "lat": "41.6217487",
      "lon": "14.8753229",
      "superficie_kmq": "68.2452",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070075",
      "denominazione_ita_altra": "Sepino",
      "denominazione_ita": "Sepino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I618",
      "lat": "41.4070643",
      "lon": "14.6190289",
      "superficie_kmq": "61.3707",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070076",
      "denominazione_ita_altra": "Spinete",
      "denominazione_ita": "Spinete",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I910",
      "lat": "41.5433275",
      "lon": "14.4875470",
      "superficie_kmq": "17.8338",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070077",
      "denominazione_ita_altra": "Tavenna",
      "denominazione_ita": "Tavenna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L069",
      "lat": "41.9085922",
      "lon": "14.7632698",
      "superficie_kmq": "21.9697",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070078",
      "denominazione_ita_altra": "Termoli",
      "denominazione_ita": "Termoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L113",
      "lat": "42.0026667",
      "lon": "14.9955399",
      "superficie_kmq": "55.6345",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070079",
      "denominazione_ita_altra": "Torella del Sannio",
      "denominazione_ita": "Torella del Sannio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L215",
      "lat": "41.6403869",
      "lon": "14.5215555",
      "superficie_kmq": "16.7316",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070080",
      "denominazione_ita_altra": "Toro",
      "denominazione_ita": "Toro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L230",
      "lat": "41.5738860",
      "lon": "14.7659476",
      "superficie_kmq": "24.0582",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070081",
      "denominazione_ita_altra": "Trivento",
      "denominazione_ita": "Trivento",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L435",
      "lat": "41.7840487",
      "lon": "14.5509630",
      "superficie_kmq": "73.6983",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070082",
      "denominazione_ita_altra": "Tufara",
      "denominazione_ita": "Tufara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L458",
      "lat": "41.4817967",
      "lon": "14.9457791",
      "superficie_kmq": "35.5163",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070083",
      "denominazione_ita_altra": "Ururi",
      "denominazione_ita": "Ururi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L505",
      "lat": "41.8151323",
      "lon": "15.0118251",
      "superficie_kmq": "31.6494",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "CB",
      "codice_istat": "070084",
      "denominazione_ita_altra": "Vinchiaturo",
      "denominazione_ita": "Vinchiaturo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M057",
      "lat": "41.4932478",
      "lon": "14.5865607",
      "superficie_kmq": "35.4776",
      "codice_sovracomunale": "070"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071001",
      "denominazione_ita_altra": "Accadia",
      "denominazione_ita": "Accadia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A015",
      "lat": "41.1580611",
      "lon": "15.3309179",
      "superficie_kmq": "30.7359",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071002",
      "denominazione_ita_altra": "Alberona",
      "denominazione_ita": "Alberona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A150",
      "lat": "41.4304278",
      "lon": "15.1242734",
      "superficie_kmq": "49.7469",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071003",
      "denominazione_ita_altra": "Anzano di Puglia",
      "denominazione_ita": "Anzano di Puglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A320",
      "lat": "41.1195118",
      "lon": "15.2903355",
      "superficie_kmq": "11.0140",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071004",
      "denominazione_ita_altra": "Apricena",
      "denominazione_ita": "Apricena",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A339",
      "lat": "41.7853566",
      "lon": "15.4432314",
      "superficie_kmq": "172.5024",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071005",
      "denominazione_ita_altra": "Ascoli Satriano",
      "denominazione_ita": "Ascoli Satriano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A463",
      "lat": "41.2080919",
      "lon": "15.5602605",
      "superficie_kmq": "336.6715",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071006",
      "denominazione_ita_altra": "Biccari",
      "denominazione_ita": "Biccari",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A854",
      "lat": "41.3973843",
      "lon": "15.1944914",
      "superficie_kmq": "106.6444",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071007",
      "denominazione_ita_altra": "Bovino",
      "denominazione_ita": "Bovino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B104",
      "lat": "41.2491830",
      "lon": "15.3416657",
      "superficie_kmq": "84.9297",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071008",
      "denominazione_ita_altra": "Cagnano Varano",
      "denominazione_ita": "Cagnano Varano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B357",
      "lat": "41.8286390",
      "lon": "15.7725962",
      "superficie_kmq": "166.8365",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071009",
      "denominazione_ita_altra": "Candela",
      "denominazione_ita": "Candela",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B584",
      "lat": "41.1376789",
      "lon": "15.5139530",
      "superficie_kmq": "96.8096",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071010",
      "denominazione_ita_altra": "Carapelle",
      "denominazione_ita": "Carapelle",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B724",
      "lat": "41.3627837",
      "lon": "15.6937028",
      "superficie_kmq": "25.0017",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071011",
      "denominazione_ita_altra": "Carlantino",
      "denominazione_ita": "Carlantino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B784",
      "lat": "41.5922375",
      "lon": "14.9766298",
      "superficie_kmq": "34.7114",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071012",
      "denominazione_ita_altra": "Carpino",
      "denominazione_ita": "Carpino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B829",
      "lat": "41.8445865",
      "lon": "15.8556274",
      "superficie_kmq": "80.0494",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071013",
      "denominazione_ita_altra": "Casalnuovo Monterotaro",
      "denominazione_ita": "Casalnuovo Monterotaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B904",
      "lat": "41.6183352",
      "lon": "15.1023961",
      "superficie_kmq": "48.3611",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071014",
      "denominazione_ita_altra": "Casalvecchio di Puglia",
      "denominazione_ita": "Casalvecchio di Puglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B917",
      "lat": "41.5957368",
      "lon": "15.1109433",
      "superficie_kmq": "31.9321",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071015",
      "denominazione_ita_altra": "Castelluccio dei Sauri",
      "denominazione_ita": "Castelluccio dei Sauri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C198",
      "lat": "41.3040446",
      "lon": "15.4739453",
      "superficie_kmq": "51.4736",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071016",
      "denominazione_ita_altra": "Castelluccio Valmaggiore",
      "denominazione_ita": "Castelluccio Valmaggiore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C202",
      "lat": "41.3430572",
      "lon": "15.2033214",
      "superficie_kmq": "26.7854",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071017",
      "denominazione_ita_altra": "Castelnuovo della Daunia",
      "denominazione_ita": "Castelnuovo della Daunia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C222",
      "lat": "41.5826316",
      "lon": "15.1183460",
      "superficie_kmq": "61.4861",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071018",
      "denominazione_ita_altra": "Celenza Valfortore",
      "denominazione_ita": "Celenza Valfortore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C429",
      "lat": "41.5620774",
      "lon": "14.9775086",
      "superficie_kmq": "65.4171",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071019",
      "denominazione_ita_altra": "Celle di San Vito",
      "denominazione_ita": "Celle di San Vito",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C442",
      "lat": "41.3250143",
      "lon": "15.1812112",
      "superficie_kmq": "18.4132",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071020",
      "denominazione_ita_altra": "Cerignola",
      "denominazione_ita": "Cerignola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C514",
      "lat": "41.2651688",
      "lon": "15.9036778",
      "superficie_kmq": "593.9196",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071021",
      "denominazione_ita_altra": "Chieuti",
      "denominazione_ita": "Chieuti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C633",
      "lat": "41.8444392",
      "lon": "15.1654297",
      "superficie_kmq": "61.5173",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071022",
      "denominazione_ita_altra": "Deliceto",
      "denominazione_ita": "Deliceto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D269",
      "lat": "41.2226217",
      "lon": "15.3873261",
      "superficie_kmq": "75.8521",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071023",
      "denominazione_ita_altra": "Faeto",
      "denominazione_ita": "Faeto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D459",
      "lat": "41.3231893",
      "lon": "15.1612185",
      "superficie_kmq": "26.0981",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071024",
      "denominazione_ita_altra": "Foggia",
      "denominazione_ita": "Foggia",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "D643",
      "lat": "41.4622181",
      "lon": "15.5430467",
      "superficie_kmq": "509.2545",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071025",
      "denominazione_ita_altra": "Ischitella",
      "denominazione_ita": "Ischitella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E332",
      "lat": "41.9042648",
      "lon": "15.8997351",
      "superficie_kmq": "85.4608",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071026",
      "denominazione_ita_altra": "Isole Tremiti",
      "denominazione_ita": "Isole Tremiti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E363",
      "lat": "42.1203819",
      "lon": "15.5043679",
      "superficie_kmq": "3.1780",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071027",
      "denominazione_ita_altra": "Lesina",
      "denominazione_ita": "Lesina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E549",
      "lat": "41.8633429",
      "lon": "15.3550520",
      "superficie_kmq": "160.1534",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071028",
      "denominazione_ita_altra": "Lucera",
      "denominazione_ita": "Lucera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E716",
      "lat": "41.5060976",
      "lon": "15.3329562",
      "superficie_kmq": "339.7870",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071029",
      "denominazione_ita_altra": "Manfredonia",
      "denominazione_ita": "Manfredonia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E885",
      "lat": "41.6284205",
      "lon": "15.9192065",
      "superficie_kmq": "354.5377",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071031",
      "denominazione_ita_altra": "Mattinata",
      "denominazione_ita": "Mattinata",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F059",
      "lat": "41.7104875",
      "lon": "16.0514381",
      "superficie_kmq": "73.4796",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071032",
      "denominazione_ita_altra": "Monteleone di Puglia",
      "denominazione_ita": "Monteleone di Puglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F538",
      "lat": "41.1647307",
      "lon": "15.2600108",
      "superficie_kmq": "36.4107",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071033",
      "denominazione_ita_altra": "Monte Sant'Angelo",
      "denominazione_ita": "Monte Sant'Angelo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F631",
      "lat": "41.7061308",
      "lon": "15.9581266",
      "superficie_kmq": "245.1273",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071034",
      "denominazione_ita_altra": "Motta Montecorvino",
      "denominazione_ita": "Motta Montecorvino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F777",
      "lat": "41.5072912",
      "lon": "15.1133777",
      "superficie_kmq": "19.9412",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071035",
      "denominazione_ita_altra": "Orsara di Puglia",
      "denominazione_ita": "Orsara di Puglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G125",
      "lat": "41.2810953",
      "lon": "15.2658031",
      "superficie_kmq": "83.0056",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071036",
      "denominazione_ita_altra": "Orta Nova",
      "denominazione_ita": "Orta Nova",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G131",
      "lat": "41.3260038",
      "lon": "15.7072583",
      "superficie_kmq": "105.2381",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071037",
      "denominazione_ita_altra": "Panni",
      "denominazione_ita": "Panni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G312",
      "lat": "41.2202028",
      "lon": "15.2770715",
      "superficie_kmq": "32.7043",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071038",
      "denominazione_ita_altra": "Peschici",
      "denominazione_ita": "Peschici",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G487",
      "lat": "41.9465613",
      "lon": "16.0157166",
      "superficie_kmq": "49.3895",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071039",
      "denominazione_ita_altra": "Pietramontecorvino",
      "denominazione_ita": "Pietramontecorvino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G604",
      "lat": "41.5430837",
      "lon": "15.1297753",
      "superficie_kmq": "71.6453",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071040",
      "denominazione_ita_altra": "Poggio Imperiale",
      "denominazione_ita": "Poggio Imperiale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G761",
      "lat": "41.8218525",
      "lon": "15.3658548",
      "superficie_kmq": "52.8824",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071041",
      "denominazione_ita_altra": "Rignano Garganico",
      "denominazione_ita": "Rignano Garganico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H287",
      "lat": "41.6771272",
      "lon": "15.5878412",
      "superficie_kmq": "89.3968",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071042",
      "denominazione_ita_altra": "Rocchetta Sant'Antonio",
      "denominazione_ita": "Rocchetta Sant'Antonio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H467",
      "lat": "41.1027351",
      "lon": "15.4598754",
      "superficie_kmq": "72.4654",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071043",
      "denominazione_ita_altra": "Rodi Garganico",
      "denominazione_ita": "Rodi Garganico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H480",
      "lat": "41.9280933",
      "lon": "15.8812217",
      "superficie_kmq": "13.4471",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071044",
      "denominazione_ita_altra": "Roseto Valfortore",
      "denominazione_ita": "Roseto Valfortore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H568",
      "lat": "41.3706750",
      "lon": "15.0948482",
      "superficie_kmq": "50.0521",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071046",
      "denominazione_ita_altra": "San Giovanni Rotondo",
      "denominazione_ita": "San Giovanni Rotondo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H926",
      "lat": "41.7077655",
      "lon": "15.7266326",
      "superficie_kmq": "261.8788",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071047",
      "denominazione_ita_altra": "San Marco in Lamis",
      "denominazione_ita": "San Marco in Lamis",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H985",
      "lat": "41.7129159",
      "lon": "15.6384244",
      "superficie_kmq": "234.1973",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071048",
      "denominazione_ita_altra": "San Marco la Catola",
      "denominazione_ita": "San Marco la Catola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H986",
      "lat": "41.5256497",
      "lon": "15.0063118",
      "superficie_kmq": "28.6249",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071049",
      "denominazione_ita_altra": "San Nicandro Garganico",
      "denominazione_ita": "San Nicandro Garganico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I054",
      "lat": "41.8388608",
      "lon": "15.5612719",
      "superficie_kmq": "173.3574",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071050",
      "denominazione_ita_altra": "San Paolo di Civitate",
      "denominazione_ita": "San Paolo di Civitate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I072",
      "lat": "41.7407072",
      "lon": "15.2588983",
      "superficie_kmq": "91.1604",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071051",
      "denominazione_ita_altra": "San Severo",
      "denominazione_ita": "San Severo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I158",
      "lat": "41.6858260",
      "lon": "15.3807336",
      "superficie_kmq": "336.3046",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071052",
      "denominazione_ita_altra": "Sant'Agata di Puglia",
      "denominazione_ita": "Sant'Agata di Puglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I193",
      "lat": "41.1502642",
      "lon": "15.3793818",
      "superficie_kmq": "116.1326",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071053",
      "denominazione_ita_altra": "Serracapriola",
      "denominazione_ita": "Serracapriola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I641",
      "lat": "41.8100356",
      "lon": "15.1619425",
      "superficie_kmq": "143.3541",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071054",
      "denominazione_ita_altra": "Stornara",
      "denominazione_ita": "Stornara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I962",
      "lat": "41.2881118",
      "lon": "15.7678000",
      "superficie_kmq": "33.8629",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071055",
      "denominazione_ita_altra": "Stornarella",
      "denominazione_ita": "Stornarella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I963",
      "lat": "41.2563280",
      "lon": "15.7302150",
      "superficie_kmq": "33.8122",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071056",
      "denominazione_ita_altra": "Torremaggiore",
      "denominazione_ita": "Torremaggiore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L273",
      "lat": "41.6904389",
      "lon": "15.2869663",
      "superficie_kmq": "210.0084",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071058",
      "denominazione_ita_altra": "Troia",
      "denominazione_ita": "Troia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L447",
      "lat": "41.3616215",
      "lon": "15.3081049",
      "superficie_kmq": "168.2450",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071059",
      "denominazione_ita_altra": "Vico del Gargano",
      "denominazione_ita": "Vico del Gargano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L842",
      "lat": "41.8968272",
      "lon": "15.9555138",
      "superficie_kmq": "111.0822",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071060",
      "denominazione_ita_altra": "Vieste",
      "denominazione_ita": "Vieste",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L858",
      "lat": "41.8815708",
      "lon": "16.1806268",
      "superficie_kmq": "169.1885",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071061",
      "denominazione_ita_altra": "Volturara Appula",
      "denominazione_ita": "Volturara Appula",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M131",
      "lat": "41.4949590",
      "lon": "15.0517552",
      "superficie_kmq": "51.9970",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071062",
      "denominazione_ita_altra": "Volturino",
      "denominazione_ita": "Volturino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M132",
      "lat": "41.4781303",
      "lon": "15.1243759",
      "superficie_kmq": "58.3471",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071063",
      "denominazione_ita_altra": "Ordona",
      "denominazione_ita": "Ordona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M266",
      "lat": "41.3153185",
      "lon": "15.6285583",
      "superficie_kmq": "39.5651",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "FG",
      "codice_istat": "071064",
      "denominazione_ita_altra": "Zapponeta",
      "denominazione_ita": "Zapponeta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M267",
      "lat": "41.4557661",
      "lon": "15.9552241",
      "superficie_kmq": "41.7458",
      "codice_sovracomunale": "071"
    },
    {
      "sigla_provincia": "BA",
      "codice_istat": "072001",
      "denominazione_ita_altra": "Acquaviva delle Fonti",
      "denominazione_ita": "Acquaviva delle Fonti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A048",
      "lat": "40.8965155",
      "lon": "16.8418845",
      "superficie_kmq": "132.0242",
      "codice_sovracomunale": "272"
    },
    {
      "sigla_provincia": "BA",
      "codice_istat": "072002",
      "denominazione_ita_altra": "Adelfia",
      "denominazione_ita": "Adelfia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A055",
      "lat": "41.0017774",
      "lon": "16.8719924",
      "superficie_kmq": "29.8139",
      "codice_sovracomunale": "272"
    },
    {
      "sigla_provincia": "BA",
      "codice_istat": "072003",
      "denominazione_ita_altra": "Alberobello",
      "denominazione_ita": "Alberobello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A149",
      "lat": "40.7836718",
      "lon": "17.2375277",
      "superficie_kmq": "40.8232",
      "codice_sovracomunale": "272"
    },
    {
      "sigla_provincia": "BA",
      "codice_istat": "072004",
      "denominazione_ita_altra": "Altamura",
      "denominazione_ita": "Altamura",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A225",
      "lat": "40.8278859",
      "lon": "16.5523058",
      "superficie_kmq": "431.3700",
      "codice_sovracomunale": "272"
    },
    {
      "sigla_provincia": "BA",
      "codice_istat": "072006",
      "denominazione_ita_altra": "Bari",
      "denominazione_ita": "Bari",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "A662",
      "lat": "41.1255417",
      "lon": "16.8673637",
      "superficie_kmq": "117.4097",
      "codice_sovracomunale": "272"
    },
    {
      "sigla_provincia": "BA",
      "codice_istat": "072008",
      "denominazione_ita_altra": "Binetto",
      "denominazione_ita": "Binetto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A874",
      "lat": "41.0241425",
      "lon": "16.7084529",
      "superficie_kmq": "17.6537",
      "codice_sovracomunale": "272"
    },
    {
      "sigla_provincia": "BA",
      "codice_istat": "072010",
      "denominazione_ita_altra": "Bitetto",
      "denominazione_ita": "Bitetto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A892",
      "lat": "41.0386282",
      "lon": "16.7478433",
      "superficie_kmq": "33.9452",
      "codice_sovracomunale": "272"
    },
    {
      "sigla_provincia": "BA",
      "codice_istat": "072011",
      "denominazione_ita_altra": "Bitonto",
      "denominazione_ita": "Bitonto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A893",
      "lat": "41.1099638",
      "lon": "16.6943126",
      "superficie_kmq": "174.3365",
      "codice_sovracomunale": "272"
    },
    {
      "sigla_provincia": "BA",
      "codice_istat": "072012",
      "denominazione_ita_altra": "Bitritto",
      "denominazione_ita": "Bitritto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A894",
      "lat": "41.0410131",
      "lon": "16.8257974",
      "superficie_kmq": "17.9815",
      "codice_sovracomunale": "272"
    },
    {
      "sigla_provincia": "BA",
      "codice_istat": "072014",
      "denominazione_ita_altra": "Capurso",
      "denominazione_ita": "Capurso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B716",
      "lat": "41.0478326",
      "lon": "16.9202023",
      "superficie_kmq": "15.1052",
      "codice_sovracomunale": "272"
    },
    {
      "sigla_provincia": "BA",
      "codice_istat": "072015",
      "denominazione_ita_altra": "Casamassima",
      "denominazione_ita": "Casamassima",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B923",
      "lat": "40.9553229",
      "lon": "16.9180617",
      "superficie_kmq": "78.4318",
      "codice_sovracomunale": "272"
    },
    {
      "sigla_provincia": "BA",
      "codice_istat": "072016",
      "denominazione_ita_altra": "Cassano delle Murge",
      "denominazione_ita": "Cassano delle Murge",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B998",
      "lat": "40.8896686",
      "lon": "16.7717385",
      "superficie_kmq": "90.1974",
      "codice_sovracomunale": "272"
    },
    {
      "sigla_provincia": "BA",
      "codice_istat": "072017",
      "denominazione_ita_altra": "Castellana Grotte",
      "denominazione_ita": "Castellana Grotte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C134",
      "lat": "40.8824429",
      "lon": "17.1697784",
      "superficie_kmq": "69.1282",
      "codice_sovracomunale": "272"
    },
    {
      "sigla_provincia": "BA",
      "codice_istat": "072018",
      "denominazione_ita_altra": "Cellamare",
      "denominazione_ita": "Cellamare",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C436",
      "lat": "41.0183678",
      "lon": "16.9290598",
      "superficie_kmq": "5.9108",
      "codice_sovracomunale": "272"
    },
    {
      "sigla_provincia": "BA",
      "codice_istat": "072019",
      "denominazione_ita_altra": "Conversano",
      "denominazione_ita": "Conversano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C975",
      "lat": "40.9660330",
      "lon": "17.1152363",
      "superficie_kmq": "128.4204",
      "codice_sovracomunale": "272"
    },
    {
      "sigla_provincia": "BA",
      "codice_istat": "072020",
      "denominazione_ita_altra": "Corato",
      "denominazione_ita": "Corato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C983",
      "lat": "41.1537442",
      "lon": "16.4132590",
      "superficie_kmq": "169.2783",
      "codice_sovracomunale": "272"
    },
    {
      "sigla_provincia": "BA",
      "codice_istat": "072021",
      "denominazione_ita_altra": "Gioia del Colle",
      "denominazione_ita": "Gioia del Colle",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E038",
      "lat": "40.8006362",
      "lon": "16.9240607",
      "superficie_kmq": "208.9351",
      "codice_sovracomunale": "272"
    },
    {
      "sigla_provincia": "BA",
      "codice_istat": "072022",
      "denominazione_ita_altra": "Giovinazzo",
      "denominazione_ita": "Giovinazzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E047",
      "lat": "41.1880532",
      "lon": "16.6726122",
      "superficie_kmq": "44.2824",
      "codice_sovracomunale": "272"
    },
    {
      "sigla_provincia": "BA",
      "codice_istat": "072023",
      "denominazione_ita_altra": "Gravina in Puglia",
      "denominazione_ita": "Gravina in Puglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E155",
      "lat": "40.8193479",
      "lon": "16.4162001",
      "superficie_kmq": "384.7214",
      "codice_sovracomunale": "272"
    },
    {
      "sigla_provincia": "BA",
      "codice_istat": "072024",
      "denominazione_ita_altra": "Grumo Appula",
      "denominazione_ita": "Grumo Appula",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E223",
      "lat": "41.0121585",
      "lon": "16.7106429",
      "superficie_kmq": "81.3018",
      "codice_sovracomunale": "272"
    },
    {
      "sigla_provincia": "BA",
      "codice_istat": "072025",
      "denominazione_ita_altra": "Locorotondo",
      "denominazione_ita": "Locorotondo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E645",
      "lat": "40.7523180",
      "lon": "17.3266172",
      "superficie_kmq": "48.1791",
      "codice_sovracomunale": "272"
    },
    {
      "sigla_provincia": "BA",
      "codice_istat": "072027",
      "denominazione_ita_altra": "Modugno",
      "denominazione_ita": "Modugno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F262",
      "lat": "41.0826457",
      "lon": "16.7802637",
      "superficie_kmq": "32.2364",
      "codice_sovracomunale": "272"
    },
    {
      "sigla_provincia": "BA",
      "codice_istat": "072028",
      "denominazione_ita_altra": "Mola di Bari",
      "denominazione_ita": "Mola di Bari",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F280",
      "lat": "41.0608725",
      "lon": "17.0817107",
      "superficie_kmq": "50.9399",
      "codice_sovracomunale": "272"
    },
    {
      "sigla_provincia": "BA",
      "codice_istat": "072029",
      "denominazione_ita_altra": "Molfetta",
      "denominazione_ita": "Molfetta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F284",
      "lat": "41.2049775",
      "lon": "16.6006262",
      "superficie_kmq": "58.9733",
      "codice_sovracomunale": "272"
    },
    {
      "sigla_provincia": "BA",
      "codice_istat": "072030",
      "denominazione_ita_altra": "Monopoli",
      "denominazione_ita": "Monopoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F376",
      "lat": "40.9525248",
      "lon": "17.3020957",
      "superficie_kmq": "157.8253",
      "codice_sovracomunale": "272"
    },
    {
      "sigla_provincia": "BA",
      "codice_istat": "072031",
      "denominazione_ita_altra": "Noci",
      "denominazione_ita": "Noci",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F915",
      "lat": "40.7947634",
      "lon": "17.1224725",
      "superficie_kmq": "150.5967",
      "codice_sovracomunale": "272"
    },
    {
      "sigla_provincia": "BA",
      "codice_istat": "072032",
      "denominazione_ita_altra": "Noicattaro",
      "denominazione_ita": "Noicattaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F923",
      "lat": "41.0338456",
      "lon": "16.9848296",
      "superficie_kmq": "40.7925",
      "codice_sovracomunale": "272"
    },
    {
      "sigla_provincia": "BA",
      "codice_istat": "072033",
      "denominazione_ita_altra": "Palo del Colle",
      "denominazione_ita": "Palo del Colle",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G291",
      "lat": "41.0565928",
      "lon": "16.7040083",
      "superficie_kmq": "79.7126",
      "codice_sovracomunale": "272"
    },
    {
      "sigla_provincia": "BA",
      "codice_istat": "072034",
      "denominazione_ita_altra": "Poggiorsini",
      "denominazione_ita": "Poggiorsini",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G769",
      "lat": "40.9148823",
      "lon": "16.2524418",
      "superficie_kmq": "43.4436",
      "codice_sovracomunale": "272"
    },
    {
      "sigla_provincia": "BA",
      "codice_istat": "072035",
      "denominazione_ita_altra": "Polignano a Mare",
      "denominazione_ita": "Polignano a Mare",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G787",
      "lat": "40.9921019",
      "lon": "17.2191270",
      "superficie_kmq": "63.0865",
      "codice_sovracomunale": "272"
    },
    {
      "sigla_provincia": "BA",
      "codice_istat": "072036",
      "denominazione_ita_altra": "Putignano",
      "denominazione_ita": "Putignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H096",
      "lat": "40.8495821",
      "lon": "17.1190936",
      "superficie_kmq": "100.1539",
      "codice_sovracomunale": "272"
    },
    {
      "sigla_provincia": "BA",
      "codice_istat": "072037",
      "denominazione_ita_altra": "Rutigliano",
      "denominazione_ita": "Rutigliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H643",
      "lat": "41.0106618",
      "lon": "17.0079855",
      "superficie_kmq": "53.8522",
      "codice_sovracomunale": "272"
    },
    {
      "sigla_provincia": "BA",
      "codice_istat": "072038",
      "denominazione_ita_altra": "Ruvo di Puglia",
      "denominazione_ita": "Ruvo di Puglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H645",
      "lat": "41.1169421",
      "lon": "16.4848449",
      "superficie_kmq": "223.8254",
      "codice_sovracomunale": "272"
    },
    {
      "sigla_provincia": "BA",
      "codice_istat": "072039",
      "denominazione_ita_altra": "Sammichele di Bari",
      "denominazione_ita": "Sammichele di Bari",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H749",
      "lat": "40.8878379",
      "lon": "16.9544731",
      "superficie_kmq": "34.2339",
      "codice_sovracomunale": "272"
    },
    {
      "sigla_provincia": "BA",
      "codice_istat": "072040",
      "denominazione_ita_altra": "Sannicandro di Bari",
      "denominazione_ita": "Sannicandro di Bari",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I053",
      "lat": "41.0006364",
      "lon": "16.7994067",
      "superficie_kmq": "56.7931",
      "codice_sovracomunale": "272"
    },
    {
      "sigla_provincia": "BA",
      "codice_istat": "072041",
      "denominazione_ita_altra": "Santeramo in Colle",
      "denominazione_ita": "Santeramo in Colle",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I330",
      "lat": "40.7945583",
      "lon": "16.7525335",
      "superficie_kmq": "144.8521",
      "codice_sovracomunale": "272"
    },
    {
      "sigla_provincia": "BA",
      "codice_istat": "072043",
      "denominazione_ita_altra": "Terlizzi",
      "denominazione_ita": "Terlizzi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L109",
      "lat": "41.1308592",
      "lon": "16.5421203",
      "superficie_kmq": "69.2294",
      "codice_sovracomunale": "272"
    },
    {
      "sigla_provincia": "BA",
      "codice_istat": "072044",
      "denominazione_ita_altra": "Toritto",
      "denominazione_ita": "Toritto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L220",
      "lat": "40.9957622",
      "lon": "16.6788669",
      "superficie_kmq": "75.3502",
      "codice_sovracomunale": "272"
    },
    {
      "sigla_provincia": "BA",
      "codice_istat": "072046",
      "denominazione_ita_altra": "Triggiano",
      "denominazione_ita": "Triggiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L425",
      "lat": "41.0652552",
      "lon": "16.9250241",
      "superficie_kmq": "20.1386",
      "codice_sovracomunale": "272"
    },
    {
      "sigla_provincia": "BA",
      "codice_istat": "072047",
      "denominazione_ita_altra": "Turi",
      "denominazione_ita": "Turi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L472",
      "lat": "40.9155656",
      "lon": "17.0191037",
      "superficie_kmq": "71.3998",
      "codice_sovracomunale": "272"
    },
    {
      "sigla_provincia": "BA",
      "codice_istat": "072048",
      "denominazione_ita_altra": "Valenzano",
      "denominazione_ita": "Valenzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L571",
      "lat": "41.0420992",
      "lon": "16.8875762",
      "superficie_kmq": "15.9786",
      "codice_sovracomunale": "272"
    },
    {
      "sigla_provincia": "TA",
      "codice_istat": "073001",
      "denominazione_ita_altra": "Avetrana",
      "denominazione_ita": "Avetrana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A514",
      "lat": "40.3485687",
      "lon": "17.7255314",
      "superficie_kmq": "74.1707",
      "codice_sovracomunale": "073"
    },
    {
      "sigla_provincia": "TA",
      "codice_istat": "073002",
      "denominazione_ita_altra": "Carosino",
      "denominazione_ita": "Carosino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B808",
      "lat": "40.4659360",
      "lon": "17.3980533",
      "superficie_kmq": "10.9347",
      "codice_sovracomunale": "073"
    },
    {
      "sigla_provincia": "TA",
      "codice_istat": "073003",
      "denominazione_ita_altra": "Castellaneta",
      "denominazione_ita": "Castellaneta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C136",
      "lat": "40.6302003",
      "lon": "16.9320662",
      "superficie_kmq": "242.3148",
      "codice_sovracomunale": "073"
    },
    {
      "sigla_provincia": "TA",
      "codice_istat": "073004",
      "denominazione_ita_altra": "Crispiano",
      "denominazione_ita": "Crispiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D171",
      "lat": "40.6051644",
      "lon": "17.2310254",
      "superficie_kmq": "112.3004",
      "codice_sovracomunale": "073"
    },
    {
      "sigla_provincia": "TA",
      "codice_istat": "073005",
      "denominazione_ita_altra": "Faggiano",
      "denominazione_ita": "Faggiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D463",
      "lat": "40.4215151",
      "lon": "17.3885894",
      "superficie_kmq": "21.0642",
      "codice_sovracomunale": "073"
    },
    {
      "sigla_provincia": "TA",
      "codice_istat": "073006",
      "denominazione_ita_altra": "Fragagnano",
      "denominazione_ita": "Fragagnano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D754",
      "lat": "40.4318388",
      "lon": "17.4745399",
      "superficie_kmq": "22.4128",
      "codice_sovracomunale": "073"
    },
    {
      "sigla_provincia": "TA",
      "codice_istat": "073007",
      "denominazione_ita_altra": "Ginosa",
      "denominazione_ita": "Ginosa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E036",
      "lat": "40.5775462",
      "lon": "16.7575652",
      "superficie_kmq": "188.5030",
      "codice_sovracomunale": "073"
    },
    {
      "sigla_provincia": "TA",
      "codice_istat": "073008",
      "denominazione_ita_altra": "Grottaglie",
      "denominazione_ita": "Grottaglie",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E205",
      "lat": "40.5333933",
      "lon": "17.4262331",
      "superficie_kmq": "102.1219",
      "codice_sovracomunale": "073"
    },
    {
      "sigla_provincia": "TA",
      "codice_istat": "073009",
      "denominazione_ita_altra": "Laterza",
      "denominazione_ita": "Laterza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E469",
      "lat": "40.6224151",
      "lon": "16.7990353",
      "superficie_kmq": "161.1716",
      "codice_sovracomunale": "073"
    },
    {
      "sigla_provincia": "TA",
      "codice_istat": "073010",
      "denominazione_ita_altra": "Leporano",
      "denominazione_ita": "Leporano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E537",
      "lat": "40.3838078",
      "lon": "17.3326297",
      "superficie_kmq": "15.3135",
      "codice_sovracomunale": "073"
    },
    {
      "sigla_provincia": "TA",
      "codice_istat": "073011",
      "denominazione_ita_altra": "Lizzano",
      "denominazione_ita": "Lizzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E630",
      "lat": "40.3905077",
      "lon": "17.4488284",
      "superficie_kmq": "47.1769",
      "codice_sovracomunale": "073"
    },
    {
      "sigla_provincia": "TA",
      "codice_istat": "073012",
      "denominazione_ita_altra": "Manduria",
      "denominazione_ita": "Manduria",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E882",
      "lat": "40.3989037",
      "lon": "17.6343058",
      "superficie_kmq": "180.4069",
      "codice_sovracomunale": "073"
    },
    {
      "sigla_provincia": "TA",
      "codice_istat": "073013",
      "denominazione_ita_altra": "Martina Franca",
      "denominazione_ita": "Martina Franca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E986",
      "lat": "40.7048674",
      "lon": "17.3389631",
      "superficie_kmq": "298.7194",
      "codice_sovracomunale": "073"
    },
    {
      "sigla_provincia": "TA",
      "codice_istat": "073014",
      "denominazione_ita_altra": "Maruggio",
      "denominazione_ita": "Maruggio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E995",
      "lat": "40.3199008",
      "lon": "17.5703547",
      "superficie_kmq": "49.0687",
      "codice_sovracomunale": "073"
    },
    {
      "sigla_provincia": "TA",
      "codice_istat": "073015",
      "denominazione_ita_altra": "Massafra",
      "denominazione_ita": "Massafra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F027",
      "lat": "40.5881342",
      "lon": "17.1124485",
      "superficie_kmq": "127.9935",
      "codice_sovracomunale": "073"
    },
    {
      "sigla_provincia": "TA",
      "codice_istat": "073016",
      "denominazione_ita_altra": "Monteiasi",
      "denominazione_ita": "Monteiasi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F531",
      "lat": "40.5020618",
      "lon": "17.3790329",
      "superficie_kmq": "9.7460",
      "codice_sovracomunale": "073"
    },
    {
      "sigla_provincia": "TA",
      "codice_istat": "073017",
      "denominazione_ita_altra": "Montemesola",
      "denominazione_ita": "Montemesola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F563",
      "lat": "40.5622407",
      "lon": "17.3353957",
      "superficie_kmq": "16.4326",
      "codice_sovracomunale": "073"
    },
    {
      "sigla_provincia": "TA",
      "codice_istat": "073018",
      "denominazione_ita_altra": "Monteparano",
      "denominazione_ita": "Monteparano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F587",
      "lat": "40.4440376",
      "lon": "17.4136945",
      "superficie_kmq": "3.8486",
      "codice_sovracomunale": "073"
    },
    {
      "sigla_provincia": "TA",
      "codice_istat": "073019",
      "denominazione_ita_altra": "Mottola",
      "denominazione_ita": "Mottola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F784",
      "lat": "40.6331451",
      "lon": "17.0382788",
      "superficie_kmq": "213.9603",
      "codice_sovracomunale": "073"
    },
    {
      "sigla_provincia": "TA",
      "codice_istat": "073020",
      "denominazione_ita_altra": "Palagianello",
      "denominazione_ita": "Palagianello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G251",
      "lat": "40.6111033",
      "lon": "16.9780149",
      "superficie_kmq": "43.8616",
      "codice_sovracomunale": "073"
    },
    {
      "sigla_provincia": "TA",
      "codice_istat": "073021",
      "denominazione_ita_altra": "Palagiano",
      "denominazione_ita": "Palagiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G252",
      "lat": "40.5784464",
      "lon": "17.0371926",
      "superficie_kmq": "69.9641",
      "codice_sovracomunale": "073"
    },
    {
      "sigla_provincia": "TA",
      "codice_istat": "073022",
      "denominazione_ita_altra": "Pulsano",
      "denominazione_ita": "Pulsano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H090",
      "lat": "40.3829022",
      "lon": "17.3524957",
      "superficie_kmq": "18.5244",
      "codice_sovracomunale": "073"
    },
    {
      "sigla_provincia": "TA",
      "codice_istat": "073023",
      "denominazione_ita_altra": "Roccaforzata",
      "denominazione_ita": "Roccaforzata",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H409",
      "lat": "40.4376247",
      "lon": "17.3888637",
      "superficie_kmq": "6.1453",
      "codice_sovracomunale": "073"
    },
    {
      "sigla_provincia": "TA",
      "codice_istat": "073024",
      "denominazione_ita_altra": "San Giorgio Ionico",
      "denominazione_ita": "San Giorgio Ionico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H882",
      "lat": "40.4593302",
      "lon": "17.3835353",
      "superficie_kmq": "23.5598",
      "codice_sovracomunale": "073"
    },
    {
      "sigla_provincia": "TA",
      "codice_istat": "073025",
      "denominazione_ita_altra": "San Marzano di San Giuseppe",
      "denominazione_ita": "San Marzano di San Giuseppe",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I018",
      "lat": "40.4508865",
      "lon": "17.5055867",
      "superficie_kmq": "19.1944",
      "codice_sovracomunale": "073"
    },
    {
      "sigla_provincia": "TA",
      "codice_istat": "073026",
      "denominazione_ita_altra": "Sava",
      "denominazione_ita": "Sava",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I467",
      "lat": "40.4032584",
      "lon": "17.5562236",
      "superficie_kmq": "44.5686",
      "codice_sovracomunale": "073"
    },
    {
      "sigla_provincia": "TA",
      "codice_istat": "073027",
      "denominazione_ita_altra": "Taranto",
      "denominazione_ita": "Taranto",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "L049",
      "lat": "40.4734845",
      "lon": "17.2323685",
      "superficie_kmq": "249.6027",
      "codice_sovracomunale": "073"
    },
    {
      "sigla_provincia": "TA",
      "codice_istat": "073028",
      "denominazione_ita_altra": "Torricella",
      "denominazione_ita": "Torricella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L294",
      "lat": "40.3549323",
      "lon": "17.5017447",
      "superficie_kmq": "26.9252",
      "codice_sovracomunale": "073"
    },
    {
      "sigla_provincia": "TA",
      "codice_istat": "073029",
      "denominazione_ita_altra": "Statte",
      "denominazione_ita": "Statte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M298",
      "lat": "40.5635106",
      "lon": "17.2006097",
      "superficie_kmq": "67.3200",
      "codice_sovracomunale": "073"
    },
    {
      "sigla_provincia": "BR",
      "codice_istat": "074001",
      "denominazione_ita_altra": "Brindisi",
      "denominazione_ita": "Brindisi",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "B180",
      "lat": "40.6384255",
      "lon": "17.9460157",
      "superficie_kmq": "333.0135",
      "codice_sovracomunale": "074"
    },
    {
      "sigla_provincia": "BR",
      "codice_istat": "074002",
      "denominazione_ita_altra": "Carovigno",
      "denominazione_ita": "Carovigno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B809",
      "lat": "40.7070388",
      "lon": "17.6593812",
      "superficie_kmq": "106.6192",
      "codice_sovracomunale": "074"
    },
    {
      "sigla_provincia": "BR",
      "codice_istat": "074003",
      "denominazione_ita_altra": "Ceglie Messapica",
      "denominazione_ita": "Ceglie Messapica",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C424",
      "lat": "40.6469136",
      "lon": "17.5170848",
      "superficie_kmq": "132.0201",
      "codice_sovracomunale": "074"
    },
    {
      "sigla_provincia": "BR",
      "codice_istat": "074004",
      "denominazione_ita_altra": "Cellino San Marco",
      "denominazione_ita": "Cellino San Marco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C448",
      "lat": "40.4742221",
      "lon": "17.9696310",
      "superficie_kmq": "37.8384",
      "codice_sovracomunale": "074"
    },
    {
      "sigla_provincia": "BR",
      "codice_istat": "074005",
      "denominazione_ita_altra": "Cisternino",
      "denominazione_ita": "Cisternino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C741",
      "lat": "40.7392633",
      "lon": "17.4257046",
      "superficie_kmq": "54.1688",
      "codice_sovracomunale": "074"
    },
    {
      "sigla_provincia": "BR",
      "codice_istat": "074006",
      "denominazione_ita_altra": "Erchie",
      "denominazione_ita": "Erchie",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D422",
      "lat": "40.4383720",
      "lon": "17.7325973",
      "superficie_kmq": "44.6284",
      "codice_sovracomunale": "074"
    },
    {
      "sigla_provincia": "BR",
      "codice_istat": "074007",
      "denominazione_ita_altra": "Fasano",
      "denominazione_ita": "Fasano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D508",
      "lat": "40.8343449",
      "lon": "17.3589861",
      "superficie_kmq": "131.7854",
      "codice_sovracomunale": "074"
    },
    {
      "sigla_provincia": "BR",
      "codice_istat": "074008",
      "denominazione_ita_altra": "Francavilla Fontana",
      "denominazione_ita": "Francavilla Fontana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D761",
      "lat": "40.5328157",
      "lon": "17.5833761",
      "superficie_kmq": "177.9378",
      "codice_sovracomunale": "074"
    },
    {
      "sigla_provincia": "BR",
      "codice_istat": "074009",
      "denominazione_ita_altra": "Latiano",
      "denominazione_ita": "Latiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E471",
      "lat": "40.5545182",
      "lon": "17.7163549",
      "superficie_kmq": "55.3820",
      "codice_sovracomunale": "074"
    },
    {
      "sigla_provincia": "BR",
      "codice_istat": "074010",
      "denominazione_ita_altra": "Mesagne",
      "denominazione_ita": "Mesagne",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F152",
      "lat": "40.5545320",
      "lon": "17.8085743",
      "superficie_kmq": "124.0505",
      "codice_sovracomunale": "074"
    },
    {
      "sigla_provincia": "BR",
      "codice_istat": "074011",
      "denominazione_ita_altra": "Oria",
      "denominazione_ita": "Oria",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G098",
      "lat": "40.5026453",
      "lon": "17.6460611",
      "superficie_kmq": "83.6667",
      "codice_sovracomunale": "074"
    },
    {
      "sigla_provincia": "BR",
      "codice_istat": "074012",
      "denominazione_ita_altra": "Ostuni",
      "denominazione_ita": "Ostuni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G187",
      "lat": "40.7321399",
      "lon": "17.5778218",
      "superficie_kmq": "225.5577",
      "codice_sovracomunale": "074"
    },
    {
      "sigla_provincia": "BR",
      "codice_istat": "074013",
      "denominazione_ita_altra": "San Donaci",
      "denominazione_ita": "San Donaci",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H822",
      "lat": "40.4467205",
      "lon": "17.9221457",
      "superficie_kmq": "34.0443",
      "codice_sovracomunale": "074"
    },
    {
      "sigla_provincia": "BR",
      "codice_istat": "074014",
      "denominazione_ita_altra": "San Michele Salentino",
      "denominazione_ita": "San Michele Salentino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I045",
      "lat": "40.6289469",
      "lon": "17.6315040",
      "superficie_kmq": "26.5343",
      "codice_sovracomunale": "074"
    },
    {
      "sigla_provincia": "BR",
      "codice_istat": "074015",
      "denominazione_ita_altra": "San Pancrazio Salentino",
      "denominazione_ita": "San Pancrazio Salentino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I066",
      "lat": "40.4171608",
      "lon": "17.8383384",
      "superficie_kmq": "56.6779",
      "codice_sovracomunale": "074"
    },
    {
      "sigla_provincia": "BR",
      "codice_istat": "074016",
      "denominazione_ita_altra": "San Pietro Vernotico",
      "denominazione_ita": "San Pietro Vernotico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I119",
      "lat": "40.4883122",
      "lon": "17.9971596",
      "superficie_kmq": "46.9393",
      "codice_sovracomunale": "074"
    },
    {
      "sigla_provincia": "BR",
      "codice_istat": "074017",
      "denominazione_ita_altra": "San Vito dei Normanni",
      "denominazione_ita": "San Vito dei Normanni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I396",
      "lat": "40.6563995",
      "lon": "17.7032741",
      "superficie_kmq": "67.0790",
      "codice_sovracomunale": "074"
    },
    {
      "sigla_provincia": "BR",
      "codice_istat": "074018",
      "denominazione_ita_altra": "Torchiarolo",
      "denominazione_ita": "Torchiarolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L213",
      "lat": "40.4802150",
      "lon": "18.0552461",
      "superficie_kmq": "32.4790",
      "codice_sovracomunale": "074"
    },
    {
      "sigla_provincia": "BR",
      "codice_istat": "074019",
      "denominazione_ita_altra": "Torre Santa Susanna",
      "denominazione_ita": "Torre Santa Susanna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L280",
      "lat": "40.4637908",
      "lon": "17.7428042",
      "superficie_kmq": "55.7645",
      "codice_sovracomunale": "074"
    },
    {
      "sigla_provincia": "BR",
      "codice_istat": "074020",
      "denominazione_ita_altra": "Villa Castelli",
      "denominazione_ita": "Villa Castelli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L920",
      "lat": "40.5771091",
      "lon": "17.4758815",
      "superficie_kmq": "35.1455",
      "codice_sovracomunale": "074"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075002",
      "denominazione_ita_altra": "Alessano",
      "denominazione_ita": "Alessano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A184",
      "lat": "39.8848943",
      "lon": "18.3371688",
      "superficie_kmq": "28.6923",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075003",
      "denominazione_ita_altra": "Alezio",
      "denominazione_ita": "Alezio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A185",
      "lat": "40.0600417",
      "lon": "18.0622169",
      "superficie_kmq": "16.7894",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075004",
      "denominazione_ita_altra": "Alliste",
      "denominazione_ita": "Alliste",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A208",
      "lat": "39.9474442",
      "lon": "18.0894971",
      "superficie_kmq": "23.5298",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075005",
      "denominazione_ita_altra": "Andrano",
      "denominazione_ita": "Andrano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A281",
      "lat": "39.9835188",
      "lon": "18.3867991",
      "superficie_kmq": "15.7116",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075006",
      "denominazione_ita_altra": "Aradeo",
      "denominazione_ita": "Aradeo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A350",
      "lat": "40.1276292",
      "lon": "18.1347845",
      "superficie_kmq": "8.5803",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075007",
      "denominazione_ita_altra": "Arnesano",
      "denominazione_ita": "Arnesano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A425",
      "lat": "40.3352939",
      "lon": "18.0896555",
      "superficie_kmq": "13.5747",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075008",
      "denominazione_ita_altra": "Bagnolo del Salento",
      "denominazione_ita": "Bagnolo del Salento",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A572",
      "lat": "40.1480992",
      "lon": "18.3527016",
      "superficie_kmq": "6.7446",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075009",
      "denominazione_ita_altra": "Botrugno",
      "denominazione_ita": "Botrugno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B086",
      "lat": "40.0643739",
      "lon": "18.3246283",
      "superficie_kmq": "9.7498",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075010",
      "denominazione_ita_altra": "Calimera",
      "denominazione_ita": "Calimera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B413",
      "lat": "40.2511485",
      "lon": "18.2800475",
      "superficie_kmq": "11.1807",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075011",
      "denominazione_ita_altra": "Campi Salentina",
      "denominazione_ita": "Campi Salentina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B506",
      "lat": "40.3991200",
      "lon": "18.0202897",
      "superficie_kmq": "45.8786",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075012",
      "denominazione_ita_altra": "Cannole",
      "denominazione_ita": "Cannole",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B616",
      "lat": "40.1677985",
      "lon": "18.3635053",
      "superficie_kmq": "20.3484",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075013",
      "denominazione_ita_altra": "Caprarica di Lecce",
      "denominazione_ita": "Caprarica di Lecce",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B690",
      "lat": "40.2628228",
      "lon": "18.2450241",
      "superficie_kmq": "10.7074",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075014",
      "denominazione_ita_altra": "Carmiano",
      "denominazione_ita": "Carmiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B792",
      "lat": "40.3454892",
      "lon": "18.0454576",
      "superficie_kmq": "24.2405",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075015",
      "denominazione_ita_altra": "Carpignano Salentino",
      "denominazione_ita": "Carpignano Salentino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B822",
      "lat": "40.1978768",
      "lon": "18.3405584",
      "superficie_kmq": "48.9866",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075016",
      "denominazione_ita_altra": "Casarano",
      "denominazione_ita": "Casarano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B936",
      "lat": "40.0091052",
      "lon": "18.1610622",
      "superficie_kmq": "38.7274",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075017",
      "denominazione_ita_altra": "Castri di Lecce",
      "denominazione_ita": "Castri di Lecce",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C334",
      "lat": "40.2726034",
      "lon": "18.2667876",
      "superficie_kmq": "12.9451",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075018",
      "denominazione_ita_altra": "Castrignano de' Greci",
      "denominazione_ita": "Castrignano de' Greci",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C335",
      "lat": "40.1747803",
      "lon": "18.2952401",
      "superficie_kmq": "9.6246",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075019",
      "denominazione_ita_altra": "Castrignano del Capo",
      "denominazione_ita": "Castrignano del Capo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C336",
      "lat": "39.8293061",
      "lon": "18.3535086",
      "superficie_kmq": "20.7689",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075020",
      "denominazione_ita_altra": "Cavallino",
      "denominazione_ita": "Cavallino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C377",
      "lat": "40.3156018",
      "lon": "18.1944932",
      "superficie_kmq": "22.6543",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075021",
      "denominazione_ita_altra": "Collepasso",
      "denominazione_ita": "Collepasso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C865",
      "lat": "40.0731419",
      "lon": "18.1629276",
      "superficie_kmq": "12.7891",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075022",
      "denominazione_ita_altra": "Copertino",
      "denominazione_ita": "Copertino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C978",
      "lat": "40.2717939",
      "lon": "18.0478075",
      "superficie_kmq": "58.5317",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075023",
      "denominazione_ita_altra": "Corigliano d'Otranto",
      "denominazione_ita": "Corigliano d'Otranto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D006",
      "lat": "40.1598418",
      "lon": "18.2617373",
      "superficie_kmq": "28.4134",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075024",
      "denominazione_ita_altra": "Corsano",
      "denominazione_ita": "Corsano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D044",
      "lat": "39.8896121",
      "lon": "18.3696892",
      "superficie_kmq": "9.1202",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075025",
      "denominazione_ita_altra": "Cursi",
      "denominazione_ita": "Cursi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D223",
      "lat": "40.1500485",
      "lon": "18.3140885",
      "superficie_kmq": "8.3571",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075026",
      "denominazione_ita_altra": "Cutrofiano",
      "denominazione_ita": "Cutrofiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D237",
      "lat": "40.1271538",
      "lon": "18.1998960",
      "superficie_kmq": "56.8074",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075027",
      "denominazione_ita_altra": "Diso",
      "denominazione_ita": "Diso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D305",
      "lat": "40.0081485",
      "lon": "18.3871599",
      "superficie_kmq": "11.4238",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075028",
      "denominazione_ita_altra": "Gagliano del Capo",
      "denominazione_ita": "Gagliano del Capo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D851",
      "lat": "39.8464574",
      "lon": "18.3694752",
      "superficie_kmq": "16.5981",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075029",
      "denominazione_ita_altra": "Galatina",
      "denominazione_ita": "Galatina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D862",
      "lat": "40.1738903",
      "lon": "18.1722661",
      "superficie_kmq": "82.6466",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075030",
      "denominazione_ita_altra": "Galatone",
      "denominazione_ita": "Galatone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D863",
      "lat": "40.1483820",
      "lon": "18.0716699",
      "superficie_kmq": "47.0682",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075031",
      "denominazione_ita_altra": "Gallipoli",
      "denominazione_ita": "Gallipoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D883",
      "lat": "40.0531131",
      "lon": "17.9742183",
      "superficie_kmq": "41.2188",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075032",
      "denominazione_ita_altra": "Giuggianello",
      "denominazione_ita": "Giuggianello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E053",
      "lat": "40.0938789",
      "lon": "18.3686854",
      "superficie_kmq": "10.2665",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075033",
      "denominazione_ita_altra": "Giurdignano",
      "denominazione_ita": "Giurdignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E061",
      "lat": "40.1236737",
      "lon": "18.4311230",
      "superficie_kmq": "14.0433",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075034",
      "denominazione_ita_altra": "Guagnano",
      "denominazione_ita": "Guagnano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E227",
      "lat": "40.4033653",
      "lon": "17.9493519",
      "superficie_kmq": "38.0291",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075035",
      "denominazione_ita_altra": "Lecce",
      "denominazione_ita": "Lecce",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "E506",
      "lat": "40.3534791",
      "lon": "18.1718981",
      "superficie_kmq": "239.3384",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075036",
      "denominazione_ita_altra": "Lequile",
      "denominazione_ita": "Lequile",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E538",
      "lat": "40.3070893",
      "lon": "18.1409840",
      "superficie_kmq": "36.7045",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075037",
      "denominazione_ita_altra": "Leverano",
      "denominazione_ita": "Leverano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E563",
      "lat": "40.2865578",
      "lon": "17.9962298",
      "superficie_kmq": "49.5030",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075038",
      "denominazione_ita_altra": "Lizzanello",
      "denominazione_ita": "Lizzanello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E629",
      "lat": "40.3034810",
      "lon": "18.2221298",
      "superficie_kmq": "25.4162",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075039",
      "denominazione_ita_altra": "Maglie",
      "denominazione_ita": "Maglie",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E815",
      "lat": "40.1209522",
      "lon": "18.2988493",
      "superficie_kmq": "22.6639",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075040",
      "denominazione_ita_altra": "Martano",
      "denominazione_ita": "Martano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E979",
      "lat": "40.2037705",
      "lon": "18.3012009",
      "superficie_kmq": "22.2478",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075041",
      "denominazione_ita_altra": "Martignano",
      "denominazione_ita": "Martignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E984",
      "lat": "40.2374149",
      "lon": "18.2589261",
      "superficie_kmq": "6.4921",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075042",
      "denominazione_ita_altra": "Matino",
      "denominazione_ita": "Matino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F054",
      "lat": "40.0325700",
      "lon": "18.1340977",
      "superficie_kmq": "26.6286",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075043",
      "denominazione_ita_altra": "Melendugno",
      "denominazione_ita": "Melendugno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F101",
      "lat": "40.2667781",
      "lon": "18.3370443",
      "superficie_kmq": "92.3099",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075044",
      "denominazione_ita_altra": "Melissano",
      "denominazione_ita": "Melissano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F109",
      "lat": "39.9734765",
      "lon": "18.1236104",
      "superficie_kmq": "12.5488",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075045",
      "denominazione_ita_altra": "Melpignano",
      "denominazione_ita": "Melpignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F117",
      "lat": "40.1546948",
      "lon": "18.2894361",
      "superficie_kmq": "11.1026",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075046",
      "denominazione_ita_altra": "Miggiano",
      "denominazione_ita": "Miggiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F194",
      "lat": "39.9649986",
      "lon": "18.3068422",
      "superficie_kmq": "7.7990",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075047",
      "denominazione_ita_altra": "Minervino di Lecce",
      "denominazione_ita": "Minervino di Lecce",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F221",
      "lat": "40.0925344",
      "lon": "18.4223617",
      "superficie_kmq": "18.1285",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075048",
      "denominazione_ita_altra": "Monteroni di Lecce",
      "denominazione_ita": "Monteroni di Lecce",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F604",
      "lat": "40.3287514",
      "lon": "18.0975433",
      "superficie_kmq": "16.7428",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075049",
      "denominazione_ita_altra": "Montesano Salentino",
      "denominazione_ita": "Montesano Salentino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F623",
      "lat": "39.9758543",
      "lon": "18.3218481",
      "superficie_kmq": "8.5283",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075050",
      "denominazione_ita_altra": "Morciano di Leuca",
      "denominazione_ita": "Morciano di Leuca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F716",
      "lat": "39.8468995",
      "lon": "18.3113855",
      "superficie_kmq": "13.5676",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075051",
      "denominazione_ita_altra": "Muro Leccese",
      "denominazione_ita": "Muro Leccese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F816",
      "lat": "40.0999231",
      "lon": "18.3393391",
      "superficie_kmq": "16.7671",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075052",
      "denominazione_ita_altra": "Nardò",
      "denominazione_ita": "Nardò",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F842",
      "lat": "40.1758774",
      "lon": "18.0311881",
      "superficie_kmq": "193.2643",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075053",
      "denominazione_ita_altra": "Neviano",
      "denominazione_ita": "Neviano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F881",
      "lat": "40.1053587",
      "lon": "18.1190404",
      "superficie_kmq": "16.3019",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075054",
      "denominazione_ita_altra": "Nociglia",
      "denominazione_ita": "Nociglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F916",
      "lat": "40.0361872",
      "lon": "18.3303261",
      "superficie_kmq": "11.1267",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075055",
      "denominazione_ita_altra": "Novoli",
      "denominazione_ita": "Novoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F970",
      "lat": "40.3758116",
      "lon": "18.0487680",
      "superficie_kmq": "18.0207",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075056",
      "denominazione_ita_altra": "Ortelle",
      "denominazione_ita": "Ortelle",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G136",
      "lat": "40.0341697",
      "lon": "18.3930147",
      "superficie_kmq": "10.2252",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075057",
      "denominazione_ita_altra": "Otranto",
      "denominazione_ita": "Otranto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G188",
      "lat": "40.1462869",
      "lon": "18.4911268",
      "superficie_kmq": "77.1936",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075058",
      "denominazione_ita_altra": "Palmariggi",
      "denominazione_ita": "Palmariggi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G285",
      "lat": "40.1315616",
      "lon": "18.3812973",
      "superficie_kmq": "8.9724",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075059",
      "denominazione_ita_altra": "Parabita",
      "denominazione_ita": "Parabita",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G325",
      "lat": "40.0453397",
      "lon": "18.1265955",
      "superficie_kmq": "21.0919",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075060",
      "denominazione_ita_altra": "Patù",
      "denominazione_ita": "Patù",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G378",
      "lat": "39.8395781",
      "lon": "18.3387260",
      "superficie_kmq": "8.6891",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075061",
      "denominazione_ita_altra": "Poggiardo",
      "denominazione_ita": "Poggiardo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G751",
      "lat": "40.0533642",
      "lon": "18.3768556",
      "superficie_kmq": "19.9594",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075063",
      "denominazione_ita_altra": "Racale",
      "denominazione_ita": "Racale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H147",
      "lat": "39.9573885",
      "lon": "18.0905916",
      "superficie_kmq": "24.2878",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075064",
      "denominazione_ita_altra": "Ruffano",
      "denominazione_ita": "Ruffano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H632",
      "lat": "39.9834972",
      "lon": "18.2467884",
      "superficie_kmq": "39.7265",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075065",
      "denominazione_ita_altra": "Salice Salentino",
      "denominazione_ita": "Salice Salentino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H708",
      "lat": "40.3847643",
      "lon": "17.9651655",
      "superficie_kmq": "59.8721",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075066",
      "denominazione_ita_altra": "Salve",
      "denominazione_ita": "Salve",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H729",
      "lat": "39.8617640",
      "lon": "18.2940682",
      "superficie_kmq": "33.0704",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075067",
      "denominazione_ita_altra": "Sanarica",
      "denominazione_ita": "Sanarica",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H757",
      "lat": "40.0875675",
      "lon": "18.3493673",
      "superficie_kmq": "13.0223",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075068",
      "denominazione_ita_altra": "San Cesario di Lecce",
      "denominazione_ita": "San Cesario di Lecce",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H793",
      "lat": "40.3018034",
      "lon": "18.1602981",
      "superficie_kmq": "8.0889",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075069",
      "denominazione_ita_altra": "San Donato di Lecce",
      "denominazione_ita": "San Donato di Lecce",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H826",
      "lat": "40.2680660",
      "lon": "18.1835147",
      "superficie_kmq": "21.5772",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075070",
      "denominazione_ita_altra": "Sannicola",
      "denominazione_ita": "Sannicola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I059",
      "lat": "40.0937985",
      "lon": "18.0640560",
      "superficie_kmq": "27.6390",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075071",
      "denominazione_ita_altra": "San Pietro in Lama",
      "denominazione_ita": "San Pietro in Lama",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I115",
      "lat": "40.3091001",
      "lon": "18.1318605",
      "superficie_kmq": "8.1977",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075072",
      "denominazione_ita_altra": "Santa Cesarea Terme",
      "denominazione_ita": "Santa Cesarea Terme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I172",
      "lat": "40.0377893",
      "lon": "18.4633555",
      "superficie_kmq": "26.8239",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075073",
      "denominazione_ita_altra": "Scorrano",
      "denominazione_ita": "Scorrano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I549",
      "lat": "40.0900110",
      "lon": "18.2983549",
      "superficie_kmq": "35.3303",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075074",
      "denominazione_ita_altra": "Seclì",
      "denominazione_ita": "Seclì",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I559",
      "lat": "40.1315013",
      "lon": "18.1049482",
      "superficie_kmq": "8.7759",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075075",
      "denominazione_ita_altra": "Sogliano Cavour",
      "denominazione_ita": "Sogliano Cavour",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I780",
      "lat": "40.1461683",
      "lon": "18.1994047",
      "superficie_kmq": "5.3306",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075076",
      "denominazione_ita_altra": "Soleto",
      "denominazione_ita": "Soleto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I800",
      "lat": "40.1875154",
      "lon": "18.2071855",
      "superficie_kmq": "30.4551",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075077",
      "denominazione_ita_altra": "Specchia",
      "denominazione_ita": "Specchia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I887",
      "lat": "39.9387714",
      "lon": "18.2960743",
      "superficie_kmq": "25.0953",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075078",
      "denominazione_ita_altra": "Spongano",
      "denominazione_ita": "Spongano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I923",
      "lat": "40.0130658",
      "lon": "18.3637805",
      "superficie_kmq": "12.4246",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075079",
      "denominazione_ita_altra": "Squinzano",
      "denominazione_ita": "Squinzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I930",
      "lat": "40.4343808",
      "lon": "18.0458090",
      "superficie_kmq": "31.1636",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075080",
      "denominazione_ita_altra": "Sternatia",
      "denominazione_ita": "Sternatia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I950",
      "lat": "40.2215895",
      "lon": "18.2238366",
      "superficie_kmq": "16.7645",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075081",
      "denominazione_ita_altra": "Supersano",
      "denominazione_ita": "Supersano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L008",
      "lat": "40.0199902",
      "lon": "18.2389212",
      "superficie_kmq": "36.4054",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075082",
      "denominazione_ita_altra": "Surano",
      "denominazione_ita": "Surano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L010",
      "lat": "40.0282725",
      "lon": "18.3416117",
      "superficie_kmq": "8.9883",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075083",
      "denominazione_ita_altra": "Surbo",
      "denominazione_ita": "Surbo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L011",
      "lat": "40.3974908",
      "lon": "18.1315794",
      "superficie_kmq": "20.7829",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075084",
      "denominazione_ita_altra": "Taurisano",
      "denominazione_ita": "Taurisano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L064",
      "lat": "39.9555465",
      "lon": "18.2143691",
      "superficie_kmq": "23.6797",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075085",
      "denominazione_ita_altra": "Taviano",
      "denominazione_ita": "Taviano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L074",
      "lat": "39.9829197",
      "lon": "18.0884842",
      "superficie_kmq": "22.1311",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075086",
      "denominazione_ita_altra": "Tiggiano",
      "denominazione_ita": "Tiggiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L166",
      "lat": "39.9027212",
      "lon": "18.3672227",
      "superficie_kmq": "7.7107",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075087",
      "denominazione_ita_altra": "Trepuzzi",
      "denominazione_ita": "Trepuzzi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L383",
      "lat": "40.4050961",
      "lon": "18.0736859",
      "superficie_kmq": "23.7720",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075088",
      "denominazione_ita_altra": "Tricase",
      "denominazione_ita": "Tricase",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L419",
      "lat": "39.9348833",
      "lon": "18.3507524",
      "superficie_kmq": "43.3296",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075089",
      "denominazione_ita_altra": "Tuglie",
      "denominazione_ita": "Tuglie",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L462",
      "lat": "40.0701161",
      "lon": "18.1029800",
      "superficie_kmq": "8.5014",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075090",
      "denominazione_ita_altra": "Ugento",
      "denominazione_ita": "Ugento",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L484",
      "lat": "39.9284992",
      "lon": "18.1616684",
      "superficie_kmq": "100.3960",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075091",
      "denominazione_ita_altra": "Uggiano la Chiesa",
      "denominazione_ita": "Uggiano la Chiesa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L485",
      "lat": "40.0994540",
      "lon": "18.4539799",
      "superficie_kmq": "14.4580",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075092",
      "denominazione_ita_altra": "Veglie",
      "denominazione_ita": "Veglie",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L711",
      "lat": "40.3349926",
      "lon": "17.9657322",
      "superficie_kmq": "62.3085",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075093",
      "denominazione_ita_altra": "Vernole",
      "denominazione_ita": "Vernole",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L776",
      "lat": "40.2883961",
      "lon": "18.2987313",
      "superficie_kmq": "61.2157",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075094",
      "denominazione_ita_altra": "Zollino",
      "denominazione_ita": "Zollino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M187",
      "lat": "40.2051625",
      "lon": "18.2513760",
      "superficie_kmq": "9.9545",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075095",
      "denominazione_ita_altra": "San Cassiano",
      "denominazione_ita": "San Cassiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M264",
      "lat": "40.0538577",
      "lon": "18.3299677",
      "superficie_kmq": "8.7747",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075096",
      "denominazione_ita_altra": "Castro",
      "denominazione_ita": "Castro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M261",
      "lat": "40.0078297",
      "lon": "18.4247599",
      "superficie_kmq": "4.5637",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075097",
      "denominazione_ita_altra": "Porto Cesareo",
      "denominazione_ita": "Porto Cesareo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M263",
      "lat": "40.2602838",
      "lon": "17.8996473",
      "superficie_kmq": "35.1153",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "LE",
      "codice_istat": "075098",
      "denominazione_ita_altra": "Presicce-Acquarica",
      "denominazione_ita": "Presicce-Acquarica",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M428",
      "lat": "39.8964473",
      "lon": "18.2459011",
      "superficie_kmq": "43.0595",
      "codice_sovracomunale": "075"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076001",
      "denominazione_ita_altra": "Abriola",
      "denominazione_ita": "Abriola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A013",
      "lat": "40.5078687",
      "lon": "15.8121946",
      "superficie_kmq": "97.1894",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076002",
      "denominazione_ita_altra": "Acerenza",
      "denominazione_ita": "Acerenza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A020",
      "lat": "40.7954738",
      "lon": "15.9416701",
      "superficie_kmq": "77.6422",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076003",
      "denominazione_ita_altra": "Albano di Lucania",
      "denominazione_ita": "Albano di Lucania",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A131",
      "lat": "40.5829351",
      "lon": "16.0361505",
      "superficie_kmq": "55.8735",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076004",
      "denominazione_ita_altra": "Anzi",
      "denominazione_ita": "Anzi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A321",
      "lat": "40.5144310",
      "lon": "15.9239193",
      "superficie_kmq": "77.0954",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076005",
      "denominazione_ita_altra": "Armento",
      "denominazione_ita": "Armento",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A415",
      "lat": "40.3051249",
      "lon": "16.0664730",
      "superficie_kmq": "58.9765",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076006",
      "denominazione_ita_altra": "Atella",
      "denominazione_ita": "Atella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A482",
      "lat": "40.8773633",
      "lon": "15.6527369",
      "superficie_kmq": "88.4769",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076007",
      "denominazione_ita_altra": "Avigliano",
      "denominazione_ita": "Avigliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A519",
      "lat": "40.7297170",
      "lon": "15.7177959",
      "superficie_kmq": "85.4804",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076008",
      "denominazione_ita_altra": "Balvano",
      "denominazione_ita": "Balvano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A604",
      "lat": "40.6511840",
      "lon": "15.5120538",
      "superficie_kmq": "42.1526",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076009",
      "denominazione_ita_altra": "Banzi",
      "denominazione_ita": "Banzi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A612",
      "lat": "40.8602772",
      "lon": "16.0132828",
      "superficie_kmq": "83.0544",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076010",
      "denominazione_ita_altra": "Baragiano",
      "denominazione_ita": "Baragiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A615",
      "lat": "40.6795038",
      "lon": "15.5942002",
      "superficie_kmq": "29.6030",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076011",
      "denominazione_ita_altra": "Barile",
      "denominazione_ita": "Barile",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A666",
      "lat": "40.9460229",
      "lon": "15.6727242",
      "superficie_kmq": "24.1247",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076012",
      "denominazione_ita_altra": "Bella",
      "denominazione_ita": "Bella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A743",
      "lat": "40.7585328",
      "lon": "15.5377823",
      "superficie_kmq": "99.7113",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076013",
      "denominazione_ita_altra": "Brienza",
      "denominazione_ita": "Brienza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B173",
      "lat": "40.4773097",
      "lon": "15.6281215",
      "superficie_kmq": "82.9325",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076014",
      "denominazione_ita_altra": "Brindisi Montagna",
      "denominazione_ita": "Brindisi Montagna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B181",
      "lat": "40.6098334",
      "lon": "15.9400610",
      "superficie_kmq": "59.8776",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076015",
      "denominazione_ita_altra": "Calvello",
      "denominazione_ita": "Calvello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B440",
      "lat": "40.4751838",
      "lon": "15.8472159",
      "superficie_kmq": "106.3938",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076016",
      "denominazione_ita_altra": "Calvera",
      "denominazione_ita": "Calvera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B443",
      "lat": "40.1486380",
      "lon": "16.1436118",
      "superficie_kmq": "16.0061",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076017",
      "denominazione_ita_altra": "Campomaggiore",
      "denominazione_ita": "Campomaggiore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B549",
      "lat": "40.5670484",
      "lon": "16.0736750",
      "superficie_kmq": "12.4751",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076018",
      "denominazione_ita_altra": "Cancellara",
      "denominazione_ita": "Cancellara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B580",
      "lat": "40.7307309",
      "lon": "15.9251179",
      "superficie_kmq": "42.4985",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076019",
      "denominazione_ita_altra": "Carbone",
      "denominazione_ita": "Carbone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B743",
      "lat": "40.1415595",
      "lon": "16.0895915",
      "superficie_kmq": "48.5290",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076020",
      "denominazione_ita_altra": "San Paolo Albanese",
      "denominazione_ita": "San Paolo Albanese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B906",
      "lat": "40.0356024",
      "lon": "16.3349707",
      "superficie_kmq": "30.2232",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076021",
      "denominazione_ita_altra": "Castelgrande",
      "denominazione_ita": "Castelgrande",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C120",
      "lat": "40.7851637",
      "lon": "15.4330577",
      "superficie_kmq": "34.8983",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076022",
      "denominazione_ita_altra": "Castelluccio Inferiore",
      "denominazione_ita": "Castelluccio Inferiore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C199",
      "lat": "40.0050316",
      "lon": "15.9728906",
      "superficie_kmq": "28.9630",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076023",
      "denominazione_ita_altra": "Castelluccio Superiore",
      "denominazione_ita": "Castelluccio Superiore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C201",
      "lat": "40.0095308",
      "lon": "15.9767135",
      "superficie_kmq": "32.9788",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076024",
      "denominazione_ita_altra": "Castelmezzano",
      "denominazione_ita": "Castelmezzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C209",
      "lat": "40.5306056",
      "lon": "16.0481284",
      "superficie_kmq": "33.9081",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076025",
      "denominazione_ita_altra": "Castelsaraceno",
      "denominazione_ita": "Castelsaraceno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C271",
      "lat": "40.1649569",
      "lon": "15.9927036",
      "superficie_kmq": "74.7742",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076026",
      "denominazione_ita_altra": "Castronuovo di Sant'Andrea",
      "denominazione_ita": "Castronuovo di Sant'Andrea",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C345",
      "lat": "40.1884688",
      "lon": "16.1860817",
      "superficie_kmq": "47.4521",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076027",
      "denominazione_ita_altra": "Cersosimo",
      "denominazione_ita": "Cersosimo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C539",
      "lat": "40.0456428",
      "lon": "16.3492749",
      "superficie_kmq": "24.7426",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076028",
      "denominazione_ita_altra": "Chiaromonte",
      "denominazione_ita": "Chiaromonte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C619",
      "lat": "40.1242604",
      "lon": "16.2149864",
      "superficie_kmq": "70.0175",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076029",
      "denominazione_ita_altra": "Corleto Perticara",
      "denominazione_ita": "Corleto Perticara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D010",
      "lat": "40.3837540",
      "lon": "16.0424901",
      "superficie_kmq": "89.3392",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076030",
      "denominazione_ita_altra": "Episcopia",
      "denominazione_ita": "Episcopia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D414",
      "lat": "40.0743891",
      "lon": "16.0981359",
      "superficie_kmq": "28.6440",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076031",
      "denominazione_ita_altra": "Fardella",
      "denominazione_ita": "Fardella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D497",
      "lat": "40.1140380",
      "lon": "16.1667006",
      "superficie_kmq": "29.0762",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076032",
      "denominazione_ita_altra": "Filiano",
      "denominazione_ita": "Filiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D593",
      "lat": "40.8091716",
      "lon": "15.7068470",
      "superficie_kmq": "71.8087",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076033",
      "denominazione_ita_altra": "Forenza",
      "denominazione_ita": "Forenza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D696",
      "lat": "40.8593150",
      "lon": "15.8571330",
      "superficie_kmq": "116.3055",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076034",
      "denominazione_ita_altra": "Francavilla in Sinni",
      "denominazione_ita": "Francavilla in Sinni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D766",
      "lat": "40.0816094",
      "lon": "16.2032342",
      "superficie_kmq": "46.8176",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076035",
      "denominazione_ita_altra": "Gallicchio",
      "denominazione_ita": "Gallicchio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D876",
      "lat": "40.2888542",
      "lon": "16.1385449",
      "superficie_kmq": "23.6307",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076036",
      "denominazione_ita_altra": "Genzano di Lucania",
      "denominazione_ita": "Genzano di Lucania",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D971",
      "lat": "40.8502986",
      "lon": "16.0315310",
      "superficie_kmq": "208.9252",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076037",
      "denominazione_ita_altra": "Grumento Nova",
      "denominazione_ita": "Grumento Nova",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E221",
      "lat": "40.2823464",
      "lon": "15.8880506",
      "superficie_kmq": "66.6494",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076038",
      "denominazione_ita_altra": "Guardia Perticara",
      "denominazione_ita": "Guardia Perticara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E246",
      "lat": "40.3635131",
      "lon": "16.0980262",
      "superficie_kmq": "53.6795",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076039",
      "denominazione_ita_altra": "Lagonegro",
      "denominazione_ita": "Lagonegro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E409",
      "lat": "40.1224777",
      "lon": "15.7631434",
      "superficie_kmq": "113.0670",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076040",
      "denominazione_ita_altra": "Latronico",
      "denominazione_ita": "Latronico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E474",
      "lat": "40.0884925",
      "lon": "16.0090585",
      "superficie_kmq": "76.6582",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076041",
      "denominazione_ita_altra": "Laurenzana",
      "denominazione_ita": "Laurenzana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E482",
      "lat": "40.4594018",
      "lon": "15.9721598",
      "superficie_kmq": "95.7074",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076042",
      "denominazione_ita_altra": "Lauria",
      "denominazione_ita": "Lauria",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E483",
      "lat": "40.0472162",
      "lon": "15.8369067",
      "superficie_kmq": "176.6271",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076043",
      "denominazione_ita_altra": "Lavello",
      "denominazione_ita": "Lavello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E493",
      "lat": "41.0499007",
      "lon": "15.8010478",
      "superficie_kmq": "134.6546",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076044",
      "denominazione_ita_altra": "Maratea",
      "denominazione_ita": "Maratea",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E919",
      "lat": "39.9957055",
      "lon": "15.7246166",
      "superficie_kmq": "67.8354",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076045",
      "denominazione_ita_altra": "Marsico Nuovo",
      "denominazione_ita": "Marsico Nuovo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E976",
      "lat": "40.4191356",
      "lon": "15.7342208",
      "superficie_kmq": "100.9652",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076046",
      "denominazione_ita_altra": "Marsicovetere",
      "denominazione_ita": "Marsicovetere",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E977",
      "lat": "40.3745488",
      "lon": "15.8263729",
      "superficie_kmq": "38.0116",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076047",
      "denominazione_ita_altra": "Maschito",
      "denominazione_ita": "Maschito",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F006",
      "lat": "40.9066727",
      "lon": "15.8314202",
      "superficie_kmq": "45.8173",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076048",
      "denominazione_ita_altra": "Melfi",
      "denominazione_ita": "Melfi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F104",
      "lat": "40.9943795",
      "lon": "15.6529090",
      "superficie_kmq": "206.2060",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076049",
      "denominazione_ita_altra": "Missanello",
      "denominazione_ita": "Missanello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F249",
      "lat": "40.2821333",
      "lon": "16.1652040",
      "superficie_kmq": "22.3407",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076050",
      "denominazione_ita_altra": "Moliterno",
      "denominazione_ita": "Moliterno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F295",
      "lat": "40.2420007",
      "lon": "15.8687516",
      "superficie_kmq": "98.5528",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076051",
      "denominazione_ita_altra": "Montemilone",
      "denominazione_ita": "Montemilone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F568",
      "lat": "41.0270180",
      "lon": "15.9671552",
      "superficie_kmq": "114.1330",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076052",
      "denominazione_ita_altra": "Montemurro",
      "denominazione_ita": "Montemurro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F573",
      "lat": "40.2991376",
      "lon": "15.9939025",
      "superficie_kmq": "56.8705",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076053",
      "denominazione_ita_altra": "Muro Lucano",
      "denominazione_ita": "Muro Lucano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F817",
      "lat": "40.7527604",
      "lon": "15.4836980",
      "superficie_kmq": "126.1840",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076054",
      "denominazione_ita_altra": "Nemoli",
      "denominazione_ita": "Nemoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F866",
      "lat": "40.0658725",
      "lon": "15.7992595",
      "superficie_kmq": "19.4925",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076055",
      "denominazione_ita_altra": "Noepoli",
      "denominazione_ita": "Noepoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F917",
      "lat": "40.0870999",
      "lon": "16.3280657",
      "superficie_kmq": "46.7094",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076056",
      "denominazione_ita_altra": "Oppido Lucano",
      "denominazione_ita": "Oppido Lucano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G081",
      "lat": "40.7659439",
      "lon": "15.9900637",
      "superficie_kmq": "54.8779",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076057",
      "denominazione_ita_altra": "Palazzo San Gervasio",
      "denominazione_ita": "Palazzo San Gervasio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G261",
      "lat": "40.9305503",
      "lon": "15.9807868",
      "superficie_kmq": "62.9102",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076058",
      "denominazione_ita_altra": "Pescopagano",
      "denominazione_ita": "Pescopagano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G496",
      "lat": "40.8358268",
      "lon": "15.3969127",
      "superficie_kmq": "69.8342",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076059",
      "denominazione_ita_altra": "Picerno",
      "denominazione_ita": "Picerno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G590",
      "lat": "40.6370365",
      "lon": "15.6360060",
      "superficie_kmq": "78.5089",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076060",
      "denominazione_ita_altra": "Pietragalla",
      "denominazione_ita": "Pietragalla",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G616",
      "lat": "40.7480492",
      "lon": "15.8823844",
      "superficie_kmq": "66.0977",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076061",
      "denominazione_ita_altra": "Pietrapertosa",
      "denominazione_ita": "Pietrapertosa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G623",
      "lat": "40.5155876",
      "lon": "16.0634819",
      "superficie_kmq": "67.7025",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076062",
      "denominazione_ita_altra": "Pignola",
      "denominazione_ita": "Pignola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G663",
      "lat": "40.5739625",
      "lon": "15.7849512",
      "superficie_kmq": "56.2350",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076063",
      "denominazione_ita_altra": "Potenza",
      "denominazione_ita": "Potenza",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "G942",
      "lat": "40.6394086",
      "lon": "15.8051432",
      "superficie_kmq": "175.4318",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076064",
      "denominazione_ita_altra": "Rapolla",
      "denominazione_ita": "Rapolla",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H186",
      "lat": "40.9771223",
      "lon": "15.6728172",
      "superficie_kmq": "29.8734",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076065",
      "denominazione_ita_altra": "Rapone",
      "denominazione_ita": "Rapone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H187",
      "lat": "40.8464091",
      "lon": "15.4981153",
      "superficie_kmq": "29.5060",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076066",
      "denominazione_ita_altra": "Rionero in Vulture",
      "denominazione_ita": "Rionero in Vulture",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H307",
      "lat": "40.9269393",
      "lon": "15.6737915",
      "superficie_kmq": "53.5181",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076067",
      "denominazione_ita_altra": "Ripacandida",
      "denominazione_ita": "Ripacandida",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H312",
      "lat": "40.9127936",
      "lon": "15.7273873",
      "superficie_kmq": "33.4848",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076068",
      "denominazione_ita_altra": "Rivello",
      "denominazione_ita": "Rivello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H348",
      "lat": "40.0771530",
      "lon": "15.7577557",
      "superficie_kmq": "69.5848",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076069",
      "denominazione_ita_altra": "Roccanova",
      "denominazione_ita": "Roccanova",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H426",
      "lat": "40.2141675",
      "lon": "16.2050925",
      "superficie_kmq": "61.7351",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076070",
      "denominazione_ita_altra": "Rotonda",
      "denominazione_ita": "Rotonda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H590",
      "lat": "39.9519025",
      "lon": "16.0361836",
      "superficie_kmq": "42.9226",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076071",
      "denominazione_ita_altra": "Ruoti",
      "denominazione_ita": "Ruoti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H641",
      "lat": "40.7173940",
      "lon": "15.6762120",
      "superficie_kmq": "55.4508",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076072",
      "denominazione_ita_altra": "Ruvo del Monte",
      "denominazione_ita": "Ruvo del Monte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H646",
      "lat": "40.8472855",
      "lon": "15.5412966",
      "superficie_kmq": "32.6210",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076073",
      "denominazione_ita_altra": "San Chirico Nuovo",
      "denominazione_ita": "San Chirico Nuovo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H795",
      "lat": "40.6768568",
      "lon": "16.0794557",
      "superficie_kmq": "23.3938",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076074",
      "denominazione_ita_altra": "San Chirico Raparo",
      "denominazione_ita": "San Chirico Raparo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H796",
      "lat": "40.1905167",
      "lon": "16.0764141",
      "superficie_kmq": "84.0711",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076075",
      "denominazione_ita_altra": "San Costantino Albanese",
      "denominazione_ita": "San Costantino Albanese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H808",
      "lat": "40.0352305",
      "lon": "16.3044987",
      "superficie_kmq": "43.2479",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076076",
      "denominazione_ita_altra": "San Fele",
      "denominazione_ita": "San Fele",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H831",
      "lat": "40.8191473",
      "lon": "15.5392267",
      "superficie_kmq": "97.6989",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076077",
      "denominazione_ita_altra": "San Martino d'Agri",
      "denominazione_ita": "San Martino d'Agri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H994",
      "lat": "40.2368811",
      "lon": "16.0506525",
      "superficie_kmq": "50.3893",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076078",
      "denominazione_ita_altra": "San Severino Lucano",
      "denominazione_ita": "San Severino Lucano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I157",
      "lat": "40.0182229",
      "lon": "16.1418930",
      "superficie_kmq": "61.1583",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076079",
      "denominazione_ita_altra": "Sant'Angelo Le Fratte",
      "denominazione_ita": "Sant'Angelo Le Fratte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I288",
      "lat": "40.5461876",
      "lon": "15.5591307",
      "superficie_kmq": "23.1067",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076080",
      "denominazione_ita_altra": "Sant'Arcangelo",
      "denominazione_ita": "Sant'Arcangelo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I305",
      "lat": "40.2472193",
      "lon": "16.2760410",
      "superficie_kmq": "89.0963",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076081",
      "denominazione_ita_altra": "Sarconi",
      "denominazione_ita": "Sarconi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I426",
      "lat": "40.2477688",
      "lon": "15.8882277",
      "superficie_kmq": "30.6860",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076082",
      "denominazione_ita_altra": "Sasso di Castalda",
      "denominazione_ita": "Sasso di Castalda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I457",
      "lat": "40.4864274",
      "lon": "15.6779016",
      "superficie_kmq": "45.4313",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076083",
      "denominazione_ita_altra": "Satriano di Lucania",
      "denominazione_ita": "Satriano di Lucania",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G614",
      "lat": "40.5449976",
      "lon": "15.6363039",
      "superficie_kmq": "32.9008",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076084",
      "denominazione_ita_altra": "Savoia di Lucania",
      "denominazione_ita": "Savoia di Lucania",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H730",
      "lat": "40.5699030",
      "lon": "15.5522487",
      "superficie_kmq": "32.8373",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076085",
      "denominazione_ita_altra": "Senise",
      "denominazione_ita": "Senise",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I610",
      "lat": "40.1434678",
      "lon": "16.2897182",
      "superficie_kmq": "97.3049",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076086",
      "denominazione_ita_altra": "Spinoso",
      "denominazione_ita": "Spinoso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I917",
      "lat": "40.2653133",
      "lon": "15.9661464",
      "superficie_kmq": "38.1821",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076087",
      "denominazione_ita_altra": "Teana",
      "denominazione_ita": "Teana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L082",
      "lat": "40.1248637",
      "lon": "16.1501665",
      "superficie_kmq": "19.2982",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076088",
      "denominazione_ita_altra": "Terranova di Pollino",
      "denominazione_ita": "Terranova di Pollino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L126",
      "lat": "39.9767413",
      "lon": "16.2982664",
      "superficie_kmq": "113.0690",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076089",
      "denominazione_ita_altra": "Tito",
      "denominazione_ita": "Tito",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L181",
      "lat": "40.5828240",
      "lon": "15.6777522",
      "superficie_kmq": "71.2665",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076090",
      "denominazione_ita_altra": "Tolve",
      "denominazione_ita": "Tolve",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L197",
      "lat": "40.6908600",
      "lon": "16.0117227",
      "superficie_kmq": "128.6832",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076091",
      "denominazione_ita_altra": "Tramutola",
      "denominazione_ita": "Tramutola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L326",
      "lat": "40.3211674",
      "lon": "15.7875784",
      "superficie_kmq": "36.6436",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076092",
      "denominazione_ita_altra": "Trecchina",
      "denominazione_ita": "Trecchina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L357",
      "lat": "40.0265327",
      "lon": "15.7797164",
      "superficie_kmq": "38.1885",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076093",
      "denominazione_ita_altra": "Trivigno",
      "denominazione_ita": "Trivigno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L439",
      "lat": "40.5808809",
      "lon": "15.9892824",
      "superficie_kmq": "25.9965",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076094",
      "denominazione_ita_altra": "Vaglio Basilicata",
      "denominazione_ita": "Vaglio Basilicata",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L532",
      "lat": "40.6657401",
      "lon": "15.9199929",
      "superficie_kmq": "43.3628",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076095",
      "denominazione_ita_altra": "Venosa",
      "denominazione_ita": "Venosa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L738",
      "lat": "40.9644919",
      "lon": "15.8232789",
      "superficie_kmq": "170.3885",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076096",
      "denominazione_ita_altra": "Vietri di Potenza",
      "denominazione_ita": "Vietri di Potenza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L859",
      "lat": "40.5995593",
      "lon": "15.5070113",
      "superficie_kmq": "52.2410",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076097",
      "denominazione_ita_altra": "Viggianello",
      "denominazione_ita": "Viggianello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L873",
      "lat": "39.9729169",
      "lon": "16.0880323",
      "superficie_kmq": "120.8270",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076098",
      "denominazione_ita_altra": "Viggiano",
      "denominazione_ita": "Viggiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L874",
      "lat": "40.3401926",
      "lon": "15.9033669",
      "superficie_kmq": "89.6975",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076099",
      "denominazione_ita_altra": "Ginestra",
      "denominazione_ita": "Ginestra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E033",
      "lat": "40.9304284",
      "lon": "15.7340750",
      "superficie_kmq": "13.3184",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "PZ",
      "codice_istat": "076100",
      "denominazione_ita_altra": "Paterno",
      "denominazione_ita": "Paterno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M269",
      "lat": "40.3773486",
      "lon": "15.7321423",
      "superficie_kmq": "40.7355",
      "codice_sovracomunale": "076"
    },
    {
      "sigla_provincia": "MT",
      "codice_istat": "077001",
      "denominazione_ita_altra": "Accettura",
      "denominazione_ita": "Accettura",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A017",
      "lat": "40.4914410",
      "lon": "16.1573517",
      "superficie_kmq": "90.3668",
      "codice_sovracomunale": "077"
    },
    {
      "sigla_provincia": "MT",
      "codice_istat": "077002",
      "denominazione_ita_altra": "Aliano",
      "denominazione_ita": "Aliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A196",
      "lat": "40.3138927",
      "lon": "16.2310467",
      "superficie_kmq": "98.4081",
      "codice_sovracomunale": "077"
    },
    {
      "sigla_provincia": "MT",
      "codice_istat": "077003",
      "denominazione_ita_altra": "Bernalda",
      "denominazione_ita": "Bernalda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A801",
      "lat": "40.4081157",
      "lon": "16.6863642",
      "superficie_kmq": "126.1966",
      "codice_sovracomunale": "077"
    },
    {
      "sigla_provincia": "MT",
      "codice_istat": "077004",
      "denominazione_ita_altra": "Calciano",
      "denominazione_ita": "Calciano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B391",
      "lat": "40.5873112",
      "lon": "16.1901038",
      "superficie_kmq": "49.6912",
      "codice_sovracomunale": "077"
    },
    {
      "sigla_provincia": "MT",
      "codice_istat": "077005",
      "denominazione_ita_altra": "Cirigliano",
      "denominazione_ita": "Cirigliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C723",
      "lat": "40.3929570",
      "lon": "16.1712069",
      "superficie_kmq": "14.9042",
      "codice_sovracomunale": "077"
    },
    {
      "sigla_provincia": "MT",
      "codice_istat": "077006",
      "denominazione_ita_altra": "Colobraro",
      "denominazione_ita": "Colobraro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C888",
      "lat": "40.1879096",
      "lon": "16.4233878",
      "superficie_kmq": "66.6099",
      "codice_sovracomunale": "077"
    },
    {
      "sigla_provincia": "MT",
      "codice_istat": "077007",
      "denominazione_ita_altra": "Craco",
      "denominazione_ita": "Craco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D128",
      "lat": "40.3585534",
      "lon": "16.4937852",
      "superficie_kmq": "77.0417",
      "codice_sovracomunale": "077"
    },
    {
      "sigla_provincia": "MT",
      "codice_istat": "077008",
      "denominazione_ita_altra": "Ferrandina",
      "denominazione_ita": "Ferrandina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D547",
      "lat": "40.4928454",
      "lon": "16.4568235",
      "superficie_kmq": "218.1083",
      "codice_sovracomunale": "077"
    },
    {
      "sigla_provincia": "MT",
      "codice_istat": "077009",
      "denominazione_ita_altra": "Garaguso",
      "denominazione_ita": "Garaguso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D909",
      "lat": "40.5487609",
      "lon": "16.2271613",
      "superficie_kmq": "38.6109",
      "codice_sovracomunale": "077"
    },
    {
      "sigla_provincia": "MT",
      "codice_istat": "077010",
      "denominazione_ita_altra": "Gorgoglione",
      "denominazione_ita": "Gorgoglione",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E093",
      "lat": "40.3945497",
      "lon": "16.1430984",
      "superficie_kmq": "34.9256",
      "codice_sovracomunale": "077"
    },
    {
      "sigla_provincia": "MT",
      "codice_istat": "077011",
      "denominazione_ita_altra": "Grassano",
      "denominazione_ita": "Grassano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E147",
      "lat": "40.6334826",
      "lon": "16.2842730",
      "superficie_kmq": "41.6299",
      "codice_sovracomunale": "077"
    },
    {
      "sigla_provincia": "MT",
      "codice_istat": "077012",
      "denominazione_ita_altra": "Grottole",
      "denominazione_ita": "Grottole",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E213",
      "lat": "40.6019446",
      "lon": "16.3766569",
      "superficie_kmq": "117.1502",
      "codice_sovracomunale": "077"
    },
    {
      "sigla_provincia": "MT",
      "codice_istat": "077013",
      "denominazione_ita_altra": "Irsina",
      "denominazione_ita": "Irsina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E326",
      "lat": "40.7456291",
      "lon": "16.2431456",
      "superficie_kmq": "263.4530",
      "codice_sovracomunale": "077"
    },
    {
      "sigla_provincia": "MT",
      "codice_istat": "077014",
      "denominazione_ita_altra": "Matera",
      "denominazione_ita": "Matera",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "F052",
      "lat": "40.6674509",
      "lon": "16.5979200",
      "superficie_kmq": "392.0810",
      "codice_sovracomunale": "077"
    },
    {
      "sigla_provincia": "MT",
      "codice_istat": "077015",
      "denominazione_ita_altra": "Miglionico",
      "denominazione_ita": "Miglionico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F201",
      "lat": "40.5657079",
      "lon": "16.5015715",
      "superficie_kmq": "88.8385",
      "codice_sovracomunale": "077"
    },
    {
      "sigla_provincia": "MT",
      "codice_istat": "077016",
      "denominazione_ita_altra": "Montalbano Jonico",
      "denominazione_ita": "Montalbano Jonico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F399",
      "lat": "40.2879820",
      "lon": "16.5688281",
      "superficie_kmq": "135.9956",
      "codice_sovracomunale": "077"
    },
    {
      "sigla_provincia": "MT",
      "codice_istat": "077017",
      "denominazione_ita_altra": "Montescaglioso",
      "denominazione_ita": "Montescaglioso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F637",
      "lat": "40.5519821",
      "lon": "16.6639060",
      "superficie_kmq": "175.7987",
      "codice_sovracomunale": "077"
    },
    {
      "sigla_provincia": "MT",
      "codice_istat": "077018",
      "denominazione_ita_altra": "Nova Siri",
      "denominazione_ita": "Nova Siri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A942",
      "lat": "40.1470648",
      "lon": "16.5387653",
      "superficie_kmq": "52.7498",
      "codice_sovracomunale": "077"
    },
    {
      "sigla_provincia": "MT",
      "codice_istat": "077019",
      "denominazione_ita_altra": "Oliveto Lucano",
      "denominazione_ita": "Oliveto Lucano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G037",
      "lat": "40.5361961",
      "lon": "16.1850887",
      "superficie_kmq": "31.1844",
      "codice_sovracomunale": "077"
    },
    {
      "sigla_provincia": "MT",
      "codice_istat": "077020",
      "denominazione_ita_altra": "Pisticci",
      "denominazione_ita": "Pisticci",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G712",
      "lat": "40.3906649",
      "lon": "16.5586339",
      "superficie_kmq": "233.6684",
      "codice_sovracomunale": "077"
    },
    {
      "sigla_provincia": "MT",
      "codice_istat": "077021",
      "denominazione_ita_altra": "Policoro",
      "denominazione_ita": "Policoro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G786",
      "lat": "40.2086522",
      "lon": "16.6696794",
      "superficie_kmq": "67.6564",
      "codice_sovracomunale": "077"
    },
    {
      "sigla_provincia": "MT",
      "codice_istat": "077022",
      "denominazione_ita_altra": "Pomarico",
      "denominazione_ita": "Pomarico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G806",
      "lat": "40.5177617",
      "lon": "16.5462402",
      "superficie_kmq": "129.6666",
      "codice_sovracomunale": "077"
    },
    {
      "sigla_provincia": "MT",
      "codice_istat": "077023",
      "denominazione_ita_altra": "Rotondella",
      "denominazione_ita": "Rotondella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H591",
      "lat": "40.1724950",
      "lon": "16.5256094",
      "superficie_kmq": "76.7161",
      "codice_sovracomunale": "077"
    },
    {
      "sigla_provincia": "MT",
      "codice_istat": "077024",
      "denominazione_ita_altra": "Salandra",
      "denominazione_ita": "Salandra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H687",
      "lat": "40.5254870",
      "lon": "16.3196438",
      "superficie_kmq": "77.4371",
      "codice_sovracomunale": "077"
    },
    {
      "sigla_provincia": "MT",
      "codice_istat": "077025",
      "denominazione_ita_altra": "San Giorgio Lucano",
      "denominazione_ita": "San Giorgio Lucano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H888",
      "lat": "40.1141046",
      "lon": "16.3895091",
      "superficie_kmq": "39.2579",
      "codice_sovracomunale": "077"
    },
    {
      "sigla_provincia": "MT",
      "codice_istat": "077026",
      "denominazione_ita_altra": "San Mauro Forte",
      "denominazione_ita": "San Mauro Forte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I029",
      "lat": "40.4871582",
      "lon": "16.2523316",
      "superficie_kmq": "87.0579",
      "codice_sovracomunale": "077"
    },
    {
      "sigla_provincia": "MT",
      "codice_istat": "077027",
      "denominazione_ita_altra": "Stigliano",
      "denominazione_ita": "Stigliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I954",
      "lat": "40.4024360",
      "lon": "16.2280472",
      "superficie_kmq": "211.1428",
      "codice_sovracomunale": "077"
    },
    {
      "sigla_provincia": "MT",
      "codice_istat": "077028",
      "denominazione_ita_altra": "Tricarico",
      "denominazione_ita": "Tricarico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L418",
      "lat": "40.6188933",
      "lon": "16.1405715",
      "superficie_kmq": "178.1606",
      "codice_sovracomunale": "077"
    },
    {
      "sigla_provincia": "MT",
      "codice_istat": "077029",
      "denominazione_ita_altra": "Tursi",
      "denominazione_ita": "Tursi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L477",
      "lat": "40.2464286",
      "lon": "16.4772023",
      "superficie_kmq": "159.9300",
      "codice_sovracomunale": "077"
    },
    {
      "sigla_provincia": "MT",
      "codice_istat": "077030",
      "denominazione_ita_altra": "Valsinni",
      "denominazione_ita": "Valsinni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D513",
      "lat": "40.1706069",
      "lon": "16.4419798",
      "superficie_kmq": "32.2192",
      "codice_sovracomunale": "077"
    },
    {
      "sigla_provincia": "MT",
      "codice_istat": "077031",
      "denominazione_ita_altra": "Scanzano Jonico",
      "denominazione_ita": "Scanzano Jonico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M256",
      "lat": "40.2502549",
      "lon": "16.6909045",
      "superficie_kmq": "72.1780",
      "codice_sovracomunale": "077"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078001",
      "denominazione_ita_altra": "Acquaformosa",
      "denominazione_ita": "Acquaformosa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A033",
      "lat": "39.7216983",
      "lon": "16.0900710",
      "superficie_kmq": "22.7052",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078002",
      "denominazione_ita_altra": "Acquappesa",
      "denominazione_ita": "Acquappesa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A041",
      "lat": "39.4945469",
      "lon": "15.9540287",
      "superficie_kmq": "14.4536",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078003",
      "denominazione_ita_altra": "Acri",
      "denominazione_ita": "Acri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A053",
      "lat": "39.4882795",
      "lon": "16.3815423",
      "superficie_kmq": "200.6273",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078004",
      "denominazione_ita_altra": "Aiello Calabro",
      "denominazione_ita": "Aiello Calabro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A102",
      "lat": "39.1168865",
      "lon": "16.1670373",
      "superficie_kmq": "38.5059",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078005",
      "denominazione_ita_altra": "Aieta",
      "denominazione_ita": "Aieta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A105",
      "lat": "39.9282832",
      "lon": "15.8236454",
      "superficie_kmq": "48.2952",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078006",
      "denominazione_ita_altra": "Albidona",
      "denominazione_ita": "Albidona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A160",
      "lat": "39.9226141",
      "lon": "16.4745363",
      "superficie_kmq": "64.6690",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078007",
      "denominazione_ita_altra": "Alessandria del Carretto",
      "denominazione_ita": "Alessandria del Carretto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A183",
      "lat": "39.9584649",
      "lon": "16.3782677",
      "superficie_kmq": "41.1120",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078008",
      "denominazione_ita_altra": "Altilia",
      "denominazione_ita": "Altilia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A234",
      "lat": "39.1300262",
      "lon": "16.2526871",
      "superficie_kmq": "10.5604",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078009",
      "denominazione_ita_altra": "Altomonte",
      "denominazione_ita": "Altomonte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A240",
      "lat": "39.6981317",
      "lon": "16.1292428",
      "superficie_kmq": "65.7159",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078010",
      "denominazione_ita_altra": "Amantea",
      "denominazione_ita": "Amantea",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A253",
      "lat": "39.1327914",
      "lon": "16.0800039",
      "superficie_kmq": "29.4560",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078011",
      "denominazione_ita_altra": "Amendolara",
      "denominazione_ita": "Amendolara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A263",
      "lat": "39.9509419",
      "lon": "16.5840929",
      "superficie_kmq": "60.9084",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078012",
      "denominazione_ita_altra": "Aprigliano",
      "denominazione_ita": "Aprigliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A340",
      "lat": "39.2404544",
      "lon": "16.3407970",
      "superficie_kmq": "122.4229",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078013",
      "denominazione_ita_altra": "Belmonte Calabro",
      "denominazione_ita": "Belmonte Calabro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A762",
      "lat": "39.1604785",
      "lon": "16.0778872",
      "superficie_kmq": "23.9782",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078014",
      "denominazione_ita_altra": "Belsito",
      "denominazione_ita": "Belsito",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A768",
      "lat": "39.1739291",
      "lon": "16.2879397",
      "superficie_kmq": "11.5532",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078015",
      "denominazione_ita_altra": "Belvedere Marittimo",
      "denominazione_ita": "Belvedere Marittimo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A773",
      "lat": "39.6179677",
      "lon": "15.8613079",
      "superficie_kmq": "37.0850",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078016",
      "denominazione_ita_altra": "Bianchi",
      "denominazione_ita": "Bianchi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A842",
      "lat": "39.1007239",
      "lon": "16.4106538",
      "superficie_kmq": "33.3199",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078017",
      "denominazione_ita_altra": "Bisignano",
      "denominazione_ita": "Bisignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A887",
      "lat": "39.5125500",
      "lon": "16.2865125",
      "superficie_kmq": "86.1967",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078018",
      "denominazione_ita_altra": "Bocchigliero",
      "denominazione_ita": "Bocchigliero",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A912",
      "lat": "39.4176775",
      "lon": "16.7562689",
      "superficie_kmq": "98.8185",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078019",
      "denominazione_ita_altra": "Bonifati",
      "denominazione_ita": "Bonifati",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A973",
      "lat": "39.5868378",
      "lon": "15.9050635",
      "superficie_kmq": "33.8466",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078020",
      "denominazione_ita_altra": "Buonvicino",
      "denominazione_ita": "Buonvicino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B270",
      "lat": "39.6888477",
      "lon": "15.8839056",
      "superficie_kmq": "30.5945",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078021",
      "denominazione_ita_altra": "Calopezzati",
      "denominazione_ita": "Calopezzati",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B424",
      "lat": "39.5588163",
      "lon": "16.8017035",
      "superficie_kmq": "22.5740",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078022",
      "denominazione_ita_altra": "Caloveto",
      "denominazione_ita": "Caloveto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B426",
      "lat": "39.5074681",
      "lon": "16.7576341",
      "superficie_kmq": "24.9642",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078023",
      "denominazione_ita_altra": "Campana",
      "denominazione_ita": "Campana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B500",
      "lat": "39.4097513",
      "lon": "16.8260075",
      "superficie_kmq": "104.6457",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078024",
      "denominazione_ita_altra": "Canna",
      "denominazione_ita": "Canna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B607",
      "lat": "40.0947047",
      "lon": "16.5052346",
      "superficie_kmq": "20.3671",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078025",
      "denominazione_ita_altra": "Cariati",
      "denominazione_ita": "Cariati",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B774",
      "lat": "39.4957116",
      "lon": "16.9470806",
      "superficie_kmq": "28.8228",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078026",
      "denominazione_ita_altra": "Carolei",
      "denominazione_ita": "Carolei",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B802",
      "lat": "39.2530166",
      "lon": "16.2186001",
      "superficie_kmq": "15.4296",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078027",
      "denominazione_ita_altra": "Carpanzano",
      "denominazione_ita": "Carpanzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B813",
      "lat": "39.1475788",
      "lon": "16.3035633",
      "superficie_kmq": "14.2733",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078029",
      "denominazione_ita_altra": "Cassano all'Ionio",
      "denominazione_ita": "Cassano all'Ionio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C002",
      "lat": "39.7817979",
      "lon": "16.3192004",
      "superficie_kmq": "159.0676",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078030",
      "denominazione_ita_altra": "Castiglione Cosentino",
      "denominazione_ita": "Castiglione Cosentino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C301",
      "lat": "39.3526316",
      "lon": "16.2882071",
      "superficie_kmq": "14.0869",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078031",
      "denominazione_ita_altra": "Castrolibero",
      "denominazione_ita": "Castrolibero",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C108",
      "lat": "39.3099426",
      "lon": "16.1945284",
      "superficie_kmq": "11.5571",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078032",
      "denominazione_ita_altra": "Castroregio",
      "denominazione_ita": "Castroregio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C348",
      "lat": "39.9925042",
      "lon": "16.4783075",
      "superficie_kmq": "42.0574",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078033",
      "denominazione_ita_altra": "Castrovillari",
      "denominazione_ita": "Castrovillari",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C349",
      "lat": "39.8109028",
      "lon": "16.2022884",
      "superficie_kmq": "130.6354",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078034",
      "denominazione_ita_altra": "Celico",
      "denominazione_ita": "Celico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C430",
      "lat": "39.3105876",
      "lon": "16.3402814",
      "superficie_kmq": "99.7468",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078035",
      "denominazione_ita_altra": "Cellara",
      "denominazione_ita": "Cellara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C437",
      "lat": "39.2178823",
      "lon": "16.3327605",
      "superficie_kmq": "5.8584",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078036",
      "denominazione_ita_altra": "Cerchiara di Calabria",
      "denominazione_ita": "Cerchiara di Calabria",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C489",
      "lat": "39.8590811",
      "lon": "16.3816347",
      "superficie_kmq": "81.9632",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078037",
      "denominazione_ita_altra": "Cerisano",
      "denominazione_ita": "Cerisano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C515",
      "lat": "39.2744659",
      "lon": "16.1783566",
      "superficie_kmq": "15.3177",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078038",
      "denominazione_ita_altra": "Cervicati",
      "denominazione_ita": "Cervicati",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C554",
      "lat": "39.5422911",
      "lon": "16.1268116",
      "superficie_kmq": "12.0861",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078039",
      "denominazione_ita_altra": "Cerzeto",
      "denominazione_ita": "Cerzeto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C560",
      "lat": "39.5076329",
      "lon": "16.1139720",
      "superficie_kmq": "21.8954",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078040",
      "denominazione_ita_altra": "Cetraro",
      "denominazione_ita": "Cetraro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C588",
      "lat": "39.5161374",
      "lon": "15.9430145",
      "superficie_kmq": "66.1416",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078041",
      "denominazione_ita_altra": "Civita",
      "denominazione_ita": "Civita",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C763",
      "lat": "39.8276285",
      "lon": "16.3131820",
      "superficie_kmq": "27.6187",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078042",
      "denominazione_ita_altra": "Cleto",
      "denominazione_ita": "Cleto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C795",
      "lat": "39.1164978",
      "lon": "16.1659183",
      "superficie_kmq": "18.9840",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078043",
      "denominazione_ita_altra": "Colosimi",
      "denominazione_ita": "Colosimi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C905",
      "lat": "39.1187790",
      "lon": "16.3998410",
      "superficie_kmq": "25.5793",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078045",
      "denominazione_ita_altra": "Cosenza",
      "denominazione_ita": "Cosenza",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "D086",
      "lat": "39.2930066",
      "lon": "16.2560903",
      "superficie_kmq": "37.8605",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078046",
      "denominazione_ita_altra": "Cropalati",
      "denominazione_ita": "Cropalati",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D180",
      "lat": "39.5155572",
      "lon": "16.7260555",
      "superficie_kmq": "33.6973",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078047",
      "denominazione_ita_altra": "Crosia",
      "denominazione_ita": "Crosia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D184",
      "lat": "39.5700338",
      "lon": "16.7698686",
      "superficie_kmq": "21.1022",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078048",
      "denominazione_ita_altra": "Diamante",
      "denominazione_ita": "Diamante",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D289",
      "lat": "39.6762500",
      "lon": "15.8217544",
      "superficie_kmq": "12.2068",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078049",
      "denominazione_ita_altra": "Dipignano",
      "denominazione_ita": "Dipignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D304",
      "lat": "39.2375923",
      "lon": "16.2522370",
      "superficie_kmq": "23.3725",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078050",
      "denominazione_ita_altra": "Domanico",
      "denominazione_ita": "Domanico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D328",
      "lat": "39.2151510",
      "lon": "16.2073406",
      "superficie_kmq": "23.6617",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078051",
      "denominazione_ita_altra": "Fagnano Castello",
      "denominazione_ita": "Fagnano Castello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D464",
      "lat": "39.5610819",
      "lon": "16.0544471",
      "superficie_kmq": "29.6743",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078052",
      "denominazione_ita_altra": "Falconara Albanese",
      "denominazione_ita": "Falconara Albanese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D473",
      "lat": "39.2742953",
      "lon": "16.0882704",
      "superficie_kmq": "19.2708",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078053",
      "denominazione_ita_altra": "Figline Vegliaturo",
      "denominazione_ita": "Figline Vegliaturo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D582",
      "lat": "39.2259719",
      "lon": "16.3293960",
      "superficie_kmq": "4.1646",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078054",
      "denominazione_ita_altra": "Firmo",
      "denominazione_ita": "Firmo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D614",
      "lat": "39.7220031",
      "lon": "16.1783315",
      "superficie_kmq": "11.7008",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078055",
      "denominazione_ita_altra": "Fiumefreddo Bruzio",
      "denominazione_ita": "Fiumefreddo Bruzio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D624",
      "lat": "39.2358102",
      "lon": "16.0663407",
      "superficie_kmq": "32.0600",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078056",
      "denominazione_ita_altra": "Francavilla Marittima",
      "denominazione_ita": "Francavilla Marittima",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D764",
      "lat": "39.8197076",
      "lon": "16.3875032",
      "superficie_kmq": "33.0161",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078057",
      "denominazione_ita_altra": "Frascineto",
      "denominazione_ita": "Frascineto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D774",
      "lat": "39.8368998",
      "lon": "16.2628041",
      "superficie_kmq": "29.1137",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078058",
      "denominazione_ita_altra": "Fuscaldo",
      "denominazione_ita": "Fuscaldo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D828",
      "lat": "39.4161846",
      "lon": "16.0313394",
      "superficie_kmq": "60.7961",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078059",
      "denominazione_ita_altra": "Grimaldi",
      "denominazione_ita": "Grimaldi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E180",
      "lat": "39.1396397",
      "lon": "16.2346727",
      "superficie_kmq": "24.7085",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078060",
      "denominazione_ita_altra": "Grisolia",
      "denominazione_ita": "Grisolia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E185",
      "lat": "39.7263773",
      "lon": "15.8537508",
      "superficie_kmq": "51.7482",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078061",
      "denominazione_ita_altra": "Guardia Piemontese",
      "denominazione_ita": "Guardia Piemontese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E242",
      "lat": "39.4651245",
      "lon": "15.9996777",
      "superficie_kmq": "21.4629",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078062",
      "denominazione_ita_altra": "Lago",
      "denominazione_ita": "Lago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E407",
      "lat": "39.1664693",
      "lon": "16.1490945",
      "superficie_kmq": "49.9546",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078063",
      "denominazione_ita_altra": "Laino Borgo",
      "denominazione_ita": "Laino Borgo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E417",
      "lat": "39.9547130",
      "lon": "15.9722930",
      "superficie_kmq": "57.0811",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078064",
      "denominazione_ita_altra": "Laino Castello",
      "denominazione_ita": "Laino Castello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E419",
      "lat": "39.9343758",
      "lon": "15.9744360",
      "superficie_kmq": "37.3359",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078065",
      "denominazione_ita_altra": "Lappano",
      "denominazione_ita": "Lappano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E450",
      "lat": "39.3191871",
      "lon": "16.3138197",
      "superficie_kmq": "12.2096",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078066",
      "denominazione_ita_altra": "Lattarico",
      "denominazione_ita": "Lattarico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E475",
      "lat": "39.4620131",
      "lon": "16.1365957",
      "superficie_kmq": "43.9328",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078067",
      "denominazione_ita_altra": "Longobardi",
      "denominazione_ita": "Longobardi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E677",
      "lat": "39.2073419",
      "lon": "16.0768095",
      "superficie_kmq": "18.2379",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078068",
      "denominazione_ita_altra": "Longobucco",
      "denominazione_ita": "Longobucco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E678",
      "lat": "39.4486412",
      "lon": "16.6094858",
      "superficie_kmq": "212.2541",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078069",
      "denominazione_ita_altra": "Lungro",
      "denominazione_ita": "Lungro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E745",
      "lat": "39.7439995",
      "lon": "16.1217398",
      "superficie_kmq": "35.6500",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078070",
      "denominazione_ita_altra": "Luzzi",
      "denominazione_ita": "Luzzi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E773",
      "lat": "39.4462636",
      "lon": "16.2881684",
      "superficie_kmq": "77.6003",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078071",
      "denominazione_ita_altra": "Maierà",
      "denominazione_ita": "Maierà",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E835",
      "lat": "39.7160536",
      "lon": "15.8504040",
      "superficie_kmq": "17.7812",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078072",
      "denominazione_ita_altra": "Malito",
      "denominazione_ita": "Malito",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E859",
      "lat": "39.1587894",
      "lon": "16.2464581",
      "superficie_kmq": "16.9158",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078073",
      "denominazione_ita_altra": "Malvito",
      "denominazione_ita": "Malvito",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E872",
      "lat": "39.6009780",
      "lon": "16.0534014",
      "superficie_kmq": "38.2373",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078074",
      "denominazione_ita_altra": "Mandatoriccio",
      "denominazione_ita": "Mandatoriccio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E878",
      "lat": "39.4701518",
      "lon": "16.8366865",
      "superficie_kmq": "37.3158",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078075",
      "denominazione_ita_altra": "Mangone",
      "denominazione_ita": "Mangone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E888",
      "lat": "39.2042906",
      "lon": "16.3326165",
      "superficie_kmq": "12.2730",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078076",
      "denominazione_ita_altra": "Marano Marchesato",
      "denominazione_ita": "Marano Marchesato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E914",
      "lat": "39.3149830",
      "lon": "16.1736119",
      "superficie_kmq": "5.0425",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078077",
      "denominazione_ita_altra": "Marano Principato",
      "denominazione_ita": "Marano Principato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E915",
      "lat": "39.3012124",
      "lon": "16.1742377",
      "superficie_kmq": "6.3222",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078078",
      "denominazione_ita_altra": "Marzi",
      "denominazione_ita": "Marzi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F001",
      "lat": "39.1705458",
      "lon": "16.3070385",
      "superficie_kmq": "15.8110",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078079",
      "denominazione_ita_altra": "Mendicino",
      "denominazione_ita": "Mendicino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F125",
      "lat": "39.2620360",
      "lon": "16.1928794",
      "superficie_kmq": "35.6846",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078080",
      "denominazione_ita_altra": "Mongrassano",
      "denominazione_ita": "Mongrassano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F370",
      "lat": "39.5270733",
      "lon": "16.1114374",
      "superficie_kmq": "35.1555",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078081",
      "denominazione_ita_altra": "Montalto Uffugo",
      "denominazione_ita": "Montalto Uffugo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F416",
      "lat": "39.4054448",
      "lon": "16.1584613",
      "superficie_kmq": "76.6698",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078082",
      "denominazione_ita_altra": "Montegiordano",
      "denominazione_ita": "Montegiordano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F519",
      "lat": "40.0440056",
      "lon": "16.5324418",
      "superficie_kmq": "35.8744",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078083",
      "denominazione_ita_altra": "Morano Calabro",
      "denominazione_ita": "Morano Calabro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F708",
      "lat": "39.8417314",
      "lon": "16.1353402",
      "superficie_kmq": "116.2522",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078084",
      "denominazione_ita_altra": "Mormanno",
      "denominazione_ita": "Mormanno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F735",
      "lat": "39.8896585",
      "lon": "15.9875947",
      "superficie_kmq": "78.8798",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078085",
      "denominazione_ita_altra": "Mottafollone",
      "denominazione_ita": "Mottafollone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F775",
      "lat": "39.6469829",
      "lon": "16.0666332",
      "superficie_kmq": "31.5829",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078086",
      "denominazione_ita_altra": "Nocara",
      "denominazione_ita": "Nocara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F907",
      "lat": "40.0988739",
      "lon": "16.4835297",
      "superficie_kmq": "34.0460",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078087",
      "denominazione_ita_altra": "Oriolo",
      "denominazione_ita": "Oriolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G110",
      "lat": "40.0528659",
      "lon": "16.4444815",
      "superficie_kmq": "85.6014",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078088",
      "denominazione_ita_altra": "Orsomarso",
      "denominazione_ita": "Orsomarso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G129",
      "lat": "39.7990101",
      "lon": "15.9078414",
      "superficie_kmq": "90.4127",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078089",
      "denominazione_ita_altra": "Paludi",
      "denominazione_ita": "Paludi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G298",
      "lat": "39.5309429",
      "lon": "16.6785628",
      "superficie_kmq": "41.7394",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078090",
      "denominazione_ita_altra": "Panettieri",
      "denominazione_ita": "Panettieri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G307",
      "lat": "39.0599876",
      "lon": "16.4537414",
      "superficie_kmq": "14.6663",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078091",
      "denominazione_ita_altra": "Paola",
      "denominazione_ita": "Paola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G317",
      "lat": "39.3598564",
      "lon": "16.0415121",
      "superficie_kmq": "42.8807",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078092",
      "denominazione_ita_altra": "Papasidero",
      "denominazione_ita": "Papasidero",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G320",
      "lat": "39.8715173",
      "lon": "15.9058022",
      "superficie_kmq": "55.2168",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078093",
      "denominazione_ita_altra": "Parenti",
      "denominazione_ita": "Parenti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G331",
      "lat": "39.1627199",
      "lon": "16.4127561",
      "superficie_kmq": "37.6176",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078094",
      "denominazione_ita_altra": "Paterno Calabro",
      "denominazione_ita": "Paterno Calabro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G372",
      "lat": "39.2250716",
      "lon": "16.2630945",
      "superficie_kmq": "24.2016",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078096",
      "denominazione_ita_altra": "Pedivigliano",
      "denominazione_ita": "Pedivigliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G411",
      "lat": "39.1102533",
      "lon": "16.3048547",
      "superficie_kmq": "16.6546",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078097",
      "denominazione_ita_altra": "Piane Crati",
      "denominazione_ita": "Piane Crati",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G553",
      "lat": "39.2347143",
      "lon": "16.3229746",
      "superficie_kmq": "2.3317",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078098",
      "denominazione_ita_altra": "Pietrafitta",
      "denominazione_ita": "Pietrafitta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G615",
      "lat": "39.2618397",
      "lon": "16.3387540",
      "superficie_kmq": "9.2390",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078099",
      "denominazione_ita_altra": "Pietrapaola",
      "denominazione_ita": "Pietrapaola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G622",
      "lat": "39.4867537",
      "lon": "16.8159727",
      "superficie_kmq": "52.8163",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078100",
      "denominazione_ita_altra": "Plataci",
      "denominazione_ita": "Plataci",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G733",
      "lat": "39.9006295",
      "lon": "16.4314691",
      "superficie_kmq": "49.4092",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078101",
      "denominazione_ita_altra": "Praia a Mare",
      "denominazione_ita": "Praia a Mare",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G975",
      "lat": "39.8952440",
      "lon": "15.7797730",
      "superficie_kmq": "23.5918",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078102",
      "denominazione_ita_altra": "Rende",
      "denominazione_ita": "Rende",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H235",
      "lat": "39.3314565",
      "lon": "16.1804057",
      "superficie_kmq": "55.2809",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078103",
      "denominazione_ita_altra": "Rocca Imperiale",
      "denominazione_ita": "Rocca Imperiale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H416",
      "lat": "40.1104974",
      "lon": "16.5753435",
      "superficie_kmq": "55.0290",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078104",
      "denominazione_ita_altra": "Roggiano Gravina",
      "denominazione_ita": "Roggiano Gravina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H488",
      "lat": "39.6164000",
      "lon": "16.1581227",
      "superficie_kmq": "44.8772",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078105",
      "denominazione_ita_altra": "Rogliano",
      "denominazione_ita": "Rogliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H490",
      "lat": "39.1799792",
      "lon": "16.3253090",
      "superficie_kmq": "41.6841",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078106",
      "denominazione_ita_altra": "Rose",
      "denominazione_ita": "Rose",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H565",
      "lat": "39.3991749",
      "lon": "16.2978738",
      "superficie_kmq": "47.4913",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078107",
      "denominazione_ita_altra": "Roseto Capo Spulico",
      "denominazione_ita": "Roseto Capo Spulico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H572",
      "lat": "39.9865814",
      "lon": "16.6012578",
      "superficie_kmq": "30.6600",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078109",
      "denominazione_ita_altra": "Rota Greca",
      "denominazione_ita": "Rota Greca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H585",
      "lat": "39.4669282",
      "lon": "16.1135008",
      "superficie_kmq": "13.1240",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078110",
      "denominazione_ita_altra": "Rovito",
      "denominazione_ita": "Rovito",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H621",
      "lat": "39.3098227",
      "lon": "16.3227109",
      "superficie_kmq": "10.6823",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078111",
      "denominazione_ita_altra": "San Basile",
      "denominazione_ita": "San Basile",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H765",
      "lat": "39.8108934",
      "lon": "16.1636007",
      "superficie_kmq": "18.6715",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078112",
      "denominazione_ita_altra": "San Benedetto Ullano",
      "denominazione_ita": "San Benedetto Ullano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H774",
      "lat": "39.4262109",
      "lon": "16.1233182",
      "superficie_kmq": "19.5715",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078113",
      "denominazione_ita_altra": "San Cosmo Albanese",
      "denominazione_ita": "San Cosmo Albanese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H806",
      "lat": "39.5822663",
      "lon": "16.4205576",
      "superficie_kmq": "11.5719",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078114",
      "denominazione_ita_altra": "San Demetrio Corone",
      "denominazione_ita": "San Demetrio Corone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H818",
      "lat": "39.5675974",
      "lon": "16.3631862",
      "superficie_kmq": "61.8737",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078115",
      "denominazione_ita_altra": "San Donato di Ninea",
      "denominazione_ita": "San Donato di Ninea",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H825",
      "lat": "39.7118172",
      "lon": "16.0481943",
      "superficie_kmq": "82.3970",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078116",
      "denominazione_ita_altra": "San Fili",
      "denominazione_ita": "San Fili",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H841",
      "lat": "39.3375485",
      "lon": "16.1423268",
      "superficie_kmq": "20.9596",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078117",
      "denominazione_ita_altra": "Sangineto",
      "denominazione_ita": "Sangineto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H877",
      "lat": "39.6035903",
      "lon": "15.9125039",
      "superficie_kmq": "27.5092",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078118",
      "denominazione_ita_altra": "San Giorgio Albanese",
      "denominazione_ita": "San Giorgio Albanese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H881",
      "lat": "39.5806785",
      "lon": "16.4545264",
      "superficie_kmq": "22.6832",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078119",
      "denominazione_ita_altra": "San Giovanni in Fiore",
      "denominazione_ita": "San Giovanni in Fiore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H919",
      "lat": "39.2535748",
      "lon": "16.6973092",
      "superficie_kmq": "282.5284",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078120",
      "denominazione_ita_altra": "San Lorenzo Bellizzi",
      "denominazione_ita": "San Lorenzo Bellizzi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H961",
      "lat": "39.8891581",
      "lon": "16.3282173",
      "superficie_kmq": "40.6282",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078121",
      "denominazione_ita_altra": "San Lorenzo del Vallo",
      "denominazione_ita": "San Lorenzo del Vallo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H962",
      "lat": "39.6667843",
      "lon": "16.2947186",
      "superficie_kmq": "22.9284",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078122",
      "denominazione_ita_altra": "San Lucido",
      "denominazione_ita": "San Lucido",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H971",
      "lat": "39.3081207",
      "lon": "16.0509334",
      "superficie_kmq": "27.1183",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078123",
      "denominazione_ita_altra": "San Marco Argentano",
      "denominazione_ita": "San Marco Argentano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H981",
      "lat": "39.5567394",
      "lon": "16.1178602",
      "superficie_kmq": "80.4949",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078124",
      "denominazione_ita_altra": "San Martino di Finita",
      "denominazione_ita": "San Martino di Finita",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H992",
      "lat": "39.4906114",
      "lon": "16.1071091",
      "superficie_kmq": "23.8985",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078125",
      "denominazione_ita_altra": "San Nicola Arcella",
      "denominazione_ita": "San Nicola Arcella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I060",
      "lat": "39.8457189",
      "lon": "15.7936417",
      "superficie_kmq": "11.6934",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078126",
      "denominazione_ita_altra": "San Pietro in Amantea",
      "denominazione_ita": "San Pietro in Amantea",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I108",
      "lat": "39.1364997",
      "lon": "16.1124266",
      "superficie_kmq": "9.8441",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078127",
      "denominazione_ita_altra": "San Pietro in Guarano",
      "denominazione_ita": "San Pietro in Guarano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I114",
      "lat": "39.3446604",
      "lon": "16.3121004",
      "superficie_kmq": "48.3442",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078128",
      "denominazione_ita_altra": "San Sosti",
      "denominazione_ita": "San Sosti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I165",
      "lat": "39.6580765",
      "lon": "16.0291540",
      "superficie_kmq": "43.5465",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078129",
      "denominazione_ita_altra": "Santa Caterina Albanese",
      "denominazione_ita": "Santa Caterina Albanese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I171",
      "lat": "39.5857004",
      "lon": "16.0706719",
      "superficie_kmq": "17.3426",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078130",
      "denominazione_ita_altra": "Santa Domenica Talao",
      "denominazione_ita": "Santa Domenica Talao",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I183",
      "lat": "39.8194989",
      "lon": "15.8554220",
      "superficie_kmq": "36.1160",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078131",
      "denominazione_ita_altra": "Sant'Agata di Esaro",
      "denominazione_ita": "Sant'Agata di Esaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I192",
      "lat": "39.6208311",
      "lon": "15.9823731",
      "superficie_kmq": "47.6249",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078132",
      "denominazione_ita_altra": "Santa Maria del Cedro",
      "denominazione_ita": "Santa Maria del Cedro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C717",
      "lat": "39.7488162",
      "lon": "15.8362278",
      "superficie_kmq": "18.4204",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078133",
      "denominazione_ita_altra": "Santa Sofia d'Epiro",
      "denominazione_ita": "Santa Sofia d'Epiro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I309",
      "lat": "39.5458982",
      "lon": "16.3288244",
      "superficie_kmq": "39.2169",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078134",
      "denominazione_ita_altra": "Santo Stefano di Rogliano",
      "denominazione_ita": "Santo Stefano di Rogliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I359",
      "lat": "39.1921535",
      "lon": "16.3207841",
      "superficie_kmq": "19.5644",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078135",
      "denominazione_ita_altra": "San Vincenzo La Costa",
      "denominazione_ita": "San Vincenzo La Costa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I388",
      "lat": "39.3646798",
      "lon": "16.1508812",
      "superficie_kmq": "18.4163",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078136",
      "denominazione_ita_altra": "Saracena",
      "denominazione_ita": "Saracena",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I423",
      "lat": "39.7752009",
      "lon": "16.1561811",
      "superficie_kmq": "109.1501",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078137",
      "denominazione_ita_altra": "Scala Coeli",
      "denominazione_ita": "Scala Coeli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I485",
      "lat": "39.4487471",
      "lon": "16.8858818",
      "superficie_kmq": "67.4966",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078138",
      "denominazione_ita_altra": "Scalea",
      "denominazione_ita": "Scalea",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I489",
      "lat": "39.8063572",
      "lon": "15.7909980",
      "superficie_kmq": "22.5554",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078139",
      "denominazione_ita_altra": "Scigliano",
      "denominazione_ita": "Scigliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D290",
      "lat": "39.1281448",
      "lon": "16.3066997",
      "superficie_kmq": "17.4634",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078140",
      "denominazione_ita_altra": "Serra d'Aiello",
      "denominazione_ita": "Serra d'Aiello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I642",
      "lat": "39.0899562",
      "lon": "16.1261136",
      "superficie_kmq": "4.5118",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078142",
      "denominazione_ita_altra": "Spezzano Albanese",
      "denominazione_ita": "Spezzano Albanese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I895",
      "lat": "39.6617691",
      "lon": "16.3085600",
      "superficie_kmq": "32.2636",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078143",
      "denominazione_ita_altra": "Spezzano della Sila",
      "denominazione_ita": "Spezzano della Sila",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I896",
      "lat": "39.2977244",
      "lon": "16.3373565",
      "superficie_kmq": "80.2874",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078145",
      "denominazione_ita_altra": "Tarsia",
      "denominazione_ita": "Tarsia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L055",
      "lat": "39.6172093",
      "lon": "16.2730079",
      "superficie_kmq": "48.2778",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078146",
      "denominazione_ita_altra": "Terranova da Sibari",
      "denominazione_ita": "Terranova da Sibari",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L124",
      "lat": "39.6557450",
      "lon": "16.3437935",
      "superficie_kmq": "43.4561",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078147",
      "denominazione_ita_altra": "Terravecchia",
      "denominazione_ita": "Terravecchia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L134",
      "lat": "39.4635882",
      "lon": "16.9475152",
      "superficie_kmq": "20.1208",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078148",
      "denominazione_ita_altra": "Torano Castello",
      "denominazione_ita": "Torano Castello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L206",
      "lat": "39.5048197",
      "lon": "16.1447098",
      "superficie_kmq": "30.2216",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078149",
      "denominazione_ita_altra": "Tortora",
      "denominazione_ita": "Tortora",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L305",
      "lat": "39.9220296",
      "lon": "15.7682841",
      "superficie_kmq": "58.2150",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078150",
      "denominazione_ita_altra": "Trebisacce",
      "denominazione_ita": "Trebisacce",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L353",
      "lat": "39.8667265",
      "lon": "16.5304544",
      "superficie_kmq": "26.7168",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078152",
      "denominazione_ita_altra": "Vaccarizzo Albanese",
      "denominazione_ita": "Vaccarizzo Albanese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L524",
      "lat": "39.5869969",
      "lon": "16.4332221",
      "superficie_kmq": "8.5337",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078153",
      "denominazione_ita_altra": "Verbicaro",
      "denominazione_ita": "Verbicaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L747",
      "lat": "39.7548126",
      "lon": "15.9111148",
      "superficie_kmq": "32.6418",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078154",
      "denominazione_ita_altra": "Villapiana",
      "denominazione_ita": "Villapiana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B903",
      "lat": "39.8468912",
      "lon": "16.4551139",
      "superficie_kmq": "39.7330",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078155",
      "denominazione_ita_altra": "Zumpano",
      "denominazione_ita": "Zumpano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M202",
      "lat": "39.3105525",
      "lon": "16.2934974",
      "superficie_kmq": "8.0838",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078156",
      "denominazione_ita_altra": "Casali del Manco",
      "denominazione_ita": "Casali del Manco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M385",
      "lat": "39.2957559",
      "lon": "16.4750876",
      "superficie_kmq": "168.9546",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CS",
      "codice_istat": "078157",
      "denominazione_ita_altra": "Corigliano-Rossano",
      "denominazione_ita": "Corigliano-Rossano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M403",
      "lat": "39.6064577",
      "lon": "16.5539503",
      "superficie_kmq": "346.5524",
      "codice_sovracomunale": "078"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079002",
      "denominazione_ita_altra": "Albi",
      "denominazione_ita": "Albi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A155",
      "lat": "39.0244735",
      "lon": "16.5972030",
      "superficie_kmq": "29.6368",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079003",
      "denominazione_ita_altra": "Amaroni",
      "denominazione_ita": "Amaroni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A255",
      "lat": "38.7935293",
      "lon": "16.4483363",
      "superficie_kmq": "9.8829",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079004",
      "denominazione_ita_altra": "Amato",
      "denominazione_ita": "Amato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A257",
      "lat": "38.9413085",
      "lon": "16.4627530",
      "superficie_kmq": "20.9328",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079005",
      "denominazione_ita_altra": "Andali",
      "denominazione_ita": "Andali",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A272",
      "lat": "39.0138582",
      "lon": "16.7670366",
      "superficie_kmq": "17.8675",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079007",
      "denominazione_ita_altra": "Argusto",
      "denominazione_ita": "Argusto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A397",
      "lat": "38.6807185",
      "lon": "16.4358694",
      "superficie_kmq": "6.8792",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079008",
      "denominazione_ita_altra": "Badolato",
      "denominazione_ita": "Badolato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A542",
      "lat": "38.5681923",
      "lon": "16.5258858",
      "superficie_kmq": "37.0665",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079009",
      "denominazione_ita_altra": "Belcastro",
      "denominazione_ita": "Belcastro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A736",
      "lat": "39.0173234",
      "lon": "16.7875414",
      "superficie_kmq": "53.2162",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079011",
      "denominazione_ita_altra": "Borgia",
      "denominazione_ita": "Borgia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B002",
      "lat": "38.8272395",
      "lon": "16.5142418",
      "superficie_kmq": "42.3744",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079012",
      "denominazione_ita_altra": "Botricello",
      "denominazione_ita": "Botricello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B085",
      "lat": "38.9372524",
      "lon": "16.8612599",
      "superficie_kmq": "15.4779",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079017",
      "denominazione_ita_altra": "Caraffa di Catanzaro",
      "denominazione_ita": "Caraffa di Catanzaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B717",
      "lat": "38.8802706",
      "lon": "16.4872404",
      "superficie_kmq": "25.0502",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079018",
      "denominazione_ita_altra": "Cardinale",
      "denominazione_ita": "Cardinale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B758",
      "lat": "38.6423242",
      "lon": "16.3861283",
      "superficie_kmq": "30.1190",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079020",
      "denominazione_ita_altra": "Carlopoli",
      "denominazione_ita": "Carlopoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B790",
      "lat": "39.0570803",
      "lon": "16.4548618",
      "superficie_kmq": "16.4125",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079023",
      "denominazione_ita_altra": "Catanzaro",
      "denominazione_ita": "Catanzaro",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "C352",
      "lat": "38.9058909",
      "lon": "16.5943952",
      "superficie_kmq": "112.7223",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079024",
      "denominazione_ita_altra": "Cenadi",
      "denominazione_ita": "Cenadi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C453",
      "lat": "38.7171595",
      "lon": "16.4100475",
      "superficie_kmq": "11.9153",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079025",
      "denominazione_ita_altra": "Centrache",
      "denominazione_ita": "Centrache",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C472",
      "lat": "38.7294367",
      "lon": "16.4301677",
      "superficie_kmq": "7.9582",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079027",
      "denominazione_ita_altra": "Cerva",
      "denominazione_ita": "Cerva",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C542",
      "lat": "39.0202377",
      "lon": "16.7424645",
      "superficie_kmq": "21.3688",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079029",
      "denominazione_ita_altra": "Chiaravalle Centrale",
      "denominazione_ita": "Chiaravalle Centrale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C616",
      "lat": "38.6839886",
      "lon": "16.4141866",
      "superficie_kmq": "23.8247",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079030",
      "denominazione_ita_altra": "Cicala",
      "denominazione_ita": "Cicala",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C674",
      "lat": "39.0229972",
      "lon": "16.4863553",
      "superficie_kmq": "9.2845",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079033",
      "denominazione_ita_altra": "Conflenti",
      "denominazione_ita": "Conflenti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C960",
      "lat": "39.0723122",
      "lon": "16.2843184",
      "superficie_kmq": "29.3399",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079034",
      "denominazione_ita_altra": "Cortale",
      "denominazione_ita": "Cortale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D049",
      "lat": "38.8422805",
      "lon": "16.4112338",
      "superficie_kmq": "30.0099",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079036",
      "denominazione_ita_altra": "Cropani",
      "denominazione_ita": "Cropani",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D181",
      "lat": "38.9681294",
      "lon": "16.7828212",
      "superficie_kmq": "44.8062",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079039",
      "denominazione_ita_altra": "Curinga",
      "denominazione_ita": "Curinga",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D218",
      "lat": "38.8288344",
      "lon": "16.3187669",
      "superficie_kmq": "52.5252",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079042",
      "denominazione_ita_altra": "Davoli",
      "denominazione_ita": "Davoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D257",
      "lat": "38.6488764",
      "lon": "16.4868150",
      "superficie_kmq": "25.0251",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079043",
      "denominazione_ita_altra": "Decollatura",
      "denominazione_ita": "Decollatura",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D261",
      "lat": "39.0466362",
      "lon": "16.3568953",
      "superficie_kmq": "50.8301",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079047",
      "denominazione_ita_altra": "Falerna",
      "denominazione_ita": "Falerna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D476",
      "lat": "39.0021017",
      "lon": "16.1683440",
      "superficie_kmq": "24.0411",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079048",
      "denominazione_ita_altra": "Feroleto Antico",
      "denominazione_ita": "Feroleto Antico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D544",
      "lat": "38.9616823",
      "lon": "16.3874138",
      "superficie_kmq": "22.3812",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079052",
      "denominazione_ita_altra": "Fossato Serralta",
      "denominazione_ita": "Fossato Serralta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D744",
      "lat": "38.9956490",
      "lon": "16.5798297",
      "superficie_kmq": "11.8533",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079055",
      "denominazione_ita_altra": "Gagliato",
      "denominazione_ita": "Gagliato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D852",
      "lat": "38.6767363",
      "lon": "16.4635029",
      "superficie_kmq": "7.0372",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079056",
      "denominazione_ita_altra": "Gasperina",
      "denominazione_ita": "Gasperina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D932",
      "lat": "38.7391769",
      "lon": "16.5095835",
      "superficie_kmq": "6.7817",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079058",
      "denominazione_ita_altra": "Gimigliano",
      "denominazione_ita": "Gimigliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E031",
      "lat": "38.9753587",
      "lon": "16.5291091",
      "superficie_kmq": "33.5464",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079059",
      "denominazione_ita_altra": "Girifalco",
      "denominazione_ita": "Girifalco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E050",
      "lat": "38.8223238",
      "lon": "16.4255392",
      "superficie_kmq": "43.0791",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079060",
      "denominazione_ita_altra": "Gizzeria",
      "denominazione_ita": "Gizzeria",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E068",
      "lat": "38.9789858",
      "lon": "16.2081146",
      "superficie_kmq": "37.1937",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079061",
      "denominazione_ita_altra": "Guardavalle",
      "denominazione_ita": "Guardavalle",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E239",
      "lat": "38.5058128",
      "lon": "16.5062654",
      "superficie_kmq": "60.2658",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079063",
      "denominazione_ita_altra": "Isca sullo Ionio",
      "denominazione_ita": "Isca sullo Ionio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E328",
      "lat": "38.6004673",
      "lon": "16.5189697",
      "superficie_kmq": "23.5552",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079065",
      "denominazione_ita_altra": "Jacurso",
      "denominazione_ita": "Jacurso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E274",
      "lat": "38.8472856",
      "lon": "16.3785761",
      "superficie_kmq": "21.2035",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079068",
      "denominazione_ita_altra": "Magisano",
      "denominazione_ita": "Magisano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E806",
      "lat": "39.0147922",
      "lon": "16.6286556",
      "superficie_kmq": "31.9401",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079069",
      "denominazione_ita_altra": "Maida",
      "denominazione_ita": "Maida",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E834",
      "lat": "38.8581890",
      "lon": "16.3654736",
      "superficie_kmq": "58.3341",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079071",
      "denominazione_ita_altra": "Marcedusa",
      "denominazione_ita": "Marcedusa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E923",
      "lat": "39.0269497",
      "lon": "16.8357881",
      "superficie_kmq": "15.6834",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079072",
      "denominazione_ita_altra": "Marcellinara",
      "denominazione_ita": "Marcellinara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E925",
      "lat": "38.9291832",
      "lon": "16.4944176",
      "superficie_kmq": "20.9053",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079073",
      "denominazione_ita_altra": "Martirano",
      "denominazione_ita": "Martirano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E990",
      "lat": "39.0829501",
      "lon": "16.2475771",
      "superficie_kmq": "14.8982",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079074",
      "denominazione_ita_altra": "Martirano Lombardo",
      "denominazione_ita": "Martirano Lombardo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E991",
      "lat": "39.0756424",
      "lon": "16.2310461",
      "superficie_kmq": "19.8432",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079077",
      "denominazione_ita_altra": "Miglierina",
      "denominazione_ita": "Miglierina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F200",
      "lat": "38.9475269",
      "lon": "16.4724541",
      "superficie_kmq": "13.9398",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079080",
      "denominazione_ita_altra": "Montauro",
      "denominazione_ita": "Montauro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F432",
      "lat": "38.7496900",
      "lon": "16.5097843",
      "superficie_kmq": "11.7399",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079081",
      "denominazione_ita_altra": "Montepaone",
      "denominazione_ita": "Montepaone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F586",
      "lat": "38.7216000",
      "lon": "16.4986371",
      "superficie_kmq": "16.8499",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079083",
      "denominazione_ita_altra": "Motta Santa Lucia",
      "denominazione_ita": "Motta Santa Lucia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F780",
      "lat": "39.0919936",
      "lon": "16.2928589",
      "superficie_kmq": "26.2959",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079087",
      "denominazione_ita_altra": "Nocera Terinese",
      "denominazione_ita": "Nocera Terinese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F910",
      "lat": "39.0373642",
      "lon": "16.1639965",
      "superficie_kmq": "46.5793",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079088",
      "denominazione_ita_altra": "Olivadi",
      "denominazione_ita": "Olivadi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G034",
      "lat": "38.7257243",
      "lon": "16.4209801",
      "superficie_kmq": "7.1687",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079089",
      "denominazione_ita_altra": "Palermiti",
      "denominazione_ita": "Palermiti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G272",
      "lat": "38.7481873",
      "lon": "16.4519837",
      "superficie_kmq": "18.3841",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079092",
      "denominazione_ita_altra": "Pentone",
      "denominazione_ita": "Pentone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G439",
      "lat": "38.9849068",
      "lon": "16.5821222",
      "superficie_kmq": "12.3828",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079094",
      "denominazione_ita_altra": "Petrizzi",
      "denominazione_ita": "Petrizzi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G517",
      "lat": "38.7024023",
      "lon": "16.4739959",
      "superficie_kmq": "21.8991",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079095",
      "denominazione_ita_altra": "Petronà",
      "denominazione_ita": "Petronà",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G518",
      "lat": "39.0393959",
      "lon": "16.7561616",
      "superficie_kmq": "46.1278",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079096",
      "denominazione_ita_altra": "Pianopoli",
      "denominazione_ita": "Pianopoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D546",
      "lat": "38.9542818",
      "lon": "16.3891682",
      "superficie_kmq": "24.6500",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079099",
      "denominazione_ita_altra": "Platania",
      "denominazione_ita": "Platania",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G734",
      "lat": "39.0043379",
      "lon": "16.3187594",
      "superficie_kmq": "26.8396",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079108",
      "denominazione_ita_altra": "San Floro",
      "denominazione_ita": "San Floro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H846",
      "lat": "38.8376151",
      "lon": "16.5185877",
      "superficie_kmq": "18.3199",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079110",
      "denominazione_ita_altra": "San Mango d'Aquino",
      "denominazione_ita": "San Mango d'Aquino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H976",
      "lat": "39.0599977",
      "lon": "16.1896988",
      "superficie_kmq": "6.8918",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079114",
      "denominazione_ita_altra": "San Pietro a Maida",
      "denominazione_ita": "San Pietro a Maida",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I093",
      "lat": "38.8462705",
      "lon": "16.3476967",
      "superficie_kmq": "16.4530",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079115",
      "denominazione_ita_altra": "San Pietro Apostolo",
      "denominazione_ita": "San Pietro Apostolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I095",
      "lat": "39.0046225",
      "lon": "16.4689952",
      "superficie_kmq": "11.7227",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079116",
      "denominazione_ita_altra": "San Sostene",
      "denominazione_ita": "San Sostene",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I164",
      "lat": "38.6372381",
      "lon": "16.4875062",
      "superficie_kmq": "32.4844",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079117",
      "denominazione_ita_altra": "Santa Caterina dello Ionio",
      "denominazione_ita": "Santa Caterina dello Ionio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I170",
      "lat": "38.5348854",
      "lon": "16.5210015",
      "superficie_kmq": "40.6942",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079118",
      "denominazione_ita_altra": "Sant'Andrea Apostolo dello Ionio",
      "denominazione_ita": "Sant'Andrea Apostolo dello Ionio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I266",
      "lat": "38.6231705",
      "lon": "16.5285752",
      "superficie_kmq": "21.4332",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079122",
      "denominazione_ita_altra": "San Vito sullo Ionio",
      "denominazione_ita": "San Vito sullo Ionio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I393",
      "lat": "38.7105187",
      "lon": "16.4071080",
      "superficie_kmq": "17.1712",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079123",
      "denominazione_ita_altra": "Satriano",
      "denominazione_ita": "Satriano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I463",
      "lat": "38.6676893",
      "lon": "16.4828999",
      "superficie_kmq": "21.1558",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079126",
      "denominazione_ita_altra": "Sellia",
      "denominazione_ita": "Sellia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I589",
      "lat": "38.9815826",
      "lon": "16.6282011",
      "superficie_kmq": "12.8090",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079127",
      "denominazione_ita_altra": "Sellia Marina",
      "denominazione_ita": "Sellia Marina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I590",
      "lat": "38.9035746",
      "lon": "16.7449964",
      "superficie_kmq": "41.4550",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079129",
      "denominazione_ita_altra": "Serrastretta",
      "denominazione_ita": "Serrastretta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I655",
      "lat": "39.0125050",
      "lon": "16.4180661",
      "superficie_kmq": "41.6486",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079130",
      "denominazione_ita_altra": "Sersale",
      "denominazione_ita": "Sersale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I671",
      "lat": "39.0120684",
      "lon": "16.7256040",
      "superficie_kmq": "53.2973",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079131",
      "denominazione_ita_altra": "Settingiano",
      "denominazione_ita": "Settingiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I704",
      "lat": "38.9140532",
      "lon": "16.5132950",
      "superficie_kmq": "14.3494",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079133",
      "denominazione_ita_altra": "Simeri Crichi",
      "denominazione_ita": "Simeri Crichi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I745",
      "lat": "38.9551742",
      "lon": "16.6461581",
      "superficie_kmq": "46.7436",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079134",
      "denominazione_ita_altra": "Sorbo San Basile",
      "denominazione_ita": "Sorbo San Basile",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I844",
      "lat": "39.0193195",
      "lon": "16.5693599",
      "superficie_kmq": "59.2790",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079137",
      "denominazione_ita_altra": "Soverato",
      "denominazione_ita": "Soverato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I872",
      "lat": "38.6883927",
      "lon": "16.5494021",
      "superficie_kmq": "7.7924",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079138",
      "denominazione_ita_altra": "Soveria Mannelli",
      "denominazione_ita": "Soveria Mannelli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I874",
      "lat": "39.0840090",
      "lon": "16.3734129",
      "superficie_kmq": "20.4957",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079139",
      "denominazione_ita_altra": "Soveria Simeri",
      "denominazione_ita": "Soveria Simeri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I875",
      "lat": "38.9461796",
      "lon": "16.6804295",
      "superficie_kmq": "22.2796",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079142",
      "denominazione_ita_altra": "Squillace",
      "denominazione_ita": "Squillace",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I929",
      "lat": "38.7811682",
      "lon": "16.5192319",
      "superficie_kmq": "34.3260",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079143",
      "denominazione_ita_altra": "Stalettì",
      "denominazione_ita": "Stalettì",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I937",
      "lat": "38.7660907",
      "lon": "16.5409849",
      "superficie_kmq": "12.1084",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079146",
      "denominazione_ita_altra": "Taverna",
      "denominazione_ita": "Taverna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L070",
      "lat": "39.0199130",
      "lon": "16.5824597",
      "superficie_kmq": "132.3058",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079147",
      "denominazione_ita_altra": "Tiriolo",
      "denominazione_ita": "Tiriolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L177",
      "lat": "38.9461260",
      "lon": "16.5075112",
      "superficie_kmq": "29.2624",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079148",
      "denominazione_ita_altra": "Torre di Ruggiero",
      "denominazione_ita": "Torre di Ruggiero",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L240",
      "lat": "38.6529880",
      "lon": "16.3727904",
      "superficie_kmq": "25.3698",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079151",
      "denominazione_ita_altra": "Vallefiorita",
      "denominazione_ita": "Vallefiorita",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I322",
      "lat": "38.7762676",
      "lon": "16.4643744",
      "superficie_kmq": "13.8782",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079157",
      "denominazione_ita_altra": "Zagarise",
      "denominazione_ita": "Zagarise",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M140",
      "lat": "38.9996616",
      "lon": "16.6605909",
      "superficie_kmq": "49.3295",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "CZ",
      "codice_istat": "079160",
      "denominazione_ita_altra": "Lamezia Terme",
      "denominazione_ita": "Lamezia Terme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M208",
      "lat": "38.9751367",
      "lon": "16.3193816",
      "superficie_kmq": "162.4255",
      "codice_sovracomunale": "079"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080001",
      "denominazione_ita_altra": "Africo",
      "denominazione_ita": "Africo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A065",
      "lat": "38.0500664",
      "lon": "16.1303676",
      "superficie_kmq": "53.8961",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080002",
      "denominazione_ita_altra": "Agnana Calabra",
      "denominazione_ita": "Agnana Calabra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A077",
      "lat": "38.3022655",
      "lon": "16.2251397",
      "superficie_kmq": "8.4872",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080003",
      "denominazione_ita_altra": "Anoia",
      "denominazione_ita": "Anoia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A303",
      "lat": "38.4357066",
      "lon": "16.0837119",
      "superficie_kmq": "10.1679",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080004",
      "denominazione_ita_altra": "Antonimina",
      "denominazione_ita": "Antonimina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A314",
      "lat": "38.2728844",
      "lon": "16.1482340",
      "superficie_kmq": "22.9063",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080005",
      "denominazione_ita_altra": "Ardore",
      "denominazione_ita": "Ardore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A385",
      "lat": "38.1916281",
      "lon": "16.1683901",
      "superficie_kmq": "32.7748",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080006",
      "denominazione_ita_altra": "Bagaladi",
      "denominazione_ita": "Bagaladi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A544",
      "lat": "38.0252727",
      "lon": "15.8205560",
      "superficie_kmq": "30.0185",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080007",
      "denominazione_ita_altra": "Bagnara Calabra",
      "denominazione_ita": "Bagnara Calabra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A552",
      "lat": "38.2841241",
      "lon": "15.8004307",
      "superficie_kmq": "24.8528",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080008",
      "denominazione_ita_altra": "Benestare",
      "denominazione_ita": "Benestare",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A780",
      "lat": "38.1864605",
      "lon": "16.1393116",
      "superficie_kmq": "18.7159",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080009",
      "denominazione_ita_altra": "Bianco",
      "denominazione_ita": "Bianco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A843",
      "lat": "38.0887684",
      "lon": "16.1485336",
      "superficie_kmq": "29.9844",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080010",
      "denominazione_ita_altra": "Bivongi",
      "denominazione_ita": "Bivongi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A897",
      "lat": "38.4815821",
      "lon": "16.4533814",
      "superficie_kmq": "25.3448",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080011",
      "denominazione_ita_altra": "Bova",
      "denominazione_ita": "Bova",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B097",
      "lat": "37.9944729",
      "lon": "15.9317928",
      "superficie_kmq": "46.9429",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080012",
      "denominazione_ita_altra": "Bovalino",
      "denominazione_ita": "Bovalino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B098",
      "lat": "38.1465910",
      "lon": "16.1741109",
      "superficie_kmq": "18.0561",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080013",
      "denominazione_ita_altra": "Bova Marina",
      "denominazione_ita": "Bova Marina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B099",
      "lat": "37.9322928",
      "lon": "15.9207982",
      "superficie_kmq": "29.4995",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080014",
      "denominazione_ita_altra": "Brancaleone",
      "denominazione_ita": "Brancaleone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B118",
      "lat": "37.9669238",
      "lon": "16.1030409",
      "superficie_kmq": "36.1371",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080015",
      "denominazione_ita_altra": "Bruzzano Zeffirio",
      "denominazione_ita": "Bruzzano Zeffirio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B234",
      "lat": "38.0125272",
      "lon": "16.0813261",
      "superficie_kmq": "20.7427",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080016",
      "denominazione_ita_altra": "Calanna",
      "denominazione_ita": "Calanna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B379",
      "lat": "38.1824752",
      "lon": "15.7226180",
      "superficie_kmq": "10.9648",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080017",
      "denominazione_ita_altra": "Camini",
      "denominazione_ita": "Camini",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B481",
      "lat": "38.4319414",
      "lon": "16.4817202",
      "superficie_kmq": "17.4105",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080018",
      "denominazione_ita_altra": "Campo Calabro",
      "denominazione_ita": "Campo Calabro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B516",
      "lat": "38.2146051",
      "lon": "15.6580447",
      "superficie_kmq": "8.0092",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080019",
      "denominazione_ita_altra": "Candidoni",
      "denominazione_ita": "Candidoni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B591",
      "lat": "38.5048946",
      "lon": "16.0857155",
      "superficie_kmq": "26.9521",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080020",
      "denominazione_ita_altra": "Canolo",
      "denominazione_ita": "Canolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B617",
      "lat": "38.3150485",
      "lon": "16.2003564",
      "superficie_kmq": "28.2971",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080021",
      "denominazione_ita_altra": "Caraffa del Bianco",
      "denominazione_ita": "Caraffa del Bianco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B718",
      "lat": "38.0920740",
      "lon": "16.0854108",
      "superficie_kmq": "11.4621",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080022",
      "denominazione_ita_altra": "Cardeto",
      "denominazione_ita": "Cardeto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B756",
      "lat": "38.0843067",
      "lon": "15.7657599",
      "superficie_kmq": "37.2657",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080023",
      "denominazione_ita_altra": "Careri",
      "denominazione_ita": "Careri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B766",
      "lat": "38.1785305",
      "lon": "16.1162020",
      "superficie_kmq": "38.1623",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080024",
      "denominazione_ita_altra": "Casignana",
      "denominazione_ita": "Casignana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B966",
      "lat": "38.0992196",
      "lon": "16.0900842",
      "superficie_kmq": "24.5414",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080025",
      "denominazione_ita_altra": "Caulonia",
      "denominazione_ita": "Caulonia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C285",
      "lat": "38.3813011",
      "lon": "16.4104839",
      "superficie_kmq": "101.7562",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080026",
      "denominazione_ita_altra": "Ciminà",
      "denominazione_ita": "Ciminà",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C695",
      "lat": "38.2465809",
      "lon": "16.1415378",
      "superficie_kmq": "49.2342",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080027",
      "denominazione_ita_altra": "Cinquefrondi",
      "denominazione_ita": "Cinquefrondi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C710",
      "lat": "38.4160961",
      "lon": "16.0966248",
      "superficie_kmq": "29.9509",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080028",
      "denominazione_ita_altra": "Cittanova",
      "denominazione_ita": "Cittanova",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C747",
      "lat": "38.3552879",
      "lon": "16.0803688",
      "superficie_kmq": "61.9827",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080029",
      "denominazione_ita_altra": "Condofuri",
      "denominazione_ita": "Condofuri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C954",
      "lat": "38.0041672",
      "lon": "15.8590026",
      "superficie_kmq": "60.2945",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080030",
      "denominazione_ita_altra": "Cosoleto",
      "denominazione_ita": "Cosoleto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D089",
      "lat": "38.2744916",
      "lon": "15.9292156",
      "superficie_kmq": "34.3736",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080031",
      "denominazione_ita_altra": "Delianuova",
      "denominazione_ita": "Delianuova",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D268",
      "lat": "38.2402860",
      "lon": "15.9181542",
      "superficie_kmq": "21.3800",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080032",
      "denominazione_ita_altra": "Feroleto della Chiesa",
      "denominazione_ita": "Feroleto della Chiesa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D545",
      "lat": "38.4660022",
      "lon": "16.0701141",
      "superficie_kmq": "7.5555",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080033",
      "denominazione_ita_altra": "Ferruzzano",
      "denominazione_ita": "Ferruzzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D557",
      "lat": "38.0392402",
      "lon": "16.0876395",
      "superficie_kmq": "19.1061",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080034",
      "denominazione_ita_altra": "Fiumara",
      "denominazione_ita": "Fiumara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D619",
      "lat": "38.2107791",
      "lon": "15.6921595",
      "superficie_kmq": "6.5876",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080035",
      "denominazione_ita_altra": "Galatro",
      "denominazione_ita": "Galatro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D864",
      "lat": "38.4601775",
      "lon": "16.1084851",
      "superficie_kmq": "51.3403",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080036",
      "denominazione_ita_altra": "Gerace",
      "denominazione_ita": "Gerace",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D975",
      "lat": "38.2715077",
      "lon": "16.2181525",
      "superficie_kmq": "28.9874",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080037",
      "denominazione_ita_altra": "Giffone",
      "denominazione_ita": "Giffone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E025",
      "lat": "38.4387783",
      "lon": "16.1499866",
      "superficie_kmq": "14.7186",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080038",
      "denominazione_ita_altra": "Gioia Tauro",
      "denominazione_ita": "Gioia Tauro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E041",
      "lat": "38.4297884",
      "lon": "15.8972880",
      "superficie_kmq": "39.8668",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080039",
      "denominazione_ita_altra": "Gioiosa Ionica",
      "denominazione_ita": "Gioiosa Ionica",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E044",
      "lat": "38.3362254",
      "lon": "16.3025153",
      "superficie_kmq": "36.0708",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080040",
      "denominazione_ita_altra": "Grotteria",
      "denominazione_ita": "Grotteria",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E212",
      "lat": "38.3683634",
      "lon": "16.2651946",
      "superficie_kmq": "37.9745",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080041",
      "denominazione_ita_altra": "Laganadi",
      "denominazione_ita": "Laganadi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E402",
      "lat": "38.1743394",
      "lon": "15.7419485",
      "superficie_kmq": "8.1905",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080042",
      "denominazione_ita_altra": "Laureana di Borrello",
      "denominazione_ita": "Laureana di Borrello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E479",
      "lat": "38.4894736",
      "lon": "16.0818683",
      "superficie_kmq": "35.6906",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080043",
      "denominazione_ita_altra": "Locri",
      "denominazione_ita": "Locri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D976",
      "lat": "38.2366537",
      "lon": "16.2640203",
      "superficie_kmq": "25.7538",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080044",
      "denominazione_ita_altra": "Mammola",
      "denominazione_ita": "Mammola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E873",
      "lat": "38.3619809",
      "lon": "16.2405095",
      "superficie_kmq": "81.0708",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080045",
      "denominazione_ita_altra": "Marina di Gioiosa Ionica",
      "denominazione_ita": "Marina di Gioiosa Ionica",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E956",
      "lat": "38.3032293",
      "lon": "16.3290633",
      "superficie_kmq": "16.1551",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080046",
      "denominazione_ita_altra": "Maropati",
      "denominazione_ita": "Maropati",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E968",
      "lat": "38.4425693",
      "lon": "16.0964968",
      "superficie_kmq": "10.5190",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080047",
      "denominazione_ita_altra": "Martone",
      "denominazione_ita": "Martone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E993",
      "lat": "38.3536567",
      "lon": "16.2873022",
      "superficie_kmq": "8.3362",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080048",
      "denominazione_ita_altra": "Melicuccà",
      "denominazione_ita": "Melicuccà",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F105",
      "lat": "38.3021107",
      "lon": "15.8819091",
      "superficie_kmq": "17.4033",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080049",
      "denominazione_ita_altra": "Melicucco",
      "denominazione_ita": "Melicucco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F106",
      "lat": "38.4338665",
      "lon": "16.0597713",
      "superficie_kmq": "6.5328",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080050",
      "denominazione_ita_altra": "Melito di Porto Salvo",
      "denominazione_ita": "Melito di Porto Salvo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F112",
      "lat": "37.9207738",
      "lon": "15.7850856",
      "superficie_kmq": "35.4083",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080051",
      "denominazione_ita_altra": "Molochio",
      "denominazione_ita": "Molochio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F301",
      "lat": "38.3047385",
      "lon": "16.0318296",
      "superficie_kmq": "37.4469",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080052",
      "denominazione_ita_altra": "Monasterace",
      "denominazione_ita": "Monasterace",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F324",
      "lat": "38.4535456",
      "lon": "16.5527392",
      "superficie_kmq": "15.7333",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080053",
      "denominazione_ita_altra": "Montebello Jonico",
      "denominazione_ita": "Montebello Jonico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D746",
      "lat": "37.9815922",
      "lon": "15.7582615",
      "superficie_kmq": "56.4508",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080054",
      "denominazione_ita_altra": "Motta San Giovanni",
      "denominazione_ita": "Motta San Giovanni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F779",
      "lat": "38.0016594",
      "lon": "15.6973085",
      "superficie_kmq": "46.4757",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080055",
      "denominazione_ita_altra": "Oppido Mamertina",
      "denominazione_ita": "Oppido Mamertina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G082",
      "lat": "38.2925175",
      "lon": "15.9855899",
      "superficie_kmq": "58.8739",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080056",
      "denominazione_ita_altra": "Palizzi",
      "denominazione_ita": "Palizzi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G277",
      "lat": "37.9191807",
      "lon": "15.9833096",
      "superficie_kmq": "52.6163",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080057",
      "denominazione_ita_altra": "Palmi",
      "denominazione_ita": "Palmi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G288",
      "lat": "38.3572886",
      "lon": "15.8498065",
      "superficie_kmq": "32.1210",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080058",
      "denominazione_ita_altra": "Pazzano",
      "denominazione_ita": "Pazzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G394",
      "lat": "38.4770732",
      "lon": "16.4505076",
      "superficie_kmq": "15.5690",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080059",
      "denominazione_ita_altra": "Placanica",
      "denominazione_ita": "Placanica",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G729",
      "lat": "38.4097087",
      "lon": "16.4534411",
      "superficie_kmq": "29.5105",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080060",
      "denominazione_ita_altra": "Platì",
      "denominazione_ita": "Platì",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G735",
      "lat": "38.2201857",
      "lon": "16.0439598",
      "superficie_kmq": "50.8735",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080061",
      "denominazione_ita_altra": "Polistena",
      "denominazione_ita": "Polistena",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G791",
      "lat": "38.4078814",
      "lon": "16.0761673",
      "superficie_kmq": "11.7699",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080062",
      "denominazione_ita_altra": "Portigliola",
      "denominazione_ita": "Portigliola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G905",
      "lat": "38.2275957",
      "lon": "16.2028416",
      "superficie_kmq": "5.9980",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080063",
      "denominazione_ita_altra": "Reggio di Calabria",
      "denominazione_ita": "Reggio di Calabria",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "H224",
      "lat": "38.1091311",
      "lon": "15.6434448",
      "superficie_kmq": "239.0361",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080064",
      "denominazione_ita_altra": "Riace",
      "denominazione_ita": "Riace",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H265",
      "lat": "38.4179641",
      "lon": "16.4808451",
      "superficie_kmq": "16.2369",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080065",
      "denominazione_ita_altra": "Rizziconi",
      "denominazione_ita": "Rizziconi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H359",
      "lat": "38.4117620",
      "lon": "15.9587495",
      "superficie_kmq": "40.2229",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080066",
      "denominazione_ita_altra": "Roccaforte del Greco",
      "denominazione_ita": "Roccaforte del Greco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H408",
      "lat": "38.0458131",
      "lon": "15.8995948",
      "superficie_kmq": "43.8565",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080067",
      "denominazione_ita_altra": "Roccella Ionica",
      "denominazione_ita": "Roccella Ionica",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H456",
      "lat": "38.3251548",
      "lon": "16.4034605",
      "superficie_kmq": "37.8198",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080068",
      "denominazione_ita_altra": "Roghudi",
      "denominazione_ita": "Roghudi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H489",
      "lat": "37.9248960",
      "lon": "15.7653581",
      "superficie_kmq": "46.9207",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080069",
      "denominazione_ita_altra": "Rosarno",
      "denominazione_ita": "Rosarno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H558",
      "lat": "38.4875208",
      "lon": "15.9828233",
      "superficie_kmq": "39.5572",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080070",
      "denominazione_ita_altra": "Samo",
      "denominazione_ita": "Samo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H013",
      "lat": "38.0740364",
      "lon": "16.0562934",
      "superficie_kmq": "50.2181",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080071",
      "denominazione_ita_altra": "San Giorgio Morgeto",
      "denominazione_ita": "San Giorgio Morgeto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H889",
      "lat": "38.3869301",
      "lon": "16.1035458",
      "superficie_kmq": "35.4002",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080072",
      "denominazione_ita_altra": "San Giovanni di Gerace",
      "denominazione_ita": "San Giovanni di Gerace",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H903",
      "lat": "38.3656743",
      "lon": "16.2778906",
      "superficie_kmq": "13.5662",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080073",
      "denominazione_ita_altra": "San Lorenzo",
      "denominazione_ita": "San Lorenzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H959",
      "lat": "38.0105375",
      "lon": "15.8338276",
      "superficie_kmq": "64.5201",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080074",
      "denominazione_ita_altra": "San Luca",
      "denominazione_ita": "San Luca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H970",
      "lat": "38.1450367",
      "lon": "16.0672556",
      "superficie_kmq": "105.3446",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080075",
      "denominazione_ita_altra": "San Pietro di Caridà",
      "denominazione_ita": "San Pietro di Caridà",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I102",
      "lat": "38.5215985",
      "lon": "16.1324197",
      "superficie_kmq": "48.0777",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080076",
      "denominazione_ita_altra": "San Procopio",
      "denominazione_ita": "San Procopio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I132",
      "lat": "38.2816448",
      "lon": "15.8894226",
      "superficie_kmq": "11.3583",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080077",
      "denominazione_ita_altra": "San Roberto",
      "denominazione_ita": "San Roberto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I139",
      "lat": "38.2113594",
      "lon": "15.7355463",
      "superficie_kmq": "34.6363",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080078",
      "denominazione_ita_altra": "Santa Cristina d'Aspromonte",
      "denominazione_ita": "Santa Cristina d'Aspromonte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I176",
      "lat": "38.2541804",
      "lon": "15.9700261",
      "superficie_kmq": "23.4089",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080079",
      "denominazione_ita_altra": "Sant'Agata del Bianco",
      "denominazione_ita": "Sant'Agata del Bianco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I198",
      "lat": "38.0923265",
      "lon": "16.0825010",
      "superficie_kmq": "20.1995",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080080",
      "denominazione_ita_altra": "Sant'Alessio in Aspromonte",
      "denominazione_ita": "Sant'Alessio in Aspromonte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I214",
      "lat": "38.1721015",
      "lon": "15.7572967",
      "superficie_kmq": "3.9925",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080081",
      "denominazione_ita_altra": "Sant'Eufemia d'Aspromonte",
      "denominazione_ita": "Sant'Eufemia d'Aspromonte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I333",
      "lat": "38.2619863",
      "lon": "15.8547256",
      "superficie_kmq": "32.8795",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080082",
      "denominazione_ita_altra": "Sant'Ilario dello Ionio",
      "denominazione_ita": "Sant'Ilario dello Ionio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I341",
      "lat": "38.2207429",
      "lon": "16.1925234",
      "superficie_kmq": "13.9996",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080083",
      "denominazione_ita_altra": "Santo Stefano in Aspromonte",
      "denominazione_ita": "Santo Stefano in Aspromonte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I371",
      "lat": "38.1672839",
      "lon": "15.7893368",
      "superficie_kmq": "17.7968",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080084",
      "denominazione_ita_altra": "Scido",
      "denominazione_ita": "Scido",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I536",
      "lat": "38.2437590",
      "lon": "15.9320110",
      "superficie_kmq": "17.5297",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080085",
      "denominazione_ita_altra": "Scilla",
      "denominazione_ita": "Scilla",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I537",
      "lat": "38.2532552",
      "lon": "15.7152469",
      "superficie_kmq": "44.1308",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080086",
      "denominazione_ita_altra": "Seminara",
      "denominazione_ita": "Seminara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I600",
      "lat": "38.3360064",
      "lon": "15.8704880",
      "superficie_kmq": "33.8540",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080087",
      "denominazione_ita_altra": "Serrata",
      "denominazione_ita": "Serrata",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I656",
      "lat": "38.5126821",
      "lon": "16.1015597",
      "superficie_kmq": "22.0617",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080088",
      "denominazione_ita_altra": "Siderno",
      "denominazione_ita": "Siderno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I725",
      "lat": "38.2686064",
      "lon": "16.2975802",
      "superficie_kmq": "31.8595",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080089",
      "denominazione_ita_altra": "Sinopoli",
      "denominazione_ita": "Sinopoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I753",
      "lat": "38.2634084",
      "lon": "15.8774064",
      "superficie_kmq": "25.2146",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080090",
      "denominazione_ita_altra": "Staiti",
      "denominazione_ita": "Staiti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I936",
      "lat": "37.9999552",
      "lon": "16.0334916",
      "superficie_kmq": "16.3085",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080091",
      "denominazione_ita_altra": "Stignano",
      "denominazione_ita": "Stignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I955",
      "lat": "38.4181129",
      "lon": "16.4704698",
      "superficie_kmq": "17.7650",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080092",
      "denominazione_ita_altra": "Stilo",
      "denominazione_ita": "Stilo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I956",
      "lat": "38.4755140",
      "lon": "16.4670616",
      "superficie_kmq": "78.1081",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080093",
      "denominazione_ita_altra": "Taurianova",
      "denominazione_ita": "Taurianova",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L063",
      "lat": "38.3524680",
      "lon": "16.0130136",
      "superficie_kmq": "48.5444",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080094",
      "denominazione_ita_altra": "Terranova Sappo Minulio",
      "denominazione_ita": "Terranova Sappo Minulio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L127",
      "lat": "38.3216553",
      "lon": "16.0074574",
      "superficie_kmq": "9.1204",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080095",
      "denominazione_ita_altra": "Varapodio",
      "denominazione_ita": "Varapodio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L673",
      "lat": "38.3145559",
      "lon": "15.9832203",
      "superficie_kmq": "29.1240",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080096",
      "denominazione_ita_altra": "Villa San Giovanni",
      "denominazione_ita": "Villa San Giovanni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M018",
      "lat": "38.2190721",
      "lon": "15.6369134",
      "superficie_kmq": "12.1747",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "RC",
      "codice_istat": "080097",
      "denominazione_ita_altra": "San Ferdinando",
      "denominazione_ita": "San Ferdinando",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M277",
      "lat": "38.4837529",
      "lon": "15.9156494",
      "superficie_kmq": "14.1980",
      "codice_sovracomunale": "280"
    },
    {
      "sigla_provincia": "TP",
      "codice_istat": "081001",
      "denominazione_ita_altra": "Alcamo",
      "denominazione_ita": "Alcamo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A176",
      "lat": "37.9805029",
      "lon": "12.9649646",
      "superficie_kmq": "130.8932",
      "codice_sovracomunale": "081"
    },
    {
      "sigla_provincia": "TP",
      "codice_istat": "081002",
      "denominazione_ita_altra": "Buseto Palizzolo",
      "denominazione_ita": "Buseto Palizzolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B288",
      "lat": "38.0120724",
      "lon": "12.7113180",
      "superficie_kmq": "72.8093",
      "codice_sovracomunale": "081"
    },
    {
      "sigla_provincia": "TP",
      "codice_istat": "081003",
      "denominazione_ita_altra": "Calatafimi-Segesta",
      "denominazione_ita": "Calatafimi-Segesta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B385",
      "lat": "37.9140621",
      "lon": "12.8627083",
      "superficie_kmq": "154.8616",
      "codice_sovracomunale": "081"
    },
    {
      "sigla_provincia": "TP",
      "codice_istat": "081004",
      "denominazione_ita_altra": "Campobello di Mazara",
      "denominazione_ita": "Campobello di Mazara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B521",
      "lat": "37.6344852",
      "lon": "12.7440849",
      "superficie_kmq": "65.8321",
      "codice_sovracomunale": "081"
    },
    {
      "sigla_provincia": "TP",
      "codice_istat": "081005",
      "denominazione_ita_altra": "Castellammare del Golfo",
      "denominazione_ita": "Castellammare del Golfo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C130",
      "lat": "38.0262791",
      "lon": "12.8804382",
      "superficie_kmq": "127.3202",
      "codice_sovracomunale": "081"
    },
    {
      "sigla_provincia": "TP",
      "codice_istat": "081006",
      "denominazione_ita_altra": "Castelvetrano",
      "denominazione_ita": "Castelvetrano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C286",
      "lat": "37.6790570",
      "lon": "12.7916327",
      "superficie_kmq": "209.7569",
      "codice_sovracomunale": "081"
    },
    {
      "sigla_provincia": "TP",
      "codice_istat": "081007",
      "denominazione_ita_altra": "Custonaci",
      "denominazione_ita": "Custonaci",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D234",
      "lat": "38.0779290",
      "lon": "12.6755356",
      "superficie_kmq": "69.9007",
      "codice_sovracomunale": "081"
    },
    {
      "sigla_provincia": "TP",
      "codice_istat": "081008",
      "denominazione_ita_altra": "Erice",
      "denominazione_ita": "Erice",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D423",
      "lat": "38.0380037",
      "lon": "12.5870322",
      "superficie_kmq": "47.3374",
      "codice_sovracomunale": "081"
    },
    {
      "sigla_provincia": "TP",
      "codice_istat": "081009",
      "denominazione_ita_altra": "Favignana",
      "denominazione_ita": "Favignana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D518",
      "lat": "37.9316748",
      "lon": "12.3272361",
      "superficie_kmq": "38.3149",
      "codice_sovracomunale": "081"
    },
    {
      "sigla_provincia": "TP",
      "codice_istat": "081010",
      "denominazione_ita_altra": "Gibellina",
      "denominazione_ita": "Gibellina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E023",
      "lat": "37.8098390",
      "lon": "12.8698133",
      "superficie_kmq": "46.5738",
      "codice_sovracomunale": "081"
    },
    {
      "sigla_provincia": "TP",
      "codice_istat": "081011",
      "denominazione_ita_altra": "Marsala",
      "denominazione_ita": "Marsala",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E974",
      "lat": "37.7990698",
      "lon": "12.4349421",
      "superficie_kmq": "243.2581",
      "codice_sovracomunale": "081"
    },
    {
      "sigla_provincia": "TP",
      "codice_istat": "081012",
      "denominazione_ita_altra": "Mazara del Vallo",
      "denominazione_ita": "Mazara del Vallo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F061",
      "lat": "37.6512438",
      "lon": "12.5897151",
      "superficie_kmq": "274.7416",
      "codice_sovracomunale": "081"
    },
    {
      "sigla_provincia": "TP",
      "codice_istat": "081013",
      "denominazione_ita_altra": "Paceco",
      "denominazione_ita": "Paceco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G208",
      "lat": "37.9809920",
      "lon": "12.5570936",
      "superficie_kmq": "58.0074",
      "codice_sovracomunale": "081"
    },
    {
      "sigla_provincia": "TP",
      "codice_istat": "081014",
      "denominazione_ita_altra": "Pantelleria",
      "denominazione_ita": "Pantelleria",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G315",
      "lat": "36.8317779",
      "lon": "11.9447401",
      "superficie_kmq": "84.5311",
      "codice_sovracomunale": "081"
    },
    {
      "sigla_provincia": "TP",
      "codice_istat": "081015",
      "denominazione_ita_altra": "Partanna",
      "denominazione_ita": "Partanna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G347",
      "lat": "37.7212930",
      "lon": "12.8896583",
      "superficie_kmq": "82.7263",
      "codice_sovracomunale": "081"
    },
    {
      "sigla_provincia": "TP",
      "codice_istat": "081016",
      "denominazione_ita_altra": "Poggioreale",
      "denominazione_ita": "Poggioreale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G767",
      "lat": "37.7620429",
      "lon": "13.0339373",
      "superficie_kmq": "37.4571",
      "codice_sovracomunale": "081"
    },
    {
      "sigla_provincia": "TP",
      "codice_istat": "081017",
      "denominazione_ita_altra": "Salaparuta",
      "denominazione_ita": "Salaparuta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H688",
      "lat": "37.7568719",
      "lon": "13.0062679",
      "superficie_kmq": "41.4154",
      "codice_sovracomunale": "081"
    },
    {
      "sigla_provincia": "TP",
      "codice_istat": "081018",
      "denominazione_ita_altra": "Salemi",
      "denominazione_ita": "Salemi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H700",
      "lat": "37.8159015",
      "lon": "12.8015038",
      "superficie_kmq": "182.4210",
      "codice_sovracomunale": "081"
    },
    {
      "sigla_provincia": "TP",
      "codice_istat": "081019",
      "denominazione_ita_altra": "Santa Ninfa",
      "denominazione_ita": "Santa Ninfa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I291",
      "lat": "37.7737193",
      "lon": "12.8802128",
      "superficie_kmq": "60.9436",
      "codice_sovracomunale": "081"
    },
    {
      "sigla_provincia": "TP",
      "codice_istat": "081020",
      "denominazione_ita_altra": "San Vito Lo Capo",
      "denominazione_ita": "San Vito Lo Capo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I407",
      "lat": "38.1730011",
      "lon": "12.7350159",
      "superficie_kmq": "60.1187",
      "codice_sovracomunale": "081"
    },
    {
      "sigla_provincia": "TP",
      "codice_istat": "081021",
      "denominazione_ita_altra": "Trapani",
      "denominazione_ita": "Trapani",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "L331",
      "lat": "38.0184007",
      "lon": "12.5136521",
      "superficie_kmq": "180.5964",
      "codice_sovracomunale": "081"
    },
    {
      "sigla_provincia": "TP",
      "codice_istat": "081022",
      "denominazione_ita_altra": "Valderice",
      "denominazione_ita": "Valderice",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G319",
      "lat": "38.0378485",
      "lon": "12.6174886",
      "superficie_kmq": "52.9625",
      "codice_sovracomunale": "081"
    },
    {
      "sigla_provincia": "TP",
      "codice_istat": "081023",
      "denominazione_ita_altra": "Vita",
      "denominazione_ita": "Vita",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M081",
      "lat": "37.8683244",
      "lon": "12.8145835",
      "superficie_kmq": "9.1029",
      "codice_sovracomunale": "081"
    },
    {
      "sigla_provincia": "TP",
      "codice_istat": "081024",
      "denominazione_ita_altra": "Petrosino",
      "denominazione_ita": "Petrosino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M281",
      "lat": "37.7126626",
      "lon": "12.4923188",
      "superficie_kmq": "45.2764",
      "codice_sovracomunale": "081"
    },
    {
      "sigla_provincia": "TP",
      "codice_istat": "081025",
      "denominazione_ita_altra": "Misiliscemi",
      "denominazione_ita": "Misiliscemi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M432",
      "lat": "37.9156734",
      "lon": "12.5473840",
      "superficie_kmq": "92.5372",
      "codice_sovracomunale": "081"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082001",
      "denominazione_ita_altra": "Alia",
      "denominazione_ita": "Alia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A195",
      "lat": "37.7784762",
      "lon": "13.7131200",
      "superficie_kmq": "45.9748",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082002",
      "denominazione_ita_altra": "Alimena",
      "denominazione_ita": "Alimena",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A202",
      "lat": "37.6920260",
      "lon": "14.1136670",
      "superficie_kmq": "59.6975",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082003",
      "denominazione_ita_altra": "Aliminusa",
      "denominazione_ita": "Aliminusa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A203",
      "lat": "37.8640736",
      "lon": "13.7810383",
      "superficie_kmq": "13.6806",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082004",
      "denominazione_ita_altra": "Altavilla Milicia",
      "denominazione_ita": "Altavilla Milicia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A229",
      "lat": "38.0422181",
      "lon": "13.5504473",
      "superficie_kmq": "23.7839",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082005",
      "denominazione_ita_altra": "Altofonte",
      "denominazione_ita": "Altofonte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A239",
      "lat": "38.0442561",
      "lon": "13.2982849",
      "superficie_kmq": "35.4356",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082006",
      "denominazione_ita_altra": "Bagheria",
      "denominazione_ita": "Bagheria",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A546",
      "lat": "38.0787843",
      "lon": "13.5109784",
      "superficie_kmq": "29.8358",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082007",
      "denominazione_ita_altra": "Balestrate",
      "denominazione_ita": "Balestrate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A592",
      "lat": "38.0512159",
      "lon": "13.0043843",
      "superficie_kmq": "6.4298",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082008",
      "denominazione_ita_altra": "Baucina",
      "denominazione_ita": "Baucina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A719",
      "lat": "37.9250266",
      "lon": "13.5372337",
      "superficie_kmq": "24.4710",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082009",
      "denominazione_ita_altra": "Belmonte Mezzagno",
      "denominazione_ita": "Belmonte Mezzagno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A764",
      "lat": "38.0482532",
      "lon": "13.3901052",
      "superficie_kmq": "29.2934",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082010",
      "denominazione_ita_altra": "Bisacquino",
      "denominazione_ita": "Bisacquino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A882",
      "lat": "37.7056838",
      "lon": "13.2587245",
      "superficie_kmq": "64.9696",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082011",
      "denominazione_ita_altra": "Bolognetta",
      "denominazione_ita": "Bolognetta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A946",
      "lat": "37.9646186",
      "lon": "13.4556696",
      "superficie_kmq": "27.6253",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082012",
      "denominazione_ita_altra": "Bompietro",
      "denominazione_ita": "Bompietro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A958",
      "lat": "37.7469815",
      "lon": "14.0995147",
      "superficie_kmq": "42.4101",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082013",
      "denominazione_ita_altra": "Borgetto",
      "denominazione_ita": "Borgetto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A991",
      "lat": "38.0463215",
      "lon": "13.1427357",
      "superficie_kmq": "26.0237",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082014",
      "denominazione_ita_altra": "Caccamo",
      "denominazione_ita": "Caccamo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B315",
      "lat": "37.9316995",
      "lon": "13.6635257",
      "superficie_kmq": "188.2312",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082015",
      "denominazione_ita_altra": "Caltavuturo",
      "denominazione_ita": "Caltavuturo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B430",
      "lat": "37.8202069",
      "lon": "13.8900645",
      "superficie_kmq": "97.9459",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082016",
      "denominazione_ita_altra": "Campofelice di Fitalia",
      "denominazione_ita": "Campofelice di Fitalia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B533",
      "lat": "37.8261139",
      "lon": "13.4845647",
      "superficie_kmq": "35.4560",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082017",
      "denominazione_ita_altra": "Campofelice di Roccella",
      "denominazione_ita": "Campofelice di Roccella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B532",
      "lat": "37.9919108",
      "lon": "13.8858388",
      "superficie_kmq": "14.5060",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082018",
      "denominazione_ita_altra": "Campofiorito",
      "denominazione_ita": "Campofiorito",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B535",
      "lat": "37.7531593",
      "lon": "13.2691796",
      "superficie_kmq": "21.6994",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082019",
      "denominazione_ita_altra": "Camporeale",
      "denominazione_ita": "Camporeale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B556",
      "lat": "37.8968995",
      "lon": "13.0953002",
      "superficie_kmq": "38.7206",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082020",
      "denominazione_ita_altra": "Capaci",
      "denominazione_ita": "Capaci",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B645",
      "lat": "38.1707412",
      "lon": "13.2393462",
      "superficie_kmq": "6.1204",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082021",
      "denominazione_ita_altra": "Carini",
      "denominazione_ita": "Carini",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B780",
      "lat": "38.1330928",
      "lon": "13.1824038",
      "superficie_kmq": "76.5940",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082022",
      "denominazione_ita_altra": "Castelbuono",
      "denominazione_ita": "Castelbuono",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C067",
      "lat": "37.9337484",
      "lon": "14.0882501",
      "superficie_kmq": "60.7859",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082023",
      "denominazione_ita_altra": "Casteldaccia",
      "denominazione_ita": "Casteldaccia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C074",
      "lat": "38.0552690",
      "lon": "13.5325147",
      "superficie_kmq": "33.9185",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082024",
      "denominazione_ita_altra": "Castellana Sicula",
      "denominazione_ita": "Castellana Sicula",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C135",
      "lat": "37.7816618",
      "lon": "14.0440866",
      "superficie_kmq": "73.1964",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082025",
      "denominazione_ita_altra": "Castronovo di Sicilia",
      "denominazione_ita": "Castronovo di Sicilia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C344",
      "lat": "37.6783187",
      "lon": "13.6037287",
      "superficie_kmq": "201.0400",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082026",
      "denominazione_ita_altra": "Cefalà Diana",
      "denominazione_ita": "Cefalà Diana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C420",
      "lat": "37.9153055",
      "lon": "13.4637095",
      "superficie_kmq": "9.0592",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082027",
      "denominazione_ita_altra": "Cefalù",
      "denominazione_ita": "Cefalù",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C421",
      "lat": "38.0395998",
      "lon": "14.0220173",
      "superficie_kmq": "66.2409",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082028",
      "denominazione_ita_altra": "Cerda",
      "denominazione_ita": "Cerda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C496",
      "lat": "37.9037599",
      "lon": "13.8153645",
      "superficie_kmq": "43.8343",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082029",
      "denominazione_ita_altra": "Chiusa Sclafani",
      "denominazione_ita": "Chiusa Sclafani",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C654",
      "lat": "37.6769580",
      "lon": "13.2698663",
      "superficie_kmq": "57.5503",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082030",
      "denominazione_ita_altra": "Ciminna",
      "denominazione_ita": "Ciminna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C696",
      "lat": "37.8971610",
      "lon": "13.5619471",
      "superficie_kmq": "56.4206",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082031",
      "denominazione_ita_altra": "Cinisi",
      "denominazione_ita": "Cinisi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C708",
      "lat": "38.1548051",
      "lon": "13.1091311",
      "superficie_kmq": "33.1617",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082032",
      "denominazione_ita_altra": "Collesano",
      "denominazione_ita": "Collesano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C871",
      "lat": "37.9209551",
      "lon": "13.9375383",
      "superficie_kmq": "108.1646",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082033",
      "denominazione_ita_altra": "Contessa Entellina",
      "denominazione_ita": "Contessa Entellina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C968",
      "lat": "37.7280926",
      "lon": "13.1840342",
      "superficie_kmq": "136.4759",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082034",
      "denominazione_ita_altra": "Corleone",
      "denominazione_ita": "Corleone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D009",
      "lat": "37.8119397",
      "lon": "13.3015745",
      "superficie_kmq": "229.4546",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082035",
      "denominazione_ita_altra": "Ficarazzi",
      "denominazione_ita": "Ficarazzi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D567",
      "lat": "38.0882530",
      "lon": "13.4632015",
      "superficie_kmq": "3.5295",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082036",
      "denominazione_ita_altra": "Gangi",
      "denominazione_ita": "Gangi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D907",
      "lat": "37.7980515",
      "lon": "14.2052420",
      "superficie_kmq": "127.4634",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082037",
      "denominazione_ita_altra": "Geraci Siculo",
      "denominazione_ita": "Geraci Siculo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D977",
      "lat": "37.8588866",
      "lon": "14.1541440",
      "superficie_kmq": "113.3465",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082038",
      "denominazione_ita_altra": "Giardinello",
      "denominazione_ita": "Giardinello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E013",
      "lat": "38.0885135",
      "lon": "13.1567781",
      "superficie_kmq": "12.8844",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082039",
      "denominazione_ita_altra": "Giuliana",
      "denominazione_ita": "Giuliana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E055",
      "lat": "37.6741634",
      "lon": "13.2378653",
      "superficie_kmq": "24.1410",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082040",
      "denominazione_ita_altra": "Godrano",
      "denominazione_ita": "Godrano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E074",
      "lat": "37.9029347",
      "lon": "13.4286124",
      "superficie_kmq": "39.2021",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082041",
      "denominazione_ita_altra": "Gratteri",
      "denominazione_ita": "Gratteri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E149",
      "lat": "37.9672103",
      "lon": "13.9730665",
      "superficie_kmq": "38.1661",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082042",
      "denominazione_ita_altra": "Isnello",
      "denominazione_ita": "Isnello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E337",
      "lat": "37.9438843",
      "lon": "14.0060662",
      "superficie_kmq": "50.9969",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082043",
      "denominazione_ita_altra": "Isola delle Femmine",
      "denominazione_ita": "Isola delle Femmine",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E350",
      "lat": "38.2004926",
      "lon": "13.2461999",
      "superficie_kmq": "3.5663",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082044",
      "denominazione_ita_altra": "Lascari",
      "denominazione_ita": "Lascari",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E459",
      "lat": "37.9997841",
      "lon": "13.9411134",
      "superficie_kmq": "10.3266",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082045",
      "denominazione_ita_altra": "Lercara Friddi",
      "denominazione_ita": "Lercara Friddi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E541",
      "lat": "37.7473869",
      "lon": "13.6028286",
      "superficie_kmq": "37.4931",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082046",
      "denominazione_ita_altra": "Marineo",
      "denominazione_ita": "Marineo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E957",
      "lat": "37.9525803",
      "lon": "13.4141023",
      "superficie_kmq": "33.2219",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082047",
      "denominazione_ita_altra": "Mezzojuso",
      "denominazione_ita": "Mezzojuso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F184",
      "lat": "37.8643942",
      "lon": "13.4645037",
      "superficie_kmq": "49.2544",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082048",
      "denominazione_ita_altra": "Misilmeri",
      "denominazione_ita": "Misilmeri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F246",
      "lat": "38.0350414",
      "lon": "13.4515407",
      "superficie_kmq": "69.4886",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082049",
      "denominazione_ita_altra": "Monreale",
      "denominazione_ita": "Monreale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F377",
      "lat": "38.0822282",
      "lon": "13.2916944",
      "superficie_kmq": "530.1798",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082050",
      "denominazione_ita_altra": "Montelepre",
      "denominazione_ita": "Montelepre",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F544",
      "lat": "38.0896937",
      "lon": "13.1732611",
      "superficie_kmq": "9.8912",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082051",
      "denominazione_ita_altra": "Montemaggiore Belsito",
      "denominazione_ita": "Montemaggiore Belsito",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F553",
      "lat": "37.8477913",
      "lon": "13.7625711",
      "superficie_kmq": "32.0755",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082052",
      "denominazione_ita_altra": "Palazzo Adriano",
      "denominazione_ita": "Palazzo Adriano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G263",
      "lat": "37.6815211",
      "lon": "13.3796524",
      "superficie_kmq": "130.0959",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082053",
      "denominazione_ita_altra": "Palermo",
      "denominazione_ita": "Palermo",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "G273",
      "lat": "38.1155990",
      "lon": "13.3623509",
      "superficie_kmq": "160.5878",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082054",
      "denominazione_ita_altra": "Partinico",
      "denominazione_ita": "Partinico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G348",
      "lat": "38.0479830",
      "lon": "13.1152134",
      "superficie_kmq": "108.0579",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082055",
      "denominazione_ita_altra": "Petralia Soprana",
      "denominazione_ita": "Petralia Soprana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G510",
      "lat": "37.7962410",
      "lon": "14.1082436",
      "superficie_kmq": "56.1025",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082056",
      "denominazione_ita_altra": "Petralia Sottana",
      "denominazione_ita": "Petralia Sottana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G511",
      "lat": "37.8058413",
      "lon": "14.0922092",
      "superficie_kmq": "178.3440",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082057",
      "denominazione_ita_altra": "Piana degli Albanesi",
      "denominazione_ita": "Piana degli Albanesi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G543",
      "lat": "37.9943716",
      "lon": "13.2819871",
      "superficie_kmq": "64.9165",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082058",
      "denominazione_ita_altra": "Polizzi Generosa",
      "denominazione_ita": "Polizzi Generosa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G792",
      "lat": "37.8128092",
      "lon": "13.9994649",
      "superficie_kmq": "134.6583",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082059",
      "denominazione_ita_altra": "Pollina",
      "denominazione_ita": "Pollina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G797",
      "lat": "37.9941505",
      "lon": "14.1465751",
      "superficie_kmq": "49.9291",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082060",
      "denominazione_ita_altra": "Prizzi",
      "denominazione_ita": "Prizzi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H070",
      "lat": "37.7215500",
      "lon": "13.4309850",
      "superficie_kmq": "95.0363",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082061",
      "denominazione_ita_altra": "Roccamena",
      "denominazione_ita": "Roccamena",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H422",
      "lat": "37.8384910",
      "lon": "13.1552949",
      "superficie_kmq": "33.7170",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082062",
      "denominazione_ita_altra": "Roccapalumba",
      "denominazione_ita": "Roccapalumba",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H428",
      "lat": "37.8059710",
      "lon": "13.6394473",
      "superficie_kmq": "31.5663",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082063",
      "denominazione_ita_altra": "San Cipirello",
      "denominazione_ita": "San Cipirello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H797",
      "lat": "37.9601256",
      "lon": "13.1816978",
      "superficie_kmq": "20.8522",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082064",
      "denominazione_ita_altra": "San Giuseppe Jato",
      "denominazione_ita": "San Giuseppe Jato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H933",
      "lat": "37.9680946",
      "lon": "13.1811565",
      "superficie_kmq": "29.7814",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082065",
      "denominazione_ita_altra": "San Mauro Castelverde",
      "denominazione_ita": "San Mauro Castelverde",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I028",
      "lat": "37.9143620",
      "lon": "14.1900864",
      "superficie_kmq": "114.3653",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082066",
      "denominazione_ita_altra": "Santa Cristina Gela",
      "denominazione_ita": "Santa Cristina Gela",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I174",
      "lat": "37.9849863",
      "lon": "13.3275102",
      "superficie_kmq": "38.7414",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082067",
      "denominazione_ita_altra": "Santa Flavia",
      "denominazione_ita": "Santa Flavia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I188",
      "lat": "38.0820752",
      "lon": "13.5249931",
      "superficie_kmq": "14.5987",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082068",
      "denominazione_ita_altra": "Sciara",
      "denominazione_ita": "Sciara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I534",
      "lat": "37.9145429",
      "lon": "13.7626085",
      "superficie_kmq": "31.1931",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082069",
      "denominazione_ita_altra": "Sclafani Bagni",
      "denominazione_ita": "Sclafani Bagni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I541",
      "lat": "37.8210277",
      "lon": "13.8545973",
      "superficie_kmq": "134.9024",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082070",
      "denominazione_ita_altra": "Termini Imerese",
      "denominazione_ita": "Termini Imerese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L112",
      "lat": "37.9867093",
      "lon": "13.6959372",
      "superficie_kmq": "76.6885",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082071",
      "denominazione_ita_altra": "Terrasini",
      "denominazione_ita": "Terrasini",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L131",
      "lat": "38.1528559",
      "lon": "13.0823830",
      "superficie_kmq": "19.8463",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082072",
      "denominazione_ita_altra": "Torretta",
      "denominazione_ita": "Torretta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L282",
      "lat": "38.1310801",
      "lon": "13.2331328",
      "superficie_kmq": "25.5421",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082073",
      "denominazione_ita_altra": "Trabia",
      "denominazione_ita": "Trabia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L317",
      "lat": "37.9956567",
      "lon": "13.6554076",
      "superficie_kmq": "22.0772",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082074",
      "denominazione_ita_altra": "Trappeto",
      "denominazione_ita": "Trappeto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L332",
      "lat": "38.0664567",
      "lon": "13.0365202",
      "superficie_kmq": "4.1885",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082075",
      "denominazione_ita_altra": "Ustica",
      "denominazione_ita": "Ustica",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L519",
      "lat": "38.7099876",
      "lon": "13.1928376",
      "superficie_kmq": "8.2407",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082076",
      "denominazione_ita_altra": "Valledolmo",
      "denominazione_ita": "Valledolmo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L603",
      "lat": "37.7469806",
      "lon": "13.8282527",
      "superficie_kmq": "25.7793",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082077",
      "denominazione_ita_altra": "Ventimiglia di Sicilia",
      "denominazione_ita": "Ventimiglia di Sicilia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L740",
      "lat": "37.9260406",
      "lon": "13.5679043",
      "superficie_kmq": "26.9001",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082078",
      "denominazione_ita_altra": "Vicari",
      "denominazione_ita": "Vicari",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L837",
      "lat": "37.8264740",
      "lon": "13.5707442",
      "superficie_kmq": "85.9462",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082079",
      "denominazione_ita_altra": "Villabate",
      "denominazione_ita": "Villabate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L916",
      "lat": "38.0755441",
      "lon": "13.4420858",
      "superficie_kmq": "3.7977",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082080",
      "denominazione_ita_altra": "Villafrati",
      "denominazione_ita": "Villafrati",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L951",
      "lat": "37.9079730",
      "lon": "13.4867156",
      "superficie_kmq": "25.6441",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082081",
      "denominazione_ita_altra": "Scillato",
      "denominazione_ita": "Scillato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I538",
      "lat": "37.8566035",
      "lon": "13.9068230",
      "superficie_kmq": "31.6956",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "PA",
      "codice_istat": "082082",
      "denominazione_ita_altra": "Blufi",
      "denominazione_ita": "Blufi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M268",
      "lat": "37.7522074",
      "lon": "14.0767336",
      "superficie_kmq": "21.9813",
      "codice_sovracomunale": "282"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083001",
      "denominazione_ita_altra": "Alcara li Fusi",
      "denominazione_ita": "Alcara li Fusi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A177",
      "lat": "38.0213554",
      "lon": "14.7010779",
      "superficie_kmq": "62.9346",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083002",
      "denominazione_ita_altra": "Alì",
      "denominazione_ita": "Alì",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A194",
      "lat": "38.0268428",
      "lon": "15.4179642",
      "superficie_kmq": "15.9386",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083003",
      "denominazione_ita_altra": "Alì Terme",
      "denominazione_ita": "Alì Terme",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A201",
      "lat": "38.0036543",
      "lon": "15.4226087",
      "superficie_kmq": "6.2744",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083004",
      "denominazione_ita_altra": "Antillo",
      "denominazione_ita": "Antillo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A313",
      "lat": "37.9760312",
      "lon": "15.2453858",
      "superficie_kmq": "43.6346",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083005",
      "denominazione_ita_altra": "Barcellona Pozzo di Gotto",
      "denominazione_ita": "Barcellona Pozzo di Gotto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A638",
      "lat": "38.1468287",
      "lon": "15.2185671",
      "superficie_kmq": "59.1418",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083006",
      "denominazione_ita_altra": "Basicò",
      "denominazione_ita": "Basicò",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A698",
      "lat": "38.0615016",
      "lon": "15.0617469",
      "superficie_kmq": "12.0685",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083007",
      "denominazione_ita_altra": "Brolo",
      "denominazione_ita": "Brolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B198",
      "lat": "38.1567510",
      "lon": "14.8277092",
      "superficie_kmq": "7.6646",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083008",
      "denominazione_ita_altra": "Capizzi",
      "denominazione_ita": "Capizzi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B660",
      "lat": "37.8487733",
      "lon": "14.4784102",
      "superficie_kmq": "70.1664",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083009",
      "denominazione_ita_altra": "Capo d'Orlando",
      "denominazione_ita": "Capo d'Orlando",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B666",
      "lat": "38.1602063",
      "lon": "14.7439178",
      "superficie_kmq": "14.4275",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083010",
      "denominazione_ita_altra": "Capri Leone",
      "denominazione_ita": "Capri Leone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B695",
      "lat": "38.1074283",
      "lon": "14.7072309",
      "superficie_kmq": "6.7645",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083011",
      "denominazione_ita_altra": "Caronia",
      "denominazione_ita": "Caronia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B804",
      "lat": "38.0246102",
      "lon": "14.4407585",
      "superficie_kmq": "227.2587",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083012",
      "denominazione_ita_altra": "Casalvecchio Siculo",
      "denominazione_ita": "Casalvecchio Siculo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B918",
      "lat": "37.9581154",
      "lon": "15.3234913",
      "superficie_kmq": "33.6218",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083013",
      "denominazione_ita_altra": "Castel di Lucio",
      "denominazione_ita": "Castel di Lucio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C094",
      "lat": "37.8871229",
      "lon": "14.3104515",
      "superficie_kmq": "28.7781",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083014",
      "denominazione_ita_altra": "Castell'Umberto",
      "denominazione_ita": "Castell'Umberto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C051",
      "lat": "38.0844821",
      "lon": "14.8068711",
      "superficie_kmq": "11.4282",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083015",
      "denominazione_ita_altra": "Castelmola",
      "denominazione_ita": "Castelmola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C210",
      "lat": "37.8580877",
      "lon": "15.2766513",
      "superficie_kmq": "16.8280",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083016",
      "denominazione_ita_altra": "Castroreale",
      "denominazione_ita": "Castroreale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C347",
      "lat": "38.0983865",
      "lon": "15.2110168",
      "superficie_kmq": "53.0694",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083017",
      "denominazione_ita_altra": "Cesarò",
      "denominazione_ita": "Cesarò",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C568",
      "lat": "37.8436541",
      "lon": "14.7121705",
      "superficie_kmq": "216.9304",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083018",
      "denominazione_ita_altra": "Condrò",
      "denominazione_ita": "Condrò",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C956",
      "lat": "38.1756076",
      "lon": "15.3264768",
      "superficie_kmq": "5.1324",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083019",
      "denominazione_ita_altra": "Falcone",
      "denominazione_ita": "Falcone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D474",
      "lat": "38.1163347",
      "lon": "15.0822810",
      "superficie_kmq": "9.3367",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083020",
      "denominazione_ita_altra": "Ficarra",
      "denominazione_ita": "Ficarra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D569",
      "lat": "38.1103231",
      "lon": "14.8274700",
      "superficie_kmq": "18.6563",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083021",
      "denominazione_ita_altra": "Fiumedinisi",
      "denominazione_ita": "Fiumedinisi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D622",
      "lat": "38.0249301",
      "lon": "15.3823042",
      "superficie_kmq": "36.6938",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083022",
      "denominazione_ita_altra": "Floresta",
      "denominazione_ita": "Floresta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D635",
      "lat": "37.9876034",
      "lon": "14.9094438",
      "superficie_kmq": "31.3311",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083023",
      "denominazione_ita_altra": "Fondachelli-Fantina",
      "denominazione_ita": "Fondachelli-Fantina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D661",
      "lat": "37.9858792",
      "lon": "15.1730670",
      "superficie_kmq": "42.2116",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083024",
      "denominazione_ita_altra": "Forza d'Agrò",
      "denominazione_ita": "Forza d'Agrò",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D733",
      "lat": "37.9140130",
      "lon": "15.3351219",
      "superficie_kmq": "11.1890",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083025",
      "denominazione_ita_altra": "Francavilla di Sicilia",
      "denominazione_ita": "Francavilla di Sicilia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D765",
      "lat": "37.9011468",
      "lon": "15.1361330",
      "superficie_kmq": "82.7262",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083026",
      "denominazione_ita_altra": "Frazzanò",
      "denominazione_ita": "Frazzanò",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D793",
      "lat": "38.0722458",
      "lon": "14.7447399",
      "superficie_kmq": "6.9981",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083027",
      "denominazione_ita_altra": "Furci Siculo",
      "denominazione_ita": "Furci Siculo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D824",
      "lat": "37.9608060",
      "lon": "15.3799657",
      "superficie_kmq": "17.9132",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083028",
      "denominazione_ita_altra": "Furnari",
      "denominazione_ita": "Furnari",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D825",
      "lat": "38.1046563",
      "lon": "15.1254812",
      "superficie_kmq": "13.5543",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083029",
      "denominazione_ita_altra": "Gaggi",
      "denominazione_ita": "Gaggi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D844",
      "lat": "37.8607632",
      "lon": "15.2205499",
      "superficie_kmq": "7.6505",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083030",
      "denominazione_ita_altra": "Galati Mamertino",
      "denominazione_ita": "Galati Mamertino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D861",
      "lat": "38.0329315",
      "lon": "14.7710142",
      "superficie_kmq": "39.3108",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083031",
      "denominazione_ita_altra": "Gallodoro",
      "denominazione_ita": "Gallodoro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D885",
      "lat": "37.9015816",
      "lon": "15.2938069",
      "superficie_kmq": "6.9083",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083032",
      "denominazione_ita_altra": "Giardini-Naxos",
      "denominazione_ita": "Giardini-Naxos",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E014",
      "lat": "37.8401321",
      "lon": "15.2774161",
      "superficie_kmq": "5.2904",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083033",
      "denominazione_ita_altra": "Gioiosa Marea",
      "denominazione_ita": "Gioiosa Marea",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E043",
      "lat": "38.1718911",
      "lon": "14.8928346",
      "superficie_kmq": "26.4823",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083034",
      "denominazione_ita_altra": "Graniti",
      "denominazione_ita": "Graniti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E142",
      "lat": "37.8912305",
      "lon": "15.2266984",
      "superficie_kmq": "10.0474",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083035",
      "denominazione_ita_altra": "Gualtieri Sicaminò",
      "denominazione_ita": "Gualtieri Sicaminò",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E233",
      "lat": "38.1629691",
      "lon": "15.3167515",
      "superficie_kmq": "14.3838",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083036",
      "denominazione_ita_altra": "Itala",
      "denominazione_ita": "Itala",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E374",
      "lat": "38.0505158",
      "lon": "15.4373077",
      "superficie_kmq": "10.9835",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083037",
      "denominazione_ita_altra": "Leni",
      "denominazione_ita": "Leni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E523",
      "lat": "38.5548563",
      "lon": "14.8241588",
      "superficie_kmq": "8.7899",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083038",
      "denominazione_ita_altra": "Letojanni",
      "denominazione_ita": "Letojanni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E555",
      "lat": "37.8802094",
      "lon": "15.3062471",
      "superficie_kmq": "6.7241",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083039",
      "denominazione_ita_altra": "Librizzi",
      "denominazione_ita": "Librizzi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E571",
      "lat": "38.0970678",
      "lon": "14.9581793",
      "superficie_kmq": "23.3863",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083040",
      "denominazione_ita_altra": "Limina",
      "denominazione_ita": "Limina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E594",
      "lat": "37.9407795",
      "lon": "15.2726340",
      "superficie_kmq": "9.9924",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083041",
      "denominazione_ita_altra": "Lipari",
      "denominazione_ita": "Lipari",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E606",
      "lat": "38.4689472",
      "lon": "14.9565137",
      "superficie_kmq": "89.7135",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083042",
      "denominazione_ita_altra": "Longi",
      "denominazione_ita": "Longi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E674",
      "lat": "38.0270703",
      "lon": "14.7526055",
      "superficie_kmq": "42.1099",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083043",
      "denominazione_ita_altra": "Malfa",
      "denominazione_ita": "Malfa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E855",
      "lat": "38.5769064",
      "lon": "14.8360362",
      "superficie_kmq": "8.7352",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083044",
      "denominazione_ita_altra": "Malvagna",
      "denominazione_ita": "Malvagna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E869",
      "lat": "37.9185611",
      "lon": "15.0543466",
      "superficie_kmq": "6.7094",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083045",
      "denominazione_ita_altra": "Mandanici",
      "denominazione_ita": "Mandanici",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E876",
      "lat": "38.0021988",
      "lon": "15.3171024",
      "superficie_kmq": "11.8536",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083046",
      "denominazione_ita_altra": "Mazzarrà Sant'Andrea",
      "denominazione_ita": "Mazzarrà Sant'Andrea",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F066",
      "lat": "38.0896999",
      "lon": "15.1358473",
      "superficie_kmq": "6.6942",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083047",
      "denominazione_ita_altra": "Merì",
      "denominazione_ita": "Merì",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F147",
      "lat": "38.1678463",
      "lon": "15.2464355",
      "superficie_kmq": "1.8503",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083048",
      "denominazione_ita_altra": "Messina",
      "denominazione_ita": "Messina",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "F158",
      "lat": "38.1938629",
      "lon": "15.5557152",
      "superficie_kmq": "213.7621",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083049",
      "denominazione_ita_altra": "Milazzo",
      "denominazione_ita": "Milazzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F206",
      "lat": "38.2207751",
      "lon": "15.2427359",
      "superficie_kmq": "24.7022",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083050",
      "denominazione_ita_altra": "Militello Rosmarino",
      "denominazione_ita": "Militello Rosmarino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F210",
      "lat": "38.0462596",
      "lon": "14.6760318",
      "superficie_kmq": "29.5356",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083051",
      "denominazione_ita_altra": "Mirto",
      "denominazione_ita": "Mirto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F242",
      "lat": "38.0850265",
      "lon": "14.7528459",
      "superficie_kmq": "9.2692",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083052",
      "denominazione_ita_altra": "Mistretta",
      "denominazione_ita": "Mistretta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F251",
      "lat": "37.9303865",
      "lon": "14.3623477",
      "superficie_kmq": "127.4678",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083053",
      "denominazione_ita_altra": "Moio Alcantara",
      "denominazione_ita": "Moio Alcantara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F277",
      "lat": "37.8987358",
      "lon": "15.0505212",
      "superficie_kmq": "8.5996",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083054",
      "denominazione_ita_altra": "Monforte San Giorgio",
      "denominazione_ita": "Monforte San Giorgio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F359",
      "lat": "38.1567704",
      "lon": "15.3835088",
      "superficie_kmq": "32.2599",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083055",
      "denominazione_ita_altra": "Mongiuffi Melia",
      "denominazione_ita": "Mongiuffi Melia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F368",
      "lat": "37.9040985",
      "lon": "15.2749224",
      "superficie_kmq": "24.3623",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083056",
      "denominazione_ita_altra": "Montagnareale",
      "denominazione_ita": "Montagnareale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F395",
      "lat": "38.1323639",
      "lon": "14.9477007",
      "superficie_kmq": "16.3769",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083057",
      "denominazione_ita_altra": "Montalbano Elicona",
      "denominazione_ita": "Montalbano Elicona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F400",
      "lat": "38.0236143",
      "lon": "15.0141231",
      "superficie_kmq": "67.8008",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083058",
      "denominazione_ita_altra": "Motta Camastra",
      "denominazione_ita": "Motta Camastra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F772",
      "lat": "37.8955882",
      "lon": "15.1724960",
      "superficie_kmq": "25.3090",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083059",
      "denominazione_ita_altra": "Motta d'Affermo",
      "denominazione_ita": "Motta d'Affermo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F773",
      "lat": "37.9811913",
      "lon": "14.3020543",
      "superficie_kmq": "14.5764",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083060",
      "denominazione_ita_altra": "Naso",
      "denominazione_ita": "Naso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F848",
      "lat": "38.1218472",
      "lon": "14.7881924",
      "superficie_kmq": "36.7367",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083061",
      "denominazione_ita_altra": "Nizza di Sicilia",
      "denominazione_ita": "Nizza di Sicilia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F901",
      "lat": "37.9887617",
      "lon": "15.4079348",
      "superficie_kmq": "13.4154",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083062",
      "denominazione_ita_altra": "Novara di Sicilia",
      "denominazione_ita": "Novara di Sicilia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F951",
      "lat": "38.0161515",
      "lon": "15.1319298",
      "superficie_kmq": "49.1783",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083063",
      "denominazione_ita_altra": "Oliveri",
      "denominazione_ita": "Oliveri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G036",
      "lat": "38.1245369",
      "lon": "15.0594701",
      "superficie_kmq": "10.4348",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083064",
      "denominazione_ita_altra": "Pace del Mela",
      "denominazione_ita": "Pace del Mela",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G209",
      "lat": "38.1800334",
      "lon": "15.3056965",
      "superficie_kmq": "12.1757",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083065",
      "denominazione_ita_altra": "Pagliara",
      "denominazione_ita": "Pagliara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G234",
      "lat": "37.9864894",
      "lon": "15.3594908",
      "superficie_kmq": "14.4792",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083066",
      "denominazione_ita_altra": "Patti",
      "denominazione_ita": "Patti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G377",
      "lat": "38.1396874",
      "lon": "14.9640281",
      "superficie_kmq": "50.0766",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083067",
      "denominazione_ita_altra": "Pettineo",
      "denominazione_ita": "Pettineo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G522",
      "lat": "37.9665141",
      "lon": "14.2912173",
      "superficie_kmq": "30.6164",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083068",
      "denominazione_ita_altra": "Piraino",
      "denominazione_ita": "Piraino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G699",
      "lat": "38.1618029",
      "lon": "14.8631585",
      "superficie_kmq": "16.9687",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083069",
      "denominazione_ita_altra": "Raccuja",
      "denominazione_ita": "Raccuja",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H151",
      "lat": "38.0555154",
      "lon": "14.9106422",
      "superficie_kmq": "25.2019",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083070",
      "denominazione_ita_altra": "Reitano",
      "denominazione_ita": "Reitano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H228",
      "lat": "37.9729772",
      "lon": "14.3444394",
      "superficie_kmq": "14.1173",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083071",
      "denominazione_ita_altra": "Roccafiorita",
      "denominazione_ita": "Roccafiorita",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H405",
      "lat": "37.9297770",
      "lon": "15.2684603",
      "superficie_kmq": "1.1681",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083072",
      "denominazione_ita_altra": "Roccalumera",
      "denominazione_ita": "Roccalumera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H418",
      "lat": "37.9749946",
      "lon": "15.3945240",
      "superficie_kmq": "8.9060",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083073",
      "denominazione_ita_altra": "Roccavaldina",
      "denominazione_ita": "Roccavaldina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H380",
      "lat": "38.1822046",
      "lon": "15.3749678",
      "superficie_kmq": "7.1325",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083074",
      "denominazione_ita_altra": "Roccella Valdemone",
      "denominazione_ita": "Roccella Valdemone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H455",
      "lat": "37.9326556",
      "lon": "15.0095104",
      "superficie_kmq": "41.1511",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083075",
      "denominazione_ita_altra": "Rodì Milici",
      "denominazione_ita": "Rodì Milici",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H479",
      "lat": "38.1103605",
      "lon": "15.1695618",
      "superficie_kmq": "36.5448",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083076",
      "denominazione_ita_altra": "Rometta",
      "denominazione_ita": "Rometta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H519",
      "lat": "38.1725595",
      "lon": "15.4154864",
      "superficie_kmq": "32.1231",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083077",
      "denominazione_ita_altra": "San Filippo del Mela",
      "denominazione_ita": "San Filippo del Mela",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H842",
      "lat": "38.1691945",
      "lon": "15.2718038",
      "superficie_kmq": "10.0502",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083078",
      "denominazione_ita_altra": "San Fratello",
      "denominazione_ita": "San Fratello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H850",
      "lat": "38.0127927",
      "lon": "14.5962215",
      "superficie_kmq": "67.6292",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083079",
      "denominazione_ita_altra": "San Marco d'Alunzio",
      "denominazione_ita": "San Marco d'Alunzio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H982",
      "lat": "38.0729520",
      "lon": "14.6988276",
      "superficie_kmq": "26.1407",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083080",
      "denominazione_ita_altra": "San Pier Niceto",
      "denominazione_ita": "San Pier Niceto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I084",
      "lat": "38.1586369",
      "lon": "15.3515104",
      "superficie_kmq": "36.6798",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083081",
      "denominazione_ita_altra": "San Piero Patti",
      "denominazione_ita": "San Piero Patti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I086",
      "lat": "38.0505855",
      "lon": "14.9662157",
      "superficie_kmq": "41.8183",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083082",
      "denominazione_ita_altra": "San Salvatore di Fitalia",
      "denominazione_ita": "San Salvatore di Fitalia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I147",
      "lat": "38.0704470",
      "lon": "14.7774476",
      "superficie_kmq": "14.9963",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083083",
      "denominazione_ita_altra": "Santa Domenica Vittoria",
      "denominazione_ita": "Santa Domenica Vittoria",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I184",
      "lat": "37.9154467",
      "lon": "14.9618786",
      "superficie_kmq": "20.1634",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083084",
      "denominazione_ita_altra": "Sant'Agata di Militello",
      "denominazione_ita": "Sant'Agata di Militello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I199",
      "lat": "38.0694226",
      "lon": "14.6336698",
      "superficie_kmq": "33.9764",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083085",
      "denominazione_ita_altra": "Sant'Alessio Siculo",
      "denominazione_ita": "Sant'Alessio Siculo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I215",
      "lat": "37.9174781",
      "lon": "15.3447678",
      "superficie_kmq": "6.1676",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083086",
      "denominazione_ita_altra": "Santa Lucia del Mela",
      "denominazione_ita": "Santa Lucia del Mela",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I220",
      "lat": "38.1398967",
      "lon": "15.2831679",
      "superficie_kmq": "85.6740",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083087",
      "denominazione_ita_altra": "Santa Marina Salina",
      "denominazione_ita": "Santa Marina Salina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I254",
      "lat": "38.5593310",
      "lon": "14.8716878",
      "superficie_kmq": "8.7814",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083088",
      "denominazione_ita_altra": "Sant'Angelo di Brolo",
      "denominazione_ita": "Sant'Angelo di Brolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I283",
      "lat": "38.1131050",
      "lon": "14.8821434",
      "superficie_kmq": "30.3859",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083089",
      "denominazione_ita_altra": "Santa Teresa di Riva",
      "denominazione_ita": "Santa Teresa di Riva",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I311",
      "lat": "37.9456139",
      "lon": "15.3679381",
      "superficie_kmq": "8.1175",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083090",
      "denominazione_ita_altra": "San Teodoro",
      "denominazione_ita": "San Teodoro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I328",
      "lat": "37.8477070",
      "lon": "14.6993658",
      "superficie_kmq": "13.9740",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083091",
      "denominazione_ita_altra": "Santo Stefano di Camastra",
      "denominazione_ita": "Santo Stefano di Camastra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I370",
      "lat": "38.0162489",
      "lon": "14.3483199",
      "superficie_kmq": "21.9173",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083092",
      "denominazione_ita_altra": "Saponara",
      "denominazione_ita": "Saponara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I420",
      "lat": "38.1937712",
      "lon": "15.4342465",
      "superficie_kmq": "26.2623",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083093",
      "denominazione_ita_altra": "Savoca",
      "denominazione_ita": "Savoca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I477",
      "lat": "37.9559746",
      "lon": "15.3397828",
      "superficie_kmq": "9.0755",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083094",
      "denominazione_ita_altra": "Scaletta Zanclea",
      "denominazione_ita": "Scaletta Zanclea",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I492",
      "lat": "38.0427580",
      "lon": "15.4593190",
      "superficie_kmq": "4.7618",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083095",
      "denominazione_ita_altra": "Sinagra",
      "denominazione_ita": "Sinagra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I747",
      "lat": "38.0831398",
      "lon": "14.8495202",
      "superficie_kmq": "24.0270",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083096",
      "denominazione_ita_altra": "Spadafora",
      "denominazione_ita": "Spadafora",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I881",
      "lat": "38.2219818",
      "lon": "15.3741288",
      "superficie_kmq": "10.5246",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083097",
      "denominazione_ita_altra": "Taormina",
      "denominazione_ita": "Taormina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L042",
      "lat": "37.8510327",
      "lon": "15.2830750",
      "superficie_kmq": "13.1331",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083098",
      "denominazione_ita_altra": "Torregrotta",
      "denominazione_ita": "Torregrotta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L271",
      "lat": "38.1915595",
      "lon": "15.3523075",
      "superficie_kmq": "4.1260",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083099",
      "denominazione_ita_altra": "Tortorici",
      "denominazione_ita": "Tortorici",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L308",
      "lat": "38.0304426",
      "lon": "14.8248560",
      "superficie_kmq": "70.5008",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083100",
      "denominazione_ita_altra": "Tripi",
      "denominazione_ita": "Tripi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L431",
      "lat": "38.0492397",
      "lon": "15.0994219",
      "superficie_kmq": "54.6669",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083101",
      "denominazione_ita_altra": "Tusa",
      "denominazione_ita": "Tusa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L478",
      "lat": "37.9844705",
      "lon": "14.2385207",
      "superficie_kmq": "41.0737",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083102",
      "denominazione_ita_altra": "Ucria",
      "denominazione_ita": "Ucria",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L482",
      "lat": "38.0455290",
      "lon": "14.8809137",
      "superficie_kmq": "26.2643",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083103",
      "denominazione_ita_altra": "Valdina",
      "denominazione_ita": "Valdina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L561",
      "lat": "38.1934030",
      "lon": "15.3707298",
      "superficie_kmq": "2.6016",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083104",
      "denominazione_ita_altra": "Venetico",
      "denominazione_ita": "Venetico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L735",
      "lat": "38.2189586",
      "lon": "15.3647899",
      "superficie_kmq": "4.5170",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083105",
      "denominazione_ita_altra": "Villafranca Tirrena",
      "denominazione_ita": "Villafranca Tirrena",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L950",
      "lat": "38.2353590",
      "lon": "15.4324910",
      "superficie_kmq": "14.2458",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083106",
      "denominazione_ita_altra": "Terme Vigliatore",
      "denominazione_ita": "Terme Vigliatore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M210",
      "lat": "38.1366306",
      "lon": "15.1624029",
      "superficie_kmq": "13.2280",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083107",
      "denominazione_ita_altra": "Acquedolci",
      "denominazione_ita": "Acquedolci",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M211",
      "lat": "38.0562630",
      "lon": "14.5880545",
      "superficie_kmq": "12.9304",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "ME",
      "codice_istat": "083108",
      "denominazione_ita_altra": "Torrenova",
      "denominazione_ita": "Torrenova",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M286",
      "lat": "38.0910284",
      "lon": "14.6773322",
      "superficie_kmq": "12.9268",
      "codice_sovracomunale": "283"
    },
    {
      "sigla_provincia": "AG",
      "codice_istat": "084001",
      "denominazione_ita_altra": "Agrigento",
      "denominazione_ita": "Agrigento",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "A089",
      "lat": "37.3096024",
      "lon": "13.5845684",
      "superficie_kmq": "243.5037",
      "codice_sovracomunale": "084"
    },
    {
      "sigla_provincia": "AG",
      "codice_istat": "084002",
      "denominazione_ita_altra": "Alessandria della Rocca",
      "denominazione_ita": "Alessandria della Rocca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A181",
      "lat": "37.5696297",
      "lon": "13.4534821",
      "superficie_kmq": "62.2395",
      "codice_sovracomunale": "084"
    },
    {
      "sigla_provincia": "AG",
      "codice_istat": "084003",
      "denominazione_ita_altra": "Aragona",
      "denominazione_ita": "Aragona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A351",
      "lat": "37.4023233",
      "lon": "13.6177823",
      "superficie_kmq": "74.7691",
      "codice_sovracomunale": "084"
    },
    {
      "sigla_provincia": "AG",
      "codice_istat": "084004",
      "denominazione_ita_altra": "Bivona",
      "denominazione_ita": "Bivona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A896",
      "lat": "37.6180053",
      "lon": "13.4403942",
      "superficie_kmq": "88.5732",
      "codice_sovracomunale": "084"
    },
    {
      "sigla_provincia": "AG",
      "codice_istat": "084005",
      "denominazione_ita_altra": "Burgio",
      "denominazione_ita": "Burgio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B275",
      "lat": "37.5987656",
      "lon": "13.2895311",
      "superficie_kmq": "42.2307",
      "codice_sovracomunale": "084"
    },
    {
      "sigla_provincia": "AG",
      "codice_istat": "084006",
      "denominazione_ita_altra": "Calamonaci",
      "denominazione_ita": "Calamonaci",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B377",
      "lat": "37.5252364",
      "lon": "13.2908970",
      "superficie_kmq": "32.8901",
      "codice_sovracomunale": "084"
    },
    {
      "sigla_provincia": "AG",
      "codice_istat": "084007",
      "denominazione_ita_altra": "Caltabellotta",
      "denominazione_ita": "Caltabellotta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B427",
      "lat": "37.5767978",
      "lon": "13.2156442",
      "superficie_kmq": "124.0870",
      "codice_sovracomunale": "084"
    },
    {
      "sigla_provincia": "AG",
      "codice_istat": "084008",
      "denominazione_ita_altra": "Camastra",
      "denominazione_ita": "Camastra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B460",
      "lat": "37.2520482",
      "lon": "13.7937666",
      "superficie_kmq": "16.3155",
      "codice_sovracomunale": "084"
    },
    {
      "sigla_provincia": "AG",
      "codice_istat": "084009",
      "denominazione_ita_altra": "Cammarata",
      "denominazione_ita": "Cammarata",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B486",
      "lat": "37.6326245",
      "lon": "13.6379709",
      "superficie_kmq": "192.4530",
      "codice_sovracomunale": "084"
    },
    {
      "sigla_provincia": "AG",
      "codice_istat": "084010",
      "denominazione_ita_altra": "Campobello di Licata",
      "denominazione_ita": "Campobello di Licata",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B520",
      "lat": "37.2596745",
      "lon": "13.9188827",
      "superficie_kmq": "81.3276",
      "codice_sovracomunale": "084"
    },
    {
      "sigla_provincia": "AG",
      "codice_istat": "084011",
      "denominazione_ita_altra": "Canicattì",
      "denominazione_ita": "Canicattì",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B602",
      "lat": "37.3601311",
      "lon": "13.8499839",
      "superficie_kmq": "91.8595",
      "codice_sovracomunale": "084"
    },
    {
      "sigla_provincia": "AG",
      "codice_istat": "084012",
      "denominazione_ita_altra": "Casteltermini",
      "denominazione_ita": "Casteltermini",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C275",
      "lat": "37.5407047",
      "lon": "13.6433966",
      "superficie_kmq": "99.9816",
      "codice_sovracomunale": "084"
    },
    {
      "sigla_provincia": "AG",
      "codice_istat": "084013",
      "denominazione_ita_altra": "Castrofilippo",
      "denominazione_ita": "Castrofilippo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C341",
      "lat": "37.3485820",
      "lon": "13.7501767",
      "superficie_kmq": "18.0752",
      "codice_sovracomunale": "084"
    },
    {
      "sigla_provincia": "AG",
      "codice_istat": "084014",
      "denominazione_ita_altra": "Cattolica Eraclea",
      "denominazione_ita": "Cattolica Eraclea",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C356",
      "lat": "37.4394212",
      "lon": "13.3953936",
      "superficie_kmq": "62.1638",
      "codice_sovracomunale": "084"
    },
    {
      "sigla_provincia": "AG",
      "codice_istat": "084015",
      "denominazione_ita_altra": "Cianciana",
      "denominazione_ita": "Cianciana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C668",
      "lat": "37.5173412",
      "lon": "13.4352519",
      "superficie_kmq": "38.0815",
      "codice_sovracomunale": "084"
    },
    {
      "sigla_provincia": "AG",
      "codice_istat": "084016",
      "denominazione_ita_altra": "Comitini",
      "denominazione_ita": "Comitini",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C928",
      "lat": "37.4085017",
      "lon": "13.6436136",
      "superficie_kmq": "21.8900",
      "codice_sovracomunale": "084"
    },
    {
      "sigla_provincia": "AG",
      "codice_istat": "084017",
      "denominazione_ita_altra": "Favara",
      "denominazione_ita": "Favara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D514",
      "lat": "37.3106013",
      "lon": "13.6564564",
      "superficie_kmq": "83.6201",
      "codice_sovracomunale": "084"
    },
    {
      "sigla_provincia": "AG",
      "codice_istat": "084018",
      "denominazione_ita_altra": "Grotte",
      "denominazione_ita": "Grotte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E209",
      "lat": "37.4024414",
      "lon": "13.6991729",
      "superficie_kmq": "23.9760",
      "codice_sovracomunale": "084"
    },
    {
      "sigla_provincia": "AG",
      "codice_istat": "084019",
      "denominazione_ita_altra": "Joppolo Giancaxio",
      "denominazione_ita": "Joppolo Giancaxio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E390",
      "lat": "37.3876382",
      "lon": "13.5540913",
      "superficie_kmq": "19.1421",
      "codice_sovracomunale": "084"
    },
    {
      "sigla_provincia": "AG",
      "codice_istat": "084020",
      "denominazione_ita_altra": "Lampedusa e Linosa",
      "denominazione_ita": "Lampedusa e Linosa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E431",
      "lat": "35.5018240",
      "lon": "12.6098342",
      "superficie_kmq": "25.2217",
      "codice_sovracomunale": "084"
    },
    {
      "sigla_provincia": "AG",
      "codice_istat": "084021",
      "denominazione_ita_altra": "Licata",
      "denominazione_ita": "Licata",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E573",
      "lat": "37.1014565",
      "lon": "13.9371761",
      "superficie_kmq": "179.9535",
      "codice_sovracomunale": "084"
    },
    {
      "sigla_provincia": "AG",
      "codice_istat": "084022",
      "denominazione_ita_altra": "Lucca Sicula",
      "denominazione_ita": "Lucca Sicula",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E714",
      "lat": "37.5783506",
      "lon": "13.3067011",
      "superficie_kmq": "18.6320",
      "codice_sovracomunale": "084"
    },
    {
      "sigla_provincia": "AG",
      "codice_istat": "084023",
      "denominazione_ita_altra": "Menfi",
      "denominazione_ita": "Menfi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F126",
      "lat": "37.5981198",
      "lon": "12.9664056",
      "superficie_kmq": "113.5780",
      "codice_sovracomunale": "084"
    },
    {
      "sigla_provincia": "AG",
      "codice_istat": "084024",
      "denominazione_ita_altra": "Montallegro",
      "denominazione_ita": "Montallegro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F414",
      "lat": "37.3909815",
      "lon": "13.3506733",
      "superficie_kmq": "27.4124",
      "codice_sovracomunale": "084"
    },
    {
      "sigla_provincia": "AG",
      "codice_istat": "084025",
      "denominazione_ita_altra": "Montevago",
      "denominazione_ita": "Montevago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F655",
      "lat": "37.7025190",
      "lon": "12.9901717",
      "superficie_kmq": "32.9092",
      "codice_sovracomunale": "084"
    },
    {
      "sigla_provincia": "AG",
      "codice_istat": "084026",
      "denominazione_ita_altra": "Naro",
      "denominazione_ita": "Naro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F845",
      "lat": "37.2959553",
      "lon": "13.7920346",
      "superficie_kmq": "207.4873",
      "codice_sovracomunale": "084"
    },
    {
      "sigla_provincia": "AG",
      "codice_istat": "084027",
      "denominazione_ita_altra": "Palma di Montechiaro",
      "denominazione_ita": "Palma di Montechiaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G282",
      "lat": "37.1915666",
      "lon": "13.7636683",
      "superficie_kmq": "77.0626",
      "codice_sovracomunale": "084"
    },
    {
      "sigla_provincia": "AG",
      "codice_istat": "084028",
      "denominazione_ita_altra": "Porto Empedocle",
      "denominazione_ita": "Porto Empedocle",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F299",
      "lat": "37.2883565",
      "lon": "13.5274606",
      "superficie_kmq": "25.2257",
      "codice_sovracomunale": "084"
    },
    {
      "sigla_provincia": "AG",
      "codice_istat": "084029",
      "denominazione_ita_altra": "Racalmuto",
      "denominazione_ita": "Racalmuto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H148",
      "lat": "37.4076057",
      "lon": "13.7314106",
      "superficie_kmq": "68.1009",
      "codice_sovracomunale": "084"
    },
    {
      "sigla_provincia": "AG",
      "codice_istat": "084030",
      "denominazione_ita_altra": "Raffadali",
      "denominazione_ita": "Raffadali",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H159",
      "lat": "37.4047897",
      "lon": "13.5310878",
      "superficie_kmq": "22.2987",
      "codice_sovracomunale": "084"
    },
    {
      "sigla_provincia": "AG",
      "codice_istat": "084031",
      "denominazione_ita_altra": "Ravanusa",
      "denominazione_ita": "Ravanusa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H194",
      "lat": "37.2681275",
      "lon": "13.9724745",
      "superficie_kmq": "49.5006",
      "codice_sovracomunale": "084"
    },
    {
      "sigla_provincia": "AG",
      "codice_istat": "084032",
      "denominazione_ita_altra": "Realmonte",
      "denominazione_ita": "Realmonte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H205",
      "lat": "37.3066971",
      "lon": "13.4627851",
      "superficie_kmq": "20.3693",
      "codice_sovracomunale": "084"
    },
    {
      "sigla_provincia": "AG",
      "codice_istat": "084033",
      "denominazione_ita_altra": "Ribera",
      "denominazione_ita": "Ribera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H269",
      "lat": "37.5037148",
      "lon": "13.2686515",
      "superficie_kmq": "118.5210",
      "codice_sovracomunale": "084"
    },
    {
      "sigla_provincia": "AG",
      "codice_istat": "084034",
      "denominazione_ita_altra": "Sambuca di Sicilia",
      "denominazione_ita": "Sambuca di Sicilia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H743",
      "lat": "37.6473161",
      "lon": "13.1107201",
      "superficie_kmq": "96.3715",
      "codice_sovracomunale": "084"
    },
    {
      "sigla_provincia": "AG",
      "codice_istat": "084035",
      "denominazione_ita_altra": "San Biagio Platani",
      "denominazione_ita": "San Biagio Platani",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H778",
      "lat": "37.5088862",
      "lon": "13.5242218",
      "superficie_kmq": "42.6663",
      "codice_sovracomunale": "084"
    },
    {
      "sigla_provincia": "AG",
      "codice_istat": "084036",
      "denominazione_ita_altra": "San Giovanni Gemini",
      "denominazione_ita": "San Giovanni Gemini",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H914",
      "lat": "37.6298401",
      "lon": "13.6411954",
      "superficie_kmq": "26.5594",
      "codice_sovracomunale": "084"
    },
    {
      "sigla_provincia": "AG",
      "codice_istat": "084037",
      "denominazione_ita_altra": "Santa Elisabetta",
      "denominazione_ita": "Santa Elisabetta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I185",
      "lat": "37.4314776",
      "lon": "13.5548984",
      "superficie_kmq": "16.1674",
      "codice_sovracomunale": "084"
    },
    {
      "sigla_provincia": "AG",
      "codice_istat": "084038",
      "denominazione_ita_altra": "Santa Margherita di Belice",
      "denominazione_ita": "Santa Margherita di Belice",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I224",
      "lat": "37.6918686",
      "lon": "13.0240186",
      "superficie_kmq": "67.2752",
      "codice_sovracomunale": "084"
    },
    {
      "sigla_provincia": "AG",
      "codice_istat": "084039",
      "denominazione_ita_altra": "Sant'Angelo Muxaro",
      "denominazione_ita": "Sant'Angelo Muxaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I290",
      "lat": "37.4790811",
      "lon": "13.5442697",
      "superficie_kmq": "64.5212",
      "codice_sovracomunale": "084"
    },
    {
      "sigla_provincia": "AG",
      "codice_istat": "084040",
      "denominazione_ita_altra": "Santo Stefano Quisquina",
      "denominazione_ita": "Santo Stefano Quisquina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I356",
      "lat": "37.6245936",
      "lon": "13.4903137",
      "superficie_kmq": "85.5183",
      "codice_sovracomunale": "084"
    },
    {
      "sigla_provincia": "AG",
      "codice_istat": "084041",
      "denominazione_ita_altra": "Sciacca",
      "denominazione_ita": "Sciacca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I533",
      "lat": "37.5076652",
      "lon": "13.0829203",
      "superficie_kmq": "191.6678",
      "codice_sovracomunale": "084"
    },
    {
      "sigla_provincia": "AG",
      "codice_istat": "084042",
      "denominazione_ita_altra": "Siculiana",
      "denominazione_ita": "Siculiana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I723",
      "lat": "37.3363763",
      "lon": "13.4200364",
      "superficie_kmq": "40.9909",
      "codice_sovracomunale": "084"
    },
    {
      "sigla_provincia": "AG",
      "codice_istat": "084043",
      "denominazione_ita_altra": "Villafranca Sicula",
      "denominazione_ita": "Villafranca Sicula",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L944",
      "lat": "37.5867282",
      "lon": "13.2909291",
      "superficie_kmq": "17.6292",
      "codice_sovracomunale": "084"
    },
    {
      "sigla_provincia": "CL",
      "codice_istat": "085001",
      "denominazione_ita_altra": "Acquaviva Platani",
      "denominazione_ita": "Acquaviva Platani",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A049",
      "lat": "37.5715991",
      "lon": "13.7001362",
      "superficie_kmq": "14.6333",
      "codice_sovracomunale": "085"
    },
    {
      "sigla_provincia": "CL",
      "codice_istat": "085002",
      "denominazione_ita_altra": "Bompensiere",
      "denominazione_ita": "Bompensiere",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A957",
      "lat": "37.4725272",
      "lon": "13.7806331",
      "superficie_kmq": "19.9464",
      "codice_sovracomunale": "085"
    },
    {
      "sigla_provincia": "CL",
      "codice_istat": "085003",
      "denominazione_ita_altra": "Butera",
      "denominazione_ita": "Butera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B302",
      "lat": "37.1863205",
      "lon": "14.1862754",
      "superficie_kmq": "298.5222",
      "codice_sovracomunale": "085"
    },
    {
      "sigla_provincia": "CL",
      "codice_istat": "085004",
      "denominazione_ita_altra": "Caltanissetta",
      "denominazione_ita": "Caltanissetta",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "B429",
      "lat": "37.4920259",
      "lon": "14.0618427",
      "superficie_kmq": "421.2465",
      "codice_sovracomunale": "085"
    },
    {
      "sigla_provincia": "CL",
      "codice_istat": "085005",
      "denominazione_ita_altra": "Campofranco",
      "denominazione_ita": "Campofranco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B537",
      "lat": "37.5119041",
      "lon": "13.7125461",
      "superficie_kmq": "36.1130",
      "codice_sovracomunale": "085"
    },
    {
      "sigla_provincia": "CL",
      "codice_istat": "085006",
      "denominazione_ita_altra": "Delia",
      "denominazione_ita": "Delia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D267",
      "lat": "37.3559513",
      "lon": "13.9276184",
      "superficie_kmq": "12.3988",
      "codice_sovracomunale": "085"
    },
    {
      "sigla_provincia": "CL",
      "codice_istat": "085007",
      "denominazione_ita_altra": "Gela",
      "denominazione_ita": "Gela",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D960",
      "lat": "37.0653433",
      "lon": "14.2503423",
      "superficie_kmq": "279.2195",
      "codice_sovracomunale": "085"
    },
    {
      "sigla_provincia": "CL",
      "codice_istat": "085008",
      "denominazione_ita_altra": "Marianopoli",
      "denominazione_ita": "Marianopoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E953",
      "lat": "37.5987279",
      "lon": "13.9150739",
      "superficie_kmq": "13.0705",
      "codice_sovracomunale": "085"
    },
    {
      "sigla_provincia": "CL",
      "codice_istat": "085009",
      "denominazione_ita_altra": "Mazzarino",
      "denominazione_ita": "Mazzarino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F065",
      "lat": "37.3048920",
      "lon": "14.2142365",
      "superficie_kmq": "295.5893",
      "codice_sovracomunale": "085"
    },
    {
      "sigla_provincia": "CL",
      "codice_istat": "085010",
      "denominazione_ita_altra": "Milena",
      "denominazione_ita": "Milena",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E618",
      "lat": "37.4713463",
      "lon": "13.7362730",
      "superficie_kmq": "24.6266",
      "codice_sovracomunale": "085"
    },
    {
      "sigla_provincia": "CL",
      "codice_istat": "085011",
      "denominazione_ita_altra": "Montedoro",
      "denominazione_ita": "Montedoro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F489",
      "lat": "37.4536301",
      "lon": "13.8163706",
      "superficie_kmq": "14.5331",
      "codice_sovracomunale": "085"
    },
    {
      "sigla_provincia": "CL",
      "codice_istat": "085012",
      "denominazione_ita_altra": "Mussomeli",
      "denominazione_ita": "Mussomeli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F830",
      "lat": "37.5801194",
      "lon": "13.7540191",
      "superficie_kmq": "164.4247",
      "codice_sovracomunale": "085"
    },
    {
      "sigla_provincia": "CL",
      "codice_istat": "085013",
      "denominazione_ita_altra": "Niscemi",
      "denominazione_ita": "Niscemi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F899",
      "lat": "37.1451712",
      "lon": "14.3850320",
      "superficie_kmq": "96.8171",
      "codice_sovracomunale": "085"
    },
    {
      "sigla_provincia": "CL",
      "codice_istat": "085014",
      "denominazione_ita_altra": "Resuttano",
      "denominazione_ita": "Resuttano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H245",
      "lat": "37.6800247",
      "lon": "14.0310463",
      "superficie_kmq": "38.2733",
      "codice_sovracomunale": "085"
    },
    {
      "sigla_provincia": "CL",
      "codice_istat": "085015",
      "denominazione_ita_altra": "Riesi",
      "denominazione_ita": "Riesi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H281",
      "lat": "37.2832744",
      "lon": "14.0821991",
      "superficie_kmq": "66.9953",
      "codice_sovracomunale": "085"
    },
    {
      "sigla_provincia": "CL",
      "codice_istat": "085016",
      "denominazione_ita_altra": "San Cataldo",
      "denominazione_ita": "San Cataldo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H792",
      "lat": "37.4870559",
      "lon": "13.9904720",
      "superficie_kmq": "72.7746",
      "codice_sovracomunale": "085"
    },
    {
      "sigla_provincia": "CL",
      "codice_istat": "085017",
      "denominazione_ita_altra": "Santa Caterina Villarmosa",
      "denominazione_ita": "Santa Caterina Villarmosa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I169",
      "lat": "37.5916453",
      "lon": "14.0318829",
      "superficie_kmq": "75.8139",
      "codice_sovracomunale": "085"
    },
    {
      "sigla_provincia": "CL",
      "codice_istat": "085018",
      "denominazione_ita_altra": "Serradifalco",
      "denominazione_ita": "Serradifalco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I644",
      "lat": "37.4564310",
      "lon": "13.8825015",
      "superficie_kmq": "41.9382",
      "codice_sovracomunale": "085"
    },
    {
      "sigla_provincia": "CL",
      "codice_istat": "085019",
      "denominazione_ita_altra": "Sommatino",
      "denominazione_ita": "Sommatino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I824",
      "lat": "37.3337555",
      "lon": "13.9912158",
      "superficie_kmq": "34.7565",
      "codice_sovracomunale": "085"
    },
    {
      "sigla_provincia": "CL",
      "codice_istat": "085020",
      "denominazione_ita_altra": "Sutera",
      "denominazione_ita": "Sutera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L016",
      "lat": "37.5254765",
      "lon": "13.7312981",
      "superficie_kmq": "35.5836",
      "codice_sovracomunale": "085"
    },
    {
      "sigla_provincia": "CL",
      "codice_istat": "085021",
      "denominazione_ita_altra": "Vallelunga Pratameno",
      "denominazione_ita": "Vallelunga Pratameno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L609",
      "lat": "37.6801031",
      "lon": "13.8304646",
      "superficie_kmq": "39.3738",
      "codice_sovracomunale": "085"
    },
    {
      "sigla_provincia": "CL",
      "codice_istat": "085022",
      "denominazione_ita_altra": "Villalba",
      "denominazione_ita": "Villalba",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L959",
      "lat": "37.6548730",
      "lon": "13.8439260",
      "superficie_kmq": "41.8177",
      "codice_sovracomunale": "085"
    },
    {
      "sigla_provincia": "EN",
      "codice_istat": "086001",
      "denominazione_ita_altra": "Agira",
      "denominazione_ita": "Agira",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A070",
      "lat": "37.6571089",
      "lon": "14.5237881",
      "superficie_kmq": "164.0807",
      "codice_sovracomunale": "086"
    },
    {
      "sigla_provincia": "EN",
      "codice_istat": "086002",
      "denominazione_ita_altra": "Aidone",
      "denominazione_ita": "Aidone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A098",
      "lat": "37.4158957",
      "lon": "14.4478835",
      "superficie_kmq": "210.7759",
      "codice_sovracomunale": "086"
    },
    {
      "sigla_provincia": "EN",
      "codice_istat": "086003",
      "denominazione_ita_altra": "Assoro",
      "denominazione_ita": "Assoro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A478",
      "lat": "37.6280588",
      "lon": "14.4240789",
      "superficie_kmq": "112.1499",
      "codice_sovracomunale": "086"
    },
    {
      "sigla_provincia": "EN",
      "codice_istat": "086004",
      "denominazione_ita_altra": "Barrafranca",
      "denominazione_ita": "Barrafranca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A676",
      "lat": "37.3727424",
      "lon": "14.2038710",
      "superficie_kmq": "53.7120",
      "codice_sovracomunale": "086"
    },
    {
      "sigla_provincia": "EN",
      "codice_istat": "086005",
      "denominazione_ita_altra": "Calascibetta",
      "denominazione_ita": "Calascibetta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B381",
      "lat": "37.5894535",
      "lon": "14.2730833",
      "superficie_kmq": "89.1179",
      "codice_sovracomunale": "086"
    },
    {
      "sigla_provincia": "EN",
      "codice_istat": "086006",
      "denominazione_ita_altra": "Catenanuova",
      "denominazione_ita": "Catenanuova",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C353",
      "lat": "37.5678206",
      "lon": "14.6914502",
      "superficie_kmq": "11.2247",
      "codice_sovracomunale": "086"
    },
    {
      "sigla_provincia": "EN",
      "codice_istat": "086007",
      "denominazione_ita_altra": "Centuripe",
      "denominazione_ita": "Centuripe",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C471",
      "lat": "37.6202062",
      "lon": "14.7414226",
      "superficie_kmq": "174.1946",
      "codice_sovracomunale": "086"
    },
    {
      "sigla_provincia": "EN",
      "codice_istat": "086008",
      "denominazione_ita_altra": "Cerami",
      "denominazione_ita": "Cerami",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C480",
      "lat": "37.8102492",
      "lon": "14.5063637",
      "superficie_kmq": "95.0465",
      "codice_sovracomunale": "086"
    },
    {
      "sigla_provincia": "EN",
      "codice_istat": "086009",
      "denominazione_ita_altra": "Enna",
      "denominazione_ita": "Enna",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "C342",
      "lat": "37.5669523",
      "lon": "14.2790865",
      "superficie_kmq": "358.7437",
      "codice_sovracomunale": "086"
    },
    {
      "sigla_provincia": "EN",
      "codice_istat": "086010",
      "denominazione_ita_altra": "Gagliano Castelferrato",
      "denominazione_ita": "Gagliano Castelferrato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D849",
      "lat": "37.7102162",
      "lon": "14.5355764",
      "superficie_kmq": "56.2367",
      "codice_sovracomunale": "086"
    },
    {
      "sigla_provincia": "EN",
      "codice_istat": "086011",
      "denominazione_ita_altra": "Leonforte",
      "denominazione_ita": "Leonforte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E536",
      "lat": "37.6390783",
      "lon": "14.3920586",
      "superficie_kmq": "84.3917",
      "codice_sovracomunale": "086"
    },
    {
      "sigla_provincia": "EN",
      "codice_istat": "086012",
      "denominazione_ita_altra": "Nicosia",
      "denominazione_ita": "Nicosia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F892",
      "lat": "37.7479498",
      "lon": "14.3985399",
      "superficie_kmq": "218.5057",
      "codice_sovracomunale": "086"
    },
    {
      "sigla_provincia": "EN",
      "codice_istat": "086013",
      "denominazione_ita_altra": "Nissoria",
      "denominazione_ita": "Nissoria",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F900",
      "lat": "37.6550535",
      "lon": "14.4493560",
      "superficie_kmq": "61.8245",
      "codice_sovracomunale": "086"
    },
    {
      "sigla_provincia": "EN",
      "codice_istat": "086014",
      "denominazione_ita_altra": "Piazza Armerina",
      "denominazione_ita": "Piazza Armerina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G580",
      "lat": "37.3841015",
      "lon": "14.3660284",
      "superficie_kmq": "304.5376",
      "codice_sovracomunale": "086"
    },
    {
      "sigla_provincia": "EN",
      "codice_istat": "086015",
      "denominazione_ita_altra": "Pietraperzia",
      "denominazione_ita": "Pietraperzia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G624",
      "lat": "37.4215277",
      "lon": "14.1369063",
      "superficie_kmq": "118.1099",
      "codice_sovracomunale": "086"
    },
    {
      "sigla_provincia": "EN",
      "codice_istat": "086016",
      "denominazione_ita_altra": "Regalbuto",
      "denominazione_ita": "Regalbuto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H221",
      "lat": "37.6525228",
      "lon": "14.6407897",
      "superficie_kmq": "170.2924",
      "codice_sovracomunale": "086"
    },
    {
      "sigla_provincia": "EN",
      "codice_istat": "086017",
      "denominazione_ita_altra": "Sperlinga",
      "denominazione_ita": "Sperlinga",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I891",
      "lat": "37.7647984",
      "lon": "14.3533642",
      "superficie_kmq": "59.1390",
      "codice_sovracomunale": "086"
    },
    {
      "sigla_provincia": "EN",
      "codice_istat": "086018",
      "denominazione_ita_altra": "Troina",
      "denominazione_ita": "Troina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L448",
      "lat": "37.7849166",
      "lon": "14.6004405",
      "superficie_kmq": "168.2777",
      "codice_sovracomunale": "086"
    },
    {
      "sigla_provincia": "EN",
      "codice_istat": "086019",
      "denominazione_ita_altra": "Valguarnera Caropepe",
      "denominazione_ita": "Valguarnera Caropepe",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L583",
      "lat": "37.4965730",
      "lon": "14.3881559",
      "superficie_kmq": "9.4134",
      "codice_sovracomunale": "086"
    },
    {
      "sigla_provincia": "EN",
      "codice_istat": "086020",
      "denominazione_ita_altra": "Villarosa",
      "denominazione_ita": "Villarosa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M011",
      "lat": "37.5878130",
      "lon": "14.1742270",
      "superficie_kmq": "54.8920",
      "codice_sovracomunale": "086"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087001",
      "denominazione_ita_altra": "Aci Bonaccorsi",
      "denominazione_ita": "Aci Bonaccorsi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A025",
      "lat": "37.5977900",
      "lon": "15.1085988",
      "superficie_kmq": "1.7242",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087002",
      "denominazione_ita_altra": "Aci Castello",
      "denominazione_ita": "Aci Castello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A026",
      "lat": "37.5557140",
      "lon": "15.1452247",
      "superficie_kmq": "8.7058",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087003",
      "denominazione_ita_altra": "Aci Catena",
      "denominazione_ita": "Aci Catena",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A027",
      "lat": "37.6025781",
      "lon": "15.1422331",
      "superficie_kmq": "8.5219",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087004",
      "denominazione_ita_altra": "Acireale",
      "denominazione_ita": "Acireale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A028",
      "lat": "37.6120473",
      "lon": "15.1662793",
      "superficie_kmq": "40.4413",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087005",
      "denominazione_ita_altra": "Aci Sant'Antonio",
      "denominazione_ita": "Aci Sant'Antonio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A029",
      "lat": "37.6059138",
      "lon": "15.1258443",
      "superficie_kmq": "14.3333",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087006",
      "denominazione_ita_altra": "Adrano",
      "denominazione_ita": "Adrano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A056",
      "lat": "37.6617107",
      "lon": "14.8326793",
      "superficie_kmq": "83.2208",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087007",
      "denominazione_ita_altra": "Belpasso",
      "denominazione_ita": "Belpasso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A766",
      "lat": "37.5912846",
      "lon": "14.9770400",
      "superficie_kmq": "166.3264",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087008",
      "denominazione_ita_altra": "Biancavilla",
      "denominazione_ita": "Biancavilla",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A841",
      "lat": "37.6411531",
      "lon": "14.8695329",
      "superficie_kmq": "70.2753",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087009",
      "denominazione_ita_altra": "Bronte",
      "denominazione_ita": "Bronte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B202",
      "lat": "37.7874171",
      "lon": "14.8328915",
      "superficie_kmq": "250.8569",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087010",
      "denominazione_ita_altra": "Calatabiano",
      "denominazione_ita": "Calatabiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B384",
      "lat": "37.8232278",
      "lon": "15.2277413",
      "superficie_kmq": "26.4172",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087011",
      "denominazione_ita_altra": "Caltagirone",
      "denominazione_ita": "Caltagirone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B428",
      "lat": "37.2379771",
      "lon": "14.5127363",
      "superficie_kmq": "383.3735",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087012",
      "denominazione_ita_altra": "Camporotondo Etneo",
      "denominazione_ita": "Camporotondo Etneo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B561",
      "lat": "37.5672962",
      "lon": "15.0059499",
      "superficie_kmq": "6.5540",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087013",
      "denominazione_ita_altra": "Castel di Iudica",
      "denominazione_ita": "Castel di Iudica",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C091",
      "lat": "37.4943327",
      "lon": "14.6494415",
      "superficie_kmq": "103.2097",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087014",
      "denominazione_ita_altra": "Castiglione di Sicilia",
      "denominazione_ita": "Castiglione di Sicilia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C297",
      "lat": "37.8826039",
      "lon": "15.1223819",
      "superficie_kmq": "118.9022",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087015",
      "denominazione_ita_altra": "Catania",
      "denominazione_ita": "Catania",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "C351",
      "lat": "37.5027731",
      "lon": "15.0870388",
      "superficie_kmq": "182.7960",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087016",
      "denominazione_ita_altra": "Fiumefreddo di Sicilia",
      "denominazione_ita": "Fiumefreddo di Sicilia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D623",
      "lat": "37.7928293",
      "lon": "15.2081929",
      "superficie_kmq": "12.1599",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087017",
      "denominazione_ita_altra": "Giarre",
      "denominazione_ita": "Giarre",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E017",
      "lat": "37.7299350",
      "lon": "15.1844955",
      "superficie_kmq": "27.3544",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087018",
      "denominazione_ita_altra": "Grammichele",
      "denominazione_ita": "Grammichele",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E133",
      "lat": "37.2148965",
      "lon": "14.6372289",
      "superficie_kmq": "32.0655",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087019",
      "denominazione_ita_altra": "Gravina di Catania",
      "denominazione_ita": "Gravina di Catania",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E156",
      "lat": "37.5604777",
      "lon": "15.0621181",
      "superficie_kmq": "5.1302",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087020",
      "denominazione_ita_altra": "Licodia Eubea",
      "denominazione_ita": "Licodia Eubea",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E578",
      "lat": "37.1556186",
      "lon": "14.7033109",
      "superficie_kmq": "112.4453",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087021",
      "denominazione_ita_altra": "Linguaglossa",
      "denominazione_ita": "Linguaglossa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E602",
      "lat": "37.8406327",
      "lon": "15.1439999",
      "superficie_kmq": "60.2466",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087022",
      "denominazione_ita_altra": "Maletto",
      "denominazione_ita": "Maletto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E854",
      "lat": "37.8294003",
      "lon": "14.8662810",
      "superficie_kmq": "40.9571",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087023",
      "denominazione_ita_altra": "Mascali",
      "denominazione_ita": "Mascali",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F004",
      "lat": "37.7573938",
      "lon": "15.1953500",
      "superficie_kmq": "37.8462",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087024",
      "denominazione_ita_altra": "Mascalucia",
      "denominazione_ita": "Mascalucia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F005",
      "lat": "37.5729538",
      "lon": "15.0530131",
      "superficie_kmq": "16.3066",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087025",
      "denominazione_ita_altra": "Militello in Val di Catania",
      "denominazione_ita": "Militello in Val di Catania",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F209",
      "lat": "37.2746975",
      "lon": "14.7939895",
      "superficie_kmq": "62.4748",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087026",
      "denominazione_ita_altra": "Milo",
      "denominazione_ita": "Milo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F214",
      "lat": "37.7232425",
      "lon": "15.1160571",
      "superficie_kmq": "16.6713",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087027",
      "denominazione_ita_altra": "Mineo",
      "denominazione_ita": "Mineo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F217",
      "lat": "37.2647665",
      "lon": "14.6911031",
      "superficie_kmq": "245.2684",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087028",
      "denominazione_ita_altra": "Mirabella Imbaccari",
      "denominazione_ita": "Mirabella Imbaccari",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F231",
      "lat": "37.3243280",
      "lon": "14.4460248",
      "superficie_kmq": "15.3009",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087029",
      "denominazione_ita_altra": "Misterbianco",
      "denominazione_ita": "Misterbianco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F250",
      "lat": "37.5207400",
      "lon": "15.0063638",
      "superficie_kmq": "37.6868",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087030",
      "denominazione_ita_altra": "Motta Sant'Anastasia",
      "denominazione_ita": "Motta Sant'Anastasia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F781",
      "lat": "37.5119963",
      "lon": "14.9697592",
      "superficie_kmq": "35.6999",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087031",
      "denominazione_ita_altra": "Nicolosi",
      "denominazione_ita": "Nicolosi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F890",
      "lat": "37.6140360",
      "lon": "15.0267053",
      "superficie_kmq": "42.6505",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087032",
      "denominazione_ita_altra": "Palagonia",
      "denominazione_ita": "Palagonia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G253",
      "lat": "37.3275519",
      "lon": "14.7457557",
      "superficie_kmq": "57.7851",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087033",
      "denominazione_ita_altra": "Paternò",
      "denominazione_ita": "Paternò",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G371",
      "lat": "37.5768631",
      "lon": "14.9025104",
      "superficie_kmq": "144.6802",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087034",
      "denominazione_ita_altra": "Pedara",
      "denominazione_ita": "Pedara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G402",
      "lat": "37.6171007",
      "lon": "15.0602075",
      "superficie_kmq": "19.2394",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087035",
      "denominazione_ita_altra": "Piedimonte Etneo",
      "denominazione_ita": "Piedimonte Etneo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G597",
      "lat": "37.8075674",
      "lon": "15.1782057",
      "superficie_kmq": "26.5431",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087036",
      "denominazione_ita_altra": "Raddusa",
      "denominazione_ita": "Raddusa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H154",
      "lat": "37.4728131",
      "lon": "14.5317237",
      "superficie_kmq": "23.3861",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087037",
      "denominazione_ita_altra": "Ramacca",
      "denominazione_ita": "Ramacca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H168",
      "lat": "37.3847654",
      "lon": "14.6937748",
      "superficie_kmq": "306.4331",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087038",
      "denominazione_ita_altra": "Randazzo",
      "denominazione_ita": "Randazzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H175",
      "lat": "37.8778084",
      "lon": "14.9460456",
      "superficie_kmq": "205.6137",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087039",
      "denominazione_ita_altra": "Riposto",
      "denominazione_ita": "Riposto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H325",
      "lat": "37.7317167",
      "lon": "15.2038266",
      "superficie_kmq": "13.2139",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087040",
      "denominazione_ita_altra": "San Cono",
      "denominazione_ita": "San Cono",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H805",
      "lat": "37.2898337",
      "lon": "14.3659970",
      "superficie_kmq": "6.6313",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087041",
      "denominazione_ita_altra": "San Giovanni la Punta",
      "denominazione_ita": "San Giovanni la Punta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H922",
      "lat": "37.5769637",
      "lon": "15.0942885",
      "superficie_kmq": "10.8496",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087042",
      "denominazione_ita_altra": "San Gregorio di Catania",
      "denominazione_ita": "San Gregorio di Catania",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H940",
      "lat": "37.5647800",
      "lon": "15.1084511",
      "superficie_kmq": "5.6490",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087043",
      "denominazione_ita_altra": "San Michele di Ganzaria",
      "denominazione_ita": "San Michele di Ganzaria",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I035",
      "lat": "37.2793597",
      "lon": "14.4261085",
      "superficie_kmq": "25.8122",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087044",
      "denominazione_ita_altra": "San Pietro Clarenza",
      "denominazione_ita": "San Pietro Clarenza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I098",
      "lat": "37.5722306",
      "lon": "15.0241851",
      "superficie_kmq": "6.2721",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087045",
      "denominazione_ita_altra": "Sant'Agata li Battiati",
      "denominazione_ita": "Sant'Agata li Battiati",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I202",
      "lat": "37.5585423",
      "lon": "15.0802215",
      "superficie_kmq": "3.1180",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087046",
      "denominazione_ita_altra": "Sant'Alfio",
      "denominazione_ita": "Sant'Alfio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I216",
      "lat": "37.7439716",
      "lon": "15.1441058",
      "superficie_kmq": "25.8595",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087047",
      "denominazione_ita_altra": "Santa Maria di Licodia",
      "denominazione_ita": "Santa Maria di Licodia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I240",
      "lat": "37.6160746",
      "lon": "14.8873898",
      "superficie_kmq": "26.2767",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087048",
      "denominazione_ita_altra": "Santa Venerina",
      "denominazione_ita": "Santa Venerina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I314",
      "lat": "37.6868726",
      "lon": "15.1388679",
      "superficie_kmq": "19.0259",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087049",
      "denominazione_ita_altra": "Scordia",
      "denominazione_ita": "Scordia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I548",
      "lat": "37.2963863",
      "lon": "14.8460821",
      "superficie_kmq": "24.3092",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087050",
      "denominazione_ita_altra": "Trecastagni",
      "denominazione_ita": "Trecastagni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L355",
      "lat": "37.6168974",
      "lon": "15.0791935",
      "superficie_kmq": "19.1562",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087051",
      "denominazione_ita_altra": "Tremestieri Etneo",
      "denominazione_ita": "Tremestieri Etneo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L369",
      "lat": "37.5757319",
      "lon": "15.0721359",
      "superficie_kmq": "6.4902",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087052",
      "denominazione_ita_altra": "Valverde",
      "denominazione_ita": "Valverde",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L658",
      "lat": "37.5788996",
      "lon": "15.1248148",
      "superficie_kmq": "5.5218",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087053",
      "denominazione_ita_altra": "Viagrande",
      "denominazione_ita": "Viagrande",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L828",
      "lat": "37.6104821",
      "lon": "15.0963204",
      "superficie_kmq": "10.0944",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087054",
      "denominazione_ita_altra": "Vizzini",
      "denominazione_ita": "Vizzini",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M100",
      "lat": "37.1609224",
      "lon": "14.7485094",
      "superficie_kmq": "126.7508",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087055",
      "denominazione_ita_altra": "Zafferana Etnea",
      "denominazione_ita": "Zafferana Etnea",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M139",
      "lat": "37.6946063",
      "lon": "15.1033674",
      "superficie_kmq": "76.8653",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087056",
      "denominazione_ita_altra": "Mazzarrone",
      "denominazione_ita": "Mazzarrone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M271",
      "lat": "37.0838950",
      "lon": "14.5615875",
      "superficie_kmq": "34.7833",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087057",
      "denominazione_ita_altra": "Maniace",
      "denominazione_ita": "Maniace",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M283",
      "lat": "37.8619093",
      "lon": "14.8077456",
      "superficie_kmq": "37.7004",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "CT",
      "codice_istat": "087058",
      "denominazione_ita_altra": "Ragalna",
      "denominazione_ita": "Ragalna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M287",
      "lat": "37.6300252",
      "lon": "14.9391357",
      "superficie_kmq": "39.5280",
      "codice_sovracomunale": "287"
    },
    {
      "sigla_provincia": "RG",
      "codice_istat": "088001",
      "denominazione_ita_altra": "Acate",
      "denominazione_ita": "Acate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A014",
      "lat": "37.0262889",
      "lon": "14.4937210",
      "superficie_kmq": "102.4684",
      "codice_sovracomunale": "088"
    },
    {
      "sigla_provincia": "RG",
      "codice_istat": "088002",
      "denominazione_ita_altra": "Chiaramonte Gulfi",
      "denominazione_ita": "Chiaramonte Gulfi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C612",
      "lat": "37.0314647",
      "lon": "14.7012591",
      "superficie_kmq": "127.3740",
      "codice_sovracomunale": "088"
    },
    {
      "sigla_provincia": "RG",
      "codice_istat": "088003",
      "denominazione_ita_altra": "Comiso",
      "denominazione_ita": "Comiso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C927",
      "lat": "36.9455447",
      "lon": "14.6046778",
      "superficie_kmq": "65.4008",
      "codice_sovracomunale": "088"
    },
    {
      "sigla_provincia": "RG",
      "codice_istat": "088004",
      "denominazione_ita_altra": "Giarratana",
      "denominazione_ita": "Giarratana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E016",
      "lat": "37.0481644",
      "lon": "14.7930230",
      "superficie_kmq": "43.6296",
      "codice_sovracomunale": "088"
    },
    {
      "sigla_provincia": "RG",
      "codice_istat": "088005",
      "denominazione_ita_altra": "Ispica",
      "denominazione_ita": "Ispica",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E366",
      "lat": "36.7860646",
      "lon": "14.9035161",
      "superficie_kmq": "113.7530",
      "codice_sovracomunale": "088"
    },
    {
      "sigla_provincia": "RG",
      "codice_istat": "088006",
      "denominazione_ita_altra": "Modica",
      "denominazione_ita": "Modica",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F258",
      "lat": "36.8587337",
      "lon": "14.7613518",
      "superficie_kmq": "292.3698",
      "codice_sovracomunale": "088"
    },
    {
      "sigla_provincia": "RG",
      "codice_istat": "088007",
      "denominazione_ita_altra": "Monterosso Almo",
      "denominazione_ita": "Monterosso Almo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F610",
      "lat": "37.0887725",
      "lon": "14.7625599",
      "superficie_kmq": "56.5500",
      "codice_sovracomunale": "088"
    },
    {
      "sigla_provincia": "RG",
      "codice_istat": "088008",
      "denominazione_ita_altra": "Pozzallo",
      "denominazione_ita": "Pozzallo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G953",
      "lat": "36.7264784",
      "lon": "14.8461474",
      "superficie_kmq": "15.3764",
      "codice_sovracomunale": "088"
    },
    {
      "sigla_provincia": "RG",
      "codice_istat": "088009",
      "denominazione_ita_altra": "Ragusa",
      "denominazione_ita": "Ragusa",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "H163",
      "lat": "36.9249795",
      "lon": "14.7306908",
      "superficie_kmq": "444.7079",
      "codice_sovracomunale": "088"
    },
    {
      "sigla_provincia": "RG",
      "codice_istat": "088010",
      "denominazione_ita_altra": "Santa Croce Camerina",
      "denominazione_ita": "Santa Croce Camerina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I178",
      "lat": "36.8284215",
      "lon": "14.5254320",
      "superficie_kmq": "41.0850",
      "codice_sovracomunale": "088"
    },
    {
      "sigla_provincia": "RG",
      "codice_istat": "088011",
      "denominazione_ita_altra": "Scicli",
      "denominazione_ita": "Scicli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I535",
      "lat": "36.7934776",
      "lon": "14.7058951",
      "superficie_kmq": "138.7215",
      "codice_sovracomunale": "088"
    },
    {
      "sigla_provincia": "RG",
      "codice_istat": "088012",
      "denominazione_ita_altra": "Vittoria",
      "denominazione_ita": "Vittoria",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M088",
      "lat": "36.9486752",
      "lon": "14.5379983",
      "superficie_kmq": "182.4725",
      "codice_sovracomunale": "088"
    },
    {
      "sigla_provincia": "SR",
      "codice_istat": "089001",
      "denominazione_ita_altra": "Augusta",
      "denominazione_ita": "Augusta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A494",
      "lat": "37.2304973",
      "lon": "15.2194319",
      "superficie_kmq": "111.1579",
      "codice_sovracomunale": "089"
    },
    {
      "sigla_provincia": "SR",
      "codice_istat": "089002",
      "denominazione_ita_altra": "Avola",
      "denominazione_ita": "Avola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A522",
      "lat": "36.9082792",
      "lon": "15.1354349",
      "superficie_kmq": "74.5914",
      "codice_sovracomunale": "089"
    },
    {
      "sigla_provincia": "SR",
      "codice_istat": "089003",
      "denominazione_ita_altra": "Buccheri",
      "denominazione_ita": "Buccheri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B237",
      "lat": "37.1264012",
      "lon": "14.8504599",
      "superficie_kmq": "57.8310",
      "codice_sovracomunale": "089"
    },
    {
      "sigla_provincia": "SR",
      "codice_istat": "089004",
      "denominazione_ita_altra": "Buscemi",
      "denominazione_ita": "Buscemi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B287",
      "lat": "37.0851232",
      "lon": "14.8841471",
      "superficie_kmq": "52.0509",
      "codice_sovracomunale": "089"
    },
    {
      "sigla_provincia": "SR",
      "codice_istat": "089005",
      "denominazione_ita_altra": "Canicattini Bagni",
      "denominazione_ita": "Canicattini Bagni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B603",
      "lat": "37.0348786",
      "lon": "15.0617299",
      "superficie_kmq": "15.0628",
      "codice_sovracomunale": "089"
    },
    {
      "sigla_provincia": "SR",
      "codice_istat": "089006",
      "denominazione_ita_altra": "Carlentini",
      "denominazione_ita": "Carlentini",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B787",
      "lat": "37.2758692",
      "lon": "15.0147619",
      "superficie_kmq": "158.9089",
      "codice_sovracomunale": "089"
    },
    {
      "sigla_provincia": "SR",
      "codice_istat": "089007",
      "denominazione_ita_altra": "Cassaro",
      "denominazione_ita": "Cassaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C006",
      "lat": "37.1051174",
      "lon": "14.9476361",
      "superficie_kmq": "19.6155",
      "codice_sovracomunale": "089"
    },
    {
      "sigla_provincia": "SR",
      "codice_istat": "089008",
      "denominazione_ita_altra": "Ferla",
      "denominazione_ita": "Ferla",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D540",
      "lat": "37.1185029",
      "lon": "14.9414568",
      "superficie_kmq": "24.8976",
      "codice_sovracomunale": "089"
    },
    {
      "sigla_provincia": "SR",
      "codice_istat": "089009",
      "denominazione_ita_altra": "Floridia",
      "denominazione_ita": "Floridia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D636",
      "lat": "37.0820044",
      "lon": "15.1528828",
      "superficie_kmq": "26.4772",
      "codice_sovracomunale": "089"
    },
    {
      "sigla_provincia": "SR",
      "codice_istat": "089010",
      "denominazione_ita_altra": "Francofonte",
      "denominazione_ita": "Francofonte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D768",
      "lat": "37.2306729",
      "lon": "14.8801194",
      "superficie_kmq": "74.2007",
      "codice_sovracomunale": "089"
    },
    {
      "sigla_provincia": "SR",
      "codice_istat": "089011",
      "denominazione_ita_altra": "Lentini",
      "denominazione_ita": "Lentini",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E532",
      "lat": "37.2844058",
      "lon": "14.9993278",
      "superficie_kmq": "216.7816",
      "codice_sovracomunale": "089"
    },
    {
      "sigla_provincia": "SR",
      "codice_istat": "089012",
      "denominazione_ita_altra": "Melilli",
      "denominazione_ita": "Melilli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F107",
      "lat": "37.1783994",
      "lon": "15.1267460",
      "superficie_kmq": "136.4172",
      "codice_sovracomunale": "089"
    },
    {
      "sigla_provincia": "SR",
      "codice_istat": "089013",
      "denominazione_ita_altra": "Noto",
      "denominazione_ita": "Noto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F943",
      "lat": "36.8915837",
      "lon": "15.0666935",
      "superficie_kmq": "554.9841",
      "codice_sovracomunale": "089"
    },
    {
      "sigla_provincia": "SR",
      "codice_istat": "089014",
      "denominazione_ita_altra": "Pachino",
      "denominazione_ita": "Pachino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G211",
      "lat": "36.7159718",
      "lon": "15.0931428",
      "superficie_kmq": "50.9828",
      "codice_sovracomunale": "089"
    },
    {
      "sigla_provincia": "SR",
      "codice_istat": "089015",
      "denominazione_ita_altra": "Palazzolo Acreide",
      "denominazione_ita": "Palazzolo Acreide",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G267",
      "lat": "37.0617697",
      "lon": "14.9038176",
      "superficie_kmq": "87.5343",
      "codice_sovracomunale": "089"
    },
    {
      "sigla_provincia": "SR",
      "codice_istat": "089016",
      "denominazione_ita_altra": "Rosolini",
      "denominazione_ita": "Rosolini",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H574",
      "lat": "36.8202316",
      "lon": "14.9535222",
      "superficie_kmq": "76.4685",
      "codice_sovracomunale": "089"
    },
    {
      "sigla_provincia": "SR",
      "codice_istat": "089017",
      "denominazione_ita_altra": "Siracusa",
      "denominazione_ita": "Siracusa",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "I754",
      "lat": "37.0598065",
      "lon": "15.2933233",
      "superficie_kmq": "207.8248",
      "codice_sovracomunale": "089"
    },
    {
      "sigla_provincia": "SR",
      "codice_istat": "089018",
      "denominazione_ita_altra": "Solarino",
      "denominazione_ita": "Solarino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I785",
      "lat": "37.1011332",
      "lon": "15.1190907",
      "superficie_kmq": "13.0204",
      "codice_sovracomunale": "089"
    },
    {
      "sigla_provincia": "SR",
      "codice_istat": "089019",
      "denominazione_ita_altra": "Sortino",
      "denominazione_ita": "Sortino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I864",
      "lat": "37.1624595",
      "lon": "15.0328262",
      "superficie_kmq": "93.3295",
      "codice_sovracomunale": "089"
    },
    {
      "sigla_provincia": "SR",
      "codice_istat": "089020",
      "denominazione_ita_altra": "Portopalo di Capo Passero",
      "denominazione_ita": "Portopalo di Capo Passero",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M257",
      "lat": "36.6832103",
      "lon": "15.1339572",
      "superficie_kmq": "15.0908",
      "codice_sovracomunale": "089"
    },
    {
      "sigla_provincia": "SR",
      "codice_istat": "089021",
      "denominazione_ita_altra": "Priolo Gargallo",
      "denominazione_ita": "Priolo Gargallo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M279",
      "lat": "37.1593105",
      "lon": "15.1882846",
      "superficie_kmq": "56.9645",
      "codice_sovracomunale": "089"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090001",
      "denominazione_ita_altra": "Aggius",
      "denominazione_ita": "Aggius",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A069",
      "lat": "40.9675483",
      "lon": "9.0277380",
      "superficie_kmq": "86.3046",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090002",
      "denominazione_ita_altra": "Alà dei Sardi",
      "denominazione_ita": "Alà dei Sardi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A115",
      "lat": "40.6787806",
      "lon": "9.3430908",
      "superficie_kmq": "197.9892",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090003",
      "denominazione_ita_altra": "Alghero",
      "denominazione_ita": "Alghero",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A192",
      "lat": "40.5483691",
      "lon": "8.3260820",
      "superficie_kmq": "225.4113",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090004",
      "denominazione_ita_altra": "Anela",
      "denominazione_ita": "Anela",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A287",
      "lat": "40.4434770",
      "lon": "9.0566642",
      "superficie_kmq": "36.8902",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090005",
      "denominazione_ita_altra": "Ardara",
      "denominazione_ita": "Ardara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A379",
      "lat": "40.6238210",
      "lon": "8.8092638",
      "superficie_kmq": "38.1886",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090006",
      "denominazione_ita_altra": "Arzachena",
      "denominazione_ita": "Arzachena",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A453",
      "lat": "41.0906938",
      "lon": "9.4112254",
      "superficie_kmq": "230.8505",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090007",
      "denominazione_ita_altra": "Banari",
      "denominazione_ita": "Banari",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A606",
      "lat": "40.5719102",
      "lon": "8.6975309",
      "superficie_kmq": "21.2509",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090008",
      "denominazione_ita_altra": "Benetutti",
      "denominazione_ita": "Benetutti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A781",
      "lat": "40.4557269",
      "lon": "9.1682826",
      "superficie_kmq": "94.4460",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090009",
      "denominazione_ita_altra": "Berchidda",
      "denominazione_ita": "Berchidda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A789",
      "lat": "40.7905753",
      "lon": "9.2082544",
      "superficie_kmq": "201.8768",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090010",
      "denominazione_ita_altra": "Bessude",
      "denominazione_ita": "Bessude",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A827",
      "lat": "40.5556135",
      "lon": "8.7272210",
      "superficie_kmq": "26.7940",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090011",
      "denominazione_ita_altra": "Bonnanaro",
      "denominazione_ita": "Bonnanaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A976",
      "lat": "40.5323969",
      "lon": "8.7663835",
      "superficie_kmq": "21.8441",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090012",
      "denominazione_ita_altra": "Bono",
      "denominazione_ita": "Bono",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A977",
      "lat": "40.4168385",
      "lon": "9.0297523",
      "superficie_kmq": "74.5393",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090013",
      "denominazione_ita_altra": "Bonorva",
      "denominazione_ita": "Bonorva",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A978",
      "lat": "40.4169126",
      "lon": "8.7685315",
      "superficie_kmq": "149.7440",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090014",
      "denominazione_ita_altra": "Bortigiadas",
      "denominazione_ita": "Bortigiadas",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B063",
      "lat": "40.8807863",
      "lon": "8.9926227",
      "superficie_kmq": "75.8973",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090015",
      "denominazione_ita_altra": "Borutta",
      "denominazione_ita": "Borutta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B064",
      "lat": "40.5222095",
      "lon": "8.7437421",
      "superficie_kmq": "4.7583",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090016",
      "denominazione_ita_altra": "Bottidda",
      "denominazione_ita": "Bottidda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B094",
      "lat": "40.3906007",
      "lon": "9.0069729",
      "superficie_kmq": "33.7144",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090017",
      "denominazione_ita_altra": "Buddusò",
      "denominazione_ita": "Buddusò",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B246",
      "lat": "40.5981265",
      "lon": "9.2602230",
      "superficie_kmq": "176.8381",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090018",
      "denominazione_ita_altra": "Bultei",
      "denominazione_ita": "Bultei",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B264",
      "lat": "40.4570075",
      "lon": "9.0614866",
      "superficie_kmq": "96.8314",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090019",
      "denominazione_ita_altra": "Bulzi",
      "denominazione_ita": "Bulzi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B265",
      "lat": "40.8479798",
      "lon": "8.8297338",
      "superficie_kmq": "21.6697",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090020",
      "denominazione_ita_altra": "Burgos",
      "denominazione_ita": "Burgos",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B276",
      "lat": "40.3905476",
      "lon": "8.9936854",
      "superficie_kmq": "18.0770",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090021",
      "denominazione_ita_altra": "Calangianus",
      "denominazione_ita": "Calangianus",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B378",
      "lat": "40.9061470",
      "lon": "9.2419918",
      "superficie_kmq": "126.8371",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090022",
      "denominazione_ita_altra": "Cargeghe",
      "denominazione_ita": "Cargeghe",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B772",
      "lat": "40.6674862",
      "lon": "8.6160697",
      "superficie_kmq": "12.0464",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090023",
      "denominazione_ita_altra": "Castelsardo",
      "denominazione_ita": "Castelsardo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C272",
      "lat": "40.9157603",
      "lon": "8.7133303",
      "superficie_kmq": "43.3436",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090024",
      "denominazione_ita_altra": "Cheremule",
      "denominazione_ita": "Cheremule",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C600",
      "lat": "40.5060355",
      "lon": "8.7251408",
      "superficie_kmq": "24.2502",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090025",
      "denominazione_ita_altra": "Chiaramonti",
      "denominazione_ita": "Chiaramonti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C613",
      "lat": "40.7517558",
      "lon": "8.8200010",
      "superficie_kmq": "98.6116",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090026",
      "denominazione_ita_altra": "Codrongianos",
      "denominazione_ita": "Codrongianos",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C818",
      "lat": "40.6580349",
      "lon": "8.6800998",
      "superficie_kmq": "30.3907",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090027",
      "denominazione_ita_altra": "Cossoine",
      "denominazione_ita": "Cossoine",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D100",
      "lat": "40.4313624",
      "lon": "8.7125928",
      "superficie_kmq": "39.1704",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090028",
      "denominazione_ita_altra": "Esporlatu",
      "denominazione_ita": "Esporlatu",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D441",
      "lat": "40.3859128",
      "lon": "8.9906592",
      "superficie_kmq": "18.4001",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090029",
      "denominazione_ita_altra": "Florinas",
      "denominazione_ita": "Florinas",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D637",
      "lat": "40.6478654",
      "lon": "8.6657013",
      "superficie_kmq": "36.0608",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090030",
      "denominazione_ita_altra": "Giave",
      "denominazione_ita": "Giave",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E019",
      "lat": "40.4519488",
      "lon": "8.7505429",
      "superficie_kmq": "47.0662",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090031",
      "denominazione_ita_altra": "Illorai",
      "denominazione_ita": "Illorai",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E285",
      "lat": "40.3543714",
      "lon": "9.0021059",
      "superficie_kmq": "57.1904",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090032",
      "denominazione_ita_altra": "Ittireddu",
      "denominazione_ita": "Ittireddu",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E376",
      "lat": "40.5439239",
      "lon": "8.9020597",
      "superficie_kmq": "23.6867",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090033",
      "denominazione_ita_altra": "Ittiri",
      "denominazione_ita": "Ittiri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E377",
      "lat": "40.5940728",
      "lon": "8.5688078",
      "superficie_kmq": "111.4578",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090034",
      "denominazione_ita_altra": "Laerru",
      "denominazione_ita": "Laerru",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E401",
      "lat": "40.8181871",
      "lon": "8.8359758",
      "superficie_kmq": "19.8495",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090035",
      "denominazione_ita_altra": "La Maddalena",
      "denominazione_ita": "La Maddalena",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E425",
      "lat": "41.2273189",
      "lon": "9.4211255",
      "superficie_kmq": "51.6732",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090036",
      "denominazione_ita_altra": "Luogosanto",
      "denominazione_ita": "Luogosanto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E747",
      "lat": "41.0653033",
      "lon": "9.2123834",
      "superficie_kmq": "135.0697",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090037",
      "denominazione_ita_altra": "Luras",
      "denominazione_ita": "Luras",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E752",
      "lat": "40.9814290",
      "lon": "9.1909134",
      "superficie_kmq": "87.5852",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090038",
      "denominazione_ita_altra": "Mara",
      "denominazione_ita": "Mara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E902",
      "lat": "40.4106324",
      "lon": "8.6369062",
      "superficie_kmq": "18.6434",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090039",
      "denominazione_ita_altra": "Martis",
      "denominazione_ita": "Martis",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E992",
      "lat": "40.7788296",
      "lon": "8.8086627",
      "superficie_kmq": "22.9593",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090040",
      "denominazione_ita_altra": "Monteleone Rocca Doria",
      "denominazione_ita": "Monteleone Rocca Doria",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F542",
      "lat": "40.4718095",
      "lon": "8.5599380",
      "superficie_kmq": "13.3858",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090041",
      "denominazione_ita_altra": "Monti",
      "denominazione_ita": "Monti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F667",
      "lat": "40.7967720",
      "lon": "9.3568427",
      "superficie_kmq": "123.8220",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090042",
      "denominazione_ita_altra": "Mores",
      "denominazione_ita": "Mores",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F721",
      "lat": "40.5493755",
      "lon": "8.8336742",
      "superficie_kmq": "94.8547",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090043",
      "denominazione_ita_altra": "Muros",
      "denominazione_ita": "Muros",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F818",
      "lat": "40.6790413",
      "lon": "8.6152907",
      "superficie_kmq": "11.2256",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090044",
      "denominazione_ita_altra": "Nughedu San Nicolò",
      "denominazione_ita": "Nughedu San Nicolò",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F975",
      "lat": "40.5562027",
      "lon": "9.0220643",
      "superficie_kmq": "67.8938",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090045",
      "denominazione_ita_altra": "Nule",
      "denominazione_ita": "Nule",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F976",
      "lat": "40.4608384",
      "lon": "9.1872889",
      "superficie_kmq": "51.9467",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090046",
      "denominazione_ita_altra": "Nulvi",
      "denominazione_ita": "Nulvi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F977",
      "lat": "40.7805171",
      "lon": "8.7445741",
      "superficie_kmq": "67.3750",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090047",
      "denominazione_ita_altra": "Olbia",
      "denominazione_ita": "Olbia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G015",
      "lat": "40.9231699",
      "lon": "9.4798536",
      "superficie_kmq": "383.6361",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090048",
      "denominazione_ita_altra": "Olmedo",
      "denominazione_ita": "Olmedo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G046",
      "lat": "40.6499728",
      "lon": "8.3826213",
      "superficie_kmq": "33.4655",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090049",
      "denominazione_ita_altra": "Oschiri",
      "denominazione_ita": "Oschiri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G153",
      "lat": "40.7271082",
      "lon": "9.1017571",
      "superficie_kmq": "215.6111",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090050",
      "denominazione_ita_altra": "Osilo",
      "denominazione_ita": "Osilo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G156",
      "lat": "40.7439600",
      "lon": "8.6713260",
      "superficie_kmq": "98.0310",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090051",
      "denominazione_ita_altra": "Ossi",
      "denominazione_ita": "Ossi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G178",
      "lat": "40.6762779",
      "lon": "8.5906743",
      "superficie_kmq": "30.0898",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090052",
      "denominazione_ita_altra": "Ozieri",
      "denominazione_ita": "Ozieri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G203",
      "lat": "40.5849099",
      "lon": "8.9983813",
      "superficie_kmq": "252.1279",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090053",
      "denominazione_ita_altra": "Padria",
      "denominazione_ita": "Padria",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G225",
      "lat": "40.3979380",
      "lon": "8.6304405",
      "superficie_kmq": "48.3942",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090054",
      "denominazione_ita_altra": "Palau",
      "denominazione_ita": "Palau",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G258",
      "lat": "41.1680253",
      "lon": "9.3560292",
      "superficie_kmq": "44.4411",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090055",
      "denominazione_ita_altra": "Pattada",
      "denominazione_ita": "Pattada",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G376",
      "lat": "40.5827459",
      "lon": "9.1121026",
      "superficie_kmq": "164.8777",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090056",
      "denominazione_ita_altra": "Perfugas",
      "denominazione_ita": "Perfugas",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G450",
      "lat": "40.8301791",
      "lon": "8.8834732",
      "superficie_kmq": "60.8803",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090057",
      "denominazione_ita_altra": "Ploaghe",
      "denominazione_ita": "Ploaghe",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G740",
      "lat": "40.6656678",
      "lon": "8.7493043",
      "superficie_kmq": "96.2684",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090058",
      "denominazione_ita_altra": "Porto Torres",
      "denominazione_ita": "Porto Torres",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G924",
      "lat": "40.8371672",
      "lon": "8.4012288",
      "superficie_kmq": "104.5108",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090059",
      "denominazione_ita_altra": "Pozzomaggiore",
      "denominazione_ita": "Pozzomaggiore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G962",
      "lat": "40.3992524",
      "lon": "8.6565765",
      "superficie_kmq": "78.7714",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090060",
      "denominazione_ita_altra": "Putifigari",
      "denominazione_ita": "Putifigari",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H095",
      "lat": "40.5624480",
      "lon": "8.4606582",
      "superficie_kmq": "53.1002",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090061",
      "denominazione_ita_altra": "Romana",
      "denominazione_ita": "Romana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H507",
      "lat": "40.4842799",
      "lon": "8.5865565",
      "superficie_kmq": "21.5953",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090062",
      "denominazione_ita_altra": "Aglientu",
      "denominazione_ita": "Aglientu",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H848",
      "lat": "41.0958539",
      "lon": "9.1080231",
      "superficie_kmq": "148.1899",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090063",
      "denominazione_ita_altra": "Santa Teresa Gallura",
      "denominazione_ita": "Santa Teresa Gallura",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I312",
      "lat": "41.2026054",
      "lon": "9.2263769",
      "superficie_kmq": "102.2847",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090064",
      "denominazione_ita_altra": "Sassari",
      "denominazione_ita": "Sassari",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "I452",
      "lat": "40.7266126",
      "lon": "8.5596676",
      "superficie_kmq": "547.0333",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090065",
      "denominazione_ita_altra": "Sedini",
      "denominazione_ita": "Sedini",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I565",
      "lat": "40.8501151",
      "lon": "8.8171574",
      "superficie_kmq": "40.5057",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090066",
      "denominazione_ita_altra": "Semestene",
      "denominazione_ita": "Semestene",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I598",
      "lat": "40.3980979",
      "lon": "8.7261182",
      "superficie_kmq": "39.5828",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090067",
      "denominazione_ita_altra": "Sennori",
      "denominazione_ita": "Sennori",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I614",
      "lat": "40.7880806",
      "lon": "8.5952654",
      "superficie_kmq": "31.3396",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090068",
      "denominazione_ita_altra": "Siligo",
      "denominazione_ita": "Siligo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I732",
      "lat": "40.5771742",
      "lon": "8.7255670",
      "superficie_kmq": "43.4487",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090069",
      "denominazione_ita_altra": "Sorso",
      "denominazione_ita": "Sorso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I863",
      "lat": "40.7969137",
      "lon": "8.5778558",
      "superficie_kmq": "67.0106",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090070",
      "denominazione_ita_altra": "Tempio Pausania",
      "denominazione_ita": "Tempio Pausania",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L093",
      "lat": "40.9500831",
      "lon": "9.1363421",
      "superficie_kmq": "210.8176",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090071",
      "denominazione_ita_altra": "Thiesi",
      "denominazione_ita": "Thiesi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L158",
      "lat": "40.5219484",
      "lon": "8.7205804",
      "superficie_kmq": "63.2444",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090072",
      "denominazione_ita_altra": "Tissi",
      "denominazione_ita": "Tissi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L180",
      "lat": "40.6786820",
      "lon": "8.5623733",
      "superficie_kmq": "10.2376",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090073",
      "denominazione_ita_altra": "Torralba",
      "denominazione_ita": "Torralba",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L235",
      "lat": "40.5141844",
      "lon": "8.7619807",
      "superficie_kmq": "36.5029",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090074",
      "denominazione_ita_altra": "Trinità d'Agultu e Vignola",
      "denominazione_ita": "Trinità d'Agultu e Vignola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L428",
      "lat": "41.0262730",
      "lon": "8.9654626",
      "superficie_kmq": "134.0008",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090075",
      "denominazione_ita_altra": "Tula",
      "denominazione_ita": "Tula",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L464",
      "lat": "40.7336650",
      "lon": "8.9866843",
      "superficie_kmq": "66.1853",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090076",
      "denominazione_ita_altra": "Uri",
      "denominazione_ita": "Uri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L503",
      "lat": "40.6378522",
      "lon": "8.4865029",
      "superficie_kmq": "56.8063",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090077",
      "denominazione_ita_altra": "Usini",
      "denominazione_ita": "Usini",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L509",
      "lat": "40.6636504",
      "lon": "8.5405676",
      "superficie_kmq": "30.7367",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090078",
      "denominazione_ita_altra": "Villanova Monteleone",
      "denominazione_ita": "Villanova Monteleone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L989",
      "lat": "40.5035062",
      "lon": "8.4703820",
      "superficie_kmq": "202.6815",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090079",
      "denominazione_ita_altra": "Valledoria",
      "denominazione_ita": "Valledoria",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L604",
      "lat": "40.9276942",
      "lon": "8.8224550",
      "superficie_kmq": "25.9539",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090080",
      "denominazione_ita_altra": "Telti",
      "denominazione_ita": "Telti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L088",
      "lat": "40.8953597",
      "lon": "9.3575166",
      "superficie_kmq": "83.2468",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090081",
      "denominazione_ita_altra": "Badesi",
      "denominazione_ita": "Badesi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M214",
      "lat": "40.9562286",
      "lon": "8.8797347",
      "superficie_kmq": "31.3026",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090082",
      "denominazione_ita_altra": "Viddalba",
      "denominazione_ita": "Viddalba",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M259",
      "lat": "40.9169949",
      "lon": "8.8945756",
      "superficie_kmq": "50.4089",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090083",
      "denominazione_ita_altra": "Golfo Aranci",
      "denominazione_ita": "Golfo Aranci",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M274",
      "lat": "40.9880219",
      "lon": "9.5738137",
      "superficie_kmq": "37.4285",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090084",
      "denominazione_ita_altra": "Loiri Porto San Paolo",
      "denominazione_ita": "Loiri Porto San Paolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M275",
      "lat": "40.8354164",
      "lon": "9.5588265",
      "superficie_kmq": "118.5196",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090085",
      "denominazione_ita_altra": "Sant'Antonio di Gallura",
      "denominazione_ita": "Sant'Antonio di Gallura",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M276",
      "lat": "40.9773266",
      "lon": "9.3262772",
      "superficie_kmq": "81.6928",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090086",
      "denominazione_ita_altra": "Tergu",
      "denominazione_ita": "Tergu",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M282",
      "lat": "40.8628455",
      "lon": "8.7124085",
      "superficie_kmq": "36.8773",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090087",
      "denominazione_ita_altra": "Santa Maria Coghinas",
      "denominazione_ita": "Santa Maria Coghinas",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M284",
      "lat": "40.9042333",
      "lon": "8.8630923",
      "superficie_kmq": "22.9701",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090088",
      "denominazione_ita_altra": "Erula",
      "denominazione_ita": "Erula",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M292",
      "lat": "40.7912681",
      "lon": "8.9416869",
      "superficie_kmq": "39.3115",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090089",
      "denominazione_ita_altra": "Stintino",
      "denominazione_ita": "Stintino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M290",
      "lat": "40.9369331",
      "lon": "8.2262946",
      "superficie_kmq": "59.0430",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090090",
      "denominazione_ita_altra": "Padru",
      "denominazione_ita": "Padru",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M301",
      "lat": "40.7215650",
      "lon": "9.5100531",
      "superficie_kmq": "157.9960",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090091",
      "denominazione_ita_altra": "Budoni",
      "denominazione_ita": "Budoni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B248",
      "lat": "40.7040503",
      "lon": "9.6875992",
      "superficie_kmq": "54.2785",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "SS",
      "codice_istat": "090092",
      "denominazione_ita_altra": "San Teodoro",
      "denominazione_ita": "San Teodoro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I329",
      "lat": "40.7727325",
      "lon": "9.6381231",
      "superficie_kmq": "107.5989",
      "codice_sovracomunale": "090"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091001",
      "denominazione_ita_altra": "Aritzo",
      "denominazione_ita": "Aritzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A407",
      "lat": "39.9593107",
      "lon": "9.1979496",
      "superficie_kmq": "75.5753",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091002",
      "denominazione_ita_altra": "Arzana",
      "denominazione_ita": "Arzana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A454",
      "lat": "39.8757208",
      "lon": "9.4527421",
      "superficie_kmq": "162.4860",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091003",
      "denominazione_ita_altra": "Atzara",
      "denominazione_ita": "Atzara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A492",
      "lat": "39.9915990",
      "lon": "9.0762953",
      "superficie_kmq": "35.9218",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091004",
      "denominazione_ita_altra": "Austis",
      "denominazione_ita": "Austis",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A503",
      "lat": "40.0709733",
      "lon": "9.0885002",
      "superficie_kmq": "50.8081",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091005",
      "denominazione_ita_altra": "Bari Sardo",
      "denominazione_ita": "Bari Sardo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A663",
      "lat": "39.8371258",
      "lon": "9.6549892",
      "superficie_kmq": "37.4258",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091006",
      "denominazione_ita_altra": "Baunei",
      "denominazione_ita": "Baunei",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A722",
      "lat": "40.0991607",
      "lon": "9.6316439",
      "superficie_kmq": "211.8973",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091007",
      "denominazione_ita_altra": "Belvì",
      "denominazione_ita": "Belvì",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A776",
      "lat": "39.9617185",
      "lon": "9.1840835",
      "superficie_kmq": "18.1022",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091008",
      "denominazione_ita_altra": "Birori",
      "denominazione_ita": "Birori",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A880",
      "lat": "40.2659795",
      "lon": "8.8154948",
      "superficie_kmq": "17.3275",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091009",
      "denominazione_ita_altra": "Bitti",
      "denominazione_ita": "Bitti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A895",
      "lat": "40.4749947",
      "lon": "9.3814808",
      "superficie_kmq": "215.3679",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091010",
      "denominazione_ita_altra": "Bolotana",
      "denominazione_ita": "Bolotana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A948",
      "lat": "40.3277949",
      "lon": "8.9588939",
      "superficie_kmq": "108.4346",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091011",
      "denominazione_ita_altra": "Borore",
      "denominazione_ita": "Borore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B056",
      "lat": "40.2153405",
      "lon": "8.8033175",
      "superficie_kmq": "42.6755",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091012",
      "denominazione_ita_altra": "Bortigali",
      "denominazione_ita": "Bortigali",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B062",
      "lat": "40.2828837",
      "lon": "8.8367751",
      "superficie_kmq": "67.3270",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091016",
      "denominazione_ita_altra": "Desulo",
      "denominazione_ita": "Desulo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D287",
      "lat": "40.0111514",
      "lon": "9.2287056",
      "superficie_kmq": "74.4978",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091017",
      "denominazione_ita_altra": "Dorgali",
      "denominazione_ita": "Dorgali",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D345",
      "lat": "40.2892160",
      "lon": "9.5881204",
      "superficie_kmq": "226.5328",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091018",
      "denominazione_ita_altra": "Dualchi",
      "denominazione_ita": "Dualchi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D376",
      "lat": "40.2303046",
      "lon": "8.9002285",
      "superficie_kmq": "23.4099",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091019",
      "denominazione_ita_altra": "Elini",
      "denominazione_ita": "Elini",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D395",
      "lat": "39.9016785",
      "lon": "9.5950757",
      "superficie_kmq": "10.6497",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091024",
      "denominazione_ita_altra": "Fonni",
      "denominazione_ita": "Fonni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D665",
      "lat": "40.1199553",
      "lon": "9.2524113",
      "superficie_kmq": "112.2661",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091025",
      "denominazione_ita_altra": "Gadoni",
      "denominazione_ita": "Gadoni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D842",
      "lat": "39.9126339",
      "lon": "9.1830929",
      "superficie_kmq": "43.4345",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091026",
      "denominazione_ita_altra": "Gairo",
      "denominazione_ita": "Gairo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D859",
      "lat": "39.8556021",
      "lon": "9.4919520",
      "superficie_kmq": "77.4900",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091027",
      "denominazione_ita_altra": "Galtellì",
      "denominazione_ita": "Galtellì",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D888",
      "lat": "40.3865572",
      "lon": "9.6167099",
      "superficie_kmq": "56.5300",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091028",
      "denominazione_ita_altra": "Gavoi",
      "denominazione_ita": "Gavoi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D947",
      "lat": "40.1587278",
      "lon": "9.1949790",
      "superficie_kmq": "38.0587",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091031",
      "denominazione_ita_altra": "Girasole",
      "denominazione_ita": "Girasole",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E049",
      "lat": "39.9535718",
      "lon": "9.6442995",
      "superficie_kmq": "13.1613",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091032",
      "denominazione_ita_altra": "Ilbono",
      "denominazione_ita": "Ilbono",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E283",
      "lat": "39.8861697",
      "lon": "9.5924372",
      "superficie_kmq": "31.1320",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091033",
      "denominazione_ita_altra": "Irgoli",
      "denominazione_ita": "Irgoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E323",
      "lat": "40.4092480",
      "lon": "9.6305264",
      "superficie_kmq": "75.2988",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091035",
      "denominazione_ita_altra": "Jerzu",
      "denominazione_ita": "Jerzu",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E387",
      "lat": "39.7344169",
      "lon": "9.5428295",
      "superficie_kmq": "102.4051",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091037",
      "denominazione_ita_altra": "Lanusei",
      "denominazione_ita": "Lanusei",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E441",
      "lat": "39.7992043",
      "lon": "9.5754172",
      "superficie_kmq": "53.1687",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091038",
      "denominazione_ita_altra": "Lei",
      "denominazione_ita": "Lei",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E517",
      "lat": "40.3074552",
      "lon": "8.9202315",
      "superficie_kmq": "19.1146",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091039",
      "denominazione_ita_altra": "Loceri",
      "denominazione_ita": "Loceri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E644",
      "lat": "39.8071661",
      "lon": "9.6037505",
      "superficie_kmq": "19.3735",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091040",
      "denominazione_ita_altra": "Loculi",
      "denominazione_ita": "Loculi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E646",
      "lat": "40.4066771",
      "lon": "9.6110483",
      "superficie_kmq": "38.1494",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091041",
      "denominazione_ita_altra": "Lodè",
      "denominazione_ita": "Lodè",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E647",
      "lat": "40.5912555",
      "lon": "9.5395661",
      "superficie_kmq": "123.4437",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091042",
      "denominazione_ita_altra": "Lotzorai",
      "denominazione_ita": "Lotzorai",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E700",
      "lat": "39.9756157",
      "lon": "9.6532294",
      "superficie_kmq": "16.8669",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091043",
      "denominazione_ita_altra": "Lula",
      "denominazione_ita": "Lula",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E736",
      "lat": "40.4712230",
      "lon": "9.4850405",
      "superficie_kmq": "148.7146",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091044",
      "denominazione_ita_altra": "Macomer",
      "denominazione_ita": "Macomer",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E788",
      "lat": "40.2649786",
      "lon": "8.7825028",
      "superficie_kmq": "122.7633",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091046",
      "denominazione_ita_altra": "Mamoiada",
      "denominazione_ita": "Mamoiada",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E874",
      "lat": "40.2143152",
      "lon": "9.2839387",
      "superficie_kmq": "48.8294",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091047",
      "denominazione_ita_altra": "Meana Sardo",
      "denominazione_ita": "Meana Sardo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F073",
      "lat": "39.9475371",
      "lon": "9.0688689",
      "superficie_kmq": "73.8037",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091050",
      "denominazione_ita_altra": "Noragugume",
      "denominazione_ita": "Noragugume",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F933",
      "lat": "40.2246807",
      "lon": "8.9199680",
      "superficie_kmq": "26.7317",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091051",
      "denominazione_ita_altra": "Nuoro",
      "denominazione_ita": "Nuoro",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "F979",
      "lat": "40.3231187",
      "lon": "9.3302961",
      "superficie_kmq": "192.0590",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091055",
      "denominazione_ita_altra": "Oliena",
      "denominazione_ita": "Oliena",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G031",
      "lat": "40.2712521",
      "lon": "9.4059477",
      "superficie_kmq": "165.7374",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091056",
      "denominazione_ita_altra": "Ollolai",
      "denominazione_ita": "Ollolai",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G044",
      "lat": "40.1691184",
      "lon": "9.1812237",
      "superficie_kmq": "27.2372",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091057",
      "denominazione_ita_altra": "Olzai",
      "denominazione_ita": "Olzai",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G058",
      "lat": "40.1827770",
      "lon": "9.1488790",
      "superficie_kmq": "69.8209",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091058",
      "denominazione_ita_altra": "Onanì",
      "denominazione_ita": "Onanì",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G064",
      "lat": "40.4843218",
      "lon": "9.4408655",
      "superficie_kmq": "71.9700",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091059",
      "denominazione_ita_altra": "Onifai",
      "denominazione_ita": "Onifai",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G070",
      "lat": "40.4071283",
      "lon": "9.6513217",
      "superficie_kmq": "43.1843",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091060",
      "denominazione_ita_altra": "Oniferi",
      "denominazione_ita": "Oniferi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G071",
      "lat": "40.2721109",
      "lon": "9.1702558",
      "superficie_kmq": "35.6734",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091061",
      "denominazione_ita_altra": "Orani",
      "denominazione_ita": "Orani",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G084",
      "lat": "40.2499945",
      "lon": "9.1774560",
      "superficie_kmq": "130.4318",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091062",
      "denominazione_ita_altra": "Orgosolo",
      "denominazione_ita": "Orgosolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G097",
      "lat": "40.2052458",
      "lon": "9.3555126",
      "superficie_kmq": "222.5957",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091063",
      "denominazione_ita_altra": "Orosei",
      "denominazione_ita": "Orosei",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G119",
      "lat": "40.3785319",
      "lon": "9.6971312",
      "superficie_kmq": "90.9957",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091064",
      "denominazione_ita_altra": "Orotelli",
      "denominazione_ita": "Orotelli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G120",
      "lat": "40.3027466",
      "lon": "9.1122786",
      "superficie_kmq": "61.1765",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091066",
      "denominazione_ita_altra": "Ortueri",
      "denominazione_ita": "Ortueri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G146",
      "lat": "40.0360072",
      "lon": "8.9854843",
      "superficie_kmq": "38.8335",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091067",
      "denominazione_ita_altra": "Orune",
      "denominazione_ita": "Orune",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G147",
      "lat": "40.4060640",
      "lon": "9.3697387",
      "superficie_kmq": "128.4462",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091068",
      "denominazione_ita_altra": "Osidda",
      "denominazione_ita": "Osidda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G154",
      "lat": "40.5240868",
      "lon": "9.2187933",
      "superficie_kmq": "25.6759",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091069",
      "denominazione_ita_altra": "Osini",
      "denominazione_ita": "Osini",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G158",
      "lat": "39.7672043",
      "lon": "9.5290599",
      "superficie_kmq": "39.8140",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091070",
      "denominazione_ita_altra": "Ottana",
      "denominazione_ita": "Ottana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G191",
      "lat": "40.2349212",
      "lon": "9.0454442",
      "superficie_kmq": "45.0708",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091071",
      "denominazione_ita_altra": "Ovodda",
      "denominazione_ita": "Ovodda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G201",
      "lat": "40.0961161",
      "lon": "9.1609659",
      "superficie_kmq": "40.8474",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091072",
      "denominazione_ita_altra": "Perdasdefogu",
      "denominazione_ita": "Perdasdefogu",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G445",
      "lat": "39.6678966",
      "lon": "9.4538613",
      "superficie_kmq": "77.7449",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091073",
      "denominazione_ita_altra": "Posada",
      "denominazione_ita": "Posada",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G929",
      "lat": "40.6331956",
      "lon": "9.7179968",
      "superficie_kmq": "32.7669",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091077",
      "denominazione_ita_altra": "Sarule",
      "denominazione_ita": "Sarule",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I448",
      "lat": "40.2288926",
      "lon": "9.1659975",
      "superficie_kmq": "52.7148",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091083",
      "denominazione_ita_altra": "Silanus",
      "denominazione_ita": "Silanus",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I730",
      "lat": "40.2903797",
      "lon": "8.8894503",
      "superficie_kmq": "47.9345",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091084",
      "denominazione_ita_altra": "Sindia",
      "denominazione_ita": "Sindia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I748",
      "lat": "40.2960042",
      "lon": "8.6593911",
      "superficie_kmq": "58.5657",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091085",
      "denominazione_ita_altra": "Siniscola",
      "denominazione_ita": "Siniscola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I751",
      "lat": "40.5749424",
      "lon": "9.6942828",
      "superficie_kmq": "196.4124",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091086",
      "denominazione_ita_altra": "Sorgono",
      "denominazione_ita": "Sorgono",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I851",
      "lat": "40.0277250",
      "lon": "9.1037115",
      "superficie_kmq": "56.0512",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091088",
      "denominazione_ita_altra": "Talana",
      "denominazione_ita": "Talana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L036",
      "lat": "40.0310148",
      "lon": "9.4977819",
      "superficie_kmq": "118.6731",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091089",
      "denominazione_ita_altra": "Tertenia",
      "denominazione_ita": "Tertenia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L140",
      "lat": "39.6855239",
      "lon": "9.5957405",
      "superficie_kmq": "117.6501",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091090",
      "denominazione_ita_altra": "Teti",
      "denominazione_ita": "Teti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L153",
      "lat": "40.0963642",
      "lon": "9.1192593",
      "superficie_kmq": "43.8006",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091091",
      "denominazione_ita_altra": "Tiana",
      "denominazione_ita": "Tiana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L160",
      "lat": "40.0673219",
      "lon": "9.1488843",
      "superficie_kmq": "19.3156",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091093",
      "denominazione_ita_altra": "Tonara",
      "denominazione_ita": "Tonara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L202",
      "lat": "40.0246967",
      "lon": "9.1735732",
      "superficie_kmq": "52.0143",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091094",
      "denominazione_ita_altra": "Torpè",
      "denominazione_ita": "Torpè",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L231",
      "lat": "40.6286147",
      "lon": "9.6784535",
      "superficie_kmq": "91.5027",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091095",
      "denominazione_ita_altra": "Tortolì",
      "denominazione_ita": "Tortolì",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A355",
      "lat": "39.9145924",
      "lon": "9.6645684",
      "superficie_kmq": "40.2850",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091097",
      "denominazione_ita_altra": "Triei",
      "denominazione_ita": "Triei",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L423",
      "lat": "40.0192193",
      "lon": "9.6228582",
      "superficie_kmq": "32.9802",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091098",
      "denominazione_ita_altra": "Ulassai",
      "denominazione_ita": "Ulassai",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L489",
      "lat": "39.7301169",
      "lon": "9.4761277",
      "superficie_kmq": "122.4109",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091099",
      "denominazione_ita_altra": "Urzulei",
      "denominazione_ita": "Urzulei",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L506",
      "lat": "40.1269187",
      "lon": "9.5124326",
      "superficie_kmq": "129.6349",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091100",
      "denominazione_ita_altra": "Ussassai",
      "denominazione_ita": "Ussassai",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L514",
      "lat": "39.8174042",
      "lon": "9.4044085",
      "superficie_kmq": "47.3230",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091101",
      "denominazione_ita_altra": "Villagrande Strisaili",
      "denominazione_ita": "Villagrande Strisaili",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L953",
      "lat": "39.9930346",
      "lon": "9.4428353",
      "superficie_kmq": "210.3510",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091103",
      "denominazione_ita_altra": "Cardedu",
      "denominazione_ita": "Cardedu",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M285",
      "lat": "39.7710897",
      "lon": "9.6387866",
      "superficie_kmq": "33.3890",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "NU",
      "codice_istat": "091104",
      "denominazione_ita_altra": "Lodine",
      "denominazione_ita": "Lodine",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E649",
      "lat": "40.1476896",
      "lon": "9.2193566",
      "superficie_kmq": "7.7001",
      "codice_sovracomunale": "091"
    },
    {
      "sigla_provincia": "CA",
      "codice_istat": "092003",
      "denominazione_ita_altra": "Assemini",
      "denominazione_ita": "Assemini",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A474",
      "lat": "39.2895334",
      "lon": "9.0062327",
      "superficie_kmq": "118.1723",
      "codice_sovracomunale": "292"
    },
    {
      "sigla_provincia": "CA",
      "codice_istat": "092009",
      "denominazione_ita_altra": "Cagliari",
      "denominazione_ita": "Cagliari",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "B354",
      "lat": "39.2152270",
      "lon": "9.1106162",
      "superficie_kmq": "84.5839",
      "codice_sovracomunale": "292"
    },
    {
      "sigla_provincia": "CA",
      "codice_istat": "092011",
      "denominazione_ita_altra": "Capoterra",
      "denominazione_ita": "Capoterra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B675",
      "lat": "39.1738624",
      "lon": "8.9744974",
      "superficie_kmq": "68.4888",
      "codice_sovracomunale": "292"
    },
    {
      "sigla_provincia": "CA",
      "codice_istat": "092015",
      "denominazione_ita_altra": "Decimomannu",
      "denominazione_ita": "Decimomannu",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D259",
      "lat": "39.3109141",
      "lon": "8.9656479",
      "superficie_kmq": "27.7167",
      "codice_sovracomunale": "292"
    },
    {
      "sigla_provincia": "CA",
      "codice_istat": "092037",
      "denominazione_ita_altra": "Maracalagonis",
      "denominazione_ita": "Maracalagonis",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E903",
      "lat": "39.2851477",
      "lon": "9.2281948",
      "superficie_kmq": "101.3687",
      "codice_sovracomunale": "292"
    },
    {
      "sigla_provincia": "CA",
      "codice_istat": "092050",
      "denominazione_ita_altra": "Pula",
      "denominazione_ita": "Pula",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H088",
      "lat": "39.0106147",
      "lon": "9.0007699",
      "superficie_kmq": "138.9157",
      "codice_sovracomunale": "292"
    },
    {
      "sigla_provincia": "CA",
      "codice_istat": "092051",
      "denominazione_ita_altra": "Quartu Sant'Elena",
      "denominazione_ita": "Quartu Sant'Elena",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H118",
      "lat": "39.2409990",
      "lon": "9.1853437",
      "superficie_kmq": "96.4102",
      "codice_sovracomunale": "292"
    },
    {
      "sigla_provincia": "CA",
      "codice_istat": "092066",
      "denominazione_ita_altra": "Sarroch",
      "denominazione_ita": "Sarroch",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I443",
      "lat": "39.0634506",
      "lon": "9.0111626",
      "superficie_kmq": "67.8293",
      "codice_sovracomunale": "292"
    },
    {
      "sigla_provincia": "CA",
      "codice_istat": "092068",
      "denominazione_ita_altra": "Selargius",
      "denominazione_ita": "Selargius",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I580",
      "lat": "39.2536192",
      "lon": "9.1655553",
      "superficie_kmq": "26.6857",
      "codice_sovracomunale": "292"
    },
    {
      "sigla_provincia": "CA",
      "codice_istat": "092074",
      "denominazione_ita_altra": "Sestu",
      "denominazione_ita": "Sestu",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I695",
      "lat": "39.3002350",
      "lon": "9.0919459",
      "superficie_kmq": "48.2851",
      "codice_sovracomunale": "292"
    },
    {
      "sigla_provincia": "CA",
      "codice_istat": "092075",
      "denominazione_ita_altra": "Settimo San Pietro",
      "denominazione_ita": "Settimo San Pietro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I699",
      "lat": "39.2917007",
      "lon": "9.1840253",
      "superficie_kmq": "23.2940",
      "codice_sovracomunale": "292"
    },
    {
      "sigla_provincia": "CA",
      "codice_istat": "092080",
      "denominazione_ita_altra": "Sinnai",
      "denominazione_ita": "Sinnai",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I752",
      "lat": "39.2974079",
      "lon": "9.2055321",
      "superficie_kmq": "223.9080",
      "codice_sovracomunale": "292"
    },
    {
      "sigla_provincia": "CA",
      "codice_istat": "092090",
      "denominazione_ita_altra": "Uta",
      "denominazione_ita": "Uta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L521",
      "lat": "39.2929748",
      "lon": "8.9529785",
      "superficie_kmq": "134.7065",
      "codice_sovracomunale": "292"
    },
    {
      "sigla_provincia": "CA",
      "codice_istat": "092099",
      "denominazione_ita_altra": "Villa San Pietro",
      "denominazione_ita": "Villa San Pietro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I118",
      "lat": "39.0337420",
      "lon": "8.9950801",
      "superficie_kmq": "39.8870",
      "codice_sovracomunale": "292"
    },
    {
      "sigla_provincia": "CA",
      "codice_istat": "092105",
      "denominazione_ita_altra": "Quartucciu",
      "denominazione_ita": "Quartucciu",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H119",
      "lat": "39.2541914",
      "lon": "9.1800270",
      "superficie_kmq": "27.9315",
      "codice_sovracomunale": "292"
    },
    {
      "sigla_provincia": "CA",
      "codice_istat": "092108",
      "denominazione_ita_altra": "Elmas",
      "denominazione_ita": "Elmas",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D399",
      "lat": "39.2708024",
      "lon": "9.0488474",
      "superficie_kmq": "14.0545",
      "codice_sovracomunale": "292"
    },
    {
      "sigla_provincia": "CA",
      "codice_istat": "092109",
      "denominazione_ita_altra": "Monserrato",
      "denominazione_ita": "Monserrato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F383",
      "lat": "39.2539897",
      "lon": "9.1434384",
      "superficie_kmq": "6.4217",
      "codice_sovracomunale": "292"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093001",
      "denominazione_ita_altra": "Andreis",
      "denominazione_ita": "Andreis",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A283",
      "lat": "46.2018308",
      "lon": "12.6145633",
      "superficie_kmq": "26.9502",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093002",
      "denominazione_ita_altra": "Arba",
      "denominazione_ita": "Arba",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A354",
      "lat": "46.1462078",
      "lon": "12.7907620",
      "superficie_kmq": "15.3112",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093004",
      "denominazione_ita_altra": "Aviano",
      "denominazione_ita": "Aviano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A516",
      "lat": "46.0672177",
      "lon": "12.5881796",
      "superficie_kmq": "113.3483",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093005",
      "denominazione_ita_altra": "Azzano Decimo",
      "denominazione_ita": "Azzano Decimo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A530",
      "lat": "45.8812554",
      "lon": "12.7141322",
      "superficie_kmq": "51.3378",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093006",
      "denominazione_ita_altra": "Barcis",
      "denominazione_ita": "Barcis",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A640",
      "lat": "46.1908762",
      "lon": "12.5600166",
      "superficie_kmq": "103.4107",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093007",
      "denominazione_ita_altra": "Brugnera",
      "denominazione_ita": "Brugnera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B215",
      "lat": "45.8992011",
      "lon": "12.5367971",
      "superficie_kmq": "29.1193",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093008",
      "denominazione_ita_altra": "Budoia",
      "denominazione_ita": "Budoia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B247",
      "lat": "46.0429392",
      "lon": "12.5326502",
      "superficie_kmq": "37.3582",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093009",
      "denominazione_ita_altra": "Caneva",
      "denominazione_ita": "Caneva",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B598",
      "lat": "45.9685682",
      "lon": "12.4490307",
      "superficie_kmq": "41.7946",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093010",
      "denominazione_ita_altra": "Casarsa della Delizia",
      "denominazione_ita": "Casarsa della Delizia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B940",
      "lat": "45.9537596",
      "lon": "12.8401483",
      "superficie_kmq": "20.4660",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093011",
      "denominazione_ita_altra": "Castelnovo del Friuli",
      "denominazione_ita": "Castelnovo del Friuli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C217",
      "lat": "46.1999228",
      "lon": "12.9032517",
      "superficie_kmq": "22.4812",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093012",
      "denominazione_ita_altra": "Cavasso Nuovo",
      "denominazione_ita": "Cavasso Nuovo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C385",
      "lat": "46.1940500",
      "lon": "12.7688147",
      "superficie_kmq": "10.5962",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093013",
      "denominazione_ita_altra": "Chions",
      "denominazione_ita": "Chions",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C640",
      "lat": "45.8623082",
      "lon": "12.7523494",
      "superficie_kmq": "33.4461",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093014",
      "denominazione_ita_altra": "Cimolais",
      "denominazione_ita": "Cimolais",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C699",
      "lat": "46.2881024",
      "lon": "12.4377990",
      "superficie_kmq": "100.8511",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093015",
      "denominazione_ita_altra": "Claut",
      "denominazione_ita": "Claut",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C790",
      "lat": "46.2671566",
      "lon": "12.5139186",
      "superficie_kmq": "165.9013",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093016",
      "denominazione_ita_altra": "Clauzetto",
      "denominazione_ita": "Clauzetto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C791",
      "lat": "46.2290726",
      "lon": "12.9149366",
      "superficie_kmq": "28.3078",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093017",
      "denominazione_ita_altra": "Cordenons",
      "denominazione_ita": "Cordenons",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C991",
      "lat": "45.9885064",
      "lon": "12.7071519",
      "superficie_kmq": "56.3393",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093018",
      "denominazione_ita_altra": "Cordovado",
      "denominazione_ita": "Cordovado",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C993",
      "lat": "45.8501428",
      "lon": "12.8821226",
      "superficie_kmq": "12.0182",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093019",
      "denominazione_ita_altra": "Erto e Casso",
      "denominazione_ita": "Erto e Casso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D426",
      "lat": "46.2789113",
      "lon": "12.3723624",
      "superficie_kmq": "52.4192",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093020",
      "denominazione_ita_altra": "Fanna",
      "denominazione_ita": "Fanna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D487",
      "lat": "46.1876913",
      "lon": "12.7542726",
      "superficie_kmq": "10.2629",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093021",
      "denominazione_ita_altra": "Fiume Veneto",
      "denominazione_ita": "Fiume Veneto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D621",
      "lat": "45.9280344",
      "lon": "12.7368704",
      "superficie_kmq": "35.7555",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093022",
      "denominazione_ita_altra": "Fontanafredda",
      "denominazione_ita": "Fontanafredda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D670",
      "lat": "45.9889243",
      "lon": "12.5478000",
      "superficie_kmq": "46.4018",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093024",
      "denominazione_ita_altra": "Frisanco",
      "denominazione_ita": "Frisanco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D804",
      "lat": "46.2126100",
      "lon": "12.7238346",
      "superficie_kmq": "60.9870",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093025",
      "denominazione_ita_altra": "Maniago",
      "denominazione_ita": "Maniago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E889",
      "lat": "46.1710618",
      "lon": "12.7081682",
      "superficie_kmq": "69.4596",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093026",
      "denominazione_ita_altra": "Meduno",
      "denominazione_ita": "Meduno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F089",
      "lat": "46.2167503",
      "lon": "12.7865283",
      "superficie_kmq": "31.5906",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093027",
      "denominazione_ita_altra": "Montereale Valcellina",
      "denominazione_ita": "Montereale Valcellina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F596",
      "lat": "46.1551924",
      "lon": "12.6560609",
      "superficie_kmq": "67.8802",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093028",
      "denominazione_ita_altra": "Morsano al Tagliamento",
      "denominazione_ita": "Morsano al Tagliamento",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F750",
      "lat": "45.8578461",
      "lon": "12.9290325",
      "superficie_kmq": "32.5331",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093029",
      "denominazione_ita_altra": "Pasiano di Pordenone",
      "denominazione_ita": "Pasiano di Pordenone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G353",
      "lat": "45.8513395",
      "lon": "12.6274159",
      "superficie_kmq": "45.5945",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093030",
      "denominazione_ita_altra": "Pinzano al Tagliamento",
      "denominazione_ita": "Pinzano al Tagliamento",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G680",
      "lat": "46.1831555",
      "lon": "12.9460068",
      "superficie_kmq": "21.9508",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093031",
      "denominazione_ita_altra": "Polcenigo",
      "denominazione_ita": "Polcenigo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G780",
      "lat": "46.0305885",
      "lon": "12.5017415",
      "superficie_kmq": "49.6887",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093032",
      "denominazione_ita_altra": "Porcia",
      "denominazione_ita": "Porcia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G886",
      "lat": "45.9580690",
      "lon": "12.6137554",
      "superficie_kmq": "29.5333",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093033",
      "denominazione_ita_altra": "Pordenone",
      "denominazione_ita": "Pordenone",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "G888",
      "lat": "45.9544487",
      "lon": "12.6600300",
      "superficie_kmq": "38.2114",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093034",
      "denominazione_ita_altra": "Prata di Pordenone",
      "denominazione_ita": "Prata di Pordenone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G994",
      "lat": "45.8939508",
      "lon": "12.5972647",
      "superficie_kmq": "22.9602",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093035",
      "denominazione_ita_altra": "Pravisdomini",
      "denominazione_ita": "Pravisdomini",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H010",
      "lat": "45.8175684",
      "lon": "12.6921540",
      "superficie_kmq": "16.2091",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093036",
      "denominazione_ita_altra": "Roveredo in Piano",
      "denominazione_ita": "Roveredo in Piano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H609",
      "lat": "46.0095552",
      "lon": "12.6175518",
      "superficie_kmq": "15.8565",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093037",
      "denominazione_ita_altra": "Sacile",
      "denominazione_ita": "Sacile",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H657",
      "lat": "45.9542900",
      "lon": "12.5030375",
      "superficie_kmq": "32.7407",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093038",
      "denominazione_ita_altra": "San Giorgio della Richinvelda",
      "denominazione_ita": "San Giorgio della Richinvelda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H891",
      "lat": "46.0454240",
      "lon": "12.8677412",
      "superficie_kmq": "48.1525",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093039",
      "denominazione_ita_altra": "San Martino al Tagliamento",
      "denominazione_ita": "San Martino al Tagliamento",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H999",
      "lat": "46.0208364",
      "lon": "12.8638504",
      "superficie_kmq": "17.9790",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093040",
      "denominazione_ita_altra": "San Quirino",
      "denominazione_ita": "San Quirino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I136",
      "lat": "46.0363822",
      "lon": "12.6803080",
      "superficie_kmq": "51.7586",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093041",
      "denominazione_ita_altra": "San Vito al Tagliamento",
      "denominazione_ita": "San Vito al Tagliamento",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I403",
      "lat": "45.9149852",
      "lon": "12.8547376",
      "superficie_kmq": "60.8809",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093042",
      "denominazione_ita_altra": "Sequals",
      "denominazione_ita": "Sequals",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I621",
      "lat": "46.1662311",
      "lon": "12.8265820",
      "superficie_kmq": "27.7045",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093043",
      "denominazione_ita_altra": "Sesto al Reghena",
      "denominazione_ita": "Sesto al Reghena",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I686",
      "lat": "45.8485865",
      "lon": "12.8165161",
      "superficie_kmq": "40.6729",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093044",
      "denominazione_ita_altra": "Spilimbergo",
      "denominazione_ita": "Spilimbergo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I904",
      "lat": "46.1121343",
      "lon": "12.9056727",
      "superficie_kmq": "71.8756",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093045",
      "denominazione_ita_altra": "Tramonti di Sopra",
      "denominazione_ita": "Tramonti di Sopra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L324",
      "lat": "46.3099862",
      "lon": "12.7896085",
      "superficie_kmq": "125.1433",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093046",
      "denominazione_ita_altra": "Tramonti di Sotto",
      "denominazione_ita": "Tramonti di Sotto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L325",
      "lat": "46.2849722",
      "lon": "12.7958176",
      "superficie_kmq": "85.5471",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093047",
      "denominazione_ita_altra": "Travesio",
      "denominazione_ita": "Travesio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L347",
      "lat": "46.1964913",
      "lon": "12.8719739",
      "superficie_kmq": "28.3790",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093049",
      "denominazione_ita_altra": "Vito d'Asio",
      "denominazione_ita": "Vito d'Asio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M085",
      "lat": "46.2333532",
      "lon": "12.9585345",
      "superficie_kmq": "53.7144",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093050",
      "denominazione_ita_altra": "Vivaro",
      "denominazione_ita": "Vivaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M096",
      "lat": "46.0742085",
      "lon": "12.7764160",
      "superficie_kmq": "37.6768",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093051",
      "denominazione_ita_altra": "Zoppola",
      "denominazione_ita": "Zoppola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M190",
      "lat": "45.9664018",
      "lon": "12.7703984",
      "superficie_kmq": "45.5349",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093052",
      "denominazione_ita_altra": "Vajont",
      "denominazione_ita": "Vajont",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M265",
      "lat": "46.1466239",
      "lon": "12.6980325",
      "superficie_kmq": "1.5859",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "PN",
      "codice_istat": "093053",
      "denominazione_ita_altra": "Valvasone Arzene",
      "denominazione_ita": "Valvasone Arzene",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M346",
      "lat": "45.9893854",
      "lon": "12.8632070",
      "superficie_kmq": "29.6750",
      "codice_sovracomunale": "093"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094001",
      "denominazione_ita_altra": "Acquaviva d'Isernia",
      "denominazione_ita": "Acquaviva d'Isernia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A051",
      "lat": "41.6720607",
      "lon": "14.1498571",
      "superficie_kmq": "13.5139",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094002",
      "denominazione_ita_altra": "Agnone",
      "denominazione_ita": "Agnone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A080",
      "lat": "41.8060550",
      "lon": "14.3665683",
      "superficie_kmq": "96.8489",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094003",
      "denominazione_ita_altra": "Bagnoli del Trigno",
      "denominazione_ita": "Bagnoli del Trigno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A567",
      "lat": "41.7045692",
      "lon": "14.4582305",
      "superficie_kmq": "36.8009",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094004",
      "denominazione_ita_altra": "Belmonte del Sannio",
      "denominazione_ita": "Belmonte del Sannio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A761",
      "lat": "41.8250495",
      "lon": "14.4234816",
      "superficie_kmq": "20.3167",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094005",
      "denominazione_ita_altra": "Cantalupo nel Sannio",
      "denominazione_ita": "Cantalupo nel Sannio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B630",
      "lat": "41.5219196",
      "lon": "14.3924002",
      "superficie_kmq": "15.6380",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094006",
      "denominazione_ita_altra": "Capracotta",
      "denominazione_ita": "Capracotta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B682",
      "lat": "41.8338057",
      "lon": "14.2642419",
      "superficie_kmq": "42.5447",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094007",
      "denominazione_ita_altra": "Carovilli",
      "denominazione_ita": "Carovilli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B810",
      "lat": "41.7131660",
      "lon": "14.2942630",
      "superficie_kmq": "41.5624",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094008",
      "denominazione_ita_altra": "Carpinone",
      "denominazione_ita": "Carpinone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B830",
      "lat": "41.5914512",
      "lon": "14.3238014",
      "superficie_kmq": "32.4292",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094009",
      "denominazione_ita_altra": "Castel del Giudice",
      "denominazione_ita": "Castel del Giudice",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C082",
      "lat": "41.8549366",
      "lon": "14.2314741",
      "superficie_kmq": "14.8083",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094010",
      "denominazione_ita_altra": "Castelpetroso",
      "denominazione_ita": "Castelpetroso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C246",
      "lat": "41.5594757",
      "lon": "14.3452531",
      "superficie_kmq": "22.7064",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094011",
      "denominazione_ita_altra": "Castelpizzuto",
      "denominazione_ita": "Castelpizzuto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C247",
      "lat": "41.5213485",
      "lon": "14.2926601",
      "superficie_kmq": "15.3894",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094012",
      "denominazione_ita_altra": "Castel San Vincenzo",
      "denominazione_ita": "Castel San Vincenzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C270",
      "lat": "41.6549863",
      "lon": "14.0625206",
      "superficie_kmq": "21.9784",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094013",
      "denominazione_ita_altra": "Castelverrino",
      "denominazione_ita": "Castelverrino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C200",
      "lat": "41.7681864",
      "lon": "14.3975860",
      "superficie_kmq": "6.1987",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094014",
      "denominazione_ita_altra": "Cerro al Volturno",
      "denominazione_ita": "Cerro al Volturno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C534",
      "lat": "41.6540262",
      "lon": "14.1031507",
      "superficie_kmq": "23.7896",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094015",
      "denominazione_ita_altra": "Chiauci",
      "denominazione_ita": "Chiauci",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C620",
      "lat": "41.6780107",
      "lon": "14.3855281",
      "superficie_kmq": "15.8538",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094016",
      "denominazione_ita_altra": "Civitanova del Sannio",
      "denominazione_ita": "Civitanova del Sannio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C769",
      "lat": "41.6676572",
      "lon": "14.4042150",
      "superficie_kmq": "50.4723",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094017",
      "denominazione_ita_altra": "Colli a Volturno",
      "denominazione_ita": "Colli a Volturno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C878",
      "lat": "41.5989823",
      "lon": "14.1039139",
      "superficie_kmq": "25.2497",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094018",
      "denominazione_ita_altra": "Conca Casale",
      "denominazione_ita": "Conca Casale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C941",
      "lat": "41.4944078",
      "lon": "14.0066982",
      "superficie_kmq": "14.4296",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094019",
      "denominazione_ita_altra": "Filignano",
      "denominazione_ita": "Filignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D595",
      "lat": "41.5450742",
      "lon": "14.0563046",
      "superficie_kmq": "30.8761",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094020",
      "denominazione_ita_altra": "Forlì del Sannio",
      "denominazione_ita": "Forlì del Sannio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D703",
      "lat": "41.6952059",
      "lon": "14.1811905",
      "superficie_kmq": "32.5616",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094021",
      "denominazione_ita_altra": "Fornelli",
      "denominazione_ita": "Fornelli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D715",
      "lat": "41.6062971",
      "lon": "14.1409502",
      "superficie_kmq": "23.1728",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094022",
      "denominazione_ita_altra": "Frosolone",
      "denominazione_ita": "Frosolone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D811",
      "lat": "41.6014882",
      "lon": "14.4491302",
      "superficie_kmq": "49.8862",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094023",
      "denominazione_ita_altra": "Isernia",
      "denominazione_ita": "Isernia",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "E335",
      "lat": "41.5879585",
      "lon": "14.2257502",
      "superficie_kmq": "69.1501",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094024",
      "denominazione_ita_altra": "Longano",
      "denominazione_ita": "Longano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E669",
      "lat": "41.5216822",
      "lon": "14.2457044",
      "superficie_kmq": "27.3726",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094025",
      "denominazione_ita_altra": "Macchia d'Isernia",
      "denominazione_ita": "Macchia d'Isernia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E778",
      "lat": "41.5627567",
      "lon": "14.1674654",
      "superficie_kmq": "17.7115",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094026",
      "denominazione_ita_altra": "Macchiagodena",
      "denominazione_ita": "Macchiagodena",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E779",
      "lat": "41.5592099",
      "lon": "14.4058281",
      "superficie_kmq": "34.3511",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094027",
      "denominazione_ita_altra": "Miranda",
      "denominazione_ita": "Miranda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F239",
      "lat": "41.6438461",
      "lon": "14.2462244",
      "superficie_kmq": "22.1461",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094028",
      "denominazione_ita_altra": "Montaquila",
      "denominazione_ita": "Montaquila",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F429",
      "lat": "41.5663572",
      "lon": "14.1167021",
      "superficie_kmq": "25.4490",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094029",
      "denominazione_ita_altra": "Montenero Val Cocchiara",
      "denominazione_ita": "Montenero Val Cocchiara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F580",
      "lat": "41.7146845",
      "lon": "14.0661796",
      "superficie_kmq": "22.0189",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094030",
      "denominazione_ita_altra": "Monteroduni",
      "denominazione_ita": "Monteroduni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F601",
      "lat": "41.5227475",
      "lon": "14.1757071",
      "superficie_kmq": "37.2123",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094031",
      "denominazione_ita_altra": "Pesche",
      "denominazione_ita": "Pesche",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G486",
      "lat": "41.6111923",
      "lon": "14.2816323",
      "superficie_kmq": "12.9625",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094032",
      "denominazione_ita_altra": "Pescolanciano",
      "denominazione_ita": "Pescolanciano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G495",
      "lat": "41.6788100",
      "lon": "14.3368356",
      "superficie_kmq": "34.7337",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094033",
      "denominazione_ita_altra": "Pescopennataro",
      "denominazione_ita": "Pescopennataro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G497",
      "lat": "41.8782017",
      "lon": "14.2948435",
      "superficie_kmq": "18.8320",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094034",
      "denominazione_ita_altra": "Pettoranello del Molise",
      "denominazione_ita": "Pettoranello del Molise",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G523",
      "lat": "41.5735278",
      "lon": "14.2769719",
      "superficie_kmq": "15.5840",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094035",
      "denominazione_ita_altra": "Pietrabbondante",
      "denominazione_ita": "Pietrabbondante",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G606",
      "lat": "41.7486517",
      "lon": "14.3877602",
      "superficie_kmq": "27.4436",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094036",
      "denominazione_ita_altra": "Pizzone",
      "denominazione_ita": "Pizzone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G727",
      "lat": "41.6667271",
      "lon": "14.0357471",
      "superficie_kmq": "33.4825",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094037",
      "denominazione_ita_altra": "Poggio Sannita",
      "denominazione_ita": "Poggio Sannita",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B317",
      "lat": "41.7785632",
      "lon": "14.4131805",
      "superficie_kmq": "25.7364",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094038",
      "denominazione_ita_altra": "Pozzilli",
      "denominazione_ita": "Pozzilli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G954",
      "lat": "41.5114108",
      "lon": "14.0619180",
      "superficie_kmq": "34.6597",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094039",
      "denominazione_ita_altra": "Rionero Sannitico",
      "denominazione_ita": "Rionero Sannitico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H308",
      "lat": "41.7121982",
      "lon": "14.1399342",
      "superficie_kmq": "29.2126",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094040",
      "denominazione_ita_altra": "Roccamandolfi",
      "denominazione_ita": "Roccamandolfi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H420",
      "lat": "41.4928605",
      "lon": "14.3494986",
      "superficie_kmq": "53.6623",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094041",
      "denominazione_ita_altra": "Roccasicura",
      "denominazione_ita": "Roccasicura",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H445",
      "lat": "41.6981546",
      "lon": "14.2317411",
      "superficie_kmq": "28.6049",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094042",
      "denominazione_ita_altra": "Rocchetta a Volturno",
      "denominazione_ita": "Rocchetta a Volturno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H458",
      "lat": "41.6242459",
      "lon": "14.0858834",
      "superficie_kmq": "23.3409",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094043",
      "denominazione_ita_altra": "San Pietro Avellana",
      "denominazione_ita": "San Pietro Avellana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I096",
      "lat": "41.7887716",
      "lon": "14.1834790",
      "superficie_kmq": "44.9457",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094044",
      "denominazione_ita_altra": "Sant'Agapito",
      "denominazione_ita": "Sant'Agapito",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I189",
      "lat": "41.5436928",
      "lon": "14.2217677",
      "superficie_kmq": "15.9289",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094045",
      "denominazione_ita_altra": "Santa Maria del Molise",
      "denominazione_ita": "Santa Maria del Molise",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I238",
      "lat": "41.5526532",
      "lon": "14.3705433",
      "superficie_kmq": "17.1965",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094046",
      "denominazione_ita_altra": "Sant'Angelo del Pesco",
      "denominazione_ita": "Sant'Angelo del Pesco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I282",
      "lat": "41.8831190",
      "lon": "14.2557840",
      "superficie_kmq": "15.5895",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094047",
      "denominazione_ita_altra": "Sant'Elena Sannita",
      "denominazione_ita": "Sant'Elena Sannita",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B466",
      "lat": "41.5766841",
      "lon": "14.4719521",
      "superficie_kmq": "14.0760",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094048",
      "denominazione_ita_altra": "Scapoli",
      "denominazione_ita": "Scapoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I507",
      "lat": "41.6152511",
      "lon": "14.0588052",
      "superficie_kmq": "18.9353",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094049",
      "denominazione_ita_altra": "Sessano del Molise",
      "denominazione_ita": "Sessano del Molise",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I679",
      "lat": "41.6383035",
      "lon": "14.3319690",
      "superficie_kmq": "25.3179",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094050",
      "denominazione_ita_altra": "Sesto Campano",
      "denominazione_ita": "Sesto Campano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I682",
      "lat": "41.4210330",
      "lon": "14.0773525",
      "superficie_kmq": "35.3166",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094051",
      "denominazione_ita_altra": "Vastogirardi",
      "denominazione_ita": "Vastogirardi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L696",
      "lat": "41.7741894",
      "lon": "14.2594214",
      "superficie_kmq": "60.7037",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "IS",
      "codice_istat": "094052",
      "denominazione_ita_altra": "Venafro",
      "denominazione_ita": "Venafro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L725",
      "lat": "41.4861400",
      "lon": "14.0451054",
      "superficie_kmq": "46.4518",
      "codice_sovracomunale": "094"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095001",
      "denominazione_ita_altra": "Abbasanta",
      "denominazione_ita": "Abbasanta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A007",
      "lat": "40.1238307",
      "lon": "8.8210225",
      "superficie_kmq": "39.8469",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095002",
      "denominazione_ita_altra": "Aidomaggiore",
      "denominazione_ita": "Aidomaggiore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A097",
      "lat": "40.1717923",
      "lon": "8.8564342",
      "superficie_kmq": "41.2051",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095003",
      "denominazione_ita_altra": "Albagiara",
      "denominazione_ita": "Albagiara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A126",
      "lat": "39.7881878",
      "lon": "8.8606283",
      "superficie_kmq": "8.8673",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095004",
      "denominazione_ita_altra": "Ales",
      "denominazione_ita": "Ales",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A180",
      "lat": "39.7691501",
      "lon": "8.8158449",
      "superficie_kmq": "22.4520",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095005",
      "denominazione_ita_altra": "Allai",
      "denominazione_ita": "Allai",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A204",
      "lat": "39.9578602",
      "lon": "8.8595144",
      "superficie_kmq": "27.3604",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095006",
      "denominazione_ita_altra": "Arborea",
      "denominazione_ita": "Arborea",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A357",
      "lat": "39.7733742",
      "lon": "8.5821806",
      "superficie_kmq": "94.7921",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095007",
      "denominazione_ita_altra": "Ardauli",
      "denominazione_ita": "Ardauli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A380",
      "lat": "40.0872152",
      "lon": "8.9112308",
      "superficie_kmq": "20.5295",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095008",
      "denominazione_ita_altra": "Assolo",
      "denominazione_ita": "Assolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A477",
      "lat": "39.8106641",
      "lon": "8.9156271",
      "superficie_kmq": "16.3723",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095009",
      "denominazione_ita_altra": "Asuni",
      "denominazione_ita": "Asuni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A480",
      "lat": "39.8718398",
      "lon": "8.9477351",
      "superficie_kmq": "21.3433",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095010",
      "denominazione_ita_altra": "Baradili",
      "denominazione_ita": "Baradili",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A614",
      "lat": "39.7224324",
      "lon": "8.8976030",
      "superficie_kmq": "5.5735",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095011",
      "denominazione_ita_altra": "Baratili San Pietro",
      "denominazione_ita": "Baratili San Pietro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A621",
      "lat": "39.9932293",
      "lon": "8.5549669",
      "superficie_kmq": "6.0966",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095012",
      "denominazione_ita_altra": "Baressa",
      "denominazione_ita": "Baressa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A655",
      "lat": "39.7146033",
      "lon": "8.8740492",
      "superficie_kmq": "12.5116",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095013",
      "denominazione_ita_altra": "Bauladu",
      "denominazione_ita": "Bauladu",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A721",
      "lat": "40.0196104",
      "lon": "8.6713684",
      "superficie_kmq": "24.2197",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095014",
      "denominazione_ita_altra": "Bidonì",
      "denominazione_ita": "Bidonì",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A856",
      "lat": "40.1129317",
      "lon": "8.9351207",
      "superficie_kmq": "11.7239",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095015",
      "denominazione_ita_altra": "Bonarcado",
      "denominazione_ita": "Bonarcado",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A960",
      "lat": "40.1007474",
      "lon": "8.6545332",
      "superficie_kmq": "28.4117",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095016",
      "denominazione_ita_altra": "Boroneddu",
      "denominazione_ita": "Boroneddu",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B055",
      "lat": "40.1126646",
      "lon": "8.8698821",
      "superficie_kmq": "4.5886",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095017",
      "denominazione_ita_altra": "Busachi",
      "denominazione_ita": "Busachi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B281",
      "lat": "40.0332154",
      "lon": "8.8980765",
      "superficie_kmq": "59.0266",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095018",
      "denominazione_ita_altra": "Cabras",
      "denominazione_ita": "Cabras",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B314",
      "lat": "39.9298374",
      "lon": "8.5311929",
      "superficie_kmq": "102.2534",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095019",
      "denominazione_ita_altra": "Cuglieri",
      "denominazione_ita": "Cuglieri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D200",
      "lat": "40.1897111",
      "lon": "8.5682182",
      "superficie_kmq": "120.5970",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095020",
      "denominazione_ita_altra": "Fordongianus",
      "denominazione_ita": "Fordongianus",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D695",
      "lat": "39.9948417",
      "lon": "8.8079655",
      "superficie_kmq": "39.4817",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095021",
      "denominazione_ita_altra": "Ghilarza",
      "denominazione_ita": "Ghilarza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E004",
      "lat": "40.1213900",
      "lon": "8.8346934",
      "superficie_kmq": "55.4597",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095022",
      "denominazione_ita_altra": "Gonnoscodina",
      "denominazione_ita": "Gonnoscodina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E087",
      "lat": "39.7006149",
      "lon": "8.8358446",
      "superficie_kmq": "8.8205",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095023",
      "denominazione_ita_altra": "Gonnosnò",
      "denominazione_ita": "Gonnosnò",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D585",
      "lat": "39.7599562",
      "lon": "8.8719056",
      "superficie_kmq": "15.4582",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095024",
      "denominazione_ita_altra": "Gonnostramatza",
      "denominazione_ita": "Gonnostramatza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E088",
      "lat": "39.6800309",
      "lon": "8.8314312",
      "superficie_kmq": "17.6423",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095025",
      "denominazione_ita_altra": "Marrubiu",
      "denominazione_ita": "Marrubiu",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E972",
      "lat": "39.7488037",
      "lon": "8.6362624",
      "superficie_kmq": "61.2346",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095026",
      "denominazione_ita_altra": "Masullas",
      "denominazione_ita": "Masullas",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F050",
      "lat": "39.7013855",
      "lon": "8.7792539",
      "superficie_kmq": "18.6808",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095027",
      "denominazione_ita_altra": "Milis",
      "denominazione_ita": "Milis",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F208",
      "lat": "40.0492344",
      "lon": "8.6371823",
      "superficie_kmq": "18.6691",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095028",
      "denominazione_ita_altra": "Mogorella",
      "denominazione_ita": "Mogorella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F270",
      "lat": "39.8630477",
      "lon": "8.8582654",
      "superficie_kmq": "17.0619",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095029",
      "denominazione_ita_altra": "Mogoro",
      "denominazione_ita": "Mogoro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F272",
      "lat": "39.6840271",
      "lon": "8.7745758",
      "superficie_kmq": "48.9861",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095030",
      "denominazione_ita_altra": "Morgongiori",
      "denominazione_ita": "Morgongiori",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F727",
      "lat": "39.7460752",
      "lon": "8.7677257",
      "superficie_kmq": "45.1972",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095031",
      "denominazione_ita_altra": "Narbolia",
      "denominazione_ita": "Narbolia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F840",
      "lat": "40.0476365",
      "lon": "8.5750012",
      "superficie_kmq": "40.5004",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095032",
      "denominazione_ita_altra": "Neoneli",
      "denominazione_ita": "Neoneli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F867",
      "lat": "40.0614306",
      "lon": "8.9440127",
      "superficie_kmq": "48.0142",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095033",
      "denominazione_ita_altra": "Norbello",
      "denominazione_ita": "Norbello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F934",
      "lat": "40.1338831",
      "lon": "8.8322502",
      "superficie_kmq": "26.1807",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095034",
      "denominazione_ita_altra": "Nughedu Santa Vittoria",
      "denominazione_ita": "Nughedu Santa Vittoria",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F974",
      "lat": "40.1019972",
      "lon": "8.9542970",
      "superficie_kmq": "28.5715",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095035",
      "denominazione_ita_altra": "Nurachi",
      "denominazione_ita": "Nurachi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F980",
      "lat": "39.9737158",
      "lon": "8.5399149",
      "superficie_kmq": "15.9653",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095036",
      "denominazione_ita_altra": "Nureci",
      "denominazione_ita": "Nureci",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F985",
      "lat": "39.8240241",
      "lon": "8.9739722",
      "superficie_kmq": "12.8665",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095037",
      "denominazione_ita_altra": "Ollastra",
      "denominazione_ita": "Ollastra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G043",
      "lat": "39.9511509",
      "lon": "8.7346684",
      "superficie_kmq": "21.4698",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095038",
      "denominazione_ita_altra": "Oristano",
      "denominazione_ita": "Oristano",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "G113",
      "lat": "39.9037353",
      "lon": "8.5911836",
      "superficie_kmq": "84.5723",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095039",
      "denominazione_ita_altra": "Palmas Arborea",
      "denominazione_ita": "Palmas Arborea",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G286",
      "lat": "39.8759204",
      "lon": "8.6456826",
      "superficie_kmq": "39.3276",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095040",
      "denominazione_ita_altra": "Pau",
      "denominazione_ita": "Pau",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G379",
      "lat": "39.7926412",
      "lon": "8.8030983",
      "superficie_kmq": "13.8160",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095041",
      "denominazione_ita_altra": "Paulilatino",
      "denominazione_ita": "Paulilatino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G384",
      "lat": "40.0864920",
      "lon": "8.7604944",
      "superficie_kmq": "103.8471",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095042",
      "denominazione_ita_altra": "Pompu",
      "denominazione_ita": "Pompu",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G817",
      "lat": "39.7248022",
      "lon": "8.7957192",
      "superficie_kmq": "5.3157",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095043",
      "denominazione_ita_altra": "Riola Sardo",
      "denominazione_ita": "Riola Sardo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H301",
      "lat": "39.9964712",
      "lon": "8.5384735",
      "superficie_kmq": "48.1091",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095044",
      "denominazione_ita_altra": "Ruinas",
      "denominazione_ita": "Ruinas",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F271",
      "lat": "39.9056951",
      "lon": "8.8964176",
      "superficie_kmq": "30.4581",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095045",
      "denominazione_ita_altra": "Samugheo",
      "denominazione_ita": "Samugheo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H756",
      "lat": "39.9471162",
      "lon": "8.9396056",
      "superficie_kmq": "81.2826",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095046",
      "denominazione_ita_altra": "San Nicolò d'Arcidano",
      "denominazione_ita": "San Nicolò d'Arcidano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A368",
      "lat": "39.6852094",
      "lon": "8.6466924",
      "superficie_kmq": "28.3569",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095047",
      "denominazione_ita_altra": "Santa Giusta",
      "denominazione_ita": "Santa Giusta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I205",
      "lat": "39.8783745",
      "lon": "8.6086307",
      "superficie_kmq": "69.2151",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095048",
      "denominazione_ita_altra": "Villa Sant'Antonio",
      "denominazione_ita": "Villa Sant'Antonio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I298",
      "lat": "39.8580790",
      "lon": "8.9012848",
      "superficie_kmq": "19.0500",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095049",
      "denominazione_ita_altra": "Santu Lussurgiu",
      "denominazione_ita": "Santu Lussurgiu",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I374",
      "lat": "40.1372408",
      "lon": "8.6519668",
      "superficie_kmq": "99.7954",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095050",
      "denominazione_ita_altra": "San Vero Milis",
      "denominazione_ita": "San Vero Milis",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I384",
      "lat": "40.0127188",
      "lon": "8.5991507",
      "superficie_kmq": "72.4749",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095051",
      "denominazione_ita_altra": "Scano di Montiferro",
      "denominazione_ita": "Scano di Montiferro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I503",
      "lat": "40.2167522",
      "lon": "8.5835189",
      "superficie_kmq": "60.4676",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095052",
      "denominazione_ita_altra": "Sedilo",
      "denominazione_ita": "Sedilo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I564",
      "lat": "40.1747822",
      "lon": "8.9209170",
      "superficie_kmq": "68.4524",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095053",
      "denominazione_ita_altra": "Seneghe",
      "denominazione_ita": "Seneghe",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I605",
      "lat": "40.0805699",
      "lon": "8.6114684",
      "superficie_kmq": "57.8527",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095054",
      "denominazione_ita_altra": "Senis",
      "denominazione_ita": "Senis",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I609",
      "lat": "39.8215604",
      "lon": "8.9395487",
      "superficie_kmq": "16.0628",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095055",
      "denominazione_ita_altra": "Sennariolo",
      "denominazione_ita": "Sennariolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I613",
      "lat": "40.2123652",
      "lon": "8.5562875",
      "superficie_kmq": "15.6128",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095056",
      "denominazione_ita_altra": "Siamaggiore",
      "denominazione_ita": "Siamaggiore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I717",
      "lat": "39.9486667",
      "lon": "8.6361548",
      "superficie_kmq": "13.1726",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095057",
      "denominazione_ita_altra": "Siamanna",
      "denominazione_ita": "Siamanna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I718",
      "lat": "39.9201193",
      "lon": "8.7596186",
      "superficie_kmq": "28.3563",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095058",
      "denominazione_ita_altra": "Simala",
      "denominazione_ita": "Simala",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I742",
      "lat": "39.7192102",
      "lon": "8.8296252",
      "superficie_kmq": "13.3786",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095059",
      "denominazione_ita_altra": "Simaxis",
      "denominazione_ita": "Simaxis",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I743",
      "lat": "39.9302619",
      "lon": "8.6909050",
      "superficie_kmq": "27.8199",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095060",
      "denominazione_ita_altra": "Sini",
      "denominazione_ita": "Sini",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I749",
      "lat": "39.7523943",
      "lon": "8.9063632",
      "superficie_kmq": "8.7514",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095061",
      "denominazione_ita_altra": "Siris",
      "denominazione_ita": "Siris",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I757",
      "lat": "39.7127341",
      "lon": "8.7748757",
      "superficie_kmq": "6.0044",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095062",
      "denominazione_ita_altra": "Solarussa",
      "denominazione_ita": "Solarussa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I791",
      "lat": "39.9530829",
      "lon": "8.6707587",
      "superficie_kmq": "31.8589",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095063",
      "denominazione_ita_altra": "Sorradile",
      "denominazione_ita": "Sorradile",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I861",
      "lat": "40.1065753",
      "lon": "8.9327695",
      "superficie_kmq": "26.3402",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095064",
      "denominazione_ita_altra": "Tadasuni",
      "denominazione_ita": "Tadasuni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L023",
      "lat": "40.1106260",
      "lon": "8.8826058",
      "superficie_kmq": "5.0943",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095065",
      "denominazione_ita_altra": "Terralba",
      "denominazione_ita": "Terralba",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L122",
      "lat": "39.7201224",
      "lon": "8.6361843",
      "superficie_kmq": "49.9709",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095066",
      "denominazione_ita_altra": "Tramatza",
      "denominazione_ita": "Tramatza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L321",
      "lat": "40.0028062",
      "lon": "8.6486247",
      "superficie_kmq": "16.7998",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095067",
      "denominazione_ita_altra": "Tresnuraghes",
      "denominazione_ita": "Tresnuraghes",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L393",
      "lat": "40.2508544",
      "lon": "8.5188205",
      "superficie_kmq": "31.4950",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095068",
      "denominazione_ita_altra": "Ulà Tirso",
      "denominazione_ita": "Ulà Tirso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L488",
      "lat": "40.0449478",
      "lon": "8.9054288",
      "superficie_kmq": "18.8464",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095069",
      "denominazione_ita_altra": "Uras",
      "denominazione_ita": "Uras",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L496",
      "lat": "39.6982739",
      "lon": "8.7010763",
      "superficie_kmq": "39.2388",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095070",
      "denominazione_ita_altra": "Usellus",
      "denominazione_ita": "Usellus",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L508",
      "lat": "39.8082032",
      "lon": "8.8526330",
      "superficie_kmq": "35.0735",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095071",
      "denominazione_ita_altra": "Villanova Truschedu",
      "denominazione_ita": "Villanova Truschedu",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L991",
      "lat": "39.9895194",
      "lon": "8.7525694",
      "superficie_kmq": "16.6098",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095072",
      "denominazione_ita_altra": "Villaurbana",
      "denominazione_ita": "Villaurbana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M030",
      "lat": "39.8861046",
      "lon": "8.7781036",
      "superficie_kmq": "58.7001",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095073",
      "denominazione_ita_altra": "Villa Verde",
      "denominazione_ita": "Villa Verde",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A609",
      "lat": "39.7959589",
      "lon": "8.8210208",
      "superficie_kmq": "17.6511",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095074",
      "denominazione_ita_altra": "Zeddiani",
      "denominazione_ita": "Zeddiani",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M153",
      "lat": "39.9895011",
      "lon": "8.5954876",
      "superficie_kmq": "11.8094",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095075",
      "denominazione_ita_altra": "Zerfaliu",
      "denominazione_ita": "Zerfaliu",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M168",
      "lat": "39.9579420",
      "lon": "8.7075494",
      "superficie_kmq": "15.5607",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095076",
      "denominazione_ita_altra": "Siapiccia",
      "denominazione_ita": "Siapiccia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I721",
      "lat": "39.9263528",
      "lon": "8.7617627",
      "superficie_kmq": "17.9310",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095077",
      "denominazione_ita_altra": "Curcuris",
      "denominazione_ita": "Curcuris",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D214",
      "lat": "39.7462842",
      "lon": "8.8314134",
      "superficie_kmq": "7.1807",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095078",
      "denominazione_ita_altra": "Soddì",
      "denominazione_ita": "Soddì",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I778",
      "lat": "40.1289488",
      "lon": "8.8771933",
      "superficie_kmq": "5.2425",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095079",
      "denominazione_ita_altra": "Bosa",
      "denominazione_ita": "Bosa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B068",
      "lat": "40.2990788",
      "lon": "8.4978307",
      "superficie_kmq": "128.0155",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095080",
      "denominazione_ita_altra": "Flussio",
      "denominazione_ita": "Flussio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D640",
      "lat": "40.2670227",
      "lon": "8.5415204",
      "superficie_kmq": "6.8744",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095082",
      "denominazione_ita_altra": "Laconi",
      "denominazione_ita": "Laconi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E400",
      "lat": "39.8538621",
      "lon": "9.0522368",
      "superficie_kmq": "124.7513",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095083",
      "denominazione_ita_altra": "Magomadas",
      "denominazione_ita": "Magomadas",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E825",
      "lat": "40.2641329",
      "lon": "8.5253996",
      "superficie_kmq": "9.1090",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095084",
      "denominazione_ita_altra": "Modolo",
      "denominazione_ita": "Modolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F261",
      "lat": "40.2740117",
      "lon": "8.5278218",
      "superficie_kmq": "2.4744",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095085",
      "denominazione_ita_altra": "Montresta",
      "denominazione_ita": "Montresta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F698",
      "lat": "40.3736443",
      "lon": "8.4998749",
      "superficie_kmq": "31.1594",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095086",
      "denominazione_ita_altra": "Sagama",
      "denominazione_ita": "Sagama",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H661",
      "lat": "40.2609196",
      "lon": "8.5780940",
      "superficie_kmq": "11.7228",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095087",
      "denominazione_ita_altra": "Suni",
      "denominazione_ita": "Suni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L006",
      "lat": "40.2796716",
      "lon": "8.5502802",
      "superficie_kmq": "47.4624",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "OR",
      "codice_istat": "095088",
      "denominazione_ita_altra": "Tinnura",
      "denominazione_ita": "Tinnura",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L172",
      "lat": "40.2691792",
      "lon": "8.5482074",
      "superficie_kmq": "3.8487",
      "codice_sovracomunale": "095"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096001",
      "denominazione_ita_altra": "Ailoche",
      "denominazione_ita": "Ailoche",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A107",
      "lat": "45.6980656",
      "lon": "8.2203797",
      "superficie_kmq": "10.7826",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096002",
      "denominazione_ita_altra": "Andorno Micca",
      "denominazione_ita": "Andorno Micca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A280",
      "lat": "45.6155697",
      "lon": "8.0533177",
      "superficie_kmq": "11.8868",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096003",
      "denominazione_ita_altra": "Benna",
      "denominazione_ita": "Benna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A784",
      "lat": "45.5124011",
      "lon": "8.1251504",
      "superficie_kmq": "9.3890",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096004",
      "denominazione_ita_altra": "Biella",
      "denominazione_ita": "Biella",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "A859",
      "lat": "45.5665179",
      "lon": "8.0540821",
      "superficie_kmq": "46.6883",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096005",
      "denominazione_ita_altra": "Bioglio",
      "denominazione_ita": "Bioglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A876",
      "lat": "45.6075981",
      "lon": "8.1302601",
      "superficie_kmq": "18.8452",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096006",
      "denominazione_ita_altra": "Borriana",
      "denominazione_ita": "Borriana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B058",
      "lat": "45.5059776",
      "lon": "8.0395803",
      "superficie_kmq": "5.3451",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096007",
      "denominazione_ita_altra": "Brusnengo",
      "denominazione_ita": "Brusnengo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B229",
      "lat": "45.5961361",
      "lon": "8.2525374",
      "superficie_kmq": "10.4533",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096008",
      "denominazione_ita_altra": "Callabiana",
      "denominazione_ita": "Callabiana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B417",
      "lat": "45.6337268",
      "lon": "8.0907382",
      "superficie_kmq": "6.5573",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096009",
      "denominazione_ita_altra": "Camandona",
      "denominazione_ita": "Camandona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B457",
      "lat": "45.6417158",
      "lon": "8.1000101",
      "superficie_kmq": "9.2001",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096010",
      "denominazione_ita_altra": "Camburzano",
      "denominazione_ita": "Camburzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B465",
      "lat": "45.5442149",
      "lon": "8.0033138",
      "superficie_kmq": "3.8025",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096012",
      "denominazione_ita_altra": "Candelo",
      "denominazione_ita": "Candelo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B586",
      "lat": "45.5463048",
      "lon": "8.1127014",
      "superficie_kmq": "15.1210",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096013",
      "denominazione_ita_altra": "Caprile",
      "denominazione_ita": "Caprile",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B708",
      "lat": "45.6929625",
      "lon": "8.2129724",
      "superficie_kmq": "11.5955",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096014",
      "denominazione_ita_altra": "Casapinta",
      "denominazione_ita": "Casapinta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B933",
      "lat": "45.6150416",
      "lon": "8.1934006",
      "superficie_kmq": "2.8608",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096015",
      "denominazione_ita_altra": "Castelletto Cervo",
      "denominazione_ita": "Castelletto Cervo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C155",
      "lat": "45.5190085",
      "lon": "8.2252012",
      "superficie_kmq": "14.9021",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096016",
      "denominazione_ita_altra": "Cavaglià",
      "denominazione_ita": "Cavaglià",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C363",
      "lat": "45.4057659",
      "lon": "8.0934912",
      "superficie_kmq": "25.6248",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096018",
      "denominazione_ita_altra": "Cerrione",
      "denominazione_ita": "Cerrione",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C532",
      "lat": "45.4809131",
      "lon": "8.0739946",
      "superficie_kmq": "27.9876",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096019",
      "denominazione_ita_altra": "Coggiola",
      "denominazione_ita": "Coggiola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C819",
      "lat": "45.6845897",
      "lon": "8.1851642",
      "superficie_kmq": "23.7819",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096020",
      "denominazione_ita_altra": "Cossato",
      "denominazione_ita": "Cossato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D094",
      "lat": "45.5701031",
      "lon": "8.1805983",
      "superficie_kmq": "27.7338",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096021",
      "denominazione_ita_altra": "Crevacuore",
      "denominazione_ita": "Crevacuore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D165",
      "lat": "45.6858439",
      "lon": "8.2471267",
      "superficie_kmq": "8.6006",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096023",
      "denominazione_ita_altra": "Curino",
      "denominazione_ita": "Curino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D219",
      "lat": "45.6276001",
      "lon": "8.2358883",
      "superficie_kmq": "21.6492",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096024",
      "denominazione_ita_altra": "Donato",
      "denominazione_ita": "Donato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D339",
      "lat": "45.5282979",
      "lon": "7.9075819",
      "superficie_kmq": "12.0694",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096025",
      "denominazione_ita_altra": "Dorzano",
      "denominazione_ita": "Dorzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D350",
      "lat": "45.4252225",
      "lon": "8.0969696",
      "superficie_kmq": "4.7415",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096026",
      "denominazione_ita_altra": "Gaglianico",
      "denominazione_ita": "Gaglianico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D848",
      "lat": "45.5394108",
      "lon": "8.0747237",
      "superficie_kmq": "4.4984",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096027",
      "denominazione_ita_altra": "Gifflenga",
      "denominazione_ita": "Gifflenga",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E024",
      "lat": "45.4932836",
      "lon": "8.2316413",
      "superficie_kmq": "2.2559",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096028",
      "denominazione_ita_altra": "Graglia",
      "denominazione_ita": "Graglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E130",
      "lat": "45.5578972",
      "lon": "7.9811173",
      "superficie_kmq": "20.1389",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096030",
      "denominazione_ita_altra": "Magnano",
      "denominazione_ita": "Magnano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E821",
      "lat": "45.4627089",
      "lon": "8.0051271",
      "superficie_kmq": "10.5560",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096031",
      "denominazione_ita_altra": "Massazza",
      "denominazione_ita": "Massazza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F037",
      "lat": "45.4920784",
      "lon": "8.1651629",
      "superficie_kmq": "11.6095",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096032",
      "denominazione_ita_altra": "Masserano",
      "denominazione_ita": "Masserano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F042",
      "lat": "45.5957023",
      "lon": "8.2232689",
      "superficie_kmq": "27.0732",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096033",
      "denominazione_ita_altra": "Mezzana Mortigliengo",
      "denominazione_ita": "Mezzana Mortigliengo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F167",
      "lat": "45.6259713",
      "lon": "8.1894465",
      "superficie_kmq": "4.3092",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096034",
      "denominazione_ita_altra": "Miagliano",
      "denominazione_ita": "Miagliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F189",
      "lat": "45.6141426",
      "lon": "8.0437742",
      "superficie_kmq": "0.6678",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096035",
      "denominazione_ita_altra": "Mongrando",
      "denominazione_ita": "Mongrando",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F369",
      "lat": "45.5192354",
      "lon": "8.0072470",
      "superficie_kmq": "16.5025",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096037",
      "denominazione_ita_altra": "Mottalciata",
      "denominazione_ita": "Mottalciata",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F776",
      "lat": "45.5086462",
      "lon": "8.2074871",
      "superficie_kmq": "18.3909",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096038",
      "denominazione_ita_altra": "Muzzano",
      "denominazione_ita": "Muzzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F833",
      "lat": "45.5606169",
      "lon": "7.9864982",
      "superficie_kmq": "6.0973",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096039",
      "denominazione_ita_altra": "Netro",
      "denominazione_ita": "Netro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F878",
      "lat": "45.5380394",
      "lon": "7.9478973",
      "superficie_kmq": "12.5683",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096040",
      "denominazione_ita_altra": "Occhieppo Inferiore",
      "denominazione_ita": "Occhieppo Inferiore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F992",
      "lat": "45.5532253",
      "lon": "8.0225627",
      "superficie_kmq": "4.0645",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096041",
      "denominazione_ita_altra": "Occhieppo Superiore",
      "denominazione_ita": "Occhieppo Superiore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F993",
      "lat": "45.5647846",
      "lon": "8.0047190",
      "superficie_kmq": "5.1503",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096042",
      "denominazione_ita_altra": "Pettinengo",
      "denominazione_ita": "Pettinengo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G521",
      "lat": "45.6131216",
      "lon": "8.1056082",
      "superficie_kmq": "15.2975",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096043",
      "denominazione_ita_altra": "Piatto",
      "denominazione_ita": "Piatto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G577",
      "lat": "45.5918080",
      "lon": "8.1351932",
      "superficie_kmq": "3.5913",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096044",
      "denominazione_ita_altra": "Piedicavallo",
      "denominazione_ita": "Piedicavallo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G594",
      "lat": "45.6896229",
      "lon": "7.9560585",
      "superficie_kmq": "17.7505",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096046",
      "denominazione_ita_altra": "Pollone",
      "denominazione_ita": "Pollone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G798",
      "lat": "45.5835439",
      "lon": "8.0009346",
      "superficie_kmq": "16.2169",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096047",
      "denominazione_ita_altra": "Ponderano",
      "denominazione_ita": "Ponderano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G820",
      "lat": "45.5385581",
      "lon": "8.0568102",
      "superficie_kmq": "7.0519",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096048",
      "denominazione_ita_altra": "Portula",
      "denominazione_ita": "Portula",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G927",
      "lat": "45.6753285",
      "lon": "8.1790828",
      "superficie_kmq": "11.3050",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096049",
      "denominazione_ita_altra": "Pralungo",
      "denominazione_ita": "Pralungo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G980",
      "lat": "45.5909394",
      "lon": "8.0380563",
      "superficie_kmq": "7.2497",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096050",
      "denominazione_ita_altra": "Pray",
      "denominazione_ita": "Pray",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G974",
      "lat": "45.6761758",
      "lon": "8.2087287",
      "superficie_kmq": "9.1846",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096053",
      "denominazione_ita_altra": "Ronco Biellese",
      "denominazione_ita": "Ronco Biellese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H538",
      "lat": "45.5816177",
      "lon": "8.0988816",
      "superficie_kmq": "3.8493",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096054",
      "denominazione_ita_altra": "Roppolo",
      "denominazione_ita": "Roppolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H553",
      "lat": "45.4204159",
      "lon": "8.0686508",
      "superficie_kmq": "8.6512",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096055",
      "denominazione_ita_altra": "Rosazza",
      "denominazione_ita": "Rosazza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H561",
      "lat": "45.6761406",
      "lon": "7.9775338",
      "superficie_kmq": "9.0230",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096056",
      "denominazione_ita_altra": "Sagliano Micca",
      "denominazione_ita": "Sagliano Micca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H662",
      "lat": "45.6228190",
      "lon": "8.0452822",
      "superficie_kmq": "14.6112",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096057",
      "denominazione_ita_altra": "Sala Biellese",
      "denominazione_ita": "Sala Biellese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H681",
      "lat": "45.5083234",
      "lon": "7.9554909",
      "superficie_kmq": "8.0298",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096058",
      "denominazione_ita_altra": "Salussola",
      "denominazione_ita": "Salussola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H726",
      "lat": "45.4493162",
      "lon": "8.1088850",
      "superficie_kmq": "38.5152",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096059",
      "denominazione_ita_altra": "Sandigliano",
      "denominazione_ita": "Sandigliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H821",
      "lat": "45.5214971",
      "lon": "8.0759436",
      "superficie_kmq": "10.2224",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096063",
      "denominazione_ita_altra": "Sordevolo",
      "denominazione_ita": "Sordevolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I847",
      "lat": "45.5715035",
      "lon": "7.9752863",
      "superficie_kmq": "13.7379",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096064",
      "denominazione_ita_altra": "Sostegno",
      "denominazione_ita": "Sostegno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I868",
      "lat": "45.6535428",
      "lon": "8.2689894",
      "superficie_kmq": "18.0670",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096065",
      "denominazione_ita_altra": "Strona",
      "denominazione_ita": "Strona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I980",
      "lat": "45.6207124",
      "lon": "8.1692787",
      "superficie_kmq": "3.7187",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096066",
      "denominazione_ita_altra": "Tavigliano",
      "denominazione_ita": "Tavigliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L075",
      "lat": "45.6236346",
      "lon": "8.0531941",
      "superficie_kmq": "11.2354",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096067",
      "denominazione_ita_altra": "Ternengo",
      "denominazione_ita": "Ternengo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L116",
      "lat": "45.5901186",
      "lon": "8.1149427",
      "superficie_kmq": "1.9799",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096068",
      "denominazione_ita_altra": "Tollegno",
      "denominazione_ita": "Tollegno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L193",
      "lat": "45.5944610",
      "lon": "8.0532550",
      "superficie_kmq": "3.3143",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096069",
      "denominazione_ita_altra": "Torrazzo",
      "denominazione_ita": "Torrazzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L239",
      "lat": "45.4981071",
      "lon": "7.9553973",
      "superficie_kmq": "5.7712",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096071",
      "denominazione_ita_altra": "Valdengo",
      "denominazione_ita": "Valdengo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L556",
      "lat": "45.5711127",
      "lon": "8.1361498",
      "superficie_kmq": "7.6768",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096072",
      "denominazione_ita_altra": "Vallanzengo",
      "denominazione_ita": "Vallanzengo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L586",
      "lat": "45.6034948",
      "lon": "8.1482972",
      "superficie_kmq": "4.6653",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096074",
      "denominazione_ita_altra": "Valle San Nicolao",
      "denominazione_ita": "Valle San Nicolao",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L620",
      "lat": "45.6070409",
      "lon": "8.1407708",
      "superficie_kmq": "13.2572",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096075",
      "denominazione_ita_altra": "Veglio",
      "denominazione_ita": "Veglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L712",
      "lat": "45.6400909",
      "lon": "8.1141403",
      "superficie_kmq": "6.4058",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096076",
      "denominazione_ita_altra": "Verrone",
      "denominazione_ita": "Verrone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L785",
      "lat": "45.5039433",
      "lon": "8.1197154",
      "superficie_kmq": "8.5859",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096077",
      "denominazione_ita_altra": "Vigliano Biellese",
      "denominazione_ita": "Vigliano Biellese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L880",
      "lat": "45.5621648",
      "lon": "8.1099411",
      "superficie_kmq": "8.3978",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096078",
      "denominazione_ita_altra": "Villa del Bosco",
      "denominazione_ita": "Villa del Bosco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L933",
      "lat": "45.6202943",
      "lon": "8.2826472",
      "superficie_kmq": "3.6991",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096079",
      "denominazione_ita_altra": "Villanova Biellese",
      "denominazione_ita": "Villanova Biellese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L978",
      "lat": "45.4827515",
      "lon": "8.1926997",
      "superficie_kmq": "7.8731",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096080",
      "denominazione_ita_altra": "Viverone",
      "denominazione_ita": "Viverone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M098",
      "lat": "45.4256034",
      "lon": "8.0506576",
      "superficie_kmq": "12.2610",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096081",
      "denominazione_ita_altra": "Zimone",
      "denominazione_ita": "Zimone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M179",
      "lat": "45.4494559",
      "lon": "8.0373517",
      "superficie_kmq": "2.9495",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096082",
      "denominazione_ita_altra": "Zubiena",
      "denominazione_ita": "Zubiena",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M196",
      "lat": "45.4928394",
      "lon": "7.9973287",
      "superficie_kmq": "12.4736",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096083",
      "denominazione_ita_altra": "Zumaglia",
      "denominazione_ita": "Zumaglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M201",
      "lat": "45.5947917",
      "lon": "8.0887023",
      "superficie_kmq": "2.6137",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096085",
      "denominazione_ita_altra": "Lessona",
      "denominazione_ita": "Lessona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M371",
      "lat": "45.5720329",
      "lon": "8.2102816",
      "superficie_kmq": "12.7749",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096086",
      "denominazione_ita_altra": "Campiglia Cervo",
      "denominazione_ita": "Campiglia Cervo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M373",
      "lat": "45.6610170",
      "lon": "8.0017140",
      "superficie_kmq": "28.2053",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096087",
      "denominazione_ita_altra": "Quaregna Cerreto",
      "denominazione_ita": "Quaregna Cerreto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M414",
      "lat": "45.5799482",
      "lon": "8.1616401",
      "superficie_kmq": "8.4160",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "BI",
      "codice_istat": "096088",
      "denominazione_ita_altra": "Valdilana",
      "denominazione_ita": "Valdilana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M417",
      "lat": "45.6715593",
      "lon": "8.1275692",
      "superficie_kmq": "61.1382",
      "codice_sovracomunale": "096"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097001",
      "denominazione_ita_altra": "Abbadia Lariana",
      "denominazione_ita": "Abbadia Lariana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A005",
      "lat": "45.9005113",
      "lon": "9.3345493",
      "superficie_kmq": "16.6724",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097002",
      "denominazione_ita_altra": "Airuno",
      "denominazione_ita": "Airuno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A112",
      "lat": "45.7544159",
      "lon": "9.4224664",
      "superficie_kmq": "4.2859",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097003",
      "denominazione_ita_altra": "Annone di Brianza",
      "denominazione_ita": "Annone di Brianza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A301",
      "lat": "45.8052276",
      "lon": "9.3309915",
      "superficie_kmq": "5.9795",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097004",
      "denominazione_ita_altra": "Ballabio",
      "denominazione_ita": "Ballabio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A594",
      "lat": "45.8960481",
      "lon": "9.4241676",
      "superficie_kmq": "15.0377",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097005",
      "denominazione_ita_altra": "Barzago",
      "denominazione_ita": "Barzago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A683",
      "lat": "45.7552268",
      "lon": "9.3164929",
      "superficie_kmq": "3.5617",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097006",
      "denominazione_ita_altra": "Barzanò",
      "denominazione_ita": "Barzanò",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A686",
      "lat": "45.7346483",
      "lon": "9.3167765",
      "superficie_kmq": "3.6195",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097007",
      "denominazione_ita_altra": "Barzio",
      "denominazione_ita": "Barzio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A687",
      "lat": "45.9464815",
      "lon": "9.4683444",
      "superficie_kmq": "21.3496",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097008",
      "denominazione_ita_altra": "Bellano",
      "denominazione_ita": "Bellano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A745",
      "lat": "46.0433056",
      "lon": "9.3027690",
      "superficie_kmq": "22.0271",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097009",
      "denominazione_ita_altra": "Bosisio Parini",
      "denominazione_ita": "Bosisio Parini",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B081",
      "lat": "45.8004343",
      "lon": "9.2879220",
      "superficie_kmq": "5.8240",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097010",
      "denominazione_ita_altra": "Brivio",
      "denominazione_ita": "Brivio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B194",
      "lat": "45.7421289",
      "lon": "9.4483253",
      "superficie_kmq": "7.9534",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097011",
      "denominazione_ita_altra": "Bulciago",
      "denominazione_ita": "Bulciago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B261",
      "lat": "45.7508726",
      "lon": "9.2838910",
      "superficie_kmq": "3.1176",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097012",
      "denominazione_ita_altra": "Calco",
      "denominazione_ita": "Calco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B396",
      "lat": "45.7253911",
      "lon": "9.4178815",
      "superficie_kmq": "4.5889",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097013",
      "denominazione_ita_altra": "Calolziocorte",
      "denominazione_ita": "Calolziocorte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B423",
      "lat": "45.8018019",
      "lon": "9.4321101",
      "superficie_kmq": "9.1002",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097014",
      "denominazione_ita_altra": "Carenno",
      "denominazione_ita": "Carenno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B763",
      "lat": "45.8014355",
      "lon": "9.4616684",
      "superficie_kmq": "7.7853",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097015",
      "denominazione_ita_altra": "Casargo",
      "denominazione_ita": "Casargo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B937",
      "lat": "46.0383744",
      "lon": "9.3882702",
      "superficie_kmq": "19.7127",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097016",
      "denominazione_ita_altra": "Casatenovo",
      "denominazione_ita": "Casatenovo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B943",
      "lat": "45.6990742",
      "lon": "9.3155946",
      "superficie_kmq": "12.6640",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097017",
      "denominazione_ita_altra": "Cassago Brianza",
      "denominazione_ita": "Cassago Brianza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B996",
      "lat": "45.7405001",
      "lon": "9.2948267",
      "superficie_kmq": "3.5476",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097018",
      "denominazione_ita_altra": "Cassina Valsassina",
      "denominazione_ita": "Cassina Valsassina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C024",
      "lat": "45.9319904",
      "lon": "9.4793854",
      "superficie_kmq": "2.7243",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097019",
      "denominazione_ita_altra": "Castello di Brianza",
      "denominazione_ita": "Castello di Brianza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C187",
      "lat": "45.7558403",
      "lon": "9.3482512",
      "superficie_kmq": "3.5941",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097020",
      "denominazione_ita_altra": "Cernusco Lombardone",
      "denominazione_ita": "Cernusco Lombardone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C521",
      "lat": "45.6904976",
      "lon": "9.3975537",
      "superficie_kmq": "3.7419",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097021",
      "denominazione_ita_altra": "Cesana Brianza",
      "denominazione_ita": "Cesana Brianza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C563",
      "lat": "45.8194671",
      "lon": "9.3007643",
      "superficie_kmq": "3.6953",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097022",
      "denominazione_ita_altra": "Civate",
      "denominazione_ita": "Civate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C752",
      "lat": "45.8258633",
      "lon": "9.3473994",
      "superficie_kmq": "9.2704",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097023",
      "denominazione_ita_altra": "Colico",
      "denominazione_ita": "Colico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C839",
      "lat": "46.1338495",
      "lon": "9.3750111",
      "superficie_kmq": "35.0639",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097024",
      "denominazione_ita_altra": "Colle Brianza",
      "denominazione_ita": "Colle Brianza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C851",
      "lat": "45.7617034",
      "lon": "9.3659087",
      "superficie_kmq": "8.3179",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097025",
      "denominazione_ita_altra": "Cortenova",
      "denominazione_ita": "Cortenova",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D065",
      "lat": "45.9997327",
      "lon": "9.3837512",
      "superficie_kmq": "11.7688",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097026",
      "denominazione_ita_altra": "Costa Masnaga",
      "denominazione_ita": "Costa Masnaga",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D112",
      "lat": "45.7696326",
      "lon": "9.2762982",
      "superficie_kmq": "5.6179",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097027",
      "denominazione_ita_altra": "Crandola Valsassina",
      "denominazione_ita": "Crandola Valsassina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D131",
      "lat": "46.0221977",
      "lon": "9.3801035",
      "superficie_kmq": "8.8068",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097028",
      "denominazione_ita_altra": "Cremella",
      "denominazione_ita": "Cremella",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D143",
      "lat": "45.7399525",
      "lon": "9.3034098",
      "superficie_kmq": "1.8932",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097029",
      "denominazione_ita_altra": "Cremeno",
      "denominazione_ita": "Cremeno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D145",
      "lat": "45.9333814",
      "lon": "9.4736257",
      "superficie_kmq": "13.1833",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097030",
      "denominazione_ita_altra": "Dervio",
      "denominazione_ita": "Dervio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D280",
      "lat": "46.0770926",
      "lon": "9.3067290",
      "superficie_kmq": "11.6954",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097031",
      "denominazione_ita_altra": "Dolzago",
      "denominazione_ita": "Dolzago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D327",
      "lat": "45.7683821",
      "lon": "9.3436502",
      "superficie_kmq": "2.2583",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097032",
      "denominazione_ita_altra": "Dorio",
      "denominazione_ita": "Dorio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D346",
      "lat": "46.1017490",
      "lon": "9.3193677",
      "superficie_kmq": "11.6556",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097033",
      "denominazione_ita_altra": "Ello",
      "denominazione_ita": "Ello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D398",
      "lat": "45.7878257",
      "lon": "9.3656920",
      "superficie_kmq": "2.4193",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097034",
      "denominazione_ita_altra": "Erve",
      "denominazione_ita": "Erve",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D428",
      "lat": "45.8207545",
      "lon": "9.4455939",
      "superficie_kmq": "6.2005",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097035",
      "denominazione_ita_altra": "Esino Lario",
      "denominazione_ita": "Esino Lario",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D436",
      "lat": "45.9949367",
      "lon": "9.3353660",
      "superficie_kmq": "18.0526",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097036",
      "denominazione_ita_altra": "Galbiate",
      "denominazione_ita": "Galbiate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D865",
      "lat": "45.8143592",
      "lon": "9.3744399",
      "superficie_kmq": "15.6446",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097037",
      "denominazione_ita_altra": "Garbagnate Monastero",
      "denominazione_ita": "Garbagnate Monastero",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D913",
      "lat": "45.7723912",
      "lon": "9.3026695",
      "superficie_kmq": "3.4965",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097038",
      "denominazione_ita_altra": "Garlate",
      "denominazione_ita": "Garlate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D926",
      "lat": "45.8042826",
      "lon": "9.4040880",
      "superficie_kmq": "3.3023",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097039",
      "denominazione_ita_altra": "Imbersago",
      "denominazione_ita": "Imbersago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E287",
      "lat": "45.7052944",
      "lon": "9.4446061",
      "superficie_kmq": "3.1445",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097040",
      "denominazione_ita_altra": "Introbio",
      "denominazione_ita": "Introbio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E305",
      "lat": "45.9763190",
      "lon": "9.4518048",
      "superficie_kmq": "26.0331",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097042",
      "denominazione_ita_altra": "Lecco",
      "denominazione_ita": "Lecco",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "E507",
      "lat": "45.8557688",
      "lon": "9.3933923",
      "superficie_kmq": "45.1348",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097043",
      "denominazione_ita_altra": "Lierna",
      "denominazione_ita": "Lierna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E581",
      "lat": "45.9561578",
      "lon": "9.3069375",
      "superficie_kmq": "11.2384",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097044",
      "denominazione_ita_altra": "Lomagna",
      "denominazione_ita": "Lomagna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E656",
      "lat": "45.6675938",
      "lon": "9.3756718",
      "superficie_kmq": "3.9135",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097045",
      "denominazione_ita_altra": "Malgrate",
      "denominazione_ita": "Malgrate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E858",
      "lat": "45.8518932",
      "lon": "9.3734068",
      "superficie_kmq": "1.8954",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097046",
      "denominazione_ita_altra": "Mandello del Lario",
      "denominazione_ita": "Mandello del Lario",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E879",
      "lat": "45.9172827",
      "lon": "9.3195249",
      "superficie_kmq": "43.3309",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097047",
      "denominazione_ita_altra": "Margno",
      "denominazione_ita": "Margno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E947",
      "lat": "46.0304947",
      "lon": "9.3799840",
      "superficie_kmq": "3.5859",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097048",
      "denominazione_ita_altra": "Merate",
      "denominazione_ita": "Merate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F133",
      "lat": "45.6985451",
      "lon": "9.4100149",
      "superficie_kmq": "11.0634",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097049",
      "denominazione_ita_altra": "Missaglia",
      "denominazione_ita": "Missaglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F248",
      "lat": "45.7085189",
      "lon": "9.3343062",
      "superficie_kmq": "11.5189",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097050",
      "denominazione_ita_altra": "Moggio",
      "denominazione_ita": "Moggio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F265",
      "lat": "45.9312658",
      "lon": "9.4900395",
      "superficie_kmq": "13.4294",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097051",
      "denominazione_ita_altra": "Molteno",
      "denominazione_ita": "Molteno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F304",
      "lat": "45.7770764",
      "lon": "9.3065866",
      "superficie_kmq": "3.1193",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097052",
      "denominazione_ita_altra": "Monte Marenzo",
      "denominazione_ita": "Monte Marenzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F561",
      "lat": "45.7709452",
      "lon": "9.4535332",
      "superficie_kmq": "3.0584",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097053",
      "denominazione_ita_altra": "Montevecchia",
      "denominazione_ita": "Montevecchia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F657",
      "lat": "45.7054692",
      "lon": "9.3806468",
      "superficie_kmq": "5.7993",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097054",
      "denominazione_ita_altra": "Monticello Brianza",
      "denominazione_ita": "Monticello Brianza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F674",
      "lat": "45.7106958",
      "lon": "9.3150383",
      "superficie_kmq": "4.6072",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097055",
      "denominazione_ita_altra": "Morterone",
      "denominazione_ita": "Morterone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F758",
      "lat": "45.8762649",
      "lon": "9.4827587",
      "superficie_kmq": "13.7102",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097056",
      "denominazione_ita_altra": "Nibionno",
      "denominazione_ita": "Nibionno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F887",
      "lat": "45.7531280",
      "lon": "9.2639575",
      "superficie_kmq": "3.5135",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097057",
      "denominazione_ita_altra": "Oggiono",
      "denominazione_ita": "Oggiono",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G009",
      "lat": "45.7919173",
      "lon": "9.3509847",
      "superficie_kmq": "7.9575",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097058",
      "denominazione_ita_altra": "Olgiate Molgora",
      "denominazione_ita": "Olgiate Molgora",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G026",
      "lat": "45.7297891",
      "lon": "9.4029920",
      "superficie_kmq": "7.0910",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097059",
      "denominazione_ita_altra": "Olginate",
      "denominazione_ita": "Olginate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G030",
      "lat": "45.8022900",
      "lon": "9.4103244",
      "superficie_kmq": "8.0004",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097060",
      "denominazione_ita_altra": "Oliveto Lario",
      "denominazione_ita": "Oliveto Lario",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G040",
      "lat": "45.9299918",
      "lon": "9.2839673",
      "superficie_kmq": "15.6963",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097061",
      "denominazione_ita_altra": "Osnago",
      "denominazione_ita": "Osnago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G161",
      "lat": "45.6757428",
      "lon": "9.3880167",
      "superficie_kmq": "4.4936",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097062",
      "denominazione_ita_altra": "Paderno d'Adda",
      "denominazione_ita": "Paderno d'Adda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G218",
      "lat": "45.6802565",
      "lon": "9.4473934",
      "superficie_kmq": "3.5630",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097063",
      "denominazione_ita_altra": "Pagnona",
      "denominazione_ita": "Pagnona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G241",
      "lat": "46.0598329",
      "lon": "9.4024443",
      "superficie_kmq": "9.1968",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097064",
      "denominazione_ita_altra": "Parlasco",
      "denominazione_ita": "Parlasco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G336",
      "lat": "46.0181403",
      "lon": "9.3453815",
      "superficie_kmq": "2.9972",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097065",
      "denominazione_ita_altra": "Pasturo",
      "denominazione_ita": "Pasturo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G368",
      "lat": "45.9513959",
      "lon": "9.4434422",
      "superficie_kmq": "21.7839",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097067",
      "denominazione_ita_altra": "Perledo",
      "denominazione_ita": "Perledo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G456",
      "lat": "46.0153004",
      "lon": "9.2955719",
      "superficie_kmq": "13.6837",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097068",
      "denominazione_ita_altra": "Pescate",
      "denominazione_ita": "Pescate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G485",
      "lat": "45.8299841",
      "lon": "9.3945409",
      "superficie_kmq": "2.1001",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097069",
      "denominazione_ita_altra": "Premana",
      "denominazione_ita": "Premana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H028",
      "lat": "46.0522504",
      "lon": "9.4231058",
      "superficie_kmq": "33.6353",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097070",
      "denominazione_ita_altra": "Primaluna",
      "denominazione_ita": "Primaluna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H063",
      "lat": "45.9868940",
      "lon": "9.4294660",
      "superficie_kmq": "22.4254",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097071",
      "denominazione_ita_altra": "Robbiate",
      "denominazione_ita": "Robbiate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G223",
      "lat": "45.6888328",
      "lon": "9.4398136",
      "superficie_kmq": "4.7003",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097072",
      "denominazione_ita_altra": "Rogeno",
      "denominazione_ita": "Rogeno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H486",
      "lat": "45.7833290",
      "lon": "9.2669637",
      "superficie_kmq": "4.8248",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097074",
      "denominazione_ita_altra": "Santa Maria Hoè",
      "denominazione_ita": "Santa Maria Hoè",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I243",
      "lat": "45.7436397",
      "lon": "9.3715013",
      "superficie_kmq": "2.8344",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097075",
      "denominazione_ita_altra": "Sirone",
      "denominazione_ita": "Sirone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I759",
      "lat": "45.7725560",
      "lon": "9.3236467",
      "superficie_kmq": "3.2057",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097076",
      "denominazione_ita_altra": "Sirtori",
      "denominazione_ita": "Sirtori",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I761",
      "lat": "45.7352868",
      "lon": "9.3338630",
      "superficie_kmq": "4.2907",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097077",
      "denominazione_ita_altra": "Sueglio",
      "denominazione_ita": "Sueglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I994",
      "lat": "46.0857050",
      "lon": "9.3330968",
      "superficie_kmq": "4.1084",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097078",
      "denominazione_ita_altra": "Suello",
      "denominazione_ita": "Suello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I996",
      "lat": "45.8185587",
      "lon": "9.3081594",
      "superficie_kmq": "2.6290",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097079",
      "denominazione_ita_altra": "Taceno",
      "denominazione_ita": "Taceno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L022",
      "lat": "46.0209606",
      "lon": "9.3651434",
      "superficie_kmq": "3.7516",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097082",
      "denominazione_ita_altra": "Valgreghentino",
      "denominazione_ita": "Valgreghentino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L581",
      "lat": "45.7694459",
      "lon": "9.4131965",
      "superficie_kmq": "6.2463",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097083",
      "denominazione_ita_altra": "Valmadrera",
      "denominazione_ita": "Valmadrera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L634",
      "lat": "45.8469862",
      "lon": "9.3602709",
      "superficie_kmq": "12.5992",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097084",
      "denominazione_ita_altra": "Varenna",
      "denominazione_ita": "Varenna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L680",
      "lat": "46.0106262",
      "lon": "9.2835877",
      "superficie_kmq": "12.5679",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097086",
      "denominazione_ita_altra": "Vercurago",
      "denominazione_ita": "Vercurago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L751",
      "lat": "45.8107602",
      "lon": "9.4248005",
      "superficie_kmq": "2.1157",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097090",
      "denominazione_ita_altra": "Viganò",
      "denominazione_ita": "Viganò",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L866",
      "lat": "45.7260803",
      "lon": "9.3241967",
      "superficie_kmq": "1.6049",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097091",
      "denominazione_ita_altra": "Verderio",
      "denominazione_ita": "Verderio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M337",
      "lat": "45.6630760",
      "lon": "9.4394887",
      "superficie_kmq": "6.4653",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097092",
      "denominazione_ita_altra": "La Valletta Brianza",
      "denominazione_ita": "La Valletta Brianza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M348",
      "lat": "45.7307357",
      "lon": "9.3654233",
      "superficie_kmq": "8.7846",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LC",
      "codice_istat": "097093",
      "denominazione_ita_altra": "Valvarrone",
      "denominazione_ita": "Valvarrone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M395",
      "lat": "46.0830999",
      "lon": "9.3630663",
      "superficie_kmq": "14.9218",
      "codice_sovracomunale": "097"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098001",
      "denominazione_ita_altra": "Abbadia Cerreto",
      "denominazione_ita": "Abbadia Cerreto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A004",
      "lat": "45.3123939",
      "lon": "9.5925249",
      "superficie_kmq": "6.1986",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098002",
      "denominazione_ita_altra": "Bertonico",
      "denominazione_ita": "Bertonico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A811",
      "lat": "45.2338898",
      "lon": "9.6686016",
      "superficie_kmq": "20.8317",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098003",
      "denominazione_ita_altra": "Boffalora d'Adda",
      "denominazione_ita": "Boffalora d'Adda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A919",
      "lat": "45.3586344",
      "lon": "9.4946905",
      "superficie_kmq": "8.1303",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098004",
      "denominazione_ita_altra": "Borghetto Lodigiano",
      "denominazione_ita": "Borghetto Lodigiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A995",
      "lat": "45.2127426",
      "lon": "9.4980235",
      "superficie_kmq": "23.6411",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098005",
      "denominazione_ita_altra": "Borgo San Giovanni",
      "denominazione_ita": "Borgo San Giovanni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B017",
      "lat": "45.2756374",
      "lon": "9.4253413",
      "superficie_kmq": "7.5038",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098006",
      "denominazione_ita_altra": "Brembio",
      "denominazione_ita": "Brembio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B141",
      "lat": "45.2151046",
      "lon": "9.5696971",
      "superficie_kmq": "17.0835",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098008",
      "denominazione_ita_altra": "Casaletto Lodigiano",
      "denominazione_ita": "Casaletto Lodigiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B887",
      "lat": "45.2931359",
      "lon": "9.3618133",
      "superficie_kmq": "9.7506",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098009",
      "denominazione_ita_altra": "Casalmaiocco",
      "denominazione_ita": "Casalmaiocco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B899",
      "lat": "45.3530479",
      "lon": "9.3716625",
      "superficie_kmq": "4.7138",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098010",
      "denominazione_ita_altra": "Casalpusterlengo",
      "denominazione_ita": "Casalpusterlengo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B910",
      "lat": "45.1777976",
      "lon": "9.6510712",
      "superficie_kmq": "25.6090",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098011",
      "denominazione_ita_altra": "Caselle Landi",
      "denominazione_ita": "Caselle Landi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B961",
      "lat": "45.1037685",
      "lon": "9.7919901",
      "superficie_kmq": "26.0043",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098012",
      "denominazione_ita_altra": "Caselle Lurani",
      "denominazione_ita": "Caselle Lurani",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B958",
      "lat": "45.2804351",
      "lon": "9.3601395",
      "superficie_kmq": "7.6782",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098013",
      "denominazione_ita_altra": "Castelnuovo Bocca d'Adda",
      "denominazione_ita": "Castelnuovo Bocca d'Adda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C228",
      "lat": "45.1123690",
      "lon": "9.8694131",
      "superficie_kmq": "20.3232",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098014",
      "denominazione_ita_altra": "Castiglione d'Adda",
      "denominazione_ita": "Castiglione d'Adda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C304",
      "lat": "45.2156870",
      "lon": "9.6950303",
      "superficie_kmq": "12.9782",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098015",
      "denominazione_ita_altra": "Castiraga Vidardo",
      "denominazione_ita": "Castiraga Vidardo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C329",
      "lat": "45.2564556",
      "lon": "9.4026397",
      "superficie_kmq": "5.0381",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098017",
      "denominazione_ita_altra": "Cavenago d'Adda",
      "denominazione_ita": "Cavenago d'Adda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C394",
      "lat": "45.2839675",
      "lon": "9.6017950",
      "superficie_kmq": "16.0980",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098018",
      "denominazione_ita_altra": "Cervignano d'Adda",
      "denominazione_ita": "Cervignano d'Adda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C555",
      "lat": "45.3738340",
      "lon": "9.4226379",
      "superficie_kmq": "4.0688",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098019",
      "denominazione_ita_altra": "Codogno",
      "denominazione_ita": "Codogno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C816",
      "lat": "45.1600278",
      "lon": "9.7031917",
      "superficie_kmq": "20.8695",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098020",
      "denominazione_ita_altra": "Comazzo",
      "denominazione_ita": "Comazzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C917",
      "lat": "45.4406577",
      "lon": "9.4639616",
      "superficie_kmq": "12.8023",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098021",
      "denominazione_ita_altra": "Cornegliano Laudense",
      "denominazione_ita": "Cornegliano Laudense",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D021",
      "lat": "45.2844063",
      "lon": "9.4724500",
      "superficie_kmq": "5.6998",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098022",
      "denominazione_ita_altra": "Corno Giovine",
      "denominazione_ita": "Corno Giovine",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D028",
      "lat": "45.1331676",
      "lon": "9.7557533",
      "superficie_kmq": "9.9361",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098023",
      "denominazione_ita_altra": "Cornovecchio",
      "denominazione_ita": "Cornovecchio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D029",
      "lat": "45.1361361",
      "lon": "9.7976232",
      "superficie_kmq": "6.5317",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098024",
      "denominazione_ita_altra": "Corte Palasio",
      "denominazione_ita": "Corte Palasio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D068",
      "lat": "45.3132838",
      "lon": "9.5627725",
      "superficie_kmq": "15.6782",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098025",
      "denominazione_ita_altra": "Crespiatica",
      "denominazione_ita": "Crespiatica",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D159",
      "lat": "45.3570602",
      "lon": "9.5746095",
      "superficie_kmq": "7.0319",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098026",
      "denominazione_ita_altra": "Fombio",
      "denominazione_ita": "Fombio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D660",
      "lat": "45.1367943",
      "lon": "9.6835988",
      "superficie_kmq": "7.3971",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098027",
      "denominazione_ita_altra": "Galgagnano",
      "denominazione_ita": "Galgagnano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D868",
      "lat": "45.3578833",
      "lon": "9.4429580",
      "superficie_kmq": "6.0109",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098028",
      "denominazione_ita_altra": "Graffignana",
      "denominazione_ita": "Graffignana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E127",
      "lat": "45.2113252",
      "lon": "9.4532720",
      "superficie_kmq": "10.9213",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098029",
      "denominazione_ita_altra": "Guardamiglio",
      "denominazione_ita": "Guardamiglio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E238",
      "lat": "45.1090622",
      "lon": "9.6813057",
      "superficie_kmq": "10.4374",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098030",
      "denominazione_ita_altra": "Livraga",
      "denominazione_ita": "Livraga",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E627",
      "lat": "45.1926331",
      "lon": "9.5448086",
      "superficie_kmq": "12.3711",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098031",
      "denominazione_ita_altra": "Lodi",
      "denominazione_ita": "Lodi",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "E648",
      "lat": "45.3144099",
      "lon": "9.5037208",
      "superficie_kmq": "41.3786",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098032",
      "denominazione_ita_altra": "Lodi Vecchio",
      "denominazione_ita": "Lodi Vecchio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E651",
      "lat": "45.3007166",
      "lon": "9.4148464",
      "superficie_kmq": "16.4503",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098033",
      "denominazione_ita_altra": "Maccastorna",
      "denominazione_ita": "Maccastorna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E777",
      "lat": "45.1466303",
      "lon": "9.8525327",
      "superficie_kmq": "5.7452",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098034",
      "denominazione_ita_altra": "Mairago",
      "denominazione_ita": "Mairago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E840",
      "lat": "45.2522636",
      "lon": "9.5784982",
      "superficie_kmq": "11.2472",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098035",
      "denominazione_ita_altra": "Maleo",
      "denominazione_ita": "Maleo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E852",
      "lat": "45.1682079",
      "lon": "9.7645369",
      "superficie_kmq": "19.8334",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098036",
      "denominazione_ita_altra": "Marudo",
      "denominazione_ita": "Marudo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E994",
      "lat": "45.2525597",
      "lon": "9.3749910",
      "superficie_kmq": "4.2024",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098037",
      "denominazione_ita_altra": "Massalengo",
      "denominazione_ita": "Massalengo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F028",
      "lat": "45.2635192",
      "lon": "9.4898503",
      "superficie_kmq": "8.4767",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098038",
      "denominazione_ita_altra": "Meleti",
      "denominazione_ita": "Meleti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F102",
      "lat": "45.1187980",
      "lon": "9.8367805",
      "superficie_kmq": "7.3856",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098039",
      "denominazione_ita_altra": "Merlino",
      "denominazione_ita": "Merlino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F149",
      "lat": "45.4329051",
      "lon": "9.4298794",
      "superficie_kmq": "10.7302",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098040",
      "denominazione_ita_altra": "Montanaso Lombardo",
      "denominazione_ita": "Montanaso Lombardo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F423",
      "lat": "45.3352848",
      "lon": "9.4630882",
      "superficie_kmq": "9.5212",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098041",
      "denominazione_ita_altra": "Mulazzano",
      "denominazione_ita": "Mulazzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F801",
      "lat": "45.3721519",
      "lon": "9.3979284",
      "superficie_kmq": "15.5790",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098042",
      "denominazione_ita_altra": "Orio Litta",
      "denominazione_ita": "Orio Litta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G107",
      "lat": "45.1572130",
      "lon": "9.5553131",
      "superficie_kmq": "9.7830",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098043",
      "denominazione_ita_altra": "Ospedaletto Lodigiano",
      "denominazione_ita": "Ospedaletto Lodigiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G166",
      "lat": "45.1692293",
      "lon": "9.5809635",
      "superficie_kmq": "8.4968",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098044",
      "denominazione_ita_altra": "Ossago Lodigiano",
      "denominazione_ita": "Ossago Lodigiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G171",
      "lat": "45.2448672",
      "lon": "9.5364797",
      "superficie_kmq": "11.5250",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098045",
      "denominazione_ita_altra": "Pieve Fissiraga",
      "denominazione_ita": "Pieve Fissiraga",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G096",
      "lat": "45.2626941",
      "lon": "9.4592515",
      "superficie_kmq": "12.2653",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098046",
      "denominazione_ita_altra": "Salerano sul Lambro",
      "denominazione_ita": "Salerano sul Lambro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H701",
      "lat": "45.2945701",
      "lon": "9.3856847",
      "superficie_kmq": "4.3749",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098047",
      "denominazione_ita_altra": "San Fiorano",
      "denominazione_ita": "San Fiorano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H844",
      "lat": "45.1359382",
      "lon": "9.7219177",
      "superficie_kmq": "8.9657",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098048",
      "denominazione_ita_altra": "San Martino in Strada",
      "denominazione_ita": "San Martino in Strada",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I012",
      "lat": "45.2707935",
      "lon": "9.5305323",
      "superficie_kmq": "13.1484",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098049",
      "denominazione_ita_altra": "San Rocco al Porto",
      "denominazione_ita": "San Rocco al Porto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I140",
      "lat": "45.0804733",
      "lon": "9.6982919",
      "superficie_kmq": "30.5620",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098050",
      "denominazione_ita_altra": "Sant'Angelo Lodigiano",
      "denominazione_ita": "Sant'Angelo Lodigiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I274",
      "lat": "45.2381190",
      "lon": "9.4095785",
      "superficie_kmq": "20.0529",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098051",
      "denominazione_ita_altra": "Santo Stefano Lodigiano",
      "denominazione_ita": "Santo Stefano Lodigiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I362",
      "lat": "45.1220738",
      "lon": "9.7430538",
      "superficie_kmq": "10.5321",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098052",
      "denominazione_ita_altra": "Secugnago",
      "denominazione_ita": "Secugnago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I561",
      "lat": "45.2303463",
      "lon": "9.5914169",
      "superficie_kmq": "6.7459",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098053",
      "denominazione_ita_altra": "Senna Lodigiana",
      "denominazione_ita": "Senna Lodigiana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I612",
      "lat": "45.1472496",
      "lon": "9.5935848",
      "superficie_kmq": "27.0154",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098054",
      "denominazione_ita_altra": "Somaglia",
      "denominazione_ita": "Somaglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I815",
      "lat": "45.1469862",
      "lon": "9.6378862",
      "superficie_kmq": "20.8222",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098055",
      "denominazione_ita_altra": "Sordio",
      "denominazione_ita": "Sordio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I848",
      "lat": "45.3401996",
      "lon": "9.3668676",
      "superficie_kmq": "2.8206",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098056",
      "denominazione_ita_altra": "Tavazzano con Villavesco",
      "denominazione_ita": "Tavazzano con Villavesco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F260",
      "lat": "45.3275049",
      "lon": "9.4096956",
      "superficie_kmq": "16.0684",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098057",
      "denominazione_ita_altra": "Terranova dei Passerini",
      "denominazione_ita": "Terranova dei Passerini",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L125",
      "lat": "45.1986831",
      "lon": "9.6782873",
      "superficie_kmq": "11.2567",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098058",
      "denominazione_ita_altra": "Turano Lodigiano",
      "denominazione_ita": "Turano Lodigiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L469",
      "lat": "45.2482403",
      "lon": "9.6227199",
      "superficie_kmq": "16.3773",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098059",
      "denominazione_ita_altra": "Valera Fratta",
      "denominazione_ita": "Valera Fratta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L572",
      "lat": "45.2568245",
      "lon": "9.3335100",
      "superficie_kmq": "8.0146",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098060",
      "denominazione_ita_altra": "Villanova del Sillaro",
      "denominazione_ita": "Villanova del Sillaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L977",
      "lat": "45.2373117",
      "lon": "9.4808905",
      "superficie_kmq": "13.4986",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098061",
      "denominazione_ita_altra": "Zelo Buon Persico",
      "denominazione_ita": "Zelo Buon Persico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M158",
      "lat": "45.4116133",
      "lon": "9.4292324",
      "superficie_kmq": "18.8824",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "LO",
      "codice_istat": "098062",
      "denominazione_ita_altra": "Castelgerundo",
      "denominazione_ita": "Castelgerundo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M393",
      "lat": "45.2001800",
      "lon": "9.7378137",
      "superficie_kmq": "19.8696",
      "codice_sovracomunale": "098"
    },
    {
      "sigla_provincia": "RN",
      "codice_istat": "099001",
      "denominazione_ita_altra": "Bellaria-Igea Marina",
      "denominazione_ita": "Bellaria-Igea Marina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A747",
      "lat": "44.1475882",
      "lon": "12.4580573",
      "superficie_kmq": "18.1698",
      "codice_sovracomunale": "099"
    },
    {
      "sigla_provincia": "RN",
      "codice_istat": "099002",
      "denominazione_ita_altra": "Cattolica",
      "denominazione_ita": "Cattolica",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C357",
      "lat": "43.9637506",
      "lon": "12.7446422",
      "superficie_kmq": "6.1953",
      "codice_sovracomunale": "099"
    },
    {
      "sigla_provincia": "RN",
      "codice_istat": "099003",
      "denominazione_ita_altra": "Coriano",
      "denominazione_ita": "Coriano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D004",
      "lat": "43.9643900",
      "lon": "12.6025326",
      "superficie_kmq": "46.7666",
      "codice_sovracomunale": "099"
    },
    {
      "sigla_provincia": "RN",
      "codice_istat": "099004",
      "denominazione_ita_altra": "Gemmano",
      "denominazione_ita": "Gemmano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D961",
      "lat": "43.9043175",
      "lon": "12.5828289",
      "superficie_kmq": "18.8499",
      "codice_sovracomunale": "099"
    },
    {
      "sigla_provincia": "RN",
      "codice_istat": "099005",
      "denominazione_ita_altra": "Misano Adriatico",
      "denominazione_ita": "Misano Adriatico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F244",
      "lat": "43.9733450",
      "lon": "12.6932352",
      "superficie_kmq": "22.3450",
      "codice_sovracomunale": "099"
    },
    {
      "sigla_provincia": "RN",
      "codice_istat": "099006",
      "denominazione_ita_altra": "Mondaino",
      "denominazione_ita": "Mondaino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F346",
      "lat": "43.8560103",
      "lon": "12.6713804",
      "superficie_kmq": "19.8400",
      "codice_sovracomunale": "099"
    },
    {
      "sigla_provincia": "RN",
      "codice_istat": "099008",
      "denominazione_ita_altra": "Montefiore Conca",
      "denominazione_ita": "Montefiore Conca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F502",
      "lat": "43.8906534",
      "lon": "12.6112639",
      "superficie_kmq": "22.3216",
      "codice_sovracomunale": "099"
    },
    {
      "sigla_provincia": "RN",
      "codice_istat": "099009",
      "denominazione_ita_altra": "Montegridolfo",
      "denominazione_ita": "Montegridolfo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F523",
      "lat": "43.8590451",
      "lon": "12.6891418",
      "superficie_kmq": "6.9373",
      "codice_sovracomunale": "099"
    },
    {
      "sigla_provincia": "RN",
      "codice_istat": "099011",
      "denominazione_ita_altra": "Morciano di Romagna",
      "denominazione_ita": "Morciano di Romagna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F715",
      "lat": "43.9137249",
      "lon": "12.6459361",
      "superficie_kmq": "5.4400",
      "codice_sovracomunale": "099"
    },
    {
      "sigla_provincia": "RN",
      "codice_istat": "099013",
      "denominazione_ita_altra": "Riccione",
      "denominazione_ita": "Riccione",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H274",
      "lat": "44.0000099",
      "lon": "12.6550568",
      "superficie_kmq": "17.5208",
      "codice_sovracomunale": "099"
    },
    {
      "sigla_provincia": "RN",
      "codice_istat": "099014",
      "denominazione_ita_altra": "Rimini",
      "denominazione_ita": "Rimini",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "H294",
      "lat": "44.0608861",
      "lon": "12.5656286",
      "superficie_kmq": "135.7915",
      "codice_sovracomunale": "099"
    },
    {
      "sigla_provincia": "RN",
      "codice_istat": "099015",
      "denominazione_ita_altra": "Saludecio",
      "denominazione_ita": "Saludecio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H724",
      "lat": "43.8726508",
      "lon": "12.6688082",
      "superficie_kmq": "34.2646",
      "codice_sovracomunale": "099"
    },
    {
      "sigla_provincia": "RN",
      "codice_istat": "099016",
      "denominazione_ita_altra": "San Clemente",
      "denominazione_ita": "San Clemente",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H801",
      "lat": "43.9322292",
      "lon": "12.6256041",
      "superficie_kmq": "20.6958",
      "codice_sovracomunale": "099"
    },
    {
      "sigla_provincia": "RN",
      "codice_istat": "099017",
      "denominazione_ita_altra": "San Giovanni in Marignano",
      "denominazione_ita": "San Giovanni in Marignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H921",
      "lat": "43.9389701",
      "lon": "12.7124586",
      "superficie_kmq": "21.3734",
      "codice_sovracomunale": "099"
    },
    {
      "sigla_provincia": "RN",
      "codice_istat": "099018",
      "denominazione_ita_altra": "Santarcangelo di Romagna",
      "denominazione_ita": "Santarcangelo di Romagna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I304",
      "lat": "44.0628768",
      "lon": "12.4470221",
      "superficie_kmq": "45.0587",
      "codice_sovracomunale": "099"
    },
    {
      "sigla_provincia": "RN",
      "codice_istat": "099020",
      "denominazione_ita_altra": "Verucchio",
      "denominazione_ita": "Verucchio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L797",
      "lat": "43.9836895",
      "lon": "12.4213817",
      "superficie_kmq": "27.3019",
      "codice_sovracomunale": "099"
    },
    {
      "sigla_provincia": "RN",
      "codice_istat": "099021",
      "denominazione_ita_altra": "Casteldelci",
      "denominazione_ita": "Casteldelci",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C080",
      "lat": "43.7913749",
      "lon": "12.1551182",
      "superficie_kmq": "49.6767",
      "codice_sovracomunale": "099"
    },
    {
      "sigla_provincia": "RN",
      "codice_istat": "099022",
      "denominazione_ita_altra": "Maiolo",
      "denominazione_ita": "Maiolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E838",
      "lat": "43.8736160",
      "lon": "12.3108707",
      "superficie_kmq": "24.2755",
      "codice_sovracomunale": "099"
    },
    {
      "sigla_provincia": "RN",
      "codice_istat": "099023",
      "denominazione_ita_altra": "Novafeltria",
      "denominazione_ita": "Novafeltria",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F137",
      "lat": "43.8958199",
      "lon": "12.2892668",
      "superficie_kmq": "41.8381",
      "codice_sovracomunale": "099"
    },
    {
      "sigla_provincia": "RN",
      "codice_istat": "099024",
      "denominazione_ita_altra": "Pennabilli",
      "denominazione_ita": "Pennabilli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G433",
      "lat": "43.8182921",
      "lon": "12.2647715",
      "superficie_kmq": "69.8034",
      "codice_sovracomunale": "099"
    },
    {
      "sigla_provincia": "RN",
      "codice_istat": "099025",
      "denominazione_ita_altra": "San Leo",
      "denominazione_ita": "San Leo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H949",
      "lat": "43.8961623",
      "lon": "12.3432792",
      "superficie_kmq": "53.1360",
      "codice_sovracomunale": "099"
    },
    {
      "sigla_provincia": "RN",
      "codice_istat": "099026",
      "denominazione_ita_altra": "Sant'Agata Feltria",
      "denominazione_ita": "Sant'Agata Feltria",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I201",
      "lat": "43.8640230",
      "lon": "12.2084525",
      "superficie_kmq": "79.7408",
      "codice_sovracomunale": "099"
    },
    {
      "sigla_provincia": "RN",
      "codice_istat": "099027",
      "denominazione_ita_altra": "Talamello",
      "denominazione_ita": "Talamello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L034",
      "lat": "43.9048873",
      "lon": "12.2861310",
      "superficie_kmq": "10.5887",
      "codice_sovracomunale": "099"
    },
    {
      "sigla_provincia": "RN",
      "codice_istat": "099028",
      "denominazione_ita_altra": "Poggio Torriana",
      "denominazione_ita": "Poggio Torriana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M324",
      "lat": "43.9956987",
      "lon": "12.3868813",
      "superficie_kmq": "34.7351",
      "codice_sovracomunale": "099"
    },
    {
      "sigla_provincia": "RN",
      "codice_istat": "099029",
      "denominazione_ita_altra": "Montescudo-Monte Colombo",
      "denominazione_ita": "Montescudo-Monte Colombo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M368",
      "lat": "43.9255872",
      "lon": "12.5505032",
      "superficie_kmq": "32.3479",
      "codice_sovracomunale": "099"
    },
    {
      "sigla_provincia": "RN",
      "codice_istat": "099030",
      "denominazione_ita_altra": "Montecopiolo",
      "denominazione_ita": "Montecopiolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F478",
      "lat": "43.8399389",
      "lon": "12.3647291",
      "superficie_kmq": "35.8116",
      "codice_sovracomunale": "099"
    },
    {
      "sigla_provincia": "RN",
      "codice_istat": "099031",
      "denominazione_ita_altra": "Sassofeltrio",
      "denominazione_ita": "Sassofeltrio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I460",
      "lat": "43.8904448",
      "lon": "12.4805379",
      "superficie_kmq": "21.0784",
      "codice_sovracomunale": "099"
    },
    {
      "sigla_provincia": "PO",
      "codice_istat": "100001",
      "denominazione_ita_altra": "Cantagallo",
      "denominazione_ita": "Cantagallo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B626",
      "lat": "44.0282526",
      "lon": "11.1078096",
      "superficie_kmq": "95.6224",
      "codice_sovracomunale": "100"
    },
    {
      "sigla_provincia": "PO",
      "codice_istat": "100002",
      "denominazione_ita_altra": "Carmignano",
      "denominazione_ita": "Carmignano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B794",
      "lat": "43.8138139",
      "lon": "11.0183474",
      "superficie_kmq": "38.4275",
      "codice_sovracomunale": "100"
    },
    {
      "sigla_provincia": "PO",
      "codice_istat": "100003",
      "denominazione_ita_altra": "Montemurlo",
      "denominazione_ita": "Montemurlo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F572",
      "lat": "43.9268837",
      "lon": "11.0371366",
      "superficie_kmq": "30.7758",
      "codice_sovracomunale": "100"
    },
    {
      "sigla_provincia": "PO",
      "codice_istat": "100004",
      "denominazione_ita_altra": "Poggio a Caiano",
      "denominazione_ita": "Poggio a Caiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G754",
      "lat": "43.8162680",
      "lon": "11.0558538",
      "superficie_kmq": "5.9995",
      "codice_sovracomunale": "100"
    },
    {
      "sigla_provincia": "PO",
      "codice_istat": "100005",
      "denominazione_ita_altra": "Prato",
      "denominazione_ita": "Prato",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "G999",
      "lat": "43.8806048",
      "lon": "11.0970325",
      "superficie_kmq": "97.3521",
      "codice_sovracomunale": "100"
    },
    {
      "sigla_provincia": "PO",
      "codice_istat": "100006",
      "denominazione_ita_altra": "Vaiano",
      "denominazione_ita": "Vaiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L537",
      "lat": "43.9686299",
      "lon": "11.1233131",
      "superficie_kmq": "34.1088",
      "codice_sovracomunale": "100"
    },
    {
      "sigla_provincia": "PO",
      "codice_istat": "100007",
      "denominazione_ita_altra": "Vernio",
      "denominazione_ita": "Vernio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L775",
      "lat": "44.0533294",
      "lon": "11.1557588",
      "superficie_kmq": "63.3768",
      "codice_sovracomunale": "100"
    },
    {
      "sigla_provincia": "KR",
      "codice_istat": "101001",
      "denominazione_ita_altra": "Belvedere di Spinello",
      "denominazione_ita": "Belvedere di Spinello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A772",
      "lat": "39.2136030",
      "lon": "16.8868357",
      "superficie_kmq": "30.3109",
      "codice_sovracomunale": "101"
    },
    {
      "sigla_provincia": "KR",
      "codice_istat": "101002",
      "denominazione_ita_altra": "Caccuri",
      "denominazione_ita": "Caccuri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B319",
      "lat": "39.2271195",
      "lon": "16.7802802",
      "superficie_kmq": "61.3762",
      "codice_sovracomunale": "101"
    },
    {
      "sigla_provincia": "KR",
      "codice_istat": "101003",
      "denominazione_ita_altra": "Carfizzi",
      "denominazione_ita": "Carfizzi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B771",
      "lat": "39.3061153",
      "lon": "16.9760971",
      "superficie_kmq": "20.7254",
      "codice_sovracomunale": "101"
    },
    {
      "sigla_provincia": "KR",
      "codice_istat": "101004",
      "denominazione_ita_altra": "Casabona",
      "denominazione_ita": "Casabona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B857",
      "lat": "39.2483820",
      "lon": "16.9582900",
      "superficie_kmq": "67.6721",
      "codice_sovracomunale": "101"
    },
    {
      "sigla_provincia": "KR",
      "codice_istat": "101005",
      "denominazione_ita_altra": "Castelsilano",
      "denominazione_ita": "Castelsilano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B968",
      "lat": "39.2705307",
      "lon": "16.7668595",
      "superficie_kmq": "40.0630",
      "codice_sovracomunale": "101"
    },
    {
      "sigla_provincia": "KR",
      "codice_istat": "101006",
      "denominazione_ita_altra": "Cerenzia",
      "denominazione_ita": "Cerenzia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C501",
      "lat": "39.2449605",
      "lon": "16.7828007",
      "superficie_kmq": "21.9655",
      "codice_sovracomunale": "101"
    },
    {
      "sigla_provincia": "KR",
      "codice_istat": "101007",
      "denominazione_ita_altra": "Cirò",
      "denominazione_ita": "Cirò",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C725",
      "lat": "39.3816070",
      "lon": "17.0596456",
      "superficie_kmq": "71.0449",
      "codice_sovracomunale": "101"
    },
    {
      "sigla_provincia": "KR",
      "codice_istat": "101008",
      "denominazione_ita_altra": "Cirò Marina",
      "denominazione_ita": "Cirò Marina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C726",
      "lat": "39.3652128",
      "lon": "17.1308293",
      "superficie_kmq": "41.6808",
      "codice_sovracomunale": "101"
    },
    {
      "sigla_provincia": "KR",
      "codice_istat": "101009",
      "denominazione_ita_altra": "Cotronei",
      "denominazione_ita": "Cotronei",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D123",
      "lat": "39.1895078",
      "lon": "16.6967722",
      "superficie_kmq": "79.2012",
      "codice_sovracomunale": "101"
    },
    {
      "sigla_provincia": "KR",
      "codice_istat": "101010",
      "denominazione_ita_altra": "Crotone",
      "denominazione_ita": "Crotone",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "D122",
      "lat": "39.0802866",
      "lon": "17.1253830",
      "superficie_kmq": "181.9947",
      "codice_sovracomunale": "101"
    },
    {
      "sigla_provincia": "KR",
      "codice_istat": "101011",
      "denominazione_ita_altra": "Crucoli",
      "denominazione_ita": "Crucoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D189",
      "lat": "39.4246097",
      "lon": "17.0036888",
      "superficie_kmq": "50.4250",
      "codice_sovracomunale": "101"
    },
    {
      "sigla_provincia": "KR",
      "codice_istat": "101012",
      "denominazione_ita_altra": "Cutro",
      "denominazione_ita": "Cutro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D236",
      "lat": "39.0331118",
      "lon": "16.9819446",
      "superficie_kmq": "133.6858",
      "codice_sovracomunale": "101"
    },
    {
      "sigla_provincia": "KR",
      "codice_istat": "101013",
      "denominazione_ita_altra": "Isola di Capo Rizzuto",
      "denominazione_ita": "Isola di Capo Rizzuto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E339",
      "lat": "38.9589497",
      "lon": "17.0956412",
      "superficie_kmq": "126.6438",
      "codice_sovracomunale": "101"
    },
    {
      "sigla_provincia": "KR",
      "codice_istat": "101014",
      "denominazione_ita_altra": "Melissa",
      "denominazione_ita": "Melissa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F108",
      "lat": "39.3051090",
      "lon": "17.0228126",
      "superficie_kmq": "51.6315",
      "codice_sovracomunale": "101"
    },
    {
      "sigla_provincia": "KR",
      "codice_istat": "101015",
      "denominazione_ita_altra": "Mesoraca",
      "denominazione_ita": "Mesoraca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F157",
      "lat": "39.0824420",
      "lon": "16.7938554",
      "superficie_kmq": "94.7896",
      "codice_sovracomunale": "101"
    },
    {
      "sigla_provincia": "KR",
      "codice_istat": "101016",
      "denominazione_ita_altra": "Pallagorio",
      "denominazione_ita": "Pallagorio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G278",
      "lat": "39.3082392",
      "lon": "16.9133413",
      "superficie_kmq": "44.4826",
      "codice_sovracomunale": "101"
    },
    {
      "sigla_provincia": "KR",
      "codice_istat": "101017",
      "denominazione_ita_altra": "Petilia Policastro",
      "denominazione_ita": "Petilia Policastro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G508",
      "lat": "39.1105674",
      "lon": "16.7926970",
      "superficie_kmq": "98.3481",
      "codice_sovracomunale": "101"
    },
    {
      "sigla_provincia": "KR",
      "codice_istat": "101018",
      "denominazione_ita_altra": "Roccabernarda",
      "denominazione_ita": "Roccabernarda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H383",
      "lat": "39.1338696",
      "lon": "16.8717215",
      "superficie_kmq": "64.8880",
      "codice_sovracomunale": "101"
    },
    {
      "sigla_provincia": "KR",
      "codice_istat": "101019",
      "denominazione_ita_altra": "Rocca di Neto",
      "denominazione_ita": "Rocca di Neto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H403",
      "lat": "39.1916287",
      "lon": "17.0023311",
      "superficie_kmq": "44.9243",
      "codice_sovracomunale": "101"
    },
    {
      "sigla_provincia": "KR",
      "codice_istat": "101020",
      "denominazione_ita_altra": "San Mauro Marchesato",
      "denominazione_ita": "San Mauro Marchesato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I026",
      "lat": "39.1026835",
      "lon": "16.9225301",
      "superficie_kmq": "41.9108",
      "codice_sovracomunale": "101"
    },
    {
      "sigla_provincia": "KR",
      "codice_istat": "101021",
      "denominazione_ita_altra": "San Nicola dell'Alto",
      "denominazione_ita": "San Nicola dell'Alto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I057",
      "lat": "39.2881224",
      "lon": "16.9757477",
      "superficie_kmq": "7.8517",
      "codice_sovracomunale": "101"
    },
    {
      "sigla_provincia": "KR",
      "codice_istat": "101022",
      "denominazione_ita_altra": "Santa Severina",
      "denominazione_ita": "Santa Severina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I308",
      "lat": "39.1477621",
      "lon": "16.9149544",
      "superficie_kmq": "52.3117",
      "codice_sovracomunale": "101"
    },
    {
      "sigla_provincia": "KR",
      "codice_istat": "101023",
      "denominazione_ita_altra": "Savelli",
      "denominazione_ita": "Savelli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I468",
      "lat": "39.3122185",
      "lon": "16.7808124",
      "superficie_kmq": "48.9212",
      "codice_sovracomunale": "101"
    },
    {
      "sigla_provincia": "KR",
      "codice_istat": "101024",
      "denominazione_ita_altra": "Scandale",
      "denominazione_ita": "Scandale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I494",
      "lat": "39.1230570",
      "lon": "16.9585158",
      "superficie_kmq": "54.2595",
      "codice_sovracomunale": "101"
    },
    {
      "sigla_provincia": "KR",
      "codice_istat": "101025",
      "denominazione_ita_altra": "Strongoli",
      "denominazione_ita": "Strongoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I982",
      "lat": "39.2674219",
      "lon": "17.0527210",
      "superficie_kmq": "85.5577",
      "codice_sovracomunale": "101"
    },
    {
      "sigla_provincia": "KR",
      "codice_istat": "101026",
      "denominazione_ita_altra": "Umbriatico",
      "denominazione_ita": "Umbriatico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L492",
      "lat": "39.3527326",
      "lon": "16.9171788",
      "superficie_kmq": "73.3549",
      "codice_sovracomunale": "101"
    },
    {
      "sigla_provincia": "KR",
      "codice_istat": "101027",
      "denominazione_ita_altra": "Verzino",
      "denominazione_ita": "Verzino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L802",
      "lat": "39.3123987",
      "lon": "16.8616722",
      "superficie_kmq": "45.6322",
      "codice_sovracomunale": "101"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102001",
      "denominazione_ita_altra": "Acquaro",
      "denominazione_ita": "Acquaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A043",
      "lat": "38.5554914",
      "lon": "16.1899550",
      "superficie_kmq": "25.2495",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102002",
      "denominazione_ita_altra": "Arena",
      "denominazione_ita": "Arena",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A386",
      "lat": "38.5628663",
      "lon": "16.2099910",
      "superficie_kmq": "34.3219",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102003",
      "denominazione_ita_altra": "Briatico",
      "denominazione_ita": "Briatico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B169",
      "lat": "38.7243666",
      "lon": "16.0341465",
      "superficie_kmq": "27.9223",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102004",
      "denominazione_ita_altra": "Brognaturo",
      "denominazione_ita": "Brognaturo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B197",
      "lat": "38.6014411",
      "lon": "16.3424681",
      "superficie_kmq": "25.6890",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102005",
      "denominazione_ita_altra": "Capistrano",
      "denominazione_ita": "Capistrano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B655",
      "lat": "38.6905820",
      "lon": "16.2886835",
      "superficie_kmq": "21.1225",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102006",
      "denominazione_ita_altra": "Cessaniti",
      "denominazione_ita": "Cessaniti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C581",
      "lat": "38.6638635",
      "lon": "16.0295822",
      "superficie_kmq": "17.9691",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102007",
      "denominazione_ita_altra": "Dasà",
      "denominazione_ita": "Dasà",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D253",
      "lat": "38.5652783",
      "lon": "16.1964376",
      "superficie_kmq": "6.4627",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102008",
      "denominazione_ita_altra": "Dinami",
      "denominazione_ita": "Dinami",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D303",
      "lat": "38.5295467",
      "lon": "16.1476951",
      "superficie_kmq": "44.4447",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102009",
      "denominazione_ita_altra": "Drapia",
      "denominazione_ita": "Drapia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D364",
      "lat": "38.6660282",
      "lon": "15.9112149",
      "superficie_kmq": "21.5938",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102010",
      "denominazione_ita_altra": "Fabrizia",
      "denominazione_ita": "Fabrizia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D453",
      "lat": "38.4834860",
      "lon": "16.3030840",
      "superficie_kmq": "40.0030",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102011",
      "denominazione_ita_altra": "Filadelfia",
      "denominazione_ita": "Filadelfia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D587",
      "lat": "38.7834694",
      "lon": "16.2934256",
      "superficie_kmq": "31.5038",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102012",
      "denominazione_ita_altra": "Filandari",
      "denominazione_ita": "Filandari",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D589",
      "lat": "38.6153240",
      "lon": "16.0313758",
      "superficie_kmq": "18.8395",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102013",
      "denominazione_ita_altra": "Filogaso",
      "denominazione_ita": "Filogaso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D596",
      "lat": "38.6808272",
      "lon": "16.2308614",
      "superficie_kmq": "23.8975",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102014",
      "denominazione_ita_altra": "Francavilla Angitola",
      "denominazione_ita": "Francavilla Angitola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D762",
      "lat": "38.7779302",
      "lon": "16.2706572",
      "superficie_kmq": "28.6319",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102015",
      "denominazione_ita_altra": "Francica",
      "denominazione_ita": "Francica",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D767",
      "lat": "38.6182255",
      "lon": "16.1016828",
      "superficie_kmq": "22.7009",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102016",
      "denominazione_ita_altra": "Gerocarne",
      "denominazione_ita": "Gerocarne",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D988",
      "lat": "38.5870630",
      "lon": "16.2206777",
      "superficie_kmq": "45.2254",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102017",
      "denominazione_ita_altra": "Ionadi",
      "denominazione_ita": "Ionadi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E321",
      "lat": "38.6277287",
      "lon": "16.0544408",
      "superficie_kmq": "8.8113",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102018",
      "denominazione_ita_altra": "Joppolo",
      "denominazione_ita": "Joppolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E389",
      "lat": "38.5838179",
      "lon": "15.8974616",
      "superficie_kmq": "21.6692",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102019",
      "denominazione_ita_altra": "Limbadi",
      "denominazione_ita": "Limbadi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E590",
      "lat": "38.5587070",
      "lon": "15.9620169",
      "superficie_kmq": "29.1519",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102020",
      "denominazione_ita_altra": "Maierato",
      "denominazione_ita": "Maierato",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E836",
      "lat": "38.7069780",
      "lon": "16.1923254",
      "superficie_kmq": "39.9325",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102021",
      "denominazione_ita_altra": "Mileto",
      "denominazione_ita": "Mileto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F207",
      "lat": "38.6031248",
      "lon": "16.0598903",
      "superficie_kmq": "35.6532",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102022",
      "denominazione_ita_altra": "Mongiana",
      "denominazione_ita": "Mongiana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F364",
      "lat": "38.5133491",
      "lon": "16.3192324",
      "superficie_kmq": "18.4089",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102023",
      "denominazione_ita_altra": "Monterosso Calabro",
      "denominazione_ita": "Monterosso Calabro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F607",
      "lat": "38.7178442",
      "lon": "16.2873806",
      "superficie_kmq": "18.3653",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102024",
      "denominazione_ita_altra": "Nardodipace",
      "denominazione_ita": "Nardodipace",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F843",
      "lat": "38.4740890",
      "lon": "16.3429464",
      "superficie_kmq": "33.3029",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102025",
      "denominazione_ita_altra": "Nicotera",
      "denominazione_ita": "Nicotera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F893",
      "lat": "38.5434963",
      "lon": "15.9318901",
      "superficie_kmq": "28.2468",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102026",
      "denominazione_ita_altra": "Parghelia",
      "denominazione_ita": "Parghelia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G335",
      "lat": "38.6817479",
      "lon": "15.9242273",
      "superficie_kmq": "7.9491",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102027",
      "denominazione_ita_altra": "Pizzo",
      "denominazione_ita": "Pizzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G722",
      "lat": "38.7358176",
      "lon": "16.1615857",
      "superficie_kmq": "22.8879",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102028",
      "denominazione_ita_altra": "Pizzoni",
      "denominazione_ita": "Pizzoni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G728",
      "lat": "38.6233316",
      "lon": "16.2494825",
      "superficie_kmq": "21.7004",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102029",
      "denominazione_ita_altra": "Polia",
      "denominazione_ita": "Polia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G785",
      "lat": "38.7508329",
      "lon": "16.3140456",
      "superficie_kmq": "31.5057",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102030",
      "denominazione_ita_altra": "Ricadi",
      "denominazione_ita": "Ricadi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H271",
      "lat": "38.6241238",
      "lon": "15.8663825",
      "superficie_kmq": "22.5378",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102031",
      "denominazione_ita_altra": "Rombiolo",
      "denominazione_ita": "Rombiolo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H516",
      "lat": "38.5975282",
      "lon": "16.0015724",
      "superficie_kmq": "22.8427",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102032",
      "denominazione_ita_altra": "San Calogero",
      "denominazione_ita": "San Calogero",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H785",
      "lat": "38.5705722",
      "lon": "16.0132685",
      "superficie_kmq": "25.3367",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102033",
      "denominazione_ita_altra": "San Costantino Calabro",
      "denominazione_ita": "San Costantino Calabro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H807",
      "lat": "38.6333028",
      "lon": "16.0708070",
      "superficie_kmq": "6.7892",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102034",
      "denominazione_ita_altra": "San Gregorio d'Ippona",
      "denominazione_ita": "San Gregorio d'Ippona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H941",
      "lat": "38.6432154",
      "lon": "16.1017296",
      "superficie_kmq": "12.5345",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102035",
      "denominazione_ita_altra": "San Nicola da Crissa",
      "denominazione_ita": "San Nicola da Crissa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I058",
      "lat": "38.6642905",
      "lon": "16.2862375",
      "superficie_kmq": "19.3959",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102036",
      "denominazione_ita_altra": "Sant'Onofrio",
      "denominazione_ita": "Sant'Onofrio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I350",
      "lat": "38.6964331",
      "lon": "16.1428264",
      "superficie_kmq": "18.6586",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102037",
      "denominazione_ita_altra": "Serra San Bruno",
      "denominazione_ita": "Serra San Bruno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I639",
      "lat": "38.5734820",
      "lon": "16.3269373",
      "superficie_kmq": "40.5725",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102038",
      "denominazione_ita_altra": "Simbario",
      "denominazione_ita": "Simbario",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I744",
      "lat": "38.6107124",
      "lon": "16.3382588",
      "superficie_kmq": "20.8328",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102039",
      "denominazione_ita_altra": "Sorianello",
      "denominazione_ita": "Sorianello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I853",
      "lat": "38.5921562",
      "lon": "16.2328584",
      "superficie_kmq": "9.5926",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102040",
      "denominazione_ita_altra": "Soriano Calabro",
      "denominazione_ita": "Soriano Calabro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I854",
      "lat": "38.5963641",
      "lon": "16.2301018",
      "superficie_kmq": "15.4369",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102041",
      "denominazione_ita_altra": "Spadola",
      "denominazione_ita": "Spadola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I884",
      "lat": "38.6035531",
      "lon": "16.3365019",
      "superficie_kmq": "9.6462",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102042",
      "denominazione_ita_altra": "Spilinga",
      "denominazione_ita": "Spilinga",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I905",
      "lat": "38.6284627",
      "lon": "15.9058275",
      "superficie_kmq": "17.4235",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102043",
      "denominazione_ita_altra": "Stefanaconi",
      "denominazione_ita": "Stefanaconi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I945",
      "lat": "38.6761160",
      "lon": "16.1271644",
      "superficie_kmq": "23.1798",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102044",
      "denominazione_ita_altra": "Tropea",
      "denominazione_ita": "Tropea",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L452",
      "lat": "38.6785400",
      "lon": "15.8978837",
      "superficie_kmq": "3.6604",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102045",
      "denominazione_ita_altra": "Vallelonga",
      "denominazione_ita": "Vallelonga",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L607",
      "lat": "38.6468744",
      "lon": "16.2959770",
      "superficie_kmq": "17.6406",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102046",
      "denominazione_ita_altra": "Vazzano",
      "denominazione_ita": "Vazzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L699",
      "lat": "38.6334477",
      "lon": "16.2459890",
      "superficie_kmq": "20.2027",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102047",
      "denominazione_ita_altra": "Vibo Valentia",
      "denominazione_ita": "Vibo Valentia",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "F537",
      "lat": "38.6761528",
      "lon": "16.1015668",
      "superficie_kmq": "46.5709",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102048",
      "denominazione_ita_altra": "Zaccanopoli",
      "denominazione_ita": "Zaccanopoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M138",
      "lat": "38.6640098",
      "lon": "15.9306789",
      "superficie_kmq": "5.3751",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102049",
      "denominazione_ita_altra": "Zambrone",
      "denominazione_ita": "Zambrone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M143",
      "lat": "38.6986912",
      "lon": "15.9898127",
      "superficie_kmq": "15.7735",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VV",
      "codice_istat": "102050",
      "denominazione_ita_altra": "Zungri",
      "denominazione_ita": "Zungri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M204",
      "lat": "38.6566932",
      "lon": "15.9836271",
      "superficie_kmq": "23.4549",
      "codice_sovracomunale": "102"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103001",
      "denominazione_ita_altra": "Antrona Schieranco",
      "denominazione_ita": "Antrona Schieranco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A317",
      "lat": "46.0614271",
      "lon": "8.1150995",
      "superficie_kmq": "100.1815",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103002",
      "denominazione_ita_altra": "Anzola d'Ossola",
      "denominazione_ita": "Anzola d'Ossola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A325",
      "lat": "45.9894910",
      "lon": "8.3474609",
      "superficie_kmq": "13.6569",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103003",
      "denominazione_ita_altra": "Arizzano",
      "denominazione_ita": "Arizzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A409",
      "lat": "45.9556198",
      "lon": "8.5831089",
      "superficie_kmq": "1.5995",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103004",
      "denominazione_ita_altra": "Arola",
      "denominazione_ita": "Arola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A427",
      "lat": "45.8095455",
      "lon": "8.3587581",
      "superficie_kmq": "6.6126",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103005",
      "denominazione_ita_altra": "Aurano",
      "denominazione_ita": "Aurano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A497",
      "lat": "45.9987237",
      "lon": "8.5879951",
      "superficie_kmq": "21.1563",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103006",
      "denominazione_ita_altra": "Baceno",
      "denominazione_ita": "Baceno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A534",
      "lat": "46.2615084",
      "lon": "8.3194837",
      "superficie_kmq": "77.2735",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103007",
      "denominazione_ita_altra": "Bannio Anzino",
      "denominazione_ita": "Bannio Anzino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A610",
      "lat": "45.9844783",
      "lon": "8.1461873",
      "superficie_kmq": "39.4656",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103008",
      "denominazione_ita_altra": "Baveno",
      "denominazione_ita": "Baveno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A725",
      "lat": "45.9089304",
      "lon": "8.5048327",
      "superficie_kmq": "17.1002",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103009",
      "denominazione_ita_altra": "Bee",
      "denominazione_ita": "Bee",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A733",
      "lat": "45.9627376",
      "lon": "8.5802311",
      "superficie_kmq": "3.5046",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103010",
      "denominazione_ita_altra": "Belgirate",
      "denominazione_ita": "Belgirate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A742",
      "lat": "45.8383609",
      "lon": "8.5715879",
      "superficie_kmq": "7.1292",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103011",
      "denominazione_ita_altra": "Beura-Cardezza",
      "denominazione_ita": "Beura-Cardezza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A834",
      "lat": "46.0788949",
      "lon": "8.2993364",
      "superficie_kmq": "28.5514",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103012",
      "denominazione_ita_altra": "Bognanco",
      "denominazione_ita": "Bognanco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A925",
      "lat": "46.1227956",
      "lon": "8.2063952",
      "superficie_kmq": "57.9948",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103013",
      "denominazione_ita_altra": "Brovello-Carpugnino",
      "denominazione_ita": "Brovello-Carpugnino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B207",
      "lat": "45.8427550",
      "lon": "8.5331948",
      "superficie_kmq": "8.2233",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103014",
      "denominazione_ita_altra": "Calasca-Castiglione",
      "denominazione_ita": "Calasca-Castiglione",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B380",
      "lat": "46.0038367",
      "lon": "8.1702429",
      "superficie_kmq": "57.0676",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103015",
      "denominazione_ita_altra": "Cambiasca",
      "denominazione_ita": "Cambiasca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B463",
      "lat": "45.9643060",
      "lon": "8.5411319",
      "superficie_kmq": "3.9645",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103016",
      "denominazione_ita_altra": "Cannero Riviera",
      "denominazione_ita": "Cannero Riviera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B610",
      "lat": "46.0220744",
      "lon": "8.6793313",
      "superficie_kmq": "14.4193",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103017",
      "denominazione_ita_altra": "Cannobio",
      "denominazione_ita": "Cannobio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B615",
      "lat": "46.0617344",
      "lon": "8.7001927",
      "superficie_kmq": "52.5335",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103018",
      "denominazione_ita_altra": "Caprezzo",
      "denominazione_ita": "Caprezzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B694",
      "lat": "45.9806676",
      "lon": "8.5612856",
      "superficie_kmq": "7.2565",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103019",
      "denominazione_ita_altra": "Casale Corte Cerro",
      "denominazione_ita": "Casale Corte Cerro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B876",
      "lat": "45.9167894",
      "lon": "8.4150551",
      "superficie_kmq": "12.5227",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103021",
      "denominazione_ita_altra": "Ceppo Morelli",
      "denominazione_ita": "Ceppo Morelli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C478",
      "lat": "45.9713805",
      "lon": "8.0667094",
      "superficie_kmq": "40.1858",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103022",
      "denominazione_ita_altra": "Cesara",
      "denominazione_ita": "Cesara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C567",
      "lat": "45.8351078",
      "lon": "8.3659338",
      "superficie_kmq": "11.6545",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103023",
      "denominazione_ita_altra": "Cossogno",
      "denominazione_ita": "Cossogno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D099",
      "lat": "45.9639938",
      "lon": "8.5100243",
      "superficie_kmq": "40.2573",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103024",
      "denominazione_ita_altra": "Craveggia",
      "denominazione_ita": "Craveggia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D134",
      "lat": "46.1406507",
      "lon": "8.4897857",
      "superficie_kmq": "36.2167",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103025",
      "denominazione_ita_altra": "Crevoladossola",
      "denominazione_ita": "Crevoladossola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D168",
      "lat": "46.1560584",
      "lon": "8.3035773",
      "superficie_kmq": "39.8738",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103026",
      "denominazione_ita_altra": "Crodo",
      "denominazione_ita": "Crodo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D177",
      "lat": "46.2234753",
      "lon": "8.3234113",
      "superficie_kmq": "53.5753",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103028",
      "denominazione_ita_altra": "Domodossola",
      "denominazione_ita": "Domodossola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D332",
      "lat": "46.1153096",
      "lon": "8.2920422",
      "superficie_kmq": "36.8911",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103029",
      "denominazione_ita_altra": "Druogno",
      "denominazione_ita": "Druogno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D374",
      "lat": "46.1353644",
      "lon": "8.4318072",
      "superficie_kmq": "29.6091",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103031",
      "denominazione_ita_altra": "Formazza",
      "denominazione_ita": "Formazza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D706",
      "lat": "46.3767467",
      "lon": "8.4267294",
      "superficie_kmq": "130.6467",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103032",
      "denominazione_ita_altra": "Germagno",
      "denominazione_ita": "Germagno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D984",
      "lat": "45.8924866",
      "lon": "8.3879633",
      "superficie_kmq": "2.8976",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103033",
      "denominazione_ita_altra": "Ghiffa",
      "denominazione_ita": "Ghiffa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E003",
      "lat": "45.9563626",
      "lon": "8.6170738",
      "superficie_kmq": "14.6546",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103034",
      "denominazione_ita_altra": "Gignese",
      "denominazione_ita": "Gignese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E028",
      "lat": "45.8605858",
      "lon": "8.5098910",
      "superficie_kmq": "14.5784",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103035",
      "denominazione_ita_altra": "Gravellona Toce",
      "denominazione_ita": "Gravellona Toce",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E153",
      "lat": "45.9276017",
      "lon": "8.4314215",
      "superficie_kmq": "14.2127",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103036",
      "denominazione_ita_altra": "Gurro",
      "denominazione_ita": "Gurro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E269",
      "lat": "46.0859738",
      "lon": "8.5701145",
      "superficie_kmq": "13.2848",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103037",
      "denominazione_ita_altra": "Intragna",
      "denominazione_ita": "Intragna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E304",
      "lat": "45.9953110",
      "lon": "8.5763440",
      "superficie_kmq": "9.9219",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103038",
      "denominazione_ita_altra": "Loreglia",
      "denominazione_ita": "Loreglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E685",
      "lat": "45.9063761",
      "lon": "8.3710295",
      "superficie_kmq": "9.1531",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103039",
      "denominazione_ita_altra": "Macugnaga",
      "denominazione_ita": "Macugnaga",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E790",
      "lat": "45.9686455",
      "lon": "7.9678173",
      "superficie_kmq": "99.5680",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103040",
      "denominazione_ita_altra": "Madonna del Sasso",
      "denominazione_ita": "Madonna del Sasso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E795",
      "lat": "45.7923555",
      "lon": "8.3681379",
      "superficie_kmq": "15.4114",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103041",
      "denominazione_ita_altra": "Malesco",
      "denominazione_ita": "Malesco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E853",
      "lat": "46.1266674",
      "lon": "8.5003587",
      "superficie_kmq": "43.1763",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103042",
      "denominazione_ita_altra": "Masera",
      "denominazione_ita": "Masera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F010",
      "lat": "46.1369860",
      "lon": "8.3264764",
      "superficie_kmq": "20.3537",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103043",
      "denominazione_ita_altra": "Massiola",
      "denominazione_ita": "Massiola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F048",
      "lat": "45.9122507",
      "lon": "8.3196804",
      "superficie_kmq": "8.0549",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103044",
      "denominazione_ita_altra": "Mergozzo",
      "denominazione_ita": "Mergozzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F146",
      "lat": "45.9609939",
      "lon": "8.4529325",
      "superficie_kmq": "26.9947",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103045",
      "denominazione_ita_altra": "Miazzina",
      "denominazione_ita": "Miazzina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F192",
      "lat": "45.9752321",
      "lon": "8.5239177",
      "superficie_kmq": "21.1750",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103046",
      "denominazione_ita_altra": "Montecrestese",
      "denominazione_ita": "Montecrestese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F483",
      "lat": "46.1654942",
      "lon": "8.3264836",
      "superficie_kmq": "86.1483",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103047",
      "denominazione_ita_altra": "Montescheno",
      "denominazione_ita": "Montescheno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F639",
      "lat": "46.0666167",
      "lon": "8.2329065",
      "superficie_kmq": "22.1708",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103048",
      "denominazione_ita_altra": "Nonio",
      "denominazione_ita": "Nonio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F932",
      "lat": "45.8450705",
      "lon": "8.3767951",
      "superficie_kmq": "9.7997",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103049",
      "denominazione_ita_altra": "Oggebbio",
      "denominazione_ita": "Oggebbio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G007",
      "lat": "45.9969278",
      "lon": "8.6528753",
      "superficie_kmq": "21.4390",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103050",
      "denominazione_ita_altra": "Omegna",
      "denominazione_ita": "Omegna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G062",
      "lat": "45.8756277",
      "lon": "8.4089282",
      "superficie_kmq": "30.3719",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103051",
      "denominazione_ita_altra": "Ornavasso",
      "denominazione_ita": "Ornavasso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G117",
      "lat": "45.9692058",
      "lon": "8.4157734",
      "superficie_kmq": "25.9194",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103052",
      "denominazione_ita_altra": "Pallanzeno",
      "denominazione_ita": "Pallanzeno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G280",
      "lat": "46.0434824",
      "lon": "8.2578588",
      "superficie_kmq": "4.3658",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103053",
      "denominazione_ita_altra": "Piedimulera",
      "denominazione_ita": "Piedimulera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G600",
      "lat": "46.0271082",
      "lon": "8.2598102",
      "superficie_kmq": "7.5656",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103054",
      "denominazione_ita_altra": "Pieve Vergonte",
      "denominazione_ita": "Pieve Vergonte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G658",
      "lat": "46.0055697",
      "lon": "8.2684859",
      "superficie_kmq": "41.6720",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103055",
      "denominazione_ita_altra": "Premeno",
      "denominazione_ita": "Premeno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H030",
      "lat": "45.9771360",
      "lon": "8.5914628",
      "superficie_kmq": "7.8796",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103056",
      "denominazione_ita_altra": "Premia",
      "denominazione_ita": "Premia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H033",
      "lat": "46.2688543",
      "lon": "8.3395321",
      "superficie_kmq": "88.9024",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103057",
      "denominazione_ita_altra": "Premosello-Chiovenda",
      "denominazione_ita": "Premosello-Chiovenda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H037",
      "lat": "46.0027486",
      "lon": "8.3294231",
      "superficie_kmq": "34.1628",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103058",
      "denominazione_ita_altra": "Quarna Sopra",
      "denominazione_ita": "Quarna Sopra",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H106",
      "lat": "45.8730578",
      "lon": "8.3722386",
      "superficie_kmq": "9.3948",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103059",
      "denominazione_ita_altra": "Quarna Sotto",
      "denominazione_ita": "Quarna Sotto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H107",
      "lat": "45.8682394",
      "lon": "8.3638816",
      "superficie_kmq": "16.3739",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103060",
      "denominazione_ita_altra": "Re",
      "denominazione_ita": "Re",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H203",
      "lat": "46.1305711",
      "lon": "8.5443725",
      "superficie_kmq": "27.1522",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103061",
      "denominazione_ita_altra": "San Bernardino Verbano",
      "denominazione_ita": "San Bernardino Verbano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H777",
      "lat": "45.9568073",
      "lon": "8.5201786",
      "superficie_kmq": "26.6756",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103062",
      "denominazione_ita_altra": "Santa Maria Maggiore",
      "denominazione_ita": "Santa Maria Maggiore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I249",
      "lat": "46.1350695",
      "lon": "8.4668701",
      "superficie_kmq": "53.7054",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103064",
      "denominazione_ita_altra": "Stresa",
      "denominazione_ita": "Stresa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I976",
      "lat": "45.8839001",
      "lon": "8.5407310",
      "superficie_kmq": "35.3592",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103065",
      "denominazione_ita_altra": "Toceno",
      "denominazione_ita": "Toceno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L187",
      "lat": "46.1434588",
      "lon": "8.4675593",
      "superficie_kmq": "15.7687",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103066",
      "denominazione_ita_altra": "Trarego Viggiona",
      "denominazione_ita": "Trarego Viggiona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L333",
      "lat": "46.0339071",
      "lon": "8.6639920",
      "superficie_kmq": "18.9006",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103067",
      "denominazione_ita_altra": "Trasquera",
      "denominazione_ita": "Trasquera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L336",
      "lat": "46.2124969",
      "lon": "8.2140018",
      "superficie_kmq": "39.6013",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103068",
      "denominazione_ita_altra": "Trontano",
      "denominazione_ita": "Trontano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L450",
      "lat": "46.1222623",
      "lon": "8.3344822",
      "superficie_kmq": "56.7344",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103069",
      "denominazione_ita_altra": "Valstrona",
      "denominazione_ita": "Valstrona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L651",
      "lat": "45.9073024",
      "lon": "8.3408185",
      "superficie_kmq": "51.8893",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103070",
      "denominazione_ita_altra": "Vanzone con San Carlo",
      "denominazione_ita": "Vanzone con San Carlo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L666",
      "lat": "45.9799804",
      "lon": "8.1103610",
      "superficie_kmq": "15.7344",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103071",
      "denominazione_ita_altra": "Varzo",
      "denominazione_ita": "Varzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L691",
      "lat": "46.2067422",
      "lon": "8.2554792",
      "superficie_kmq": "93.7665",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103072",
      "denominazione_ita_altra": "Verbania",
      "denominazione_ita": "Verbania",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "L746",
      "lat": "45.9214574",
      "lon": "8.5510787",
      "superficie_kmq": "37.4934",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103074",
      "denominazione_ita_altra": "Vignone",
      "denominazione_ita": "Vignone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L889",
      "lat": "45.9604484",
      "lon": "8.5663943",
      "superficie_kmq": "3.3806",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103075",
      "denominazione_ita_altra": "Villadossola",
      "denominazione_ita": "Villadossola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L906",
      "lat": "46.0677274",
      "lon": "8.2601325",
      "superficie_kmq": "18.7332",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103076",
      "denominazione_ita_altra": "Villette",
      "denominazione_ita": "Villette",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M042",
      "lat": "46.1313074",
      "lon": "8.5340765",
      "superficie_kmq": "7.3834",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103077",
      "denominazione_ita_altra": "Vogogna",
      "denominazione_ita": "Vogogna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M111",
      "lat": "46.0078467",
      "lon": "8.2954852",
      "superficie_kmq": "15.6245",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103078",
      "denominazione_ita_altra": "Borgomezzavalle",
      "denominazione_ita": "Borgomezzavalle",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M370",
      "lat": "46.0470825",
      "lon": "8.1882525",
      "superficie_kmq": "19.0831",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "VB",
      "codice_istat": "103079",
      "denominazione_ita_altra": "Valle Cannobina",
      "denominazione_ita": "Valle Cannobina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M404",
      "lat": "46.0851824",
      "lon": "8.5808222",
      "superficie_kmq": "55.1741",
      "codice_sovracomunale": "103"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108001",
      "denominazione_ita_altra": "Agrate Brianza",
      "denominazione_ita": "Agrate Brianza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A087",
      "lat": "45.5754636",
      "lon": "9.3517641",
      "superficie_kmq": "11.2176",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108002",
      "denominazione_ita_altra": "Aicurzio",
      "denominazione_ita": "Aicurzio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A096",
      "lat": "45.6407083",
      "lon": "9.4110373",
      "superficie_kmq": "2.4676",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108003",
      "denominazione_ita_altra": "Albiate",
      "denominazione_ita": "Albiate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A159",
      "lat": "45.6567015",
      "lon": "9.2613400",
      "superficie_kmq": "2.8631",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108004",
      "denominazione_ita_altra": "Arcore",
      "denominazione_ita": "Arcore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A376",
      "lat": "45.6269745",
      "lon": "9.3210811",
      "superficie_kmq": "9.2455",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108005",
      "denominazione_ita_altra": "Barlassina",
      "denominazione_ita": "Barlassina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A668",
      "lat": "45.6561250",
      "lon": "9.1305081",
      "superficie_kmq": "2.7560",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108006",
      "denominazione_ita_altra": "Bellusco",
      "denominazione_ita": "Bellusco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A759",
      "lat": "45.6185699",
      "lon": "9.4177921",
      "superficie_kmq": "6.5372",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108007",
      "denominazione_ita_altra": "Bernareggio",
      "denominazione_ita": "Bernareggio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A802",
      "lat": "45.6500413",
      "lon": "9.4056753",
      "superficie_kmq": "5.9338",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108008",
      "denominazione_ita_altra": "Besana in Brianza",
      "denominazione_ita": "Besana in Brianza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A818",
      "lat": "45.7007697",
      "lon": "9.2885111",
      "superficie_kmq": "15.7578",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108009",
      "denominazione_ita_altra": "Biassono",
      "denominazione_ita": "Biassono",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A849",
      "lat": "45.6285750",
      "lon": "9.2762308",
      "superficie_kmq": "4.8948",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108010",
      "denominazione_ita_altra": "Bovisio-Masciago",
      "denominazione_ita": "Bovisio-Masciago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B105",
      "lat": "45.6111006",
      "lon": "9.1486620",
      "superficie_kmq": "4.9323",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108011",
      "denominazione_ita_altra": "Briosco",
      "denominazione_ita": "Briosco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B187",
      "lat": "45.7093014",
      "lon": "9.2371969",
      "superficie_kmq": "6.6128",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108012",
      "denominazione_ita_altra": "Brugherio",
      "denominazione_ita": "Brugherio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B212",
      "lat": "45.5510368",
      "lon": "9.3000603",
      "superficie_kmq": "10.4121",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108013",
      "denominazione_ita_altra": "Burago di Molgora",
      "denominazione_ita": "Burago di Molgora",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B272",
      "lat": "45.5959286",
      "lon": "9.3790694",
      "superficie_kmq": "3.4317",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108014",
      "denominazione_ita_altra": "Camparada",
      "denominazione_ita": "Camparada",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B501",
      "lat": "45.6550033",
      "lon": "9.3223784",
      "superficie_kmq": "1.6337",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108015",
      "denominazione_ita_altra": "Carate Brianza",
      "denominazione_ita": "Carate Brianza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B729",
      "lat": "45.6770606",
      "lon": "9.2331221",
      "superficie_kmq": "9.9166",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108016",
      "denominazione_ita_altra": "Carnate",
      "denominazione_ita": "Carnate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B798",
      "lat": "45.6510728",
      "lon": "9.3783364",
      "superficie_kmq": "3.4702",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108017",
      "denominazione_ita_altra": "Cavenago di Brianza",
      "denominazione_ita": "Cavenago di Brianza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C395",
      "lat": "45.5853025",
      "lon": "9.4148772",
      "superficie_kmq": "4.3932",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108018",
      "denominazione_ita_altra": "Ceriano Laghetto",
      "denominazione_ita": "Ceriano Laghetto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C512",
      "lat": "45.6272005",
      "lon": "9.0790679",
      "superficie_kmq": "7.0780",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108019",
      "denominazione_ita_altra": "Cesano Maderno",
      "denominazione_ita": "Cesano Maderno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C566",
      "lat": "45.6264876",
      "lon": "9.1480286",
      "superficie_kmq": "11.5157",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108020",
      "denominazione_ita_altra": "Cogliate",
      "denominazione_ita": "Cogliate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C820",
      "lat": "45.6457609",
      "lon": "9.0818465",
      "superficie_kmq": "6.9549",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108021",
      "denominazione_ita_altra": "Concorezzo",
      "denominazione_ita": "Concorezzo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C952",
      "lat": "45.5910629",
      "lon": "9.3386580",
      "superficie_kmq": "8.5081",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108022",
      "denominazione_ita_altra": "Correzzana",
      "denominazione_ita": "Correzzana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D038",
      "lat": "45.6678626",
      "lon": "9.3044636",
      "superficie_kmq": "2.5060",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108023",
      "denominazione_ita_altra": "Desio",
      "denominazione_ita": "Desio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D286",
      "lat": "45.6201241",
      "lon": "9.2088266",
      "superficie_kmq": "14.7540",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108024",
      "denominazione_ita_altra": "Giussano",
      "denominazione_ita": "Giussano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E063",
      "lat": "45.6914293",
      "lon": "9.2049300",
      "superficie_kmq": "10.2805",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108025",
      "denominazione_ita_altra": "Lazzate",
      "denominazione_ita": "Lazzate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E504",
      "lat": "45.6687841",
      "lon": "9.0841894",
      "superficie_kmq": "5.3139",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108026",
      "denominazione_ita_altra": "Lesmo",
      "denominazione_ita": "Lesmo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E550",
      "lat": "45.6463895",
      "lon": "9.3024806",
      "superficie_kmq": "5.1171",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108027",
      "denominazione_ita_altra": "Limbiate",
      "denominazione_ita": "Limbiate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E591",
      "lat": "45.6050410",
      "lon": "9.1230275",
      "superficie_kmq": "12.2860",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108028",
      "denominazione_ita_altra": "Lissone",
      "denominazione_ita": "Lissone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E617",
      "lat": "45.6105418",
      "lon": "9.2438562",
      "superficie_kmq": "9.2958",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108029",
      "denominazione_ita_altra": "Macherio",
      "denominazione_ita": "Macherio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E786",
      "lat": "45.6413139",
      "lon": "9.2712061",
      "superficie_kmq": "3.1826",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108030",
      "denominazione_ita_altra": "Meda",
      "denominazione_ita": "Meda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F078",
      "lat": "45.6615589",
      "lon": "9.1552722",
      "superficie_kmq": "8.3083",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108031",
      "denominazione_ita_altra": "Mezzago",
      "denominazione_ita": "Mezzago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F165",
      "lat": "45.6259780",
      "lon": "9.4394806",
      "superficie_kmq": "4.3185",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108032",
      "denominazione_ita_altra": "Misinto",
      "denominazione_ita": "Misinto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F247",
      "lat": "45.6646391",
      "lon": "9.0774750",
      "superficie_kmq": "5.1093",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108033",
      "denominazione_ita_altra": "Monza",
      "denominazione_ita": "Monza",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "F704",
      "lat": "45.5843974",
      "lon": "9.2735825",
      "superficie_kmq": "33.0867",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108034",
      "denominazione_ita_altra": "Muggiò",
      "denominazione_ita": "Muggiò",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F797",
      "lat": "45.5936711",
      "lon": "9.2295601",
      "superficie_kmq": "5.4764",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108035",
      "denominazione_ita_altra": "Nova Milanese",
      "denominazione_ita": "Nova Milanese",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F944",
      "lat": "45.5869933",
      "lon": "9.2017170",
      "superficie_kmq": "5.8456",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108036",
      "denominazione_ita_altra": "Ornago",
      "denominazione_ita": "Ornago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G116",
      "lat": "45.5983291",
      "lon": "9.4220095",
      "superficie_kmq": "5.8844",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108037",
      "denominazione_ita_altra": "Renate",
      "denominazione_ita": "Renate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H233",
      "lat": "45.7226699",
      "lon": "9.2846741",
      "superficie_kmq": "2.8878",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108038",
      "denominazione_ita_altra": "Ronco Briantino",
      "denominazione_ita": "Ronco Briantino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H537",
      "lat": "45.6677832",
      "lon": "9.4007212",
      "superficie_kmq": "2.9685",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108039",
      "denominazione_ita_altra": "Seregno",
      "denominazione_ita": "Seregno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I625",
      "lat": "45.6502205",
      "lon": "9.2017660",
      "superficie_kmq": "13.0449",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108040",
      "denominazione_ita_altra": "Seveso",
      "denominazione_ita": "Seveso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I709",
      "lat": "45.6473597",
      "lon": "9.1385122",
      "superficie_kmq": "7.4024",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108041",
      "denominazione_ita_altra": "Sovico",
      "denominazione_ita": "Sovico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I878",
      "lat": "45.6493697",
      "lon": "9.2648947",
      "superficie_kmq": "3.2630",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108042",
      "denominazione_ita_altra": "Sulbiate",
      "denominazione_ita": "Sulbiate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I998",
      "lat": "45.6348345",
      "lon": "9.4214832",
      "superficie_kmq": "5.2018",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108043",
      "denominazione_ita_altra": "Triuggio",
      "denominazione_ita": "Triuggio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L434",
      "lat": "45.6592025",
      "lon": "9.2644511",
      "superficie_kmq": "8.3378",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108044",
      "denominazione_ita_altra": "Usmate Velate",
      "denominazione_ita": "Usmate Velate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L511",
      "lat": "45.6506726",
      "lon": "9.3614608",
      "superficie_kmq": "9.7508",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108045",
      "denominazione_ita_altra": "Varedo",
      "denominazione_ita": "Varedo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L677",
      "lat": "45.5980559",
      "lon": "9.1570648",
      "superficie_kmq": "4.8480",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108046",
      "denominazione_ita_altra": "Vedano al Lambro",
      "denominazione_ita": "Vedano al Lambro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L704",
      "lat": "45.6095353",
      "lon": "9.2728890",
      "superficie_kmq": "1.9793",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108047",
      "denominazione_ita_altra": "Veduggio con Colzano",
      "denominazione_ita": "Veduggio con Colzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L709",
      "lat": "45.7358021",
      "lon": "9.2676126",
      "superficie_kmq": "3.5554",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108048",
      "denominazione_ita_altra": "Verano Brianza",
      "denominazione_ita": "Verano Brianza",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L744",
      "lat": "45.6846601",
      "lon": "9.2296085",
      "superficie_kmq": "3.5184",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108049",
      "denominazione_ita_altra": "Villasanta",
      "denominazione_ita": "Villasanta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M017",
      "lat": "45.6042805",
      "lon": "9.3019524",
      "superficie_kmq": "4.8648",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108050",
      "denominazione_ita_altra": "Vimercate",
      "denominazione_ita": "Vimercate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M052",
      "lat": "45.6131733",
      "lon": "9.3697491",
      "superficie_kmq": "20.7181",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108051",
      "denominazione_ita_altra": "Busnago",
      "denominazione_ita": "Busnago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B289",
      "lat": "45.6171804",
      "lon": "9.4661925",
      "superficie_kmq": "5.7763",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108052",
      "denominazione_ita_altra": "Caponago",
      "denominazione_ita": "Caponago",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B671",
      "lat": "45.5653201",
      "lon": "9.3770330",
      "superficie_kmq": "5.0384",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108053",
      "denominazione_ita_altra": "Cornate d'Adda",
      "denominazione_ita": "Cornate d'Adda",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D019",
      "lat": "45.6487676",
      "lon": "9.4638870",
      "superficie_kmq": "13.8153",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108054",
      "denominazione_ita_altra": "Lentate sul Seveso",
      "denominazione_ita": "Lentate sul Seveso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E530",
      "lat": "45.6782259",
      "lon": "9.1192427",
      "superficie_kmq": "13.9782",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "MB",
      "codice_istat": "108055",
      "denominazione_ita_altra": "Roncello",
      "denominazione_ita": "Roncello",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H529",
      "lat": "45.6048874",
      "lon": "9.4569624",
      "superficie_kmq": "3.1611",
      "codice_sovracomunale": "108"
    },
    {
      "sigla_provincia": "FM",
      "codice_istat": "109001",
      "denominazione_ita_altra": "Altidona",
      "denominazione_ita": "Altidona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A233",
      "lat": "43.1066923",
      "lon": "13.7944080",
      "superficie_kmq": "12.9725",
      "codice_sovracomunale": "109"
    },
    {
      "sigla_provincia": "FM",
      "codice_istat": "109002",
      "denominazione_ita_altra": "Amandola",
      "denominazione_ita": "Amandola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A252",
      "lat": "42.9803980",
      "lon": "13.3557101",
      "superficie_kmq": "69.4940",
      "codice_sovracomunale": "109"
    },
    {
      "sigla_provincia": "FM",
      "codice_istat": "109003",
      "denominazione_ita_altra": "Belmonte Piceno",
      "denominazione_ita": "Belmonte Piceno",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A760",
      "lat": "43.0914934",
      "lon": "13.5388213",
      "superficie_kmq": "10.5319",
      "codice_sovracomunale": "109"
    },
    {
      "sigla_provincia": "FM",
      "codice_istat": "109004",
      "denominazione_ita_altra": "Campofilone",
      "denominazione_ita": "Campofilone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B534",
      "lat": "43.0793004",
      "lon": "13.8145364",
      "superficie_kmq": "12.2112",
      "codice_sovracomunale": "109"
    },
    {
      "sigla_provincia": "FM",
      "codice_istat": "109005",
      "denominazione_ita_altra": "Falerone",
      "denominazione_ita": "Falerone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D477",
      "lat": "43.1078082",
      "lon": "13.4739075",
      "superficie_kmq": "24.6124",
      "codice_sovracomunale": "109"
    },
    {
      "sigla_provincia": "FM",
      "codice_istat": "109006",
      "denominazione_ita_altra": "Fermo",
      "denominazione_ita": "Fermo",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "D542",
      "lat": "43.1605580",
      "lon": "13.7183933",
      "superficie_kmq": "124.5320",
      "codice_sovracomunale": "109"
    },
    {
      "sigla_provincia": "FM",
      "codice_istat": "109007",
      "denominazione_ita_altra": "Francavilla d'Ete",
      "denominazione_ita": "Francavilla d'Ete",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D760",
      "lat": "43.1909745",
      "lon": "13.5399486",
      "superficie_kmq": "10.2041",
      "codice_sovracomunale": "109"
    },
    {
      "sigla_provincia": "FM",
      "codice_istat": "109008",
      "denominazione_ita_altra": "Grottazzolina",
      "denominazione_ita": "Grottazzolina",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E208",
      "lat": "43.1109671",
      "lon": "13.6010084",
      "superficie_kmq": "9.2618",
      "codice_sovracomunale": "109"
    },
    {
      "sigla_provincia": "FM",
      "codice_istat": "109009",
      "denominazione_ita_altra": "Lapedona",
      "denominazione_ita": "Lapedona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E447",
      "lat": "43.1097211",
      "lon": "13.7721495",
      "superficie_kmq": "14.9249",
      "codice_sovracomunale": "109"
    },
    {
      "sigla_provincia": "FM",
      "codice_istat": "109010",
      "denominazione_ita_altra": "Magliano di Tenna",
      "denominazione_ita": "Magliano di Tenna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E807",
      "lat": "43.1385566",
      "lon": "13.5862170",
      "superficie_kmq": "7.9294",
      "codice_sovracomunale": "109"
    },
    {
      "sigla_provincia": "FM",
      "codice_istat": "109011",
      "denominazione_ita_altra": "Massa Fermana",
      "denominazione_ita": "Massa Fermana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F021",
      "lat": "43.1503891",
      "lon": "13.4756382",
      "superficie_kmq": "7.7274",
      "codice_sovracomunale": "109"
    },
    {
      "sigla_provincia": "FM",
      "codice_istat": "109012",
      "denominazione_ita_altra": "Monsampietro Morico",
      "denominazione_ita": "Monsampietro Morico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F379",
      "lat": "43.0676416",
      "lon": "13.5558420",
      "superficie_kmq": "9.7625",
      "codice_sovracomunale": "109"
    },
    {
      "sigla_provincia": "FM",
      "codice_istat": "109013",
      "denominazione_ita_altra": "Montappone",
      "denominazione_ita": "Montappone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F428",
      "lat": "43.1354822",
      "lon": "13.4693551",
      "superficie_kmq": "10.4054",
      "codice_sovracomunale": "109"
    },
    {
      "sigla_provincia": "FM",
      "codice_istat": "109014",
      "denominazione_ita_altra": "Montefalcone Appennino",
      "denominazione_ita": "Montefalcone Appennino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F493",
      "lat": "42.9876710",
      "lon": "13.4595656",
      "superficie_kmq": "15.9920",
      "codice_sovracomunale": "109"
    },
    {
      "sigla_provincia": "FM",
      "codice_istat": "109015",
      "denominazione_ita_altra": "Montefortino",
      "denominazione_ita": "Montefortino",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F509",
      "lat": "42.9426141",
      "lon": "13.3412338",
      "superficie_kmq": "78.6156",
      "codice_sovracomunale": "109"
    },
    {
      "sigla_provincia": "FM",
      "codice_istat": "109016",
      "denominazione_ita_altra": "Monte Giberto",
      "denominazione_ita": "Monte Giberto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F517",
      "lat": "43.0912525",
      "lon": "13.6308586",
      "superficie_kmq": "12.5337",
      "codice_sovracomunale": "109"
    },
    {
      "sigla_provincia": "FM",
      "codice_istat": "109017",
      "denominazione_ita_altra": "Montegiorgio",
      "denominazione_ita": "Montegiorgio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F520",
      "lat": "43.1300034",
      "lon": "13.5377838",
      "superficie_kmq": "47.4458",
      "codice_sovracomunale": "109"
    },
    {
      "sigla_provincia": "FM",
      "codice_istat": "109018",
      "denominazione_ita_altra": "Montegranaro",
      "denominazione_ita": "Montegranaro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F522",
      "lat": "43.2333628",
      "lon": "13.6325961",
      "superficie_kmq": "31.4183",
      "codice_sovracomunale": "109"
    },
    {
      "sigla_provincia": "FM",
      "codice_istat": "109019",
      "denominazione_ita_altra": "Monteleone di Fermo",
      "denominazione_ita": "Monteleone di Fermo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F536",
      "lat": "43.0473977",
      "lon": "13.5288098",
      "superficie_kmq": "8.2142",
      "codice_sovracomunale": "109"
    },
    {
      "sigla_provincia": "FM",
      "codice_istat": "109020",
      "denominazione_ita_altra": "Montelparo",
      "denominazione_ita": "Montelparo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F549",
      "lat": "43.0179714",
      "lon": "13.5353676",
      "superficie_kmq": "21.6262",
      "codice_sovracomunale": "109"
    },
    {
      "sigla_provincia": "FM",
      "codice_istat": "109021",
      "denominazione_ita_altra": "Monte Rinaldo",
      "denominazione_ita": "Monte Rinaldo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F599",
      "lat": "43.0283339",
      "lon": "13.5809039",
      "superficie_kmq": "7.9216",
      "codice_sovracomunale": "109"
    },
    {
      "sigla_provincia": "FM",
      "codice_istat": "109022",
      "denominazione_ita_altra": "Monterubbiano",
      "denominazione_ita": "Monterubbiano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F614",
      "lat": "43.0853802",
      "lon": "13.7164312",
      "superficie_kmq": "32.2351",
      "codice_sovracomunale": "109"
    },
    {
      "sigla_provincia": "FM",
      "codice_istat": "109023",
      "denominazione_ita_altra": "Monte San Pietrangeli",
      "denominazione_ita": "Monte San Pietrangeli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F626",
      "lat": "43.1917666",
      "lon": "13.5796040",
      "superficie_kmq": "18.4509",
      "codice_sovracomunale": "109"
    },
    {
      "sigla_provincia": "FM",
      "codice_istat": "109024",
      "denominazione_ita_altra": "Monte Urano",
      "denominazione_ita": "Monte Urano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F653",
      "lat": "43.2061911",
      "lon": "13.6711127",
      "superficie_kmq": "16.7156",
      "codice_sovracomunale": "109"
    },
    {
      "sigla_provincia": "FM",
      "codice_istat": "109025",
      "denominazione_ita_altra": "Monte Vidon Combatte",
      "denominazione_ita": "Monte Vidon Combatte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F664",
      "lat": "43.0496182",
      "lon": "13.6307106",
      "superficie_kmq": "11.1670",
      "codice_sovracomunale": "109"
    },
    {
      "sigla_provincia": "FM",
      "codice_istat": "109026",
      "denominazione_ita_altra": "Monte Vidon Corrado",
      "denominazione_ita": "Monte Vidon Corrado",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F665",
      "lat": "43.1213713",
      "lon": "13.4844825",
      "superficie_kmq": "5.9490",
      "codice_sovracomunale": "109"
    },
    {
      "sigla_provincia": "FM",
      "codice_istat": "109027",
      "denominazione_ita_altra": "Montottone",
      "denominazione_ita": "Montottone",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F697",
      "lat": "43.0619286",
      "lon": "13.5848071",
      "superficie_kmq": "16.3789",
      "codice_sovracomunale": "109"
    },
    {
      "sigla_provincia": "FM",
      "codice_istat": "109028",
      "denominazione_ita_altra": "Moresco",
      "denominazione_ita": "Moresco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F722",
      "lat": "43.0856845",
      "lon": "13.7324426",
      "superficie_kmq": "6.3504",
      "codice_sovracomunale": "109"
    },
    {
      "sigla_provincia": "FM",
      "codice_istat": "109029",
      "denominazione_ita_altra": "Ortezzano",
      "denominazione_ita": "Ortezzano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G137",
      "lat": "43.0315507",
      "lon": "13.6074938",
      "superficie_kmq": "7.0749",
      "codice_sovracomunale": "109"
    },
    {
      "sigla_provincia": "FM",
      "codice_istat": "109030",
      "denominazione_ita_altra": "Pedaso",
      "denominazione_ita": "Pedaso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G403",
      "lat": "43.0979966",
      "lon": "13.8409187",
      "superficie_kmq": "3.8470",
      "codice_sovracomunale": "109"
    },
    {
      "sigla_provincia": "FM",
      "codice_istat": "109031",
      "denominazione_ita_altra": "Petritoli",
      "denominazione_ita": "Petritoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G516",
      "lat": "43.0669649",
      "lon": "13.6561640",
      "superficie_kmq": "24.0023",
      "codice_sovracomunale": "109"
    },
    {
      "sigla_provincia": "FM",
      "codice_istat": "109032",
      "denominazione_ita_altra": "Ponzano di Fermo",
      "denominazione_ita": "Ponzano di Fermo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G873",
      "lat": "43.1018929",
      "lon": "13.6586425",
      "superficie_kmq": "14.2677",
      "codice_sovracomunale": "109"
    },
    {
      "sigla_provincia": "FM",
      "codice_istat": "109033",
      "denominazione_ita_altra": "Porto San Giorgio",
      "denominazione_ita": "Porto San Giorgio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G920",
      "lat": "43.1844148",
      "lon": "13.7963864",
      "superficie_kmq": "8.7932",
      "codice_sovracomunale": "109"
    },
    {
      "sigla_provincia": "FM",
      "codice_istat": "109034",
      "denominazione_ita_altra": "Porto Sant'Elpidio",
      "denominazione_ita": "Porto Sant'Elpidio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G921",
      "lat": "43.2579592",
      "lon": "13.7580697",
      "superficie_kmq": "18.1256",
      "codice_sovracomunale": "109"
    },
    {
      "sigla_provincia": "FM",
      "codice_istat": "109035",
      "denominazione_ita_altra": "Rapagnano",
      "denominazione_ita": "Rapagnano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H182",
      "lat": "43.1609820",
      "lon": "13.5928008",
      "superficie_kmq": "12.6456",
      "codice_sovracomunale": "109"
    },
    {
      "sigla_provincia": "FM",
      "codice_istat": "109036",
      "denominazione_ita_altra": "Santa Vittoria in Matenano",
      "denominazione_ita": "Santa Vittoria in Matenano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I315",
      "lat": "43.0199224",
      "lon": "13.4967193",
      "superficie_kmq": "26.1790",
      "codice_sovracomunale": "109"
    },
    {
      "sigla_provincia": "FM",
      "codice_istat": "109037",
      "denominazione_ita_altra": "Sant'Elpidio a Mare",
      "denominazione_ita": "Sant'Elpidio a Mare",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I324",
      "lat": "43.2291392",
      "lon": "13.6865322",
      "superficie_kmq": "50.5172",
      "codice_sovracomunale": "109"
    },
    {
      "sigla_provincia": "FM",
      "codice_istat": "109038",
      "denominazione_ita_altra": "Servigliano",
      "denominazione_ita": "Servigliano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C070",
      "lat": "43.0807524",
      "lon": "13.4921828",
      "superficie_kmq": "18.4918",
      "codice_sovracomunale": "109"
    },
    {
      "sigla_provincia": "FM",
      "codice_istat": "109039",
      "denominazione_ita_altra": "Smerillo",
      "denominazione_ita": "Smerillo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I774",
      "lat": "43.0044218",
      "lon": "13.4454726",
      "superficie_kmq": "11.2924",
      "codice_sovracomunale": "109"
    },
    {
      "sigla_provincia": "FM",
      "codice_istat": "109040",
      "denominazione_ita_altra": "Torre San Patrizio",
      "denominazione_ita": "Torre San Patrizio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L279",
      "lat": "43.1844270",
      "lon": "13.6075292",
      "superficie_kmq": "11.9329",
      "codice_sovracomunale": "109"
    },
    {
      "sigla_provincia": "BT",
      "codice_istat": "110001",
      "denominazione_ita_altra": "Andria",
      "denominazione_ita": "Andria",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "A285",
      "lat": "41.2269972",
      "lon": "16.2952003",
      "superficie_kmq": "402.8821",
      "codice_sovracomunale": "110"
    },
    {
      "sigla_provincia": "BT",
      "codice_istat": "110002",
      "denominazione_ita_altra": "Barletta",
      "denominazione_ita": "Barletta",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "A669",
      "lat": "41.3195048",
      "lon": "16.2771701",
      "superficie_kmq": "149.3513",
      "codice_sovracomunale": "110"
    },
    {
      "sigla_provincia": "BT",
      "codice_istat": "110003",
      "denominazione_ita_altra": "Bisceglie",
      "denominazione_ita": "Bisceglie",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A883",
      "lat": "41.2424117",
      "lon": "16.5026076",
      "superficie_kmq": "69.2439",
      "codice_sovracomunale": "110"
    },
    {
      "sigla_provincia": "BT",
      "codice_istat": "110004",
      "denominazione_ita_altra": "Canosa di Puglia",
      "denominazione_ita": "Canosa di Puglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B619",
      "lat": "41.2253386",
      "lon": "16.0626379",
      "superficie_kmq": "150.9317",
      "codice_sovracomunale": "110"
    },
    {
      "sigla_provincia": "BT",
      "codice_istat": "110005",
      "denominazione_ita_altra": "Margherita di Savoia",
      "denominazione_ita": "Margherita di Savoia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E946",
      "lat": "41.3725648",
      "lon": "16.1488835",
      "superficie_kmq": "35.6977",
      "codice_sovracomunale": "110"
    },
    {
      "sigla_provincia": "BT",
      "codice_istat": "110006",
      "denominazione_ita_altra": "Minervino Murge",
      "denominazione_ita": "Minervino Murge",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F220",
      "lat": "41.0891341",
      "lon": "16.0796598",
      "superficie_kmq": "257.4065",
      "codice_sovracomunale": "110"
    },
    {
      "sigla_provincia": "BT",
      "codice_istat": "110007",
      "denominazione_ita_altra": "San Ferdinando di Puglia",
      "denominazione_ita": "San Ferdinando di Puglia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H839",
      "lat": "41.3007425",
      "lon": "16.0688328",
      "superficie_kmq": "41.2298",
      "codice_sovracomunale": "110"
    },
    {
      "sigla_provincia": "BT",
      "codice_istat": "110008",
      "denominazione_ita_altra": "Spinazzola",
      "denominazione_ita": "Spinazzola",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I907",
      "lat": "40.9693785",
      "lon": "16.0892592",
      "superficie_kmq": "184.0052",
      "codice_sovracomunale": "110"
    },
    {
      "sigla_provincia": "BT",
      "codice_istat": "110009",
      "denominazione_ita_altra": "Trani",
      "denominazione_ita": "Trani",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "L328",
      "lat": "41.2740349",
      "lon": "16.4172134",
      "superficie_kmq": "103.4761",
      "codice_sovracomunale": "110"
    },
    {
      "sigla_provincia": "BT",
      "codice_istat": "110010",
      "denominazione_ita_altra": "Trinitapoli",
      "denominazione_ita": "Trinitapoli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B915",
      "lat": "41.3595174",
      "lon": "16.0855425",
      "superficie_kmq": "148.7657",
      "codice_sovracomunale": "110"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111001",
      "denominazione_ita_altra": "Arbus",
      "denominazione_ita": "Arbus",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A359",
      "lat": "39.5738451",
      "lon": "8.4987144",
      "superficie_kmq": "269.1174",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111002",
      "denominazione_ita_altra": "Armungia",
      "denominazione_ita": "Armungia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A419",
      "lat": "39.5380239",
      "lon": "9.4148604",
      "superficie_kmq": "54.7539",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111003",
      "denominazione_ita_altra": "Ballao",
      "denominazione_ita": "Ballao",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A597",
      "lat": "39.5734514",
      "lon": "9.3821398",
      "superficie_kmq": "46.6242",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111004",
      "denominazione_ita_altra": "Barrali",
      "denominazione_ita": "Barrali",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A677",
      "lat": "39.4759138",
      "lon": "9.1051094",
      "superficie_kmq": "11.2269",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111005",
      "denominazione_ita_altra": "Barumini",
      "denominazione_ita": "Barumini",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "A681",
      "lat": "39.6936402",
      "lon": "9.0171406",
      "superficie_kmq": "26.3994",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111006",
      "denominazione_ita_altra": "Buggerru",
      "denominazione_ita": "Buggerru",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B250",
      "lat": "39.4016751",
      "lon": "8.4334191",
      "superficie_kmq": "48.3317",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111007",
      "denominazione_ita_altra": "Burcei",
      "denominazione_ita": "Burcei",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B274",
      "lat": "39.3607775",
      "lon": "9.4012382",
      "superficie_kmq": "94.8516",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111008",
      "denominazione_ita_altra": "Calasetta",
      "denominazione_ita": "Calasetta",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B383",
      "lat": "39.0740744",
      "lon": "8.3797872",
      "superficie_kmq": "31.0599",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111009",
      "denominazione_ita_altra": "Carbonia",
      "denominazione_ita": "Carbonia",
      "denominazione_altra": "",
      "flag_capoluogo": "SI",
      "codice_belfiore": "B745",
      "lat": "39.2015492",
      "lon": "8.5238554",
      "superficie_kmq": "145.5415",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111010",
      "denominazione_ita_altra": "Carloforte",
      "denominazione_ita": "Carloforte",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B789",
      "lat": "39.1409087",
      "lon": "8.2757127",
      "superficie_kmq": "51.1010",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111011",
      "denominazione_ita_altra": "Castiadas",
      "denominazione_ita": "Castiadas",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M288",
      "lat": "39.2431201",
      "lon": "9.5192322",
      "superficie_kmq": "103.8877",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111012",
      "denominazione_ita_altra": "Collinas",
      "denominazione_ita": "Collinas",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "C882",
      "lat": "39.6472773",
      "lon": "8.8297908",
      "superficie_kmq": "20.8264",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111013",
      "denominazione_ita_altra": "Decimoputzu",
      "denominazione_ita": "Decimoputzu",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D260",
      "lat": "39.3417750",
      "lon": "8.8825679",
      "superficie_kmq": "44.7736",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111014",
      "denominazione_ita_altra": "Dolianova",
      "denominazione_ita": "Dolianova",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D323",
      "lat": "39.3943536",
      "lon": "9.2196340",
      "superficie_kmq": "84.3042",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111015",
      "denominazione_ita_altra": "Domus de Maria",
      "denominazione_ita": "Domus de Maria",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D333",
      "lat": "38.9519039",
      "lon": "8.8542327",
      "superficie_kmq": "97.1360",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111016",
      "denominazione_ita_altra": "Domusnovas",
      "denominazione_ita": "Domusnovas",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D334",
      "lat": "39.3647147",
      "lon": "8.6217156",
      "superficie_kmq": "80.5920",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111017",
      "denominazione_ita_altra": "Donori",
      "denominazione_ita": "Donori",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D344",
      "lat": "39.4412698",
      "lon": "9.1339460",
      "superficie_kmq": "35.3087",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111018",
      "denominazione_ita_altra": "Escalaplano",
      "denominazione_ita": "Escalaplano",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D430",
      "lat": "39.6348965",
      "lon": "9.3553570",
      "superficie_kmq": "94.0356",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111019",
      "denominazione_ita_altra": "Escolca",
      "denominazione_ita": "Escolca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D431",
      "lat": "39.6800175",
      "lon": "9.1106848",
      "superficie_kmq": "14.7598",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111020",
      "denominazione_ita_altra": "Esterzili",
      "denominazione_ita": "Esterzili",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D443",
      "lat": "39.7421968",
      "lon": "9.3177867",
      "superficie_kmq": "100.7428",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111021",
      "denominazione_ita_altra": "Fluminimaggiore",
      "denominazione_ita": "Fluminimaggiore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D639",
      "lat": "39.4296042",
      "lon": "8.5078654",
      "superficie_kmq": "108.1820",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111022",
      "denominazione_ita_altra": "Furtei",
      "denominazione_ita": "Furtei",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D827",
      "lat": "39.5572557",
      "lon": "8.9511936",
      "superficie_kmq": "26.1114",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111023",
      "denominazione_ita_altra": "Genoni",
      "denominazione_ita": "Genoni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D968",
      "lat": "39.7909164",
      "lon": "8.9726947",
      "superficie_kmq": "43.7929",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111024",
      "denominazione_ita_altra": "Genuri",
      "denominazione_ita": "Genuri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D970",
      "lat": "39.7401657",
      "lon": "8.9229149",
      "superficie_kmq": "7.5149",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111025",
      "denominazione_ita_altra": "Gergei",
      "denominazione_ita": "Gergei",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D982",
      "lat": "39.6955872",
      "lon": "9.0795925",
      "superficie_kmq": "36.1778",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111026",
      "denominazione_ita_altra": "Gesico",
      "denominazione_ita": "Gesico",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D994",
      "lat": "39.6199568",
      "lon": "9.0887686",
      "superficie_kmq": "25.6223",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111027",
      "denominazione_ita_altra": "Gesturi",
      "denominazione_ita": "Gesturi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "D997",
      "lat": "39.7444674",
      "lon": "9.0104769",
      "superficie_kmq": "46.8295",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111028",
      "denominazione_ita_altra": "Giba",
      "denominazione_ita": "Giba",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E022",
      "lat": "39.0620540",
      "lon": "8.6003079",
      "superficie_kmq": "30.4364",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111029",
      "denominazione_ita_altra": "Goni",
      "denominazione_ita": "Goni",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E084",
      "lat": "39.5739405",
      "lon": "9.2823075",
      "superficie_kmq": "18.5982",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111030",
      "denominazione_ita_altra": "Gonnesa",
      "denominazione_ita": "Gonnesa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E086",
      "lat": "39.2590776",
      "lon": "8.4465837",
      "superficie_kmq": "48.0554",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111031",
      "denominazione_ita_altra": "Gonnosfanadiga",
      "denominazione_ita": "Gonnosfanadiga",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E085",
      "lat": "39.5044595",
      "lon": "8.6331390",
      "superficie_kmq": "125.1869",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111032",
      "denominazione_ita_altra": "Guamaggiore",
      "denominazione_ita": "Guamaggiore",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E234",
      "lat": "39.5853243",
      "lon": "9.0653838",
      "superficie_kmq": "16.8014",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111033",
      "denominazione_ita_altra": "Guasila",
      "denominazione_ita": "Guasila",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E252",
      "lat": "39.5629515",
      "lon": "9.0298519",
      "superficie_kmq": "43.5101",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111034",
      "denominazione_ita_altra": "Guspini",
      "denominazione_ita": "Guspini",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E270",
      "lat": "39.6067284",
      "lon": "8.6086565",
      "superficie_kmq": "174.6654",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111035",
      "denominazione_ita_altra": "Iglesias",
      "denominazione_ita": "Iglesias",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E281",
      "lat": "39.3209551",
      "lon": "8.5337186",
      "superficie_kmq": "208.2299",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111036",
      "denominazione_ita_altra": "Isili",
      "denominazione_ita": "Isili",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E336",
      "lat": "39.7680339",
      "lon": "9.1299682",
      "superficie_kmq": "67.8361",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111037",
      "denominazione_ita_altra": "Las Plassas",
      "denominazione_ita": "Las Plassas",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E464",
      "lat": "39.6747608",
      "lon": "8.9789530",
      "superficie_kmq": "11.0416",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111038",
      "denominazione_ita_altra": "Lunamatrona",
      "denominazione_ita": "Lunamatrona",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E742",
      "lat": "39.6367836",
      "lon": "8.9027447",
      "superficie_kmq": "20.5853",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111039",
      "denominazione_ita_altra": "Mandas",
      "denominazione_ita": "Mandas",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "E877",
      "lat": "39.6479166",
      "lon": "9.1296802",
      "superficie_kmq": "45.0144",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111040",
      "denominazione_ita_altra": "Masainas",
      "denominazione_ita": "Masainas",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M270",
      "lat": "39.0357849",
      "lon": "8.6153348",
      "superficie_kmq": "23.6861",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111041",
      "denominazione_ita_altra": "Monastir",
      "denominazione_ita": "Monastir",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F333",
      "lat": "39.3834300",
      "lon": "9.0439989",
      "superficie_kmq": "31.7868",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111042",
      "denominazione_ita_altra": "Muravera",
      "denominazione_ita": "Muravera",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F808",
      "lat": "39.3426996",
      "lon": "9.5759325",
      "superficie_kmq": "93.5065",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111043",
      "denominazione_ita_altra": "Musei",
      "denominazione_ita": "Musei",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F822",
      "lat": "39.2985256",
      "lon": "8.6893790",
      "superficie_kmq": "20.2739",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111044",
      "denominazione_ita_altra": "Narcao",
      "denominazione_ita": "Narcao",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F841",
      "lat": "39.1903437",
      "lon": "8.6780250",
      "superficie_kmq": "85.8744",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111045",
      "denominazione_ita_altra": "Nuragus",
      "denominazione_ita": "Nuragus",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F981",
      "lat": "39.7735870",
      "lon": "9.0448425",
      "superficie_kmq": "19.8991",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111046",
      "denominazione_ita_altra": "Nurallao",
      "denominazione_ita": "Nurallao",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F982",
      "lat": "39.8055384",
      "lon": "9.0954370",
      "superficie_kmq": "34.7617",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111047",
      "denominazione_ita_altra": "Nuraminis",
      "denominazione_ita": "Nuraminis",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F983",
      "lat": "39.4407938",
      "lon": "9.0135571",
      "superficie_kmq": "45.1794",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111048",
      "denominazione_ita_altra": "Nurri",
      "denominazione_ita": "Nurri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F986",
      "lat": "39.7089479",
      "lon": "9.2161264",
      "superficie_kmq": "73.6723",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111049",
      "denominazione_ita_altra": "Nuxis",
      "denominazione_ita": "Nuxis",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "F991",
      "lat": "39.1485126",
      "lon": "8.7620425",
      "superficie_kmq": "61.5918",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111050",
      "denominazione_ita_altra": "Orroli",
      "denominazione_ita": "Orroli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G122",
      "lat": "39.6518535",
      "lon": "9.2602527",
      "superficie_kmq": "75.5932",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111051",
      "denominazione_ita_altra": "Ortacesus",
      "denominazione_ita": "Ortacesus",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G133",
      "lat": "39.5231509",
      "lon": "9.0883595",
      "superficie_kmq": "23.6259",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111052",
      "denominazione_ita_altra": "Pabillonis",
      "denominazione_ita": "Pabillonis",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G207",
      "lat": "39.6078029",
      "lon": "8.7116620",
      "superficie_kmq": "37.4236",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111053",
      "denominazione_ita_altra": "Pauli Arbarei",
      "denominazione_ita": "Pauli Arbarei",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G382",
      "lat": "39.6610416",
      "lon": "8.9326415",
      "superficie_kmq": "15.1381",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111054",
      "denominazione_ita_altra": "Perdaxius",
      "denominazione_ita": "Perdaxius",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G446",
      "lat": "39.1594194",
      "lon": "8.6179617",
      "superficie_kmq": "29.4953",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111055",
      "denominazione_ita_altra": "Pimentel",
      "denominazione_ita": "Pimentel",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G669",
      "lat": "39.4980874",
      "lon": "9.0660502",
      "superficie_kmq": "14.9677",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111056",
      "denominazione_ita_altra": "Piscinas",
      "denominazione_ita": "Piscinas",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M291",
      "lat": "39.0692178",
      "lon": "8.6662685",
      "superficie_kmq": "16.8946",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111057",
      "denominazione_ita_altra": "Portoscuso",
      "denominazione_ita": "Portoscuso",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G922",
      "lat": "39.2013126",
      "lon": "8.4117885",
      "superficie_kmq": "38.0899",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111058",
      "denominazione_ita_altra": "Sadali",
      "denominazione_ita": "Sadali",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H659",
      "lat": "39.8043519",
      "lon": "9.2514469",
      "superficie_kmq": "49.6103",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111059",
      "denominazione_ita_altra": "Samassi",
      "denominazione_ita": "Samassi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H738",
      "lat": "39.4883645",
      "lon": "8.9071566",
      "superficie_kmq": "42.0434",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111060",
      "denominazione_ita_altra": "Samatzai",
      "denominazione_ita": "Samatzai",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H739",
      "lat": "39.4834873",
      "lon": "9.0432490",
      "superficie_kmq": "31.1592",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111061",
      "denominazione_ita_altra": "San Basilio",
      "denominazione_ita": "San Basilio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H766",
      "lat": "39.5196376",
      "lon": "9.2092131",
      "superficie_kmq": "44.6243",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111062",
      "denominazione_ita_altra": "San Gavino Monreale",
      "denominazione_ita": "San Gavino Monreale",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H856",
      "lat": "39.5502833",
      "lon": "8.7771697",
      "superficie_kmq": "87.3983",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111063",
      "denominazione_ita_altra": "San Giovanni Suergiu",
      "denominazione_ita": "San Giovanni Suergiu",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G287",
      "lat": "39.1095347",
      "lon": "8.5065897",
      "superficie_kmq": "72.3709",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111064",
      "denominazione_ita_altra": "San Nicolò Gerrei",
      "denominazione_ita": "San Nicolò Gerrei",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "G383",
      "lat": "39.4789166",
      "lon": "9.2938330",
      "superficie_kmq": "63.5145",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111065",
      "denominazione_ita_altra": "San Sperate",
      "denominazione_ita": "San Sperate",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I166",
      "lat": "39.3524368",
      "lon": "9.0136140",
      "superficie_kmq": "26.2413",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111066",
      "denominazione_ita_altra": "San Vito",
      "denominazione_ita": "San Vito",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I402",
      "lat": "39.4140197",
      "lon": "9.5030754",
      "superficie_kmq": "231.6382",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111067",
      "denominazione_ita_altra": "Sanluri",
      "denominazione_ita": "Sanluri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "H974",
      "lat": "39.5470911",
      "lon": "8.8785622",
      "superficie_kmq": "84.2252",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111068",
      "denominazione_ita_altra": "Santadi",
      "denominazione_ita": "Santadi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I182",
      "lat": "39.0816530",
      "lon": "8.7598981",
      "superficie_kmq": "116.4841",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111069",
      "denominazione_ita_altra": "Sant'Andrea Frius",
      "denominazione_ita": "Sant'Andrea Frius",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I271",
      "lat": "39.4753732",
      "lon": "9.1856026",
      "superficie_kmq": "36.1629",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111070",
      "denominazione_ita_altra": "Sant'Anna Arresi",
      "denominazione_ita": "Sant'Anna Arresi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M209",
      "lat": "38.9949875",
      "lon": "8.6122899",
      "superficie_kmq": "36.6787",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111071",
      "denominazione_ita_altra": "Sant'Antioco",
      "denominazione_ita": "Sant'Antioco",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I294",
      "lat": "39.0329141",
      "lon": "8.4290532",
      "superficie_kmq": "87.8946",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111072",
      "denominazione_ita_altra": "Sardara",
      "denominazione_ita": "Sardara",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I428",
      "lat": "39.6086234",
      "lon": "8.8075609",
      "superficie_kmq": "56.2294",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111073",
      "denominazione_ita_altra": "Segariu",
      "denominazione_ita": "Segariu",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I570",
      "lat": "39.5698395",
      "lon": "8.9841747",
      "superficie_kmq": "16.6918",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111074",
      "denominazione_ita_altra": "Selegas",
      "denominazione_ita": "Selegas",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I582",
      "lat": "39.5781830",
      "lon": "9.1033065",
      "superficie_kmq": "20.3934",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111075",
      "denominazione_ita_altra": "Senorbì",
      "denominazione_ita": "Senorbì",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I615",
      "lat": "39.5270601",
      "lon": "9.1434540",
      "superficie_kmq": "34.2889",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111076",
      "denominazione_ita_altra": "Serdiana",
      "denominazione_ita": "Serdiana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I624",
      "lat": "39.3935894",
      "lon": "9.1517960",
      "superficie_kmq": "55.7060",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111077",
      "denominazione_ita_altra": "Serramanna",
      "denominazione_ita": "Serramanna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I647",
      "lat": "39.4353226",
      "lon": "8.8961613",
      "superficie_kmq": "83.8383",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111078",
      "denominazione_ita_altra": "Serrenti",
      "denominazione_ita": "Serrenti",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I667",
      "lat": "39.4999678",
      "lon": "8.9724659",
      "superficie_kmq": "42.7842",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111079",
      "denominazione_ita_altra": "Serri",
      "denominazione_ita": "Serri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I668",
      "lat": "39.7046037",
      "lon": "9.1538265",
      "superficie_kmq": "19.1826",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111080",
      "denominazione_ita_altra": "Setzu",
      "denominazione_ita": "Setzu",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I705",
      "lat": "39.7343702",
      "lon": "8.9403248",
      "superficie_kmq": "7.7682",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111081",
      "denominazione_ita_altra": "Seui",
      "denominazione_ita": "Seui",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I706",
      "lat": "39.8387080",
      "lon": "9.3461350",
      "superficie_kmq": "148.2049",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111082",
      "denominazione_ita_altra": "Seulo",
      "denominazione_ita": "Seulo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I707",
      "lat": "39.8751027",
      "lon": "9.2334262",
      "superficie_kmq": "58.7880",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111083",
      "denominazione_ita_altra": "Siddi",
      "denominazione_ita": "Siddi",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I724",
      "lat": "39.6718864",
      "lon": "8.8831269",
      "superficie_kmq": "11.0203",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111084",
      "denominazione_ita_altra": "Siliqua",
      "denominazione_ita": "Siliqua",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I734",
      "lat": "39.2626056",
      "lon": "8.7919524",
      "superficie_kmq": "189.8443",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111085",
      "denominazione_ita_altra": "Silius",
      "denominazione_ita": "Silius",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I735",
      "lat": "39.5362417",
      "lon": "9.2934797",
      "superficie_kmq": "38.3587",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111086",
      "denominazione_ita_altra": "Siurgus Donigala",
      "denominazione_ita": "Siurgus Donigala",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I765",
      "lat": "39.5899059",
      "lon": "9.2121338",
      "superficie_kmq": "76.3871",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111087",
      "denominazione_ita_altra": "Soleminis",
      "denominazione_ita": "Soleminis",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I797",
      "lat": "39.3431764",
      "lon": "9.1768933",
      "superficie_kmq": "12.7937",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111088",
      "denominazione_ita_altra": "Suelli",
      "denominazione_ita": "Suelli",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "I995",
      "lat": "39.5740932",
      "lon": "9.1376454",
      "superficie_kmq": "19.1982",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111089",
      "denominazione_ita_altra": "Teulada",
      "denominazione_ita": "Teulada",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L154",
      "lat": "38.9698280",
      "lon": "8.7277344",
      "superficie_kmq": "246.1839",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111090",
      "denominazione_ita_altra": "Tratalias",
      "denominazione_ita": "Tratalias",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L337",
      "lat": "39.1160269",
      "lon": "8.5786093",
      "superficie_kmq": "31.0024",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111091",
      "denominazione_ita_altra": "Tuili",
      "denominazione_ita": "Tuili",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L463",
      "lat": "39.7137647",
      "lon": "8.9633530",
      "superficie_kmq": "24.5857",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111092",
      "denominazione_ita_altra": "Turri",
      "denominazione_ita": "Turri",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L473",
      "lat": "39.7029359",
      "lon": "8.9225336",
      "superficie_kmq": "9.6009",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111093",
      "denominazione_ita_altra": "Ussana",
      "denominazione_ita": "Ussana",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L512",
      "lat": "39.4037939",
      "lon": "9.0860824",
      "superficie_kmq": "32.8211",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111094",
      "denominazione_ita_altra": "Ussaramanna",
      "denominazione_ita": "Ussaramanna",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L513",
      "lat": "39.6908897",
      "lon": "8.9032756",
      "superficie_kmq": "9.7603",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111095",
      "denominazione_ita_altra": "Vallermosa",
      "denominazione_ita": "Vallermosa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L613",
      "lat": "39.3663699",
      "lon": "8.7765191",
      "superficie_kmq": "61.7509",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111096",
      "denominazione_ita_altra": "Villacidro",
      "denominazione_ita": "Villacidro",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L924",
      "lat": "39.4432850",
      "lon": "8.7345376",
      "superficie_kmq": "183.4750",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111097",
      "denominazione_ita_altra": "Villamar",
      "denominazione_ita": "Villamar",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L966",
      "lat": "39.6166581",
      "lon": "8.9718756",
      "superficie_kmq": "38.5293",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111098",
      "denominazione_ita_altra": "Villamassargia",
      "denominazione_ita": "Villamassargia",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L968",
      "lat": "39.2552138",
      "lon": "8.6699122",
      "superficie_kmq": "91.3876",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111099",
      "denominazione_ita_altra": "Villanova Tulo",
      "denominazione_ita": "Villanova Tulo",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L992",
      "lat": "39.7898167",
      "lon": "9.1917465",
      "superficie_kmq": "40.4494",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111100",
      "denominazione_ita_altra": "Villanovaforru",
      "denominazione_ita": "Villanovaforru",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L986",
      "lat": "39.6241415",
      "lon": "8.8728313",
      "superficie_kmq": "10.9328",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111101",
      "denominazione_ita_altra": "Villanovafranca",
      "denominazione_ita": "Villanovafranca",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L987",
      "lat": "39.6463064",
      "lon": "9.0120743",
      "superficie_kmq": "27.5900",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111102",
      "denominazione_ita_altra": "Villaperuccio",
      "denominazione_ita": "Villaperuccio",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M278",
      "lat": "39.1137402",
      "lon": "8.6489869",
      "superficie_kmq": "36.4283",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111103",
      "denominazione_ita_altra": "Villaputzu",
      "denominazione_ita": "Villaputzu",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "L998",
      "lat": "39.5346598",
      "lon": "9.5514436",
      "superficie_kmq": "181.3074",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111104",
      "denominazione_ita_altra": "Villasalto",
      "denominazione_ita": "Villasalto",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M016",
      "lat": "39.4680466",
      "lon": "9.3941121",
      "superficie_kmq": "130.3596",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111105",
      "denominazione_ita_altra": "Villasimius",
      "denominazione_ita": "Villasimius",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "B738",
      "lat": "39.1550781",
      "lon": "9.5099547",
      "superficie_kmq": "57.9708",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111106",
      "denominazione_ita_altra": "Villasor",
      "denominazione_ita": "Villasor",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M025",
      "lat": "39.3833349",
      "lon": "8.9108004",
      "superficie_kmq": "86.7935",
      "codice_sovracomunale": "111"
    },
    {
      "sigla_provincia": "SU",
      "codice_istat": "111107",
      "denominazione_ita_altra": "Villaspeciosa",
      "denominazione_ita": "Villaspeciosa",
      "denominazione_altra": "",
      "flag_capoluogo": "NO",
      "codice_belfiore": "M026",
      "lat": "39.2969841",
      "lon": "8.8938206",
      "superficie_kmq": "27.1937",
      "codice_sovracomunale": "111"
    }
  ];