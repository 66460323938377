import Button from "react-bootstrap/Button";
import "./UseButton.scss";
const UseButton = ({ name, variant, labels, onClick, type, disabled }) => {
  // console.log(labels)
  return (
    <div className={`UseButton ${name}`}>
      <Button variant={variant} onClick={onClick} type={type} disabled={disabled}>
        {labels}
      </Button>
    </div>
  );
};
export default UseButton;
