import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import UseButton from "../../UseButton/UseButton";
import "./CardSeven.scss";
const CardSeven = () => {
  const navigate = useNavigate();
  const quiz = () => {
    navigate("/quiz");
  };
  let [sex, setSex] = useState("maschio");
  useEffect(() => {
    setTimeout(() => {
      setSex(localStorage.getItem("sex"));
    }, 2000);
  }, []);

  return (
    <div className='cardSeven'>
      <h5>Posso donare?</h5>
      <Card>
        <Card.Body>
          <Card.Title>Evita viaggi a vuoto, completa il tuo test di idoneità</Card.Title>
          <Card.Text>
            <svg
              width='30'
              height='30'
              viewBox='0 0 30 30'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M15 14.375V20.625'
                stroke='#BB0F31'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M15 9.3875L15.0125 9.37361'
                stroke='#BB0F31'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M15 27.5C21.9036 27.5 27.5 21.9036 27.5 15C27.5 8.09644 21.9036 2.5 15 2.5C8.09644 2.5 2.5 8.09644 2.5 15C2.5 21.9036 8.09644 27.5 15 27.5Z'
                stroke='#BB0F31'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>{" "}
            Puoi donare ogni {sex == "maschio" ? "90" : "180"} giorni.
          </Card.Text>
          <UseButton
            onClick={quiz}
            name='adminBtnPrimary'
            variant='btn btn-outline-secondary'
            labels='Compila il quiz'
          />
          {/* <Button>Compila il quiz</Button> */}
          {/* <Row>
                      <Col xs={2} sm={2} md={2} lg={2} className='d-flex justify-content-cente align-items-center'>
                      <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="42" height="42" rx="10" fill="#FFF5F5"/>
<path d="M21 20.375V26.625" stroke="#BB0F31" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M21 15.3875L21.0125 15.3736" stroke="#BB0F31" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M21 33.5C27.9036 33.5 33.5 27.9036 33.5 21C33.5 14.0964 27.9036 8.5 21 8.5C14.0964 8.5 8.5 14.0964 8.5 21C8.5 27.9036 14.0964 33.5 21 33.5Z" stroke="#BB0F31" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

                      </Col>
                      <Col xs={10} sm={10} md={10} lg={10}>
                          <p>Puoi donare ogni 90 giorni.
Ti contatteremo noi per organizzare ogni appuntamento, oppure puoi organizzarli direttamente da qui :)</p>
                      </Col>
                  </Row> */}
        </Card.Body>
      </Card>
    </div>
  );
};
export default CardSeven;
