import React from "react";
import { Breadcrumb, Col, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import "./BreadCrum.scss";
const BreadCrum = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  let breadcrumbPath = "";

  return (
    <div className='BreadCrum' data-testid='BreadCrum'>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} className='d-flex align-items-center'>
          {/* <Breadcrumb.Item key={breadcrumbPath}> sdsd</Breadcrumb.Item> */}
          <Breadcrumb>
            {pathnames.map((name, index) => {
              breadcrumbPath += `/${name}`;
              const isLast = index === pathnames.length - 1;

              return isLast ? (
                <Breadcrumb.Item key={breadcrumbPath}>
                  {" "}
                  {name.replace(/-/g, " ")}
                </Breadcrumb.Item>
              ) : (
                <Breadcrumb.Item key={breadcrumbPath}>
                  {" "}
                  <Link to={breadcrumbPath}>{name.replace(/-/, " ")}</Link>
                </Breadcrumb.Item>
              );
            })}
          </Breadcrumb>
        </Col>
      </Row>
    </div>
  );
};

BreadCrum.propTypes = {};

BreadCrum.defaultProps = {};

export default BreadCrum;
