import React from "react";
import CarouselSliderThree from "../../Components/CarouselSlider/CarouselSliderThree/CarouselSliderThree";
import "./Seminar.scss";
const Seminar = ({ companyId }) => (
  <div className='Seminar' id='webinar' data-testid='Seminar'>
    <h2>Tutti i webinar</h2>
    <CarouselSliderThree companyId={companyId} />
  </div>
);

Seminar.propTypes = {};

Seminar.defaultProps = {};

export default Seminar;
