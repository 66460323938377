import { configureStore } from "@reduxjs/toolkit";
import UserReducer from "../Slice/registerSlice";
// import CurrencyReducer from '../Slice/CurrencySlice/CurrencySlice';
const store = configureStore({
  reducer: {
    user: UserReducer,
    // currency:CurrencyReducer
  },
});
export default store;
