import React from "react";
import { Button, Card } from "react-bootstrap";
import BookedIcon from "../../../Pages/DonationTimeline/BookedIcon";
import CancelledIcon from "../../../Pages/DonationTimeline/CancelledIcon";
import "./CardThree.scss";
const CardThree = ({ data, onCancel, onModify }) => {
  function formatDateToItalian(dateStr) {
    const months = [
      "Gennaio",
      "Febbraio",
      "Marzo",
      "Aprile",
      "Maggio",
      "Giugno",
      "Luglio",
      "Agosto",
      "Settembre",
      "Ottobre",
      "Novembre",
      "Dicembre",
    ];

    const date = new Date(dateStr);

    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  }
  return (
    <div className='customCardThree'>
      <Card>
        <Card.Body>
          {data.status === "cancelled" || data.status === "failed" ? (
            <Card.Title className='failed_booking'>
              Prenotazione annullata
              <CancelledIcon fill={"#9696A0"} />
            </Card.Title>
          ) : (
            <Card.Title>
              Prenotazione confermata
              <BookedIcon />
            </Card.Title>
          )}
          <div className='card-center'>
            <Card.Subtitle>
              Donazione del <br />
              {formatDateToItalian(data.date)}
            </Card.Subtitle>
            <div className='card-content'>
              <div className='details'>
                <svg
                  width='31'
                  height='30'
                  viewBox='0 0 31 30'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M19.418 5V2.5M19.418 5V7.5M19.418 5H13.793M4.41797 12.5V23.75C4.41797 25.1307 5.53726 26.25 6.91797 26.25H24.418C25.7987 26.25 26.918 25.1307 26.918 23.75V12.5H4.41797Z'
                    stroke='#980522'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M4.41797 12.5V7.5C4.41797 6.11929 5.53726 5 6.91797 5H9.41797'
                    stroke='#980522'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M9.41797 2.5V7.5'
                    stroke='#980522'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M26.918 12.5V7.5C26.918 6.11929 25.7987 5 24.418 5H23.793'
                    stroke='#980522'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>{" "}
                {formatDateToItalian(data.date)} - {data.slot.trim().slice(0, 5)}
              </div>
              <div className='details'>
                <svg
                  width='31'
                  height='30'
                  viewBox='0 0 31 30'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M25.668 12.5C25.668 18.0228 15.668 27.5 15.668 27.5C15.668 27.5 5.66797 18.0228 5.66797 12.5C5.66797 6.97715 10.1451 2.5 15.668 2.5C21.1908 2.5 25.668 6.97715 25.668 12.5Z'
                    stroke='#980522'
                    strokeWidth='2'
                  />
                  <path
                    d='M15.668 13.75C16.3583 13.75 16.918 13.1904 16.918 12.5C16.918 11.8096 16.3583 11.25 15.668 11.25C14.9776 11.25 14.418 11.8096 14.418 12.5C14.418 13.1904 14.9776 13.75 15.668 13.75Z'
                    fill='#980522'
                    stroke='#980522'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>{" "}
                {data.donationCenterAddress}
              </div>
            </div>
          </div>
          <Card.Footer>
            {data.status == "cancelled" || data.status == "failed" ? (
              <>
                <div className='spacer'></div>
                <Button variant='primary disabled '>Prenotazione annullata</Button>
              </>
            ) : (
              <>
                <Button
                  variant='primary'
                  onClick={() => {
                    onCancel && onCancel(data);
                  }}
                >
                  Annulla
                </Button>
                <Button
                  onClick={() => {
                    onModify && onModify(data);
                  }}
                  variant='outline-primary'
                >
                  Modifica
                </Button>
              </>
            )}
          </Card.Footer>
        </Card.Body>
      </Card>
    </div>
  );
};
export default CardThree;
