import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import AdminLayout from "./Admin/AdminLayout/AdminLayout";
import LazyAdminLogin from "./Admin/AdminLogin/AdminLogin";
import "./App.css";
import LazyBookNow from "./Pages/BookNow/BookNow";
import LazyBookSlot from "./Pages/BookSlot/BookSlot";
import LazyFindPlace from "./Pages/FindPlace/FindPlace";
import LazyHomePage from "./Pages/HomePage/HomePage";
import LazyLayout from "./Pages/Layout/Layout";
import LazyLogin from "./Pages/Login/Login";
import LazyNotification from "./Pages/Notification/Notification";
import LazyQuiz from "./Pages/Quiz/Quiz";
import RegisterDetails from "./Pages/RegisterDetails/RegisterDetails";
import LazyUserRegister from "./Pages/UserRegister/UserRegister";
import LazyVideo from "./Pages/video/video";
import "./assets/scss/admin.scss";
import "./assets/scss/custom.scss";
// import AdminSidebar from './Admin/AdminSidebar/AdminSidebar';
import LazyAddCenter from "./Admin/AddCenter/AddCenter";
import LazyAddConventions from "./Admin/AddConventions/AddConventions";
import LazyAddDonationCenter from "./Admin/AddDonationCenter/AddDonationCenter";
import LazyAddProfile from "./Admin/AddProfile/AddProfile";
import LazyAddReservations from "./Admin/AddReservations/AddReservations";
import LazyAddUser from "./Admin/AddUser/AddUser";
import LazyAdminSettings from "./Admin/AdminSettings/AdminSettings";
import LazyAlertManagement from "./Admin/AlertManagement/AlertManagement";
import LazyCompanies from "./Admin/Companies/Companies";
import LazyCompanyProfile from "./Admin/CompanyProfile/CompanyProfile";
import LazyConventions from "./Admin/Conventions/Conventions";
import LazyDashboard from "./Admin/Dashboard/Dashboard";
import LazyDonationCenters from "./Admin/DonationCenters/DonationCenters";
import LazyEmployeeProfile from "./Admin/EmployeeProfile/EmployeeProfile";
import LazyNoticeCard from "./Admin/NoticeCard/NoticeCard";
import LazyUsers from "./Admin/Users/Users";
import LazyVenudetails from "./Admin/venudetails/venudetails";
import PageNotFound from "./Components/PageNotFound/PageNotFound";
import PrivateRoutes from "./Components/Utils/PrivateRoutes";
import LazyActivateAccount from "./Pages/ActivateAccount/ActivateAccount";
import LazyForgot from "./Pages/Forgot/Forgot";
import Generali from "./Pages/Generali";
import LazyGuestRegister from "./Pages/GuestRegister/GuestRegister";
import GeneraliLayout from "./Pages/Layout/GeneraliLayout.lazy";
import { Logout } from "./Pages/Logout";
import RegistrationCancelled from "./Pages/ReservationCancelled/RegistrationCancelled";
import LazyReservations from "./Pages/Reservations/Reservations";
import LazySetPwd from "./Pages/SetPwd/SetPwd";
import LazyUserAccount from "./Pages/UserAccount/UserAccount";

function App() {
  return (
    <div className='App'>
      <Router>
        <Routes>
          <Route path='/login' element={<LazyLogin />} />
          <Route path='/admin-login' element={<LazyAdminLogin />} />
          <Route path='/forgot' element={<LazyForgot />} />
          <Route path='/set-password' element={<LazySetPwd />} />
          <Route path='/attiva-account' element={<LazyActivateAccount />} />
          <Route path='/user-register' element={<RegisterDetails />} />
          <Route path='/generali' element={<Generali />} />
          <Route path='*' element={<PageNotFound />} />
          <Route
            path='/reservation/cancel/:bookingId/:donationCenterId/:timeslotId/:intervalId/:userId'
            element={<RegistrationCancelled />}
          />

          {/* <Route path='/book-now/:id' element={<LazyBookNow/>} /> */}
          <Route path='/quiz' element={<LazyQuiz />} />

          <Route element={<PrivateRoutes />}>
            {/* <Route path='/' element={<LazyLandingPage/>} />                                           */}
            <Route path='/guest-register' element={<LazyGuestRegister />} />
            <Route
              path='/'
              element={
                <LazyLayout>
                  <LazyHomePage />
                </LazyLayout>
              }
            />
            <Route path='/logout' element={<Logout />} />
            <Route
              path='/home'
              element={
                <LazyLayout>
                  <LazyHomePage />
                </LazyLayout>
              }
            />
            <Route
              path='/home/Scegli-il-posto'
              element={
                <LazyLayout>
                  <LazyFindPlace />
                </LazyLayout>
              }
            />
            <Route
              path='/book'
              element={
                <LazyLayout>
                  <LazyBookSlot />
                </LazyLayout>
              }
            />
            <Route path='/register' element={<LazyUserRegister />} />
            <Route
              path='/settings'
              element={
                <LazyLayout>
                  <LazyUserAccount />
                </LazyLayout>
              }
            />
            {/* <Route
              path='/settings'
              element={
                <LazyLayout>
                  <LazySettings />
                </LazyLayout>
              }
            /> */}

            <Route
              path='/generali/:id'
              element={
                <GeneraliLayout>
                  <LazyBookNow />
                </GeneraliLayout>
              }
            />
            <Route
              path='/eventi/:id'
              element={
                <GeneraliLayout>
                  <LazyBookNow />
                </GeneraliLayout>
              }
            />

            <Route
              path='/video'
              element={
                <LazyLayout>
                  <LazyVideo />
                </LazyLayout>
              }
            />
            <Route
              path='/notifiche'
              element={
                <LazyLayout>
                  <LazyNotification />
                </LazyLayout>
              }
            />
            <Route
              path='/resevations'
              element={
                <LazyLayout>
                  <LazyReservations />
                </LazyLayout>
              }
            />

            {/* <Route path='/account'
            element={
            <LazyLayout>
                <LazyUserAccount/>
            </LazyLayout>}
            /> */}

            <Route
              path='/dashboard'
              element={
                <AdminLayout>
                  <LazyDashboard />
                </AdminLayout>
              }
            />
            <Route
              path='/Aziende'
              element={
                <AdminLayout>
                  <LazyCompanies />
                </AdminLayout>
              }
            />
            <Route
              path='/Utenti'
              element={
                <AdminLayout>
                  <LazyUsers />
                </AdminLayout>
              }
            />
            <Route
              path='/venue'
              element={
                <AdminLayout>
                  <LazyVenudetails />
                </AdminLayout>
              }
            />
            <Route
              path='/Gestione-avvisi'
              element={
                <AdminLayout>
                  <LazyAlertManagement />
                </AdminLayout>
              }
            />
            <Route
              path='/Convenzioni'
              element={
                <AdminLayout>
                  <LazyConventions />
                </AdminLayout>
              }
            />
            <Route
              path='/Centro-donazione'
              element={
                <AdminLayout>
                  <LazyDonationCenters />
                </AdminLayout>
              }
            />
            <Route
              path='/Aziende/Aggiungi-azienda'
              element={
                <AdminLayout>
                  <LazyCompanyProfile />
                </AdminLayout>
              }
            />
            <Route
              path='/Aziende/Modifica-azienda'
              element={
                <AdminLayout>
                  <LazyCompanyProfile />
                </AdminLayout>
              }
            />
            <Route
              path='/Utenti/Aggiungi-Utenti'
              element={
                <AdminLayout>
                  <LazyAddUser />
                </AdminLayout>
              }
            />
            <Route
              path='/Utenti/Modificare-Utenti'
              element={
                <AdminLayout>
                  <LazyAddUser />
                </AdminLayout>
              }
            />

            <Route
              path='/Centro-donazione/Scheda-centro'
              element={
                <AdminLayout>
                  <LazyAddCenter />
                </AdminLayout>
              }
            />
            <Route
              path='/Centro-donazione/Scheda-centro/Aggiungi-data'
              element={
                <AdminLayout>
                  <LazyAddReservations />
                </AdminLayout>
              }
            />

            <Route
              path='/Convenzioni/Aggiungi-convenzione'
              element={
                <AdminLayout>
                  <LazyAddConventions />
                </AdminLayout>
              }
            />
            <Route
              path='/Convenzioni/Modificare-convenzione'
              element={
                <AdminLayout>
                  <LazyAddConventions />
                </AdminLayout>
              }
            />
            <Route
              path='/Convenzioni/Modificare-Convenzioni'
              element={
                <AdminLayout>
                  <LazyAddConventions />
                </AdminLayout>
              }
            />

            <Route
              path='/Centro-donazione/Scheda-centro-donazioni'
              element={
                <AdminLayout>
                  <LazyAddDonationCenter />
                </AdminLayout>
              }
            />
            <Route
              path='/Centro-donazione/Modificare-centro-donazioni'
              element={
                <AdminLayout>
                  <LazyAddDonationCenter />
                </AdminLayout>
              }
            />

            <Route
              path='/Gestione-avvisi/Crea-avviso'
              element={
                <AdminLayout>
                  <LazyNoticeCard />
                </AdminLayout>
              }
            />
            <Route
              path='/Gestione-avvisi/Scheda-avviso'
              element={
                <AdminLayout>
                  <LazyNoticeCard />
                </AdminLayout>
              }
            />
            <Route
              path='/Impostazioni'
              element={
                <AdminLayout>
                  <LazyAdminSettings />
                </AdminLayout>
              }
            />
            <Route
              path='/Impostazioni/Modificare-profilo-collaboratore'
              element={
                <AdminLayout>
                  <LazyEmployeeProfile />
                </AdminLayout>
              }
            />
            <Route
              path='/Impostazioni/Scheda-profilo-collaboratore'
              element={
                <AdminLayout>
                  <LazyAddProfile />
                </AdminLayout>
              }
            />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
