import React from "react";
import LazyFooter from "../Footer/Footer";
import LazyHeader from "../Header/Header";
import "./Layout.scss";
const Layout = ({ children }) => (
  <div className='Layout' data-testid='Layout'>
    <LazyHeader />
    <div className='innerContent'>{children}</div>
    <LazyFooter />
  </div>
);

Layout.propTypes = {};

Layout.defaultProps = {};

export default Layout;
