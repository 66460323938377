import { Loader } from "@googlemaps/js-api-loader";
import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import LazyBreadCrum from "../../Admin/BreadCrum/BreadCrum";
import AddressAutocomplete from "../../Components/AddressAutocomplete/AddressAutocomplete";
import MapCard from "../../Components/Card/MapCard/MapCard";
import GoogleMap from "../../Components/GoogleMap/GoogleMap";
import UseLoader from "../../Components/Loader/Loader";
import "./FindPlace.scss";
const FindPlace = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [coords, setCoords] = useState({
    lat: "",
    lng: "",
  });

  const [address, setAddress] = useState("");
  const [foundLocations, setFoundLocations] = useState([]);

  const isValidCoordinates = !isNaN(coords.lat) && !isNaN(coords.lng);
  const apiKey = "AIzaSyACvPjW5jrUbbCOqnC-y-RqIaR5s4E_suU";
  const [selectedAddress, setSelectedAddress] = useState(null);
  const loader = new Loader({
    apiKey: apiKey,
    version: "weekly",
    libraries: ["places", "geometry"], // Include the geometry library here
  });
  // const [centerLocation, setCenterLocation] = useState('')
  // const [locationDetails, setLocaionDetails] = useState([])
  const navigate = useNavigate();
  const functions = getFunctions();
  useEffect(() => {
    loadDonationCenters();
    setLoading(true);
    const queryParams = new URLSearchParams(window.location.search);
    setCoords({
      lat: parseFloat(queryParams.get("lat")),
      lng: parseFloat(queryParams.get("lng")),
    });
  }, []);
  const loadDonationCenters = () => {
    try {
      // const getLocations = httpsCallable(functions,"getLocations")
      const getDoantionCenterDeails = httpsCallable(
        functions,
        "getDonationCentersWithFutureEvents",
      );
      getDoantionCenterDeails()
        .then((response) => {
          console.log("donation center response", response);
          setData(response.data);
          // let donar = response.data;
          // var donation='';
          // response.data.map((response)=>(
          //   console.log('donar id',response.donationCenterId)

          // ))

          // const getDoantionCenterAddress = httpsCallable(functions,"getDonationCenterById")
          // getDoantionCenterAddress({id:donar})
          // .then((response)=>{
          //   console.log('location address',response)
          //   setLocaionDetails(response.data)
          // })
          // .catch((error)=>console.error('error',error));
        })
        .catch((error) => console.error("error", error))
        .finally(() => setLoading(false));
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };
  const book = (data) => {
    navigate("/book", {
      state: {
        centerId: data.id,
        location: { lat: data.coords.lat, lng: data.coords.lng },
      },
    });
  };

  useEffect(() => {
    if (loader) {
      loader.load().then(() => {
        if (navigator.geolocation) {
          const queryParams = new URLSearchParams(window.location.search);

          if (
            !(
              parseFloat(queryParams.get("lat")) &&
              parseFloat(queryParams.get("lat")) &&
              queryParams.get("address")
            )
          ) {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                const geocoder = new window.google.maps.Geocoder();
                geocoder.geocode(
                  {
                    location: {
                      lat: position.coords.latitude,
                      lng: position.coords.longitude,
                    },
                  },
                  (results, status) => {
                    setAddress(results[0].formatted_address);
                  },
                );
                setCoords({
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
                });
              },
              (error) => {
                setAddress("Roma RM, Italia");
              },
              { enableHighAccuracy: true },
            );
          } else {
            setAddress(queryParams.get("address"));
          }
        } else {
          setAddress("Roma RM, Italia");
        }
      });
    } else {
      setAddress("Roma RM, Italia");
    }
  }, [loader]);

  return (
    <div className='FindPlace' data-testid='FindPlace'>
      <section className='showMap'>
        <Container>
          <Row>
            <Col xs={12} dm={4} md={4} lg={4}>
              <LazyBreadCrum />
              <h2>Scegli il posto più vicino a te</h2>
              <h5>Dove ti trovi?</h5>
              <div>
                <AddressAutocomplete
                  address={address ? address : null}
                  apiKey={apiKey}
                  isGeolocationEnabled={false}
                  loader={loader}
                  onPlaceSelected={(place) => {
                    if (place && place.geometry) {
                      setSelectedAddress(place);
                      setCoords({
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng(),
                      });
                    }
                  }}
                />
              </div>
              <div className='FindPlace' data-testid='FindPlace'>
                <div className='mapList'>
                  {foundLocations.length === 0 ? (
                    <p>Nessun centro di prelievo nella tua zona.</p>
                  ) : (
                    data
                      .filter((item) =>
                        foundLocations.some(
                          (location) => location.addressLocation.address === item.address,
                        ),
                      )
                      .map((filteredItem, index) => {
                        // Find the matching location to get the coords
                        const matchingLocation = foundLocations.find(
                          (location) =>
                            location.addressLocation.address === filteredItem.address,
                        );
                        console.log(matchingLocation);
                        return (
                          <MapCard
                            key={index}
                            data={filteredItem}
                            onClick={(data) => book(data)}
                            coords={
                              matchingLocation ? matchingLocation.coords : undefined
                            } // Pass the found coords or undefined if not found
                          />
                        );
                      })
                  )}
                </div>
              </div>
            </Col>
            <Col xs={12} dm={8} md={8} lg={8} className='map'>
              {isValidCoordinates && data && data.length > 0 ? (
                <GoogleMap
                  loader={loader}
                  apiKey={apiKey}
                  lat={coords.lat}
                  lng={coords.lng}
                  radius={10}
                  zoom={100}
                  locations={
                    data
                      ? data.map((d) => {
                          return {
                            location: d.address,
                            name: d.name,
                            id: d.id,
                          };
                        })
                      : []
                  }
                  onLocationsFound={(locs) => {
                    setFoundLocations(locs);
                    console.log(locs);
                  }}
                />
              ) : (
                <GoogleMap
                  loader={loader}
                  apiKey={apiKey}
                  lat={41.9027835}
                  lng={12.4963655}
                  radius={10}
                  zoom={100}
                  locations={
                    data
                      ? data.map((d) => {
                          if (d.city == "Rome") {
                            return {
                              location: d.address,
                              name: d.name,
                              id: d.id,
                            };
                          }
                        })
                      : []
                  }
                  onLocationsFound={(locs) => {
                    setFoundLocations(locs);
                    console.log(locs);
                  }}
                />
              )}
            </Col>
          </Row>
        </Container>
      </section>
      {/* <Container>
      <LazyFindMe/>
        <Row>
            <Col xs={12} dm={3} md={3} lg={3}>
                  <section className='showLocation'>
                      <MapCard />
                      <MapCard />
                      <MapCard />

                  </section>
            </Col>
            <Col xs={12} dm={9} md={9} lg={9}>
                  <section className='showMap'>
                      <Image src={Map} alt="Map" className='mapImg'  />
                  </section>
            </Col>
        </Row>
    </Container> */}
      {loading == true && <UseLoader />}
    </div>
  );
};

FindPlace.propTypes = {};

FindPlace.defaultProps = {};

export default FindPlace;
