import { Loader } from "@googlemaps/js-api-loader";
import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { Container, Image } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import GoogleMap from "../../Components/GoogleMap/GoogleMap";
import UseLoader from "../../Components/Loader/Loader";
import UseButton from "../../Components/UseButton/UseButton";
import LazyAvailableSlot from "../AvailableTimeSlote/AvailableTimeSlote";
import "./BookSlot.scss";
const BookSlot = () => {
  const [data, setData] = useState([]);
  const [center, setCenter] = useState(null);
  const [donationCenter, setDonationCenter] = useState([]);
  const [loader, setLoader] = useState(false);
  const functions = getFunctions();
  const [conventionLogo, setConventionLogo] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    loadSlot();
    setLoader(true);
  }, []);
  const apiKey = "AIzaSyACvPjW5jrUbbCOqnC-y-RqIaR5s4E_suU";
  const _loader = new Loader({
    apiKey: apiKey,
    version: "weekly",
    libraries: ["places", "geometry"], // Include the geometry library here
  });
  const loadSlot = () => {
    const getDoantionSlots = httpsCallable(functions, "getDonationCenterById");
    getDoantionSlots({
      id: location?.state?.reservation?.donationCenterId || location.state.centerId,
    })
      .then((response) => {
        console.log("slot response1", response);

        // const getDonationCenter = response?.data?.donationCenter?.map(item => item.donationCenter);
        // console.log('getDonationCenter',getDonationCenter)
        console.log("slot response2", response.data.data);
        console.log("slot response3", response.data.donationCenter);

        // const firstRecord = response?.data?.data?.filter((datas,index)=>index===0)
        // console.log('f record>>>111111',firstRecord);
        // setFirstRecord(firstRecord)

        try {
          const getPartnership = httpsCallable(functions, "getPartnershipById");
          getPartnership({ id: response.data.donationCenter.partnership })
            .then((_response) => {
              setConventionLogo(_response.data.logo);
            })
            .catch((error) => console.error("error", error));
        } catch (error) {}

        setData(response.data.data);
        setCenter(response.data.donationCenter);
        setDonationCenter(response.data.donationCenter);
      })
      .catch((error) => console.error("error", error))
      .finally(() => setLoader(false));
  };

  const back = () => {
    navigate(
      `/home/Scegli-il-posto?address=${center.address}&lat=${location.state.location?.lat}&lng=${location.state.location?.lng}`,
    );
  };
  console.log("select centerrrrrrrrrr", data);
  const location = useLocation();
  console.log(location);
  const getMap = () => {
    return (
      <GoogleMap
        loader={_loader}
        apiKey={apiKey}
        lat={location.state.location?.lat}
        zoom={100}
        lng={location.state.location?.lng}
        radius={10}
        locations={[
          {
            location: center?.address,
          },
        ]}
      />
    );
  };
  return (
    <div className='BookSlot' data-testid='BookSlot'>
      <section className='bookSlotes'>
        <Container>
          <h2>
            <UseButton
              name='adminBtnPrimary'
              variant='outline-primary'
              labels='Indietro'
              onClick={() => back()}
            />{" "}
            Concludi la tua prenotazione&nbsp;<span>presso {data[0]?.address}</span>{" "}
          </h2>
          {conventionLogo && (
            <p>
              Servizio di prenotazione in convenzione con{" "}
              <Image src={conventionLogo} alt='Image' className='partner' />{" "}
            </p>
          )}
          <div className='slotDetails'>
            <LazyAvailableSlot
              map={center ? getMap() : null}
              data={data}
              reservation={location.state.reservation}
              center={donationCenter}
            />
          </div>
        </Container>
        {loader == true && <UseLoader />}
      </section>
    </div>
  );
};

BookSlot.propTypes = {};

BookSlot.defaultProps = {};

export default BookSlot;
