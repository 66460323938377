import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import "./MenuBar.scss";
const MenuBar = ({ corporate }) => (
  <div className='MenuBar' data-testid='MenuBar'>
    <Navbar collapseOnSelect expand='lg' className='bg-body-tertiarys'>
      <Container>
        {/* <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand> */}
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='me-auto'>
            <Nav.Link href='/#Home'>Home</Nav.Link>
            <Nav.Link href='/#Diary'>Diario</Nav.Link>
            <Nav.Link href='/#Events'>Eventi</Nav.Link>
            {!corporate ? (
              <Nav.Link href='/#FAQ'>FAQ</Nav.Link>
            ) : (
              <Nav.Link href='/#webinar'>Webinar</Nav.Link>
            )}

            <Nav.Link href='/notifiche'>Notifiche</Nav.Link>
            <Nav.Link href='/quiz'>Quiz d’idoneità</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  </div>
);

MenuBar.propTypes = {};

MenuBar.defaultProps = {};

export default MenuBar;
