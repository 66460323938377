import React from "react";
import { Container } from "react-bootstrap";
import NotificationList from "../../Components/ListGroup/Notification/Notification";
import "./Notification.scss";
const Notification = () => {
  return (
    <div className='Notification' data-testid='Notification'>
      <Container>
        <NotificationList />
      </Container>
    </div>
  );
};

Notification.propTypes = {};

Notification.defaultProps = {};

export default Notification;
