import { format, isValid } from "date-fns";
import { getFunctions, httpsCallable } from "firebase/functions";
import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import "./Notification.scss";
const NotificationList = () => {
  const functions = getFunctions();
  const [notifications, setNotifications] = React.useState([{}]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isTruncated, setIsTruncated] = React.useState(
    new Array(notifications.length).fill(true),
  );
  React.useEffect(() => {
    setIsLoading(true);
    const getNotifications = httpsCallable(functions, "getNotifications");
    getNotifications()
      .then((result) => {
        setNotifications(result.data);
        setIsTruncated(new Array(result.data.length).fill(true));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [functions]);

  const toggleIsTruncated = (index) => {
    const newIsTruncated = [...isTruncated];
    newIsTruncated[index] = !newIsTruncated[index];
    setIsTruncated(newIsTruncated);
  };

  if (isLoading) {
    return null;
  }

  return (
    <div className='notificationList'>
      <Row className='justify-content-md-center justify-content-lg-between'>
        <Col xs={12} md={6}>
          <h2 className='fw-bold'>Le tue notifiche</h2>
        </Col>
        <Col xs={12} md={6}>
          <div className='d-flex justify-content-lg-end justify-content-md-center'>
            <Button onClick={() => true}>Segna tutte come lette</Button>
          </div>
        </Col>
      </Row>
      {notifications && notifications.length ? (
        notifications.map((notification, index) => {
          const date = new Date(
            notification?.createdAt?._seconds * 1000 +
              notification?.createdAt?._nanoseconds / 1000000,
          );
          const isValidDate = isValid(date);
          const formattedDate = isValidDate ? format(date, "dd/MM/yy") : "";
          const formattedTime = isValidDate ? format(date, "HH:mm") : "";
          return (
            <Row
              key={notification.id}
              className='justify-content-md-center justify-content-lg-between my-3 py-3 px-5 bg-body-secondary align-items-center'
            >
              <Col xs={12} md={8}>
                <div>
                  {isTruncated[index] ? (
                    <div className='d-flex flex-row align-items-center'>
                      <p
                        className='d-inline-block text-truncate lh-1 mb-0'
                        style={{ maxWidth: "30vw" }}
                        onClick={() => toggleIsTruncated(index)}
                      >
                        {notification.message}
                      </p>
                      <p
                        className='fw-bold lh-1 mb-0'
                        onClick={() => toggleIsTruncated(index)}
                      >
                        continua a leggere
                      </p>
                    </div>
                  ) : (
                    <p className='lh-1 mb-0' onClick={() => toggleIsTruncated(index)}>
                      {notification.message}
                    </p>
                  )}
                </div>
              </Col>
              <Col
                xs={12}
                md={4}
                className='d-flex flex-column align-items-lg-end align-items-md-center'
              >
                <p className='fw-bold text-primary lh-1 mb-1'>{formattedDate}</p>
                <p className='fw-bold text-primary lh-1'>{formattedTime}</p>
              </Col>
            </Row>
          );
        })
      ) : (
        <p className='fw-bold text-primary'>Nessuna notifica da visualizzare</p>
      )}
    </div>
  );
};
export default NotificationList;
