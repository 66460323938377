import { getAuth } from "firebase/auth";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../Hookes/useAuth";

const auth = getAuth();

const PrivateRoutes = () => {
  const { uid, role, loading } = useAuth();
  if (loading) return null;
  return !loading && uid ? <Outlet /> : <Navigate to='/login' />;
};

export default PrivateRoutes;
