import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { useNavigate } from "react-router-dom";
import UseLoader from "../../Components/Loader/Loader";
import Modal from "../../Components/Modal/Modal";
import SearchFilter from "../../Components/SearchFilter/SearchFilter";
import UseTopBar from "../../Components/TopBar/TopBar";
import UseButton from "../../Components/UseButton/UseButton";
import "./Conventions.scss";
const Conventions = () => {
  const navigate = useNavigate();
  const functions = getFunctions();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [selectedorderBy, setSelectedOrderBy] = useState(null);
  const [selectedCities, setSelectedCities] = useState([]);
  const [openFilterOne, setOpenFilterOne] = useState();
  const [openFilterTwo, setOpenFilterTwo] = useState();

  const [loader, setLoader] = useState(true);
  const [conventionToDelete, setConventionToDelete] = useState(null);
  const [deleteConventionModalOpen, setDeleteConventionModalOpen] = useState(false);
  const [partnershipCities, setPartnershipsCities] = useState([]);
  const addConventions = () => {
    navigate("/Convenzioni/Aggiungi-convenzione");
  };
  useEffect(() => {
    // const result = data.length/rowsPerPage;
    // const finalValue = Math.round(result)
    // setTotalPage(finalValue-1);

    getPartnership();
    getFilters();
  }, []);

  const csvData = useMemo(() => data, [data]);

  const getFilters = () => {
    try {
      const getPartnershipsScities = httpsCallable(functions, "getPartnershipsCities");

      getPartnershipsScities()
        .then((response) => {
          if (response.data && response.data.length > 0) {
            setPartnershipsCities(
              response.data.map((d) => {
                return {
                  value: d,
                  label: d,
                };
              }),
            );
          }
        })
        .catch((error) => console.error("error", error));
    } catch (error) {
      console.log("error", error);
    }
  };
  const getPartnership = (data) => {
    try {
      const getPartnership = httpsCallable(functions, "getPartnerships");
      getPartnership(data)
        .then((response) => {
          setLoader(false);
          setData(response.data);
        })
        .catch((error) => console.error("error", error));
    } catch (error) {
      console.log("error", error);
    }
  };

  const removeLocations = (partnershipId) => {
    setConventionToDelete(partnershipId);
    setDeleteConventionModalOpen(true);
  };

  const removeConventionConfirm = (partnershipId) => {
    const deletePartnership = httpsCallable(functions, "deletePartnership");
    setConventionToDelete(null);
    setDeleteConventionModalOpen(false);
    setLoader(true);
    deletePartnership({ id: partnershipId })
      .then((response) => {
        setLoader(false);
        window.location.reload();
      })
      .catch((error) => {
        console.error("error", error);
        setLoader(false);
      });
  };

  const removeConventionCancel = () => {
    setConventionToDelete(null);
    setDeleteConventionModalOpen(false);
  };

  const editPartnership = (partnershipId, name) => {
    // console.log('location iddddddddddd',partnershipId);
    navigate("/Convenzioni/Modificare-convenzione", {
      state: {
        editPartnershipId: partnershipId,
        agencyName: name,
        editPartnershipStatus: true,
      },
    });
  };

  const filterCities = (value) => {
    setSelectedCities((prev) => {
      if (prev.includes(value)) {
        return prev.filter((val) => val !== value);
      }

      return [...prev, value];
    });
  };

  const getPartnershipsWithFilters = (reset) => {
    getPartnership(
      reset
        ? null
        : {
            filters: {
              orderBy: selectedorderBy,
              city: selectedCities,
            },
          },
    );
    setOpenFilterOne(false);
    setOpenFilterTwo(false);
  };

  const csvUserData = [
    ["Nome", "Città", "Centro donazioni", "Data iscrizione"],
    ...csvData.map(({ agencyName, city, donationCenterName, createdAt }) => [
      agencyName,
      city,
      donationCenterName,
      new Date(
        createdAt._seconds * 1000 + createdAt._nanoseconds / 1000000,
      ).toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
      }),
    ]),
  ];

  return (
    <div className='Conventions' data-testid='Conventions'>
      <UseTopBar />
      <Row>
        <Col xs={12} sm={12} md={12} lg={3}>
          <h2>Convenzioni</h2>
        </Col>
        <Col xs={12} sm={12} md={12} lg={3}>
          <Form.Control
            className='search'
            type='text'
            placeholder='Cerca una convenzione'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Col>
        <Col xs={12} sm={12} md={12} lg={5} className='d-flex buttons'>
          <UseButton
            name='adminBtnPrimary'
            variant='btn btn-outline-primary'
            labels='Cancella filtri'
            onClick={() => {
              setSelectedCities([]);
              setSelectedOrderBy(null);
              getPartnershipsWithFilters(true);
            }}
          />
          <div className='adminBtnPrimary UseButton'>
            <CSVLink
              name='adminBtnPrimary'
              className='btn btn-btn btn-outline-primary'
              filename='convenzioni.csv'
              data={csvUserData}
              style={{ borderRadius: 12 }}
            >
              Esporta CSV
            </CSVLink>
          </div>
          <UseButton
            name='adminBtnPrimary'
            variant='primary'
            labels='Aggiungi convenzione'
            onClick={addConventions}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={6} lg={3}>
          <p>Ordina per</p>
          {/* <Form.Select aria-label="Default select example">
              <option>Tutte</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </Form.Select> */}
          <SearchFilter
            open={openFilterOne}
            setOpen={setOpenFilterOne}
            onConfirm={() => {
              getPartnershipsWithFilters();
            }}
            data={{
              name: "donors",
              options: [
                {
                  label: "Ultimi eventi creati",
                  value: "lastEvents",
                },
                {
                  label: "Numero donazioni - crescente",
                  value: "donationsNrAsc",
                },
                {
                  label: "Numero donazioni - decrescente",
                  value: "donationsNrDesc",
                },
              ],
            }}
            type='radio'
            onChangeFilterValue={setSelectedOrderBy}
            placeholder={"Cerca filtro"}
            selectedValue={selectedorderBy}
          />
        </Col>

        <Col xs={12} sm={12} md={6} lg={3}>
          <p>Città</p>
          {/* <Form.Select aria-label="Default select example">
              <option>Tutti</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </Form.Select> */}
          <SearchFilter
            open={openFilterTwo}
            setOpen={setOpenFilterTwo}
            data={partnershipCities}
            selectedValue={selectedCities}
            onConfirm={() => {
              getPartnershipsWithFilters();
            }}
            onChangeFilterValue={filterCities}
            placeholder='Filtra per città'
          />
        </Col>
      </Row>
      <Table hover responsive>
        <thead>
          <tr>
            <th>Name</th>
            <th>Città</th>
            <th>Centro donazioni</th>
            <th>Raccolte attive</th>
            <th>Data iscrizione</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data
              .filter((item) => {
                return search.toLowerCase() === ""
                  ? item
                  : item.agencyName.toLowerCase().includes(search.toLowerCase());
              })
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((datas, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <span onClick={() => editPartnership(datas.id, datas.agencyName)}>
                        {datas.agencyName}
                      </span>
                    </td>
                    <td>{datas.city}</td>
                    <td>{datas.donationCenterName}</td>
                    <td>-</td>
                    <td>
                      {/* {datas.createdAt} */}
                      {new Date(
                        datas.createdAt._seconds * 1000 +
                          datas.createdAt._nanoseconds / 1000000,
                      ).toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "2-digit",
                      })}
                    </td>
                    <td className='d-flex'>
                      <Button
                        className='edit'
                        onClick={() => editPartnership(datas.id, datas.agencyName)}
                      >
                        <svg
                          width='16'
                          height='18'
                          viewBox='0 0 16 18'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M3.345 12.2419L11.7967 3.79026L10.6183 2.61193L2.16667 11.0636V12.2419H3.345ZM4.03583 13.9086H0.5V10.3728L10.0292 0.843598C10.1854 0.687372 10.3974 0.599609 10.6183 0.599609C10.8393 0.599609 11.0512 0.687372 11.2075 0.843598L13.565 3.2011C13.7212 3.35737 13.809 3.56929 13.809 3.79026C13.809 4.01123 13.7212 4.22316 13.565 4.37943L4.03583 13.9086ZM0.5 15.5753H15.5V17.2419H0.5V15.5753Z'
                            fill='#2264D1'
                          />
                        </svg>
                      </Button>
                      <Button
                        className='delete'
                        onClick={() => removeLocations(datas.id)}
                      >
                        <svg
                          width='14'
                          height='16'
                          viewBox='0 0 14 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M2.83398 15.5C2.37565 15.5 1.98343 15.3369 1.65732 15.0108C1.33121 14.6847 1.16787 14.2922 1.16732 13.8333V3H0.333984V1.33333H4.50065V0.5H9.50065V1.33333H13.6673V3H12.834V13.8333C12.834 14.2917 12.6709 14.6842 12.3448 15.0108C12.0187 15.3375 11.6262 15.5006 11.1673 15.5H2.83398ZM11.1673 3H2.83398V13.8333H11.1673V3ZM4.50065 12.1667H6.16732V4.66667H4.50065V12.1667ZM7.83398 12.1667H9.50065V4.66667H7.83398V12.1667Z'
                            fill='#2264D1'
                          />
                        </svg>
                      </Button>
                    </td>
                  </tr>
                );
              })
          ) : (
            <tr>
              <td colSpan='6' style={{ textAlign: "center" }}>
                Nessun dato
              </td>
            </tr>
          )}

          {/* {data.length>0?(data.map((datas,index)=>{
              return <tr key={index}>
                        <td>{datas.agencyName}</td>
                        <td>{datas.city}</td>
                        <td>{datas.agencyName}</td>
                        <td>-</td>
                        <td>-
                          {new Date(datas.createdAt._seconds * 1000 + datas.createdAt._nanoseconds / 1000000).toDateString()}&nbsp;&nbsp;
                          {new Date(datas.createdAt._seconds * 1000 + datas.createdAt._nanoseconds / 1000000).toLocaleTimeString()}
                          </td>
                        <td>
                   <Button className='edit' onClick={()=>editPartnership(datas.id,datas.agencyName)}><svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.345 12.2419L11.7967 3.79026L10.6183 2.61193L2.16667 11.0636V12.2419H3.345ZM4.03583 13.9086H0.5V10.3728L10.0292 0.843598C10.1854 0.687372 10.3974 0.599609 10.6183 0.599609C10.8393 0.599609 11.0512 0.687372 11.2075 0.843598L13.565 3.2011C13.7212 3.35737 13.809 3.56929 13.809 3.79026C13.809 4.01123 13.7212 4.22316 13.565 4.37943L4.03583 13.9086ZM0.5 15.5753H15.5V17.2419H0.5V15.5753Z" fill="#2264D1"/>
</svg></Button>
<Button className='delete' onClick={()=>removeLocations(datas.id)}>
                      <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.83398 15.5C2.37565 15.5 1.98343 15.3369 1.65732 15.0108C1.33121 14.6847 1.16787 14.2922 1.16732 13.8333V3H0.333984V1.33333H4.50065V0.5H9.50065V1.33333H13.6673V3H12.834V13.8333C12.834 14.2917 12.6709 14.6842 12.3448 15.0108C12.0187 15.3375 11.6262 15.5006 11.1673 15.5H2.83398ZM11.1673 3H2.83398V13.8333H11.1673V3ZM4.50065 12.1667H6.16732V4.66667H4.50065V12.1667ZM7.83398 12.1667H9.50065V4.66667H7.83398V12.1667Z" fill="#2264D1"/>
</svg>
</Button>
                    </td>
                     </tr>
            })):(<tr><td colSpan="6" style={{textAlign:'center'}}>No data</td></tr>)} */}
        </tbody>
      </Table>
      {data.length >= 10 && (
        <div className='pagination'>
          {page + 1} - {Math.round(data.length / rowsPerPage)}
          <button className='prev' onClick={() => setPage(page - 1)} disabled={page == 0}>
            <svg
              width='8'
              height='13'
              viewBox='0 0 8 13'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M7.71094 2.12094L3.12094 6.71094L7.71094 11.3009L6.29094 12.7109L0.290937 6.71094L6.29094 0.710938L7.71094 2.12094Z'
                fill='#787885'
              />
            </svg>
          </button>
          <button
            className='next'
            onClick={() => setPage(page + 1)}
            disabled={Number(page) == Math.round(Math.abs(data.length / rowsPerPage - 1))}
          >
            <svg
              width='8'
              height='13'
              viewBox='0 0 8 13'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M0.295078 2.11312L4.87508 6.70312L0.295078 11.2931L1.70508 12.7031L7.70508 6.70312L1.70508 0.703125L0.295078 2.11312Z'
                fill='#787885'
              />
            </svg>
          </button>
        </div>
      )}
      {loader == true && <UseLoader />}
      {deleteConventionModalOpen && (
        <Modal
          title='Sei sicuro?'
          onConfirmReturnData={conventionToDelete}
          onCancel={removeConventionCancel}
          onConfirm={removeConventionConfirm}
        >
          <p>Una volta eliminato non potrai tornare indietro</p>
        </Modal>
      )}
    </div>
  );
};

Conventions.propTypes = {};

Conventions.defaultProps = {};

export default Conventions;
