import React from "react";
import { ListGroup } from "react-bootstrap";
import "./NotificationNumber.scss";
const NotificationNumberList = ({ list, getFunction, search, noData, activeClass }) => {
  const filteredList = list?.filter(
    (item) => search?.toLowerCase() === "" || item?.toLowerCase()?.includes(search),
  );

  // console.log('activeClass',activeClass)
  return (
    <div className='notificationNumberList'>
      <ListGroup>
        {filteredList.length > 0 ? (
          filteredList.map((data, index) => (
            <ListGroup.Item
              className={activeClass?.map((datas) => (datas == data ? "active" : ""))}
              key={index}
              onClick={() => getFunction(data)}
            >
              {data}
            </ListGroup.Item>
          ))
        ) : (
          <ListGroup.Item>{noData}</ListGroup.Item>
        )}
        {/* {
  list?.filter((item) => {
    return search.toLowerCase() === '' ? item : item.name.toLowerCase().includes(search);
  }).length > 0 ? (
    list?.filter((item) => {
      return search.toLowerCase() === '' ? item : item.name.toLowerCase().includes(search);
    }).map((data, index) => {
      return <ListGroup.Item key={index} onClick={() => selectDonationCenter(data?.id)}>{data?.name}</ListGroup.Item>
    })
  ) : (
    <ListGroup.Item>No match found</ListGroup.Item>
  )
}          */}
      </ListGroup>
    </div>
  );
};
export default NotificationNumberList;
