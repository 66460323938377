import { getFunctions, httpsCallable } from "firebase/functions";
import { useCallback, useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "../../../../node_modules/react-responsive-carousel/lib/styles/carousel.min.css";
import CardTwo from "../../Card/CardTwo/CardTwo";
import "./CarouselSliderThree.scss";
const CarouselSliderThree = ({ companyId }) => {
  const [data, setData] = useState([]);
  const functions = getFunctions();
  const getWebinar = useCallback(() => {
    try {
      const getWeninar = httpsCallable(functions, "getWebinars");
      getWeninar({ company: companyId })
        .then((response) => {
          console.log("webinar rsponse", response);
          setData(response.data);
        })
        .catch((error) => console.error("error", error));
    } catch (error) {
      console.error("error", error);
    }
  }, [companyId, functions]);
  useEffect(() => {
    getWebinar();
  }, [getWebinar]);

  return (
    <div className='CaroselSliderThree'>
      <Carousel>
        {data?.map((data, index) => {
          return <CardTwo key={index} data={data} />;
        })}
      </Carousel>
      {data.length === 0 && (
        <p style={{ width: "100%", textAlign: "center" }}>Nessun webinar da mostrare</p>
      )}
    </div>
  );
};
export default CarouselSliderThree;
