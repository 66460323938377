import { getAuth } from "firebase/auth";
import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import LazyAdminSidebar from "../AdminSidebar/AdminSidebar";
import "./AdminLayout.scss";

const auth = getAuth();

const AdminLayout = ({ children }) => {
  const navigate = useNavigate();
  useEffect(() => {
    let customAttrs = JSON.parse(auth?.currentUser?.reloadUserInfo.customAttributes);
    if (customAttrs?.role !== "admin") {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <div className='AdminLayout' data-testid='AdminLayout'>
      <LazyAdminSidebar></LazyAdminSidebar>

      <div className='innerContent'>
        <Container className='customContainer'>
          {/* <LazyBreadCrum /> */}
          {/* <UseTopBar /> */}
          {children}
        </Container>
      </div>
    </div>
  );
};

AdminLayout.propTypes = {};

AdminLayout.defaultProps = {};

export default React.memo(AdminLayout);
