import "./WebinarImageUpload.scss";
const WebinarImageUpload = ({ label, click }) => {
  return (
    <div className='webinarImageUpload'>
      <label for='webinar'>
        {label}
        <input type='file' accept='image/*' onChange={click} id='webinar' />
      </label>
    </div>
  );
};
export default WebinarImageUpload;
