import React from "react";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./CardFive.scss";

const CardFive = ({ _alert }) => {
  const navigate = useNavigate();

  const nav = () => {
    navigate("/Gestione-avvisi/Scheda-avviso", {
      state: { _alert },
    });
  };

  return (
    <div className='CardFive'>
      <Card>
        <Card.Body>
          <Card.Text>
            {_alert.name}
            <Card.Link
              as='button'
              onClick={nav}
              style={{
                border: "none",
                background: "none",
                padding: 0,
                margin: 0,
                textDecoration: "underline",
                color: "#2264D1",
              }}
            >
              <svg
                width='20'
                height='20'
                viewBox='0 0 20 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M5.345 13.24L13.7967 4.78831L12.6183 3.60998L4.16667 12.0616V13.24H5.345ZM6.03583 14.9066H2.5V11.3708L12.0292 1.84164C12.1854 1.68542 12.3974 1.59766 12.6183 1.59766C12.8393 1.59766 13.0512 1.68542 13.2075 1.84164L15.565 4.19914C15.7212 4.35542 15.809 4.56734 15.809 4.78831C15.809 5.00928 15.7212 5.22121 15.565 5.37748L6.03583 14.9066ZM2.5 16.5733H17.5V18.24H2.5V16.5733Z'
                  fill='#2264D1'
                />
              </svg>
            </Card.Link>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
};

export default CardFive;
