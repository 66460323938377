import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import UseLoader from "../../Components/Loader/Loader";
import UseButton from "../../Components/UseButton/UseButton";
import FlowerRosso from "../../assets/images/Flower Rosso.png";
import Donate from "../../assets/images/donate.png";
import Events from "../../assets/images/events.jpg";
import Header from "../Header/Header";
import "./BookNow.scss";

const BookNow = () => {
  const [data, setData] = useState([]);
  const [doncationCenter, setDonationCenter] = useState([]);
  const [centerId, setCenterId] = useState("");
  const [date, setDate] = useState("");
  const [userId, setUserId] = useState("");
  const [slotId, setSlotId] = useState(0);
  const [currentSlot, setCurrentSlot] = useState("");
  const [intervalId, setIntervalId] = useState("");
  const [slotDetails, setSlotDetails] = useState("");
  const [address, setAddress] = useState("");
  const [map, setMap] = useState("");
  const [companyDetails, setCompanyDetails] = useState(null);
  const [company, setCompany] = useState([]);
  const [locations, setLocations] = useState([]);
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const functions = getFunctions();
  const { id } = useParams();

  useEffect(() => {
    let id = localStorage.getItem("uid");
    setUserId(id);

    if (data) {
      let dc = data.filter((d) => d.id === location.state.event)[0];
      setCenterId(dc?.donationCenterId);
      setDate(dc?.date);
      setSlotId(dc?.id);
      setAddress(dc?.address);
    }

    // getEvents();
    getEventList();
    // const timer = setTimeout(() => {
    //   setLoader(false)

    // }, 1600);

    // return () => {clearTimeout(timer);}
  }, []);

  const register = (datas) => {
    console.log("datas", datas);
    const data = {
      donationCenterId: centerId,
      date: date,
      userId: userId,
      timeslotId: slotId,
      intervalId: datas.id,
    };
    setCurrentSlot(datas.id);
    setIntervalId(datas.id);
    setSlotDetails(datas.slot);
    // navigate('/register')
    // navigate('/guest-register',{state:{slotDetails:data}})
  };

  function formatDateToItalian(dateStr) {
    const days = [
      "Dom", // Sunday
      "Lun", // Monday
      "Mar", // Tuesday
      "Mer", // Wednesday
      "Gio", // Thursday
      "Ven", // Friday
      "Sab", // Saturday
    ];

    const months = [
      "Gennaio",
      "Febbraio",
      "Marzo",
      "Aprile",
      "Maggio",
      "Giugno",
      "Luglio",
      "Agosto",
      "Settembre",
      "Ottobre",
      "Novembre",
      "Dicembre",
    ];

    const date = new Date(dateStr);

    const dayOfWeek = days[date.getDay()];
    const dayOfMonth = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${dayOfWeek} ${dayOfMonth} ${month} `;
  }

  const getEventList = () => {
    try {
      const getDonionCenter = httpsCallable(functions, "getDonationCenterById");
      getDonionCenter({ id })
        .then((response) => {
          setData(
            /*response.data.data.filter(item => {
          const itemDate = new Date(item.date);
          const now = new Date();
          return itemDate > now;
        })*/ response.data.data,
          );
          setLoader(false);
          let address =
            response.data.donationCenter?.address.split(" ").join("+") +
            "+" +
            response.data.donationCenter.city;
          setMap("https://www.google.com/maps/place/" + address);
          let dc = response.data.data.filter((d) => d.id === location.state.event)[0];
          setDonationCenter(dc);
          setCenterId(dc?.donationCenterId);
          setDate(dc?.date);
          setSlotId(dc?.id);
          setAddress(dc?.address);
        })
        .catch((e) => {
          alert(e);
          navigate("/404");
        });
    } catch (error) {
      console.error("error", error);
    }
  };

  const userRegister = () => {
    const data = {
      donationCenterId: centerId,
      date: date,
      userId: userId,
      timeslotId: slotId,
      intervalId: intervalId,
      address,
      slot: slotDetails,
      formattedDate: formatDateToItalian(date),
    };
    if (localStorage.getItem("email")) {
      navigate("/register", {
        state: { slotDetails: data, slot: slotDetails, address: address },
      });
    } else {
      navigate("/guest-register", {
        state: { slotDetails: data, slot: slotDetails, address: address },
      });
    }
  };

  // const getEvents = () => {
  //   try{
  //     // const getLocations = httpsCallable(functions,"getLocations")
  //     const getEvents = httpsCallable(functions,"getEventById")
  //     getEvents({id:location.state.eventId})
  //     .then((response)=>{
  //         console.log('response',response);
  //         let company = response.data.company;
  //         let location = response.data.location
  //         console.log('company id',company,'location',location)

  //         const getCompanyList = httpsCallable(functions,"getCompanyById")
  //         getCompanyList({id:company})
  //         .then((response)=>{
  //             console.log('company response',response)
  //             setCompany(response.data)
  //         }).catch((error)=>console.error('company error',error));

  //         const getDonarList = httpsCallable(functions,"getDonationCenterById")
  //         getDonarList({id:location})
  //         .then((response)=>{
  //             console.log('donation center response',response)
  //             setLocations(response.data)
  //         }).catch((error)=>console.error('company error',error));

  //         // setData(response.data);
  //     })
  //     .catch((error)=>console.error('error',error));
  //   }catch(error){
  //     console.log('error',error);
  //   }
  // }

  useEffect(() => {
    if (data) {
      try {
        const getEventsById = httpsCallable(functions, "getCompanyEventById");
        getEventsById({ id: doncationCenter.id })
          .then((response) => {
            setCompany(response.data);
          })
          .catch((error) => {});
      } catch (error) {
        console.error("error", error);
      }
    }
  }, [data]);

  useEffect(() => {
    if (company) {
      const getCompanyById = httpsCallable(functions, "getCompanyById");
      getCompanyById({ id: company.company })
        .then((response) => {
          setCompanyDetails(response.data);
        })
        .catch((error) => console.error("error", error));
    }
  }, [company]);

  return (
    <div className='BookNow' data-testid='BookNow'>
      <Header
        image={
          <div className='logos'>
            {companyDetails && companyDetails.logo ? (
              <div
                className='company'
                style={{ backgroundImage: `url(${companyDetails?.logo})` }}
              ></div>
            ) : (
              <div className='rosso'></div>
            )}
          </div>
        }
      />

      <Container>
        <h2>
          {" "}
          Concludi la tua prenotazione&nbsp;
          <span>
            Presso {company.donationCenterAddress}, {doncationCenter?.city}
          </span>
        </h2>

        {/* <section className='banner'></section> */}
        <section className='donnerDetails'>
          <Row>
            <Col xs={12} sm={8} md={8} lg={8}>
              <h3>{doncationCenter?.eventName}</h3>
              <div className='info'>
                <svg
                  width='26'
                  height='25'
                  viewBox='0 0 26 25'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M13 7.23047V12.2305L16.75 15.9805L13 7.23047ZM24.25 12.2305C24.25 13.7078 23.959 15.1707 23.3936 16.5357C22.8283 17.9006 21.9996 19.1408 20.955 20.1854C19.9103 21.2301 18.6701 22.0587 17.3052 22.6241C15.9403 23.1895 14.4774 23.4805 13 23.4805C11.5226 23.4805 10.0597 23.1895 8.69481 22.6241C7.3299 22.0587 6.08971 21.2301 5.04505 20.1854C4.00039 19.1408 3.17172 17.9006 2.60636 16.5357C2.04099 15.1707 1.75 13.7078 1.75 12.2305C1.75 9.24678 2.93526 6.3853 5.04505 4.27552C7.15483 2.16573 10.0163 0.980469 13 0.980469C15.9837 0.980469 18.8452 2.16573 20.955 4.27552C23.0647 6.3853 24.25 9.24678 24.25 12.2305Z'
                    stroke='#292A31'
                    strokeWidth='1.875'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
                {doncationCenter?.startTime} - {doncationCenter?.endTime}
              </div>
              <div className='info'>
                <svg
                  width='30'
                  height='30'
                  viewBox='0 0 30 30'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M25 12.5C25 18.0228 15 27.5 15 27.5C15 27.5 5 18.0228 5 12.5C5 6.97715 9.47715 2.5 15 2.5C20.5228 2.5 25 6.97715 25 12.5Z'
                    stroke='#292A31'
                    strokeWidth='2'
                  />
                  <path
                    d='M15 13.75C15.6904 13.75 16.25 13.1904 16.25 12.5C16.25 11.8096 15.6904 11.25 15 11.25C14.3096 11.25 13.75 11.8096 13.75 12.5C13.75 13.1904 14.3096 13.75 15 13.75Z'
                    fill='#292A31'
                    stroke='#292A31'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>{" "}
                {doncationCenter?.address}
              </div>
              <div className='info'>
                <Link to={map} target='_blank'>
                  <svg
                    width='26'
                    height='26'
                    viewBox='0 0 26 26'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M1.5 1.23047L11.5714 24.7305L14.9286 14.659L25 11.3019L1.5 1.23047Z'
                      stroke='#292A31'
                      strokeWidth='1.67857'
                      strokeLinejoin='round'
                    />
                  </svg>
                  Vai a Google Maps
                </Link>
              </div>
              <div className='divider'></div>
              <h5>A che ora vorresti donare?</h5>
              {window.location.href.includes("tPlM6Bb3iVpYl82NHouH") && (
                <div class='slotFull'>
                  <h4>Disponibilità terminate</h4>
                </div>
              )}
              {!window.location.href.includes("tPlM6Bb3iVpYl82NHouH") &&
                data
                  .filter((d) => d.id === location.state.event)[0]
                  ?.timeIntervals?.map((item, index) => {
                    return (
                      <Button
                        disabled={
                          !(
                            item?.bookedUsers?.length == 0 ||
                            item?.bookedUsers?.filter((u) => u.status == "pending")
                              .length < doncationCenter?.personPerSlot
                          )
                        }
                        className={
                          !(
                            item?.bookedUsers?.length == 0 ||
                            item?.bookedUsers?.filter((u) => u.status == "pending")
                              .length < doncationCenter?.personPerSlot
                          )
                            ? "booked"
                            : currentSlot == item.id
                            ? "btnActive"
                            : "normalBtn"
                        }
                        key={index}
                        onClick={() => register(item)}
                      >
                        <svg
                          className='tick'
                          width='21'
                          height='20'
                          viewBox='0 0 21 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M17.5 4C17.22 4 16.97 4.11 16.79 4.29L7.5 13.59L4.21 10.3C4.03 10.11 3.78 10 3.5 10C2.95 10 2.5 10.45 2.5 11C2.5 11.28 2.61 11.53 2.79 11.71L6.79 15.71C6.97 15.89 7.22 16 7.5 16C7.78 16 8.03 15.89 8.21 15.71L18.21 5.71C18.39 5.53 18.5 5.28 18.5 5C18.5 4.45 18.05 4 17.5 4Z'
                            fill='#FEF2F1'
                          />
                        </svg>
                        <svg
                          className='booked'
                          width='21'
                          height='20'
                          viewBox='0 0 21 20'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M10.5 0C4.98 0 0.5 4.48 0.5 10C0.5 15.52 4.98 20 10.5 20C16.02 20 20.5 15.52 20.5 10C20.5 4.48 16.02 0 10.5 0ZM2.5 10C2.5 5.58 6.08 2 10.5 2C12.35 2 14.05 2.63 15.4 3.69L4.19 14.9C3.13 13.55 2.5 11.85 2.5 10ZM10.5 18C8.65 18 6.95 17.37 5.6 16.31L16.81 5.1C17.87 6.45 18.5 8.15 18.5 10C18.5 14.42 14.92 18 10.5 18Z'
                            fill='#9696A0'
                          ></path>
                        </svg>{" "}
                        {item.slot.trim().slice(0, 5)}
                      </Button>
                    );
                  })}

              {/* <Button onClick={register}>Lun 10 Novembre</Button>
  <Button onClick={register} className='booked'><svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M10.5 0C4.98 0 0.5 4.48 0.5 10C0.5 15.52 4.98 20 10.5 20C16.02 20 20.5 15.52 20.5 10C20.5 4.48 16.02 0 10.5 0ZM2.5 10C2.5 5.58 6.08 2 10.5 2C12.35 2 14.05 2.63 15.4 3.69L4.19 14.9C3.13 13.55 2.5 11.85 2.5 10ZM10.5 18C8.65 18 6.95 17.37 5.6 16.31L16.81 5.1C17.87 6.45 18.5 8.15 18.5 10C18.5 14.42 14.92 18 10.5 18Z" fill="#9696A0"/>
</svg>&nbsp;&nbsp;Mar 11 Novembre</Button>
  <Button onClick={register}>7:10</Button>
  <Button onClick={register}>8:15</Button>
  <Button onClick={register}>9:10</Button>
  <Button onClick={register}>9:20</Button> */}

              <div className='divider'></div>

              <h5>Altre informazioni</h5>
              <div className='profileInfo d-flex align-items-center'>
                <div>
                  <img
                    src={companyDetails?.logo || FlowerRosso}
                    alt='Profile'
                    style={{ width: "70px" }}
                  />
                </div>
                <div>
                  <h4>{company?.contactPersonName || "-"}</h4>
                  <h6>
                    <span>
                      {company?.contactPersonEmail || company?.contactPersonPhone || "-"}
                    </span>
                  </h6>
                </div>
              </div>
              <p className='mt-3'>{company?.description}</p>
            </Col>
            <Col
              xs={12}
              sm={4}
              md={4}
              lg={4}
              className={currentSlot == "" ? "" : "activeSlot"}
            >
              <img
                src={
                  window.location.href.includes("tPlM6Bb3iVpYl82NHouH")
                    ? Events
                    : company?.image
                    ? company?.image
                    : Donate
                }
                style={{ borderRadius: "12px", width: "100%" }}
                alt='Donate'
              />
              <UseButton
                onClick={userRegister}
                disabled={currentSlot == ""}
                name='adminBtnPrimary'
                variant='primary'
                labels='Prenota ora la donazione'
              />
            </Col>
          </Row>
        </section>
      </Container>

      {loader == true && <UseLoader />}
    </div>
  );
};

BookNow.propTypes = {};

BookNow.defaultProps = {};

export default BookNow;
