import React from "react";
import { Card } from "react-bootstrap";
import "./CardBox.scss";
const CardBox = ({ value, text, styleBg, color }) => {
  return (
    <div className='CardBox'>
      <Card style={styleBg}>
        <Card.Body>
          <h5>{text}</h5>
          <h2 style={color}>{value}</h2>
        </Card.Body>
      </Card>
    </div>
  );
};
export default CardBox;
