import { getFunctions, httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import UseLoader from "../../../../Components/Loader/Loader";
import UseAlert from "../../../../Components/UseAlert/UseAlert";
import UseButton from "../../../../Components/UseButton/UseButton";

const NewBooking = ({ donationCenters, companies, userId, endAdd }) => {
  const functions = getFunctions();

  const [events, setEvents] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [bookingType, setBookingType] = useState("event");
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedDonationCenter, setSelectedDonationCenter] = useState(null);
  const [selectedDonationCenterDetails, setSelectedDonationCenterDetails] =
    useState(null);
  const [selectDonationCenterIntervalMax, setSelectDonationCenterIntervalMax] =
    useState(0);
  const [selectedintervalId, setSelectedIntervalId] = useState(null);
  const [selectedSlotId, setSelectedSlotId] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [availableDates, setAvailableDates] = useState([]);
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
  const [notes, setNotes] = useState(null);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [variant, setVariant] = useState("success");
  const [message, setMessage] = useState(null);

  const getAvailableDates = (donationCenterId) => {
    try {
      setSelectedDonationCenter(donationCenterId);
      setLoading(true);
      setSelectedDate("");
      setSelectedSlot("");
      const getslotsByCenter = httpsCallable(functions, "getSlotsByDonationCenter");
      getslotsByCenter({ donationCenterId })
        .then((response) => {
          if (response.data.length === 0) {
            setAvailableDates([]);
            setAvailableTimeSlots([]);
            setSelectedDate("");
            setSelectedSlot("");
          } else {
            const filteredDates = response.data.filter((slot) => {
              const slotDate = new Date(slot.date);
              const today = new Date();
              today.setHours(0, 0, 0, 0);
              return slotDate > today;
            });

            setAvailableDates(filteredDates);
            if (filteredDates.length > 0) {
              setSelectedDate(filteredDates[0].date);
            } else {
              setSelectedDate("");
              setAvailableTimeSlots([]);
              setSelectedSlot("");
            }
          }

          setLoading(false);
        })
        .catch((error) => {
          setAvailableDates([]);
          setAvailableTimeSlots([]);
          setSelectedDate(null);
          setLoading(false);
        });
    } catch (error) {
      setAvailableDates([]);
      setAvailableTimeSlots([]);
      setSelectedDate(null);
      setLoading(false);
    }
  };

  const getDonationCenter = (id) => {
    try {
      setLoading(true);
      const getCenter = httpsCallable(functions, "getDonationCenterById");
      getCenter({ id })
        .then((response) => {
          setLoading(false);
          setSelectedDonationCenterDetails(response.data);
        })
        .catch((error) => console.error("error", error));
    } catch (error) {
      console.log("error", error);
    }
  };

  const getCompanyEvents = (id) => {
    try {
      setLoading(true);
      const getEvents = httpsCallable(functions, "getUpcomingCompanyEvents");
      getEvents({ company: id })
        .then((response) => {
          setLoading(false);
          setEvents(response.data);
        })
        .catch((error) => console.error("error", error));
    } catch (error) {
      console.log("error", error);
    }
  };

  const createReservation = () => {
    try {
      setLoading(true);
      const setFeatureReservation = httpsCallable(functions, "setReservation");
      setFeatureReservation({
        donationCenterId: selectedDonationCenterDetails.donationCenter.id,
        date: selectedDate,
        userId: userId,
        timeslotId: selectedSlotId,
        intervalId: selectedintervalId,
      })
        .then((response) => {
          setLoading(false);
          setShow(true);
          setVariant("success");
          setMessage(response.data.message);
          setTimeout(() => {
            endAdd();
          }, 2000);
        })
        .catch((error) => {
          setShow(true);
          setVariant("danger");
          setLoading(false);
          setMessage(error.message);
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (companies && events.length > 0 && donationCenters) {
      const companyIdsWithEvents = new Set(events.map((event) => event.company));
      setFilteredCompanies(
        companies.filter((company) => companyIdsWithEvents.has(company.id)),
      );
    }
  }, [companies, events, donationCenters]);

  useEffect(() => {
    if (filteredCompanies && filteredCompanies.length > 0) {
      setSelectedCompany(filteredCompanies[0].id);
      setSelectedEvent(events.filter((e) => e.company == filteredCompanies[0].id)[0].id);
    }
  }, [filteredCompanies]);

  useEffect(() => {
    if (selectedCompany) {
      setAvailableDates([]);
      setAvailableTimeSlots([]);
      getCompanyEvents(selectedCompany);
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (selectedEvent) {
      getAvailableDates(events.filter((e) => e.id == selectedEvent)[0].donationCenterId);
    }
  }, [selectedEvent]);

  useEffect(() => {
    if (selectedDate) {
      setAvailableTimeSlots(
        availableDates.filter((d) => d.date == selectedDate)[0].timeIntervals,
      );
      setSelectedSlot(
        availableDates.filter((d) => d.date == selectedDate)[0].timeIntervals[0]?.slot,
      );
      setSelectedSlotId(availableDates.filter((d) => d.date == selectedDate)[0].id);
    }
  }, [selectedDate]);

  useEffect(() => {
    if (bookingType == "center") {
      setSelectedDate("");
      setSelectedSlot("");
      setAvailableDates([]);
      setAvailableTimeSlots([]);
      getAvailableDates(selectedDonationCenter);
    } else {
      if (selectedEvent) {
        getAvailableDates(
          events.filter((e) => e.id == selectedEvent)[0].donationCenterId,
        );
      }
    }
  }, [bookingType]);

  useEffect(() => {
    if (selectedDonationCenter) {
      getAvailableDates(selectedDonationCenter);
      getDonationCenter(selectedDonationCenter);
    }
  }, [selectedDonationCenter]);

  useEffect(() => {
    if (selectedDonationCenterDetails) {
      let interval = selectedDonationCenterDetails.data.filter(
        (d) => d.date == selectedDate,
      )[0];
      setSelectDonationCenterIntervalMax(interval?.personPerSlot);
      setSelectedIntervalId(interval?.timeIntervals[0]?.id);
    }
  }, [selectedDonationCenterDetails]);
  console.log(selectedDonationCenter);
  return (
    <section className='addReservations'>
      Seleziona tipo di prenotazione
      <div className='eventTypeWrap'>
        <Form.Check
          value='event'
          type='radio'
          aria-label='radio 1'
          label='Evento'
          onChange={(e) => {
            setBookingType(e.target.value);
            getCompanyEvents(selectedCompany);
          }}
          checked={bookingType === "event"}
        />
        <Form.Check
          value='center'
          type='radio'
          aria-label='radio 2'
          label='Centro di donazione'
          onChange={(e) => {
            setBookingType(e.target.value);
          }}
          checked={bookingType === "center"}
        />
      </div>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          createReservation();
        }}
      >
        {bookingType == "center" && (
          <Row>
            <Col xs={12} sm={6} md={6} lg={6}>
              <Form.Group className='mb-3' controlId='formBasicEmail'>
                <Form.Label>Centro di donazione</Form.Label>
                <Form.Select
                  aria-label='Default select example'
                  value={selectedDonationCenter}
                  name='donationCenter'
                  onChange={(e) => {
                    setSelectedDonationCenter(e.currentTarget.value);
                  }}
                >
                  <option disabled value='' selected>
                    Selezionare
                  </option>
                  {donationCenters.map((data, index) => {
                    return (
                      <option value={data.id} key={index}>
                        {data.name}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6}></Col>
          </Row>
        )}
        {bookingType == "event" && (
          <Row>
            <Col xs={12} sm={6} md={6} lg={6}>
              <Form.Group className='mb-3' controlId='formBasicEmail'>
                <Form.Label>Azienda</Form.Label>
                <Form.Select
                  aria-label='Default select example'
                  value={selectedCompany}
                  name='donationCenter'
                  onChange={(e) => {
                    setSelectedCompany(e.currentTarget.value);
                  }}
                >
                  <option disabled value='' selected>
                    Selezionare
                  </option>
                  {companies.map((data, index) => {
                    return (
                      <option value={data.id} key={index}>
                        {data.name}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6}></Col>
            <Col xs={12} sm={6} md={6} lg={6}>
              <Form.Group className='mb-3' controlId='formBasicEmail'>
                <Form.Label>Nome evento</Form.Label>
                <Form.Select
                  disabled={!selectedCompany}
                  aria-label='Default select example'
                  value={selectedEvent}
                  name='donationCenter'
                  onChange={(e) => {
                    setSelectedEvent(e.currentTarget.value);
                  }}
                >
                  <option disabled value='' selected>
                    Selezionare
                  </option>
                  {events
                    .filter((c) => c.company == selectedCompany && c.donationCenterId)
                    .map((data, index) => {
                      return (
                        <option value={data.id} key={index}>
                          {data.name}
                        </option>
                      );
                    })}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6}></Col>
          </Row>
        )}
        <Row>
          <Col xs={12} sm={6} md={6} lg={6}>
            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <Form.Label>Data dell’evento</Form.Label>
              <Form.Select
                aria-label='Default select example'
                value={selectedDate}
                disabled={
                  bookingType == "event" ? !selectedEvent : !selectedDonationCenter
                }
                name='eventDate'
                onChange={(e) => {
                  setSelectedDate(e.currentTarget.value);
                }}
              >
                <option disabled value='' selected>
                  Selezionare
                </option>
                {availableDates.map((datas, index) => {
                  return (
                    <option value={datas.date} key={index}>
                      {datas.date}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}></Col>
        </Row>
        <Row>
          <Col xs={12} sm={6} md={6} lg={6}>
            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <Form.Label>Orario dell’evento</Form.Label>
              <Form.Select
                aria-label='Default select example'
                value={selectedSlot}
                disabled={!selectedDate}
                name='eventTime'
                onChange={(e) => {
                  setSelectedSlot(e.currentTarget.value);
                  setSelectedIntervalId(e.currentTarget.value);
                }}
              >
                <option disabled value='' selected>
                  Selezionare
                </option>
                {availableTimeSlots.map((data, index) => {
                  return (
                    <option value={data.id} key={index}>
                      {data.slot}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}></Col>
        </Row>

        <Row>
          <Col xs={12} sm={6} md={6} lg={6}>
            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <Form.Label>Note</Form.Label>
              <Form.Control
                as='textarea'
                rows={3}
                maxLength={200}
                name='eventNote'
                onChange={(e) => setNotes(e.target.value)}
                value={notes}
              />
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}></Col>
        </Row>
        <Row>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className='mb-3 d-flex justify-content-end'
          >
            <UseButton
              disabled={!selectedDate || !selectedSlot}
              type='submit'
              name='adminBtnPrimary'
              variant='primary'
              labels='Crea prenotazione'
            />
          </Col>
        </Row>
      </Form>
      {loading && <UseLoader />}
      <UseAlert show={show} setShow={setShow} variant={variant} alertMsg={message} />
    </section>
  );
};

export default NewBooking;
