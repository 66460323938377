import it from "date-fns/locale/it";
import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.module.css";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import UseLoader from "../../Components/Loader/Loader";
import UseTopBar from "../../Components/TopBar/TopBar";
import UseAlert from "../../Components/UseAlert/UseAlert";
import UseButton from "../../Components/UseButton/UseButton";
import LazyBreadCrum from "../BreadCrum/BreadCrum";
import "./AddReservations.scss";
// import UseDropDownWithSearch from '../../Components/UseDropdownWithSearch/UseDropdownWithSearch';
import UseDateDropDownWithSearch from "../../Components/UseDateDropdownWithSearch/UseDateDropdownWithSearch";

const AddReservations = () => {
  const location = useLocation();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    getValue,
    watch,
    formState: {
      errors,
      isValid,
      isDirty,
      isSubmitting,
      isSubmitted,
      isSubmitSuccessful,
    },
  } = useForm({
    defaultValues: {
      date: "",
      startTime: "",
      endTime: "",
      slotDuration: 0,
      personPerSlot: 0,
      intervalsExcluded: "",
      occurrence: "",
      donationCenterId: "",
    },
    mode: "onSubmit",
  });

  const [personPerSot, setPersonPerSlot] = useState([
    { id: 1, name: "1 person" },
    { id: 2, name: "2 person" },
    { id: 3, name: "3 person" },
  ]);

  const [locations, setlocations] = useState([]);
  const [donar, setDoanr] = useState([]);

  const [openSlot, setOpenSlot] = useState(false);
  const [openInterval, setOpenInterval] = useState(false);

  const [slot, setSlot] = useState([]);
  const [timeSlot, setTimeSlot] = useState([]);

  const [getInterval, setInterval] = useState([]);
  const [company, setCompany] = useState([]);

  const [show, setShow] = useState(false);
  const [variant, setVarient] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const [interval] = useState([10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60]);
  const [startDate, setStartDate] = useState(new Date());
  const [loader, setLoader] = useState(false);

  const [timeOpen, setTimeOpen] = useState(false);
  const [searchInterval, setSearchInterval] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [activeTime, setActiveTime] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const navigation = useNavigate();

  const toggleSlot = (event) => {
    setOpenSlot(!openSlot);
  };

  const toggleInterval = () => {
    // setOpenInterval(!openInterval)
    const values = getValues();
    const [startHours, startMinutes] = values.startTime.split(":").map(Number);
    const [endHours, endMinutes] = values.endTime.split(":").map(Number);

    // const startHours = values.startTime;
    console.log("startHours", startHours, "endHours", endHours);
    const startTimes = { hours: Number(startHours), minutes: Number(startMinutes) };
    const endTimes = { hours: Number(endHours), minutes: Number(endMinutes) };
    const slotDuration = Number(values.slotDuration); // in minutes
    console.log("startTimes", startTimes, "End ties", endTimes);
    const timeSlots = [];
    let currentTime = { ...startTimes };

    while (
      currentTime.hours < endTimes.hours ||
      (currentTime.hours === endTimes.hours && currentTime.minutes <= endTimes.minutes)
    ) {
      const timeString = `${currentTime.hours
        .toString()
        .padStart(2, "0")}:${currentTime.minutes.toString().padStart(2, "0")}`;
      if (!(currentTime.hours === 13 && currentTime.minutes === 0)) {
        timeSlots.push(timeString);
      }

      // Increment current time by slotDuration minutes
      currentTime.minutes += slotDuration;
      if (currentTime.minutes >= 60) {
        currentTime.hours += 1;
        currentTime.minutes -= 60;
      }
    }
    // Remove last value
    timeSlots.pop();
    setInterval(timeSlots);
  };
  // console.log('selectedTime>>>>>>',selectedTime.length )
  const functions = getFunctions();
  useEffect(() => {
    getLoationId();
    getdoanrId();
    getCompany();
    setValue("date", new Date());
    const did = localStorage.getItem("dcid");
    setValue("donationCenterId", did);
    setValue("intervalsExcluded", selectedTime);
    //   if(getInterval.length>0){
    //   setValue('intervalsExcluded',getInterval)
    // }
    // if(watchField === getInterval){
    //   setValue('intervalsExcluded',getInterval)
    // }
    // let Hours=Number(slot[0]?.to?.hours)+Number(12);
    // setValue('startTime',`${slot[0]?.from.hours}:${slot[0]?.from.minutes == 0?'00' : slot[0]?.from.minutes }`)
    // setValue('endTime',`${Hours}:${slot[0]?.to?.minutes == 0?'00':slot[0]?.to?.minutes}`)
    // setValue('intervalsExcluded',getInterval)

    // const values = getValues()
    // const [startHours, startMinutes] = values.startTime.split(':').map(Number);
    // const [endHours, endMinutes] = values.endTime.split(':').map(Number);

    // // const startHours = values.startTime;
    // console.log('startHours',startHours,'endHours',endHours)
    // const startTimes = { hours: Number(startHours), minutes:Number(startMinutes) };
    // const endTimes = { hours: Number(endHours), minutes: Number(endMinutes) };
    // const slotDuration = Number(values.slotDuration); // in minutes
    // console.log('startTimes',startTimes,'End ties',endTimes)
    // const timeSlots  = [];
    // let currentTime = { ...startTimes };

    // while (currentTime.hours < endTimes.hours || (currentTime.hours === endTimes.hours && currentTime.minutes <= endTimes.minutes)) {
    //   const timeString = `${currentTime.hours.toString().padStart(2, '0')}:${currentTime.minutes.toString().padStart(2, '0')}`;
    //   if (!(currentTime.hours === 13 && currentTime.minutes === 0)) {
    //     timeSlots.push(timeString);
    //   }

    //   // Increment current time by slotDuration minutes
    //   currentTime.minutes += slotDuration;
    //   if (currentTime.minutes >= 60) {
    //     currentTime.hours += 1;
    //     currentTime.minutes -= 60;
    //   }
    // }
    // setInterval(timeSlots)
  }, [setValue, selectedTime]);

  const getLoationId = () => {
    try {
      const getLocations = httpsCallable(functions, "getDonationCenters");
      getLocations()
        .then((response) => {
          const result = response.data.map(({ id, name }) => ({ id, name }));
          setlocations(result);
        })
        .catch((error) => console.error("error", error));
    } catch (error) {
      console.error("error", error);
    }
  };

  const getdoanrId = () => {
    try {
      const getDonar = httpsCallable(functions, "getDonors");
      getDonar()
        .then((response) => {
          const result = response.data.map(({ id, name }) => ({ id, name }));
          setDoanr(result);
        })
        .catch((error) => console.error("error", error));
    } catch (error) {
      console.error("error", error);
    }
  };

  const getCompany = () => {
    try {
      const getCompanies = httpsCallable(functions, "getCompanies");
      getCompanies()
        .then((response) => {
          const result = response.data.map(({ id, companyDomain }) => ({
            id,
            companyDomain,
          }));
          setCompany(result);
        })
        .catch((error) => console.error("error", error));
    } catch (error) {
      console.error("error", error);
    }
  };

  const submitHandler = (data) => {
    console.log("add data", data);

    // console.log('final slot', slot);

    // console.log('slotttttttttttttttt',slot)

    setValue("intervalsExcluded", selectedTime);

    // const data={
    //   date:'',
    //   startTime:'',
    //   endTime:'',
    //   slotDuration:0,
    //   personPerSlot:0,
    //   intervalsExcluded:'',
    //   occurrence:'',
    //   donationCenterId:''
    // }

    try {
      setLoader(true);
      const setDonarCenters = httpsCallable(functions, "setDonationCenterTimeSlots");
      // setDonarCenters({...data,intervalsExcluded:getInterval})
      setDonarCenters({
        ...data,
        date: selectedDate,
      })
        .then((response) => {
          console.log("location add response", response);
          setLoader(false);
          setShow(true);
          setVarient("success");
          setAlertMsg(response.data.message);
          setTimeout(() => {
            navigation("/Centro-donazione/Scheda-centro");
          }, 2000);
        })
        .catch((error) => {
          console.error("error", error);
          setLoader(false);
          setShow(true);
          setVarient("danger");
          setAlertMsg(error.message);
        });
    } catch (error) {
      console.error("error", error);
    }

    //   const StartTIme = slot[0]?.from.hours * 60 + slot[0]?.from.minutes;
    // const EndTime =
    //   (Number(slot[0]?.to?.hours) + Number(12)) * 60 + slot[0]?.to?.minutes;
    // const TimeInterval = 30; // in minutes
    // console.log('end time changesssss1', slot[0]?.to?.hours);
    // console.log(
    //   'end time changesssss2',
    //   Number(slot[0]?.to?.hours) + Number(12)
    // );
    // // console.log('From Hours',slot[0].from.hours,'Minutes',slot[0].from.minutes,'Seconds',slot[0].from.seconds,'day',slot[0].from.fromDay)

    // // console.log('To Hours',slot[0].to.hours,'Minutes',slot[0].to.minutes,'Seconds',slot[0].to.seconds,'day',slot[0].to.toDay)

    // console.log('Start Time : ', StartTIme);
    // console.log('End Time : ', EndTime);

    // const slots = [];
    // for (let time = StartTIme; time < EndTime; time += TimeInterval) {
    //   const hour = Math.floor(time / 60);
    //   const minute = time % 60;
    //   // Skip the time interval from 1:00 pm to 2:00 pm
    //   // console.log('intervaalllllllll111', getInterval);
    //   // console.log('intervaalllllllll222', getInterval[0]);
    //   // console.log('intervaalllllllll2333', getInterval[0].hours);
    //   // console.log(
    //   //   'intervaalllllllll444',
    //   //   Number(getInterval[0].hours) + Number(12)
    //   // );
    //   if (
    //     hour === Number(getInterval[0].hours) + Number(12) &&
    //     minute >= 0 &&
    //     minute < 60
    //   ) {
    //     // if (hour === 13 && minute >= 0 && minute < 60) {
    //     continue;
    //   }
    //   let period = 'AM';
    //   let formattedHour = hour;
    //   if (hour >= 12) {
    //     period = 'PM';
    //     formattedHour = hour === 12 ? 12 : hour - 12;
    //   }
    //   if (formattedHour === 0) {
    //     formattedHour = 12;
    //   }
    //   const timeString = `${formattedHour.toString().padStart(2, '0')}:${minute
    //     .toString()
    //     .padStart(2, '0')} ${period}`;
    //   // slots.push(timeString);
    //   slots.push({ time: timeString, booking: false });
    // }
    // setTimeSlot(slots);
  };

  const removeIntervals = (index) => {
    console.log("index", index);
    const result = getInterval.filter((_, i) => i !== index);
    const result1 = activeTime.filter((_, i) => i !== index);
    setInterval(result);
    setActiveTime(result1);
  };

  // const getDate = (date) => {
  //   console.log('dattttttttttttttttt',date)
  //   console.log('dattttttttttttttttt1111',date.toLocaleDateString())
  //   setValue('date',date.toLocaleDateString())
  //   setStartDate(date)
  // }

  const getDate = (e) => {
    // console.log('dattttttttttttttttt',date)
    // console.log('dattttttttttttttttt1111',date.toLocaleDateString())

    let date = new Date(e);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const newDate = `${month}/${day}/${year}`;
    console.log("newDate", newDate);
    setSelectedDate(newDate);
    setStartDate(e);
  };

  const selectedTimeSlotsToExclude = (e) => {
    console.log("selected time slots", e);
    const finalData = e.slice(0, 2);
    setTimeOpen(false);
    setSelectedTime([...selectedTime, e]);
    setActiveTime([...activeTime, e]);
    // setValue('')
  };
  console.log("intervals>>>>", selectedTime);
  // console.log('locationssssss',locations)
  // console.log('Donarssssssssss',donar)
  // console.log('getInterval',getInterval)
  // console.log('startDate',startDate)
  return (
    <div className='AddReservations' data-testid='AddReservations'>
      <UseTopBar back='Centro-donazione/Scheda-centro' />
      <LazyBreadCrum />
      <h2>Aggiungi data per {location?.state?.centerName}</h2>
      <Form onSubmit={handleSubmit(submitHandler)}>
        <Row>
          <input type='hidden' name='date' {...register("date")} />
          <Col xs={12} sm={12} md={12} lg={12}>
            <Row>
              <Col xs={12} sm={6} md={6} lg={6} className='reactCustomDatePickerAdmin'>
                <Form.Group className='mb-3' controlId='formBasicEmail'>
                  <Form.Label>Date</Form.Label>
                  <br></br>
                  <DatePicker
                    locale={it}
                    dateFormat='dd/MM/YYYY'
                    selected={startDate}
                    onChange={getDate}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6} md={6} lg={6}>
                &nbsp;
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6} md={6} lg={6}>
                <Row>
                  <Col xs={12} sm={6} md={6} lg={6}>
                    <Form.Group className='mb-3' controlId='formBasicEmail'>
                      <Form.Label>Ora d'inizio</Form.Label>
                      <Form.Select
                        aria-label='Select Durata degli slot'
                        {...register("startTime", { required: true })}
                      >
                        <option value='00:00'>00:00</option>
                        <option value='01:00'>01:00</option>
                        <option value='02:00'>02:00</option>
                        <option value='03:00'>03:00</option>
                        <option value='04:00'>04:00</option>
                        <option value='05:00'>05:00</option>
                        <option value='06:00'>06:00</option>
                        <option value='07:00'>07:00</option>
                        <option value='08:00'>08:00</option>
                        <option value='09:00'>09:00</option>
                        <option value='10:00'>10:00</option>
                        <option value='11:00'>11:00</option>
                        <option value='12:00'>12:00</option>
                        <option value='13:00'>13:00</option>
                        <option value='14:00'>14:00</option>
                        <option value='15:00'>15:00</option>
                        <option value='16:00'>16:00</option>
                        <option value='17:00'>17:00</option>
                        <option value='18:00'>18:00</option>
                        <option value='19:00'>19:00</option>
                        <option value='20:00'>20:00</option>
                        <option value='21:00'>21:00</option>
                        <option value='22:00'>22:00</option>
                        <option value='23:00'>23:00</option>
                        <option value='23:00'>24:00</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6}>
                    <Form.Group className='mb-3' controlId='formBasicEmail'>
                      <Form.Label>Ora di fine</Form.Label>
                      <Form.Select
                        aria-label='Select Durata degli slot'
                        {...register("endTime", { required: true })}
                      >
                        <option value='00:00'>00:00</option>
                        <option value='01:00'>01:00</option>
                        <option value='02:00'>02:00</option>
                        <option value='03:00'>03:00</option>
                        <option value='04:00'>04:00</option>
                        <option value='05:00'>05:00</option>
                        <option value='06:00'>06:00</option>
                        <option value='07:00'>07:00</option>
                        <option value='08:00'>08:00</option>
                        <option value='09:00'>09:00</option>
                        <option value='10:00'>10:00</option>
                        <option value='11:00'>11:00</option>
                        <option value='12:00'>12:00</option>
                        <option value='13:00'>13:00</option>
                        <option value='14:00'>14:00</option>
                        <option value='15:00'>15:00</option>
                        <option value='16:00'>16:00</option>
                        <option value='17:00'>17:00</option>
                        <option value='18:00'>18:00</option>
                        <option value='19:00'>19:00</option>
                        <option value='20:00'>20:00</option>
                        <option value='21:00'>21:00</option>
                        <option value='22:00'>22:00</option>
                        <option value='23:00'>23:00</option>
                        <option value='23:00'>24:00</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} sm={6} md={6} lg={6}>
                &nbsp;
              </Col>
            </Row>

            <Row>
              <Col xs={12} sm={6} md={6} lg={6}>
                <Form.Group className='mb-3' controlId='formBasicEmail'>
                  <Form.Label>Durata degli slot</Form.Label>
                  <Form.Select
                    aria-label='Select Durata degli slot'
                    {...register("slotDuration", { required: true })}
                  >
                    {interval.map((value, index) => {
                      return (
                        <option key={index} value={value}>
                          {value} minuti
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xs={12} sm={6} md={6} lg={6}>
                &nbsp;
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                sm={6}
                md={6}
                lg={6}
                onClick={() => toggleInterval()}
                className='connectCompanies'
              >
                <Form.Group className='mb-3 position-relative' controlId='formBasicEmail'>
                  <Form.Label>Intervalli esclusi</Form.Label>
                  {/* <div className='connectGroup'>                         */}
                  {/* <div className='addInterval'>
                              <UseButton type="button" onClick={()=>toggleInterval()} name="adminBtnPrimary" variant="btn btn-outline-primary" labels="Aggiungi esclusione" />
                              <SlotInterval open={openInterval} setInterval={setInterval} getInterval={getInterval} setOpenInterval={setOpenInterval} />
                              </div> */}
                  {/* {
                                getInterval?.map((item, index)=>{
                                  return  <span key={index}>
                                  {item}
                                  <b onClick={()=>removeIntervals()}><svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M5.628 4L8.26 1.368C8.412 1.224 8.5 1.024 8.5 0.8C8.5 0.36 8.14 0 7.7 0C7.476 0 7.276 0.088 7.132 0.232L4.5 2.872L1.868 0.232C1.724 0.088 1.524 0 1.3 0C0.86 0 0.5 0.36 0.5 0.8C0.5 1.024 0.588 1.224 0.732 1.368L3.372 4L0.74 6.632C0.588 6.776 0.5 6.976 0.5 7.2C0.5 7.64 0.86 8 1.3 8C1.524 8 1.724 7.912 1.868 7.768L4.5 5.128L7.132 7.76C7.276 7.912 7.476 8 7.7 8C8.14 8 8.5 7.64 8.5 7.2C8.5 6.976 8.412 6.776 8.268 6.632L5.628 4Z" fill="#4A4B57"/>
    </svg>
    </b>
                                </span>
                                })
                              } */}

                  {/* <Form.Select aria-label="Default select example" name='personPerSlot' {...register('personPerSlot',{required:true})}>
                                {getInterval?.map((item, index)=>{
                                    return <option key={index} value={item}>{item}</option>
                                })}
                              </Form.Select> */}

                  {/* </div> */}

                  <UseDateDropDownWithSearch
                    search={searchInterval}
                    onSearch={setSearchInterval}
                    open={timeOpen}
                    setOpen={setTimeOpen}
                    list={getInterval}
                    label='Scegli orari'
                    activeClass={activeTime}
                    getFunction={selectedTimeSlotsToExclude}
                    noData='Non ci sono date disponibili'
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6} md={6} lg={6} className='selctedTimeList'>
                {activeTime?.map((value, index) => {
                  return (
                    <span key={index}>
                      {value}{" "}
                      <svg
                        onClick={() => removeIntervals(index)}
                        width='8'
                        height='8'
                        viewBox='0 0 8 8'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M5.128 4L7.76 1.368C7.912 1.224 8 1.024 8 0.8C8 0.36 7.64 0 7.2 0C6.976 0 6.776 0.088 6.632 0.232L4 2.872L1.368 0.232C1.224 0.088 1.024 0 0.8 0C0.36 0 0 0.36 0 0.8C0 1.024 0.088 1.224 0.232 1.368L2.872 4L0.24 6.632C0.0880002 6.776 0 6.976 0 7.2C0 7.64 0.36 8 0.8 8C1.024 8 1.224 7.912 1.368 7.768L4 5.128L6.632 7.76C6.776 7.912 6.976 8 7.2 8C7.64 8 8 7.64 8 7.2C8 6.976 7.912 6.776 7.768 6.632L5.128 4Z'
                          fill='#4A4B57'
                        />
                      </svg>
                    </span>
                  );
                })}
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6} md={6} lg={6}>
                <Form.Group className='mb-3' controlId='formBasicEmail'>
                  <Form.Label>Persone per slot</Form.Label>
                  <Form.Select
                    aria-label='Default select example'
                    name='personPerSlot'
                    {...register("personPerSlot", { required: true })}
                  >
                    {personPerSot?.map((item, index) => {
                      return (
                        <option key={index} value={Number(item.id)}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xs={12} sm={6} md={6} lg={6}>
                &nbsp;
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6} md={6} lg={6}>
                <Form.Group className='mb-3' controlId='formBasicEmail'>
                  <Form.Label>Ricorrenza</Form.Label>
                  <Form.Select
                    aria-label='Default select example'
                    {...register("occurrence")}
                  >
                    <option value=''>Nessuna ricorrenza</option>
                    <option value='1 volta a settimana'>1 volta a settimana</option>
                    <option value='2 volta a settimana'>2 volta a settimana</option>
                    <option value='3 volta a settimana'>3 volta a settimana</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xs={12} sm={6} md={6} lg={6}>
                &nbsp;
              </Col>
            </Row>
          </Col>

          <Row>
            <Col
              xs={12}
              sm={6}
              md={12}
              lg={12}
              className='mb-4 d-flex justify-content-end align-items-center'
            >
              {(!isDirty || !isValid) && (
                <p style={{ color: "red", fontSize: "14px", margin: "0 20px 0 0" }}>
                  Tutti i campi sono obbligatori
                </p>
              )}
              <UseButton
                disabled={!isDirty || !isValid}
                type='submit'
                name='adminBtnPrimary'
                variant='primary'
                labels='Salva modifiche'
              />
            </Col>
          </Row>
        </Row>
      </Form>

      {/* <input type='hidden' name="startTime" {...register('startTime')} />
                  <input type='hidden' name="endTime" {...register('endTime')} />
                  <input type='hidden' name="date" {...register('date')} />       */}
      {/* <Form style={{display:'none'}}>
                <Row>

                    <Col xs={12} sm={6} md={6} lg={6}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                              <Form.Label>Nome evento</Form.Label>
                              <Form.Control type="text" name="eventName" {...register('eventName')} />
                        </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6} className="reactCustomDatePickerAdmin">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                              <Form.Label>Date</Form.Label>

                              <br></br>

                              <DatePicker locale={it} dateFormat="dd/MM/YYYY" selected={startDate} onChange={getDate} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                      <Col xs={12} sm={6} md={6} lg={6}>
                              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                          <Form.Label>Descrizione</Form.Label>
                                          <Form.Control as="textarea" rows={3} name="description" {...register('description')} />
                              </Form.Group>
                              <p>0/200 caratteri</p>
                      </Col>

                      <Col xs={12} sm={6} md={6} lg={6}>
                          <Row>
                              <Col xs={12} sm={12} md={12} lg={12}>
                                  <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Orario</Form.Label>
                                    <BookingSlot open={openSlot} toggleSlot={toggleSlot} slot={slot} setSlot={setSlot} setOpenSlot={setOpenSlot} />
                                  </Form.Group>
                              </Col>
                              <Col xs={12} sm={12} md={12} lg={12}>
                                  <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Durata degli slot</Form.Label>

                                    <Form.Select aria-label="Select Durata degli slot" name='slotDuration' {...register('slotDuration')} >
                                          <option>Select Durata degli slot</option>
                                              {interval.map((value, index)=>{
                                              return <option key={index} value={value}>{value} minuti</option>
                                              })
                                              }
                                    </Form.Select>
                                  </Form.Group>
                              </Col>
                          </Row>
                      </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={6} md={6} lg={6}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Aziende collegate</Form.Label>

                          <Form.Select aria-label="Default select example" name='company' {...register('company')}>
                                <option>Select companies</option>
                                {company?.map((item, index)=>{
                                    return <option key={index} value={item.id}>{item.companyDomain}</option>
                                })}
                          </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6} className='connectCompanies'>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Intervalli esclusi</Form.Label>

                            <div className='connectGroup'>

                              <div className='addInterval'>

                              <UseButton type="button" onClick={()=>toggleInterval()} name="adminBtnPrimary" variant="btn btn-outline-primary" labels="Aggiungi esclusione" />
                              <SlotInterval open={openInterval} setInterval={setInterval} getInterval={getInterval} setOpenInterval={setOpenInterval} />
                              </div>

                              {
                                getInterval?.map((item, index)=>{
                                  return  <span key={index}>

                                  {item}

                                  <b onClick={()=>removeIntervals()}><svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M5.628 4L8.26 1.368C8.412 1.224 8.5 1.024 8.5 0.8C8.5 0.36 8.14 0 7.7 0C7.476 0 7.276 0.088 7.132 0.232L4.5 2.872L1.868 0.232C1.724 0.088 1.524 0 1.3 0C0.86 0 0.5 0.36 0.5 0.8C0.5 1.024 0.588 1.224 0.732 1.368L3.372 4L0.74 6.632C0.588 6.776 0.5 6.976 0.5 7.2C0.5 7.64 0.86 8 1.3 8C1.524 8 1.724 7.912 1.868 7.768L4.5 5.128L7.132 7.76C7.276 7.912 7.476 8 7.7 8C8.14 8 8.5 7.64 8.5 7.2C8.5 6.976 8.412 6.776 8.268 6.632L5.628 4Z" fill="#4A4B57"/>
    </svg>
    </b>
                                </span>
                                })
                              }

                            </div>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={6} md={6} lg={6}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                              <Form.Label>Referente</Form.Label>
                              <Form.Control type="text" name='contactPersonName' {...register('contactPersonName')} />
                          </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                              <Form.Label>Persone per slot</Form.Label>
                              <Form.Select aria-label="Default select example" name='personPerSlot' {...register('personPerSlot')}>

                              <option>Select Persone per slot</option>


                                {personPerSot?.map((item, index)=>{
                                    return <option key={index} value={Number(item.id)}>{item.name}</option>
                                })}


                              </Form.Select>

                          </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                              <Form.Label>Numero del telefono referente</Form.Label>
                              <Form.Control type="text" name='contactPersonPhone' {...register('contactPersonPhone')} />
                          </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                              <Form.Label>Ricorrenza</Form.Label>
                              <Form.Select aria-label="Default select example">
                                <option>Select Ricorrenza</option>
                                <option value="1 time a week">1 time a week</option>
                                <option value="2 time a week">2 time a week</option>
                                <option value="3 time a week">3 time a week</option>
                              </Form.Select>
                          </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                              <Form.Label>Occurrence</Form.Label>
                              <Form.Control type="text" name='occurrence' {...register('occurrence')} />
                          </Form.Group>
                    </Col>

                    <Col xs={12} sm={6} md={6} lg={6}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Location</Form.Label>
                            <Form.Select aria-label="Default select example" name="donationCenterId" {...register('donationCenterId')} >
                                <option>Select location</option>
                                {locations.map((value,index)=>{
                                  return <option key={index} value={value.id}>{value.name}</option>
                                })

                                }
                              </Form.Select>
                        </Form.Group>
                  </Col>

                </Row>
                <Row>
                    <Col xs={12} sm={6} md={12} lg={12} className='mb-4 d-flex justify-content-end'>
                    <UseButton type="submit" name="adminBtnPrimary" variant="primary" labels="Salva modifiche"  />
                    </Col>
                </Row>
            </Form> */}

      {/* {
                timeSlot.length>0 &&

            <div className='availbleSlots'>
            <ul>
          {timeSlot?.map((time, index) => (
            <li key={index}>{time.time}</li>
          ))}
        </ul>
        </div>
                } */}

      <UseAlert show={show} setShow={setShow} variant={variant} alertMsg={alertMsg} />
      {loader == true && <UseLoader />}
    </div>
  );
};

AddReservations.propTypes = {};

AddReservations.defaultProps = {};

export default AddReservations;
