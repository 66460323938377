import React from "react";
import CaroselSliderOne from "../../Components/CarouselSlider/CarouselSliderOne";
import "./Events.scss";
const Events = ({ companyId }) => (
  <div className='Events' data-testid='Events'>
    <span id='Events'></span>
    <h2>{companyId ? "Donazioni in sede" : "Tutti gli eventi"}</h2>
    <CaroselSliderOne companyId={companyId} />
  </div>
);

Events.propTypes = {};

Events.defaultProps = {};

export default Events;
