import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import UseLoader from "../../Components/Loader/Loader";
import UseTopBar from "../../Components/TopBar/TopBar";
import UseAlert from "../../Components/UseAlert/UseAlert";
import UseButton from "../../Components/UseButton/UseButton";
import LazyBreadCrum from "../BreadCrum/BreadCrum";
import "./EmployeeProfile.scss";
const EmployeeProfile = () => {
  const [openModal, setModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [image, setImage] = useState("");
  const [show, setShow] = useState(false);
  const [variant, setVarient] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const functions = getFunctions();
  const location = useLocation();
  const navigate = useNavigate();
  // const createWebinar = () => {
  //        setModalOpen(true)
  //      }
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      lastName: "",
      role: "",
      email: "",
      profileImage: "",
    },
    mode: "onSubmit",
  });

  useEffect(() => {
    if (location?.state?.editPorfileId) {
      getProfile();
    }
    // setValue('name',data?.name)
  }, []);

  const getProfile = () => {
    try {
      setLoader(true);
      const getProfile = httpsCallable(functions, "getCollaboratorById");
      getProfile({ id: location?.state?.editPorfileId }).then((response) => {
        setLoader(false);
        console.log("edit get profile image", response);
        setData(response.data);
        loadProfile(response.data);
      });
    } catch (error) {
      console.error("error", error);
    } finally {
      setLoader(false);
    }
  };

  const loadProfile = (data) => {
    console.log("load", data);
    setValue("name", data?.name);
    setValue("lastName", data?.lastName);
    setValue("email", data?.email);
    setEmail(data?.email);
    setValue("role", data?.role);
    setImage(data.profileImage);
  };

  // const closeModal = () =>{
  //        setModalOpen(false)
  // }

  const employeeProfile = () => {
    setModalOpen(true);
  };

  const onSubmit = (data) => {
    try {
      setLoader(true);
      const updateProfile = httpsCallable(functions, "updateCollaborators");
      updateProfile({ ...data, id: location?.state?.editPorfileId })
        .then((response) => {
          setLoader(false);
          console.log("edit prodfile reesponse", response);
          setShow(true);
          setVarient("success");
          setAlertMsg(response.data.message);
          setTimeout(() => {
            navigate("/Impostazioni", { state: { addTabs: "profile" } });
          }, 2000);
        })
        .catch((error) => {
          console.error("error", error);
          setShow(true);
          setVarient("success");
          setAlertMsg(error.message);
        });
    } catch (error) {
      console.error("error", error);
    }
  };

  const removeLogo = () => {
    setValue("profileImage", "");
    setImage("");
    // window.location.reload();
  };

  const resetProfilePassword = () => {
    try {
      setLoader(true);
      const resetPassword = httpsCallable(functions, "sendResetPasswordLink");
      resetPassword({ email: email }).then((response) => {
        setLoader(false);
        setShow(true);
        setVarient("success");
        setAlertMsg(response.data.message);
        setTimeout(() => {
          //       window.location.reload();
          navigate("/Impostazioni", { state: { addTabs: "profile" } });
        }, 2000);
        console.log("profile reset password response", response);
      });
    } catch (error) {
      console.error("error", error);
      setLoader(false);
      setShow(true);
      setVarient("danger");
      setAlertMsg(error.message);
    }
  };
  // console.log('locatipn',location);
  return (
    <div className='EmployeeProfile' data-testid='EmployeeProfile'>
      <UseTopBar back='Impostazioni' />
      <LazyBreadCrum />
      {location?.pathname.slice(1) == "Impostazioni/Modificare-profilo-collaboratore" && (
        <h2>{location?.state?.editProfileName || "-"}</h2>
      )}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs={12} sm={6} md={6} lg={6} className='imageUpload'>
            {/* {image && <div className='logoPreview'><img src={image} alt='Image' /><span onClick={removeLogo}>x</span></div>} */}
            <Form.Group className='mb-3'>
              <Form.Label>&nbsp;</Form.Label>
              {/* <Form.Control type="text" placeholder="Webinar sulla formazione" />                                       */}
              <h6>
                Ultimo accesso avvenuto <strong>10 giorni fa</strong>
              </h6>
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}>
            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <Form.Label>Email</Form.Label>
              <Form.Control type='text' name='email' {...register("email")} />
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}>
            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <Form.Label>Nome</Form.Label>
              <Form.Control type='text' name='name' {...register("name")} />
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}>
            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <Form.Label>Ruolo</Form.Label>
              <Form.Select name='role' {...register("role")}>
                <option value='admin'>Admin</option>
                <option value='viewer'>Viewer</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}>
            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <Form.Label>Cognome</Form.Label>
              <Form.Control type='text' name='lastName' {...register("lastName")} />
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}>
            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <Form.Label>Password</Form.Label>
              {/* <Form.Control type="text" placeholder="Webinar sulla formazione" />                                       */}
              <UseButton
                type='button'
                onClick={resetProfilePassword}
                name='adminBtnPrimary'
                variant='outline-primary'
                labels='Invia link reset'
              />
            </Form.Group>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} className='d-flex justify-content-end'>
            <UseButton
              name='adminBtnPrimary profileBottomBtn'
              variant='primary'
              labels='Salva modifiche'
              onClick={employeeProfile}
            />
          </Col>
        </Row>
      </Form>

      {/* <UseModal openModal={openModal} closeModal={closeModal} /> */}
      <UseAlert show={show} setShow={setShow} variant={variant} alertMsg={alertMsg} />
      {loader == true && <UseLoader />}
    </div>
  );
};

EmployeeProfile.propTypes = {};

EmployeeProfile.defaultProps = {};

export default EmployeeProfile;
