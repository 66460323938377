import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { isAfter } from "date-fns";
import UseLoader from "../../Components/Loader/Loader";
import UseAlert from "../../Components/UseAlert/UseAlert";
import UseButton from "../../Components/UseButton/UseButton";
import "./AvailableTimeSlote.scss";
const AvailableTimeSlote = ({ data, center, map, reservation }) => {
  // const [datas, setDatas] = useState([data])
  const [userId, setUserId] = useState("");
  const [centerId, setCenterId] = useState("");
  const [slotId, setSlotId] = useState("");
  const [date, setDate] = useState("");
  const [firstRecord, setFirstRecord] = useState([]);
  const [getSlotDetails, setSlotDetails] = useState([]);
  const [loadFirstRecord, setLoadFirtsRecord] = useState(false);
  const [selectedSots, setSelectedSlots] = useState([]);
  const [activeDate, setActiveDate] = useState("");
  const [currentSlot, setCurrentSlot] = useState("");
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [variant, setVarient] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const [startTime, setStartTime] = useState("");
  const [currentTimeSlot, setCurrentTime] = useState("");
  const [mapLink, setMapLink] = useState("");
  // const [bookedSlot, setBookedSlot] = useState([])
  // const [datas, setDatas] = useState([])
  const navigate = useNavigate();
  const functions = getFunctions();
  const isAfterSunday = isAfter(new Date(), new Date("2024-06-02"));
  useEffect(() => {
    let id = localStorage.getItem("uid");
    setUserId(id);

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const futureData = data.filter(({ date }) => new Date(date) >= today);
    const firstFutureDateEntry = futureData[0];

    if (firstFutureDateEntry) {
      setCenterId(firstFutureDateEntry.donationCenterId);
      setSlotId(firstFutureDateEntry.id);
      setDate(firstFutureDateEntry.date);
      setActiveDate(firstFutureDateEntry.date);
      selectSlotsDetails(firstFutureDateEntry);
    } else {
      console.log("No future dates found in data");
    }

    return () => {};
  }, [data, userId]); // Dependencies array

  const bookSlots = (sid) => {
    const data = {
      donationCenterId: centerId,
      date: activeDate,
      userId: userId,
      timeslotId: slotId,
      intervalId: sid.id,
    };
    // console.log('slot details',sid)
    setCurrentSlot(sid.id);
    setCurrentTime(sid.slot);
  };

  const selectSlotsDetails = (datas) => {
    setLoadFirtsRecord(true);
    setActiveDate(datas.date);
    setSlotId(datas.id);
    const data = {
      donationCenterId: datas.donationCenterId,
      timeslotId: datas.id,
      date: datas.date,
    };
    setLoader(true);
    try {
      const filterByTimeSlots = httpsCallable(
        functions,
        "getDonationCenterTimeslotByDate",
      );
      filterByTimeSlots(data)
        .then((response) => {
          // console.log('slot booking response',response)
          let result = response.data.slice(0, 1);
          let address = center?.address.split(" ").join("+") + "+" + center.city;
          setMapLink("https://www.google.com/maps/place/" + address);
          setSelectedSlots(result[0]);
          // console.log('response result',result)
        })
        .catch((error) => console.error("error", error));
    } catch (error) {
      console.error("error", error);
    } finally {
      setLoader(false);
    }
  };

  function formatDateToItalian(dateStr) {
    const days = [
      "Dom", // Sunday
      "Lun", // Monday
      "Mar", // Tuesday
      "Mer", // Wednesday
      "Gio", // Thursday
      "Ven", // Friday
      "Sab", // Saturday
    ];

    const months = [
      "Gennaio",
      "Febbraio",
      "Marzo",
      "Aprile",
      "Maggio",
      "Giugno",
      "Luglio",
      "Agosto",
      "Settembre",
      "Ottobre",
      "Novembre",
      "Dicembre",
    ];

    const date = new Date(dateStr);

    const dayOfWeek = days[date.getDay()];
    const dayOfMonth = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${dayOfWeek} ${dayOfMonth} ${month} `;
  }

  const bookSlotes = () => {
    const data = {
      donationCenterId: centerId,
      date: activeDate,
      userId: userId,
      timeslotId: slotId,
      intervalId: currentSlot,
      slot: currentTimeSlot,
      address: selectedSots?.address,
      formattedDate: formatDateToItalian(activeDate),
    };
    navigate("/register", { state: { slotDetails: data, reservation } });
  };

  return (
    <div className='AvailableTimeSlote' data-testid='AvailableTimeSlote'>
      <selction className='showmap'>
        <Row>
          <Col xs={12} sm={8} md={8} lg={8}>
            {loadFirstRecord === false && <h2>{data[0]?.eventName}</h2>}

            {loadFirstRecord === true && <h2>{selectedSots?.eventName}</h2>}
            {loadFirstRecord === false && (
              <p>
                <svg
                  width='26'
                  height='26'
                  viewBox='0 0 26 26'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M13 8V13L16.75 16.75L13 8ZM24.25 13C24.25 14.4774 23.959 15.9403 23.3936 17.3052C22.8283 18.6701 21.9996 19.9103 20.955 20.955C19.9103 21.9996 18.6701 22.8283 17.3052 23.3936C15.9403 23.959 14.4774 24.25 13 24.25C11.5226 24.25 10.0597 23.959 8.69481 23.3936C7.3299 22.8283 6.08971 21.9996 5.04505 20.955C4.00039 19.9103 3.17172 18.6701 2.60636 17.3052C2.04099 15.9403 1.75 14.4774 1.75 13C1.75 10.0163 2.93526 7.15483 5.04505 5.04505C7.15483 2.93526 10.0163 1.75 13 1.75C15.9837 1.75 18.8452 2.93526 20.955 5.04505C23.0647 7.15483 24.25 10.0163 24.25 13Z'
                    stroke='#292A31'
                    strokeWidth='1.875'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>{" "}
                {data[0]?.startTime} - {data[0]?.endTime}
              </p>
            )}
            {loadFirstRecord === true && (
              <p>
                <svg
                  width='26'
                  height='26'
                  viewBox='0 0 26 26'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M13 8V13L16.75 16.75L13 8ZM24.25 13C24.25 14.4774 23.959 15.9403 23.3936 17.3052C22.8283 18.6701 21.9996 19.9103 20.955 20.955C19.9103 21.9996 18.6701 22.8283 17.3052 23.3936C15.9403 23.959 14.4774 24.25 13 24.25C11.5226 24.25 10.0597 23.959 8.69481 23.3936C7.3299 22.8283 6.08971 21.9996 5.04505 20.955C4.00039 19.9103 3.17172 18.6701 2.60636 17.3052C2.04099 15.9403 1.75 14.4774 1.75 13C1.75 10.0163 2.93526 7.15483 5.04505 5.04505C7.15483 2.93526 10.0163 1.75 13 1.75C15.9837 1.75 18.8452 2.93526 20.955 5.04505C23.0647 7.15483 24.25 10.0163 24.25 13Z'
                    stroke='#292A31'
                    strokeWidth='1.875'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
                {selectedSots?.startTime} - {selectedSots?.endTime}
              </p>
            )}
            {/* {loadFirstRecord === false && */}
            <p>
              <svg
                width='30'
                height='30'
                viewBoxrf='0 0 30 30'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M25 12.5C25 18.0228 15 27.5 15 27.5C15 27.5 5 18.0228 5 12.5C5 6.97715 9.47715 2.5 15 2.5C20.5228 2.5 25 6.97715 25 12.5Z'
                  stroke='#292A31'
                  strokeWidth='2'
                />
                <path
                  d='M15 13.75C15.6904 13.75 16.25 13.1904 16.25 12.5C16.25 11.8096 15.6904 11.25 15 11.25C14.3096 11.25 13.75 11.8096 13.75 12.5C13.75 13.1904 14.3096 13.75 15 13.75Z'
                  fill='#292A31'
                  stroke='#292A31'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>{" "}
              {data[0]?.address}
            </p>
            <p>
              <svg
                width='30'
                height='30'
                viewBox='0 0 30 30'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M3.5 3L13.5714 26.5L16.9286 16.4286L27 13.0714L3.5 3Z'
                  stroke='#292A31'
                  strokeWidth='1.67857'
                  strokeLinejoin='round'
                />
              </svg>{" "}
              <Link to={mapLink} target='_blank'>
                Vai a Google Maps
              </Link>
            </p>
            <div className='divider'></div>

            <div className='mb-5'>
              <h5>Quando vorresti donare?</h5>
              {data?.map((data, index) => {
                return (
                  <Button
                    disabled={
                      new Date(data.date) < new Date(new Date().setHours(0, 0, 0, 0))
                    }
                    className={activeDate == data.date ? "aciveDate" : "dateBtn"}
                    onClick={() => selectSlotsDetails(data)}
                    key={index}
                  >
                    <svg
                      width='20'
                      height='20'
                      viewBox='0 0 20 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M17 4C16.72 4 16.47 4.11 16.29 4.29L7 13.59L3.71 10.3C3.53 10.11 3.28 10 3 10C2.45 10 2 10.45 2 11C2 11.28 2.11 11.53 2.29 11.71L6.29 15.71C6.47 15.89 6.72 16 7 16C7.28 16 7.53 15.89 7.71 15.71L17.71 5.71C17.89 5.53 18 5.28 18 5C18 4.45 17.55 4 17 4Z'
                        fill='#FEF2F1'
                      />
                    </svg>
                    {formatDateToItalian(data.date)}
                  </Button>
                );
              })}
            </div>

            <h5>A che ora?</h5>
            {loadFirstRecord === false &&
              data[0]?.timeIntervals?.map((slot, index) => {
                return (
                  <Button
                    disabled={
                      !(
                        slot?.bookedUsers?.length == 0 ||
                        slot?.bookedUsers?.filter((u) => u.status == "pending").length <
                          selectedSots?.personPerSlot
                      )
                    }
                    className={
                      !(
                        slot?.bookedUsers?.length == 0 ||
                        slot?.bookedUsers?.filter((u) => u.status == "pending").length <
                          selectedSots?.personPerSlot
                      )
                        ? "booked"
                        : currentSlot == slot.id
                        ? "btnActive"
                        : "normalBtn"
                    }
                    onClick={() => (slot?.bookedUsers?.length ? null : bookSlots(slot))}
                    key={index}
                  >
                    <svg
                      className='tick'
                      width='21'
                      height='20'
                      viewBox='0 0 21 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M17.5 4C17.22 4 16.97 4.11 16.79 4.29L7.5 13.59L4.21 10.3C4.03 10.11 3.78 10 3.5 10C2.95 10 2.5 10.45 2.5 11C2.5 11.28 2.61 11.53 2.79 11.71L6.79 15.71C6.97 15.89 7.22 16 7.5 16C7.78 16 8.03 15.89 8.21 15.71L18.21 5.71C18.39 5.53 18.5 5.28 18.5 5C18.5 4.45 18.05 4 17.5 4Z'
                        fill='#FEF2F1'
                      />
                    </svg>
                    <svg
                      className='booked'
                      width='21'
                      height='20'
                      viewBox='0 0 21 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M10.5 0C4.98 0 0.5 4.48 0.5 10C0.5 15.52 4.98 20 10.5 20C16.02 20 20.5 15.52 20.5 10C20.5 4.48 16.02 0 10.5 0ZM2.5 10C2.5 5.58 6.08 2 10.5 2C12.35 2 14.05 2.63 15.4 3.69L4.19 14.9C3.13 13.55 2.5 11.85 2.5 10ZM10.5 18C8.65 18 6.95 17.37 5.6 16.31L16.81 5.1C17.87 6.45 18.5 8.15 18.5 10C18.5 14.42 14.92 18 10.5 18Z'
                        fill='#9696A0'
                      ></path>
                    </svg>{" "}
                    {slot?.slot.trim().slice(0, 5)}
                  </Button>
                );
              })}

            {loadFirstRecord === true &&
              selectedSots?.timeIntervals?.map((slot, index) => {
                return (
                  <Button
                    disabled={
                      !(
                        slot?.bookedUsers?.length == 0 ||
                        slot?.bookedUsers?.filter((u) => u.status == "pending").length <
                          selectedSots?.personPerSlot
                      )
                    }
                    className={
                      !(
                        slot?.bookedUsers?.length == 0 ||
                        slot?.bookedUsers?.filter((u) => u.status == "pending").length <
                          selectedSots?.personPerSlot
                      )
                        ? "booked"
                        : currentSlot == slot.id
                        ? "btnActive"
                        : "normalBtn"
                    }
                    onClick={() => bookSlots(slot)}
                    key={index}
                  >
                    <svg
                      className='tick'
                      width='21'
                      height='20'
                      viewBox='0 0 21 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M17.5 4C17.22 4 16.97 4.11 16.79 4.29L7.5 13.59L4.21 10.3C4.03 10.11 3.78 10 3.5 10C2.95 10 2.5 10.45 2.5 11C2.5 11.28 2.61 11.53 2.79 11.71L6.79 15.71C6.97 15.89 7.22 16 7.5 16C7.78 16 8.03 15.89 8.21 15.71L18.21 5.71C18.39 5.53 18.5 5.28 18.5 5C18.5 4.45 18.05 4 17.5 4Z'
                        fill='#FEF2F1'
                      />
                    </svg>
                    <svg
                      className='booked'
                      width='21'
                      height='20'
                      viewBox='0 0 21 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M10.5 0C4.98 0 0.5 4.48 0.5 10C0.5 15.52 4.98 20 10.5 20C16.02 20 20.5 15.52 20.5 10C20.5 4.48 16.02 0 10.5 0ZM2.5 10C2.5 5.58 6.08 2 10.5 2C12.35 2 14.05 2.63 15.4 3.69L4.19 14.9C3.13 13.55 2.5 11.85 2.5 10ZM10.5 18C8.65 18 6.95 17.37 5.6 16.31L16.81 5.1C17.87 6.45 18.5 8.15 18.5 10C18.5 14.42 14.92 18 10.5 18Z'
                        fill='#9696A0'
                      ></path>
                    </svg>{" "}
                    {slot?.slot.trim().slice(0, 5)}
                  </Button>
                );
              })}

            <div className='divider'></div>
            <h5>Altre informazioni</h5>
            {loadFirstRecord === false && <div>{data[0]?.description}</div>}
            {loadFirstRecord === true && <div>{selectedSots?.description}</div>}
          </Col>

          <Col xs={12} sm={4} md={4} lg={4} className='sendSlots'>
            {map}
            <UseButton
              onClick={bookSlotes}
              disabled={currentSlot == ""}
              name={currentSlot == "" ? "" : "activeSlot"}
              variant='primary'
              labels='Prenota ora la donazione'
            />
          </Col>
        </Row>
      </selction>
      <UseAlert show={show} setShow={setShow} variant={variant} alertMsg={alertMsg} />
      {loader == true && <UseLoader />}
    </div>
  );
};

AvailableTimeSlote.propTypes = {};

AvailableTimeSlote.defaultProps = {};

export default AvailableTimeSlote;
