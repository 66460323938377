import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Hookes/useAuth";
import "./AvailableSlot.scss";
const AvailableSlot = () => {
  const { currentUser, loading } = useAuth();
  const navigate = useNavigate();
  const currentDay =
    new Date().getDate() +
    " " +
    new Date().toLocaleDateString("en-US", { month: "long" }).slice(0, 3);

  if (loading) return null;
  return (
    <div className='AvailableSlot' data-testid='AvailableSlot'>
      <Container>
        <Row>
          <Col xs={6} sm={6} md={6} lg={6}>
            <h2>Ciao, {currentUser?.displayName || "User"}!</h2>
            <p>
              Benvenuto nella tua <strong>area personale</strong>, qui puoi prenotare una
              donazione, trovare lo storico delle tue donazioni, visualizzare i nostri
              eventi e molto altro!
            </p>
          </Col>
          <Col xs={6} sm={6} md={6} lg={6} className='slotTimings'>
            <div className='AvailableSlotBg'>
              <div className='AvailableSlotCurrentDay'>{currentDay}</div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

AvailableSlot.propTypes = {};

AvailableSlot.defaultProps = {};

export default AvailableSlot;
