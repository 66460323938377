import { useEffect } from "react";
import { Alert } from "react-bootstrap";
const UseAlert = ({ setShow, variant, alertMsg, show }) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(false);
    }, 3000);

    return () => clearTimeout(timeout);
  }, [show]);
  return (
    <Alert
      show={show}
      onClose={() => setShow(false)}
      key={variant}
      variant={variant}
      dismissible
    >
      {alertMsg}
    </Alert>
  );
};
export default UseAlert;
