import React from "react";
import { Col, Container, Image, Row, Stack } from "react-bootstrap";
import Logo from "../../assets/images/flogo.png";
import "./Footer.scss";
const Footer = () => (
  <div className='Footer' data-testid='Footer'>
    <Container>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} className='pt-5'>
          <Image src={Logo} alt='Logo' />
        </Col>
        <Col xs={12} sm={4} md={4} lg={4}>
          <h5 className='pt-5'>Contacts</h5>
          <p className='pt-3'>
            Ema Health SRL<br></br> P.IVA 03133670640<br></br> Via Ramiro Marcone 5,
            83010, Avellino
          </p>
          <a className='mt-3' href='mailto:ciao@donarosso.it'>
            ciao@donarosso.it
          </a>
        </Col>
        <Col xs={6} sm={4} md={4} lg={4} className='socialMedia position-relative'>
          <a href='https://www.linkedin.com/company/donarosso/'>
            <svg
              width='88'
              height='88'
              viewBox='0 0 88 88'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M34.4792 26.4829C34.4792 29.0365 32.2658 31.1066 29.5355 31.1066C26.8052 31.1066 24.5918 29.0365 24.5918 26.4829C24.5918 23.9294 26.8052 21.8593 29.5355 21.8593C32.2658 21.8593 34.4792 23.9294 34.4792 26.4829Z'
                fill='white'
              />
              <path d='M25.2679 34.5052H33.7186V60.1131H25.2679V34.5052Z' fill='white' />
              <path
                d='M47.3243 34.5052H38.8736V60.1131H47.3243C47.3243 60.1131 47.3243 52.0513 47.3243 47.0108C47.3243 43.9853 48.3574 40.9467 52.4793 40.9467C57.1376 40.9467 57.1096 44.9059 57.0878 47.9732C57.0594 51.9826 57.1272 56.0742 57.1272 60.1131H65.578V46.5978C65.5064 37.9679 63.2577 33.9914 55.8596 33.9914C51.4662 33.9914 48.7428 35.986 47.3243 37.7906V34.5052Z'
                fill='white'
              />
            </svg>
          </a>
          <a href='https://instagram.com/dona.rosso'>
            <svg
              width='66'
              height='66'
              viewBox='0 0 66 66'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M47.1342 21.5177C47.1342 23.2154 45.7579 24.5917 44.0602 24.5917C42.3625 24.5917 40.9862 23.2154 40.9862 21.5177C40.9862 19.82 42.3625 18.4438 44.0602 18.4438C45.7579 18.4438 47.1342 19.82 47.1342 21.5177Z'
                fill='white'
              />
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M32.789 43.0355C38.448 43.0355 43.0355 38.448 43.0355 32.7889C43.0355 27.1299 38.448 22.5424 32.789 22.5424C27.13 22.5424 22.5424 27.1299 22.5424 32.7889C22.5424 38.448 27.13 43.0355 32.789 43.0355ZM32.789 38.9369C36.1844 38.9369 38.9369 36.1843 38.9369 32.7889C38.9369 29.3935 36.1844 26.641 32.789 26.641C29.3936 26.641 26.6411 29.3935 26.6411 32.7889C26.6411 36.1843 29.3936 38.9369 32.789 38.9369Z'
                fill='white'
              />
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M12.2959 31.9692C12.2959 25.0829 12.2959 21.6397 13.6361 19.0095C14.8149 16.6959 16.6959 14.8149 19.0096 13.636C21.6398 12.2959 25.0829 12.2959 31.9693 12.2959H33.6087C40.495 12.2959 43.9382 12.2959 46.5684 13.636C48.882 14.8149 50.7631 16.6959 51.9419 19.0095C53.2821 21.6397 53.2821 25.0829 53.2821 31.9692V33.6087C53.2821 40.495 53.2821 43.9382 51.9419 46.5684C50.7631 48.882 48.882 50.763 46.5684 51.9419C43.9382 53.282 40.495 53.282 33.6087 53.282H31.9693C25.0829 53.282 21.6398 53.282 19.0096 51.9419C16.6959 50.763 14.8149 48.882 13.6361 46.5684C12.2959 43.9382 12.2959 40.495 12.2959 33.6087V31.9692ZM31.9693 16.3945H33.6087C37.1195 16.3945 39.5061 16.3977 41.3508 16.5484C43.1477 16.6952 44.0667 16.9613 44.7077 17.2879C46.2501 18.0738 47.5041 19.3278 48.29 20.8702C48.6166 21.5113 48.8827 22.4302 49.0295 24.2271C49.1803 26.0718 49.1835 28.4584 49.1835 31.9692V33.6087C49.1835 37.1195 49.1803 39.5061 49.0295 41.3508C48.8827 43.1477 48.6166 44.0666 48.29 44.7076C47.5041 46.2501 46.2501 47.5041 44.7077 48.29C44.0667 48.6166 43.1477 48.8827 41.3508 49.0295C39.5061 49.1802 37.1195 49.1834 33.6087 49.1834H31.9693C28.4585 49.1834 26.0719 49.1802 24.2271 49.0295C22.4302 48.8827 21.5113 48.6166 20.8703 48.29C19.3279 47.5041 18.0739 46.2501 17.288 44.7076C16.9613 44.0666 16.6952 43.1477 16.5484 41.3508C16.3977 39.5061 16.3945 37.1195 16.3945 33.6087V31.9692C16.3945 28.4584 16.3977 26.0718 16.5484 24.2271C16.6952 22.4302 16.9613 21.5113 17.288 20.8702C18.0739 19.3278 19.3279 18.0738 20.8703 17.2879C21.5113 16.9613 22.4302 16.6952 24.2271 16.5484C26.0719 16.3977 28.4585 16.3945 31.9693 16.3945Z'
                fill='white'
              />
            </svg>
          </a>
        </Col>
        <Col xs={6} sm={4} md={4} lg={4} className='needhelp'>
          <svg
            width='44'
            height='43'
            viewBox='0 0 44 43'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M20.9711 0.272702C9.76381 0.808811 0.919712 10.1857 0.953711 21.4579C0.964063 24.8911 1.78906 28.1316 3.2444 30.9949L1.00994 41.8917C0.889063 42.4811 1.41822 42.9972 2.00107 42.8586L12.6292 40.3288C15.3598 41.6953 18.4267 42.4846 21.6726 42.5343C33.1275 42.7101 42.6789 33.5723 43.0359 22.0684C43.4186 9.73699 33.2883 -0.31663 20.9711 0.272702ZM33.6527 33.0994C30.5402 36.2265 26.4018 37.9486 22 37.9486C19.4226 37.9486 16.9551 37.3676 14.6656 36.2219L13.1855 35.4811L6.66933 37.0321L8.04094 30.3428L7.31175 28.9082C6.12322 26.5698 5.52058 24.041 5.52058 21.3924C5.52058 16.9701 7.23472 12.8125 10.3473 9.6854C13.432 6.58631 17.6379 4.83615 22.0003 4.83615C26.4021 4.83625 30.5403 6.55837 33.6528 9.6853C36.7654 12.8123 38.4795 16.9699 38.4796 21.3921C38.4795 25.775 36.7375 30.0004 33.6527 33.0994Z'
              fill='#F7F7FA'
            />
            <path
              d='M32.2144 25.9787L28.1378 24.8027C27.6018 24.6481 27.0246 24.8009 26.6341 25.2007L25.6372 26.2211C25.2168 26.6514 24.579 26.7896 24.0226 26.5635C22.0941 25.7794 18.0374 22.1557 17.0015 20.3432C16.7026 19.8203 16.752 19.1666 17.119 18.6896L17.9893 17.5583C18.3303 17.1151 18.4023 16.5198 18.1768 16.0075L16.4616 12.1102C16.0508 11.1768 14.8635 10.9051 14.0878 11.5641C12.95 12.5309 11.6 14.0001 11.4359 15.6277C11.1466 18.4973 12.3716 22.1146 17.0038 26.4581C22.3553 31.4761 26.6407 32.1391 29.4309 31.46C31.0135 31.0749 32.2783 29.531 33.0765 28.2669C33.6207 27.405 33.191 26.2604 32.2144 25.9787Z'
              fill='#F7F7FA'
            />
          </svg>

          <h3>Hai ancora bisogno di aiuto?</h3>
          <a className='whatsapp-button' href='http://donarosso.it/whatsapp'>
            Scrivici ora
          </a>
        </Col>
      </Row>
      <div className='footerBottom'>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6}>
            <Stack direction='horizontal' gap={0} className='stack'>
              <div className='listItem'>
                <a className='reserved' href='#link'>
                  2024 All right reserved
                </a>
              </div>
            </Stack>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <Stack direction='horizontal' className='stack' gap={0}>
              <div className='listItem'>
                <a href='https://donarosso.it/wp-content/uploads/2023/07/Informativa-Privacy-Easyblood_Ema-Health_21-07-2023.pdf'>
                  Terms and Condition
                </a>
              </div>
              <div className='listItem'>
                <a href='https://donarosso.it/wp-content/uploads/2023/07/Informativa-Privacy-Easyblood_Ema-Health_21-07-2023.pdf'>
                  Privacy policy
                </a>
              </div>
              <div className='listItem'>
                <a href='#link'>Cookie Policy</a>
              </div>
            </Stack>
          </Col>
        </Row>
      </div>
    </Container>
  </div>
);

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
