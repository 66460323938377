// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
// import {getAuth,connectAuthEmulator,signInWithEmailAndPassword} from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCk9uu7EB-fihfy7dCf6H9SCExfnFZrDPU",
  authDomain: "rosso-easyblood.firebaseapp.com",
  projectId: "rosso-easyblood",
  storageBucket: "rosso-easyblood.appspot.com",
  messagingSenderId: "723595859817",
  appId: "1:723595859817:web:d76ff5def0c9ec7ad8cff8",
  measurementId: "G-939JPK8PHV",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const db = getFirestore(app);
export const functions = getFunctions();
// export const auth = getAuth();
