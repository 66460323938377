import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Feedback from "react-bootstrap/esm/Feedback";
import { useFieldArray, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import UseLoader from "../../Components/Loader/Loader";
import Modal from "../../Components/Modal/Modal";
import UseTopBar from "../../Components/TopBar/TopBar";
import UseButton from "../../Components/UseButton/UseButton";
import UseCounter from "../../Components/UseCounter/UseCounter";
import LazyBreadCrum from "../BreadCrum/BreadCrum";
import "./NoticeCard.scss";

const NoticeCard = () => {
  const [counter, setCounter] = useState(0);
  const [companies, setCompanies] = useState([]);
  const [addVariableModal, setAddVariableModal] = useState(false);
  const [deleteAvvisoModal, setDeleteAvvisoModal] = useState(false);
  const [deleteVariableModal, setDeleteVariableModal] = useState(false);
  const [confirmFrom, setConfirmFrom] = useState(null);
  const [messagePreview, setMessagePreview] = useState("");
  const [variableToEdit, setVariabletoEdit] = useState(null);
  const [variableToDelete, setVariableToDelete] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [variable, setVariable] = useState({
    name: "",
    description: "",
    exampleValue: "",
  });
  const [avviso, setAvviso] = useState(
    location?.state?._alert || {
      name: "",
      parameter: "",
      differenceInDays: 0,
      description: "",
      company: "",
      whatsappTemplateId: "",
      subject: "",
      message: "",
      gender: "",
      variables: [],
      active: false,
      channel: "all",
    },
  );
  const functions = getFunctions();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: location?.state?._alert || {
      name: "",
      parameter: "",
      description: "",
      whatsappTemplateId: "",
      subject: "",
      message: "",
    },
    mode: "onSubmit",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "variables",
  });

  useEffect(() => {
    if (avviso) {
      buildPreview(avviso.message);
    }
  }, [avviso]);

  useEffect(() => {
    try {
      const getCompanies = httpsCallable(functions, "getCompanies");
      getCompanies()
        .then((response) => {
          const result = response.data;
          setCompanies(result);
        })
        .catch((error) => console.error("error", error));
    } catch (error) {
      console.error("error", error);
    }
  }, []);

  const buildPreview = (text) => {
    avviso.variables.forEach((variable) => {
      const pattern = new RegExp(`{{${variable.name}}}`, "g");
      text = text.replace(pattern, variable.exampleValue);
    });
    setMessagePreview(text);
  };

  const createAlert = () => {
    try {
      setLoading(true);
      const setAlert = httpsCallable(functions, avviso.id ? "updateAlert" : "setAlert");
      setAlert({ ...avviso })
        .then((response) => {
          navigate("/Gestione-avvisi");
          setLoading(false);
        })
        .catch((error) => console.error("error", error));
    } catch (error) {
      setLoading(false);
      console.error("error", error);
    }
  };

  const deleteAlert = () => {
    if (avviso.id) {
      setLoading(true);
      try {
        const deleteAlert = httpsCallable(functions, "deleteAlert");
        deleteAlert({ id: avviso.id })
          .then((response) => {
            navigate("/Gestione-avvisi");
            setLoading(false);
          })
          .catch((error) => console.error("error", error));
      } catch (error) {
        setLoading(false);
        console.error("error", error);
      }
    } else {
      navigate("/Gestione-avvisi");
    }
  };

  useEffect(() => {
    if (location && location.state && location.state._alert) {
      buildPreview(location.state._alert.message);
    }
  }, [location]);

  return (
    <div className='NoticeCard' data-testid='NoticeCard'>
      <UseTopBar back='Gestione-avvisi' />
      <LazyBreadCrum />
      <h2>{avviso.name}</h2>
      <Form onSubmit={handleSubmit(createAlert)}>
        <Row>
          <Col xs={12} sm={6} md={6} lg={6}>
            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <Form.Label>Nome avviso</Form.Label>
              <Form.Control
                style={{ borderColor: errors?.name?.message && "red" }}
                name='name'
                {...register("name", { required: "campo obbligatorio" })}
                value={avviso.name}
                onChange={(e) => {
                  setAvviso((old) => {
                    return {
                      ...old,
                      name: e.target.value,
                    };
                  });
                }}
                type='text'
                placeholder='Avviso 2 giorni prima'
              />
              {errors?.name?.message && (
                <Feedback className='d-block' type='invalid'>
                  {errors?.name?.message}
                </Feedback>
              )}
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6} className='dayDifferene'>
            <Row>
              <Col xs={8} sm={8} md={8} lg={8}>
                <Form.Group className='mb-3' controlId='formBasicEmail'>
                  <Form.Label>Parametro</Form.Label>
                  <Form.Select
                    style={{ borderColor: errors?.parameter?.message && "red" }}
                    name='parameter'
                    {...register("parameter", { required: "campo obbligatorio" })}
                    aria-label='Default select example'
                    onChange={(e) => {
                      setAvviso((old) => {
                        return {
                          ...old,
                          parameter: e.target.value,
                        };
                      });
                    }}
                    value={avviso.parameter}
                  >
                    <option value=''>Selezionare</option>
                    <option value={"donationDate"}>Data della donazione</option>
                    <option value={"bookingDate"}>Giorno di prenotazione</option>
                    <option value={"dateOfBirth"}>Compleanno</option>
                    <option value={"today"}>⁠⁠Giorno odierno</option>
                  </Form.Select>
                  {/* <Form.Control.Feedback type='invalid'>
                              campo obbligatorio

                            </Form.Control.Feedback> */}
                  {errors?.parameter?.message && (
                    <Feedback className='d-block' type='invalid'>
                      {errors?.parameter?.message}
                    </Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col xs={4} sm={4} md={4} lg={4}>
                <Form.Group className='mb-3' controlId='formBasicEmail'>
                  <Form.Label>Giorni di differenza</Form.Label>
                  {/* <Form.Control type="text" placeholder="Ultima donazione" />                                       */}
                  <UseCounter
                    counter={avviso.differenceInDays}
                    setCounter={(n) => {
                      setAvviso((old) => {
                        return {
                          ...old,
                          differenceInDays: n,
                        };
                      });
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}>
            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <Form.Label>Descrizione dell'avviso</Form.Label>
              <Form.Control
                style={{ borderColor: errors?.description?.message && "red" }}
                name='description'
                {...register("description", { required: "campo obbligatorio" })}
                type='text'
                value={avviso.description}
                onChange={(e) => {
                  setAvviso((old) => {
                    return {
                      ...old,
                      description: e.target.value,
                    };
                  });
                }}
                placeholder='Questa descrizione serve per...'
              />
              {errors?.description?.message && (
                <Feedback className='d-block' type='invalid'>
                  {errors?.description?.message}
                </Feedback>
              )}
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}>
            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <Form.Label>WhatsApp Template ID</Form.Label>
              <Form.Control
                name='whatsappTemplateId'
                style={{ borderColor: errors?.whatsappTemplateId?.message && "red" }}
                {...register("whatsappTemplateId", { required: "campo obbligatorio" })}
                type='text'
                value={avviso.whatsappTemplateId}
                onChange={(e) => {
                  setAvviso((old) => {
                    return {
                      ...old,
                      whatsappTemplateId: e.target.value,
                    };
                  });
                }}
                placeholder='WhatsApp Template ID'
              />
              {errors?.whatsappTemplateId?.message && (
                <Feedback className='d-block' type='invalid'>
                  {errors?.whatsappTemplateId?.message}
                </Feedback>
              )}
            </Form.Group>
          </Col>

          <Col xs={12} sm={6} md={6} lg={6}>
            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <Form.Label>Soggetto</Form.Label>
              <Form.Control
                name='subject'
                style={{ borderColor: errors?.subject?.message && "red" }}
                {...register("subject", { required: "campo obbligatorio" })}
                type='text'
                value={avviso.subject}
                onChange={(e) => {
                  setAvviso((old) => {
                    return {
                      ...old,
                      subject: e.target.value,
                    };
                  });
                }}
                placeholder='Manca poco all tua donazione..'
              />
              {errors?.subject?.message && (
                <Feedback className='d-block' type='invalid'>
                  {errors?.subject?.message}
                </Feedback>
              )}
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}>
            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <Form.Label>Sesso</Form.Label>
              <Form.Select
                aria-label='Default select example'
                onChange={(e) => {
                  setAvviso((old) => {
                    return {
                      ...old,
                      gender: e.target.value,
                    };
                  });
                }}
                value={avviso.gender}
              >
                <option value=''>Selezionare</option>
                <option value={"maschio"}>{"Maschio"}</option>
                <option value={"femmina"}>{"Femmina"}</option>
              </Form.Select>
              {/* <Form.Control.Feedback type='invalid'>
                              campo obbligatorio
                            </Form.Control.Feedback> */}
              {errors?.company?.message && (
                <Feedback className='d-block' type='invalid'>
                  {errors?.company?.message}
                </Feedback>
              )}
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}>
            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <Form.Label>Azienda collegata</Form.Label>
              <Form.Select
                aria-label='Default select example'
                style={{ borderColor: errors?.company?.message && "red" }}
                onChange={(e) => {
                  setAvviso((old) => {
                    return {
                      ...old,
                      company: e.target.value,
                    };
                  });
                }}
                value={avviso.company}
              >
                <option value=''>Selezionare</option>
                {companies &&
                  companies.map((company) => {
                    return <option value={company.id}>{company.name}</option>;
                  })}
              </Form.Select>
              {/* <Form.Control.Feedback type='invalid'>
                              campo obbligatorio
                            </Form.Control.Feedback> */}
              {errors?.company?.message && (
                <Feedback className='d-block' type='invalid'>
                  {errors?.company?.message}
                </Feedback>
              )}
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}>
            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <Form.Label>Canale</Form.Label>
              <Form.Select
                aria-label='Default select example'
                onChange={(e) => {
                  setAvviso((old) => {
                    return {
                      ...old,
                      channel: e.target.value,
                    };
                  });
                }}
                value={avviso.channel}
              >
                <option value='all'>Tutti</option>
                <option value={"whatsaop"}>{"WhatsApp"}</option>
                <option value={"email"}>{"Email"}</option>
              </Form.Select>
              {/* <Form.Control.Feedback type='invalid'>
                              campo obbligatorio
                            </Form.Control.Feedback> */}
              {errors?.company?.message && (
                <Feedback className='d-block' type='invalid'>
                  {errors?.company?.message}
                </Feedback>
              )}
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}>
            <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
              <Form.Label>Messaggio</Form.Label>
              <Form.Control
                as='textarea'
                rows={3}
                maxLength={200}
                value={avviso.message}
                style={{ borderColor: errors?.message?.message && "red" }}
                name='message'
                {...register("message", { required: "campo obbligatorio" })}
                onChange={(e) => {
                  setAvviso((old) => {
                    return {
                      ...old,
                      message: e.target.value,
                    };
                  });
                  buildPreview(e.target.value);
                }}
              />
              {errors?.message?.message && (
                <Feedback className='d-block' type='invalid'>
                  {errors?.message?.message}
                </Feedback>
              )}
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}>
            <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
              <Form.Label>Preview del messaggio</Form.Label>
              <Form.Control
                as='textarea'
                rows={3}
                maxLength={200}
                value={messagePreview}
                disabled={true}
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <p>Variabili:</p>
            <div className='variables'>
              {fields &&
                fields.map((_var, index) => {
                  return (
                    <div
                      key={_var.id}
                      className='variable'
                      onClick={() => {
                        setAddVariableModal(true);
                        setVariabletoEdit({
                          ..._var,
                          oldName: _var.name,
                        });
                      }}
                    >
                      {_var.name}
                      <svg
                        onClick={(event) => {
                          event.stopPropagation(); // Stop the click event from bubbling up to the parent div
                          setVariableToDelete({ ..._var, index });
                          setDeleteVariableModal(true);
                          buildPreview(avviso.message);
                        }}
                        className='delete_username'
                        width='8'
                        height='8'
                        viewBox='0 0 8 8'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M5.128 4L7.76 1.368C7.912 1.224 8 1.024 8 0.8C8 0.36 7.64 0 7.2 0C6.976 0 6.776 0.088 6.632 0.232L4 2.872L1.368 0.232C1.224 0.088 1.024 0 0.8 0C0.36 0 0 0.36 0 0.8C0 1.024 0.088 1.224 0.232 1.368L2.872 4L0.24 6.632C0.0880002 6.776 0 6.976 0 7.2C0 7.64 0.36 8 0.8 8C1.024 8 1.224 7.912 1.368 7.768L4 5.128L6.632 7.76C6.776 7.912 6.976 8 7.2 8C7.64 8 8 7.64 8 7.2C8 6.976 7.912 6.776 7.768 6.632L5.128 4Z'
                          fill='#4A4B57'
                        />
                      </svg>
                    </div>
                  );
                })}
              <UseButton
                type={"button"}
                name='adminBtnPrimary'
                variant='btn btn-outline-primary'
                labels='Crea variabile'
                onClick={() => {
                  setAddVariableModal(true);
                }}
              />
            </div>
          </Col>
          <Col xs={12} className='col-reverse'>
            <Form.Check
              type='switch'
              id='remainderDay'
              name='remainderDay'
              onChange={(e) => {
                setAvviso((old) => {
                  return {
                    ...old,
                    active: e.target.checked,
                  };
                });
              }}
              checked={avviso.active === true}
              value={avviso.active}
              label='Disattiva avviso'
            />
            <Button
              className='delete'
              onClick={() => {
                setDeleteAvvisoModal(true);
              }}
            >
              <svg
                width='14'
                height='16'
                viewBox='0 0 14 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M2.83398 15.5C2.37565 15.5 1.98343 15.3369 1.65732 15.0108C1.33121 14.6847 1.16787 14.2922 1.16732 13.8333V3H0.333984V1.33333H4.50065V0.5H9.50065V1.33333H13.6673V3H12.834V13.8333C12.834 14.2917 12.6709 14.6842 12.3448 15.0108C12.0187 15.3375 11.6262 15.5006 11.1673 15.5H2.83398ZM11.1673 3H2.83398V13.8333H11.1673V3ZM4.50065 12.1667H6.16732V4.66667H4.50065V12.1667ZM7.83398 12.1667H9.50065V4.66667H7.83398V12.1667Z'
                  fill='#2264D1'
                />
              </svg>
            </Button>
          </Col>
        </Row>
        <UseButton
          type='submit'
          name='adminBtnPrimary noticeSaveBtn'
          variant='primary'
          labels='Salva modifiche'
        />
      </Form>
      {addVariableModal && (
        <Modal
          className={"var-modal"}
          title={variableToEdit ? "Modifica Variabile" : "Crea variabile"}
          onCancel={() => {
            setAddVariableModal(false);
            setVariabletoEdit(null);
            setVariable({
              name: "",
              description: "",
              exampleValue: "",
            });
          }}
          onConfirm={() => {
            if (variableToEdit) {
              let vars = avviso.variables.filter((_var) => {
                return _var.name !== variableToEdit.oldName;
              });
              setAvviso((old) => {
                return {
                  ...old,
                  variables: [...vars, variableToEdit],
                };
              });
            } else {
              append(variable);
              setAvviso((old) => {
                return {
                  ...old,
                  variables: [...old.variables, variable],
                };
              });
            }

            setVariabletoEdit(null);
            setVariable({
              name: "",
              description: "",
              exampleValue: "",
            });
            setAddVariableModal(false);
          }}
          confirmText={variableToEdit ? "Salva modfiche" : "Crea variabile"}
          cancelText={"Indietro"}
        >
          <Form.Group className='mb-3' controlId='formBasicEmail'>
            <Form.Label>Nome variabile</Form.Label>
            <Form.Select
              style={{ borderColor: errors?.parameter?.message && "red" }}
              aria-label='Default select example'
              onChange={(e) => {
                if (variableToEdit) {
                  setVariabletoEdit((old) => {
                    return {
                      ...old,
                      name: e.target.value,
                    };
                  });
                } else {
                  setVariable((old) => {
                    return {
                      ...old,
                      name: e.target.value,
                    };
                  });
                }
              }}
              value={variableToEdit?.name || variable.name}
            >
              <option value=''>Selezionare</option>
              <option value={"name"}>Nome</option>
              <option value={"date"}>Data</option>
              <option value={"address"}>Indirizzo</option>
              <option value={"slot"}>Fascia oraria</option>
              <option value={"breakfast_info"}>Informazioni sulla colazione</option>
              <option value={"instructions"}>Istruzioni</option>
            </Form.Select>
          </Form.Group>
          {/* <Form.Control.Feedback type='invalid'>
                              campo obbligatorio

                            </Form.Control.Feedback> */}
          {/* {errors?.parameter?.message && (
                    <Feedback className='d-block' type='invalid'>
                      {errors?.parameter?.message}
                    </Feedback>
                  )}
                </Form.Group> */}
          {/* <Form.Group className='mb-3' controlId='formBasicEmail'>
            <Form.Label>Nome variabile</Form.Label>
            <Form.Control
              type='text'
              value={variableToEdit?.name || variable.name}
              onChange={(e) => {
                if (variableToEdit) {
                  setVariabletoEdit((old) => {
                    return {
                      ...old,
                      name: e.target.value,
                    };
                  });
                } else {
                  setVariable((old) => {
                    return {
                      ...old,
                      name: e.target.value,
                    };
                  });
                }
              }}
              placeholder='Nome'
            />
          </Form.Group> */}
          <Form.Group className='mb-3' controlId='formBasicEmail'>
            <Form.Label>Descrizione</Form.Label>
            <Form.Control
              type='text'
              value={variableToEdit?.description || variable.description}
              onChange={(e) => {
                if (variableToEdit) {
                  setVariabletoEdit((old) => {
                    return {
                      ...old,
                      description: e.target.value,
                    };
                  });
                } else {
                  setVariable((old) => {
                    return {
                      ...old,
                      description: e.target.value,
                    };
                  });
                }
              }}
              placeholder="Nome dell'utente"
            />
          </Form.Group>
          <Form.Group className='mb-3' controlId='formBasicEmail'>
            <Form.Label>Valore d'esempio'</Form.Label>
            <Form.Control
              type='text'
              value={variableToEdit?.exampleValue || variable.exampleValue}
              onChange={(e) => {
                if (variableToEdit) {
                  setVariabletoEdit((old) => {
                    return {
                      ...old,
                      exampleValue: e.target.value,
                    };
                  });
                } else {
                  setVariable((old) => {
                    return {
                      ...old,
                      exampleValue: e.target.value,
                    };
                  });
                }
              }}
            />
          </Form.Group>
        </Modal>
      )}

      {deleteAvvisoModal && (
        <Modal
          className={"var-modal"}
          title='Vuoi davvero eliminare questo avviso?'
          onCancel={() => {
            setDeleteAvvisoModal(false);
          }}
          onConfirm={() => {
            deleteAlert();
            setDeleteAvvisoModal(false);
          }}
          confirmText={"Elimina"}
          cancelText={"Annulla"}
        >
          <p style={{ textAlign: "center" }}>
            Una volta eliminato non potrai tornare indietro
          </p>
        </Modal>
      )}
      {deleteVariableModal && (
        <Modal
          className={"var-modal"}
          title={`Vuoi davvero eliminare la variabile "${variableToDelete.name}"`}
          onCancel={() => {
            setDeleteVariableModal(false);
          }}
          onConfirm={() => {
            remove(variableToDelete.index);
            //   setAvviso((old) => {
            //     return {
            //       ...old,
            //       variables: old.variables.filter(
            //         (_var) => _var.name !== variableToDelete.name,
            //       ),
            //     };
            //   });
            setVariableToDelete(null);
            setDeleteVariableModal(false);
          }}
          confirmText={"Elimina"}
          cancelText={"Annulla"}
        >
          <p style={{ textAlign: "center" }}>
            Una volta eliminato non potrai tornare indietro
          </p>
        </Modal>
      )}
      {loading && <UseLoader />}
    </div>
  );
};

NoticeCard.propTypes = {};

NoticeCard.defaultProps = {};

export default NoticeCard;
