import { getAuth, signInAnonymously } from "firebase/auth";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UseLoader from "../../Components/Loader/Loader";
export default function Generali() {
  const navigate = useNavigate();
  useEffect(() => {
    try {
      const auth = getAuth();
      signInAnonymously(auth)
        .then((response) => {
          console.log("anonymus auth login response", response);
          let id = response.user.uid;
          localStorage.setItem("uid", id);
          navigate("/generali/tPlM6Bb3iVpYl82NHouH");
        })
        .catch((error) => console.error("error", error));
    } catch (error) {
      console.error("error", error);
    }
  }, []);
  return (
    <div>
      <UseLoader />
    </div>
  );
}
