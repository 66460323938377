import "./UseImageUpload.scss";
const UseImageUpload = ({ label, onChange }) => {
  return (
    <div className='useImageUpload'>
      <label for='upload'>
        {label}
        <input type='file' accept='image/*' onChange={onChange} id='upload' />
      </label>
    </div>
  );
};
export default UseImageUpload;
