import React from "react";
import { Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./Donate.scss";
const Donate = () => {
  const navigate = useNavigate();
  const gotoquiz = () => {
    navigate("/quiz");
  };
  return (
    <div className='Donate' data-testid='Donate'>
      <Container>
        <h2>
          Hai i requisiti per fare<br></br> una donazione?
        </h2>
        <Button varient='primary' onClick={gotoquiz}>
          Vai al quiz
        </Button>
      </Container>
    </div>
  );
};

Donate.propTypes = {};

Donate.defaultProps = {};

export default Donate;
