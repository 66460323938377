import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { Col, Form, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import UseLoader from "../../Components/Loader/Loader";
import Modal from "../../Components/Modal/Modal";
import SearchFilter from "../../Components/SearchFilter/SearchFilter";
import UseTopBar from "../../Components/TopBar/TopBar";
import UseButton from "../../Components/UseButton/UseButton";
import "./Companies.scss";
const Companies = () => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  // pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteCompanyConfirm, setDeleteCompanyConfirm] = useState(false);
  const [companyToDelete, setCompanyToDelete] = useState(null);
  const [openFilterOne, setOpenFilterOne] = useState();

  // const [data4, setData4] = useState([])
  const [citiesSelected, setcitiesSelected] = useState([]);

  const [searchFilterLastActivity, setSearchFilterLastActivity] = useState("");
  const [loader, setLoader] = useState(true);
  const [companyLastAcrtivityFilter, setCompanyLastActivityFilter] = useState(null);
  const functions = getFunctions();
  const navigate = useNavigate();
  const addCompanyProfile = () => {
    navigate("/Aziende/Aggiungi-azienda");
  };

  useEffect(() => {
    // const result = data.length / rowsPerPage;
    // const finalValue = Math.round(result)
    // setTotalPage(finalValue - 1);

    getCompamiesDetails();
  }, []);

  // const fireBaseTime = new Date(
  //   data.createdAt._seconds * 1000 + data.createdAt._nanoseconds / 1000000,
  // );
  // console.log('fireBaseTime',fireBaseTime)

  const getCompamiesDetails = (data) => {
    try {
      const getCompanies = httpsCallable(functions, "getCompanies");
      getCompanies(data)
        .then((response) => {
          setLoader(false);
          setData(response.data);
        })
        .catch((error) => console.error("error", error));
    } catch (error) {
      console.log("error", error);
    }
  };

  const removeCompanies = (cid) => {
    setDeleteCompanyConfirm(true);
    setCompanyToDelete(cid);
  };

  const removeCompoanyConfirm = (cid) => {
    setLoader(true);
    const deleteCompanies = httpsCallable(functions, "deleteCompany");
    setDeleteCompanyConfirm(false);
    setCompanyToDelete(null);

    deleteCompanies({ id: cid })
      .then((response) => {
        setLoader(false);
        window.location.reload();
      })
      .catch((error) => {
        console.error("error", error);
        setLoader(false);
      });
  };

  const removeCompoanyCancel = () => {
    setDeleteCompanyConfirm(false);
    setCompanyToDelete(null);
  };

  const editCompany = (id, name) => {
    // console.log("iddddd", id, "name>>>>>>>", name);
    localStorage.setItem("cid", id);
    navigate("/Aziende/Modifica-azienda", {
      state: { editCompanyId: id, companyName: name, editCompany: true },
    });
  };

  const filterLastActivity = (value) => {
    setCompanyLastActivityFilter(value);
  };

  const getCompaniesWithFilters = (reset) => {
    getCompamiesDetails(
      reset
        ? null
        : {
            filters: {
              orderBy: companyLastAcrtivityFilter,
              city: citiesSelected,
            },
          },
    );
    setOpenFilterOne(false);
    setSearchFilterLastActivity(false);
  };
  function formatDateToItalian(dateStr) {
    const months = [
      "Gennaio",
      "Febbraio",
      "Marzo",
      "Aprile",
      "Maggio",
      "Giugno",
      "Luglio",
      "Agosto",
      "Settembre",
      "Ottobre",
      "Novembre",
      "Dicembre",
    ];

    const date = new Date(dateStr);

    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  }
  // console.log('total page in companies',totalPage)
  // console.log('Dateeeeee',data[0].createdAt._nanoseconds)
  // console.log('Formated date',new Date(data[0]?.createdAt?._seconds * 1000 + data[0]?.createdAt?._nanoseconds / 1000000 ))
  return (
    <div className='Companies' data-testid='Companies'>
      <UseTopBar />
      <Row>
        <Col xs={12} sm={12} md={12} lg={4}>
          <h2>Tutte le aziende</h2>
        </Col>
        <Col xs={12} sm={12} md={12} lg={8}>
          <Row>
            <Col xs={12} sm={8} md={8} lg={4}>
              <Form.Control
                className='search'
                type='text'
                placeholder='Cerca un’azienda'
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Col>
            <Col xs={12} sm={4} md={4} lg={8} className='d-flex buttons'>
              <UseButton
                name='adminBtnPrimary'
                variant='btn btn-outline-primary'
                labels='Cancella filtri'
                onClick={() => {
                  setCompanyLastActivityFilter(null);
                  getCompaniesWithFilters(true);
                }}
              />
              <UseButton
                name='adminBtnPrimary'
                variant='btn btn-outline-primary'
                labels='Rosso Company'
                onClick={() => {
                  editCompany("FZQRlO8KtgiMU7TN4eZQ", "Rosso");
                }}
              />
              <UseButton
                onClick={addCompanyProfile}
                name='adminBtnPrimary'
                variant='primary'
                labels='Aggiungi azienda'
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={6} lg={3} className='reactCustomDatePickerFilterAdmin'>
          <p>Ordina per</p>
          {/* <Form.Select aria-label="Default select example">
              <option>Sempre</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </Form.Select> */}
          {/*<DatePicker locale={it} dateFormat="dd/MM/YYYY" selected={startDate} onChange={getDate} />*/}

          <SearchFilter
            open={searchFilterLastActivity}
            setOpen={setSearchFilterLastActivity}
            onConfirm={() => {
              getCompaniesWithFilters();
            }}
            data={{
              name: "CompaniesLastActivity",
              options: [
                {
                  label: "Ultimi eventi creati",
                  value: "mostRecentEvent",
                },
                {
                  label: "Numero utenti iscritti (ASC)",
                  value: "mostRegisteredUsersAsc",
                },
                {
                  label: "Numero utenti iscritti (DESC)",
                  value: "mostRegisteredUsersDesc",
                },
              ],
            }}
            type='radio'
            onChangeFilterValue={filterLastActivity}
            placeholder={"Cerca filtro"}
            selectedValue={companyLastAcrtivityFilter}
          />
        </Col>
        {/* <Col xs={12} sm={12} md={6} lg={3}>
          <p>Città</p>
          <SearchFilter
            open={openFilterOne}
            setOpen={setOpenFilterOne}
            data={[
              {
                value: "Milano",
                label: "Milano",
              },
              {
                value: "Torino",
                label: "Torino",
              },
              {
                value: "Roma",
                label: "Roma",
              },
            ]}
            selectedValue={citiesSelected}
            onConfirm={() => {
              getCompaniesWithFilters();
            }}
            onChangeFilterValue={filterDonationCenter}
            placeholder='Filtra per donazione'
          />
        </Col> */}
      </Row>
      <Table hover responsive>
        <thead>
          <tr>
            <th>Nome azienda</th>
            <th>Utenti iscritti</th>
            <th>Data di creazione</th>
            <th>Stato</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data
              .filter((item) => {
                return search.toLowerCase() == ""
                  ? item
                  : item.name.toLowerCase().includes(search.toLowerCase());
              })
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((data, index) => {
                return (
                  <tr key={index}>
                    <td
                      style={{ cursor: "pointer" }}
                      onClick={() => editCompany(data.id, data.name)}
                    >
                      {data.name}
                    </td>
                    <td>{data.totalUsers}</td>
                    <td>
                      {formatDateToItalian(
                        new Date(
                          data.updatedAt._seconds * 1000 +
                            data.updatedAt._nanoseconds / 1000000,
                        ),
                      )}
                    </td>
                    <td>
                      {data.isActive || data.isActive == "" ? (
                        <span
                          className={data.isActive ? "activeUser" : "inactiveUser"}
                        ></span>
                      ) : null}
                    </td>
                    <td className='d-flex'>
                      <button
                        className='edit'
                        onClick={() => editCompany(data.id, data.name)}
                      >
                        <svg
                          width='16'
                          height='18'
                          viewBox='0 0 16 18'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M3.345 12.2419L11.7967 3.79026L10.6183 2.61193L2.16667 11.0636V12.2419H3.345ZM4.03583 13.9086H0.5V10.3728L10.0292 0.843598C10.1854 0.687372 10.3974 0.599609 10.6183 0.599609C10.8393 0.599609 11.0512 0.687372 11.2075 0.843598L13.565 3.2011C13.7212 3.35737 13.809 3.56929 13.809 3.79026C13.809 4.01123 13.7212 4.22316 13.565 4.37943L4.03583 13.9086ZM0.5 15.5753H15.5V17.2419H0.5V15.5753Z'
                            fill='#2264D1'
                          />
                        </svg>
                      </button>
                      <button className='delete' onClick={() => removeCompanies(data.id)}>
                        <svg
                          width='14'
                          height='16'
                          viewBox='0 0 14 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M2.83398 15.5C2.37565 15.5 1.98343 15.3369 1.65732 15.0108C1.33121 14.6847 1.16787 14.2922 1.16732 13.8333V3H0.333984V1.33333H4.50065V0.5H9.50065V1.33333H13.6673V3H12.834V13.8333C12.834 14.2917 12.6709 14.6842 12.3448 15.0108C12.0187 15.3375 11.6262 15.5006 11.1673 15.5H2.83398ZM11.1673 3H2.83398V13.8333H11.1673V3ZM4.50065 12.1667H6.16732V4.66667H4.50065V12.1667ZM7.83398 12.1667H9.50065V4.66667H7.83398V12.1667Z'
                            fill='#2264D1'
                          />
                        </svg>
                      </button>
                    </td>
                  </tr>
                );
              })
          ) : (
            <tr>
              <td colSpan='5' style={{ textAlign: "center" }}>
                Nessun dato
              </td>
            </tr>
          )}

          {/* {
                    data.length>0? (data.map((data,index)=>{
                        return <tr key={index}>
                                        <td>{data.companyDomain}</td>
                                        <td>{data.name}</td>
                                        <td>
                                          {new Date(data.createdAt._seconds * 1000 + data.createdAt._nanoseconds / 1000000).toDateString()}&nbsp;&nbsp;
                                          {new Date(data.createdAt._seconds * 1000 + data.createdAt._nanoseconds / 1000000).toLocaleTimeString()}
                                          </td>
                                        <td>-</td>
                                        <td>
                    <button className='edit' onClick={()=>editCompany(data.id,data.name)}><svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.345 12.2419L11.7967 3.79026L10.6183 2.61193L2.16667 11.0636V12.2419H3.345ZM4.03583 13.9086H0.5V10.3728L10.0292 0.843598C10.1854 0.687372 10.3974 0.599609 10.6183 0.599609C10.8393 0.599609 11.0512 0.687372 11.2075 0.843598L13.565 3.2011C13.7212 3.35737 13.809 3.56929 13.809 3.79026C13.809 4.01123 13.7212 4.22316 13.565 4.37943L4.03583 13.9086ZM0.5 15.5753H15.5V17.2419H0.5V15.5753Z" fill="#2264D1"/>
</svg></button>
<button className='delete' onClick={()=>removeCompanies(data.id)}>
                      <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.83398 15.5C2.37565 15.5 1.98343 15.3369 1.65732 15.0108C1.33121 14.6847 1.16787 14.2922 1.16732 13.8333V3H0.333984V1.33333H4.50065V0.5H9.50065V1.33333H13.6673V3H12.834V13.8333C12.834 14.2917 12.6709 14.6842 12.3448 15.0108C12.0187 15.3375 11.6262 15.5006 11.1673 15.5H2.83398ZM11.1673 3H2.83398V13.8333H11.1673V3ZM4.50065 12.1667H6.16732V4.66667H4.50065V12.1667ZM7.83398 12.1667H9.50065V4.66667H7.83398V12.1667Z" fill="#2264D1"/>
</svg>

                      </button>
                    </td>
                                </tr>
                    })):(<tr><td colSpan="5" style={{textAlign:'center'}}>No data</td></tr>)
                } */}
        </tbody>
      </Table>
      {data.length >= 10 && (
        <div className='pagination'>
          {page + 1} - {Math.round(data.length / rowsPerPage)}
          <button className='prev' onClick={() => setPage(page - 1)} disabled={page == 0}>
            <svg
              width='8'
              height='13'
              viewBox='0 0 8 13'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M7.71094 2.12094L3.12094 6.71094L7.71094 11.3009L6.29094 12.7109L0.290937 6.71094L6.29094 0.710938L7.71094 2.12094Z'
                fill='#787885'
              />
            </svg>
          </button>
          <button
            className='next'
            onClick={() => setPage(page + 1)}
            disabled={Number(page) == Math.round(Math.abs(data.length / rowsPerPage - 1))}
          >
            <svg
              width='8'
              height='13'
              viewBox='0 0 8 13'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M0.295078 2.11312L4.87508 6.70312L0.295078 11.2931L1.70508 12.7031L7.70508 6.70312L1.70508 0.703125L0.295078 2.11312Z'
                fill='#787885'
              />
            </svg>
          </button>
        </div>
      )}

      {deleteCompanyConfirm && (
        <Modal
          title='Sei sicuro?'
          onConfirmReturnData={companyToDelete}
          onCancel={removeCompoanyCancel}
          onConfirm={removeCompoanyConfirm}
        >
          <p>Una volta eliminato non potrai tornare indietro</p>
        </Modal>
      )}
      {loader && <UseLoader />}
    </div>
  );
};

Companies.propTypes = {};

Companies.defaultProps = {};

export default Companies;
