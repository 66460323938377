import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useState } from "react";
import { Button, Col, Form, Image, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import UseLoader from "../../Components/Loader/Loader";
import UseAlert from "../../Components/UseAlert/UseAlert";
import Logo from "../../assets/images/logo_full.png";
import "./Forgot.scss";
const Forgot = () => {
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [variant, setVarient] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const functions = getFunctions();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
    },
    mode: "onSubmit",
  });

  const resetEmailId = () => {
    setValue("email", "");
  };
  const login = () => {
    navigate("/login");
  };
  const onSubmit = (data) => {
    try {
      setLoader(true);
      const resetPassword = httpsCallable(functions, "sendResetPasswordLink");
      resetPassword(data)
        .then((response) => {
          setLoader(false);
          setShow(true);
          setVarient("success");
          setAlertMsg(
            "Riceverai presto le istruzioni per reimpostare la password nell'e-mail fornita",
          );
          setTimeout(() => {
            navigate("/login");
          }, 2000);
          console.log("reset password response", response);
        })
        .catch((error) => {
          setLoader(false);
          setShow(true);
          setVarient("danger");
          setAlertMsg(error.message);
        });
    } catch (error) {
      console.error("error", error);
    }
  };
  return (
    <div className='Forgot' data-testid='Forgot'>
      <div className='innerLogin'>
        <Image src={Logo} alt='Rosso' className='logo' height={80} />
        <h3>Hai dimenticato la tua password?</h3>
        <div className='forgetCard'>
          <h4>Ti invieremo una mail per cambiare la tua password</h4>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <Form.Group className='position-relative'>
                  <Form.Label>Inserisci la tua email</Form.Label>
                  <Form.Control
                    type='text'
                    name='email'
                    {...register("email", {
                      required: true,
                      pattern: {
                        value:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "ID e-mail non valido",
                      },
                    })}
                  />
                  <span
                    className='closeEmail'
                    style={{
                      display: watch("email").length > 0 ? "inline-block" : "none",
                    }}
                    onClick={resetEmailId}
                  >
                    <svg
                      width='13'
                      height='12'
                      viewBox='0 0 13 12'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M7.91 6L12.2 1.71C12.39 1.53 12.5 1.28 12.5 1C12.5 0.45 12.05 0 11.5 0C11.22 0 10.97 0.11 10.79 0.29L6.5 4.59L2.21 0.29C2.03 0.11 1.78 0 1.5 0C0.95 0 0.5 0.45 0.5 1C0.5 1.28 0.61 1.53 0.79 1.71L5.09 6L0.8 10.29C0.61 10.47 0.5 10.72 0.5 11C0.5 11.55 0.95 12 1.5 12C1.78 12 2.03 11.89 2.21 11.71L6.5 7.41L10.79 11.7C10.97 11.89 11.22 12 11.5 12C12.05 12 12.5 11.55 12.5 11C12.5 10.72 12.39 10.47 12.21 10.29L7.91 6Z'
                        fill='#9696A0'
                      />
                    </svg>
                  </span>
                </Form.Group>
                {errors.email && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "14px",
                      position: "relative",
                      top: "5px",
                    }}
                  >
                    Inserisci un ID e-mail valido
                  </p>
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <Button variant='primary' type='submit'>
                  Invia il link
                </Button>
                <Button
                  type='button'
                  onClick={login}
                  variant='outline-primary'
                  className='oulineBnt'
                >
                  Torna indietro
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
      <UseAlert show={show} setShow={setShow} variant={variant} alertMsg={alertMsg} />
      {loader && <UseLoader />}
    </div>
  );
};

Forgot.propTypes = {};

Forgot.defaultProps = {};

export default Forgot;
