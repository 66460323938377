import { Loader } from "@googlemaps/js-api-loader";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Feedback from "react-bootstrap/esm/Feedback";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import UseLoader from "../../Components/Loader/Loader";
import UseTopBar from "../../Components/TopBar/TopBar";
import UseAlert from "../../Components/UseAlert/UseAlert";
import UseButton from "../../Components/UseButton/UseButton";
import UseImageUpload from "../../Components/UseImageUpload/UseImageUpload";
import LazyBreadCrum from "../BreadCrum/BreadCrum";
import "./AddConventions.scss";
const AddConventions = () => {
  const functions = getFunctions();
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [variant, setVarient] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const [loader, setLoader] = useState(false);
  const [logoError, setLogoError] = useState(false);

  const [image, setImage] = useState("");
  const [donationCenters, setDonationCenters] = useState([]);
  const [webinarCover, setWebinarCover] = useState("");
  const apiKey = "AIzaSyACvPjW5jrUbbCOqnC-y-RqIaR5s4E_suU";

  const _loader = new Loader({
    apiKey: apiKey,
    version: "weekly",
    libraries: ["places", "geometry"], // Include the geometry library here
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: {
      errors,
      isValid,
      isDirty,
      isSubmitting,
      isSubmitted,
      isSubmitSuccessful,
    },
  } = useForm({
    defaultValues: {
      agencyName: "",
      city: "",
      partnershipNotes: "",
      logo: "",
      contactPersonName: "",
      contactPersonEmail: "",
      contactPersonPhone: "",
      donationCenter: "",
    },
    mode: "onSubmit",
  });

  useEffect(() => {
    _loader.load().then(() => {
      if (document.getElementById("city")) {
        const autocomplete = new window.google.maps.places.Autocomplete(
          document.getElementById("city"),
          { types: ["(cities)"] }, // This restricts the search to cities only.
        );
        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          const cityComponent = place.address_components.find(
            (component) => component.types.includes("locality"), // 'locality' typically corresponds to the city part
          );
          const city = cityComponent ? cityComponent.long_name : "";
          setValue("city", city);
        });
      }
    });
  }, []);

  const loadData = useCallback(
    (data) => {
      setValue("id", data.id);
      setValue("agencyName", data.agencyName);
      setValue("city", data.city);
      setValue("partnerShipNotes", data.partnerShipNotes);
      setValue("logo", data.logo);
      setValue("contactPersonName", data.contactPersonName);
      setValue("contactPersonEmail", data.contactPersonEmail);
      setValue("contactPersonPhone", data.contactPersonPhone);
      setValue("donationCenter", data.donationCenter);
      setImage(data.logo);
    },
    [setValue],
  );

  const editPartnership = useCallback(() => {
    if (location?.state?.editPartnershipId) {
      const getPartnership = httpsCallable(functions, "getPartnershipById");
      getPartnership({ id: location?.state?.editPartnershipId })
        .then((response) => {
          loadData(response.data);
        })
        .catch((error) => console.error("error", error));
    }
  }, [functions, loadData, location?.state?.editPartnershipId]);

  useEffect(() => {
    editPartnership();
  }, [editPartnership]);

  const getDonationCenters = useCallback(() => {
    const getDonationCenters = httpsCallable(functions, "getDonationCenters");
    getDonationCenters()
      .then((response) => {
        const result = response.data.map(({ id, name }) => ({ id, name }));
        setDonationCenters(result);
      })
      .catch((error) => console.error("error", error));
  }, [functions]);

  useEffect(() => {
    getDonationCenters();
  }, [getDonationCenters]);

  const addConventions = (data) => {
    if (!data.logo) {
      setLogoError(true);
    }
    if (location?.state?.editPartnershipStatus !== true) {
      setLoader(true);
      const setPartner = httpsCallable(functions, "setPartnership");
      setPartner(data)
        .then((response) => {
          setShow(true);
          setVarient("success");
          setAlertMsg("Aggiunto con successo!");
          setLoader(false);
          navigate("/Convenzioni");
        })
        .catch((error) => console.error("error", error))
        .finally(() => setLoader(false));
    }

    if (location?.state?.editPartnershipStatus === true) {
      console.log("edit conventions", data);
      setLoader(true);
      const updatePartner = httpsCallable(functions, "updatePartnership");
      updatePartner(data)
        .then((response) => {
          setShow(true);
          setVarient("success");
          setLoader(false);
          setAlertMsg(response.data.message);
          setTimeout(() => {
            navigate("/Convenzioni");
          }, 2000);
        })
        .catch((error) => {
          console.error("error", error);
          setShow(true);
          setVarient("danger");
          setAlertMsg(error.data.message);
        })
        .finally(() => setLoader(false));
    }
  };

  const logoUpload = (e) => {
    setWebinarCover(e.target.files[0]);
    const file = e.target.files[0];
    if (file) {
      uploadFile(file);
    }
  };

  const uploadFile = async (file) => {
    try {
      setLoader(true);
      const generateUploadUrl = httpsCallable(functions, "uploadFiles");
      const data = {
        contentType: file.type,
        collection: "partnerships",
      };
      const {
        data: { url },
      } = await generateUploadUrl(data);
      const response = await fetch(url, {
        method: "POST",
        body: file,
      });

      if (!response.ok) {
        throw new Error("File upload failed");
      }
      const link = new URL(response?.url);
      const params = new URLSearchParams(link.search);
      const name = params.get("name");
      const fileName = name.split("/")[2];

      const storage = getStorage();

      const fileRef = ref(storage, `/uploads/partnerships/${fileName}`);

      const downloadUrl = await getDownloadURL(fileRef);
      setValue("logo", downloadUrl);
      setImage(downloadUrl);
    } catch (error) {
      console.error("error", error);
    } finally {
      setLoader(false);
    }
  };

  const removeLogo = () => {
    setValue("logo", "");
    setImage("");
    // window.location.reload();
  };

  return (
    <div className='AddConventions' data-testid='AddConventions'>
      <UseTopBar back='Convenzioni' />
      <LazyBreadCrum />
      <Form onSubmit={handleSubmit(addConventions)}>
        {location?.state?.agencyName && location?.state?.editPartnershipId && (
          <h2>{location?.state?.agencyName || "-"}</h2>
        )}
        {location.pathname.slice(1) == "Convenzioni/Aggiungi-convenzione" &&
          !location?.state?.editPartnershipId && <h2>Crea nuova convenzione</h2>}
        <input
          type='hidden'
          name='logo'
          {...register("logo", { required: "campo obbligatorio" })}
        />
        <Row>
          <Col xs={12} sm={6} md={6} lg={6}>
            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <Form.Label>Nome centro convenzione</Form.Label>
              <Form.Control
                type='text'
                name='agencyName'
                {...register("agencyName", { required: "campo obbligatorio" })}
                style={{ borderColor: errors?.agencyName?.message && "red" }}
              />
              {errors?.agencyName?.message && (
                <Feedback className='d-block' type='invalid'>
                  {errors?.agencyName?.message}
                </Feedback>
              )}
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}>
            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <Form.Label>Referente</Form.Label>
              <Form.Control
                type='text'
                name='contactPersonName'
                {...register("contactPersonName")}
                style={{ borderColor: errors?.contactPersonName?.message && "red" }}
              />
              {errors?.contactPersonName?.message && (
                <Feedback className='d-block' type='invalid'>
                  {errors?.contactPersonName?.message}
                </Feedback>
              )}
            </Form.Group>
          </Col>

          <Col xs={12} sm={6} md={6} lg={6} className='imageUpload'>
            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <Form.Label>Logo convenzione</Form.Label> <br></br>
              {/* <label  for="upload">11111111 <input type="file" accept='image/*' name="logo" onChange={logoUpload} {...register('logo')} id="upload"/></label>                 */}
              <UseImageUpload label='Carica logo' onChange={logoUpload} />
              {errors?.logo?.message && (
                <p className='mb-0 mx-2 text-danger fw-medium fs-6'>campo obbligatorio</p>
              )}
              {/* <UseButton name="adminBtnPrimary" variant="outline-primary" labels="Carica la copertina" />                                 */}
              {/* <input type='file' accept='image/*' onChange={logoUpload} />  */}
            </Form.Group>
            {image && (
              <div className='logoPreview'>
                <img src={image} alt='Image' />
                <span onClick={removeLogo}>x</span>
              </div>
            )}
            <Form.Text className='text-muted'>
              <svg
                width='21'
                height='20'
                viewBox='0 0 21 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M10.5 0C4.98 0 0.5 4.48 0.5 10C0.5 15.52 4.98 20 10.5 20C16.02 20 20.5 15.52 20.5 10C20.5 4.48 16.02 0 10.5 0ZM11.5 16H9.5V14H11.5V16ZM11.5 13H9.5V4H11.5V13Z'
                  fill='#D2D2D6'
                />
              </svg>
              400px - max 70mb - PNG, JPEG
            </Form.Text>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}>
            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <Form.Label>Email referente</Form.Label>
              <Form.Control
                type='email'
                name='contactPersonEmail'
                {...register("contactPersonEmail")}
                style={{ borderColor: errors?.contactPersonEmail?.message && "red" }}
              />
              {errors?.contactPersonEmail?.message && (
                <Feedback className='d-block' type='invalid'>
                  {errors?.contactPersonEmail?.message}
                </Feedback>
              )}
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}>
            <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
              <Form.Label>Note</Form.Label>
              <Form.Control
                as='textarea'
                rows={3}
                maxLength={200}
                name='partnerShipNotes'
                {...register("partnerShipNotes")}
              />
            </Form.Group>
            <p>{watch("partnerShipNotes")?.length}/200 caratteri</p>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}>
            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <Form.Label>Numero di telefono referente</Form.Label>
              <Form.Control
                type='tel'
                name='contactPersonPhone'
                {...register("contactPersonPhone")}
                style={{
                  borderColor: errors?.contactPersonPhone?.message && "red",
                }}
              />
              {errors?.contactPersonPhone?.message && (
                <Feedback className='d-block' type='invalid'>
                  {errors?.contactPersonPhone?.message}
                </Feedback>
              )}
            </Form.Group>
          </Col>
          {/* <Col xs={12} sm={6} md={6} lg={6}>
                 <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Name</Form.Label>
                      <Form.Control type="text" name="contactPersonName" {...register('contactPersonName')} />
                 </Form.Group>
        </Col>
        <Col xs={12} sm={6} md={6} lg={6}>
                 <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control type="text" name="contactPersonName" {...register('contactPersonEmail')} />
                 </Form.Group>
        </Col>
        <Col xs={12} sm={6} md={6} lg={6}>
                 <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Phone</Form.Label>
                      <Form.Control type="text" name="contactPersonPhone" {...register('contactPersonPhone')} />
                 </Form.Group>
        </Col> */}
          {/* <Col xs={12} sm={6} md={6} lg={6}>
                 <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Surname</Form.Label>
                      <Form.Control type="text" name="surName" onChange={(e)=>setSurName(e.target.value)} />
                 </Form.Group>
        </Col> */}
          {/* <Col xs={12} sm={6} md={6} lg={6}>
                 <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Notes</Form.Label>
                      <Form.Control type="text" name="note" onChange={(e)=>setNote(e.target.value)} />
                 </Form.Group>
        </Col> */}
        </Row>
        <Row>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className='mt-4 d-flex justify-content-end'
          >
            <UseButton
              type='submit'
              name='adminBtnPrimary'
              variant='primary'
              labels={
                location?.state?.editPartnershipStatus
                  ? "Salva modifiche"
                  : "Crea convenzione"
              }
            />
            {/* <Button variant="primary" type="submit">Salva modifiche</Button> */}
          </Col>
        </Row>
      </Form>
      {loader == true && <UseLoader />}
      <UseAlert show={show} setShow={setShow} variant={variant} alertMsg={alertMsg} />
    </div>
  );
};

AddConventions.propTypes = {};

AddConventions.defaultProps = {};

export default AddConventions;
