import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";

const AddressAutocomplete = ({
  apiKey,
  isGeolocationEnabled,
  onPlaceSelected,
  address,
  loader,
}) => {
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    if (loader) {
      loader.load().then(() => {
        if (inputRef.current) {
          const autocomplete = new window.google.maps.places.Autocomplete(
            inputRef.current,
          );
          autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();
            if (onPlaceSelected) {
              onPlaceSelected(place);
            }
            setInputValue(place.formatted_address || "");
          });
        }
      });
    }
  }, [apiKey, loader]);

  useEffect(() => {
    if (isGeolocationEnabled && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const geocoder = new window.google.maps.Geocoder();
          geocoder.geocode(
            {
              location: { lat: position.coords.latitude, lng: position.coords.longitude },
            },
            (results, status) => {
              if (status === "OK" && results[0]) {
                setInputValue(results[0].formatted_address);
                onPlaceSelected(results[0]);
              }
            },
          );
        },
        (error) => {
          console.error("Geolocation error:", error);
        },
        { enableHighAccuracy: true },
      );
    } else {
      setInputValue("");
    }
  }, [isGeolocationEnabled]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  useEffect(() => {
    if (address) {
      setInputValue(address);
    }
  }, [address]);

  return (
    <Form.Group className='mb-3'>
      <Form.Control
        ref={inputRef}
        type='text'
        placeholder='Cerca...'
        value={inputValue}
        onChange={handleInputChange}
      />
    </Form.Group>
  );
};

export default AddressAutocomplete;
