import { Loader } from "@googlemaps/js-api-loader";
import { format, subYears } from "date-fns";
import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Form, Row, Tab, Table, Tabs } from "react-bootstrap";
import Feedback from "react-bootstrap/esm/Feedback";
import DatePicker from "react-datepicker";
import { useLocation, useNavigate } from "react-router-dom";
import BookingStatus from "../../Components/BookingStatus/BookingStatus";
import UseLoader from "../../Components/Loader/Loader";
import UseTopBar from "../../Components/TopBar/TopBar";
import UseAlert from "../../Components/UseAlert/UseAlert";
import UseButton from "../../Components/UseButton/UseButton";
import { months, years } from "../../Components/Utils/Config";
import LazyBreadCrum from "../BreadCrum/BreadCrum";
import NewBooking from "./components/NewBooking";

import "./AddUser.scss";
const AddUser = () => {
  const [reservation, setReservation] = useState(false);
  const [addReservation, setAddReservation] = useState(false);
  const [editReservation, SetEditReservation] = useState(false);
  const [editPastReservation, SetEditPastReservation] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [eventType, setEventType] = useState("event");
  const [companyEvents, setCompanyEvents] = useState([]);
  const [currentReservationName, setCurrentReservationEventName] = useState(null);
  const apiKey = "AIzaSyACvPjW5jrUbbCOqnC-y-RqIaR5s4E_suU";

  const _loader = new Loader({
    apiKey: apiKey,
    version: "weekly",
    libraries: ["places", "geometry"], // Include the geometry library here
  });

  const functions = getFunctions();
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const bloodGroupLists = [
    { value: "Non lo so", label: "Non lo so" },
    { value: "A+", label: "A+" },
    { value: "A-", label: "A-" },
    { value: "B+", label: "B+" },
    { value: "B-", label: "B-" },
    { value: "O+", label: "O+" },
    { value: "O-", label: "O-" },
    { value: "AB+", label: "AB+" },
    { value: "AB-", label: "AB-" },
  ];
  //   information
  const [name, setName] = useState("");
  const [surName, setSurName] = useState("");
  const [number, setNumber] = useState("");
  const [birthPlace, setBirthPlace] = useState("");
  const [dateError, setDateError] = useState(false);
  const [dob, setDob] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [note, setNote] = useState("");
  const [reference, setReference] = useState("");
  const [contact, setContact] = useState("whatsapp");
  const [tax, setTax] = useState("");
  const [reCall, setReCall] = useState("");
  const [sex, setSex] = useState("");
  const [company, setCompany] = useState("");
  const [loader, setLoader] = useState(false);
  const [bloodGroup, setBloodGroup] = useState(bloodGroupLists[0].value);
  // Reservations
  const [eventName, setEventName] = useState("");
  const [place, setPlace] = useState("");
  const [contactName, setContactName] = useState("");
  const [timeEvent, setTimeEvent] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [featureSlotes, setFeatureSlots] = useState("");
  const [reseveNotes, setReserveNotes] = useState("");
  const [featureSlotDate, setFeatureSlotDate] = useState("");
  const [editDonationCenterId, setDonationCenterId] = useState("");
  const [past, setIsPast] = useState(false);
  // Motices
  const [remainderDay, setRemainderDay] = useState(true);
  const [remainderAfter3Months, setRemainderAfter3Months] = useState(false);
  const [remainderNextDay, setRamainderNextDay] = useState(false);
  const [birthday, setBirthday] = useState(false);
  const [linkNotice, setlinkNotice] = useState("");

  // Eligibility
  const [checkUp, setCheckup] = useState(false);
  const [bloodTest, setBloodTest] = useState(false);
  const [occupation, setOccupation] = useState("");
  const [doanted, setDonated] = useState("");
  const [donationCenterName, setDonationCenterName] = useState(null);
  const [whenDonate, setWhenDonate] = useState("");
  const [smoker, setSmoker] = useState(false);
  const [howManyCigrates, setHowManyCigrates] = useState("");
  const [foodIntolerance, setFoodIntolerance] = useState("");
  const [intolrance, setIntolrance] = useState("");
  const [supplemets, setSupplements] = useState("");
  const [whatMedicines, setWhatMedicines] = useState("");

  //Alert
  const [show, setShow] = useState(false);
  const [variant, setVarient] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const [validated, setValidated] = useState(false);
  const [userAlerts, setUserAlerts] = useState([]);

  //date
  const [startDate, setStartDate] = useState(null);
  const [currentInterval, setCurrentInterval] = useState(null);
  const [currentReservation, setCurrentReservation] = useState(null);
  const [currentSlot, setCurrentSlot] = useState(null);
  const [activeKey, setActiveKey] = useState("Informazioni");
  const [reservationss, setReservatons] = useState([]);
  const [pastReservation, setPastReservation] = useState([]);
  const [bookingStatusList, setBookingStatuseList] = useState([
    {
      value: "pending",
      label: "In corso",
    },
    {
      value: "completed",
      label: "Effettuata",
    },
    {
      value: "cancelled",
      label: "Annullata",
    },
    {
      value: "failed",
      label: "Fallita",
    },
    {
      value: "analyzed",
      label: "analizzato",
    },
  ]);

  const getCOmpanyEvents = () => {
    const getEvents = httpsCallable(functions, "getCompanyEvents");
    getEvents({})
      .then((response) => {
        setCompanyEvents(response.data);
      })
      .catch((error) => console.error("error", error));
  };
  const getUserAlerts = ({ company }) => {
    const getAlerts = httpsCallable(functions, "getUserAlerts");
    getAlerts({ company, userId: location?.state?.editUserId })
      .then((response) => {
        setUserAlerts(response.data);
      })
      .catch((error) => console.error("error", error));
  };

  const [getBookingStatus, setBookingStatuse] = useState("pending");
  const [open, setOpen] = useState();

  // add resevations
  const [donationCenter, setDonationCenter] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [eventTime, setEventTime] = useState("");
  const [eventNote, setEventNote] = useState("");
  const [donationCenterList, setDoantionCenteList] = useState([]);
  const [doantionCenterDateList, setDoantionCenterDateList] = useState([]);
  const [donationCenterFreeSlotes, setDonationCenterFreeSlots] = useState([]);
  const [selectedFeatureTimeSlotId, setSelectedFeatureTimeSlotId] = useState([]);
  const [selectedTimeSlotId, setSelectedTImeSlotId] = useState([]);
  const [editFeatureSlotDonationCenter, setEditFeatureSlotDoantionCenter] = useState([]);
  const [editFeatureTimeIntervalid, setEditFeatureTimeIntervalId] = useState([]);
  const crateReservation = () => {
    setReservation(true);
    SetEditReservation(false);
    SetEditPastReservation(false);
    setAddReservation(true);

    //  setActiveKey(key);
    //  setReservation(false)
    //  SetEditReservation(false)
    //  SetEditPastReservation(false)
    //  setAddReservation(false)
  };

  const createUsers = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (!dob.length) {
      setDateError(true);
    }
    if (form.checkValidity()) {
      const data = {
        name: name,
        surname: surName,
        email: email,
        phoneNumber: number,
        dob: dob,
        birthPlace: birthPlace,
        currentCity: city,
        taxCode: tax,
        company: reference,
        contact: contact,
        alerts: {
          dayAfter: false,
          dayBefore: false,
          birthday: false,
          recall: false,
        },
        sex: sex,
        eligibilityQuiz: {
          hasDonated: doanted,
          sinceWhenDonates: whenDonate,
          didBloodAnalysis: bloodTest,
          smoker: smoker,
          howMuchSmoke: howManyCigrates,
          foodIntolerance: foodIntolerance,
          whichIntolerances: intolrance,
          occupation: occupation,
          takesMedications: supplemets,
          whichMedications: whatMedicines,
        },
      };

      try {
        setLoader(true);
        const createUser = httpsCallable(functions, "createUser");
        createUser({ email, password: "123456789" })
          .then((response) => {
            if (response.data.uid) {
              const setDonar = httpsCallable(functions, "setDonor");
              setDonar({ ...data, userId: response.data.uid })
                .then((response) => {
                  setShow(true);
                  setVarient("success");
                  setAlertMsg("utente creato con successo");
                  navigate("/Utenti");
                })
                .catch((error) => {
                  console.error("error =====>", error);
                  setShow(true);
                  setVarient("danger");
                  setAlertMsg(error?.message || error);
                })
                .finally(() => setLoader(false));
            } else {
              navigate("/Utenti");
            }
          })
          .catch((error) => {
            console.error("error <=======", error);
            setShow(true);
            setVarient("danger");
            setAlertMsg(error?.message || error);
          });
      } catch (error) {
        console.error("error =======x", error);
      }
    } else {
      setValidated(true);
    }
  };

  useEffect(() => {
    _loader.load().then(() => {
      if (document.getElementById("city")) {
        const autocompleteCity = new window.google.maps.places.Autocomplete(
          document.getElementById("city"),
          { types: ["(cities)"] },
        );
        autocompleteCity.addListener("place_changed", () => {
          const place = autocompleteCity.getPlace();
          const cityComponent = place.address_components.find((component) =>
            component.types.includes("locality"),
          );
          if (cityComponent) {
            setCity(cityComponent.long_name);
          }
        });
      }

      if (document.getElementById("birthPlace")) {
        const autocompleteBirthPlace = new window.google.maps.places.Autocomplete(
          document.getElementById("birthPlace"),
          { types: ["(cities)"] },
        );
        autocompleteBirthPlace.addListener("place_changed", () => {
          const place = autocompleteBirthPlace.getPlace();
          const cityComponent = place.address_components.find((component) =>
            component.types.includes("locality"),
          );
          if (cityComponent) {
            setBirthPlace(cityComponent.long_name);
          }
        });
      }
    });
  }, []);

  const registerUser = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity()) {
      if (location?.state?.editUserId) {
        const data = {
          id: location?.state?.editUserId,
          name: name,
          surname: surName,
          email: email,
          phoneNumber: number,
          dob: dob,
          birthPlace: birthPlace,
          currentCity: city,
          bloodGroup: bloodGroup,
          taxCode: tax,
          company: reference,
          contact: contact,
          alerts: {
            dayAfter: remainderNextDay,
            dayBefore: remainderDay,
            birthday: birthday,
            recall: remainderAfter3Months,
          },
          sex: sex,
          recall: reCall,
          userId: location?.state?.editUserId,
          eligibilityQuiz: {
            hasDonated: doanted,
            sinceWhenDonates: whenDonate,
            didBloodAnalysis: bloodTest,
            smoker: smoker,
            howMuchSmoke: howManyCigrates,
            foodIntolerance: foodIntolerance,
            whichIntolerances: intolrance,
            occupation: occupation,
            takesMedications: supplemets,
            whichMedications: whatMedicines,
          },
        };

        setLoader(true);
        const updateDonar = httpsCallable(functions, "updateDonor");
        updateDonar(data)
          .then((response) => {
            console.log("edit donar response", response);
            // console.log('userId---->>>>>>',userId)
            setLoader(false);
            setShow(true);
            setVarient("success");
            setAlertMsg(response.data.message);
            setTimeout(() => {
              navigate("/Utenti");
            }, 2000);
          })
          .catch((error) => {
            console.error("error", error);
            setShow(true);
            setVarient("danger");
            setAlertMsg(error.data.message);
          })
          .finally(() => setLoader(false));
      }

      if (location?.state?.dashboardEditUserId) {
        const data = {
          id: location?.state?.dashboardEditUserId,
          name: name,
          surname: surName,
          email: email,
          phoneNumber: number,
          dob: dob,
          birthPlace: birthPlace,
          currentCity: city,
          taxCode: tax,
          company: reference,
          contact: contact,
          alerts: {
            dayAfter: remainderNextDay,
            dayBefore: remainderDay,
            birthday: birthday,
            recall: remainderAfter3Months,
          },
          sex: sex,
          recall: reCall,
          userId: location?.state?.editUserId,
          eligibilityQuiz: {
            hasDonated: doanted,
            sinceWhenDonates: whenDonate,
            didBloodAnalysis: bloodTest,
            smoker: smoker,
            howMuchSmoke: howManyCigrates,
            foodIntolerance: foodIntolerance,
            whichIntolerances: intolrance,
            occupation: occupation,
            takesMedications: supplemets,
            whichMedications: whatMedicines,
          },
        };

        setLoader(true);
        const updateDonar = httpsCallable(functions, "updateDonor");
        updateDonar(data)
          .then((response) => {
            // console.log('userId---->>>>>>',userId)
            setLoader(false);
            setShow(true);
            setVarient("success");
            setAlertMsg(response.data.message);
            setTimeout(() => {
              navigate("/Utenti");
            }, 2000);
          })
          .catch((error) => {
            console.error("error", error);
            setShow(true);
            setVarient("danger");
            setAlertMsg(error.data.message);
          })
          .finally(() => setLoader(false));
      }
    } else {
      setValidated(true);
    }
  };

  useEffect(() => {
    if (
      location?.state?.dashboardEditUserId &&
      location?.state?.dashboardUserEditStatus
    ) {
      dashboardEditUser();
    }
    if (location?.state?.editUserId && location?.state?.editUserStatus) {
      editUsers();
      reservations();
      pastReservations();
    }
    loadCompany();
    getDonationCenter();
    getCOmpanyEvents();
  }, []);

  useEffect(() => {
    getUserAlerts({ company });
  }, [company]);

  const dashboardEditUser = () => {
    try {
      const getDonorsById = httpsCallable(functions, "getDonorById");
      getDonorsById({ id: location?.state?.dashboardEditUserId })
        .then((response) => {
          // setData(response.data);
          loadUsers(response.data);
        })
        .catch((error) => console.error("error", error));
    } catch (error) {
      console.log("error", error);
    }
  };
  const editUsers = () => {
    try {
      const getDonorsById = httpsCallable(functions, "getDonorById");
      getDonorsById({ id: location?.state?.editUserId })
        .then((response) => {
          // setData(response.data);
          loadUsers(response.data);
        })
        .catch((error) => console.error("error", error));
    } catch (error) {
      console.log("error", error);
    }
  };

  const loadUsers = (data) => {
    setData(data);
    setName(data.name);
    setSurName(data.surname);
    setEmail(data.email);
    setNumber(data.phoneNumber);
    setBloodGroup(data.bloodGroup || bloodGroupLists[0].value);

    // console.log('edit DOB--->>>111',data?.dob);
    // console.log('edit DOB--->>>222',data?.dob?.toLocaleString());
    let date = data?.dob;
    // console.log('split date',date);
    const parts = date.split(/[-: ,]/);
    const year = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // Month is zero-based in JavaScript Date
    const day = parseInt(parts[2], 10);
    const dates = new Date(year, month, day);

    // console.log('load edit dates222',data?.dob)

    setDob(data?.dob);
    setStartDate(dates);

    setBirthPlace(data.birthPlace);
    setCity(data.currentCity);
    setTax(data.taxCode);
    // setNumber(data.phoneNumber)
    setCompany(data.company);
    setReference(data.company);
    setContact(data.contact);
    setSex(data.sex);
    setReCall(data.recall);
    setDonated(data.eligibilityQuiz.hasDonated);
    setWhenDonate(data.eligibilityQuiz.sinceWhenDonates);
    setBloodTest(data.eligibilityQuiz.didBloodAnalysis);
    setSmoker(data.eligibilityQuiz.smoker);
    setHowManyCigrates(data.eligibilityQuiz.howMuchSmoke);
    setFoodIntolerance(data.eligibilityQuiz.foodIntolerance);
    setIntolrance(data.eligibilityQuiz.whichIntolerances);
    setOccupation(data.eligibilityQuiz.occupation);
    setSupplements(data.eligibilityQuiz.takesMedications);
    setWhatMedicines(data.eligibilityQuiz.whichMedications);

    setRemainderDay(data.alerts.dayBefore);
    setRemainderAfter3Months(data.alerts.recall);
    setRamainderNextDay(data.alerts.dayAfter);
    setBirthday(data.alerts.birthday);

    // const [remainderDay, setRemainderDay] = useState(true)
    // const [remainderAfter3Months, setRemainderAfter3Months] = useState(false)
    // const [remainderNextDay, setRamainderNextDay] = useState(false)
    // const [birthday, setBirthday] = useState(false)

    // console.log('>>>>>>>> hasDonated',data.eligibilityQuiz.hasDonated)
  };

  const reservations = () => {
    try {
      const getDonorsById = httpsCallable(functions, "getUpcomingReservations");
      getDonorsById({ userId: location?.state?.userId })
        .then((response) => {
          setReservatons(response.data);
        })
        .catch((error) => console.error("error", error));
    } catch (error) {
      console.error("error", error);
    }
  };

  const pastReservations = () => {
    try {
      const getPastReservation = httpsCallable(functions, "getPastReservations");
      getPastReservation({ userId: location?.state?.userId })
        .then((response) => {
          setPastReservation(response.data);
        })
        .catch((error) => console.error("error", error));
    } catch (error) {
      console.error("error", error);
    }
  };

  const changeStatus = (e) => {
    setBookingStatuse(e.target.value);
    setOpen(false);
  };

  const getDate = (e) => {
    setDateError(false);
    setStartDate(e);
    const formattedDate = format(new Date(e), "yyyy-MM-dd");
    setDob(formattedDate);
  };

  const getYear = (dob) => {
    return dob.getFullYear();
  };

  const loadCompany = () => {
    try {
      const getCompaniesList = httpsCallable(functions, "getCompanies");
      getCompaniesList()
        .then((response) => {
          const company = response.data.map((item) => ({
            id: item.id,
            name: item.name,
          }));
          // console.log('company>>>>>',company)
          setCompanyList(company);
          setSelectedCompany(response.data[0]);
        })
        .catch((error) => console.error("error", error));
    } catch (error) {
      console.error("error", error);
    }
  };

  const handleActiveTabs = (key) => {
    setActiveKey(key);
    setReservation(false);
    SetEditReservation(false);
    SetEditPastReservation(false);
    setAddReservation(false);
  };

  const everDoanted = (e) => {
    setDonated(e.target.value === "true");
    if (e.target.value == "false") {
      setWhenDonate("");
    }
  };

  const smokerDetails = (e) => {
    setSmoker(e.target.value === "true");
    if (e.target.value == "false") {
      setHowManyCigrates("");
    }
  };

  const getFoodDetails = (e) => {
    setFoodIntolerance(e.target.value === "true");
    if (e.target.value == "false") {
      setIntolrance("");
    }
  };

  const getSupplimentsDetails = (e) => {
    setSupplements(e.target.value === "true");
    if (e.target.value == "false") {
      setWhatMedicines("");
    }
  };

  const getDonationCenter = (e) => {
    try {
      // const getLocations = httpsCallable(functions,"getLocations")
      const getLocations = httpsCallable(functions, "getDonationCenters");
      getLocations()
        .then((response) => {
          setDoantionCenteList(response.data);
        })
        .catch((error) => console.error("error", error));
    } catch (error) {
      console.log("error", error);
    }
  };

  const selectDonationCenter = (e) => {
    const id = e.target.value?.id || e.target.value;

    setDonationCenter(e.target.value);
    try {
      // const getLocations = httpsCallable(functions,"getLocations")
      const getslotsByCenter = httpsCallable(functions, "getSlotsByDonationCenter");
      getslotsByCenter({ donationCenterId: id })
        .then((response) => {
          setDoantionCenterDateList(response.data);
          getDoationEventDate({ target: { value: id } });
        })
        .catch((error) => console.error("error", error));
    } catch (error) {
      console.log("error", error);
    }
  };

  const getDoationEventDate = (e) => {
    const values = e.target.value;
    setEventDate(values);
    // const data = e.target.value;
    const result = doantionCenterDateList.filter((value) => {
      return value.id === e.target.value;
    });

    // console.log('result1',result)
    // console.log('result2',result[0].id,result[0].date)
    try {
      const getDoantionCenterTimeslotByDate = httpsCallable(
        functions,
        "getDonationCenterTimeslotByDate",
      );
      getDoantionCenterTimeslotByDate({
        donationCenterId: donationCenter,
        timeslotId: result[0].id,
        date: result[0].date,
      })
        .then((response) => {
          // console.log("available free slots1", response);
          // console.log("available free slots2", response.data[0]?.timeIntervals);
          setDonationCenterFreeSlots(response.data);
        })
        .catch((error) => console.error("error", error));
    } catch (error) {
      console.log("error", error);
    }
    setSelectedTImeSlotId(result);
  };
  // console.log('donationCenterFreeSlotes >> 1',donationCenterFreeSlotes[0]?.timeIntervals.id)
  // console.log('donationCenterFreeSlotes >> 2',donationCenterFreeSlotes[0]?.timeIntervals.slot)
  const addUserReservationSlot = (e) => {
    e.preventDefault();
    try {
      setLoader(true);
      const setFeatureReservation = httpsCallable(functions, "setReservation");
      setFeatureReservation({
        donationCenterId: donationCenter,
        date: selectedTimeSlotId[0].date,
        userId: location?.state?.userId,
        timeslotId: selectedTimeSlotId[0].id,
        intervalId: eventTime,
      })
        .then((response) => {
          // setDonationCenterFreeSlots(response.data);
          setLoader(false);
          setShow(true);
          setVarient("success");
          setAlertMsg(response.data.message);
          setTimeout(() => {
            setAddReservation(false);
            setReservation(false);
            SetEditReservation(false);
            reservations();
          }, 2000);
        })
        .catch((error) => {
          setShow(true);
          setVarient("danger");
          setLoader(false);
          setAlertMsg(error.message);
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  const editFeatureEvents = (id, when) => {
    SetEditReservation(true);
    setReservation(true);
    SetEditPastReservation(false);
    setIsPast(when == "past");
    try {
      const getFeatureReservationById = httpsCallable(functions, "getReservationById");
      getFeatureReservationById({ id: id })
        .then((response) => {
          // setSelectedFeatureTimeSlotId(response.data);
          // setEventName(response.data.eventName)
          // setBookingStatuseList(response.data.status)
          // setBookingStatuse(response.data.status)
          // setPlace(response.data.donationCenterAddress)
          // setContactName(response.data.contactPersonName)
          // setContactNumber(response.data.contactPersonPhone)
          // setTimeEvent(response.data.date)
          // setFeatureSlotDate(response.data.date)
          // setFeatureSlots(response.data.slot)
          // console.log('setTimeEvent >>>>',response.data.date)
          setCurrentInterval(response.data.intervalId);
          setFeatureSlots(response.data.intervalId);
          setCurrentSlot(response.data.slot);
          setCurrentReservation(response.data.id);
          loadEditFeatureEventSlots(response.data);
          setCurrentReservationEventName(response.data.eventName);
          setDonationCenterName(response.data.donationCenterName);
        })
        .catch((error) => console.error("error", error));
    } catch (error) {
      console.log("error", error);
    }
  };

  const loadEditFeatureEventSlots = (data) => {
    setEventName(data.eventName);
    setBookingStatuse(data.status);
    setPlace(data.donationCenterAddress);
    setContactName(data.contactPersonName);
    setContactNumber(data.contactPersonPhone);
    setTimeEvent(data.date);
    setFeatureSlotDate(data.date);
    setFeatureSlots(data.slot);
    setDonationCenterId(data.donationCenterId);
    try {
      // const getLocations = httpsCallable(functions,"getLocations")
      const getslotsByCenter = httpsCallable(functions, "getSlotsByDonationCenter");
      getslotsByCenter({ donationCenterId: data.donationCenterId })
        .then((response) => {
          const result = response.data.filter((d) => d.date === data.date);

          setEditFeatureTimeIntervalId(result);

          // setDoantionCenterDateList(response.data);
        })
        .catch((error) => console.error("error", error));
    } catch (error) {
      console.log("error", error);
    }

    try {
      const getDonationCenterByIds = httpsCallable(functions, "getDonationCenterById");
      getDonationCenterByIds({ id: data.donationCenterId })
        .then((response) => {
          const result = response.data.data.find((c) => c.date == data.date);

          const finalResult = result.timeIntervals.filter(
            (interval) =>
              interval.bookedUsers.length < result.personPerSlot ||
              interval.id == data.intervalId,
          );
          setEditFeatureSlotDoantionCenter(finalResult);
        })
        .catch((error) => console.error("error", error));
    } catch (error) {
      console.error("error", error);
    }
  };
  const getFeatureSlots = (e) => {
    setCurrentInterval(e.target.value);

    const finalSlots = editFeatureSlotDonationCenter.filter(
      (c) => c.id === e.target.value,
    );

    setCurrentSlot(finalSlots[0]?.slot);
    setSelectedFeatureTimeSlotId(finalSlots);
  };

  const getResevations = (e) => {
    e.preventDefault();
    // console.log('Event Name : ',eventName,'Booking Status : ',bookingStatus,'Place : ',place,'Contact Name and Surname : ',contactName,'Evetn : ',timeEvent,'Telephone numbr : ',contactNumber,'Note >>',reseveNotes)
    // console.log('<< donation center id >>',editDonationCenterId,'Date : ',timeEvent, 'user id', location?.state?.userId , 'timeslotId : ',editFeatureTimeIntervalid[0].id,'interval id',selectedFeatureTimeSlotId[0].id)
    // console.log('editFeatureTimeIntervalid 000',editFeatureTimeIntervalid)
    if (editPastReservation == false) {
      try {
        setLoader(true);
        const featureSetServations = httpsCallable(functions, "updateReservation");
        featureSetServations({
          slot: currentSlot,
          id: currentReservation,
          intervalId: currentInterval,
          status: getBookingStatus,
          notes: reseveNotes,
        })
          .then((response) => {
            setLoader(false);
            setShow(true);
            setVarient("success");
            setAlertMsg(response.data.message);
            setTimeout(() => {
              setReservation(false);
              SetEditReservation(false);
              SetEditPastReservation(false);
            }, 2000);
          })
          .catch((error) => {
            setLoader(false);
            setShow(true);
            setVarient("danger");
            setAlertMsg(error.message);
          });
      } catch (error) {
        console.error("error", error);
      }
    }
    if (editPastReservation == true) {
      try {
        setLoader(true);
        const featureSetServations = httpsCallable(functions, "updateReservation");
        featureSetServations({
          slot: currentSlot,
          id: currentReservation,
          intervalId: currentInterval,
          status: getBookingStatus,
        })
          .then((response) => {
            setLoader(false);
            setShow(true);
            setVarient("success");
            setLoader(false);
            setAlertMsg(response.data.message);
            setTimeout(() => {
              setReservation(false);
              SetEditReservation(false);
              SetEditPastReservation(false);
            }, 2000);
          })
          .catch((error) => {
            setLoader(false);
            setShow(true);
            setVarient("danger");
            setLoader(false);
            setAlertMsg(error.message);
          });
      } catch (error) {
        console.error("error", error);
      }
    }
  };

  const handleAlertToggle = ({ enabled, alertId }) => {
    setLoader(true);
    const toggleAlert = httpsCallable(functions, "toggleAlert");
    toggleAlert({
      enabled: enabled,
      alertId: alertId,
      userId: location?.state?.editUserId,
    })
      .then(async (response) => {
        await getUserAlerts({ company });
        setLoader(false);
      })
      .catch((error) => {
        console.error("error", error);
        setLoader(false);
      });
  };

  const nextReminder = useMemo(() => {
    if (!userAlerts) return null;
    if (userAlerts.length === 0) return null;
    const nextReminder = userAlerts.reduce((acc, item) => {
      if (item.nextReminder) {
        if (!acc) {
          return item.nextReminder;
        }
        return Math.min(acc, item.nextReminder);
      }
      return acc.nextReminder;
    });
    return nextReminder || 0;
  }, [userAlerts]);

  return (
    <div className='AddUser' data-testid='AddUser'>
      <UseTopBar back='/Utenti' data={{ donationCenterId: donationCenter?.id }} />
      <LazyBreadCrum />
      {location.pathname.slice(1) === "Utenti/Aggiungi-Utenti" && (
        <h2>Crea nuovo utente</h2>
      )}
      {location.pathname.slice(1) === "Utenti/Modificare-Utenti" &&
        location?.state?.editUserId && (
          <h2>
            {location?.state?.userName} {location?.state?.surName}
          </h2>
        )}
      {location.pathname.slice(1) === "Utenti/Modificare-Utenti" &&
        location?.state?.dashboardEditUserId && (
          <h2>
            {location?.state?.dashboardEditUserName}{" "}
            {location?.state?.dashboardUserSurName || "-"}
          </h2>
        )}
      <section className='registerUser'>
        <div className='customAdminTabs'>
          <Tabs
            activeKey={activeKey}
            onSelect={(key) => handleActiveTabs(key)}
            id='uncontrolled-tab-example'
            className='mb-3'
          >
            <Tab eventKey='Informazioni' title='Informazioni'>
              {/* <Form onSubmit={registerUser}> */}
              {location.pathname.slice(1) === "Utenti/Aggiungi-Utenti" && (
                <Form noValidate validated={validated} onSubmit={createUsers}>
                  <Row>
                    <Col xs={12} sm={6} md={6} lg={6}>
                      <Form.Group className='mb-3' controlId='formBasicEmail'>
                        <Form.Label>Nome</Form.Label>
                        <Form.Control
                          required
                          type='text'
                          name='name'
                          onChange={(e) => setName(e.target.value)}
                          value={name}
                        />
                        <Form.Control.Feedback type='invalid'>
                          campo obbligatorio
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6}>
                      <Form.Group className='mb-3' controlId='formBasicEmail'>
                        <Form.Label>Numero di telefonoe</Form.Label>
                        <Form.Control
                          required
                          type='telephone'
                          name='number'
                          onChange={(e) => setNumber(e.target.value)}
                          value={number}
                        />
                        <Form.Control.Feedback type='invalid'>
                          campo obbligatorio
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6}>
                      <Form.Group className='mb-3' controlId='formBasicEmail'>
                        <Form.Label>Cognome</Form.Label>
                        <Form.Control
                          required
                          type='text'
                          name='surName'
                          onChange={(e) => setSurName(e.target.value)}
                          value={surName}
                        />
                        <Form.Control.Feedback type='invalid'>
                          campo obbligatorio
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6}>
                      <Row>
                        <Col xs={12} sm={6} md={6} lg={6}>
                          <Form.Group className='mb-3' controlId='formBasicEmail'>
                            <Form.Label>Luogo di nascita</Form.Label>
                            <Form.Control
                              required
                              type='text'
                              name='birthPlace'
                              placeholder='Inserisci luogo di nascita'
                              id='birthPlace'
                              onChange={(e) => setBirthPlace(e.target.value)}
                              value={birthPlace}
                            />
                            <Form.Control.Feedback type='invalid'>
                              campo obbligatorio
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col
                          xs={12}
                          sm={6}
                          md={6}
                          lg={6}
                          className='reactCustomDatePicker'
                        >
                          <Form.Group className='mb-3' controlId='formBasicEmail'>
                            <Form.Label>Data di nascita</Form.Label>
                            <DatePicker
                              required
                              placeholderText='seleziona una data'
                              maxDate={subYears(new Date(), 18)}
                              renderCustomHeader={({
                                date,
                                changeYear,
                                changeMonth,
                                decreaseMonth,
                                increaseMonth,
                                prevMonthButtonDisabled,
                                nextMonthButtonDisabled,
                              }) => (
                                <div
                                  style={{
                                    margin: 10,
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <button
                                    onClick={decreaseMonth}
                                    disabled={prevMonthButtonDisabled}
                                  >
                                    {"<"}
                                  </button>
                                  <select
                                    value={getYear(date)}
                                    onChange={({ target: { value } }) =>
                                      changeYear(value)
                                    }
                                  >
                                    {years.map((option) => (
                                      <option key={option} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </select>

                                  <select
                                    value={date.getMonth()} // Use getMonth() directly for the value
                                    onChange={(e) => changeMonth(Number(e.target.value))} // Convert value to number
                                  >
                                    {months.map((month, index) => (
                                      <option key={month} value={index}>
                                        {month}
                                      </option>
                                    ))}
                                  </select>

                                  <button
                                    onClick={increaseMonth}
                                    disabled={nextMonthButtonDisabled}
                                  >
                                    {">"}
                                  </button>
                                </div>
                              )}
                              selected={startDate}
                              onChange={getDate}
                              className={dateError ? "date-error" : ""}
                            />
                            {dateError && (
                              <Feedback type='invalid' style={{ display: "block" }}>
                                campo obbligatorio
                              </Feedback>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>

                    <Col xs={12} sm={6} md={6} lg={6}>
                      <Form.Group className='mb-3' controlId='formBasicEmail'>
                        <Form.Label>Email </Form.Label>
                        <Form.Control
                          required
                          type='text'
                          name='email'
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                        />
                        <Form.Control.Feedback type='invalid'>
                          campo obbligatorio
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6}>
                      <Form.Group className='mb-3' controlId='formBasicEmail'>
                        <Form.Label>Città attuale</Form.Label>
                        <Form.Control
                          required
                          type='text'
                          name='city'
                          id='city'
                          onChange={(e) => setCity(e.target.value)}
                          value={city}
                        />
                        <Form.Control.Feedback type='invalid'>
                          campo obbligatorio
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={6} md={6} lg={6}>
                      <Form.Group
                        className='mb-3'
                        controlId='exampleForm.ControlTextarea1'
                      >
                        <Form.Label>Note</Form.Label>
                        <Form.Control
                          as='textarea'
                          rows={3}
                          name='note'
                          onChange={(e) => setNote(e.target.value)}
                          value={note}
                        />
                      </Form.Group>
                      <p className='d-flex justify-content-end'>
                        {note.length}/200 caratteri
                      </p>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6}>
                      <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                          <Form.Group className='mb-3' controlId='formBasicEmail'>
                            <Form.Label>Azienda di riferimento</Form.Label>
                            <Form.Select
                              aria-label='Default select example'
                              name='reference'
                              onChange={(e) => setReference(e.target.value)}
                              value={reference}
                            >
                              <option value=''>Selezionare</option>
                              {companyList?.map((item, index) => {
                                return (
                                  <option key={index} value={item.id}>
                                    {item.name}
                                  </option>
                                );
                              })}
                            </Form.Select>
                            {/* <Form.Control.Feedback type='invalid'>
                              campo obbligatorio
                            </Form.Control.Feedback> */}
                          </Form.Group>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12}>
                          <Form.Group className='mb-3' controlId='formBasicEmail'>
                            <Form.Label>Piattaforma di contatto</Form.Label>
                            <Form.Select
                              required
                              aria-label='Default select example'
                              name='contact'
                              value={contact}
                              onChange={(e) => setContact(e.target.value)}
                            >
                              {/* <option>Open this select menu</option> */}
                              <option value='email'>Email</option>
                              <option value='whatsapp'>Whatsapp</option>
                            </Form.Select>
                            <Form.Control.Feedback type='invalid'>
                              campo obbligatorio
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={6} md={6} lg={6}>
                      <Form.Group className='mb-3' controlId='formBasicEmail'>
                        <Form.Label>Codice fiscale</Form.Label>
                        <Form.Control
                          required
                          type='text'
                          name='tax'
                          value={tax}
                          onChange={(e) => setTax(e.target.value)}
                        />
                        <Form.Control.Feedback type='invalid'>
                          campo obbligatorio
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6}>
                      <Form.Group className='mb-3' controlId='formBasicEmail'>
                        <Form.Label>Gruppo Sanguigno</Form.Label>
                        <Form.Select
                          required
                          aria-label='Default select example'
                          name='bloodGroup'
                          value={bloodGroup}
                          onChange={(e) => setBloodGroup(e.target.value)}
                        >
                          {bloodGroupLists?.map((item, index) => {
                            return (
                              <option key={index} value={item.value}>
                                {item.label}
                              </option>
                            );
                          })}
                        </Form.Select>
                        <Form.Control.Feedback type='invalid'>
                          campo obbligatorio
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6}>
                      <Form.Group className='mb-3' controlId='formBasicEmail'>
                        <Form.Label>Sesso Biologico </Form.Label>
                        <Form.Select
                          required
                          aria-label='Default select example'
                          name='sex'
                          value={sex}
                          onChange={(e) => setSex(e.target.value)}
                        >
                          <option value='' disabled>
                            Seleziona tipologia
                          </option>
                          <option value='maschio'>Maschio</option>
                          <option value='femmina'>Femmina</option>
                        </Form.Select>
                        <Form.Control.Feedback type='invalid'>
                          campo obbligatorio
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      className='d-flex mt-4 justify-content-end'
                    >
                      <Form.Label>&nbsp;</Form.Label>
                      <UseButton
                        type='submit'
                        name='adminBtnPrimary'
                        variant='primary'
                        labels='Crea utente'
                      />
                    </Col>
                  </Row>
                </Form>
              )}
              {location.pathname.slice(1) === "Utenti/Modificare-Utenti" && (
                <Form noValidate validated={validated} onSubmit={registerUser}>
                  <Row>
                    <Col xs={12} sm={6} md={6} lg={6}>
                      <Form.Group className='mb-3' controlId='formBasicEmail'>
                        <Form.Label>Nome</Form.Label>
                        <Form.Control
                          required
                          type='text'
                          name='name'
                          onChange={(e) => setName(e.target.value)}
                          value={name}
                        />
                        <Form.Control.Feedback type='invalid'>
                          campo obbligatorio
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6}>
                      <Form.Group className='mb-3' controlId='formBasicEmail'>
                        <Form.Label>Numero di telefonoe</Form.Label>
                        <Form.Control
                          required
                          type='telephone'
                          name='number'
                          onChange={(e) => setNumber(e.target.value)}
                          value={number}
                        />
                        <Form.Control.Feedback type='invalid'>
                          campo obbligatorio
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6}>
                      <Form.Group className='mb-3' controlId='formBasicEmail'>
                        <Form.Label>Cognome</Form.Label>
                        <Form.Control
                          required
                          type='text'
                          name='surName'
                          onChange={(e) => setSurName(e.target.value)}
                          value={surName}
                        />
                        <Form.Control.Feedback type='invalid'>
                          campo obbligatorio
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6}>
                      <Row>
                        <Col xs={12} sm={6} md={6} lg={6}>
                          <Form.Group className='mb-3' controlId='formBasicEmail'>
                            <Form.Label>Luogo di nascita</Form.Label>
                            <Form.Control
                              required
                              placeholder='inserisci luogo di nascita'
                              type='text'
                              name='birthPlace'
                              id='birthPlace'
                              onChange={(e) => setBirthPlace(e.target.value)}
                              value={birthPlace}
                            />
                            <Form.Control.Feedback type='invalid'>
                              campo obbligatorio
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={6}>
                          <Form.Group className='mb-3' controlId='formBasicEmail'>
                            <Form.Label>Data di nascita</Form.Label>
                            <DatePicker
                              required
                              placeholderText='seleziona una data'
                              maxDate={subYears(new Date(), 18)}
                              renderCustomHeader={({
                                date,
                                changeYear,
                                changeMonth,
                                decreaseMonth,
                                increaseMonth,
                                prevMonthButtonDisabled,
                                nextMonthButtonDisabled,
                              }) => (
                                <div
                                  style={{
                                    margin: 10,
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <button
                                    onClick={decreaseMonth}
                                    disabled={prevMonthButtonDisabled}
                                  >
                                    {"<"}
                                  </button>
                                  <select
                                    value={getYear(date)}
                                    onChange={({ target: { value } }) =>
                                      changeYear(value)
                                    }
                                  >
                                    {years.map((option) => (
                                      <option key={option} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </select>

                                  <select
                                    value={date.getMonth()} // Use getMonth() directly for the value
                                    onChange={(e) => changeMonth(Number(e.target.value))} // Convert value to number
                                  >
                                    {months.map((month, index) => (
                                      <option key={month} value={index}>
                                        {month}
                                      </option>
                                    ))}
                                  </select>

                                  <button
                                    onClick={increaseMonth}
                                    disabled={nextMonthButtonDisabled}
                                  >
                                    {">"}
                                  </button>
                                </div>
                              )}
                              selected={startDate}
                              onChange={getDate}
                              className={dateError ? "date-error" : ""}
                            />
                            {dateError && (
                              <Feedback type='invalid' style={{ display: "block" }}>
                                campo obbligatorio
                              </Feedback>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>

                    <Col xs={12} sm={6} md={6} lg={6}>
                      <Form.Group className='mb-3' controlId='formBasicEmail'>
                        <Form.Label>Email </Form.Label>
                        <Form.Control
                          required
                          type='text'
                          name='email'
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                        />
                        <Form.Control.Feedback type='invalid'>
                          campo obbligatorio
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6}>
                      <Form.Group className='mb-3' controlId='formBasicEmail'>
                        <Form.Label>Città attuale</Form.Label>
                        <Form.Control
                          required
                          type='text'
                          name='city'
                          id='city'
                          onChange={(e) => setCity(e.target.value)}
                          value={city}
                        />
                        <Form.Control.Feedback type='invalid'>
                          campo obbligatorio
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={6} md={6} lg={6}>
                      <Form.Group
                        className='mb-3'
                        controlId='exampleForm.ControlTextarea1'
                      >
                        <Form.Label>Note</Form.Label>
                        <Form.Control
                          as='textarea'
                          rows={3}
                          name='note'
                          onChange={(e) => setNote(e.target.value)}
                          value={note}
                        />
                      </Form.Group>
                      <p className='d-flex justify-content-end'>
                        {note.length}/200 caratteri
                      </p>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6}>
                      <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                          <Form.Group className='mb-3' controlId='formBasicEmail'>
                            <Form.Label>Azienda di riferimento</Form.Label>
                            <Form.Select
                              aria-label='Default select example'
                              name='reference'
                              onChange={(e) => setReference(e.target.value)}
                              value={reference}
                            >
                              <option value=''>Selezionare</option>
                              {companyList?.map((item, index) => {
                                return (
                                  <option key={index} value={item.id}>
                                    {item.name}
                                  </option>
                                );
                              })}
                            </Form.Select>
                            {/* <Form.Control.Feedback type='invalid'>
                              campo obbligatorio
                            </Form.Control.Feedback> */}
                          </Form.Group>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12}>
                          <Form.Group className='mb-3' controlId='formBasicEmail'>
                            <Form.Label>Piattaforma di contatto</Form.Label>
                            <Form.Select
                              required
                              aria-label='Default select example'
                              name='contact'
                              value={contact}
                              onChange={(e) => setContact(e.target.value)}
                            >
                              {/* <option>Open this select menu</option> */}
                              <option value='email'>Email</option>
                              <option value='whatsapp'>Whatsapp</option>
                            </Form.Select>
                            <Form.Control.Feedback type='invalid'>
                              campo obbligatorio
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={6} md={6} lg={6}>
                      <Form.Group className='mb-3' controlId='formBasicEmail'>
                        <Form.Label>Codice fiscale</Form.Label>
                        <Form.Control
                          required
                          type='text'
                          name='tax'
                          value={tax}
                          onChange={(e) => setTax(e.target.value)}
                        />
                        <Form.Control.Feedback type='invalid'>
                          campo obbligatorio
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6}>
                      <Form.Group className='mb-3' controlId='formBasicEmail'>
                        <Form.Label>Gruppo Sanguigno</Form.Label>
                        <Form.Select
                          required
                          aria-label='Default select example'
                          name='bloodGroup'
                          value={bloodGroup}
                          onChange={(e) => setBloodGroup(e.target.value)}
                        >
                          {bloodGroupLists?.map((item, index) => {
                            return (
                              <option key={index} value={item.value}>
                                {item.label}
                              </option>
                            );
                          })}
                        </Form.Select>
                        <Form.Control.Feedback type='invalid'>
                          campo obbligatorio
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6}>
                      <Form.Group className='mb-3' controlId='formBasicEmail'>
                        <Form.Label>Sesso Biologico </Form.Label>
                        <Form.Select
                          required
                          aria-label='Default select example'
                          name='sex'
                          value={sex}
                          onChange={(e) => setSex(e.target.value)}
                        >
                          <option value='' disabled>
                            Seleziona tipologia
                          </option>
                          <option value='maschio'>Maschio</option>
                          <option value='femmina'>Femmina</option>
                        </Form.Select>
                        <Form.Control.Feedback type='invalid'>
                          campo obbligatorio
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      className='d-flex mt-4 justify-content-end'
                    >
                      <Form.Label>&nbsp;</Form.Label>
                      <UseButton
                        type='submit'
                        name='adminBtnPrimary'
                        variant='primary'
                        labels='Salva modifiche'
                      />
                    </Col>
                  </Row>
                </Form>
              )}

              {/* end of tab1 */}
            </Tab>
            {location.pathname.slice(1) === "Utenti/Modificare-Utenti" && (
              <Tab eventKey='Prenotazioni' title='Prenotazioni'>
                {reservation === false && (
                  <section className='reservation'>
                    <UseButton
                      onClick={crateReservation}
                      name='adminBtnPrimary personalizationBtn'
                      variant='primary'
                      labels='Crea prenotazione'
                    />
                    <Row>
                      <Col xs={12}>
                        <h4>Prenotazioni future</h4>
                        <Table hover>
                          <thead>
                            <tr>
                              <th>Nome evento</th>
                              <th>Centro donazione</th>
                              <th>Tipo</th>
                              <th>Indirizzo</th>
                              <th>Data evento</th>
                              <th>&nbsp;</th>
                            </tr>
                          </thead>
                          <tbody>
                            {reservationss.length > 0 ? (
                              reservationss?.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{item.eventName}</td>
                                    <td>{item.donationCenterName}</td>
                                    <td>{item.eventName ? "EVENTO" : "CENTRO"}</td>
                                    <td>{item.donationCenterAddress}</td>
                                    <td>{item.date}</td>
                                    <td width='20'>
                                      <svg
                                        onClick={() => editFeatureEvents(item.id)}
                                        style={{ cursor: "pointer" }}
                                        width='16'
                                        height='18'
                                        viewBox='0 0 16 18'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'
                                      >
                                        <path
                                          d='M3.345 12.2419L11.7967 3.79026L10.6183 2.61193L2.16667 11.0636V12.2419H3.345ZM4.03583 13.9086H0.5V10.3728L10.0292 0.843598C10.1854 0.687372 10.3974 0.599609 10.6183 0.599609C10.8393 0.599609 11.0512 0.687372 11.2075 0.843598L13.565 3.2011C13.7212 3.35737 13.809 3.56929 13.809 3.79026C13.809 4.01123 13.7212 4.22316 13.565 4.37943L4.03583 13.9086ZM0.5 15.5753H15.5V17.2419H0.5V15.5753Z'
                                          fill='#2264D1'
                                        />
                                      </svg>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan='6'>No data</td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </Col>
                      <Col xs={12}>
                        <h4>Prenotazioni passate</h4>
                        <Table hover>
                          <thead>
                            <tr>
                              <th>Nome evento</th>
                              <th>Centro donazione</th>
                              <th>Tipo</th>
                              <th>Indirizzo</th>
                              <th>Data evento</th>
                              <th>&nbsp;</th>
                            </tr>
                          </thead>
                          <tbody>
                            {pastReservation.length > 0 ? (
                              pastReservation?.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{item.eventName}</td>
                                    <td>{item.donationCenterName}</td>
                                    <td>{item.eventName ? "EVENTO" : "CENTRO"}</td>
                                    <td>{item.donationCenterAddress}</td>
                                    <td>{item.date}</td>
                                    <td>
                                      <svg
                                        onClick={() => editFeatureEvents(item.id, "past")}
                                        style={{ cursor: "pointer" }}
                                        width='16'
                                        height='18'
                                        viewBox='0 0 16 18'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'
                                      >
                                        <path
                                          d='M3.345 12.2419L11.7967 3.79026L10.6183 2.61193L2.16667 11.0636V12.2419H3.345ZM4.03583 13.9086H0.5V10.3728L10.0292 0.843598C10.1854 0.687372 10.3974 0.599609 10.6183 0.599609C10.8393 0.599609 11.0512 0.687372 11.2075 0.843598L13.565 3.2011C13.7212 3.35737 13.809 3.56929 13.809 3.79026C13.809 4.01123 13.7212 4.22316 13.565 4.37943L4.03583 13.9086ZM0.5 15.5753H15.5V17.2419H0.5V15.5753Z'
                                          fill='#2264D1'
                                        />
                                      </svg>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan='6'>No data</td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </section>
                )}
                {editReservation === true && (
                  <section className='createReservation'>
                    <div className='eventTypeWrap'>
                      <Form.Check
                        value='event'
                        type='radio'
                        aria-label='radio 1'
                        disabled={true}
                        label='Evento'
                        onChange={(e) => {
                          //setBookingType(e.target.value)
                        }}
                        checked={currentReservationName}
                      />
                      <Form.Check
                        value='center'
                        disabled={true}
                        type='radio'
                        aria-label='radio 2'
                        label='Centro di donazione'
                        onChange={(e) => {
                          // setBookingType(e.target.value)
                        }}
                        checked={!currentReservationName}
                      />
                    </div>
                    <form onSubmit={getResevations}>
                      <Row>
                        {currentReservationName && (
                          <Col xs={12} sm={6} md={6} lg={6}>
                            <Form.Group className='mb-3' controlId='formBasicEmail'>
                              <Form.Label>Azienda</Form.Label>
                              <Form.Control
                                type='text'
                                disabled
                                value={selectedCompany.name}
                                name='place'
                              />
                            </Form.Group>
                          </Col>
                        )}
                        {!currentReservationName && (
                          <Col xs={12} sm={6} md={6} lg={6}></Col>
                        )}
                        <Col xs={12} sm={6} md={6} lg={6}>
                          <div className='bookingState'>
                            <Form.Group className='mb-3' controlId='formBasicEmail'>
                              <Form.Label>Stato prenotazione</Form.Label>
                              <BookingStatus
                                open={open}
                                setOpen={setOpen}
                                status={bookingStatusList}
                                getBookingStatus={getBookingStatus}
                                disabled={!past}
                                setBookingStatuse={setBookingStatuse}
                                changeStatus={changeStatus}
                              />
                              <Form.Text className='text-muted'>
                                <svg
                                  width='21'
                                  height='20'
                                  viewBox='0 0 21 20'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    fill-rule='evenodd'
                                    clip-rule='evenodd'
                                    d='M10.5 0C4.98 0 0.5 4.48 0.5 10C0.5 15.52 4.98 20 10.5 20C16.02 20 20.5 15.52 20.5 10C20.5 4.48 16.02 0 10.5 0ZM11.5 16H9.5V14H11.5V16ZM11.5 13H9.5V4H11.5V13Z'
                                    fill='#D2D2D6'
                                  />
                                </svg>
                                La donazione ancora non è stata effettuata
                              </Form.Text>
                            </Form.Group>
                          </div>
                        </Col>
                        {!currentReservationName && (
                          <Col xs={12} sm={6} md={6} lg={6}></Col>
                        )}
                        {!currentReservationName && (
                          <Col xs={12} sm={6} md={6} lg={6}></Col>
                        )}
                        {currentReservationName && (
                          <Col xs={12} sm={6} md={6} lg={6}>
                            <Form.Group className='mb-3' controlId='formBasicEmail'>
                              <Form.Label>Nome evento</Form.Label>
                              <Form.Control
                                type='text'
                                name='eventName'
                                value={eventName}
                                disabled
                                onChange={(e) => setEventName(e.target.value)}
                              />
                            </Form.Group>
                          </Col>
                        )}

                        {!currentReservationName && (
                          <Col xs={12} sm={6} md={6} lg={6}>
                            <Form.Group className='mb-3' controlId='formBasicEmail'>
                              <Form.Label>Centro di donazione</Form.Label>
                              <Form.Control
                                type='text'
                                disabled
                                value={donationCenterName}
                                name='place'
                                onChange={(e) => setPlace(e.target.value)}
                              />
                            </Form.Group>
                          </Col>
                        )}
                        <Col xs={12} sm={6} md={6} lg={6}></Col>
                        <Col xs={12} sm={6} md={6} lg={6}>
                          <Form.Group className='mb-3' controlId='formBasicEmail'>
                            <Form.Label>Data dell’evento</Form.Label>
                            <Form.Control
                              disabled
                              type='text'
                              value={timeEvent}
                              name='timeEvent'
                              onChange={(e) => setTimeEvent(e.target.value)}
                            />
                          </Form.Group>
                        </Col>

                        <Col xs={12} sm={6} md={6} lg={6}></Col>
                        {editPastReservation == false && (
                          <Col xs={12} sm={6} md={6} lg={6}>
                            <Form.Group className='mb-3' controlId='formBasicEmail'>
                              <Form.Label>Orario di prenotazione</Form.Label>
                              {/* <Form.Control type="text" value={featureSlotes} name="timeEvent" onChange={(e)=>setFeatureSlots(e.target.value)}/>                                       */}
                              <Form.Select
                                disabled={past}
                                aria-label='Default select example'
                                value={currentInterval}
                                name='timeEvent'
                                onChange={getFeatureSlots}
                              >
                                {editFeatureSlotDonationCenter?.map((data, index) => {
                                  return (
                                    <option value={data.id} key={index}>
                                      {data.slot}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                            </Form.Group>
                          </Col>
                        )}

                        {editPastReservation == true && (
                          <Col xs={12} sm={6} md={6} lg={6}>
                            <Form.Group className='mb-3' controlId='formBasicEmail'>
                              <Form.Label>Orario dell’evento</Form.Label>
                              <Form.Control
                                type='text'
                                disabled
                                value={featureSlotes}
                                name='timeEvent'
                                onChange={(e) => setFeatureSlots(e.target.value)}
                              />
                            </Form.Group>
                          </Col>
                        )}
                      </Row>
                      <Row>
                        <Col xs={12} sm={6} md={6} lg={6}>
                          <Form.Group
                            className='mb-3'
                            controlId='exampleForm.ControlTextarea1'
                          >
                            <Form.Label>Note</Form.Label>
                            <Form.Control
                              as='textarea'
                              rows={3}
                              name='reseveNotes'
                              value={reseveNotes}
                              onChange={(e) => setReserveNotes(e.target.value)}
                            />
                            <p>{reseveNotes.length}/200 caratteri</p>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          className='mt-4 d-flex justify-content-end'
                        >
                          <UseButton
                            type='submit'
                            name='adminBtnPrimary'
                            variant='primary'
                            labels='Salva modifiche '
                          />
                        </Col>
                      </Row>
                    </form>
                  </section>
                )}
                {addReservation === true && (
                  <NewBooking
                    donationCenters={donationCenterList}
                    companies={companyList}
                    events={companyEvents}
                    userId={location?.state?.userId}
                    endAdd={() => {
                      setAddReservation(false);
                      setReservation(false);
                      reservations();
                    }}
                  />
                )}
                {/* end of tab2 */}
              </Tab>
            )}
            {location.pathname.slice(1) === "Utenti/Modificare-Utenti" && (
              <Tab eventKey='Avvisi' title='Avvisi'>
                <div className='avvisi adminCustomToggle'>
                  {userAlerts?.length ? (
                    <h6>
                      Il prossimo avviso avverrà tra{" "}
                      <strong className='ms-2'> {nextReminder || 0} giorni</strong>
                    </h6>
                  ) : (
                    <h6>Nessun avviso in programa</h6>
                  )}
                  <Row>
                    {userAlerts?.length
                      ? userAlerts.map((alert) => (
                          <Col
                            key={alert.id}
                            xs={12}
                            sm={2}
                            md={4}
                            lg={4}
                            className='mt-4'
                          >
                            <Form.Check
                              type='switch'
                              id='remainderDay'
                              name='remainderDay'
                              onChange={(e) =>
                                handleAlertToggle({
                                  enabled: e.target.checked,
                                  alertId: alert.id,
                                })
                              }
                              checked={alert.isActive}
                              value={alert.isActive}
                              label={alert.name}
                            />
                          </Col>
                        ))
                      : null}
                  </Row>
                  {/* <Row className='mt-4'>
                    <Col xs={12} sm={6} md={6} lg={6}>
                      <Form.Group className='mb-3' controlId='formBasicEmail'>
                        <Form.Label>Link avviso</Form.Label>
                        <Form.Control
                          type='text'
                          name='linkNotice'
                          value={linkNotice}
                          onChange={(e) => setlinkNotice(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} sm={4} md={4} lg={4}>
                      <Form.Group className='mb-3' controlId='formBasicEmail'>
                        <Form.Label>&nbsp;</Form.Label>
                        <UseButton
                          name='adminBtnPrimary'
                          variant='outline-primary'
                          labels='Manda ora l’avviso'
                        />
                      </Form.Group>
                    </Col>
                  </Row> */}
                </div>
                {/* end of tab3 */}
              </Tab>
            )}
            {location.pathname.slice(1) === "Utenti/Modificare-Utenti" && (
              <Tab eventKey='Idoneità' title='Idoneità'>
                <section className='Idoneità'>
                  <h6>Questo quiz è stato compilato 10 giorni fa</h6>
                  <Form onSubmit={registerUser}>
                    <Row>
                      <Col xs={12} sm={4} md={4} lg={4}>
                        <Form.Group className='mb-3' controlId='checkUp'>
                          <Form.Label>Hai mai fatto check-up medici?</Form.Label>
                          {/* <Form.Control type="text" placeholder="Webinar sulla formazione" />                                       */}
                          <Row>
                            <Col xs={12} sm={6} md={6} lg={6}>
                              <Form.Check
                                type='radio'
                                label='Si'
                                name='checkUp'
                                value='true'
                                checked={checkUp === true}
                                onChange={(e) => setCheckup(e.target.value === "true")}
                              />
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={6}>
                              <Form.Check
                                type='radio'
                                label='No'
                                name='checkUp'
                                value='false'
                                checked={checkUp === false}
                                onChange={(e) => setCheckup(e.target.value === "true")}
                              />
                            </Col>
                          </Row>
                        </Form.Group>
                      </Col>
                      <Col xs={12} sm={4} md={4} lg={4}>
                        <Form.Group className='mb-3' controlId='bloodTest'>
                          <Form.Label>Hai mai fatto analisi del sangue?</Form.Label>
                          {/* <Form.Control type="text" placeholder="Webinar sulla formazione" />                                       */}
                          <Row>
                            <Col xs={12} sm={6} md={6} lg={6}>
                              <Form.Check
                                type='radio'
                                label='Si'
                                name='bloodTest'
                                value='true'
                                checked={bloodTest === true}
                                onChange={(e) => setBloodTest(e.target.value === "true")}
                              />
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={6}>
                              <Form.Check
                                type='radio'
                                label='No'
                                name='bloodTest'
                                value='false'
                                checked={bloodTest === false}
                                onChange={(e) => setBloodTest(e.target.value === "true")}
                              />
                            </Col>
                          </Row>
                        </Form.Group>
                      </Col>
                      <Col xs={12} sm={4} md={4} lg={4}>
                        <Form.Group className='mb-3' controlId='occupation'>
                          <Form.Label>Di cosa ti occupi?</Form.Label>
                          {/* <Form.Control type="text" placeholder="Webinar sulla formazione" />                                       */}
                          <Form.Select
                            aria-label='Default select example'
                            name='occupation'
                            value={occupation}
                            onChange={(e) => setOccupation(e.target.value)}
                          >
                            <option value='' disabled>
                              Seleziona opzione
                            </option>
                            <option value='studente '>Studente </option>
                            <option value='lavoratore-dipendente'>
                              Lavoratore dipendente
                            </option>
                            <option value='lavoratore-autonomo'>
                              Lavoratore autonomo
                            </option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={6} md={6} lg={6}>
                        <Form.Group className='mb-3' controlId='doanted'>
                          <Form.Label>Hai mai donato?</Form.Label>
                          {/* <Form.Control type="text" name="doanted" value={doanted} onChange={(e)=>setOccupation(e.target.va)} />                                       */}
                          <Row>
                            <Col xs={12} sm={6} md={6} lg={6}>
                              <Form.Check
                                type='radio'
                                label='Si'
                                name='doanted'
                                value='true'
                                checked={doanted === true}
                                onChange={everDoanted}
                              />
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={6}>
                              <Form.Check
                                type='radio'
                                label='No'
                                name='doanted'
                                value='false'
                                checked={doanted === false}
                                onChange={everDoanted}
                              />
                            </Col>
                          </Row>
                        </Form.Group>
                      </Col>
                      <Col xs={12} sm={6} md={6} lg={6}>
                        <Form.Group className='mb-3' controlId='whenDonate'>
                          <Form.Label>Quanto tempo fa hai donato?</Form.Label>
                          {/* <Form.Control disabled={doanted === false} type="text" name="whenDonate" value={whenDonate} onChange={(e)=>setWhenDonate(e.target.value)} />*/}
                          <Form.Select
                            disabled={doanted === false}
                            name='whenDonate'
                            value={whenDonate}
                            onChange={(e) => setWhenDonate(e.target.value)}
                          >
                            <option value='' disabled>
                              Seleziona opzione
                            </option>
                            <option value='meno di 3 mesi fa '>Meno di 3 mesi fa </option>
                            <option value='meno di 6 mesi fa'>Meno di 6 mesi fa</option>
                            <option value='piu di 6 mesi fa'>Piu di 6 mesi fa</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col xs={12} sm={6} md={6} lg={6}>
                        <Form.Group className='mb-3' controlId='smoker'>
                          <Form.Label>Sei un fumatore?</Form.Label>
                          {/* <Form.Control type="text" name="smoker" onChange={(e)=>setSmoker(e.target.value)}  />                                       */}
                          <Row>
                            <Col xs={12} sm={6} md={6} lg={6}>
                              {smoker}
                              <Form.Check
                                type='radio'
                                label='Si'
                                name='smoker'
                                value='true'
                                checked={smoker === true}
                                onChange={smokerDetails}
                              />
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={6}>
                              <Form.Check
                                type='radio'
                                label='No'
                                name='smoker'
                                value='false'
                                checked={smoker === false}
                                onChange={smokerDetails}
                              />
                            </Col>
                          </Row>
                        </Form.Group>
                      </Col>
                      <Col xs={12} sm={6} md={6} lg={6}>
                        <Form.Group className='mb-3' controlId='howManyCigrates'>
                          <Form.Label>Quante sigarette fumi al giorno?</Form.Label>
                          {/* <Form.Control disabled={smoker === false} type="text" name="howManyCigrates" value={howManyCigrates} onChange={(e)=>setHowManyCigrates(e.target.value)} />*/}
                          <Form.Select
                            disabled={smoker === false}
                            name='howManyCigrates'
                            value={howManyCigrates}
                            onChange={(e) => setHowManyCigrates(e.target.value)}
                          >
                            <option value='' disabled>
                              Seleziona opzione
                            </option>
                            <option value='1 al giorno'>1 al giorno</option>
                            <option value='tra 2 e 5'>Tra 2 e 5</option>
                            <option value='tra 5 e 10'>Tra 5 e 10</option>
                            <option value='più di 10 al giorno'>
                              Più di 10 al giorno
                            </option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col xs={12} sm={6} md={6} lg={6}>
                        <Form.Group className='mb-3' controlId='foodIntolerance'>
                          <Form.Label>Hai intollerante alimentari?</Form.Label>
                          {/* <Form.Control type="text" placeholder="Webinar sulla formazione" />                                       */}
                          <Row>
                            <Col xs={12} sm={6} md={6} lg={6}>
                              <Form.Check
                                type='radio'
                                label='Si'
                                name='foodIntolerance'
                                value='true'
                                checked={foodIntolerance === true}
                                onChange={getFoodDetails}
                              />
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={6}>
                              <Form.Check
                                type='radio'
                                label='No'
                                name='foodIntolerance'
                                value='false'
                                checked={foodIntolerance === false}
                                onChange={getFoodDetails}
                              />
                            </Col>
                          </Row>
                        </Form.Group>
                      </Col>
                      <Col xs={12} sm={6} md={6} lg={6}>
                        <Form.Group className='mb-3' controlId='intolrance'>
                          <Form.Label>Quali sono le tue intolleranze?</Form.Label>
                          <Form.Control
                            disabled={foodIntolerance === false}
                            type='text'
                            name='intolrance'
                            value={intolrance}
                            onChange={(e) => setIntolrance(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} sm={6} md={6} lg={6}>
                        <Form.Group className='mb-3' controlId='supplemets'>
                          <Form.Label>Prendi farmaci o integratori?</Form.Label>
                          {/* <Form.Control type="text" placeholder="Webinar sulla formazione" />                                       */}
                          <Row>
                            <Col xs={12} sm={6} md={6} lg={6}>
                              <Form.Check
                                type='radio'
                                label='Si'
                                value='true'
                                name='supplemets'
                                checked={supplemets === true}
                                onChange={getSupplimentsDetails}
                              />
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={6}>
                              <Form.Check
                                type='radio'
                                label='No'
                                value='false'
                                name='supplemets'
                                checked={supplemets === false}
                                onChange={getSupplimentsDetails}
                              />
                            </Col>
                          </Row>
                        </Form.Group>
                      </Col>
                      <Col xs={12} sm={6} md={6} lg={6}>
                        <Form.Group className='mb-3' controlId='whatMedicines'>
                          <Form.Label>Quali farmaci assumi?</Form.Label>
                          <Form.Control
                            disabled={supplemets === false}
                            type='text'
                            name='whatMedicines'
                            value={whatMedicines}
                            onChange={(e) => setWhatMedicines(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        className='d-flex justify-content-end mb-4'
                      >
                        <UseButton
                          type='submit'
                          name='adminBtnPrimary'
                          variant='primary'
                          labels='Salva modifiche'
                        />
                      </Col>
                    </Row>
                  </Form>
                </section>
                {/* end of tab4 */}
              </Tab>
            )}
          </Tabs>
        </div>
        {loader && <UseLoader />}
        <UseAlert show={show} setShow={setShow} variant={variant} alertMsg={alertMsg} />
      </section>
    </div>
  );
};

AddUser.propTypes = {};

AddUser.defaultProps = {};

export default AddUser;
