import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import UseButton from "../../Components/UseButton/UseButton";
import "./UseModal.scss";
const UseModal = ({
  openModal,
  closeModal,
  webName,
  setWebName,
  webDesc,
  setWebDesc,
  url,
  setUrl,
  image,
  setImage,
  webinarId,
}) => {
  const [show, setShow] = useState(false);
  const location = useLocation();
  const navigates = useNavigate();
  const functions = getFunctions();
  const remveWebinar = () => {
    try {
      const removeWebinar = httpsCallable(functions, "deleteWebinar");
      removeWebinar({ id: webinarId })
        .then((response) => {
          console.log("remoe Webinar response", response);
          closeModal(false);
          window.location.reload();
        })
        .catch((error) => console.error("error", error));
    } catch (error) {
      console.log("error", error);
    }
  };
  const backToSlot = () => {
    navigates("/home/Scegli-il-posto");
    window.location.reload();
  };

  return (
    <div className='UseModal' data-testid='UseModal'>
      <div className='modal show' style={{ display: "block", position: "initial" }}>
        <Modal show={openModal} onHide={closeModal} centered>
          {/* <Modal.Header closeButton>
                              <Modal.Title>Modal heading</Modal.Title>
                            </Modal.Header> */}
          <Modal.Body>
            {location.pathname.slice(1) === "companies/company-profile" && (
              <div>
                <h2>Vuoi davvero eliminare il webinar “Nome webinar”</h2>
                <h6>Una volta eliminato non potrai tornare indietro</h6>

                <Row>
                  <Col xs={12} sm={6} md={6} lg={6}>
                    <UseButton
                      name='adminBtnPrimary'
                      variant='outline-primary'
                      labels='Annulla'
                    />
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6}>
                    <UseButton
                      name='adminBtnPrimary'
                      variant='primary'
                      labels='Elimina'
                      onClick={remveWebinar}
                    />
                  </Col>
                </Row>
              </div>
            )}

            {location.pathname.slice(1) === "register" && (
              <div>
                <h2>Vuoi davvero annullare la tua prenotazione?</h2>
                <h6 style={{ textAlign: "center" }}>
                  I dati inseriti saranno cancellati
                </h6>

                <Row className='userModalBtn'>
                  <Col xs={12} sm={6} md={6} lg={6}>
                    <UseButton
                      name='adminBtnPrimary'
                      variant='outline-primary'
                      labels='Indietro'
                      onClick={backToSlot}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6}>
                    <UseButton
                      name='adminBtnPrimary'
                      variant='primary'
                      labels='Annulla prenotazione'
                      onClick={closeModal}
                    />
                  </Col>
                </Row>
              </div>
            )}

            {/* <h2>Vuoi davvero eliminare il webinar “Nome webinar”</h2>
                                  <h6>Una volta eliminato non potrai tornare indietro</h6>
                                  <Row>
                                      <Col xs={12} sm={6} md={6} lg={6}>
                                              <UseButton name="adminBtnPrimary" variant="outline-primary" labels="Annulla" />
                                      </Col>
                                      <Col xs={12} sm={6} md={6} lg={6}>
                                              <UseButton name="adminBtnPrimary" variant="primary" labels="Elimina" />
                                      </Col>
                                  </Row>     */}
          </Modal.Body>
          {/* <Modal.Footer>
                              <Button variant="secondary" onClick={closeModal}>
                                Close
                              </Button>
                              <Button variant="primary" onClick={closeModal}>
                                Save Changes
                              </Button>
                            </Modal.Footer> */}
        </Modal>
      </div>
    </div>
  );
};

UseModal.propTypes = {};

UseModal.defaultProps = {};

export default UseModal;
