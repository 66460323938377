import { addMonths } from "date-fns";
import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, ListGroup, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import UseWindowSize from "../../Components/Hooks/UseWindowSize";
import UseButton from "../../Components/UseButton/UseButton";
import { useAuth } from "../../Hookes/useAuth";
import Logo from "../../assets/images/Rosso_white.png";
import BLogo from "../../assets/images/bloodLogo.png";
import "./Quiz.scss";
const Quiz = () => {
  const { currentUser, loading, uid } = useAuth();
  const { height } = UseWindowSize();
  // const [progressValue, setProgressValue] = useState(10);
  const [start, setStart] = useState(false);
  const [name, setName] = useState("");
  const [screen, setScreen] = useState("screen1");
  const [quiz, setQuiz] = useState(true);
  const [getQuizResult, setGetQuizResult] = useState(false);
  const [setDonationStatus, setDoantionStatus] = useState(false);
  const [gender, setGender] = useState(0);
  // const [endQuiz, setEndQuiz] = useState(false)

  const [quiz1, setQuiz1] = useState(false);
  const [quiz2, setQuiz2] = useState(false);
  const [quiz3, setQuiz3] = useState(false);
  const [quiz4, setQuiz4] = useState(false);
  const [quiz5, setQuiz5] = useState(false);
  const [quiz6, setQuiz6] = useState(false);
  const [quiz7, setQuiz7] = useState(false);
  const [description, setDescription] = useState("");

  const navigate = useNavigate();
  const functions = getFunctions();

  useEffect(() => {
    let name = localStorage.getItem("name");
    let id = localStorage.getItem("uid");
    setName(name);
    // setId(id)
    getUserGender(id);
  }, []);

  const startQuiz = () => {
    setQuiz(false);
    setScreen("screen2");
    setGetQuizResult(true);
    // setMsg()
  };

  const back = () => {
    setQuiz(true);
    setScreen("screen1");
    setGetQuizResult(false);
    // setMsg()
  };

  const confirmBooking = () => {
    if (
      quiz1 === true &&
      quiz3 === true &&
      quiz5 === false &&
      quiz7 === false &&
      quiz2 === false &&
      quiz4 === false &&
      (quiz6 === true || quiz6 === false)
    ) {
      setQuiz(false);
      setScreen("success");
      setGetQuizResult(false);
      setDoantionStatus(true);
      //setMsg('success')
    } else {
      try {
        const afterSixMonth = httpsCallable(functions, "setUserAlert");
        afterSixMonth({
          name: "Dona fra 6 mesi",
          userId: uid,
          alertDate: addMonths(new Date(), 6),
        })
          .then((response) => {
            // console.log('individula user response',response)
            setQuiz(false);
            setScreen("failer");
            setGetQuizResult(false);
            setDoantionStatus(true);
          })
          .catch((error) => console.error("error", error));
      } catch (error) {
        console.error("error", error);
      }
      //setMsg('failer')
    }
  };

  const goToDonationCenterList = () => {
    navigate("/home/Scegli-il-posto");
  };

  const goToHomePage = () => {
    navigate("/home");
  };

  const getUserGender = (id) => {
    try {
      const getDonors = httpsCallable(functions, "getDonorById");
      getDonors({ id: id })
        .then((response) => {
          // console.log('individula user response',response)
          setGender(response.data.sex);
        })
        .catch((error) => console.error("error", error));
    } catch (error) {
      console.error("error", error);
    }
  };

  return (
    <div className='Quiz' data-testid='Quiz'>
      {quiz && screen === "screen1" && (
        <section className='startquiz' style={{ height: height - 0 }}>
          <div className='content'>
            <div>
              <h2>
                Benvenuto nella Community di <span>Rosso!</span>
              </h2>
              {/* <h6>Ti abbiamo inviato una Email con tutti i tuoi dati</h6> */}
            </div>
            <Card>
              <Card.Body>
                <Row>
                  <Col
                    xs={2}
                    sm={2}
                    md={2}
                    lg={2}
                    className='d-flex align-items-center justify-content-center'
                  >
                    <svg
                      width='42'
                      height='42'
                      viewBox='0 0 42 42'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <rect width='42' height='42' rx='10' fill='#FFF5F5' />
                      <path
                        d='M21 20.375V26.625'
                        stroke='#BB0F31'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M21 15.3875L21.0125 15.3736'
                        stroke='#BB0F31'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M21 33.5C27.9036 33.5 33.5 27.9036 33.5 21C33.5 14.0964 27.9036 8.5 21 8.5C14.0964 8.5 8.5 14.0964 8.5 21C8.5 27.9036 14.0964 33.5 21 33.5Z'
                        stroke='#BB0F31'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                  </Col>
                  <Col xs={10} sm={10} md={10} lg={10}>
                    <p>
                      Ti faremo qualche domanda per avere tutte le informazioni necessarie
                      a donare. <strong>Rispondi sinceramente</strong>, è importante.
                    </p>
                    <div className='agree'>
                      <Form.Check // prettier-ignore
                        type='checkbox'
                        name='start'
                        value={start}
                        checked={start === true}
                        onChange={(e) => setStart(e.target.checked)}
                        label=''
                      />{" "}
                      Ho letto e accetto <span>i Termini e Condizioni</span>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            <Button disabled={start == false} onClick={startQuiz} className='customBtn'>
              Iniziamo
            </Button>
          </div>
        </section>
      )}
      {getQuizResult && screen === "screen2" && (
        <section className='eligibilityQuiz'>
          <div className='logos'>
            <img src={Logo} alt='Logo' style={{ width: "190px" }} />
          </div>
          <div className='innerScreen'>
            <Row>
              <Col xs={12} sm={12} md={6} lg={6}>
                <h6>Pesi più di 50 kg?</h6>
                <Row>
                  <Col xs={6} sm={6} md={6} lg={6} className='mb-4'>
                    <Form.Check
                      type='radio'
                      label='Si'
                      name='quiz1'
                      value='true'
                      checked={quiz1 === true}
                      onChange={(e) => setQuiz1(e.target.value === "true")}
                    />
                  </Col>
                  <Col xs={6} sm={6} md={6} lg={6} className='mb-4'>
                    <Form.Check
                      type='radio'
                      label='No'
                      name='quiz1'
                      value='false'
                      checked={quiz1 === false}
                      onChange={(e) => setQuiz1(e.target.value === "true")}
                    />
                  </Col>
                </Row>
                <h6>Hai meno di 65 anni?</h6>
                <Row>
                  <Col xs={6} sm={6} md={6} lg={6} className='mb-4'>
                    <Form.Check
                      type='radio'
                      label='Si'
                      name='quiz3'
                      value='true'
                      checked={quiz3 === true}
                      onChange={(e) => setQuiz3(e.target.value === "true")}
                    />
                  </Col>
                  <Col xs={6} sm={6} md={6} lg={6} className='mb-4'>
                    <Form.Check
                      type='radio'
                      label='No'
                      name='quiz3'
                      value='false'
                      checked={quiz3 === false}
                      onChange={(e) => setQuiz3(e.target.value === "true")}
                    />
                  </Col>
                </Row>
                <h6>Attualmente mi trovo in una delle seguenti condizioni fisiche:</h6>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12} className='mb-4'>
                    <ListGroup as='ul' className='quiz-list'>
                      <ListGroup.Item as='li'>
                        Tatuaggio e piercing (da almeno 3 mesi)
                      </ListGroup.Item>
                      <ListGroup.Item as='li'>
                        Medicinali (da almeno 3 giorni)
                      </ListGroup.Item>
                      <ListGroup.Item as='li'>
                        Gravidanza (da almeno 6 mesi)
                      </ListGroup.Item>
                      <ListGroup.Item as='li'>
                        Sintomi influenzali (da almeno 7 giorni)
                      </ListGroup.Item>
                      <ListGroup.Item as='li'>
                        Cure odontoiatriche (da almeno 4 giorni)
                      </ListGroup.Item>
                      <ListGroup.Item as='li'>
                        Interventi chirurgici (da almeno 14 giorni)
                      </ListGroup.Item>
                      <ListGroup.Item as='li'>Covid (da almeno 7 giorni)</ListGroup.Item>
                      <ListGroup.Item as='li'>
                        Viaggi estero (da almeno 3 mesi)
                      </ListGroup.Item>
                      <ListGroup.Item as='li'>Vaccini (da almeno 1 mese)</ListGroup.Item>
                      <ListGroup.Item as='li'>
                        Trasfusioni (da almeno 4 mesi)
                      </ListGroup.Item>
                      <ListGroup.Item as='li'>
                        Comportamenti sessuali a rischio{" "}
                      </ListGroup.Item>
                    </ListGroup>
                    (da almeno 14 giorni)
                    <Row>
                      <Col xs={6} sm={6} md={6} lg={6}>
                        <Form.Check
                          type='radio'
                          label='Si'
                          name='quiz5'
                          value='true'
                          checked={quiz5 === true}
                          onChange={(e) => setQuiz5(e.target.value === "true")}
                        />
                      </Col>
                      <Col xs={6} sm={6} md={6} lg={6}>
                        <Form.Check
                          type='radio'
                          label='No'
                          name='quiz5'
                          value='false'
                          checked={quiz5 === false}
                          onChange={(e) => setQuiz5(e.target.value === "true")}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <h6>Hai fatto un tatuaggio nelle ultime due settimane?</h6>
                <Row>
                  <Col xs={6} sm={6} md={6} lg={6}>
                    <Form.Check
                      type='radio'
                      label='Si'
                      name='quiz7'
                      value='true'
                      checked={quiz7 === true}
                      onChange={(e) => setQuiz7(e.target.value === "true")}
                    />
                  </Col>
                  <Col xs={6} sm={6} md={6} lg={6}>
                    <Form.Check
                      type='radio'
                      label='No'
                      name='quiz7'
                      value='false'
                      checked={quiz7 === false}
                      onChange={(e) => setQuiz7(e.target.value === "true")}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <h6>Hai donato negli ultimi 90 giorni?</h6>
                    <Row>
                      <Col xs={6} sm={6} md={6} lg={6} className='mb-4'>
                        <Form.Check
                          type='radio'
                          label='Si'
                          name='quiz2'
                          value='true'
                          checked={quiz2 === true}
                          onChange={(e) => setQuiz2(e.target.value === "true")}
                        />
                      </Col>
                      <Col xs={6} sm={6} md={6} lg={6} className='mb-4'>
                        <Form.Check
                          type='radio'
                          label='No'
                          name='quiz2'
                          value='false'
                          checked={quiz2 === false}
                          onChange={(e) => setQuiz2(e.target.value === "true")}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12} className='mb-4'>
                    <h6>Ho una delle seguenti patologie</h6>
                    <ListGroup as='ul' className='quiz-list'>
                      <ListGroup.Item as='li'>Diabete</ListGroup.Item>
                      <ListGroup.Item as='li'>Malattie cardiovascolari</ListGroup.Item>
                      <ListGroup.Item as='li'>Neoplasie</ListGroup.Item>
                      <ListGroup.Item as='li'>Allergie/Intolleranze</ListGroup.Item>
                      <ListGroup.Item as='li'>Tossicodipendenza</ListGroup.Item>
                      <ListGroup.Item as='li'>Alcolismo cronico</ListGroup.Item>
                    </ListGroup>
                    Quelle indicate hanno carattere di esempio
                    <Row>
                      <Col xs={6} sm={6} md={6} lg={6}>
                        <Form.Check
                          type='radio'
                          label='Si'
                          name='quiz4'
                          value='true'
                          checked={quiz4 === true}
                          onChange={(e) => setQuiz4(e.target.value === "true")}
                        />
                      </Col>
                      <Col xs={6} sm={6} md={6} lg={6}>
                        <Form.Check
                          type='radio'
                          label='No'
                          name='quiz4'
                          value='false'
                          checked={quiz4 === false}
                          onChange={(e) => setQuiz4(e.target.value === "true")}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <h6>Ho dei dubbi circa la mia idoneità</h6>
                    <Row>
                      <Col xs={6} sm={6} md={6} lg={6}>
                        <Form.Check
                          type='radio'
                          label='Si'
                          name='quiz6'
                          value='true'
                          checked={quiz6 === true}
                          onChange={(e) => setQuiz6(e.target.value === "true")}
                        />
                      </Col>
                      <Col xs={6} sm={6} md={6} lg={6}>
                        <Form.Check
                          type='radio'
                          label='No'
                          name='quiz6'
                          value='false'
                          checked={quiz6 === false}
                          onChange={(e) => setQuiz6(e.target.value === "true")}
                        />
                      </Col>
                    </Row>
                    <Form.Control
                      name='description'
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      as='textarea'
                      rows={3}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div className='bottom'>
            <div className='content'>
              <span onClick={back} style={{ cursor: "pointer" }}>
                <svg
                  width='40'
                  height='40'
                  viewBox='0 0 40 40'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M26.6666 20.0013H13.3333M13.3333 20.0013L19.1666 25.8346M13.3333 20.0013L19.1666 14.168'
                    stroke='black'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M19.9999 36.6654C29.2047 36.6654 36.6666 29.2034 36.6666 19.9987C36.6666 10.794 29.2047 3.33203 19.9999 3.33203C10.7952 3.33203 3.33325 10.794 3.33325 19.9987C3.33325 29.2034 10.7952 36.6654 19.9999 36.6654Z'
                    stroke='black'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </span>
              <Button onClick={confirmBooking}>
                Conferma
                <svg
                  width='21'
                  height='20'
                  viewBox='0 0 21 20'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M14.21 9.29L8.21 3.29C8.03 3.11 7.78 3 7.5 3C6.95 3 6.5 3.45 6.5 4C6.5 4.28 6.61 4.53 6.79 4.71L12.09 10L6.8 15.29C6.61 15.47 6.5 15.72 6.5 16C6.5 16.55 6.95 17 7.5 17C7.78 17 8.03 16.89 8.21 16.71L14.21 10.71C14.39 10.53 14.5 10.28 14.5 10C14.5 9.72 14.39 9.47 14.21 9.29Z'
                    fill='#F7F7FA'
                  />
                </svg>
              </Button>
            </div>
          </div>
        </section>
      )}
      {setDonationStatus && (
        <section className='success'>
          {screen === "success" && (
            <div className='innerContent'>
              <img src={BLogo} alt='Rosso' className='logo' />
              <h2>
                Ciao {currentUser?.displayName || "User"}! Non ti manca che prenotare la
                tua prima donazione
              </h2>
              <h6>
                Potrebbe essere posticipata a data da destinarsi se non rispetti tutte le
                caratteristiche prima elencate.
              </h6>
              <UseButton
                onClick={goToDonationCenterList}
                name='adminBtnPrimary'
                variant='primary'
                labels='Prenota ora'
              />
            </div>
          )}
          {screen === "failer" && (
            <div className='innerContent'>
              <img src={BLogo} alt='Rosso' className='logo' />
              <h2>
                Ciao {currentUser?.displayName || "User"}! Purtroppo non puoi donare ora,
                ti ricontattiamo noi.
              </h2>
              <h6>Tra {gender === "maschio" ? "3" : "6"} mesi riceverai un reminder.</h6>
              <UseButton
                onClick={goToHomePage}
                name='adminBtnPrimary'
                variant='primary'
                labels='Torna alla homepage'
              />
            </div>
          )}
        </section>
      )}
    </div>
  );
};

Quiz.propTypes = {};

Quiz.defaultProps = {};

export default Quiz;
