import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const registerUser = createAsyncThunk(
  "user/loginUser",
  async (userRegister, { rejectWithValue }) => {
    console.log("redux from userRegister", userRegister);
    // try{
    //     const request = await axios.post(Baseurl+'/userapi/login',userCredentials)
    //     const response = await request.data;
    //     // console.log('login><>>>',response)
    //     localStorage.setItem('username',response.content.fullName)
    //     localStorage.setItem('userId',response.content.userId)
    //     localStorage.setItem('id',response.content.token)
    //     localStorage.setItem('role',response.content.role[0].roleName)
    //     return response;
    // }catch(err){
    //         return rejectWithValue(err.response.data)
    // }
  },
);

const userRegisterSlice = createSlice({
  name: "user",
  initialState: {
    loading: false,
    user: null,
    error: null,
    username: "",
    userDetails: [],
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.user = null;
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.error = null;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false;
        state.user = null;
        // console.log('>>>rejected stae',state);
        // console.log('>>>rejected',action);
        // if(action.error.message === 'Request failed with status code 401' || action.error.message === 'Request failed with status code 400'){
        //     state.error = 'Invalid username or password';
        // }else{
        //     state.error = action.error.message;
        // }
        state.error = action.error.message;
      });
  },
});

export default userRegisterSlice.reducer;
