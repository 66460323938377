import it from "date-fns/locale/it";
import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import UseLoader from "../../Components/Loader/Loader";
import UseAlert from "../../Components/UseAlert/UseAlert";
import UseButton from "../../Components/UseButton/UseButton";
import { months, years } from "../../Components/Utils/Config";
import { useAuth } from "../../Hookes/useAuth";
import "./UserAccount.scss";
const UserAccount = () => {
  const { currentUser } = useAuth();
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [ids, setIds] = useState(0);
  const [loader, setLoader] = useState(false);
  const [token, setToken] = useState("");
  const [email, setEmail] = useState("");
  const [startDate, setStartDate] = useState(new Date());

  const [show, setShow] = useState(false);
  const [variant, setVarient] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const navigate = useNavigate();
  const functions = getFunctions();
  const {
    register,
    handleSubmit,
    setValue,
    getValue,
    getValues,
    watch,
    formState: {
      errors,
      isValid,
      isDirty,
      isSubmitting,
      isSubmitted,
      isSubmitSuccessful,
    },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      phoneNumber: "",
      lastName: "",
      tax: "",
      birthPlace: "",
      city: "",
      contact: "",
      dob: "",
      id: "",
    },
    mode: "onSubmit",
  });
  const providerId = useMemo(
    () => currentUser.providerData[0].providerId,
    [currentUser.providerData],
  );

  useEffect(() => {
    let name = localStorage.getItem("name");
    let id = localStorage.getItem("uid");
    let token = localStorage.getItem("token");
    setName(name);
    setIds(id);
    setToken(token);

    getUserInfo(id);
  }, []);

  const onSubmit = (data) => {
    console.log("update donar", data);

    try {
      setLoader(true);
      const upadteDonar = httpsCallable(functions, "updateDonor");
      upadteDonar(data)
        .then((response) => {
          console.log("edit donar settings", response);
          setShow(true);
          setVarient("success");
          setAlertMsg(response.data.message);
          setLoader(false);
        })
        .catch((error) => {
          setShow(true);
          setVarient("danger");
          setAlertMsg(error.message);
        });
    } catch (error) {
      console.error("error", error);
    }
  };

  const getUserInfo = (ids) => {
    try {
      setLoader(true);
      const getDonarsById = httpsCallable(functions, "getDonorById");
      getDonarsById({ id: ids })
        .then((response) => {
          console.log("get donar settings", response);
          // setShow(true)
          // setVarient('success')
          // setAlertMsg(response.data.message)
          setLoader(false);
          accountLoader(response.data);
        })
        .catch((error) => {
          setLoader(false);
          console.error("error", error);
        });
    } catch (error) {
      setLoader(false);
      setShow(true);
      setVarient("danger");
      setAlertMsg(error.message);
    }
  };

  const accountLoader = (data) => {
    setValue("name", data.name);
    setValue("email", data.email);
    setEmail(data.email);
    setValue("phoneNumber", data.phoneNumber);
    setValue("lastName", data.surname);
    setValue("tax", data.taxCode);
    setValue("birthPlace", data.birthPlace);
    setValue("city", data.currentCity);
    setValue("contact", data.contact);
    setValue("id", data.id);
    setValue("dob", data.dob);
    setStartDate(data.dob);
    setCompany(data.companyName || "Rosso");
  };

  const getDate = (e) => {
    console.log("date>>>>>>1111", e);
    // console.log('date>>>>>>2222',e.toLocaleDateString())
    // let date = e.toLocaleDateString()
    let date = new Date(e);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const newDate = `${month}/${day}/${year}`;
    console.log("new datae>>>>>", newDate);
    setStartDate(e);
    setValue("dateOfBirth", newDate);
  };

  const getYear = (dob) => {
    return dob.getFullYear();
  };

  const changeYear = (year) => {
    console.log("Selected year:", year);
  };

  const changePassword = () => {
    navigate("/set-password", {
      state: { settingsEmailId: email, settingsUserId: ids, settingsToken: token },
    });
    // console.log('id : ',ids,'email : ',email,'token : ',token)
    // const value = getValues();
    // const data = {
    //   email:value.email,
    //   id:value.id,
    //   password:value.password,
    //   token:token,
    // }

    // try{
    //   setLoader(true)
    //   const changePasswords = httpsCallable(functions, "changePassword")
    //   changePasswords(data)
    //   .then((response)=>{
    //       console.log('change password',response)
    //       setShow(true)
    //       setVarient('success')
    //       setAlertMsg(response.data.message)
    //       setLoader(false)
    //   })
    //   .catch((error)=>{
    //     setShow(true);
    //     setVarient("danger");
    //     setAlertMsg(error.message);
    //   });
    // }catch(error){
    //   console.error('error',error);
    // }
  };

  return (
    <div className='UserAccount' data-testid='UserAccount'>
      <Container>
        <h2>
          Il tuo account - <span>azienda {company || "Nessuna azienda Associata"}</span>
        </h2>

        <section className='account'>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <input type='hidden' name='id' {...register("id")} />
            <Row>
              <Col xs={12} sm={4} md={4} lg={4}>
                <Form.Group className='mb-4' controlId='name'>
                  <Form.Label>Nome</Form.Label>
                  <Form.Control
                    type='text'
                    name='name'
                    {...register("name")}
                    disabled='disabled'
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={4} md={4} lg={4}>
                <Form.Group className='mb-4' controlId='email'>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type='text'
                    name='email'
                    {...register("email")}
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={4} md={4} lg={4}>
                <Form.Group className='mb-4' controlId='phoneNumber'>
                  <Form.Label>Numero di telefono</Form.Label>
                  <Form.Control
                    type='tel'
                    name='phoneNumber'
                    {...register("phoneNumber")}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={4} md={4} lg={4}>
                <Form.Group className='mb-4' controlId='lastName'>
                  <Form.Label>Cognome</Form.Label>
                  <Form.Control
                    type='text'
                    name='lastName'
                    {...register("lastName")}
                    disabled='disabled'
                  />
                </Form.Group>
              </Col>
              {providerId === "password" && (
                <Col xs={12} sm={4} md={4} lg={4} className='passwordCnt'>
                  <Form.Group className='mb-4' controlId='password'>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      disabled='disabled'
                      type='password'
                      name='password'
                      {...register("password")}
                    />
                    <Button type='button' onClick={changePassword}>
                      Modifica
                    </Button>
                  </Form.Group>
                </Col>
              )}
              <Col xs={12} sm={4} md={4} lg={4} className='texCode'>
                <Form.Group className='mb-4' controlId='tax'>
                  <Form.Label>Codice fiscale</Form.Label>
                  <Form.Control
                    type='text'
                    name='tax'
                    {...register("tax")}
                    disabled='disabled'
                  />
                  <Form.Text className='text-muted'>
                    <Link to={"https://www.donarosso.it/whatsapp"} target='_blank'>
                      Richiedi cambio codice fiscale
                    </Link>
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={4} md={4} lg={4}>
                <Form.Group className='mb-4' controlId='birthPlace'>
                  <Form.Label>Luogo di nascita</Form.Label>
                  <Form.Control
                    type='birthPlace'
                    name='birthPlace'
                    {...register("birthPlace")}
                    disabled='disabled'
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={4} md={4} lg={4}>
                <Form.Group className='mb-4' controlId='city'>
                  <Form.Label>Città attuale</Form.Label>
                  <Form.Control type='text' name='city' {...register("city")} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={4} md={4} lg={4}>
                <Form.Group className='mb-4' controlId='contact'>
                  <Form.Label>Dove preferisci essere contattato/a?</Form.Label>
                  {/* <Form.Control type="text" name="contact" /> */}
                  <Form.Select name='contact' {...register("contact")}>
                    <option value='email'>Email</option>
                    <option value='whatsapp'>Whatsapp</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={4} md={4} lg={4} className='reactCustomDatePicker'>
                <Form.Group className='mb-4' controlId='date'>
                  <Form.Label>Data di nascita</Form.Label>
                  {/* <Form.Control type="text" name="dob" /> */}
                  <DatePicker
                    renderCustomHeader={({
                      date,
                      changeYear,
                      changeMonth,
                      decreaseMonth,
                      increaseMonth,
                      prevMonthButtonDisabled,
                      nextMonthButtonDisabled,
                    }) => (
                      <div
                        style={{
                          margin: 10,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <button
                          onClick={decreaseMonth}
                          disabled={prevMonthButtonDisabled}
                        >
                          {"<"}
                        </button>
                        <select
                          value={getYear(date)}
                          onChange={({ target: { value } }) => changeYear(value)}
                        >
                          {years.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>

                        <select
                          value={date.getMonth()} // Use getMonth() directly for the value
                          onChange={(e) => changeMonth(Number(e.target.value))} // Convert value to number
                        >
                          {months.map((month, index) => (
                            <option key={month} value={index}>
                              {month}
                            </option>
                          ))}
                        </select>

                        <button
                          onClick={increaseMonth}
                          disabled={nextMonthButtonDisabled}
                        >
                          {">"}
                        </button>
                      </div>
                    )}
                    locale={it}
                    disabled='disabled'
                    dateFormat='dd/MM/YYYY'
                    selected={startDate}
                    maxDate={new Date()}
                    onChange={getDate}
                    {...register("dob")}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={21} lg={12} className='d-flex justify-content-end'>
                <UseButton
                  type='submit'
                  name='adminBtnPrimary'
                  variant='primary'
                  labels='Salva modifiche'
                />
              </Col>
            </Row>
          </Form>
        </section>
      </Container>
      {loader == true && <UseLoader />}
      <UseAlert show={show} setShow={setShow} variant={variant} alertMsg={alertMsg} />
    </div>
  );
};

UserAccount.propTypes = {};

UserAccount.defaultProps = {};

export default UserAccount;
