import { Button, Form } from "react-bootstrap";
import "./UseCounter.scss";
const UseCounter = ({ counter, setCounter }) => {
  return (
    <div className='useCounter'>
      <Button disabled={counter === 0} onClick={() => setCounter(counter - 1)}>
        <svg
          width='12'
          height='3'
          viewBox='0 0 12 3'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M1.33398 1.5H10.6673'
            stroke='#133774'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </Button>
      <Form.Control type='text' value={counter} />
      <Button onClick={() => setCounter(counter + 1)}>
        <svg
          width='12'
          height='12'
          viewBox='0 0 12 12'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M5.75 0.5C5.94891 0.5 6.13968 0.579018 6.28033 0.71967C6.42098 0.860322 6.5 1.05109 6.5 1.25V5.5H10.75C10.9489 5.5 11.1397 5.57902 11.2803 5.71967C11.421 5.86032 11.5 6.05109 11.5 6.25C11.5 6.44891 11.421 6.63968 11.2803 6.78033C11.1397 6.92098 10.9489 7 10.75 7H6.5V11.25C6.5 11.4489 6.42098 11.6397 6.28033 11.7803C6.13968 11.921 5.94891 12 5.75 12C5.55109 12 5.36032 11.921 5.21967 11.7803C5.07902 11.6397 5 11.4489 5 11.25V7H0.75C0.551088 7 0.360322 6.92098 0.21967 6.78033C0.0790175 6.63968 0 6.44891 0 6.25C0 6.05109 0.0790175 5.86032 0.21967 5.71967C0.360322 5.57902 0.551088 5.5 0.75 5.5H5V1.25C5 1.05109 5.07902 0.860322 5.21967 0.71967C5.36032 0.579018 5.55109 0.5 5.75 0.5Z'
            fill='#133774'
          />
        </svg>
      </Button>
    </div>
  );
};
export default UseCounter;
