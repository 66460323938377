import { Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/images/red2.png";
import UseButton from "../UseButton/UseButton";
import "./PageNotFound.scss";
const PageNotFound = () => {
  const navigate = useNavigate();

  const goToDashboard = () => {
    navigate("/");
  };
  return (
    <div className='PageNotFound'>
      <Image src={Logo} alt='Rosso' />
      <h2>Ops! Pagina non trovata.</h2>
      <div className='content'>
        <h5>
          Forse hai aperto la finestra sbagliata, perchè non sono riuscito a trovare la
          pagina che cercavi.
        </h5>
        <UseButton
          name=''
          variant='primary'
          labels='Torna alla home'
          onClick={goToDashboard}
        />
      </div>
      <div></div>
    </div>
  );
};
export default PageNotFound;
