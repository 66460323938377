import React from "react";

const CancelledIcon = ({ height, width, fill }) => {
  return (
    <svg
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.464 18.5355L14.9995 15M18.5351 11.4645L14.9995 15M14.9995 15L11.464 11.4645M14.9995 15L18.5351 18.5355'
        stroke='#9696A0'
        strokeWidth='1.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15 27.5C21.9036 27.5 27.5 21.9036 27.5 15C27.5 8.09644 21.9036 2.5 15 2.5C8.09644 2.5 2.5 8.09644 2.5 15C2.5 21.9036 8.09644 27.5 15 27.5Z'
        stroke='#9696A0'
        strokeWidth='1.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default CancelledIcon;
