import { Form } from "react-bootstrap";
import DropdownList from "../ListGroup/DropdownList/DropdownList";
import "./UseDropdownWithSearch.scss";
const UseDropDownWithSearch = ({
  search,
  onSearch,
  list,
  label,
  open,
  setOpen,
  getFunction,
  removeData,
  noData,
  activeClass,
  dynamicKeysOne,
  dynamicKeysTwo,
}) => {
  return (
    <div className='UseDropDownWithSearch'>
      <div
        className={activeClass?.length !== 0 ? "searchLabelActive" : "searchLabel"}
        onClick={() => setOpen(!open)}
      >
        {label}
        {activeClass && (
          <span onClick={removeData}>
            <svg
              width='13'
              height='12'
              viewBox='0 0 13 12'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M7.91 6L12.2 1.71C12.39 1.53 12.5 1.28 12.5 1C12.5 0.45 12.05 0 11.5 0C11.22 0 10.97 0.11 10.79 0.29L6.5 4.59L2.21 0.29C2.03 0.11 1.78 0 1.5 0C0.95 0 0.5 0.45 0.5 1C0.5 1.28 0.61 1.53 0.79 1.71L5.09 6L0.8 10.29C0.61 10.47 0.5 10.72 0.5 11C0.5 11.55 0.95 12 1.5 12C1.78 12 2.03 11.89 2.21 11.71L6.5 7.41L10.79 11.7C10.97 11.89 11.22 12 11.5 12C12.05 12 12.5 11.55 12.5 11C12.5 10.72 12.39 10.47 12.21 10.29L7.91 6Z'
                fill='#9696A0'
              />
            </svg>
          </span>
        )}
      </div>
      <div className='searchableList' style={{ display: open ? "flex" : "none" }}>
        <div className='innerSearch'>
          <div className='container'>
            <div className='searchForm'>
              <Form.Control
                placeholder='Cerca'
                type='text'
                value={search}
                onChange={(e) => onSearch(e.target.value)}
              />
            </div>
            <DropdownList
              dynamicKeysOne={dynamicKeysOne}
              dynamicKeysTwo={dynamicKeysTwo}
              activeClass={activeClass}
              noData={noData}
              search={search}
              list={list}
              getFunction={getFunction}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default UseDropDownWithSearch;
