import { Loader } from "@googlemaps/js-api-loader";
import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Feedback from "react-bootstrap/esm/Feedback";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import UseLoader from "../../Components/Loader/Loader";
import UseTopBar from "../../Components/TopBar/TopBar";
import UseAlert from "../../Components/UseAlert/UseAlert";
import UseButton from "../../Components/UseButton/UseButton";
import LazyBreadCrum from "../BreadCrum/BreadCrum";
import "./AddDonationCenter.scss";
const AddDonationCenter = () => {
  const functions = getFunctions();
  const [data, setData] = useState([]);
  const [edit, setEdit] = useState(false);
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [variant, setVarient] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const [cname, setCname] = useState("");
  const location = useLocation();
  const [site, setSite] = useState(true);
  const [textOnly, settextOnly] = useState(false);
  const apiKey = "AIzaSyACvPjW5jrUbbCOqnC-y-RqIaR5s4E_suU";

  const _loader = new Loader({
    apiKey: apiKey,
    version: "weekly",
    libraries: ["places", "geometry"], // Include the geometry library here
  });

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    watch,
    formState: {
      errors,
      isValid,
      isDirty,
      isSubmitting,
      isSubmitted,
      isSubmitSuccessful,
    },
  } = useForm({
    defaultValues: {
      name: "",
      address: "",
      city: "",
      phoneNumber: "",
      partnership: "",
      contactPersonName: "",
      contactPersonEmail: "",
      contactPersonPhone: "",
      downloadText: "",
      downloadWebsite: "",
    },
    mode: "onSubmit",
  });
  const navigate = useNavigate();
  useEffect(() => {
    _loader.load().then(() => {
      if (document.getElementById("city")) {
        const autocomplete = new window.google.maps.places.Autocomplete(
          document.getElementById("city"),
          { types: ["(cities)"] }, // This restricts the search to cities only.
        );
        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          const cityComponent = place.address_components.find(
            (component) => component.types.includes("locality"), // 'locality' typically corresponds to the city part
          );
          const city = cityComponent ? cityComponent.long_name : "";
          setValue("city", city);
        });
      }
    });
  }, []);
  useEffect(() => {
    getPartnerShip();
    if (location?.state?.locatiosId) {
      getLocation();
    }
  }, []);

  const getPartnerShip = () => {
    const setPartnership = httpsCallable(functions, "getPartnerships");
    setPartnership()
      .then((response) => {
        // const result = response.data.map(({id,agencyName})=>{id,agencyName})
        const result = response.data.map(({ id, agencyName }) => ({ id, agencyName }));
        setData(result);
        // console.log('newArray>>>>',result)
      })
      .catch((error) => console.error("error", error));
  };

  const getLocation = () => {
    // console.log('idddddd',location?.state?.locatiosId)
    const getLocations = httpsCallable(functions, "getDonationCenterById");
    getLocations({ id: location?.state?.locatiosId })
      .then((response) => {
        loadLocation(response.data);
      })
      .catch((error) => console.error("error", error));
  };

  const loadLocation = (data) => {
    // console.log('edit load lcoation2',data[0].city);
    setEdit(true);
    setCname(data.donationCenter.name);
    setValue("id", data.donationCenter.id);
    setValue("name", data.donationCenter.name);
    setValue("city", data.donationCenter.city);
    setValue("phoneNumber", data.phoneNumber);
    setValue("email", data.email);
    setValue("address", data.donationCenter.address);
    setValue("partnership", data.donationCenter.partnership);
    setValue("contactPersonName", data.donationCenter.contactPersonName);
    setValue("contactPersonEmail", data.donationCenter.contactPersonEmail);
    setValue("contactPersonPhone", data.donationCenter.contactPersonPhone);
    setValue("downloadText", data.donationCenter.downloadText);
    setValue("downloadWebsite", data.donationCenter.downloadWebsite);
    setSite(data.donationCenter.downloadType === "website");
    settextOnly(data.donationCenter.downloadType === "text");
  };

  const onSubmitHandler = (data) => {
    data["downloadType"] = textOnly ? "text" : "website";
    setLoader(true);

    if (edit === false) {
      const addLocation = httpsCallable(functions, "setDonationCenter");
      addLocation(data)
        .then((response) => {
          setLoader(false);
          console.log("add location response", response);
          navigate("/Centro-donazione");
        })
        .catch((error) => console.error("error", error))
        .finally(() => setLoader(false));
    }
    if (edit === true) {
      setLoader(true);
      const updateLocation = httpsCallable(functions, "updateDonationCenter");
      updateLocation(data)
        .then((response) => {
          console.log("edit location response", response);
          setLoader(false);
          setShow(true);
          setVarient("success");
          setLoader(false);
          setAlertMsg(response.data.message);
          setTimeout(() => {
            navigate("/Centro-donazione");
          }, 2000);
        })
        .catch((error) => {
          console.error("error", error);
          setShow(true);
          setVarient("danger");
          setAlertMsg(error.data.message);
        })
        .finally(() => setLoader(false));
    }
  };

  return (
    <div className='AddDonationCenter' data-testid='AddDonationCenter'>
      <UseTopBar back='Centro-donazione' />
      <LazyBreadCrum />
      {/* {(location?.state?.centerName && location?.state?.locatiosId) &&
        <h2>{location.state.centerName || '-'}</h2>
    } */}
      {cname.length > 0 && <h2>{cname}</h2>}
      <section className='addDonationCenter'>
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          {location.pathname.slice(1) == "Centro-donazione/Scheda-centro-donazioni" && (
            <h2>Crea nuovo centro donazioni</h2>
          )}
          <Row>
            <Col xs={12} sm={6} md={6} lg={6}>
              <Form.Group className='mb-3' controlId='formBasicEmail'>
                <Form.Label>Nome centro</Form.Label>
                <Form.Control
                  type='text'
                  name='name'
                  {...register("name", { required: "campo obbligatorio" })}
                  style={{ borderColor: errors?.name?.message && "red" }}
                />
                {errors?.name?.message && (
                  <Feedback className='d-block' type='invalid'>
                    {errors?.name?.message}
                  </Feedback>
                )}
              </Form.Group>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6}>
              <Form.Group className='mb-3' controlId='formBasicEmail'>
                <Form.Label>Referente</Form.Label>
                <Form.Control
                  type='text'
                  name='contactPersonName'
                  {...register("contactPersonName")}
                  style={{ borderColor: errors?.contactPersonName?.message && "red" }}
                />
                {errors?.contactPersonName?.message && (
                  <Feedback className='d-block' type='invalid'>
                    {errors?.contactPersonName?.message}
                  </Feedback>
                )}
              </Form.Group>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6}>
              <Form.Group className='mb-3' controlId='formBasicEmail'>
                <Form.Label>Indirizzo</Form.Label>
                <Form.Control
                  type='text'
                  name='address'
                  {...register("address", { required: "campo obbligatorio" })}
                  style={{ borderColor: errors?.address?.message && "red" }}
                />
                {errors?.address?.message && (
                  <Feedback className='d-block' type='invalid'>
                    {errors?.address?.message}
                  </Feedback>
                )}
              </Form.Group>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6}>
              <Form.Group className='mb-3' controlId='formBasicEmail'>
                <Form.Label>Email referente</Form.Label>
                <Form.Control
                  type='email'
                  name='contactPersonEmail'
                  {...register("contactPersonEmail")}
                  style={{ borderColor: errors?.contactPersonEmail?.message && "red" }}
                />
                {errors?.contactPersonEmail?.message && (
                  <Feedback className='d-block' type='invalid'>
                    {errors?.contactPersonEmail?.message}
                  </Feedback>
                )}
              </Form.Group>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6}>
              <Form.Group className='mb-3' controlId='formBasicEmail'>
                <Form.Label>Città</Form.Label>
                <Form.Control
                  id='city'
                  type='text'
                  name='city'
                  {...register("city", { required: "campo obbligatorio" })}
                  style={{ borderColor: errors?.city?.message && "red" }}
                />
                {errors?.city?.message && (
                  <Feedback className='d-block' type='invalid'>
                    {errors?.city?.message}
                  </Feedback>
                )}
              </Form.Group>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6}>
              <Form.Group className='mb-3' controlId='formBasicEmail'>
                <Form.Label>Numero di telefono referente</Form.Label>
                <Form.Control
                  type='text'
                  name='contactPersonPhone'
                  {...register("contactPersonPhone")}
                  style={{ borderColor: errors?.contactPersonPhone?.message && "red" }}
                />
                {errors?.contactPersonPhone?.message && (
                  <Feedback className='d-block' type='invalid'>
                    {errors?.contactPersonPhone?.message}
                  </Feedback>
                )}
              </Form.Group>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6}>
              <Form.Group className='mb-3' controlId='formBasicEmail'>
                <Form.Label>Convenzione</Form.Label>
                {/* <Form.Control type="text" name="partnership" {...register('partnership')} />                                       */}
                <Form.Select
                  name='partnership'
                  {...register("partnership")}
                  aria-label='Default select example'
                  style={{ borderColor: errors?.partnership?.message && "red" }}
                >
                  <option value=''>Nessuna convenzione</option>
                  {data.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.agencyName}
                      </option>
                    );
                  })}
                </Form.Select>
                {errors?.partnership?.message && (
                  <Feedback className='d-block' type='invalid'>
                    {errors?.partnership?.message}
                  </Feedback>
                )}
              </Form.Group>
            </Col>
            <Col xs={6}></Col>
            <Col xs={6}>
              <Form.Group className='mb-3' controlId='formBasicEmail'>
                <div className='analysis'>
                  <p>Seleziona l'opzione "Scarica le analisi"</p>
                  <div className='analysisGroup'>
                    <Form.Check
                      type='radio'
                      name='analysis'
                      checked={site}
                      onChange={(e) => {
                        if (e.currentTarget.checked) {
                          setSite(true);
                          settextOnly(false);
                          clearErrors("downloadText");
                        }
                      }}
                    />
                    <Form.Control
                      disabled={textOnly}
                      type='text'
                      name='downloadWebsite'
                      {...register("downloadWebsite", {
                        required: site && !textOnly ? "campo obbligatorio" : false,
                      })}
                      placeholder='www.example.com'
                      style={{ borderColor: errors?.downloadWebsite?.message && "red" }}
                    />
                    {/* {errors?.downloadWebsite?.message && (
                      <Feedback className='d-block' type='invalid'>
                        {errors?.downloadWebsite?.message}
                      </Feedback>
                    )} */}
                  </div>
                  <div className='analysisGroup'>
                    <Form.Check
                      type='radio'
                      name='analysis'
                      checked={textOnly}
                      onChange={(e) => {
                        if (e.currentTarget.checked) {
                          setSite(false);
                          settextOnly(true);
                          clearErrors("downloadWebsite");
                        }
                      }}
                    />
                    <Form.Control
                      disabled={site}
                      as='textarea'
                      name='downloadText'
                      {...register("downloadText", {
                        required: textOnly && !site ? "campo obbligatorio" : false,
                      })}
                      placeholder='Inserisci testo'
                      style={{ borderColor: errors?.downloadText?.message && "red" }}
                    />
                  </div>
                </div>
                {/* {errors?.downloadText?.message && (
                  <Feedback className='d-block' type='invalid'>
                    {errors?.downloadText?.message}
                  </Feedback>
                )} */}
              </Form.Group>
            </Col>

            {/* <Col xs={12} sm={6} md={6} lg={6}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>contactPersonName</Form.Label>
                        <Form.Control type="text" name="contactPersonName" {...register('contactPersonName')} />
                    </Form.Group>
              </Col>
              <Col xs={12} sm={6} md={6} lg={6}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>contactPersonEmail</Form.Label>
                        <Form.Control type="email" name="contactPersonEmail" {...register('contactPersonEmail')} />
                    </Form.Group>
              </Col>
              <Col xs={12} sm={6} md={6} lg={6}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>contactPersonPhone</Form.Label>
                        <Form.Control type="text" name="contactPersonPhone" {...register('contactPersonPhone')} />
                    </Form.Group>
              </Col> */}
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className='mb-4 d-flex justify-content-end'
            >
              <UseButton
                type='submit'
                name='adminBtnPrimary'
                variant='primary'
                labels={edit ? "Salva modifiche" : "Crea centro di donazione"}
              />
            </Col>
          </Row>
        </form>
      </section>
      {loader == true && <UseLoader />}
      <UseAlert show={show} setShow={setShow} variant={variant} alertMsg={alertMsg} />
    </div>
  );
};

AddDonationCenter.propTypes = {};

AddDonationCenter.defaultProps = {};

export default AddDonationCenter;
