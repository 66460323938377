import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import CardSliderFour from "../../Components/CarouselSlider/CardSliderFour/CardSliderFour";
import CarouselSliderTwo from "../../Components/CarouselSlider/CarouselSliderTwo/CarouselSliderTwo";
import "./Reservations.scss";
const Reservations = () => {
  const [upcomingReservations, setUpcomingReservations] = useState([]);
  const [pastReservations, setPastReservations] = useState([]);
  const functions = getFunctions();
  useEffect(() => {
    loadData();
    loadPastData();
  }, []);

  const loadData = () => {
    try {
      const getUpcomingSlotDetails = httpsCallable(functions, "getUpcomingReservations");
      getUpcomingSlotDetails()
        .then((response) => {
          setUpcomingReservations(response.data);
        })
        .catch((error) => console.error("error", error));
    } catch (error) {
      console.error("error", error);
    }
  };
  const loadPastData = () => {
    try {
      const getPastSlotDetails = httpsCallable(functions, "getPastReservations");
      getPastSlotDetails()
        .then((response) => {
          setPastReservations(response.data);
        })
        .catch((error) => console.error("error", error));
    } catch (error) {
      console.error("error", error);
    }
  };

  return (
    <div className='Reservations' data-testid='Reservations'>
      <span id='Diary'></span>
      <Container>
        <h2>Le tue prenotazioni</h2>
        <Tabs id='uncontrolled-tab-example' className='mb-3'>
          <Tab eventKey='In arrivo' title='In arrivo'>
            <CarouselSliderTwo events={upcomingReservations} />
          </Tab>
          <Tab eventKey='Passate' title='Passate'>
            <CardSliderFour events={pastReservations} />
          </Tab>
        </Tabs>
      </Container>
    </div>
  );
};

Reservations.propTypes = {};

Reservations.defaultProps = {};

export default Reservations;
