import React from "react";
import LazyAdminSidebar from "../AdminMenuBar/AdminMenuBar";
import "./AdminSidebar.scss";
const AdminSidebar = () => {
  return (
    <div className='AdminSidebar' data-testid='AdminSidebar'>
      {/* <h2>Rosso</h2> */}
      <LazyAdminSidebar />
    </div>
  );
};

AdminSidebar.propTypes = {};

AdminSidebar.defaultProps = {};

export default AdminSidebar;
