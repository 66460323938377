import React from "react";
import { Card, Col, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import video2 from "../../../assets/images/video2.jpg";
import "./VideoCard.scss";
const VideoCard = () => {
  return (
    <div className='videoCard'>
      <Link to={"#"}>
        <Card>
          <Card.Body>
            <Row>
              <Col xs={3} sm={3} md={3} lg={3}>
                <Image src={video2} alt='image' className='small-image' />
              </Col>
              <Col xs={9} sm={9} md={9} lg={9}>
                <h2>video heading</h2>
                <p>
                  In publishing and graphic design, Lorem ipsum is a placeholder text
                  commonly used to demo.
                </p>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Link>
    </div>
  );
};
export default VideoCard;
