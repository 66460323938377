import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
const GoogleMap = ({
  apiKey,
  lat,
  lng,
  radius,
  locations,
  onLocationsFound,
  loader,
  zoom,
}) => {
  const mapRef = useRef(null);
  const navigate = useNavigate();
  useEffect(() => {
    loader.load().then(() => {
      if (!lat || !lng || !radius) {
        console.error("Latitude, longitude, or radius props are missing or invalid.");
        return;
      }

      const center = { lat, lng };
      const map = new window.google.maps.Map(mapRef.current, {
        center: center,
        minZoom: 13,
      });

      const circle = new window.google.maps.Circle({
        center: center,
        radius: radius * 1000,
      });

      map.fitBounds(circle.getBounds());
      const geocoder = new window.google.maps.Geocoder();
      const geocodePromises = locations.map(
        (address) =>
          new Promise((resolve, reject) => {
            geocoder.geocode({ address: address.location }, (results, status) => {
              if (status === "OK") {
                const location = results[0].geometry.location;
                if (
                  window.google.maps.geometry.spherical.computeDistanceBetween(
                    location,
                    circle.getCenter(),
                  ) <= circle.getRadius()
                ) {
                  let marker = new window.google.maps.Marker({
                    map: map,
                    position: location,
                    title: address.location,
                    icon: {
                      path: "M29.4325 29.5574L17.5 41.4899L5.56751 29.5574C3.20752 27.1973 1.60035 24.1905 0.949241 20.9171C0.298132 17.6436 0.632325 14.2507 1.90956 11.1672C3.18679 8.08368 5.34971 5.44818 8.12478 3.59394C10.8999 1.7397 14.1625 0.75 17.5 0.75C20.8376 0.75 24.1002 1.7397 26.8752 3.59394C29.6503 5.44818 31.8132 8.08368 33.0905 11.1672C34.3677 14.2507 34.7019 17.6436 34.0508 20.9171C33.3997 24.1905 31.7925 27.1973 29.4325 29.5574ZM17.5 25.1249C19.4891 25.1249 21.3968 24.3347 22.8033 22.9282C24.2098 21.5216 25 19.614 25 17.6249C25 15.6357 24.2098 13.7281 22.8033 12.3216C21.3968 10.915 19.4891 10.1249 17.5 10.1249C15.5109 10.1249 13.6032 10.915 12.1967 12.3216C10.7902 13.7281 10 15.6357 10 17.6249C10 19.614 10.7902 21.5216 12.1967 22.9282C13.6032 24.3347 15.5109 25.1249 17.5 25.1249ZM17.5 21.3749C16.5054 21.3749 15.5516 20.9798 14.8484 20.2765C14.1451 19.5732 13.75 18.6194 13.75 17.6249C13.75 16.6303 14.1451 15.6765 14.8484 14.9732C15.5516 14.2699 16.5054 13.8749 17.5 13.8749C18.4946 13.8749 19.4484 14.2699 20.1517 14.9732C20.8549 15.6765 21.25 16.6303 21.25 17.6249C21.25 18.6194 20.8549 19.5732 20.1517 20.2765C19.4484 20.9798 18.4946 21.3749 17.5 21.3749Z",
                      fillColor: "#E31A42",
                      fillOpacity: 0.8,
                      scale: 1,
                      strokeColor: "black",
                      strokeWeight: 2,
                      anchor: new window.google.maps.Point(12, 24),
                    },
                  });
                  let closeInfoWindowTimeout = null;

                  if (address.name) {
                    window.google.maps.event.addListener(marker, "click", function () {
                      if (!marker.infoWindowOpened) {
                        let infoWindow = new window.google.maps.InfoWindow({
                          content: `<div id="infoWindowContent" style="background-color: #BA0F31; cursor: pointer;z-index: 9999;border-radius: 7px;color: #fff; padding: 10px 40px">${address.name}</div>`,
                          ariaLabel: "Uluru",
                        });

                        infoWindow.open({
                          anchor: marker,
                          map,
                          shouldFocus: false,
                        });

                        marker.infoWindowOpened = true;

                        window.google.maps.event.addListenerOnce(
                          infoWindow,
                          "domready",
                          function () {
                            document
                              .getElementById("infoWindowContent")
                              .addEventListener("click", function () {
                                navigate("/book", {
                                  state: {
                                    centerId: address.id,
                                    location: {
                                      lat: location.lat(),
                                      lng: location.lng(),
                                    },
                                  },
                                });
                              });
                          },
                        );

                        window.google.maps.event.addListener(
                          infoWindow,
                          "closeclick",
                          function () {
                            marker.infoWindowOpened = false;
                          },
                        );
                      }
                    });
                  }
                  resolve({
                    address: address.location, // The original address location string
                    lat: location.lat(), // The latitude from the geocode result
                    lng: location.lng(), // The longitude from the geocode result
                  });
                } else {
                  resolve(null);
                }
              } else {
                resolve(null);
              }
            });
          }),
      );

      Promise.all(geocodePromises)
        .then((results) => {
          // Filter out null values where address was not within radius or geocode failed
          const foundLocations = results.filter((result) => result !== null);

          // Map the results to include both the address location and the geometry.location
          const locationsWithCoords = foundLocations.map((location) => ({
            addressLocation: location,
            coords: { lat: location.lat, lng: location.lng }, // Assuming location here is the resolved object with lat and lng properties
          }));

          // Call the callback with the updated locations
          onLocationsFound && onLocationsFound(locationsWithCoords);
        })
        .catch((error) => console.error(error));
    });
  }, [apiKey, lat, lng, radius]);

  return <div ref={mapRef} style={{ height: "100%", width: "100%" }} />;
};

export default GoogleMap;
