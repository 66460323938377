import React, { useEffect, useState } from "react";
import { Button, Form, ListGroup } from "react-bootstrap";
import InfoIcon from "./InfoIcon";
import "./SearchFilter.scss";
const SearchFilter = ({
  open,
  setOpen,
  data,
  searchFilter,
  updateFilter,
  getValue,
  onChangeFilterValue,
  placeholder,
  type,
  onConfirm,
  selectedValue,
}) => {
  const [localData, setLocalData] = useState(data);

  useEffect(() => {
    if (localData.length == 0) {
      setLocalData(data);
    }
  }, [data]);

  const getFlatTitle = () => {
    let title = "";
    selectedValue.map((x) => {
      data.map((d) => {
        if (x == d.value) {
          console.log(d.label);
          title += d.label + "\n";
        }
      });
    });
    return title;
  };

  const searchFilters = (value, type) => {
    if (!value) {
      setLocalData(data);
    } else {
      switch (type) {
        case "radio":
          setLocalData((prev) => {
            return {
              ...prev,
              options: prev.options.map((p) => {
                return {
                  ...p,
                  visible: p.label.toLowerCase().includes(value.toLowerCase()),
                };
              }),
            };
          });
          break;
        case "check":
          setLocalData((prev) => {
            return prev.map((p) => {
              return {
                ...p,
                visible: p.label.toLowerCase().includes(value.toLowerCase()),
              };
            });
          });
          break;
      }
    }
  };

  switch (type) {
    case "radio":
      return (
        <div className='searchFilter'>
          <div className='showValues' onClick={() => setOpen(!open)}>
            {localData.options.filter((x) => x.value == selectedValue)[0]?.label ||
              "Tutte"}
          </div>

          <div className='listOfValues' style={{ display: open ? "flex" : "none" }}>
            <input
              type='text'
              className='search'
              placeholder={placeholder}
              onChange={(e) => searchFilters(e.currentTarget.value, "radio")}
            />

            <ListGroup>
              {localData.options.map((option, index) => {
                return (
                  <div
                    key={index}
                    className={`form-check radio ${
                      option.visible === false ? "hidden" : ""
                    } ${selectedValue == option.value ? "selected" : ""}`}
                  >
                    <input
                      checked={selectedValue == option.value}
                      className='form-check-input'
                      type='radio'
                      value={option.value}
                      name={data.name}
                      id={option.value}
                      onChange={(e) => {
                        onChangeFilterValue(e.currentTarget.value);
                      }}
                    />
                    <label className='form-check-label' htmlFor={option.value}>
                      {option.label}
                    </label>
                  </div>
                );
              })}
            </ListGroup>
            <Button onClick={() => onConfirm()}>
              <svg
                width='27'
                height='20'
                viewBox='0 0 27 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M24.6992 0.400391C24.2512 0.400391 23.8512 0.576391 23.5632 0.864391L8.69922 15.7444L3.43522 10.4804C3.14722 10.1764 2.74722 10.0004 2.29922 10.0004C1.41922 10.0004 0.699219 10.7204 0.699219 11.6004C0.699219 12.0484 0.875219 12.4484 1.16322 12.7364L7.56322 19.1364C7.85122 19.4244 8.25122 19.6004 8.69922 19.6004C9.14722 19.6004 9.54722 19.4244 9.83522 19.1364L25.8352 3.13639C26.1232 2.84839 26.2992 2.44839 26.2992 2.00039C26.2992 1.12039 25.5792 0.400391 24.6992 0.400391Z'
                  fill='#2264D1'
                />
              </svg>
              Conferma
            </Button>
          </div>
        </div>
      );
    default:
      return (
        <div className='searchFilter'>
          <div className='showValues' onClick={() => setOpen(!open)}>
            {selectedValue !== null && selectedValue.length > 0 ? (
              <span title={getFlatTitle()}>
                Selezione
                <InfoIcon height={15} width={15} fill={"#2979FF"} />
              </span>
            ) : (
              " Tutte"
            )}
          </div>
          <div className='listOfValues' style={{ display: open ? "flex" : "none" }}>
            <input
              type='text'
              className='search'
              placeholder={placeholder}
              onChange={(e) => searchFilters(e.currentTarget.value, "check")}
            />
            <ListGroup>
              {localData.map((item, index) => {
                return (
                  <ListGroup.Item
                    key={index}
                    className={`check  ${item.visible === false ? "hidden" : ""} ${
                      selectedValue.includes(item.value) ? "active" : "pp"
                    }`}
                  >
                    <Form.Check
                      checked={selectedValue.includes(item.value)}
                      type='checkbox'
                      value={item.value}
                      onChange={(e) => [onChangeFilterValue(e.currentTarget.value)]}
                      key={item.value}
                      id={item.value}
                      label={item.label}
                    />
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
            <Button onClick={() => onConfirm()}>
              <svg
                width='27'
                height='20'
                viewBox='0 0 27 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M24.6992 0.400391C24.2512 0.400391 23.8512 0.576391 23.5632 0.864391L8.69922 15.7444L3.43522 10.4804C3.14722 10.1764 2.74722 10.0004 2.29922 10.0004C1.41922 10.0004 0.699219 10.7204 0.699219 11.6004C0.699219 12.0484 0.875219 12.4484 1.16322 12.7364L7.56322 19.1364C7.85122 19.4244 8.25122 19.6004 8.69922 19.6004C9.14722 19.6004 9.54722 19.4244 9.83522 19.1364L25.8352 3.13639C26.1232 2.84839 26.2992 2.44839 26.2992 2.00039C26.2992 1.12039 25.5792 0.400391 24.6992 0.400391Z'
                  fill='#2264D1'
                />
              </svg>
              Conferma
            </Button>
          </div>
        </div>
      );
  }
};
export default SearchFilter;
