import React from "react";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import UseButton from "../../UseButton/UseButton";
import "./MapCard.scss";
const MapCard = ({ onClick, data, coords }) => {
  console.log(data);
  const navigate = useNavigate();
  const bookDonation = () => {
    navigate(`/book?address=${data.address}&lat=${coords.lat}&lng=${coords.lng}`);
  };
  return (
    <div className='MapCard'>
      <Card>
        <Card.Body>
          <h5 className='card-title'>{data.name}</h5>
          <Card.Text>
            <svg
              width='26'
              height='26'
              viewBox='0 0 26 26'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M13 8V13L16.75 16.75L13 8ZM24.25 13C24.25 14.4774 23.959 15.9403 23.3936 17.3052C22.8283 18.6701 21.9996 19.9103 20.955 20.955C19.9103 21.9996 18.6701 22.8283 17.3052 23.3936C15.9403 23.959 14.4774 24.25 13 24.25C11.5226 24.25 10.0597 23.959 8.69481 23.3936C7.3299 22.8283 6.08971 21.9996 5.04505 20.955C4.00039 19.9103 3.17172 18.6701 2.60636 17.3052C2.04099 15.9403 1.75 14.4774 1.75 13C1.75 10.0163 2.93526 7.15483 5.04505 5.04505C7.15483 2.93526 10.0163 1.75 13 1.75C15.9837 1.75 18.8452 2.93526 20.955 5.04505C23.0647 7.15483 24.25 10.0163 24.25 13Z'
                stroke='#292A31'
                strokeWidth='1.875'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>{" "}
            10:00{data.startTime} - 20:00{data.endTime}
          </Card.Text>
          <Card.Text className='mt-4'>
            <svg
              width='30'
              height='30'
              viewBox='0 0 30 30'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M25 12.5C25 18.0228 15 27.5 15 27.5C15 27.5 5 18.0228 5 12.5C5 6.97715 9.47715 2.5 15 2.5C20.5228 2.5 25 6.97715 25 12.5Z'
                stroke='#292A31'
                strokeWidth='2'
              />
              <path
                d='M15 13.75C15.6904 13.75 16.25 13.1904 16.25 12.5C16.25 11.8096 15.6904 11.25 15 11.25C14.3096 11.25 13.75 11.8096 13.75 12.5C13.75 13.1904 14.3096 13.75 15 13.75Z'
                fill='#292A31'
                stroke='#292A31'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>{" "}
            {data.address}
          </Card.Text>
          {/* <span>150m</span> */}
          <Card.Footer>
            <UseButton
              onClick={() => onClick({ ...data, coords })}
              name='adminBtnPrimary'
              variant='secondary'
              labels='Prenota qui'
            />
          </Card.Footer>
        </Card.Body>
      </Card>
    </div>
  );
};
export default MapCard;
