import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CardFive from "../../Components/Card/CardFive/CardFive";
import UseTopBar from "../../Components/TopBar/TopBar";
import UseButton from "../../Components/UseButton/UseButton";
import "./AlertManagement.scss";
const AlertManagement = () => {
  const navigate = useNavigate();
  const functions = getFunctions();
  const notice = () => {
    navigate("/Gestione-avvisi/Crea-avviso");
  };

  const [alerts, setAlerts] = useState([]);

  useEffect(() => {
    try {
      const getAlerts = httpsCallable(functions, "getAlerts");
      getAlerts()
        .then((response) => {
          const result = response.data;
          setAlerts(result);
        })
        .catch((error) => console.error("error", error));
    } catch (error) {
      console.error("error", error);
    }
  }, []);

  return (
    <div className='AlertManagement' data-testid='AlertManagement'>
      <UseTopBar />
      <Row>
        <Col xs={12} sm={4} md={6} lg={6}>
          <h2>Tutti gli avvisi</h2>
        </Col>
        <Col xs={12} sm={4} md={6} lg={6} className='d-flex justify-content-end'>
          <UseButton
            name='adminBtnPrimary'
            variant='primary'
            labels='Crea avviso'
            onClick={notice}
          />
        </Col>
      </Row>
      <Row>
        {alerts &&
          alerts.map((_alert) => {
            return (
              <Col xs={12} sm={3} md={3} lg={3}>
                <CardFive _alert={_alert} />
              </Col>
            );
          })}
      </Row>
    </div>
  );
};

AlertManagement.propTypes = {};

AlertManagement.defaultProps = {};

export default AlertManagement;
