import { signOut } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Image, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import UseLoader from "../../Components/Loader/Loader";
import UseAlert from "../../Components/UseAlert/UseAlert";
import { auth } from "../../Config/firebase";
import Logo from "../../assets/svg/logo.svg";
import "./SetPwd.scss";
const SetPwd = () => {
  const [pwd1, setPwd1] = useState(false);
  const [pwd2, setPwd2] = useState(false);
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [variant, setVarient] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const [searchParams] = useSearchParams();
  const [afterLogin, setAfterLogin] = useState(false);
  const navigate = useNavigate();
  const functions = getFunctions();
  const location = useLocation();

  // const [email, setEmail] = useState(searchParams.get('email'))
  // const [id, setId] = useState(searchParams.get('id'))
  // const [token, setToken] = useState(searchParams.get('token'))
  // const { email, id, token } = useParams();
  // console.log('searchParams',email,'-',id,'-',token)

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: "",
      repassword: "",
      email: "",
      id: "",
      token: "",
    },
    mode: "onSubmit",
  });
  let pwd = watch("password");

  useEffect(() => {
    setValue("email", searchParams.get("email"));
    setValue("id", searchParams.get("id"));
    setValue("token", searchParams.get("token"));

    if (
      location?.state?.settingsEmailId &&
      location?.state?.settingsUserId &&
      location?.state?.settingsToken
    ) {
      setValue("email", location?.state?.settingsEmailId);
      setValue("id", location?.state?.settingsUserId);
      setValue("token", location?.state?.settingsToken);
      setAfterLogin(true);
    }
  }, []);
  // console.log('e,mail iddddddddddd',email)
  const onSubmit = (data) => {
    if (afterLogin == false) {
      try {
        setLoader(true);
        const changePassword = httpsCallable(functions, "changePassword");
        changePassword(data).then((response) => {
          setLoader(false);
          setShow(true);
          setVarient("success");
          setAlertMsg(response.data.message);
          setTimeout(() => {
            navigate("/login");
          }, 2000);
          // console.log('reset password response',response)
        });
      } catch (error) {
        console.error("error", error);
        setLoader(false);
        setShow(true);
        setVarient("danger");
        setAlertMsg(error.message);
      }
    }

    if (afterLogin == true) {
      console.log("load data from navigate");
      try {
        setLoader(true);
        const changePassword = httpsCallable(functions, "changePassword");
        changePassword(data)
          .then((response) => {
            setLoader(false);
            setShow(true);
            setVarient("success");
            setAlertMsg(response.data.message);
            // signOut(auth);
            signOut(auth)
              .then(() => {
                localStorage.clear();
                setTimeout(() => {
                  navigate("/login");
                }, 2000);
              })
              .catch((error) => {
                setLoader(false);
                setShow(true);
                setVarient("danger");
                setAlertMsg(error.message);
              });

            // console.log('reset password response',response)
          })
          .catch((error) => {
            setLoader(false);
            setShow(true);
            setVarient("danger");
            setAlertMsg(error.message);
          });
      } catch (error) {
        console.error("error", error);
      }
    }
  };

  return (
    <div className='SetPwd' data-testid='SetPwd'>
      <div className='innerLogin'>
        <Image src={Logo} alt='Rosso' className='logo' />
        {/* <h3>Hai dimenticato la tua password?</h3> */}
        <div className='forgetCard'>
          {/* <h4>Ti invieremo una mail per cambiare la tua password</h4> */}
          <Form onSubmit={handleSubmit(onSubmit)}>
            {/* <input type='hidden' name="email" {...register('email')} />   */}
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} className='mb-4'>
                <Form.Group className='position-relative'>
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type={pwd1 ? "text" : "password"}
                    name='password'
                    {...register("password", {
                      required: "La password inserita è errata",
                      minLength: {
                        value: 8,
                        message: "La password deve contenere almeno 8 caratteri",
                      },
                    })}
                  />
                  <span
                    className='closeEmail'
                    style={{
                      display: watch("password").length > 0 ? "inline-block" : "none",
                    }}
                    onClick={() => setPwd1(!pwd1)}
                  >
                    <svg
                      width='21'
                      height='14'
                      viewBox='0 0 21 14'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M19.8211 6.74687C19.7937 6.68516 19.132 5.21719 17.6609 3.74609C15.7008 1.78594 13.225 0.75 10.5 0.75C7.77499 0.75 5.29921 1.78594 3.33905 3.74609C1.86796 5.21719 1.20312 6.6875 1.1789 6.74687C1.14336 6.82681 1.125 6.91331 1.125 7.00078C1.125 7.08825 1.14336 7.17476 1.1789 7.25469C1.20624 7.31641 1.86796 8.78359 3.33905 10.2547C5.29921 12.2141 7.77499 13.25 10.5 13.25C13.225 13.25 15.7008 12.2141 17.6609 10.2547C19.132 8.78359 19.7937 7.31641 19.8211 7.25469C19.8566 7.17476 19.875 7.08825 19.875 7.00078C19.875 6.91331 19.8566 6.82681 19.8211 6.74687ZM10.5 12C8.0953 12 5.99452 11.1258 4.25546 9.40234C3.5419 8.69273 2.93483 7.88356 2.45312 7C2.9347 6.11636 3.54179 5.30717 4.25546 4.59766C5.99452 2.87422 8.0953 2 10.5 2C12.9047 2 15.0055 2.87422 16.7445 4.59766C17.4595 5.307 18.0679 6.11619 18.5508 7C17.9875 8.05156 15.5336 12 10.5 12ZM10.5 3.25C9.75831 3.25 9.03329 3.46993 8.4166 3.88199C7.79992 4.29404 7.31927 4.87971 7.03544 5.56494C6.75162 6.25016 6.67735 7.00416 6.82205 7.73159C6.96674 8.45902 7.32389 9.1272 7.84834 9.65165C8.37279 10.1761 9.04097 10.5332 9.7684 10.6779C10.4958 10.8226 11.2498 10.7484 11.9351 10.4645C12.6203 10.1807 13.2059 9.70007 13.618 9.08339C14.0301 8.4667 14.25 7.74168 14.25 7C14.249 6.00575 13.8535 5.05253 13.1505 4.34949C12.4475 3.64645 11.4942 3.25103 10.5 3.25ZM10.5 9.5C10.0055 9.5 9.52219 9.35338 9.11107 9.07867C8.69994 8.80397 8.37951 8.41352 8.19029 7.95671C8.00107 7.49989 7.95157 6.99723 8.04803 6.51227C8.14449 6.02732 8.38259 5.58186 8.73222 5.23223C9.08186 4.8826 9.52731 4.6445 10.0123 4.54804C10.4972 4.45157 10.9999 4.50108 11.4567 4.6903C11.9135 4.87952 12.304 5.19995 12.5787 5.61107C12.8534 6.0222 13 6.50555 13 7C13 7.66304 12.7366 8.29892 12.2678 8.76777C11.7989 9.23661 11.163 9.5 10.5 9.5Z'
                        fill='#2264D1'
                        stroke='#2264D1'
                        strokeWidth='0.637037'
                      />
                    </svg>
                  </span>
                </Form.Group>
                {errors.password && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "14px",
                      position: "relative",
                      top: "5px",
                    }}
                  >
                    {errors.password.message}
                  </p>
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <Form.Group className='position-relative'>
                  <Form.Label>Ripeti la password</Form.Label>
                  <Form.Control
                    type={pwd2 ? "text" : "password"}
                    name='repassword'
                    {...register("repassword", {
                      required: "La password inserita è errata",
                      validate: (value) =>
                        value === pwd || "La password inserita non corrisponde",
                    })}
                  />
                  <span
                    className='closeEmail'
                    style={{
                      display: watch("repassword").length > 0 ? "inline-block" : "none",
                    }}
                    onClick={() => setPwd2(!pwd2)}
                  >
                    <svg
                      width='21'
                      height='14'
                      viewBox='0 0 21 14'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M19.8211 6.74687C19.7937 6.68516 19.132 5.21719 17.6609 3.74609C15.7008 1.78594 13.225 0.75 10.5 0.75C7.77499 0.75 5.29921 1.78594 3.33905 3.74609C1.86796 5.21719 1.20312 6.6875 1.1789 6.74687C1.14336 6.82681 1.125 6.91331 1.125 7.00078C1.125 7.08825 1.14336 7.17476 1.1789 7.25469C1.20624 7.31641 1.86796 8.78359 3.33905 10.2547C5.29921 12.2141 7.77499 13.25 10.5 13.25C13.225 13.25 15.7008 12.2141 17.6609 10.2547C19.132 8.78359 19.7937 7.31641 19.8211 7.25469C19.8566 7.17476 19.875 7.08825 19.875 7.00078C19.875 6.91331 19.8566 6.82681 19.8211 6.74687ZM10.5 12C8.0953 12 5.99452 11.1258 4.25546 9.40234C3.5419 8.69273 2.93483 7.88356 2.45312 7C2.9347 6.11636 3.54179 5.30717 4.25546 4.59766C5.99452 2.87422 8.0953 2 10.5 2C12.9047 2 15.0055 2.87422 16.7445 4.59766C17.4595 5.307 18.0679 6.11619 18.5508 7C17.9875 8.05156 15.5336 12 10.5 12ZM10.5 3.25C9.75831 3.25 9.03329 3.46993 8.4166 3.88199C7.79992 4.29404 7.31927 4.87971 7.03544 5.56494C6.75162 6.25016 6.67735 7.00416 6.82205 7.73159C6.96674 8.45902 7.32389 9.1272 7.84834 9.65165C8.37279 10.1761 9.04097 10.5332 9.7684 10.6779C10.4958 10.8226 11.2498 10.7484 11.9351 10.4645C12.6203 10.1807 13.2059 9.70007 13.618 9.08339C14.0301 8.4667 14.25 7.74168 14.25 7C14.249 6.00575 13.8535 5.05253 13.1505 4.34949C12.4475 3.64645 11.4942 3.25103 10.5 3.25ZM10.5 9.5C10.0055 9.5 9.52219 9.35338 9.11107 9.07867C8.69994 8.80397 8.37951 8.41352 8.19029 7.95671C8.00107 7.49989 7.95157 6.99723 8.04803 6.51227C8.14449 6.02732 8.38259 5.58186 8.73222 5.23223C9.08186 4.8826 9.52731 4.6445 10.0123 4.54804C10.4972 4.45157 10.9999 4.50108 11.4567 4.6903C11.9135 4.87952 12.304 5.19995 12.5787 5.61107C12.8534 6.0222 13 6.50555 13 7C13 7.66304 12.7366 8.29892 12.2678 8.76777C11.7989 9.23661 11.163 9.5 10.5 9.5Z'
                        fill='#2264D1'
                        stroke='#2264D1'
                        strokeWidth='0.637037'
                      />
                    </svg>
                  </span>
                </Form.Group>
                {errors.repassword && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "14px",
                      position: "relative",
                      top: "5px",
                    }}
                  >
                    {errors.repassword.message}
                  </p>
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <Button variant='primary' type='submit'>
                  Salva nuova password
                </Button>
              </Col>
            </Row>
            {/* <Button variant="outline-primary" className='oulineBnt'>Torna indietro</Button> */}
          </Form>
        </div>
        <div></div>
      </div>
      <UseAlert show={show} setShow={setShow} variant={variant} alertMsg={alertMsg} />
      {loader && <UseLoader />}
    </div>
  );
};

SetPwd.propTypes = {};

SetPwd.defaultProps = {};

export default SetPwd;
