import React from "react";
import { ListGroup } from "react-bootstrap";
import "./DropdownList.scss";
const DropdownList = ({
  list,
  getFunction,
  search,
  noData,
  activeClass,
  dynamicKeysOne,
  dynamicKeysTwo,
}) => {
  const filteredList = list?.filter(
    (item) =>
      search?.toLowerCase() === "" ||
      item?.[dynamicKeysOne]?.toLowerCase()?.includes(search),
  );
  // console.log('active class',dynamicKeysOne,dynamicKeysTwo);
  // console.log('activeClass',activeClass)
  return (
    <div className='dropdownList'>
      <ListGroup>
        {filteredList.length > 0 ? (
          filteredList.map((data, index) => (
            <ListGroup.Item
              className={
                activeClass?.[dynamicKeysTwo] == data?.[dynamicKeysTwo] ? "active" : ""
              }
              key={index}
              onClick={() => getFunction(data)}
            >
              {data?.[dynamicKeysOne]}
            </ListGroup.Item>
          ))
        ) : (
          <ListGroup.Item>{noData}</ListGroup.Item>
        )}
        {/* {
  list?.filter((item) => {
    return search.toLowerCase() === '' ? item : item.name.toLowerCase().includes(search);
  }).length > 0 ? (
    list?.filter((item) => {
      return search.toLowerCase() === '' ? item : item.name.toLowerCase().includes(search);
    }).map((data, index) => {
      return <ListGroup.Item key={index} onClick={() => selectDonationCenter(data?.id)}>{data?.name}</ListGroup.Item>
    })
  ) : (
    <ListGroup.Item>No match found</ListGroup.Item>
  )
}          */}
      </ListGroup>
    </div>
  );
};
export default DropdownList;
