import { getFunctions, httpsCallable } from "firebase/functions";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import React, { useEffect, useState } from "react";
import { Col, Form, Row, Tab, Table, Tabs } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import UseLoader from "../../Components/Loader/Loader";
import Modal from "../../Components/Modal/Modal";
import UseTopBar from "../../Components/TopBar/TopBar";
import UseAlert from "../../Components/UseAlert/UseAlert";
import UseButton from "../../Components/UseButton/UseButton";
import UseImageUpload from "../../Components/UseImageUpload/UseImageUpload";
import { useAuth } from "../../Hookes/useAuth";
import "./AdminSettings.scss";
const AdminSettings = () => {
  const { uid, loading } = useAuth();
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [image, setImage] = useState("");
  const [email, setEmail] = useState("");
  const [show, setShow] = useState(false);
  const [variant, setVarient] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const [deleteProfileModalOpen, setDeleteProfileModalOpen] = useState(false);
  const [profileToDelete, setProfileToDelete] = useState(null);
  const [activeKey, setActiveKey] = useState("home");
  const [id, setId] = useState("");
  const [username, setUserName] = useState("");
  const functions = getFunctions();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      lastName: "",
      role: "",
      email: "",
      profileImage: "",
    },
    mode: "onSubmit",
  });
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    let id = localStorage?.getItem("uid");
    setId(id || uid);
    getCurrentUserProfile(id || uid);
    getProfileInfo();
    if (location?.state?.addTabs) {
      setActiveKey(location.state.addTabs);
    }
  }, []);
  const getCurrentUserProfile = (id) => {
    try {
      const getProfile = httpsCallable(functions, "getCollaboratorById");
      getProfile({ id: uid }).then((response) => {
        loadProfile(response.data);
      });
    } catch (error) {
      console.error("error", error);
    }
  };

  const loadProfile = (data) => {
    console.log("load individula profile", data);
    //name:'',lastName:'',role:'',email:'',profileImage:''
    setValue("name", data?.name);
    setValue("lastName", data?.lastName);
    setValue("role", data?.role);
    setValue("email", data?.email);
    setEmail(data?.email);
    setValue("profileImage", data?.profileImage);
    setImage(data?.profileImage);
  };

  const getProfileInfo = () => {
    try {
      setLoader(true);
      const getProfile = httpsCallable(functions, "getCollaborators");
      getProfile()
        .then((response) => {
          setData(true);
          setLoader(false);
          console.log("load profile", response);
          setData(response.data);
        })
        .catch((error) => console.error("error", error));
    } catch (error) {
      console.error("error", error);
    } finally {
      setData(false);
    }
  };

  const employeeProfile = () => {
    navigate("/Impostazioni/Scheda-profilo-collaboratore");
  };

  const logoUpload = (e) => {
    console.log("file>>>>>", e.target.files[0]);
    // setImage(e.target.files[0])
    const file = e.target.files[0];
    if (file) {
      uploadFile(file);
    }
  };

  const uploadFile = async (file) => {
    try {
      setLoader(true);
      const generateUploadUrl = httpsCallable(functions, "uploadFiles");
      const data = {
        contentType: file.type,
        collection: "users",
      };
      const {
        data: { url },
      } = await generateUploadUrl(data);
      const response = await fetch(url, {
        method: "POST",
        body: file,
      });

      if (!response.ok) {
        throw new Error("File upload failed");
      }
      const link = new URL(response?.url);
      const params = new URLSearchParams(link.search);
      const name = params.get("name");
      const fileName = name.split("/")[2];

      const storage = getStorage();

      const fileRef = ref(storage, `/uploads/users/${fileName}`);

      const downloadUrl = await getDownloadURL(fileRef);
      console.log("===Download Url====", downloadUrl);
      setValue("profileImage", downloadUrl);
      setImage(downloadUrl);
    } catch (error) {
      console.error("error", error);
    } finally {
      setLoader(false);
    }
  };

  const onSubmit = (data) => {
    // console.log('dataaaaaaaaaaa',data)
    try {
      setLoader(true);
      localStorage.setItem("name", data.name);
      const createProfile = httpsCallable(functions, "updateCollaborators");
      createProfile({ ...data, id: id })
        .then((response) => {
          setLoader(false);
          setShow(true);
          setVarient("success");
          setAlertMsg("Salvato con successo");
          localStorage.setItem("profileImage", image);
          window.location.reload();
          // setTimeout(() => {
          //     window.location.reload();
          // }, 2000);
          console.log("add profile response", response);
        })
        .catch((error) => {
          setLoader(false);
          setShow(true);
          setVarient("danger");
          setAlertMsg(error.message);
        });
    } catch (error) {
      console.error("error", error);
    }
  };

  const removeLogo = () => {
    setValue("profileImage", "");
    setImage("");
    // window.location.reload();
  };

  const editProfile = (id, name, email) => {
    console.log("profile id : ", id, "name : ", name, "email : ", email);
    navigate("/Impostazioni/Modificare-profilo-collaboratore", {
      state: {
        editPorfileId: id,
        editProfileName: name,
        editProfileEmailId: email,
        EditProfileStatus: true,
      },
    });
  };

  const removeProfile = (id, name, lastName) => {
    setProfileToDelete(id);
    setDeleteProfileModalOpen(true);
    setUserName(name, lastName);
  };

  const cofirmDeleteProfile = () => {
    const deleteProfile = httpsCallable(functions, "deleteCollaborator");
    setProfileToDelete(null);
    setDeleteProfileModalOpen(false);
    setLoader(true);
    deleteProfile({ id: profileToDelete })
      .then((response) => {
        console.log("response", response);
        setLoader(false);
        // window.location.reload();
        setActiveKey("profile");
        getProfileInfo();
      })
      .catch((error) => {
        console.error("error", error);
        setLoader(false);
      });
  };

  const removeConventionCancel = () => {
    setProfileToDelete(null);
    setDeleteProfileModalOpen(false);
  };

  const handleActiveTabs = (key) => {
    setActiveKey(key);
  };

  const resetPassword = () => {
    try {
      setLoader(true);
      const resetPassword = httpsCallable(functions, "sendResetPasswordLink");
      resetPassword({ email: email }).then((response) => {
        setLoader(false);
        setShow(true);
        setVarient("success");
        setAlertMsg(response.data.message);
        // setTimeout(() => {
        //     window.location.reload();
        // }, 2000);
        // console.log('reset password response',response)
      });
    } catch (error) {
      console.error("error", error);
      setLoader(false);
      setShow(true);
      setVarient("danger");
      setAlertMsg(error.message);
    }
  };
  if (loading) return null;
  return (
    <div className='AdminSettings' data-testid='AdminSettings'>
      <UseTopBar back='dashboard' hideSettings={true} />
      <section className='settings-tabs'>
        <Tabs
          activeKey={activeKey}
          onSelect={(key) => handleActiveTabs(key)}
          id='uncontrolled-tab-example'
          className='mb-3'
        >
          <Tab eventKey='home' title='Profilo personale'>
            <h2>Impostazioni account</h2>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col xs={12} sm={6} md={6} lg={6}>
                  <Form.Group className='mb-3' controlId='formBasicEmail'>
                    <Form.Label>Nome</Form.Label>
                    <Form.Control
                      type='text'
                      name='name'
                      {...register("name", { required: true })}
                    />
                    {errors.name && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "14px",
                          position: "relative",
                          top: "5px",
                        }}
                      >
                        Please enter Name
                      </p>
                    )}
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6}>
                  <Form.Group className='mb-3' controlId='formBasicEmail'>
                    <Form.Label>Ruolo</Form.Label>
                    <Form.Select name='role' {...register("role")}>
                      <option value='admin'>Admin</option>
                      <option value='viewer'>Viewer</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6}>
                  <Form.Group className='mb-3' controlId='formBasicEmail'>
                    <Form.Label>Cognome</Form.Label>
                    <Form.Control type='text' name='lastName' {...register("lastName")} />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6}>
                  <Form.Group className='mb-3' controlId='formBasicEmail'>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type='email'
                      name='email'
                      {...register("email", {
                        required: true,
                        pattern: {
                          value:
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: "ID e-mail non valido",
                        },
                      })}
                    />
                    {errors.email && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "14px",
                          position: "relative",
                          top: "5px",
                        }}
                      >
                        Please enter valid emailid
                      </p>
                    )}
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6} className='imageUpload '>
                  <Form.Group className='mb-3' controlId='formBasicEmail'>
                    <Form.Label>Immagine profilo</Form.Label> <br></br>
                    {/* <UseButton name="adminBtnPrimary" variant="outline-primary" labels="Carica l’immagine" />                                  */}
                    {!image && (
                      <UseImageUpload label='Carica l’immagine' onChange={logoUpload} />
                    )}
                    {image && (
                      <div className='logoPreview'>
                        <img src={image} alt='Image' />
                        <span onClick={removeLogo}>
                          <svg
                            width='10'
                            height='10'
                            viewBox='0 0 10 10'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              fillRule='evenodd'
                              clipRule='evenodd'
                              d='M1.75322 6.55322L3.45122 8.25122L8.04122 3.66122L6.34322 1.96322L1.75322 6.55322ZM0.199219 9.79922L2.84522 8.84522L1.15922 7.17122L0.199219 9.79922ZM8.59922 0.199219C8.26922 0.199219 7.96922 0.331219 7.75322 0.553219L6.76322 1.54322L8.46122 3.24122L9.45122 2.25122C9.66722 2.02922 9.79922 1.72922 9.79922 1.39922C9.79922 0.739219 9.25922 0.199219 8.59922 0.199219Z'
                              fill='#F7F7FA'
                            />
                          </svg>
                        </span>
                      </div>
                    )}
                  </Form.Group>
                  <Form.Text className='text-muted'>
                    <svg
                      width='21'
                      height='20'
                      viewBox='0 0 21 20'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M10.5 0C4.98 0 0.5 4.48 0.5 10C0.5 15.52 4.98 20 10.5 20C16.02 20 20.5 15.52 20.5 10C20.5 4.48 16.02 0 10.5 0ZM11.5 16H9.5V14H11.5V16ZM11.5 13H9.5V4H11.5V13Z'
                        fill='#D2D2D6'
                      />
                    </svg>
                    400px - max 70mb - PNG, JPEG
                  </Form.Text>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6}>
                  <Form.Group className='mb-3' controlId='formBasicEmail'>
                    <Form.Label>Password</Form.Label>
                    <UseButton
                      type='button'
                      onClick={resetPassword}
                      name='adminBtnPrimary'
                      variant='outline-primary'
                      labels='Invia link reset'
                    />
                    {/* <Form.Control type="text" placeholder="platform.mariorossi.it" />                                       */}
                  </Form.Group>
                </Col>
              </Row>
              <UseButton
                type='submit'
                name='adminBtnPrimary profileSaveBtn'
                variant='primary'
                labels='Salva modifiche'
              />
            </Form>
          </Tab>
          <Tab eventKey='profile' title='Altri collaboratori'>
            <div className='collabarations'>
              <UseButton
                onClick={employeeProfile}
                name='adminBtnPrimary'
                variant='primary'
                labels='Crea nuovo profilo'
              />
              <h2>Profili collaboratori</h2>
              <Table hover>
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>Cognome</th>
                    <th>Ruolo</th>
                    <th>Email</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 ? (
                    data.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td>{item.lastName}</td>
                          <td>{item.role}</td>
                          <td>{item.email}</td>
                          <td width='80'>
                            <button
                              onClick={() => editProfile(item.id, item.name, item.email)}
                              className='edit'
                            >
                              <svg
                                width='16'
                                height='18'
                                viewBox='0 0 16 18'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M3.345 12.2419L11.7967 3.79026L10.6183 2.61193L2.16667 11.0636V12.2419H3.345ZM4.03583 13.9086H0.5V10.3728L10.0292 0.843598C10.1854 0.687372 10.3974 0.599609 10.6183 0.599609C10.8393 0.599609 11.0512 0.687372 11.2075 0.843598L13.565 3.2011C13.7212 3.35737 13.809 3.56929 13.809 3.79026C13.809 4.01123 13.7212 4.22316 13.565 4.37943L4.03583 13.9086ZM0.5 15.5753H15.5V17.2419H0.5V15.5753Z'
                                  fill='#2264D1'
                                />
                              </svg>
                            </button>

                            <button
                              onClick={() =>
                                removeProfile(item.id, item.name, item.lastName)
                              }
                              className='delete'
                            >
                              {" "}
                              <svg
                                width='14'
                                height='16'
                                viewBox='0 0 14 16'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M2.83398 15.5C2.37565 15.5 1.98343 15.3369 1.65732 15.0108C1.33121 14.6847 1.16787 14.2922 1.16732 13.8333V3H0.333984V1.33333H4.50065V0.5H9.50065V1.33333H13.6673V3H12.834V13.8333C12.834 14.2917 12.6709 14.6842 12.3448 15.0108C12.0187 15.3375 11.6262 15.5006 11.1673 15.5H2.83398ZM11.1673 3H2.83398V13.8333H11.1673V3ZM4.50065 12.1667H6.16732V4.66667H4.50065V12.1667ZM7.83398 12.1667H9.50065V4.66667H7.83398V12.1667Z'
                                  fill='#2264D1'
                                />
                              </svg>
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan='5'>Nessun dato</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </Tab>
        </Tabs>
      </section>
      {loader == true && <UseLoader />}
      {deleteProfileModalOpen && (
        <Modal
          onConfirmReturnData={profileToDelete}
          onCancel={removeConventionCancel}
          onConfirm={cofirmDeleteProfile}
        >
          <h3>Vuoi davvero eliminare il profilo di&ldquo;{username}&rdquo;?</h3>
          <p>Una volta eliminato non potrai tornare indietro</p>
        </Modal>
      )}
      <UseAlert show={show} setShow={setShow} variant={variant} alertMsg={alertMsg} />
    </div>
  );
};

AdminSettings.propTypes = {};

AdminSettings.defaultProps = {};

export default AdminSettings;
