import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "../../../../node_modules/react-responsive-carousel/lib/styles/carousel.min.css";
import CardThree from "../../Card/CardThree/CardThree";
import UseLoader from "../../Loader/Loader";
import Modal from "../../Modal/Modal";
import "./CarouselSliderTwo.scss";
const CarouselSliderTwo = ({ events = [] }) => {
  const [cancelBooking, setCancelBooking] = useState(false);
  const [bookingToDelete, setBookingToDelete] = useState(null);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState(events);
  const navigate = useNavigate();

  useEffect(() => {
    if (events.length) {
      setData(events);
    }
  }, [events]);
  const onCancelConfirm = () => {
    setLoader(true);
    let postData = {
      userId: bookingToDelete.userId,
      timeslotId: bookingToDelete.timeslotId,
      intervalId: bookingToDelete.intervalId,
      donationCenterId: bookingToDelete.donationCenterId,
    };
    console.log(postData);
    const deleteUser = httpsCallable(functions, "removeUserFromBookingSlot");
    deleteUser(postData)
      .then((response) => {
        loadData();
      })
      .catch((error) => {
        setLoader(false);
      })
      .finally(() => {
        setLoader(false);
      });

    setBookingToDelete(null);
    setCancelBooking(false);
  };
  const onCancelStart = (data) => {
    console.log(data);
    setBookingToDelete(data);
    setCancelBooking(true);
  };

  const onModify = (_data) => {
    navigate("/book", { state: { reservation: _data } });
  };
  //   const [oldData, setOldData] = useState([])
  const functions = getFunctions();

  const loadData = () => {
    setLoader(false);
    try {
      const getUpcomingSlotDetails = httpsCallable(functions, "getUpcomingReservations");
      getUpcomingSlotDetails()
        .then((response) => {
          console.log("feature slots", response);
          setData(response.data);
          setLoader(false);
        })
        .catch((error) => {
          console.error("error", error);
          setLoader(false);
        });
    } catch (error) {
      console.error("error", error);
      setLoader(false);
    }

    // try{
    //       const getPastSlotDetails = httpsCallable(functions,"getPastReservations")
    //             getPastSlotDetails()
    //             .then((response)=>{
    //                   console.log('past slots',response)
    //                   setOldData(response.data)
    //             })
    //             .catch((error)=>console.error('error',error));
    //       }
    //       catch(error){
    //             console.error('error',error);
    //       }
  };

  return (
    <div className='CaroselSliderTwo'>
      {/* <CardThree /> */}
      <Row infiniteLoop={false}>
        {data?.map((_data, index) => {
          return (
            <Col xs='12' sm='6' lg='4'>
              <CardThree
                onModify={onModify}
                onCancel={onCancelStart}
                key={index}
                data={_data}
              />
            </Col>
          );
        })}
      </Row>
      {cancelBooking && (
        <Modal
          confirmText={"Conferma"}
          onCancel={() => {
            setCancelBooking(false);
            setBookingToDelete(null);
          }}
          redButtons={true}
          onConfirm={onCancelConfirm}
          title={"Confermi di cancellare la tua prenotazione?"}
        >
          <p>Una volta cancellata non potrai più recuperarla</p>
        </Modal>
      )}
      {!data.length ? (
        <p className='noSlot'>
          Nessuna donazione ancora prenotata,{" "}
          <Link to={"/home/Scegli-il-posto"}>clicca qui</Link> per prenotare la prima.
        </p>
      ) : null}
      {loader && <UseLoader />}
    </div>
  );
};
export default CarouselSliderTwo;
