import React from "react";

const DoubleCheckedicon = ({ height, width, fill, title }) => {
  return (
    <svg
      title={title}
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill={fill}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20 7.84969C20 8.60147 19.7031 9.32353 19.1729 9.85764C17.9524 11.0876 16.7687 12.3701 15.5027 13.5554C15.2124 13.8232 14.7521 13.8134 14.4744 13.5335L10.8269 9.85764C9.72437 8.74656 9.72437 6.95282 10.8269 5.84174C11.9402 4.71973 13.754 4.71973 14.8673 5.84174L14.9999 5.97534L15.1324 5.84181C15.6662 5.30358 16.3932 5 17.1527 5C17.9121 5 18.6391 5.30355 19.1729 5.84174C19.7032 6.37591 20 7.09794 20 7.84969Z'
        stroke='#33A9A9'
        strokeWidth='1.875'
        strokeLinejoin='round'
      />
      <path
        d='M22.5 25L27.2803 20.2197C27.421 20.079 27.5 19.8883 27.5 19.6893V13.125C27.5 12.0895 26.6605 11.25 25.625 11.25V11.25C24.5895 11.25 23.75 12.0895 23.75 13.125V18.75'
        stroke='#33A9A9'
        strokeWidth='1.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M22.5 20L23.5727 18.9273C23.6862 18.8138 23.75 18.6598 23.75 18.4992V18.4992C23.75 18.2699 23.6204 18.0602 23.4153 17.9577L22.8617 17.6808C21.8992 17.1996 20.7368 17.3882 19.9759 18.1491L18.8572 19.2678C18.3884 19.7366 18.125 20.3725 18.125 21.0355V25'
        stroke='#33A9A9'
        strokeWidth='1.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.5 25L2.71967 20.2197C2.57902 20.079 2.5 19.8883 2.5 19.6893V13.125C2.5 12.0895 3.33947 11.25 4.375 11.25V11.25C5.41053 11.25 6.25 12.0895 6.25 13.125V18.75'
        stroke='#33A9A9'
        strokeWidth='1.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.5 20L6.42734 18.9273C6.31379 18.8138 6.25 18.6598 6.25 18.4992V18.4992C6.25 18.2699 6.37957 18.0602 6.5847 17.9577L7.13833 17.6808C8.1008 17.1996 9.26323 17.3882 10.0241 18.1491L11.1428 19.2678C11.6116 19.7366 11.875 20.3725 11.875 21.0355V25'
        stroke='#33A9A9'
        strokeWidth='1.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default DoubleCheckedicon;
