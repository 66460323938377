import "./UseDateDropdownWithSearch.scss";
// import NotificationList from "../ListGroup/Notification/Notification";
import { Form } from "react-bootstrap";
import NotificationNumberList from "../ListGroup/NotificationNumber/NotificationNumber";
const UseDateDropDownWithSearch = ({
  search,
  onSearch,
  list,
  label,
  open,
  setOpen,
  getFunction,
  selectDonationCenter,
  noData,
  activeClass,
  dynamicKeysOne,
  dynamicKeysTwo,
}) => {
  return (
    <div className='UseDateDropDownWithSearch'>
      <div className='searchLabel' onClick={() => setOpen(!open)}>
        {label}
      </div>
      <div className='searchableList' style={{ display: open ? "flex" : "none" }}>
        <div className='innerSearch'>
          <div className='container'>
            <div className='searchForm'>
              <Form.Control
                placeholder='Cerca'
                type='text'
                value={search}
                onChange={(e) => onSearch(e.target.value)}
              />
            </div>
            <NotificationNumberList
              dynamicKeysOne={dynamicKeysOne}
              dynamicKeysTwo={dynamicKeysTwo}
              activeClass={activeClass}
              noData={noData}
              search={search}
              list={list}
              getFunction={getFunction}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default UseDateDropDownWithSearch;
