import { useEffect, useState } from "react";
import "./CarouselSliderOne.scss";
// import {Row, Col, Button} from 'react-bootstrap';
import { getFunctions, httpsCallable } from "firebase/functions";
import { Col, Row } from "react-bootstrap";
import "../../../node_modules/react-responsive-carousel/lib/styles/carousel.min.css";
import CardOne from "../Card/CardOne/CardOne";
const CaroselSliderOne = ({ companyId }) => {
  const [data, setData] = useState([]);
  const functions = getFunctions();

  const loadEvents = () => {
    const getEvents = httpsCallable(functions, "getUpcomingCompanyEvents");
    getEvents({ company: companyId })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => console.error("error", error));
  };

  useEffect(() => {
    loadEvents();
  }, [companyId]);
  return (
    <div className='CarouselOne'>
      {data.length !== 0 && (
        <Row className='justify-content-start '>
          {data.map((datas, index) => {
            return (
              <Col xs='12' sm='6' lg='4'>
                <CardOne key={index} data={datas} />
              </Col>
            );
          })}
        </Row>
      )}
      {!data?.length && <p className='noEvents'>Nessun evento da mostrare</p>}
    </div>
  );
};
export default CaroselSliderOne;
