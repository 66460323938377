import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import VideoCard from "../../Components/Card/VideoCard/VideoCard";
import video1 from "../../assets/images/video1.jpg";
import "./video.scss";
const Video = () => (
  <div className='video' data-testid='Video'>
    <Container>
      <section className='content'>
        <Row>
          <Col xs={12} sm={9} md={9} lg={9}>
            <Image src={video1} alt='image' className='large-image' />
          </Col>
          <Col xs={3} sm={3} md={3} lg={3}>
            <div className='videList'>
              <VideoCard />
              <VideoCard />
              <VideoCard />
              <VideoCard />
              <VideoCard />
            </div>
          </Col>
        </Row>
      </section>
    </Container>
  </div>
);

Video.propTypes = {};

Video.defaultProps = {};

export default Video;
