import React from "react";
import { Col, Row } from "react-bootstrap";
import CardSeven from "../../Components/Card/CardSeven/CardSeven";
import CardSix from "../../Components/Card/CardSix/CardSix";
import "./DonateNow.scss";
const DonateNow = () => {
  return (
    <div className='DonateNow' data-testid='DonateNow'>
      <Row>
        <Col xs={12} sm={6} md={6} lg={6}>
          {/* <h5>Prenota subito</h5> */}
          <CardSix />
          {/* <form noValidate>
              <Card className=''>
                <h6>Dove ti trovi?</h6>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">

                <Form.Control type="text" placeholder="Viale Alessandro Magno 347, Roma, 00124" />
               </Form.Group>
               <Button>Cerca il luogo più vicino</Button>
               <div style={{textAlign:'center'}}>
                <span><svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M0.5 8.81437L9.67 11.3144L12.17 20.4844L20.5 0.484375L0.5 8.81437Z" fill="#B4B4BB"/>
</svg> oppure attiva geolocalizzazione</span>
</div>
                </Card>
           </form> */}
        </Col>
        <Col xs={12} sm={6} md={6} lg={6} className='d-flex justify-content-end'>
          {/* <h5>Posso donare?</h5> */}
          <CardSeven />
        </Col>
      </Row>
    </div>
  );
};

DonateNow.propTypes = {};

DonateNow.defaultProps = {};

export default DonateNow;
