import { format } from "date-fns";
import it from "date-fns/locale/it";
import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import UseLoader from "../../Components/Loader/Loader";
import Modal from "../../Components/Modal/Modal";
import SearchFilter from "../../Components/SearchFilter/SearchFilter";
import UseTopBar from "../../Components/TopBar/TopBar";
import UseButton from "../../Components/UseButton/UseButton";
import "./Users.scss";

const Users = () => {
  const functions = getFunctions();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [search, setSearch] = useState("");

  const [openFilterOne, setOpenFilterOne] = useState();
  const [openFilterTwo, setOpenFilterTwo] = useState();
  const [openFilterThree, setOpenFilterThree] = useState();
  const [openFilterFour, setOpenFilterFour] = useState();
  const [openFilterFive, setOpenFilterFive] = useState();
  const [openFilterSix, setOpenFilterSix] = useState();

  const [deleteUserConfirmOpen, setDeleteUserConfirmOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [loader, setLoader] = useState(true);

  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedorderBy, setSelectedOrderBy] = useState(null);
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedDonationCentres, setSelectedDonationCentres] = useState([]);
  const [selectedPartnerships, setSelectedPartnerships] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedbloodGroups, setSelectedbloodGroups] = useState([]);

  const [userCompanies, setUserCompanies] = useState([]);
  const [userDonationCenters, setUserDonationCenters] = useState([]);
  const [userCities, setuserCities] = useState([]);
  const [userConventions, setUserConventions] = useState([]);
  const [bloodGroups, setbloodGroups] = useState([
    {
      label: "Non lo so",
      value: "Non lo so",
    },
    {
      label: "A+ (A Rh D positivo)",
      value: "A+",
    },
    {
      label: "A- (A Rh D negativo)",
      value: "A-",
    },
    {
      label: "B+ (B Rh D positivo)",
      value: "B+",
    },
    {
      label: "B- (B Rh D negativo)",
      value: "B-",
    },
    {
      label: "O+ (O Rh D positivo)",
      value: "O+",
    },
    {
      label: "O- (O Rh D negativo)",
      value: "O-",
    },
    {
      label: "AB+ (AB Rh D positivo)",
      value: "AB+",
    },
    {
      label: "AB- (AB Rh D negativo)",
      value: "AB-",
    },
  ]);

  const addUser = () => {
    navigate("/Utenti/Aggiungi-Utenti");
  };
  useEffect(() => {
    getDonarDetails();
    getFilters();
  }, []);
  const csvData = useMemo(() => data, [data]);
  const getDonarDetails = (data) => {
    try {
      const getDonors = httpsCallable(functions, "getDonors");
      getDonors(data)
        .then((response) => {
          setLoader(false);
          setData(response.data);
        })
        .catch((error) => console.error("error", error));
    } catch (error) {
      console.log("error", error);
    }
  };

  const removeUser = (userId) => {
    setUserToDelete(userId);
    setDeleteUserConfirmOpen(true);
  };
  const removeUserConfirm = (userId) => {
    const deleteDonar = httpsCallable(functions, "deleteDonor");
    setLoader(true);
    setUserToDelete(null);
    setDeleteUserConfirmOpen(false);

    deleteDonar({ id: userId })
      .then((response) => {
        setLoader(false);
        window.location.reload();
      })
      .catch((error) => {
        setLoader(false);
        console.error("error", error);
      });
  };

  const removeUserCancel = () => {
    setUserToDelete(null);
    setDeleteUserConfirmOpen(false);
  };

  const editUsers = (id, userId, name, surName) => {
    navigate("/Utenti/Modificare-Utenti", {
      state: {
        editUserId: id,
        userName: name,
        surName: surName,
        userId: userId,
        editUserStatus: true,
      },
    });
  };

  const getDate = (e) => {
    setSelectedDate(e);
    getUsersWithFilters();
  };

  const getUsersWithFilters = (reset) => {
    getDonarDetails(
      reset
        ? null
        : {
            filters: {
              orderBy: selectedorderBy,
              city: selectedCities,
              company: selectedCompanies,
              donationCentre: selectedDonationCentres,
              partnership: selectedPartnerships,
              date: selectedDate,
              bloodGroups: selectedbloodGroups,
            },
          },
    );
    setOpenFilterOne(false);
    setOpenFilterTwo(false);
    setOpenFilterThree(false);
    setOpenFilterFour(false);
    setOpenFilterFive(false);
    setOpenFilterSix(false);
  };

  const filterCities = (value) => {
    setSelectedCities((prev) => {
      if (prev.includes(value)) {
        return prev.filter((val) => val !== value);
      }

      return [...prev, value];
    });
  };

  const filterPartnerships = (value) => {
    setSelectedPartnerships((prev) => {
      if (prev.includes(value)) {
        return prev.filter((val) => val !== value);
      }

      return [...prev, value];
    });
  };

  const filterDonationCenters = (value) => {
    setSelectedDonationCentres((prev) => {
      if (prev.includes(value)) {
        return prev.filter((val) => val !== value);
      }

      return [...prev, value];
    });
  };

  const filterCompanies = (value) => {
    setSelectedCompanies((prev) => {
      if (prev.includes(value)) {
        return prev.filter((val) => val !== value);
      }

      return [...prev, value];
    });
  };
  const filterbloodGroups = (value) => {
    setSelectedbloodGroups((prev) => {
      if (prev.includes(value)) {
        return prev.filter((val) => val !== value);
      }

      return [...prev, value];
    });
  };

  const getFilters = () => {
    try {
      const getUserCompanies = httpsCallable(functions, "getUsersCompanies");
      const getUserDonationCenters = httpsCallable(functions, "getUsersDonationCenters");
      const getUsersCities = httpsCallable(functions, "getUsersCities");
      const getUserConventions = httpsCallable(functions, "getUsersPartnerships");

      getUserCompanies()
        .then((response) => {
          let UC = [];
          if (response.data && response.data.length > 0) {
            response.data.map((d) => {
              if (d.companyId && d.companyName) {
                UC.push({
                  value: d.companyId,
                  label: d.companyName,
                });
              }
            });
          }
          setUserCompanies(UC);
        })
        .catch((error) => console.error("error", error));

      getUserDonationCenters()
        .then((response) => {
          let UDC = [];
          if (response.data && response.data.length > 0) {
            response.data.map((d) => {
              if (d.donationCenterId && d.donationCenterName) {
                UDC.push({
                  value: d.donationCenterId,
                  label: d.donationCenterName,
                });
              }
            });
          }
          setUserDonationCenters(UDC);
        })
        .catch((error) => console.error("error", error));

      getUsersCities()
        .then((response) => {
          if (response.data && response.data.length > 0) {
            setuserCities(
              response.data.map((d) => {
                return {
                  value: d,
                  label: d,
                };
              }),
            );
          }
        })
        .catch((error) => console.error("error", error));

      getUserConventions()
        .then((response) => {
          let UP = [];
          if (response.data && response.data.length > 0) {
            response.data.map((d) => {
              if (d.partnershipId && d.partnershipName) {
                UP.push({
                  value: d.partnershipId,
                  label: d.partnershipName,
                });
              }
            });
          }
          setUserConventions(UP);
        })
        .catch((error) => console.error("error", error));
    } catch (error) {
      console.log("error", error);
    }
  };

  const csvUserData = [
    ["Nome", "Cognome", "Email", "Numero di telefono", "Azienda", "Ultima donazione"],
    ...csvData.map(
      ({ name, surname, email, phoneNumber, companyName, lastDonatedDate }) => [
        name,
        surname,
        email,
        phoneNumber,
        companyName,
        lastDonatedDate || "",
      ],
    ),
  ];

  return (
    <div className='Users' data-testid='Users'>
      <UseTopBar />
      <Row>
        <Col xs={12} sm={12} md={12} lg={3}>
          <h2>Tutti gli utenti</h2>
        </Col>
        <Col xs={12} sm={12} md={12} lg={3}>
          <Form.Control
            className='search me-4'
            type='text'
            placeholder='Cerca un utente'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Col>
        <Col xs={12} sm={12} md={12} lg={6} className='d-flex buttons'>
          <UseButton
            name='adminBtnPrimary'
            variant='btn btn-outline-primary'
            labels='Cancella filtri'
            onClick={() => {
              setSelectedCompanies([]);
              setSelectedPartnerships([]);
              setSelectedCities([]);
              setSelectedDonationCentres([]);
              setSelectedDate(null);
              setSelectedbloodGroups([]);
              setSelectedOrderBy(null);
              getUsersWithFilters(true);
            }}
          />
          <div className='adminBtnPrimary UseButton'>
            <CSVLink
              name='adminBtnPrimary'
              className='btn btn-btn btn-outline-primary'
              filename='utenti.csv'
              data={csvUserData}
              style={{ borderRadius: 12 }}
            >
              Esporta CSV
            </CSVLink>
          </div>
          <UseButton
            name='adminBtnPrimary'
            variant='primary'
            labels='Aggiungi utente'
            onClick={addUser}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={6} lg={2}>
          <p>Azienda</p>
          <SearchFilter
            open={openFilterFive}
            setOpen={setOpenFilterFive}
            data={userCompanies}
            selectedValue={selectedCompanies}
            onConfirm={() => {
              getUsersWithFilters();
            }}
            onChangeFilterValue={filterCompanies}
            placeholder='Filtra per azienda'
          />
        </Col>
        <Col xs={12} sm={12} md={6} lg={2}>
          <p>Ordina per</p>
          <SearchFilter
            open={openFilterOne}
            setOpen={setOpenFilterOne}
            onConfirm={() => {
              getUsersWithFilters();
            }}
            data={{
              name: "donors",
              options: [
                {
                  label: "Ultimi donatori",
                  value: "lastDonors",
                },
                {
                  label: "Ultimi iscritti",
                  value: "lastRegistered",
                },
                {
                  label: "Donatori più coinvolti",
                  value: "mostDonations",
                },
                {
                  label: "Donatori meno coinvolti",
                  value: "leastDonations",
                },
              ],
            }}
            type='radio'
            onChangeFilterValue={setSelectedOrderBy}
            placeholder={"Cerca filtro"}
            selectedValue={selectedorderBy}
          />
        </Col>

        <Col xs={12} sm={12} md={6} lg={2}>
          <p>Città</p>
          <SearchFilter
            open={openFilterTwo}
            setOpen={setOpenFilterTwo}
            data={userCities}
            selectedValue={selectedCities}
            onConfirm={() => {
              getUsersWithFilters();
            }}
            onChangeFilterValue={filterCities}
            placeholder='Filtra per città'
          />
        </Col>
        <Col xs={12} sm={12} md={6} lg={2}>
          <p>Centro di donazione</p>
          <SearchFilter
            open={openFilterThree}
            setOpen={setOpenFilterThree}
            data={userDonationCenters}
            selectedValue={selectedDonationCentres}
            onConfirm={() => {
              getUsersWithFilters();
            }}
            onChangeFilterValue={filterDonationCenters}
            placeholder='Filtra per centro donazione'
          />
        </Col>
        <Col xs={12} sm={12} md={6} lg={2}>
          <p>Convenzioni</p>
          <SearchFilter
            open={openFilterFour}
            setOpen={setOpenFilterFour}
            data={userConventions}
            selectedValue={selectedPartnerships}
            onConfirm={() => {
              getUsersWithFilters();
            }}
            onChangeFilterValue={filterPartnerships}
            placeholder='Filtra per convenzione'
          />
        </Col>
        <Col xs={12} sm={12} md={6} lg={2} className='reactCustomDatePickerFilterAdmin'>
          <p>Data</p>
          <DatePicker
            locale={it}
            dateFormat='dd/MM/YYYY'
            selected={selectedDate}
            onChange={getDate}
          />
        </Col>
        <Col xs={12} sm={12} md={6} lg={2}>
          <p> Gruppo sanguineo</p>
          <SearchFilter
            open={openFilterSix}
            selectedValue={selectedbloodGroups}
            setOpen={setOpenFilterSix}
            onConfirm={() => {
              getUsersWithFilters();
            }}
            data={bloodGroups}
            type='check'
            onChangeFilterValue={filterbloodGroups}
            placeholder={"Cerca per gruppo sanguineo"}
          />
        </Col>
      </Row>
      <Table hover responsive>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Cognome</th>
            <th>Email</th>
            <th>Numero di telefono</th>
            <th>Azienda</th>
            <th>Ultima donazione</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data
              .filter((item) => {
                return search.toLowerCase() == ""
                  ? item
                  : item?.name.toLowerCase().includes(search.toLocaleLowerCase()) ||
                      item?.surname?.toLowerCase().includes(search.toLocaleLowerCase()) ||
                      item?.email?.toLowerCase().includes(search.toLocaleLowerCase()) ||
                      (typeof item.phoneNumber === "string" &&
                        item.phoneNumber.includes(search.toLocaleLowerCase()));
              })
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((data, index) => {
                if (data) {
                  return (
                    <tr key={index}>
                      <td className='user_id'>{page * rowsPerPage + index + 1}</td>
                      <td
                        className='user_name'
                        onClick={() =>
                          editUsers(data.id, data.userId, data.name, data.surname)
                        }
                      >
                        {data.name}
                      </td>
                      <td>{data.surname}</td>
                      <td>{data.email}</td>
                      <td>{data.phoneNumber}</td>

                      <td>{data.companyName || "-"}</td>
                      <td>
                        {data.lastDonatedDate
                          ? format(new Date(data.lastDonatedDate), "dd/MM/yyyy")
                          : "-"}
                      </td>
                      <td className='d-flex'>
                        <Button
                          className='edit'
                          onClick={() =>
                            editUsers(data.id, data.userId, data.name, data.surname)
                          }
                        >
                          {" "}
                          <svg
                            width='16'
                            height='18'
                            viewBox='0 0 16 18'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M3.345 12.2419L11.7967 3.79026L10.6183 2.61193L2.16667 11.0636V12.2419H3.345ZM4.03583 13.9086H0.5V10.3728L10.0292 0.843598C10.1854 0.687372 10.3974 0.599609 10.6183 0.599609C10.8393 0.599609 11.0512 0.687372 11.2075 0.843598L13.565 3.2011C13.7212 3.35737 13.809 3.56929 13.809 3.79026C13.809 4.01123 13.7212 4.22316 13.565 4.37943L4.03583 13.9086ZM0.5 15.5753H15.5V17.2419H0.5V15.5753Z'
                              fill='#2264D1'
                            />
                          </svg>{" "}
                        </Button>{" "}
                        <Button className='delete' onClick={() => removeUser(data.id)}>
                          <svg
                            width='14'
                            height='16'
                            viewBox='0 0 14 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M2.83398 15.5C2.37565 15.5 1.98343 15.3369 1.65732 15.0108C1.33121 14.6847 1.16787 14.2922 1.16732 13.8333V3H0.333984V1.33333H4.50065V0.5H9.50065V1.33333H13.6673V3H12.834V13.8333C12.834 14.2917 12.6709 14.6842 12.3448 15.0108C12.0187 15.3375 11.6262 15.5006 11.1673 15.5H2.83398ZM11.1673 3H2.83398V13.8333H11.1673V3ZM4.50065 12.1667H6.16732V4.66667H4.50065V12.1667ZM7.83398 12.1667H9.50065V4.66667H7.83398V12.1667Z'
                              fill='#2264D1'
                            />
                          </svg>
                        </Button>
                      </td>
                    </tr>
                  );
                }
                if (!data) {
                  return (
                    <tr key={index}>
                      <td colSpan='8'>Nessun dato</td>
                    </tr>
                  );
                }
              })
          ) : (
            <tr>
              <td colSpan='8'>Nessun dato</td>
            </tr>
          )}
        </tbody>
      </Table>
      {data.length >= 10 && (
        <div className='pagination'>
          {page + 1} - {Math.ceil(data.length / rowsPerPage)}
          <button className='prev' onClick={() => setPage(page - 1)} disabled={page == 0}>
            <svg
              width='8'
              height='13'
              viewBox='0 0 8 13'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M7.71094 2.12094L3.12094 6.71094L7.71094 11.3009L6.29094 12.7109L0.290937 6.71094L6.29094 0.710938L7.71094 2.12094Z'
                fill='#787885'
              />
            </svg>
          </button>
          <button
            className='next'
            onClick={() => setPage(page + 1)}
            disabled={Number(page) == Math.ceil(Math.abs(data.length / rowsPerPage - 1))}
          >
            <svg
              width='8'
              height='13'
              viewBox='0 0 8 13'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M0.295078 2.11312L4.87508 6.70312L0.295078 11.2931L1.70508 12.7031L7.70508 6.70312L1.70508 0.703125L0.295078 2.11312Z'
                fill='#787885'
              />
            </svg>
          </button>
        </div>
      )}

      {deleteUserConfirmOpen && (
        <Modal
          title='Sei sicuro?'
          onConfirmReturnData={userToDelete}
          onCancel={removeUserCancel}
          onConfirm={removeUserConfirm}
        >
          <p>Una volta eliminato non potrai tornare indietro</p>
        </Modal>
      )}
      {loader && <UseLoader />}
    </div>
  );
};

Users.propTypes = {};

Users.defaultProps = {};

export default Users;
