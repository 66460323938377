import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Card, ListGroup } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "./CardFour.scss";
moment.locale("en");
const CardFour = ({ data, onDeleteItem, onDeleteDay }) => {
  // const [data, setData] = useState([])
  // console.log('inside card 4<>>>',data)
  // useEffect(()=>{
  //     setData(datas)
  // },[])
  console.log("dataaaaaa", data);
  const [localData, setLocalData] = useState(data);
  const navigate = useNavigate();

  useEffect(() => {
    setLocalData(data);
  }, [data]);

  const setIntervalEditMode = (intervalId) => {
    let newIntervals = localData.timeIntervals.map((interval) => {
      return {
        ...interval,
        editMode: intervalId == interval.id ? !interval.editMode : interval.editMode,
      };
    });

    setLocalData({ ...localData, timeIntervals: [...newIntervals] });
  };

  const userProfile = (info) => {
    // console.log('info',info);
    navigate("/Utenti/Modificare-Utenti", {
      state: {
        editUserId: info.userId,
        userId: info.userId,
        userName: info.userName,
        editUserStatus: true,
      },
    });
  };

  return (
    <div className='CardFour'>
      <Card>
        <Card.Body>
          <Card.Title>
            {moment(data.date).format("Do MMM, YYYY")}
            {/* {for} */}
            <Link to={"#"}>
              <button className='delete' onClick={() => onDeleteDay(data)}>
                <svg
                  width='14'
                  height='16'
                  viewBox='0 0 14 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M2.83398 15.5C2.37565 15.5 1.98343 15.3369 1.65732 15.0108C1.33121 14.6847 1.16787 14.2922 1.16732 13.8333V3H0.333984V1.33333H4.50065V0.5H9.50065V1.33333H13.6673V3H12.834V13.8333C12.834 14.2917 12.6709 14.6842 12.3448 15.0108C12.0187 15.3375 11.6262 15.5006 11.1673 15.5H2.83398ZM11.1673 3H2.83398V13.8333H11.1673V3ZM4.50065 12.1667H6.16732V4.66667H4.50065V12.1667ZM7.83398 12.1667H9.50065V4.66667H7.83398V12.1667Z'
                    fill='#2264D1'
                  />
                </svg>
              </button>
            </Link>
          </Card.Title>
          <div className='content'>
            <ListGroup variant='flush'>
              {localData?.timeIntervals?.map((data, index) => {
                return (
                  <ListGroup.Item key={index}>
                    <h6 onClick={() => setIntervalEditMode(data.id)} className='slot'>
                      {data.slot}{" "}
                      <svg
                        className='close'
                        width='20'
                        height='20'
                        viewBox='0 0 20 20'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M5.345 13.24L13.7967 4.78831L12.6183 3.60998L4.16667 12.0616V13.24H5.345ZM6.03583 14.9066H2.5V11.3708L12.0292 1.84164C12.1854 1.68542 12.3974 1.59766 12.6183 1.59766C12.8393 1.59766 13.0512 1.68542 13.2075 1.84164L15.565 4.19914C15.7212 4.35542 15.809 4.56734 15.809 4.78831C15.809 5.00928 15.7212 5.22121 15.565 5.37748L6.03583 14.9066ZM2.5 16.5733H17.5V18.24H2.5V16.5733Z'
                          fill='#2264D1'
                        />
                      </svg>
                    </h6>
                    {!data.bookedUsers.length ? (
                      <Button>Libero</Button>
                    ) : (
                      <div className='users_wrap'>
                        {data.bookedUsers.map((data1, index) => {
                          if (data1.status == "pending") {
                            return (
                              <span
                                className='username'
                                onClick={() => userProfile(data1)}
                                key={index}
                              >
                                {data1.userName}
                                {data.editMode ? (
                                  <svg
                                    onClick={(event) => {
                                      // Prevent click event from bubbling up to parent elements
                                      event.stopPropagation();
                                      if (onDeleteItem) {
                                        onDeleteItem(data1.userId, localData.id, data.id);
                                      }
                                    }}
                                    className='delete_username'
                                    width='8'
                                    height='8'
                                    viewBox='0 0 8 8'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                  >
                                    <path
                                      fillRule='evenodd'
                                      clipRule='evenodd'
                                      d='M5.128 4L7.76 1.368C7.912 1.224 8 1.024 8 0.8C8 0.36 7.64 0 7.2 0C6.976 0 6.776 0.088 6.632 0.232L4 2.872L1.368 0.232C1.224 0.088 1.024 0 0.8 0C0.36 0 0 0.36 0 0.8C0 1.024 0.088 1.224 0.232 1.368L2.872 4L0.24 6.632C0.0880002 6.776 0 6.976 0 7.2C0 7.64 0.36 8 0.8 8C1.024 8 1.224 7.912 1.368 7.768L4 5.128L6.632 7.76C6.776 7.912 6.976 8 7.2 8C7.64 8 8 7.64 8 7.2C8 6.976 7.912 6.776 7.768 6.632L5.128 4Z'
                                      fill='#4A4B57'
                                    />
                                  </svg>
                                ) : null}
                              </span>
                            );
                          } else {
                            return <Button>Libero</Button>;
                          }
                        })}
                      </div>
                    )}
                  </ListGroup.Item>
                );
              })}

              {/* {
                            data?.timeIntervals?.map((value, index)=>{
                                return <div key={index}>
                                    {value.bookedUsers?.map((value1, index)=>{
                                        return <ListGroup.Item key={index}>

                                                    {new Date(value1.createdAt._seconds * 1000 + value1?.createdAt._nanoseconds / 1000000).toDateString()}&nbsp;&nbsp;
                                                    {new Date(value1.createdAt._seconds * 1000 + value1?.createdAt._nanoseconds / 1000000).toLocaleTimeString()}
                                            </ListGroup.Item>
                                    })}
                                </div>
                            })
                          } */}
            </ListGroup>

            {/* {data?.timeIntervals?.map((value,index)=>{
                        return (<div className='bookedDonars' key={index}>
                                     {value?.bookedUsers?.map((value1, index)=>{
                                    return <span key={index}>{value1.userName}</span>
                                })}
                            </div>)
                     })}      */}

            {/* <div className='bookedDonars'>



                            <span>Mario Rossi</span>
                            <span>Mario Rossi</span>
                        </div>  */}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};
export default CardFour;
