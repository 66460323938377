import React, { lazy, Suspense } from "react";

const GeneraliLazyLayout = lazy(() => import("./GeneraliLayout"));

const GeneraliLayout = (props) => (
  <Suspense fallback={null}>
    <GeneraliLazyLayout {...props} />
  </Suspense>
);

export default GeneraliLayout;
