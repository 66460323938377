import React from "react";

const BookIcon = ({ height, width, fill }) => {
  return (
    <svg
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill={fill}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.25 26.25H6.25C4.86929 26.25 3.75 25.1307 3.75 23.75V12.5H26.25V16.25M18.75 5V2.5M18.75 5V7.5M18.75 5H13.125'
        stroke='#3C3C3C'
        strokeWidth='1.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.75 12.5V7.5C3.75 6.11929 4.86929 5 6.25 5H8.75'
        stroke='#3C3C3C'
        strokeWidth='1.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.75 2.5V7.5'
        stroke='#3C3C3C'
        strokeWidth='1.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M26.25 12.5V7.5C26.25 6.11929 25.1307 5 23.75 5H23.125'
        stroke='#3C3C3C'
        strokeWidth='1.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.7402 22.5H22.4902M26.25 22.5H22.4902M22.4902 22.5V18.75M22.4902 22.5V26.25'
        stroke='#3C3C3C'
        strokeWidth='1.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default BookIcon;
